import { gql } from '@apollo/client/core';

export const GET_PUBLISHED_JOBS = gql`
  query GET_PUBLISHED_JOBS($where: Jobs_bool_exp!, $limit: Int!) {
    Jobs(where: $where, limit: $limit, order_by: [{ status_precedence: asc }, { title: asc }]) {
      id
      title
      publishable_title
      status
      updated_at
      location
      created_at
      city
      state
      zip
      country
      application_due_date
      description_publishable
      required_skills_publishable
      desired_skills_publishable
      published_date
      security_clearance
      security_clearance_publishable
      additional_details_publishable
      category
      type
      city
      state
      zip
      country
      company_id
      questionnaire_id
      include_questionnaire_on_application
      Questionnaire {
        id
        title
        model
      }
      job_availability_id
      JobAvailability {
        id
        title
      }
    }
  }
`;

export const GET_PUBLISHED_JOBS_COUNT = gql`
  query GET_PUBLISHED_JOBS_COUNT($where: Jobs_bool_exp!) {
    Jobs_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const LISTEN_FOR_PARSED_RESUMES = gql`
  subscription LISTEN_FOR_PARSED_RESUMES($resumeId: String!) {
    ResumeIngestStatus(where: { object_key: { _eq: $resumeId } }) {
      object_key
      status_code
    }
  }
`;
