import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApolloModule } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { ApplicationFormComponent } from './components/application-form/application-form.component';
import { EeoComponent } from './components/eeo/eeo.component';
import { ErrorComponent } from './components/error/error.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { JobCardComponent } from './components/job-card/job-card.component';
import { JobDetailsPreviewComponent } from './components/job-details-preview/job-details-preview.component';
import { JobDetailsComponent } from './components/job-details/job-details.component';
import { JobsFilterComponent } from './components/jobs-filter/jobs-filter.component';
import { JobsPageComponent } from './components/jobs-page/jobs-page.component';
import { ResumeUploadComponent } from './components/resume-upload/resume-upload.component';
import { SelectFooComponent } from './components/select-foo/select-foo.component';
import { GraphQLModule } from './graphql.module';
import { MaterialModule } from './material.module';
import { AppStatusPipe } from './pipes/app-status.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { JobIdPipe } from './pipes/job-id.pipe';
import { ButtonComponent } from './components/button/button.component';
import { setLicenseKey } from 'survey-angular';
import { SurveyModule } from 'survey-angular-ui';

@NgModule({
  declarations: [
    AppComponent,
    JobsPageComponent,
    SelectFooComponent,
    JobDetailsComponent,
    AccordionComponent,
    ApplicationFormComponent,
    ResumeUploadComponent,
    JobCardComponent,
    JobsFilterComponent,
    EeoComponent,
    ErrorComponent,
    JobDetailsPreviewComponent,
    DateAgoPipe,
    FormInputComponent,
    InputErrorComponent,
    AppStatusPipe,
    JobIdPipe,
    ButtonComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ApolloModule,
    GraphQLModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    FormsModule,
    SurveyModule,
  ],
  providers: [CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    setLicenseKey(
      'NTA0Y2YxNTQtYzI0NS00Y2Q4LTkxNzktNmY0MmQ2NjY1ZTcyOzE9MjAyNC0xMi0wNCwyPTIwMjQtMTItMDQsND0yMDI0LTEyLTA0',
    );
  }
}
