export function getSubdomainFromUrl(): string {
  const isDev =
    window.location.host.startsWith('localhost:') || window.location.hostname.includes('.dev.');

  let subdomain = isDev ? 'develop' : window.location.host.split('.')[0];
  if (subdomain.endsWith('-jobs')) {
    subdomain = subdomain.slice(0, -5);
  }
  if (subdomain.endsWith('-careers')) {
    subdomain = subdomain.slice(0, -8);
  }

  return subdomain;
}
