<button
  matRipple
  mat-raised-button
  [matTooltip]="matTooltip"
  type="button"
  *ngIf="text"
  [disabled]="hostDisabled || loading"
  style="display: flex; align-items: center"
  [ngClass]="{
    'ao-light-button': type === 'light',
    'ao-cta-button': type === 'cta',
    'ao-cta-outline-button': type === 'cta-outline',
    'ao-outline-button': type === 'outline',
    'ao-danger-button': type === 'danger',
    'ao-danger-clear-button': type === 'danger-clear',
    'ao-clear-button': type === 'clear',
    'ao-clear-primary-button': type === 'clear-primary',
    'ao-clear-cta-button': type === 'cta-clear',
    'w-100': fillWidth
  }"
>
  <span *ngIf="text" style="white-space: nowrap">{{ text }}</span>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
</button>

<button
  matRipple
  mat-icon-button
  type="button"
  [matTooltip]="matTooltip"
  *ngIf="!text && icon"
  [disabled]="hostDisabled || loading"
  style="display: flex; align-items: center"
  [ngClass]="{
    'ao-light-button': type === 'light',
    'ao-cta-button': type === 'cta',
    'ao-cta-outline-button': type === 'cta-outline',
    'ao-outline-button': type === 'outline',
    'ao-danger-button': type === 'danger',
    'ao-danger-clear-button': type === 'danger-clear',
    'ao-clear-button': type === 'clear',
    'ao-clear-primary-button': type === 'clear-primary',
    'ao-clear-cta-button': type === 'cta-clear',
    'w-100': fillWidth
  }"
>
  <span *ngIf="text" style="white-space: nowrap">{{ text }}</span>
  @if (icon) {
    <mat-icon>{{ icon }}</mat-icon>
  }
</button>
