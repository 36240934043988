import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { JobsService } from './services/jobs.service';

@Component({
  selector: 'agile-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentYear: number = new Date().getFullYear();
  onDetailsPage = false;
  private _subs: Subscription = new Subscription();

  constructor(
    public deviceDetectorService: DeviceDetectorService,
    public jobsService: JobsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this._subs.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.jobsService.isEmbedded = event.url.includes('embed');
          this.onDetailsPage = event.url.includes('details');
        }),
    );
  }

  ngOnDestroy() {
    this._subs?.unsubscribe();
  }
}
