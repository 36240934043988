import { Component, Input } from '@angular/core';

@Component({
  selector: 'agile-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() expanded = false;
  @Input() completed = false;
  @Input() showStatus = false;
  @Input() hideToggle = false;
}
