import { ApolloLink } from '@apollo/client/core';
import { getSubdomainFromUrl } from './get-subdomain-from-url';

async function fetchGraphQL(hasuraEp: string, query: string, operationName, variables) {
  const result = await fetch(hasuraEp, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables,
      operationName,
    }),
  });

  return result.json();
}

const companyIdQuery = `
    query GET_COMPANY_ID_BY_SUBDOMAIN($subdomain: String!) {
      Companies(where: {subdomain: {_eq: $subdomain}}) {
        id
        description
        name
        logo
        slug
        sponsoring_applicants
      }
    }
  `;

export const fetchGET_COMPANY_ID_BY_SUBDOMAIN = (hasuraEp: string, subdomain: string) => {
  return fetchGraphQL(hasuraEp, companyIdQuery, 'GET_COMPANY_ID_BY_SUBDOMAIN', {
    subdomain,
  });
};

let companyId;

export const doInit = async () => {
  let hasuraEndpoint = localStorage.getItem('hasura_http_ep');
  let subdomain = localStorage.getItem('subdomain');

  if (!hasuraEndpoint || !subdomain) {
    const isDev =
      window.location.host.startsWith('localhost:') || window.location.hostname.includes('.dev.');

    subdomain = getSubdomainFromUrl();

    hasuraEndpoint = isDev
      ? `https://api-develop.dev.agileonboard.com/v1/graphql`
      : `https://api-${subdomain}.services.agileonboarding.com/v1/graphql`;

    localStorage.setItem('hasura_http_ep', hasuraEndpoint);
    localStorage.setItem('subdomain', subdomain);
  }

  try {
    console.log(hasuraEndpoint, subdomain);
    companyId = (await fetchGET_COMPANY_ID_BY_SUBDOMAIN(hasuraEndpoint, subdomain))?.data?.Companies[0]
      ?.id;
    return companyId;
  } catch (e) {
    console.error(
      'Error resolving subdomain to company_id, things will likely not work as expected until this is resolved.',
      e,
      subdomain,
    );
  }
  if (!companyId && window.location.host.startsWith('localhost:')) {
    companyId = 1;
  }
};

export const companyIdLimitingLink = new ApolloLink((op, forward) => {
  const operation = op;

  if (operation.variables['where']) {
    operation.variables['where'].company_id = {
      _eq: parseInt(localStorage.getItem('companyId'), 10),
    };
  }

  if (operation.operationName === 'GET_JOBS') {
    operation.variables['company_id'] = parseInt(localStorage.getItem('companyId'), 10);
  }

  if (operation.variables['company_id'] === null) {
    operation.variables['company_id'] = parseInt(localStorage.getItem('companyId'), 10);
  }

  return forward(operation);
});
