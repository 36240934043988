import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core'; // prettier-ignore
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'agile-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
})
export class FormInputComponent implements ControlValueAccessor, OnChanges {
  @Input() stacked = false;
  @Input() prefixIcon: string;
  @Input() setUpperCase: false;
  @Input() maxLength: any;
  @Input() text: string;
  @Input() color: ThemePalette;
  @Input() forText: string;
  @Input() labelName: string;
  @Input() isDropdown = false;
  @Input() inputType = 'text';
  @Input() readOnly = false;
  @Input() placeholder = '';
  @Input() identityId: number;
  @Input() identityFullName: string;
  @Input() img: string;
  @Input() textClass = '';
  @Input() floatLabel = 'auto';
  @Input() displayCondition = true;
  @Input() errorCondition: boolean;
  @Input() invalidCondition = false;
  @Input() requiredCondition = false;
  @Input() userType: 'Account_Manager' | 'Resource_Manager' | 'Program_Lead';
  @Input() showUserImage = false;
  @Input() required = false;
  @Input() disabled = false;
  @Output() keyupFn = new EventEmitter();
  @Output() change = new EventEmitter();
  @Input() roIsDisabled = true;
  value: string;

  // Deprecated
  @Input() colClass = 'col-7';
  // Deprecated
  @Input() labelClass = 'col-5';
  // Deprecated
  @Input() fullAccess = true;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {}

  onInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.change.emit();
    this.onChange(target.value);
  }

  // ==========================================================================
  // Functions neccesary for formControl to work
  // ==========================================================================

  /** Placeholder until registerOnChange reassigns it another callback func */
  onChange = (value) => value;

  onTouched = () => {};

  writeValue(value: string) {
    this.value = value;
    this.onChange(this.value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
