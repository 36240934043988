import { Component, HostBinding, Input } from '@angular/core';

type AoButtonSize = 'sm' | 'md' | 'lg';
type AoButtonType =
  | 'cta'
  | 'cta-outline'
  | 'cta-clear'
  | 'outline'
  | 'danger'
  | 'danger-clear'
  | 'clear'
  | 'light'
  | 'clear-primary';

@Component({
  selector: 'ao-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type: AoButtonType = 'clear';
  @Input() size: AoButtonSize = 'md';
  @Input() text: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() background = true;
  @Input() fillWidth = false;
  @Input() icon: string;
  @Input() matTooltip: string;

  @HostBinding('disabled')
  get hostDisabled(): boolean {
    return this.disabled;
  }
}
