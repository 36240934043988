import { gql } from '@apollo/client/core';

export const GET_SIGNED_URL = gql`
  mutation GET_SIGNED_URL($getSignedUrlInput: GetSignedUrlInput!) {
    getSignedUrl(getSignedUrlInput: $getSignedUrlInput) {
      signedUrl
    }
  }
`;

export const CREATE_JOB_APPLICATION = gql`
  mutation CREATE_JOB_APPLICATION($applicant: JobApplicationInput!) {
    createJobApplication(applicant: $applicant) {
      message
    }
  }
`;
