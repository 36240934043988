import { gql } from '@apollo/client/core';

export const GET_JOBS_BY_IDS = gql`
  query GET_JOBS_BY_IDS($jobIds: [Int!]!, $company_id: Int!) {
    Jobs(where: { id: { _in: $jobIds }, deleted: { _eq: false }, company_id: { _eq: $company_id } }) {
      id
      title
      publishable_title
      status
      location
      created_at
      application_due_date
      description_publishable
      required_skills_publishable
      desired_skills_publishable
      published_date
      security_clearance
      security_clearance_publishable
      additional_details_publishable
      category
      type
      company_id
      questionnaire_id
      include_questionnaire_on_application
      Questionnaire {
        id
        title
        model
      }
      job_availability_id
      JobAvailability {
        id
        title
      }
    }
  }
`;

export const GET_COMPANY_ID_BY_SUBDOMAIN = gql`
  query GET_COMPANY_ID_BY_SUBDOMAIN($subdomain: String!) {
    Companies(where: { subdomain: { _eq: $subdomain } }) {
      id
      description
      name
      slug
      logo
      sponsoring_applicants
      authorized_applicants
      public_metadata
      indeed_opt_in
    }
  }
`;

export const GET_RESUME_BUCKET_KEY_BY_DATA_HASH = gql`
  query GET_RESUME_BUCKET_KEY_BY_DATA_HASH($data_hash: String!) {
    Resumes(where: { data_hash: { _eq: $data_hash } }) {
      s3bucketkey
    }
  }
`;

export const GET_JOB_FILTER_DATA = gql`
  query GET_JOB_FILTER_DATA($where: Jobs_bool_exp!) {
    Jobs(where: $where) {
      id
      status
      location
      security_clearance
      security_clearance_publishable
      category
      type
      job_availability_id
      JobAvailability {
        id
        title
      }
    }
  }
`;

export const GET_ENUMS = gql`
  query GET_ENUMS {
    VeteranStatusEnum {
      key
      value
    }
    RaceEnum {
      key
      value
    }
    DisabilityStatusEnum {
      key
      value
    }
    GenderEnum {
      key
      value
    }
  }
`;

export const GET_SECURITY_CLEARANCES_EXTERNAL_QUERY = gql`
  query GET_SECURITY_CLEARANCES_EXTERNAL {
    SecurityClearances {
      id
      title
      rank
      description
    }
  }
`;
