import { parsePhoneNumber } from 'awesome-phonenumber';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validatePhoneNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const phoneNumber = parsePhoneNumber(value, { regionCode: 'US' });
    return phoneNumber.valid ? null : { invalidPhoneNumber: true };
  };
}
