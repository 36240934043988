export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  bigint: any;
  bit: any;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  interval: any;
  json: any;
  jsonb: any;
  money: any;
  name: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  tsvector: any;
  uuid: any;
};

/** columns and relationships of "APITokens" */
export type ApiTokens = {
  __typename?: 'APITokens';
  /** An object relationship */
  Identity: Identities;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  expire_days: Scalars['timestamptz'];
  identity_id: Scalars['Int'];
  key: Scalars['uuid'];
  secret?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "APITokens" */
export type ApiTokens_Aggregate = {
  __typename?: 'APITokens_aggregate';
  aggregate?: Maybe<ApiTokens_Aggregate_Fields>;
  nodes: Array<ApiTokens>;
};

export type ApiTokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<ApiTokens_Aggregate_Bool_Exp_Count>;
};

export type ApiTokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ApiTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ApiTokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "APITokens" */
export type ApiTokens_Aggregate_Fields = {
  __typename?: 'APITokens_aggregate_fields';
  avg?: Maybe<ApiTokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ApiTokens_Max_Fields>;
  min?: Maybe<ApiTokens_Min_Fields>;
  stddev?: Maybe<ApiTokens_Stddev_Fields>;
  stddev_pop?: Maybe<ApiTokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ApiTokens_Stddev_Samp_Fields>;
  sum?: Maybe<ApiTokens_Sum_Fields>;
  var_pop?: Maybe<ApiTokens_Var_Pop_Fields>;
  var_samp?: Maybe<ApiTokens_Var_Samp_Fields>;
  variance?: Maybe<ApiTokens_Variance_Fields>;
};


/** aggregate fields of "APITokens" */
export type ApiTokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ApiTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "APITokens" */
export type ApiTokens_Aggregate_Order_By = {
  avg?: InputMaybe<ApiTokens_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ApiTokens_Max_Order_By>;
  min?: InputMaybe<ApiTokens_Min_Order_By>;
  stddev?: InputMaybe<ApiTokens_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ApiTokens_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ApiTokens_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ApiTokens_Sum_Order_By>;
  var_pop?: InputMaybe<ApiTokens_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ApiTokens_Var_Samp_Order_By>;
  variance?: InputMaybe<ApiTokens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "APITokens" */
export type ApiTokens_Arr_Rel_Insert_Input = {
  data: Array<ApiTokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ApiTokens_On_Conflict>;
};

/** aggregate avg on columns */
export type ApiTokens_Avg_Fields = {
  __typename?: 'APITokens_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "APITokens" */
export type ApiTokens_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "APITokens". All fields are combined with a logical 'AND'. */
export type ApiTokens_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<ApiTokens_Bool_Exp>>;
  _not?: InputMaybe<ApiTokens_Bool_Exp>;
  _or?: InputMaybe<Array<ApiTokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  expire_days?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<Uuid_Comparison_Exp>;
  secret?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "APITokens" */
export enum ApiTokens_Constraint {
  /** unique or primary key constraint on columns "key" */
  ApiTokensPkey = 'APITokens_pkey'
}

/** input type for incrementing numeric columns in table "APITokens" */
export type ApiTokens_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "APITokens" */
export type ApiTokens_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  expire_days?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['uuid']>;
  secret?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ApiTokens_Max_Fields = {
  __typename?: 'APITokens_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  expire_days?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['uuid']>;
  secret?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "APITokens" */
export type ApiTokens_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  expire_days?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  secret?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ApiTokens_Min_Fields = {
  __typename?: 'APITokens_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  expire_days?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['uuid']>;
  secret?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "APITokens" */
export type ApiTokens_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  expire_days?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  secret?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "APITokens" */
export type ApiTokens_Mutation_Response = {
  __typename?: 'APITokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ApiTokens>;
};

/** on_conflict condition type for table "APITokens" */
export type ApiTokens_On_Conflict = {
  constraint: ApiTokens_Constraint;
  update_columns?: Array<ApiTokens_Update_Column>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};

/** Ordering options when selecting data from "APITokens". */
export type ApiTokens_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  expire_days?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  secret?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: APITokens */
export type ApiTokens_Pk_Columns_Input = {
  key: Scalars['uuid'];
};

/** select columns of table "APITokens" */
export enum ApiTokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ExpireDays = 'expire_days',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Key = 'key',
  /** column name */
  Secret = 'secret',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "APITokens" */
export type ApiTokens_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  expire_days?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['uuid']>;
  secret?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ApiTokens_Stddev_Fields = {
  __typename?: 'APITokens_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "APITokens" */
export type ApiTokens_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ApiTokens_Stddev_Pop_Fields = {
  __typename?: 'APITokens_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "APITokens" */
export type ApiTokens_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ApiTokens_Stddev_Samp_Fields = {
  __typename?: 'APITokens_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "APITokens" */
export type ApiTokens_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "APITokens" */
export type ApiTokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ApiTokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ApiTokens_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  expire_days?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['uuid']>;
  secret?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ApiTokens_Sum_Fields = {
  __typename?: 'APITokens_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "APITokens" */
export type ApiTokens_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "APITokens" */
export enum ApiTokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ExpireDays = 'expire_days',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Key = 'key',
  /** column name */
  Secret = 'secret',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ApiTokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ApiTokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApiTokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: ApiTokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ApiTokens_Var_Pop_Fields = {
  __typename?: 'APITokens_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "APITokens" */
export type ApiTokens_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ApiTokens_Var_Samp_Fields = {
  __typename?: 'APITokens_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "APITokens" */
export type ApiTokens_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ApiTokens_Variance_Fields = {
  __typename?: 'APITokens_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "APITokens" */
export type ApiTokens_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "AccessIntents" */
export type AccessIntents = {
  __typename?: 'AccessIntents';
  description: Scalars['String'];
  intent: Scalars['String'];
};

/** aggregated selection of "AccessIntents" */
export type AccessIntents_Aggregate = {
  __typename?: 'AccessIntents_aggregate';
  aggregate?: Maybe<AccessIntents_Aggregate_Fields>;
  nodes: Array<AccessIntents>;
};

/** aggregate fields of "AccessIntents" */
export type AccessIntents_Aggregate_Fields = {
  __typename?: 'AccessIntents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccessIntents_Max_Fields>;
  min?: Maybe<AccessIntents_Min_Fields>;
};


/** aggregate fields of "AccessIntents" */
export type AccessIntents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AccessIntents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AccessIntents". All fields are combined with a logical 'AND'. */
export type AccessIntents_Bool_Exp = {
  _and?: InputMaybe<Array<AccessIntents_Bool_Exp>>;
  _not?: InputMaybe<AccessIntents_Bool_Exp>;
  _or?: InputMaybe<Array<AccessIntents_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  intent?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AccessIntents" */
export enum AccessIntents_Constraint {
  /** unique or primary key constraint on columns "intent" */
  AccessIntentsPkey = 'AccessIntents_pkey'
}

export enum AccessIntents_Enum {
  /** Grant, revoke, or modify an entitlement */
  Access = 'ACCESS',
  /** Insert a new row */
  Create = 'CREATE',
  /** Delete an existing row */
  Delete = 'DELETE',
  /** Provides full access to the associated category */
  FullAccess = 'FULL_ACCESS',
  /** Hides the associated category from view */
  Hidden = 'HIDDEN',
  /** Read */
  Read = 'READ',
  /** Provides read only access to the associated category */
  ReadOnly = 'READ_ONLY',
  /** Update an existing row */
  Update = 'UPDATE'
}

/** Boolean expression to compare columns of type "AccessIntents_enum". All fields are combined with logical 'AND'. */
export type AccessIntents_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AccessIntents_Enum>;
  _in?: InputMaybe<Array<AccessIntents_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccessIntents_Enum>;
  _nin?: InputMaybe<Array<AccessIntents_Enum>>;
};

/** input type for inserting data into table "AccessIntents" */
export type AccessIntents_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  intent?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccessIntents_Max_Fields = {
  __typename?: 'AccessIntents_max_fields';
  description?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccessIntents_Min_Fields = {
  __typename?: 'AccessIntents_min_fields';
  description?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AccessIntents" */
export type AccessIntents_Mutation_Response = {
  __typename?: 'AccessIntents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessIntents>;
};

/** input type for inserting object relation for remote table "AccessIntents" */
export type AccessIntents_Obj_Rel_Insert_Input = {
  data: AccessIntents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AccessIntents_On_Conflict>;
};

/** on_conflict condition type for table "AccessIntents" */
export type AccessIntents_On_Conflict = {
  constraint: AccessIntents_Constraint;
  update_columns?: Array<AccessIntents_Update_Column>;
  where?: InputMaybe<AccessIntents_Bool_Exp>;
};

/** Ordering options when selecting data from "AccessIntents". */
export type AccessIntents_Order_By = {
  description?: InputMaybe<Order_By>;
  intent?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AccessIntents */
export type AccessIntents_Pk_Columns_Input = {
  intent: Scalars['String'];
};

/** select columns of table "AccessIntents" */
export enum AccessIntents_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Intent = 'intent'
}

/** input type for updating data in table "AccessIntents" */
export type AccessIntents_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  intent?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AccessIntents" */
export type AccessIntents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AccessIntents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessIntents_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  intent?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AccessIntents" */
export enum AccessIntents_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Intent = 'intent'
}

export type AccessIntents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessIntents_Set_Input>;
  /** filter the rows which have to be updated */
  where: AccessIntents_Bool_Exp;
};

/** columns and relationships of "AccessTypeEnum" */
export type AccessTypeEnum = {
  __typename?: 'AccessTypeEnum';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "AccessTypeEnum" */
export type AccessTypeEnum_Aggregate = {
  __typename?: 'AccessTypeEnum_aggregate';
  aggregate?: Maybe<AccessTypeEnum_Aggregate_Fields>;
  nodes: Array<AccessTypeEnum>;
};

/** aggregate fields of "AccessTypeEnum" */
export type AccessTypeEnum_Aggregate_Fields = {
  __typename?: 'AccessTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccessTypeEnum_Max_Fields>;
  min?: Maybe<AccessTypeEnum_Min_Fields>;
};


/** aggregate fields of "AccessTypeEnum" */
export type AccessTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AccessTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AccessTypeEnum". All fields are combined with a logical 'AND'. */
export type AccessTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<AccessTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<AccessTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<AccessTypeEnum_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AccessTypeEnum" */
export enum AccessTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccessTypeEnumPkey = 'AccessTypeEnum_pkey'
}

export enum AccessTypeEnum_Enum {
  /** Can Edit */
  CanEdit = 'can_edit',
  /** Can View */
  CanView = 'can_view',
  /** Hidden */
  Hidden = 'hidden'
}

/** Boolean expression to compare columns of type "AccessTypeEnum_enum". All fields are combined with logical 'AND'. */
export type AccessTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AccessTypeEnum_Enum>;
  _in?: InputMaybe<Array<AccessTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccessTypeEnum_Enum>;
  _nin?: InputMaybe<Array<AccessTypeEnum_Enum>>;
};

/** input type for inserting data into table "AccessTypeEnum" */
export type AccessTypeEnum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccessTypeEnum_Max_Fields = {
  __typename?: 'AccessTypeEnum_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccessTypeEnum_Min_Fields = {
  __typename?: 'AccessTypeEnum_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AccessTypeEnum" */
export type AccessTypeEnum_Mutation_Response = {
  __typename?: 'AccessTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessTypeEnum>;
};

/** on_conflict condition type for table "AccessTypeEnum" */
export type AccessTypeEnum_On_Conflict = {
  constraint: AccessTypeEnum_Constraint;
  update_columns?: Array<AccessTypeEnum_Update_Column>;
  where?: InputMaybe<AccessTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "AccessTypeEnum". */
export type AccessTypeEnum_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AccessTypeEnum */
export type AccessTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "AccessTypeEnum" */
export enum AccessTypeEnum_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "AccessTypeEnum" */
export type AccessTypeEnum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AccessTypeEnum" */
export type AccessTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AccessTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessTypeEnum_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AccessTypeEnum" */
export enum AccessTypeEnum_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type AccessTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: AccessTypeEnum_Bool_Exp;
};

export type AccountsApiInsertIdentity = {
  name_first: Scalars['String'];
  name_last: Scalars['String'];
  work_email: Scalars['String'];
};

export type AccountsApiOutput = {
  __typename?: 'AccountsApiOutput';
  message?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ActiveEntitlements" */
export type ActiveEntitlements = {
  __typename?: 'ActiveEntitlements';
  attribute?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  ends_on?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  intent?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "ActiveEntitlements" */
export type ActiveEntitlements_Aggregate = {
  __typename?: 'ActiveEntitlements_aggregate';
  aggregate?: Maybe<ActiveEntitlements_Aggregate_Fields>;
  nodes: Array<ActiveEntitlements>;
};

/** aggregate fields of "ActiveEntitlements" */
export type ActiveEntitlements_Aggregate_Fields = {
  __typename?: 'ActiveEntitlements_aggregate_fields';
  avg?: Maybe<ActiveEntitlements_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ActiveEntitlements_Max_Fields>;
  min?: Maybe<ActiveEntitlements_Min_Fields>;
  stddev?: Maybe<ActiveEntitlements_Stddev_Fields>;
  stddev_pop?: Maybe<ActiveEntitlements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ActiveEntitlements_Stddev_Samp_Fields>;
  sum?: Maybe<ActiveEntitlements_Sum_Fields>;
  var_pop?: Maybe<ActiveEntitlements_Var_Pop_Fields>;
  var_samp?: Maybe<ActiveEntitlements_Var_Samp_Fields>;
  variance?: Maybe<ActiveEntitlements_Variance_Fields>;
};


/** aggregate fields of "ActiveEntitlements" */
export type ActiveEntitlements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActiveEntitlements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ActiveEntitlements_Avg_Fields = {
  __typename?: 'ActiveEntitlements_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ActiveEntitlements". All fields are combined with a logical 'AND'. */
export type ActiveEntitlements_Bool_Exp = {
  _and?: InputMaybe<Array<ActiveEntitlements_Bool_Exp>>;
  _not?: InputMaybe<ActiveEntitlements_Bool_Exp>;
  _or?: InputMaybe<Array<ActiveEntitlements_Bool_Exp>>;
  attribute?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ends_on?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  intent?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  row_id?: InputMaybe<Int_Comparison_Exp>;
  starts_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "ActiveEntitlements" */
export type ActiveEntitlements_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ActiveEntitlements" */
export type ActiveEntitlements_Insert_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  ends_on?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  intent?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ActiveEntitlements_Max_Fields = {
  __typename?: 'ActiveEntitlements_max_fields';
  attribute?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  ends_on?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  intent?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ActiveEntitlements_Min_Fields = {
  __typename?: 'ActiveEntitlements_min_fields';
  attribute?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  ends_on?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  intent?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ActiveEntitlements" */
export type ActiveEntitlements_Mutation_Response = {
  __typename?: 'ActiveEntitlements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActiveEntitlements>;
};

/** Ordering options when selecting data from "ActiveEntitlements". */
export type ActiveEntitlements_Order_By = {
  attribute?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ends_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  intent?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  starts_on?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** select columns of table "ActiveEntitlements" */
export enum ActiveEntitlements_Select_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  EndsOn = 'ends_on',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Intent = 'intent',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  RowId = 'row_id',
  /** column name */
  StartsOn = 'starts_on',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ActiveEntitlements" */
export type ActiveEntitlements_Set_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  ends_on?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  intent?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ActiveEntitlements_Stddev_Fields = {
  __typename?: 'ActiveEntitlements_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ActiveEntitlements_Stddev_Pop_Fields = {
  __typename?: 'ActiveEntitlements_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ActiveEntitlements_Stddev_Samp_Fields = {
  __typename?: 'ActiveEntitlements_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ActiveEntitlements" */
export type ActiveEntitlements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActiveEntitlements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActiveEntitlements_Stream_Cursor_Value_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  ends_on?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  intent?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ActiveEntitlements_Sum_Fields = {
  __typename?: 'ActiveEntitlements_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

export type ActiveEntitlements_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActiveEntitlements_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActiveEntitlements_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActiveEntitlements_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ActiveEntitlements_Var_Pop_Fields = {
  __typename?: 'ActiveEntitlements_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ActiveEntitlements_Var_Samp_Fields = {
  __typename?: 'ActiveEntitlements_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ActiveEntitlements_Variance_Fields = {
  __typename?: 'ActiveEntitlements_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ActivityLogTypes" */
export type ActivityLogTypes = {
  __typename?: 'ActivityLogTypes';
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "ActivityLogTypes" */
export type ActivityLogTypesCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "ActivityLogTypes" */
export type ActivityLogTypesComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** aggregated selection of "ActivityLogTypes" */
export type ActivityLogTypes_Aggregate = {
  __typename?: 'ActivityLogTypes_aggregate';
  aggregate?: Maybe<ActivityLogTypes_Aggregate_Fields>;
  nodes: Array<ActivityLogTypes>;
};

/** aggregate fields of "ActivityLogTypes" */
export type ActivityLogTypes_Aggregate_Fields = {
  __typename?: 'ActivityLogTypes_aggregate_fields';
  avg?: Maybe<ActivityLogTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ActivityLogTypes_Max_Fields>;
  min?: Maybe<ActivityLogTypes_Min_Fields>;
  stddev?: Maybe<ActivityLogTypes_Stddev_Fields>;
  stddev_pop?: Maybe<ActivityLogTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ActivityLogTypes_Stddev_Samp_Fields>;
  sum?: Maybe<ActivityLogTypes_Sum_Fields>;
  var_pop?: Maybe<ActivityLogTypes_Var_Pop_Fields>;
  var_samp?: Maybe<ActivityLogTypes_Var_Samp_Fields>;
  variance?: Maybe<ActivityLogTypes_Variance_Fields>;
};


/** aggregate fields of "ActivityLogTypes" */
export type ActivityLogTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityLogTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ActivityLogTypes_Avg_Fields = {
  __typename?: 'ActivityLogTypes_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ActivityLogTypes". All fields are combined with a logical 'AND'. */
export type ActivityLogTypes_Bool_Exp = {
  Comments?: InputMaybe<Comments_Bool_Exp>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<ActivityLogTypes_Bool_Exp>>;
  _not?: InputMaybe<ActivityLogTypes_Bool_Exp>;
  _or?: InputMaybe<Array<ActivityLogTypes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActivityLogTypes" */
export enum ActivityLogTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivityLogTypesPkey = 'ActivityLogTypes_pkey',
  /** unique or primary key constraint on columns "type" */
  ActivityLogTypesTypeKey = 'ActivityLogTypes_type_key'
}

/** input type for incrementing numeric columns in table "ActivityLogTypes" */
export type ActivityLogTypes_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ActivityLogTypes" */
export type ActivityLogTypes_Insert_Input = {
  Comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ActivityLogTypes_Max_Fields = {
  __typename?: 'ActivityLogTypes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ActivityLogTypes_Min_Fields = {
  __typename?: 'ActivityLogTypes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ActivityLogTypes" */
export type ActivityLogTypes_Mutation_Response = {
  __typename?: 'ActivityLogTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityLogTypes>;
};

/** input type for inserting object relation for remote table "ActivityLogTypes" */
export type ActivityLogTypes_Obj_Rel_Insert_Input = {
  data: ActivityLogTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ActivityLogTypes_On_Conflict>;
};

/** on_conflict condition type for table "ActivityLogTypes" */
export type ActivityLogTypes_On_Conflict = {
  constraint: ActivityLogTypes_Constraint;
  update_columns?: Array<ActivityLogTypes_Update_Column>;
  where?: InputMaybe<ActivityLogTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "ActivityLogTypes". */
export type ActivityLogTypes_Order_By = {
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ActivityLogTypes */
export type ActivityLogTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ActivityLogTypes" */
export enum ActivityLogTypes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ActivityLogTypes" */
export type ActivityLogTypes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ActivityLogTypes_Stddev_Fields = {
  __typename?: 'ActivityLogTypes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ActivityLogTypes_Stddev_Pop_Fields = {
  __typename?: 'ActivityLogTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ActivityLogTypes_Stddev_Samp_Fields = {
  __typename?: 'ActivityLogTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ActivityLogTypes" */
export type ActivityLogTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActivityLogTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivityLogTypes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ActivityLogTypes_Sum_Fields = {
  __typename?: 'ActivityLogTypes_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ActivityLogTypes" */
export enum ActivityLogTypes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ActivityLogTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActivityLogTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivityLogTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActivityLogTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ActivityLogTypes_Var_Pop_Fields = {
  __typename?: 'ActivityLogTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ActivityLogTypes_Var_Samp_Fields = {
  __typename?: 'ActivityLogTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ActivityLogTypes_Variance_Fields = {
  __typename?: 'ActivityLogTypes_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Activity Table Templates per User */
export type ActivityTemplates = {
  __typename?: 'ActivityTemplates';
  /** An object relationship */
  Identity: Identities;
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  owner_id: Scalars['Int'];
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ActivityTemplates" */
export type ActivityTemplates_Aggregate = {
  __typename?: 'ActivityTemplates_aggregate';
  aggregate?: Maybe<ActivityTemplates_Aggregate_Fields>;
  nodes: Array<ActivityTemplates>;
};

/** aggregate fields of "ActivityTemplates" */
export type ActivityTemplates_Aggregate_Fields = {
  __typename?: 'ActivityTemplates_aggregate_fields';
  avg?: Maybe<ActivityTemplates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ActivityTemplates_Max_Fields>;
  min?: Maybe<ActivityTemplates_Min_Fields>;
  stddev?: Maybe<ActivityTemplates_Stddev_Fields>;
  stddev_pop?: Maybe<ActivityTemplates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ActivityTemplates_Stddev_Samp_Fields>;
  sum?: Maybe<ActivityTemplates_Sum_Fields>;
  var_pop?: Maybe<ActivityTemplates_Var_Pop_Fields>;
  var_samp?: Maybe<ActivityTemplates_Var_Samp_Fields>;
  variance?: Maybe<ActivityTemplates_Variance_Fields>;
};


/** aggregate fields of "ActivityTemplates" */
export type ActivityTemplates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityTemplates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ActivityTemplates_Avg_Fields = {
  __typename?: 'ActivityTemplates_avg_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ActivityTemplates". All fields are combined with a logical 'AND'. */
export type ActivityTemplates_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<ActivityTemplates_Bool_Exp>>;
  _not?: InputMaybe<ActivityTemplates_Bool_Exp>;
  _or?: InputMaybe<Array<ActivityTemplates_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActivityTemplates" */
export enum ActivityTemplates_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivityTemplatePkey = 'ActivityTemplate_pkey'
}

/** input type for incrementing numeric columns in table "ActivityTemplates" */
export type ActivityTemplates_Inc_Input = {
  owner_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ActivityTemplates" */
export type ActivityTemplates_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ActivityTemplates_Max_Fields = {
  __typename?: 'ActivityTemplates_max_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ActivityTemplates_Min_Fields = {
  __typename?: 'ActivityTemplates_min_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ActivityTemplates" */
export type ActivityTemplates_Mutation_Response = {
  __typename?: 'ActivityTemplates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityTemplates>;
};

/** on_conflict condition type for table "ActivityTemplates" */
export type ActivityTemplates_On_Conflict = {
  constraint: ActivityTemplates_Constraint;
  update_columns?: Array<ActivityTemplates_Update_Column>;
  where?: InputMaybe<ActivityTemplates_Bool_Exp>;
};

/** Ordering options when selecting data from "ActivityTemplates". */
export type ActivityTemplates_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ActivityTemplates */
export type ActivityTemplates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ActivityTemplates" */
export enum ActivityTemplates_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ActivityTemplates" */
export type ActivityTemplates_Set_Input = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ActivityTemplates_Stddev_Fields = {
  __typename?: 'ActivityTemplates_stddev_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ActivityTemplates_Stddev_Pop_Fields = {
  __typename?: 'ActivityTemplates_stddev_pop_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ActivityTemplates_Stddev_Samp_Fields = {
  __typename?: 'ActivityTemplates_stddev_samp_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ActivityTemplates" */
export type ActivityTemplates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActivityTemplates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivityTemplates_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ActivityTemplates_Sum_Fields = {
  __typename?: 'ActivityTemplates_sum_fields';
  owner_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ActivityTemplates" */
export enum ActivityTemplates_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ActivityTemplates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActivityTemplates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivityTemplates_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActivityTemplates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ActivityTemplates_Var_Pop_Fields = {
  __typename?: 'ActivityTemplates_var_pop_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ActivityTemplates_Var_Samp_Fields = {
  __typename?: 'ActivityTemplates_var_samp_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ActivityTemplates_Variance_Fields = {
  __typename?: 'ActivityTemplates_variance_fields';
  owner_id?: Maybe<Scalars['Float']>;
};

export type ActivityTotals = {
  __typename?: 'ActivityTotals';
  Identity?: Maybe<Identities>;
  attempt?: Maybe<Scalars['Int']>;
  author: Scalars['String'];
  author_id: Scalars['Int'];
  email?: Maybe<Scalars['Int']>;
  hr?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['Int']>;
  phone_screen?: Maybe<Scalars['Int']>;
  text_message?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ActivityTotalsOrderBy = {
  attempt?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hr?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  phone_screen?: InputMaybe<Scalars['String']>;
  text_message?: InputMaybe<Scalars['String']>;
  total?: InputMaybe<Scalars['String']>;
};

export type ActivityTotalsSearchCriteria = {
  author_ids?: InputMaybe<Array<Scalars['Int']>>;
  candidate_ids?: InputMaybe<Array<Scalars['Int']>>;
  candidate_source_ids?: InputMaybe<Array<Scalars['Int']>>;
  candidate_status_ids?: InputMaybe<Array<Scalars['Int']>>;
  contract_ids?: InputMaybe<Array<Scalars['Int']>>;
  end_date: Scalars['timestamptz'];
  entered_on_end?: InputMaybe<Scalars['timestamptz']>;
  entered_on_start?: InputMaybe<Scalars['timestamptz']>;
  filter?: InputMaybe<Scalars['String']>;
  first_comment_end?: InputMaybe<Scalars['timestamptz']>;
  first_comment_start?: InputMaybe<Scalars['timestamptz']>;
  job_ids?: InputMaybe<Array<Scalars['Int']>>;
  referred_by?: InputMaybe<Scalars['String']>;
  start_date: Scalars['timestamptz'];
};

/** columns and relationships of "AoIdentifierTypes" */
export type AoIdentifierTypes = {
  __typename?: 'AoIdentifierTypes';
  id: Scalars['uuid'];
  identifier: Scalars['String'];
  module: Scalars['String'];
  route?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "AoIdentifierTypes" */
export type AoIdentifierTypes_Aggregate = {
  __typename?: 'AoIdentifierTypes_aggregate';
  aggregate?: Maybe<AoIdentifierTypes_Aggregate_Fields>;
  nodes: Array<AoIdentifierTypes>;
};

/** aggregate fields of "AoIdentifierTypes" */
export type AoIdentifierTypes_Aggregate_Fields = {
  __typename?: 'AoIdentifierTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AoIdentifierTypes_Max_Fields>;
  min?: Maybe<AoIdentifierTypes_Min_Fields>;
};


/** aggregate fields of "AoIdentifierTypes" */
export type AoIdentifierTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AoIdentifierTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AoIdentifierTypes". All fields are combined with a logical 'AND'. */
export type AoIdentifierTypes_Bool_Exp = {
  _and?: InputMaybe<Array<AoIdentifierTypes_Bool_Exp>>;
  _not?: InputMaybe<AoIdentifierTypes_Bool_Exp>;
  _or?: InputMaybe<Array<AoIdentifierTypes_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  module?: InputMaybe<String_Comparison_Exp>;
  route?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AoIdentifierTypes" */
export enum AoIdentifierTypes_Constraint {
  /** unique or primary key constraint on columns "identifier" */
  AoIdentifierTypesIdentifierKey = 'AoIdentifierTypes_identifier_key',
  /** unique or primary key constraint on columns "module" */
  AoIdentifierTypesModuleKey = 'AoIdentifierTypes_module_key',
  /** unique or primary key constraint on columns "id" */
  AoIdentifierTypesPkey = 'AoIdentifierTypes_pkey'
}

/** input type for inserting data into table "AoIdentifierTypes" */
export type AoIdentifierTypes_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AoIdentifierTypes_Max_Fields = {
  __typename?: 'AoIdentifierTypes_max_fields';
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AoIdentifierTypes_Min_Fields = {
  __typename?: 'AoIdentifierTypes_min_fields';
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AoIdentifierTypes" */
export type AoIdentifierTypes_Mutation_Response = {
  __typename?: 'AoIdentifierTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AoIdentifierTypes>;
};

/** on_conflict condition type for table "AoIdentifierTypes" */
export type AoIdentifierTypes_On_Conflict = {
  constraint: AoIdentifierTypes_Constraint;
  update_columns?: Array<AoIdentifierTypes_Update_Column>;
  where?: InputMaybe<AoIdentifierTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "AoIdentifierTypes". */
export type AoIdentifierTypes_Order_By = {
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  route?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AoIdentifierTypes */
export type AoIdentifierTypes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "AoIdentifierTypes" */
export enum AoIdentifierTypes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Module = 'module',
  /** column name */
  Route = 'route',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "AoIdentifierTypes" */
export type AoIdentifierTypes_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AoIdentifierTypes" */
export type AoIdentifierTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AoIdentifierTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AoIdentifierTypes_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AoIdentifierTypes" */
export enum AoIdentifierTypes_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Module = 'module',
  /** column name */
  Route = 'route',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type AoIdentifierTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AoIdentifierTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: AoIdentifierTypes_Bool_Exp;
};

/** columns and relationships of "ApplicationStatus" */
export type ApplicationStatus = {
  __typename?: 'ApplicationStatus';
  key: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ApplicationStatus" */
export type ApplicationStatus_Aggregate = {
  __typename?: 'ApplicationStatus_aggregate';
  aggregate?: Maybe<ApplicationStatus_Aggregate_Fields>;
  nodes: Array<ApplicationStatus>;
};

/** aggregate fields of "ApplicationStatus" */
export type ApplicationStatus_Aggregate_Fields = {
  __typename?: 'ApplicationStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ApplicationStatus_Max_Fields>;
  min?: Maybe<ApplicationStatus_Min_Fields>;
};


/** aggregate fields of "ApplicationStatus" */
export type ApplicationStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ApplicationStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ApplicationStatus". All fields are combined with a logical 'AND'. */
export type ApplicationStatus_Bool_Exp = {
  _and?: InputMaybe<Array<ApplicationStatus_Bool_Exp>>;
  _not?: InputMaybe<ApplicationStatus_Bool_Exp>;
  _or?: InputMaybe<Array<ApplicationStatus_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ApplicationStatus" */
export enum ApplicationStatus_Constraint {
  /** unique or primary key constraint on columns "key" */
  ApplicationStatusPkey = 'ApplicationStatus_pkey'
}

/** input type for inserting data into table "ApplicationStatus" */
export type ApplicationStatus_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ApplicationStatus_Max_Fields = {
  __typename?: 'ApplicationStatus_max_fields';
  key?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ApplicationStatus_Min_Fields = {
  __typename?: 'ApplicationStatus_min_fields';
  key?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ApplicationStatus" */
export type ApplicationStatus_Mutation_Response = {
  __typename?: 'ApplicationStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ApplicationStatus>;
};

/** input type for inserting object relation for remote table "ApplicationStatus" */
export type ApplicationStatus_Obj_Rel_Insert_Input = {
  data: ApplicationStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ApplicationStatus_On_Conflict>;
};

/** on_conflict condition type for table "ApplicationStatus" */
export type ApplicationStatus_On_Conflict = {
  constraint: ApplicationStatus_Constraint;
  update_columns?: Array<ApplicationStatus_Update_Column>;
  where?: InputMaybe<ApplicationStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "ApplicationStatus". */
export type ApplicationStatus_Order_By = {
  key?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ApplicationStatus */
export type ApplicationStatus_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "ApplicationStatus" */
export enum ApplicationStatus_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ApplicationStatus" */
export type ApplicationStatus_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ApplicationStatus" */
export type ApplicationStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ApplicationStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ApplicationStatus_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ApplicationStatus" */
export enum ApplicationStatus_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

export type ApplicationStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApplicationStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: ApplicationStatus_Bool_Exp;
};

/** Application Types  */
export type ApplicationType = {
  __typename?: 'ApplicationType';
  key: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ApplicationType" */
export type ApplicationType_Aggregate = {
  __typename?: 'ApplicationType_aggregate';
  aggregate?: Maybe<ApplicationType_Aggregate_Fields>;
  nodes: Array<ApplicationType>;
};

/** aggregate fields of "ApplicationType" */
export type ApplicationType_Aggregate_Fields = {
  __typename?: 'ApplicationType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ApplicationType_Max_Fields>;
  min?: Maybe<ApplicationType_Min_Fields>;
};


/** aggregate fields of "ApplicationType" */
export type ApplicationType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ApplicationType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ApplicationType". All fields are combined with a logical 'AND'. */
export type ApplicationType_Bool_Exp = {
  _and?: InputMaybe<Array<ApplicationType_Bool_Exp>>;
  _not?: InputMaybe<ApplicationType_Bool_Exp>;
  _or?: InputMaybe<Array<ApplicationType_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ApplicationType" */
export enum ApplicationType_Constraint {
  /** unique or primary key constraint on columns "key" */
  ApplicationTypePkey = 'ApplicationType_pkey'
}

/** input type for inserting data into table "ApplicationType" */
export type ApplicationType_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ApplicationType_Max_Fields = {
  __typename?: 'ApplicationType_max_fields';
  key?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ApplicationType_Min_Fields = {
  __typename?: 'ApplicationType_min_fields';
  key?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ApplicationType" */
export type ApplicationType_Mutation_Response = {
  __typename?: 'ApplicationType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ApplicationType>;
};

/** input type for inserting object relation for remote table "ApplicationType" */
export type ApplicationType_Obj_Rel_Insert_Input = {
  data: ApplicationType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ApplicationType_On_Conflict>;
};

/** on_conflict condition type for table "ApplicationType" */
export type ApplicationType_On_Conflict = {
  constraint: ApplicationType_Constraint;
  update_columns?: Array<ApplicationType_Update_Column>;
  where?: InputMaybe<ApplicationType_Bool_Exp>;
};

/** Ordering options when selecting data from "ApplicationType". */
export type ApplicationType_Order_By = {
  key?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ApplicationType */
export type ApplicationType_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "ApplicationType" */
export enum ApplicationType_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ApplicationType" */
export type ApplicationType_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ApplicationType" */
export type ApplicationType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ApplicationType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ApplicationType_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ApplicationType" */
export enum ApplicationType_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

export type ApplicationType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApplicationType_Set_Input>;
  /** filter the rows which have to be updated */
  where: ApplicationType_Bool_Exp;
};

/** columns and relationships of "Applications" */
export type Applications = {
  __typename?: 'Applications';
  /** An object relationship */
  ApplicationStatus?: Maybe<ApplicationStatus>;
  /** An object relationship */
  ApplicationType?: Maybe<ApplicationType>;
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Job: Jobs;
  /** An object relationship */
  QuestionnaireResponse?: Maybe<QuestionnaireResponses>;
  /** An object relationship */
  Rejection?: Maybe<Rejections>;
  acknowledgement?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "application_on_board" */
  application_on_board?: Maybe<Scalars['Boolean']>;
  autogenerated: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted: Scalars['Boolean'];
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  /** An object relationship */
  identityByRejectedBy?: Maybe<Identities>;
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  job_position_number: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  questionnaire_response_id?: Maybe<Scalars['uuid']>;
  rejected_by?: Maybe<Scalars['Int']>;
  rejection_date?: Maybe<Scalars['timestamp']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Applications" */
export type ApplicationsBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Applications" */
export type ApplicationsBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Applications" */
export type ApplicationsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Applications" */
export type ApplicationsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Applications" */
export type Applications_Aggregate = {
  __typename?: 'Applications_aggregate';
  aggregate?: Maybe<Applications_Aggregate_Fields>;
  nodes: Array<Applications>;
};

export type Applications_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Applications_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Applications_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Applications_Aggregate_Bool_Exp_Count>;
};

export type Applications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Applications_Select_Column_Applications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Applications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Applications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Applications_Select_Column_Applications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Applications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Applications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Applications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Applications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Applications" */
export type Applications_Aggregate_Fields = {
  __typename?: 'Applications_aggregate_fields';
  avg?: Maybe<Applications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Applications_Max_Fields>;
  min?: Maybe<Applications_Min_Fields>;
  stddev?: Maybe<Applications_Stddev_Fields>;
  stddev_pop?: Maybe<Applications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Applications_Stddev_Samp_Fields>;
  sum?: Maybe<Applications_Sum_Fields>;
  var_pop?: Maybe<Applications_Var_Pop_Fields>;
  var_samp?: Maybe<Applications_Var_Samp_Fields>;
  variance?: Maybe<Applications_Variance_Fields>;
};


/** aggregate fields of "Applications" */
export type Applications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Applications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Applications" */
export type Applications_Aggregate_Order_By = {
  avg?: InputMaybe<Applications_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Applications_Max_Order_By>;
  min?: InputMaybe<Applications_Min_Order_By>;
  stddev?: InputMaybe<Applications_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Applications_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Applications_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Applications_Sum_Order_By>;
  var_pop?: InputMaybe<Applications_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Applications_Var_Samp_Order_By>;
  variance?: InputMaybe<Applications_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Applications_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Applications" */
export type Applications_Arr_Rel_Insert_Input = {
  data: Array<Applications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Applications_On_Conflict>;
};

/** aggregate avg on columns */
export type Applications_Avg_Fields = {
  __typename?: 'Applications_avg_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  rejected_by?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Applications" */
export type Applications_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Applications". All fields are combined with a logical 'AND'. */
export type Applications_Bool_Exp = {
  ApplicationStatus?: InputMaybe<ApplicationStatus_Bool_Exp>;
  ApplicationType?: InputMaybe<ApplicationType_Bool_Exp>;
  BoardSnippets?: InputMaybe<BoardSnippets_Bool_Exp>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  QuestionnaireResponse?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
  Rejection?: InputMaybe<Rejections_Bool_Exp>;
  _and?: InputMaybe<Array<Applications_Bool_Exp>>;
  _not?: InputMaybe<Applications_Bool_Exp>;
  _or?: InputMaybe<Array<Applications_Bool_Exp>>;
  acknowledgement?: InputMaybe<Boolean_Comparison_Exp>;
  application_on_board?: InputMaybe<Boolean_Comparison_Exp>;
  autogenerated?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityByRejectedBy?: InputMaybe<Identities_Bool_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_position_number?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  questionnaire_response_id?: InputMaybe<Uuid_Comparison_Exp>;
  rejected_by?: InputMaybe<Int_Comparison_Exp>;
  rejection_date?: InputMaybe<Timestamp_Comparison_Exp>;
  rejection_id?: InputMaybe<Int_Comparison_Exp>;
  relevance_last_calced?: InputMaybe<Timestamptz_Comparison_Exp>;
  relevance_score?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Applications" */
export enum Applications_Constraint {
  /** unique or primary key constraint on columns "job_position_number", "job_id" */
  ApplicationsAcceptedPidx = 'Applications_accepted_pidx',
  /** unique or primary key constraint on columns "identity_id", "job_id" */
  ApplicationsIdentityIdJobIdKey = 'Applications_identity_id_job_id_key',
  /** unique or primary key constraint on columns "id" */
  ApplicationsPkey = 'Applications_pkey',
  /** unique or primary key constraint on columns "etl_id_token" */
  ApplicationsUniqueEtlIdToken = 'applications_unique_etl_id_token'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Applications_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Applications_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Applications_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Applications" */
export type Applications_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_position_number?: InputMaybe<Scalars['Int']>;
  rejected_by?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Applications" */
export type Applications_Insert_Input = {
  ApplicationStatus?: InputMaybe<ApplicationStatus_Obj_Rel_Insert_Input>;
  ApplicationType?: InputMaybe<ApplicationType_Obj_Rel_Insert_Input>;
  BoardSnippets?: InputMaybe<BoardSnippets_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  QuestionnaireResponse?: InputMaybe<QuestionnaireResponses_Obj_Rel_Insert_Input>;
  Rejection?: InputMaybe<Rejections_Obj_Rel_Insert_Input>;
  acknowledgement?: InputMaybe<Scalars['Boolean']>;
  autogenerated?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identityByRejectedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_position_number?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  questionnaire_response_id?: InputMaybe<Scalars['uuid']>;
  rejected_by?: InputMaybe<Scalars['Int']>;
  rejection_date?: InputMaybe<Scalars['timestamp']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Applications_Max_Fields = {
  __typename?: 'Applications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  questionnaire_response_id?: Maybe<Scalars['uuid']>;
  rejected_by?: Maybe<Scalars['Int']>;
  rejection_date?: Maybe<Scalars['timestamp']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Applications" */
export type Applications_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  questionnaire_response_id?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_date?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Applications_Min_Fields = {
  __typename?: 'Applications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  questionnaire_response_id?: Maybe<Scalars['uuid']>;
  rejected_by?: Maybe<Scalars['Int']>;
  rejection_date?: Maybe<Scalars['timestamp']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Applications" */
export type Applications_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  questionnaire_response_id?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_date?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Applications" */
export type Applications_Mutation_Response = {
  __typename?: 'Applications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Applications>;
};

/** input type for inserting object relation for remote table "Applications" */
export type Applications_Obj_Rel_Insert_Input = {
  data: Applications_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Applications_On_Conflict>;
};

/** on_conflict condition type for table "Applications" */
export type Applications_On_Conflict = {
  constraint: Applications_Constraint;
  update_columns?: Array<Applications_Update_Column>;
  where?: InputMaybe<Applications_Bool_Exp>;
};

/** Ordering options when selecting data from "Applications". */
export type Applications_Order_By = {
  ApplicationStatus?: InputMaybe<ApplicationStatus_Order_By>;
  ApplicationType?: InputMaybe<ApplicationType_Order_By>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  QuestionnaireResponse?: InputMaybe<QuestionnaireResponses_Order_By>;
  Rejection?: InputMaybe<Rejections_Order_By>;
  acknowledgement?: InputMaybe<Order_By>;
  application_on_board?: InputMaybe<Order_By>;
  autogenerated?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityByRejectedBy?: InputMaybe<Identities_Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  questionnaire_response_id?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_date?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Applications */
export type Applications_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Applications_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Applications" */
export enum Applications_Select_Column {
  /** column name */
  Acknowledgement = 'acknowledgement',
  /** column name */
  Autogenerated = 'autogenerated',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobPositionNumber = 'job_position_number',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  QuestionnaireResponseId = 'questionnaire_response_id',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  RejectionDate = 'rejection_date',
  /** column name */
  RejectionId = 'rejection_id',
  /** column name */
  RelevanceLastCalced = 'relevance_last_calced',
  /** column name */
  RelevanceScore = 'relevance_score',
  /** column name */
  Status = 'status',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "Applications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Applications" */
export enum Applications_Select_Column_Applications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Acknowledgement = 'acknowledgement',
  /** column name */
  Autogenerated = 'autogenerated',
  /** column name */
  Deleted = 'deleted'
}

/** select "Applications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Applications" */
export enum Applications_Select_Column_Applications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Acknowledgement = 'acknowledgement',
  /** column name */
  Autogenerated = 'autogenerated',
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "Applications" */
export type Applications_Set_Input = {
  acknowledgement?: InputMaybe<Scalars['Boolean']>;
  autogenerated?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_position_number?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  questionnaire_response_id?: InputMaybe<Scalars['uuid']>;
  rejected_by?: InputMaybe<Scalars['Int']>;
  rejection_date?: InputMaybe<Scalars['timestamp']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Applications_Stddev_Fields = {
  __typename?: 'Applications_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  rejected_by?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Applications" */
export type Applications_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Applications_Stddev_Pop_Fields = {
  __typename?: 'Applications_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  rejected_by?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Applications" */
export type Applications_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Applications_Stddev_Samp_Fields = {
  __typename?: 'Applications_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  rejected_by?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Applications" */
export type Applications_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Applications" */
export type Applications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Applications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Applications_Stream_Cursor_Value_Input = {
  acknowledgement?: InputMaybe<Scalars['Boolean']>;
  autogenerated?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_position_number?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  questionnaire_response_id?: InputMaybe<Scalars['uuid']>;
  rejected_by?: InputMaybe<Scalars['Int']>;
  rejection_date?: InputMaybe<Scalars['timestamp']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Applications_Sum_Fields = {
  __typename?: 'Applications_sum_fields';
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  rejected_by?: Maybe<Scalars['Int']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Applications" */
export type Applications_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Applications" */
export enum Applications_Update_Column {
  /** column name */
  Acknowledgement = 'acknowledgement',
  /** column name */
  Autogenerated = 'autogenerated',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobPositionNumber = 'job_position_number',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  QuestionnaireResponseId = 'questionnaire_response_id',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  RejectionDate = 'rejection_date',
  /** column name */
  RejectionId = 'rejection_id',
  /** column name */
  RelevanceLastCalced = 'relevance_last_calced',
  /** column name */
  RelevanceScore = 'relevance_score',
  /** column name */
  Status = 'status',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Applications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Applications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Applications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Applications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Applications_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Applications_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Applications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Applications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Applications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Applications_Var_Pop_Fields = {
  __typename?: 'Applications_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  rejected_by?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Applications" */
export type Applications_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Applications_Var_Samp_Fields = {
  __typename?: 'Applications_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  rejected_by?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Applications" */
export type Applications_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Applications_Variance_Fields = {
  __typename?: 'Applications_variance_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  rejected_by?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Applications" */
export type Applications_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "Assignments" */
export type Assignments = {
  __typename?: 'Assignments';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An array relationship */
  IdentityAssignments: Array<IdentityAssignments>;
  /** An aggregate relationship */
  IdentityAssignments_aggregate: IdentityAssignments_Aggregate;
  _search?: Maybe<Scalars['tsvector']>;
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  identityByCandidateId?: Maybe<Identities>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "Assignments" */
export type AssignmentsIdentityAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


/** columns and relationships of "Assignments" */
export type AssignmentsIdentityAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};

/** aggregated selection of "Assignments" */
export type Assignments_Aggregate = {
  __typename?: 'Assignments_aggregate';
  aggregate?: Maybe<Assignments_Aggregate_Fields>;
  nodes: Array<Assignments>;
};

export type Assignments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Assignments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Assignments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Assignments_Aggregate_Bool_Exp_Count>;
};

export type Assignments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Assignments_Select_Column_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assignments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Assignments_Select_Column_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Assignments" */
export type Assignments_Aggregate_Fields = {
  __typename?: 'Assignments_aggregate_fields';
  avg?: Maybe<Assignments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assignments_Max_Fields>;
  min?: Maybe<Assignments_Min_Fields>;
  stddev?: Maybe<Assignments_Stddev_Fields>;
  stddev_pop?: Maybe<Assignments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assignments_Stddev_Samp_Fields>;
  sum?: Maybe<Assignments_Sum_Fields>;
  var_pop?: Maybe<Assignments_Var_Pop_Fields>;
  var_samp?: Maybe<Assignments_Var_Samp_Fields>;
  variance?: Maybe<Assignments_Variance_Fields>;
};


/** aggregate fields of "Assignments" */
export type Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Assignments" */
export type Assignments_Aggregate_Order_By = {
  avg?: InputMaybe<Assignments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assignments_Max_Order_By>;
  min?: InputMaybe<Assignments_Min_Order_By>;
  stddev?: InputMaybe<Assignments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assignments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assignments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assignments_Sum_Order_By>;
  var_pop?: InputMaybe<Assignments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assignments_Var_Samp_Order_By>;
  variance?: InputMaybe<Assignments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Assignments" */
export type Assignments_Arr_Rel_Insert_Input = {
  data: Array<Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};

/** aggregate avg on columns */
export type Assignments_Avg_Fields = {
  __typename?: 'Assignments_avg_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Assignments" */
export type Assignments_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Assignments". All fields are combined with a logical 'AND'. */
export type Assignments_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  IdentityAssignments?: InputMaybe<IdentityAssignments_Bool_Exp>;
  IdentityAssignments_aggregate?: InputMaybe<IdentityAssignments_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Assignments_Bool_Exp>>;
  _not?: InputMaybe<Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Assignments_Bool_Exp>>;
  _search?: InputMaybe<Tsvector_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityByCandidateId?: InputMaybe<Identities_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  priority?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Assignments" */
export enum Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssignmentsPkey = 'Assignments_pkey'
}

/** input type for incrementing numeric columns in table "Assignments" */
export type Assignments_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Assignments" */
export type Assignments_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  IdentityAssignments?: InputMaybe<IdentityAssignments_Arr_Rel_Insert_Input>;
  _search?: InputMaybe<Scalars['tsvector']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityByCandidateId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Assignments_Max_Fields = {
  __typename?: 'Assignments_max_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Assignments" */
export type Assignments_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assignments_Min_Fields = {
  __typename?: 'Assignments_min_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Assignments" */
export type Assignments_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Assignments" */
export type Assignments_Mutation_Response = {
  __typename?: 'Assignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignments>;
};

/** input type for inserting object relation for remote table "Assignments" */
export type Assignments_Obj_Rel_Insert_Input = {
  data: Assignments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};

/** on_conflict condition type for table "Assignments" */
export type Assignments_On_Conflict = {
  constraint: Assignments_Constraint;
  update_columns?: Array<Assignments_Update_Column>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "Assignments". */
export type Assignments_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  IdentityAssignments_aggregate?: InputMaybe<IdentityAssignments_Aggregate_Order_By>;
  _search?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  completed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityByCandidateId?: InputMaybe<Identities_Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Assignments */
export type Assignments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Assignments" */
export enum Assignments_Select_Column {
  /** column name */
  Search = '_search',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Assignments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Assignments" */
export enum Assignments_Select_Column_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Completed = 'completed',
  /** column name */
  Deleted = 'deleted'
}

/** select "Assignments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Assignments" */
export enum Assignments_Select_Column_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Completed = 'completed',
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "Assignments" */
export type Assignments_Set_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Assignments_Stddev_Fields = {
  __typename?: 'Assignments_stddev_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Assignments" */
export type Assignments_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assignments_Stddev_Pop_Fields = {
  __typename?: 'Assignments_stddev_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Assignments" */
export type Assignments_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assignments_Stddev_Samp_Fields = {
  __typename?: 'Assignments_stddev_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Assignments" */
export type Assignments_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Assignments" */
export type Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assignments_Stream_Cursor_Value_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Assignments_Sum_Fields = {
  __typename?: 'Assignments_sum_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Assignments" */
export type Assignments_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** update columns of table "Assignments" */
export enum Assignments_Update_Column {
  /** column name */
  Search = '_search',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Assignments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Assignments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assignments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assignments_Var_Pop_Fields = {
  __typename?: 'Assignments_var_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Assignments" */
export type Assignments_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assignments_Var_Samp_Fields = {
  __typename?: 'Assignments_var_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Assignments" */
export type Assignments_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Assignments_Variance_Fields = {
  __typename?: 'Assignments_variance_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Assignments" */
export type Assignments_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

export type AsyncOperationInput = {
  op_code: Scalars['String'];
  serializedJSONInput?: InputMaybe<Scalars['String']>;
};

export type AsyncOperationOutput = {
  __typename?: 'AsyncOperationOutput';
  id: Scalars['String'];
};

/** columns and relationships of "AsyncOperations" */
export type AsyncOperations = {
  __typename?: 'AsyncOperations';
  /** An object relationship */
  AsyncOperationsState: AsyncOperationsStates;
  /** An object relationship */
  Owner?: Maybe<Identities>;
  company_id: Scalars['Int'];
  completion_pct?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  current_ticks?: Maybe<Scalars['bigint']>;
  id: Scalars['uuid'];
  input?: Maybe<Scalars['jsonb']>;
  is_public: Scalars['Boolean'];
  log_info?: Maybe<Scalars['jsonb']>;
  message?: Maybe<Scalars['String']>;
  op_code?: Maybe<Scalars['String']>;
  op_name: Scalars['String'];
  output?: Maybe<Scalars['jsonb']>;
  owner_id?: Maybe<Scalars['Int']>;
  parent_operation_id?: Maybe<Scalars['uuid']>;
  state: AsyncOperationsStates_Enum;
  system_state_info?: Maybe<Scalars['jsonb']>;
  total_ticks?: Maybe<Scalars['bigint']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "AsyncOperations" */
export type AsyncOperationsInputArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "AsyncOperations" */
export type AsyncOperationsLog_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "AsyncOperations" */
export type AsyncOperationsOutputArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "AsyncOperations" */
export type AsyncOperationsSystem_State_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "AsyncOperationsStates" */
export type AsyncOperationsStates = {
  __typename?: 'AsyncOperationsStates';
  description: Scalars['String'];
  state: Scalars['String'];
};

/** aggregated selection of "AsyncOperationsStates" */
export type AsyncOperationsStates_Aggregate = {
  __typename?: 'AsyncOperationsStates_aggregate';
  aggregate?: Maybe<AsyncOperationsStates_Aggregate_Fields>;
  nodes: Array<AsyncOperationsStates>;
};

/** aggregate fields of "AsyncOperationsStates" */
export type AsyncOperationsStates_Aggregate_Fields = {
  __typename?: 'AsyncOperationsStates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AsyncOperationsStates_Max_Fields>;
  min?: Maybe<AsyncOperationsStates_Min_Fields>;
};


/** aggregate fields of "AsyncOperationsStates" */
export type AsyncOperationsStates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AsyncOperationsStates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AsyncOperationsStates". All fields are combined with a logical 'AND'. */
export type AsyncOperationsStates_Bool_Exp = {
  _and?: InputMaybe<Array<AsyncOperationsStates_Bool_Exp>>;
  _not?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
  _or?: InputMaybe<Array<AsyncOperationsStates_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AsyncOperationsStates" */
export enum AsyncOperationsStates_Constraint {
  /** unique or primary key constraint on columns "state" */
  AsyncOperationsStatesPkey = 'AsyncOperationsStates_pkey'
}

export enum AsyncOperationsStates_Enum {
  /** Operation completed with an error */
  FinishedError = 'FINISHED_ERROR',
  /** Operation completed successfully */
  FinishedSuccess = 'FINISHED_SUCCESS',
  /** Operation is in progress */
  InProgress = 'IN_PROGRESS',
  /** Operation has been created but has not yet begun */
  NotStarted = 'NOT_STARTED'
}

/** Boolean expression to compare columns of type "AsyncOperationsStates_enum". All fields are combined with logical 'AND'. */
export type AsyncOperationsStates_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AsyncOperationsStates_Enum>;
  _in?: InputMaybe<Array<AsyncOperationsStates_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AsyncOperationsStates_Enum>;
  _nin?: InputMaybe<Array<AsyncOperationsStates_Enum>>;
};

/** input type for inserting data into table "AsyncOperationsStates" */
export type AsyncOperationsStates_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AsyncOperationsStates_Max_Fields = {
  __typename?: 'AsyncOperationsStates_max_fields';
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AsyncOperationsStates_Min_Fields = {
  __typename?: 'AsyncOperationsStates_min_fields';
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AsyncOperationsStates" */
export type AsyncOperationsStates_Mutation_Response = {
  __typename?: 'AsyncOperationsStates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AsyncOperationsStates>;
};

/** input type for inserting object relation for remote table "AsyncOperationsStates" */
export type AsyncOperationsStates_Obj_Rel_Insert_Input = {
  data: AsyncOperationsStates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AsyncOperationsStates_On_Conflict>;
};

/** on_conflict condition type for table "AsyncOperationsStates" */
export type AsyncOperationsStates_On_Conflict = {
  constraint: AsyncOperationsStates_Constraint;
  update_columns?: Array<AsyncOperationsStates_Update_Column>;
  where?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
};

/** Ordering options when selecting data from "AsyncOperationsStates". */
export type AsyncOperationsStates_Order_By = {
  description?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AsyncOperationsStates */
export type AsyncOperationsStates_Pk_Columns_Input = {
  state: Scalars['String'];
};

/** select columns of table "AsyncOperationsStates" */
export enum AsyncOperationsStates_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "AsyncOperationsStates" */
export type AsyncOperationsStates_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AsyncOperationsStates" */
export type AsyncOperationsStates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AsyncOperationsStates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AsyncOperationsStates_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AsyncOperationsStates" */
export enum AsyncOperationsStates_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state'
}

export type AsyncOperationsStates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AsyncOperationsStates_Set_Input>;
  /** filter the rows which have to be updated */
  where: AsyncOperationsStates_Bool_Exp;
};

/** aggregated selection of "AsyncOperations" */
export type AsyncOperations_Aggregate = {
  __typename?: 'AsyncOperations_aggregate';
  aggregate?: Maybe<AsyncOperations_Aggregate_Fields>;
  nodes: Array<AsyncOperations>;
};

/** aggregate fields of "AsyncOperations" */
export type AsyncOperations_Aggregate_Fields = {
  __typename?: 'AsyncOperations_aggregate_fields';
  avg?: Maybe<AsyncOperations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AsyncOperations_Max_Fields>;
  min?: Maybe<AsyncOperations_Min_Fields>;
  stddev?: Maybe<AsyncOperations_Stddev_Fields>;
  stddev_pop?: Maybe<AsyncOperations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AsyncOperations_Stddev_Samp_Fields>;
  sum?: Maybe<AsyncOperations_Sum_Fields>;
  var_pop?: Maybe<AsyncOperations_Var_Pop_Fields>;
  var_samp?: Maybe<AsyncOperations_Var_Samp_Fields>;
  variance?: Maybe<AsyncOperations_Variance_Fields>;
};


/** aggregate fields of "AsyncOperations" */
export type AsyncOperations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AsyncOperations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AsyncOperations_Append_Input = {
  input?: InputMaybe<Scalars['jsonb']>;
  log_info?: InputMaybe<Scalars['jsonb']>;
  output?: InputMaybe<Scalars['jsonb']>;
  system_state_info?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type AsyncOperations_Avg_Fields = {
  __typename?: 'AsyncOperations_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  completion_pct?: Maybe<Scalars['Float']>;
  current_ticks?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  total_ticks?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AsyncOperations". All fields are combined with a logical 'AND'. */
export type AsyncOperations_Bool_Exp = {
  AsyncOperationsState?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
  Owner?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<AsyncOperations_Bool_Exp>>;
  _not?: InputMaybe<AsyncOperations_Bool_Exp>;
  _or?: InputMaybe<Array<AsyncOperations_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  completion_pct?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_ticks?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  input?: InputMaybe<Jsonb_Comparison_Exp>;
  is_public?: InputMaybe<Boolean_Comparison_Exp>;
  log_info?: InputMaybe<Jsonb_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  op_code?: InputMaybe<String_Comparison_Exp>;
  op_name?: InputMaybe<String_Comparison_Exp>;
  output?: InputMaybe<Jsonb_Comparison_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  parent_operation_id?: InputMaybe<Uuid_Comparison_Exp>;
  state?: InputMaybe<AsyncOperationsStates_Enum_Comparison_Exp>;
  system_state_info?: InputMaybe<Jsonb_Comparison_Exp>;
  total_ticks?: InputMaybe<Bigint_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "AsyncOperations" */
export enum AsyncOperations_Constraint {
  /** unique or primary key constraint on columns "id" */
  AsyncOperationsPkey = 'AsyncOperations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AsyncOperations_Delete_At_Path_Input = {
  input?: InputMaybe<Array<Scalars['String']>>;
  log_info?: InputMaybe<Array<Scalars['String']>>;
  output?: InputMaybe<Array<Scalars['String']>>;
  system_state_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AsyncOperations_Delete_Elem_Input = {
  input?: InputMaybe<Scalars['Int']>;
  log_info?: InputMaybe<Scalars['Int']>;
  output?: InputMaybe<Scalars['Int']>;
  system_state_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AsyncOperations_Delete_Key_Input = {
  input?: InputMaybe<Scalars['String']>;
  log_info?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['String']>;
  system_state_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "AsyncOperations" */
export type AsyncOperations_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  completion_pct?: InputMaybe<Scalars['numeric']>;
  current_ticks?: InputMaybe<Scalars['bigint']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  total_ticks?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "AsyncOperations" */
export type AsyncOperations_Insert_Input = {
  AsyncOperationsState?: InputMaybe<AsyncOperationsStates_Obj_Rel_Insert_Input>;
  Owner?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  completion_pct?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_ticks?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  is_public?: InputMaybe<Scalars['Boolean']>;
  log_info?: InputMaybe<Scalars['jsonb']>;
  message?: InputMaybe<Scalars['String']>;
  op_code?: InputMaybe<Scalars['String']>;
  op_name?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  parent_operation_id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<AsyncOperationsStates_Enum>;
  system_state_info?: InputMaybe<Scalars['jsonb']>;
  total_ticks?: InputMaybe<Scalars['bigint']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AsyncOperations_Max_Fields = {
  __typename?: 'AsyncOperations_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  completion_pct?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_ticks?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  op_code?: Maybe<Scalars['String']>;
  op_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  parent_operation_id?: Maybe<Scalars['uuid']>;
  total_ticks?: Maybe<Scalars['bigint']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type AsyncOperations_Min_Fields = {
  __typename?: 'AsyncOperations_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  completion_pct?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_ticks?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  op_code?: Maybe<Scalars['String']>;
  op_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  parent_operation_id?: Maybe<Scalars['uuid']>;
  total_ticks?: Maybe<Scalars['bigint']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "AsyncOperations" */
export type AsyncOperations_Mutation_Response = {
  __typename?: 'AsyncOperations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AsyncOperations>;
};

/** on_conflict condition type for table "AsyncOperations" */
export type AsyncOperations_On_Conflict = {
  constraint: AsyncOperations_Constraint;
  update_columns?: Array<AsyncOperations_Update_Column>;
  where?: InputMaybe<AsyncOperations_Bool_Exp>;
};

/** Ordering options when selecting data from "AsyncOperations". */
export type AsyncOperations_Order_By = {
  AsyncOperationsState?: InputMaybe<AsyncOperationsStates_Order_By>;
  Owner?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  completion_pct?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_ticks?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  input?: InputMaybe<Order_By>;
  is_public?: InputMaybe<Order_By>;
  log_info?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  op_code?: InputMaybe<Order_By>;
  op_name?: InputMaybe<Order_By>;
  output?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  system_state_info?: InputMaybe<Order_By>;
  total_ticks?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AsyncOperations */
export type AsyncOperations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AsyncOperations_Prepend_Input = {
  input?: InputMaybe<Scalars['jsonb']>;
  log_info?: InputMaybe<Scalars['jsonb']>;
  output?: InputMaybe<Scalars['jsonb']>;
  system_state_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "AsyncOperations" */
export enum AsyncOperations_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompletionPct = 'completion_pct',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentTicks = 'current_ticks',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LogInfo = 'log_info',
  /** column name */
  Message = 'message',
  /** column name */
  OpCode = 'op_code',
  /** column name */
  OpName = 'op_name',
  /** column name */
  Output = 'output',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ParentOperationId = 'parent_operation_id',
  /** column name */
  State = 'state',
  /** column name */
  SystemStateInfo = 'system_state_info',
  /** column name */
  TotalTicks = 'total_ticks',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "AsyncOperations" */
export type AsyncOperations_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  completion_pct?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_ticks?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  is_public?: InputMaybe<Scalars['Boolean']>;
  log_info?: InputMaybe<Scalars['jsonb']>;
  message?: InputMaybe<Scalars['String']>;
  op_code?: InputMaybe<Scalars['String']>;
  op_name?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  parent_operation_id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<AsyncOperationsStates_Enum>;
  system_state_info?: InputMaybe<Scalars['jsonb']>;
  total_ticks?: InputMaybe<Scalars['bigint']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type AsyncOperations_Stddev_Fields = {
  __typename?: 'AsyncOperations_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  completion_pct?: Maybe<Scalars['Float']>;
  current_ticks?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  total_ticks?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AsyncOperations_Stddev_Pop_Fields = {
  __typename?: 'AsyncOperations_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  completion_pct?: Maybe<Scalars['Float']>;
  current_ticks?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  total_ticks?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AsyncOperations_Stddev_Samp_Fields = {
  __typename?: 'AsyncOperations_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  completion_pct?: Maybe<Scalars['Float']>;
  current_ticks?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  total_ticks?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "AsyncOperations" */
export type AsyncOperations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AsyncOperations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AsyncOperations_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  completion_pct?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_ticks?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  is_public?: InputMaybe<Scalars['Boolean']>;
  log_info?: InputMaybe<Scalars['jsonb']>;
  message?: InputMaybe<Scalars['String']>;
  op_code?: InputMaybe<Scalars['String']>;
  op_name?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  parent_operation_id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<AsyncOperationsStates_Enum>;
  system_state_info?: InputMaybe<Scalars['jsonb']>;
  total_ticks?: InputMaybe<Scalars['bigint']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type AsyncOperations_Sum_Fields = {
  __typename?: 'AsyncOperations_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  completion_pct?: Maybe<Scalars['numeric']>;
  current_ticks?: Maybe<Scalars['bigint']>;
  owner_id?: Maybe<Scalars['Int']>;
  total_ticks?: Maybe<Scalars['bigint']>;
};

/** update columns of table "AsyncOperations" */
export enum AsyncOperations_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompletionPct = 'completion_pct',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentTicks = 'current_ticks',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LogInfo = 'log_info',
  /** column name */
  Message = 'message',
  /** column name */
  OpCode = 'op_code',
  /** column name */
  OpName = 'op_name',
  /** column name */
  Output = 'output',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ParentOperationId = 'parent_operation_id',
  /** column name */
  State = 'state',
  /** column name */
  SystemStateInfo = 'system_state_info',
  /** column name */
  TotalTicks = 'total_ticks',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AsyncOperations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AsyncOperations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AsyncOperations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AsyncOperations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AsyncOperations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AsyncOperations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AsyncOperations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AsyncOperations_Set_Input>;
  /** filter the rows which have to be updated */
  where: AsyncOperations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AsyncOperations_Var_Pop_Fields = {
  __typename?: 'AsyncOperations_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  completion_pct?: Maybe<Scalars['Float']>;
  current_ticks?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  total_ticks?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AsyncOperations_Var_Samp_Fields = {
  __typename?: 'AsyncOperations_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  completion_pct?: Maybe<Scalars['Float']>;
  current_ticks?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  total_ticks?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AsyncOperations_Variance_Fields = {
  __typename?: 'AsyncOperations_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  completion_pct?: Maybe<Scalars['Float']>;
  current_ticks?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  total_ticks?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Attachments" */
export type Attachments = {
  __typename?: 'Attachments';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Email?: Maybe<Emails>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  email_id?: Maybe<Scalars['Int']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  temporary: Scalars['Boolean'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Attachments" */
export type Attachments_Aggregate = {
  __typename?: 'Attachments_aggregate';
  aggregate?: Maybe<Attachments_Aggregate_Fields>;
  nodes: Array<Attachments>;
};

export type Attachments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Attachments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Attachments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Attachments_Aggregate_Bool_Exp_Count>;
};

export type Attachments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Attachments_Select_Column_Attachments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attachments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Attachments_Select_Column_Attachments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attachments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Attachments" */
export type Attachments_Aggregate_Fields = {
  __typename?: 'Attachments_aggregate_fields';
  avg?: Maybe<Attachments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachments_Max_Fields>;
  min?: Maybe<Attachments_Min_Fields>;
  stddev?: Maybe<Attachments_Stddev_Fields>;
  stddev_pop?: Maybe<Attachments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachments_Stddev_Samp_Fields>;
  sum?: Maybe<Attachments_Sum_Fields>;
  var_pop?: Maybe<Attachments_Var_Pop_Fields>;
  var_samp?: Maybe<Attachments_Var_Samp_Fields>;
  variance?: Maybe<Attachments_Variance_Fields>;
};


/** aggregate fields of "Attachments" */
export type Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Attachments" */
export type Attachments_Aggregate_Order_By = {
  avg?: InputMaybe<Attachments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attachments_Max_Order_By>;
  min?: InputMaybe<Attachments_Min_Order_By>;
  stddev?: InputMaybe<Attachments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Attachments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Attachments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Attachments_Sum_Order_By>;
  var_pop?: InputMaybe<Attachments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Attachments_Var_Samp_Order_By>;
  variance?: InputMaybe<Attachments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Attachments" */
export type Attachments_Arr_Rel_Insert_Input = {
  data: Array<Attachments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachments_On_Conflict>;
};

/** aggregate avg on columns */
export type Attachments_Avg_Fields = {
  __typename?: 'Attachments_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Attachments" */
export type Attachments_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Attachments". All fields are combined with a logical 'AND'. */
export type Attachments_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Email?: InputMaybe<Emails_Bool_Exp>;
  _and?: InputMaybe<Array<Attachments_Bool_Exp>>;
  _not?: InputMaybe<Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Attachments_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<String_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  temporary?: InputMaybe<Boolean_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Attachments" */
export enum Attachments_Constraint {
  /** unique or primary key constraint on columns "filename", "company_id", "etl_id_token" */
  AttachmentsEtlIdTokenCompanyIdFilenameKey = 'Attachments_etl_id_token_company_id_filename_key',
  /** unique or primary key constraint on columns "id" */
  AttachmentsPkey = 'Attachments_pkey'
}

/** input type for incrementing numeric columns in table "Attachments" */
export type Attachments_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Attachments" */
export type Attachments_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Email?: InputMaybe<Emails_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  temporary?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Attachments_Max_Fields = {
  __typename?: 'Attachments_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Attachments" */
export type Attachments_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Attachments_Min_Fields = {
  __typename?: 'Attachments_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Attachments" */
export type Attachments_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Attachments" */
export type Attachments_Mutation_Response = {
  __typename?: 'Attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachments>;
};

/** on_conflict condition type for table "Attachments" */
export type Attachments_On_Conflict = {
  constraint: Attachments_Constraint;
  update_columns?: Array<Attachments_Update_Column>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "Attachments". */
export type Attachments_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Email?: InputMaybe<Emails_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  temporary?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Attachments */
export type Attachments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Attachments" */
export enum Attachments_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Size = 'size',
  /** column name */
  Temporary = 'temporary',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Attachments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Attachments" */
export enum Attachments_Select_Column_Attachments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Temporary = 'temporary'
}

/** select "Attachments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Attachments" */
export enum Attachments_Select_Column_Attachments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Temporary = 'temporary'
}

/** input type for updating data in table "Attachments" */
export type Attachments_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  temporary?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Attachments_Stddev_Fields = {
  __typename?: 'Attachments_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Attachments" */
export type Attachments_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Attachments_Stddev_Pop_Fields = {
  __typename?: 'Attachments_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Attachments" */
export type Attachments_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Attachments_Stddev_Samp_Fields = {
  __typename?: 'Attachments_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Attachments" */
export type Attachments_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Attachments" */
export type Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachments_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  temporary?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Attachments_Sum_Fields = {
  __typename?: 'Attachments_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Attachments" */
export type Attachments_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** update columns of table "Attachments" */
export enum Attachments_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Size = 'size',
  /** column name */
  Temporary = 'temporary',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Attachments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachments_Var_Pop_Fields = {
  __typename?: 'Attachments_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Attachments" */
export type Attachments_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Attachments_Var_Samp_Fields = {
  __typename?: 'Attachments_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Attachments" */
export type Attachments_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Attachments_Variance_Fields = {
  __typename?: 'Attachments_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Attachments" */
export type Attachments_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** columns and relationships of "AuditCreates" */
export type AuditCreates = {
  __typename?: 'AuditCreates';
  /** An object relationship */
  Author?: Maybe<Identities>;
  /** An object relationship */
  Company?: Maybe<Companies>;
  action?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "AuditCreates" */
export type AuditCreates_Aggregate = {
  __typename?: 'AuditCreates_aggregate';
  aggregate?: Maybe<AuditCreates_Aggregate_Fields>;
  nodes: Array<AuditCreates>;
};

export type AuditCreates_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuditCreates_Aggregate_Bool_Exp_Count>;
};

export type AuditCreates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuditCreates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuditCreates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "AuditCreates" */
export type AuditCreates_Aggregate_Fields = {
  __typename?: 'AuditCreates_aggregate_fields';
  avg?: Maybe<AuditCreates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AuditCreates_Max_Fields>;
  min?: Maybe<AuditCreates_Min_Fields>;
  stddev?: Maybe<AuditCreates_Stddev_Fields>;
  stddev_pop?: Maybe<AuditCreates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuditCreates_Stddev_Samp_Fields>;
  sum?: Maybe<AuditCreates_Sum_Fields>;
  var_pop?: Maybe<AuditCreates_Var_Pop_Fields>;
  var_samp?: Maybe<AuditCreates_Var_Samp_Fields>;
  variance?: Maybe<AuditCreates_Variance_Fields>;
};


/** aggregate fields of "AuditCreates" */
export type AuditCreates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuditCreates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AuditCreates" */
export type AuditCreates_Aggregate_Order_By = {
  avg?: InputMaybe<AuditCreates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuditCreates_Max_Order_By>;
  min?: InputMaybe<AuditCreates_Min_Order_By>;
  stddev?: InputMaybe<AuditCreates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AuditCreates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AuditCreates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AuditCreates_Sum_Order_By>;
  var_pop?: InputMaybe<AuditCreates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AuditCreates_Var_Samp_Order_By>;
  variance?: InputMaybe<AuditCreates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "AuditCreates" */
export type AuditCreates_Arr_Rel_Insert_Input = {
  data: Array<AuditCreates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuditCreates_On_Conflict>;
};

/** aggregate avg on columns */
export type AuditCreates_Avg_Fields = {
  __typename?: 'AuditCreates_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "AuditCreates" */
export type AuditCreates_Avg_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "AuditCreates". All fields are combined with a logical 'AND'. */
export type AuditCreates_Bool_Exp = {
  Author?: InputMaybe<Identities_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  _and?: InputMaybe<Array<AuditCreates_Bool_Exp>>;
  _not?: InputMaybe<AuditCreates_Bool_Exp>;
  _or?: InputMaybe<Array<AuditCreates_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "AuditCreates" */
export enum AuditCreates_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuditCreatesPkey = 'AuditCreates_pkey'
}

/** input type for incrementing numeric columns in table "AuditCreates" */
export type AuditCreates_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "AuditCreates" */
export type AuditCreates_Insert_Input = {
  Author?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  action?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AuditCreates_Max_Fields = {
  __typename?: 'AuditCreates_max_fields';
  action?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "AuditCreates" */
export type AuditCreates_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuditCreates_Min_Fields = {
  __typename?: 'AuditCreates_min_fields';
  action?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "AuditCreates" */
export type AuditCreates_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "AuditCreates" */
export type AuditCreates_Mutation_Response = {
  __typename?: 'AuditCreates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuditCreates>;
};

/** on_conflict condition type for table "AuditCreates" */
export type AuditCreates_On_Conflict = {
  constraint: AuditCreates_Constraint;
  update_columns?: Array<AuditCreates_Update_Column>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};

/** Ordering options when selecting data from "AuditCreates". */
export type AuditCreates_Order_By = {
  Author?: InputMaybe<Identities_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  action?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AuditCreates */
export type AuditCreates_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "AuditCreates" */
export enum AuditCreates_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "AuditCreates" */
export type AuditCreates_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type AuditCreates_Stddev_Fields = {
  __typename?: 'AuditCreates_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "AuditCreates" */
export type AuditCreates_Stddev_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AuditCreates_Stddev_Pop_Fields = {
  __typename?: 'AuditCreates_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "AuditCreates" */
export type AuditCreates_Stddev_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AuditCreates_Stddev_Samp_Fields = {
  __typename?: 'AuditCreates_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "AuditCreates" */
export type AuditCreates_Stddev_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "AuditCreates" */
export type AuditCreates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuditCreates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuditCreates_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type AuditCreates_Sum_Fields = {
  __typename?: 'AuditCreates_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "AuditCreates" */
export type AuditCreates_Sum_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "AuditCreates" */
export enum AuditCreates_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AuditCreates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuditCreates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuditCreates_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuditCreates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AuditCreates_Var_Pop_Fields = {
  __typename?: 'AuditCreates_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "AuditCreates" */
export type AuditCreates_Var_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AuditCreates_Var_Samp_Fields = {
  __typename?: 'AuditCreates_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "AuditCreates" */
export type AuditCreates_Var_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AuditCreates_Variance_Fields = {
  __typename?: 'AuditCreates_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "AuditCreates" */
export type AuditCreates_Variance_Order_By = {
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "AuditStages" */
export type AuditStages = {
  __typename?: 'AuditStages';
  /** An object relationship */
  AuthorDeleted?: Maybe<Deleted_Ppl_Names>;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An object relationship */
  RegressionReason?: Maybe<WorkflowRegressions>;
  /** An object relationship */
  Stage?: Maybe<Stages>;
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  from_stage_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  identityByAmId?: Maybe<Identities>;
  /** An object relationship */
  identityByAuthorId?: Maybe<Identities>;
  /** An object relationship */
  identityByIdentityId?: Maybe<Identities>;
  /** An object relationship */
  identityByRmId?: Maybe<Identities>;
  identity_id?: Maybe<Scalars['Int']>;
  is_reentrant_instance: Scalars['Boolean'];
  is_transition_to_off_board: Scalars['Boolean'];
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  regression_number: Scalars['Int'];
  regression_reason_id?: Maybe<Scalars['Int']>;
  regression_reason_txt?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  /** An object relationship */
  stageByFromStageId?: Maybe<Stages>;
  stage_exit_reason?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  template_id?: Maybe<Scalars['Int']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  time_in_stage_text?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "AuditStagesWithWorkflowBeginAndStartedDates" */
export type AuditStagesWithWorkflowBeginAndStartedDates = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates';
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  is_transition_to_off_board?: Maybe<Scalars['Boolean']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  regression_number?: Maybe<Scalars['Int']>;
  regression_reason_id?: Maybe<Scalars['Int']>;
  regression_reason_txt?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  stage_exit_reason?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  started_dt?: Maybe<Scalars['timestamptz']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  time_in_stage_text?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workflow_begin_dt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "AuditStagesWithWorkflowBeginAndStartedDates" */
export type AuditStagesWithWorkflowBeginAndStartedDates_Aggregate = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_aggregate';
  aggregate?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Aggregate_Fields>;
  nodes: Array<AuditStagesWithWorkflowBeginAndStartedDates>;
};

/** aggregate fields of "AuditStagesWithWorkflowBeginAndStartedDates" */
export type AuditStagesWithWorkflowBeginAndStartedDates_Aggregate_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_aggregate_fields';
  avg?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Max_Fields>;
  min?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Min_Fields>;
  stddev?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Stddev_Fields>;
  stddev_pop?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Stddev_Samp_Fields>;
  sum?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Sum_Fields>;
  var_pop?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Var_Pop_Fields>;
  var_samp?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Var_Samp_Fields>;
  variance?: Maybe<AuditStagesWithWorkflowBeginAndStartedDates_Variance_Fields>;
};


/** aggregate fields of "AuditStagesWithWorkflowBeginAndStartedDates" */
export type AuditStagesWithWorkflowBeginAndStartedDates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Avg_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_avg_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AuditStagesWithWorkflowBeginAndStartedDates". All fields are combined with a logical 'AND'. */
export type AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp = {
  _and?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>>;
  _not?: InputMaybe<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>;
  _or?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>>;
  am_id?: InputMaybe<Int_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_stage_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  is_transition_to_off_board?: InputMaybe<Boolean_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  last_update_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  regression_number?: InputMaybe<Int_Comparison_Exp>;
  regression_reason_id?: InputMaybe<Int_Comparison_Exp>;
  regression_reason_txt?: InputMaybe<String_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  stage_exit_reason?: InputMaybe<String_Comparison_Exp>;
  stage_exit_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  started_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  time_in_stage_ms?: InputMaybe<Bigint_Comparison_Exp>;
  time_in_stage_text?: InputMaybe<String_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workflow_begin_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Max_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_max_fields';
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  regression_number?: Maybe<Scalars['Int']>;
  regression_reason_id?: Maybe<Scalars['Int']>;
  regression_reason_txt?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  stage_exit_reason?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  started_dt?: Maybe<Scalars['timestamptz']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  time_in_stage_text?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workflow_begin_dt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Min_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_min_fields';
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  regression_number?: Maybe<Scalars['Int']>;
  regression_reason_id?: Maybe<Scalars['Int']>;
  regression_reason_txt?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  stage_exit_reason?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  started_dt?: Maybe<Scalars['timestamptz']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  time_in_stage_text?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workflow_begin_dt?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "AuditStagesWithWorkflowBeginAndStartedDates". */
export type AuditStagesWithWorkflowBeginAndStartedDates_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  is_transition_to_off_board?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_update_ts?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  regression_reason_txt?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  stage_exit_reason?: InputMaybe<Order_By>;
  stage_exit_time?: InputMaybe<Order_By>;
  started_dt?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  time_in_stage_text?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workflow_begin_dt?: InputMaybe<Order_By>;
};

/** select columns of table "AuditStagesWithWorkflowBeginAndStartedDates" */
export enum AuditStagesWithWorkflowBeginAndStartedDates_Select_Column {
  /** column name */
  AmId = 'am_id',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsTransitionToOffBoard = 'is_transition_to_off_board',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastUpdateTs = 'last_update_ts',
  /** column name */
  RegressionNumber = 'regression_number',
  /** column name */
  RegressionReasonId = 'regression_reason_id',
  /** column name */
  RegressionReasonTxt = 'regression_reason_txt',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  StageExitReason = 'stage_exit_reason',
  /** column name */
  StageExitTime = 'stage_exit_time',
  /** column name */
  StartedDt = 'started_dt',
  /** column name */
  TimeInStageMs = 'time_in_stage_ms',
  /** column name */
  TimeInStageText = 'time_in_stage_text',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkflowBeginDt = 'workflow_begin_dt'
}

/** aggregate stddev on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Stddev_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_stddev_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Stddev_Pop_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_stddev_pop_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Stddev_Samp_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_stddev_samp_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "AuditStagesWithWorkflowBeginAndStartedDates" */
export type AuditStagesWithWorkflowBeginAndStartedDates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuditStagesWithWorkflowBeginAndStartedDates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuditStagesWithWorkflowBeginAndStartedDates_Stream_Cursor_Value_Input = {
  am_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_transition_to_off_board?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_update_ts?: InputMaybe<Scalars['timestamptz']>;
  regression_number?: InputMaybe<Scalars['Int']>;
  regression_reason_id?: InputMaybe<Scalars['Int']>;
  regression_reason_txt?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  stage_exit_reason?: InputMaybe<Scalars['String']>;
  stage_exit_time?: InputMaybe<Scalars['timestamptz']>;
  started_dt?: InputMaybe<Scalars['timestamptz']>;
  time_in_stage_ms?: InputMaybe<Scalars['bigint']>;
  time_in_stage_text?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workflow_begin_dt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Sum_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_sum_fields';
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  regression_number?: Maybe<Scalars['Int']>;
  regression_reason_id?: Maybe<Scalars['Int']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Var_Pop_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_var_pop_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Var_Samp_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_var_samp_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AuditStagesWithWorkflowBeginAndStartedDates_Variance_Fields = {
  __typename?: 'AuditStagesWithWorkflowBeginAndStartedDates_variance_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "AuditStages" */
export type AuditStages_Aggregate = {
  __typename?: 'AuditStages_aggregate';
  aggregate?: Maybe<AuditStages_Aggregate_Fields>;
  nodes: Array<AuditStages>;
};

export type AuditStages_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<AuditStages_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<AuditStages_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<AuditStages_Aggregate_Bool_Exp_Count>;
};

export type AuditStages_Aggregate_Bool_Exp_Bool_And = {
  arguments: AuditStages_Select_Column_AuditStages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuditStages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AuditStages_Aggregate_Bool_Exp_Bool_Or = {
  arguments: AuditStages_Select_Column_AuditStages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuditStages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AuditStages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuditStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuditStages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "AuditStages" */
export type AuditStages_Aggregate_Fields = {
  __typename?: 'AuditStages_aggregate_fields';
  avg?: Maybe<AuditStages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AuditStages_Max_Fields>;
  min?: Maybe<AuditStages_Min_Fields>;
  stddev?: Maybe<AuditStages_Stddev_Fields>;
  stddev_pop?: Maybe<AuditStages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuditStages_Stddev_Samp_Fields>;
  sum?: Maybe<AuditStages_Sum_Fields>;
  var_pop?: Maybe<AuditStages_Var_Pop_Fields>;
  var_samp?: Maybe<AuditStages_Var_Samp_Fields>;
  variance?: Maybe<AuditStages_Variance_Fields>;
};


/** aggregate fields of "AuditStages" */
export type AuditStages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuditStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AuditStages" */
export type AuditStages_Aggregate_Order_By = {
  avg?: InputMaybe<AuditStages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuditStages_Max_Order_By>;
  min?: InputMaybe<AuditStages_Min_Order_By>;
  stddev?: InputMaybe<AuditStages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AuditStages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AuditStages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AuditStages_Sum_Order_By>;
  var_pop?: InputMaybe<AuditStages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AuditStages_Var_Samp_Order_By>;
  variance?: InputMaybe<AuditStages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "AuditStages" */
export type AuditStages_Arr_Rel_Insert_Input = {
  data: Array<AuditStages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuditStages_On_Conflict>;
};

/** aggregate avg on columns */
export type AuditStages_Avg_Fields = {
  __typename?: 'AuditStages_avg_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "AuditStages" */
export type AuditStages_Avg_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "AuditStages". All fields are combined with a logical 'AND'. */
export type AuditStages_Bool_Exp = {
  AuthorDeleted?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  RegressionReason?: InputMaybe<WorkflowRegressions_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  _and?: InputMaybe<Array<AuditStages_Bool_Exp>>;
  _not?: InputMaybe<AuditStages_Bool_Exp>;
  _or?: InputMaybe<Array<AuditStages_Bool_Exp>>;
  am_id?: InputMaybe<Int_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_stage_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityByAmId?: InputMaybe<Identities_Bool_Exp>;
  identityByAuthorId?: InputMaybe<Identities_Bool_Exp>;
  identityByIdentityId?: InputMaybe<Identities_Bool_Exp>;
  identityByRmId?: InputMaybe<Identities_Bool_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  is_reentrant_instance?: InputMaybe<Boolean_Comparison_Exp>;
  is_transition_to_off_board?: InputMaybe<Boolean_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  last_update_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  regression_number?: InputMaybe<Int_Comparison_Exp>;
  regression_reason_id?: InputMaybe<Int_Comparison_Exp>;
  regression_reason_txt?: InputMaybe<String_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  stageByFromStageId?: InputMaybe<Stages_Bool_Exp>;
  stage_exit_reason?: InputMaybe<String_Comparison_Exp>;
  stage_exit_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  template_id?: InputMaybe<Int_Comparison_Exp>;
  time_in_stage_ms?: InputMaybe<Bigint_Comparison_Exp>;
  time_in_stage_text?: InputMaybe<String_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "AuditStages" */
export enum AuditStages_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuditStagesPkey = 'AuditStages_pkey',
  /** unique or primary key constraint on columns "identity_id", "to_stage_id", "from_stage_id", "regression_number", "job_id" */
  AuditstagesNodupesIndex = 'auditstages__nodupes_index'
}

/** input type for incrementing numeric columns in table "AuditStages" */
export type AuditStages_Inc_Input = {
  am_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  regression_number?: InputMaybe<Scalars['Int']>;
  regression_reason_id?: InputMaybe<Scalars['Int']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  template_id?: InputMaybe<Scalars['Int']>;
  time_in_stage_ms?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "AuditStages" */
export type AuditStages_Insert_Input = {
  AuthorDeleted?: InputMaybe<Deleted_Ppl_Names_Obj_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  RegressionReason?: InputMaybe<WorkflowRegressions_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  am_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityByAmId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identityByAuthorId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identityByIdentityId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identityByRmId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_reentrant_instance?: InputMaybe<Scalars['Boolean']>;
  is_transition_to_off_board?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_update_ts?: InputMaybe<Scalars['timestamptz']>;
  regression_number?: InputMaybe<Scalars['Int']>;
  regression_reason_id?: InputMaybe<Scalars['Int']>;
  regression_reason_txt?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  stageByFromStageId?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  stage_exit_reason?: InputMaybe<Scalars['String']>;
  stage_exit_time?: InputMaybe<Scalars['timestamptz']>;
  template_id?: InputMaybe<Scalars['Int']>;
  time_in_stage_ms?: InputMaybe<Scalars['bigint']>;
  time_in_stage_text?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AuditStages_Max_Fields = {
  __typename?: 'AuditStages_max_fields';
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  regression_number?: Maybe<Scalars['Int']>;
  regression_reason_id?: Maybe<Scalars['Int']>;
  regression_reason_txt?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  stage_exit_reason?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  template_id?: Maybe<Scalars['Int']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  time_in_stage_text?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "AuditStages" */
export type AuditStages_Max_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_update_ts?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  regression_reason_txt?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  stage_exit_reason?: InputMaybe<Order_By>;
  stage_exit_time?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  time_in_stage_text?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuditStages_Min_Fields = {
  __typename?: 'AuditStages_min_fields';
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  regression_number?: Maybe<Scalars['Int']>;
  regression_reason_id?: Maybe<Scalars['Int']>;
  regression_reason_txt?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  stage_exit_reason?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  template_id?: Maybe<Scalars['Int']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  time_in_stage_text?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "AuditStages" */
export type AuditStages_Min_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_update_ts?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  regression_reason_txt?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  stage_exit_reason?: InputMaybe<Order_By>;
  stage_exit_time?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  time_in_stage_text?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "AuditStages" */
export type AuditStages_Mutation_Response = {
  __typename?: 'AuditStages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuditStages>;
};

/** input type for inserting object relation for remote table "AuditStages" */
export type AuditStages_Obj_Rel_Insert_Input = {
  data: AuditStages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuditStages_On_Conflict>;
};

/** on_conflict condition type for table "AuditStages" */
export type AuditStages_On_Conflict = {
  constraint: AuditStages_Constraint;
  update_columns?: Array<AuditStages_Update_Column>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};

/** Ordering options when selecting data from "AuditStages". */
export type AuditStages_Order_By = {
  AuthorDeleted?: InputMaybe<Deleted_Ppl_Names_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  RegressionReason?: InputMaybe<WorkflowRegressions_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityByAmId?: InputMaybe<Identities_Order_By>;
  identityByAuthorId?: InputMaybe<Identities_Order_By>;
  identityByIdentityId?: InputMaybe<Identities_Order_By>;
  identityByRmId?: InputMaybe<Identities_Order_By>;
  identity_id?: InputMaybe<Order_By>;
  is_reentrant_instance?: InputMaybe<Order_By>;
  is_transition_to_off_board?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_update_ts?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  regression_reason_txt?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  stageByFromStageId?: InputMaybe<Stages_Order_By>;
  stage_exit_reason?: InputMaybe<Order_By>;
  stage_exit_time?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  time_in_stage_text?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AuditStages */
export type AuditStages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "AuditStages" */
export enum AuditStages_Select_Column {
  /** column name */
  AmId = 'am_id',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsReentrantInstance = 'is_reentrant_instance',
  /** column name */
  IsTransitionToOffBoard = 'is_transition_to_off_board',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastUpdateTs = 'last_update_ts',
  /** column name */
  RegressionNumber = 'regression_number',
  /** column name */
  RegressionReasonId = 'regression_reason_id',
  /** column name */
  RegressionReasonTxt = 'regression_reason_txt',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  StageExitReason = 'stage_exit_reason',
  /** column name */
  StageExitTime = 'stage_exit_time',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  TimeInStageMs = 'time_in_stage_ms',
  /** column name */
  TimeInStageText = 'time_in_stage_text',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "AuditStages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "AuditStages" */
export enum AuditStages_Select_Column_AuditStages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsReentrantInstance = 'is_reentrant_instance',
  /** column name */
  IsTransitionToOffBoard = 'is_transition_to_off_board'
}

/** select "AuditStages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "AuditStages" */
export enum AuditStages_Select_Column_AuditStages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsReentrantInstance = 'is_reentrant_instance',
  /** column name */
  IsTransitionToOffBoard = 'is_transition_to_off_board'
}

/** input type for updating data in table "AuditStages" */
export type AuditStages_Set_Input = {
  am_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_reentrant_instance?: InputMaybe<Scalars['Boolean']>;
  is_transition_to_off_board?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_update_ts?: InputMaybe<Scalars['timestamptz']>;
  regression_number?: InputMaybe<Scalars['Int']>;
  regression_reason_id?: InputMaybe<Scalars['Int']>;
  regression_reason_txt?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  stage_exit_reason?: InputMaybe<Scalars['String']>;
  stage_exit_time?: InputMaybe<Scalars['timestamptz']>;
  template_id?: InputMaybe<Scalars['Int']>;
  time_in_stage_ms?: InputMaybe<Scalars['bigint']>;
  time_in_stage_text?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type AuditStages_Stddev_Fields = {
  __typename?: 'AuditStages_stddev_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "AuditStages" */
export type AuditStages_Stddev_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AuditStages_Stddev_Pop_Fields = {
  __typename?: 'AuditStages_stddev_pop_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "AuditStages" */
export type AuditStages_Stddev_Pop_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AuditStages_Stddev_Samp_Fields = {
  __typename?: 'AuditStages_stddev_samp_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "AuditStages" */
export type AuditStages_Stddev_Samp_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "AuditStages" */
export type AuditStages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuditStages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuditStages_Stream_Cursor_Value_Input = {
  am_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_reentrant_instance?: InputMaybe<Scalars['Boolean']>;
  is_transition_to_off_board?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_update_ts?: InputMaybe<Scalars['timestamptz']>;
  regression_number?: InputMaybe<Scalars['Int']>;
  regression_reason_id?: InputMaybe<Scalars['Int']>;
  regression_reason_txt?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  stage_exit_reason?: InputMaybe<Scalars['String']>;
  stage_exit_time?: InputMaybe<Scalars['timestamptz']>;
  template_id?: InputMaybe<Scalars['Int']>;
  time_in_stage_ms?: InputMaybe<Scalars['bigint']>;
  time_in_stage_text?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type AuditStages_Sum_Fields = {
  __typename?: 'AuditStages_sum_fields';
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  regression_number?: Maybe<Scalars['Int']>;
  regression_reason_id?: Maybe<Scalars['Int']>;
  rm_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['Int']>;
  time_in_stage_ms?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "AuditStages" */
export type AuditStages_Sum_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** update columns of table "AuditStages" */
export enum AuditStages_Update_Column {
  /** column name */
  AmId = 'am_id',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsReentrantInstance = 'is_reentrant_instance',
  /** column name */
  IsTransitionToOffBoard = 'is_transition_to_off_board',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastUpdateTs = 'last_update_ts',
  /** column name */
  RegressionNumber = 'regression_number',
  /** column name */
  RegressionReasonId = 'regression_reason_id',
  /** column name */
  RegressionReasonTxt = 'regression_reason_txt',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  StageExitReason = 'stage_exit_reason',
  /** column name */
  StageExitTime = 'stage_exit_time',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  TimeInStageMs = 'time_in_stage_ms',
  /** column name */
  TimeInStageText = 'time_in_stage_text',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AuditStages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuditStages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuditStages_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuditStages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AuditStages_Var_Pop_Fields = {
  __typename?: 'AuditStages_var_pop_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "AuditStages" */
export type AuditStages_Var_Pop_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AuditStages_Var_Samp_Fields = {
  __typename?: 'AuditStages_var_samp_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "AuditStages" */
export type AuditStages_Var_Samp_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AuditStages_Variance_Fields = {
  __typename?: 'AuditStages_variance_fields';
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  regression_number?: Maybe<Scalars['Float']>;
  regression_reason_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
  time_in_stage_ms?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "AuditStages" */
export type AuditStages_Variance_Order_By = {
  am_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  regression_number?: InputMaybe<Order_By>;
  regression_reason_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  time_in_stage_ms?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "AuthAudits" */
export type AuthAudits = {
  __typename?: 'AuthAudits';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  aws_event?: Maybe<Scalars['jsonb']>;
  cog_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  event: Scalars['String'];
  id: Scalars['bigint'];
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "AuthAudits" */
export type AuthAuditsAws_EventArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "AuthAudits" */
export type AuthAuditsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "AuthAudits" */
export type AuthAudits_Aggregate = {
  __typename?: 'AuthAudits_aggregate';
  aggregate?: Maybe<AuthAudits_Aggregate_Fields>;
  nodes: Array<AuthAudits>;
};

/** aggregate fields of "AuthAudits" */
export type AuthAudits_Aggregate_Fields = {
  __typename?: 'AuthAudits_aggregate_fields';
  avg?: Maybe<AuthAudits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AuthAudits_Max_Fields>;
  min?: Maybe<AuthAudits_Min_Fields>;
  stddev?: Maybe<AuthAudits_Stddev_Fields>;
  stddev_pop?: Maybe<AuthAudits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuthAudits_Stddev_Samp_Fields>;
  sum?: Maybe<AuthAudits_Sum_Fields>;
  var_pop?: Maybe<AuthAudits_Var_Pop_Fields>;
  var_samp?: Maybe<AuthAudits_Var_Samp_Fields>;
  variance?: Maybe<AuthAudits_Variance_Fields>;
};


/** aggregate fields of "AuthAudits" */
export type AuthAudits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthAudits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthAudits_Append_Input = {
  aws_event?: InputMaybe<Scalars['jsonb']>;
  data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type AuthAudits_Avg_Fields = {
  __typename?: 'AuthAudits_avg_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AuthAudits". All fields are combined with a logical 'AND'. */
export type AuthAudits_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<AuthAudits_Bool_Exp>>;
  _not?: InputMaybe<AuthAudits_Bool_Exp>;
  _or?: InputMaybe<Array<AuthAudits_Bool_Exp>>;
  aws_event?: InputMaybe<Jsonb_Comparison_Exp>;
  cog_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AuthAudits" */
export enum AuthAudits_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuthAuditsPkey = 'AuthAudits_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthAudits_Delete_At_Path_Input = {
  aws_event?: InputMaybe<Array<Scalars['String']>>;
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthAudits_Delete_Elem_Input = {
  aws_event?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthAudits_Delete_Key_Input = {
  aws_event?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "AuthAudits" */
export type AuthAudits_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "AuthAudits" */
export type AuthAudits_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  aws_event?: InputMaybe<Scalars['jsonb']>;
  cog_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AuthAudits_Max_Fields = {
  __typename?: 'AuthAudits_max_fields';
  cog_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AuthAudits_Min_Fields = {
  __typename?: 'AuthAudits_min_fields';
  cog_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AuthAudits" */
export type AuthAudits_Mutation_Response = {
  __typename?: 'AuthAudits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthAudits>;
};

/** on_conflict condition type for table "AuthAudits" */
export type AuthAudits_On_Conflict = {
  constraint: AuthAudits_Constraint;
  update_columns?: Array<AuthAudits_Update_Column>;
  where?: InputMaybe<AuthAudits_Bool_Exp>;
};

/** Ordering options when selecting data from "AuthAudits". */
export type AuthAudits_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  aws_event?: InputMaybe<Order_By>;
  cog_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AuthAudits */
export type AuthAudits_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthAudits_Prepend_Input = {
  aws_event?: InputMaybe<Scalars['jsonb']>;
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "AuthAudits" */
export enum AuthAudits_Select_Column {
  /** column name */
  AwsEvent = 'aws_event',
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "AuthAudits" */
export type AuthAudits_Set_Input = {
  aws_event?: InputMaybe<Scalars['jsonb']>;
  cog_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AuthAudits_Stddev_Fields = {
  __typename?: 'AuthAudits_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AuthAudits_Stddev_Pop_Fields = {
  __typename?: 'AuthAudits_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AuthAudits_Stddev_Samp_Fields = {
  __typename?: 'AuthAudits_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "AuthAudits" */
export type AuthAudits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthAudits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthAudits_Stream_Cursor_Value_Input = {
  aws_event?: InputMaybe<Scalars['jsonb']>;
  cog_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type AuthAudits_Sum_Fields = {
  __typename?: 'AuthAudits_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "AuthAudits" */
export enum AuthAudits_Update_Column {
  /** column name */
  AwsEvent = 'aws_event',
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type AuthAudits_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthAudits_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthAudits_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthAudits_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthAudits_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthAudits_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthAudits_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthAudits_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthAudits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AuthAudits_Var_Pop_Fields = {
  __typename?: 'AuthAudits_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AuthAudits_Var_Samp_Fields = {
  __typename?: 'AuthAudits_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AuthAudits_Variance_Fields = {
  __typename?: 'AuthAudits_variance_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Badges" */
export type Badges = {
  __typename?: 'Badges';
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Badges" */
export type Badges_Aggregate = {
  __typename?: 'Badges_aggregate';
  aggregate?: Maybe<Badges_Aggregate_Fields>;
  nodes: Array<Badges>;
};

/** aggregate fields of "Badges" */
export type Badges_Aggregate_Fields = {
  __typename?: 'Badges_aggregate_fields';
  avg?: Maybe<Badges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Badges_Max_Fields>;
  min?: Maybe<Badges_Min_Fields>;
  stddev?: Maybe<Badges_Stddev_Fields>;
  stddev_pop?: Maybe<Badges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Badges_Stddev_Samp_Fields>;
  sum?: Maybe<Badges_Sum_Fields>;
  var_pop?: Maybe<Badges_Var_Pop_Fields>;
  var_samp?: Maybe<Badges_Var_Samp_Fields>;
  variance?: Maybe<Badges_Variance_Fields>;
};


/** aggregate fields of "Badges" */
export type Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Badges_Avg_Fields = {
  __typename?: 'Badges_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Badges". All fields are combined with a logical 'AND'. */
export type Badges_Bool_Exp = {
  _and?: InputMaybe<Array<Badges_Bool_Exp>>;
  _not?: InputMaybe<Badges_Bool_Exp>;
  _or?: InputMaybe<Array<Badges_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  weight?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Badges" */
export enum Badges_Constraint {
  /** unique or primary key constraint on columns "id" */
  BadgesPkey = 'Badges_pkey'
}

/** input type for incrementing numeric columns in table "Badges" */
export type Badges_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Badges" */
export type Badges_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Badges_Max_Fields = {
  __typename?: 'Badges_max_fields';
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Badges_Min_Fields = {
  __typename?: 'Badges_min_fields';
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Badges" */
export type Badges_Mutation_Response = {
  __typename?: 'Badges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Badges>;
};

/** on_conflict condition type for table "Badges" */
export type Badges_On_Conflict = {
  constraint: Badges_Constraint;
  update_columns?: Array<Badges_Update_Column>;
  where?: InputMaybe<Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "Badges". */
export type Badges_Order_By = {
  category?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Badges */
export type Badges_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Badges" */
export enum Badges_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "Badges" */
export type Badges_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Badges_Stddev_Fields = {
  __typename?: 'Badges_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Badges_Stddev_Pop_Fields = {
  __typename?: 'Badges_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Badges_Stddev_Samp_Fields = {
  __typename?: 'Badges_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Badges" */
export type Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Badges_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Badges_Sum_Fields = {
  __typename?: 'Badges_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

/** update columns of table "Badges" */
export enum Badges_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Weight = 'weight'
}

export type Badges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Badges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Badges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Badges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Badges_Var_Pop_Fields = {
  __typename?: 'Badges_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Badges_Var_Samp_Fields = {
  __typename?: 'Badges_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Badges_Variance_Fields = {
  __typename?: 'Badges_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Benefit" */
export type Benefit = {
  __typename?: 'Benefit';
  /** An object relationship */
  BenefitType?: Maybe<BenefitTypes>;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An array relationship */
  Expenses: Array<Expense>;
  /** An aggregate relationship */
  Expenses_aggregate: Expense_Aggregate;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  amount?: Maybe<Scalars['money']>;
  benefitTypeId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  identityId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "Benefit" */
export type BenefitExpensesArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


/** columns and relationships of "Benefit" */
export type BenefitExpenses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};

/** columns and relationships of "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes = {
  __typename?: 'BenefitTypeIdentitySubTypes';
  /** An object relationship */
  BenefitType: BenefitTypes;
  /** An object relationship */
  IdentitySubType: IdentitySubTypes;
  benefit_type_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  identity_sub_type_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Aggregate = {
  __typename?: 'BenefitTypeIdentitySubTypes_aggregate';
  aggregate?: Maybe<BenefitTypeIdentitySubTypes_Aggregate_Fields>;
  nodes: Array<BenefitTypeIdentitySubTypes>;
};

export type BenefitTypeIdentitySubTypes_Aggregate_Bool_Exp = {
  count?: InputMaybe<BenefitTypeIdentitySubTypes_Aggregate_Bool_Exp_Count>;
};

export type BenefitTypeIdentitySubTypes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Aggregate_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_aggregate_fields';
  avg?: Maybe<BenefitTypeIdentitySubTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BenefitTypeIdentitySubTypes_Max_Fields>;
  min?: Maybe<BenefitTypeIdentitySubTypes_Min_Fields>;
  stddev?: Maybe<BenefitTypeIdentitySubTypes_Stddev_Fields>;
  stddev_pop?: Maybe<BenefitTypeIdentitySubTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BenefitTypeIdentitySubTypes_Stddev_Samp_Fields>;
  sum?: Maybe<BenefitTypeIdentitySubTypes_Sum_Fields>;
  var_pop?: Maybe<BenefitTypeIdentitySubTypes_Var_Pop_Fields>;
  var_samp?: Maybe<BenefitTypeIdentitySubTypes_Var_Samp_Fields>;
  variance?: Maybe<BenefitTypeIdentitySubTypes_Variance_Fields>;
};


/** aggregate fields of "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Aggregate_Order_By = {
  avg?: InputMaybe<BenefitTypeIdentitySubTypes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<BenefitTypeIdentitySubTypes_Max_Order_By>;
  min?: InputMaybe<BenefitTypeIdentitySubTypes_Min_Order_By>;
  stddev?: InputMaybe<BenefitTypeIdentitySubTypes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<BenefitTypeIdentitySubTypes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<BenefitTypeIdentitySubTypes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<BenefitTypeIdentitySubTypes_Sum_Order_By>;
  var_pop?: InputMaybe<BenefitTypeIdentitySubTypes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<BenefitTypeIdentitySubTypes_Var_Samp_Order_By>;
  variance?: InputMaybe<BenefitTypeIdentitySubTypes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Arr_Rel_Insert_Input = {
  data: Array<BenefitTypeIdentitySubTypes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<BenefitTypeIdentitySubTypes_On_Conflict>;
};

/** aggregate avg on columns */
export type BenefitTypeIdentitySubTypes_Avg_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_avg_fields';
  benefit_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Avg_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BenefitTypeIdentitySubTypes". All fields are combined with a logical 'AND'. */
export type BenefitTypeIdentitySubTypes_Bool_Exp = {
  BenefitType?: InputMaybe<BenefitTypes_Bool_Exp>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Bool_Exp>;
  _and?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Bool_Exp>>;
  _not?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
  _or?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Bool_Exp>>;
  benefit_type_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_sub_type_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "BenefitTypeIdentitySubTypes" */
export enum BenefitTypeIdentitySubTypes_Constraint {
  /** unique or primary key constraint on columns "benefit_type_id", "identity_sub_type_id" */
  BenefitTypeIdentitySubTypesBenefitTypeIdIdentitySubTypeI = 'BenefitTypeIdentitySubTypes_benefit_type_id_identity_sub_type_i',
  /** unique or primary key constraint on columns "id" */
  BenefitTypeIdentitySubTypesPkey = 'BenefitTypeIdentitySubTypes_pkey'
}

/** input type for incrementing numeric columns in table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Inc_Input = {
  benefit_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Insert_Input = {
  BenefitType?: InputMaybe<BenefitTypes_Obj_Rel_Insert_Input>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Obj_Rel_Insert_Input>;
  benefit_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BenefitTypeIdentitySubTypes_Max_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_max_fields';
  benefit_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Max_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type BenefitTypeIdentitySubTypes_Min_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_min_fields';
  benefit_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Min_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Mutation_Response = {
  __typename?: 'BenefitTypeIdentitySubTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BenefitTypeIdentitySubTypes>;
};

/** on_conflict condition type for table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_On_Conflict = {
  constraint: BenefitTypeIdentitySubTypes_Constraint;
  update_columns?: Array<BenefitTypeIdentitySubTypes_Update_Column>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "BenefitTypeIdentitySubTypes". */
export type BenefitTypeIdentitySubTypes_Order_By = {
  BenefitType?: InputMaybe<BenefitTypes_Order_By>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Order_By>;
  benefit_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BenefitTypeIdentitySubTypes */
export type BenefitTypeIdentitySubTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "BenefitTypeIdentitySubTypes" */
export enum BenefitTypeIdentitySubTypes_Select_Column {
  /** column name */
  BenefitTypeId = 'benefit_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentitySubTypeId = 'identity_sub_type_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Set_Input = {
  benefit_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type BenefitTypeIdentitySubTypes_Stddev_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_stddev_fields';
  benefit_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Stddev_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BenefitTypeIdentitySubTypes_Stddev_Pop_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_stddev_pop_fields';
  benefit_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Stddev_Pop_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BenefitTypeIdentitySubTypes_Stddev_Samp_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_stddev_samp_fields';
  benefit_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Stddev_Samp_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BenefitTypeIdentitySubTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BenefitTypeIdentitySubTypes_Stream_Cursor_Value_Input = {
  benefit_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type BenefitTypeIdentitySubTypes_Sum_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_sum_fields';
  benefit_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Sum_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** update columns of table "BenefitTypeIdentitySubTypes" */
export enum BenefitTypeIdentitySubTypes_Update_Column {
  /** column name */
  BenefitTypeId = 'benefit_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentitySubTypeId = 'identity_sub_type_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BenefitTypeIdentitySubTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BenefitTypeIdentitySubTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BenefitTypeIdentitySubTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: BenefitTypeIdentitySubTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type BenefitTypeIdentitySubTypes_Var_Pop_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_var_pop_fields';
  benefit_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Var_Pop_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BenefitTypeIdentitySubTypes_Var_Samp_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_var_samp_fields';
  benefit_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Var_Samp_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type BenefitTypeIdentitySubTypes_Variance_Fields = {
  __typename?: 'BenefitTypeIdentitySubTypes_variance_fields';
  benefit_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BenefitTypeIdentitySubTypes" */
export type BenefitTypeIdentitySubTypes_Variance_Order_By = {
  benefit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "BenefitTypes" */
export type BenefitTypes = {
  __typename?: 'BenefitTypes';
  /** An array relationship */
  BenefitTypeIdentitySubTypes: Array<BenefitTypeIdentitySubTypes>;
  /** An aggregate relationship */
  BenefitTypeIdentitySubTypes_aggregate: BenefitTypeIdentitySubTypes_Aggregate;
  /** An array relationship */
  Benefits: Array<Benefit>;
  /** An aggregate relationship */
  Benefits_aggregate: Benefit_Aggregate;
  /** An object relationship */
  Company: Companies;
  companyId: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  defaultAmount: Scalars['money'];
  deleted: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "BenefitTypes" */
export type BenefitTypesBenefitTypeIdentitySubTypesArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


/** columns and relationships of "BenefitTypes" */
export type BenefitTypesBenefitTypeIdentitySubTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


/** columns and relationships of "BenefitTypes" */
export type BenefitTypesBenefitsArgs = {
  distinct_on?: InputMaybe<Array<Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Benefit_Order_By>>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};


/** columns and relationships of "BenefitTypes" */
export type BenefitTypesBenefits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Benefit_Order_By>>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};

/** aggregated selection of "BenefitTypes" */
export type BenefitTypes_Aggregate = {
  __typename?: 'BenefitTypes_aggregate';
  aggregate?: Maybe<BenefitTypes_Aggregate_Fields>;
  nodes: Array<BenefitTypes>;
};

/** aggregate fields of "BenefitTypes" */
export type BenefitTypes_Aggregate_Fields = {
  __typename?: 'BenefitTypes_aggregate_fields';
  avg?: Maybe<BenefitTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BenefitTypes_Max_Fields>;
  min?: Maybe<BenefitTypes_Min_Fields>;
  stddev?: Maybe<BenefitTypes_Stddev_Fields>;
  stddev_pop?: Maybe<BenefitTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BenefitTypes_Stddev_Samp_Fields>;
  sum?: Maybe<BenefitTypes_Sum_Fields>;
  var_pop?: Maybe<BenefitTypes_Var_Pop_Fields>;
  var_samp?: Maybe<BenefitTypes_Var_Samp_Fields>;
  variance?: Maybe<BenefitTypes_Variance_Fields>;
};


/** aggregate fields of "BenefitTypes" */
export type BenefitTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BenefitTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BenefitTypes_Avg_Fields = {
  __typename?: 'BenefitTypes_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  defaultAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BenefitTypes". All fields are combined with a logical 'AND'. */
export type BenefitTypes_Bool_Exp = {
  BenefitTypeIdentitySubTypes?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
  BenefitTypeIdentitySubTypes_aggregate?: InputMaybe<BenefitTypeIdentitySubTypes_Aggregate_Bool_Exp>;
  Benefits?: InputMaybe<Benefit_Bool_Exp>;
  Benefits_aggregate?: InputMaybe<Benefit_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  _and?: InputMaybe<Array<BenefitTypes_Bool_Exp>>;
  _not?: InputMaybe<BenefitTypes_Bool_Exp>;
  _or?: InputMaybe<Array<BenefitTypes_Bool_Exp>>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  defaultAmount?: InputMaybe<Money_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "BenefitTypes" */
export enum BenefitTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  BenefitTypesPkey = 'BenefitTypes_pkey'
}

/** input type for incrementing numeric columns in table "BenefitTypes" */
export type BenefitTypes_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  defaultAmount?: InputMaybe<Scalars['money']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "BenefitTypes" */
export type BenefitTypes_Insert_Input = {
  BenefitTypeIdentitySubTypes?: InputMaybe<BenefitTypeIdentitySubTypes_Arr_Rel_Insert_Input>;
  Benefits?: InputMaybe<Benefit_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  defaultAmount?: InputMaybe<Scalars['money']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BenefitTypes_Max_Fields = {
  __typename?: 'BenefitTypes_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  defaultAmount?: Maybe<Scalars['money']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type BenefitTypes_Min_Fields = {
  __typename?: 'BenefitTypes_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  defaultAmount?: Maybe<Scalars['money']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "BenefitTypes" */
export type BenefitTypes_Mutation_Response = {
  __typename?: 'BenefitTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BenefitTypes>;
};

/** input type for inserting object relation for remote table "BenefitTypes" */
export type BenefitTypes_Obj_Rel_Insert_Input = {
  data: BenefitTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<BenefitTypes_On_Conflict>;
};

/** on_conflict condition type for table "BenefitTypes" */
export type BenefitTypes_On_Conflict = {
  constraint: BenefitTypes_Constraint;
  update_columns?: Array<BenefitTypes_Update_Column>;
  where?: InputMaybe<BenefitTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "BenefitTypes". */
export type BenefitTypes_Order_By = {
  BenefitTypeIdentitySubTypes_aggregate?: InputMaybe<BenefitTypeIdentitySubTypes_Aggregate_Order_By>;
  Benefits_aggregate?: InputMaybe<Benefit_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  companyId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  defaultAmount?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BenefitTypes */
export type BenefitTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "BenefitTypes" */
export enum BenefitTypes_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAmount = 'defaultAmount',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "BenefitTypes" */
export type BenefitTypes_Set_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  defaultAmount?: InputMaybe<Scalars['money']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type BenefitTypes_Stddev_Fields = {
  __typename?: 'BenefitTypes_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  defaultAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BenefitTypes_Stddev_Pop_Fields = {
  __typename?: 'BenefitTypes_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  defaultAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BenefitTypes_Stddev_Samp_Fields = {
  __typename?: 'BenefitTypes_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  defaultAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "BenefitTypes" */
export type BenefitTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BenefitTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BenefitTypes_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  defaultAmount?: InputMaybe<Scalars['money']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type BenefitTypes_Sum_Fields = {
  __typename?: 'BenefitTypes_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  defaultAmount?: Maybe<Scalars['money']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "BenefitTypes" */
export enum BenefitTypes_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAmount = 'defaultAmount',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BenefitTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BenefitTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BenefitTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: BenefitTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type BenefitTypes_Var_Pop_Fields = {
  __typename?: 'BenefitTypes_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  defaultAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BenefitTypes_Var_Samp_Fields = {
  __typename?: 'BenefitTypes_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  defaultAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BenefitTypes_Variance_Fields = {
  __typename?: 'BenefitTypes_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  defaultAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Benefit" */
export type Benefit_Aggregate = {
  __typename?: 'Benefit_aggregate';
  aggregate?: Maybe<Benefit_Aggregate_Fields>;
  nodes: Array<Benefit>;
};

export type Benefit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Benefit_Aggregate_Bool_Exp_Count>;
};

export type Benefit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Benefit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Benefit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Benefit" */
export type Benefit_Aggregate_Fields = {
  __typename?: 'Benefit_aggregate_fields';
  avg?: Maybe<Benefit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Benefit_Max_Fields>;
  min?: Maybe<Benefit_Min_Fields>;
  stddev?: Maybe<Benefit_Stddev_Fields>;
  stddev_pop?: Maybe<Benefit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Benefit_Stddev_Samp_Fields>;
  sum?: Maybe<Benefit_Sum_Fields>;
  var_pop?: Maybe<Benefit_Var_Pop_Fields>;
  var_samp?: Maybe<Benefit_Var_Samp_Fields>;
  variance?: Maybe<Benefit_Variance_Fields>;
};


/** aggregate fields of "Benefit" */
export type Benefit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Benefit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Benefit" */
export type Benefit_Aggregate_Order_By = {
  avg?: InputMaybe<Benefit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Benefit_Max_Order_By>;
  min?: InputMaybe<Benefit_Min_Order_By>;
  stddev?: InputMaybe<Benefit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Benefit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Benefit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Benefit_Sum_Order_By>;
  var_pop?: InputMaybe<Benefit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Benefit_Var_Samp_Order_By>;
  variance?: InputMaybe<Benefit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Benefit" */
export type Benefit_Arr_Rel_Insert_Input = {
  data: Array<Benefit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Benefit_On_Conflict>;
};

/** aggregate avg on columns */
export type Benefit_Avg_Fields = {
  __typename?: 'Benefit_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  benefitTypeId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Benefit" */
export type Benefit_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Benefit". All fields are combined with a logical 'AND'. */
export type Benefit_Bool_Exp = {
  BenefitType?: InputMaybe<BenefitTypes_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  Expenses?: InputMaybe<Expense_Bool_Exp>;
  Expenses_aggregate?: InputMaybe<Expense_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Benefit_Bool_Exp>>;
  _not?: InputMaybe<Benefit_Bool_Exp>;
  _or?: InputMaybe<Array<Benefit_Bool_Exp>>;
  amount?: InputMaybe<Money_Comparison_Exp>;
  benefitTypeId?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  startDate?: InputMaybe<Date_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Benefit" */
export enum Benefit_Constraint {
  /** unique or primary key constraint on columns "benefitTypeId", "identityId" */
  BenefitBenefitTypeIdIdentityIdKey = 'Benefit_benefitTypeId_identityId_key',
  /** unique or primary key constraint on columns "id" */
  BenefitPkey = 'Benefit_pkey'
}

/** input type for incrementing numeric columns in table "Benefit" */
export type Benefit_Inc_Input = {
  amount?: InputMaybe<Scalars['money']>;
  benefitTypeId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Benefit" */
export type Benefit_Insert_Input = {
  BenefitType?: InputMaybe<BenefitTypes_Obj_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Expenses?: InputMaybe<Expense_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['money']>;
  benefitTypeId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Benefit_Max_Fields = {
  __typename?: 'Benefit_max_fields';
  amount?: Maybe<Scalars['money']>;
  benefitTypeId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Benefit" */
export type Benefit_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Benefit_Min_Fields = {
  __typename?: 'Benefit_min_fields';
  amount?: Maybe<Scalars['money']>;
  benefitTypeId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Benefit" */
export type Benefit_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Benefit" */
export type Benefit_Mutation_Response = {
  __typename?: 'Benefit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Benefit>;
};

/** on_conflict condition type for table "Benefit" */
export type Benefit_On_Conflict = {
  constraint: Benefit_Constraint;
  update_columns?: Array<Benefit_Update_Column>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};

/** Ordering options when selecting data from "Benefit". */
export type Benefit_Order_By = {
  BenefitType?: InputMaybe<BenefitTypes_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  Expenses_aggregate?: InputMaybe<Expense_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Benefit */
export type Benefit_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Benefit" */
export enum Benefit_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BenefitTypeId = 'benefitTypeId',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Benefit" */
export type Benefit_Set_Input = {
  amount?: InputMaybe<Scalars['money']>;
  benefitTypeId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Benefit_Stddev_Fields = {
  __typename?: 'Benefit_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  benefitTypeId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Benefit" */
export type Benefit_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Benefit_Stddev_Pop_Fields = {
  __typename?: 'Benefit_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  benefitTypeId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Benefit" */
export type Benefit_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Benefit_Stddev_Samp_Fields = {
  __typename?: 'Benefit_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  benefitTypeId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Benefit" */
export type Benefit_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Benefit" */
export type Benefit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Benefit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Benefit_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['money']>;
  benefitTypeId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Benefit_Sum_Fields = {
  __typename?: 'Benefit_sum_fields';
  amount?: Maybe<Scalars['money']>;
  benefitTypeId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Benefit" */
export type Benefit_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** update columns of table "Benefit" */
export enum Benefit_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BenefitTypeId = 'benefitTypeId',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Benefit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Benefit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Benefit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Benefit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Benefit_Var_Pop_Fields = {
  __typename?: 'Benefit_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  benefitTypeId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Benefit" */
export type Benefit_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Benefit_Var_Samp_Fields = {
  __typename?: 'Benefit_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  benefitTypeId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Benefit" */
export type Benefit_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Benefit_Variance_Fields = {
  __typename?: 'Benefit_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  benefitTypeId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Benefit" */
export type Benefit_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  benefitTypeId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** columns and relationships of "BoardFilters" */
export type BoardFilters = {
  __typename?: 'BoardFilters';
  /** An object relationship */
  User: Identities;
  created_at?: Maybe<Scalars['timestamptz']>;
  default: Scalars['Boolean'];
  filter_data: Scalars['json'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['Int'];
};


/** columns and relationships of "BoardFilters" */
export type BoardFiltersFilter_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "BoardFilters" */
export type BoardFilters_Aggregate = {
  __typename?: 'BoardFilters_aggregate';
  aggregate?: Maybe<BoardFilters_Aggregate_Fields>;
  nodes: Array<BoardFilters>;
};

/** aggregate fields of "BoardFilters" */
export type BoardFilters_Aggregate_Fields = {
  __typename?: 'BoardFilters_aggregate_fields';
  avg?: Maybe<BoardFilters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardFilters_Max_Fields>;
  min?: Maybe<BoardFilters_Min_Fields>;
  stddev?: Maybe<BoardFilters_Stddev_Fields>;
  stddev_pop?: Maybe<BoardFilters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardFilters_Stddev_Samp_Fields>;
  sum?: Maybe<BoardFilters_Sum_Fields>;
  var_pop?: Maybe<BoardFilters_Var_Pop_Fields>;
  var_samp?: Maybe<BoardFilters_Var_Samp_Fields>;
  variance?: Maybe<BoardFilters_Variance_Fields>;
};


/** aggregate fields of "BoardFilters" */
export type BoardFilters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BoardFilters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BoardFilters_Avg_Fields = {
  __typename?: 'BoardFilters_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BoardFilters". All fields are combined with a logical 'AND'. */
export type BoardFilters_Bool_Exp = {
  User?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<BoardFilters_Bool_Exp>>;
  _not?: InputMaybe<BoardFilters_Bool_Exp>;
  _or?: InputMaybe<Array<BoardFilters_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  filter_data?: InputMaybe<Json_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "BoardFilters" */
export enum BoardFilters_Constraint {
  /** unique or primary key constraint on columns "id" */
  BoardFiltersPkey = 'BoardFilters_pkey'
}

/** input type for incrementing numeric columns in table "BoardFilters" */
export type BoardFilters_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "BoardFilters" */
export type BoardFilters_Insert_Input = {
  User?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  filter_data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type BoardFilters_Max_Fields = {
  __typename?: 'BoardFilters_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BoardFilters_Min_Fields = {
  __typename?: 'BoardFilters_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "BoardFilters" */
export type BoardFilters_Mutation_Response = {
  __typename?: 'BoardFilters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardFilters>;
};

/** on_conflict condition type for table "BoardFilters" */
export type BoardFilters_On_Conflict = {
  constraint: BoardFilters_Constraint;
  update_columns?: Array<BoardFilters_Update_Column>;
  where?: InputMaybe<BoardFilters_Bool_Exp>;
};

/** Ordering options when selecting data from "BoardFilters". */
export type BoardFilters_Order_By = {
  User?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  filter_data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BoardFilters */
export type BoardFilters_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "BoardFilters" */
export enum BoardFilters_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  FilterData = 'filter_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "BoardFilters" */
export type BoardFilters_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  filter_data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BoardFilters_Stddev_Fields = {
  __typename?: 'BoardFilters_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BoardFilters_Stddev_Pop_Fields = {
  __typename?: 'BoardFilters_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BoardFilters_Stddev_Samp_Fields = {
  __typename?: 'BoardFilters_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "BoardFilters" */
export type BoardFilters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BoardFilters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BoardFilters_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  filter_data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type BoardFilters_Sum_Fields = {
  __typename?: 'BoardFilters_sum_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "BoardFilters" */
export enum BoardFilters_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  FilterData = 'filter_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type BoardFilters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BoardFilters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BoardFilters_Set_Input>;
  /** filter the rows which have to be updated */
  where: BoardFilters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type BoardFilters_Var_Pop_Fields = {
  __typename?: 'BoardFilters_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BoardFilters_Var_Samp_Fields = {
  __typename?: 'BoardFilters_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BoardFilters_Variance_Fields = {
  __typename?: 'BoardFilters_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "BoardSnippets" */
export type BoardSnippets = {
  __typename?: 'BoardSnippets';
  /** An object relationship */
  Application?: Maybe<Applications>;
  /** An array relationship */
  AuditStages: Array<AuditStages>;
  /** An aggregate relationship */
  AuditStages_aggregate: AuditStages_Aggregate;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An array relationship */
  Interviews: Array<Interviews>;
  /** An aggregate relationship */
  Interviews_aggregate: Interviews_Aggregate;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An object relationship */
  LastRegressionReason?: Maybe<WorkflowRegressions>;
  /** An object relationship */
  Rejection?: Maybe<Rejections>;
  /** An object relationship */
  Resource_Manager?: Maybe<Identities>;
  /** An object relationship */
  Stage?: Maybe<Stages>;
  /** An object relationship */
  Submission?: Maybe<Submissions>;
  application_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_changed_by_id?: Maybe<Scalars['Int']>;
  last_regression_reason_id?: Maybe<Scalars['Int']>;
  last_regression_reason_txt?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  order?: Maybe<Scalars['Int']>;
  regression_count: Scalars['Int'];
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  removal_reason?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsAuditStagesArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsAuditStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsInterviewsArgs = {
  distinct_on?: InputMaybe<Array<Interviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interviews_Order_By>>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsInterviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interviews_Order_By>>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "BoardSnippets" */
export type BoardSnippetsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "BoardSnippets" */
export type BoardSnippets_Aggregate = {
  __typename?: 'BoardSnippets_aggregate';
  aggregate?: Maybe<BoardSnippets_Aggregate_Fields>;
  nodes: Array<BoardSnippets>;
};

export type BoardSnippets_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp_Count>;
};

export type BoardSnippets_Aggregate_Bool_Exp_Bool_And = {
  arguments: BoardSnippets_Select_Column_BoardSnippets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BoardSnippets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type BoardSnippets_Aggregate_Bool_Exp_Bool_Or = {
  arguments: BoardSnippets_Select_Column_BoardSnippets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BoardSnippets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type BoardSnippets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BoardSnippets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "BoardSnippets" */
export type BoardSnippets_Aggregate_Fields = {
  __typename?: 'BoardSnippets_aggregate_fields';
  avg?: Maybe<BoardSnippets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardSnippets_Max_Fields>;
  min?: Maybe<BoardSnippets_Min_Fields>;
  stddev?: Maybe<BoardSnippets_Stddev_Fields>;
  stddev_pop?: Maybe<BoardSnippets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardSnippets_Stddev_Samp_Fields>;
  sum?: Maybe<BoardSnippets_Sum_Fields>;
  var_pop?: Maybe<BoardSnippets_Var_Pop_Fields>;
  var_samp?: Maybe<BoardSnippets_Var_Samp_Fields>;
  variance?: Maybe<BoardSnippets_Variance_Fields>;
};


/** aggregate fields of "BoardSnippets" */
export type BoardSnippets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BoardSnippets" */
export type BoardSnippets_Aggregate_Order_By = {
  avg?: InputMaybe<BoardSnippets_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<BoardSnippets_Max_Order_By>;
  min?: InputMaybe<BoardSnippets_Min_Order_By>;
  stddev?: InputMaybe<BoardSnippets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<BoardSnippets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<BoardSnippets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<BoardSnippets_Sum_Order_By>;
  var_pop?: InputMaybe<BoardSnippets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<BoardSnippets_Var_Samp_Order_By>;
  variance?: InputMaybe<BoardSnippets_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type BoardSnippets_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "BoardSnippets" */
export type BoardSnippets_Arr_Rel_Insert_Input = {
  data: Array<BoardSnippets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<BoardSnippets_On_Conflict>;
};

/** aggregate avg on columns */
export type BoardSnippets_Avg_Fields = {
  __typename?: 'BoardSnippets_avg_fields';
  application_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  last_changed_by_id?: Maybe<Scalars['Float']>;
  last_regression_reason_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  regression_count?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BoardSnippets" */
export type BoardSnippets_Avg_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BoardSnippets". All fields are combined with a logical 'AND'. */
export type BoardSnippets_Bool_Exp = {
  Application?: InputMaybe<Applications_Bool_Exp>;
  AuditStages?: InputMaybe<AuditStages_Bool_Exp>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  Comments?: InputMaybe<Comments_Bool_Exp>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Interviews?: InputMaybe<Interviews_Bool_Exp>;
  Interviews_aggregate?: InputMaybe<Interviews_Aggregate_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  LastRegressionReason?: InputMaybe<WorkflowRegressions_Bool_Exp>;
  Rejection?: InputMaybe<Rejections_Bool_Exp>;
  Resource_Manager?: InputMaybe<Identities_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  Submission?: InputMaybe<Submissions_Bool_Exp>;
  _and?: InputMaybe<Array<BoardSnippets_Bool_Exp>>;
  _not?: InputMaybe<BoardSnippets_Bool_Exp>;
  _or?: InputMaybe<Array<BoardSnippets_Bool_Exp>>;
  application_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  last_changed_by_id?: InputMaybe<Int_Comparison_Exp>;
  last_regression_reason_id?: InputMaybe<Int_Comparison_Exp>;
  last_regression_reason_txt?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  regression_count?: InputMaybe<Int_Comparison_Exp>;
  rejection_id?: InputMaybe<Int_Comparison_Exp>;
  relevance_last_calced?: InputMaybe<Timestamptz_Comparison_Exp>;
  relevance_score?: InputMaybe<Numeric_Comparison_Exp>;
  removal_reason?: InputMaybe<String_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  submission_id?: InputMaybe<Uuid_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "BoardSnippets" */
export enum BoardSnippets_Constraint {
  /** unique or primary key constraint on columns "identity_id", "company_id", "deleted", "job_id" */
  BoardSnippetsCompanyIdIdentityIdJobIdDeletedIdx = 'BoardSnippets_company_id_identity_id_job_id_deleted_idx',
  /** unique or primary key constraint on columns "etl_id_token" */
  BoardSnippetsEtlIdTokenKey = 'BoardSnippets_etl_id_token_key',
  /** unique or primary key constraint on columns "id" */
  BoardSnippetsPkey = 'BoardSnippets_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type BoardSnippets_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type BoardSnippets_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type BoardSnippets_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "BoardSnippets" */
export type BoardSnippets_Inc_Input = {
  application_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_changed_by_id?: InputMaybe<Scalars['Int']>;
  last_regression_reason_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  regression_count?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "BoardSnippets" */
export type BoardSnippets_Insert_Input = {
  Application?: InputMaybe<Applications_Obj_Rel_Insert_Input>;
  AuditStages?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  Comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Interviews?: InputMaybe<Interviews_Arr_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  LastRegressionReason?: InputMaybe<WorkflowRegressions_Obj_Rel_Insert_Input>;
  Rejection?: InputMaybe<Rejections_Obj_Rel_Insert_Input>;
  Resource_Manager?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  Submission?: InputMaybe<Submissions_Obj_Rel_Insert_Input>;
  application_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_changed_by_id?: InputMaybe<Scalars['Int']>;
  last_regression_reason_id?: InputMaybe<Scalars['Int']>;
  last_regression_reason_txt?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order?: InputMaybe<Scalars['Int']>;
  regression_count?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  removal_reason?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  submission_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type BoardSnippets_Max_Fields = {
  __typename?: 'BoardSnippets_max_fields';
  application_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_changed_by_id?: Maybe<Scalars['Int']>;
  last_regression_reason_id?: Maybe<Scalars['Int']>;
  last_regression_reason_txt?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  regression_count?: Maybe<Scalars['Int']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  removal_reason?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "BoardSnippets" */
export type BoardSnippets_Max_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  last_regression_reason_txt?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  removal_reason?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  submission_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type BoardSnippets_Min_Fields = {
  __typename?: 'BoardSnippets_min_fields';
  application_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_changed_by_id?: Maybe<Scalars['Int']>;
  last_regression_reason_id?: Maybe<Scalars['Int']>;
  last_regression_reason_txt?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  regression_count?: Maybe<Scalars['Int']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  removal_reason?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "BoardSnippets" */
export type BoardSnippets_Min_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  last_regression_reason_txt?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  removal_reason?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  submission_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "BoardSnippets" */
export type BoardSnippets_Mutation_Response = {
  __typename?: 'BoardSnippets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardSnippets>;
};

/** input type for inserting object relation for remote table "BoardSnippets" */
export type BoardSnippets_Obj_Rel_Insert_Input = {
  data: BoardSnippets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<BoardSnippets_On_Conflict>;
};

/** on_conflict condition type for table "BoardSnippets" */
export type BoardSnippets_On_Conflict = {
  constraint: BoardSnippets_Constraint;
  update_columns?: Array<BoardSnippets_Update_Column>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};

/** Ordering options when selecting data from "BoardSnippets". */
export type BoardSnippets_Order_By = {
  Application?: InputMaybe<Applications_Order_By>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Interviews_aggregate?: InputMaybe<Interviews_Aggregate_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  LastRegressionReason?: InputMaybe<WorkflowRegressions_Order_By>;
  Rejection?: InputMaybe<Rejections_Order_By>;
  Resource_Manager?: InputMaybe<Identities_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  Submission?: InputMaybe<Submissions_Order_By>;
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  last_regression_reason_txt?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  removal_reason?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  submission_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BoardSnippets */
export type BoardSnippets_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type BoardSnippets_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "BoardSnippets" */
export enum BoardSnippets_Select_Column {
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastChangedById = 'last_changed_by_id',
  /** column name */
  LastRegressionReasonId = 'last_regression_reason_id',
  /** column name */
  LastRegressionReasonTxt = 'last_regression_reason_txt',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Order = 'order',
  /** column name */
  RegressionCount = 'regression_count',
  /** column name */
  RejectionId = 'rejection_id',
  /** column name */
  RelevanceLastCalced = 'relevance_last_calced',
  /** column name */
  RelevanceScore = 'relevance_score',
  /** column name */
  RemovalReason = 'removal_reason',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "BoardSnippets_aggregate_bool_exp_bool_and_arguments_columns" columns of table "BoardSnippets" */
export enum BoardSnippets_Select_Column_BoardSnippets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** select "BoardSnippets_aggregate_bool_exp_bool_or_arguments_columns" columns of table "BoardSnippets" */
export enum BoardSnippets_Select_Column_BoardSnippets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "BoardSnippets" */
export type BoardSnippets_Set_Input = {
  application_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_changed_by_id?: InputMaybe<Scalars['Int']>;
  last_regression_reason_id?: InputMaybe<Scalars['Int']>;
  last_regression_reason_txt?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order?: InputMaybe<Scalars['Int']>;
  regression_count?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  removal_reason?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  submission_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BoardSnippets_Stddev_Fields = {
  __typename?: 'BoardSnippets_stddev_fields';
  application_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  last_changed_by_id?: Maybe<Scalars['Float']>;
  last_regression_reason_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  regression_count?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BoardSnippets" */
export type BoardSnippets_Stddev_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BoardSnippets_Stddev_Pop_Fields = {
  __typename?: 'BoardSnippets_stddev_pop_fields';
  application_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  last_changed_by_id?: Maybe<Scalars['Float']>;
  last_regression_reason_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  regression_count?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BoardSnippets" */
export type BoardSnippets_Stddev_Pop_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BoardSnippets_Stddev_Samp_Fields = {
  __typename?: 'BoardSnippets_stddev_samp_fields';
  application_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  last_changed_by_id?: Maybe<Scalars['Float']>;
  last_regression_reason_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  regression_count?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BoardSnippets" */
export type BoardSnippets_Stddev_Samp_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "BoardSnippets" */
export type BoardSnippets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BoardSnippets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BoardSnippets_Stream_Cursor_Value_Input = {
  application_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_changed_by_id?: InputMaybe<Scalars['Int']>;
  last_regression_reason_id?: InputMaybe<Scalars['Int']>;
  last_regression_reason_txt?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  order?: InputMaybe<Scalars['Int']>;
  regression_count?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  removal_reason?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  submission_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type BoardSnippets_Sum_Fields = {
  __typename?: 'BoardSnippets_sum_fields';
  application_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_changed_by_id?: Maybe<Scalars['Int']>;
  last_regression_reason_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  regression_count?: Maybe<Scalars['Int']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  rm_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BoardSnippets" */
export type BoardSnippets_Sum_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "BoardSnippets" */
export enum BoardSnippets_Update_Column {
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastChangedById = 'last_changed_by_id',
  /** column name */
  LastRegressionReasonId = 'last_regression_reason_id',
  /** column name */
  LastRegressionReasonTxt = 'last_regression_reason_txt',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Order = 'order',
  /** column name */
  RegressionCount = 'regression_count',
  /** column name */
  RejectionId = 'rejection_id',
  /** column name */
  RelevanceLastCalced = 'relevance_last_calced',
  /** column name */
  RelevanceScore = 'relevance_score',
  /** column name */
  RemovalReason = 'removal_reason',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type BoardSnippets_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<BoardSnippets_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<BoardSnippets_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<BoardSnippets_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<BoardSnippets_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BoardSnippets_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<BoardSnippets_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BoardSnippets_Set_Input>;
  /** filter the rows which have to be updated */
  where: BoardSnippets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type BoardSnippets_Var_Pop_Fields = {
  __typename?: 'BoardSnippets_var_pop_fields';
  application_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  last_changed_by_id?: Maybe<Scalars['Float']>;
  last_regression_reason_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  regression_count?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BoardSnippets" */
export type BoardSnippets_Var_Pop_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BoardSnippets_Var_Samp_Fields = {
  __typename?: 'BoardSnippets_var_samp_fields';
  application_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  last_changed_by_id?: Maybe<Scalars['Float']>;
  last_regression_reason_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  regression_count?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BoardSnippets" */
export type BoardSnippets_Var_Samp_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type BoardSnippets_Variance_Fields = {
  __typename?: 'BoardSnippets_variance_fields';
  application_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  last_changed_by_id?: Maybe<Scalars['Float']>;
  last_regression_reason_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  regression_count?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BoardSnippets" */
export type BoardSnippets_Variance_Order_By = {
  application_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_changed_by_id?: InputMaybe<Order_By>;
  last_regression_reason_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  regression_count?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "BullhornSyncStatus" */
export type BullhornSyncStatus = {
  __typename?: 'BullhornSyncStatus';
  action: Scalars['String'];
  aob_entity: Scalars['String'];
  aob_id: Scalars['Int'];
  aob_last_canonical_json_sha256?: Maybe<Scalars['String']>;
  aob_obj_after?: Maybe<Scalars['jsonb']>;
  aob_obj_before?: Maybe<Scalars['jsonb']>;
  bh_access_token?: Maybe<Scalars['String']>;
  bh_entity: Scalars['String'];
  bh_id: Scalars['Int'];
  bh_last_canonical_json_sha256?: Maybe<Scalars['String']>;
  bh_obj?: Maybe<Scalars['jsonb']>;
  bh_refresh_token?: Maybe<Scalars['String']>;
  bh_rest_token?: Maybe<Scalars['String']>;
  bh_rest_url: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  first_sync_type?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  sync_time: Scalars['timestamptz'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "BullhornSyncStatus" */
export type BullhornSyncStatusAob_Obj_AfterArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "BullhornSyncStatus" */
export type BullhornSyncStatusAob_Obj_BeforeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "BullhornSyncStatus" */
export type BullhornSyncStatusBh_ObjArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "BullhornSyncStatus" */
export type BullhornSyncStatus_Aggregate = {
  __typename?: 'BullhornSyncStatus_aggregate';
  aggregate?: Maybe<BullhornSyncStatus_Aggregate_Fields>;
  nodes: Array<BullhornSyncStatus>;
};

/** aggregate fields of "BullhornSyncStatus" */
export type BullhornSyncStatus_Aggregate_Fields = {
  __typename?: 'BullhornSyncStatus_aggregate_fields';
  avg?: Maybe<BullhornSyncStatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BullhornSyncStatus_Max_Fields>;
  min?: Maybe<BullhornSyncStatus_Min_Fields>;
  stddev?: Maybe<BullhornSyncStatus_Stddev_Fields>;
  stddev_pop?: Maybe<BullhornSyncStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BullhornSyncStatus_Stddev_Samp_Fields>;
  sum?: Maybe<BullhornSyncStatus_Sum_Fields>;
  var_pop?: Maybe<BullhornSyncStatus_Var_Pop_Fields>;
  var_samp?: Maybe<BullhornSyncStatus_Var_Samp_Fields>;
  variance?: Maybe<BullhornSyncStatus_Variance_Fields>;
};


/** aggregate fields of "BullhornSyncStatus" */
export type BullhornSyncStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BullhornSyncStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type BullhornSyncStatus_Append_Input = {
  aob_obj_after?: InputMaybe<Scalars['jsonb']>;
  aob_obj_before?: InputMaybe<Scalars['jsonb']>;
  bh_obj?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type BullhornSyncStatus_Avg_Fields = {
  __typename?: 'BullhornSyncStatus_avg_fields';
  aob_id?: Maybe<Scalars['Float']>;
  bh_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BullhornSyncStatus". All fields are combined with a logical 'AND'. */
export type BullhornSyncStatus_Bool_Exp = {
  _and?: InputMaybe<Array<BullhornSyncStatus_Bool_Exp>>;
  _not?: InputMaybe<BullhornSyncStatus_Bool_Exp>;
  _or?: InputMaybe<Array<BullhornSyncStatus_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  aob_entity?: InputMaybe<String_Comparison_Exp>;
  aob_id?: InputMaybe<Int_Comparison_Exp>;
  aob_last_canonical_json_sha256?: InputMaybe<String_Comparison_Exp>;
  aob_obj_after?: InputMaybe<Jsonb_Comparison_Exp>;
  aob_obj_before?: InputMaybe<Jsonb_Comparison_Exp>;
  bh_access_token?: InputMaybe<String_Comparison_Exp>;
  bh_entity?: InputMaybe<String_Comparison_Exp>;
  bh_id?: InputMaybe<Int_Comparison_Exp>;
  bh_last_canonical_json_sha256?: InputMaybe<String_Comparison_Exp>;
  bh_obj?: InputMaybe<Jsonb_Comparison_Exp>;
  bh_refresh_token?: InputMaybe<String_Comparison_Exp>;
  bh_rest_token?: InputMaybe<String_Comparison_Exp>;
  bh_rest_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_sync_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sync_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "BullhornSyncStatus" */
export enum BullhornSyncStatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  BullhornSyncStatusPkey = 'BullhornSyncStatus_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type BullhornSyncStatus_Delete_At_Path_Input = {
  aob_obj_after?: InputMaybe<Array<Scalars['String']>>;
  aob_obj_before?: InputMaybe<Array<Scalars['String']>>;
  bh_obj?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type BullhornSyncStatus_Delete_Elem_Input = {
  aob_obj_after?: InputMaybe<Scalars['Int']>;
  aob_obj_before?: InputMaybe<Scalars['Int']>;
  bh_obj?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type BullhornSyncStatus_Delete_Key_Input = {
  aob_obj_after?: InputMaybe<Scalars['String']>;
  aob_obj_before?: InputMaybe<Scalars['String']>;
  bh_obj?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "BullhornSyncStatus" */
export type BullhornSyncStatus_Inc_Input = {
  aob_id?: InputMaybe<Scalars['Int']>;
  bh_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "BullhornSyncStatus" */
export type BullhornSyncStatus_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  aob_entity?: InputMaybe<Scalars['String']>;
  aob_id?: InputMaybe<Scalars['Int']>;
  aob_last_canonical_json_sha256?: InputMaybe<Scalars['String']>;
  aob_obj_after?: InputMaybe<Scalars['jsonb']>;
  aob_obj_before?: InputMaybe<Scalars['jsonb']>;
  bh_access_token?: InputMaybe<Scalars['String']>;
  bh_entity?: InputMaybe<Scalars['String']>;
  bh_id?: InputMaybe<Scalars['Int']>;
  bh_last_canonical_json_sha256?: InputMaybe<Scalars['String']>;
  bh_obj?: InputMaybe<Scalars['jsonb']>;
  bh_refresh_token?: InputMaybe<Scalars['String']>;
  bh_rest_token?: InputMaybe<Scalars['String']>;
  bh_rest_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_sync_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  sync_time?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BullhornSyncStatus_Max_Fields = {
  __typename?: 'BullhornSyncStatus_max_fields';
  action?: Maybe<Scalars['String']>;
  aob_entity?: Maybe<Scalars['String']>;
  aob_id?: Maybe<Scalars['Int']>;
  aob_last_canonical_json_sha256?: Maybe<Scalars['String']>;
  bh_access_token?: Maybe<Scalars['String']>;
  bh_entity?: Maybe<Scalars['String']>;
  bh_id?: Maybe<Scalars['Int']>;
  bh_last_canonical_json_sha256?: Maybe<Scalars['String']>;
  bh_refresh_token?: Maybe<Scalars['String']>;
  bh_rest_token?: Maybe<Scalars['String']>;
  bh_rest_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_sync_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sync_time?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type BullhornSyncStatus_Min_Fields = {
  __typename?: 'BullhornSyncStatus_min_fields';
  action?: Maybe<Scalars['String']>;
  aob_entity?: Maybe<Scalars['String']>;
  aob_id?: Maybe<Scalars['Int']>;
  aob_last_canonical_json_sha256?: Maybe<Scalars['String']>;
  bh_access_token?: Maybe<Scalars['String']>;
  bh_entity?: Maybe<Scalars['String']>;
  bh_id?: Maybe<Scalars['Int']>;
  bh_last_canonical_json_sha256?: Maybe<Scalars['String']>;
  bh_refresh_token?: Maybe<Scalars['String']>;
  bh_rest_token?: Maybe<Scalars['String']>;
  bh_rest_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_sync_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sync_time?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "BullhornSyncStatus" */
export type BullhornSyncStatus_Mutation_Response = {
  __typename?: 'BullhornSyncStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BullhornSyncStatus>;
};

/** on_conflict condition type for table "BullhornSyncStatus" */
export type BullhornSyncStatus_On_Conflict = {
  constraint: BullhornSyncStatus_Constraint;
  update_columns?: Array<BullhornSyncStatus_Update_Column>;
  where?: InputMaybe<BullhornSyncStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "BullhornSyncStatus". */
export type BullhornSyncStatus_Order_By = {
  action?: InputMaybe<Order_By>;
  aob_entity?: InputMaybe<Order_By>;
  aob_id?: InputMaybe<Order_By>;
  aob_last_canonical_json_sha256?: InputMaybe<Order_By>;
  aob_obj_after?: InputMaybe<Order_By>;
  aob_obj_before?: InputMaybe<Order_By>;
  bh_access_token?: InputMaybe<Order_By>;
  bh_entity?: InputMaybe<Order_By>;
  bh_id?: InputMaybe<Order_By>;
  bh_last_canonical_json_sha256?: InputMaybe<Order_By>;
  bh_obj?: InputMaybe<Order_By>;
  bh_refresh_token?: InputMaybe<Order_By>;
  bh_rest_token?: InputMaybe<Order_By>;
  bh_rest_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_sync_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sync_time?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BullhornSyncStatus */
export type BullhornSyncStatus_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type BullhornSyncStatus_Prepend_Input = {
  aob_obj_after?: InputMaybe<Scalars['jsonb']>;
  aob_obj_before?: InputMaybe<Scalars['jsonb']>;
  bh_obj?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "BullhornSyncStatus" */
export enum BullhornSyncStatus_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  AobEntity = 'aob_entity',
  /** column name */
  AobId = 'aob_id',
  /** column name */
  AobLastCanonicalJsonSha256 = 'aob_last_canonical_json_sha256',
  /** column name */
  AobObjAfter = 'aob_obj_after',
  /** column name */
  AobObjBefore = 'aob_obj_before',
  /** column name */
  BhAccessToken = 'bh_access_token',
  /** column name */
  BhEntity = 'bh_entity',
  /** column name */
  BhId = 'bh_id',
  /** column name */
  BhLastCanonicalJsonSha256 = 'bh_last_canonical_json_sha256',
  /** column name */
  BhObj = 'bh_obj',
  /** column name */
  BhRefreshToken = 'bh_refresh_token',
  /** column name */
  BhRestToken = 'bh_rest_token',
  /** column name */
  BhRestUrl = 'bh_rest_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstSyncType = 'first_sync_type',
  /** column name */
  Id = 'id',
  /** column name */
  SyncTime = 'sync_time',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "BullhornSyncStatus" */
export type BullhornSyncStatus_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  aob_entity?: InputMaybe<Scalars['String']>;
  aob_id?: InputMaybe<Scalars['Int']>;
  aob_last_canonical_json_sha256?: InputMaybe<Scalars['String']>;
  aob_obj_after?: InputMaybe<Scalars['jsonb']>;
  aob_obj_before?: InputMaybe<Scalars['jsonb']>;
  bh_access_token?: InputMaybe<Scalars['String']>;
  bh_entity?: InputMaybe<Scalars['String']>;
  bh_id?: InputMaybe<Scalars['Int']>;
  bh_last_canonical_json_sha256?: InputMaybe<Scalars['String']>;
  bh_obj?: InputMaybe<Scalars['jsonb']>;
  bh_refresh_token?: InputMaybe<Scalars['String']>;
  bh_rest_token?: InputMaybe<Scalars['String']>;
  bh_rest_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_sync_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  sync_time?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type BullhornSyncStatus_Stddev_Fields = {
  __typename?: 'BullhornSyncStatus_stddev_fields';
  aob_id?: Maybe<Scalars['Float']>;
  bh_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BullhornSyncStatus_Stddev_Pop_Fields = {
  __typename?: 'BullhornSyncStatus_stddev_pop_fields';
  aob_id?: Maybe<Scalars['Float']>;
  bh_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BullhornSyncStatus_Stddev_Samp_Fields = {
  __typename?: 'BullhornSyncStatus_stddev_samp_fields';
  aob_id?: Maybe<Scalars['Float']>;
  bh_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "BullhornSyncStatus" */
export type BullhornSyncStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BullhornSyncStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BullhornSyncStatus_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  aob_entity?: InputMaybe<Scalars['String']>;
  aob_id?: InputMaybe<Scalars['Int']>;
  aob_last_canonical_json_sha256?: InputMaybe<Scalars['String']>;
  aob_obj_after?: InputMaybe<Scalars['jsonb']>;
  aob_obj_before?: InputMaybe<Scalars['jsonb']>;
  bh_access_token?: InputMaybe<Scalars['String']>;
  bh_entity?: InputMaybe<Scalars['String']>;
  bh_id?: InputMaybe<Scalars['Int']>;
  bh_last_canonical_json_sha256?: InputMaybe<Scalars['String']>;
  bh_obj?: InputMaybe<Scalars['jsonb']>;
  bh_refresh_token?: InputMaybe<Scalars['String']>;
  bh_rest_token?: InputMaybe<Scalars['String']>;
  bh_rest_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_sync_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  sync_time?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type BullhornSyncStatus_Sum_Fields = {
  __typename?: 'BullhornSyncStatus_sum_fields';
  aob_id?: Maybe<Scalars['Int']>;
  bh_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "BullhornSyncStatus" */
export enum BullhornSyncStatus_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  AobEntity = 'aob_entity',
  /** column name */
  AobId = 'aob_id',
  /** column name */
  AobLastCanonicalJsonSha256 = 'aob_last_canonical_json_sha256',
  /** column name */
  AobObjAfter = 'aob_obj_after',
  /** column name */
  AobObjBefore = 'aob_obj_before',
  /** column name */
  BhAccessToken = 'bh_access_token',
  /** column name */
  BhEntity = 'bh_entity',
  /** column name */
  BhId = 'bh_id',
  /** column name */
  BhLastCanonicalJsonSha256 = 'bh_last_canonical_json_sha256',
  /** column name */
  BhObj = 'bh_obj',
  /** column name */
  BhRefreshToken = 'bh_refresh_token',
  /** column name */
  BhRestToken = 'bh_rest_token',
  /** column name */
  BhRestUrl = 'bh_rest_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstSyncType = 'first_sync_type',
  /** column name */
  Id = 'id',
  /** column name */
  SyncTime = 'sync_time',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BullhornSyncStatus_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<BullhornSyncStatus_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<BullhornSyncStatus_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<BullhornSyncStatus_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<BullhornSyncStatus_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BullhornSyncStatus_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<BullhornSyncStatus_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BullhornSyncStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: BullhornSyncStatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type BullhornSyncStatus_Var_Pop_Fields = {
  __typename?: 'BullhornSyncStatus_var_pop_fields';
  aob_id?: Maybe<Scalars['Float']>;
  bh_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BullhornSyncStatus_Var_Samp_Fields = {
  __typename?: 'BullhornSyncStatus_var_samp_fields';
  aob_id?: Maybe<Scalars['Float']>;
  bh_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BullhornSyncStatus_Variance_Fields = {
  __typename?: 'BullhornSyncStatus_variance_fields';
  aob_id?: Maybe<Scalars['Float']>;
  bh_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CalcEquationResults" */
export type CalcEquationResults = {
  __typename?: 'CalcEquationResults';
  /** An object relationship */
  Calc: Calcs;
  /** An object relationship */
  Equation: Equations;
  calc_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  equation_id: Scalars['Int'];
  id: Scalars['Int'];
  result: Scalars['float8'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "CalcEquationResults" */
export type CalcEquationResults_Aggregate = {
  __typename?: 'CalcEquationResults_aggregate';
  aggregate?: Maybe<CalcEquationResults_Aggregate_Fields>;
  nodes: Array<CalcEquationResults>;
};

export type CalcEquationResults_Aggregate_Bool_Exp = {
  avg?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp_Var_Samp>;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Avg = {
  arguments: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Corr = {
  arguments: CalcEquationResults_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Corr_Arguments = {
  X: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: CalcEquationResults_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Max = {
  arguments: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Min = {
  arguments: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Sum = {
  arguments: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type CalcEquationResults_Aggregate_Bool_Exp_Var_Samp = {
  arguments: CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcEquationResults_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "CalcEquationResults" */
export type CalcEquationResults_Aggregate_Fields = {
  __typename?: 'CalcEquationResults_aggregate_fields';
  avg?: Maybe<CalcEquationResults_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CalcEquationResults_Max_Fields>;
  min?: Maybe<CalcEquationResults_Min_Fields>;
  stddev?: Maybe<CalcEquationResults_Stddev_Fields>;
  stddev_pop?: Maybe<CalcEquationResults_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CalcEquationResults_Stddev_Samp_Fields>;
  sum?: Maybe<CalcEquationResults_Sum_Fields>;
  var_pop?: Maybe<CalcEquationResults_Var_Pop_Fields>;
  var_samp?: Maybe<CalcEquationResults_Var_Samp_Fields>;
  variance?: Maybe<CalcEquationResults_Variance_Fields>;
};


/** aggregate fields of "CalcEquationResults" */
export type CalcEquationResults_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CalcEquationResults" */
export type CalcEquationResults_Aggregate_Order_By = {
  avg?: InputMaybe<CalcEquationResults_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CalcEquationResults_Max_Order_By>;
  min?: InputMaybe<CalcEquationResults_Min_Order_By>;
  stddev?: InputMaybe<CalcEquationResults_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CalcEquationResults_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CalcEquationResults_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CalcEquationResults_Sum_Order_By>;
  var_pop?: InputMaybe<CalcEquationResults_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CalcEquationResults_Var_Samp_Order_By>;
  variance?: InputMaybe<CalcEquationResults_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CalcEquationResults" */
export type CalcEquationResults_Arr_Rel_Insert_Input = {
  data: Array<CalcEquationResults_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CalcEquationResults_On_Conflict>;
};

/** aggregate avg on columns */
export type CalcEquationResults_Avg_Fields = {
  __typename?: 'CalcEquationResults_avg_fields';
  calc_id?: Maybe<Scalars['Float']>;
  equation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Avg_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CalcEquationResults". All fields are combined with a logical 'AND'. */
export type CalcEquationResults_Bool_Exp = {
  Calc?: InputMaybe<Calcs_Bool_Exp>;
  Equation?: InputMaybe<Equations_Bool_Exp>;
  _and?: InputMaybe<Array<CalcEquationResults_Bool_Exp>>;
  _not?: InputMaybe<CalcEquationResults_Bool_Exp>;
  _or?: InputMaybe<Array<CalcEquationResults_Bool_Exp>>;
  calc_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  equation_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  result?: InputMaybe<Float8_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CalcEquationResults" */
export enum CalcEquationResults_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalcEquationResultsPkey = 'CalcEquationResults_pkey'
}

/** input type for incrementing numeric columns in table "CalcEquationResults" */
export type CalcEquationResults_Inc_Input = {
  calc_id?: InputMaybe<Scalars['Int']>;
  equation_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "CalcEquationResults" */
export type CalcEquationResults_Insert_Input = {
  Calc?: InputMaybe<Calcs_Obj_Rel_Insert_Input>;
  Equation?: InputMaybe<Equations_Obj_Rel_Insert_Input>;
  calc_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  equation_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['float8']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CalcEquationResults_Max_Fields = {
  __typename?: 'CalcEquationResults_max_fields';
  calc_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  equation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['float8']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Max_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CalcEquationResults_Min_Fields = {
  __typename?: 'CalcEquationResults_min_fields';
  calc_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  equation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['float8']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Min_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CalcEquationResults" */
export type CalcEquationResults_Mutation_Response = {
  __typename?: 'CalcEquationResults_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalcEquationResults>;
};

/** on_conflict condition type for table "CalcEquationResults" */
export type CalcEquationResults_On_Conflict = {
  constraint: CalcEquationResults_Constraint;
  update_columns?: Array<CalcEquationResults_Update_Column>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};

/** Ordering options when selecting data from "CalcEquationResults". */
export type CalcEquationResults_Order_By = {
  Calc?: InputMaybe<Calcs_Order_By>;
  Equation?: InputMaybe<Equations_Order_By>;
  calc_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CalcEquationResults */
export type CalcEquationResults_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column {
  /** column name */
  CalcId = 'calc_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EquationId = 'equation_id',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "CalcEquationResults_aggregate_bool_exp_avg_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** select "CalcEquationResults_aggregate_bool_exp_corr_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** select "CalcEquationResults_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** select "CalcEquationResults_aggregate_bool_exp_max_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** select "CalcEquationResults_aggregate_bool_exp_min_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** select "CalcEquationResults_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** select "CalcEquationResults_aggregate_bool_exp_sum_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** select "CalcEquationResults_aggregate_bool_exp_var_samp_arguments_columns" columns of table "CalcEquationResults" */
export enum CalcEquationResults_Select_Column_CalcEquationResults_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Result = 'result'
}

/** input type for updating data in table "CalcEquationResults" */
export type CalcEquationResults_Set_Input = {
  calc_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  equation_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['float8']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CalcEquationResults_Stddev_Fields = {
  __typename?: 'CalcEquationResults_stddev_fields';
  calc_id?: Maybe<Scalars['Float']>;
  equation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Stddev_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CalcEquationResults_Stddev_Pop_Fields = {
  __typename?: 'CalcEquationResults_stddev_pop_fields';
  calc_id?: Maybe<Scalars['Float']>;
  equation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Stddev_Pop_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CalcEquationResults_Stddev_Samp_Fields = {
  __typename?: 'CalcEquationResults_stddev_samp_fields';
  calc_id?: Maybe<Scalars['Float']>;
  equation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Stddev_Samp_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CalcEquationResults" */
export type CalcEquationResults_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CalcEquationResults_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CalcEquationResults_Stream_Cursor_Value_Input = {
  calc_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  equation_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['float8']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CalcEquationResults_Sum_Fields = {
  __typename?: 'CalcEquationResults_sum_fields';
  calc_id?: Maybe<Scalars['Int']>;
  equation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Sum_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** update columns of table "CalcEquationResults" */
export enum CalcEquationResults_Update_Column {
  /** column name */
  CalcId = 'calc_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EquationId = 'equation_id',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CalcEquationResults_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CalcEquationResults_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CalcEquationResults_Set_Input>;
  /** filter the rows which have to be updated */
  where: CalcEquationResults_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CalcEquationResults_Var_Pop_Fields = {
  __typename?: 'CalcEquationResults_var_pop_fields';
  calc_id?: Maybe<Scalars['Float']>;
  equation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Var_Pop_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CalcEquationResults_Var_Samp_Fields = {
  __typename?: 'CalcEquationResults_var_samp_fields';
  calc_id?: Maybe<Scalars['Float']>;
  equation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Var_Samp_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CalcEquationResults_Variance_Fields = {
  __typename?: 'CalcEquationResults_variance_fields';
  calc_id?: Maybe<Scalars['Float']>;
  equation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CalcEquationResults" */
export type CalcEquationResults_Variance_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  equation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
};

/** columns and relationships of "CalcFormValues" */
export type CalcFormValues = {
  __typename?: 'CalcFormValues';
  /** An object relationship */
  Calc: Calcs;
  /** An object relationship */
  CalculationVersionFormInputs?: Maybe<CalculationVersionFormInputs>;
  calc_id: Scalars['Int'];
  calc_version_form_input_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  is_number: Scalars['Boolean'];
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "CalcFormValues" */
export type CalcFormValues_Aggregate = {
  __typename?: 'CalcFormValues_aggregate';
  aggregate?: Maybe<CalcFormValues_Aggregate_Fields>;
  nodes: Array<CalcFormValues>;
};

export type CalcFormValues_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CalcFormValues_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CalcFormValues_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CalcFormValues_Aggregate_Bool_Exp_Count>;
};

export type CalcFormValues_Aggregate_Bool_Exp_Bool_And = {
  arguments: CalcFormValues_Select_Column_CalcFormValues_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcFormValues_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CalcFormValues_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CalcFormValues_Select_Column_CalcFormValues_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcFormValues_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CalcFormValues_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalcFormValues_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CalcFormValues" */
export type CalcFormValues_Aggregate_Fields = {
  __typename?: 'CalcFormValues_aggregate_fields';
  avg?: Maybe<CalcFormValues_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CalcFormValues_Max_Fields>;
  min?: Maybe<CalcFormValues_Min_Fields>;
  stddev?: Maybe<CalcFormValues_Stddev_Fields>;
  stddev_pop?: Maybe<CalcFormValues_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CalcFormValues_Stddev_Samp_Fields>;
  sum?: Maybe<CalcFormValues_Sum_Fields>;
  var_pop?: Maybe<CalcFormValues_Var_Pop_Fields>;
  var_samp?: Maybe<CalcFormValues_Var_Samp_Fields>;
  variance?: Maybe<CalcFormValues_Variance_Fields>;
};


/** aggregate fields of "CalcFormValues" */
export type CalcFormValues_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CalcFormValues" */
export type CalcFormValues_Aggregate_Order_By = {
  avg?: InputMaybe<CalcFormValues_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CalcFormValues_Max_Order_By>;
  min?: InputMaybe<CalcFormValues_Min_Order_By>;
  stddev?: InputMaybe<CalcFormValues_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CalcFormValues_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CalcFormValues_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CalcFormValues_Sum_Order_By>;
  var_pop?: InputMaybe<CalcFormValues_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CalcFormValues_Var_Samp_Order_By>;
  variance?: InputMaybe<CalcFormValues_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CalcFormValues" */
export type CalcFormValues_Arr_Rel_Insert_Input = {
  data: Array<CalcFormValues_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CalcFormValues_On_Conflict>;
};

/** aggregate avg on columns */
export type CalcFormValues_Avg_Fields = {
  __typename?: 'CalcFormValues_avg_fields';
  calc_id?: Maybe<Scalars['Float']>;
  calc_version_form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CalcFormValues" */
export type CalcFormValues_Avg_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CalcFormValues". All fields are combined with a logical 'AND'. */
export type CalcFormValues_Bool_Exp = {
  Calc?: InputMaybe<Calcs_Bool_Exp>;
  CalculationVersionFormInputs?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
  _and?: InputMaybe<Array<CalcFormValues_Bool_Exp>>;
  _not?: InputMaybe<CalcFormValues_Bool_Exp>;
  _or?: InputMaybe<Array<CalcFormValues_Bool_Exp>>;
  calc_id?: InputMaybe<Int_Comparison_Exp>;
  calc_version_form_input_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_number?: InputMaybe<Boolean_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CalcFormValues" */
export enum CalcFormValues_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalcFormValuesPkey = 'CalcFormValues_pkey'
}

/** input type for incrementing numeric columns in table "CalcFormValues" */
export type CalcFormValues_Inc_Input = {
  calc_id?: InputMaybe<Scalars['Int']>;
  calc_version_form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CalcFormValues" */
export type CalcFormValues_Insert_Input = {
  Calc?: InputMaybe<Calcs_Obj_Rel_Insert_Input>;
  CalculationVersionFormInputs?: InputMaybe<CalculationVersionFormInputs_Obj_Rel_Insert_Input>;
  calc_id?: InputMaybe<Scalars['Int']>;
  calc_version_form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_number?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CalcFormValues_Max_Fields = {
  __typename?: 'CalcFormValues_max_fields';
  calc_id?: Maybe<Scalars['Int']>;
  calc_version_form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CalcFormValues" */
export type CalcFormValues_Max_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CalcFormValues_Min_Fields = {
  __typename?: 'CalcFormValues_min_fields';
  calc_id?: Maybe<Scalars['Int']>;
  calc_version_form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "CalcFormValues" */
export type CalcFormValues_Min_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CalcFormValues" */
export type CalcFormValues_Mutation_Response = {
  __typename?: 'CalcFormValues_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalcFormValues>;
};

/** on_conflict condition type for table "CalcFormValues" */
export type CalcFormValues_On_Conflict = {
  constraint: CalcFormValues_Constraint;
  update_columns?: Array<CalcFormValues_Update_Column>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};

/** Ordering options when selecting data from "CalcFormValues". */
export type CalcFormValues_Order_By = {
  Calc?: InputMaybe<Calcs_Order_By>;
  CalculationVersionFormInputs?: InputMaybe<CalculationVersionFormInputs_Order_By>;
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_number?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CalcFormValues */
export type CalcFormValues_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "CalcFormValues" */
export enum CalcFormValues_Select_Column {
  /** column name */
  CalcId = 'calc_id',
  /** column name */
  CalcVersionFormInputId = 'calc_version_form_input_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsNumber = 'is_number',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

/** select "CalcFormValues_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CalcFormValues" */
export enum CalcFormValues_Select_Column_CalcFormValues_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsNumber = 'is_number'
}

/** select "CalcFormValues_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CalcFormValues" */
export enum CalcFormValues_Select_Column_CalcFormValues_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsNumber = 'is_number'
}

/** input type for updating data in table "CalcFormValues" */
export type CalcFormValues_Set_Input = {
  calc_id?: InputMaybe<Scalars['Int']>;
  calc_version_form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_number?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CalcFormValues_Stddev_Fields = {
  __typename?: 'CalcFormValues_stddev_fields';
  calc_id?: Maybe<Scalars['Float']>;
  calc_version_form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CalcFormValues" */
export type CalcFormValues_Stddev_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CalcFormValues_Stddev_Pop_Fields = {
  __typename?: 'CalcFormValues_stddev_pop_fields';
  calc_id?: Maybe<Scalars['Float']>;
  calc_version_form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CalcFormValues" */
export type CalcFormValues_Stddev_Pop_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CalcFormValues_Stddev_Samp_Fields = {
  __typename?: 'CalcFormValues_stddev_samp_fields';
  calc_id?: Maybe<Scalars['Float']>;
  calc_version_form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CalcFormValues" */
export type CalcFormValues_Stddev_Samp_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CalcFormValues" */
export type CalcFormValues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CalcFormValues_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CalcFormValues_Stream_Cursor_Value_Input = {
  calc_id?: InputMaybe<Scalars['Int']>;
  calc_version_form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_number?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type CalcFormValues_Sum_Fields = {
  __typename?: 'CalcFormValues_sum_fields';
  calc_id?: Maybe<Scalars['Int']>;
  calc_version_form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CalcFormValues" */
export type CalcFormValues_Sum_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "CalcFormValues" */
export enum CalcFormValues_Update_Column {
  /** column name */
  CalcId = 'calc_id',
  /** column name */
  CalcVersionFormInputId = 'calc_version_form_input_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsNumber = 'is_number',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

export type CalcFormValues_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CalcFormValues_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CalcFormValues_Set_Input>;
  /** filter the rows which have to be updated */
  where: CalcFormValues_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CalcFormValues_Var_Pop_Fields = {
  __typename?: 'CalcFormValues_var_pop_fields';
  calc_id?: Maybe<Scalars['Float']>;
  calc_version_form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CalcFormValues" */
export type CalcFormValues_Var_Pop_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CalcFormValues_Var_Samp_Fields = {
  __typename?: 'CalcFormValues_var_samp_fields';
  calc_id?: Maybe<Scalars['Float']>;
  calc_version_form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CalcFormValues" */
export type CalcFormValues_Var_Samp_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CalcFormValues_Variance_Fields = {
  __typename?: 'CalcFormValues_variance_fields';
  calc_id?: Maybe<Scalars['Float']>;
  calc_version_form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CalcFormValues" */
export type CalcFormValues_Variance_Order_By = {
  calc_id?: InputMaybe<Order_By>;
  calc_version_form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** This is the new calculations feature table */
export type Calcs = {
  __typename?: 'Calcs';
  /** An object relationship */
  Author?: Maybe<Identities>;
  /** An array relationship */
  CalcEquationResults: Array<CalcEquationResults>;
  /** An aggregate relationship */
  CalcEquationResults_aggregate: CalcEquationResults_Aggregate;
  /** An array relationship */
  CalcFormValues: Array<CalcFormValues>;
  /** An aggregate relationship */
  CalcFormValues_aggregate: CalcFormValues_Aggregate;
  /** An object relationship */
  CalculationTemplateVersion?: Maybe<CalculationTemplateVersions>;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  author_id?: Maybe<Scalars['Int']>;
  calculation_template_version_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deleted: Scalars['Boolean'];
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  job_id?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** This is the new calculations feature table */
export type CalcsCalcEquationResultsArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


/** This is the new calculations feature table */
export type CalcsCalcEquationResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


/** This is the new calculations feature table */
export type CalcsCalcFormValuesArgs = {
  distinct_on?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcFormValues_Order_By>>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};


/** This is the new calculations feature table */
export type CalcsCalcFormValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcFormValues_Order_By>>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};

/** aggregated selection of "Calcs" */
export type Calcs_Aggregate = {
  __typename?: 'Calcs_aggregate';
  aggregate?: Maybe<Calcs_Aggregate_Fields>;
  nodes: Array<Calcs>;
};

/** aggregate fields of "Calcs" */
export type Calcs_Aggregate_Fields = {
  __typename?: 'Calcs_aggregate_fields';
  avg?: Maybe<Calcs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Calcs_Max_Fields>;
  min?: Maybe<Calcs_Min_Fields>;
  stddev?: Maybe<Calcs_Stddev_Fields>;
  stddev_pop?: Maybe<Calcs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Calcs_Stddev_Samp_Fields>;
  sum?: Maybe<Calcs_Sum_Fields>;
  var_pop?: Maybe<Calcs_Var_Pop_Fields>;
  var_samp?: Maybe<Calcs_Var_Samp_Fields>;
  variance?: Maybe<Calcs_Variance_Fields>;
};


/** aggregate fields of "Calcs" */
export type Calcs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calcs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Calcs_Avg_Fields = {
  __typename?: 'Calcs_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Calcs". All fields are combined with a logical 'AND'. */
export type Calcs_Bool_Exp = {
  Author?: InputMaybe<Identities_Bool_Exp>;
  CalcEquationResults?: InputMaybe<CalcEquationResults_Bool_Exp>;
  CalcEquationResults_aggregate?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp>;
  CalcFormValues?: InputMaybe<CalcFormValues_Bool_Exp>;
  CalcFormValues_aggregate?: InputMaybe<CalcFormValues_Aggregate_Bool_Exp>;
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<Calcs_Bool_Exp>>;
  _not?: InputMaybe<Calcs_Bool_Exp>;
  _or?: InputMaybe<Array<Calcs_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  calculation_template_version_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  primary?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Calcs" */
export enum Calcs_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalcsPkey = 'Calcs_pkey'
}

/** input type for incrementing numeric columns in table "Calcs" */
export type Calcs_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Calcs" */
export type Calcs_Insert_Input = {
  Author?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  CalcEquationResults?: InputMaybe<CalcEquationResults_Arr_Rel_Insert_Input>;
  CalcFormValues?: InputMaybe<CalcFormValues_Arr_Rel_Insert_Input>;
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  author_id?: InputMaybe<Scalars['Int']>;
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Calcs_Max_Fields = {
  __typename?: 'Calcs_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  calculation_template_version_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Calcs_Min_Fields = {
  __typename?: 'Calcs_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  calculation_template_version_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Calcs" */
export type Calcs_Mutation_Response = {
  __typename?: 'Calcs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calcs>;
};

/** input type for inserting object relation for remote table "Calcs" */
export type Calcs_Obj_Rel_Insert_Input = {
  data: Calcs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Calcs_On_Conflict>;
};

/** on_conflict condition type for table "Calcs" */
export type Calcs_On_Conflict = {
  constraint: Calcs_Constraint;
  update_columns?: Array<Calcs_Update_Column>;
  where?: InputMaybe<Calcs_Bool_Exp>;
};

/** Ordering options when selecting data from "Calcs". */
export type Calcs_Order_By = {
  Author?: InputMaybe<Identities_Order_By>;
  CalcEquationResults_aggregate?: InputMaybe<CalcEquationResults_Aggregate_Order_By>;
  CalcFormValues_aggregate?: InputMaybe<CalcFormValues_Aggregate_Order_By>;
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  author_id?: InputMaybe<Order_By>;
  calculation_template_version_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  primary?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Calcs */
export type Calcs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Calcs" */
export enum Calcs_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CalculationTemplateVersionId = 'calculation_template_version_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "Calcs" */
export type Calcs_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Calcs_Stddev_Fields = {
  __typename?: 'Calcs_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Calcs_Stddev_Pop_Fields = {
  __typename?: 'Calcs_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Calcs_Stddev_Samp_Fields = {
  __typename?: 'Calcs_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Calcs" */
export type Calcs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calcs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calcs_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Calcs_Sum_Fields = {
  __typename?: 'Calcs_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  calculation_template_version_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "Calcs" */
export enum Calcs_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CalculationTemplateVersionId = 'calculation_template_version_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Calcs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Calcs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calcs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calcs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Calcs_Var_Pop_Fields = {
  __typename?: 'Calcs_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Calcs_Var_Samp_Fields = {
  __typename?: 'Calcs_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Calcs_Variance_Fields = {
  __typename?: 'Calcs_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CalculationTemplateVersions" */
export type CalculationTemplateVersions = {
  __typename?: 'CalculationTemplateVersions';
  /** An object relationship */
  CalculationTemplate: CalculationTemplates;
  /** An array relationship */
  CalculationVersionFormInputs: Array<CalculationVersionFormInputs>;
  /** An aggregate relationship */
  CalculationVersionFormInputs_aggregate: CalculationVersionFormInputs_Aggregate;
  /** An array relationship */
  Equations: Array<Equations>;
  /** An aggregate relationship */
  Equations_aggregate: Equations_Aggregate;
  active: Scalars['Boolean'];
  calc_template_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  version_number: Scalars['Int'];
};


/** columns and relationships of "CalculationTemplateVersions" */
export type CalculationTemplateVersionsCalculationVersionFormInputsArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


/** columns and relationships of "CalculationTemplateVersions" */
export type CalculationTemplateVersionsCalculationVersionFormInputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


/** columns and relationships of "CalculationTemplateVersions" */
export type CalculationTemplateVersionsEquationsArgs = {
  distinct_on?: InputMaybe<Array<Equations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equations_Order_By>>;
  where?: InputMaybe<Equations_Bool_Exp>;
};


/** columns and relationships of "CalculationTemplateVersions" */
export type CalculationTemplateVersionsEquations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Equations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equations_Order_By>>;
  where?: InputMaybe<Equations_Bool_Exp>;
};

/** aggregated selection of "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Aggregate = {
  __typename?: 'CalculationTemplateVersions_aggregate';
  aggregate?: Maybe<CalculationTemplateVersions_Aggregate_Fields>;
  nodes: Array<CalculationTemplateVersions>;
};

export type CalculationTemplateVersions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CalculationTemplateVersions_Aggregate_Bool_Exp_Count>;
};

export type CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_And = {
  arguments: CalculationTemplateVersions_Select_Column_CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CalculationTemplateVersions_Select_Column_CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CalculationTemplateVersions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Aggregate_Fields = {
  __typename?: 'CalculationTemplateVersions_aggregate_fields';
  avg?: Maybe<CalculationTemplateVersions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CalculationTemplateVersions_Max_Fields>;
  min?: Maybe<CalculationTemplateVersions_Min_Fields>;
  stddev?: Maybe<CalculationTemplateVersions_Stddev_Fields>;
  stddev_pop?: Maybe<CalculationTemplateVersions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CalculationTemplateVersions_Stddev_Samp_Fields>;
  sum?: Maybe<CalculationTemplateVersions_Sum_Fields>;
  var_pop?: Maybe<CalculationTemplateVersions_Var_Pop_Fields>;
  var_samp?: Maybe<CalculationTemplateVersions_Var_Samp_Fields>;
  variance?: Maybe<CalculationTemplateVersions_Variance_Fields>;
};


/** aggregate fields of "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Aggregate_Order_By = {
  avg?: InputMaybe<CalculationTemplateVersions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CalculationTemplateVersions_Max_Order_By>;
  min?: InputMaybe<CalculationTemplateVersions_Min_Order_By>;
  stddev?: InputMaybe<CalculationTemplateVersions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CalculationTemplateVersions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CalculationTemplateVersions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CalculationTemplateVersions_Sum_Order_By>;
  var_pop?: InputMaybe<CalculationTemplateVersions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CalculationTemplateVersions_Var_Samp_Order_By>;
  variance?: InputMaybe<CalculationTemplateVersions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Arr_Rel_Insert_Input = {
  data: Array<CalculationTemplateVersions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CalculationTemplateVersions_On_Conflict>;
};

/** aggregate avg on columns */
export type CalculationTemplateVersions_Avg_Fields = {
  __typename?: 'CalculationTemplateVersions_avg_fields';
  calc_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Avg_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CalculationTemplateVersions". All fields are combined with a logical 'AND'. */
export type CalculationTemplateVersions_Bool_Exp = {
  CalculationTemplate?: InputMaybe<CalculationTemplates_Bool_Exp>;
  CalculationVersionFormInputs?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
  CalculationVersionFormInputs_aggregate?: InputMaybe<CalculationVersionFormInputs_Aggregate_Bool_Exp>;
  Equations?: InputMaybe<Equations_Bool_Exp>;
  Equations_aggregate?: InputMaybe<Equations_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<CalculationTemplateVersions_Bool_Exp>>;
  _not?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  _or?: InputMaybe<Array<CalculationTemplateVersions_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  calc_template_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  version_number?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "CalculationTemplateVersions" */
export enum CalculationTemplateVersions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalcTemplateVersionsPkey = 'CalcTemplateVersions_pkey'
}

/** input type for incrementing numeric columns in table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Inc_Input = {
  calc_template_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Insert_Input = {
  CalculationTemplate?: InputMaybe<CalculationTemplates_Obj_Rel_Insert_Input>;
  CalculationVersionFormInputs?: InputMaybe<CalculationVersionFormInputs_Arr_Rel_Insert_Input>;
  Equations?: InputMaybe<Equations_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  calc_template_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CalculationTemplateVersions_Max_Fields = {
  __typename?: 'CalculationTemplateVersions_max_fields';
  calc_template_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  version_number?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Max_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CalculationTemplateVersions_Min_Fields = {
  __typename?: 'CalculationTemplateVersions_min_fields';
  calc_template_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  version_number?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Min_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Mutation_Response = {
  __typename?: 'CalculationTemplateVersions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalculationTemplateVersions>;
};

/** input type for inserting object relation for remote table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Obj_Rel_Insert_Input = {
  data: CalculationTemplateVersions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CalculationTemplateVersions_On_Conflict>;
};

/** on_conflict condition type for table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_On_Conflict = {
  constraint: CalculationTemplateVersions_Constraint;
  update_columns?: Array<CalculationTemplateVersions_Update_Column>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};

/** Ordering options when selecting data from "CalculationTemplateVersions". */
export type CalculationTemplateVersions_Order_By = {
  CalculationTemplate?: InputMaybe<CalculationTemplates_Order_By>;
  CalculationVersionFormInputs_aggregate?: InputMaybe<CalculationVersionFormInputs_Aggregate_Order_By>;
  Equations_aggregate?: InputMaybe<Equations_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  calc_template_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CalculationTemplateVersions */
export type CalculationTemplateVersions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "CalculationTemplateVersions" */
export enum CalculationTemplateVersions_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CalcTemplateId = 'calc_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VersionNumber = 'version_number'
}

/** select "CalculationTemplateVersions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CalculationTemplateVersions" */
export enum CalculationTemplateVersions_Select_Column_CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "CalculationTemplateVersions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CalculationTemplateVersions" */
export enum CalculationTemplateVersions_Select_Column_CalculationTemplateVersions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  calc_template_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CalculationTemplateVersions_Stddev_Fields = {
  __typename?: 'CalculationTemplateVersions_stddev_fields';
  calc_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Stddev_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CalculationTemplateVersions_Stddev_Pop_Fields = {
  __typename?: 'CalculationTemplateVersions_stddev_pop_fields';
  calc_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Stddev_Pop_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CalculationTemplateVersions_Stddev_Samp_Fields = {
  __typename?: 'CalculationTemplateVersions_stddev_samp_fields';
  calc_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Stddev_Samp_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CalculationTemplateVersions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CalculationTemplateVersions_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  calc_template_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CalculationTemplateVersions_Sum_Fields = {
  __typename?: 'CalculationTemplateVersions_sum_fields';
  calc_template_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
  version_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Sum_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** update columns of table "CalculationTemplateVersions" */
export enum CalculationTemplateVersions_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CalcTemplateId = 'calc_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VersionNumber = 'version_number'
}

export type CalculationTemplateVersions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CalculationTemplateVersions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CalculationTemplateVersions_Set_Input>;
  /** filter the rows which have to be updated */
  where: CalculationTemplateVersions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CalculationTemplateVersions_Var_Pop_Fields = {
  __typename?: 'CalculationTemplateVersions_var_pop_fields';
  calc_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Var_Pop_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CalculationTemplateVersions_Var_Samp_Fields = {
  __typename?: 'CalculationTemplateVersions_var_samp_fields';
  calc_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Var_Samp_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CalculationTemplateVersions_Variance_Fields = {
  __typename?: 'CalculationTemplateVersions_variance_fields';
  calc_template_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
  version_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CalculationTemplateVersions" */
export type CalculationTemplateVersions_Variance_Order_By = {
  calc_template_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** columns and relationships of "CalculationTemplates" */
export type CalculationTemplates = {
  __typename?: 'CalculationTemplates';
  /** An array relationship */
  CalculationTemplateVersions: Array<CalculationTemplateVersions>;
  /** An aggregate relationship */
  CalculationTemplateVersions_aggregate: CalculationTemplateVersions_Aggregate;
  /** An object relationship */
  Company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "CalculationTemplates" */
export type CalculationTemplatesCalculationTemplateVersionsArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplateVersions_Order_By>>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};


/** columns and relationships of "CalculationTemplates" */
export type CalculationTemplatesCalculationTemplateVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplateVersions_Order_By>>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};

/** aggregated selection of "CalculationTemplates" */
export type CalculationTemplates_Aggregate = {
  __typename?: 'CalculationTemplates_aggregate';
  aggregate?: Maybe<CalculationTemplates_Aggregate_Fields>;
  nodes: Array<CalculationTemplates>;
};

/** aggregate fields of "CalculationTemplates" */
export type CalculationTemplates_Aggregate_Fields = {
  __typename?: 'CalculationTemplates_aggregate_fields';
  avg?: Maybe<CalculationTemplates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CalculationTemplates_Max_Fields>;
  min?: Maybe<CalculationTemplates_Min_Fields>;
  stddev?: Maybe<CalculationTemplates_Stddev_Fields>;
  stddev_pop?: Maybe<CalculationTemplates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CalculationTemplates_Stddev_Samp_Fields>;
  sum?: Maybe<CalculationTemplates_Sum_Fields>;
  var_pop?: Maybe<CalculationTemplates_Var_Pop_Fields>;
  var_samp?: Maybe<CalculationTemplates_Var_Samp_Fields>;
  variance?: Maybe<CalculationTemplates_Variance_Fields>;
};


/** aggregate fields of "CalculationTemplates" */
export type CalculationTemplates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalculationTemplates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CalculationTemplates_Avg_Fields = {
  __typename?: 'CalculationTemplates_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CalculationTemplates". All fields are combined with a logical 'AND'. */
export type CalculationTemplates_Bool_Exp = {
  CalculationTemplateVersions?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  CalculationTemplateVersions_aggregate?: InputMaybe<CalculationTemplateVersions_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  _and?: InputMaybe<Array<CalculationTemplates_Bool_Exp>>;
  _not?: InputMaybe<CalculationTemplates_Bool_Exp>;
  _or?: InputMaybe<Array<CalculationTemplates_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "CalculationTemplates" */
export enum CalculationTemplates_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalculationTemplatesPkey = 'CalculationTemplates_pkey'
}

/** input type for incrementing numeric columns in table "CalculationTemplates" */
export type CalculationTemplates_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CalculationTemplates" */
export type CalculationTemplates_Insert_Input = {
  CalculationTemplateVersions?: InputMaybe<CalculationTemplateVersions_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CalculationTemplates_Max_Fields = {
  __typename?: 'CalculationTemplates_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CalculationTemplates_Min_Fields = {
  __typename?: 'CalculationTemplates_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CalculationTemplates" */
export type CalculationTemplates_Mutation_Response = {
  __typename?: 'CalculationTemplates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalculationTemplates>;
};

/** input type for inserting object relation for remote table "CalculationTemplates" */
export type CalculationTemplates_Obj_Rel_Insert_Input = {
  data: CalculationTemplates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CalculationTemplates_On_Conflict>;
};

/** on_conflict condition type for table "CalculationTemplates" */
export type CalculationTemplates_On_Conflict = {
  constraint: CalculationTemplates_Constraint;
  update_columns?: Array<CalculationTemplates_Update_Column>;
  where?: InputMaybe<CalculationTemplates_Bool_Exp>;
};

/** Ordering options when selecting data from "CalculationTemplates". */
export type CalculationTemplates_Order_By = {
  CalculationTemplateVersions_aggregate?: InputMaybe<CalculationTemplateVersions_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CalculationTemplates */
export type CalculationTemplates_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "CalculationTemplates" */
export enum CalculationTemplates_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "CalculationTemplates" */
export type CalculationTemplates_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CalculationTemplates_Stddev_Fields = {
  __typename?: 'CalculationTemplates_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CalculationTemplates_Stddev_Pop_Fields = {
  __typename?: 'CalculationTemplates_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CalculationTemplates_Stddev_Samp_Fields = {
  __typename?: 'CalculationTemplates_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CalculationTemplates" */
export type CalculationTemplates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CalculationTemplates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CalculationTemplates_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CalculationTemplates_Sum_Fields = {
  __typename?: 'CalculationTemplates_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "CalculationTemplates" */
export enum CalculationTemplates_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type CalculationTemplates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CalculationTemplates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CalculationTemplates_Set_Input>;
  /** filter the rows which have to be updated */
  where: CalculationTemplates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CalculationTemplates_Var_Pop_Fields = {
  __typename?: 'CalculationTemplates_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CalculationTemplates_Var_Samp_Fields = {
  __typename?: 'CalculationTemplates_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CalculationTemplates_Variance_Fields = {
  __typename?: 'CalculationTemplates_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs = {
  __typename?: 'CalculationVersionFormInputs';
  /** An object relationship */
  CalculationTemplateVersion: CalculationTemplateVersions;
  /** An object relationship */
  FormInput: FormInputs;
  analytics?: Maybe<Scalars['jsonb']>;
  calculation_template_version_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  form_input_id: Scalars['Int'];
  id: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "CalculationVersionFormInputs" */
export type CalculationVersionFormInputsAnalyticsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Aggregate = {
  __typename?: 'CalculationVersionFormInputs_aggregate';
  aggregate?: Maybe<CalculationVersionFormInputs_Aggregate_Fields>;
  nodes: Array<CalculationVersionFormInputs>;
};

export type CalculationVersionFormInputs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CalculationVersionFormInputs_Aggregate_Bool_Exp_Count>;
};

export type CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_And = {
  arguments: CalculationVersionFormInputs_Select_Column_CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CalculationVersionFormInputs_Select_Column_CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CalculationVersionFormInputs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Aggregate_Fields = {
  __typename?: 'CalculationVersionFormInputs_aggregate_fields';
  avg?: Maybe<CalculationVersionFormInputs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CalculationVersionFormInputs_Max_Fields>;
  min?: Maybe<CalculationVersionFormInputs_Min_Fields>;
  stddev?: Maybe<CalculationVersionFormInputs_Stddev_Fields>;
  stddev_pop?: Maybe<CalculationVersionFormInputs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CalculationVersionFormInputs_Stddev_Samp_Fields>;
  sum?: Maybe<CalculationVersionFormInputs_Sum_Fields>;
  var_pop?: Maybe<CalculationVersionFormInputs_Var_Pop_Fields>;
  var_samp?: Maybe<CalculationVersionFormInputs_Var_Samp_Fields>;
  variance?: Maybe<CalculationVersionFormInputs_Variance_Fields>;
};


/** aggregate fields of "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Aggregate_Order_By = {
  avg?: InputMaybe<CalculationVersionFormInputs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CalculationVersionFormInputs_Max_Order_By>;
  min?: InputMaybe<CalculationVersionFormInputs_Min_Order_By>;
  stddev?: InputMaybe<CalculationVersionFormInputs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CalculationVersionFormInputs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CalculationVersionFormInputs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CalculationVersionFormInputs_Sum_Order_By>;
  var_pop?: InputMaybe<CalculationVersionFormInputs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CalculationVersionFormInputs_Var_Samp_Order_By>;
  variance?: InputMaybe<CalculationVersionFormInputs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CalculationVersionFormInputs_Append_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Arr_Rel_Insert_Input = {
  data: Array<CalculationVersionFormInputs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CalculationVersionFormInputs_On_Conflict>;
};

/** aggregate avg on columns */
export type CalculationVersionFormInputs_Avg_Fields = {
  __typename?: 'CalculationVersionFormInputs_avg_fields';
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Avg_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CalculationVersionFormInputs". All fields are combined with a logical 'AND'. */
export type CalculationVersionFormInputs_Bool_Exp = {
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  FormInput?: InputMaybe<FormInputs_Bool_Exp>;
  _and?: InputMaybe<Array<CalculationVersionFormInputs_Bool_Exp>>;
  _not?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
  _or?: InputMaybe<Array<CalculationVersionFormInputs_Bool_Exp>>;
  analytics?: InputMaybe<Jsonb_Comparison_Exp>;
  calculation_template_version_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  form_input_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  required?: InputMaybe<Boolean_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "CalculationVersionFormInputs" */
export enum CalculationVersionFormInputs_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalculationTemplateFormInputsPkey = 'CalculationTemplateFormInputs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CalculationVersionFormInputs_Delete_At_Path_Input = {
  analytics?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CalculationVersionFormInputs_Delete_Elem_Input = {
  analytics?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CalculationVersionFormInputs_Delete_Key_Input = {
  analytics?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Inc_Input = {
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Insert_Input = {
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Obj_Rel_Insert_Input>;
  FormInput?: InputMaybe<FormInputs_Obj_Rel_Insert_Input>;
  analytics?: InputMaybe<Scalars['jsonb']>;
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CalculationVersionFormInputs_Max_Fields = {
  __typename?: 'CalculationVersionFormInputs_max_fields';
  calculation_template_version_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Max_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CalculationVersionFormInputs_Min_Fields = {
  __typename?: 'CalculationVersionFormInputs_min_fields';
  calculation_template_version_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Min_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Mutation_Response = {
  __typename?: 'CalculationVersionFormInputs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CalculationVersionFormInputs>;
};

/** input type for inserting object relation for remote table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Obj_Rel_Insert_Input = {
  data: CalculationVersionFormInputs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CalculationVersionFormInputs_On_Conflict>;
};

/** on_conflict condition type for table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_On_Conflict = {
  constraint: CalculationVersionFormInputs_Constraint;
  update_columns?: Array<CalculationVersionFormInputs_Update_Column>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};

/** Ordering options when selecting data from "CalculationVersionFormInputs". */
export type CalculationVersionFormInputs_Order_By = {
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Order_By>;
  FormInput?: InputMaybe<FormInputs_Order_By>;
  analytics?: InputMaybe<Order_By>;
  calculation_template_version_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  required?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CalculationVersionFormInputs */
export type CalculationVersionFormInputs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CalculationVersionFormInputs_Prepend_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "CalculationVersionFormInputs" */
export enum CalculationVersionFormInputs_Select_Column {
  /** column name */
  Analytics = 'analytics',
  /** column name */
  CalculationTemplateVersionId = 'calculation_template_version_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormInputId = 'form_input_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Required = 'required',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "CalculationVersionFormInputs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CalculationVersionFormInputs" */
export enum CalculationVersionFormInputs_Select_Column_CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Required = 'required'
}

/** select "CalculationVersionFormInputs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CalculationVersionFormInputs" */
export enum CalculationVersionFormInputs_Select_Column_CalculationVersionFormInputs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Required = 'required'
}

/** input type for updating data in table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Set_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CalculationVersionFormInputs_Stddev_Fields = {
  __typename?: 'CalculationVersionFormInputs_stddev_fields';
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Stddev_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CalculationVersionFormInputs_Stddev_Pop_Fields = {
  __typename?: 'CalculationVersionFormInputs_stddev_pop_fields';
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Stddev_Pop_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CalculationVersionFormInputs_Stddev_Samp_Fields = {
  __typename?: 'CalculationVersionFormInputs_stddev_samp_fields';
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Stddev_Samp_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CalculationVersionFormInputs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CalculationVersionFormInputs_Stream_Cursor_Value_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
  calculation_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CalculationVersionFormInputs_Sum_Fields = {
  __typename?: 'CalculationVersionFormInputs_sum_fields';
  calculation_template_version_id?: Maybe<Scalars['Int']>;
  form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Sum_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "CalculationVersionFormInputs" */
export enum CalculationVersionFormInputs_Update_Column {
  /** column name */
  Analytics = 'analytics',
  /** column name */
  CalculationTemplateVersionId = 'calculation_template_version_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormInputId = 'form_input_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Required = 'required',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type CalculationVersionFormInputs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CalculationVersionFormInputs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CalculationVersionFormInputs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CalculationVersionFormInputs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CalculationVersionFormInputs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CalculationVersionFormInputs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CalculationVersionFormInputs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CalculationVersionFormInputs_Set_Input>;
  /** filter the rows which have to be updated */
  where: CalculationVersionFormInputs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CalculationVersionFormInputs_Var_Pop_Fields = {
  __typename?: 'CalculationVersionFormInputs_var_pop_fields';
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Var_Pop_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CalculationVersionFormInputs_Var_Samp_Fields = {
  __typename?: 'CalculationVersionFormInputs_var_samp_fields';
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Var_Samp_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CalculationVersionFormInputs_Variance_Fields = {
  __typename?: 'CalculationVersionFormInputs_variance_fields';
  calculation_template_version_id?: Maybe<Scalars['Float']>;
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CalculationVersionFormInputs" */
export type CalculationVersionFormInputs_Variance_Order_By = {
  calculation_template_version_id?: InputMaybe<Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** This table will be deprecated for the new Calcs table */
export type Calculations = {
  __typename?: 'Calculations';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Contract?: Maybe<Contracts>;
  /** An object relationship */
  Identity: Identities;
  additional_costs?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  benefits_plan?: Maybe<Scalars['String']>;
  bill_rate?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  dental_cost?: Maybe<Scalars['float8']>;
  dependents?: Maybe<Scalars['json']>;
  description?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['timestamptz']>;
  employment_type?: Maybe<Scalars['String']>;
  estimated_age?: Maybe<Scalars['Int']>;
  explanation_costs?: Maybe<Scalars['String']>;
  family_type?: Maybe<Scalars['String']>;
  first_pay_date?: Maybe<Scalars['String']>;
  health_cost?: Maybe<Scalars['float8']>;
  hourly_rate?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  identityByAuthorId?: Maybe<Identities>;
  identity_id: Scalars['Int'];
  insurance_type?: Maybe<Scalars['String']>;
  is_primary: Scalars['Boolean'];
  job_id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  program_manager?: Maybe<Scalars['String']>;
  pto_days?: Maybe<Scalars['Int']>;
  pto_weeks?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Int']>;
  second_pay_date?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  submission_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  training?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamptz'];
};


/** This table will be deprecated for the new Calcs table */
export type CalculationsDependentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** This table will be deprecated for the new Calcs table */
export type CalculationsOptionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Calculations" */
export type Calculations_Aggregate = {
  __typename?: 'Calculations_aggregate';
  aggregate?: Maybe<Calculations_Aggregate_Fields>;
  nodes: Array<Calculations>;
};

export type Calculations_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Calculations_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Calculations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Calculations_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Calculations_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Calculations_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Calculations_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Calculations_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Calculations_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Calculations_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Calculations_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Calculations_Aggregate_Bool_Exp_Var_Samp>;
};

export type Calculations_Aggregate_Bool_Exp_Avg = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Corr = {
  arguments: Calculations_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Calculations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calculations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Calculations_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Calculations_Aggregate_Bool_Exp_Max = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Min = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Sum = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Calculations_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calculations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "Calculations" */
export type Calculations_Aggregate_Fields = {
  __typename?: 'Calculations_aggregate_fields';
  avg?: Maybe<Calculations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Calculations_Max_Fields>;
  min?: Maybe<Calculations_Min_Fields>;
  stddev?: Maybe<Calculations_Stddev_Fields>;
  stddev_pop?: Maybe<Calculations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Calculations_Stddev_Samp_Fields>;
  sum?: Maybe<Calculations_Sum_Fields>;
  var_pop?: Maybe<Calculations_Var_Pop_Fields>;
  var_samp?: Maybe<Calculations_Var_Samp_Fields>;
  variance?: Maybe<Calculations_Variance_Fields>;
};


/** aggregate fields of "Calculations" */
export type Calculations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calculations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Calculations" */
export type Calculations_Aggregate_Order_By = {
  avg?: InputMaybe<Calculations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calculations_Max_Order_By>;
  min?: InputMaybe<Calculations_Min_Order_By>;
  stddev?: InputMaybe<Calculations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Calculations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Calculations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Calculations_Sum_Order_By>;
  var_pop?: InputMaybe<Calculations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Calculations_Var_Samp_Order_By>;
  variance?: InputMaybe<Calculations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Calculations_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Calculations" */
export type Calculations_Arr_Rel_Insert_Input = {
  data: Array<Calculations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calculations_On_Conflict>;
};

/** aggregate avg on columns */
export type Calculations_Avg_Fields = {
  __typename?: 'Calculations_avg_fields';
  additional_costs?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  dental_cost?: Maybe<Scalars['Float']>;
  estimated_age?: Maybe<Scalars['Float']>;
  health_cost?: Maybe<Scalars['Float']>;
  hourly_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  pto_days?: Maybe<Scalars['Float']>;
  pto_weeks?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Calculations" */
export type Calculations_Avg_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Calculations". All fields are combined with a logical 'AND'. */
export type Calculations_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Calculations_Bool_Exp>>;
  _not?: InputMaybe<Calculations_Bool_Exp>;
  _or?: InputMaybe<Array<Calculations_Bool_Exp>>;
  additional_costs?: InputMaybe<Int_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  benefits_plan?: InputMaybe<String_Comparison_Exp>;
  bill_rate?: InputMaybe<Float8_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dental_cost?: InputMaybe<Float8_Comparison_Exp>;
  dependents?: InputMaybe<Json_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  effective_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  employment_type?: InputMaybe<String_Comparison_Exp>;
  estimated_age?: InputMaybe<Int_Comparison_Exp>;
  explanation_costs?: InputMaybe<String_Comparison_Exp>;
  family_type?: InputMaybe<String_Comparison_Exp>;
  first_pay_date?: InputMaybe<String_Comparison_Exp>;
  health_cost?: InputMaybe<Float8_Comparison_Exp>;
  hourly_rate?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityByAuthorId?: InputMaybe<Identities_Bool_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  insurance_type?: InputMaybe<String_Comparison_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  program_manager?: InputMaybe<String_Comparison_Exp>;
  pto_days?: InputMaybe<Int_Comparison_Exp>;
  pto_weeks?: InputMaybe<Int_Comparison_Exp>;
  salary?: InputMaybe<Int_Comparison_Exp>;
  second_pay_date?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  submission_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  training?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Calculations" */
export enum Calculations_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalculationsPkey = 'Calculations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Calculations_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Calculations_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Calculations_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Calculations" */
export type Calculations_Inc_Input = {
  additional_costs?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  dental_cost?: InputMaybe<Scalars['float8']>;
  estimated_age?: InputMaybe<Scalars['Int']>;
  health_cost?: InputMaybe<Scalars['float8']>;
  hourly_rate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  pto_days?: InputMaybe<Scalars['Int']>;
  pto_weeks?: InputMaybe<Scalars['Int']>;
  salary?: InputMaybe<Scalars['Int']>;
  training?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "Calculations" */
export type Calculations_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  additional_costs?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  benefits_plan?: InputMaybe<Scalars['String']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dental_cost?: InputMaybe<Scalars['float8']>;
  dependents?: InputMaybe<Scalars['json']>;
  description?: InputMaybe<Scalars['String']>;
  effective_date?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Scalars['String']>;
  estimated_age?: InputMaybe<Scalars['Int']>;
  explanation_costs?: InputMaybe<Scalars['String']>;
  family_type?: InputMaybe<Scalars['String']>;
  first_pay_date?: InputMaybe<Scalars['String']>;
  health_cost?: InputMaybe<Scalars['float8']>;
  hourly_rate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityByAuthorId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identity_id?: InputMaybe<Scalars['Int']>;
  insurance_type?: InputMaybe<Scalars['String']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  labor_category?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Scalars['jsonb']>;
  program_manager?: InputMaybe<Scalars['String']>;
  pto_days?: InputMaybe<Scalars['Int']>;
  pto_weeks?: InputMaybe<Scalars['Int']>;
  salary?: InputMaybe<Scalars['Int']>;
  second_pay_date?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  submission_date?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  training?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Calculations_Max_Fields = {
  __typename?: 'Calculations_max_fields';
  additional_costs?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  benefits_plan?: Maybe<Scalars['String']>;
  bill_rate?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dental_cost?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['timestamptz']>;
  employment_type?: Maybe<Scalars['String']>;
  estimated_age?: Maybe<Scalars['Int']>;
  explanation_costs?: Maybe<Scalars['String']>;
  family_type?: Maybe<Scalars['String']>;
  first_pay_date?: Maybe<Scalars['String']>;
  health_cost?: Maybe<Scalars['float8']>;
  hourly_rate?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  insurance_type?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  program_manager?: Maybe<Scalars['String']>;
  pto_days?: Maybe<Scalars['Int']>;
  pto_weeks?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Int']>;
  second_pay_date?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  submission_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  training?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Calculations" */
export type Calculations_Max_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  benefits_plan?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  explanation_costs?: InputMaybe<Order_By>;
  family_type?: InputMaybe<Order_By>;
  first_pay_date?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  insurance_type?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  program_manager?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  second_pay_date?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  submission_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calculations_Min_Fields = {
  __typename?: 'Calculations_min_fields';
  additional_costs?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  benefits_plan?: Maybe<Scalars['String']>;
  bill_rate?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dental_cost?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['timestamptz']>;
  employment_type?: Maybe<Scalars['String']>;
  estimated_age?: Maybe<Scalars['Int']>;
  explanation_costs?: Maybe<Scalars['String']>;
  family_type?: Maybe<Scalars['String']>;
  first_pay_date?: Maybe<Scalars['String']>;
  health_cost?: Maybe<Scalars['float8']>;
  hourly_rate?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  insurance_type?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  program_manager?: Maybe<Scalars['String']>;
  pto_days?: Maybe<Scalars['Int']>;
  pto_weeks?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Int']>;
  second_pay_date?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  submission_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  training?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Calculations" */
export type Calculations_Min_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  benefits_plan?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  explanation_costs?: InputMaybe<Order_By>;
  family_type?: InputMaybe<Order_By>;
  first_pay_date?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  insurance_type?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  program_manager?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  second_pay_date?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  submission_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Calculations" */
export type Calculations_Mutation_Response = {
  __typename?: 'Calculations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calculations>;
};

/** on_conflict condition type for table "Calculations" */
export type Calculations_On_Conflict = {
  constraint: Calculations_Constraint;
  update_columns?: Array<Calculations_Update_Column>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};

/** Ordering options when selecting data from "Calculations". */
export type Calculations_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Contract?: InputMaybe<Contracts_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  benefits_plan?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  dependents?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  explanation_costs?: InputMaybe<Order_By>;
  family_type?: InputMaybe<Order_By>;
  first_pay_date?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityByAuthorId?: InputMaybe<Identities_Order_By>;
  identity_id?: InputMaybe<Order_By>;
  insurance_type?: InputMaybe<Order_By>;
  is_primary?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  program_manager?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  second_pay_date?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  submission_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Calculations */
export type Calculations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Calculations_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Calculations" */
export enum Calculations_Select_Column {
  /** column name */
  AdditionalCosts = 'additional_costs',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  BenefitsPlan = 'benefits_plan',
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  Dependents = 'dependents',
  /** column name */
  Description = 'description',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  EstimatedAge = 'estimated_age',
  /** column name */
  ExplanationCosts = 'explanation_costs',
  /** column name */
  FamilyType = 'family_type',
  /** column name */
  FirstPayDate = 'first_pay_date',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  HourlyRate = 'hourly_rate',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InsuranceType = 'insurance_type',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  Notes = 'notes',
  /** column name */
  Options = 'options',
  /** column name */
  ProgramManager = 'program_manager',
  /** column name */
  PtoDays = 'pto_days',
  /** column name */
  PtoWeeks = 'pto_weeks',
  /** column name */
  Salary = 'salary',
  /** column name */
  SecondPayDate = 'second_pay_date',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SubmissionDate = 'submission_date',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Training = 'training',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Calculations_aggregate_bool_exp_avg_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** select "Calculations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary'
}

/** select "Calculations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary'
}

/** select "Calculations_aggregate_bool_exp_corr_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** select "Calculations_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** select "Calculations_aggregate_bool_exp_max_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** select "Calculations_aggregate_bool_exp_min_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** select "Calculations_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** select "Calculations_aggregate_bool_exp_sum_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** select "Calculations_aggregate_bool_exp_var_samp_arguments_columns" columns of table "Calculations" */
export enum Calculations_Select_Column_Calculations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  Training = 'training'
}

/** input type for updating data in table "Calculations" */
export type Calculations_Set_Input = {
  additional_costs?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  benefits_plan?: InputMaybe<Scalars['String']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dental_cost?: InputMaybe<Scalars['float8']>;
  dependents?: InputMaybe<Scalars['json']>;
  description?: InputMaybe<Scalars['String']>;
  effective_date?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Scalars['String']>;
  estimated_age?: InputMaybe<Scalars['Int']>;
  explanation_costs?: InputMaybe<Scalars['String']>;
  family_type?: InputMaybe<Scalars['String']>;
  first_pay_date?: InputMaybe<Scalars['String']>;
  health_cost?: InputMaybe<Scalars['float8']>;
  hourly_rate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  insurance_type?: InputMaybe<Scalars['String']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  labor_category?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Scalars['jsonb']>;
  program_manager?: InputMaybe<Scalars['String']>;
  pto_days?: InputMaybe<Scalars['Int']>;
  pto_weeks?: InputMaybe<Scalars['Int']>;
  salary?: InputMaybe<Scalars['Int']>;
  second_pay_date?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  submission_date?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  training?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Calculations_Stddev_Fields = {
  __typename?: 'Calculations_stddev_fields';
  additional_costs?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  dental_cost?: Maybe<Scalars['Float']>;
  estimated_age?: Maybe<Scalars['Float']>;
  health_cost?: Maybe<Scalars['Float']>;
  hourly_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  pto_days?: Maybe<Scalars['Float']>;
  pto_weeks?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Calculations" */
export type Calculations_Stddev_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Calculations_Stddev_Pop_Fields = {
  __typename?: 'Calculations_stddev_pop_fields';
  additional_costs?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  dental_cost?: Maybe<Scalars['Float']>;
  estimated_age?: Maybe<Scalars['Float']>;
  health_cost?: Maybe<Scalars['Float']>;
  hourly_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  pto_days?: Maybe<Scalars['Float']>;
  pto_weeks?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Calculations" */
export type Calculations_Stddev_Pop_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Calculations_Stddev_Samp_Fields = {
  __typename?: 'Calculations_stddev_samp_fields';
  additional_costs?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  dental_cost?: Maybe<Scalars['Float']>;
  estimated_age?: Maybe<Scalars['Float']>;
  health_cost?: Maybe<Scalars['Float']>;
  hourly_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  pto_days?: Maybe<Scalars['Float']>;
  pto_weeks?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Calculations" */
export type Calculations_Stddev_Samp_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Calculations" */
export type Calculations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calculations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calculations_Stream_Cursor_Value_Input = {
  additional_costs?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  benefits_plan?: InputMaybe<Scalars['String']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dental_cost?: InputMaybe<Scalars['float8']>;
  dependents?: InputMaybe<Scalars['json']>;
  description?: InputMaybe<Scalars['String']>;
  effective_date?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Scalars['String']>;
  estimated_age?: InputMaybe<Scalars['Int']>;
  explanation_costs?: InputMaybe<Scalars['String']>;
  family_type?: InputMaybe<Scalars['String']>;
  first_pay_date?: InputMaybe<Scalars['String']>;
  health_cost?: InputMaybe<Scalars['float8']>;
  hourly_rate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  insurance_type?: InputMaybe<Scalars['String']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  labor_category?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Scalars['jsonb']>;
  program_manager?: InputMaybe<Scalars['String']>;
  pto_days?: InputMaybe<Scalars['Int']>;
  pto_weeks?: InputMaybe<Scalars['Int']>;
  salary?: InputMaybe<Scalars['Int']>;
  second_pay_date?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  submission_date?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  training?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Calculations_Sum_Fields = {
  __typename?: 'Calculations_sum_fields';
  additional_costs?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  bill_rate?: Maybe<Scalars['float8']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  dental_cost?: Maybe<Scalars['float8']>;
  estimated_age?: Maybe<Scalars['Int']>;
  health_cost?: Maybe<Scalars['float8']>;
  hourly_rate?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  pto_days?: Maybe<Scalars['Int']>;
  pto_weeks?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Int']>;
  training?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "Calculations" */
export type Calculations_Sum_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** update columns of table "Calculations" */
export enum Calculations_Update_Column {
  /** column name */
  AdditionalCosts = 'additional_costs',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  BenefitsPlan = 'benefits_plan',
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DentalCost = 'dental_cost',
  /** column name */
  Dependents = 'dependents',
  /** column name */
  Description = 'description',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  EstimatedAge = 'estimated_age',
  /** column name */
  ExplanationCosts = 'explanation_costs',
  /** column name */
  FamilyType = 'family_type',
  /** column name */
  FirstPayDate = 'first_pay_date',
  /** column name */
  HealthCost = 'health_cost',
  /** column name */
  HourlyRate = 'hourly_rate',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InsuranceType = 'insurance_type',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  Notes = 'notes',
  /** column name */
  Options = 'options',
  /** column name */
  ProgramManager = 'program_manager',
  /** column name */
  PtoDays = 'pto_days',
  /** column name */
  PtoWeeks = 'pto_weeks',
  /** column name */
  Salary = 'salary',
  /** column name */
  SecondPayDate = 'second_pay_date',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SubmissionDate = 'submission_date',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Training = 'training',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Calculations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Calculations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Calculations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Calculations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Calculations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Calculations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Calculations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calculations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calculations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Calculations_Var_Pop_Fields = {
  __typename?: 'Calculations_var_pop_fields';
  additional_costs?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  dental_cost?: Maybe<Scalars['Float']>;
  estimated_age?: Maybe<Scalars['Float']>;
  health_cost?: Maybe<Scalars['Float']>;
  hourly_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  pto_days?: Maybe<Scalars['Float']>;
  pto_weeks?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Calculations" */
export type Calculations_Var_Pop_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Calculations_Var_Samp_Fields = {
  __typename?: 'Calculations_var_samp_fields';
  additional_costs?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  dental_cost?: Maybe<Scalars['Float']>;
  estimated_age?: Maybe<Scalars['Float']>;
  health_cost?: Maybe<Scalars['Float']>;
  hourly_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  pto_days?: Maybe<Scalars['Float']>;
  pto_weeks?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Calculations" */
export type Calculations_Var_Samp_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Calculations_Variance_Fields = {
  __typename?: 'Calculations_variance_fields';
  additional_costs?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  dental_cost?: Maybe<Scalars['Float']>;
  estimated_age?: Maybe<Scalars['Float']>;
  health_cost?: Maybe<Scalars['Float']>;
  hourly_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  pto_days?: Maybe<Scalars['Float']>;
  pto_weeks?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  training?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Calculations" */
export type Calculations_Variance_Order_By = {
  additional_costs?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  dental_cost?: InputMaybe<Order_By>;
  estimated_age?: InputMaybe<Order_By>;
  health_cost?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pto_days?: InputMaybe<Order_By>;
  pto_weeks?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  training?: InputMaybe<Order_By>;
};

/** columns and relationships of "CandidateSources" */
export type CandidateSources = {
  __typename?: 'CandidateSources';
  /** An object relationship */
  Company: Companies;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deletable: Scalars['Boolean'];
  groupId?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  text: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "CandidateSources" */
export type CandidateSourcesIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "CandidateSources" */
export type CandidateSourcesIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};

/** aggregated selection of "CandidateSources" */
export type CandidateSources_Aggregate = {
  __typename?: 'CandidateSources_aggregate';
  aggregate?: Maybe<CandidateSources_Aggregate_Fields>;
  nodes: Array<CandidateSources>;
};

/** aggregate fields of "CandidateSources" */
export type CandidateSources_Aggregate_Fields = {
  __typename?: 'CandidateSources_aggregate_fields';
  avg?: Maybe<CandidateSources_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CandidateSources_Max_Fields>;
  min?: Maybe<CandidateSources_Min_Fields>;
  stddev?: Maybe<CandidateSources_Stddev_Fields>;
  stddev_pop?: Maybe<CandidateSources_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CandidateSources_Stddev_Samp_Fields>;
  sum?: Maybe<CandidateSources_Sum_Fields>;
  var_pop?: Maybe<CandidateSources_Var_Pop_Fields>;
  var_samp?: Maybe<CandidateSources_Var_Samp_Fields>;
  variance?: Maybe<CandidateSources_Variance_Fields>;
};


/** aggregate fields of "CandidateSources" */
export type CandidateSources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CandidateSources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CandidateSources_Avg_Fields = {
  __typename?: 'CandidateSources_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CandidateSources". All fields are combined with a logical 'AND'. */
export type CandidateSources_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identities?: InputMaybe<Identities_Bool_Exp>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<CandidateSources_Bool_Exp>>;
  _not?: InputMaybe<CandidateSources_Bool_Exp>;
  _or?: InputMaybe<Array<CandidateSources_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletable?: InputMaybe<Boolean_Comparison_Exp>;
  groupId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "CandidateSources" */
export enum CandidateSources_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateSourcesPkey = 'CandidateSources_pkey',
  /** unique or primary key constraint on columns "text", "company_id" */
  CandidateSourcesTextCompanyIdKey = 'CandidateSources_text_company_id_key',
  /** unique or primary key constraint on columns "text" */
  CandidateSourcesTextKey = 'CandidateSources_text_key'
}

/** input type for incrementing numeric columns in table "CandidateSources" */
export type CandidateSources_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CandidateSources" */
export type CandidateSources_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identities?: InputMaybe<Identities_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CandidateSources_Max_Fields = {
  __typename?: 'CandidateSources_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CandidateSources_Min_Fields = {
  __typename?: 'CandidateSources_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CandidateSources" */
export type CandidateSources_Mutation_Response = {
  __typename?: 'CandidateSources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CandidateSources>;
};

/** input type for inserting object relation for remote table "CandidateSources" */
export type CandidateSources_Obj_Rel_Insert_Input = {
  data: CandidateSources_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CandidateSources_On_Conflict>;
};

/** on_conflict condition type for table "CandidateSources" */
export type CandidateSources_On_Conflict = {
  constraint: CandidateSources_Constraint;
  update_columns?: Array<CandidateSources_Update_Column>;
  where?: InputMaybe<CandidateSources_Bool_Exp>;
};

/** Ordering options when selecting data from "CandidateSources". */
export type CandidateSources_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deletable?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CandidateSources */
export type CandidateSources_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "CandidateSources" */
export enum CandidateSources_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "CandidateSources" */
export type CandidateSources_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CandidateSources_Stddev_Fields = {
  __typename?: 'CandidateSources_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CandidateSources_Stddev_Pop_Fields = {
  __typename?: 'CandidateSources_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CandidateSources_Stddev_Samp_Fields = {
  __typename?: 'CandidateSources_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CandidateSources" */
export type CandidateSources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CandidateSources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CandidateSources_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CandidateSources_Sum_Fields = {
  __typename?: 'CandidateSources_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "CandidateSources" */
export enum CandidateSources_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type CandidateSources_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CandidateSources_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CandidateSources_Set_Input>;
  /** filter the rows which have to be updated */
  where: CandidateSources_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CandidateSources_Var_Pop_Fields = {
  __typename?: 'CandidateSources_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CandidateSources_Var_Samp_Fields = {
  __typename?: 'CandidateSources_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CandidateSources_Variance_Fields = {
  __typename?: 'CandidateSources_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CandidateStatuses" */
export type CandidateStatuses = {
  __typename?: 'CandidateStatuses';
  company_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  default: Scalars['Boolean'];
  deletable?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  status: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  /** An object relationship */
  updatedBy: Identities;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by: Scalars['Int'];
};

/** aggregated selection of "CandidateStatuses" */
export type CandidateStatuses_Aggregate = {
  __typename?: 'CandidateStatuses_aggregate';
  aggregate?: Maybe<CandidateStatuses_Aggregate_Fields>;
  nodes: Array<CandidateStatuses>;
};

/** aggregate fields of "CandidateStatuses" */
export type CandidateStatuses_Aggregate_Fields = {
  __typename?: 'CandidateStatuses_aggregate_fields';
  avg?: Maybe<CandidateStatuses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CandidateStatuses_Max_Fields>;
  min?: Maybe<CandidateStatuses_Min_Fields>;
  stddev?: Maybe<CandidateStatuses_Stddev_Fields>;
  stddev_pop?: Maybe<CandidateStatuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CandidateStatuses_Stddev_Samp_Fields>;
  sum?: Maybe<CandidateStatuses_Sum_Fields>;
  var_pop?: Maybe<CandidateStatuses_Var_Pop_Fields>;
  var_samp?: Maybe<CandidateStatuses_Var_Samp_Fields>;
  variance?: Maybe<CandidateStatuses_Variance_Fields>;
};


/** aggregate fields of "CandidateStatuses" */
export type CandidateStatuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CandidateStatuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CandidateStatuses_Avg_Fields = {
  __typename?: 'CandidateStatuses_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CandidateStatuses". All fields are combined with a logical 'AND'. */
export type CandidateStatuses_Bool_Exp = {
  _and?: InputMaybe<Array<CandidateStatuses_Bool_Exp>>;
  _not?: InputMaybe<CandidateStatuses_Bool_Exp>;
  _or?: InputMaybe<Array<CandidateStatuses_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  deletable?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updatedBy?: InputMaybe<Identities_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "CandidateStatuses" */
export enum CandidateStatuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentityStatusesPkey = 'IdentityStatuses_pkey'
}

/** input type for incrementing numeric columns in table "CandidateStatuses" */
export type CandidateStatuses_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CandidateStatuses" */
export type CandidateStatuses_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CandidateStatuses_Max_Fields = {
  __typename?: 'CandidateStatuses_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CandidateStatuses_Min_Fields = {
  __typename?: 'CandidateStatuses_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CandidateStatuses" */
export type CandidateStatuses_Mutation_Response = {
  __typename?: 'CandidateStatuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CandidateStatuses>;
};

/** input type for inserting object relation for remote table "CandidateStatuses" */
export type CandidateStatuses_Obj_Rel_Insert_Input = {
  data: CandidateStatuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CandidateStatuses_On_Conflict>;
};

/** on_conflict condition type for table "CandidateStatuses" */
export type CandidateStatuses_On_Conflict = {
  constraint: CandidateStatuses_Constraint;
  update_columns?: Array<CandidateStatuses_Update_Column>;
  where?: InputMaybe<CandidateStatuses_Bool_Exp>;
};

/** Ordering options when selecting data from "CandidateStatuses". */
export type CandidateStatuses_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  deletable?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Identities_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CandidateStatuses */
export type CandidateStatuses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "CandidateStatuses" */
export enum CandidateStatuses_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "CandidateStatuses" */
export type CandidateStatuses_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CandidateStatuses_Stddev_Fields = {
  __typename?: 'CandidateStatuses_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CandidateStatuses_Stddev_Pop_Fields = {
  __typename?: 'CandidateStatuses_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CandidateStatuses_Stddev_Samp_Fields = {
  __typename?: 'CandidateStatuses_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CandidateStatuses" */
export type CandidateStatuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CandidateStatuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CandidateStatuses_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CandidateStatuses_Sum_Fields = {
  __typename?: 'CandidateStatuses_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "CandidateStatuses" */
export enum CandidateStatuses_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type CandidateStatuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CandidateStatuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CandidateStatuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: CandidateStatuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CandidateStatuses_Var_Pop_Fields = {
  __typename?: 'CandidateStatuses_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CandidateStatuses_Var_Samp_Fields = {
  __typename?: 'CandidateStatuses_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CandidateStatuses_Variance_Fields = {
  __typename?: 'CandidateStatuses_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CaptureNodes" */
export type CaptureNodes = {
  __typename?: 'CaptureNodes';
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['Int'];
  label: Scalars['String'];
  properties?: Maybe<Scalars['jsonb']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "CaptureNodes" */
export type CaptureNodesPropertiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "CaptureNodes" */
export type CaptureNodes_Aggregate = {
  __typename?: 'CaptureNodes_aggregate';
  aggregate?: Maybe<CaptureNodes_Aggregate_Fields>;
  nodes: Array<CaptureNodes>;
};

/** aggregate fields of "CaptureNodes" */
export type CaptureNodes_Aggregate_Fields = {
  __typename?: 'CaptureNodes_aggregate_fields';
  avg?: Maybe<CaptureNodes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CaptureNodes_Max_Fields>;
  min?: Maybe<CaptureNodes_Min_Fields>;
  stddev?: Maybe<CaptureNodes_Stddev_Fields>;
  stddev_pop?: Maybe<CaptureNodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CaptureNodes_Stddev_Samp_Fields>;
  sum?: Maybe<CaptureNodes_Sum_Fields>;
  var_pop?: Maybe<CaptureNodes_Var_Pop_Fields>;
  var_samp?: Maybe<CaptureNodes_Var_Samp_Fields>;
  variance?: Maybe<CaptureNodes_Variance_Fields>;
};


/** aggregate fields of "CaptureNodes" */
export type CaptureNodes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CaptureNodes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CaptureNodes_Append_Input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type CaptureNodes_Avg_Fields = {
  __typename?: 'CaptureNodes_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CaptureNodes". All fields are combined with a logical 'AND'. */
export type CaptureNodes_Bool_Exp = {
  _and?: InputMaybe<Array<CaptureNodes_Bool_Exp>>;
  _not?: InputMaybe<CaptureNodes_Bool_Exp>;
  _or?: InputMaybe<Array<CaptureNodes_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CaptureNodes" */
export enum CaptureNodes_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaptureNodesPkey = 'CaptureNodes_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CaptureNodes_Delete_At_Path_Input = {
  properties?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CaptureNodes_Delete_Elem_Input = {
  properties?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CaptureNodes_Delete_Key_Input = {
  properties?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "CaptureNodes" */
export type CaptureNodes_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CaptureNodes" */
export type CaptureNodes_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CaptureNodes_Max_Fields = {
  __typename?: 'CaptureNodes_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CaptureNodes_Min_Fields = {
  __typename?: 'CaptureNodes_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "CaptureNodes" */
export type CaptureNodes_Mutation_Response = {
  __typename?: 'CaptureNodes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CaptureNodes>;
};

/** on_conflict condition type for table "CaptureNodes" */
export type CaptureNodes_On_Conflict = {
  constraint: CaptureNodes_Constraint;
  update_columns?: Array<CaptureNodes_Update_Column>;
  where?: InputMaybe<CaptureNodes_Bool_Exp>;
};

/** Ordering options when selecting data from "CaptureNodes". */
export type CaptureNodes_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CaptureNodes */
export type CaptureNodes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CaptureNodes_Prepend_Input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "CaptureNodes" */
export enum CaptureNodes_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Properties = 'properties',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CaptureNodes" */
export type CaptureNodes_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CaptureNodes_Stddev_Fields = {
  __typename?: 'CaptureNodes_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CaptureNodes_Stddev_Pop_Fields = {
  __typename?: 'CaptureNodes_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CaptureNodes_Stddev_Samp_Fields = {
  __typename?: 'CaptureNodes_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CaptureNodes" */
export type CaptureNodes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CaptureNodes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CaptureNodes_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CaptureNodes_Sum_Fields = {
  __typename?: 'CaptureNodes_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "CaptureNodes" */
export enum CaptureNodes_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Properties = 'properties',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CaptureNodes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CaptureNodes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CaptureNodes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CaptureNodes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CaptureNodes_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CaptureNodes_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CaptureNodes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CaptureNodes_Set_Input>;
  /** filter the rows which have to be updated */
  where: CaptureNodes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CaptureNodes_Var_Pop_Fields = {
  __typename?: 'CaptureNodes_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CaptureNodes_Var_Samp_Fields = {
  __typename?: 'CaptureNodes_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CaptureNodes_Variance_Fields = {
  __typename?: 'CaptureNodes_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['uuid'];
  method: Scalars['String'];
  request: Scalars['jsonb'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};


/** columns and relationships of "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecordRequestArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Aggregate = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_aggregate';
  aggregate?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Aggregate_Fields>;
  nodes: Array<ClientSideExternalSystemIntegrationPluginRecord>;
};

/** aggregate fields of "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Aggregate_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_aggregate_fields';
  avg?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Max_Fields>;
  min?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Min_Fields>;
  stddev?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Stddev_Fields>;
  stddev_pop?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Stddev_Samp_Fields>;
  sum?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Sum_Fields>;
  var_pop?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Var_Pop_Fields>;
  var_samp?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Var_Samp_Fields>;
  variance?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Variance_Fields>;
};


/** aggregate fields of "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClientSideExternalSystemIntegrationPluginRecord_Append_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Avg_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ClientSideExternalSystemIntegrationPluginRecord". All fields are combined with a logical 'AND'. */
export type ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp = {
  _and?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>>;
  _not?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>;
  _or?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  method?: InputMaybe<String_Comparison_Exp>;
  request?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ClientSideExternalSystemIntegrationPluginRecord" */
export enum ClientSideExternalSystemIntegrationPluginRecord_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientSideExternalSystemIntegrationPluginRecordPkey = 'ClientSideExternalSystemIntegrationPluginRecord_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClientSideExternalSystemIntegrationPluginRecord_Delete_At_Path_Input = {
  request?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClientSideExternalSystemIntegrationPluginRecord_Delete_Elem_Input = {
  request?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClientSideExternalSystemIntegrationPluginRecord_Delete_Key_Input = {
  request?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Max_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  method?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Min_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  method?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Mutation_Response = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientSideExternalSystemIntegrationPluginRecord>;
};

/** on_conflict condition type for table "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_On_Conflict = {
  constraint: ClientSideExternalSystemIntegrationPluginRecord_Constraint;
  update_columns?: Array<ClientSideExternalSystemIntegrationPluginRecord_Update_Column>;
  where?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>;
};

/** Ordering options when selecting data from "ClientSideExternalSystemIntegrationPluginRecord". */
export type ClientSideExternalSystemIntegrationPluginRecord_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ClientSideExternalSystemIntegrationPluginRecord */
export type ClientSideExternalSystemIntegrationPluginRecord_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClientSideExternalSystemIntegrationPluginRecord_Prepend_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ClientSideExternalSystemIntegrationPluginRecord" */
export enum ClientSideExternalSystemIntegrationPluginRecord_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Request = 'request',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Stddev_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Stddev_Pop_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Stddev_Samp_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ClientSideExternalSystemIntegrationPluginRecord" */
export type ClientSideExternalSystemIntegrationPluginRecord_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientSideExternalSystemIntegrationPluginRecord_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientSideExternalSystemIntegrationPluginRecord_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Sum_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "ClientSideExternalSystemIntegrationPluginRecord" */
export enum ClientSideExternalSystemIntegrationPluginRecord_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  Request = 'request',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type ClientSideExternalSystemIntegrationPluginRecord_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Var_Pop_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Var_Samp_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClientSideExternalSystemIntegrationPluginRecord_Variance_Fields = {
  __typename?: 'ClientSideExternalSystemIntegrationPluginRecord_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
};

export type CloneGSheetCalcTemplateOutput = {
  __typename?: 'CloneGSheetCalcTemplateOutput';
  GoogleSheetCalculation?: Maybe<GoogleSheetCalculations>;
  gSheetCalcId: Scalars['uuid'];
};

/** columns and relationships of "CognitoUserData" */
export type CognitoUserData = {
  __typename?: 'CognitoUserData';
  cog_id: Scalars['String'];
  cognito_data: Scalars['jsonb'];
  enabled: Scalars['Boolean'];
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "CognitoUserData" */
export type CognitoUserDataCognito_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "CognitoUserData" */
export type CognitoUserData_Aggregate = {
  __typename?: 'CognitoUserData_aggregate';
  aggregate?: Maybe<CognitoUserData_Aggregate_Fields>;
  nodes: Array<CognitoUserData>;
};

/** aggregate fields of "CognitoUserData" */
export type CognitoUserData_Aggregate_Fields = {
  __typename?: 'CognitoUserData_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CognitoUserData_Max_Fields>;
  min?: Maybe<CognitoUserData_Min_Fields>;
};


/** aggregate fields of "CognitoUserData" */
export type CognitoUserData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CognitoUserData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CognitoUserData_Append_Input = {
  cognito_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "CognitoUserData". All fields are combined with a logical 'AND'. */
export type CognitoUserData_Bool_Exp = {
  _and?: InputMaybe<Array<CognitoUserData_Bool_Exp>>;
  _not?: InputMaybe<CognitoUserData_Bool_Exp>;
  _or?: InputMaybe<Array<CognitoUserData_Bool_Exp>>;
  cog_id?: InputMaybe<String_Comparison_Exp>;
  cognito_data?: InputMaybe<Jsonb_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CognitoUserData" */
export enum CognitoUserData_Constraint {
  /** unique or primary key constraint on columns "cog_id" */
  CognitoUserDataPkey = 'CognitoUserData_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CognitoUserData_Delete_At_Path_Input = {
  cognito_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CognitoUserData_Delete_Elem_Input = {
  cognito_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CognitoUserData_Delete_Key_Input = {
  cognito_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "CognitoUserData" */
export type CognitoUserData_Insert_Input = {
  cog_id?: InputMaybe<Scalars['String']>;
  cognito_data?: InputMaybe<Scalars['jsonb']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CognitoUserData_Max_Fields = {
  __typename?: 'CognitoUserData_max_fields';
  cog_id?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CognitoUserData_Min_Fields = {
  __typename?: 'CognitoUserData_min_fields';
  cog_id?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "CognitoUserData" */
export type CognitoUserData_Mutation_Response = {
  __typename?: 'CognitoUserData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CognitoUserData>;
};

/** on_conflict condition type for table "CognitoUserData" */
export type CognitoUserData_On_Conflict = {
  constraint: CognitoUserData_Constraint;
  update_columns?: Array<CognitoUserData_Update_Column>;
  where?: InputMaybe<CognitoUserData_Bool_Exp>;
};

/** Ordering options when selecting data from "CognitoUserData". */
export type CognitoUserData_Order_By = {
  cog_id?: InputMaybe<Order_By>;
  cognito_data?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CognitoUserData */
export type CognitoUserData_Pk_Columns_Input = {
  cog_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CognitoUserData_Prepend_Input = {
  cognito_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "CognitoUserData" */
export enum CognitoUserData_Select_Column {
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CognitoData = 'cognito_data',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "CognitoUserData" */
export type CognitoUserData_Set_Input = {
  cog_id?: InputMaybe<Scalars['String']>;
  cognito_data?: InputMaybe<Scalars['jsonb']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CognitoUserData" */
export type CognitoUserData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CognitoUserData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CognitoUserData_Stream_Cursor_Value_Input = {
  cog_id?: InputMaybe<Scalars['String']>;
  cognito_data?: InputMaybe<Scalars['jsonb']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CognitoUserData" */
export enum CognitoUserData_Update_Column {
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CognitoData = 'cognito_data',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type CognitoUserData_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CognitoUserData_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CognitoUserData_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CognitoUserData_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CognitoUserData_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CognitoUserData_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CognitoUserData_Set_Input>;
  /** filter the rows which have to be updated */
  where: CognitoUserData_Bool_Exp;
};

/** columns and relationships of "CommentTypes" */
export type CommentTypes = {
  __typename?: 'CommentTypes';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "CommentTypes" */
export type CommentTypes_Aggregate = {
  __typename?: 'CommentTypes_aggregate';
  aggregate?: Maybe<CommentTypes_Aggregate_Fields>;
  nodes: Array<CommentTypes>;
};

/** aggregate fields of "CommentTypes" */
export type CommentTypes_Aggregate_Fields = {
  __typename?: 'CommentTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CommentTypes_Max_Fields>;
  min?: Maybe<CommentTypes_Min_Fields>;
};


/** aggregate fields of "CommentTypes" */
export type CommentTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CommentTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CommentTypes". All fields are combined with a logical 'AND'. */
export type CommentTypes_Bool_Exp = {
  _and?: InputMaybe<Array<CommentTypes_Bool_Exp>>;
  _not?: InputMaybe<CommentTypes_Bool_Exp>;
  _or?: InputMaybe<Array<CommentTypes_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CommentTypes" */
export enum CommentTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  CommentTypesPkey = 'CommentTypes_pkey'
}

export enum CommentTypes_Enum {
  /** Attempt to reach candidate with no response */
  Attempt = 'ATTEMPT',
  /** Email comment recording when emails have been sent to a candidate */
  Email = 'EMAIL',
  /** Human Resources comment */
  Hr = 'HR',
  /** General note visible by everyone */
  Note = 'NOTE',
  /** Phone screen with candidate conducted */
  PhoneScreen = 'PHONE_SCREEN',
  /** Text message sent to/received from a candidate */
  TextMessage = 'TEXT_MESSAGE'
}

/** Boolean expression to compare columns of type "CommentTypes_enum". All fields are combined with logical 'AND'. */
export type CommentTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CommentTypes_Enum>;
  _in?: InputMaybe<Array<CommentTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CommentTypes_Enum>;
  _nin?: InputMaybe<Array<CommentTypes_Enum>>;
};

/** input type for inserting data into table "CommentTypes" */
export type CommentTypes_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CommentTypes_Max_Fields = {
  __typename?: 'CommentTypes_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CommentTypes_Min_Fields = {
  __typename?: 'CommentTypes_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "CommentTypes" */
export type CommentTypes_Mutation_Response = {
  __typename?: 'CommentTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CommentTypes>;
};

/** input type for inserting object relation for remote table "CommentTypes" */
export type CommentTypes_Obj_Rel_Insert_Input = {
  data: CommentTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CommentTypes_On_Conflict>;
};

/** on_conflict condition type for table "CommentTypes" */
export type CommentTypes_On_Conflict = {
  constraint: CommentTypes_Constraint;
  update_columns?: Array<CommentTypes_Update_Column>;
  where?: InputMaybe<CommentTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "CommentTypes". */
export type CommentTypes_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CommentTypes */
export type CommentTypes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "CommentTypes" */
export enum CommentTypes_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CommentTypes" */
export type CommentTypes_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CommentTypes" */
export type CommentTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CommentTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CommentTypes_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CommentTypes" */
export enum CommentTypes_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CommentTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CommentTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: CommentTypes_Bool_Exp;
};

/** columns and relationships of "Comments" */
export type Comments = {
  __typename?: 'Comments';
  /** An object relationship */
  ActivityLogType: ActivityLogTypes;
  /** An object relationship */
  Author?: Maybe<Identities>;
  /** An object relationship */
  AuthorDeleted?: Maybe<Deleted_Ppl_Names>;
  /** An object relationship */
  BoardSnippet?: Maybe<BoardSnippets>;
  /** An object relationship */
  CommentType: CommentTypes;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An array relationship */
  ContractComments: Array<ContractComments>;
  /** An aggregate relationship */
  ContractComments_aggregate: ContractComments_Aggregate;
  /** An array relationship */
  EmailEvents: Array<Email_Events>;
  /** An aggregate relationship */
  EmailEvents_aggregate: Email_Events_Aggregate;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An array relationship */
  IdentityComments: Array<IdentityComments>;
  /** An aggregate relationship */
  IdentityComments_aggregate: IdentityComments_Aggregate;
  /** An array relationship */
  JobComments: Array<JobComments>;
  /** An aggregate relationship */
  JobComments_aggregate: JobComments_Aggregate;
  /** An object relationship */
  RenderedEmail?: Maybe<RenderedEmails>;
  activitylogtype_id: Scalars['Int'];
  author_id?: Maybe<Scalars['Int']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_object?: Maybe<Scalars['jsonb']>;
  duration?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  pinned: Scalars['Boolean'];
  rendered_email_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type: CommentTypes_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Comments" */
export type CommentsContractCommentsArgs = {
  distinct_on?: InputMaybe<Array<ContractComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractComments_Order_By>>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsContractComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractComments_Order_By>>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsEmailEventsArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsEmailEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsIdentityCommentsArgs = {
  distinct_on?: InputMaybe<Array<IdentityComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityComments_Order_By>>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsIdentityComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityComments_Order_By>>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsJobCommentsArgs = {
  distinct_on?: InputMaybe<Array<JobComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobComments_Order_By>>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsJobComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobComments_Order_By>>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};


/** columns and relationships of "Comments" */
export type CommentsData_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Comments" */
export type CommentsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Comments" */
export type CommentsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Comments" */
export type Comments_Aggregate = {
  __typename?: 'Comments_aggregate';
  aggregate?: Maybe<Comments_Aggregate_Fields>;
  nodes: Array<Comments>;
};

export type Comments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Comments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Comments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Comments_Aggregate_Bool_Exp_Count>;
};

export type Comments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Comments_Select_Column_Comments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Comments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Comments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Comments_Select_Column_Comments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Comments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Comments" */
export type Comments_Aggregate_Fields = {
  __typename?: 'Comments_aggregate_fields';
  avg?: Maybe<Comments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Comments_Max_Fields>;
  min?: Maybe<Comments_Min_Fields>;
  stddev?: Maybe<Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Comments_Sum_Fields>;
  var_pop?: Maybe<Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Comments_Var_Samp_Fields>;
  variance?: Maybe<Comments_Variance_Fields>;
};


/** aggregate fields of "Comments" */
export type Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Comments" */
export type Comments_Aggregate_Order_By = {
  avg?: InputMaybe<Comments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comments_Max_Order_By>;
  min?: InputMaybe<Comments_Min_Order_By>;
  stddev?: InputMaybe<Comments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Comments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Comments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Comments_Sum_Order_By>;
  var_pop?: InputMaybe<Comments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Comments_Var_Samp_Order_By>;
  variance?: InputMaybe<Comments_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Comments_Append_Input = {
  data_object?: InputMaybe<Scalars['jsonb']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Comments" */
export type Comments_Arr_Rel_Insert_Input = {
  data: Array<Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Comments_Avg_Fields = {
  __typename?: 'Comments_avg_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  rendered_email_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Comments" */
export type Comments_Avg_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  ActivityLogType?: InputMaybe<ActivityLogTypes_Bool_Exp>;
  Author?: InputMaybe<Identities_Bool_Exp>;
  AuthorDeleted?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
  BoardSnippet?: InputMaybe<BoardSnippets_Bool_Exp>;
  CommentType?: InputMaybe<CommentTypes_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  ContractComments?: InputMaybe<ContractComments_Bool_Exp>;
  ContractComments_aggregate?: InputMaybe<ContractComments_Aggregate_Bool_Exp>;
  EmailEvents?: InputMaybe<Email_Events_Bool_Exp>;
  EmailEvents_aggregate?: InputMaybe<Email_Events_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  IdentityComments?: InputMaybe<IdentityComments_Bool_Exp>;
  IdentityComments_aggregate?: InputMaybe<IdentityComments_Aggregate_Bool_Exp>;
  JobComments?: InputMaybe<JobComments_Bool_Exp>;
  JobComments_aggregate?: InputMaybe<JobComments_Aggregate_Bool_Exp>;
  RenderedEmail?: InputMaybe<RenderedEmails_Bool_Exp>;
  _and?: InputMaybe<Array<Comments_Bool_Exp>>;
  _not?: InputMaybe<Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Comments_Bool_Exp>>;
  activitylogtype_id?: InputMaybe<Int_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  board_snippet_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_object?: InputMaybe<Jsonb_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  etl_source_entity?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  pinned?: InputMaybe<Boolean_Comparison_Exp>;
  rendered_email_id?: InputMaybe<Int_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<CommentTypes_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint on columns "identity_id", "created_at", "duration", "author_id", "company_id", "activitylogtype_id" */
  CommentsCreatedAtAuthorIdIdentityIdActivitylogtypeIdTe = 'Comments_created_at_author_id_identity_id_activitylogtype_id_te',
  /** unique or primary key constraint on columns "etl_id_token" */
  CommentsEtlIdTokenKey = 'Comments_etl_id_token_key',
  /** unique or primary key constraint on columns "id" */
  CommentsPkey = 'Comments_pkey',
  /** unique or primary key constraint on columns "etl_id_token" */
  CommentsEtlIdToken = 'comments_etl_id_token'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Comments_Delete_At_Path_Input = {
  data_object?: InputMaybe<Array<Scalars['String']>>;
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Comments_Delete_Elem_Input = {
  data_object?: InputMaybe<Scalars['Int']>;
  etl_original_object?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Comments_Delete_Key_Input = {
  data_object?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Comments" */
export type Comments_Inc_Input = {
  activitylogtype_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Comments" */
export type Comments_Insert_Input = {
  ActivityLogType?: InputMaybe<ActivityLogTypes_Obj_Rel_Insert_Input>;
  Author?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  AuthorDeleted?: InputMaybe<Deleted_Ppl_Names_Obj_Rel_Insert_Input>;
  BoardSnippet?: InputMaybe<BoardSnippets_Obj_Rel_Insert_Input>;
  CommentType?: InputMaybe<CommentTypes_Obj_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  ContractComments?: InputMaybe<ContractComments_Arr_Rel_Insert_Input>;
  EmailEvents?: InputMaybe<Email_Events_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  IdentityComments?: InputMaybe<IdentityComments_Arr_Rel_Insert_Input>;
  JobComments?: InputMaybe<JobComments_Arr_Rel_Insert_Input>;
  RenderedEmail?: InputMaybe<RenderedEmails_Obj_Rel_Insert_Input>;
  activitylogtype_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_object?: InputMaybe<Scalars['jsonb']>;
  duration?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CommentTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Comments_Max_Fields = {
  __typename?: 'Comments_max_fields';
  activitylogtype_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  rendered_email_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Comments" */
export type Comments_Max_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Min_Fields = {
  __typename?: 'Comments_min_fields';
  activitylogtype_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  rendered_email_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Comments" */
export type Comments_Min_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Comments" */
export type Comments_Mutation_Response = {
  __typename?: 'Comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Comments>;
};

/** input type for inserting object relation for remote table "Comments" */
export type Comments_Obj_Rel_Insert_Input = {
  data: Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** on_conflict condition type for table "Comments" */
export type Comments_On_Conflict = {
  constraint: Comments_Constraint;
  update_columns?: Array<Comments_Update_Column>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "Comments". */
export type Comments_Order_By = {
  ActivityLogType?: InputMaybe<ActivityLogTypes_Order_By>;
  Author?: InputMaybe<Identities_Order_By>;
  AuthorDeleted?: InputMaybe<Deleted_Ppl_Names_Order_By>;
  BoardSnippet?: InputMaybe<BoardSnippets_Order_By>;
  CommentType?: InputMaybe<CommentTypes_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  ContractComments_aggregate?: InputMaybe<ContractComments_Aggregate_Order_By>;
  EmailEvents_aggregate?: InputMaybe<Email_Events_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  IdentityComments_aggregate?: InputMaybe<IdentityComments_Aggregate_Order_By>;
  JobComments_aggregate?: InputMaybe<JobComments_Aggregate_Order_By>;
  RenderedEmail?: InputMaybe<RenderedEmails_Order_By>;
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_object?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  pinned?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Comments */
export type Comments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Comments_Prepend_Input = {
  data_object?: InputMaybe<Scalars['jsonb']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Comments" */
export enum Comments_Select_Column {
  /** column name */
  ActivitylogtypeId = 'activitylogtype_id',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataObject = 'data_object',
  /** column name */
  Duration = 'duration',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  RenderedEmailId = 'rendered_email_id',
  /** column name */
  Text = 'text',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "Comments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Comments" */
export enum Comments_Select_Column_Comments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Pinned = 'pinned'
}

/** select "Comments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Comments" */
export enum Comments_Select_Column_Comments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Pinned = 'pinned'
}

/** input type for updating data in table "Comments" */
export type Comments_Set_Input = {
  activitylogtype_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_object?: InputMaybe<Scalars['jsonb']>;
  duration?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CommentTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Comments_Stddev_Fields = {
  __typename?: 'Comments_stddev_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  rendered_email_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Comments" */
export type Comments_Stddev_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comments_Stddev_Pop_Fields = {
  __typename?: 'Comments_stddev_pop_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  rendered_email_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Comments" */
export type Comments_Stddev_Pop_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comments_Stddev_Samp_Fields = {
  __typename?: 'Comments_stddev_samp_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  rendered_email_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Comments" */
export type Comments_Stddev_Samp_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Comments" */
export type Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Stream_Cursor_Value_Input = {
  activitylogtype_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_object?: InputMaybe<Scalars['jsonb']>;
  duration?: InputMaybe<Scalars['Int']>;
  email_id?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  rendered_email_id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CommentTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Comments_Sum_Fields = {
  __typename?: 'Comments_sum_fields';
  activitylogtype_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  rendered_email_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Comments" */
export type Comments_Sum_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Comments" */
export enum Comments_Update_Column {
  /** column name */
  ActivitylogtypeId = 'activitylogtype_id',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataObject = 'data_object',
  /** column name */
  Duration = 'duration',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  Text = 'text',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Comments_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Comments_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Comments_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Comments_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Comments_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Comments_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Comments_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Comments_Var_Pop_Fields = {
  __typename?: 'Comments_var_pop_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  rendered_email_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Comments" */
export type Comments_Var_Pop_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comments_Var_Samp_Fields = {
  __typename?: 'Comments_var_samp_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  rendered_email_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Comments" */
export type Comments_Var_Samp_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Comments_Variance_Fields = {
  __typename?: 'Comments_variance_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  rendered_email_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Comments" */
export type Comments_Variance_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  rendered_email_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "Companies" */
export type Companies = {
  __typename?: 'Companies';
  /** An array relationship */
  Assignments: Array<Assignments>;
  /** An aggregate relationship */
  Assignments_aggregate: Assignments_Aggregate;
  /** An array relationship */
  Attachments: Array<Attachments>;
  /** An aggregate relationship */
  Attachments_aggregate: Attachments_Aggregate;
  /** An array relationship */
  AuditCreates: Array<AuditCreates>;
  /** An aggregate relationship */
  AuditCreates_aggregate: AuditCreates_Aggregate;
  /** An array relationship */
  AuditStages: Array<AuditStages>;
  /** An aggregate relationship */
  AuditStages_aggregate: AuditStages_Aggregate;
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** An array relationship */
  Calculations: Array<Calculations>;
  /** An aggregate relationship */
  Calculations_aggregate: Calculations_Aggregate;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  CompanyEvents: Array<CompanyEvents>;
  /** An aggregate relationship */
  CompanyEvents_aggregate: CompanyEvents_Aggregate;
  /** An object relationship */
  CompanyStatus?: Maybe<CompanyStatusEnum>;
  /** An array relationship */
  Contracts: Array<Contracts>;
  /** An aggregate relationship */
  Contracts_aggregate: Contracts_Aggregate;
  /** An array relationship */
  EarnedBadges: Array<EarnedBadges>;
  /** An aggregate relationship */
  EarnedBadges_aggregate: EarnedBadges_Aggregate;
  /** An array relationship */
  Emails: Array<Emails>;
  /** An aggregate relationship */
  Emails_aggregate: Emails_Aggregate;
  /** An array relationship */
  Events: Array<Events>;
  /** An aggregate relationship */
  Events_aggregate: Events_Aggregate;
  /** An array relationship */
  Files: Array<Files>;
  /** An aggregate relationship */
  Files_aggregate: Files_Aggregate;
  /** An array relationship */
  Goals: Array<Goals>;
  /** An aggregate relationship */
  Goals_aggregate: Goals_Aggregate;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An array relationship */
  Jobs: Array<Jobs>;
  /** An aggregate relationship */
  Jobs_aggregate: Jobs_Aggregate;
  /** An array relationship */
  Resumes: Array<Resumes>;
  /** An aggregate relationship */
  Resumes_aggregate: Resumes_Aggregate;
  /** An array relationship */
  Roles: Array<Roles>;
  /** An aggregate relationship */
  Roles_aggregate: Roles_Aggregate;
  /** An array relationship */
  Stages: Array<Stages>;
  /** An aggregate relationship */
  Stages_aggregate: Stages_Aggregate;
  /** An array relationship */
  TableConfigs: Array<TableConfigs>;
  /** An aggregate relationship */
  TableConfigs_aggregate: TableConfigs_Aggregate;
  /** An array relationship */
  TimelineEvents: Array<TimelineEvents>;
  /** An aggregate relationship */
  TimelineEvents_aggregate: TimelineEvents_Aggregate;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  auto_unpublish_jobs: Scalars['Boolean'];
  benefit_start_date?: Maybe<Scalars['date']>;
  benefit_tracker_config?: Maybe<Scalars['Boolean']>;
  calc_config: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  company_address?: Maybe<Scalars['json']>;
  company_phone?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  default_access_control: Scalars['jsonb'];
  default_job_hire_type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  ds_impersonated_user_guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  poc_id?: Maybe<Scalars['Int']>;
  provisioned_users?: Maybe<Scalars['Int']>;
  public_metadata?: Maybe<Scalars['jsonb']>;
  slug?: Maybe<Scalars['String']>;
  sponsoring_applicants: Scalars['Boolean'];
  startOfCompanyYear?: Maybe<Scalars['date']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<CompanyStatusEnum_Enum>;
  street?: Maybe<Scalars['String']>;
  street_additional?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_capacity?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Companies" */
export type CompaniesAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesAuditCreatesArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesAuditCreates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesAuditStagesArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesAuditStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesCalculationsArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesCompanyEventsArgs = {
  distinct_on?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyEvents_Order_By>>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesCompanyEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyEvents_Order_By>>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesContractsArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesEarnedBadgesArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesEarnedBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesResumesArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesResumes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesStagesArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesTableConfigsArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesTableConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesTimelineEventsArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesTimelineEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "Companies" */
export type CompaniesCompany_AddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Companies" */
export type CompaniesDefault_Access_ControlArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Companies" */
export type CompaniesPublic_MetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Companies" */
export type Companies_Aggregate = {
  __typename?: 'Companies_aggregate';
  aggregate?: Maybe<Companies_Aggregate_Fields>;
  nodes: Array<Companies>;
};

/** aggregate fields of "Companies" */
export type Companies_Aggregate_Fields = {
  __typename?: 'Companies_aggregate_fields';
  avg?: Maybe<Companies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Companies_Max_Fields>;
  min?: Maybe<Companies_Min_Fields>;
  stddev?: Maybe<Companies_Stddev_Fields>;
  stddev_pop?: Maybe<Companies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Companies_Stddev_Samp_Fields>;
  sum?: Maybe<Companies_Sum_Fields>;
  var_pop?: Maybe<Companies_Var_Pop_Fields>;
  var_samp?: Maybe<Companies_Var_Samp_Fields>;
  variance?: Maybe<Companies_Variance_Fields>;
};


/** aggregate fields of "Companies" */
export type Companies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Companies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Companies_Append_Input = {
  default_access_control?: InputMaybe<Scalars['jsonb']>;
  public_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Companies_Avg_Fields = {
  __typename?: 'Companies_avg_fields';
  id?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  provisioned_users?: Maybe<Scalars['Float']>;
  user_capacity?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Companies". All fields are combined with a logical 'AND'. */
export type Companies_Bool_Exp = {
  Assignments?: InputMaybe<Assignments_Bool_Exp>;
  Assignments_aggregate?: InputMaybe<Assignments_Aggregate_Bool_Exp>;
  Attachments?: InputMaybe<Attachments_Bool_Exp>;
  Attachments_aggregate?: InputMaybe<Attachments_Aggregate_Bool_Exp>;
  AuditCreates?: InputMaybe<AuditCreates_Bool_Exp>;
  AuditCreates_aggregate?: InputMaybe<AuditCreates_Aggregate_Bool_Exp>;
  AuditStages?: InputMaybe<AuditStages_Bool_Exp>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  BoardSnippets?: InputMaybe<BoardSnippets_Bool_Exp>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp>;
  Calculations?: InputMaybe<Calculations_Bool_Exp>;
  Calculations_aggregate?: InputMaybe<Calculations_Aggregate_Bool_Exp>;
  Comments?: InputMaybe<Comments_Bool_Exp>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  CompanyEvents?: InputMaybe<CompanyEvents_Bool_Exp>;
  CompanyEvents_aggregate?: InputMaybe<CompanyEvents_Aggregate_Bool_Exp>;
  CompanyStatus?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
  Contracts?: InputMaybe<Contracts_Bool_Exp>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Bool_Exp>;
  EarnedBadges?: InputMaybe<EarnedBadges_Bool_Exp>;
  EarnedBadges_aggregate?: InputMaybe<EarnedBadges_Aggregate_Bool_Exp>;
  Emails?: InputMaybe<Emails_Bool_Exp>;
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Bool_Exp>;
  Events?: InputMaybe<Events_Bool_Exp>;
  Events_aggregate?: InputMaybe<Events_Aggregate_Bool_Exp>;
  Files?: InputMaybe<Files_Bool_Exp>;
  Files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  Goals?: InputMaybe<Goals_Bool_Exp>;
  Goals_aggregate?: InputMaybe<Goals_Aggregate_Bool_Exp>;
  Identities?: InputMaybe<Identities_Bool_Exp>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Jobs?: InputMaybe<Jobs_Bool_Exp>;
  Jobs_aggregate?: InputMaybe<Jobs_Aggregate_Bool_Exp>;
  Resumes?: InputMaybe<Resumes_Bool_Exp>;
  Resumes_aggregate?: InputMaybe<Resumes_Aggregate_Bool_Exp>;
  Roles?: InputMaybe<Roles_Bool_Exp>;
  Roles_aggregate?: InputMaybe<Roles_Aggregate_Bool_Exp>;
  Stages?: InputMaybe<Stages_Bool_Exp>;
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Bool_Exp>;
  TableConfigs?: InputMaybe<TableConfigs_Bool_Exp>;
  TableConfigs_aggregate?: InputMaybe<TableConfigs_Aggregate_Bool_Exp>;
  TimelineEvents?: InputMaybe<TimelineEvents_Bool_Exp>;
  TimelineEvents_aggregate?: InputMaybe<TimelineEvents_Aggregate_Bool_Exp>;
  Users?: InputMaybe<Users_Bool_Exp>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Companies_Bool_Exp>>;
  _not?: InputMaybe<Companies_Bool_Exp>;
  _or?: InputMaybe<Array<Companies_Bool_Exp>>;
  auto_unpublish_jobs?: InputMaybe<Boolean_Comparison_Exp>;
  benefit_start_date?: InputMaybe<Date_Comparison_Exp>;
  benefit_tracker_config?: InputMaybe<Boolean_Comparison_Exp>;
  calc_config?: InputMaybe<Boolean_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  company_address?: InputMaybe<Json_Comparison_Exp>;
  company_phone?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_access_control?: InputMaybe<Jsonb_Comparison_Exp>;
  default_job_hire_type?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ds_impersonated_user_guid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  poc_id?: InputMaybe<Int_Comparison_Exp>;
  provisioned_users?: InputMaybe<Int_Comparison_Exp>;
  public_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sponsoring_applicants?: InputMaybe<Boolean_Comparison_Exp>;
  startOfCompanyYear?: InputMaybe<Date_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<CompanyStatusEnum_Enum_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  street_additional?: InputMaybe<String_Comparison_Exp>;
  subdomain?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_capacity?: InputMaybe<Int_Comparison_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Companies" */
export enum Companies_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompaniesPkey = 'Companies_pkey',
  /** unique or primary key constraint on columns "subdomain" */
  CompaniesSubdomainKey = 'Companies_subdomain_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Companies_Delete_At_Path_Input = {
  default_access_control?: InputMaybe<Array<Scalars['String']>>;
  public_metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Companies_Delete_Elem_Input = {
  default_access_control?: InputMaybe<Scalars['Int']>;
  public_metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Companies_Delete_Key_Input = {
  default_access_control?: InputMaybe<Scalars['String']>;
  public_metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Companies" */
export type Companies_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  provisioned_users?: InputMaybe<Scalars['Int']>;
  user_capacity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Companies" */
export type Companies_Insert_Input = {
  Assignments?: InputMaybe<Assignments_Arr_Rel_Insert_Input>;
  Attachments?: InputMaybe<Attachments_Arr_Rel_Insert_Input>;
  AuditCreates?: InputMaybe<AuditCreates_Arr_Rel_Insert_Input>;
  AuditStages?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  BoardSnippets?: InputMaybe<BoardSnippets_Arr_Rel_Insert_Input>;
  Calculations?: InputMaybe<Calculations_Arr_Rel_Insert_Input>;
  Comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  CompanyEvents?: InputMaybe<CompanyEvents_Arr_Rel_Insert_Input>;
  CompanyStatus?: InputMaybe<CompanyStatusEnum_Obj_Rel_Insert_Input>;
  Contracts?: InputMaybe<Contracts_Arr_Rel_Insert_Input>;
  EarnedBadges?: InputMaybe<EarnedBadges_Arr_Rel_Insert_Input>;
  Emails?: InputMaybe<Emails_Arr_Rel_Insert_Input>;
  Events?: InputMaybe<Events_Arr_Rel_Insert_Input>;
  Files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  Goals?: InputMaybe<Goals_Arr_Rel_Insert_Input>;
  Identities?: InputMaybe<Identities_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Jobs?: InputMaybe<Jobs_Arr_Rel_Insert_Input>;
  Resumes?: InputMaybe<Resumes_Arr_Rel_Insert_Input>;
  Roles?: InputMaybe<Roles_Arr_Rel_Insert_Input>;
  Stages?: InputMaybe<Stages_Arr_Rel_Insert_Input>;
  TableConfigs?: InputMaybe<TableConfigs_Arr_Rel_Insert_Input>;
  TimelineEvents?: InputMaybe<TimelineEvents_Arr_Rel_Insert_Input>;
  Users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  auto_unpublish_jobs?: InputMaybe<Scalars['Boolean']>;
  benefit_start_date?: InputMaybe<Scalars['date']>;
  benefit_tracker_config?: InputMaybe<Scalars['Boolean']>;
  calc_config?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  company_address?: InputMaybe<Scalars['json']>;
  company_phone?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_access_control?: InputMaybe<Scalars['jsonb']>;
  default_job_hire_type?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ds_impersonated_user_guid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  provisioned_users?: InputMaybe<Scalars['Int']>;
  public_metadata?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  sponsoring_applicants?: InputMaybe<Scalars['Boolean']>;
  startOfCompanyYear?: InputMaybe<Scalars['date']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CompanyStatusEnum_Enum>;
  street?: InputMaybe<Scalars['String']>;
  street_additional?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_capacity?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Companies_Max_Fields = {
  __typename?: 'Companies_max_fields';
  benefit_start_date?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  company_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_job_hire_type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ds_impersonated_user_guid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  poc_id?: Maybe<Scalars['Int']>;
  provisioned_users?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  startOfCompanyYear?: Maybe<Scalars['date']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_additional?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_capacity?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Companies_Min_Fields = {
  __typename?: 'Companies_min_fields';
  benefit_start_date?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  company_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_job_hire_type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ds_impersonated_user_guid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  poc_id?: Maybe<Scalars['Int']>;
  provisioned_users?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  startOfCompanyYear?: Maybe<Scalars['date']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_additional?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_capacity?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Companies" */
export type Companies_Mutation_Response = {
  __typename?: 'Companies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Companies>;
};

/** input type for inserting object relation for remote table "Companies" */
export type Companies_Obj_Rel_Insert_Input = {
  data: Companies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};

/** on_conflict condition type for table "Companies" */
export type Companies_On_Conflict = {
  constraint: Companies_Constraint;
  update_columns?: Array<Companies_Update_Column>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

/** Ordering options when selecting data from "Companies". */
export type Companies_Order_By = {
  Assignments_aggregate?: InputMaybe<Assignments_Aggregate_Order_By>;
  Attachments_aggregate?: InputMaybe<Attachments_Aggregate_Order_By>;
  AuditCreates_aggregate?: InputMaybe<AuditCreates_Aggregate_Order_By>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Order_By>;
  Calculations_aggregate?: InputMaybe<Calculations_Aggregate_Order_By>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  CompanyEvents_aggregate?: InputMaybe<CompanyEvents_Aggregate_Order_By>;
  CompanyStatus?: InputMaybe<CompanyStatusEnum_Order_By>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Order_By>;
  EarnedBadges_aggregate?: InputMaybe<EarnedBadges_Aggregate_Order_By>;
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Order_By>;
  Events_aggregate?: InputMaybe<Events_Aggregate_Order_By>;
  Files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  Goals_aggregate?: InputMaybe<Goals_Aggregate_Order_By>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Jobs_aggregate?: InputMaybe<Jobs_Aggregate_Order_By>;
  Resumes_aggregate?: InputMaybe<Resumes_Aggregate_Order_By>;
  Roles_aggregate?: InputMaybe<Roles_Aggregate_Order_By>;
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Order_By>;
  TableConfigs_aggregate?: InputMaybe<TableConfigs_Aggregate_Order_By>;
  TimelineEvents_aggregate?: InputMaybe<TimelineEvents_Aggregate_Order_By>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  auto_unpublish_jobs?: InputMaybe<Order_By>;
  benefit_start_date?: InputMaybe<Order_By>;
  benefit_tracker_config?: InputMaybe<Order_By>;
  calc_config?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  company_address?: InputMaybe<Order_By>;
  company_phone?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_access_control?: InputMaybe<Order_By>;
  default_job_hire_type?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ds_impersonated_user_guid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  provisioned_users?: InputMaybe<Order_By>;
  public_metadata?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sponsoring_applicants?: InputMaybe<Order_By>;
  startOfCompanyYear?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_additional?: InputMaybe<Order_By>;
  subdomain?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_capacity?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Companies */
export type Companies_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Companies_Prepend_Input = {
  default_access_control?: InputMaybe<Scalars['jsonb']>;
  public_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Companies" */
export enum Companies_Select_Column {
  /** column name */
  AutoUnpublishJobs = 'auto_unpublish_jobs',
  /** column name */
  BenefitStartDate = 'benefit_start_date',
  /** column name */
  BenefitTrackerConfig = 'benefit_tracker_config',
  /** column name */
  CalcConfig = 'calc_config',
  /** column name */
  City = 'city',
  /** column name */
  CompanyAddress = 'company_address',
  /** column name */
  CompanyPhone = 'company_phone',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAccessControl = 'default_access_control',
  /** column name */
  DefaultJobHireType = 'default_job_hire_type',
  /** column name */
  Description = 'description',
  /** column name */
  DsImpersonatedUserGuid = 'ds_impersonated_user_guid',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  PocId = 'poc_id',
  /** column name */
  ProvisionedUsers = 'provisioned_users',
  /** column name */
  PublicMetadata = 'public_metadata',
  /** column name */
  Slug = 'slug',
  /** column name */
  SponsoringApplicants = 'sponsoring_applicants',
  /** column name */
  StartOfCompanyYear = 'startOfCompanyYear',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Street = 'street',
  /** column name */
  StreetAdditional = 'street_additional',
  /** column name */
  Subdomain = 'subdomain',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCapacity = 'user_capacity',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "Companies" */
export type Companies_Set_Input = {
  auto_unpublish_jobs?: InputMaybe<Scalars['Boolean']>;
  benefit_start_date?: InputMaybe<Scalars['date']>;
  benefit_tracker_config?: InputMaybe<Scalars['Boolean']>;
  calc_config?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  company_address?: InputMaybe<Scalars['json']>;
  company_phone?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_access_control?: InputMaybe<Scalars['jsonb']>;
  default_job_hire_type?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ds_impersonated_user_guid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  provisioned_users?: InputMaybe<Scalars['Int']>;
  public_metadata?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  sponsoring_applicants?: InputMaybe<Scalars['Boolean']>;
  startOfCompanyYear?: InputMaybe<Scalars['date']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CompanyStatusEnum_Enum>;
  street?: InputMaybe<Scalars['String']>;
  street_additional?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_capacity?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Companies_Stddev_Fields = {
  __typename?: 'Companies_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  provisioned_users?: Maybe<Scalars['Float']>;
  user_capacity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Companies_Stddev_Pop_Fields = {
  __typename?: 'Companies_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  provisioned_users?: Maybe<Scalars['Float']>;
  user_capacity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Companies_Stddev_Samp_Fields = {
  __typename?: 'Companies_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  provisioned_users?: Maybe<Scalars['Float']>;
  user_capacity?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Companies" */
export type Companies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Companies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Companies_Stream_Cursor_Value_Input = {
  auto_unpublish_jobs?: InputMaybe<Scalars['Boolean']>;
  benefit_start_date?: InputMaybe<Scalars['date']>;
  benefit_tracker_config?: InputMaybe<Scalars['Boolean']>;
  calc_config?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  company_address?: InputMaybe<Scalars['json']>;
  company_phone?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_access_control?: InputMaybe<Scalars['jsonb']>;
  default_job_hire_type?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ds_impersonated_user_guid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  provisioned_users?: InputMaybe<Scalars['Int']>;
  public_metadata?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  sponsoring_applicants?: InputMaybe<Scalars['Boolean']>;
  startOfCompanyYear?: InputMaybe<Scalars['date']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CompanyStatusEnum_Enum>;
  street?: InputMaybe<Scalars['String']>;
  street_additional?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_capacity?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Companies_Sum_Fields = {
  __typename?: 'Companies_sum_fields';
  id?: Maybe<Scalars['Int']>;
  poc_id?: Maybe<Scalars['Int']>;
  provisioned_users?: Maybe<Scalars['Int']>;
  user_capacity?: Maybe<Scalars['Int']>;
};

/** update columns of table "Companies" */
export enum Companies_Update_Column {
  /** column name */
  AutoUnpublishJobs = 'auto_unpublish_jobs',
  /** column name */
  BenefitStartDate = 'benefit_start_date',
  /** column name */
  BenefitTrackerConfig = 'benefit_tracker_config',
  /** column name */
  CalcConfig = 'calc_config',
  /** column name */
  City = 'city',
  /** column name */
  CompanyAddress = 'company_address',
  /** column name */
  CompanyPhone = 'company_phone',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAccessControl = 'default_access_control',
  /** column name */
  DefaultJobHireType = 'default_job_hire_type',
  /** column name */
  Description = 'description',
  /** column name */
  DsImpersonatedUserGuid = 'ds_impersonated_user_guid',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  PocId = 'poc_id',
  /** column name */
  ProvisionedUsers = 'provisioned_users',
  /** column name */
  PublicMetadata = 'public_metadata',
  /** column name */
  Slug = 'slug',
  /** column name */
  SponsoringApplicants = 'sponsoring_applicants',
  /** column name */
  StartOfCompanyYear = 'startOfCompanyYear',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Street = 'street',
  /** column name */
  StreetAdditional = 'street_additional',
  /** column name */
  Subdomain = 'subdomain',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCapacity = 'user_capacity',
  /** column name */
  Zipcode = 'zipcode'
}

export type Companies_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Companies_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Companies_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Companies_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Companies_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Companies_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Companies_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Companies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Companies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Companies_Var_Pop_Fields = {
  __typename?: 'Companies_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  provisioned_users?: Maybe<Scalars['Float']>;
  user_capacity?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Companies_Var_Samp_Fields = {
  __typename?: 'Companies_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  provisioned_users?: Maybe<Scalars['Float']>;
  user_capacity?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Companies_Variance_Fields = {
  __typename?: 'Companies_variance_fields';
  id?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  provisioned_users?: Maybe<Scalars['Float']>;
  user_capacity?: Maybe<Scalars['Float']>;
};

export type CompanyConfigResult = {
  __typename?: 'CompanyConfigResult';
  message: Scalars['String'];
};

/** columns and relationships of "CompanyEvents" */
export type CompanyEvents = {
  __typename?: 'CompanyEvents';
  /** An object relationship */
  Company?: Maybe<Companies>;
  city?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "CompanyEvents" */
export type CompanyEvents_Aggregate = {
  __typename?: 'CompanyEvents_aggregate';
  aggregate?: Maybe<CompanyEvents_Aggregate_Fields>;
  nodes: Array<CompanyEvents>;
};

export type CompanyEvents_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompanyEvents_Aggregate_Bool_Exp_Count>;
};

export type CompanyEvents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompanyEvents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CompanyEvents" */
export type CompanyEvents_Aggregate_Fields = {
  __typename?: 'CompanyEvents_aggregate_fields';
  avg?: Maybe<CompanyEvents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CompanyEvents_Max_Fields>;
  min?: Maybe<CompanyEvents_Min_Fields>;
  stddev?: Maybe<CompanyEvents_Stddev_Fields>;
  stddev_pop?: Maybe<CompanyEvents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompanyEvents_Stddev_Samp_Fields>;
  sum?: Maybe<CompanyEvents_Sum_Fields>;
  var_pop?: Maybe<CompanyEvents_Var_Pop_Fields>;
  var_samp?: Maybe<CompanyEvents_Var_Samp_Fields>;
  variance?: Maybe<CompanyEvents_Variance_Fields>;
};


/** aggregate fields of "CompanyEvents" */
export type CompanyEvents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CompanyEvents" */
export type CompanyEvents_Aggregate_Order_By = {
  avg?: InputMaybe<CompanyEvents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompanyEvents_Max_Order_By>;
  min?: InputMaybe<CompanyEvents_Min_Order_By>;
  stddev?: InputMaybe<CompanyEvents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompanyEvents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompanyEvents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompanyEvents_Sum_Order_By>;
  var_pop?: InputMaybe<CompanyEvents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompanyEvents_Var_Samp_Order_By>;
  variance?: InputMaybe<CompanyEvents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CompanyEvents" */
export type CompanyEvents_Arr_Rel_Insert_Input = {
  data: Array<CompanyEvents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompanyEvents_On_Conflict>;
};

/** aggregate avg on columns */
export type CompanyEvents_Avg_Fields = {
  __typename?: 'CompanyEvents_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CompanyEvents" */
export type CompanyEvents_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompanyEvents". All fields are combined with a logical 'AND'. */
export type CompanyEvents_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  _and?: InputMaybe<Array<CompanyEvents_Bool_Exp>>;
  _not?: InputMaybe<CompanyEvents_Bool_Exp>;
  _or?: InputMaybe<Array<CompanyEvents_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompanyEvents" */
export enum CompanyEvents_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyEventsPkey = 'CompanyEvents_pkey'
}

/** input type for incrementing numeric columns in table "CompanyEvents" */
export type CompanyEvents_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CompanyEvents" */
export type CompanyEvents_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  city?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CompanyEvents_Max_Fields = {
  __typename?: 'CompanyEvents_max_fields';
  city?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "CompanyEvents" */
export type CompanyEvents_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompanyEvents_Min_Fields = {
  __typename?: 'CompanyEvents_min_fields';
  city?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "CompanyEvents" */
export type CompanyEvents_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CompanyEvents" */
export type CompanyEvents_Mutation_Response = {
  __typename?: 'CompanyEvents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyEvents>;
};

/** on_conflict condition type for table "CompanyEvents" */
export type CompanyEvents_On_Conflict = {
  constraint: CompanyEvents_Constraint;
  update_columns?: Array<CompanyEvents_Update_Column>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};

/** Ordering options when selecting data from "CompanyEvents". */
export type CompanyEvents_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  city?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CompanyEvents */
export type CompanyEvents_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "CompanyEvents" */
export enum CompanyEvents_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Location = 'location',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CompanyEvents" */
export type CompanyEvents_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CompanyEvents_Stddev_Fields = {
  __typename?: 'CompanyEvents_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CompanyEvents" */
export type CompanyEvents_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompanyEvents_Stddev_Pop_Fields = {
  __typename?: 'CompanyEvents_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CompanyEvents" */
export type CompanyEvents_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompanyEvents_Stddev_Samp_Fields = {
  __typename?: 'CompanyEvents_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CompanyEvents" */
export type CompanyEvents_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CompanyEvents" */
export type CompanyEvents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompanyEvents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompanyEvents_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CompanyEvents_Sum_Fields = {
  __typename?: 'CompanyEvents_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CompanyEvents" */
export type CompanyEvents_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "CompanyEvents" */
export enum CompanyEvents_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Location = 'location',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CompanyEvents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompanyEvents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompanyEvents_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompanyEvents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompanyEvents_Var_Pop_Fields = {
  __typename?: 'CompanyEvents_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CompanyEvents" */
export type CompanyEvents_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompanyEvents_Var_Samp_Fields = {
  __typename?: 'CompanyEvents_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CompanyEvents" */
export type CompanyEvents_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompanyEvents_Variance_Fields = {
  __typename?: 'CompanyEvents_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CompanyEvents" */
export type CompanyEvents_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Current Status of the Company  */
export type CompanyStatusEnum = {
  __typename?: 'CompanyStatusEnum';
  status: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "CompanyStatusEnum" */
export type CompanyStatusEnum_Aggregate = {
  __typename?: 'CompanyStatusEnum_aggregate';
  aggregate?: Maybe<CompanyStatusEnum_Aggregate_Fields>;
  nodes: Array<CompanyStatusEnum>;
};

/** aggregate fields of "CompanyStatusEnum" */
export type CompanyStatusEnum_Aggregate_Fields = {
  __typename?: 'CompanyStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompanyStatusEnum_Max_Fields>;
  min?: Maybe<CompanyStatusEnum_Min_Fields>;
};


/** aggregate fields of "CompanyStatusEnum" */
export type CompanyStatusEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompanyStatusEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CompanyStatusEnum". All fields are combined with a logical 'AND'. */
export type CompanyStatusEnum_Bool_Exp = {
  _and?: InputMaybe<Array<CompanyStatusEnum_Bool_Exp>>;
  _not?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
  _or?: InputMaybe<Array<CompanyStatusEnum_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompanyStatusEnum" */
export enum CompanyStatusEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  CompanyStatusEnumPkey = 'CompanyStatusEnum_pkey'
}

export enum CompanyStatusEnum_Enum {
  /** active */
  Active = 'active',
  /** onboarding */
  Onboarding = 'onboarding'
}

/** Boolean expression to compare columns of type "CompanyStatusEnum_enum". All fields are combined with logical 'AND'. */
export type CompanyStatusEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CompanyStatusEnum_Enum>;
  _in?: InputMaybe<Array<CompanyStatusEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CompanyStatusEnum_Enum>;
  _nin?: InputMaybe<Array<CompanyStatusEnum_Enum>>;
};

/** input type for inserting data into table "CompanyStatusEnum" */
export type CompanyStatusEnum_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CompanyStatusEnum_Max_Fields = {
  __typename?: 'CompanyStatusEnum_max_fields';
  status?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CompanyStatusEnum_Min_Fields = {
  __typename?: 'CompanyStatusEnum_min_fields';
  status?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "CompanyStatusEnum" */
export type CompanyStatusEnum_Mutation_Response = {
  __typename?: 'CompanyStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyStatusEnum>;
};

/** input type for inserting object relation for remote table "CompanyStatusEnum" */
export type CompanyStatusEnum_Obj_Rel_Insert_Input = {
  data: CompanyStatusEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CompanyStatusEnum_On_Conflict>;
};

/** on_conflict condition type for table "CompanyStatusEnum" */
export type CompanyStatusEnum_On_Conflict = {
  constraint: CompanyStatusEnum_Constraint;
  update_columns?: Array<CompanyStatusEnum_Update_Column>;
  where?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "CompanyStatusEnum". */
export type CompanyStatusEnum_Order_By = {
  status?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CompanyStatusEnum */
export type CompanyStatusEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "CompanyStatusEnum" */
export enum CompanyStatusEnum_Select_Column {
  /** column name */
  Status = 'status',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CompanyStatusEnum" */
export type CompanyStatusEnum_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CompanyStatusEnum" */
export type CompanyStatusEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompanyStatusEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompanyStatusEnum_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CompanyStatusEnum" */
export enum CompanyStatusEnum_Update_Column {
  /** column name */
  Status = 'status',
  /** column name */
  Value = 'value'
}

export type CompanyStatusEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompanyStatusEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompanyStatusEnum_Bool_Exp;
};

/** columns and relationships of "Compensations" */
export type Compensations = {
  __typename?: 'Compensations';
  /** An object relationship */
  Identity: Identities;
  amount: Scalars['money'];
  bill_rate?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  deleted: Scalars['Boolean'];
  effective_date: Scalars['date'];
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  reason: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: EmploymentTypes_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Compensations" */
export type Compensations_Aggregate = {
  __typename?: 'Compensations_aggregate';
  aggregate?: Maybe<Compensations_Aggregate_Fields>;
  nodes: Array<Compensations>;
};

export type Compensations_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Compensations_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Compensations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Compensations_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Compensations_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Compensations_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Compensations_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Compensations_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Compensations_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Compensations_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Compensations_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Compensations_Aggregate_Bool_Exp_Var_Samp>;
};

export type Compensations_Aggregate_Bool_Exp_Avg = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Corr = {
  arguments: Compensations_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Compensations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Compensations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Compensations_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Compensations_Aggregate_Bool_Exp_Max = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Min = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Sum = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Compensations_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Compensations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "Compensations" */
export type Compensations_Aggregate_Fields = {
  __typename?: 'Compensations_aggregate_fields';
  avg?: Maybe<Compensations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Compensations_Max_Fields>;
  min?: Maybe<Compensations_Min_Fields>;
  stddev?: Maybe<Compensations_Stddev_Fields>;
  stddev_pop?: Maybe<Compensations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Compensations_Stddev_Samp_Fields>;
  sum?: Maybe<Compensations_Sum_Fields>;
  var_pop?: Maybe<Compensations_Var_Pop_Fields>;
  var_samp?: Maybe<Compensations_Var_Samp_Fields>;
  variance?: Maybe<Compensations_Variance_Fields>;
};


/** aggregate fields of "Compensations" */
export type Compensations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compensations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Compensations" */
export type Compensations_Aggregate_Order_By = {
  avg?: InputMaybe<Compensations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compensations_Max_Order_By>;
  min?: InputMaybe<Compensations_Min_Order_By>;
  stddev?: InputMaybe<Compensations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Compensations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Compensations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Compensations_Sum_Order_By>;
  var_pop?: InputMaybe<Compensations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Compensations_Var_Samp_Order_By>;
  variance?: InputMaybe<Compensations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Compensations" */
export type Compensations_Arr_Rel_Insert_Input = {
  data: Array<Compensations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compensations_On_Conflict>;
};

/** aggregate avg on columns */
export type Compensations_Avg_Fields = {
  __typename?: 'Compensations_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Compensations" */
export type Compensations_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Compensations". All fields are combined with a logical 'AND'. */
export type Compensations_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Compensations_Bool_Exp>>;
  _not?: InputMaybe<Compensations_Bool_Exp>;
  _or?: InputMaybe<Array<Compensations_Bool_Exp>>;
  amount?: InputMaybe<Money_Comparison_Exp>;
  bill_rate?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  effective_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<EmploymentTypes_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Compensations" */
export enum Compensations_Constraint {
  /** unique or primary key constraint on columns "id" */
  SalariesPkey = 'Salaries_pkey'
}

/** input type for incrementing numeric columns in table "Compensations" */
export type Compensations_Inc_Input = {
  amount?: InputMaybe<Scalars['money']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Compensations" */
export type Compensations_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['money']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  effective_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmploymentTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compensations_Max_Fields = {
  __typename?: 'Compensations_max_fields';
  amount?: Maybe<Scalars['money']>;
  bill_rate?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Compensations" */
export type Compensations_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compensations_Min_Fields = {
  __typename?: 'Compensations_min_fields';
  amount?: Maybe<Scalars['money']>;
  bill_rate?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Compensations" */
export type Compensations_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Compensations" */
export type Compensations_Mutation_Response = {
  __typename?: 'Compensations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compensations>;
};

/** on_conflict condition type for table "Compensations" */
export type Compensations_On_Conflict = {
  constraint: Compensations_Constraint;
  update_columns?: Array<Compensations_Update_Column>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};

/** Ordering options when selecting data from "Compensations". */
export type Compensations_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Compensations */
export type Compensations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Compensations" */
export enum Compensations_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Compensations_aggregate_bool_exp_avg_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** select "Compensations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** select "Compensations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** select "Compensations_aggregate_bool_exp_corr_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** select "Compensations_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** select "Compensations_aggregate_bool_exp_max_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** select "Compensations_aggregate_bool_exp_min_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** select "Compensations_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** select "Compensations_aggregate_bool_exp_sum_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** select "Compensations_aggregate_bool_exp_var_samp_arguments_columns" columns of table "Compensations" */
export enum Compensations_Select_Column_Compensations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  BillRate = 'bill_rate'
}

/** input type for updating data in table "Compensations" */
export type Compensations_Set_Input = {
  amount?: InputMaybe<Scalars['money']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  effective_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmploymentTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Compensations_Stddev_Fields = {
  __typename?: 'Compensations_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Compensations" */
export type Compensations_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Compensations_Stddev_Pop_Fields = {
  __typename?: 'Compensations_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Compensations" */
export type Compensations_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Compensations_Stddev_Samp_Fields = {
  __typename?: 'Compensations_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Compensations" */
export type Compensations_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Compensations" */
export type Compensations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Compensations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Compensations_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['money']>;
  bill_rate?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  effective_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmploymentTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Compensations_Sum_Fields = {
  __typename?: 'Compensations_sum_fields';
  amount?: Maybe<Scalars['money']>;
  bill_rate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Compensations" */
export type Compensations_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "Compensations" */
export enum Compensations_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BillRate = 'bill_rate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Compensations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Compensations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Compensations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Compensations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Compensations_Var_Pop_Fields = {
  __typename?: 'Compensations_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Compensations" */
export type Compensations_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Compensations_Var_Samp_Fields = {
  __typename?: 'Compensations_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Compensations" */
export type Compensations_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Compensations_Variance_Fields = {
  __typename?: 'Compensations_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  bill_rate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Compensations" */
export type Compensations_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  bill_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

export type ComputeGSheetCalcResultsOutput = {
  __typename?: 'ComputeGSheetCalcResultsOutput';
  results: GSheetCalcResults;
};

export type ConfigResponse = {
  __typename?: 'ConfigResponse';
  message: Scalars['String'];
};

/** columns and relationships of "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum = {
  __typename?: 'ContactTemplateVariablesEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Aggregate = {
  __typename?: 'ContactTemplateVariablesEnum_aggregate';
  aggregate?: Maybe<ContactTemplateVariablesEnum_Aggregate_Fields>;
  nodes: Array<ContactTemplateVariablesEnum>;
};

/** aggregate fields of "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Aggregate_Fields = {
  __typename?: 'ContactTemplateVariablesEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContactTemplateVariablesEnum_Max_Fields>;
  min?: Maybe<ContactTemplateVariablesEnum_Min_Fields>;
};


/** aggregate fields of "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContactTemplateVariablesEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ContactTemplateVariablesEnum". All fields are combined with a logical 'AND'. */
export type ContactTemplateVariablesEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ContactTemplateVariablesEnum_Bool_Exp>>;
  _not?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ContactTemplateVariablesEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContactTemplateVariablesEnum" */
export enum ContactTemplateVariablesEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  ContactTemplateVariablesEnumPkey = 'ContactTemplateVariablesEnum_pkey'
}

export enum ContactTemplateVariablesEnum_Enum {
  /** Account Manager */
  AccountManager = 'ACCOUNT_MANAGER',
  /** Candidate Personal Email */
  CandidatePersonalEmail = 'CANDIDATE_PERSONAL_EMAIL',
  /** Employee Work Email */
  EmployeeWorkEmail = 'EMPLOYEE_WORK_EMAIL',
  /** Program Lead */
  ProgramLead = 'PROGRAM_LEAD',
  /** Resource Manager */
  ResourceManager = 'RESOURCE_MANAGER'
}

/** Boolean expression to compare columns of type "ContactTemplateVariablesEnum_enum". All fields are combined with logical 'AND'. */
export type ContactTemplateVariablesEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  _in?: InputMaybe<Array<ContactTemplateVariablesEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  _nin?: InputMaybe<Array<ContactTemplateVariablesEnum_Enum>>;
};

/** input type for inserting data into table "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContactTemplateVariablesEnum_Max_Fields = {
  __typename?: 'ContactTemplateVariablesEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContactTemplateVariablesEnum_Min_Fields = {
  __typename?: 'ContactTemplateVariablesEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Mutation_Response = {
  __typename?: 'ContactTemplateVariablesEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContactTemplateVariablesEnum>;
};

/** input type for inserting object relation for remote table "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Obj_Rel_Insert_Input = {
  data: ContactTemplateVariablesEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ContactTemplateVariablesEnum_On_Conflict>;
};

/** on_conflict condition type for table "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_On_Conflict = {
  constraint: ContactTemplateVariablesEnum_Constraint;
  update_columns?: Array<ContactTemplateVariablesEnum_Update_Column>;
  where?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ContactTemplateVariablesEnum". */
export type ContactTemplateVariablesEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContactTemplateVariablesEnum */
export type ContactTemplateVariablesEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "ContactTemplateVariablesEnum" */
export enum ContactTemplateVariablesEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ContactTemplateVariablesEnum" */
export type ContactTemplateVariablesEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContactTemplateVariablesEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactTemplateVariablesEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ContactTemplateVariablesEnum" */
export enum ContactTemplateVariablesEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type ContactTemplateVariablesEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContactTemplateVariablesEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContactTemplateVariablesEnum_Bool_Exp;
};

/** Join table between comments and contracts */
export type ContractComments = {
  __typename?: 'ContractComments';
  /** An object relationship */
  Comment: Comments;
  /** An object relationship */
  Contract: Contracts;
  comment_id: Scalars['Int'];
  contract_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ContractComments" */
export type ContractComments_Aggregate = {
  __typename?: 'ContractComments_aggregate';
  aggregate?: Maybe<ContractComments_Aggregate_Fields>;
  nodes: Array<ContractComments>;
};

export type ContractComments_Aggregate_Bool_Exp = {
  count?: InputMaybe<ContractComments_Aggregate_Bool_Exp_Count>;
};

export type ContractComments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContractComments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractComments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ContractComments" */
export type ContractComments_Aggregate_Fields = {
  __typename?: 'ContractComments_aggregate_fields';
  avg?: Maybe<ContractComments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContractComments_Max_Fields>;
  min?: Maybe<ContractComments_Min_Fields>;
  stddev?: Maybe<ContractComments_Stddev_Fields>;
  stddev_pop?: Maybe<ContractComments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContractComments_Stddev_Samp_Fields>;
  sum?: Maybe<ContractComments_Sum_Fields>;
  var_pop?: Maybe<ContractComments_Var_Pop_Fields>;
  var_samp?: Maybe<ContractComments_Var_Samp_Fields>;
  variance?: Maybe<ContractComments_Variance_Fields>;
};


/** aggregate fields of "ContractComments" */
export type ContractComments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractComments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ContractComments" */
export type ContractComments_Aggregate_Order_By = {
  avg?: InputMaybe<ContractComments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContractComments_Max_Order_By>;
  min?: InputMaybe<ContractComments_Min_Order_By>;
  stddev?: InputMaybe<ContractComments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ContractComments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ContractComments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ContractComments_Sum_Order_By>;
  var_pop?: InputMaybe<ContractComments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ContractComments_Var_Samp_Order_By>;
  variance?: InputMaybe<ContractComments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ContractComments" */
export type ContractComments_Arr_Rel_Insert_Input = {
  data: Array<ContractComments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractComments_On_Conflict>;
};

/** aggregate avg on columns */
export type ContractComments_Avg_Fields = {
  __typename?: 'ContractComments_avg_fields';
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ContractComments" */
export type ContractComments_Avg_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ContractComments". All fields are combined with a logical 'AND'. */
export type ContractComments_Bool_Exp = {
  Comment?: InputMaybe<Comments_Bool_Exp>;
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  _and?: InputMaybe<Array<ContractComments_Bool_Exp>>;
  _not?: InputMaybe<ContractComments_Bool_Exp>;
  _or?: InputMaybe<Array<ContractComments_Bool_Exp>>;
  comment_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContractComments" */
export enum ContractComments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContractCommentsPkey = 'ContractComments_pkey'
}

/** input type for incrementing numeric columns in table "ContractComments" */
export type ContractComments_Inc_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ContractComments" */
export type ContractComments_Insert_Input = {
  Comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContractComments_Max_Fields = {
  __typename?: 'ContractComments_max_fields';
  comment_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ContractComments" */
export type ContractComments_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContractComments_Min_Fields = {
  __typename?: 'ContractComments_min_fields';
  comment_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ContractComments" */
export type ContractComments_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ContractComments" */
export type ContractComments_Mutation_Response = {
  __typename?: 'ContractComments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractComments>;
};

/** on_conflict condition type for table "ContractComments" */
export type ContractComments_On_Conflict = {
  constraint: ContractComments_Constraint;
  update_columns?: Array<ContractComments_Update_Column>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};

/** Ordering options when selecting data from "ContractComments". */
export type ContractComments_Order_By = {
  Comment?: InputMaybe<Comments_Order_By>;
  Contract?: InputMaybe<Contracts_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContractComments */
export type ContractComments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ContractComments" */
export enum ContractComments_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ContractComments" */
export type ContractComments_Set_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ContractComments_Stddev_Fields = {
  __typename?: 'ContractComments_stddev_fields';
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ContractComments" */
export type ContractComments_Stddev_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContractComments_Stddev_Pop_Fields = {
  __typename?: 'ContractComments_stddev_pop_fields';
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ContractComments" */
export type ContractComments_Stddev_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContractComments_Stddev_Samp_Fields = {
  __typename?: 'ContractComments_stddev_samp_fields';
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ContractComments" */
export type ContractComments_Stddev_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ContractComments" */
export type ContractComments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractComments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractComments_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ContractComments_Sum_Fields = {
  __typename?: 'ContractComments_sum_fields';
  comment_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ContractComments" */
export type ContractComments_Sum_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ContractComments" */
export enum ContractComments_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ContractComments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ContractComments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractComments_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractComments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContractComments_Var_Pop_Fields = {
  __typename?: 'ContractComments_var_pop_fields';
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ContractComments" */
export type ContractComments_Var_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContractComments_Var_Samp_Fields = {
  __typename?: 'ContractComments_var_samp_fields';
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ContractComments" */
export type ContractComments_Var_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ContractComments_Variance_Fields = {
  __typename?: 'ContractComments_variance_fields';
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ContractComments" */
export type ContractComments_Variance_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Files connected to contracts */
export type ContractFiles = {
  __typename?: 'ContractFiles';
  /** An object relationship */
  Contract: Contracts;
  /** An object relationship */
  File: Files;
  contract_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id: Scalars['Int'];
  id: Scalars['uuid'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "ContractFiles" */
export type ContractFiles_Aggregate = {
  __typename?: 'ContractFiles_aggregate';
  aggregate?: Maybe<ContractFiles_Aggregate_Fields>;
  nodes: Array<ContractFiles>;
};

/** aggregate fields of "ContractFiles" */
export type ContractFiles_Aggregate_Fields = {
  __typename?: 'ContractFiles_aggregate_fields';
  avg?: Maybe<ContractFiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContractFiles_Max_Fields>;
  min?: Maybe<ContractFiles_Min_Fields>;
  stddev?: Maybe<ContractFiles_Stddev_Fields>;
  stddev_pop?: Maybe<ContractFiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContractFiles_Stddev_Samp_Fields>;
  sum?: Maybe<ContractFiles_Sum_Fields>;
  var_pop?: Maybe<ContractFiles_Var_Pop_Fields>;
  var_samp?: Maybe<ContractFiles_Var_Samp_Fields>;
  variance?: Maybe<ContractFiles_Variance_Fields>;
};


/** aggregate fields of "ContractFiles" */
export type ContractFiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ContractFiles_Avg_Fields = {
  __typename?: 'ContractFiles_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ContractFiles". All fields are combined with a logical 'AND'. */
export type ContractFiles_Bool_Exp = {
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  File?: InputMaybe<Files_Bool_Exp>;
  _and?: InputMaybe<Array<ContractFiles_Bool_Exp>>;
  _not?: InputMaybe<ContractFiles_Bool_Exp>;
  _or?: InputMaybe<Array<ContractFiles_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContractFiles" */
export enum ContractFiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContractFilesPkey = 'ContractFiles_pkey'
}

/** input type for incrementing numeric columns in table "ContractFiles" */
export type ContractFiles_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  file_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ContractFiles" */
export type ContractFiles_Insert_Input = {
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  File?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContractFiles_Max_Fields = {
  __typename?: 'ContractFiles_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ContractFiles_Min_Fields = {
  __typename?: 'ContractFiles_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ContractFiles" */
export type ContractFiles_Mutation_Response = {
  __typename?: 'ContractFiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractFiles>;
};

/** input type for inserting object relation for remote table "ContractFiles" */
export type ContractFiles_Obj_Rel_Insert_Input = {
  data: ContractFiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractFiles_On_Conflict>;
};

/** on_conflict condition type for table "ContractFiles" */
export type ContractFiles_On_Conflict = {
  constraint: ContractFiles_Constraint;
  update_columns?: Array<ContractFiles_Update_Column>;
  where?: InputMaybe<ContractFiles_Bool_Exp>;
};

/** Ordering options when selecting data from "ContractFiles". */
export type ContractFiles_Order_By = {
  Contract?: InputMaybe<Contracts_Order_By>;
  File?: InputMaybe<Files_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContractFiles */
export type ContractFiles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ContractFiles" */
export enum ContractFiles_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ContractFiles" */
export type ContractFiles_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ContractFiles_Stddev_Fields = {
  __typename?: 'ContractFiles_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ContractFiles_Stddev_Pop_Fields = {
  __typename?: 'ContractFiles_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ContractFiles_Stddev_Samp_Fields = {
  __typename?: 'ContractFiles_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ContractFiles" */
export type ContractFiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractFiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractFiles_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ContractFiles_Sum_Fields = {
  __typename?: 'ContractFiles_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ContractFiles" */
export enum ContractFiles_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ContractFiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ContractFiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractFiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractFiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContractFiles_Var_Pop_Fields = {
  __typename?: 'ContractFiles_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ContractFiles_Var_Samp_Fields = {
  __typename?: 'ContractFiles_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ContractFiles_Variance_Fields = {
  __typename?: 'ContractFiles_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  file_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ContractStages" */
export type ContractStages = {
  __typename?: 'ContractStages';
  /** An object relationship */
  Contract: Contracts;
  /** An object relationship */
  Stage: Stages;
  contract_id: Scalars['Int'];
  id: Scalars['Int'];
  stage_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ContractStages" */
export type ContractStages_Aggregate = {
  __typename?: 'ContractStages_aggregate';
  aggregate?: Maybe<ContractStages_Aggregate_Fields>;
  nodes: Array<ContractStages>;
};

export type ContractStages_Aggregate_Bool_Exp = {
  count?: InputMaybe<ContractStages_Aggregate_Bool_Exp_Count>;
};

export type ContractStages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContractStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractStages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ContractStages" */
export type ContractStages_Aggregate_Fields = {
  __typename?: 'ContractStages_aggregate_fields';
  avg?: Maybe<ContractStages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContractStages_Max_Fields>;
  min?: Maybe<ContractStages_Min_Fields>;
  stddev?: Maybe<ContractStages_Stddev_Fields>;
  stddev_pop?: Maybe<ContractStages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContractStages_Stddev_Samp_Fields>;
  sum?: Maybe<ContractStages_Sum_Fields>;
  var_pop?: Maybe<ContractStages_Var_Pop_Fields>;
  var_samp?: Maybe<ContractStages_Var_Samp_Fields>;
  variance?: Maybe<ContractStages_Variance_Fields>;
};


/** aggregate fields of "ContractStages" */
export type ContractStages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ContractStages" */
export type ContractStages_Aggregate_Order_By = {
  avg?: InputMaybe<ContractStages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContractStages_Max_Order_By>;
  min?: InputMaybe<ContractStages_Min_Order_By>;
  stddev?: InputMaybe<ContractStages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ContractStages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ContractStages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ContractStages_Sum_Order_By>;
  var_pop?: InputMaybe<ContractStages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ContractStages_Var_Samp_Order_By>;
  variance?: InputMaybe<ContractStages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ContractStages" */
export type ContractStages_Arr_Rel_Insert_Input = {
  data: Array<ContractStages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractStages_On_Conflict>;
};

/** aggregate avg on columns */
export type ContractStages_Avg_Fields = {
  __typename?: 'ContractStages_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ContractStages" */
export type ContractStages_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ContractStages". All fields are combined with a logical 'AND'. */
export type ContractStages_Bool_Exp = {
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  _and?: InputMaybe<Array<ContractStages_Bool_Exp>>;
  _not?: InputMaybe<ContractStages_Bool_Exp>;
  _or?: InputMaybe<Array<ContractStages_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContractStages" */
export enum ContractStages_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContractStagesPkey = 'ContractStages_pkey'
}

/** input type for incrementing numeric columns in table "ContractStages" */
export type ContractStages_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ContractStages" */
export type ContractStages_Insert_Input = {
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractStages_Max_Fields = {
  __typename?: 'ContractStages_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ContractStages" */
export type ContractStages_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContractStages_Min_Fields = {
  __typename?: 'ContractStages_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ContractStages" */
export type ContractStages_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ContractStages" */
export type ContractStages_Mutation_Response = {
  __typename?: 'ContractStages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractStages>;
};

/** on_conflict condition type for table "ContractStages" */
export type ContractStages_On_Conflict = {
  constraint: ContractStages_Constraint;
  update_columns?: Array<ContractStages_Update_Column>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};

/** Ordering options when selecting data from "ContractStages". */
export type ContractStages_Order_By = {
  Contract?: InputMaybe<Contracts_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContractStages */
export type ContractStages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ContractStages" */
export enum ContractStages_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  Id = 'id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ContractStages" */
export type ContractStages_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContractStages_Stddev_Fields = {
  __typename?: 'ContractStages_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ContractStages" */
export type ContractStages_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ContractStages_Stddev_Pop_Fields = {
  __typename?: 'ContractStages_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ContractStages" */
export type ContractStages_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ContractStages_Stddev_Samp_Fields = {
  __typename?: 'ContractStages_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ContractStages" */
export type ContractStages_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ContractStages" */
export type ContractStages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractStages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractStages_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ContractStages_Sum_Fields = {
  __typename?: 'ContractStages_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ContractStages" */
export type ContractStages_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** update columns of table "ContractStages" */
export enum ContractStages_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  Id = 'id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ContractStages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ContractStages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractStages_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractStages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ContractStages_Var_Pop_Fields = {
  __typename?: 'ContractStages_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ContractStages" */
export type ContractStages_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ContractStages_Var_Samp_Fields = {
  __typename?: 'ContractStages_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ContractStages" */
export type ContractStages_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ContractStages_Variance_Fields = {
  __typename?: 'ContractStages_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ContractStages" */
export type ContractStages_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ContractStatusEnum" */
export type ContractStatusEnum = {
  __typename?: 'ContractStatusEnum';
  value: Scalars['String'];
};

/** aggregated selection of "ContractStatusEnum" */
export type ContractStatusEnum_Aggregate = {
  __typename?: 'ContractStatusEnum_aggregate';
  aggregate?: Maybe<ContractStatusEnum_Aggregate_Fields>;
  nodes: Array<ContractStatusEnum>;
};

/** aggregate fields of "ContractStatusEnum" */
export type ContractStatusEnum_Aggregate_Fields = {
  __typename?: 'ContractStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractStatusEnum_Max_Fields>;
  min?: Maybe<ContractStatusEnum_Min_Fields>;
};


/** aggregate fields of "ContractStatusEnum" */
export type ContractStatusEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractStatusEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ContractStatusEnum". All fields are combined with a logical 'AND'. */
export type ContractStatusEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ContractStatusEnum_Bool_Exp>>;
  _not?: InputMaybe<ContractStatusEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ContractStatusEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContractStatusEnum" */
export enum ContractStatusEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContractStatusEnumPkey = 'ContractStatusEnum_pkey'
}

export enum ContractStatusEnum_Enum {
  Active = 'Active',
  Inactive = 'Inactive',
  Paso = 'Paso',
  Proposal = 'Proposal'
}

/** Boolean expression to compare columns of type "ContractStatusEnum_enum". All fields are combined with logical 'AND'. */
export type ContractStatusEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ContractStatusEnum_Enum>;
  _in?: InputMaybe<Array<ContractStatusEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContractStatusEnum_Enum>;
  _nin?: InputMaybe<Array<ContractStatusEnum_Enum>>;
};

/** input type for inserting data into table "ContractStatusEnum" */
export type ContractStatusEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractStatusEnum_Max_Fields = {
  __typename?: 'ContractStatusEnum_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractStatusEnum_Min_Fields = {
  __typename?: 'ContractStatusEnum_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ContractStatusEnum" */
export type ContractStatusEnum_Mutation_Response = {
  __typename?: 'ContractStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractStatusEnum>;
};

/** input type for inserting object relation for remote table "ContractStatusEnum" */
export type ContractStatusEnum_Obj_Rel_Insert_Input = {
  data: ContractStatusEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractStatusEnum_On_Conflict>;
};

/** on_conflict condition type for table "ContractStatusEnum" */
export type ContractStatusEnum_On_Conflict = {
  constraint: ContractStatusEnum_Constraint;
  update_columns?: Array<ContractStatusEnum_Update_Column>;
  where?: InputMaybe<ContractStatusEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ContractStatusEnum". */
export type ContractStatusEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContractStatusEnum */
export type ContractStatusEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ContractStatusEnum" */
export enum ContractStatusEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ContractStatusEnum" */
export type ContractStatusEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ContractStatusEnum" */
export type ContractStatusEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractStatusEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractStatusEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ContractStatusEnum" */
export enum ContractStatusEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type ContractStatusEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractStatusEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractStatusEnum_Bool_Exp;
};

/** columns and relationships of "Contracts" */
export type Contracts = {
  __typename?: 'Contracts';
  /** An object relationship */
  Account_Manager?: Maybe<Identities>;
  /** An array relationship */
  Calculations: Array<Calculations>;
  /** An aggregate relationship */
  Calculations_aggregate: Calculations_Aggregate;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An array relationship */
  ContractStages: Array<ContractStages>;
  /** An aggregate relationship */
  ContractStages_aggregate: ContractStages_Aggregate;
  /** An object relationship */
  ContractStatus?: Maybe<ContractStatusEnum>;
  /** An object relationship */
  Department?: Maybe<Departments>;
  /** An array relationship */
  ITRequestContractEmails: Array<ItRequestContractEmails>;
  /** An aggregate relationship */
  ITRequestContractEmails_aggregate: ItRequestContractEmails_Aggregate;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An array relationship */
  IdentityContractRelationships: Array<IdentityContractRelationship>;
  /** An aggregate relationship */
  IdentityContractRelationships_aggregate: IdentityContractRelationship_Aggregate;
  /** An array relationship */
  Jobs: Array<Jobs>;
  /** An aggregate relationship */
  Jobs_aggregate: Jobs_Aggregate;
  /** An array relationship */
  Matches: Array<Matches_Without_Author_With_Int_Uuid>;
  /** An aggregate relationship */
  Matches_aggregate: Matches_Without_Author_With_Int_Uuid_Aggregate;
  /** An object relationship */
  Questionnaire?: Maybe<Questionnaires>;
  /** An object relationship */
  Site_Lead?: Maybe<Identities>;
  /** An array relationship */
  Stages: Array<Stages>;
  /** An aggregate relationship */
  Stages_aggregate: Stages_Aggregate;
  /** An object relationship */
  UpdatedBy?: Maybe<Identities>;
  /** An object relationship */
  WorkflowContracts?: Maybe<Workflow_Contracts>;
  _search?: Maybe<Scalars['tsvector']>;
  account_manager_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  comments?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_administrator?: Maybe<Scalars['String']>;
  contract_administrator_email?: Maybe<Scalars['String']>;
  contract_administrator_phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  contract_emails: Array<Contract_Emails>;
  /** An aggregate relationship */
  contract_emails_aggregate: Contract_Emails_Aggregate;
  contract_number?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted?: Maybe<Scalars['Boolean']>;
  department_id?: Maybe<Scalars['uuid']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  fund_status: Scalars['String'];
  global_search_idx?: Maybe<Scalars['tsvector']>;
  id: Scalars['Int'];
  /** A computed field, executes function "contract_identifier" */
  identifier?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  prime?: Maybe<Scalars['String']>;
  program_manager?: Maybe<Scalars['String']>;
  program_manager_email?: Maybe<Scalars['String']>;
  program_manager_phone?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  security_officer?: Maybe<Scalars['String']>;
  security_officer_email?: Maybe<Scalars['String']>;
  security_officer_phone?: Maybe<Scalars['String']>;
  short_code?: Maybe<Scalars['String']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ContractStatusEnum_Enum>;
  sub_contract_number?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Contracts" */
export type ContractsCalculationsArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsContractStagesArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsContractStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsItRequestContractEmailsArgs = {
  distinct_on?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItRequestContractEmails_Order_By>>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsItRequestContractEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItRequestContractEmails_Order_By>>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsIdentityContractRelationshipsArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsIdentityContractRelationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsMatchesArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsMatches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsStagesArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsContract_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsContract_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


/** columns and relationships of "Contracts" */
export type ContractsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Contracts" */
export type Contracts_Aggregate = {
  __typename?: 'Contracts_aggregate';
  aggregate?: Maybe<Contracts_Aggregate_Fields>;
  nodes: Array<Contracts>;
};

export type Contracts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Contracts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Contracts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Contracts_Aggregate_Bool_Exp_Count>;
};

export type Contracts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Contracts_Select_Column_Contracts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contracts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Contracts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Contracts_Select_Column_Contracts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contracts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Contracts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contracts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Contracts" */
export type Contracts_Aggregate_Fields = {
  __typename?: 'Contracts_aggregate_fields';
  avg?: Maybe<Contracts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contracts_Max_Fields>;
  min?: Maybe<Contracts_Min_Fields>;
  stddev?: Maybe<Contracts_Stddev_Fields>;
  stddev_pop?: Maybe<Contracts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contracts_Stddev_Samp_Fields>;
  sum?: Maybe<Contracts_Sum_Fields>;
  var_pop?: Maybe<Contracts_Var_Pop_Fields>;
  var_samp?: Maybe<Contracts_Var_Samp_Fields>;
  variance?: Maybe<Contracts_Variance_Fields>;
};


/** aggregate fields of "Contracts" */
export type Contracts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Contracts" */
export type Contracts_Aggregate_Order_By = {
  avg?: InputMaybe<Contracts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contracts_Max_Order_By>;
  min?: InputMaybe<Contracts_Min_Order_By>;
  stddev?: InputMaybe<Contracts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contracts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contracts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contracts_Sum_Order_By>;
  var_pop?: InputMaybe<Contracts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contracts_Var_Samp_Order_By>;
  variance?: InputMaybe<Contracts_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Contracts_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Contracts" */
export type Contracts_Arr_Rel_Insert_Input = {
  data: Array<Contracts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contracts_On_Conflict>;
};

/** aggregate avg on columns */
export type Contracts_Avg_Fields = {
  __typename?: 'Contracts_avg_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Contracts" */
export type Contracts_Avg_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Contracts". All fields are combined with a logical 'AND'. */
export type Contracts_Bool_Exp = {
  Account_Manager?: InputMaybe<Identities_Bool_Exp>;
  Calculations?: InputMaybe<Calculations_Bool_Exp>;
  Calculations_aggregate?: InputMaybe<Calculations_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  ContractStages?: InputMaybe<ContractStages_Bool_Exp>;
  ContractStages_aggregate?: InputMaybe<ContractStages_Aggregate_Bool_Exp>;
  ContractStatus?: InputMaybe<ContractStatusEnum_Bool_Exp>;
  Department?: InputMaybe<Departments_Bool_Exp>;
  ITRequestContractEmails?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
  ITRequestContractEmails_aggregate?: InputMaybe<ItRequestContractEmails_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  IdentityContractRelationships?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
  IdentityContractRelationships_aggregate?: InputMaybe<IdentityContractRelationship_Aggregate_Bool_Exp>;
  Jobs?: InputMaybe<Jobs_Bool_Exp>;
  Jobs_aggregate?: InputMaybe<Jobs_Aggregate_Bool_Exp>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Bool_Exp>;
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  Site_Lead?: InputMaybe<Identities_Bool_Exp>;
  Stages?: InputMaybe<Stages_Bool_Exp>;
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  WorkflowContracts?: InputMaybe<Workflow_Contracts_Bool_Exp>;
  _and?: InputMaybe<Array<Contracts_Bool_Exp>>;
  _not?: InputMaybe<Contracts_Bool_Exp>;
  _or?: InputMaybe<Array<Contracts_Bool_Exp>>;
  _search?: InputMaybe<Tsvector_Comparison_Exp>;
  account_manager_id?: InputMaybe<Int_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contract_administrator?: InputMaybe<String_Comparison_Exp>;
  contract_administrator_email?: InputMaybe<String_Comparison_Exp>;
  contract_administrator_phone?: InputMaybe<String_Comparison_Exp>;
  contract_emails?: InputMaybe<Contract_Emails_Bool_Exp>;
  contract_emails_aggregate?: InputMaybe<Contract_Emails_Aggregate_Bool_Exp>;
  contract_number?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  department_id?: InputMaybe<Uuid_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  etl_source_entity?: InputMaybe<String_Comparison_Exp>;
  fund_status?: InputMaybe<String_Comparison_Exp>;
  global_search_idx?: InputMaybe<Tsvector_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  prime?: InputMaybe<String_Comparison_Exp>;
  program_manager?: InputMaybe<String_Comparison_Exp>;
  program_manager_email?: InputMaybe<String_Comparison_Exp>;
  program_manager_phone?: InputMaybe<String_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  security_officer?: InputMaybe<String_Comparison_Exp>;
  security_officer_email?: InputMaybe<String_Comparison_Exp>;
  security_officer_phone?: InputMaybe<String_Comparison_Exp>;
  short_code?: InputMaybe<String_Comparison_Exp>;
  site_lead_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<ContractStatusEnum_Enum_Comparison_Exp>;
  sub_contract_number?: InputMaybe<String_Comparison_Exp>;
  test?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Contracts" */
export enum Contracts_Constraint {
  /** unique or primary key constraint on columns "etl_id_token" */
  ContractsEtlIdTokenKey = 'Contracts_etl_id_token_key',
  /** unique or primary key constraint on columns "id" */
  ContractsPkey = 'Contracts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Contracts_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Contracts_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Contracts_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Contracts" */
export type Contracts_Inc_Input = {
  account_manager_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Contracts" */
export type Contracts_Insert_Input = {
  Account_Manager?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Calculations?: InputMaybe<Calculations_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  ContractStages?: InputMaybe<ContractStages_Arr_Rel_Insert_Input>;
  ContractStatus?: InputMaybe<ContractStatusEnum_Obj_Rel_Insert_Input>;
  Department?: InputMaybe<Departments_Obj_Rel_Insert_Input>;
  ITRequestContractEmails?: InputMaybe<ItRequestContractEmails_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  IdentityContractRelationships?: InputMaybe<IdentityContractRelationship_Arr_Rel_Insert_Input>;
  Jobs?: InputMaybe<Jobs_Arr_Rel_Insert_Input>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Arr_Rel_Insert_Input>;
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  Site_Lead?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Stages?: InputMaybe<Stages_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  WorkflowContracts?: InputMaybe<Workflow_Contracts_Obj_Rel_Insert_Input>;
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_administrator?: InputMaybe<Scalars['String']>;
  contract_administrator_email?: InputMaybe<Scalars['String']>;
  contract_administrator_phone?: InputMaybe<Scalars['String']>;
  contract_emails?: InputMaybe<Contract_Emails_Arr_Rel_Insert_Input>;
  contract_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  fund_status?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  prime?: InputMaybe<Scalars['String']>;
  program_manager?: InputMaybe<Scalars['String']>;
  program_manager_email?: InputMaybe<Scalars['String']>;
  program_manager_phone?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  security_officer?: InputMaybe<Scalars['String']>;
  security_officer_email?: InputMaybe<Scalars['String']>;
  security_officer_phone?: InputMaybe<Scalars['String']>;
  short_code?: InputMaybe<Scalars['String']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ContractStatusEnum_Enum>;
  sub_contract_number?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Contracts_Max_Fields = {
  __typename?: 'Contracts_max_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_administrator?: Maybe<Scalars['String']>;
  contract_administrator_email?: Maybe<Scalars['String']>;
  contract_administrator_phone?: Maybe<Scalars['String']>;
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  fund_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "contract_identifier" */
  identifier?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prime?: Maybe<Scalars['String']>;
  program_manager?: Maybe<Scalars['String']>;
  program_manager_email?: Maybe<Scalars['String']>;
  program_manager_phone?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  security_officer?: Maybe<Scalars['String']>;
  security_officer_email?: Maybe<Scalars['String']>;
  security_officer_phone?: Maybe<Scalars['String']>;
  short_code?: Maybe<Scalars['String']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Int']>;
  sub_contract_number?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Contracts" */
export type Contracts_Max_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_administrator?: InputMaybe<Order_By>;
  contract_administrator_email?: InputMaybe<Order_By>;
  contract_administrator_phone?: InputMaybe<Order_By>;
  contract_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  fund_status?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  prime?: InputMaybe<Order_By>;
  program_manager?: InputMaybe<Order_By>;
  program_manager_email?: InputMaybe<Order_By>;
  program_manager_phone?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  security_officer?: InputMaybe<Order_By>;
  security_officer_email?: InputMaybe<Order_By>;
  security_officer_phone?: InputMaybe<Order_By>;
  short_code?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  sub_contract_number?: InputMaybe<Order_By>;
  test?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contracts_Min_Fields = {
  __typename?: 'Contracts_min_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_administrator?: Maybe<Scalars['String']>;
  contract_administrator_email?: Maybe<Scalars['String']>;
  contract_administrator_phone?: Maybe<Scalars['String']>;
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  fund_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "contract_identifier" */
  identifier?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prime?: Maybe<Scalars['String']>;
  program_manager?: Maybe<Scalars['String']>;
  program_manager_email?: Maybe<Scalars['String']>;
  program_manager_phone?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  security_officer?: Maybe<Scalars['String']>;
  security_officer_email?: Maybe<Scalars['String']>;
  security_officer_phone?: Maybe<Scalars['String']>;
  short_code?: Maybe<Scalars['String']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Int']>;
  sub_contract_number?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Contracts" */
export type Contracts_Min_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_administrator?: InputMaybe<Order_By>;
  contract_administrator_email?: InputMaybe<Order_By>;
  contract_administrator_phone?: InputMaybe<Order_By>;
  contract_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  fund_status?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  prime?: InputMaybe<Order_By>;
  program_manager?: InputMaybe<Order_By>;
  program_manager_email?: InputMaybe<Order_By>;
  program_manager_phone?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  security_officer?: InputMaybe<Order_By>;
  security_officer_email?: InputMaybe<Order_By>;
  security_officer_phone?: InputMaybe<Order_By>;
  short_code?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  sub_contract_number?: InputMaybe<Order_By>;
  test?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Contracts" */
export type Contracts_Mutation_Response = {
  __typename?: 'Contracts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contracts>;
};

/** input type for inserting object relation for remote table "Contracts" */
export type Contracts_Obj_Rel_Insert_Input = {
  data: Contracts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contracts_On_Conflict>;
};

/** on_conflict condition type for table "Contracts" */
export type Contracts_On_Conflict = {
  constraint: Contracts_Constraint;
  update_columns?: Array<Contracts_Update_Column>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};

/** Ordering options when selecting data from "Contracts". */
export type Contracts_Order_By = {
  Account_Manager?: InputMaybe<Identities_Order_By>;
  Calculations_aggregate?: InputMaybe<Calculations_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  ContractStages_aggregate?: InputMaybe<ContractStages_Aggregate_Order_By>;
  ContractStatus?: InputMaybe<ContractStatusEnum_Order_By>;
  Department?: InputMaybe<Departments_Order_By>;
  ITRequestContractEmails_aggregate?: InputMaybe<ItRequestContractEmails_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  IdentityContractRelationships_aggregate?: InputMaybe<IdentityContractRelationship_Aggregate_Order_By>;
  Jobs_aggregate?: InputMaybe<Jobs_Aggregate_Order_By>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Order_By>;
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  Site_Lead?: InputMaybe<Identities_Order_By>;
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  WorkflowContracts?: InputMaybe<Workflow_Contracts_Order_By>;
  _search?: InputMaybe<Order_By>;
  account_manager_id?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_administrator?: InputMaybe<Order_By>;
  contract_administrator_email?: InputMaybe<Order_By>;
  contract_administrator_phone?: InputMaybe<Order_By>;
  contract_emails_aggregate?: InputMaybe<Contract_Emails_Aggregate_Order_By>;
  contract_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  fund_status?: InputMaybe<Order_By>;
  global_search_idx?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  prime?: InputMaybe<Order_By>;
  program_manager?: InputMaybe<Order_By>;
  program_manager_email?: InputMaybe<Order_By>;
  program_manager_phone?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  security_officer?: InputMaybe<Order_By>;
  security_officer_email?: InputMaybe<Order_By>;
  security_officer_phone?: InputMaybe<Order_By>;
  short_code?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_contract_number?: InputMaybe<Order_By>;
  test?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Contracts */
export type Contracts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Contracts_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Contracts" */
export enum Contracts_Select_Column {
  /** column name */
  Search = '_search',
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  Active = 'active',
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractAdministrator = 'contract_administrator',
  /** column name */
  ContractAdministratorEmail = 'contract_administrator_email',
  /** column name */
  ContractAdministratorPhone = 'contract_administrator_phone',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  FundStatus = 'fund_status',
  /** column name */
  GlobalSearchIdx = 'global_search_idx',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Prime = 'prime',
  /** column name */
  ProgramManager = 'program_manager',
  /** column name */
  ProgramManagerEmail = 'program_manager_email',
  /** column name */
  ProgramManagerPhone = 'program_manager_phone',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  SecurityOfficer = 'security_officer',
  /** column name */
  SecurityOfficerEmail = 'security_officer_email',
  /** column name */
  SecurityOfficerPhone = 'security_officer_phone',
  /** column name */
  ShortCode = 'short_code',
  /** column name */
  SiteLeadId = 'site_lead_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubContractNumber = 'sub_contract_number',
  /** column name */
  Test = 'test',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "Contracts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Contracts" */
export enum Contracts_Select_Column_Contracts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Deleted = 'deleted'
}

/** select "Contracts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Contracts" */
export enum Contracts_Select_Column_Contracts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "Contracts" */
export type Contracts_Set_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_administrator?: InputMaybe<Scalars['String']>;
  contract_administrator_email?: InputMaybe<Scalars['String']>;
  contract_administrator_phone?: InputMaybe<Scalars['String']>;
  contract_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  fund_status?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  prime?: InputMaybe<Scalars['String']>;
  program_manager?: InputMaybe<Scalars['String']>;
  program_manager_email?: InputMaybe<Scalars['String']>;
  program_manager_phone?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  security_officer?: InputMaybe<Scalars['String']>;
  security_officer_email?: InputMaybe<Scalars['String']>;
  security_officer_phone?: InputMaybe<Scalars['String']>;
  short_code?: InputMaybe<Scalars['String']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ContractStatusEnum_Enum>;
  sub_contract_number?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Contracts_Stddev_Fields = {
  __typename?: 'Contracts_stddev_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Contracts" */
export type Contracts_Stddev_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contracts_Stddev_Pop_Fields = {
  __typename?: 'Contracts_stddev_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Contracts" */
export type Contracts_Stddev_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contracts_Stddev_Samp_Fields = {
  __typename?: 'Contracts_stddev_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Contracts" */
export type Contracts_Stddev_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Contracts" */
export type Contracts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contracts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contracts_Stream_Cursor_Value_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_administrator?: InputMaybe<Scalars['String']>;
  contract_administrator_email?: InputMaybe<Scalars['String']>;
  contract_administrator_phone?: InputMaybe<Scalars['String']>;
  contract_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  fund_status?: InputMaybe<Scalars['String']>;
  global_search_idx?: InputMaybe<Scalars['tsvector']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  prime?: InputMaybe<Scalars['String']>;
  program_manager?: InputMaybe<Scalars['String']>;
  program_manager_email?: InputMaybe<Scalars['String']>;
  program_manager_phone?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  security_officer?: InputMaybe<Scalars['String']>;
  security_officer_email?: InputMaybe<Scalars['String']>;
  security_officer_phone?: InputMaybe<Scalars['String']>;
  short_code?: InputMaybe<Scalars['String']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ContractStatusEnum_Enum>;
  sub_contract_number?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Contracts_Sum_Fields = {
  __typename?: 'Contracts_sum_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Contracts" */
export type Contracts_Sum_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Contracts" */
export enum Contracts_Update_Column {
  /** column name */
  Search = '_search',
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  Active = 'active',
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractAdministrator = 'contract_administrator',
  /** column name */
  ContractAdministratorEmail = 'contract_administrator_email',
  /** column name */
  ContractAdministratorPhone = 'contract_administrator_phone',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  FundStatus = 'fund_status',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Prime = 'prime',
  /** column name */
  ProgramManager = 'program_manager',
  /** column name */
  ProgramManagerEmail = 'program_manager_email',
  /** column name */
  ProgramManagerPhone = 'program_manager_phone',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  SecurityOfficer = 'security_officer',
  /** column name */
  SecurityOfficerEmail = 'security_officer_email',
  /** column name */
  SecurityOfficerPhone = 'security_officer_phone',
  /** column name */
  ShortCode = 'short_code',
  /** column name */
  SiteLeadId = 'site_lead_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubContractNumber = 'sub_contract_number',
  /** column name */
  Test = 'test',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Contracts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Contracts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Contracts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Contracts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Contracts_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contracts_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Contracts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contracts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contracts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contracts_Var_Pop_Fields = {
  __typename?: 'Contracts_var_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Contracts" */
export type Contracts_Var_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contracts_Var_Samp_Fields = {
  __typename?: 'Contracts_var_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Contracts" */
export type Contracts_Var_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contracts_Variance_Fields = {
  __typename?: 'Contracts_variance_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Contracts" */
export type Contracts_Variance_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  /** this is actually program_lead_id, but this is an anachronism from ic-1 days. will deprecate soon and evolve to be program_lead_id */
  site_lead_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

export type ConversionHandlerInput = {
  compensationObject: Scalars['String'];
  employmentObject: Scalars['String'];
  evergreen: Scalars['Boolean'];
  identityId: Scalars['Int'];
  jobId: Scalars['Int'];
  updatedIdentityInfo: Scalars['String'];
};

export type ConversionHandlerOutput = {
  __typename?: 'ConversionHandlerOutput';
  status: Scalars['Int'];
};

export type ConversionReportQueryInput = {
  contracts?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  endOnUTCTS?: InputMaybe<Scalars['String']>;
  filterGrammar?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  rm?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sortCol: Scalars['String'];
  sortDataType: Scalars['String'];
  sortOrder: Scalars['String'];
  source?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startOnUTCTS?: InputMaybe<Scalars['String']>;
  viewType: Scalars['String'];
};

export type ConversionReportQueryOutput = {
  __typename?: 'ConversionReportQueryOutput';
  results?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortOrder?: Maybe<Scalars['String']>;
  totalRows?: Maybe<Scalars['Int']>;
};

export type CreateCalendarEventInput = {
  description?: InputMaybe<Scalars['String']>;
  includeVtc?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<Array<InputMaybe<ParticipantsInput>>>;
  title: Scalars['String'];
  when?: InputMaybe<WhenInput>;
};

export type CreateEventOutput = {
  __typename?: 'CreateEventOutput';
  message: Scalars['String'];
};

export type CreateGSheetCalcTemplateOutput = {
  __typename?: 'CreateGSheetCalcTemplateOutput';
  GoogleSheetCalcTemplate?: Maybe<GoogleSheetCalcTemplates>;
  google_sheet_url: Scalars['String'];
  id: Scalars['String'];
};

export type CreateIdibuAccountInput = {
  companyName: Scalars['String'];
  companyUrl: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  tenant: Scalars['String'];
  zip: Scalars['String'];
};

export type CreateIdibuAccountOutput = {
  __typename?: 'CreateIdibuAccountOutput';
  message: Scalars['String'];
};

/** columns and relationships of "CurrentStages" */
export type CurrentStages = {
  __typename?: 'CurrentStages';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An object relationship */
  Stage?: Maybe<Stages>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  current_stage?: Maybe<Scalars['Int']>;
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  job_identity_id_combo?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "CurrentStages" */
export type CurrentStages_Aggregate = {
  __typename?: 'CurrentStages_aggregate';
  aggregate?: Maybe<CurrentStages_Aggregate_Fields>;
  nodes: Array<CurrentStages>;
};

/** aggregate fields of "CurrentStages" */
export type CurrentStages_Aggregate_Fields = {
  __typename?: 'CurrentStages_aggregate_fields';
  avg?: Maybe<CurrentStages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CurrentStages_Max_Fields>;
  min?: Maybe<CurrentStages_Min_Fields>;
  stddev?: Maybe<CurrentStages_Stddev_Fields>;
  stddev_pop?: Maybe<CurrentStages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CurrentStages_Stddev_Samp_Fields>;
  sum?: Maybe<CurrentStages_Sum_Fields>;
  var_pop?: Maybe<CurrentStages_Var_Pop_Fields>;
  var_samp?: Maybe<CurrentStages_Var_Samp_Fields>;
  variance?: Maybe<CurrentStages_Variance_Fields>;
};


/** aggregate fields of "CurrentStages" */
export type CurrentStages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CurrentStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CurrentStages_Avg_Fields = {
  __typename?: 'CurrentStages_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CurrentStages". All fields are combined with a logical 'AND'. */
export type CurrentStages_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  _and?: InputMaybe<Array<CurrentStages_Bool_Exp>>;
  _not?: InputMaybe<CurrentStages_Bool_Exp>;
  _or?: InputMaybe<Array<CurrentStages_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_stage?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_identity_id_combo?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CurrentStages" */
export enum CurrentStages_Constraint {
  /** unique or primary key constraint on columns "identity_id", "job_id" */
  CurrentStagesPkey = 'CurrentStages_pkey'
}

/** input type for incrementing numeric columns in table "CurrentStages" */
export type CurrentStages_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  current_stage?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CurrentStages" */
export type CurrentStages_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_stage?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_identity_id_combo?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CurrentStages_Max_Fields = {
  __typename?: 'CurrentStages_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_stage?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_identity_id_combo?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CurrentStages_Min_Fields = {
  __typename?: 'CurrentStages_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_stage?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_identity_id_combo?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "CurrentStages" */
export type CurrentStages_Mutation_Response = {
  __typename?: 'CurrentStages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CurrentStages>;
};

/** on_conflict condition type for table "CurrentStages" */
export type CurrentStages_On_Conflict = {
  constraint: CurrentStages_Constraint;
  update_columns?: Array<CurrentStages_Update_Column>;
  where?: InputMaybe<CurrentStages_Bool_Exp>;
};

/** Ordering options when selecting data from "CurrentStages". */
export type CurrentStages_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_stage?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_identity_id_combo?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CurrentStages */
export type CurrentStages_Pk_Columns_Input = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
};

/** select columns of table "CurrentStages" */
export enum CurrentStages_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStage = 'current_stage',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobIdentityIdCombo = 'job_identity_id_combo',
  /** column name */
  Reason = 'reason',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CurrentStages" */
export type CurrentStages_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_stage?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_identity_id_combo?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CurrentStages_Stddev_Fields = {
  __typename?: 'CurrentStages_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CurrentStages_Stddev_Pop_Fields = {
  __typename?: 'CurrentStages_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CurrentStages_Stddev_Samp_Fields = {
  __typename?: 'CurrentStages_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CurrentStages" */
export type CurrentStages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CurrentStages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrentStages_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_stage?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_identity_id_combo?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CurrentStages_Sum_Fields = {
  __typename?: 'CurrentStages_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  current_stage?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "CurrentStages" */
export enum CurrentStages_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStage = 'current_stage',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobIdentityIdCombo = 'job_identity_id_combo',
  /** column name */
  Reason = 'reason',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CurrentStages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CurrentStages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CurrentStages_Set_Input>;
  /** filter the rows which have to be updated */
  where: CurrentStages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CurrentStages_Var_Pop_Fields = {
  __typename?: 'CurrentStages_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CurrentStages_Var_Samp_Fields = {
  __typename?: 'CurrentStages_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CurrentStages_Variance_Fields = {
  __typename?: 'CurrentStages_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "DataHistory" */
export type DataHistory = {
  __typename?: 'DataHistory';
  action: Scalars['String'];
  company_id?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  identity_id?: Maybe<Scalars['Int']>;
  mtime: Scalars['timestamptz'];
  row_data: Scalars['jsonb'];
  table_name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "DataHistory" */
export type DataHistoryRow_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "DataHistory" */
export type DataHistory_Aggregate = {
  __typename?: 'DataHistory_aggregate';
  aggregate?: Maybe<DataHistory_Aggregate_Fields>;
  nodes: Array<DataHistory>;
};

/** aggregate fields of "DataHistory" */
export type DataHistory_Aggregate_Fields = {
  __typename?: 'DataHistory_aggregate_fields';
  avg?: Maybe<DataHistory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<DataHistory_Max_Fields>;
  min?: Maybe<DataHistory_Min_Fields>;
  stddev?: Maybe<DataHistory_Stddev_Fields>;
  stddev_pop?: Maybe<DataHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DataHistory_Stddev_Samp_Fields>;
  sum?: Maybe<DataHistory_Sum_Fields>;
  var_pop?: Maybe<DataHistory_Var_Pop_Fields>;
  var_samp?: Maybe<DataHistory_Var_Samp_Fields>;
  variance?: Maybe<DataHistory_Variance_Fields>;
};


/** aggregate fields of "DataHistory" */
export type DataHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DataHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DataHistory_Append_Input = {
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type DataHistory_Avg_Fields = {
  __typename?: 'DataHistory_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "DataHistory". All fields are combined with a logical 'AND'. */
export type DataHistory_Bool_Exp = {
  _and?: InputMaybe<Array<DataHistory_Bool_Exp>>;
  _not?: InputMaybe<DataHistory_Bool_Exp>;
  _or?: InputMaybe<Array<DataHistory_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  mtime?: InputMaybe<Timestamptz_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DataHistory" */
export enum DataHistory_Constraint {
  /** unique or primary key constraint on columns "id" */
  DataHistoryPkey = 'DataHistory_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DataHistory_Delete_At_Path_Input = {
  row_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DataHistory_Delete_Elem_Input = {
  row_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DataHistory_Delete_Key_Input = {
  row_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "DataHistory" */
export type DataHistory_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "DataHistory" */
export type DataHistory_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  mtime?: InputMaybe<Scalars['timestamptz']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
  table_name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DataHistory_Max_Fields = {
  __typename?: 'DataHistory_max_fields';
  action?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  mtime?: Maybe<Scalars['timestamptz']>;
  table_name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DataHistory_Min_Fields = {
  __typename?: 'DataHistory_min_fields';
  action?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  mtime?: Maybe<Scalars['timestamptz']>;
  table_name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "DataHistory" */
export type DataHistory_Mutation_Response = {
  __typename?: 'DataHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DataHistory>;
};

/** on_conflict condition type for table "DataHistory" */
export type DataHistory_On_Conflict = {
  constraint: DataHistory_Constraint;
  update_columns?: Array<DataHistory_Update_Column>;
  where?: InputMaybe<DataHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "DataHistory". */
export type DataHistory_Order_By = {
  action?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  mtime?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DataHistory */
export type DataHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DataHistory_Prepend_Input = {
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "DataHistory" */
export enum DataHistory_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Mtime = 'mtime',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "DataHistory" */
export type DataHistory_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  mtime?: InputMaybe<Scalars['timestamptz']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
  table_name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DataHistory_Stddev_Fields = {
  __typename?: 'DataHistory_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DataHistory_Stddev_Pop_Fields = {
  __typename?: 'DataHistory_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DataHistory_Stddev_Samp_Fields = {
  __typename?: 'DataHistory_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "DataHistory" */
export type DataHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DataHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DataHistory_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  mtime?: InputMaybe<Scalars['timestamptz']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
  table_name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DataHistory_Sum_Fields = {
  __typename?: 'DataHistory_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "DataHistory" */
export enum DataHistory_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Mtime = 'mtime',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type DataHistory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DataHistory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DataHistory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DataHistory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DataHistory_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DataHistory_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DataHistory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: DataHistory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DataHistory_Var_Pop_Fields = {
  __typename?: 'DataHistory_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DataHistory_Var_Samp_Fields = {
  __typename?: 'DataHistory_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DataHistory_Variance_Fields = {
  __typename?: 'DataHistory_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

export type DeleteFileOutput = {
  __typename?: 'DeleteFileOutput';
  message: Scalars['String'];
};

/** columns and relationships of "Departments" */
export type Departments = {
  __typename?: 'Departments';
  /** An array relationship */
  Contracts: Array<Contracts>;
  /** An aggregate relationship */
  Contracts_aggregate: Contracts_Aggregate;
  /** An object relationship */
  Questionnaire?: Maybe<Questionnaires>;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  questionnaire_id?: Maybe<Scalars['uuid']>;
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workflow_departments: Array<Workflow_Departments>;
  /** An aggregate relationship */
  workflow_departments_aggregate: Workflow_Departments_Aggregate;
};


/** columns and relationships of "Departments" */
export type DepartmentsContractsArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Departments" */
export type DepartmentsContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Departments" */
export type DepartmentsWorkflow_DepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};


/** columns and relationships of "Departments" */
export type DepartmentsWorkflow_Departments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};

/** aggregated selection of "Departments" */
export type Departments_Aggregate = {
  __typename?: 'Departments_aggregate';
  aggregate?: Maybe<Departments_Aggregate_Fields>;
  nodes: Array<Departments>;
};

export type Departments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Departments_Aggregate_Bool_Exp_Count>;
};

export type Departments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Departments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Departments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Departments" */
export type Departments_Aggregate_Fields = {
  __typename?: 'Departments_aggregate_fields';
  avg?: Maybe<Departments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Departments_Max_Fields>;
  min?: Maybe<Departments_Min_Fields>;
  stddev?: Maybe<Departments_Stddev_Fields>;
  stddev_pop?: Maybe<Departments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Departments_Stddev_Samp_Fields>;
  sum?: Maybe<Departments_Sum_Fields>;
  var_pop?: Maybe<Departments_Var_Pop_Fields>;
  var_samp?: Maybe<Departments_Var_Samp_Fields>;
  variance?: Maybe<Departments_Variance_Fields>;
};


/** aggregate fields of "Departments" */
export type Departments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Departments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Departments" */
export type Departments_Aggregate_Order_By = {
  avg?: InputMaybe<Departments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Departments_Max_Order_By>;
  min?: InputMaybe<Departments_Min_Order_By>;
  stddev?: InputMaybe<Departments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Departments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Departments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Departments_Sum_Order_By>;
  var_pop?: InputMaybe<Departments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Departments_Var_Samp_Order_By>;
  variance?: InputMaybe<Departments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Departments" */
export type Departments_Arr_Rel_Insert_Input = {
  data: Array<Departments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Departments_On_Conflict>;
};

/** aggregate avg on columns */
export type Departments_Avg_Fields = {
  __typename?: 'Departments_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Departments" */
export type Departments_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Departments". All fields are combined with a logical 'AND'. */
export type Departments_Bool_Exp = {
  Contracts?: InputMaybe<Contracts_Bool_Exp>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Bool_Exp>;
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  _and?: InputMaybe<Array<Departments_Bool_Exp>>;
  _not?: InputMaybe<Departments_Bool_Exp>;
  _or?: InputMaybe<Array<Departments_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workflow_departments?: InputMaybe<Workflow_Departments_Bool_Exp>;
  workflow_departments_aggregate?: InputMaybe<Workflow_Departments_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "Departments" */
export enum Departments_Constraint {
  /** unique or primary key constraint on columns "id" */
  DepartmentsPkey = 'Departments_pkey'
}

/** input type for incrementing numeric columns in table "Departments" */
export type Departments_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Departments" */
export type Departments_Insert_Input = {
  Contracts?: InputMaybe<Contracts_Arr_Rel_Insert_Input>;
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workflow_departments?: InputMaybe<Workflow_Departments_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Departments_Max_Fields = {
  __typename?: 'Departments_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Departments" */
export type Departments_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Departments_Min_Fields = {
  __typename?: 'Departments_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Departments" */
export type Departments_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Departments" */
export type Departments_Mutation_Response = {
  __typename?: 'Departments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Departments>;
};

/** input type for inserting object relation for remote table "Departments" */
export type Departments_Obj_Rel_Insert_Input = {
  data: Departments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Departments_On_Conflict>;
};

/** on_conflict condition type for table "Departments" */
export type Departments_On_Conflict = {
  constraint: Departments_Constraint;
  update_columns?: Array<Departments_Update_Column>;
  where?: InputMaybe<Departments_Bool_Exp>;
};

/** Ordering options when selecting data from "Departments". */
export type Departments_Order_By = {
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Order_By>;
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workflow_departments_aggregate?: InputMaybe<Workflow_Departments_Aggregate_Order_By>;
};

/** primary key columns input for table: Departments */
export type Departments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Departments" */
export enum Departments_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Departments" */
export type Departments_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Departments_Stddev_Fields = {
  __typename?: 'Departments_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Departments" */
export type Departments_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Departments_Stddev_Pop_Fields = {
  __typename?: 'Departments_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Departments" */
export type Departments_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Departments_Stddev_Samp_Fields = {
  __typename?: 'Departments_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Departments" */
export type Departments_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Departments" */
export type Departments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Departments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Departments_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Departments_Sum_Fields = {
  __typename?: 'Departments_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Departments" */
export type Departments_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** update columns of table "Departments" */
export enum Departments_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Departments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Departments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Departments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Departments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Departments_Var_Pop_Fields = {
  __typename?: 'Departments_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Departments" */
export type Departments_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Departments_Var_Samp_Fields = {
  __typename?: 'Departments_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Departments" */
export type Departments_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Departments_Variance_Fields = {
  __typename?: 'Departments_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Departments" */
export type Departments_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "DisabilityStatusEnum" */
export type DisabilityStatusEnum = {
  __typename?: 'DisabilityStatusEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "DisabilityStatusEnum" */
export type DisabilityStatusEnum_Aggregate = {
  __typename?: 'DisabilityStatusEnum_aggregate';
  aggregate?: Maybe<DisabilityStatusEnum_Aggregate_Fields>;
  nodes: Array<DisabilityStatusEnum>;
};

/** aggregate fields of "DisabilityStatusEnum" */
export type DisabilityStatusEnum_Aggregate_Fields = {
  __typename?: 'DisabilityStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DisabilityStatusEnum_Max_Fields>;
  min?: Maybe<DisabilityStatusEnum_Min_Fields>;
};


/** aggregate fields of "DisabilityStatusEnum" */
export type DisabilityStatusEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DisabilityStatusEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "DisabilityStatusEnum". All fields are combined with a logical 'AND'. */
export type DisabilityStatusEnum_Bool_Exp = {
  _and?: InputMaybe<Array<DisabilityStatusEnum_Bool_Exp>>;
  _not?: InputMaybe<DisabilityStatusEnum_Bool_Exp>;
  _or?: InputMaybe<Array<DisabilityStatusEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "DisabilityStatusEnum" */
export enum DisabilityStatusEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  DisabilityStatusEnumPkey = 'DisabilityStatusEnum_pkey'
}

export enum DisabilityStatusEnum_Enum {
  /** Declined */
  Declined = 'DECLINED',
  /** No */
  No = 'NO',
  /** Yes */
  Yes = 'YES'
}

/** Boolean expression to compare columns of type "DisabilityStatusEnum_enum". All fields are combined with logical 'AND'. */
export type DisabilityStatusEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DisabilityStatusEnum_Enum>;
  _in?: InputMaybe<Array<DisabilityStatusEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DisabilityStatusEnum_Enum>;
  _nin?: InputMaybe<Array<DisabilityStatusEnum_Enum>>;
};

/** input type for inserting data into table "DisabilityStatusEnum" */
export type DisabilityStatusEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DisabilityStatusEnum_Max_Fields = {
  __typename?: 'DisabilityStatusEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DisabilityStatusEnum_Min_Fields = {
  __typename?: 'DisabilityStatusEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "DisabilityStatusEnum" */
export type DisabilityStatusEnum_Mutation_Response = {
  __typename?: 'DisabilityStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DisabilityStatusEnum>;
};

/** on_conflict condition type for table "DisabilityStatusEnum" */
export type DisabilityStatusEnum_On_Conflict = {
  constraint: DisabilityStatusEnum_Constraint;
  update_columns?: Array<DisabilityStatusEnum_Update_Column>;
  where?: InputMaybe<DisabilityStatusEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "DisabilityStatusEnum". */
export type DisabilityStatusEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DisabilityStatusEnum */
export type DisabilityStatusEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "DisabilityStatusEnum" */
export enum DisabilityStatusEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "DisabilityStatusEnum" */
export type DisabilityStatusEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "DisabilityStatusEnum" */
export type DisabilityStatusEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DisabilityStatusEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DisabilityStatusEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "DisabilityStatusEnum" */
export enum DisabilityStatusEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type DisabilityStatusEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DisabilityStatusEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: DisabilityStatusEnum_Bool_Exp;
};

export type DocuSignAuthOutput = {
  __typename?: 'DocuSignAuthOutput';
  loginStatus: Scalars['String'];
  loginUrl: Scalars['String'];
};

export type DuplicateDefinition = {
  primary_id: Scalars['Int'];
  secondary_ids: Array<InputMaybe<Scalars['Int']>>;
};

export type DuplicateIdentitiesOutput = {
  __typename?: 'DuplicateIdentitiesOutput';
  matchingIdentityIds: Array<Scalars['Int']>;
  mobilePhone: Scalars['String'];
};

/** columns and relationships of "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum = {
  __typename?: 'EEOJobCategoriesEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Aggregate = {
  __typename?: 'EEOJobCategoriesEnum_aggregate';
  aggregate?: Maybe<EeoJobCategoriesEnum_Aggregate_Fields>;
  nodes: Array<EeoJobCategoriesEnum>;
};

/** aggregate fields of "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Aggregate_Fields = {
  __typename?: 'EEOJobCategoriesEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EeoJobCategoriesEnum_Max_Fields>;
  min?: Maybe<EeoJobCategoriesEnum_Min_Fields>;
};


/** aggregate fields of "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EeoJobCategoriesEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "EEOJobCategoriesEnum". All fields are combined with a logical 'AND'. */
export type EeoJobCategoriesEnum_Bool_Exp = {
  _and?: InputMaybe<Array<EeoJobCategoriesEnum_Bool_Exp>>;
  _not?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
  _or?: InputMaybe<Array<EeoJobCategoriesEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EEOJobCategoriesEnum" */
export enum EeoJobCategoriesEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  EeoJobCategoriesEnumPkey = 'EEOJobCategoriesEnum_pkey'
}

export enum EeoJobCategoriesEnum_Enum {
  /** Administrative Support Workers */
  AdminSupport = 'ADMIN_SUPPORT',
  /** Craft Workers */
  Craft = 'CRAFT',
  /** Executive or Senior Officials and Managers */
  ExecutiveOrSeniorManager = 'EXECUTIVE_OR_SENIOR_MANAGER',
  /** First or Mid-level Officials and Managers */
  FirstOrMidlevelMgr = 'FIRST_OR_MIDLEVEL_MGR',
  /** Laborers and Helpers */
  LaborersAndHelpers = 'LABORERS_AND_HELPERS',
  /** Operatives */
  Operatives = 'OPERATIVES',
  /** Professionals */
  Pro = 'PRO',
  /** Sales Workers */
  Sales = 'SALES',
  /** Service Workers */
  Svc = 'SVC',
  /** Technicians */
  Tech = 'TECH'
}

/** Boolean expression to compare columns of type "EEOJobCategoriesEnum_enum". All fields are combined with logical 'AND'. */
export type EeoJobCategoriesEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  _in?: InputMaybe<Array<EeoJobCategoriesEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  _nin?: InputMaybe<Array<EeoJobCategoriesEnum_Enum>>;
};

/** input type for inserting data into table "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EeoJobCategoriesEnum_Max_Fields = {
  __typename?: 'EEOJobCategoriesEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EeoJobCategoriesEnum_Min_Fields = {
  __typename?: 'EEOJobCategoriesEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Mutation_Response = {
  __typename?: 'EEOJobCategoriesEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EeoJobCategoriesEnum>;
};

/** input type for inserting object relation for remote table "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Obj_Rel_Insert_Input = {
  data: EeoJobCategoriesEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EeoJobCategoriesEnum_On_Conflict>;
};

/** on_conflict condition type for table "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_On_Conflict = {
  constraint: EeoJobCategoriesEnum_Constraint;
  update_columns?: Array<EeoJobCategoriesEnum_Update_Column>;
  where?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "EEOJobCategoriesEnum". */
export type EeoJobCategoriesEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EEOJobCategoriesEnum */
export type EeoJobCategoriesEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "EEOJobCategoriesEnum" */
export enum EeoJobCategoriesEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "EEOJobCategoriesEnum" */
export type EeoJobCategoriesEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EeoJobCategoriesEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EeoJobCategoriesEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "EEOJobCategoriesEnum" */
export enum EeoJobCategoriesEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type EeoJobCategoriesEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EeoJobCategoriesEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: EeoJobCategoriesEnum_Bool_Exp;
};

/** columns and relationships of "EarnedBadges" */
export type EarnedBadges = {
  __typename?: 'EarnedBadges';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  badge_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  payed: Scalars['Boolean'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "EarnedBadges" */
export type EarnedBadges_Aggregate = {
  __typename?: 'EarnedBadges_aggregate';
  aggregate?: Maybe<EarnedBadges_Aggregate_Fields>;
  nodes: Array<EarnedBadges>;
};

export type EarnedBadges_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<EarnedBadges_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<EarnedBadges_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<EarnedBadges_Aggregate_Bool_Exp_Count>;
};

export type EarnedBadges_Aggregate_Bool_Exp_Bool_And = {
  arguments: EarnedBadges_Select_Column_EarnedBadges_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EarnedBadges_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type EarnedBadges_Aggregate_Bool_Exp_Bool_Or = {
  arguments: EarnedBadges_Select_Column_EarnedBadges_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EarnedBadges_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type EarnedBadges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EarnedBadges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "EarnedBadges" */
export type EarnedBadges_Aggregate_Fields = {
  __typename?: 'EarnedBadges_aggregate_fields';
  avg?: Maybe<EarnedBadges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EarnedBadges_Max_Fields>;
  min?: Maybe<EarnedBadges_Min_Fields>;
  stddev?: Maybe<EarnedBadges_Stddev_Fields>;
  stddev_pop?: Maybe<EarnedBadges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EarnedBadges_Stddev_Samp_Fields>;
  sum?: Maybe<EarnedBadges_Sum_Fields>;
  var_pop?: Maybe<EarnedBadges_Var_Pop_Fields>;
  var_samp?: Maybe<EarnedBadges_Var_Samp_Fields>;
  variance?: Maybe<EarnedBadges_Variance_Fields>;
};


/** aggregate fields of "EarnedBadges" */
export type EarnedBadges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "EarnedBadges" */
export type EarnedBadges_Aggregate_Order_By = {
  avg?: InputMaybe<EarnedBadges_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EarnedBadges_Max_Order_By>;
  min?: InputMaybe<EarnedBadges_Min_Order_By>;
  stddev?: InputMaybe<EarnedBadges_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EarnedBadges_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EarnedBadges_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EarnedBadges_Sum_Order_By>;
  var_pop?: InputMaybe<EarnedBadges_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EarnedBadges_Var_Samp_Order_By>;
  variance?: InputMaybe<EarnedBadges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "EarnedBadges" */
export type EarnedBadges_Arr_Rel_Insert_Input = {
  data: Array<EarnedBadges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EarnedBadges_On_Conflict>;
};

/** aggregate avg on columns */
export type EarnedBadges_Avg_Fields = {
  __typename?: 'EarnedBadges_avg_fields';
  badge_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "EarnedBadges" */
export type EarnedBadges_Avg_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "EarnedBadges". All fields are combined with a logical 'AND'. */
export type EarnedBadges_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<EarnedBadges_Bool_Exp>>;
  _not?: InputMaybe<EarnedBadges_Bool_Exp>;
  _or?: InputMaybe<Array<EarnedBadges_Bool_Exp>>;
  badge_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  likes?: InputMaybe<Int_Comparison_Exp>;
  payed?: InputMaybe<Boolean_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "EarnedBadges" */
export enum EarnedBadges_Constraint {
  /** unique or primary key constraint on columns "id" */
  EarnedBadgesPkey = 'EarnedBadges_pkey'
}

/** input type for incrementing numeric columns in table "EarnedBadges" */
export type EarnedBadges_Inc_Input = {
  badge_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  likes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EarnedBadges" */
export type EarnedBadges_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  badge_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  likes?: InputMaybe<Scalars['Int']>;
  payed?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EarnedBadges_Max_Fields = {
  __typename?: 'EarnedBadges_max_fields';
  badge_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "EarnedBadges" */
export type EarnedBadges_Max_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EarnedBadges_Min_Fields = {
  __typename?: 'EarnedBadges_min_fields';
  badge_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "EarnedBadges" */
export type EarnedBadges_Min_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "EarnedBadges" */
export type EarnedBadges_Mutation_Response = {
  __typename?: 'EarnedBadges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EarnedBadges>;
};

/** on_conflict condition type for table "EarnedBadges" */
export type EarnedBadges_On_Conflict = {
  constraint: EarnedBadges_Constraint;
  update_columns?: Array<EarnedBadges_Update_Column>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};

/** Ordering options when selecting data from "EarnedBadges". */
export type EarnedBadges_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  payed?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EarnedBadges */
export type EarnedBadges_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EarnedBadges" */
export enum EarnedBadges_Select_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Likes = 'likes',
  /** column name */
  Payed = 'payed',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "EarnedBadges_aggregate_bool_exp_bool_and_arguments_columns" columns of table "EarnedBadges" */
export enum EarnedBadges_Select_Column_EarnedBadges_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Payed = 'payed'
}

/** select "EarnedBadges_aggregate_bool_exp_bool_or_arguments_columns" columns of table "EarnedBadges" */
export enum EarnedBadges_Select_Column_EarnedBadges_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Payed = 'payed'
}

/** input type for updating data in table "EarnedBadges" */
export type EarnedBadges_Set_Input = {
  badge_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  likes?: InputMaybe<Scalars['Int']>;
  payed?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EarnedBadges_Stddev_Fields = {
  __typename?: 'EarnedBadges_stddev_fields';
  badge_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "EarnedBadges" */
export type EarnedBadges_Stddev_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EarnedBadges_Stddev_Pop_Fields = {
  __typename?: 'EarnedBadges_stddev_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "EarnedBadges" */
export type EarnedBadges_Stddev_Pop_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EarnedBadges_Stddev_Samp_Fields = {
  __typename?: 'EarnedBadges_stddev_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "EarnedBadges" */
export type EarnedBadges_Stddev_Samp_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "EarnedBadges" */
export type EarnedBadges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EarnedBadges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EarnedBadges_Stream_Cursor_Value_Input = {
  badge_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  likes?: InputMaybe<Scalars['Int']>;
  payed?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type EarnedBadges_Sum_Fields = {
  __typename?: 'EarnedBadges_sum_fields';
  badge_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "EarnedBadges" */
export type EarnedBadges_Sum_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** update columns of table "EarnedBadges" */
export enum EarnedBadges_Update_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Likes = 'likes',
  /** column name */
  Payed = 'payed',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EarnedBadges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EarnedBadges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EarnedBadges_Set_Input>;
  /** filter the rows which have to be updated */
  where: EarnedBadges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EarnedBadges_Var_Pop_Fields = {
  __typename?: 'EarnedBadges_var_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "EarnedBadges" */
export type EarnedBadges_Var_Pop_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EarnedBadges_Var_Samp_Fields = {
  __typename?: 'EarnedBadges_var_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "EarnedBadges" */
export type EarnedBadges_Var_Samp_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EarnedBadges_Variance_Fields = {
  __typename?: 'EarnedBadges_variance_fields';
  badge_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "EarnedBadges" */
export type EarnedBadges_Variance_Order_By = {
  badge_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
};

/** columns and relationships of "EmailAddresses" */
export type EmailAddresses = {
  __typename?: 'EmailAddresses';
  normalized_email: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  validation: Scalars['jsonb'];
};


/** columns and relationships of "EmailAddresses" */
export type EmailAddressesValidationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "EmailAddresses" */
export type EmailAddresses_Aggregate = {
  __typename?: 'EmailAddresses_aggregate';
  aggregate?: Maybe<EmailAddresses_Aggregate_Fields>;
  nodes: Array<EmailAddresses>;
};

/** aggregate fields of "EmailAddresses" */
export type EmailAddresses_Aggregate_Fields = {
  __typename?: 'EmailAddresses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailAddresses_Max_Fields>;
  min?: Maybe<EmailAddresses_Min_Fields>;
};


/** aggregate fields of "EmailAddresses" */
export type EmailAddresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailAddresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EmailAddresses_Append_Input = {
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "EmailAddresses". All fields are combined with a logical 'AND'. */
export type EmailAddresses_Bool_Exp = {
  _and?: InputMaybe<Array<EmailAddresses_Bool_Exp>>;
  _not?: InputMaybe<EmailAddresses_Bool_Exp>;
  _or?: InputMaybe<Array<EmailAddresses_Bool_Exp>>;
  normalized_email?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  validation?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailAddresses" */
export enum EmailAddresses_Constraint {
  /** unique or primary key constraint on columns "normalized_email" */
  EmailAddressesPkey = 'EmailAddresses_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EmailAddresses_Delete_At_Path_Input = {
  validation?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EmailAddresses_Delete_Elem_Input = {
  validation?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EmailAddresses_Delete_Key_Input = {
  validation?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "EmailAddresses" */
export type EmailAddresses_Insert_Input = {
  normalized_email?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type EmailAddresses_Max_Fields = {
  __typename?: 'EmailAddresses_max_fields';
  normalized_email?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmailAddresses_Min_Fields = {
  __typename?: 'EmailAddresses_min_fields';
  normalized_email?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EmailAddresses" */
export type EmailAddresses_Mutation_Response = {
  __typename?: 'EmailAddresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailAddresses>;
};

/** on_conflict condition type for table "EmailAddresses" */
export type EmailAddresses_On_Conflict = {
  constraint: EmailAddresses_Constraint;
  update_columns?: Array<EmailAddresses_Update_Column>;
  where?: InputMaybe<EmailAddresses_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailAddresses". */
export type EmailAddresses_Order_By = {
  normalized_email?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  validation?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailAddresses */
export type EmailAddresses_Pk_Columns_Input = {
  normalized_email: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EmailAddresses_Prepend_Input = {
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "EmailAddresses" */
export enum EmailAddresses_Select_Column {
  /** column name */
  NormalizedEmail = 'normalized_email',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Validation = 'validation'
}

/** input type for updating data in table "EmailAddresses" */
export type EmailAddresses_Set_Input = {
  normalized_email?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "EmailAddresses" */
export type EmailAddresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailAddresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailAddresses_Stream_Cursor_Value_Input = {
  normalized_email?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "EmailAddresses" */
export enum EmailAddresses_Update_Column {
  /** column name */
  NormalizedEmail = 'normalized_email',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Validation = 'validation'
}

export type EmailAddresses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EmailAddresses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EmailAddresses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EmailAddresses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EmailAddresses_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EmailAddresses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailAddresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailAddresses_Bool_Exp;
};

/** columns and relationships of "EmailEvents" */
export type EmailEvents = {
  __typename?: 'EmailEvents';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message_id?: Maybe<Scalars['String']>;
  payload: Scalars['jsonb'];
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "EmailEvents" */
export type EmailEventsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "EmailEvents" */
export type EmailEvents_Aggregate = {
  __typename?: 'EmailEvents_aggregate';
  aggregate?: Maybe<EmailEvents_Aggregate_Fields>;
  nodes: Array<EmailEvents>;
};

/** aggregate fields of "EmailEvents" */
export type EmailEvents_Aggregate_Fields = {
  __typename?: 'EmailEvents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailEvents_Max_Fields>;
  min?: Maybe<EmailEvents_Min_Fields>;
};


/** aggregate fields of "EmailEvents" */
export type EmailEvents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailEvents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EmailEvents_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "EmailEvents". All fields are combined with a logical 'AND'. */
export type EmailEvents_Bool_Exp = {
  _and?: InputMaybe<Array<EmailEvents_Bool_Exp>>;
  _not?: InputMaybe<EmailEvents_Bool_Exp>;
  _or?: InputMaybe<Array<EmailEvents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message_id?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailEvents" */
export enum EmailEvents_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailEventsPkey = 'EmailEvents_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EmailEvents_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EmailEvents_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EmailEvents_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "EmailEvents" */
export type EmailEvents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EmailEvents_Max_Fields = {
  __typename?: 'EmailEvents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmailEvents_Min_Fields = {
  __typename?: 'EmailEvents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EmailEvents" */
export type EmailEvents_Mutation_Response = {
  __typename?: 'EmailEvents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailEvents>;
};

/** on_conflict condition type for table "EmailEvents" */
export type EmailEvents_On_Conflict = {
  constraint: EmailEvents_Constraint;
  update_columns?: Array<EmailEvents_Update_Column>;
  where?: InputMaybe<EmailEvents_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailEvents". */
export type EmailEvents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailEvents */
export type EmailEvents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EmailEvents_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "EmailEvents" */
export enum EmailEvents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "EmailEvents" */
export type EmailEvents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "EmailEvents" */
export type EmailEvents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailEvents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailEvents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message_id?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** update columns of table "EmailEvents" */
export enum EmailEvents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type EmailEvents_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EmailEvents_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EmailEvents_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EmailEvents_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EmailEvents_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EmailEvents_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailEvents_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailEvents_Bool_Exp;
};

/** columns and relationships of "EmailRecords" */
export type EmailRecords = {
  __typename?: 'EmailRecords';
  /** An object relationship */
  Email: Emails;
  /** An object relationship */
  Identity: Identities;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id: Scalars['Int'];
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "EmailRecords" */
export type EmailRecords_Aggregate = {
  __typename?: 'EmailRecords_aggregate';
  aggregate?: Maybe<EmailRecords_Aggregate_Fields>;
  nodes: Array<EmailRecords>;
};

export type EmailRecords_Aggregate_Bool_Exp = {
  count?: InputMaybe<EmailRecords_Aggregate_Bool_Exp_Count>;
};

export type EmailRecords_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EmailRecords_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmailRecords_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "EmailRecords" */
export type EmailRecords_Aggregate_Fields = {
  __typename?: 'EmailRecords_aggregate_fields';
  avg?: Maybe<EmailRecords_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EmailRecords_Max_Fields>;
  min?: Maybe<EmailRecords_Min_Fields>;
  stddev?: Maybe<EmailRecords_Stddev_Fields>;
  stddev_pop?: Maybe<EmailRecords_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EmailRecords_Stddev_Samp_Fields>;
  sum?: Maybe<EmailRecords_Sum_Fields>;
  var_pop?: Maybe<EmailRecords_Var_Pop_Fields>;
  var_samp?: Maybe<EmailRecords_Var_Samp_Fields>;
  variance?: Maybe<EmailRecords_Variance_Fields>;
};


/** aggregate fields of "EmailRecords" */
export type EmailRecords_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailRecords_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "EmailRecords" */
export type EmailRecords_Aggregate_Order_By = {
  avg?: InputMaybe<EmailRecords_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EmailRecords_Max_Order_By>;
  min?: InputMaybe<EmailRecords_Min_Order_By>;
  stddev?: InputMaybe<EmailRecords_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EmailRecords_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EmailRecords_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EmailRecords_Sum_Order_By>;
  var_pop?: InputMaybe<EmailRecords_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EmailRecords_Var_Samp_Order_By>;
  variance?: InputMaybe<EmailRecords_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "EmailRecords" */
export type EmailRecords_Arr_Rel_Insert_Input = {
  data: Array<EmailRecords_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EmailRecords_On_Conflict>;
};

/** aggregate avg on columns */
export type EmailRecords_Avg_Fields = {
  __typename?: 'EmailRecords_avg_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "EmailRecords" */
export type EmailRecords_Avg_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "EmailRecords". All fields are combined with a logical 'AND'. */
export type EmailRecords_Bool_Exp = {
  Email?: InputMaybe<Emails_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<EmailRecords_Bool_Exp>>;
  _not?: InputMaybe<EmailRecords_Bool_Exp>;
  _or?: InputMaybe<Array<EmailRecords_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailRecords" */
export enum EmailRecords_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailRecordsPkey = 'EmailRecords_pkey'
}

/** input type for incrementing numeric columns in table "EmailRecords" */
export type EmailRecords_Inc_Input = {
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EmailRecords" */
export type EmailRecords_Insert_Input = {
  Email?: InputMaybe<Emails_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailRecords_Max_Fields = {
  __typename?: 'EmailRecords_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "EmailRecords" */
export type EmailRecords_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EmailRecords_Min_Fields = {
  __typename?: 'EmailRecords_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "EmailRecords" */
export type EmailRecords_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "EmailRecords" */
export type EmailRecords_Mutation_Response = {
  __typename?: 'EmailRecords_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailRecords>;
};

/** on_conflict condition type for table "EmailRecords" */
export type EmailRecords_On_Conflict = {
  constraint: EmailRecords_Constraint;
  update_columns?: Array<EmailRecords_Update_Column>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailRecords". */
export type EmailRecords_Order_By = {
  Email?: InputMaybe<Emails_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailRecords */
export type EmailRecords_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EmailRecords" */
export enum EmailRecords_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "EmailRecords" */
export type EmailRecords_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EmailRecords_Stddev_Fields = {
  __typename?: 'EmailRecords_stddev_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "EmailRecords" */
export type EmailRecords_Stddev_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EmailRecords_Stddev_Pop_Fields = {
  __typename?: 'EmailRecords_stddev_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "EmailRecords" */
export type EmailRecords_Stddev_Pop_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EmailRecords_Stddev_Samp_Fields = {
  __typename?: 'EmailRecords_stddev_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "EmailRecords" */
export type EmailRecords_Stddev_Samp_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "EmailRecords" */
export type EmailRecords_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailRecords_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailRecords_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type EmailRecords_Sum_Fields = {
  __typename?: 'EmailRecords_sum_fields';
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "EmailRecords" */
export type EmailRecords_Sum_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "EmailRecords" */
export enum EmailRecords_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EmailRecords_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EmailRecords_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailRecords_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailRecords_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EmailRecords_Var_Pop_Fields = {
  __typename?: 'EmailRecords_var_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "EmailRecords" */
export type EmailRecords_Var_Pop_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EmailRecords_Var_Samp_Fields = {
  __typename?: 'EmailRecords_var_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "EmailRecords" */
export type EmailRecords_Var_Samp_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EmailRecords_Variance_Fields = {
  __typename?: 'EmailRecords_variance_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "EmailRecords" */
export type EmailRecords_Variance_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "EmailSignatures" */
export type EmailSignatures = {
  __typename?: 'EmailSignatures';
  /** An object relationship */
  Identity: Identities;
  company_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  name: Scalars['String'];
  signature: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "EmailSignatures" */
export type EmailSignatures_Aggregate = {
  __typename?: 'EmailSignatures_aggregate';
  aggregate?: Maybe<EmailSignatures_Aggregate_Fields>;
  nodes: Array<EmailSignatures>;
};

export type EmailSignatures_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<EmailSignatures_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<EmailSignatures_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<EmailSignatures_Aggregate_Bool_Exp_Count>;
};

export type EmailSignatures_Aggregate_Bool_Exp_Bool_And = {
  arguments: EmailSignatures_Select_Column_EmailSignatures_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmailSignatures_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type EmailSignatures_Aggregate_Bool_Exp_Bool_Or = {
  arguments: EmailSignatures_Select_Column_EmailSignatures_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmailSignatures_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type EmailSignatures_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmailSignatures_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "EmailSignatures" */
export type EmailSignatures_Aggregate_Fields = {
  __typename?: 'EmailSignatures_aggregate_fields';
  avg?: Maybe<EmailSignatures_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EmailSignatures_Max_Fields>;
  min?: Maybe<EmailSignatures_Min_Fields>;
  stddev?: Maybe<EmailSignatures_Stddev_Fields>;
  stddev_pop?: Maybe<EmailSignatures_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EmailSignatures_Stddev_Samp_Fields>;
  sum?: Maybe<EmailSignatures_Sum_Fields>;
  var_pop?: Maybe<EmailSignatures_Var_Pop_Fields>;
  var_samp?: Maybe<EmailSignatures_Var_Samp_Fields>;
  variance?: Maybe<EmailSignatures_Variance_Fields>;
};


/** aggregate fields of "EmailSignatures" */
export type EmailSignatures_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "EmailSignatures" */
export type EmailSignatures_Aggregate_Order_By = {
  avg?: InputMaybe<EmailSignatures_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EmailSignatures_Max_Order_By>;
  min?: InputMaybe<EmailSignatures_Min_Order_By>;
  stddev?: InputMaybe<EmailSignatures_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EmailSignatures_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EmailSignatures_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EmailSignatures_Sum_Order_By>;
  var_pop?: InputMaybe<EmailSignatures_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EmailSignatures_Var_Samp_Order_By>;
  variance?: InputMaybe<EmailSignatures_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "EmailSignatures" */
export type EmailSignatures_Arr_Rel_Insert_Input = {
  data: Array<EmailSignatures_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EmailSignatures_On_Conflict>;
};

/** aggregate avg on columns */
export type EmailSignatures_Avg_Fields = {
  __typename?: 'EmailSignatures_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "EmailSignatures" */
export type EmailSignatures_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "EmailSignatures". All fields are combined with a logical 'AND'. */
export type EmailSignatures_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<EmailSignatures_Bool_Exp>>;
  _not?: InputMaybe<EmailSignatures_Bool_Exp>;
  _or?: InputMaybe<Array<EmailSignatures_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  signature?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailSignatures" */
export enum EmailSignatures_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailSignaturesPkey = 'email_signatures_pkey'
}

/** input type for incrementing numeric columns in table "EmailSignatures" */
export type EmailSignatures_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EmailSignatures" */
export type EmailSignatures_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailSignatures_Max_Fields = {
  __typename?: 'EmailSignatures_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "EmailSignatures" */
export type EmailSignatures_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  signature?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EmailSignatures_Min_Fields = {
  __typename?: 'EmailSignatures_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "EmailSignatures" */
export type EmailSignatures_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  signature?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "EmailSignatures" */
export type EmailSignatures_Mutation_Response = {
  __typename?: 'EmailSignatures_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailSignatures>;
};

/** on_conflict condition type for table "EmailSignatures" */
export type EmailSignatures_On_Conflict = {
  constraint: EmailSignatures_Constraint;
  update_columns?: Array<EmailSignatures_Update_Column>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailSignatures". */
export type EmailSignatures_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  signature?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailSignatures */
export type EmailSignatures_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EmailSignatures" */
export enum EmailSignatures_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Name = 'name',
  /** column name */
  Signature = 'signature',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "EmailSignatures_aggregate_bool_exp_bool_and_arguments_columns" columns of table "EmailSignatures" */
export enum EmailSignatures_Select_Column_EmailSignatures_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Default = 'default'
}

/** select "EmailSignatures_aggregate_bool_exp_bool_or_arguments_columns" columns of table "EmailSignatures" */
export enum EmailSignatures_Select_Column_EmailSignatures_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Default = 'default'
}

/** input type for updating data in table "EmailSignatures" */
export type EmailSignatures_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EmailSignatures_Stddev_Fields = {
  __typename?: 'EmailSignatures_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "EmailSignatures" */
export type EmailSignatures_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EmailSignatures_Stddev_Pop_Fields = {
  __typename?: 'EmailSignatures_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "EmailSignatures" */
export type EmailSignatures_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EmailSignatures_Stddev_Samp_Fields = {
  __typename?: 'EmailSignatures_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "EmailSignatures" */
export type EmailSignatures_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "EmailSignatures" */
export type EmailSignatures_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailSignatures_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailSignatures_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type EmailSignatures_Sum_Fields = {
  __typename?: 'EmailSignatures_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "EmailSignatures" */
export type EmailSignatures_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "EmailSignatures" */
export enum EmailSignatures_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Name = 'name',
  /** column name */
  Signature = 'signature',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EmailSignatures_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EmailSignatures_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailSignatures_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailSignatures_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EmailSignatures_Var_Pop_Fields = {
  __typename?: 'EmailSignatures_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "EmailSignatures" */
export type EmailSignatures_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EmailSignatures_Var_Samp_Fields = {
  __typename?: 'EmailSignatures_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "EmailSignatures" */
export type EmailSignatures_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EmailSignatures_Variance_Fields = {
  __typename?: 'EmailSignatures_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "EmailSignatures" */
export type EmailSignatures_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "EmailSubFolders" */
export type EmailSubFolders = {
  __typename?: 'EmailSubFolders';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  email_type: Scalars['String'];
  /** An array relationship */
  emails: Array<Emails>;
  /** An aggregate relationship */
  emails_aggregate: Emails_Aggregate;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  is_partner_folder: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};


/** columns and relationships of "EmailSubFolders" */
export type EmailSubFoldersEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "EmailSubFolders" */
export type EmailSubFoldersEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};

/** aggregated selection of "EmailSubFolders" */
export type EmailSubFolders_Aggregate = {
  __typename?: 'EmailSubFolders_aggregate';
  aggregate?: Maybe<EmailSubFolders_Aggregate_Fields>;
  nodes: Array<EmailSubFolders>;
};

/** aggregate fields of "EmailSubFolders" */
export type EmailSubFolders_Aggregate_Fields = {
  __typename?: 'EmailSubFolders_aggregate_fields';
  avg?: Maybe<EmailSubFolders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EmailSubFolders_Max_Fields>;
  min?: Maybe<EmailSubFolders_Min_Fields>;
  stddev?: Maybe<EmailSubFolders_Stddev_Fields>;
  stddev_pop?: Maybe<EmailSubFolders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EmailSubFolders_Stddev_Samp_Fields>;
  sum?: Maybe<EmailSubFolders_Sum_Fields>;
  var_pop?: Maybe<EmailSubFolders_Var_Pop_Fields>;
  var_samp?: Maybe<EmailSubFolders_Var_Samp_Fields>;
  variance?: Maybe<EmailSubFolders_Variance_Fields>;
};


/** aggregate fields of "EmailSubFolders" */
export type EmailSubFolders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailSubFolders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type EmailSubFolders_Avg_Fields = {
  __typename?: 'EmailSubFolders_avg_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "EmailSubFolders". All fields are combined with a logical 'AND'. */
export type EmailSubFolders_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<EmailSubFolders_Bool_Exp>>;
  _not?: InputMaybe<EmailSubFolders_Bool_Exp>;
  _or?: InputMaybe<Array<EmailSubFolders_Bool_Exp>>;
  email_type?: InputMaybe<String_Comparison_Exp>;
  emails?: InputMaybe<Emails_Bool_Exp>;
  emails_aggregate?: InputMaybe<Emails_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  is_partner_folder?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailSubFolders" */
export enum EmailSubFolders_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailSubFoldersPkey = 'EmailSubFolders_pkey'
}

/** input type for incrementing numeric columns in table "EmailSubFolders" */
export type EmailSubFolders_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EmailSubFolders" */
export type EmailSubFolders_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  email_type?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Emails_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_partner_folder?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EmailSubFolders_Max_Fields = {
  __typename?: 'EmailSubFolders_max_fields';
  email_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmailSubFolders_Min_Fields = {
  __typename?: 'EmailSubFolders_min_fields';
  email_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EmailSubFolders" */
export type EmailSubFolders_Mutation_Response = {
  __typename?: 'EmailSubFolders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailSubFolders>;
};

/** input type for inserting object relation for remote table "EmailSubFolders" */
export type EmailSubFolders_Obj_Rel_Insert_Input = {
  data: EmailSubFolders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EmailSubFolders_On_Conflict>;
};

/** on_conflict condition type for table "EmailSubFolders" */
export type EmailSubFolders_On_Conflict = {
  constraint: EmailSubFolders_Constraint;
  update_columns?: Array<EmailSubFolders_Update_Column>;
  where?: InputMaybe<EmailSubFolders_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailSubFolders". */
export type EmailSubFolders_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  email_type?: InputMaybe<Order_By>;
  emails_aggregate?: InputMaybe<Emails_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  is_partner_folder?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailSubFolders */
export type EmailSubFolders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EmailSubFolders" */
export enum EmailSubFolders_Select_Column {
  /** column name */
  EmailType = 'email_type',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsPartnerFolder = 'is_partner_folder',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "EmailSubFolders" */
export type EmailSubFolders_Set_Input = {
  email_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_partner_folder?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EmailSubFolders_Stddev_Fields = {
  __typename?: 'EmailSubFolders_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type EmailSubFolders_Stddev_Pop_Fields = {
  __typename?: 'EmailSubFolders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type EmailSubFolders_Stddev_Samp_Fields = {
  __typename?: 'EmailSubFolders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "EmailSubFolders" */
export type EmailSubFolders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailSubFolders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailSubFolders_Stream_Cursor_Value_Input = {
  email_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_partner_folder?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type EmailSubFolders_Sum_Fields = {
  __typename?: 'EmailSubFolders_sum_fields';
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "EmailSubFolders" */
export enum EmailSubFolders_Update_Column {
  /** column name */
  EmailType = 'email_type',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsPartnerFolder = 'is_partner_folder',
  /** column name */
  Name = 'name'
}

export type EmailSubFolders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EmailSubFolders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailSubFolders_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailSubFolders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EmailSubFolders_Var_Pop_Fields = {
  __typename?: 'EmailSubFolders_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type EmailSubFolders_Var_Samp_Fields = {
  __typename?: 'EmailSubFolders_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type EmailSubFolders_Variance_Fields = {
  __typename?: 'EmailSubFolders_variance_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "EmailTypeEnum" */
export type EmailTypeEnum = {
  __typename?: 'EmailTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "EmailTypeEnum" */
export type EmailTypeEnum_Aggregate = {
  __typename?: 'EmailTypeEnum_aggregate';
  aggregate?: Maybe<EmailTypeEnum_Aggregate_Fields>;
  nodes: Array<EmailTypeEnum>;
};

/** aggregate fields of "EmailTypeEnum" */
export type EmailTypeEnum_Aggregate_Fields = {
  __typename?: 'EmailTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailTypeEnum_Max_Fields>;
  min?: Maybe<EmailTypeEnum_Min_Fields>;
};


/** aggregate fields of "EmailTypeEnum" */
export type EmailTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "EmailTypeEnum". All fields are combined with a logical 'AND'. */
export type EmailTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<EmailTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<EmailTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<EmailTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailTypeEnum" */
export enum EmailTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  EmailTypeEnumPkey = 'EmailTypeEnum_pkey'
}

export enum EmailTypeEnum_Enum {
  Action = 'Action',
  Email = 'Email',
  Rejection = 'Rejection',
  Stage = 'Stage',
  Termination = 'Termination'
}

/** Boolean expression to compare columns of type "EmailTypeEnum_enum". All fields are combined with logical 'AND'. */
export type EmailTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<EmailTypeEnum_Enum>;
  _in?: InputMaybe<Array<EmailTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailTypeEnum_Enum>;
  _nin?: InputMaybe<Array<EmailTypeEnum_Enum>>;
};

/** input type for inserting data into table "EmailTypeEnum" */
export type EmailTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EmailTypeEnum_Max_Fields = {
  __typename?: 'EmailTypeEnum_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmailTypeEnum_Min_Fields = {
  __typename?: 'EmailTypeEnum_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EmailTypeEnum" */
export type EmailTypeEnum_Mutation_Response = {
  __typename?: 'EmailTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailTypeEnum>;
};

/** on_conflict condition type for table "EmailTypeEnum" */
export type EmailTypeEnum_On_Conflict = {
  constraint: EmailTypeEnum_Constraint;
  update_columns?: Array<EmailTypeEnum_Update_Column>;
  where?: InputMaybe<EmailTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailTypeEnum". */
export type EmailTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailTypeEnum */
export type EmailTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "EmailTypeEnum" */
export enum EmailTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "EmailTypeEnum" */
export type EmailTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "EmailTypeEnum" */
export type EmailTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "EmailTypeEnum" */
export enum EmailTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type EmailTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailTypeEnum_Bool_Exp;
};

/** columns and relationships of "EmailTypes" */
export type EmailTypes = {
  __typename?: 'EmailTypes';
  /** An array relationship */
  Emails: Array<Emails>;
  /** An aggregate relationship */
  Emails_aggregate: Emails_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "EmailTypes" */
export type EmailTypesEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "EmailTypes" */
export type EmailTypesEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};

/** aggregated selection of "EmailTypes" */
export type EmailTypes_Aggregate = {
  __typename?: 'EmailTypes_aggregate';
  aggregate?: Maybe<EmailTypes_Aggregate_Fields>;
  nodes: Array<EmailTypes>;
};

/** aggregate fields of "EmailTypes" */
export type EmailTypes_Aggregate_Fields = {
  __typename?: 'EmailTypes_aggregate_fields';
  avg?: Maybe<EmailTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EmailTypes_Max_Fields>;
  min?: Maybe<EmailTypes_Min_Fields>;
  stddev?: Maybe<EmailTypes_Stddev_Fields>;
  stddev_pop?: Maybe<EmailTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EmailTypes_Stddev_Samp_Fields>;
  sum?: Maybe<EmailTypes_Sum_Fields>;
  var_pop?: Maybe<EmailTypes_Var_Pop_Fields>;
  var_samp?: Maybe<EmailTypes_Var_Samp_Fields>;
  variance?: Maybe<EmailTypes_Variance_Fields>;
};


/** aggregate fields of "EmailTypes" */
export type EmailTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type EmailTypes_Avg_Fields = {
  __typename?: 'EmailTypes_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "EmailTypes". All fields are combined with a logical 'AND'. */
export type EmailTypes_Bool_Exp = {
  Emails?: InputMaybe<Emails_Bool_Exp>;
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<EmailTypes_Bool_Exp>>;
  _not?: InputMaybe<EmailTypes_Bool_Exp>;
  _or?: InputMaybe<Array<EmailTypes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmailTypes" */
export enum EmailTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailTypesPkey = 'EmailTypes_pkey'
}

/** input type for incrementing numeric columns in table "EmailTypes" */
export type EmailTypes_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EmailTypes" */
export type EmailTypes_Insert_Input = {
  Emails?: InputMaybe<Emails_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailTypes_Max_Fields = {
  __typename?: 'EmailTypes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type EmailTypes_Min_Fields = {
  __typename?: 'EmailTypes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "EmailTypes" */
export type EmailTypes_Mutation_Response = {
  __typename?: 'EmailTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailTypes>;
};

/** input type for inserting object relation for remote table "EmailTypes" */
export type EmailTypes_Obj_Rel_Insert_Input = {
  data: EmailTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EmailTypes_On_Conflict>;
};

/** on_conflict condition type for table "EmailTypes" */
export type EmailTypes_On_Conflict = {
  constraint: EmailTypes_Constraint;
  update_columns?: Array<EmailTypes_Update_Column>;
  where?: InputMaybe<EmailTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "EmailTypes". */
export type EmailTypes_Order_By = {
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmailTypes */
export type EmailTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EmailTypes" */
export enum EmailTypes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "EmailTypes" */
export type EmailTypes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type EmailTypes_Stddev_Fields = {
  __typename?: 'EmailTypes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type EmailTypes_Stddev_Pop_Fields = {
  __typename?: 'EmailTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type EmailTypes_Stddev_Samp_Fields = {
  __typename?: 'EmailTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "EmailTypes" */
export type EmailTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailTypes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type EmailTypes_Sum_Fields = {
  __typename?: 'EmailTypes_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "EmailTypes" */
export enum EmailTypes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EmailTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EmailTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EmailTypes_Var_Pop_Fields = {
  __typename?: 'EmailTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type EmailTypes_Var_Samp_Fields = {
  __typename?: 'EmailTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type EmailTypes_Variance_Fields = {
  __typename?: 'EmailTypes_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Emails" */
export type Emails = {
  __typename?: 'Emails';
  /** An array relationship */
  Attachments: Array<Attachments>;
  /** An aggregate relationship */
  Attachments_aggregate: Attachments_Aggregate;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  EmailType?: Maybe<EmailTypes>;
  /** An object relationship */
  IdentitySubType?: Maybe<IdentitySubTypes>;
  /** An array relationship */
  IdentitySubTypeEmails: Array<IdentitySubTypeEmails>;
  /** An aggregate relationship */
  IdentitySubTypeEmails_aggregate: IdentitySubTypeEmails_Aggregate;
  /** An array relationship */
  Rejections: Array<Rejections>;
  /** An aggregate relationship */
  Rejections_aggregate: Rejections_Aggregate;
  /** An object relationship */
  Stage?: Maybe<Stages>;
  /** An array relationship */
  StageEmails: Array<StageEmails>;
  /** An aggregate relationship */
  StageEmails_aggregate: StageEmails_Aggregate;
  attach_primary_resume: Scalars['Boolean'];
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  body_v2?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  contract_emails: Array<Contract_Emails>;
  /** An aggregate relationship */
  contract_emails_aggregate: Contract_Emails_Aggregate;
  created_at: Scalars['timestamptz'];
  email_blurb?: Maybe<Scalars['String']>;
  /** An object relationship */
  email_sub_folder?: Maybe<EmailSubFolders>;
  fromEmail?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  is_docusign?: Maybe<Scalars['Boolean']>;
  list_order: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  sub_folder_id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type: EmailTypeEnum_Enum;
  type_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Emails" */
export type EmailsAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsIdentitySubTypeEmailsArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsIdentitySubTypeEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsRejectionsArgs = {
  distinct_on?: InputMaybe<Array<Rejections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rejections_Order_By>>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsRejections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rejections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rejections_Order_By>>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsStageEmailsArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsStageEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsContract_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


/** columns and relationships of "Emails" */
export type EmailsContract_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};

/** aggregated selection of "Emails" */
export type Emails_Aggregate = {
  __typename?: 'Emails_aggregate';
  aggregate?: Maybe<Emails_Aggregate_Fields>;
  nodes: Array<Emails>;
};

export type Emails_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Emails_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Emails_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Emails_Aggregate_Bool_Exp_Count>;
};

export type Emails_Aggregate_Bool_Exp_Bool_And = {
  arguments: Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Emails_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Emails_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Emails_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Emails_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Emails_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Emails" */
export type Emails_Aggregate_Fields = {
  __typename?: 'Emails_aggregate_fields';
  avg?: Maybe<Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Emails_Max_Fields>;
  min?: Maybe<Emails_Min_Fields>;
  stddev?: Maybe<Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Emails_Sum_Fields>;
  var_pop?: Maybe<Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Emails_Var_Samp_Fields>;
  variance?: Maybe<Emails_Variance_Fields>;
};


/** aggregate fields of "Emails" */
export type Emails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Emails" */
export type Emails_Aggregate_Order_By = {
  avg?: InputMaybe<Emails_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Emails_Max_Order_By>;
  min?: InputMaybe<Emails_Min_Order_By>;
  stddev?: InputMaybe<Emails_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Emails_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Emails_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Emails_Sum_Order_By>;
  var_pop?: InputMaybe<Emails_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Emails_Var_Samp_Order_By>;
  variance?: InputMaybe<Emails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Emails" */
export type Emails_Arr_Rel_Insert_Input = {
  data: Array<Emails_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Emails_On_Conflict>;
};

/** aggregate avg on columns */
export type Emails_Avg_Fields = {
  __typename?: 'Emails_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  list_order?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_folder_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Emails" */
export type Emails_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Emails". All fields are combined with a logical 'AND'. */
export type Emails_Bool_Exp = {
  Attachments?: InputMaybe<Attachments_Bool_Exp>;
  Attachments_aggregate?: InputMaybe<Attachments_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  EmailType?: InputMaybe<EmailTypes_Bool_Exp>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Bool_Exp>;
  IdentitySubTypeEmails?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
  IdentitySubTypeEmails_aggregate?: InputMaybe<IdentitySubTypeEmails_Aggregate_Bool_Exp>;
  Rejections?: InputMaybe<Rejections_Bool_Exp>;
  Rejections_aggregate?: InputMaybe<Rejections_Aggregate_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  StageEmails?: InputMaybe<StageEmails_Bool_Exp>;
  StageEmails_aggregate?: InputMaybe<StageEmails_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Emails_Bool_Exp>>;
  _not?: InputMaybe<Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Emails_Bool_Exp>>;
  attach_primary_resume?: InputMaybe<Boolean_Comparison_Exp>;
  bcc?: InputMaybe<String_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  body_v2?: InputMaybe<String_Comparison_Exp>;
  cc?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contract_emails?: InputMaybe<Contract_Emails_Bool_Exp>;
  contract_emails_aggregate?: InputMaybe<Contract_Emails_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_blurb?: InputMaybe<String_Comparison_Exp>;
  email_sub_folder?: InputMaybe<EmailSubFolders_Bool_Exp>;
  fromEmail?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  identity_sub_type_id?: InputMaybe<Int_Comparison_Exp>;
  is_docusign?: InputMaybe<Boolean_Comparison_Exp>;
  list_order?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  sub_folder_id?: InputMaybe<Int_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  toEmail?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<EmailTypeEnum_Enum_Comparison_Exp>;
  type_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Emails" */
export enum Emails_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailsPkey = 'Emails_pkey'
}

/** input type for incrementing numeric columns in table "Emails" */
export type Emails_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  list_order?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_folder_id?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Emails" */
export type Emails_Insert_Input = {
  Attachments?: InputMaybe<Attachments_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  EmailType?: InputMaybe<EmailTypes_Obj_Rel_Insert_Input>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Obj_Rel_Insert_Input>;
  IdentitySubTypeEmails?: InputMaybe<IdentitySubTypeEmails_Arr_Rel_Insert_Input>;
  Rejections?: InputMaybe<Rejections_Arr_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  StageEmails?: InputMaybe<StageEmails_Arr_Rel_Insert_Input>;
  attach_primary_resume?: InputMaybe<Scalars['Boolean']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  body_v2?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_emails?: InputMaybe<Contract_Emails_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_blurb?: InputMaybe<Scalars['String']>;
  email_sub_folder?: InputMaybe<EmailSubFolders_Obj_Rel_Insert_Input>;
  fromEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  is_docusign?: InputMaybe<Scalars['Boolean']>;
  list_order?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_folder_id?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  toEmail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTypeEnum_Enum>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Emails_Max_Fields = {
  __typename?: 'Emails_max_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  body_v2?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_blurb?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  list_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  sub_folder_id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Emails" */
export type Emails_Max_Order_By = {
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  body_v2?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_blurb?: InputMaybe<Order_By>;
  fromEmail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  toEmail?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Emails_Min_Fields = {
  __typename?: 'Emails_min_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  body_v2?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_blurb?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  list_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  sub_folder_id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Emails" */
export type Emails_Min_Order_By = {
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  body_v2?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_blurb?: InputMaybe<Order_By>;
  fromEmail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  toEmail?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Emails" */
export type Emails_Mutation_Response = {
  __typename?: 'Emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Emails>;
};

/** input type for inserting object relation for remote table "Emails" */
export type Emails_Obj_Rel_Insert_Input = {
  data: Emails_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Emails_On_Conflict>;
};

/** on_conflict condition type for table "Emails" */
export type Emails_On_Conflict = {
  constraint: Emails_Constraint;
  update_columns?: Array<Emails_Update_Column>;
  where?: InputMaybe<Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "Emails". */
export type Emails_Order_By = {
  Attachments_aggregate?: InputMaybe<Attachments_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  EmailType?: InputMaybe<EmailTypes_Order_By>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Order_By>;
  IdentitySubTypeEmails_aggregate?: InputMaybe<IdentitySubTypeEmails_Aggregate_Order_By>;
  Rejections_aggregate?: InputMaybe<Rejections_Aggregate_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  StageEmails_aggregate?: InputMaybe<StageEmails_Aggregate_Order_By>;
  attach_primary_resume?: InputMaybe<Order_By>;
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  body_v2?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_emails_aggregate?: InputMaybe<Contract_Emails_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_blurb?: InputMaybe<Order_By>;
  email_sub_folder?: InputMaybe<EmailSubFolders_Order_By>;
  fromEmail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  is_docusign?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  toEmail?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Emails */
export type Emails_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Emails" */
export enum Emails_Select_Column {
  /** column name */
  AttachPrimaryResume = 'attach_primary_resume',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  BodyV2 = 'body_v2',
  /** column name */
  Cc = 'cc',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailBlurb = 'email_blurb',
  /** column name */
  FromEmail = 'fromEmail',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IdentitySubTypeId = 'identity_sub_type_id',
  /** column name */
  IsDocusign = 'is_docusign',
  /** column name */
  ListOrder = 'list_order',
  /** column name */
  Name = 'name',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  SubFolderId = 'sub_folder_id',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToEmail = 'toEmail',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "Emails_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Emails" */
export enum Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AttachPrimaryResume = 'attach_primary_resume',
  /** column name */
  IsDocusign = 'is_docusign'
}

/** select "Emails_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Emails" */
export enum Emails_Select_Column_Emails_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AttachPrimaryResume = 'attach_primary_resume',
  /** column name */
  IsDocusign = 'is_docusign'
}

/** input type for updating data in table "Emails" */
export type Emails_Set_Input = {
  attach_primary_resume?: InputMaybe<Scalars['Boolean']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  body_v2?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_blurb?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  is_docusign?: InputMaybe<Scalars['Boolean']>;
  list_order?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_folder_id?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  toEmail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTypeEnum_Enum>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Emails_Stddev_Fields = {
  __typename?: 'Emails_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  list_order?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_folder_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Emails" */
export type Emails_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Emails_Stddev_Pop_Fields = {
  __typename?: 'Emails_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  list_order?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_folder_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Emails" */
export type Emails_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Emails_Stddev_Samp_Fields = {
  __typename?: 'Emails_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  list_order?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_folder_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Emails" */
export type Emails_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Emails" */
export type Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Emails_Stream_Cursor_Value_Input = {
  attach_primary_resume?: InputMaybe<Scalars['Boolean']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  body_v2?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_blurb?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  is_docusign?: InputMaybe<Scalars['Boolean']>;
  list_order?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_folder_id?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  toEmail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTypeEnum_Enum>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Emails_Sum_Fields = {
  __typename?: 'Emails_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  list_order?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  sub_folder_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Emails" */
export type Emails_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Emails" */
export enum Emails_Update_Column {
  /** column name */
  AttachPrimaryResume = 'attach_primary_resume',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  BodyV2 = 'body_v2',
  /** column name */
  Cc = 'cc',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailBlurb = 'email_blurb',
  /** column name */
  FromEmail = 'fromEmail',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IdentitySubTypeId = 'identity_sub_type_id',
  /** column name */
  IsDocusign = 'is_docusign',
  /** column name */
  ListOrder = 'list_order',
  /** column name */
  Name = 'name',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  SubFolderId = 'sub_folder_id',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToEmail = 'toEmail',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Emails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Emails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Emails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Emails_Var_Pop_Fields = {
  __typename?: 'Emails_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  list_order?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_folder_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Emails" */
export type Emails_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Emails_Var_Samp_Fields = {
  __typename?: 'Emails_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  list_order?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_folder_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Emails" */
export type Emails_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Emails_Variance_Fields = {
  __typename?: 'Emails_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  list_order?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_folder_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Emails" */
export type Emails_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  list_order?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_folder_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "EmploymentHireType" */
export type EmploymentHireType = {
  __typename?: 'EmploymentHireType';
  key: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "EmploymentHireType" */
export type EmploymentHireType_Aggregate = {
  __typename?: 'EmploymentHireType_aggregate';
  aggregate?: Maybe<EmploymentHireType_Aggregate_Fields>;
  nodes: Array<EmploymentHireType>;
};

/** aggregate fields of "EmploymentHireType" */
export type EmploymentHireType_Aggregate_Fields = {
  __typename?: 'EmploymentHireType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmploymentHireType_Max_Fields>;
  min?: Maybe<EmploymentHireType_Min_Fields>;
};


/** aggregate fields of "EmploymentHireType" */
export type EmploymentHireType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmploymentHireType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "EmploymentHireType". All fields are combined with a logical 'AND'. */
export type EmploymentHireType_Bool_Exp = {
  _and?: InputMaybe<Array<EmploymentHireType_Bool_Exp>>;
  _not?: InputMaybe<EmploymentHireType_Bool_Exp>;
  _or?: InputMaybe<Array<EmploymentHireType_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmploymentHireType" */
export enum EmploymentHireType_Constraint {
  /** unique or primary key constraint on columns "key" */
  EmploymentHireTypePkey = 'EmploymentHireType_pkey'
}

/** input type for inserting data into table "EmploymentHireType" */
export type EmploymentHireType_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EmploymentHireType_Max_Fields = {
  __typename?: 'EmploymentHireType_max_fields';
  key?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmploymentHireType_Min_Fields = {
  __typename?: 'EmploymentHireType_min_fields';
  key?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EmploymentHireType" */
export type EmploymentHireType_Mutation_Response = {
  __typename?: 'EmploymentHireType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmploymentHireType>;
};

/** input type for inserting object relation for remote table "EmploymentHireType" */
export type EmploymentHireType_Obj_Rel_Insert_Input = {
  data: EmploymentHireType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EmploymentHireType_On_Conflict>;
};

/** on_conflict condition type for table "EmploymentHireType" */
export type EmploymentHireType_On_Conflict = {
  constraint: EmploymentHireType_Constraint;
  update_columns?: Array<EmploymentHireType_Update_Column>;
  where?: InputMaybe<EmploymentHireType_Bool_Exp>;
};

/** Ordering options when selecting data from "EmploymentHireType". */
export type EmploymentHireType_Order_By = {
  key?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmploymentHireType */
export type EmploymentHireType_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "EmploymentHireType" */
export enum EmploymentHireType_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "EmploymentHireType" */
export type EmploymentHireType_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "EmploymentHireType" */
export type EmploymentHireType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmploymentHireType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmploymentHireType_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "EmploymentHireType" */
export enum EmploymentHireType_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

export type EmploymentHireType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmploymentHireType_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmploymentHireType_Bool_Exp;
};

/** Enum for employment types */
export type EmploymentTypes = {
  __typename?: 'EmploymentTypes';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "EmploymentTypes" */
export type EmploymentTypes_Aggregate = {
  __typename?: 'EmploymentTypes_aggregate';
  aggregate?: Maybe<EmploymentTypes_Aggregate_Fields>;
  nodes: Array<EmploymentTypes>;
};

/** aggregate fields of "EmploymentTypes" */
export type EmploymentTypes_Aggregate_Fields = {
  __typename?: 'EmploymentTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmploymentTypes_Max_Fields>;
  min?: Maybe<EmploymentTypes_Min_Fields>;
};


/** aggregate fields of "EmploymentTypes" */
export type EmploymentTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmploymentTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "EmploymentTypes". All fields are combined with a logical 'AND'. */
export type EmploymentTypes_Bool_Exp = {
  _and?: InputMaybe<Array<EmploymentTypes_Bool_Exp>>;
  _not?: InputMaybe<EmploymentTypes_Bool_Exp>;
  _or?: InputMaybe<Array<EmploymentTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EmploymentTypes" */
export enum EmploymentTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  EmploymentTypesPkey = 'EmploymentTypes_pkey'
}

export enum EmploymentTypes_Enum {
  /** hourly employees */
  Hourly = 'hourly',
  /** salaried employees */
  Salary = 'salary'
}

/** Boolean expression to compare columns of type "EmploymentTypes_enum". All fields are combined with logical 'AND'. */
export type EmploymentTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<EmploymentTypes_Enum>;
  _in?: InputMaybe<Array<EmploymentTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmploymentTypes_Enum>;
  _nin?: InputMaybe<Array<EmploymentTypes_Enum>>;
};

/** input type for inserting data into table "EmploymentTypes" */
export type EmploymentTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EmploymentTypes_Max_Fields = {
  __typename?: 'EmploymentTypes_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmploymentTypes_Min_Fields = {
  __typename?: 'EmploymentTypes_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EmploymentTypes" */
export type EmploymentTypes_Mutation_Response = {
  __typename?: 'EmploymentTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmploymentTypes>;
};

/** on_conflict condition type for table "EmploymentTypes" */
export type EmploymentTypes_On_Conflict = {
  constraint: EmploymentTypes_Constraint;
  update_columns?: Array<EmploymentTypes_Update_Column>;
  where?: InputMaybe<EmploymentTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "EmploymentTypes". */
export type EmploymentTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EmploymentTypes */
export type EmploymentTypes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "EmploymentTypes" */
export enum EmploymentTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "EmploymentTypes" */
export type EmploymentTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "EmploymentTypes" */
export type EmploymentTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmploymentTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmploymentTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "EmploymentTypes" */
export enum EmploymentTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type EmploymentTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmploymentTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmploymentTypes_Bool_Exp;
};

/** columns and relationships of "Employments" */
export type Employments = {
  __typename?: 'Employments';
  /** An object relationship */
  BoardSnippet?: Maybe<BoardSnippets>;
  /** An object relationship */
  EmploymentHireType?: Maybe<EmploymentHireType>;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Job: Jobs;
  /** An object relationship */
  Termination?: Maybe<Terminations>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deleted: Scalars['Boolean'];
  end_date?: Maybe<Scalars['timestamptz']>;
  end_reason?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  hire_type?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  start_date?: Maybe<Scalars['date']>;
  termination_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Employments" */
export type EmploymentsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Employments" */
export type Employments_Aggregate = {
  __typename?: 'Employments_aggregate';
  aggregate?: Maybe<Employments_Aggregate_Fields>;
  nodes: Array<Employments>;
};

export type Employments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Employments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Employments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Employments_Aggregate_Bool_Exp_Count>;
};

export type Employments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Employments_Select_Column_Employments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Employments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Employments_Select_Column_Employments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Employments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Employments" */
export type Employments_Aggregate_Fields = {
  __typename?: 'Employments_aggregate_fields';
  avg?: Maybe<Employments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employments_Max_Fields>;
  min?: Maybe<Employments_Min_Fields>;
  stddev?: Maybe<Employments_Stddev_Fields>;
  stddev_pop?: Maybe<Employments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employments_Stddev_Samp_Fields>;
  sum?: Maybe<Employments_Sum_Fields>;
  var_pop?: Maybe<Employments_Var_Pop_Fields>;
  var_samp?: Maybe<Employments_Var_Samp_Fields>;
  variance?: Maybe<Employments_Variance_Fields>;
};


/** aggregate fields of "Employments" */
export type Employments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Employments" */
export type Employments_Aggregate_Order_By = {
  avg?: InputMaybe<Employments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employments_Max_Order_By>;
  min?: InputMaybe<Employments_Min_Order_By>;
  stddev?: InputMaybe<Employments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employments_Sum_Order_By>;
  var_pop?: InputMaybe<Employments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employments_Var_Samp_Order_By>;
  variance?: InputMaybe<Employments_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Employments_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Employments" */
export type Employments_Arr_Rel_Insert_Input = {
  data: Array<Employments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employments_On_Conflict>;
};

/** aggregate avg on columns */
export type Employments_Avg_Fields = {
  __typename?: 'Employments_avg_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  termination_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Employments" */
export type Employments_Avg_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Employments". All fields are combined with a logical 'AND'. */
export type Employments_Bool_Exp = {
  BoardSnippet?: InputMaybe<BoardSnippets_Bool_Exp>;
  EmploymentHireType?: InputMaybe<EmploymentHireType_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  Termination?: InputMaybe<Terminations_Bool_Exp>;
  _and?: InputMaybe<Array<Employments_Bool_Exp>>;
  _not?: InputMaybe<Employments_Bool_Exp>;
  _or?: InputMaybe<Array<Employments_Bool_Exp>>;
  board_snippet_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_reason?: InputMaybe<String_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  hire_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  termination_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Employments" */
export enum Employments_Constraint {
  /** unique or primary key constraint on columns "etl_id_token" */
  EmploymentsEtlIdTokenKey = 'Employments_etl_id_token_key',
  /** unique or primary key constraint on columns "identity_id", "job_id" */
  EmploymentsJobIdIdentityIdKey = 'Employments_job_id_identity_id_key',
  /** unique or primary key constraint on columns "id" */
  EmploymentsPkey = 'Employments_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Employments_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Employments_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Employments_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Employments" */
export type Employments_Inc_Input = {
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  termination_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Employments" */
export type Employments_Insert_Input = {
  BoardSnippet?: InputMaybe<BoardSnippets_Obj_Rel_Insert_Input>;
  EmploymentHireType?: InputMaybe<EmploymentHireType_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  Termination?: InputMaybe<Terminations_Obj_Rel_Insert_Input>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  end_reason?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  hire_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  termination_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Employments_Max_Fields = {
  __typename?: 'Employments_max_fields';
  board_snippet_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  end_reason?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  hire_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  termination_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Employments" */
export type Employments_Max_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  end_reason?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  hire_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employments_Min_Fields = {
  __typename?: 'Employments_min_fields';
  board_snippet_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  end_reason?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  hire_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  termination_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Employments" */
export type Employments_Min_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  end_reason?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  hire_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Employments" */
export type Employments_Mutation_Response = {
  __typename?: 'Employments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employments>;
};

/** input type for inserting object relation for remote table "Employments" */
export type Employments_Obj_Rel_Insert_Input = {
  data: Employments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Employments_On_Conflict>;
};

/** on_conflict condition type for table "Employments" */
export type Employments_On_Conflict = {
  constraint: Employments_Constraint;
  update_columns?: Array<Employments_Update_Column>;
  where?: InputMaybe<Employments_Bool_Exp>;
};

/** Ordering options when selecting data from "Employments". */
export type Employments_Order_By = {
  BoardSnippet?: InputMaybe<BoardSnippets_Order_By>;
  EmploymentHireType?: InputMaybe<EmploymentHireType_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  Termination?: InputMaybe<Terminations_Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  end_reason?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  hire_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Employments */
export type Employments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Employments_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Employments" */
export enum Employments_Select_Column {
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndReason = 'end_reason',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  HireType = 'hire_type',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TerminationId = 'termination_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "Employments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Employments" */
export enum Employments_Select_Column_Employments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** select "Employments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Employments" */
export enum Employments_Select_Column_Employments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "Employments" */
export type Employments_Set_Input = {
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  end_reason?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  hire_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  termination_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Employments_Stddev_Fields = {
  __typename?: 'Employments_stddev_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  termination_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Employments" */
export type Employments_Stddev_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employments_Stddev_Pop_Fields = {
  __typename?: 'Employments_stddev_pop_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  termination_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Employments" */
export type Employments_Stddev_Pop_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employments_Stddev_Samp_Fields = {
  __typename?: 'Employments_stddev_samp_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  termination_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Employments" */
export type Employments_Stddev_Samp_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Employments" */
export type Employments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employments_Stream_Cursor_Value_Input = {
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  end_reason?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  hire_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  termination_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Employments_Sum_Fields = {
  __typename?: 'Employments_sum_fields';
  board_snippet_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  termination_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Employments" */
export type Employments_Sum_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Employments" */
export enum Employments_Update_Column {
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndReason = 'end_reason',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  HireType = 'hire_type',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TerminationId = 'termination_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Employments_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Employments_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Employments_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Employments_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Employments_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employments_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Employments_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employments_Var_Pop_Fields = {
  __typename?: 'Employments_var_pop_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  termination_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Employments" */
export type Employments_Var_Pop_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employments_Var_Samp_Fields = {
  __typename?: 'Employments_var_samp_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  termination_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Employments" */
export type Employments_Var_Samp_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employments_Variance_Fields = {
  __typename?: 'Employments_variance_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  termination_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Employments" */
export type Employments_Variance_Order_By = {
  board_snippet_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  termination_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

export type EnsureUserInput = {
  email: Scalars['String'];
  email_is_verified?: InputMaybe<Scalars['Boolean']>;
  name_first: Scalars['String'];
  name_last: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  route?: InputMaybe<Scalars['String']>;
  send_magic_link?: InputMaybe<Scalars['Boolean']>;
  suppress_pw_email?: InputMaybe<Scalars['Boolean']>;
};

export type EnsureUserResult = {
  __typename?: 'EnsureUserResult';
  email: Scalars['String'];
  identity_id: Scalars['Int'];
  roleIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** columns and relationships of "EntitlementCategories" */
export type EntitlementCategories = {
  __typename?: 'EntitlementCategories';
  category: Scalars['String'];
  description: Scalars['String'];
};

/** aggregated selection of "EntitlementCategories" */
export type EntitlementCategories_Aggregate = {
  __typename?: 'EntitlementCategories_aggregate';
  aggregate?: Maybe<EntitlementCategories_Aggregate_Fields>;
  nodes: Array<EntitlementCategories>;
};

/** aggregate fields of "EntitlementCategories" */
export type EntitlementCategories_Aggregate_Fields = {
  __typename?: 'EntitlementCategories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EntitlementCategories_Max_Fields>;
  min?: Maybe<EntitlementCategories_Min_Fields>;
};


/** aggregate fields of "EntitlementCategories" */
export type EntitlementCategories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EntitlementCategories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "EntitlementCategories". All fields are combined with a logical 'AND'. */
export type EntitlementCategories_Bool_Exp = {
  _and?: InputMaybe<Array<EntitlementCategories_Bool_Exp>>;
  _not?: InputMaybe<EntitlementCategories_Bool_Exp>;
  _or?: InputMaybe<Array<EntitlementCategories_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EntitlementCategories" */
export enum EntitlementCategories_Constraint {
  /** unique or primary key constraint on columns "category" */
  EntitlementCategoriesPkey = 'EntitlementCategories_pkey'
}

export enum EntitlementCategories_Enum {
  /** Displays add button which allows users to create different objects */
  ModuleAdd = 'MODULE_ADD',
  /** Displays the analytics activity report */
  ModuleAnalyticsActivity = 'MODULE_ANALYTICS_ACTIVITY',
  /** Displays the analytics stage report */
  ModuleAnalyticsStage = 'MODULE_ANALYTICS_STAGE',
  /** Displays the applicants page */
  ModuleApplicants = 'MODULE_APPLICANTS',
  /** Displays the board */
  ModuleBoard = 'MODULE_BOARD',
  /** Displays the calculations for a candidate */
  ModuleCalculator = 'MODULE_CALCULATOR',
  /** Displays the candidates table and details page */
  ModuleCandidates = 'MODULE_CANDIDATES',
  /** Displays the configuration page */
  ModuleConfiguration = 'MODULE_CONFIGURATION',
  /** Displays the contracts table and details page */
  ModuleContracts = 'MODULE_CONTRACTS',
  /** Displays the dashboard */
  ModuleDashboard = 'MODULE_DASHBOARD',
  /** Displays the employees table and details page */
  ModuleEmployees = 'MODULE_EMPLOYEES',
  /** Displays the jobs table and details page */
  ModuleJobs = 'MODULE_JOBS',
  /** Displays the tasks page */
  ModuleTasks = 'MODULE_TASKS',
  /** Team Review */
  TeamReview = 'TEAM_REVIEW',
  /** Displays all notes on candidates/employees and allows users to save new notes. */
  WidgetCandidateActivityFeed = 'WIDGET_CANDIDATE_ACTIVITY_FEED',
  /** Displays all applications on candidates/employees. */
  WidgetCandidateApplications = 'WIDGET_CANDIDATE_APPLICATIONS',
  /** Displays calculations saved for a particular candidate/employee. Allows users to create and save calculations. */
  WidgetCandidateCalculations = 'WIDGET_CANDIDATE_CALCULATIONS',
  /** EEO information on candidate profile and modals */
  WidgetCandidateEeoInfo = 'WIDGET_CANDIDATE_EEO_INFO',
  /** Displays a list jobs associated with an employee/candidate and allows users to add a new employment. */
  WidgetCandidateEmployments = 'WIDGET_CANDIDATE_EMPLOYMENTS',
  /** Displays all files associated to a particular candidate or employee */
  WidgetCandidateFiles = 'WIDGET_CANDIDATE_FILES',
  /** Profile info widget on candidate profiles and modals */
  WidgetCandidateProfileInfo = 'WIDGET_CANDIDATE_PROFILE_INFO',
  /** Displays the employee resumes and allows users to upload new resumes. */
  WidgetCandidateResumes = 'WIDGET_CANDIDATE_RESUMES',
  /** Displays detailed security clearance information including the SSN, DoDID, DISS Profile and Scattered Castles Profile. */
  WidgetCandidateSecurityClearanceInfo = 'WIDGET_CANDIDATE_SECURITY_CLEARANCE_INFO',
  /** Displays currently open tasks for candidates and employees */
  WidgetCandidateTaskInformation = 'WIDGET_CANDIDATE_TASK_INFORMATION',
  /** Displays the teams reviews for a candidate */
  WidgetCandidateTeamReview = 'WIDGET_CANDIDATE_TEAM_REVIEW',
  /** Displays current and historical workflows for candidates and employees. */
  WidgetCandidateWorkflowOverview = 'WIDGET_CANDIDATE_WORKFLOW_OVERVIEW',
  /** Displays candidates associated with a contract */
  WidgetContractsAssociatedCandidates = 'WIDGET_CONTRACTS_ASSOCIATED_CANDIDATES',
  /** Displays employees associated with a contract */
  WidgetContractsAssociatedEmployees = 'WIDGET_CONTRACTS_ASSOCIATED_EMPLOYEES',
  /** Displays jobs associated to a contract */
  WidgetContractsAssociatedJobs = 'WIDGET_CONTRACTS_ASSOCIATED_JOBS',
  /** Displays client point of contact information */
  WidgetContractsClientPoc = 'WIDGET_CONTRACTS_CLIENT_POC',
  /** Displays comments about a contract */
  WidgetContractsComments = 'WIDGET_CONTRACTS_COMMENTS',
  /** Displays general information about the contract */
  WidgetContractsGeneralInfo = 'WIDGET_CONTRACTS_GENERAL_INFO',
  /** Displays all notes on candidates/employees and allows users to save new notes. */
  WidgetEmployeeActivityFeed = 'WIDGET_EMPLOYEE_ACTIVITY_FEED',
  /** Displays all applications on candidates/employees. */
  WidgetEmployeeApplications = 'WIDGET_EMPLOYEE_APPLICATIONS',
  /** Displays the employees' benefits and benefit usage and allows users to insert new benefits on behalf of the employee. */
  WidgetEmployeeBenefitsTracker = 'WIDGET_EMPLOYEE_BENEFITS_TRACKER',
  /** Displays calculations saved for a particular candidate/employee. Allows users to create and save calculations. */
  WidgetEmployeeCalculations = 'WIDGET_EMPLOYEE_CALCULATIONS',
  /** Displays the employees' compensation and allows users to update their compensation. */
  WidgetEmployeeCompensationInformation = 'WIDGET_EMPLOYEE_COMPENSATION_INFORMATION',
  /** EEO information on employee profile and modals */
  WidgetEmployeeEeoInfo = 'WIDGET_EMPLOYEE_EEO_INFO',
  /** Displays a list jobs associated with an employee/candidate and allows users to add a new employment. */
  WidgetEmployeeEmployments = 'WIDGET_EMPLOYEE_EMPLOYMENTS',
  /** Displays all files associated to a particular candidate or employee */
  WidgetEmployeeFiles = 'WIDGET_EMPLOYEE_FILES',
  /** Displays the employees' agile account status and in app roles. */
  WidgetEmployeeManagementInformation = 'WIDGET_EMPLOYEE_MANAGEMENT_INFORMATION',
  /** Profile info widget on EMPLOYEE profiles and modals */
  WidgetEmployeeProfileInfo = 'WIDGET_EMPLOYEE_PROFILE_INFO',
  /** Displays the employee resumes and allows users to upload new resumes. */
  WidgetEmployeeResumes = 'WIDGET_EMPLOYEE_RESUMES',
  /** Displays detailed security clearance information including the SSN, DoDID, DISS Profile and Scattered Castles Profile. */
  WidgetEmployeeSecurityClearanceInfo = 'WIDGET_EMPLOYEE_SECURITY_CLEARANCE_INFO',
  /** Displays currently open tasks for candidates and employees */
  WidgetEmployeeTaskInformation = 'WIDGET_EMPLOYEE_TASK_INFORMATION',
  /** Displays the teams reviews for an employee */
  WidgetEmployeeTeamReview = 'WIDGET_EMPLOYEE_TEAM_REVIEW',
  /** Displays current and historical workflows for candidates and employees. */
  WidgetEmployeeWorkflowOverview = 'WIDGET_EMPLOYEE_WORKFLOW_OVERVIEW',
  /** Displays details about a job like description and required skills */
  WidgetJobsDetails = 'WIDGET_JOBS_DETAILS',
  /** Displays the active employments for a job */
  WidgetJobsEmployments = 'WIDGET_JOBS_EMPLOYMENTS',
  /** Displays details about a jobs funding status and pay rate. */
  WidgetJobsFunding = 'WIDGET_JOBS_FUNDING',
  /** Displays general information about the job */
  WidgetJobsGeneralInfo = 'WIDGET_JOBS_GENERAL_INFO',
  /** Displays open workflows on the board for a job */
  WidgetJobsWorkflowOverview = 'WIDGET_JOBS_WORKFLOW_OVERVIEW'
}

/** Boolean expression to compare columns of type "EntitlementCategories_enum". All fields are combined with logical 'AND'. */
export type EntitlementCategories_Enum_Comparison_Exp = {
  _eq?: InputMaybe<EntitlementCategories_Enum>;
  _in?: InputMaybe<Array<EntitlementCategories_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EntitlementCategories_Enum>;
  _nin?: InputMaybe<Array<EntitlementCategories_Enum>>;
};

/** input type for inserting data into table "EntitlementCategories" */
export type EntitlementCategories_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EntitlementCategories_Max_Fields = {
  __typename?: 'EntitlementCategories_max_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EntitlementCategories_Min_Fields = {
  __typename?: 'EntitlementCategories_min_fields';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EntitlementCategories" */
export type EntitlementCategories_Mutation_Response = {
  __typename?: 'EntitlementCategories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EntitlementCategories>;
};

/** input type for inserting object relation for remote table "EntitlementCategories" */
export type EntitlementCategories_Obj_Rel_Insert_Input = {
  data: EntitlementCategories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EntitlementCategories_On_Conflict>;
};

/** on_conflict condition type for table "EntitlementCategories" */
export type EntitlementCategories_On_Conflict = {
  constraint: EntitlementCategories_Constraint;
  update_columns?: Array<EntitlementCategories_Update_Column>;
  where?: InputMaybe<EntitlementCategories_Bool_Exp>;
};

/** Ordering options when selecting data from "EntitlementCategories". */
export type EntitlementCategories_Order_By = {
  category?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EntitlementCategories */
export type EntitlementCategories_Pk_Columns_Input = {
  category: Scalars['String'];
};

/** select columns of table "EntitlementCategories" */
export enum EntitlementCategories_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description'
}

/** input type for updating data in table "EntitlementCategories" */
export type EntitlementCategories_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "EntitlementCategories" */
export type EntitlementCategories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EntitlementCategories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EntitlementCategories_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** update columns of table "EntitlementCategories" */
export enum EntitlementCategories_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description'
}

export type EntitlementCategories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EntitlementCategories_Set_Input>;
  /** filter the rows which have to be updated */
  where: EntitlementCategories_Bool_Exp;
};

export type EntitlementGrantInput = {
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description: Scalars['String'];
  ends_on?: InputMaybe<Scalars['String']>;
  identity_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  intent?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  role_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  role_names?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  route?: InputMaybe<Scalars['String']>;
  row_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  send_magic_link?: InputMaybe<Scalars['Boolean']>;
  starts_on?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type EntitlementGrantOutput = {
  __typename?: 'EntitlementGrantOutput';
  entitlement_ids: Array<Maybe<Scalars['String']>>;
  magic_links: Array<Maybe<Scalars['String']>>;
};

/** columns and relationships of "Entitlements" */
export type Entitlements = {
  __typename?: 'Entitlements';
  /** An object relationship */
  AccessIntent: AccessIntents;
  /** An object relationship */
  EntitlementCategory: EntitlementCategories;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Role?: Maybe<Roles>;
  attribute?: Maybe<Scalars['String']>;
  category: EntitlementCategories_Enum;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  description: Scalars['String'];
  ends_on?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  /** An object relationship */
  identityByCreatedBy: Identities;
  identity_id?: Maybe<Scalars['Int']>;
  intent: AccessIntents_Enum;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['timestamptz']>;
  state: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Entitlements" */
export type Entitlements_Aggregate = {
  __typename?: 'Entitlements_aggregate';
  aggregate?: Maybe<Entitlements_Aggregate_Fields>;
  nodes: Array<Entitlements>;
};

export type Entitlements_Aggregate_Bool_Exp = {
  count?: InputMaybe<Entitlements_Aggregate_Bool_Exp_Count>;
};

export type Entitlements_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Entitlements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Entitlements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Entitlements" */
export type Entitlements_Aggregate_Fields = {
  __typename?: 'Entitlements_aggregate_fields';
  avg?: Maybe<Entitlements_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Entitlements_Max_Fields>;
  min?: Maybe<Entitlements_Min_Fields>;
  stddev?: Maybe<Entitlements_Stddev_Fields>;
  stddev_pop?: Maybe<Entitlements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Entitlements_Stddev_Samp_Fields>;
  sum?: Maybe<Entitlements_Sum_Fields>;
  var_pop?: Maybe<Entitlements_Var_Pop_Fields>;
  var_samp?: Maybe<Entitlements_Var_Samp_Fields>;
  variance?: Maybe<Entitlements_Variance_Fields>;
};


/** aggregate fields of "Entitlements" */
export type Entitlements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entitlements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Entitlements" */
export type Entitlements_Aggregate_Order_By = {
  avg?: InputMaybe<Entitlements_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Entitlements_Max_Order_By>;
  min?: InputMaybe<Entitlements_Min_Order_By>;
  stddev?: InputMaybe<Entitlements_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Entitlements_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Entitlements_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Entitlements_Sum_Order_By>;
  var_pop?: InputMaybe<Entitlements_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Entitlements_Var_Samp_Order_By>;
  variance?: InputMaybe<Entitlements_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Entitlements" */
export type Entitlements_Arr_Rel_Insert_Input = {
  data: Array<Entitlements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Entitlements_On_Conflict>;
};

/** aggregate avg on columns */
export type Entitlements_Avg_Fields = {
  __typename?: 'Entitlements_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Entitlements" */
export type Entitlements_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Entitlements". All fields are combined with a logical 'AND'. */
export type Entitlements_Bool_Exp = {
  AccessIntent?: InputMaybe<AccessIntents_Bool_Exp>;
  EntitlementCategory?: InputMaybe<EntitlementCategories_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Role?: InputMaybe<Roles_Bool_Exp>;
  _and?: InputMaybe<Array<Entitlements_Bool_Exp>>;
  _not?: InputMaybe<Entitlements_Bool_Exp>;
  _or?: InputMaybe<Array<Entitlements_Bool_Exp>>;
  attribute?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<EntitlementCategories_Enum_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ends_on?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identityByCreatedBy?: InputMaybe<Identities_Bool_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  intent?: InputMaybe<AccessIntents_Enum_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  row_id?: InputMaybe<Int_Comparison_Exp>;
  starts_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Entitlements" */
export enum Entitlements_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntitlementsPkey = 'Entitlements_pkey'
}

/** input type for incrementing numeric columns in table "Entitlements" */
export type Entitlements_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Entitlements" */
export type Entitlements_Insert_Input = {
  AccessIntent?: InputMaybe<AccessIntents_Obj_Rel_Insert_Input>;
  EntitlementCategory?: InputMaybe<EntitlementCategories_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  attribute?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<EntitlementCategories_Enum>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  ends_on?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  identityByCreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identity_id?: InputMaybe<Scalars['Int']>;
  intent?: InputMaybe<AccessIntents_Enum>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Entitlements_Max_Fields = {
  __typename?: 'Entitlements_max_fields';
  attribute?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  ends_on?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Entitlements" */
export type Entitlements_Max_Order_By = {
  attribute?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ends_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  starts_on?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Entitlements_Min_Fields = {
  __typename?: 'Entitlements_min_fields';
  attribute?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  ends_on?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Entitlements" */
export type Entitlements_Min_Order_By = {
  attribute?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ends_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  starts_on?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Entitlements" */
export type Entitlements_Mutation_Response = {
  __typename?: 'Entitlements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Entitlements>;
};

/** on_conflict condition type for table "Entitlements" */
export type Entitlements_On_Conflict = {
  constraint: Entitlements_Constraint;
  update_columns?: Array<Entitlements_Update_Column>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};

/** Ordering options when selecting data from "Entitlements". */
export type Entitlements_Order_By = {
  AccessIntent?: InputMaybe<AccessIntents_Order_By>;
  EntitlementCategory?: InputMaybe<EntitlementCategories_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Role?: InputMaybe<Roles_Order_By>;
  attribute?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ends_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityByCreatedBy?: InputMaybe<Identities_Order_By>;
  identity_id?: InputMaybe<Order_By>;
  intent?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  starts_on?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Entitlements */
export type Entitlements_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Entitlements" */
export enum Entitlements_Select_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  EndsOn = 'ends_on',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Intent = 'intent',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  RowId = 'row_id',
  /** column name */
  StartsOn = 'starts_on',
  /** column name */
  State = 'state',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "Entitlements" */
export type Entitlements_Set_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<EntitlementCategories_Enum>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  ends_on?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  intent?: InputMaybe<AccessIntents_Enum>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Entitlements_Stddev_Fields = {
  __typename?: 'Entitlements_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Entitlements" */
export type Entitlements_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Entitlements_Stddev_Pop_Fields = {
  __typename?: 'Entitlements_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Entitlements" */
export type Entitlements_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Entitlements_Stddev_Samp_Fields = {
  __typename?: 'Entitlements_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Entitlements" */
export type Entitlements_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Entitlements" */
export type Entitlements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entitlements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entitlements_Stream_Cursor_Value_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<EntitlementCategories_Enum>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  ends_on?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  intent?: InputMaybe<AccessIntents_Enum>;
  role_id?: InputMaybe<Scalars['Int']>;
  row_id?: InputMaybe<Scalars['Int']>;
  starts_on?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Entitlements_Sum_Fields = {
  __typename?: 'Entitlements_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Entitlements" */
export type Entitlements_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Entitlements" */
export enum Entitlements_Update_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  EndsOn = 'ends_on',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Intent = 'intent',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  RowId = 'row_id',
  /** column name */
  StartsOn = 'starts_on',
  /** column name */
  State = 'state',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Entitlements_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Entitlements_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entitlements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entitlements_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Entitlements_Var_Pop_Fields = {
  __typename?: 'Entitlements_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Entitlements" */
export type Entitlements_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Entitlements_Var_Samp_Fields = {
  __typename?: 'Entitlements_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Entitlements" */
export type Entitlements_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Entitlements_Variance_Fields = {
  __typename?: 'Entitlements_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  row_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Entitlements" */
export type Entitlements_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  row_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "EquationFormulaOption" */
export type EquationFormulaOption = {
  __typename?: 'EquationFormulaOption';
  /** An object relationship */
  Equation: Equations;
  /** An object relationship */
  FormulaOption: FormulaOptions;
  equation_id: Scalars['Int'];
  formula_option_id: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  range_variable?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "EquationFormulaOption" */
export type EquationFormulaOption_Aggregate = {
  __typename?: 'EquationFormulaOption_aggregate';
  aggregate?: Maybe<EquationFormulaOption_Aggregate_Fields>;
  nodes: Array<EquationFormulaOption>;
};

export type EquationFormulaOption_Aggregate_Bool_Exp = {
  count?: InputMaybe<EquationFormulaOption_Aggregate_Bool_Exp_Count>;
};

export type EquationFormulaOption_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EquationFormulaOption_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "EquationFormulaOption" */
export type EquationFormulaOption_Aggregate_Fields = {
  __typename?: 'EquationFormulaOption_aggregate_fields';
  avg?: Maybe<EquationFormulaOption_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EquationFormulaOption_Max_Fields>;
  min?: Maybe<EquationFormulaOption_Min_Fields>;
  stddev?: Maybe<EquationFormulaOption_Stddev_Fields>;
  stddev_pop?: Maybe<EquationFormulaOption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EquationFormulaOption_Stddev_Samp_Fields>;
  sum?: Maybe<EquationFormulaOption_Sum_Fields>;
  var_pop?: Maybe<EquationFormulaOption_Var_Pop_Fields>;
  var_samp?: Maybe<EquationFormulaOption_Var_Samp_Fields>;
  variance?: Maybe<EquationFormulaOption_Variance_Fields>;
};


/** aggregate fields of "EquationFormulaOption" */
export type EquationFormulaOption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "EquationFormulaOption" */
export type EquationFormulaOption_Aggregate_Order_By = {
  avg?: InputMaybe<EquationFormulaOption_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EquationFormulaOption_Max_Order_By>;
  min?: InputMaybe<EquationFormulaOption_Min_Order_By>;
  stddev?: InputMaybe<EquationFormulaOption_Stddev_Order_By>;
  stddev_pop?: InputMaybe<EquationFormulaOption_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<EquationFormulaOption_Stddev_Samp_Order_By>;
  sum?: InputMaybe<EquationFormulaOption_Sum_Order_By>;
  var_pop?: InputMaybe<EquationFormulaOption_Var_Pop_Order_By>;
  var_samp?: InputMaybe<EquationFormulaOption_Var_Samp_Order_By>;
  variance?: InputMaybe<EquationFormulaOption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "EquationFormulaOption" */
export type EquationFormulaOption_Arr_Rel_Insert_Input = {
  data: Array<EquationFormulaOption_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EquationFormulaOption_On_Conflict>;
};

/** aggregate avg on columns */
export type EquationFormulaOption_Avg_Fields = {
  __typename?: 'EquationFormulaOption_avg_fields';
  equation_id?: Maybe<Scalars['Float']>;
  formula_option_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Avg_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "EquationFormulaOption". All fields are combined with a logical 'AND'. */
export type EquationFormulaOption_Bool_Exp = {
  Equation?: InputMaybe<Equations_Bool_Exp>;
  FormulaOption?: InputMaybe<FormulaOptions_Bool_Exp>;
  _and?: InputMaybe<Array<EquationFormulaOption_Bool_Exp>>;
  _not?: InputMaybe<EquationFormulaOption_Bool_Exp>;
  _or?: InputMaybe<Array<EquationFormulaOption_Bool_Exp>>;
  equation_id?: InputMaybe<Int_Comparison_Exp>;
  formula_option_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  range_variable?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EquationFormulaOption" */
export enum EquationFormulaOption_Constraint {
  /** unique or primary key constraint on columns "id" */
  EquationFormulaOptionPkey = 'EquationFormulaOption_pkey'
}

/** input type for incrementing numeric columns in table "EquationFormulaOption" */
export type EquationFormulaOption_Inc_Input = {
  equation_id?: InputMaybe<Scalars['Int']>;
  formula_option_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "EquationFormulaOption" */
export type EquationFormulaOption_Insert_Input = {
  Equation?: InputMaybe<Equations_Obj_Rel_Insert_Input>;
  FormulaOption?: InputMaybe<FormulaOptions_Obj_Rel_Insert_Input>;
  equation_id?: InputMaybe<Scalars['Int']>;
  formula_option_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  range_variable?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EquationFormulaOption_Max_Fields = {
  __typename?: 'EquationFormulaOption_max_fields';
  equation_id?: Maybe<Scalars['Int']>;
  formula_option_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  range_variable?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Max_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  range_variable?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EquationFormulaOption_Min_Fields = {
  __typename?: 'EquationFormulaOption_min_fields';
  equation_id?: Maybe<Scalars['Int']>;
  formula_option_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  range_variable?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Min_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  range_variable?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "EquationFormulaOption" */
export type EquationFormulaOption_Mutation_Response = {
  __typename?: 'EquationFormulaOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EquationFormulaOption>;
};

/** on_conflict condition type for table "EquationFormulaOption" */
export type EquationFormulaOption_On_Conflict = {
  constraint: EquationFormulaOption_Constraint;
  update_columns?: Array<EquationFormulaOption_Update_Column>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};

/** Ordering options when selecting data from "EquationFormulaOption". */
export type EquationFormulaOption_Order_By = {
  Equation?: InputMaybe<Equations_Order_By>;
  FormulaOption?: InputMaybe<FormulaOptions_Order_By>;
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  range_variable?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EquationFormulaOption */
export type EquationFormulaOption_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "EquationFormulaOption" */
export enum EquationFormulaOption_Select_Column {
  /** column name */
  EquationId = 'equation_id',
  /** column name */
  FormulaOptionId = 'formula_option_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RangeVariable = 'range_variable',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "EquationFormulaOption" */
export type EquationFormulaOption_Set_Input = {
  equation_id?: InputMaybe<Scalars['Int']>;
  formula_option_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  range_variable?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type EquationFormulaOption_Stddev_Fields = {
  __typename?: 'EquationFormulaOption_stddev_fields';
  equation_id?: Maybe<Scalars['Float']>;
  formula_option_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Stddev_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type EquationFormulaOption_Stddev_Pop_Fields = {
  __typename?: 'EquationFormulaOption_stddev_pop_fields';
  equation_id?: Maybe<Scalars['Float']>;
  formula_option_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Stddev_Pop_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type EquationFormulaOption_Stddev_Samp_Fields = {
  __typename?: 'EquationFormulaOption_stddev_samp_fields';
  equation_id?: Maybe<Scalars['Float']>;
  formula_option_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Stddev_Samp_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "EquationFormulaOption" */
export type EquationFormulaOption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EquationFormulaOption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EquationFormulaOption_Stream_Cursor_Value_Input = {
  equation_id?: InputMaybe<Scalars['Int']>;
  formula_option_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  range_variable?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type EquationFormulaOption_Sum_Fields = {
  __typename?: 'EquationFormulaOption_sum_fields';
  equation_id?: Maybe<Scalars['Int']>;
  formula_option_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Sum_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** update columns of table "EquationFormulaOption" */
export enum EquationFormulaOption_Update_Column {
  /** column name */
  EquationId = 'equation_id',
  /** column name */
  FormulaOptionId = 'formula_option_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RangeVariable = 'range_variable',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type EquationFormulaOption_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EquationFormulaOption_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EquationFormulaOption_Set_Input>;
  /** filter the rows which have to be updated */
  where: EquationFormulaOption_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EquationFormulaOption_Var_Pop_Fields = {
  __typename?: 'EquationFormulaOption_var_pop_fields';
  equation_id?: Maybe<Scalars['Float']>;
  formula_option_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Var_Pop_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type EquationFormulaOption_Var_Samp_Fields = {
  __typename?: 'EquationFormulaOption_var_samp_fields';
  equation_id?: Maybe<Scalars['Float']>;
  formula_option_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Var_Samp_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type EquationFormulaOption_Variance_Fields = {
  __typename?: 'EquationFormulaOption_variance_fields';
  equation_id?: Maybe<Scalars['Float']>;
  formula_option_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "EquationFormulaOption" */
export type EquationFormulaOption_Variance_Order_By = {
  equation_id?: InputMaybe<Order_By>;
  formula_option_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "Equations" */
export type Equations = {
  __typename?: 'Equations';
  /** An array relationship */
  CalcEquationResults: Array<CalcEquationResults>;
  /** An aggregate relationship */
  CalcEquationResults_aggregate: CalcEquationResults_Aggregate;
  /** An object relationship */
  CalculationTemplateVersion: CalculationTemplateVersions;
  /** An array relationship */
  EquationFormulaOptions: Array<EquationFormulaOption>;
  /** An aggregate relationship */
  EquationFormulaOptions_aggregate: EquationFormulaOption_Aggregate;
  /** An object relationship */
  ValueType: ValueTypes;
  analytics?: Maybe<Scalars['jsonb']>;
  calc_template_version_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  featured: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  value_type: ValueTypes_Enum;
};


/** columns and relationships of "Equations" */
export type EquationsCalcEquationResultsArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


/** columns and relationships of "Equations" */
export type EquationsCalcEquationResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


/** columns and relationships of "Equations" */
export type EquationsEquationFormulaOptionsArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


/** columns and relationships of "Equations" */
export type EquationsEquationFormulaOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


/** columns and relationships of "Equations" */
export type EquationsAnalyticsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Equations" */
export type Equations_Aggregate = {
  __typename?: 'Equations_aggregate';
  aggregate?: Maybe<Equations_Aggregate_Fields>;
  nodes: Array<Equations>;
};

export type Equations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Equations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Equations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Equations_Aggregate_Bool_Exp_Count>;
};

export type Equations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Equations_Select_Column_Equations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Equations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Equations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Equations_Select_Column_Equations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Equations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Equations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Equations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Equations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Equations" */
export type Equations_Aggregate_Fields = {
  __typename?: 'Equations_aggregate_fields';
  avg?: Maybe<Equations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Equations_Max_Fields>;
  min?: Maybe<Equations_Min_Fields>;
  stddev?: Maybe<Equations_Stddev_Fields>;
  stddev_pop?: Maybe<Equations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Equations_Stddev_Samp_Fields>;
  sum?: Maybe<Equations_Sum_Fields>;
  var_pop?: Maybe<Equations_Var_Pop_Fields>;
  var_samp?: Maybe<Equations_Var_Samp_Fields>;
  variance?: Maybe<Equations_Variance_Fields>;
};


/** aggregate fields of "Equations" */
export type Equations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Equations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Equations" */
export type Equations_Aggregate_Order_By = {
  avg?: InputMaybe<Equations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Equations_Max_Order_By>;
  min?: InputMaybe<Equations_Min_Order_By>;
  stddev?: InputMaybe<Equations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Equations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Equations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Equations_Sum_Order_By>;
  var_pop?: InputMaybe<Equations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Equations_Var_Samp_Order_By>;
  variance?: InputMaybe<Equations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Equations_Append_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Equations" */
export type Equations_Arr_Rel_Insert_Input = {
  data: Array<Equations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Equations_On_Conflict>;
};

/** aggregate avg on columns */
export type Equations_Avg_Fields = {
  __typename?: 'Equations_avg_fields';
  calc_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Equations" */
export type Equations_Avg_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Equations". All fields are combined with a logical 'AND'. */
export type Equations_Bool_Exp = {
  CalcEquationResults?: InputMaybe<CalcEquationResults_Bool_Exp>;
  CalcEquationResults_aggregate?: InputMaybe<CalcEquationResults_Aggregate_Bool_Exp>;
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
  EquationFormulaOptions?: InputMaybe<EquationFormulaOption_Bool_Exp>;
  EquationFormulaOptions_aggregate?: InputMaybe<EquationFormulaOption_Aggregate_Bool_Exp>;
  ValueType?: InputMaybe<ValueTypes_Bool_Exp>;
  _and?: InputMaybe<Array<Equations_Bool_Exp>>;
  _not?: InputMaybe<Equations_Bool_Exp>;
  _or?: InputMaybe<Array<Equations_Bool_Exp>>;
  analytics?: InputMaybe<Jsonb_Comparison_Exp>;
  calc_template_version_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  featured?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value_type?: InputMaybe<ValueTypes_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Equations" */
export enum Equations_Constraint {
  /** unique or primary key constraint on columns "id" */
  EquationsPkey = 'equations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Equations_Delete_At_Path_Input = {
  analytics?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Equations_Delete_Elem_Input = {
  analytics?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Equations_Delete_Key_Input = {
  analytics?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Equations" */
export type Equations_Inc_Input = {
  calc_template_version_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Equations" */
export type Equations_Insert_Input = {
  CalcEquationResults?: InputMaybe<CalcEquationResults_Arr_Rel_Insert_Input>;
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Obj_Rel_Insert_Input>;
  EquationFormulaOptions?: InputMaybe<EquationFormulaOption_Arr_Rel_Insert_Input>;
  ValueType?: InputMaybe<ValueTypes_Obj_Rel_Insert_Input>;
  analytics?: InputMaybe<Scalars['jsonb']>;
  calc_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value_type?: InputMaybe<ValueTypes_Enum>;
};

/** aggregate max on columns */
export type Equations_Max_Fields = {
  __typename?: 'Equations_max_fields';
  calc_template_version_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Equations" */
export type Equations_Max_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Equations_Min_Fields = {
  __typename?: 'Equations_min_fields';
  calc_template_version_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Equations" */
export type Equations_Min_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Equations" */
export type Equations_Mutation_Response = {
  __typename?: 'Equations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Equations>;
};

/** input type for inserting object relation for remote table "Equations" */
export type Equations_Obj_Rel_Insert_Input = {
  data: Equations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Equations_On_Conflict>;
};

/** on_conflict condition type for table "Equations" */
export type Equations_On_Conflict = {
  constraint: Equations_Constraint;
  update_columns?: Array<Equations_Update_Column>;
  where?: InputMaybe<Equations_Bool_Exp>;
};

/** Ordering options when selecting data from "Equations". */
export type Equations_Order_By = {
  CalcEquationResults_aggregate?: InputMaybe<CalcEquationResults_Aggregate_Order_By>;
  CalculationTemplateVersion?: InputMaybe<CalculationTemplateVersions_Order_By>;
  EquationFormulaOptions_aggregate?: InputMaybe<EquationFormulaOption_Aggregate_Order_By>;
  ValueType?: InputMaybe<ValueTypes_Order_By>;
  analytics?: InputMaybe<Order_By>;
  calc_template_version_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  featured?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Equations */
export type Equations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Equations_Prepend_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Equations" */
export enum Equations_Select_Column {
  /** column name */
  Analytics = 'analytics',
  /** column name */
  CalcTemplateVersionId = 'calc_template_version_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Featured = 'featured',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValueType = 'value_type'
}

/** select "Equations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Equations" */
export enum Equations_Select_Column_Equations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Featured = 'featured'
}

/** select "Equations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Equations" */
export enum Equations_Select_Column_Equations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Featured = 'featured'
}

/** input type for updating data in table "Equations" */
export type Equations_Set_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
  calc_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value_type?: InputMaybe<ValueTypes_Enum>;
};

/** aggregate stddev on columns */
export type Equations_Stddev_Fields = {
  __typename?: 'Equations_stddev_fields';
  calc_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Equations" */
export type Equations_Stddev_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Equations_Stddev_Pop_Fields = {
  __typename?: 'Equations_stddev_pop_fields';
  calc_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Equations" */
export type Equations_Stddev_Pop_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Equations_Stddev_Samp_Fields = {
  __typename?: 'Equations_stddev_samp_fields';
  calc_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Equations" */
export type Equations_Stddev_Samp_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Equations" */
export type Equations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Equations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Equations_Stream_Cursor_Value_Input = {
  analytics?: InputMaybe<Scalars['jsonb']>;
  calc_template_version_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value_type?: InputMaybe<ValueTypes_Enum>;
};

/** aggregate sum on columns */
export type Equations_Sum_Fields = {
  __typename?: 'Equations_sum_fields';
  calc_template_version_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Equations" */
export type Equations_Sum_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** update columns of table "Equations" */
export enum Equations_Update_Column {
  /** column name */
  Analytics = 'analytics',
  /** column name */
  CalcTemplateVersionId = 'calc_template_version_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Featured = 'featured',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValueType = 'value_type'
}

export type Equations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Equations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Equations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Equations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Equations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Equations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Equations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Equations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Equations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Equations_Var_Pop_Fields = {
  __typename?: 'Equations_var_pop_fields';
  calc_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Equations" */
export type Equations_Var_Pop_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Equations_Var_Samp_Fields = {
  __typename?: 'Equations_var_samp_fields';
  calc_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Equations" */
export type Equations_Var_Samp_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Equations_Variance_Fields = {
  __typename?: 'Equations_variance_fields';
  calc_template_version_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Equations" */
export type Equations_Variance_Order_By = {
  calc_template_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "Events" */
export type Events = {
  __typename?: 'Events';
  /** An object relationship */
  Company?: Maybe<Companies>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  manager?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Events" */
export type Events_Aggregate = {
  __typename?: 'Events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

export type Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Events_Aggregate_Bool_Exp_Count>;
};

export type Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Events" */
export type Events_Aggregate_Fields = {
  __typename?: 'Events_aggregate_fields';
  avg?: Maybe<Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
  stddev?: Maybe<Events_Stddev_Fields>;
  stddev_pop?: Maybe<Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Events_Stddev_Samp_Fields>;
  sum?: Maybe<Events_Sum_Fields>;
  var_pop?: Maybe<Events_Var_Pop_Fields>;
  var_samp?: Maybe<Events_Var_Samp_Fields>;
  variance?: Maybe<Events_Variance_Fields>;
};


/** aggregate fields of "Events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Events" */
export type Events_Aggregate_Order_By = {
  avg?: InputMaybe<Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Events_Max_Order_By>;
  min?: InputMaybe<Events_Min_Order_By>;
  stddev?: InputMaybe<Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Events_Sum_Order_By>;
  var_pop?: InputMaybe<Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Events" */
export type Events_Arr_Rel_Insert_Input = {
  data: Array<Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Events_Avg_Fields = {
  __typename?: 'Events_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Events" */
export type Events_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  _and?: InputMaybe<Array<Events_Bool_Exp>>;
  _not?: InputMaybe<Events_Bool_Exp>;
  _or?: InputMaybe<Array<Events_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manager?: InputMaybe<String_Comparison_Exp>;
  mobile_phone?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  resume?: InputMaybe<String_Comparison_Exp>;
  salary?: InputMaybe<String_Comparison_Exp>;
  time?: InputMaybe<Timestamptz_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Events" */
export enum Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'Events_pkey'
}

/** input type for incrementing numeric columns in table "Events" */
export type Events_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Events" */
export type Events_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  manager?: InputMaybe<Scalars['String']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'Events_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  manager?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Events" */
export type Events_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'Events_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  manager?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Events" */
export type Events_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Events" */
export type Events_Mutation_Response = {
  __typename?: 'Events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** on_conflict condition type for table "Events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** Ordering options when selecting data from "Events". */
export type Events_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Events */
export type Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Events" */
export enum Events_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Manager = 'manager',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Note = 'note',
  /** column name */
  Resume = 'resume',
  /** column name */
  Salary = 'salary',
  /** column name */
  Time = 'time',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Events" */
export type Events_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  manager?: InputMaybe<Scalars['String']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Events_Stddev_Fields = {
  __typename?: 'Events_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Events" */
export type Events_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Events_Stddev_Pop_Fields = {
  __typename?: 'Events_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Events" */
export type Events_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Events_Stddev_Samp_Fields = {
  __typename?: 'Events_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Events" */
export type Events_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Events" */
export type Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Events_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  manager?: InputMaybe<Scalars['String']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Events_Sum_Fields = {
  __typename?: 'Events_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Events" */
export type Events_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Events" */
export enum Events_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Manager = 'manager',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Note = 'note',
  /** column name */
  Resume = 'resume',
  /** column name */
  Salary = 'salary',
  /** column name */
  Time = 'time',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Events_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Events_Var_Pop_Fields = {
  __typename?: 'Events_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Events" */
export type Events_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Events_Var_Samp_Fields = {
  __typename?: 'Events_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Events" */
export type Events_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Events_Variance_Fields = {
  __typename?: 'Events_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Events" */
export type Events_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type ExchangePublicTokenInput = {
  publicToken: Scalars['String'];
};

export type ExchangePublicTokenOutput = {
  __typename?: 'ExchangePublicTokenOutput';
  statusCode: Scalars['Int'];
};

/** columns and relationships of "Expense" */
export type Expense = {
  __typename?: 'Expense';
  /** An object relationship */
  ApprovedBy?: Maybe<Identities>;
  /** An object relationship */
  File?: Maybe<Files>;
  approvedBy?: Maybe<Scalars['Int']>;
  approvedOn?: Maybe<Scalars['date']>;
  benefitId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['money']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted: Scalars['Boolean'];
  fileId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  identityId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Expense" */
export type Expense_Aggregate = {
  __typename?: 'Expense_aggregate';
  aggregate?: Maybe<Expense_Aggregate_Fields>;
  nodes: Array<Expense>;
};

export type Expense_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Expense_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Expense_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Expense_Aggregate_Bool_Exp_Count>;
};

export type Expense_Aggregate_Bool_Exp_Bool_And = {
  arguments: Expense_Select_Column_Expense_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Expense_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Expense_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Expense_Select_Column_Expense_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Expense_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Expense_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Expense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Expense_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Expense" */
export type Expense_Aggregate_Fields = {
  __typename?: 'Expense_aggregate_fields';
  avg?: Maybe<Expense_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Expense_Max_Fields>;
  min?: Maybe<Expense_Min_Fields>;
  stddev?: Maybe<Expense_Stddev_Fields>;
  stddev_pop?: Maybe<Expense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Expense_Stddev_Samp_Fields>;
  sum?: Maybe<Expense_Sum_Fields>;
  var_pop?: Maybe<Expense_Var_Pop_Fields>;
  var_samp?: Maybe<Expense_Var_Samp_Fields>;
  variance?: Maybe<Expense_Variance_Fields>;
};


/** aggregate fields of "Expense" */
export type Expense_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Expense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Expense" */
export type Expense_Aggregate_Order_By = {
  avg?: InputMaybe<Expense_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Expense_Max_Order_By>;
  min?: InputMaybe<Expense_Min_Order_By>;
  stddev?: InputMaybe<Expense_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Expense_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Expense_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Expense_Sum_Order_By>;
  var_pop?: InputMaybe<Expense_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Expense_Var_Samp_Order_By>;
  variance?: InputMaybe<Expense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Expense" */
export type Expense_Arr_Rel_Insert_Input = {
  data: Array<Expense_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Expense_On_Conflict>;
};

/** aggregate avg on columns */
export type Expense_Avg_Fields = {
  __typename?: 'Expense_avg_fields';
  approvedBy?: Maybe<Scalars['Float']>;
  benefitId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Expense" */
export type Expense_Avg_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Expense". All fields are combined with a logical 'AND'. */
export type Expense_Bool_Exp = {
  ApprovedBy?: InputMaybe<Identities_Bool_Exp>;
  File?: InputMaybe<Files_Bool_Exp>;
  _and?: InputMaybe<Array<Expense_Bool_Exp>>;
  _not?: InputMaybe<Expense_Bool_Exp>;
  _or?: InputMaybe<Array<Expense_Bool_Exp>>;
  approvedBy?: InputMaybe<Int_Comparison_Exp>;
  approvedOn?: InputMaybe<Date_Comparison_Exp>;
  benefitId?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Money_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  fileId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Expense" */
export enum Expense_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExpensePkey = 'Expense_pkey'
}

/** input type for incrementing numeric columns in table "Expense" */
export type Expense_Inc_Input = {
  approvedBy?: InputMaybe<Scalars['Int']>;
  benefitId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['money']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Expense" */
export type Expense_Insert_Input = {
  ApprovedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  File?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  approvedBy?: InputMaybe<Scalars['Int']>;
  approvedOn?: InputMaybe<Scalars['date']>;
  benefitId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['money']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Expense_Max_Fields = {
  __typename?: 'Expense_max_fields';
  approvedBy?: Maybe<Scalars['Int']>;
  approvedOn?: Maybe<Scalars['date']>;
  benefitId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['money']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Expense" */
export type Expense_Max_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  approvedOn?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Expense_Min_Fields = {
  __typename?: 'Expense_min_fields';
  approvedBy?: Maybe<Scalars['Int']>;
  approvedOn?: Maybe<Scalars['date']>;
  benefitId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['money']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Expense" */
export type Expense_Min_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  approvedOn?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Expense" */
export type Expense_Mutation_Response = {
  __typename?: 'Expense_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Expense>;
};

/** on_conflict condition type for table "Expense" */
export type Expense_On_Conflict = {
  constraint: Expense_Constraint;
  update_columns?: Array<Expense_Update_Column>;
  where?: InputMaybe<Expense_Bool_Exp>;
};

/** Ordering options when selecting data from "Expense". */
export type Expense_Order_By = {
  ApprovedBy?: InputMaybe<Identities_Order_By>;
  File?: InputMaybe<Files_Order_By>;
  approvedBy?: InputMaybe<Order_By>;
  approvedOn?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Expense */
export type Expense_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Expense" */
export enum Expense_Select_Column {
  /** column name */
  ApprovedBy = 'approvedBy',
  /** column name */
  ApprovedOn = 'approvedOn',
  /** column name */
  BenefitId = 'benefitId',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  Notes = 'notes',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Expense_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Expense" */
export enum Expense_Select_Column_Expense_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** select "Expense_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Expense" */
export enum Expense_Select_Column_Expense_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "Expense" */
export type Expense_Set_Input = {
  approvedBy?: InputMaybe<Scalars['Int']>;
  approvedOn?: InputMaybe<Scalars['date']>;
  benefitId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['money']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Expense_Stddev_Fields = {
  __typename?: 'Expense_stddev_fields';
  approvedBy?: Maybe<Scalars['Float']>;
  benefitId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Expense" */
export type Expense_Stddev_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Expense_Stddev_Pop_Fields = {
  __typename?: 'Expense_stddev_pop_fields';
  approvedBy?: Maybe<Scalars['Float']>;
  benefitId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Expense" */
export type Expense_Stddev_Pop_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Expense_Stddev_Samp_Fields = {
  __typename?: 'Expense_stddev_samp_fields';
  approvedBy?: Maybe<Scalars['Float']>;
  benefitId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Expense" */
export type Expense_Stddev_Samp_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Expense" */
export type Expense_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Expense_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Expense_Stream_Cursor_Value_Input = {
  approvedBy?: InputMaybe<Scalars['Int']>;
  approvedOn?: InputMaybe<Scalars['date']>;
  benefitId?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['money']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Expense_Sum_Fields = {
  __typename?: 'Expense_sum_fields';
  approvedBy?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['money']>;
  fileId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Expense" */
export type Expense_Sum_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** update columns of table "Expense" */
export enum Expense_Update_Column {
  /** column name */
  ApprovedBy = 'approvedBy',
  /** column name */
  ApprovedOn = 'approvedOn',
  /** column name */
  BenefitId = 'benefitId',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  Notes = 'notes',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Expense_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Expense_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Expense_Set_Input>;
  /** filter the rows which have to be updated */
  where: Expense_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Expense_Var_Pop_Fields = {
  __typename?: 'Expense_var_pop_fields';
  approvedBy?: Maybe<Scalars['Float']>;
  benefitId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Expense" */
export type Expense_Var_Pop_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Expense_Var_Samp_Fields = {
  __typename?: 'Expense_var_samp_fields';
  approvedBy?: Maybe<Scalars['Float']>;
  benefitId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Expense" */
export type Expense_Var_Samp_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Expense_Variance_Fields = {
  __typename?: 'Expense_variance_fields';
  approvedBy?: Maybe<Scalars['Float']>;
  benefitId?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  fileId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Expense" */
export type Expense_Variance_Order_By = {
  approvedBy?: InputMaybe<Order_By>;
  benefitId?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Enum table listing the possible options for external integrations */
export type ExternalIntegrationTypes = {
  __typename?: 'ExternalIntegrationTypes';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_Aggregate = {
  __typename?: 'ExternalIntegrationTypes_aggregate';
  aggregate?: Maybe<ExternalIntegrationTypes_Aggregate_Fields>;
  nodes: Array<ExternalIntegrationTypes>;
};

/** aggregate fields of "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_Aggregate_Fields = {
  __typename?: 'ExternalIntegrationTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExternalIntegrationTypes_Max_Fields>;
  min?: Maybe<ExternalIntegrationTypes_Min_Fields>;
};


/** aggregate fields of "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalIntegrationTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ExternalIntegrationTypes". All fields are combined with a logical 'AND'. */
export type ExternalIntegrationTypes_Bool_Exp = {
  _and?: InputMaybe<Array<ExternalIntegrationTypes_Bool_Exp>>;
  _not?: InputMaybe<ExternalIntegrationTypes_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalIntegrationTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ExternalIntegrationTypes" */
export enum ExternalIntegrationTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  ExternalIntegrationTypesPkey = 'ExternalIntegrationTypes_pkey'
}

export enum ExternalIntegrationTypes_Enum {
  /** Idibu Integration */
  Idibu = 'IDIBU',
  /** Indeed Integration */
  Indeed = 'INDEED'
}

/** Boolean expression to compare columns of type "ExternalIntegrationTypes_enum". All fields are combined with logical 'AND'. */
export type ExternalIntegrationTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ExternalIntegrationTypes_Enum>;
  _in?: InputMaybe<Array<ExternalIntegrationTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ExternalIntegrationTypes_Enum>;
  _nin?: InputMaybe<Array<ExternalIntegrationTypes_Enum>>;
};

/** input type for inserting data into table "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ExternalIntegrationTypes_Max_Fields = {
  __typename?: 'ExternalIntegrationTypes_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ExternalIntegrationTypes_Min_Fields = {
  __typename?: 'ExternalIntegrationTypes_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_Mutation_Response = {
  __typename?: 'ExternalIntegrationTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalIntegrationTypes>;
};

/** on_conflict condition type for table "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_On_Conflict = {
  constraint: ExternalIntegrationTypes_Constraint;
  update_columns?: Array<ExternalIntegrationTypes_Update_Column>;
  where?: InputMaybe<ExternalIntegrationTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "ExternalIntegrationTypes". */
export type ExternalIntegrationTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ExternalIntegrationTypes */
export type ExternalIntegrationTypes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ExternalIntegrationTypes" */
export enum ExternalIntegrationTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ExternalIntegrationTypes" */
export type ExternalIntegrationTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalIntegrationTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalIntegrationTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ExternalIntegrationTypes" */
export enum ExternalIntegrationTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type ExternalIntegrationTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalIntegrationTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalIntegrationTypes_Bool_Exp;
};

/** columns and relationships of "ExternalIntegrations" */
export type ExternalIntegrations = {
  __typename?: 'ExternalIntegrations';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  metadata: Scalars['jsonb'];
  type: ExternalIntegrationTypes_Enum;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "ExternalIntegrations" */
export type ExternalIntegrationsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ExternalIntegrations" */
export type ExternalIntegrations_Aggregate = {
  __typename?: 'ExternalIntegrations_aggregate';
  aggregate?: Maybe<ExternalIntegrations_Aggregate_Fields>;
  nodes: Array<ExternalIntegrations>;
};

/** aggregate fields of "ExternalIntegrations" */
export type ExternalIntegrations_Aggregate_Fields = {
  __typename?: 'ExternalIntegrations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExternalIntegrations_Max_Fields>;
  min?: Maybe<ExternalIntegrations_Min_Fields>;
};


/** aggregate fields of "ExternalIntegrations" */
export type ExternalIntegrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalIntegrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ExternalIntegrations_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ExternalIntegrations". All fields are combined with a logical 'AND'. */
export type ExternalIntegrations_Bool_Exp = {
  _and?: InputMaybe<Array<ExternalIntegrations_Bool_Exp>>;
  _not?: InputMaybe<ExternalIntegrations_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalIntegrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<ExternalIntegrationTypes_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ExternalIntegrations" */
export enum ExternalIntegrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExternalIntegrationsPkey = 'ExternalIntegrations_pkey',
  /** unique or primary key constraint on columns "type" */
  ExternalIntegrationsTypeKey = 'ExternalIntegrations_type_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ExternalIntegrations_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ExternalIntegrations_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ExternalIntegrations_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "ExternalIntegrations" */
export type ExternalIntegrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<ExternalIntegrationTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ExternalIntegrations_Max_Fields = {
  __typename?: 'ExternalIntegrations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ExternalIntegrations_Min_Fields = {
  __typename?: 'ExternalIntegrations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ExternalIntegrations" */
export type ExternalIntegrations_Mutation_Response = {
  __typename?: 'ExternalIntegrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalIntegrations>;
};

/** on_conflict condition type for table "ExternalIntegrations" */
export type ExternalIntegrations_On_Conflict = {
  constraint: ExternalIntegrations_Constraint;
  update_columns?: Array<ExternalIntegrations_Update_Column>;
  where?: InputMaybe<ExternalIntegrations_Bool_Exp>;
};

/** Ordering options when selecting data from "ExternalIntegrations". */
export type ExternalIntegrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ExternalIntegrations */
export type ExternalIntegrations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ExternalIntegrations_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ExternalIntegrations" */
export enum ExternalIntegrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ExternalIntegrations" */
export type ExternalIntegrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<ExternalIntegrationTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ExternalIntegrations" */
export type ExternalIntegrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalIntegrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalIntegrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<ExternalIntegrationTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ExternalIntegrations" */
export enum ExternalIntegrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ExternalIntegrations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ExternalIntegrations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ExternalIntegrations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ExternalIntegrations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ExternalIntegrations_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ExternalIntegrations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalIntegrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalIntegrations_Bool_Exp;
};

/** columns and relationships of "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings = {
  __typename?: 'ExternalJobBoardPublishings';
  /** An object relationship */
  ExternalJobBoard: ExternalJobBoards;
  /** An object relationship */
  Job: Jobs;
  external_job_board_id: Scalars['uuid'];
  external_job_id: Scalars['Int'];
  job_id: Scalars['Int'];
};

/** aggregated selection of "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Aggregate = {
  __typename?: 'ExternalJobBoardPublishings_aggregate';
  aggregate?: Maybe<ExternalJobBoardPublishings_Aggregate_Fields>;
  nodes: Array<ExternalJobBoardPublishings>;
};

export type ExternalJobBoardPublishings_Aggregate_Bool_Exp = {
  count?: InputMaybe<ExternalJobBoardPublishings_Aggregate_Bool_Exp_Count>;
};

export type ExternalJobBoardPublishings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Aggregate_Fields = {
  __typename?: 'ExternalJobBoardPublishings_aggregate_fields';
  avg?: Maybe<ExternalJobBoardPublishings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExternalJobBoardPublishings_Max_Fields>;
  min?: Maybe<ExternalJobBoardPublishings_Min_Fields>;
  stddev?: Maybe<ExternalJobBoardPublishings_Stddev_Fields>;
  stddev_pop?: Maybe<ExternalJobBoardPublishings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExternalJobBoardPublishings_Stddev_Samp_Fields>;
  sum?: Maybe<ExternalJobBoardPublishings_Sum_Fields>;
  var_pop?: Maybe<ExternalJobBoardPublishings_Var_Pop_Fields>;
  var_samp?: Maybe<ExternalJobBoardPublishings_Var_Samp_Fields>;
  variance?: Maybe<ExternalJobBoardPublishings_Variance_Fields>;
};


/** aggregate fields of "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Aggregate_Order_By = {
  avg?: InputMaybe<ExternalJobBoardPublishings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ExternalJobBoardPublishings_Max_Order_By>;
  min?: InputMaybe<ExternalJobBoardPublishings_Min_Order_By>;
  stddev?: InputMaybe<ExternalJobBoardPublishings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ExternalJobBoardPublishings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ExternalJobBoardPublishings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ExternalJobBoardPublishings_Sum_Order_By>;
  var_pop?: InputMaybe<ExternalJobBoardPublishings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ExternalJobBoardPublishings_Var_Samp_Order_By>;
  variance?: InputMaybe<ExternalJobBoardPublishings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Arr_Rel_Insert_Input = {
  data: Array<ExternalJobBoardPublishings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalJobBoardPublishings_On_Conflict>;
};

/** aggregate avg on columns */
export type ExternalJobBoardPublishings_Avg_Fields = {
  __typename?: 'ExternalJobBoardPublishings_avg_fields';
  external_job_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Avg_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ExternalJobBoardPublishings". All fields are combined with a logical 'AND'. */
export type ExternalJobBoardPublishings_Bool_Exp = {
  ExternalJobBoard?: InputMaybe<ExternalJobBoards_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<ExternalJobBoardPublishings_Bool_Exp>>;
  _not?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalJobBoardPublishings_Bool_Exp>>;
  external_job_board_id?: InputMaybe<Uuid_Comparison_Exp>;
  external_job_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ExternalJobBoardPublishings" */
export enum ExternalJobBoardPublishings_Constraint {
  /** unique or primary key constraint on columns "job_id", "external_job_board_id" */
  ExternalJobBoardPublishingsPkey = 'ExternalJobBoardPublishings_pkey'
}

/** input type for incrementing numeric columns in table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Inc_Input = {
  external_job_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Insert_Input = {
  ExternalJobBoard?: InputMaybe<ExternalJobBoards_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  external_job_board_id?: InputMaybe<Scalars['uuid']>;
  external_job_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ExternalJobBoardPublishings_Max_Fields = {
  __typename?: 'ExternalJobBoardPublishings_max_fields';
  external_job_board_id?: Maybe<Scalars['uuid']>;
  external_job_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Max_Order_By = {
  external_job_board_id?: InputMaybe<Order_By>;
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ExternalJobBoardPublishings_Min_Fields = {
  __typename?: 'ExternalJobBoardPublishings_min_fields';
  external_job_board_id?: Maybe<Scalars['uuid']>;
  external_job_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Min_Order_By = {
  external_job_board_id?: InputMaybe<Order_By>;
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Mutation_Response = {
  __typename?: 'ExternalJobBoardPublishings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalJobBoardPublishings>;
};

/** on_conflict condition type for table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_On_Conflict = {
  constraint: ExternalJobBoardPublishings_Constraint;
  update_columns?: Array<ExternalJobBoardPublishings_Update_Column>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};

/** Ordering options when selecting data from "ExternalJobBoardPublishings". */
export type ExternalJobBoardPublishings_Order_By = {
  ExternalJobBoard?: InputMaybe<ExternalJobBoards_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  external_job_board_id?: InputMaybe<Order_By>;
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ExternalJobBoardPublishings */
export type ExternalJobBoardPublishings_Pk_Columns_Input = {
  external_job_board_id: Scalars['uuid'];
  job_id: Scalars['Int'];
};

/** select columns of table "ExternalJobBoardPublishings" */
export enum ExternalJobBoardPublishings_Select_Column {
  /** column name */
  ExternalJobBoardId = 'external_job_board_id',
  /** column name */
  ExternalJobId = 'external_job_id',
  /** column name */
  JobId = 'job_id'
}

/** input type for updating data in table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Set_Input = {
  external_job_board_id?: InputMaybe<Scalars['uuid']>;
  external_job_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ExternalJobBoardPublishings_Stddev_Fields = {
  __typename?: 'ExternalJobBoardPublishings_stddev_fields';
  external_job_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Stddev_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ExternalJobBoardPublishings_Stddev_Pop_Fields = {
  __typename?: 'ExternalJobBoardPublishings_stddev_pop_fields';
  external_job_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Stddev_Pop_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ExternalJobBoardPublishings_Stddev_Samp_Fields = {
  __typename?: 'ExternalJobBoardPublishings_stddev_samp_fields';
  external_job_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Stddev_Samp_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalJobBoardPublishings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalJobBoardPublishings_Stream_Cursor_Value_Input = {
  external_job_board_id?: InputMaybe<Scalars['uuid']>;
  external_job_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ExternalJobBoardPublishings_Sum_Fields = {
  __typename?: 'ExternalJobBoardPublishings_sum_fields';
  external_job_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Sum_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** update columns of table "ExternalJobBoardPublishings" */
export enum ExternalJobBoardPublishings_Update_Column {
  /** column name */
  ExternalJobBoardId = 'external_job_board_id',
  /** column name */
  ExternalJobId = 'external_job_id',
  /** column name */
  JobId = 'job_id'
}

export type ExternalJobBoardPublishings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExternalJobBoardPublishings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalJobBoardPublishings_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalJobBoardPublishings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ExternalJobBoardPublishings_Var_Pop_Fields = {
  __typename?: 'ExternalJobBoardPublishings_var_pop_fields';
  external_job_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Var_Pop_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ExternalJobBoardPublishings_Var_Samp_Fields = {
  __typename?: 'ExternalJobBoardPublishings_var_samp_fields';
  external_job_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Var_Samp_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ExternalJobBoardPublishings_Variance_Fields = {
  __typename?: 'ExternalJobBoardPublishings_variance_fields';
  external_job_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ExternalJobBoardPublishings" */
export type ExternalJobBoardPublishings_Variance_Order_By = {
  external_job_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ExternalJobBoards" */
export type ExternalJobBoards = {
  __typename?: 'ExternalJobBoards';
  /** An array relationship */
  ExternalJobBoardPublishings: Array<ExternalJobBoardPublishings>;
  /** An aggregate relationship */
  ExternalJobBoardPublishings_aggregate: ExternalJobBoardPublishings_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  idibu_portal_id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "ExternalJobBoards" */
export type ExternalJobBoardsExternalJobBoardPublishingsArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


/** columns and relationships of "ExternalJobBoards" */
export type ExternalJobBoardsExternalJobBoardPublishings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};

/** aggregated selection of "ExternalJobBoards" */
export type ExternalJobBoards_Aggregate = {
  __typename?: 'ExternalJobBoards_aggregate';
  aggregate?: Maybe<ExternalJobBoards_Aggregate_Fields>;
  nodes: Array<ExternalJobBoards>;
};

/** aggregate fields of "ExternalJobBoards" */
export type ExternalJobBoards_Aggregate_Fields = {
  __typename?: 'ExternalJobBoards_aggregate_fields';
  avg?: Maybe<ExternalJobBoards_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExternalJobBoards_Max_Fields>;
  min?: Maybe<ExternalJobBoards_Min_Fields>;
  stddev?: Maybe<ExternalJobBoards_Stddev_Fields>;
  stddev_pop?: Maybe<ExternalJobBoards_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExternalJobBoards_Stddev_Samp_Fields>;
  sum?: Maybe<ExternalJobBoards_Sum_Fields>;
  var_pop?: Maybe<ExternalJobBoards_Var_Pop_Fields>;
  var_samp?: Maybe<ExternalJobBoards_Var_Samp_Fields>;
  variance?: Maybe<ExternalJobBoards_Variance_Fields>;
};


/** aggregate fields of "ExternalJobBoards" */
export type ExternalJobBoards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalJobBoards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ExternalJobBoards_Avg_Fields = {
  __typename?: 'ExternalJobBoards_avg_fields';
  idibu_portal_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ExternalJobBoards". All fields are combined with a logical 'AND'. */
export type ExternalJobBoards_Bool_Exp = {
  ExternalJobBoardPublishings?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
  ExternalJobBoardPublishings_aggregate?: InputMaybe<ExternalJobBoardPublishings_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<ExternalJobBoards_Bool_Exp>>;
  _not?: InputMaybe<ExternalJobBoards_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalJobBoards_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  idibu_portal_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ExternalJobBoards" */
export enum ExternalJobBoards_Constraint {
  /** unique or primary key constraint on columns "idibu_portal_id" */
  ExternalJobBoardsIdibuPortalIdKey = 'ExternalJobBoards_idibu_portal_id_key',
  /** unique or primary key constraint on columns "id" */
  ExternalJobBoardsPkey = 'ExternalJobBoards_pkey'
}

/** input type for incrementing numeric columns in table "ExternalJobBoards" */
export type ExternalJobBoards_Inc_Input = {
  idibu_portal_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ExternalJobBoards" */
export type ExternalJobBoards_Insert_Input = {
  ExternalJobBoardPublishings?: InputMaybe<ExternalJobBoardPublishings_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  idibu_portal_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ExternalJobBoards_Max_Fields = {
  __typename?: 'ExternalJobBoards_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idibu_portal_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ExternalJobBoards_Min_Fields = {
  __typename?: 'ExternalJobBoards_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idibu_portal_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ExternalJobBoards" */
export type ExternalJobBoards_Mutation_Response = {
  __typename?: 'ExternalJobBoards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalJobBoards>;
};

/** input type for inserting object relation for remote table "ExternalJobBoards" */
export type ExternalJobBoards_Obj_Rel_Insert_Input = {
  data: ExternalJobBoards_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalJobBoards_On_Conflict>;
};

/** on_conflict condition type for table "ExternalJobBoards" */
export type ExternalJobBoards_On_Conflict = {
  constraint: ExternalJobBoards_Constraint;
  update_columns?: Array<ExternalJobBoards_Update_Column>;
  where?: InputMaybe<ExternalJobBoards_Bool_Exp>;
};

/** Ordering options when selecting data from "ExternalJobBoards". */
export type ExternalJobBoards_Order_By = {
  ExternalJobBoardPublishings_aggregate?: InputMaybe<ExternalJobBoardPublishings_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idibu_portal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ExternalJobBoards */
export type ExternalJobBoards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ExternalJobBoards" */
export enum ExternalJobBoards_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdibuPortalId = 'idibu_portal_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ExternalJobBoards" */
export type ExternalJobBoards_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  idibu_portal_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ExternalJobBoards_Stddev_Fields = {
  __typename?: 'ExternalJobBoards_stddev_fields';
  idibu_portal_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExternalJobBoards_Stddev_Pop_Fields = {
  __typename?: 'ExternalJobBoards_stddev_pop_fields';
  idibu_portal_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExternalJobBoards_Stddev_Samp_Fields = {
  __typename?: 'ExternalJobBoards_stddev_samp_fields';
  idibu_portal_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ExternalJobBoards" */
export type ExternalJobBoards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalJobBoards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalJobBoards_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  idibu_portal_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ExternalJobBoards_Sum_Fields = {
  __typename?: 'ExternalJobBoards_sum_fields';
  idibu_portal_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ExternalJobBoards" */
export enum ExternalJobBoards_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdibuPortalId = 'idibu_portal_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ExternalJobBoards_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExternalJobBoards_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalJobBoards_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalJobBoards_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ExternalJobBoards_Var_Pop_Fields = {
  __typename?: 'ExternalJobBoards_var_pop_fields';
  idibu_portal_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExternalJobBoards_Var_Samp_Fields = {
  __typename?: 'ExternalJobBoards_var_samp_fields';
  idibu_portal_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExternalJobBoards_Variance_Fields = {
  __typename?: 'ExternalJobBoards_variance_fields';
  idibu_portal_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ExternalSystem" */
export type ExternalSystem = {
  __typename?: 'ExternalSystem';
  id: Scalars['uuid'];
  info?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  secretInfo?: Maybe<Scalars['jsonb']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "ExternalSystem" */
export type ExternalSystemInfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "ExternalSystem" */
export type ExternalSystemSecretInfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ExternalSystemInstance" */
export type ExternalSystemInstance = {
  __typename?: 'ExternalSystemInstance';
  auth_info: Scalars['jsonb'];
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  owner_id: Scalars['Int'];
  system: Scalars['uuid'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "ExternalSystemInstance" */
export type ExternalSystemInstanceAuth_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ExternalSystemInstance" */
export type ExternalSystemInstance_Aggregate = {
  __typename?: 'ExternalSystemInstance_aggregate';
  aggregate?: Maybe<ExternalSystemInstance_Aggregate_Fields>;
  nodes: Array<ExternalSystemInstance>;
};

/** aggregate fields of "ExternalSystemInstance" */
export type ExternalSystemInstance_Aggregate_Fields = {
  __typename?: 'ExternalSystemInstance_aggregate_fields';
  avg?: Maybe<ExternalSystemInstance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExternalSystemInstance_Max_Fields>;
  min?: Maybe<ExternalSystemInstance_Min_Fields>;
  stddev?: Maybe<ExternalSystemInstance_Stddev_Fields>;
  stddev_pop?: Maybe<ExternalSystemInstance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExternalSystemInstance_Stddev_Samp_Fields>;
  sum?: Maybe<ExternalSystemInstance_Sum_Fields>;
  var_pop?: Maybe<ExternalSystemInstance_Var_Pop_Fields>;
  var_samp?: Maybe<ExternalSystemInstance_Var_Samp_Fields>;
  variance?: Maybe<ExternalSystemInstance_Variance_Fields>;
};


/** aggregate fields of "ExternalSystemInstance" */
export type ExternalSystemInstance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalSystemInstance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ExternalSystemInstance_Append_Input = {
  auth_info?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ExternalSystemInstance_Avg_Fields = {
  __typename?: 'ExternalSystemInstance_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ExternalSystemInstance". All fields are combined with a logical 'AND'. */
export type ExternalSystemInstance_Bool_Exp = {
  _and?: InputMaybe<Array<ExternalSystemInstance_Bool_Exp>>;
  _not?: InputMaybe<ExternalSystemInstance_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalSystemInstance_Bool_Exp>>;
  auth_info?: InputMaybe<Jsonb_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  system?: InputMaybe<Uuid_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ExternalSystemInstance" */
export enum ExternalSystemInstance_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExternalSystemInstancePkey = 'ExternalSystemInstance_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ExternalSystemInstance_Delete_At_Path_Input = {
  auth_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ExternalSystemInstance_Delete_Elem_Input = {
  auth_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ExternalSystemInstance_Delete_Key_Input = {
  auth_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ExternalSystemInstance" */
export type ExternalSystemInstance_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ExternalSystemInstance" */
export type ExternalSystemInstance_Insert_Input = {
  auth_info?: InputMaybe<Scalars['jsonb']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  system?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ExternalSystemInstance_Max_Fields = {
  __typename?: 'ExternalSystemInstance_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  system?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ExternalSystemInstance_Min_Fields = {
  __typename?: 'ExternalSystemInstance_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  system?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ExternalSystemInstance" */
export type ExternalSystemInstance_Mutation_Response = {
  __typename?: 'ExternalSystemInstance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalSystemInstance>;
};

/** on_conflict condition type for table "ExternalSystemInstance" */
export type ExternalSystemInstance_On_Conflict = {
  constraint: ExternalSystemInstance_Constraint;
  update_columns?: Array<ExternalSystemInstance_Update_Column>;
  where?: InputMaybe<ExternalSystemInstance_Bool_Exp>;
};

/** Ordering options when selecting data from "ExternalSystemInstance". */
export type ExternalSystemInstance_Order_By = {
  auth_info?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  system?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ExternalSystemInstance */
export type ExternalSystemInstance_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ExternalSystemInstance_Prepend_Input = {
  auth_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ExternalSystemInstance" */
export enum ExternalSystemInstance_Select_Column {
  /** column name */
  AuthInfo = 'auth_info',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  System = 'system',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ExternalSystemInstance" */
export type ExternalSystemInstance_Set_Input = {
  auth_info?: InputMaybe<Scalars['jsonb']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  system?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ExternalSystemInstance_Stddev_Fields = {
  __typename?: 'ExternalSystemInstance_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExternalSystemInstance_Stddev_Pop_Fields = {
  __typename?: 'ExternalSystemInstance_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExternalSystemInstance_Stddev_Samp_Fields = {
  __typename?: 'ExternalSystemInstance_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ExternalSystemInstance" */
export type ExternalSystemInstance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalSystemInstance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalSystemInstance_Stream_Cursor_Value_Input = {
  auth_info?: InputMaybe<Scalars['jsonb']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  system?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ExternalSystemInstance_Sum_Fields = {
  __typename?: 'ExternalSystemInstance_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "ExternalSystemInstance" */
export enum ExternalSystemInstance_Update_Column {
  /** column name */
  AuthInfo = 'auth_info',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  System = 'system',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type ExternalSystemInstance_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ExternalSystemInstance_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ExternalSystemInstance_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ExternalSystemInstance_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ExternalSystemInstance_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExternalSystemInstance_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ExternalSystemInstance_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalSystemInstance_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalSystemInstance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ExternalSystemInstance_Var_Pop_Fields = {
  __typename?: 'ExternalSystemInstance_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExternalSystemInstance_Var_Samp_Fields = {
  __typename?: 'ExternalSystemInstance_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExternalSystemInstance_Variance_Fields = {
  __typename?: 'ExternalSystemInstance_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "ExternalSystem" */
export type ExternalSystem_Aggregate = {
  __typename?: 'ExternalSystem_aggregate';
  aggregate?: Maybe<ExternalSystem_Aggregate_Fields>;
  nodes: Array<ExternalSystem>;
};

/** aggregate fields of "ExternalSystem" */
export type ExternalSystem_Aggregate_Fields = {
  __typename?: 'ExternalSystem_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExternalSystem_Max_Fields>;
  min?: Maybe<ExternalSystem_Min_Fields>;
};


/** aggregate fields of "ExternalSystem" */
export type ExternalSystem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalSystem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ExternalSystem_Append_Input = {
  info?: InputMaybe<Scalars['jsonb']>;
  secretInfo?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ExternalSystem". All fields are combined with a logical 'AND'. */
export type ExternalSystem_Bool_Exp = {
  _and?: InputMaybe<Array<ExternalSystem_Bool_Exp>>;
  _not?: InputMaybe<ExternalSystem_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalSystem_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  secretInfo?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ExternalSystem" */
export enum ExternalSystem_Constraint {
  /** unique or primary key constraint on columns "name" */
  ExternalSystemNameKey = 'ExternalSystem_name_key',
  /** unique or primary key constraint on columns "id" */
  ExternalSystemPkey = 'ExternalSystem_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ExternalSystem_Delete_At_Path_Input = {
  info?: InputMaybe<Array<Scalars['String']>>;
  secretInfo?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ExternalSystem_Delete_Elem_Input = {
  info?: InputMaybe<Scalars['Int']>;
  secretInfo?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ExternalSystem_Delete_Key_Input = {
  info?: InputMaybe<Scalars['String']>;
  secretInfo?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "ExternalSystem" */
export type ExternalSystem_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  secretInfo?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ExternalSystem_Max_Fields = {
  __typename?: 'ExternalSystem_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ExternalSystem_Min_Fields = {
  __typename?: 'ExternalSystem_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ExternalSystem" */
export type ExternalSystem_Mutation_Response = {
  __typename?: 'ExternalSystem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalSystem>;
};

/** on_conflict condition type for table "ExternalSystem" */
export type ExternalSystem_On_Conflict = {
  constraint: ExternalSystem_Constraint;
  update_columns?: Array<ExternalSystem_Update_Column>;
  where?: InputMaybe<ExternalSystem_Bool_Exp>;
};

/** Ordering options when selecting data from "ExternalSystem". */
export type ExternalSystem_Order_By = {
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  secretInfo?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ExternalSystem */
export type ExternalSystem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ExternalSystem_Prepend_Input = {
  info?: InputMaybe<Scalars['jsonb']>;
  secretInfo?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ExternalSystem" */
export enum ExternalSystem_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  SecretInfo = 'secretInfo',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ExternalSystem" */
export type ExternalSystem_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  secretInfo?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ExternalSystem" */
export type ExternalSystem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalSystem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalSystem_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  secretInfo?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ExternalSystem" */
export enum ExternalSystem_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  SecretInfo = 'secretInfo',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ExternalSystem_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ExternalSystem_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ExternalSystem_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ExternalSystem_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ExternalSystem_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ExternalSystem_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalSystem_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalSystem_Bool_Exp;
};

export type FtsSortOverride = {
  direction: Scalars['String'];
  sortKey: Scalars['String'];
};

/** columns and relationships of "FeatureFlags" */
export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  /** An array relationship */
  IdentitiesInRole: Array<IdentityRoles>;
  /** An aggregate relationship */
  IdentitiesInRole_aggregate: IdentityRoles_Aggregate;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Role?: Maybe<Roles>;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  feature_desc?: Maybe<Scalars['String']>;
  feature_name: Scalars['String'];
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  identity_regex?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  role_regex?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "FeatureFlags" */
export type FeatureFlagsIdentitiesInRoleArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


/** columns and relationships of "FeatureFlags" */
export type FeatureFlagsIdentitiesInRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};

/** aggregated selection of "FeatureFlags" */
export type FeatureFlags_Aggregate = {
  __typename?: 'FeatureFlags_aggregate';
  aggregate?: Maybe<FeatureFlags_Aggregate_Fields>;
  nodes: Array<FeatureFlags>;
};

/** aggregate fields of "FeatureFlags" */
export type FeatureFlags_Aggregate_Fields = {
  __typename?: 'FeatureFlags_aggregate_fields';
  avg?: Maybe<FeatureFlags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FeatureFlags_Max_Fields>;
  min?: Maybe<FeatureFlags_Min_Fields>;
  stddev?: Maybe<FeatureFlags_Stddev_Fields>;
  stddev_pop?: Maybe<FeatureFlags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FeatureFlags_Stddev_Samp_Fields>;
  sum?: Maybe<FeatureFlags_Sum_Fields>;
  var_pop?: Maybe<FeatureFlags_Var_Pop_Fields>;
  var_samp?: Maybe<FeatureFlags_Var_Samp_Fields>;
  variance?: Maybe<FeatureFlags_Variance_Fields>;
};


/** aggregate fields of "FeatureFlags" */
export type FeatureFlags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FeatureFlags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FeatureFlags_Avg_Fields = {
  __typename?: 'FeatureFlags_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FeatureFlags". All fields are combined with a logical 'AND'. */
export type FeatureFlags_Bool_Exp = {
  IdentitiesInRole?: InputMaybe<IdentityRoles_Bool_Exp>;
  IdentitiesInRole_aggregate?: InputMaybe<IdentityRoles_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Role?: InputMaybe<Roles_Bool_Exp>;
  _and?: InputMaybe<Array<FeatureFlags_Bool_Exp>>;
  _not?: InputMaybe<FeatureFlags_Bool_Exp>;
  _or?: InputMaybe<Array<FeatureFlags_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature_desc?: InputMaybe<String_Comparison_Exp>;
  feature_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  identity_regex?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  role_regex?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "FeatureFlags" */
export enum FeatureFlags_Constraint {
  /** unique or primary key constraint on columns "identity_id", "feature_name", "company_id" */
  FeatureFlagsFeatureNameIdentityIdCompanyIdKey = 'FeatureFlags_feature_name_identity_id_company_id_key',
  /** unique or primary key constraint on columns "role_id", "feature_name", "company_id" */
  FeatureFlagsFeatureNameRoleIdCompanyIdKey = 'FeatureFlags_feature_name_role_id_company_id_key',
  /** unique or primary key constraint on columns "id" */
  FeatureFlagsPkey = 'FeatureFlags_pkey'
}

/** input type for incrementing numeric columns in table "FeatureFlags" */
export type FeatureFlags_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "FeatureFlags" */
export type FeatureFlags_Insert_Input = {
  IdentitiesInRole?: InputMaybe<IdentityRoles_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature_desc?: InputMaybe<Scalars['String']>;
  feature_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_regex?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  role_regex?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FeatureFlags_Max_Fields = {
  __typename?: 'FeatureFlags_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feature_desc?: Maybe<Scalars['String']>;
  feature_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_regex?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  role_regex?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type FeatureFlags_Min_Fields = {
  __typename?: 'FeatureFlags_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feature_desc?: Maybe<Scalars['String']>;
  feature_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_regex?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  role_regex?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "FeatureFlags" */
export type FeatureFlags_Mutation_Response = {
  __typename?: 'FeatureFlags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FeatureFlags>;
};

/** on_conflict condition type for table "FeatureFlags" */
export type FeatureFlags_On_Conflict = {
  constraint: FeatureFlags_Constraint;
  update_columns?: Array<FeatureFlags_Update_Column>;
  where?: InputMaybe<FeatureFlags_Bool_Exp>;
};

/** Ordering options when selecting data from "FeatureFlags". */
export type FeatureFlags_Order_By = {
  IdentitiesInRole_aggregate?: InputMaybe<IdentityRoles_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Role?: InputMaybe<Roles_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feature_desc?: InputMaybe<Order_By>;
  feature_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_regex?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  role_regex?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FeatureFlags */
export type FeatureFlags_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "FeatureFlags" */
export enum FeatureFlags_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeatureDesc = 'feature_desc',
  /** column name */
  FeatureName = 'feature_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IdentityRegex = 'identity_regex',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  RoleRegex = 'role_regex',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "FeatureFlags" */
export type FeatureFlags_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature_desc?: InputMaybe<Scalars['String']>;
  feature_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_regex?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  role_regex?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type FeatureFlags_Stddev_Fields = {
  __typename?: 'FeatureFlags_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FeatureFlags_Stddev_Pop_Fields = {
  __typename?: 'FeatureFlags_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FeatureFlags_Stddev_Samp_Fields = {
  __typename?: 'FeatureFlags_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "FeatureFlags" */
export type FeatureFlags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FeatureFlags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FeatureFlags_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature_desc?: InputMaybe<Scalars['String']>;
  feature_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_regex?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  role_regex?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type FeatureFlags_Sum_Fields = {
  __typename?: 'FeatureFlags_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "FeatureFlags" */
export enum FeatureFlags_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeatureDesc = 'feature_desc',
  /** column name */
  FeatureName = 'feature_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IdentityRegex = 'identity_regex',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  RoleRegex = 'role_regex',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FeatureFlags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FeatureFlags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FeatureFlags_Set_Input>;
  /** filter the rows which have to be updated */
  where: FeatureFlags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FeatureFlags_Var_Pop_Fields = {
  __typename?: 'FeatureFlags_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FeatureFlags_Var_Samp_Fields = {
  __typename?: 'FeatureFlags_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FeatureFlags_Variance_Fields = {
  __typename?: 'FeatureFlags_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Field" */
export type Field = {
  __typename?: 'Field';
  /** An object relationship */
  FieldDef: FieldDef;
  /** An array relationship */
  FieldPicklistValues: Array<FieldPicklistValues>;
  /** An aggregate relationship */
  FieldPicklistValues_aggregate: FieldPicklistValues_Aggregate;
  /** An object relationship */
  Overlay: Overlay;
  created_at: Scalars['timestamptz'];
  field_def_id: Scalars['uuid'];
  id: Scalars['uuid'];
  overlay_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Field" */
export type FieldFieldPicklistValuesArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};


/** columns and relationships of "Field" */
export type FieldFieldPicklistValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};

/** columns and relationships of "FieldDef" */
export type FieldDef = {
  __typename?: 'FieldDef';
  /** An object relationship */
  FieldTypeDef: FieldTypeDef;
  /** An array relationship */
  Fields: Array<Field>;
  /** An aggregate relationship */
  Fields_aggregate: Field_Aggregate;
  /** An object relationship */
  OverlayDefinition: OverlayDefinition;
  /** An object relationship */
  Picklist?: Maybe<Picklists>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  interaction_spec?: Maybe<Scalars['jsonb']>;
  key_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  overlay_def_id: Scalars['uuid'];
  picklist_id?: Maybe<Scalars['uuid']>;
  type_def_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "FieldDef" */
export type FieldDefFieldsArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


/** columns and relationships of "FieldDef" */
export type FieldDefFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


/** columns and relationships of "FieldDef" */
export type FieldDefInteraction_SpecArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "FieldDef" */
export type FieldDef_Aggregate = {
  __typename?: 'FieldDef_aggregate';
  aggregate?: Maybe<FieldDef_Aggregate_Fields>;
  nodes: Array<FieldDef>;
};

export type FieldDef_Aggregate_Bool_Exp = {
  count?: InputMaybe<FieldDef_Aggregate_Bool_Exp_Count>;
};

export type FieldDef_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FieldDef_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FieldDef_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "FieldDef" */
export type FieldDef_Aggregate_Fields = {
  __typename?: 'FieldDef_aggregate_fields';
  avg?: Maybe<FieldDef_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FieldDef_Max_Fields>;
  min?: Maybe<FieldDef_Min_Fields>;
  stddev?: Maybe<FieldDef_Stddev_Fields>;
  stddev_pop?: Maybe<FieldDef_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FieldDef_Stddev_Samp_Fields>;
  sum?: Maybe<FieldDef_Sum_Fields>;
  var_pop?: Maybe<FieldDef_Var_Pop_Fields>;
  var_samp?: Maybe<FieldDef_Var_Samp_Fields>;
  variance?: Maybe<FieldDef_Variance_Fields>;
};


/** aggregate fields of "FieldDef" */
export type FieldDef_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FieldDef_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FieldDef" */
export type FieldDef_Aggregate_Order_By = {
  avg?: InputMaybe<FieldDef_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FieldDef_Max_Order_By>;
  min?: InputMaybe<FieldDef_Min_Order_By>;
  stddev?: InputMaybe<FieldDef_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FieldDef_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FieldDef_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FieldDef_Sum_Order_By>;
  var_pop?: InputMaybe<FieldDef_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FieldDef_Var_Samp_Order_By>;
  variance?: InputMaybe<FieldDef_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FieldDef_Append_Input = {
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "FieldDef" */
export type FieldDef_Arr_Rel_Insert_Input = {
  data: Array<FieldDef_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FieldDef_On_Conflict>;
};

/** aggregate avg on columns */
export type FieldDef_Avg_Fields = {
  __typename?: 'FieldDef_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FieldDef" */
export type FieldDef_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FieldDef". All fields are combined with a logical 'AND'. */
export type FieldDef_Bool_Exp = {
  FieldTypeDef?: InputMaybe<FieldTypeDef_Bool_Exp>;
  Fields?: InputMaybe<Field_Bool_Exp>;
  Fields_aggregate?: InputMaybe<Field_Aggregate_Bool_Exp>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Bool_Exp>;
  Picklist?: InputMaybe<Picklists_Bool_Exp>;
  _and?: InputMaybe<Array<FieldDef_Bool_Exp>>;
  _not?: InputMaybe<FieldDef_Bool_Exp>;
  _or?: InputMaybe<Array<FieldDef_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction_spec?: InputMaybe<Jsonb_Comparison_Exp>;
  key_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  overlay_def_id?: InputMaybe<Uuid_Comparison_Exp>;
  picklist_id?: InputMaybe<Uuid_Comparison_Exp>;
  type_def_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "FieldDef" */
export enum FieldDef_Constraint {
  /** unique or primary key constraint on columns "order", "overlay_def_id", "name" */
  FieldRefNameOverlayIdOrderKey = 'FieldRef_name_overlay_id_order_key',
  /** unique or primary key constraint on columns "id" */
  FieldRefPkey = 'FieldRef_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FieldDef_Delete_At_Path_Input = {
  interaction_spec?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FieldDef_Delete_Elem_Input = {
  interaction_spec?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FieldDef_Delete_Key_Input = {
  interaction_spec?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "FieldDef" */
export type FieldDef_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "FieldDef" */
export type FieldDef_Insert_Input = {
  FieldTypeDef?: InputMaybe<FieldTypeDef_Obj_Rel_Insert_Input>;
  Fields?: InputMaybe<Field_Arr_Rel_Insert_Input>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Obj_Rel_Insert_Input>;
  Picklist?: InputMaybe<Picklists_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  key_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  picklist_id?: InputMaybe<Scalars['uuid']>;
  type_def_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FieldDef_Max_Fields = {
  __typename?: 'FieldDef_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  overlay_def_id?: Maybe<Scalars['uuid']>;
  picklist_id?: Maybe<Scalars['uuid']>;
  type_def_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "FieldDef" */
export type FieldDef_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  picklist_id?: InputMaybe<Order_By>;
  type_def_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FieldDef_Min_Fields = {
  __typename?: 'FieldDef_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  overlay_def_id?: Maybe<Scalars['uuid']>;
  picklist_id?: Maybe<Scalars['uuid']>;
  type_def_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "FieldDef" */
export type FieldDef_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  picklist_id?: InputMaybe<Order_By>;
  type_def_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FieldDef" */
export type FieldDef_Mutation_Response = {
  __typename?: 'FieldDef_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FieldDef>;
};

/** input type for inserting object relation for remote table "FieldDef" */
export type FieldDef_Obj_Rel_Insert_Input = {
  data: FieldDef_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<FieldDef_On_Conflict>;
};

/** on_conflict condition type for table "FieldDef" */
export type FieldDef_On_Conflict = {
  constraint: FieldDef_Constraint;
  update_columns?: Array<FieldDef_Update_Column>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};

/** Ordering options when selecting data from "FieldDef". */
export type FieldDef_Order_By = {
  FieldTypeDef?: InputMaybe<FieldTypeDef_Order_By>;
  Fields_aggregate?: InputMaybe<Field_Aggregate_Order_By>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Order_By>;
  Picklist?: InputMaybe<Picklists_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_spec?: InputMaybe<Order_By>;
  key_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  picklist_id?: InputMaybe<Order_By>;
  type_def_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FieldDef */
export type FieldDef_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FieldDef_Prepend_Input = {
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "FieldDef" */
export enum FieldDef_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionSpec = 'interaction_spec',
  /** column name */
  KeyName = 'key_name',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OverlayDefId = 'overlay_def_id',
  /** column name */
  PicklistId = 'picklist_id',
  /** column name */
  TypeDefId = 'type_def_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "FieldDef" */
export type FieldDef_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  key_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  picklist_id?: InputMaybe<Scalars['uuid']>;
  type_def_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type FieldDef_Stddev_Fields = {
  __typename?: 'FieldDef_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FieldDef" */
export type FieldDef_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FieldDef_Stddev_Pop_Fields = {
  __typename?: 'FieldDef_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FieldDef" */
export type FieldDef_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FieldDef_Stddev_Samp_Fields = {
  __typename?: 'FieldDef_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FieldDef" */
export type FieldDef_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FieldDef" */
export type FieldDef_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FieldDef_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FieldDef_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  key_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  picklist_id?: InputMaybe<Scalars['uuid']>;
  type_def_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type FieldDef_Sum_Fields = {
  __typename?: 'FieldDef_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FieldDef" */
export type FieldDef_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "FieldDef" */
export enum FieldDef_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionSpec = 'interaction_spec',
  /** column name */
  KeyName = 'key_name',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OverlayDefId = 'overlay_def_id',
  /** column name */
  PicklistId = 'picklist_id',
  /** column name */
  TypeDefId = 'type_def_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FieldDef_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<FieldDef_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<FieldDef_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<FieldDef_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<FieldDef_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FieldDef_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<FieldDef_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FieldDef_Set_Input>;
  /** filter the rows which have to be updated */
  where: FieldDef_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FieldDef_Var_Pop_Fields = {
  __typename?: 'FieldDef_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FieldDef" */
export type FieldDef_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FieldDef_Var_Samp_Fields = {
  __typename?: 'FieldDef_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FieldDef" */
export type FieldDef_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FieldDef_Variance_Fields = {
  __typename?: 'FieldDef_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FieldDef" */
export type FieldDef_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Stores the value of a specific picklist field */
export type FieldPicklistValues = {
  __typename?: 'FieldPicklistValues';
  /** An object relationship */
  Contract?: Maybe<Contracts>;
  /** An object relationship */
  Field: Field;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An object relationship */
  PicklistValue?: Maybe<PicklistValues>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  field_id: Scalars['uuid'];
  id: Scalars['uuid'];
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  picklist_value_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "FieldPicklistValues" */
export type FieldPicklistValues_Aggregate = {
  __typename?: 'FieldPicklistValues_aggregate';
  aggregate?: Maybe<FieldPicklistValues_Aggregate_Fields>;
  nodes: Array<FieldPicklistValues>;
};

export type FieldPicklistValues_Aggregate_Bool_Exp = {
  count?: InputMaybe<FieldPicklistValues_Aggregate_Bool_Exp_Count>;
};

export type FieldPicklistValues_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FieldPicklistValues_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "FieldPicklistValues" */
export type FieldPicklistValues_Aggregate_Fields = {
  __typename?: 'FieldPicklistValues_aggregate_fields';
  avg?: Maybe<FieldPicklistValues_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FieldPicklistValues_Max_Fields>;
  min?: Maybe<FieldPicklistValues_Min_Fields>;
  stddev?: Maybe<FieldPicklistValues_Stddev_Fields>;
  stddev_pop?: Maybe<FieldPicklistValues_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FieldPicklistValues_Stddev_Samp_Fields>;
  sum?: Maybe<FieldPicklistValues_Sum_Fields>;
  var_pop?: Maybe<FieldPicklistValues_Var_Pop_Fields>;
  var_samp?: Maybe<FieldPicklistValues_Var_Samp_Fields>;
  variance?: Maybe<FieldPicklistValues_Variance_Fields>;
};


/** aggregate fields of "FieldPicklistValues" */
export type FieldPicklistValues_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FieldPicklistValues" */
export type FieldPicklistValues_Aggregate_Order_By = {
  avg?: InputMaybe<FieldPicklistValues_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FieldPicklistValues_Max_Order_By>;
  min?: InputMaybe<FieldPicklistValues_Min_Order_By>;
  stddev?: InputMaybe<FieldPicklistValues_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FieldPicklistValues_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FieldPicklistValues_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FieldPicklistValues_Sum_Order_By>;
  var_pop?: InputMaybe<FieldPicklistValues_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FieldPicklistValues_Var_Samp_Order_By>;
  variance?: InputMaybe<FieldPicklistValues_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FieldPicklistValues" */
export type FieldPicklistValues_Arr_Rel_Insert_Input = {
  data: Array<FieldPicklistValues_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FieldPicklistValues_On_Conflict>;
};

/** aggregate avg on columns */
export type FieldPicklistValues_Avg_Fields = {
  __typename?: 'FieldPicklistValues_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FieldPicklistValues". All fields are combined with a logical 'AND'. */
export type FieldPicklistValues_Bool_Exp = {
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Field?: InputMaybe<Field_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  PicklistValue?: InputMaybe<PicklistValues_Bool_Exp>;
  _and?: InputMaybe<Array<FieldPicklistValues_Bool_Exp>>;
  _not?: InputMaybe<FieldPicklistValues_Bool_Exp>;
  _or?: InputMaybe<Array<FieldPicklistValues_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  picklist_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "FieldPicklistValues" */
export enum FieldPicklistValues_Constraint {
  /** unique or primary key constraint on columns "id" */
  FieldPicklistValuesPkey = 'FieldPicklistValues_pkey'
}

/** input type for incrementing numeric columns in table "FieldPicklistValues" */
export type FieldPicklistValues_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "FieldPicklistValues" */
export type FieldPicklistValues_Insert_Input = {
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Field?: InputMaybe<Field_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  PicklistValue?: InputMaybe<PicklistValues_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  field_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  picklist_value_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FieldPicklistValues_Max_Fields = {
  __typename?: 'FieldPicklistValues_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  picklist_value_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  picklist_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FieldPicklistValues_Min_Fields = {
  __typename?: 'FieldPicklistValues_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  picklist_value_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  picklist_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FieldPicklistValues" */
export type FieldPicklistValues_Mutation_Response = {
  __typename?: 'FieldPicklistValues_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FieldPicklistValues>;
};

/** on_conflict condition type for table "FieldPicklistValues" */
export type FieldPicklistValues_On_Conflict = {
  constraint: FieldPicklistValues_Constraint;
  update_columns?: Array<FieldPicklistValues_Update_Column>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};

/** Ordering options when selecting data from "FieldPicklistValues". */
export type FieldPicklistValues_Order_By = {
  Contract?: InputMaybe<Contracts_Order_By>;
  Field?: InputMaybe<Field_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  PicklistValue?: InputMaybe<PicklistValues_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  picklist_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FieldPicklistValues */
export type FieldPicklistValues_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "FieldPicklistValues" */
export enum FieldPicklistValues_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  PicklistValueId = 'picklist_value_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "FieldPicklistValues" */
export type FieldPicklistValues_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  field_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  picklist_value_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type FieldPicklistValues_Stddev_Fields = {
  __typename?: 'FieldPicklistValues_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FieldPicklistValues_Stddev_Pop_Fields = {
  __typename?: 'FieldPicklistValues_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FieldPicklistValues_Stddev_Samp_Fields = {
  __typename?: 'FieldPicklistValues_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FieldPicklistValues" */
export type FieldPicklistValues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FieldPicklistValues_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FieldPicklistValues_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  field_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  picklist_value_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type FieldPicklistValues_Sum_Fields = {
  __typename?: 'FieldPicklistValues_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** update columns of table "FieldPicklistValues" */
export enum FieldPicklistValues_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldId = 'field_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  PicklistValueId = 'picklist_value_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FieldPicklistValues_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FieldPicklistValues_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FieldPicklistValues_Set_Input>;
  /** filter the rows which have to be updated */
  where: FieldPicklistValues_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FieldPicklistValues_Var_Pop_Fields = {
  __typename?: 'FieldPicklistValues_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FieldPicklistValues_Var_Samp_Fields = {
  __typename?: 'FieldPicklistValues_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FieldPicklistValues_Variance_Fields = {
  __typename?: 'FieldPicklistValues_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FieldPicklistValues" */
export type FieldPicklistValues_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "FieldTypeDef" */
export type FieldTypeDef = {
  __typename?: 'FieldTypeDef';
  /** An array relationship */
  FieldDefs: Array<FieldDef>;
  /** An aggregate relationship */
  FieldDefs_aggregate: FieldDef_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  sub_type?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "FieldTypeDef" */
export type FieldTypeDefFieldDefsArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


/** columns and relationships of "FieldTypeDef" */
export type FieldTypeDefFieldDefs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};

/** aggregated selection of "FieldTypeDef" */
export type FieldTypeDef_Aggregate = {
  __typename?: 'FieldTypeDef_aggregate';
  aggregate?: Maybe<FieldTypeDef_Aggregate_Fields>;
  nodes: Array<FieldTypeDef>;
};

/** aggregate fields of "FieldTypeDef" */
export type FieldTypeDef_Aggregate_Fields = {
  __typename?: 'FieldTypeDef_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FieldTypeDef_Max_Fields>;
  min?: Maybe<FieldTypeDef_Min_Fields>;
};


/** aggregate fields of "FieldTypeDef" */
export type FieldTypeDef_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FieldTypeDef_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "FieldTypeDef". All fields are combined with a logical 'AND'. */
export type FieldTypeDef_Bool_Exp = {
  FieldDefs?: InputMaybe<FieldDef_Bool_Exp>;
  FieldDefs_aggregate?: InputMaybe<FieldDef_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<FieldTypeDef_Bool_Exp>>;
  _not?: InputMaybe<FieldTypeDef_Bool_Exp>;
  _or?: InputMaybe<Array<FieldTypeDef_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sub_type?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "FieldTypeDef" */
export enum FieldTypeDef_Constraint {
  /** unique or primary key constraint on columns "id" */
  FieldTypeDefPkey = 'FieldTypeDef_pkey',
  /** unique or primary key constraint on columns "type", "sub_type" */
  FieldTypeDefTypeSubTypeKey = 'FieldTypeDef_type_sub_type_key'
}

/** input type for inserting data into table "FieldTypeDef" */
export type FieldTypeDef_Insert_Input = {
  FieldDefs?: InputMaybe<FieldDef_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sub_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FieldTypeDef_Max_Fields = {
  __typename?: 'FieldTypeDef_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sub_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type FieldTypeDef_Min_Fields = {
  __typename?: 'FieldTypeDef_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sub_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "FieldTypeDef" */
export type FieldTypeDef_Mutation_Response = {
  __typename?: 'FieldTypeDef_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FieldTypeDef>;
};

/** input type for inserting object relation for remote table "FieldTypeDef" */
export type FieldTypeDef_Obj_Rel_Insert_Input = {
  data: FieldTypeDef_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<FieldTypeDef_On_Conflict>;
};

/** on_conflict condition type for table "FieldTypeDef" */
export type FieldTypeDef_On_Conflict = {
  constraint: FieldTypeDef_Constraint;
  update_columns?: Array<FieldTypeDef_Update_Column>;
  where?: InputMaybe<FieldTypeDef_Bool_Exp>;
};

/** Ordering options when selecting data from "FieldTypeDef". */
export type FieldTypeDef_Order_By = {
  FieldDefs_aggregate?: InputMaybe<FieldDef_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FieldTypeDef */
export type FieldTypeDef_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "FieldTypeDef" */
export enum FieldTypeDef_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "FieldTypeDef" */
export type FieldTypeDef_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sub_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "FieldTypeDef" */
export type FieldTypeDef_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FieldTypeDef_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FieldTypeDef_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sub_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "FieldTypeDef" */
export enum FieldTypeDef_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FieldTypeDef_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FieldTypeDef_Set_Input>;
  /** filter the rows which have to be updated */
  where: FieldTypeDef_Bool_Exp;
};

/** aggregated selection of "Field" */
export type Field_Aggregate = {
  __typename?: 'Field_aggregate';
  aggregate?: Maybe<Field_Aggregate_Fields>;
  nodes: Array<Field>;
};

export type Field_Aggregate_Bool_Exp = {
  count?: InputMaybe<Field_Aggregate_Bool_Exp_Count>;
};

export type Field_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Field_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Field" */
export type Field_Aggregate_Fields = {
  __typename?: 'Field_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Field_Max_Fields>;
  min?: Maybe<Field_Min_Fields>;
};


/** aggregate fields of "Field" */
export type Field_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Field" */
export type Field_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Max_Order_By>;
  min?: InputMaybe<Field_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Field" */
export type Field_Arr_Rel_Insert_Input = {
  data: Array<Field_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Field". All fields are combined with a logical 'AND'. */
export type Field_Bool_Exp = {
  FieldDef?: InputMaybe<FieldDef_Bool_Exp>;
  FieldPicklistValues?: InputMaybe<FieldPicklistValues_Bool_Exp>;
  FieldPicklistValues_aggregate?: InputMaybe<FieldPicklistValues_Aggregate_Bool_Exp>;
  Overlay?: InputMaybe<Overlay_Bool_Exp>;
  _and?: InputMaybe<Array<Field_Bool_Exp>>;
  _not?: InputMaybe<Field_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_def_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  overlay_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Field" */
export enum Field_Constraint {
  /** unique or primary key constraint on columns "field_def_id", "overlay_id" */
  FieldOverlayIdFieldDefIdKey = 'Field_overlay_id_field_def_id_key',
  /** unique or primary key constraint on columns "id" */
  FieldPkey = 'Field_pkey'
}

/** input type for inserting data into table "Field" */
export type Field_Insert_Input = {
  FieldDef?: InputMaybe<FieldDef_Obj_Rel_Insert_Input>;
  FieldPicklistValues?: InputMaybe<FieldPicklistValues_Arr_Rel_Insert_Input>;
  Overlay?: InputMaybe<Overlay_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  field_def_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Field_Max_Fields = {
  __typename?: 'Field_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  field_def_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  overlay_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Field" */
export type Field_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_def_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Min_Fields = {
  __typename?: 'Field_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  field_def_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  overlay_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Field" */
export type Field_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_def_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Field" */
export type Field_Mutation_Response = {
  __typename?: 'Field_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Field>;
};

/** input type for inserting object relation for remote table "Field" */
export type Field_Obj_Rel_Insert_Input = {
  data: Field_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_On_Conflict>;
};

/** on_conflict condition type for table "Field" */
export type Field_On_Conflict = {
  constraint: Field_Constraint;
  update_columns?: Array<Field_Update_Column>;
  where?: InputMaybe<Field_Bool_Exp>;
};

/** Ordering options when selecting data from "Field". */
export type Field_Order_By = {
  FieldDef?: InputMaybe<FieldDef_Order_By>;
  FieldPicklistValues_aggregate?: InputMaybe<FieldPicklistValues_Aggregate_Order_By>;
  Overlay?: InputMaybe<Overlay_Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_def_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Field */
export type Field_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Field" */
export enum Field_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldDefId = 'field_def_id',
  /** column name */
  Id = 'id',
  /** column name */
  OverlayId = 'overlay_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Field" */
export type Field_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  field_def_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Field" */
export type Field_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  field_def_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Field" */
export enum Field_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldDefId = 'field_def_id',
  /** column name */
  Id = 'id',
  /** column name */
  OverlayId = 'overlay_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Field_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Bool_Exp;
};

/** columns and relationships of "FileTags" */
export type FileTags = {
  __typename?: 'FileTags';
  /** An object relationship */
  File: Files;
  /** An object relationship */
  Tag: Tags;
  created_at: Scalars['timestamptz'];
  file_id: Scalars['Int'];
  tag_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "FileTags" */
export type FileTags_Aggregate = {
  __typename?: 'FileTags_aggregate';
  aggregate?: Maybe<FileTags_Aggregate_Fields>;
  nodes: Array<FileTags>;
};

export type FileTags_Aggregate_Bool_Exp = {
  count?: InputMaybe<FileTags_Aggregate_Bool_Exp_Count>;
};

export type FileTags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FileTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FileTags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "FileTags" */
export type FileTags_Aggregate_Fields = {
  __typename?: 'FileTags_aggregate_fields';
  avg?: Maybe<FileTags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FileTags_Max_Fields>;
  min?: Maybe<FileTags_Min_Fields>;
  stddev?: Maybe<FileTags_Stddev_Fields>;
  stddev_pop?: Maybe<FileTags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FileTags_Stddev_Samp_Fields>;
  sum?: Maybe<FileTags_Sum_Fields>;
  var_pop?: Maybe<FileTags_Var_Pop_Fields>;
  var_samp?: Maybe<FileTags_Var_Samp_Fields>;
  variance?: Maybe<FileTags_Variance_Fields>;
};


/** aggregate fields of "FileTags" */
export type FileTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FileTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FileTags" */
export type FileTags_Aggregate_Order_By = {
  avg?: InputMaybe<FileTags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FileTags_Max_Order_By>;
  min?: InputMaybe<FileTags_Min_Order_By>;
  stddev?: InputMaybe<FileTags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FileTags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FileTags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FileTags_Sum_Order_By>;
  var_pop?: InputMaybe<FileTags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FileTags_Var_Samp_Order_By>;
  variance?: InputMaybe<FileTags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FileTags" */
export type FileTags_Arr_Rel_Insert_Input = {
  data: Array<FileTags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FileTags_On_Conflict>;
};

/** aggregate avg on columns */
export type FileTags_Avg_Fields = {
  __typename?: 'FileTags_avg_fields';
  file_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FileTags" */
export type FileTags_Avg_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FileTags". All fields are combined with a logical 'AND'. */
export type FileTags_Bool_Exp = {
  File?: InputMaybe<Files_Bool_Exp>;
  Tag?: InputMaybe<Tags_Bool_Exp>;
  _and?: InputMaybe<Array<FileTags_Bool_Exp>>;
  _not?: InputMaybe<FileTags_Bool_Exp>;
  _or?: InputMaybe<Array<FileTags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Int_Comparison_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "FileTags" */
export enum FileTags_Constraint {
  /** unique or primary key constraint on columns "file_id", "tag_id" */
  FileTagsPkey = 'FileTags_pkey'
}

/** input type for incrementing numeric columns in table "FileTags" */
export type FileTags_Inc_Input = {
  file_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "FileTags" */
export type FileTags_Insert_Input = {
  File?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  Tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FileTags_Max_Fields = {
  __typename?: 'FileTags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "FileTags" */
export type FileTags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FileTags_Min_Fields = {
  __typename?: 'FileTags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "FileTags" */
export type FileTags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FileTags" */
export type FileTags_Mutation_Response = {
  __typename?: 'FileTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FileTags>;
};

/** on_conflict condition type for table "FileTags" */
export type FileTags_On_Conflict = {
  constraint: FileTags_Constraint;
  update_columns?: Array<FileTags_Update_Column>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};

/** Ordering options when selecting data from "FileTags". */
export type FileTags_Order_By = {
  File?: InputMaybe<Files_Order_By>;
  Tag?: InputMaybe<Tags_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FileTags */
export type FileTags_Pk_Columns_Input = {
  file_id: Scalars['Int'];
  tag_id: Scalars['uuid'];
};

/** select columns of table "FileTags" */
export enum FileTags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "FileTags" */
export type FileTags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type FileTags_Stddev_Fields = {
  __typename?: 'FileTags_stddev_fields';
  file_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FileTags" */
export type FileTags_Stddev_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FileTags_Stddev_Pop_Fields = {
  __typename?: 'FileTags_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FileTags" */
export type FileTags_Stddev_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FileTags_Stddev_Samp_Fields = {
  __typename?: 'FileTags_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FileTags" */
export type FileTags_Stddev_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FileTags" */
export type FileTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FileTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FileTags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type FileTags_Sum_Fields = {
  __typename?: 'FileTags_sum_fields';
  file_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FileTags" */
export type FileTags_Sum_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** update columns of table "FileTags" */
export enum FileTags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FileTags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FileTags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FileTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: FileTags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FileTags_Var_Pop_Fields = {
  __typename?: 'FileTags_var_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FileTags" */
export type FileTags_Var_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FileTags_Var_Samp_Fields = {
  __typename?: 'FileTags_var_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FileTags" */
export type FileTags_Var_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FileTags_Variance_Fields = {
  __typename?: 'FileTags_variance_fields';
  file_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FileTags" */
export type FileTags_Variance_Order_By = {
  file_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Files" */
export type Files = {
  __typename?: 'Files';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  ContractFile?: Maybe<ContractFiles>;
  /** An object relationship */
  CreatedBy?: Maybe<Identities>;
  /** An array relationship */
  FileTags: Array<FileTags>;
  /** An aggregate relationship */
  FileTags_aggregate: FileTags_Aggregate;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  JobFile?: Maybe<JobFiles>;
  /** An array relationship */
  ReviewGroupFiles: Array<ReviewGroupFiles>;
  /** An aggregate relationship */
  ReviewGroupFiles_aggregate: ReviewGroupFiles_Aggregate;
  /** An object relationship */
  UpdatedBy?: Maybe<Identities>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  /** A computed field which executes the "decode_filename" function, decoding the base64 filename field. */
  decoded_filename?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Files" */
export type FilesFileTagsArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


/** columns and relationships of "Files" */
export type FilesFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


/** columns and relationships of "Files" */
export type FilesReviewGroupFilesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


/** columns and relationships of "Files" */
export type FilesReviewGroupFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


/** columns and relationships of "Files" */
export type FilesEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Files" */
export type Files_Aggregate = {
  __typename?: 'Files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Bool_And = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Files" */
export type Files_Aggregate_Fields = {
  __typename?: 'Files_aggregate_fields';
  avg?: Maybe<Files_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
  stddev?: Maybe<Files_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Sum_Fields>;
  var_pop?: Maybe<Files_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Var_Samp_Fields>;
  variance?: Maybe<Files_Variance_Fields>;
};


/** aggregate fields of "Files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Files" */
export type Files_Aggregate_Order_By = {
  avg?: InputMaybe<Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
  stddev?: InputMaybe<Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Files_Sum_Order_By>;
  var_pop?: InputMaybe<Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Files_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Files_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Avg_Fields = {
  __typename?: 'Files_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Files" */
export type Files_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  ContractFile?: InputMaybe<ContractFiles_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  FileTags?: InputMaybe<FileTags_Bool_Exp>;
  FileTags_aggregate?: InputMaybe<FileTags_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  JobFile?: InputMaybe<JobFiles_Bool_Exp>;
  ReviewGroupFiles?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
  ReviewGroupFiles_aggregate?: InputMaybe<ReviewGroupFiles_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  decoded_filename?: InputMaybe<String_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  etl_source_entity?: InputMaybe<String_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  resume?: InputMaybe<Boolean_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "filename", "company_id", "etl_id_token" */
  FilesCompanyIdEtlIdTokenFilenameKey = 'Files_company_id_etl_id_token_filename_key',
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'Files_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Files_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Files_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Files_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Files" */
export type Files_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Files" */
export type Files_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  ContractFile?: InputMaybe<ContractFiles_Obj_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  FileTags?: InputMaybe<FileTags_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  JobFile?: InputMaybe<JobFiles_Obj_Rel_Insert_Input>;
  ReviewGroupFiles?: InputMaybe<ReviewGroupFiles_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'Files_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  /** A computed field which executes the "decode_filename" function, decoding the base64 filename field. */
  decoded_filename?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Files" */
export type Files_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'Files_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  /** A computed field which executes the "decode_filename" function, decoding the base64 filename field. */
  decoded_filename?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Files" */
export type Files_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Files" */
export type Files_Mutation_Response = {
  __typename?: 'Files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "Files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "Files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "Files". */
export type Files_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  ContractFile?: InputMaybe<ContractFiles_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  FileTags_aggregate?: InputMaybe<FileTags_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  JobFile?: InputMaybe<JobFiles_Order_By>;
  ReviewGroupFiles_aggregate?: InputMaybe<ReviewGroupFiles_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  decoded_filename?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Files */
export type Files_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Files_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Files" */
export enum Files_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Key = 'key',
  /** column name */
  Resume = 'resume',
  /** column name */
  Size = 'size',
  /** column name */
  Tag = 'tag',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "Files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Resume = 'resume'
}

/** select "Files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Resume = 'resume'
}

/** input type for updating data in table "Files" */
export type Files_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Files_Stddev_Fields = {
  __typename?: 'Files_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Files" */
export type Files_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Stddev_Pop_Fields = {
  __typename?: 'Files_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Files" */
export type Files_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Stddev_Samp_Fields = {
  __typename?: 'Files_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Files" */
export type Files_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Files_Sum_Fields = {
  __typename?: 'Files_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Files" */
export type Files_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Files" */
export enum Files_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Key = 'key',
  /** column name */
  Resume = 'resume',
  /** column name */
  Size = 'size',
  /** column name */
  Tag = 'tag',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Files_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Files_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Files_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Files_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Var_Pop_Fields = {
  __typename?: 'Files_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Files" */
export type Files_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Var_Samp_Fields = {
  __typename?: 'Files_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Files" */
export type Files_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Variance_Fields = {
  __typename?: 'Files_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Files" */
export type Files_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "FormInputs" */
export type FormInputs = {
  __typename?: 'FormInputs';
  /** An array relationship */
  CalculationVersionFormInputs: Array<CalculationVersionFormInputs>;
  /** An aggregate relationship */
  CalculationVersionFormInputs_aggregate: CalculationVersionFormInputs_Aggregate;
  /** An array relationship */
  FormSelectOptions: Array<FormSelectOptions>;
  /** An aggregate relationship */
  FormSelectOptions_aggregate: FormSelectOptions_Aggregate;
  /** An object relationship */
  InputType: InputTypes;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  input_type: InputTypes_Enum;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "FormInputs" */
export type FormInputsCalculationVersionFormInputsArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


/** columns and relationships of "FormInputs" */
export type FormInputsCalculationVersionFormInputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


/** columns and relationships of "FormInputs" */
export type FormInputsFormSelectOptionsArgs = {
  distinct_on?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormSelectOptions_Order_By>>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};


/** columns and relationships of "FormInputs" */
export type FormInputsFormSelectOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormSelectOptions_Order_By>>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};

/** aggregated selection of "FormInputs" */
export type FormInputs_Aggregate = {
  __typename?: 'FormInputs_aggregate';
  aggregate?: Maybe<FormInputs_Aggregate_Fields>;
  nodes: Array<FormInputs>;
};

export type FormInputs_Aggregate_Bool_Exp = {
  count?: InputMaybe<FormInputs_Aggregate_Bool_Exp_Count>;
};

export type FormInputs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FormInputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormInputs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "FormInputs" */
export type FormInputs_Aggregate_Fields = {
  __typename?: 'FormInputs_aggregate_fields';
  avg?: Maybe<FormInputs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FormInputs_Max_Fields>;
  min?: Maybe<FormInputs_Min_Fields>;
  stddev?: Maybe<FormInputs_Stddev_Fields>;
  stddev_pop?: Maybe<FormInputs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FormInputs_Stddev_Samp_Fields>;
  sum?: Maybe<FormInputs_Sum_Fields>;
  var_pop?: Maybe<FormInputs_Var_Pop_Fields>;
  var_samp?: Maybe<FormInputs_Var_Samp_Fields>;
  variance?: Maybe<FormInputs_Variance_Fields>;
};


/** aggregate fields of "FormInputs" */
export type FormInputs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FormInputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FormInputs" */
export type FormInputs_Aggregate_Order_By = {
  avg?: InputMaybe<FormInputs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FormInputs_Max_Order_By>;
  min?: InputMaybe<FormInputs_Min_Order_By>;
  stddev?: InputMaybe<FormInputs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FormInputs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FormInputs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FormInputs_Sum_Order_By>;
  var_pop?: InputMaybe<FormInputs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FormInputs_Var_Samp_Order_By>;
  variance?: InputMaybe<FormInputs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FormInputs" */
export type FormInputs_Arr_Rel_Insert_Input = {
  data: Array<FormInputs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FormInputs_On_Conflict>;
};

/** aggregate avg on columns */
export type FormInputs_Avg_Fields = {
  __typename?: 'FormInputs_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FormInputs" */
export type FormInputs_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FormInputs". All fields are combined with a logical 'AND'. */
export type FormInputs_Bool_Exp = {
  CalculationVersionFormInputs?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
  CalculationVersionFormInputs_aggregate?: InputMaybe<CalculationVersionFormInputs_Aggregate_Bool_Exp>;
  FormSelectOptions?: InputMaybe<FormSelectOptions_Bool_Exp>;
  FormSelectOptions_aggregate?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp>;
  InputType?: InputMaybe<InputTypes_Bool_Exp>;
  _and?: InputMaybe<Array<FormInputs_Bool_Exp>>;
  _not?: InputMaybe<FormInputs_Bool_Exp>;
  _or?: InputMaybe<Array<FormInputs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  input_type?: InputMaybe<InputTypes_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "FormInputs" */
export enum FormInputs_Constraint {
  /** unique or primary key constraint on columns "input_type", "name", "path" */
  FormInputsNamePathInputTypeKey = 'FormInputs_name_path_input_type_key',
  /** unique or primary key constraint on columns "id" */
  InputsPkey = 'Inputs_pkey'
}

/** input type for incrementing numeric columns in table "FormInputs" */
export type FormInputs_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "FormInputs" */
export type FormInputs_Insert_Input = {
  CalculationVersionFormInputs?: InputMaybe<CalculationVersionFormInputs_Arr_Rel_Insert_Input>;
  FormSelectOptions?: InputMaybe<FormSelectOptions_Arr_Rel_Insert_Input>;
  InputType?: InputMaybe<InputTypes_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  input_type?: InputMaybe<InputTypes_Enum>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FormInputs_Max_Fields = {
  __typename?: 'FormInputs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "FormInputs" */
export type FormInputs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FormInputs_Min_Fields = {
  __typename?: 'FormInputs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "FormInputs" */
export type FormInputs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FormInputs" */
export type FormInputs_Mutation_Response = {
  __typename?: 'FormInputs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FormInputs>;
};

/** input type for inserting object relation for remote table "FormInputs" */
export type FormInputs_Obj_Rel_Insert_Input = {
  data: FormInputs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<FormInputs_On_Conflict>;
};

/** on_conflict condition type for table "FormInputs" */
export type FormInputs_On_Conflict = {
  constraint: FormInputs_Constraint;
  update_columns?: Array<FormInputs_Update_Column>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};

/** Ordering options when selecting data from "FormInputs". */
export type FormInputs_Order_By = {
  CalculationVersionFormInputs_aggregate?: InputMaybe<CalculationVersionFormInputs_Aggregate_Order_By>;
  FormSelectOptions_aggregate?: InputMaybe<FormSelectOptions_Aggregate_Order_By>;
  InputType?: InputMaybe<InputTypes_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  input_type?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FormInputs */
export type FormInputs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "FormInputs" */
export enum FormInputs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InputType = 'input_type',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "FormInputs" */
export type FormInputs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  input_type?: InputMaybe<InputTypes_Enum>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type FormInputs_Stddev_Fields = {
  __typename?: 'FormInputs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FormInputs" */
export type FormInputs_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FormInputs_Stddev_Pop_Fields = {
  __typename?: 'FormInputs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FormInputs" */
export type FormInputs_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FormInputs_Stddev_Samp_Fields = {
  __typename?: 'FormInputs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FormInputs" */
export type FormInputs_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FormInputs" */
export type FormInputs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FormInputs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FormInputs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  input_type?: InputMaybe<InputTypes_Enum>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type FormInputs_Sum_Fields = {
  __typename?: 'FormInputs_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FormInputs" */
export type FormInputs_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "FormInputs" */
export enum FormInputs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InputType = 'input_type',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FormInputs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormInputs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormInputs_Set_Input>;
  /** filter the rows which have to be updated */
  where: FormInputs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FormInputs_Var_Pop_Fields = {
  __typename?: 'FormInputs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FormInputs" */
export type FormInputs_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FormInputs_Var_Samp_Fields = {
  __typename?: 'FormInputs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FormInputs" */
export type FormInputs_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FormInputs_Variance_Fields = {
  __typename?: 'FormInputs_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FormInputs" */
export type FormInputs_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "FormSelectOptions" */
export type FormSelectOptions = {
  __typename?: 'FormSelectOptions';
  /** An object relationship */
  FormInput: FormInputs;
  form_input_id: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  value: Scalars['float8'];
};

/** aggregated selection of "FormSelectOptions" */
export type FormSelectOptions_Aggregate = {
  __typename?: 'FormSelectOptions_aggregate';
  aggregate?: Maybe<FormSelectOptions_Aggregate_Fields>;
  nodes: Array<FormSelectOptions>;
};

export type FormSelectOptions_Aggregate_Bool_Exp = {
  avg?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<FormSelectOptions_Aggregate_Bool_Exp_Var_Samp>;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Avg = {
  arguments: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Corr = {
  arguments: FormSelectOptions_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Corr_Arguments = {
  X: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: FormSelectOptions_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Max = {
  arguments: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Min = {
  arguments: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Sum = {
  arguments: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type FormSelectOptions_Aggregate_Bool_Exp_Var_Samp = {
  arguments: FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormSelectOptions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "FormSelectOptions" */
export type FormSelectOptions_Aggregate_Fields = {
  __typename?: 'FormSelectOptions_aggregate_fields';
  avg?: Maybe<FormSelectOptions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FormSelectOptions_Max_Fields>;
  min?: Maybe<FormSelectOptions_Min_Fields>;
  stddev?: Maybe<FormSelectOptions_Stddev_Fields>;
  stddev_pop?: Maybe<FormSelectOptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FormSelectOptions_Stddev_Samp_Fields>;
  sum?: Maybe<FormSelectOptions_Sum_Fields>;
  var_pop?: Maybe<FormSelectOptions_Var_Pop_Fields>;
  var_samp?: Maybe<FormSelectOptions_Var_Samp_Fields>;
  variance?: Maybe<FormSelectOptions_Variance_Fields>;
};


/** aggregate fields of "FormSelectOptions" */
export type FormSelectOptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FormSelectOptions" */
export type FormSelectOptions_Aggregate_Order_By = {
  avg?: InputMaybe<FormSelectOptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FormSelectOptions_Max_Order_By>;
  min?: InputMaybe<FormSelectOptions_Min_Order_By>;
  stddev?: InputMaybe<FormSelectOptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FormSelectOptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FormSelectOptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FormSelectOptions_Sum_Order_By>;
  var_pop?: InputMaybe<FormSelectOptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FormSelectOptions_Var_Samp_Order_By>;
  variance?: InputMaybe<FormSelectOptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FormSelectOptions" */
export type FormSelectOptions_Arr_Rel_Insert_Input = {
  data: Array<FormSelectOptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FormSelectOptions_On_Conflict>;
};

/** aggregate avg on columns */
export type FormSelectOptions_Avg_Fields = {
  __typename?: 'FormSelectOptions_avg_fields';
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Avg_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FormSelectOptions". All fields are combined with a logical 'AND'. */
export type FormSelectOptions_Bool_Exp = {
  FormInput?: InputMaybe<FormInputs_Bool_Exp>;
  _and?: InputMaybe<Array<FormSelectOptions_Bool_Exp>>;
  _not?: InputMaybe<FormSelectOptions_Bool_Exp>;
  _or?: InputMaybe<Array<FormSelectOptions_Bool_Exp>>;
  form_input_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "FormSelectOptions" */
export enum FormSelectOptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormSelectOptionsPkey = 'FormSelectOptions_pkey'
}

/** input type for incrementing numeric columns in table "FormSelectOptions" */
export type FormSelectOptions_Inc_Input = {
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "FormSelectOptions" */
export type FormSelectOptions_Insert_Input = {
  FormInput?: InputMaybe<FormInputs_Obj_Rel_Insert_Input>;
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type FormSelectOptions_Max_Fields = {
  __typename?: 'FormSelectOptions_max_fields';
  form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Max_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FormSelectOptions_Min_Fields = {
  __typename?: 'FormSelectOptions_min_fields';
  form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Min_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FormSelectOptions" */
export type FormSelectOptions_Mutation_Response = {
  __typename?: 'FormSelectOptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FormSelectOptions>;
};

/** on_conflict condition type for table "FormSelectOptions" */
export type FormSelectOptions_On_Conflict = {
  constraint: FormSelectOptions_Constraint;
  update_columns?: Array<FormSelectOptions_Update_Column>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};

/** Ordering options when selecting data from "FormSelectOptions". */
export type FormSelectOptions_Order_By = {
  FormInput?: InputMaybe<FormInputs_Order_By>;
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FormSelectOptions */
export type FormSelectOptions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column {
  /** column name */
  FormInputId = 'form_input_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_avg_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_corr_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_max_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_min_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_sum_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "FormSelectOptions_aggregate_bool_exp_var_samp_arguments_columns" columns of table "FormSelectOptions" */
export enum FormSelectOptions_Select_Column_FormSelectOptions_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "FormSelectOptions" */
export type FormSelectOptions_Set_Input = {
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type FormSelectOptions_Stddev_Fields = {
  __typename?: 'FormSelectOptions_stddev_fields';
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Stddev_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FormSelectOptions_Stddev_Pop_Fields = {
  __typename?: 'FormSelectOptions_stddev_pop_fields';
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Stddev_Pop_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FormSelectOptions_Stddev_Samp_Fields = {
  __typename?: 'FormSelectOptions_stddev_samp_fields';
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Stddev_Samp_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FormSelectOptions" */
export type FormSelectOptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FormSelectOptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FormSelectOptions_Stream_Cursor_Value_Input = {
  form_input_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type FormSelectOptions_Sum_Fields = {
  __typename?: 'FormSelectOptions_sum_fields';
  form_input_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Sum_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "FormSelectOptions" */
export enum FormSelectOptions_Update_Column {
  /** column name */
  FormInputId = 'form_input_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Value = 'value'
}

export type FormSelectOptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormSelectOptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormSelectOptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: FormSelectOptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FormSelectOptions_Var_Pop_Fields = {
  __typename?: 'FormSelectOptions_var_pop_fields';
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Var_Pop_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FormSelectOptions_Var_Samp_Fields = {
  __typename?: 'FormSelectOptions_var_samp_fields';
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Var_Samp_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FormSelectOptions_Variance_Fields = {
  __typename?: 'FormSelectOptions_variance_fields';
  form_input_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FormSelectOptions" */
export type FormSelectOptions_Variance_Order_By = {
  form_input_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "FormulaOptions" */
export type FormulaOptions = {
  __typename?: 'FormulaOptions';
  /** An array relationship */
  EquationFormulaOptions: Array<EquationFormulaOption>;
  /** An aggregate relationship */
  EquationFormulaOptions_aggregate: EquationFormulaOption_Aggregate;
  /** An object relationship */
  RangeValue?: Maybe<RangeValues>;
  id: Scalars['Int'];
  name: Scalars['String'];
  range_value_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "FormulaOptions" */
export type FormulaOptionsEquationFormulaOptionsArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


/** columns and relationships of "FormulaOptions" */
export type FormulaOptionsEquationFormulaOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};

/** aggregated selection of "FormulaOptions" */
export type FormulaOptions_Aggregate = {
  __typename?: 'FormulaOptions_aggregate';
  aggregate?: Maybe<FormulaOptions_Aggregate_Fields>;
  nodes: Array<FormulaOptions>;
};

export type FormulaOptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<FormulaOptions_Aggregate_Bool_Exp_Count>;
};

export type FormulaOptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FormulaOptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "FormulaOptions" */
export type FormulaOptions_Aggregate_Fields = {
  __typename?: 'FormulaOptions_aggregate_fields';
  avg?: Maybe<FormulaOptions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FormulaOptions_Max_Fields>;
  min?: Maybe<FormulaOptions_Min_Fields>;
  stddev?: Maybe<FormulaOptions_Stddev_Fields>;
  stddev_pop?: Maybe<FormulaOptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FormulaOptions_Stddev_Samp_Fields>;
  sum?: Maybe<FormulaOptions_Sum_Fields>;
  var_pop?: Maybe<FormulaOptions_Var_Pop_Fields>;
  var_samp?: Maybe<FormulaOptions_Var_Samp_Fields>;
  variance?: Maybe<FormulaOptions_Variance_Fields>;
};


/** aggregate fields of "FormulaOptions" */
export type FormulaOptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FormulaOptions" */
export type FormulaOptions_Aggregate_Order_By = {
  avg?: InputMaybe<FormulaOptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FormulaOptions_Max_Order_By>;
  min?: InputMaybe<FormulaOptions_Min_Order_By>;
  stddev?: InputMaybe<FormulaOptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FormulaOptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FormulaOptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FormulaOptions_Sum_Order_By>;
  var_pop?: InputMaybe<FormulaOptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FormulaOptions_Var_Samp_Order_By>;
  variance?: InputMaybe<FormulaOptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FormulaOptions" */
export type FormulaOptions_Arr_Rel_Insert_Input = {
  data: Array<FormulaOptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FormulaOptions_On_Conflict>;
};

/** aggregate avg on columns */
export type FormulaOptions_Avg_Fields = {
  __typename?: 'FormulaOptions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  range_value_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FormulaOptions" */
export type FormulaOptions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FormulaOptions". All fields are combined with a logical 'AND'. */
export type FormulaOptions_Bool_Exp = {
  EquationFormulaOptions?: InputMaybe<EquationFormulaOption_Bool_Exp>;
  EquationFormulaOptions_aggregate?: InputMaybe<EquationFormulaOption_Aggregate_Bool_Exp>;
  RangeValue?: InputMaybe<RangeValues_Bool_Exp>;
  _and?: InputMaybe<Array<FormulaOptions_Bool_Exp>>;
  _not?: InputMaybe<FormulaOptions_Bool_Exp>;
  _or?: InputMaybe<Array<FormulaOptions_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  range_value_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "FormulaOptions" */
export enum FormulaOptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormulaOptionsPkey = 'FormulaOptions_pkey',
  /** unique or primary key constraint on columns "range_value_id" */
  FormulaOptionsRangeValueIdKey = 'FormulaOptions_range_value_id_key'
}

/** input type for incrementing numeric columns in table "FormulaOptions" */
export type FormulaOptions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  range_value_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "FormulaOptions" */
export type FormulaOptions_Insert_Input = {
  EquationFormulaOptions?: InputMaybe<EquationFormulaOption_Arr_Rel_Insert_Input>;
  RangeValue?: InputMaybe<RangeValues_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  range_value_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type FormulaOptions_Max_Fields = {
  __typename?: 'FormulaOptions_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  range_value_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "FormulaOptions" */
export type FormulaOptions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FormulaOptions_Min_Fields = {
  __typename?: 'FormulaOptions_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  range_value_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "FormulaOptions" */
export type FormulaOptions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FormulaOptions" */
export type FormulaOptions_Mutation_Response = {
  __typename?: 'FormulaOptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FormulaOptions>;
};

/** input type for inserting object relation for remote table "FormulaOptions" */
export type FormulaOptions_Obj_Rel_Insert_Input = {
  data: FormulaOptions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<FormulaOptions_On_Conflict>;
};

/** on_conflict condition type for table "FormulaOptions" */
export type FormulaOptions_On_Conflict = {
  constraint: FormulaOptions_Constraint;
  update_columns?: Array<FormulaOptions_Update_Column>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};

/** Ordering options when selecting data from "FormulaOptions". */
export type FormulaOptions_Order_By = {
  EquationFormulaOptions_aggregate?: InputMaybe<EquationFormulaOption_Aggregate_Order_By>;
  RangeValue?: InputMaybe<RangeValues_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FormulaOptions */
export type FormulaOptions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "FormulaOptions" */
export enum FormulaOptions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RangeValueId = 'range_value_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "FormulaOptions" */
export type FormulaOptions_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  range_value_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type FormulaOptions_Stddev_Fields = {
  __typename?: 'FormulaOptions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  range_value_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FormulaOptions" */
export type FormulaOptions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FormulaOptions_Stddev_Pop_Fields = {
  __typename?: 'FormulaOptions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  range_value_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FormulaOptions" */
export type FormulaOptions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FormulaOptions_Stddev_Samp_Fields = {
  __typename?: 'FormulaOptions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  range_value_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FormulaOptions" */
export type FormulaOptions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FormulaOptions" */
export type FormulaOptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FormulaOptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FormulaOptions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  range_value_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type FormulaOptions_Sum_Fields = {
  __typename?: 'FormulaOptions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  range_value_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FormulaOptions" */
export type FormulaOptions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

/** update columns of table "FormulaOptions" */
export enum FormulaOptions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RangeValueId = 'range_value_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

export type FormulaOptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormulaOptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormulaOptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: FormulaOptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FormulaOptions_Var_Pop_Fields = {
  __typename?: 'FormulaOptions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  range_value_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FormulaOptions" */
export type FormulaOptions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FormulaOptions_Var_Samp_Fields = {
  __typename?: 'FormulaOptions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  range_value_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FormulaOptions" */
export type FormulaOptions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FormulaOptions_Variance_Fields = {
  __typename?: 'FormulaOptions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  range_value_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FormulaOptions" */
export type FormulaOptions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  range_value_id?: InputMaybe<Order_By>;
};

export type GSheetCalcInputValue = {
  a1Notation: Scalars['String'];
  value: Scalars['String'];
};

export type GSheetCalcResult = {
  __typename?: 'GSheetCalcResult';
  name: Scalars['String'];
  nameA1Notation: Scalars['String'];
  value: Scalars['String'];
  valueA1Notation: Scalars['String'];
};

export type GSheetCalcResults = {
  __typename?: 'GSheetCalcResults';
  inputs: Array<GSheetCalcResult>;
  outputs: Array<GSheetCalcResult>;
};

/** columns and relationships of "GenderEnum" */
export type GenderEnum = {
  __typename?: 'GenderEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "GenderEnum" */
export type GenderEnum_Aggregate = {
  __typename?: 'GenderEnum_aggregate';
  aggregate?: Maybe<GenderEnum_Aggregate_Fields>;
  nodes: Array<GenderEnum>;
};

/** aggregate fields of "GenderEnum" */
export type GenderEnum_Aggregate_Fields = {
  __typename?: 'GenderEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GenderEnum_Max_Fields>;
  min?: Maybe<GenderEnum_Min_Fields>;
};


/** aggregate fields of "GenderEnum" */
export type GenderEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GenderEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "GenderEnum". All fields are combined with a logical 'AND'. */
export type GenderEnum_Bool_Exp = {
  _and?: InputMaybe<Array<GenderEnum_Bool_Exp>>;
  _not?: InputMaybe<GenderEnum_Bool_Exp>;
  _or?: InputMaybe<Array<GenderEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "GenderEnum" */
export enum GenderEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  GenderEnumPkey = 'GenderEnum_pkey'
}

export enum GenderEnum_Enum {
  /** Declined */
  Declined = 'DECLINED',
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Other */
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "GenderEnum_enum". All fields are combined with logical 'AND'. */
export type GenderEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<GenderEnum_Enum>;
  _in?: InputMaybe<Array<GenderEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<GenderEnum_Enum>;
  _nin?: InputMaybe<Array<GenderEnum_Enum>>;
};

/** input type for inserting data into table "GenderEnum" */
export type GenderEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GenderEnum_Max_Fields = {
  __typename?: 'GenderEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GenderEnum_Min_Fields = {
  __typename?: 'GenderEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "GenderEnum" */
export type GenderEnum_Mutation_Response = {
  __typename?: 'GenderEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GenderEnum>;
};

/** on_conflict condition type for table "GenderEnum" */
export type GenderEnum_On_Conflict = {
  constraint: GenderEnum_Constraint;
  update_columns?: Array<GenderEnum_Update_Column>;
  where?: InputMaybe<GenderEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "GenderEnum". */
export type GenderEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GenderEnum */
export type GenderEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "GenderEnum" */
export enum GenderEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "GenderEnum" */
export type GenderEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "GenderEnum" */
export type GenderEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GenderEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GenderEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "GenderEnum" */
export enum GenderEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type GenderEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GenderEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: GenderEnum_Bool_Exp;
};

/** columns and relationships of "GeocodingHistory" */
export type GeocodingHistory = {
  __typename?: 'GeocodingHistory';
  id: Scalars['Int'];
  object_id: Scalars['Int'];
  object_table: Scalars['String'];
  on: Scalars['timestamptz'];
  results: Scalars['jsonb'];
  string: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "GeocodingHistory" */
export type GeocodingHistoryResultsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "GeocodingHistory" */
export type GeocodingHistory_Aggregate = {
  __typename?: 'GeocodingHistory_aggregate';
  aggregate?: Maybe<GeocodingHistory_Aggregate_Fields>;
  nodes: Array<GeocodingHistory>;
};

/** aggregate fields of "GeocodingHistory" */
export type GeocodingHistory_Aggregate_Fields = {
  __typename?: 'GeocodingHistory_aggregate_fields';
  avg?: Maybe<GeocodingHistory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GeocodingHistory_Max_Fields>;
  min?: Maybe<GeocodingHistory_Min_Fields>;
  stddev?: Maybe<GeocodingHistory_Stddev_Fields>;
  stddev_pop?: Maybe<GeocodingHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GeocodingHistory_Stddev_Samp_Fields>;
  sum?: Maybe<GeocodingHistory_Sum_Fields>;
  var_pop?: Maybe<GeocodingHistory_Var_Pop_Fields>;
  var_samp?: Maybe<GeocodingHistory_Var_Samp_Fields>;
  variance?: Maybe<GeocodingHistory_Variance_Fields>;
};


/** aggregate fields of "GeocodingHistory" */
export type GeocodingHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GeocodingHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GeocodingHistory_Append_Input = {
  results?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type GeocodingHistory_Avg_Fields = {
  __typename?: 'GeocodingHistory_avg_fields';
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "GeocodingHistory". All fields are combined with a logical 'AND'. */
export type GeocodingHistory_Bool_Exp = {
  _and?: InputMaybe<Array<GeocodingHistory_Bool_Exp>>;
  _not?: InputMaybe<GeocodingHistory_Bool_Exp>;
  _or?: InputMaybe<Array<GeocodingHistory_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  object_id?: InputMaybe<Int_Comparison_Exp>;
  object_table?: InputMaybe<String_Comparison_Exp>;
  on?: InputMaybe<Timestamptz_Comparison_Exp>;
  results?: InputMaybe<Jsonb_Comparison_Exp>;
  string?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "GeocodingHistory" */
export enum GeocodingHistory_Constraint {
  /** unique or primary key constraint on columns "id" */
  GeocodingHistoryPkey = 'GeocodingHistory_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GeocodingHistory_Delete_At_Path_Input = {
  results?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GeocodingHistory_Delete_Elem_Input = {
  results?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GeocodingHistory_Delete_Key_Input = {
  results?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "GeocodingHistory" */
export type GeocodingHistory_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  object_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "GeocodingHistory" */
export type GeocodingHistory_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  object_id?: InputMaybe<Scalars['Int']>;
  object_table?: InputMaybe<Scalars['String']>;
  on?: InputMaybe<Scalars['timestamptz']>;
  results?: InputMaybe<Scalars['jsonb']>;
  string?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GeocodingHistory_Max_Fields = {
  __typename?: 'GeocodingHistory_max_fields';
  id?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['Int']>;
  object_table?: Maybe<Scalars['String']>;
  on?: Maybe<Scalars['timestamptz']>;
  string?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GeocodingHistory_Min_Fields = {
  __typename?: 'GeocodingHistory_min_fields';
  id?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['Int']>;
  object_table?: Maybe<Scalars['String']>;
  on?: Maybe<Scalars['timestamptz']>;
  string?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "GeocodingHistory" */
export type GeocodingHistory_Mutation_Response = {
  __typename?: 'GeocodingHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GeocodingHistory>;
};

/** on_conflict condition type for table "GeocodingHistory" */
export type GeocodingHistory_On_Conflict = {
  constraint: GeocodingHistory_Constraint;
  update_columns?: Array<GeocodingHistory_Update_Column>;
  where?: InputMaybe<GeocodingHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "GeocodingHistory". */
export type GeocodingHistory_Order_By = {
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  object_table?: InputMaybe<Order_By>;
  on?: InputMaybe<Order_By>;
  results?: InputMaybe<Order_By>;
  string?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GeocodingHistory */
export type GeocodingHistory_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GeocodingHistory_Prepend_Input = {
  results?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "GeocodingHistory" */
export enum GeocodingHistory_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectTable = 'object_table',
  /** column name */
  On = 'on',
  /** column name */
  Results = 'results',
  /** column name */
  String = 'string',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "GeocodingHistory" */
export type GeocodingHistory_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  object_id?: InputMaybe<Scalars['Int']>;
  object_table?: InputMaybe<Scalars['String']>;
  on?: InputMaybe<Scalars['timestamptz']>;
  results?: InputMaybe<Scalars['jsonb']>;
  string?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type GeocodingHistory_Stddev_Fields = {
  __typename?: 'GeocodingHistory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GeocodingHistory_Stddev_Pop_Fields = {
  __typename?: 'GeocodingHistory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GeocodingHistory_Stddev_Samp_Fields = {
  __typename?: 'GeocodingHistory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "GeocodingHistory" */
export type GeocodingHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GeocodingHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GeocodingHistory_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  object_id?: InputMaybe<Scalars['Int']>;
  object_table?: InputMaybe<Scalars['String']>;
  on?: InputMaybe<Scalars['timestamptz']>;
  results?: InputMaybe<Scalars['jsonb']>;
  string?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type GeocodingHistory_Sum_Fields = {
  __typename?: 'GeocodingHistory_sum_fields';
  id?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "GeocodingHistory" */
export enum GeocodingHistory_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectTable = 'object_table',
  /** column name */
  On = 'on',
  /** column name */
  Results = 'results',
  /** column name */
  String = 'string',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type GeocodingHistory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GeocodingHistory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GeocodingHistory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GeocodingHistory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GeocodingHistory_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GeocodingHistory_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GeocodingHistory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GeocodingHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: GeocodingHistory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GeocodingHistory_Var_Pop_Fields = {
  __typename?: 'GeocodingHistory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GeocodingHistory_Var_Samp_Fields = {
  __typename?: 'GeocodingHistory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GeocodingHistory_Variance_Fields = {
  __typename?: 'GeocodingHistory_variance_fields';
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

export type GetSignedUrlInput = {
  bucketAlias: Scalars['String'];
  bucketKey: Scalars['String'];
  companyId: Scalars['Int'];
};

export type GetSignedUrlOutput = {
  __typename?: 'GetSignedUrlOutput';
  signedUrl: Scalars['String'];
};

/** columns and relationships of "Goals" */
export type Goals = {
  __typename?: 'Goals';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  company_id?: Maybe<Scalars['Int']>;
  complete?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  due_date?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Goals" */
export type Goals_Aggregate = {
  __typename?: 'Goals_aggregate';
  aggregate?: Maybe<Goals_Aggregate_Fields>;
  nodes: Array<Goals>;
};

export type Goals_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Goals_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Goals_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Goals_Aggregate_Bool_Exp_Count>;
};

export type Goals_Aggregate_Bool_Exp_Bool_And = {
  arguments: Goals_Select_Column_Goals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Goals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Goals_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Goals_Select_Column_Goals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Goals_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Goals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Goals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Goals" */
export type Goals_Aggregate_Fields = {
  __typename?: 'Goals_aggregate_fields';
  avg?: Maybe<Goals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Goals_Max_Fields>;
  min?: Maybe<Goals_Min_Fields>;
  stddev?: Maybe<Goals_Stddev_Fields>;
  stddev_pop?: Maybe<Goals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Goals_Stddev_Samp_Fields>;
  sum?: Maybe<Goals_Sum_Fields>;
  var_pop?: Maybe<Goals_Var_Pop_Fields>;
  var_samp?: Maybe<Goals_Var_Samp_Fields>;
  variance?: Maybe<Goals_Variance_Fields>;
};


/** aggregate fields of "Goals" */
export type Goals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Goals" */
export type Goals_Aggregate_Order_By = {
  avg?: InputMaybe<Goals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Goals_Max_Order_By>;
  min?: InputMaybe<Goals_Min_Order_By>;
  stddev?: InputMaybe<Goals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Goals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Goals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Goals_Sum_Order_By>;
  var_pop?: InputMaybe<Goals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Goals_Var_Samp_Order_By>;
  variance?: InputMaybe<Goals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Goals" */
export type Goals_Arr_Rel_Insert_Input = {
  data: Array<Goals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};

/** aggregate avg on columns */
export type Goals_Avg_Fields = {
  __typename?: 'Goals_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Goals" */
export type Goals_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Goals". All fields are combined with a logical 'AND'. */
export type Goals_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Goals_Bool_Exp>>;
  _not?: InputMaybe<Goals_Bool_Exp>;
  _or?: InputMaybe<Array<Goals_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  complete?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  due_date?: InputMaybe<String_Comparison_Exp>;
  goal?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Goals" */
export enum Goals_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoalsPkey = 'Goals_pkey'
}

/** input type for incrementing numeric columns in table "Goals" */
export type Goals_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Goals" */
export type Goals_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  complete?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  due_date?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Goals_Max_Fields = {
  __typename?: 'Goals_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Goals" */
export type Goals_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  goal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Goals_Min_Fields = {
  __typename?: 'Goals_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Goals" */
export type Goals_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  goal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Goals" */
export type Goals_Mutation_Response = {
  __typename?: 'Goals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Goals>;
};

/** on_conflict condition type for table "Goals" */
export type Goals_On_Conflict = {
  constraint: Goals_Constraint;
  update_columns?: Array<Goals_Update_Column>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

/** Ordering options when selecting data from "Goals". */
export type Goals_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  complete?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  goal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Goals */
export type Goals_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Goals" */
export enum Goals_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Complete = 'complete',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Goal = 'goal',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Goals_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Goals" */
export enum Goals_Select_Column_Goals_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Complete = 'complete'
}

/** select "Goals_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Goals" */
export enum Goals_Select_Column_Goals_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Complete = 'complete'
}

/** input type for updating data in table "Goals" */
export type Goals_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  complete?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  due_date?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Goals_Stddev_Fields = {
  __typename?: 'Goals_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Goals" */
export type Goals_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Goals_Stddev_Pop_Fields = {
  __typename?: 'Goals_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Goals" */
export type Goals_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Goals_Stddev_Samp_Fields = {
  __typename?: 'Goals_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Goals" */
export type Goals_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Goals" */
export type Goals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Goals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Goals_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  complete?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  due_date?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Goals_Sum_Fields = {
  __typename?: 'Goals_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Goals" */
export type Goals_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "Goals" */
export enum Goals_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Complete = 'complete',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Goal = 'goal',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Goals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Goals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Goals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Goals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Goals_Var_Pop_Fields = {
  __typename?: 'Goals_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Goals" */
export type Goals_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Goals_Var_Samp_Fields = {
  __typename?: 'Goals_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Goals" */
export type Goals_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Goals_Variance_Fields = {
  __typename?: 'Goals_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Goals" */
export type Goals_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates = {
  __typename?: 'GoogleSheetCalcTemplates';
  /** An object relationship */
  CreatedBy: Identities;
  /** An object relationship */
  UpdatedBy: Identities;
  archived: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  google_sheet_id: Scalars['String'];
  google_sheet_url: Scalars['String'];
  id: Scalars['uuid'];
  input_fields: Scalars['jsonb'];
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplatesInput_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Aggregate = {
  __typename?: 'GoogleSheetCalcTemplates_aggregate';
  aggregate?: Maybe<GoogleSheetCalcTemplates_Aggregate_Fields>;
  nodes: Array<GoogleSheetCalcTemplates>;
};

/** aggregate fields of "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Aggregate_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_aggregate_fields';
  avg?: Maybe<GoogleSheetCalcTemplates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GoogleSheetCalcTemplates_Max_Fields>;
  min?: Maybe<GoogleSheetCalcTemplates_Min_Fields>;
  stddev?: Maybe<GoogleSheetCalcTemplates_Stddev_Fields>;
  stddev_pop?: Maybe<GoogleSheetCalcTemplates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GoogleSheetCalcTemplates_Stddev_Samp_Fields>;
  sum?: Maybe<GoogleSheetCalcTemplates_Sum_Fields>;
  var_pop?: Maybe<GoogleSheetCalcTemplates_Var_Pop_Fields>;
  var_samp?: Maybe<GoogleSheetCalcTemplates_Var_Samp_Fields>;
  variance?: Maybe<GoogleSheetCalcTemplates_Variance_Fields>;
};


/** aggregate fields of "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoogleSheetCalcTemplates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GoogleSheetCalcTemplates_Append_Input = {
  input_fields?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type GoogleSheetCalcTemplates_Avg_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "GoogleSheetCalcTemplates". All fields are combined with a logical 'AND'. */
export type GoogleSheetCalcTemplates_Bool_Exp = {
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<GoogleSheetCalcTemplates_Bool_Exp>>;
  _not?: InputMaybe<GoogleSheetCalcTemplates_Bool_Exp>;
  _or?: InputMaybe<Array<GoogleSheetCalcTemplates_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  google_sheet_id?: InputMaybe<String_Comparison_Exp>;
  google_sheet_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  input_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "GoogleSheetCalcTemplates" */
export enum GoogleSheetCalcTemplates_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoogleSheetCalcTemplatesPkey = 'GoogleSheetCalcTemplates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GoogleSheetCalcTemplates_Delete_At_Path_Input = {
  input_fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GoogleSheetCalcTemplates_Delete_Elem_Input = {
  input_fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GoogleSheetCalcTemplates_Delete_Key_Input = {
  input_fields?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Insert_Input = {
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  archived?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  google_sheet_id?: InputMaybe<Scalars['String']>;
  google_sheet_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_fields?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type GoogleSheetCalcTemplates_Max_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  google_sheet_id?: Maybe<Scalars['String']>;
  google_sheet_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type GoogleSheetCalcTemplates_Min_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  google_sheet_id?: Maybe<Scalars['String']>;
  google_sheet_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Mutation_Response = {
  __typename?: 'GoogleSheetCalcTemplates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoogleSheetCalcTemplates>;
};

/** on_conflict condition type for table "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_On_Conflict = {
  constraint: GoogleSheetCalcTemplates_Constraint;
  update_columns?: Array<GoogleSheetCalcTemplates_Update_Column>;
  where?: InputMaybe<GoogleSheetCalcTemplates_Bool_Exp>;
};

/** Ordering options when selecting data from "GoogleSheetCalcTemplates". */
export type GoogleSheetCalcTemplates_Order_By = {
  CreatedBy?: InputMaybe<Identities_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  archived?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  google_sheet_id?: InputMaybe<Order_By>;
  google_sheet_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  input_fields?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GoogleSheetCalcTemplates */
export type GoogleSheetCalcTemplates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GoogleSheetCalcTemplates_Prepend_Input = {
  input_fields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "GoogleSheetCalcTemplates" */
export enum GoogleSheetCalcTemplates_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GoogleSheetId = 'google_sheet_id',
  /** column name */
  GoogleSheetUrl = 'google_sheet_url',
  /** column name */
  Id = 'id',
  /** column name */
  InputFields = 'input_fields',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  google_sheet_id?: InputMaybe<Scalars['String']>;
  google_sheet_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_fields?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type GoogleSheetCalcTemplates_Stddev_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GoogleSheetCalcTemplates_Stddev_Pop_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GoogleSheetCalcTemplates_Stddev_Samp_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "GoogleSheetCalcTemplates" */
export type GoogleSheetCalcTemplates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoogleSheetCalcTemplates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoogleSheetCalcTemplates_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  google_sheet_id?: InputMaybe<Scalars['String']>;
  google_sheet_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_fields?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type GoogleSheetCalcTemplates_Sum_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "GoogleSheetCalcTemplates" */
export enum GoogleSheetCalcTemplates_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GoogleSheetId = 'google_sheet_id',
  /** column name */
  GoogleSheetUrl = 'google_sheet_url',
  /** column name */
  Id = 'id',
  /** column name */
  InputFields = 'input_fields',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type GoogleSheetCalcTemplates_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GoogleSheetCalcTemplates_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GoogleSheetCalcTemplates_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GoogleSheetCalcTemplates_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GoogleSheetCalcTemplates_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GoogleSheetCalcTemplates_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GoogleSheetCalcTemplates_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoogleSheetCalcTemplates_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoogleSheetCalcTemplates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GoogleSheetCalcTemplates_Var_Pop_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GoogleSheetCalcTemplates_Var_Samp_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GoogleSheetCalcTemplates_Variance_Fields = {
  __typename?: 'GoogleSheetCalcTemplates_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "GoogleSheetCalculations" */
export type GoogleSheetCalculations = {
  __typename?: 'GoogleSheetCalculations';
  /** An object relationship */
  CreatedBy: Identities;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An object relationship */
  UpdatedBy: Identities;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  deleted: Scalars['Boolean'];
  google_sheet_id: Scalars['String'];
  google_sheet_url: Scalars['String'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  input_fields: Scalars['jsonb'];
  job_id?: Maybe<Scalars['Int']>;
  primary: Scalars['Boolean'];
  results?: Maybe<Scalars['jsonb']>;
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "GoogleSheetCalculations" */
export type GoogleSheetCalculationsInput_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "GoogleSheetCalculations" */
export type GoogleSheetCalculationsResultsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Aggregate = {
  __typename?: 'GoogleSheetCalculations_aggregate';
  aggregate?: Maybe<GoogleSheetCalculations_Aggregate_Fields>;
  nodes: Array<GoogleSheetCalculations>;
};

/** aggregate fields of "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Aggregate_Fields = {
  __typename?: 'GoogleSheetCalculations_aggregate_fields';
  avg?: Maybe<GoogleSheetCalculations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GoogleSheetCalculations_Max_Fields>;
  min?: Maybe<GoogleSheetCalculations_Min_Fields>;
  stddev?: Maybe<GoogleSheetCalculations_Stddev_Fields>;
  stddev_pop?: Maybe<GoogleSheetCalculations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GoogleSheetCalculations_Stddev_Samp_Fields>;
  sum?: Maybe<GoogleSheetCalculations_Sum_Fields>;
  var_pop?: Maybe<GoogleSheetCalculations_Var_Pop_Fields>;
  var_samp?: Maybe<GoogleSheetCalculations_Var_Samp_Fields>;
  variance?: Maybe<GoogleSheetCalculations_Variance_Fields>;
};


/** aggregate fields of "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoogleSheetCalculations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GoogleSheetCalculations_Append_Input = {
  input_fields?: InputMaybe<Scalars['jsonb']>;
  results?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type GoogleSheetCalculations_Avg_Fields = {
  __typename?: 'GoogleSheetCalculations_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "GoogleSheetCalculations". All fields are combined with a logical 'AND'. */
export type GoogleSheetCalculations_Bool_Exp = {
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<GoogleSheetCalculations_Bool_Exp>>;
  _not?: InputMaybe<GoogleSheetCalculations_Bool_Exp>;
  _or?: InputMaybe<Array<GoogleSheetCalculations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  google_sheet_id?: InputMaybe<String_Comparison_Exp>;
  google_sheet_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  input_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  primary?: InputMaybe<Boolean_Comparison_Exp>;
  results?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "GoogleSheetCalculations" */
export enum GoogleSheetCalculations_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoogleSheetCalculationsPkey = 'GoogleSheetCalculations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GoogleSheetCalculations_Delete_At_Path_Input = {
  input_fields?: InputMaybe<Array<Scalars['String']>>;
  results?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GoogleSheetCalculations_Delete_Elem_Input = {
  input_fields?: InputMaybe<Scalars['Int']>;
  results?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GoogleSheetCalculations_Delete_Key_Input = {
  input_fields?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Insert_Input = {
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  google_sheet_id?: InputMaybe<Scalars['String']>;
  google_sheet_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  input_fields?: InputMaybe<Scalars['jsonb']>;
  job_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  results?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type GoogleSheetCalculations_Max_Fields = {
  __typename?: 'GoogleSheetCalculations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  google_sheet_id?: Maybe<Scalars['String']>;
  google_sheet_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type GoogleSheetCalculations_Min_Fields = {
  __typename?: 'GoogleSheetCalculations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  google_sheet_id?: Maybe<Scalars['String']>;
  google_sheet_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Mutation_Response = {
  __typename?: 'GoogleSheetCalculations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoogleSheetCalculations>;
};

/** on_conflict condition type for table "GoogleSheetCalculations" */
export type GoogleSheetCalculations_On_Conflict = {
  constraint: GoogleSheetCalculations_Constraint;
  update_columns?: Array<GoogleSheetCalculations_Update_Column>;
  where?: InputMaybe<GoogleSheetCalculations_Bool_Exp>;
};

/** Ordering options when selecting data from "GoogleSheetCalculations". */
export type GoogleSheetCalculations_Order_By = {
  CreatedBy?: InputMaybe<Identities_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  google_sheet_id?: InputMaybe<Order_By>;
  google_sheet_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  input_fields?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  primary?: InputMaybe<Order_By>;
  results?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GoogleSheetCalculations */
export type GoogleSheetCalculations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GoogleSheetCalculations_Prepend_Input = {
  input_fields?: InputMaybe<Scalars['jsonb']>;
  results?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "GoogleSheetCalculations" */
export enum GoogleSheetCalculations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GoogleSheetId = 'google_sheet_id',
  /** column name */
  GoogleSheetUrl = 'google_sheet_url',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InputFields = 'input_fields',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Results = 'results',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  google_sheet_id?: InputMaybe<Scalars['String']>;
  google_sheet_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  input_fields?: InputMaybe<Scalars['jsonb']>;
  job_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  results?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type GoogleSheetCalculations_Stddev_Fields = {
  __typename?: 'GoogleSheetCalculations_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GoogleSheetCalculations_Stddev_Pop_Fields = {
  __typename?: 'GoogleSheetCalculations_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GoogleSheetCalculations_Stddev_Samp_Fields = {
  __typename?: 'GoogleSheetCalculations_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "GoogleSheetCalculations" */
export type GoogleSheetCalculations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoogleSheetCalculations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoogleSheetCalculations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  google_sheet_id?: InputMaybe<Scalars['String']>;
  google_sheet_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  input_fields?: InputMaybe<Scalars['jsonb']>;
  job_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  results?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type GoogleSheetCalculations_Sum_Fields = {
  __typename?: 'GoogleSheetCalculations_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "GoogleSheetCalculations" */
export enum GoogleSheetCalculations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GoogleSheetId = 'google_sheet_id',
  /** column name */
  GoogleSheetUrl = 'google_sheet_url',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InputFields = 'input_fields',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  Results = 'results',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type GoogleSheetCalculations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GoogleSheetCalculations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GoogleSheetCalculations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GoogleSheetCalculations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GoogleSheetCalculations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GoogleSheetCalculations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GoogleSheetCalculations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoogleSheetCalculations_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoogleSheetCalculations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GoogleSheetCalculations_Var_Pop_Fields = {
  __typename?: 'GoogleSheetCalculations_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GoogleSheetCalculations_Var_Samp_Fields = {
  __typename?: 'GoogleSheetCalculations_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GoogleSheetCalculations_Variance_Fields = {
  __typename?: 'GoogleSheetCalculations_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Jobs which have been shared with a specific group (partner) */
export type GroupJobs = {
  __typename?: 'GroupJobs';
  /** An object relationship */
  Group: Groups;
  /** An object relationship */
  Job: Jobs;
  createdAt: Scalars['timestamptz'];
  groupId: Scalars['uuid'];
  jobId: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "GroupJobs" */
export type GroupJobs_Aggregate = {
  __typename?: 'GroupJobs_aggregate';
  aggregate?: Maybe<GroupJobs_Aggregate_Fields>;
  nodes: Array<GroupJobs>;
};

export type GroupJobs_Aggregate_Bool_Exp = {
  count?: InputMaybe<GroupJobs_Aggregate_Bool_Exp_Count>;
};

export type GroupJobs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GroupJobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupJobs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "GroupJobs" */
export type GroupJobs_Aggregate_Fields = {
  __typename?: 'GroupJobs_aggregate_fields';
  avg?: Maybe<GroupJobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GroupJobs_Max_Fields>;
  min?: Maybe<GroupJobs_Min_Fields>;
  stddev?: Maybe<GroupJobs_Stddev_Fields>;
  stddev_pop?: Maybe<GroupJobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GroupJobs_Stddev_Samp_Fields>;
  sum?: Maybe<GroupJobs_Sum_Fields>;
  var_pop?: Maybe<GroupJobs_Var_Pop_Fields>;
  var_samp?: Maybe<GroupJobs_Var_Samp_Fields>;
  variance?: Maybe<GroupJobs_Variance_Fields>;
};


/** aggregate fields of "GroupJobs" */
export type GroupJobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GroupJobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "GroupJobs" */
export type GroupJobs_Aggregate_Order_By = {
  avg?: InputMaybe<GroupJobs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GroupJobs_Max_Order_By>;
  min?: InputMaybe<GroupJobs_Min_Order_By>;
  stddev?: InputMaybe<GroupJobs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GroupJobs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GroupJobs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GroupJobs_Sum_Order_By>;
  var_pop?: InputMaybe<GroupJobs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GroupJobs_Var_Samp_Order_By>;
  variance?: InputMaybe<GroupJobs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "GroupJobs" */
export type GroupJobs_Arr_Rel_Insert_Input = {
  data: Array<GroupJobs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GroupJobs_On_Conflict>;
};

/** aggregate avg on columns */
export type GroupJobs_Avg_Fields = {
  __typename?: 'GroupJobs_avg_fields';
  jobId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "GroupJobs" */
export type GroupJobs_Avg_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "GroupJobs". All fields are combined with a logical 'AND'. */
export type GroupJobs_Bool_Exp = {
  Group?: InputMaybe<Groups_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<GroupJobs_Bool_Exp>>;
  _not?: InputMaybe<GroupJobs_Bool_Exp>;
  _or?: InputMaybe<Array<GroupJobs_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  groupId?: InputMaybe<Uuid_Comparison_Exp>;
  jobId?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "GroupJobs" */
export enum GroupJobs_Constraint {
  /** unique or primary key constraint on columns "groupId", "jobId" */
  GroupJobsPkey = 'GroupJobs_pkey'
}

/** input type for incrementing numeric columns in table "GroupJobs" */
export type GroupJobs_Inc_Input = {
  jobId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "GroupJobs" */
export type GroupJobs_Insert_Input = {
  Group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  jobId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type GroupJobs_Max_Fields = {
  __typename?: 'GroupJobs_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "GroupJobs" */
export type GroupJobs_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GroupJobs_Min_Fields = {
  __typename?: 'GroupJobs_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "GroupJobs" */
export type GroupJobs_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "GroupJobs" */
export type GroupJobs_Mutation_Response = {
  __typename?: 'GroupJobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GroupJobs>;
};

/** on_conflict condition type for table "GroupJobs" */
export type GroupJobs_On_Conflict = {
  constraint: GroupJobs_Constraint;
  update_columns?: Array<GroupJobs_Update_Column>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};

/** Ordering options when selecting data from "GroupJobs". */
export type GroupJobs_Order_By = {
  Group?: InputMaybe<Groups_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: GroupJobs */
export type GroupJobs_Pk_Columns_Input = {
  groupId: Scalars['uuid'];
  jobId: Scalars['Int'];
};

/** select columns of table "GroupJobs" */
export enum GroupJobs_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  JobId = 'jobId',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "GroupJobs" */
export type GroupJobs_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  jobId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type GroupJobs_Stddev_Fields = {
  __typename?: 'GroupJobs_stddev_fields';
  jobId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "GroupJobs" */
export type GroupJobs_Stddev_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GroupJobs_Stddev_Pop_Fields = {
  __typename?: 'GroupJobs_stddev_pop_fields';
  jobId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "GroupJobs" */
export type GroupJobs_Stddev_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GroupJobs_Stddev_Samp_Fields = {
  __typename?: 'GroupJobs_stddev_samp_fields';
  jobId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "GroupJobs" */
export type GroupJobs_Stddev_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "GroupJobs" */
export type GroupJobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GroupJobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GroupJobs_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  jobId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type GroupJobs_Sum_Fields = {
  __typename?: 'GroupJobs_sum_fields';
  jobId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "GroupJobs" */
export type GroupJobs_Sum_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** update columns of table "GroupJobs" */
export enum GroupJobs_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  JobId = 'jobId',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type GroupJobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GroupJobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GroupJobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: GroupJobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GroupJobs_Var_Pop_Fields = {
  __typename?: 'GroupJobs_var_pop_fields';
  jobId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "GroupJobs" */
export type GroupJobs_Var_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GroupJobs_Var_Samp_Fields = {
  __typename?: 'GroupJobs_var_samp_fields';
  jobId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "GroupJobs" */
export type GroupJobs_Var_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GroupJobs_Variance_Fields = {
  __typename?: 'GroupJobs_variance_fields';
  jobId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "GroupJobs" */
export type GroupJobs_Variance_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** columns and relationships of "Groups" */
export type Groups = {
  __typename?: 'Groups';
  /** An object relationship */
  CreatedBy: Identities;
  /** An array relationship */
  GroupJobs: Array<GroupJobs>;
  /** An array relationship */
  GroupJobsWithContracts: Array<Job_Contracts_Group_Id>;
  /** An aggregate relationship */
  GroupJobsWithContracts_aggregate: Job_Contracts_Group_Id_Aggregate;
  /** An aggregate relationship */
  GroupJobs_aggregate: GroupJobs_Aggregate;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  /** An array relationship */
  IdentityGroups: Array<IdentityGroups>;
  /** An aggregate relationship */
  IdentityGroups_aggregate: IdentityGroups_Aggregate;
  /** An array relationship */
  IdentityPartnerGroupData: Array<IdentityPartnerGroupData>;
  /** An aggregate relationship */
  IdentityPartnerGroupData_aggregate: IdentityPartnerGroupData_Aggregate;
  /** An object relationship */
  PointOfContact?: Maybe<Identities>;
  /** An array relationship */
  Submissions: Array<Submissions>;
  /** An aggregate relationship */
  Submissions_aggregate: Submissions_Aggregate;
  /** An object relationship */
  UpdatedBy: Identities;
  createdAt: Scalars['timestamp'];
  createdBy: Scalars['Int'];
  disabled: Scalars['Boolean'];
  external: Scalars['Boolean'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  parentGroupId?: Maybe<Scalars['uuid']>;
  poc_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
  updatedBy: Scalars['Int'];
};


/** columns and relationships of "Groups" */
export type GroupsGroupJobsArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsGroupJobsWithContractsArgs = {
  distinct_on?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Contracts_Group_Id_Order_By>>;
  where?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsGroupJobsWithContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Contracts_Group_Id_Order_By>>;
  where?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsGroupJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsIdentityGroupsArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsIdentityGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsIdentityPartnerGroupDataArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsIdentityPartnerGroupData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsSubmissionsArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


/** columns and relationships of "Groups" */
export type GroupsSubmissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};

/** aggregated selection of "Groups" */
export type Groups_Aggregate = {
  __typename?: 'Groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

export type Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Groups_Aggregate_Bool_Exp_Count>;
};

export type Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'Groups_aggregate_fields';
  avg?: Maybe<Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
  stddev?: Maybe<Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Groups_Sum_Fields>;
  var_pop?: Maybe<Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Groups_Var_Samp_Fields>;
  variance?: Maybe<Groups_Variance_Fields>;
};


/** aggregate fields of "Groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Groups" */
export type Groups_Aggregate_Order_By = {
  avg?: InputMaybe<Groups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Max_Order_By>;
  min?: InputMaybe<Groups_Min_Order_By>;
  stddev?: InputMaybe<Groups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Groups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Groups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Groups_Sum_Order_By>;
  var_pop?: InputMaybe<Groups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Groups_Var_Samp_Order_By>;
  variance?: InputMaybe<Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Groups" */
export type Groups_Arr_Rel_Insert_Input = {
  data: Array<Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Groups_Avg_Fields = {
  __typename?: 'Groups_avg_fields';
  createdBy?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Groups" */
export type Groups_Avg_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  GroupJobs?: InputMaybe<GroupJobs_Bool_Exp>;
  GroupJobsWithContracts?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
  GroupJobsWithContracts_aggregate?: InputMaybe<Job_Contracts_Group_Id_Aggregate_Bool_Exp>;
  GroupJobs_aggregate?: InputMaybe<GroupJobs_Aggregate_Bool_Exp>;
  Identities?: InputMaybe<Identities_Bool_Exp>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Bool_Exp>;
  IdentityGroups?: InputMaybe<IdentityGroups_Bool_Exp>;
  IdentityGroups_aggregate?: InputMaybe<IdentityGroups_Aggregate_Bool_Exp>;
  IdentityPartnerGroupData?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
  IdentityPartnerGroupData_aggregate?: InputMaybe<IdentityPartnerGroupData_Aggregate_Bool_Exp>;
  PointOfContact?: InputMaybe<Identities_Bool_Exp>;
  Submissions?: InputMaybe<Submissions_Bool_Exp>;
  Submissions_aggregate?: InputMaybe<Submissions_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Groups_Bool_Exp>>;
  _not?: InputMaybe<Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<Int_Comparison_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  external?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parentGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  poc_id?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  subdomain?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'Groups_pkey'
}

/** input type for incrementing numeric columns in table "Groups" */
export type Groups_Inc_Input = {
  createdBy?: InputMaybe<Scalars['Int']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Groups" */
export type Groups_Insert_Input = {
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  GroupJobs?: InputMaybe<GroupJobs_Arr_Rel_Insert_Input>;
  GroupJobsWithContracts?: InputMaybe<Job_Contracts_Group_Id_Arr_Rel_Insert_Input>;
  Identities?: InputMaybe<Identities_Arr_Rel_Insert_Input>;
  IdentityGroups?: InputMaybe<IdentityGroups_Arr_Rel_Insert_Input>;
  IdentityPartnerGroupData?: InputMaybe<IdentityPartnerGroupData_Arr_Rel_Insert_Input>;
  PointOfContact?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Submissions?: InputMaybe<Submissions_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  external?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  parentGroupId?: InputMaybe<Scalars['uuid']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'Groups_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parentGroupId?: Maybe<Scalars['uuid']>;
  poc_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Groups" */
export type Groups_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentGroupId?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  subdomain?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'Groups_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parentGroupId?: Maybe<Scalars['uuid']>;
  poc_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Groups" */
export type Groups_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentGroupId?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  subdomain?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Groups" */
export type Groups_Mutation_Response = {
  __typename?: 'Groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "Groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "Groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "Groups". */
export type Groups_Order_By = {
  CreatedBy?: InputMaybe<Identities_Order_By>;
  GroupJobsWithContracts_aggregate?: InputMaybe<Job_Contracts_Group_Id_Aggregate_Order_By>;
  GroupJobs_aggregate?: InputMaybe<GroupJobs_Aggregate_Order_By>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Order_By>;
  IdentityGroups_aggregate?: InputMaybe<IdentityGroups_Aggregate_Order_By>;
  IdentityPartnerGroupData_aggregate?: InputMaybe<IdentityPartnerGroupData_Aggregate_Order_By>;
  PointOfContact?: InputMaybe<Identities_Order_By>;
  Submissions_aggregate?: InputMaybe<Submissions_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  disabled?: InputMaybe<Order_By>;
  external?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentGroupId?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  subdomain?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Groups" */
export enum Groups_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  External = 'external',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentGroupId = 'parentGroupId',
  /** column name */
  PocId = 'poc_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Subdomain = 'subdomain',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** select "Groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  External = 'external'
}

/** select "Groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  External = 'external'
}

/** input type for updating data in table "Groups" */
export type Groups_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  external?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  parentGroupId?: InputMaybe<Scalars['uuid']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Groups_Stddev_Fields = {
  __typename?: 'Groups_stddev_fields';
  createdBy?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Groups" */
export type Groups_Stddev_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Groups_Stddev_Pop_Fields = {
  __typename?: 'Groups_stddev_pop_fields';
  createdBy?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Groups" */
export type Groups_Stddev_Pop_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Groups_Stddev_Samp_Fields = {
  __typename?: 'Groups_stddev_samp_fields';
  createdBy?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Groups" */
export type Groups_Stddev_Samp_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  external?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  parentGroupId?: InputMaybe<Scalars['uuid']>;
  poc_id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Groups_Sum_Fields = {
  __typename?: 'Groups_sum_fields';
  createdBy?: Maybe<Scalars['Int']>;
  poc_id?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Groups" */
export type Groups_Sum_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** update columns of table "Groups" */
export enum Groups_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  External = 'external',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentGroupId = 'parentGroupId',
  /** column name */
  PocId = 'poc_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Subdomain = 'subdomain',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Groups_Var_Pop_Fields = {
  __typename?: 'Groups_var_pop_fields';
  createdBy?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Groups" */
export type Groups_Var_Pop_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Groups_Var_Samp_Fields = {
  __typename?: 'Groups_var_samp_fields';
  createdBy?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Groups" */
export type Groups_Var_Samp_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Groups_Variance_Fields = {
  __typename?: 'Groups_variance_fields';
  createdBy?: Maybe<Scalars['Float']>;
  poc_id?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Groups" */
export type Groups_Variance_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  poc_id?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** columns and relationships of "HighestStages" */
export type HighestStages = {
  __typename?: 'HighestStages';
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  highest_stage?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  job_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  stage?: Maybe<Stages>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "HighestStages" */
export type HighestStages_Aggregate = {
  __typename?: 'HighestStages_aggregate';
  aggregate?: Maybe<HighestStages_Aggregate_Fields>;
  nodes: Array<HighestStages>;
};

/** aggregate fields of "HighestStages" */
export type HighestStages_Aggregate_Fields = {
  __typename?: 'HighestStages_aggregate_fields';
  avg?: Maybe<HighestStages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<HighestStages_Max_Fields>;
  min?: Maybe<HighestStages_Min_Fields>;
  stddev?: Maybe<HighestStages_Stddev_Fields>;
  stddev_pop?: Maybe<HighestStages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HighestStages_Stddev_Samp_Fields>;
  sum?: Maybe<HighestStages_Sum_Fields>;
  var_pop?: Maybe<HighestStages_Var_Pop_Fields>;
  var_samp?: Maybe<HighestStages_Var_Samp_Fields>;
  variance?: Maybe<HighestStages_Variance_Fields>;
};


/** aggregate fields of "HighestStages" */
export type HighestStages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HighestStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type HighestStages_Avg_Fields = {
  __typename?: 'HighestStages_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  highest_stage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "HighestStages". All fields are combined with a logical 'AND'. */
export type HighestStages_Bool_Exp = {
  _and?: InputMaybe<Array<HighestStages_Bool_Exp>>;
  _not?: InputMaybe<HighestStages_Bool_Exp>;
  _or?: InputMaybe<Array<HighestStages_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  highest_stage?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  stage?: InputMaybe<Stages_Bool_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "HighestStages" */
export enum HighestStages_Constraint {
  /** unique or primary key constraint on columns "id" */
  HighestStagesPkey = 'HighestStages_pkey'
}

/** input type for incrementing numeric columns in table "HighestStages" */
export type HighestStages_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  highest_stage?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "HighestStages" */
export type HighestStages_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  highest_stage?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type HighestStages_Max_Fields = {
  __typename?: 'HighestStages_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  highest_stage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type HighestStages_Min_Fields = {
  __typename?: 'HighestStages_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  highest_stage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "HighestStages" */
export type HighestStages_Mutation_Response = {
  __typename?: 'HighestStages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HighestStages>;
};

/** input type for inserting object relation for remote table "HighestStages" */
export type HighestStages_Obj_Rel_Insert_Input = {
  data: HighestStages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<HighestStages_On_Conflict>;
};

/** on_conflict condition type for table "HighestStages" */
export type HighestStages_On_Conflict = {
  constraint: HighestStages_Constraint;
  update_columns?: Array<HighestStages_Update_Column>;
  where?: InputMaybe<HighestStages_Bool_Exp>;
};

/** Ordering options when selecting data from "HighestStages". */
export type HighestStages_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  highest_stage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Stages_Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: HighestStages */
export type HighestStages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "HighestStages" */
export enum HighestStages_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HighestStage = 'highest_stage',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "HighestStages" */
export type HighestStages_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  highest_stage?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type HighestStages_Stddev_Fields = {
  __typename?: 'HighestStages_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  highest_stage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type HighestStages_Stddev_Pop_Fields = {
  __typename?: 'HighestStages_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  highest_stage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type HighestStages_Stddev_Samp_Fields = {
  __typename?: 'HighestStages_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  highest_stage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "HighestStages" */
export type HighestStages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HighestStages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HighestStages_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  highest_stage?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type HighestStages_Sum_Fields = {
  __typename?: 'HighestStages_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  highest_stage?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "HighestStages" */
export enum HighestStages_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HighestStage = 'highest_stage',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type HighestStages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HighestStages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HighestStages_Set_Input>;
  /** filter the rows which have to be updated */
  where: HighestStages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type HighestStages_Var_Pop_Fields = {
  __typename?: 'HighestStages_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  highest_stage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type HighestStages_Var_Samp_Fields = {
  __typename?: 'HighestStages_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  highest_stage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type HighestStages_Variance_Fields = {
  __typename?: 'HighestStages_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  highest_stage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "HireTypesEnum" */
export type HireTypesEnum = {
  __typename?: 'HireTypesEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "HireTypesEnum" */
export type HireTypesEnum_Aggregate = {
  __typename?: 'HireTypesEnum_aggregate';
  aggregate?: Maybe<HireTypesEnum_Aggregate_Fields>;
  nodes: Array<HireTypesEnum>;
};

/** aggregate fields of "HireTypesEnum" */
export type HireTypesEnum_Aggregate_Fields = {
  __typename?: 'HireTypesEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<HireTypesEnum_Max_Fields>;
  min?: Maybe<HireTypesEnum_Min_Fields>;
};


/** aggregate fields of "HireTypesEnum" */
export type HireTypesEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HireTypesEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "HireTypesEnum". All fields are combined with a logical 'AND'. */
export type HireTypesEnum_Bool_Exp = {
  _and?: InputMaybe<Array<HireTypesEnum_Bool_Exp>>;
  _not?: InputMaybe<HireTypesEnum_Bool_Exp>;
  _or?: InputMaybe<Array<HireTypesEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "HireTypesEnum" */
export enum HireTypesEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  HireTypesEnumPkey = 'HireTypesEnum_pkey'
}

/** input type for inserting data into table "HireTypesEnum" */
export type HireTypesEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type HireTypesEnum_Max_Fields = {
  __typename?: 'HireTypesEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type HireTypesEnum_Min_Fields = {
  __typename?: 'HireTypesEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "HireTypesEnum" */
export type HireTypesEnum_Mutation_Response = {
  __typename?: 'HireTypesEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HireTypesEnum>;
};

/** on_conflict condition type for table "HireTypesEnum" */
export type HireTypesEnum_On_Conflict = {
  constraint: HireTypesEnum_Constraint;
  update_columns?: Array<HireTypesEnum_Update_Column>;
  where?: InputMaybe<HireTypesEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "HireTypesEnum". */
export type HireTypesEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: HireTypesEnum */
export type HireTypesEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "HireTypesEnum" */
export enum HireTypesEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "HireTypesEnum" */
export type HireTypesEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "HireTypesEnum" */
export type HireTypesEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HireTypesEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HireTypesEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "HireTypesEnum" */
export enum HireTypesEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type HireTypesEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HireTypesEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: HireTypesEnum_Bool_Exp;
};

/** columns and relationships of "ITRequestContractEmails" */
export type ItRequestContractEmails = {
  __typename?: 'ITRequestContractEmails';
  contract_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id: Scalars['Int'];
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "ITRequestContractEmails" */
export type ItRequestContractEmails_Aggregate = {
  __typename?: 'ITRequestContractEmails_aggregate';
  aggregate?: Maybe<ItRequestContractEmails_Aggregate_Fields>;
  nodes: Array<ItRequestContractEmails>;
};

export type ItRequestContractEmails_Aggregate_Bool_Exp = {
  count?: InputMaybe<ItRequestContractEmails_Aggregate_Bool_Exp_Count>;
};

export type ItRequestContractEmails_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ITRequestContractEmails" */
export type ItRequestContractEmails_Aggregate_Fields = {
  __typename?: 'ITRequestContractEmails_aggregate_fields';
  avg?: Maybe<ItRequestContractEmails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ItRequestContractEmails_Max_Fields>;
  min?: Maybe<ItRequestContractEmails_Min_Fields>;
  stddev?: Maybe<ItRequestContractEmails_Stddev_Fields>;
  stddev_pop?: Maybe<ItRequestContractEmails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ItRequestContractEmails_Stddev_Samp_Fields>;
  sum?: Maybe<ItRequestContractEmails_Sum_Fields>;
  var_pop?: Maybe<ItRequestContractEmails_Var_Pop_Fields>;
  var_samp?: Maybe<ItRequestContractEmails_Var_Samp_Fields>;
  variance?: Maybe<ItRequestContractEmails_Variance_Fields>;
};


/** aggregate fields of "ITRequestContractEmails" */
export type ItRequestContractEmails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Aggregate_Order_By = {
  avg?: InputMaybe<ItRequestContractEmails_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ItRequestContractEmails_Max_Order_By>;
  min?: InputMaybe<ItRequestContractEmails_Min_Order_By>;
  stddev?: InputMaybe<ItRequestContractEmails_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ItRequestContractEmails_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ItRequestContractEmails_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ItRequestContractEmails_Sum_Order_By>;
  var_pop?: InputMaybe<ItRequestContractEmails_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ItRequestContractEmails_Var_Samp_Order_By>;
  variance?: InputMaybe<ItRequestContractEmails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ITRequestContractEmails" */
export type ItRequestContractEmails_Arr_Rel_Insert_Input = {
  data: Array<ItRequestContractEmails_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ItRequestContractEmails_On_Conflict>;
};

/** aggregate avg on columns */
export type ItRequestContractEmails_Avg_Fields = {
  __typename?: 'ITRequestContractEmails_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ITRequestContractEmails". All fields are combined with a logical 'AND'. */
export type ItRequestContractEmails_Bool_Exp = {
  _and?: InputMaybe<Array<ItRequestContractEmails_Bool_Exp>>;
  _not?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
  _or?: InputMaybe<Array<ItRequestContractEmails_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ITRequestContractEmails" */
export enum ItRequestContractEmails_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItRequestContractEmailsPkey = 'ITRequestContractEmails_pkey'
}

/** input type for incrementing numeric columns in table "ITRequestContractEmails" */
export type ItRequestContractEmails_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ITRequestContractEmails" */
export type ItRequestContractEmails_Insert_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ItRequestContractEmails_Max_Fields = {
  __typename?: 'ITRequestContractEmails_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ItRequestContractEmails_Min_Fields = {
  __typename?: 'ITRequestContractEmails_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ITRequestContractEmails" */
export type ItRequestContractEmails_Mutation_Response = {
  __typename?: 'ITRequestContractEmails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ItRequestContractEmails>;
};

/** on_conflict condition type for table "ITRequestContractEmails" */
export type ItRequestContractEmails_On_Conflict = {
  constraint: ItRequestContractEmails_Constraint;
  update_columns?: Array<ItRequestContractEmails_Update_Column>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};

/** Ordering options when selecting data from "ITRequestContractEmails". */
export type ItRequestContractEmails_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ITRequestContractEmails */
export type ItRequestContractEmails_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ITRequestContractEmails" */
export enum ItRequestContractEmails_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ITRequestContractEmails" */
export type ItRequestContractEmails_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ItRequestContractEmails_Stddev_Fields = {
  __typename?: 'ITRequestContractEmails_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ItRequestContractEmails_Stddev_Pop_Fields = {
  __typename?: 'ITRequestContractEmails_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ItRequestContractEmails_Stddev_Samp_Fields = {
  __typename?: 'ITRequestContractEmails_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ITRequestContractEmails" */
export type ItRequestContractEmails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ItRequestContractEmails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ItRequestContractEmails_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ItRequestContractEmails_Sum_Fields = {
  __typename?: 'ITRequestContractEmails_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ITRequestContractEmails" */
export enum ItRequestContractEmails_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ItRequestContractEmails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ItRequestContractEmails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ItRequestContractEmails_Set_Input>;
  /** filter the rows which have to be updated */
  where: ItRequestContractEmails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ItRequestContractEmails_Var_Pop_Fields = {
  __typename?: 'ITRequestContractEmails_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ItRequestContractEmails_Var_Samp_Fields = {
  __typename?: 'ITRequestContractEmails_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ItRequestContractEmails_Variance_Fields = {
  __typename?: 'ITRequestContractEmails_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ITRequestContractEmails" */
export type ItRequestContractEmails_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Identities" */
export type Identities = {
  __typename?: 'Identities';
  /** An array relationship */
  APITokens: Array<ApiTokens>;
  /** An aggregate relationship */
  APITokens_aggregate: ApiTokens_Aggregate;
  /** An array relationship */
  Applications: Array<Applications>;
  /** An aggregate relationship */
  Applications_aggregate: Applications_Aggregate;
  /** An array relationship */
  Assignments: Array<Assignments>;
  /** An aggregate relationship */
  Assignments_aggregate: Assignments_Aggregate;
  /** An array relationship */
  AuditCreates: Array<AuditCreates>;
  /** An aggregate relationship */
  AuditCreates_aggregate: AuditCreates_Aggregate;
  /** An array relationship */
  AuditStages: Array<AuditStages>;
  /** An aggregate relationship */
  AuditStages_aggregate: AuditStages_Aggregate;
  /** An array relationship */
  BoardAccountManagers: Array<Candidate_Account_Managers>;
  /** An aggregate relationship */
  BoardAccountManagers_aggregate: Candidate_Account_Managers_Aggregate;
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** An array relationship */
  Calculations: Array<Calculations>;
  /** An aggregate relationship */
  Calculations_aggregate: Calculations_Aggregate;
  /** An object relationship */
  CandidateSource?: Maybe<CandidateSources>;
  /** An object relationship */
  CandidateStatus?: Maybe<CandidateStatuses>;
  /** An object relationship */
  Citizenship?: Maybe<CitizenshipEnum>;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An array relationship */
  Compensations: Array<Compensations>;
  /** An aggregate relationship */
  Compensations_aggregate: Compensations_Aggregate;
  /** An array relationship */
  ContractRelationships: Array<IdentityContractRelationship>;
  /** An aggregate relationship */
  ContractRelationships_aggregate: IdentityContractRelationship_Aggregate;
  /** An array relationship */
  Contracts: Array<Contracts>;
  /** An aggregate relationship */
  Contracts_aggregate: Contracts_Aggregate;
  /** An array relationship */
  CurrentWorkflows: Array<Current_Workflows_For_Identity>;
  /** An aggregate relationship */
  CurrentWorkflows_aggregate: Current_Workflows_For_Identity_Aggregate;
  /** An array relationship */
  EarnedBadges: Array<EarnedBadges>;
  /** An aggregate relationship */
  EarnedBadges_aggregate: EarnedBadges_Aggregate;
  /** An array relationship */
  EmailRecords: Array<EmailRecords>;
  /** An aggregate relationship */
  EmailRecords_aggregate: EmailRecords_Aggregate;
  /** An array relationship */
  EmailSignatures: Array<EmailSignatures>;
  /** An aggregate relationship */
  EmailSignatures_aggregate: EmailSignatures_Aggregate;
  /** An array relationship */
  Employments: Array<Employments>;
  /** An aggregate relationship */
  Employments_aggregate: Employments_Aggregate;
  /** An array relationship */
  Entitlements: Array<Entitlements>;
  /** An aggregate relationship */
  Entitlements_aggregate: Entitlements_Aggregate;
  /** An array relationship */
  Files: Array<Files>;
  /** An aggregate relationship */
  Files_aggregate: Files_Aggregate;
  /** An array relationship */
  Goals: Array<Goals>;
  /** An aggregate relationship */
  Goals_aggregate: Goals_Aggregate;
  /** An object relationship */
  Group?: Maybe<Groups>;
  /** An array relationship */
  IdentityAssignments: Array<IdentityAssignments>;
  /** An aggregate relationship */
  IdentityAssignments_aggregate: IdentityAssignments_Aggregate;
  /** An array relationship */
  IdentityDepartmentRelationships: Array<IdentityDepartmentRelationship>;
  /** An aggregate relationship */
  IdentityDepartmentRelationships_aggregate: IdentityDepartmentRelationship_Aggregate;
  /** An array relationship */
  IdentityGroups: Array<IdentityGroups>;
  /** An aggregate relationship */
  IdentityGroups_aggregate: IdentityGroups_Aggregate;
  /** An array relationship */
  IdentityPartnerGroupData: Array<IdentityPartnerGroupData>;
  /** An aggregate relationship */
  IdentityPartnerGroupData_aggregate: IdentityPartnerGroupData_Aggregate;
  /** An array relationship */
  IdentityRoles: Array<IdentityRoles>;
  /** An aggregate relationship */
  IdentityRoles_aggregate: IdentityRoles_Aggregate;
  /** An object relationship */
  IdentitySubType?: Maybe<IdentitySubTypes>;
  /** An array relationship */
  IdentityTags: Array<IdentityTags>;
  /** An aggregate relationship */
  IdentityTags_aggregate: IdentityTags_Aggregate;
  /** An array relationship */
  IdentityTokens: Array<IdentityTokens>;
  /** An aggregate relationship */
  IdentityTokens_aggregate: IdentityTokens_Aggregate;
  /** An object relationship */
  IdentityType: IdentityTypes;
  /** An array relationship */
  Images: Array<Images>;
  /** An aggregate relationship */
  Images_aggregate: Images_Aggregate;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An array relationship */
  JobRelationships: Array<IdentityJobRelationship>;
  /** An aggregate relationship */
  JobRelationships_aggregate: IdentityJobRelationship_Aggregate;
  /** An array relationship */
  Matches: Array<Matches_Without_Author_With_Int_Uuid>;
  /** An aggregate relationship */
  Matches_aggregate: Matches_Without_Author_With_Int_Uuid_Aggregate;
  /** An object relationship */
  MostRecentComment?: Maybe<Comments>;
  /** An array relationship */
  OverlayLinks: Array<IdentitiesOverlaysLinks>;
  /** An aggregate relationship */
  OverlayLinks_aggregate: IdentitiesOverlaysLinks_Aggregate;
  /** An array relationship */
  PointOfContactGroups: Array<Groups>;
  /** An aggregate relationship */
  PointOfContactGroups_aggregate: Groups_Aggregate;
  /** An object relationship */
  Race?: Maybe<RaceEnum>;
  /** An array relationship */
  ResumeClaims: Array<ResumeClaims>;
  /** An aggregate relationship */
  ResumeClaims_aggregate: ResumeClaims_Aggregate;
  /** An array relationship */
  Resumes: Array<Resumes>;
  /** An aggregate relationship */
  Resumes_aggregate: Resumes_Aggregate;
  /** An array relationship */
  Reviews: Array<Reviews>;
  /** An aggregate relationship */
  Reviews_aggregate: Reviews_Aggregate;
  /** An object relationship */
  SourceGroup?: Maybe<Groups>;
  /** An object relationship */
  Stage?: Maybe<Stages>;
  /** An array relationship */
  Submissions: Array<Submissions>;
  /** An aggregate relationship */
  Submissions_aggregate: Submissions_Aggregate;
  /** An object relationship */
  Subtype?: Maybe<IdentitySubTypes>;
  /** An array relationship */
  TableConfigs: Array<TableConfigs>;
  /** An aggregate relationship */
  TableConfigs_aggregate: TableConfigs_Aggregate;
  /** An array relationship */
  TaskGroupAssignees: Array<TaskGroupAssignees>;
  /** An aggregate relationship */
  TaskGroupAssignees_aggregate: TaskGroupAssignees_Aggregate;
  /** An array relationship */
  TaskGroups: Array<TaskGroups>;
  /** An aggregate relationship */
  TaskGroups_aggregate: TaskGroups_Aggregate;
  /** An array relationship */
  Tasks: Array<Tasks>;
  /** An aggregate relationship */
  Tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  Terminations: Array<Terminations>;
  /** An aggregate relationship */
  Terminations_aggregate: Terminations_Aggregate;
  /** An array relationship */
  TimelineEvents: Array<TimelineEvents>;
  /** An aggregate relationship */
  TimelineEvents_aggregate: TimelineEvents_Aggregate;
  _search?: Maybe<Scalars['tsvector']>;
  account_manager_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  assignmentsByOwnerId: Array<Assignments>;
  /** An aggregate relationship */
  assignmentsByOwnerId_aggregate: Assignments_Aggregate;
  /** An array relationship */
  auditStagesByAuthorId: Array<AuditStages>;
  /** An aggregate relationship */
  auditStagesByAuthorId_aggregate: AuditStages_Aggregate;
  /** An array relationship */
  auditStagesByIdentityId: Array<AuditStages>;
  /** An aggregate relationship */
  auditStagesByIdentityId_aggregate: AuditStages_Aggregate;
  /** An array relationship */
  auditStagesByRmId: Array<AuditStages>;
  /** An aggregate relationship */
  auditStagesByRmId_aggregate: AuditStages_Aggregate;
  /** An object relationship */
  author?: Maybe<Identities>;
  /** An array relationship */
  boardSnippetsByRmId: Array<BoardSnippets>;
  /** An aggregate relationship */
  boardSnippetsByRmId_aggregate: BoardSnippets_Aggregate;
  /** An array relationship */
  calculationsByIdentityId: Array<Calculations>;
  /** An aggregate relationship */
  calculationsByIdentityId_aggregate: Calculations_Aggregate;
  candidate_source_id?: Maybe<Scalars['Int']>;
  candidate_status_id?: Maybe<Scalars['Int']>;
  citizenship?: Maybe<CitizenshipEnum_Enum>;
  /** An object relationship */
  citizenshipEnum_enum?: Maybe<CitizenshipEnum>;
  city?: Maybe<Scalars['String']>;
  clearance?: Maybe<Scalars['String']>;
  clearance_eligible?: Maybe<Scalars['Boolean']>;
  cog_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  commentsByIdentityId: Array<Comments>;
  /** An aggregate relationship */
  commentsByIdentityId_aggregate: Comments_Aggregate;
  company_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  contractsBySiteLeadId: Array<Contracts>;
  /** An aggregate relationship */
  contractsBySiteLeadId_aggregate: Contracts_Aggregate;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  /** An object relationship */
  currentEmployment?: Maybe<Employments>;
  current_employer?: Maybe<Scalars['String']>;
  current_employment_id?: Maybe<Scalars['Int']>;
  date_of_birth?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  /** Direct or Indirect */
  designation?: Maybe<Scalars['String']>;
  emergency_contact?: Maybe<Scalars['String']>;
  emergency_contact_phone?: Maybe<Scalars['String']>;
  /** Full Time or Part Time */
  employee_status?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  /** A computed field, executes function "first_comment_dt" */
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  /** Exempt or Non-Exempt */
  flsa?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum_Enum>;
  global_search_idx?: Maybe<Scalars['tsvector']>;
  gov_contract_participant?: Maybe<Scalars['Boolean']>;
  gov_employment_status?: Maybe<Scalars['String']>;
  has_disability?: Maybe<DisabilityStatusEnum_Enum>;
  hex_color?: Maybe<Scalars['String']>;
  /** An object relationship */
  highest_stage?: Maybe<HighestStages>;
  id: Scalars['Int'];
  /** A computed field, executes function "identity_identifier" */
  identifier?: Maybe<Scalars['String']>;
  /** An object relationship */
  identityByAccountManagerId?: Maybe<Identities>;
  /** An object relationship */
  identityByResourceManagerId?: Maybe<Identities>;
  /** An object relationship */
  identityBySiteLeadId?: Maybe<Identities>;
  img?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['Boolean']>;
  internal_update_counter?: Maybe<Scalars['Int']>;
  is_hispanic_latino?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_on_board" */
  is_on_board?: Maybe<Scalars['Boolean']>;
  is_service_account?: Maybe<Scalars['Boolean']>;
  is_veteran?: Maybe<Scalars['Boolean']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  labor_category?: Maybe<EeoJobCategoriesEnum_Enum>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  last_comment_txt?: Maybe<Scalars['String']>;
  latest_al_id?: Maybe<Scalars['Int']>;
  latest_comment_id?: Maybe<Scalars['Int']>;
  linkedin_url?: Maybe<Scalars['String']>;
  location_geometry?: Maybe<Scalars['geometry']>;
  manager?: Maybe<Scalars['String']>;
  metadata: Scalars['jsonb'];
  mobile_phone?: Maybe<Scalars['String']>;
  /** An object relationship */
  mostRecentALAuthored?: Maybe<Comments>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  overlays_rendered?: Maybe<Scalars['jsonb']>;
  overlays_rendered_full?: Maybe<Scalars['jsonb']>;
  override_source_group_filter: Scalars['Boolean'];
  partner_candidate_data?: Maybe<Scalars['jsonb']>;
  personal_email?: Maybe<Scalars['String']>;
  place_of_birth?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  preliminary_start_date?: Maybe<Scalars['timestamptz']>;
  profile_picture_key?: Maybe<Scalars['String']>;
  race?: Maybe<RaceEnum_Enum>;
  recruitable: Scalars['Boolean'];
  referred_by?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  resume?: Maybe<Scalars['String']>;
  resumes_combined_txt?: Maybe<Scalars['String']>;
  /** An array relationship */
  roles: Array<Identity_Roles_View>;
  /** An aggregate relationship */
  roles_aggregate: Identity_Roles_View_Aggregate;
  salary?: Maybe<Scalars['String']>;
  security_clearance_info: Scalars['jsonb'];
  shirt_size?: Maybe<Scalars['String']>;
  site_lead_id?: Maybe<Scalars['Int']>;
  source_group_id?: Maybe<Scalars['uuid']>;
  stage_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  /** deprecated */
  status?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['Int']>;
  termination_description?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  ts_identities_fts?: Maybe<Scalars['tsvector']>;
  tsv?: Maybe<Scalars['tsvector']>;
  twilio_from?: Maybe<Scalars['String']>;
  type_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  veteran_status?: Maybe<VeteranStatusEnum_Enum>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Identities" */
export type IdentitiesApiTokensArgs = {
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesApiTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesApplicationsArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesApplications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditCreatesArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditCreates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStagesArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesBoardAccountManagersArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Account_Managers_Order_By>>;
  where?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesBoardAccountManagers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Account_Managers_Order_By>>;
  where?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCalculationsArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCompensationsArgs = {
  distinct_on?: InputMaybe<Array<Compensations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compensations_Order_By>>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCompensations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compensations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compensations_Order_By>>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesContractRelationshipsArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesContractRelationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesContractsArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCurrentWorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Workflows_For_Identity_Order_By>>;
  where?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCurrentWorkflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Workflows_For_Identity_Order_By>>;
  where?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEarnedBadgesArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEarnedBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEmailRecordsArgs = {
  distinct_on?: InputMaybe<Array<EmailRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailRecords_Order_By>>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEmailRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailRecords_Order_By>>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEmailSignaturesArgs = {
  distinct_on?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSignatures_Order_By>>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEmailSignatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSignatures_Order_By>>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEmploymentsArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEmployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entitlements_Order_By>>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entitlements_Order_By>>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityDepartmentRelationshipsArgs = {
  distinct_on?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityDepartmentRelationship_Order_By>>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityDepartmentRelationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityDepartmentRelationship_Order_By>>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityGroupsArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityPartnerGroupDataArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityPartnerGroupData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityRolesArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityTagsArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityTokensArgs = {
  distinct_on?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTokens_Order_By>>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesIdentityTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTokens_Order_By>>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesJobRelationshipsArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesJobRelationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesMatchesArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesMatches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesOverlayLinksArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesOverlayLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesPointOfContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesPointOfContactGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesResumeClaimsArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesResumeClaims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesResumesArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesResumes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesSubmissionsArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesSubmissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTableConfigsArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTableConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTaskGroupAssigneesArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTaskGroupAssignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTaskGroupsArgs = {
  distinct_on?: InputMaybe<Array<TaskGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroups_Order_By>>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTaskGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroups_Order_By>>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTerminationsArgs = {
  distinct_on?: InputMaybe<Array<Terminations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminations_Order_By>>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTerminations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminations_Order_By>>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTimelineEventsArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesTimelineEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAssignmentsByOwnerIdArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAssignmentsByOwnerId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStagesByAuthorIdArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStagesByAuthorId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStagesByIdentityIdArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStagesByIdentityId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStagesByRmIdArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesAuditStagesByRmId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesBoardSnippetsByRmIdArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesBoardSnippetsByRmId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCalculationsByIdentityIdArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCalculationsByIdentityId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCommentsByIdentityIdArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesCommentsByIdentityId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesContractsBySiteLeadIdArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesContractsBySiteLeadId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Identities" */
export type IdentitiesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Identities" */
export type IdentitiesOverlays_RenderedArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Identities" */
export type IdentitiesOverlays_Rendered_FullArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Identities" */
export type IdentitiesPartner_Candidate_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Identities" */
export type IdentitiesRolesArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_View_Order_By>>;
  where?: InputMaybe<Identity_Roles_View_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_View_Order_By>>;
  where?: InputMaybe<Identity_Roles_View_Bool_Exp>;
};


/** columns and relationships of "Identities" */
export type IdentitiesSecurity_Clearance_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type IdentitiesFtsParams = {
  additionalFilterCriteria?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  countsOnly?: InputMaybe<Scalars['Int']>;
  fts?: InputMaybe<Scalars['String']>;
  ftsWhere?: InputMaybe<Scalars['String']>;
  insideWhere?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  noHighlight?: InputMaybe<Scalars['Boolean']>;
  noRelevance?: InputMaybe<Scalars['Boolean']>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<Scalars['String']>;
  sortOverride?: InputMaybe<Array<InputMaybe<FtsSortOverride>>>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks = {
  __typename?: 'IdentitiesOverlaysLinks';
  /** An object relationship */
  Entities: Identities;
  /** An object relationship */
  Overlay: Overlay;
  /** An object relationship */
  OverlayDefinition: OverlayDefinition;
  created_at: Scalars['timestamptz'];
  entity_id: Scalars['Int'];
  id: Scalars['uuid'];
  overlay_def_id: Scalars['uuid'];
  overlay_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Aggregate = {
  __typename?: 'IdentitiesOverlaysLinks_aggregate';
  aggregate?: Maybe<IdentitiesOverlaysLinks_Aggregate_Fields>;
  nodes: Array<IdentitiesOverlaysLinks>;
};

export type IdentitiesOverlaysLinks_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentitiesOverlaysLinks_Aggregate_Bool_Exp_Count>;
};

export type IdentitiesOverlaysLinks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Aggregate_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_aggregate_fields';
  avg?: Maybe<IdentitiesOverlaysLinks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentitiesOverlaysLinks_Max_Fields>;
  min?: Maybe<IdentitiesOverlaysLinks_Min_Fields>;
  stddev?: Maybe<IdentitiesOverlaysLinks_Stddev_Fields>;
  stddev_pop?: Maybe<IdentitiesOverlaysLinks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentitiesOverlaysLinks_Stddev_Samp_Fields>;
  sum?: Maybe<IdentitiesOverlaysLinks_Sum_Fields>;
  var_pop?: Maybe<IdentitiesOverlaysLinks_Var_Pop_Fields>;
  var_samp?: Maybe<IdentitiesOverlaysLinks_Var_Samp_Fields>;
  variance?: Maybe<IdentitiesOverlaysLinks_Variance_Fields>;
};


/** aggregate fields of "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Aggregate_Order_By = {
  avg?: InputMaybe<IdentitiesOverlaysLinks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentitiesOverlaysLinks_Max_Order_By>;
  min?: InputMaybe<IdentitiesOverlaysLinks_Min_Order_By>;
  stddev?: InputMaybe<IdentitiesOverlaysLinks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentitiesOverlaysLinks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentitiesOverlaysLinks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentitiesOverlaysLinks_Sum_Order_By>;
  var_pop?: InputMaybe<IdentitiesOverlaysLinks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentitiesOverlaysLinks_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentitiesOverlaysLinks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Arr_Rel_Insert_Input = {
  data: Array<IdentitiesOverlaysLinks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentitiesOverlaysLinks_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentitiesOverlaysLinks_Avg_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_avg_fields';
  entity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Avg_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentitiesOverlaysLinks". All fields are combined with a logical 'AND'. */
export type IdentitiesOverlaysLinks_Bool_Exp = {
  Entities?: InputMaybe<Identities_Bool_Exp>;
  Overlay?: InputMaybe<Overlay_Bool_Exp>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Bool_Exp>;
  _and?: InputMaybe<Array<IdentitiesOverlaysLinks_Bool_Exp>>;
  _not?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
  _or?: InputMaybe<Array<IdentitiesOverlaysLinks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  overlay_def_id?: InputMaybe<Uuid_Comparison_Exp>;
  overlay_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentitiesOverlaysLinks" */
export enum IdentitiesOverlaysLinks_Constraint {
  /** unique or primary key constraint on columns "entity_id", "overlay_def_id" */
  IdentitiesOverlaysLinksEntityIdOverlayDefIdKey = 'IdentitiesOverlaysLinks_entity_id_overlay_def_id_key',
  /** unique or primary key constraint on columns "id" */
  IdentitiesOverlaysPkey = 'IdentitiesOverlays_pkey'
}

/** input type for incrementing numeric columns in table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Inc_Input = {
  entity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Insert_Input = {
  Entities?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Overlay?: InputMaybe<Overlay_Obj_Rel_Insert_Input>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentitiesOverlaysLinks_Max_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  overlay_def_id?: Maybe<Scalars['uuid']>;
  overlay_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentitiesOverlaysLinks_Min_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  overlay_def_id?: Maybe<Scalars['uuid']>;
  overlay_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Mutation_Response = {
  __typename?: 'IdentitiesOverlaysLinks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentitiesOverlaysLinks>;
};

/** on_conflict condition type for table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_On_Conflict = {
  constraint: IdentitiesOverlaysLinks_Constraint;
  update_columns?: Array<IdentitiesOverlaysLinks_Update_Column>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentitiesOverlaysLinks". */
export type IdentitiesOverlaysLinks_Order_By = {
  Entities?: InputMaybe<Identities_Order_By>;
  Overlay?: InputMaybe<Overlay_Order_By>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Order_By>;
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentitiesOverlaysLinks */
export type IdentitiesOverlaysLinks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "IdentitiesOverlaysLinks" */
export enum IdentitiesOverlaysLinks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  OverlayDefId = 'overlay_def_id',
  /** column name */
  OverlayId = 'overlay_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentitiesOverlaysLinks_Stddev_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_stddev_fields';
  entity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Stddev_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentitiesOverlaysLinks_Stddev_Pop_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_stddev_pop_fields';
  entity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Stddev_Pop_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentitiesOverlaysLinks_Stddev_Samp_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_stddev_samp_fields';
  entity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Stddev_Samp_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentitiesOverlaysLinks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentitiesOverlaysLinks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentitiesOverlaysLinks_Sum_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_sum_fields';
  entity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Sum_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentitiesOverlaysLinks" */
export enum IdentitiesOverlaysLinks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  OverlayDefId = 'overlay_def_id',
  /** column name */
  OverlayId = 'overlay_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentitiesOverlaysLinks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentitiesOverlaysLinks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentitiesOverlaysLinks_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentitiesOverlaysLinks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentitiesOverlaysLinks_Var_Pop_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_var_pop_fields';
  entity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Var_Pop_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentitiesOverlaysLinks_Var_Samp_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_var_samp_fields';
  entity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Var_Samp_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentitiesOverlaysLinks_Variance_Fields = {
  __typename?: 'IdentitiesOverlaysLinks_variance_fields';
  entity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentitiesOverlaysLinks" */
export type IdentitiesOverlaysLinks_Variance_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "Identities" */
export type Identities_Aggregate = {
  __typename?: 'Identities_aggregate';
  aggregate?: Maybe<Identities_Aggregate_Fields>;
  nodes: Array<Identities>;
};

export type Identities_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Identities_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Identities_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Identities_Aggregate_Bool_Exp_Count>;
};

export type Identities_Aggregate_Bool_Exp_Bool_And = {
  arguments: Identities_Select_Column_Identities_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Identities_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Identities_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Identities_Select_Column_Identities_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Identities_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Identities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Identities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Identities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Identities" */
export type Identities_Aggregate_Fields = {
  __typename?: 'Identities_aggregate_fields';
  avg?: Maybe<Identities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identities_Max_Fields>;
  min?: Maybe<Identities_Min_Fields>;
  stddev?: Maybe<Identities_Stddev_Fields>;
  stddev_pop?: Maybe<Identities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identities_Stddev_Samp_Fields>;
  sum?: Maybe<Identities_Sum_Fields>;
  var_pop?: Maybe<Identities_Var_Pop_Fields>;
  var_samp?: Maybe<Identities_Var_Samp_Fields>;
  variance?: Maybe<Identities_Variance_Fields>;
};


/** aggregate fields of "Identities" */
export type Identities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Identities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Identities" */
export type Identities_Aggregate_Order_By = {
  avg?: InputMaybe<Identities_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Identities_Max_Order_By>;
  min?: InputMaybe<Identities_Min_Order_By>;
  stddev?: InputMaybe<Identities_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Identities_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Identities_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Identities_Sum_Order_By>;
  var_pop?: InputMaybe<Identities_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Identities_Var_Samp_Order_By>;
  variance?: InputMaybe<Identities_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Identities_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  overlays_rendered?: InputMaybe<Scalars['jsonb']>;
  overlays_rendered_full?: InputMaybe<Scalars['jsonb']>;
  partner_candidate_data?: InputMaybe<Scalars['jsonb']>;
  security_clearance_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Identities" */
export type Identities_Arr_Rel_Insert_Input = {
  data: Array<Identities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Identities_On_Conflict>;
};

/** aggregate avg on columns */
export type Identities_Avg_Fields = {
  __typename?: 'Identities_avg_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  candidate_source_id?: Maybe<Scalars['Float']>;
  candidate_status_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_employment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_update_counter?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  latest_al_id?: Maybe<Scalars['Float']>;
  latest_comment_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  site_lead_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_type_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Identities" */
export type Identities_Avg_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Identities". All fields are combined with a logical 'AND'. */
export type Identities_Bool_Exp = {
  APITokens?: InputMaybe<ApiTokens_Bool_Exp>;
  APITokens_aggregate?: InputMaybe<ApiTokens_Aggregate_Bool_Exp>;
  Applications?: InputMaybe<Applications_Bool_Exp>;
  Applications_aggregate?: InputMaybe<Applications_Aggregate_Bool_Exp>;
  Assignments?: InputMaybe<Assignments_Bool_Exp>;
  Assignments_aggregate?: InputMaybe<Assignments_Aggregate_Bool_Exp>;
  AuditCreates?: InputMaybe<AuditCreates_Bool_Exp>;
  AuditCreates_aggregate?: InputMaybe<AuditCreates_Aggregate_Bool_Exp>;
  AuditStages?: InputMaybe<AuditStages_Bool_Exp>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  BoardAccountManagers?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
  BoardAccountManagers_aggregate?: InputMaybe<Candidate_Account_Managers_Aggregate_Bool_Exp>;
  BoardSnippets?: InputMaybe<BoardSnippets_Bool_Exp>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp>;
  Calculations?: InputMaybe<Calculations_Bool_Exp>;
  Calculations_aggregate?: InputMaybe<Calculations_Aggregate_Bool_Exp>;
  CandidateSource?: InputMaybe<CandidateSources_Bool_Exp>;
  CandidateStatus?: InputMaybe<CandidateStatuses_Bool_Exp>;
  Citizenship?: InputMaybe<CitizenshipEnum_Bool_Exp>;
  Comments?: InputMaybe<Comments_Bool_Exp>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  Compensations?: InputMaybe<Compensations_Bool_Exp>;
  Compensations_aggregate?: InputMaybe<Compensations_Aggregate_Bool_Exp>;
  ContractRelationships?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
  ContractRelationships_aggregate?: InputMaybe<IdentityContractRelationship_Aggregate_Bool_Exp>;
  Contracts?: InputMaybe<Contracts_Bool_Exp>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Bool_Exp>;
  CurrentWorkflows?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
  CurrentWorkflows_aggregate?: InputMaybe<Current_Workflows_For_Identity_Aggregate_Bool_Exp>;
  EarnedBadges?: InputMaybe<EarnedBadges_Bool_Exp>;
  EarnedBadges_aggregate?: InputMaybe<EarnedBadges_Aggregate_Bool_Exp>;
  EmailRecords?: InputMaybe<EmailRecords_Bool_Exp>;
  EmailRecords_aggregate?: InputMaybe<EmailRecords_Aggregate_Bool_Exp>;
  EmailSignatures?: InputMaybe<EmailSignatures_Bool_Exp>;
  EmailSignatures_aggregate?: InputMaybe<EmailSignatures_Aggregate_Bool_Exp>;
  Employments?: InputMaybe<Employments_Bool_Exp>;
  Employments_aggregate?: InputMaybe<Employments_Aggregate_Bool_Exp>;
  Entitlements?: InputMaybe<Entitlements_Bool_Exp>;
  Entitlements_aggregate?: InputMaybe<Entitlements_Aggregate_Bool_Exp>;
  Files?: InputMaybe<Files_Bool_Exp>;
  Files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  Goals?: InputMaybe<Goals_Bool_Exp>;
  Goals_aggregate?: InputMaybe<Goals_Aggregate_Bool_Exp>;
  Group?: InputMaybe<Groups_Bool_Exp>;
  IdentityAssignments?: InputMaybe<IdentityAssignments_Bool_Exp>;
  IdentityAssignments_aggregate?: InputMaybe<IdentityAssignments_Aggregate_Bool_Exp>;
  IdentityDepartmentRelationships?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
  IdentityDepartmentRelationships_aggregate?: InputMaybe<IdentityDepartmentRelationship_Aggregate_Bool_Exp>;
  IdentityGroups?: InputMaybe<IdentityGroups_Bool_Exp>;
  IdentityGroups_aggregate?: InputMaybe<IdentityGroups_Aggregate_Bool_Exp>;
  IdentityPartnerGroupData?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
  IdentityPartnerGroupData_aggregate?: InputMaybe<IdentityPartnerGroupData_Aggregate_Bool_Exp>;
  IdentityRoles?: InputMaybe<IdentityRoles_Bool_Exp>;
  IdentityRoles_aggregate?: InputMaybe<IdentityRoles_Aggregate_Bool_Exp>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Bool_Exp>;
  IdentityTags?: InputMaybe<IdentityTags_Bool_Exp>;
  IdentityTags_aggregate?: InputMaybe<IdentityTags_Aggregate_Bool_Exp>;
  IdentityTokens?: InputMaybe<IdentityTokens_Bool_Exp>;
  IdentityTokens_aggregate?: InputMaybe<IdentityTokens_Aggregate_Bool_Exp>;
  IdentityType?: InputMaybe<IdentityTypes_Bool_Exp>;
  Images?: InputMaybe<Images_Bool_Exp>;
  Images_aggregate?: InputMaybe<Images_Aggregate_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  JobRelationships?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
  JobRelationships_aggregate?: InputMaybe<IdentityJobRelationship_Aggregate_Bool_Exp>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Bool_Exp>;
  MostRecentComment?: InputMaybe<Comments_Bool_Exp>;
  OverlayLinks?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
  OverlayLinks_aggregate?: InputMaybe<IdentitiesOverlaysLinks_Aggregate_Bool_Exp>;
  PointOfContactGroups?: InputMaybe<Groups_Bool_Exp>;
  PointOfContactGroups_aggregate?: InputMaybe<Groups_Aggregate_Bool_Exp>;
  Race?: InputMaybe<RaceEnum_Bool_Exp>;
  ResumeClaims?: InputMaybe<ResumeClaims_Bool_Exp>;
  ResumeClaims_aggregate?: InputMaybe<ResumeClaims_Aggregate_Bool_Exp>;
  Resumes?: InputMaybe<Resumes_Bool_Exp>;
  Resumes_aggregate?: InputMaybe<Resumes_Aggregate_Bool_Exp>;
  Reviews?: InputMaybe<Reviews_Bool_Exp>;
  Reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  SourceGroup?: InputMaybe<Groups_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  Submissions?: InputMaybe<Submissions_Bool_Exp>;
  Submissions_aggregate?: InputMaybe<Submissions_Aggregate_Bool_Exp>;
  Subtype?: InputMaybe<IdentitySubTypes_Bool_Exp>;
  TableConfigs?: InputMaybe<TableConfigs_Bool_Exp>;
  TableConfigs_aggregate?: InputMaybe<TableConfigs_Aggregate_Bool_Exp>;
  TaskGroupAssignees?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
  TaskGroupAssignees_aggregate?: InputMaybe<TaskGroupAssignees_Aggregate_Bool_Exp>;
  TaskGroups?: InputMaybe<TaskGroups_Bool_Exp>;
  TaskGroups_aggregate?: InputMaybe<TaskGroups_Aggregate_Bool_Exp>;
  Tasks?: InputMaybe<Tasks_Bool_Exp>;
  Tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  Terminations?: InputMaybe<Terminations_Bool_Exp>;
  Terminations_aggregate?: InputMaybe<Terminations_Aggregate_Bool_Exp>;
  TimelineEvents?: InputMaybe<TimelineEvents_Bool_Exp>;
  TimelineEvents_aggregate?: InputMaybe<TimelineEvents_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Identities_Bool_Exp>>;
  _not?: InputMaybe<Identities_Bool_Exp>;
  _or?: InputMaybe<Array<Identities_Bool_Exp>>;
  _search?: InputMaybe<Tsvector_Comparison_Exp>;
  account_manager_id?: InputMaybe<Int_Comparison_Exp>;
  assignmentsByOwnerId?: InputMaybe<Assignments_Bool_Exp>;
  assignmentsByOwnerId_aggregate?: InputMaybe<Assignments_Aggregate_Bool_Exp>;
  auditStagesByAuthorId?: InputMaybe<AuditStages_Bool_Exp>;
  auditStagesByAuthorId_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  auditStagesByIdentityId?: InputMaybe<AuditStages_Bool_Exp>;
  auditStagesByIdentityId_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  auditStagesByRmId?: InputMaybe<AuditStages_Bool_Exp>;
  auditStagesByRmId_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  author?: InputMaybe<Identities_Bool_Exp>;
  boardSnippetsByRmId?: InputMaybe<BoardSnippets_Bool_Exp>;
  boardSnippetsByRmId_aggregate?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp>;
  calculationsByIdentityId?: InputMaybe<Calculations_Bool_Exp>;
  calculationsByIdentityId_aggregate?: InputMaybe<Calculations_Aggregate_Bool_Exp>;
  candidate_source_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_status_id?: InputMaybe<Int_Comparison_Exp>;
  citizenship?: InputMaybe<CitizenshipEnum_Enum_Comparison_Exp>;
  citizenshipEnum_enum?: InputMaybe<CitizenshipEnum_Bool_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  clearance?: InputMaybe<String_Comparison_Exp>;
  clearance_eligible?: InputMaybe<Boolean_Comparison_Exp>;
  cog_id?: InputMaybe<String_Comparison_Exp>;
  commentsByIdentityId?: InputMaybe<Comments_Bool_Exp>;
  commentsByIdentityId_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contractsBySiteLeadId?: InputMaybe<Contracts_Bool_Exp>;
  contractsBySiteLeadId_aggregate?: InputMaybe<Contracts_Aggregate_Bool_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  currentEmployment?: InputMaybe<Employments_Bool_Exp>;
  current_employer?: InputMaybe<String_Comparison_Exp>;
  current_employment_id?: InputMaybe<Int_Comparison_Exp>;
  date_of_birth?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  designation?: InputMaybe<String_Comparison_Exp>;
  emergency_contact?: InputMaybe<String_Comparison_Exp>;
  emergency_contact_phone?: InputMaybe<String_Comparison_Exp>;
  employee_status?: InputMaybe<String_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  etl_source_entity?: InputMaybe<String_Comparison_Exp>;
  first_comment_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  flsa?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<GenderEnum_Enum_Comparison_Exp>;
  global_search_idx?: InputMaybe<Tsvector_Comparison_Exp>;
  gov_contract_participant?: InputMaybe<Boolean_Comparison_Exp>;
  gov_employment_status?: InputMaybe<String_Comparison_Exp>;
  has_disability?: InputMaybe<DisabilityStatusEnum_Enum_Comparison_Exp>;
  hex_color?: InputMaybe<String_Comparison_Exp>;
  highest_stage?: InputMaybe<HighestStages_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  identityByAccountManagerId?: InputMaybe<Identities_Bool_Exp>;
  identityByResourceManagerId?: InputMaybe<Identities_Bool_Exp>;
  identityBySiteLeadId?: InputMaybe<Identities_Bool_Exp>;
  img?: InputMaybe<String_Comparison_Exp>;
  internal?: InputMaybe<Boolean_Comparison_Exp>;
  internal_update_counter?: InputMaybe<Int_Comparison_Exp>;
  is_hispanic_latino?: InputMaybe<Boolean_Comparison_Exp>;
  is_on_board?: InputMaybe<Boolean_Comparison_Exp>;
  is_service_account?: InputMaybe<Boolean_Comparison_Exp>;
  is_veteran?: InputMaybe<Boolean_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_title?: InputMaybe<String_Comparison_Exp>;
  labor_category?: InputMaybe<EeoJobCategoriesEnum_Enum_Comparison_Exp>;
  last_comment_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_comment_txt?: InputMaybe<String_Comparison_Exp>;
  latest_al_id?: InputMaybe<Int_Comparison_Exp>;
  latest_comment_id?: InputMaybe<Int_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  location_geometry?: InputMaybe<Geometry_Comparison_Exp>;
  manager?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mobile_phone?: InputMaybe<String_Comparison_Exp>;
  mostRecentALAuthored?: InputMaybe<Comments_Bool_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_first_last?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  name_middle?: InputMaybe<String_Comparison_Exp>;
  overlays_rendered?: InputMaybe<Jsonb_Comparison_Exp>;
  overlays_rendered_full?: InputMaybe<Jsonb_Comparison_Exp>;
  override_source_group_filter?: InputMaybe<Boolean_Comparison_Exp>;
  partner_candidate_data?: InputMaybe<Jsonb_Comparison_Exp>;
  personal_email?: InputMaybe<String_Comparison_Exp>;
  place_of_birth?: InputMaybe<String_Comparison_Exp>;
  preferred_name?: InputMaybe<String_Comparison_Exp>;
  preliminary_start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile_picture_key?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<RaceEnum_Enum_Comparison_Exp>;
  recruitable?: InputMaybe<Boolean_Comparison_Exp>;
  referred_by?: InputMaybe<String_Comparison_Exp>;
  resource_manager_id?: InputMaybe<Int_Comparison_Exp>;
  resume?: InputMaybe<String_Comparison_Exp>;
  resumes_combined_txt?: InputMaybe<String_Comparison_Exp>;
  roles?: InputMaybe<Identity_Roles_View_Bool_Exp>;
  roles_aggregate?: InputMaybe<Identity_Roles_View_Aggregate_Bool_Exp>;
  salary?: InputMaybe<String_Comparison_Exp>;
  security_clearance_info?: InputMaybe<Jsonb_Comparison_Exp>;
  shirt_size?: InputMaybe<String_Comparison_Exp>;
  site_lead_id?: InputMaybe<Int_Comparison_Exp>;
  source_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  sub_type_id?: InputMaybe<Int_Comparison_Exp>;
  termination_description?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  ts_identities_fts?: InputMaybe<Tsvector_Comparison_Exp>;
  tsv?: InputMaybe<Tsvector_Comparison_Exp>;
  twilio_from?: InputMaybe<String_Comparison_Exp>;
  type_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  veteran_status?: InputMaybe<VeteranStatusEnum_Enum_Comparison_Exp>;
  work_email?: InputMaybe<String_Comparison_Exp>;
  work_phone?: InputMaybe<String_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Identities" */
export enum Identities_Constraint {
  /** unique or primary key constraint on columns "company_id", "personal_email" */
  IdentitiesPersonalEmailCompanyIdKey = 'Identities_personal_email_company_id_key',
  /** unique or primary key constraint on columns "id" */
  IdentitiesPkey = 'Identities_pkey',
  /** unique or primary key constraint on columns "twilio_from" */
  IdentitiesTwilioFromKey = 'Identities_twilio_from_key',
  /** unique or primary key constraint on columns "work_email" */
  IdentitiesWorkEmail = 'Identities_work_email',
  /** unique or primary key constraint on columns "etl_id_token" */
  IdentitiesUniqueEtlIdToken = 'identities_unique_etl_id_token',
  /** unique or primary key constraint on columns "company_id" */
  PersonalEmailCompanyIdIdx = 'personal_email_company_id_idx',
  /** unique or primary key constraint on columns "work_email" */
  WorkEmailNotDeletedIdx = 'work_email_not_deleted_idx'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Identities_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
  overlays_rendered?: InputMaybe<Array<Scalars['String']>>;
  overlays_rendered_full?: InputMaybe<Array<Scalars['String']>>;
  partner_candidate_data?: InputMaybe<Array<Scalars['String']>>;
  security_clearance_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Identities_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
  overlays_rendered?: InputMaybe<Scalars['Int']>;
  overlays_rendered_full?: InputMaybe<Scalars['Int']>;
  partner_candidate_data?: InputMaybe<Scalars['Int']>;
  security_clearance_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Identities_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  overlays_rendered?: InputMaybe<Scalars['String']>;
  overlays_rendered_full?: InputMaybe<Scalars['String']>;
  partner_candidate_data?: InputMaybe<Scalars['String']>;
  security_clearance_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Identities" */
export type Identities_Inc_Input = {
  account_manager_id?: InputMaybe<Scalars['Int']>;
  candidate_source_id?: InputMaybe<Scalars['Int']>;
  candidate_status_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  current_employment_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_update_counter?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  latest_al_id?: InputMaybe<Scalars['Int']>;
  latest_comment_id?: InputMaybe<Scalars['Int']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  site_lead_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_type_id?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Identities" */
export type Identities_Insert_Input = {
  APITokens?: InputMaybe<ApiTokens_Arr_Rel_Insert_Input>;
  Applications?: InputMaybe<Applications_Arr_Rel_Insert_Input>;
  Assignments?: InputMaybe<Assignments_Arr_Rel_Insert_Input>;
  AuditCreates?: InputMaybe<AuditCreates_Arr_Rel_Insert_Input>;
  AuditStages?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  BoardAccountManagers?: InputMaybe<Candidate_Account_Managers_Arr_Rel_Insert_Input>;
  BoardSnippets?: InputMaybe<BoardSnippets_Arr_Rel_Insert_Input>;
  Calculations?: InputMaybe<Calculations_Arr_Rel_Insert_Input>;
  CandidateSource?: InputMaybe<CandidateSources_Obj_Rel_Insert_Input>;
  CandidateStatus?: InputMaybe<CandidateStatuses_Obj_Rel_Insert_Input>;
  Citizenship?: InputMaybe<CitizenshipEnum_Obj_Rel_Insert_Input>;
  Comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Compensations?: InputMaybe<Compensations_Arr_Rel_Insert_Input>;
  ContractRelationships?: InputMaybe<IdentityContractRelationship_Arr_Rel_Insert_Input>;
  Contracts?: InputMaybe<Contracts_Arr_Rel_Insert_Input>;
  CurrentWorkflows?: InputMaybe<Current_Workflows_For_Identity_Arr_Rel_Insert_Input>;
  EarnedBadges?: InputMaybe<EarnedBadges_Arr_Rel_Insert_Input>;
  EmailRecords?: InputMaybe<EmailRecords_Arr_Rel_Insert_Input>;
  EmailSignatures?: InputMaybe<EmailSignatures_Arr_Rel_Insert_Input>;
  Employments?: InputMaybe<Employments_Arr_Rel_Insert_Input>;
  Entitlements?: InputMaybe<Entitlements_Arr_Rel_Insert_Input>;
  Files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  Goals?: InputMaybe<Goals_Arr_Rel_Insert_Input>;
  Group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  IdentityAssignments?: InputMaybe<IdentityAssignments_Arr_Rel_Insert_Input>;
  IdentityDepartmentRelationships?: InputMaybe<IdentityDepartmentRelationship_Arr_Rel_Insert_Input>;
  IdentityGroups?: InputMaybe<IdentityGroups_Arr_Rel_Insert_Input>;
  IdentityPartnerGroupData?: InputMaybe<IdentityPartnerGroupData_Arr_Rel_Insert_Input>;
  IdentityRoles?: InputMaybe<IdentityRoles_Arr_Rel_Insert_Input>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Obj_Rel_Insert_Input>;
  IdentityTags?: InputMaybe<IdentityTags_Arr_Rel_Insert_Input>;
  IdentityTokens?: InputMaybe<IdentityTokens_Arr_Rel_Insert_Input>;
  IdentityType?: InputMaybe<IdentityTypes_Obj_Rel_Insert_Input>;
  Images?: InputMaybe<Images_Arr_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  JobRelationships?: InputMaybe<IdentityJobRelationship_Arr_Rel_Insert_Input>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Arr_Rel_Insert_Input>;
  MostRecentComment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  OverlayLinks?: InputMaybe<IdentitiesOverlaysLinks_Arr_Rel_Insert_Input>;
  PointOfContactGroups?: InputMaybe<Groups_Arr_Rel_Insert_Input>;
  Race?: InputMaybe<RaceEnum_Obj_Rel_Insert_Input>;
  ResumeClaims?: InputMaybe<ResumeClaims_Arr_Rel_Insert_Input>;
  Resumes?: InputMaybe<Resumes_Arr_Rel_Insert_Input>;
  Reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  SourceGroup?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  Submissions?: InputMaybe<Submissions_Arr_Rel_Insert_Input>;
  Subtype?: InputMaybe<IdentitySubTypes_Obj_Rel_Insert_Input>;
  TableConfigs?: InputMaybe<TableConfigs_Arr_Rel_Insert_Input>;
  TaskGroupAssignees?: InputMaybe<TaskGroupAssignees_Arr_Rel_Insert_Input>;
  TaskGroups?: InputMaybe<TaskGroups_Arr_Rel_Insert_Input>;
  Tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  Terminations?: InputMaybe<Terminations_Arr_Rel_Insert_Input>;
  TimelineEvents?: InputMaybe<TimelineEvents_Arr_Rel_Insert_Input>;
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  assignmentsByOwnerId?: InputMaybe<Assignments_Arr_Rel_Insert_Input>;
  auditStagesByAuthorId?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  auditStagesByIdentityId?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  auditStagesByRmId?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  author?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  boardSnippetsByRmId?: InputMaybe<BoardSnippets_Arr_Rel_Insert_Input>;
  calculationsByIdentityId?: InputMaybe<Calculations_Arr_Rel_Insert_Input>;
  candidate_source_id?: InputMaybe<Scalars['Int']>;
  candidate_status_id?: InputMaybe<Scalars['Int']>;
  citizenship?: InputMaybe<CitizenshipEnum_Enum>;
  citizenshipEnum_enum?: InputMaybe<CitizenshipEnum_Obj_Rel_Insert_Input>;
  city?: InputMaybe<Scalars['String']>;
  clearance?: InputMaybe<Scalars['String']>;
  clearance_eligible?: InputMaybe<Scalars['Boolean']>;
  cog_id?: InputMaybe<Scalars['String']>;
  commentsByIdentityId?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  contractsBySiteLeadId?: InputMaybe<Contracts_Arr_Rel_Insert_Input>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  currentEmployment?: InputMaybe<Employments_Obj_Rel_Insert_Input>;
  current_employer?: InputMaybe<Scalars['String']>;
  current_employment_id?: InputMaybe<Scalars['Int']>;
  date_of_birth?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  /** Direct or Indirect */
  designation?: InputMaybe<Scalars['String']>;
  emergency_contact?: InputMaybe<Scalars['String']>;
  emergency_contact_phone?: InputMaybe<Scalars['String']>;
  /** Full Time or Part Time */
  employee_status?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  /** Exempt or Non-Exempt */
  flsa?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum_Enum>;
  gov_contract_participant?: InputMaybe<Scalars['Boolean']>;
  gov_employment_status?: InputMaybe<Scalars['String']>;
  has_disability?: InputMaybe<DisabilityStatusEnum_Enum>;
  hex_color?: InputMaybe<Scalars['String']>;
  highest_stage?: InputMaybe<HighestStages_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  identityByAccountManagerId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identityByResourceManagerId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identityBySiteLeadId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  img?: InputMaybe<Scalars['String']>;
  internal?: InputMaybe<Scalars['Boolean']>;
  internal_update_counter?: InputMaybe<Scalars['Int']>;
  is_veteran?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  labor_category?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  latest_al_id?: InputMaybe<Scalars['Int']>;
  latest_comment_id?: InputMaybe<Scalars['Int']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  manager?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  mostRecentALAuthored?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  name_middle?: InputMaybe<Scalars['String']>;
  overlays_rendered?: InputMaybe<Scalars['jsonb']>;
  overlays_rendered_full?: InputMaybe<Scalars['jsonb']>;
  override_source_group_filter?: InputMaybe<Scalars['Boolean']>;
  partner_candidate_data?: InputMaybe<Scalars['jsonb']>;
  personal_email?: InputMaybe<Scalars['String']>;
  place_of_birth?: InputMaybe<Scalars['String']>;
  preferred_name?: InputMaybe<Scalars['String']>;
  preliminary_start_date?: InputMaybe<Scalars['timestamptz']>;
  profile_picture_key?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<RaceEnum_Enum>;
  recruitable?: InputMaybe<Scalars['Boolean']>;
  referred_by?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  resume?: InputMaybe<Scalars['String']>;
  resumes_combined_txt?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Identity_Roles_View_Arr_Rel_Insert_Input>;
  salary?: InputMaybe<Scalars['String']>;
  security_clearance_info?: InputMaybe<Scalars['jsonb']>;
  shirt_size?: InputMaybe<Scalars['String']>;
  site_lead_id?: InputMaybe<Scalars['Int']>;
  source_group_id?: InputMaybe<Scalars['uuid']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  /** deprecated */
  status?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  sub_type_id?: InputMaybe<Scalars['Int']>;
  termination_description?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  ts_identities_fts?: InputMaybe<Scalars['tsvector']>;
  tsv?: InputMaybe<Scalars['tsvector']>;
  twilio_from?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  veteran_status?: InputMaybe<VeteranStatusEnum_Enum>;
  work_email?: InputMaybe<Scalars['String']>;
  work_phone?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Identities_Max_Fields = {
  __typename?: 'Identities_max_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  candidate_source_id?: Maybe<Scalars['Int']>;
  candidate_status_id?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  clearance?: Maybe<Scalars['String']>;
  cog_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  current_employer?: Maybe<Scalars['String']>;
  current_employment_id?: Maybe<Scalars['Int']>;
  date_of_birth?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** Direct or Indirect */
  designation?: Maybe<Scalars['String']>;
  emergency_contact?: Maybe<Scalars['String']>;
  emergency_contact_phone?: Maybe<Scalars['String']>;
  /** Full Time or Part Time */
  employee_status?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  /** A computed field, executes function "first_comment_dt" */
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  /** Exempt or Non-Exempt */
  flsa?: Maybe<Scalars['String']>;
  gov_employment_status?: Maybe<Scalars['String']>;
  hex_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "identity_identifier" */
  identifier?: Maybe<Scalars['String']>;
  img?: Maybe<Scalars['String']>;
  internal_update_counter?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  last_comment_txt?: Maybe<Scalars['String']>;
  latest_al_id?: Maybe<Scalars['Int']>;
  latest_comment_id?: Maybe<Scalars['Int']>;
  linkedin_url?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  place_of_birth?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  preliminary_start_date?: Maybe<Scalars['timestamptz']>;
  profile_picture_key?: Maybe<Scalars['String']>;
  referred_by?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  resume?: Maybe<Scalars['String']>;
  resumes_combined_txt?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  shirt_size?: Maybe<Scalars['String']>;
  site_lead_id?: Maybe<Scalars['Int']>;
  source_group_id?: Maybe<Scalars['uuid']>;
  stage_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  /** deprecated */
  status?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['Int']>;
  termination_description?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  twilio_from?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Identities" */
export type Identities_Max_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clearance?: InputMaybe<Order_By>;
  cog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employer?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  /** Direct or Indirect */
  designation?: InputMaybe<Order_By>;
  emergency_contact?: InputMaybe<Order_By>;
  emergency_contact_phone?: InputMaybe<Order_By>;
  /** Full Time or Part Time */
  employee_status?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  /** Exempt or Non-Exempt */
  flsa?: InputMaybe<Order_By>;
  gov_employment_status?: InputMaybe<Order_By>;
  hex_color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  manager?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  name_middle?: InputMaybe<Order_By>;
  personal_email?: InputMaybe<Order_By>;
  place_of_birth?: InputMaybe<Order_By>;
  preferred_name?: InputMaybe<Order_By>;
  preliminary_start_date?: InputMaybe<Order_By>;
  profile_picture_key?: InputMaybe<Order_By>;
  referred_by?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  resumes_combined_txt?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  shirt_size?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  source_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  /** deprecated */
  status?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  termination_description?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  twilio_from?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_email?: InputMaybe<Order_By>;
  work_phone?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Identities_Min_Fields = {
  __typename?: 'Identities_min_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  candidate_source_id?: Maybe<Scalars['Int']>;
  candidate_status_id?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  clearance?: Maybe<Scalars['String']>;
  cog_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  current_employer?: Maybe<Scalars['String']>;
  current_employment_id?: Maybe<Scalars['Int']>;
  date_of_birth?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** Direct or Indirect */
  designation?: Maybe<Scalars['String']>;
  emergency_contact?: Maybe<Scalars['String']>;
  emergency_contact_phone?: Maybe<Scalars['String']>;
  /** Full Time or Part Time */
  employee_status?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  /** A computed field, executes function "first_comment_dt" */
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  /** Exempt or Non-Exempt */
  flsa?: Maybe<Scalars['String']>;
  gov_employment_status?: Maybe<Scalars['String']>;
  hex_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "identity_identifier" */
  identifier?: Maybe<Scalars['String']>;
  img?: Maybe<Scalars['String']>;
  internal_update_counter?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  last_comment_txt?: Maybe<Scalars['String']>;
  latest_al_id?: Maybe<Scalars['Int']>;
  latest_comment_id?: Maybe<Scalars['Int']>;
  linkedin_url?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  place_of_birth?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  preliminary_start_date?: Maybe<Scalars['timestamptz']>;
  profile_picture_key?: Maybe<Scalars['String']>;
  referred_by?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  resume?: Maybe<Scalars['String']>;
  resumes_combined_txt?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  shirt_size?: Maybe<Scalars['String']>;
  site_lead_id?: Maybe<Scalars['Int']>;
  source_group_id?: Maybe<Scalars['uuid']>;
  stage_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  /** deprecated */
  status?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['Int']>;
  termination_description?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  twilio_from?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_email?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Identities" */
export type Identities_Min_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clearance?: InputMaybe<Order_By>;
  cog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employer?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  /** Direct or Indirect */
  designation?: InputMaybe<Order_By>;
  emergency_contact?: InputMaybe<Order_By>;
  emergency_contact_phone?: InputMaybe<Order_By>;
  /** Full Time or Part Time */
  employee_status?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  /** Exempt or Non-Exempt */
  flsa?: InputMaybe<Order_By>;
  gov_employment_status?: InputMaybe<Order_By>;
  hex_color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  manager?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  name_middle?: InputMaybe<Order_By>;
  personal_email?: InputMaybe<Order_By>;
  place_of_birth?: InputMaybe<Order_By>;
  preferred_name?: InputMaybe<Order_By>;
  preliminary_start_date?: InputMaybe<Order_By>;
  profile_picture_key?: InputMaybe<Order_By>;
  referred_by?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  resumes_combined_txt?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  shirt_size?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  source_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  /** deprecated */
  status?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  termination_description?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  twilio_from?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_email?: InputMaybe<Order_By>;
  work_phone?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Identities" */
export type Identities_Mutation_Response = {
  __typename?: 'Identities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Identities>;
};

/** input type for inserting object relation for remote table "Identities" */
export type Identities_Obj_Rel_Insert_Input = {
  data: Identities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Identities_On_Conflict>;
};

/** on_conflict condition type for table "Identities" */
export type Identities_On_Conflict = {
  constraint: Identities_Constraint;
  update_columns?: Array<Identities_Update_Column>;
  where?: InputMaybe<Identities_Bool_Exp>;
};

/** Ordering options when selecting data from "Identities". */
export type Identities_Order_By = {
  APITokens_aggregate?: InputMaybe<ApiTokens_Aggregate_Order_By>;
  Applications_aggregate?: InputMaybe<Applications_Aggregate_Order_By>;
  Assignments_aggregate?: InputMaybe<Assignments_Aggregate_Order_By>;
  AuditCreates_aggregate?: InputMaybe<AuditCreates_Aggregate_Order_By>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  BoardAccountManagers_aggregate?: InputMaybe<Candidate_Account_Managers_Aggregate_Order_By>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Order_By>;
  Calculations_aggregate?: InputMaybe<Calculations_Aggregate_Order_By>;
  CandidateSource?: InputMaybe<CandidateSources_Order_By>;
  CandidateStatus?: InputMaybe<CandidateStatuses_Order_By>;
  Citizenship?: InputMaybe<CitizenshipEnum_Order_By>;
  Comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  Compensations_aggregate?: InputMaybe<Compensations_Aggregate_Order_By>;
  ContractRelationships_aggregate?: InputMaybe<IdentityContractRelationship_Aggregate_Order_By>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Order_By>;
  CurrentWorkflows_aggregate?: InputMaybe<Current_Workflows_For_Identity_Aggregate_Order_By>;
  EarnedBadges_aggregate?: InputMaybe<EarnedBadges_Aggregate_Order_By>;
  EmailRecords_aggregate?: InputMaybe<EmailRecords_Aggregate_Order_By>;
  EmailSignatures_aggregate?: InputMaybe<EmailSignatures_Aggregate_Order_By>;
  Employments_aggregate?: InputMaybe<Employments_Aggregate_Order_By>;
  Entitlements_aggregate?: InputMaybe<Entitlements_Aggregate_Order_By>;
  Files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  Goals_aggregate?: InputMaybe<Goals_Aggregate_Order_By>;
  Group?: InputMaybe<Groups_Order_By>;
  IdentityAssignments_aggregate?: InputMaybe<IdentityAssignments_Aggregate_Order_By>;
  IdentityDepartmentRelationships_aggregate?: InputMaybe<IdentityDepartmentRelationship_Aggregate_Order_By>;
  IdentityGroups_aggregate?: InputMaybe<IdentityGroups_Aggregate_Order_By>;
  IdentityPartnerGroupData_aggregate?: InputMaybe<IdentityPartnerGroupData_Aggregate_Order_By>;
  IdentityRoles_aggregate?: InputMaybe<IdentityRoles_Aggregate_Order_By>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Order_By>;
  IdentityTags_aggregate?: InputMaybe<IdentityTags_Aggregate_Order_By>;
  IdentityTokens_aggregate?: InputMaybe<IdentityTokens_Aggregate_Order_By>;
  IdentityType?: InputMaybe<IdentityTypes_Order_By>;
  Images_aggregate?: InputMaybe<Images_Aggregate_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  JobRelationships_aggregate?: InputMaybe<IdentityJobRelationship_Aggregate_Order_By>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Order_By>;
  MostRecentComment?: InputMaybe<Comments_Order_By>;
  OverlayLinks_aggregate?: InputMaybe<IdentitiesOverlaysLinks_Aggregate_Order_By>;
  PointOfContactGroups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
  Race?: InputMaybe<RaceEnum_Order_By>;
  ResumeClaims_aggregate?: InputMaybe<ResumeClaims_Aggregate_Order_By>;
  Resumes_aggregate?: InputMaybe<Resumes_Aggregate_Order_By>;
  Reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  SourceGroup?: InputMaybe<Groups_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  Submissions_aggregate?: InputMaybe<Submissions_Aggregate_Order_By>;
  Subtype?: InputMaybe<IdentitySubTypes_Order_By>;
  TableConfigs_aggregate?: InputMaybe<TableConfigs_Aggregate_Order_By>;
  TaskGroupAssignees_aggregate?: InputMaybe<TaskGroupAssignees_Aggregate_Order_By>;
  TaskGroups_aggregate?: InputMaybe<TaskGroups_Aggregate_Order_By>;
  Tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  Terminations_aggregate?: InputMaybe<Terminations_Aggregate_Order_By>;
  TimelineEvents_aggregate?: InputMaybe<TimelineEvents_Aggregate_Order_By>;
  _search?: InputMaybe<Order_By>;
  account_manager_id?: InputMaybe<Order_By>;
  assignmentsByOwnerId_aggregate?: InputMaybe<Assignments_Aggregate_Order_By>;
  auditStagesByAuthorId_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  auditStagesByIdentityId_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  auditStagesByRmId_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  author?: InputMaybe<Identities_Order_By>;
  boardSnippetsByRmId_aggregate?: InputMaybe<BoardSnippets_Aggregate_Order_By>;
  calculationsByIdentityId_aggregate?: InputMaybe<Calculations_Aggregate_Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  citizenship?: InputMaybe<Order_By>;
  citizenshipEnum_enum?: InputMaybe<CitizenshipEnum_Order_By>;
  city?: InputMaybe<Order_By>;
  clearance?: InputMaybe<Order_By>;
  clearance_eligible?: InputMaybe<Order_By>;
  cog_id?: InputMaybe<Order_By>;
  commentsByIdentityId_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  contractsBySiteLeadId_aggregate?: InputMaybe<Contracts_Aggregate_Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  currentEmployment?: InputMaybe<Employments_Order_By>;
  current_employer?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  designation?: InputMaybe<Order_By>;
  emergency_contact?: InputMaybe<Order_By>;
  emergency_contact_phone?: InputMaybe<Order_By>;
  employee_status?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  first_comment_dt?: InputMaybe<Order_By>;
  flsa?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  global_search_idx?: InputMaybe<Order_By>;
  gov_contract_participant?: InputMaybe<Order_By>;
  gov_employment_status?: InputMaybe<Order_By>;
  has_disability?: InputMaybe<Order_By>;
  hex_color?: InputMaybe<Order_By>;
  highest_stage?: InputMaybe<HighestStages_Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  identityByAccountManagerId?: InputMaybe<Identities_Order_By>;
  identityByResourceManagerId?: InputMaybe<Identities_Order_By>;
  identityBySiteLeadId?: InputMaybe<Identities_Order_By>;
  img?: InputMaybe<Order_By>;
  internal?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  is_hispanic_latino?: InputMaybe<Order_By>;
  is_on_board?: InputMaybe<Order_By>;
  is_service_account?: InputMaybe<Order_By>;
  is_veteran?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  last_comment_dt?: InputMaybe<Order_By>;
  last_comment_txt?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  location_geometry?: InputMaybe<Order_By>;
  manager?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  mostRecentALAuthored?: InputMaybe<Comments_Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_first_last?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  name_middle?: InputMaybe<Order_By>;
  overlays_rendered?: InputMaybe<Order_By>;
  overlays_rendered_full?: InputMaybe<Order_By>;
  override_source_group_filter?: InputMaybe<Order_By>;
  partner_candidate_data?: InputMaybe<Order_By>;
  personal_email?: InputMaybe<Order_By>;
  place_of_birth?: InputMaybe<Order_By>;
  preferred_name?: InputMaybe<Order_By>;
  preliminary_start_date?: InputMaybe<Order_By>;
  profile_picture_key?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
  recruitable?: InputMaybe<Order_By>;
  referred_by?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  resumes_combined_txt?: InputMaybe<Order_By>;
  roles_aggregate?: InputMaybe<Identity_Roles_View_Aggregate_Order_By>;
  salary?: InputMaybe<Order_By>;
  security_clearance_info?: InputMaybe<Order_By>;
  shirt_size?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  source_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  termination_description?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  ts_identities_fts?: InputMaybe<Order_By>;
  tsv?: InputMaybe<Order_By>;
  twilio_from?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
  work_email?: InputMaybe<Order_By>;
  work_phone?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Identities */
export type Identities_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Identities_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  overlays_rendered?: InputMaybe<Scalars['jsonb']>;
  overlays_rendered_full?: InputMaybe<Scalars['jsonb']>;
  partner_candidate_data?: InputMaybe<Scalars['jsonb']>;
  security_clearance_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Identities" */
export enum Identities_Select_Column {
  /** column name */
  Search = '_search',
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  CandidateSourceId = 'candidate_source_id',
  /** column name */
  CandidateStatusId = 'candidate_status_id',
  /** column name */
  Citizenship = 'citizenship',
  /** column name */
  City = 'city',
  /** column name */
  Clearance = 'clearance',
  /** column name */
  ClearanceEligible = 'clearance_eligible',
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentEmployer = 'current_employer',
  /** column name */
  CurrentEmploymentId = 'current_employment_id',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  Designation = 'designation',
  /** column name */
  EmergencyContact = 'emergency_contact',
  /** column name */
  EmergencyContactPhone = 'emergency_contact_phone',
  /** column name */
  EmployeeStatus = 'employee_status',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  Flsa = 'flsa',
  /** column name */
  Gender = 'gender',
  /** column name */
  GlobalSearchIdx = 'global_search_idx',
  /** column name */
  GovContractParticipant = 'gov_contract_participant',
  /** column name */
  GovEmploymentStatus = 'gov_employment_status',
  /** column name */
  HasDisability = 'has_disability',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Internal = 'internal',
  /** column name */
  InternalUpdateCounter = 'internal_update_counter',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  IsServiceAccount = 'is_service_account',
  /** column name */
  IsVeteran = 'is_veteran',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  LatestAlId = 'latest_al_id',
  /** column name */
  LatestCommentId = 'latest_comment_id',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  LocationGeometry = 'location_geometry',
  /** column name */
  Manager = 'manager',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  OverlaysRendered = 'overlays_rendered',
  /** column name */
  OverlaysRenderedFull = 'overlays_rendered_full',
  /** column name */
  OverrideSourceGroupFilter = 'override_source_group_filter',
  /** column name */
  PartnerCandidateData = 'partner_candidate_data',
  /** column name */
  PersonalEmail = 'personal_email',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  PreferredName = 'preferred_name',
  /** column name */
  PreliminaryStartDate = 'preliminary_start_date',
  /** column name */
  ProfilePictureKey = 'profile_picture_key',
  /** column name */
  Race = 'race',
  /** column name */
  Recruitable = 'recruitable',
  /** column name */
  ReferredBy = 'referred_by',
  /** column name */
  ResourceManagerId = 'resource_manager_id',
  /** column name */
  Resume = 'resume',
  /** column name */
  ResumesCombinedTxt = 'resumes_combined_txt',
  /** column name */
  Salary = 'salary',
  /** column name */
  SecurityClearanceInfo = 'security_clearance_info',
  /** column name */
  ShirtSize = 'shirt_size',
  /** column name */
  SiteLeadId = 'site_lead_id',
  /** column name */
  SourceGroupId = 'source_group_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Street = 'street',
  /** column name */
  SubTypeId = 'sub_type_id',
  /** column name */
  TerminationDescription = 'termination_description',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  TsIdentitiesFts = 'ts_identities_fts',
  /** column name */
  Tsv = 'tsv',
  /** column name */
  TwilioFrom = 'twilio_from',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VeteranStatus = 'veteran_status',
  /** column name */
  WorkEmail = 'work_email',
  /** column name */
  WorkPhone = 'work_phone',
  /** column name */
  Zip = 'zip'
}

/** select "Identities_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Identities" */
export enum Identities_Select_Column_Identities_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ClearanceEligible = 'clearance_eligible',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GovContractParticipant = 'gov_contract_participant',
  /** column name */
  Internal = 'internal',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  IsServiceAccount = 'is_service_account',
  /** column name */
  IsVeteran = 'is_veteran',
  /** column name */
  OverrideSourceGroupFilter = 'override_source_group_filter',
  /** column name */
  Recruitable = 'recruitable'
}

/** select "Identities_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Identities" */
export enum Identities_Select_Column_Identities_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ClearanceEligible = 'clearance_eligible',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GovContractParticipant = 'gov_contract_participant',
  /** column name */
  Internal = 'internal',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  IsServiceAccount = 'is_service_account',
  /** column name */
  IsVeteran = 'is_veteran',
  /** column name */
  OverrideSourceGroupFilter = 'override_source_group_filter',
  /** column name */
  Recruitable = 'recruitable'
}

/** input type for updating data in table "Identities" */
export type Identities_Set_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  candidate_source_id?: InputMaybe<Scalars['Int']>;
  candidate_status_id?: InputMaybe<Scalars['Int']>;
  citizenship?: InputMaybe<CitizenshipEnum_Enum>;
  city?: InputMaybe<Scalars['String']>;
  clearance?: InputMaybe<Scalars['String']>;
  clearance_eligible?: InputMaybe<Scalars['Boolean']>;
  cog_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  current_employer?: InputMaybe<Scalars['String']>;
  current_employment_id?: InputMaybe<Scalars['Int']>;
  date_of_birth?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  /** Direct or Indirect */
  designation?: InputMaybe<Scalars['String']>;
  emergency_contact?: InputMaybe<Scalars['String']>;
  emergency_contact_phone?: InputMaybe<Scalars['String']>;
  /** Full Time or Part Time */
  employee_status?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  /** Exempt or Non-Exempt */
  flsa?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum_Enum>;
  gov_contract_participant?: InputMaybe<Scalars['Boolean']>;
  gov_employment_status?: InputMaybe<Scalars['String']>;
  has_disability?: InputMaybe<DisabilityStatusEnum_Enum>;
  hex_color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  internal?: InputMaybe<Scalars['Boolean']>;
  internal_update_counter?: InputMaybe<Scalars['Int']>;
  is_veteran?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  labor_category?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  latest_al_id?: InputMaybe<Scalars['Int']>;
  latest_comment_id?: InputMaybe<Scalars['Int']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  manager?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  name_middle?: InputMaybe<Scalars['String']>;
  overlays_rendered?: InputMaybe<Scalars['jsonb']>;
  overlays_rendered_full?: InputMaybe<Scalars['jsonb']>;
  override_source_group_filter?: InputMaybe<Scalars['Boolean']>;
  partner_candidate_data?: InputMaybe<Scalars['jsonb']>;
  personal_email?: InputMaybe<Scalars['String']>;
  place_of_birth?: InputMaybe<Scalars['String']>;
  preferred_name?: InputMaybe<Scalars['String']>;
  preliminary_start_date?: InputMaybe<Scalars['timestamptz']>;
  profile_picture_key?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<RaceEnum_Enum>;
  recruitable?: InputMaybe<Scalars['Boolean']>;
  referred_by?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  resume?: InputMaybe<Scalars['String']>;
  resumes_combined_txt?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['String']>;
  security_clearance_info?: InputMaybe<Scalars['jsonb']>;
  shirt_size?: InputMaybe<Scalars['String']>;
  site_lead_id?: InputMaybe<Scalars['Int']>;
  source_group_id?: InputMaybe<Scalars['uuid']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  /** deprecated */
  status?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  sub_type_id?: InputMaybe<Scalars['Int']>;
  termination_description?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  ts_identities_fts?: InputMaybe<Scalars['tsvector']>;
  tsv?: InputMaybe<Scalars['tsvector']>;
  twilio_from?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  veteran_status?: InputMaybe<VeteranStatusEnum_Enum>;
  work_email?: InputMaybe<Scalars['String']>;
  work_phone?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Identities_Stddev_Fields = {
  __typename?: 'Identities_stddev_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  candidate_source_id?: Maybe<Scalars['Float']>;
  candidate_status_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_employment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_update_counter?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  latest_al_id?: Maybe<Scalars['Float']>;
  latest_comment_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  site_lead_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_type_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Identities" */
export type Identities_Stddev_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Identities_Stddev_Pop_Fields = {
  __typename?: 'Identities_stddev_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  candidate_source_id?: Maybe<Scalars['Float']>;
  candidate_status_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_employment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_update_counter?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  latest_al_id?: Maybe<Scalars['Float']>;
  latest_comment_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  site_lead_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_type_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Identities" */
export type Identities_Stddev_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Identities_Stddev_Samp_Fields = {
  __typename?: 'Identities_stddev_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  candidate_source_id?: Maybe<Scalars['Float']>;
  candidate_status_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_employment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_update_counter?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  latest_al_id?: Maybe<Scalars['Float']>;
  latest_comment_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  site_lead_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_type_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Identities" */
export type Identities_Stddev_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Identities" */
export type Identities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identities_Stream_Cursor_Value_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  candidate_source_id?: InputMaybe<Scalars['Int']>;
  candidate_status_id?: InputMaybe<Scalars['Int']>;
  citizenship?: InputMaybe<CitizenshipEnum_Enum>;
  city?: InputMaybe<Scalars['String']>;
  clearance?: InputMaybe<Scalars['String']>;
  clearance_eligible?: InputMaybe<Scalars['Boolean']>;
  cog_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  current_employer?: InputMaybe<Scalars['String']>;
  current_employment_id?: InputMaybe<Scalars['Int']>;
  date_of_birth?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  /** Direct or Indirect */
  designation?: InputMaybe<Scalars['String']>;
  emergency_contact?: InputMaybe<Scalars['String']>;
  emergency_contact_phone?: InputMaybe<Scalars['String']>;
  /** Full Time or Part Time */
  employee_status?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  /** Exempt or Non-Exempt */
  flsa?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum_Enum>;
  global_search_idx?: InputMaybe<Scalars['tsvector']>;
  gov_contract_participant?: InputMaybe<Scalars['Boolean']>;
  gov_employment_status?: InputMaybe<Scalars['String']>;
  has_disability?: InputMaybe<DisabilityStatusEnum_Enum>;
  hex_color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  internal?: InputMaybe<Scalars['Boolean']>;
  internal_update_counter?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  is_service_account?: InputMaybe<Scalars['Boolean']>;
  is_veteran?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  labor_category?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  latest_al_id?: InputMaybe<Scalars['Int']>;
  latest_comment_id?: InputMaybe<Scalars['Int']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  manager?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  name_middle?: InputMaybe<Scalars['String']>;
  overlays_rendered?: InputMaybe<Scalars['jsonb']>;
  overlays_rendered_full?: InputMaybe<Scalars['jsonb']>;
  override_source_group_filter?: InputMaybe<Scalars['Boolean']>;
  partner_candidate_data?: InputMaybe<Scalars['jsonb']>;
  personal_email?: InputMaybe<Scalars['String']>;
  place_of_birth?: InputMaybe<Scalars['String']>;
  preferred_name?: InputMaybe<Scalars['String']>;
  preliminary_start_date?: InputMaybe<Scalars['timestamptz']>;
  profile_picture_key?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<RaceEnum_Enum>;
  recruitable?: InputMaybe<Scalars['Boolean']>;
  referred_by?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  resume?: InputMaybe<Scalars['String']>;
  resumes_combined_txt?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['String']>;
  security_clearance_info?: InputMaybe<Scalars['jsonb']>;
  shirt_size?: InputMaybe<Scalars['String']>;
  site_lead_id?: InputMaybe<Scalars['Int']>;
  source_group_id?: InputMaybe<Scalars['uuid']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  /** deprecated */
  status?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  sub_type_id?: InputMaybe<Scalars['Int']>;
  termination_description?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  ts_identities_fts?: InputMaybe<Scalars['tsvector']>;
  tsv?: InputMaybe<Scalars['tsvector']>;
  twilio_from?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  veteran_status?: InputMaybe<VeteranStatusEnum_Enum>;
  work_email?: InputMaybe<Scalars['String']>;
  work_phone?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Identities_Sum_Fields = {
  __typename?: 'Identities_sum_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  candidate_source_id?: Maybe<Scalars['Int']>;
  candidate_status_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  current_employment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  internal_update_counter?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  latest_al_id?: Maybe<Scalars['Int']>;
  latest_comment_id?: Maybe<Scalars['Int']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  site_lead_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  sub_type_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Identities" */
export type Identities_Sum_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** update columns of table "Identities" */
export enum Identities_Update_Column {
  /** column name */
  Search = '_search',
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  CandidateSourceId = 'candidate_source_id',
  /** column name */
  CandidateStatusId = 'candidate_status_id',
  /** column name */
  Citizenship = 'citizenship',
  /** column name */
  City = 'city',
  /** column name */
  Clearance = 'clearance',
  /** column name */
  ClearanceEligible = 'clearance_eligible',
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentEmployer = 'current_employer',
  /** column name */
  CurrentEmploymentId = 'current_employment_id',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  Designation = 'designation',
  /** column name */
  EmergencyContact = 'emergency_contact',
  /** column name */
  EmergencyContactPhone = 'emergency_contact_phone',
  /** column name */
  EmployeeStatus = 'employee_status',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  Flsa = 'flsa',
  /** column name */
  Gender = 'gender',
  /** column name */
  GovContractParticipant = 'gov_contract_participant',
  /** column name */
  GovEmploymentStatus = 'gov_employment_status',
  /** column name */
  HasDisability = 'has_disability',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Internal = 'internal',
  /** column name */
  InternalUpdateCounter = 'internal_update_counter',
  /** column name */
  IsVeteran = 'is_veteran',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  LatestAlId = 'latest_al_id',
  /** column name */
  LatestCommentId = 'latest_comment_id',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  LocationGeometry = 'location_geometry',
  /** column name */
  Manager = 'manager',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  OverlaysRendered = 'overlays_rendered',
  /** column name */
  OverlaysRenderedFull = 'overlays_rendered_full',
  /** column name */
  OverrideSourceGroupFilter = 'override_source_group_filter',
  /** column name */
  PartnerCandidateData = 'partner_candidate_data',
  /** column name */
  PersonalEmail = 'personal_email',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  PreferredName = 'preferred_name',
  /** column name */
  PreliminaryStartDate = 'preliminary_start_date',
  /** column name */
  ProfilePictureKey = 'profile_picture_key',
  /** column name */
  Race = 'race',
  /** column name */
  Recruitable = 'recruitable',
  /** column name */
  ReferredBy = 'referred_by',
  /** column name */
  ResourceManagerId = 'resource_manager_id',
  /** column name */
  Resume = 'resume',
  /** column name */
  ResumesCombinedTxt = 'resumes_combined_txt',
  /** column name */
  Salary = 'salary',
  /** column name */
  SecurityClearanceInfo = 'security_clearance_info',
  /** column name */
  ShirtSize = 'shirt_size',
  /** column name */
  SiteLeadId = 'site_lead_id',
  /** column name */
  SourceGroupId = 'source_group_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Street = 'street',
  /** column name */
  SubTypeId = 'sub_type_id',
  /** column name */
  TerminationDescription = 'termination_description',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  TsIdentitiesFts = 'ts_identities_fts',
  /** column name */
  Tsv = 'tsv',
  /** column name */
  TwilioFrom = 'twilio_from',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VeteranStatus = 'veteran_status',
  /** column name */
  WorkEmail = 'work_email',
  /** column name */
  WorkPhone = 'work_phone',
  /** column name */
  Zip = 'zip'
}

export type Identities_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Identities_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Identities_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Identities_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Identities_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Identities_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Identities_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Identities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Identities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Identities_Var_Pop_Fields = {
  __typename?: 'Identities_var_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  candidate_source_id?: Maybe<Scalars['Float']>;
  candidate_status_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_employment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_update_counter?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  latest_al_id?: Maybe<Scalars['Float']>;
  latest_comment_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  site_lead_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_type_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Identities" */
export type Identities_Var_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Identities_Var_Samp_Fields = {
  __typename?: 'Identities_var_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  candidate_source_id?: Maybe<Scalars['Float']>;
  candidate_status_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_employment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_update_counter?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  latest_al_id?: Maybe<Scalars['Float']>;
  latest_comment_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  site_lead_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_type_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Identities" */
export type Identities_Var_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Identities_Variance_Fields = {
  __typename?: 'Identities_variance_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  candidate_source_id?: Maybe<Scalars['Float']>;
  candidate_status_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_employment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_update_counter?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  latest_al_id?: Maybe<Scalars['Float']>;
  latest_comment_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  site_lead_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  sub_type_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Identities" */
export type Identities_Variance_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  candidate_source_id?: InputMaybe<Order_By>;
  candidate_status_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  current_employment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_update_counter?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latest_al_id?: InputMaybe<Order_By>;
  latest_comment_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  site_lead_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "IdentityAssignments" */
export type IdentityAssignments = {
  __typename?: 'IdentityAssignments';
  /** An object relationship */
  Assignment: Assignments;
  /** An object relationship */
  Identity: Identities;
  assignmentId: Scalars['Int'];
  completed?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  identityId: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentityAssignments" */
export type IdentityAssignments_Aggregate = {
  __typename?: 'IdentityAssignments_aggregate';
  aggregate?: Maybe<IdentityAssignments_Aggregate_Fields>;
  nodes: Array<IdentityAssignments>;
};

export type IdentityAssignments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<IdentityAssignments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<IdentityAssignments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<IdentityAssignments_Aggregate_Bool_Exp_Count>;
};

export type IdentityAssignments_Aggregate_Bool_Exp_Bool_And = {
  arguments: IdentityAssignments_Select_Column_IdentityAssignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityAssignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type IdentityAssignments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IdentityAssignments_Select_Column_IdentityAssignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityAssignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type IdentityAssignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityAssignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityAssignments" */
export type IdentityAssignments_Aggregate_Fields = {
  __typename?: 'IdentityAssignments_aggregate_fields';
  avg?: Maybe<IdentityAssignments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityAssignments_Max_Fields>;
  min?: Maybe<IdentityAssignments_Min_Fields>;
  stddev?: Maybe<IdentityAssignments_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityAssignments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityAssignments_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityAssignments_Sum_Fields>;
  var_pop?: Maybe<IdentityAssignments_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityAssignments_Var_Samp_Fields>;
  variance?: Maybe<IdentityAssignments_Variance_Fields>;
};


/** aggregate fields of "IdentityAssignments" */
export type IdentityAssignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityAssignments" */
export type IdentityAssignments_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityAssignments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityAssignments_Max_Order_By>;
  min?: InputMaybe<IdentityAssignments_Min_Order_By>;
  stddev?: InputMaybe<IdentityAssignments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityAssignments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityAssignments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityAssignments_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityAssignments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityAssignments_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityAssignments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityAssignments" */
export type IdentityAssignments_Arr_Rel_Insert_Input = {
  data: Array<IdentityAssignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityAssignments_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityAssignments_Avg_Fields = {
  __typename?: 'IdentityAssignments_avg_fields';
  assignmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Avg_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityAssignments". All fields are combined with a logical 'AND'. */
export type IdentityAssignments_Bool_Exp = {
  Assignment?: InputMaybe<Assignments_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityAssignments_Bool_Exp>>;
  _not?: InputMaybe<IdentityAssignments_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityAssignments_Bool_Exp>>;
  assignmentId?: InputMaybe<Int_Comparison_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityAssignments" */
export enum IdentityAssignments_Constraint {
  /** unique or primary key constraint on columns "assignmentId", "identityId" */
  IdentityAssignmentsIdentityIdAssignmentIdKey = 'IdentityAssignments_identityId_assignmentId_key',
  /** unique or primary key constraint on columns "id" */
  IdentityAssignmentsPkey = 'IdentityAssignments_pkey'
}

/** input type for incrementing numeric columns in table "IdentityAssignments" */
export type IdentityAssignments_Inc_Input = {
  assignmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityAssignments" */
export type IdentityAssignments_Insert_Input = {
  Assignment?: InputMaybe<Assignments_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  assignmentId?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityAssignments_Max_Fields = {
  __typename?: 'IdentityAssignments_max_fields';
  assignmentId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Max_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityAssignments_Min_Fields = {
  __typename?: 'IdentityAssignments_min_fields';
  assignmentId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Min_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityAssignments" */
export type IdentityAssignments_Mutation_Response = {
  __typename?: 'IdentityAssignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityAssignments>;
};

/** on_conflict condition type for table "IdentityAssignments" */
export type IdentityAssignments_On_Conflict = {
  constraint: IdentityAssignments_Constraint;
  update_columns?: Array<IdentityAssignments_Update_Column>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityAssignments". */
export type IdentityAssignments_Order_By = {
  Assignment?: InputMaybe<Assignments_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  assignmentId?: InputMaybe<Order_By>;
  completed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityAssignments */
export type IdentityAssignments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "IdentityAssignments" */
export enum IdentityAssignments_Select_Column {
  /** column name */
  AssignmentId = 'assignmentId',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "IdentityAssignments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "IdentityAssignments" */
export enum IdentityAssignments_Select_Column_IdentityAssignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Completed = 'completed'
}

/** select "IdentityAssignments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "IdentityAssignments" */
export enum IdentityAssignments_Select_Column_IdentityAssignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Completed = 'completed'
}

/** input type for updating data in table "IdentityAssignments" */
export type IdentityAssignments_Set_Input = {
  assignmentId?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityAssignments_Stddev_Fields = {
  __typename?: 'IdentityAssignments_stddev_fields';
  assignmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Stddev_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityAssignments_Stddev_Pop_Fields = {
  __typename?: 'IdentityAssignments_stddev_pop_fields';
  assignmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Stddev_Pop_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityAssignments_Stddev_Samp_Fields = {
  __typename?: 'IdentityAssignments_stddev_samp_fields';
  assignmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Stddev_Samp_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityAssignments" */
export type IdentityAssignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityAssignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityAssignments_Stream_Cursor_Value_Input = {
  assignmentId?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityAssignments_Sum_Fields = {
  __typename?: 'IdentityAssignments_sum_fields';
  assignmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Sum_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityAssignments" */
export enum IdentityAssignments_Update_Column {
  /** column name */
  AssignmentId = 'assignmentId',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityAssignments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityAssignments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityAssignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityAssignments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityAssignments_Var_Pop_Fields = {
  __typename?: 'IdentityAssignments_var_pop_fields';
  assignmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Var_Pop_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityAssignments_Var_Samp_Fields = {
  __typename?: 'IdentityAssignments_var_samp_fields';
  assignmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Var_Samp_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityAssignments_Variance_Fields = {
  __typename?: 'IdentityAssignments_variance_fields';
  assignmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityAssignments" */
export type IdentityAssignments_Variance_Order_By = {
  assignmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Join table between comments and identities for extra associations */
export type IdentityComments = {
  __typename?: 'IdentityComments';
  /** An object relationship */
  Comment: Comments;
  /** An object relationship */
  Identity: Identities;
  comment_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentityComments" */
export type IdentityComments_Aggregate = {
  __typename?: 'IdentityComments_aggregate';
  aggregate?: Maybe<IdentityComments_Aggregate_Fields>;
  nodes: Array<IdentityComments>;
};

export type IdentityComments_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityComments_Aggregate_Bool_Exp_Count>;
};

export type IdentityComments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityComments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityComments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityComments" */
export type IdentityComments_Aggregate_Fields = {
  __typename?: 'IdentityComments_aggregate_fields';
  avg?: Maybe<IdentityComments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityComments_Max_Fields>;
  min?: Maybe<IdentityComments_Min_Fields>;
  stddev?: Maybe<IdentityComments_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityComments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityComments_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityComments_Sum_Fields>;
  var_pop?: Maybe<IdentityComments_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityComments_Var_Samp_Fields>;
  variance?: Maybe<IdentityComments_Variance_Fields>;
};


/** aggregate fields of "IdentityComments" */
export type IdentityComments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityComments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityComments" */
export type IdentityComments_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityComments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityComments_Max_Order_By>;
  min?: InputMaybe<IdentityComments_Min_Order_By>;
  stddev?: InputMaybe<IdentityComments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityComments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityComments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityComments_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityComments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityComments_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityComments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityComments" */
export type IdentityComments_Arr_Rel_Insert_Input = {
  data: Array<IdentityComments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityComments_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityComments_Avg_Fields = {
  __typename?: 'IdentityComments_avg_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityComments" */
export type IdentityComments_Avg_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityComments". All fields are combined with a logical 'AND'. */
export type IdentityComments_Bool_Exp = {
  Comment?: InputMaybe<Comments_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityComments_Bool_Exp>>;
  _not?: InputMaybe<IdentityComments_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityComments_Bool_Exp>>;
  comment_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityComments" */
export enum IdentityComments_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentityCommentsPkey = 'IdentityComments_pkey'
}

/** input type for incrementing numeric columns in table "IdentityComments" */
export type IdentityComments_Inc_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityComments" */
export type IdentityComments_Insert_Input = {
  Comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityComments_Max_Fields = {
  __typename?: 'IdentityComments_max_fields';
  comment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityComments" */
export type IdentityComments_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityComments_Min_Fields = {
  __typename?: 'IdentityComments_min_fields';
  comment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityComments" */
export type IdentityComments_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityComments" */
export type IdentityComments_Mutation_Response = {
  __typename?: 'IdentityComments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityComments>;
};

/** on_conflict condition type for table "IdentityComments" */
export type IdentityComments_On_Conflict = {
  constraint: IdentityComments_Constraint;
  update_columns?: Array<IdentityComments_Update_Column>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityComments". */
export type IdentityComments_Order_By = {
  Comment?: InputMaybe<Comments_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityComments */
export type IdentityComments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "IdentityComments" */
export enum IdentityComments_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentityComments" */
export type IdentityComments_Set_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityComments_Stddev_Fields = {
  __typename?: 'IdentityComments_stddev_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityComments" */
export type IdentityComments_Stddev_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityComments_Stddev_Pop_Fields = {
  __typename?: 'IdentityComments_stddev_pop_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityComments" */
export type IdentityComments_Stddev_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityComments_Stddev_Samp_Fields = {
  __typename?: 'IdentityComments_stddev_samp_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityComments" */
export type IdentityComments_Stddev_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityComments" */
export type IdentityComments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityComments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityComments_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityComments_Sum_Fields = {
  __typename?: 'IdentityComments_sum_fields';
  comment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityComments" */
export type IdentityComments_Sum_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityComments" */
export enum IdentityComments_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityComments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityComments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityComments_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityComments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityComments_Var_Pop_Fields = {
  __typename?: 'IdentityComments_var_pop_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityComments" */
export type IdentityComments_Var_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityComments_Var_Samp_Fields = {
  __typename?: 'IdentityComments_var_samp_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityComments" */
export type IdentityComments_Var_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityComments_Variance_Fields = {
  __typename?: 'IdentityComments_variance_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityComments" */
export type IdentityComments_Variance_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Relationships between contracts and identities */
export type IdentityContractRelationship = {
  __typename?: 'IdentityContractRelationship';
  /** An object relationship */
  Contract: Contracts;
  /** An object relationship */
  CreatedBy: Identities;
  /** An object relationship */
  Identity: Identities;
  contract_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  state: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentityContractRelationship" */
export type IdentityContractRelationship_Aggregate = {
  __typename?: 'IdentityContractRelationship_aggregate';
  aggregate?: Maybe<IdentityContractRelationship_Aggregate_Fields>;
  nodes: Array<IdentityContractRelationship>;
};

export type IdentityContractRelationship_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityContractRelationship_Aggregate_Bool_Exp_Count>;
};

export type IdentityContractRelationship_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityContractRelationship" */
export type IdentityContractRelationship_Aggregate_Fields = {
  __typename?: 'IdentityContractRelationship_aggregate_fields';
  avg?: Maybe<IdentityContractRelationship_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityContractRelationship_Max_Fields>;
  min?: Maybe<IdentityContractRelationship_Min_Fields>;
  stddev?: Maybe<IdentityContractRelationship_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityContractRelationship_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityContractRelationship_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityContractRelationship_Sum_Fields>;
  var_pop?: Maybe<IdentityContractRelationship_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityContractRelationship_Var_Samp_Fields>;
  variance?: Maybe<IdentityContractRelationship_Variance_Fields>;
};


/** aggregate fields of "IdentityContractRelationship" */
export type IdentityContractRelationship_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityContractRelationship_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityContractRelationship_Max_Order_By>;
  min?: InputMaybe<IdentityContractRelationship_Min_Order_By>;
  stddev?: InputMaybe<IdentityContractRelationship_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityContractRelationship_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityContractRelationship_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityContractRelationship_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityContractRelationship_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityContractRelationship_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityContractRelationship_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityContractRelationship" */
export type IdentityContractRelationship_Arr_Rel_Insert_Input = {
  data: Array<IdentityContractRelationship_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityContractRelationship_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityContractRelationship_Avg_Fields = {
  __typename?: 'IdentityContractRelationship_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityContractRelationship". All fields are combined with a logical 'AND'. */
export type IdentityContractRelationship_Bool_Exp = {
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityContractRelationship_Bool_Exp>>;
  _not?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityContractRelationship_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityContractRelationship" */
export enum IdentityContractRelationship_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentityContractRelationshipPkey = 'IdentityContractRelationship_pkey'
}

/** input type for incrementing numeric columns in table "IdentityContractRelationship" */
export type IdentityContractRelationship_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityContractRelationship" */
export type IdentityContractRelationship_Insert_Input = {
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityContractRelationship_Max_Fields = {
  __typename?: 'IdentityContractRelationship_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityContractRelationship_Min_Fields = {
  __typename?: 'IdentityContractRelationship_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityContractRelationship" */
export type IdentityContractRelationship_Mutation_Response = {
  __typename?: 'IdentityContractRelationship_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityContractRelationship>;
};

/** on_conflict condition type for table "IdentityContractRelationship" */
export type IdentityContractRelationship_On_Conflict = {
  constraint: IdentityContractRelationship_Constraint;
  update_columns?: Array<IdentityContractRelationship_Update_Column>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityContractRelationship". */
export type IdentityContractRelationship_Order_By = {
  Contract?: InputMaybe<Contracts_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityContractRelationship */
export type IdentityContractRelationship_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "IdentityContractRelationship" */
export enum IdentityContractRelationship_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  State = 'state',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentityContractRelationship" */
export type IdentityContractRelationship_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityContractRelationship_Stddev_Fields = {
  __typename?: 'IdentityContractRelationship_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityContractRelationship_Stddev_Pop_Fields = {
  __typename?: 'IdentityContractRelationship_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityContractRelationship_Stddev_Samp_Fields = {
  __typename?: 'IdentityContractRelationship_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityContractRelationship" */
export type IdentityContractRelationship_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityContractRelationship_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityContractRelationship_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityContractRelationship_Sum_Fields = {
  __typename?: 'IdentityContractRelationship_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityContractRelationship" */
export enum IdentityContractRelationship_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  State = 'state',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityContractRelationship_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityContractRelationship_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityContractRelationship_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityContractRelationship_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityContractRelationship_Var_Pop_Fields = {
  __typename?: 'IdentityContractRelationship_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityContractRelationship_Var_Samp_Fields = {
  __typename?: 'IdentityContractRelationship_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityContractRelationship_Variance_Fields = {
  __typename?: 'IdentityContractRelationship_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityContractRelationship" */
export type IdentityContractRelationship_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Relationships between departments and identities */
export type IdentityDepartmentRelationship = {
  __typename?: 'IdentityDepartmentRelationship';
  /** An object relationship */
  Department?: Maybe<Departments>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  department_id: Scalars['uuid'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Aggregate = {
  __typename?: 'IdentityDepartmentRelationship_aggregate';
  aggregate?: Maybe<IdentityDepartmentRelationship_Aggregate_Fields>;
  nodes: Array<IdentityDepartmentRelationship>;
};

export type IdentityDepartmentRelationship_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityDepartmentRelationship_Aggregate_Bool_Exp_Count>;
};

export type IdentityDepartmentRelationship_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Aggregate_Fields = {
  __typename?: 'IdentityDepartmentRelationship_aggregate_fields';
  avg?: Maybe<IdentityDepartmentRelationship_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityDepartmentRelationship_Max_Fields>;
  min?: Maybe<IdentityDepartmentRelationship_Min_Fields>;
  stddev?: Maybe<IdentityDepartmentRelationship_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityDepartmentRelationship_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityDepartmentRelationship_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityDepartmentRelationship_Sum_Fields>;
  var_pop?: Maybe<IdentityDepartmentRelationship_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityDepartmentRelationship_Var_Samp_Fields>;
  variance?: Maybe<IdentityDepartmentRelationship_Variance_Fields>;
};


/** aggregate fields of "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityDepartmentRelationship_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityDepartmentRelationship_Max_Order_By>;
  min?: InputMaybe<IdentityDepartmentRelationship_Min_Order_By>;
  stddev?: InputMaybe<IdentityDepartmentRelationship_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityDepartmentRelationship_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityDepartmentRelationship_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityDepartmentRelationship_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityDepartmentRelationship_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityDepartmentRelationship_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityDepartmentRelationship_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Arr_Rel_Insert_Input = {
  data: Array<IdentityDepartmentRelationship_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityDepartmentRelationship_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityDepartmentRelationship_Avg_Fields = {
  __typename?: 'IdentityDepartmentRelationship_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityDepartmentRelationship". All fields are combined with a logical 'AND'. */
export type IdentityDepartmentRelationship_Bool_Exp = {
  Department?: InputMaybe<Departments_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityDepartmentRelationship_Bool_Exp>>;
  _not?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityDepartmentRelationship_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  department_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityDepartmentRelationship" */
export enum IdentityDepartmentRelationship_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentityDepartmentRelationshipPkey = 'IdentityDepartmentRelationship_pkey'
}

/** input type for incrementing numeric columns in table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Insert_Input = {
  Department?: InputMaybe<Departments_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityDepartmentRelationship_Max_Fields = {
  __typename?: 'IdentityDepartmentRelationship_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityDepartmentRelationship_Min_Fields = {
  __typename?: 'IdentityDepartmentRelationship_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Mutation_Response = {
  __typename?: 'IdentityDepartmentRelationship_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityDepartmentRelationship>;
};

/** on_conflict condition type for table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_On_Conflict = {
  constraint: IdentityDepartmentRelationship_Constraint;
  update_columns?: Array<IdentityDepartmentRelationship_Update_Column>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityDepartmentRelationship". */
export type IdentityDepartmentRelationship_Order_By = {
  Department?: InputMaybe<Departments_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityDepartmentRelationship */
export type IdentityDepartmentRelationship_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "IdentityDepartmentRelationship" */
export enum IdentityDepartmentRelationship_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityDepartmentRelationship_Stddev_Fields = {
  __typename?: 'IdentityDepartmentRelationship_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityDepartmentRelationship_Stddev_Pop_Fields = {
  __typename?: 'IdentityDepartmentRelationship_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityDepartmentRelationship_Stddev_Samp_Fields = {
  __typename?: 'IdentityDepartmentRelationship_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityDepartmentRelationship_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityDepartmentRelationship_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  department_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityDepartmentRelationship_Sum_Fields = {
  __typename?: 'IdentityDepartmentRelationship_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityDepartmentRelationship" */
export enum IdentityDepartmentRelationship_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityDepartmentRelationship_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityDepartmentRelationship_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityDepartmentRelationship_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityDepartmentRelationship_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityDepartmentRelationship_Var_Pop_Fields = {
  __typename?: 'IdentityDepartmentRelationship_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityDepartmentRelationship_Var_Samp_Fields = {
  __typename?: 'IdentityDepartmentRelationship_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityDepartmentRelationship_Variance_Fields = {
  __typename?: 'IdentityDepartmentRelationship_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityDepartmentRelationship" */
export type IdentityDepartmentRelationship_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

export type IdentityFtsHit = {
  __typename?: 'IdentityFTSHit';
  Identity?: Maybe<Identities>;
  highlights?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  relevance?: Maybe<Scalars['Float']>;
  total_hits?: Maybe<Scalars['Int']>;
};

export type IdentityForExternalReviewsOutput = {
  __typename?: 'IdentityForExternalReviewsOutput';
  Identity?: Maybe<Identities_For_External_Reviews>;
  identityId: Scalars['Int'];
  resumeHtml: Scalars['String'];
  resumeUrl: Scalars['String'];
};

/** columns and relationships of "IdentityGroups" */
export type IdentityGroups = {
  __typename?: 'IdentityGroups';
  /** An object relationship */
  Group: Groups;
  /** An object relationship */
  Identity: Identities;
  createdAt: Scalars['timestamp'];
  groupId: Scalars['uuid'];
  identityId: Scalars['Int'];
  login_enabled: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
};

/** aggregated selection of "IdentityGroups" */
export type IdentityGroups_Aggregate = {
  __typename?: 'IdentityGroups_aggregate';
  aggregate?: Maybe<IdentityGroups_Aggregate_Fields>;
  nodes: Array<IdentityGroups>;
};

export type IdentityGroups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<IdentityGroups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<IdentityGroups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<IdentityGroups_Aggregate_Bool_Exp_Count>;
};

export type IdentityGroups_Aggregate_Bool_Exp_Bool_And = {
  arguments: IdentityGroups_Select_Column_IdentityGroups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityGroups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type IdentityGroups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IdentityGroups_Select_Column_IdentityGroups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityGroups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type IdentityGroups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityGroups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityGroups" */
export type IdentityGroups_Aggregate_Fields = {
  __typename?: 'IdentityGroups_aggregate_fields';
  avg?: Maybe<IdentityGroups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityGroups_Max_Fields>;
  min?: Maybe<IdentityGroups_Min_Fields>;
  stddev?: Maybe<IdentityGroups_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityGroups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityGroups_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityGroups_Sum_Fields>;
  var_pop?: Maybe<IdentityGroups_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityGroups_Var_Samp_Fields>;
  variance?: Maybe<IdentityGroups_Variance_Fields>;
};


/** aggregate fields of "IdentityGroups" */
export type IdentityGroups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityGroups" */
export type IdentityGroups_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityGroups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityGroups_Max_Order_By>;
  min?: InputMaybe<IdentityGroups_Min_Order_By>;
  stddev?: InputMaybe<IdentityGroups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityGroups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityGroups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityGroups_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityGroups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityGroups_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityGroups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityGroups" */
export type IdentityGroups_Arr_Rel_Insert_Input = {
  data: Array<IdentityGroups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityGroups_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityGroups_Avg_Fields = {
  __typename?: 'IdentityGroups_avg_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityGroups" */
export type IdentityGroups_Avg_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityGroups". All fields are combined with a logical 'AND'. */
export type IdentityGroups_Bool_Exp = {
  Group?: InputMaybe<Groups_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityGroups_Bool_Exp>>;
  _not?: InputMaybe<IdentityGroups_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityGroups_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  groupId?: InputMaybe<Uuid_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  login_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityGroups" */
export enum IdentityGroups_Constraint {
  /** unique or primary key constraint on columns "identityId" */
  IdentityGroupsIdentityIdKey = 'IdentityGroups_identityId_key',
  /** unique or primary key constraint on columns "groupId", "identityId" */
  IdentityGroupsPkey = 'IdentityGroups_pkey'
}

/** input type for incrementing numeric columns in table "IdentityGroups" */
export type IdentityGroups_Inc_Input = {
  identityId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityGroups" */
export type IdentityGroups_Insert_Input = {
  Group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  identityId?: InputMaybe<Scalars['Int']>;
  login_enabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type IdentityGroups_Max_Fields = {
  __typename?: 'IdentityGroups_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  groupId?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "IdentityGroups" */
export type IdentityGroups_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityGroups_Min_Fields = {
  __typename?: 'IdentityGroups_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  groupId?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "IdentityGroups" */
export type IdentityGroups_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityGroups" */
export type IdentityGroups_Mutation_Response = {
  __typename?: 'IdentityGroups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityGroups>;
};

/** on_conflict condition type for table "IdentityGroups" */
export type IdentityGroups_On_Conflict = {
  constraint: IdentityGroups_Constraint;
  update_columns?: Array<IdentityGroups_Update_Column>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityGroups". */
export type IdentityGroups_Order_By = {
  Group?: InputMaybe<Groups_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  login_enabled?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityGroups */
export type IdentityGroups_Pk_Columns_Input = {
  groupId: Scalars['uuid'];
  identityId: Scalars['Int'];
};

/** select columns of table "IdentityGroups" */
export enum IdentityGroups_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  LoginEnabled = 'login_enabled',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "IdentityGroups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "IdentityGroups" */
export enum IdentityGroups_Select_Column_IdentityGroups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  LoginEnabled = 'login_enabled'
}

/** select "IdentityGroups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "IdentityGroups" */
export enum IdentityGroups_Select_Column_IdentityGroups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  LoginEnabled = 'login_enabled'
}

/** input type for updating data in table "IdentityGroups" */
export type IdentityGroups_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  identityId?: InputMaybe<Scalars['Int']>;
  login_enabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type IdentityGroups_Stddev_Fields = {
  __typename?: 'IdentityGroups_stddev_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityGroups" */
export type IdentityGroups_Stddev_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityGroups_Stddev_Pop_Fields = {
  __typename?: 'IdentityGroups_stddev_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityGroups" */
export type IdentityGroups_Stddev_Pop_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityGroups_Stddev_Samp_Fields = {
  __typename?: 'IdentityGroups_stddev_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityGroups" */
export type IdentityGroups_Stddev_Samp_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityGroups" */
export type IdentityGroups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityGroups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityGroups_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  identityId?: InputMaybe<Scalars['Int']>;
  login_enabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type IdentityGroups_Sum_Fields = {
  __typename?: 'IdentityGroups_sum_fields';
  identityId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityGroups" */
export type IdentityGroups_Sum_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityGroups" */
export enum IdentityGroups_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  LoginEnabled = 'login_enabled',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type IdentityGroups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityGroups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityGroups_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityGroups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityGroups_Var_Pop_Fields = {
  __typename?: 'IdentityGroups_var_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityGroups" */
export type IdentityGroups_Var_Pop_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityGroups_Var_Samp_Fields = {
  __typename?: 'IdentityGroups_var_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityGroups" */
export type IdentityGroups_Var_Samp_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityGroups_Variance_Fields = {
  __typename?: 'IdentityGroups_variance_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityGroups" */
export type IdentityGroups_Variance_Order_By = {
  identityId?: InputMaybe<Order_By>;
};

/** Relationships between jobs and identities */
export type IdentityJobRelationship = {
  __typename?: 'IdentityJobRelationship';
  /** An object relationship */
  CreatedBy: Identities;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Job: Jobs;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  state: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentityJobRelationship" */
export type IdentityJobRelationship_Aggregate = {
  __typename?: 'IdentityJobRelationship_aggregate';
  aggregate?: Maybe<IdentityJobRelationship_Aggregate_Fields>;
  nodes: Array<IdentityJobRelationship>;
};

export type IdentityJobRelationship_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityJobRelationship_Aggregate_Bool_Exp_Count>;
};

export type IdentityJobRelationship_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityJobRelationship" */
export type IdentityJobRelationship_Aggregate_Fields = {
  __typename?: 'IdentityJobRelationship_aggregate_fields';
  avg?: Maybe<IdentityJobRelationship_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityJobRelationship_Max_Fields>;
  min?: Maybe<IdentityJobRelationship_Min_Fields>;
  stddev?: Maybe<IdentityJobRelationship_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityJobRelationship_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityJobRelationship_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityJobRelationship_Sum_Fields>;
  var_pop?: Maybe<IdentityJobRelationship_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityJobRelationship_Var_Samp_Fields>;
  variance?: Maybe<IdentityJobRelationship_Variance_Fields>;
};


/** aggregate fields of "IdentityJobRelationship" */
export type IdentityJobRelationship_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityJobRelationship_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityJobRelationship_Max_Order_By>;
  min?: InputMaybe<IdentityJobRelationship_Min_Order_By>;
  stddev?: InputMaybe<IdentityJobRelationship_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityJobRelationship_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityJobRelationship_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityJobRelationship_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityJobRelationship_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityJobRelationship_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityJobRelationship_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityJobRelationship" */
export type IdentityJobRelationship_Arr_Rel_Insert_Input = {
  data: Array<IdentityJobRelationship_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityJobRelationship_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityJobRelationship_Avg_Fields = {
  __typename?: 'IdentityJobRelationship_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityJobRelationship". All fields are combined with a logical 'AND'. */
export type IdentityJobRelationship_Bool_Exp = {
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityJobRelationship_Bool_Exp>>;
  _not?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityJobRelationship_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityJobRelationship" */
export enum IdentityJobRelationship_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentityJobRelationshipPkey = 'IdentityJobRelationship_pkey'
}

/** input type for incrementing numeric columns in table "IdentityJobRelationship" */
export type IdentityJobRelationship_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityJobRelationship" */
export type IdentityJobRelationship_Insert_Input = {
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityJobRelationship_Max_Fields = {
  __typename?: 'IdentityJobRelationship_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityJobRelationship_Min_Fields = {
  __typename?: 'IdentityJobRelationship_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityJobRelationship" */
export type IdentityJobRelationship_Mutation_Response = {
  __typename?: 'IdentityJobRelationship_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityJobRelationship>;
};

/** input type for inserting object relation for remote table "IdentityJobRelationship" */
export type IdentityJobRelationship_Obj_Rel_Insert_Input = {
  data: IdentityJobRelationship_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityJobRelationship_On_Conflict>;
};

/** on_conflict condition type for table "IdentityJobRelationship" */
export type IdentityJobRelationship_On_Conflict = {
  constraint: IdentityJobRelationship_Constraint;
  update_columns?: Array<IdentityJobRelationship_Update_Column>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityJobRelationship". */
export type IdentityJobRelationship_Order_By = {
  CreatedBy?: InputMaybe<Identities_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityJobRelationship */
export type IdentityJobRelationship_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "IdentityJobRelationship" */
export enum IdentityJobRelationship_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  State = 'state',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentityJobRelationship" */
export type IdentityJobRelationship_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityJobRelationship_Stddev_Fields = {
  __typename?: 'IdentityJobRelationship_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityJobRelationship_Stddev_Pop_Fields = {
  __typename?: 'IdentityJobRelationship_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityJobRelationship_Stddev_Samp_Fields = {
  __typename?: 'IdentityJobRelationship_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityJobRelationship" */
export type IdentityJobRelationship_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityJobRelationship_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityJobRelationship_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityJobRelationship_Sum_Fields = {
  __typename?: 'IdentityJobRelationship_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityJobRelationship" */
export enum IdentityJobRelationship_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  State = 'state',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityJobRelationship_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityJobRelationship_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityJobRelationship_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityJobRelationship_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityJobRelationship_Var_Pop_Fields = {
  __typename?: 'IdentityJobRelationship_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityJobRelationship_Var_Samp_Fields = {
  __typename?: 'IdentityJobRelationship_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityJobRelationship_Variance_Fields = {
  __typename?: 'IdentityJobRelationship_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityJobRelationship" */
export type IdentityJobRelationship_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** When a partner creates a new candidate to submit. The link and the data they submitted are stored here.  */
export type IdentityPartnerGroupData = {
  __typename?: 'IdentityPartnerGroupData';
  /** An object relationship */
  Group: Groups;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  IdentityByCreatedBy: Identities;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  group_id: Scalars['uuid'];
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  metadata: Scalars['jsonb'];
};


/** When a partner creates a new candidate to submit. The link and the data they submitted are stored here.  */
export type IdentityPartnerGroupDataMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Aggregate = {
  __typename?: 'IdentityPartnerGroupData_aggregate';
  aggregate?: Maybe<IdentityPartnerGroupData_Aggregate_Fields>;
  nodes: Array<IdentityPartnerGroupData>;
};

export type IdentityPartnerGroupData_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityPartnerGroupData_Aggregate_Bool_Exp_Count>;
};

export type IdentityPartnerGroupData_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Aggregate_Fields = {
  __typename?: 'IdentityPartnerGroupData_aggregate_fields';
  avg?: Maybe<IdentityPartnerGroupData_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityPartnerGroupData_Max_Fields>;
  min?: Maybe<IdentityPartnerGroupData_Min_Fields>;
  stddev?: Maybe<IdentityPartnerGroupData_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityPartnerGroupData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityPartnerGroupData_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityPartnerGroupData_Sum_Fields>;
  var_pop?: Maybe<IdentityPartnerGroupData_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityPartnerGroupData_Var_Samp_Fields>;
  variance?: Maybe<IdentityPartnerGroupData_Variance_Fields>;
};


/** aggregate fields of "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityPartnerGroupData_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityPartnerGroupData_Max_Order_By>;
  min?: InputMaybe<IdentityPartnerGroupData_Min_Order_By>;
  stddev?: InputMaybe<IdentityPartnerGroupData_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityPartnerGroupData_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityPartnerGroupData_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityPartnerGroupData_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityPartnerGroupData_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityPartnerGroupData_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityPartnerGroupData_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IdentityPartnerGroupData_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Arr_Rel_Insert_Input = {
  data: Array<IdentityPartnerGroupData_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityPartnerGroupData_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityPartnerGroupData_Avg_Fields = {
  __typename?: 'IdentityPartnerGroupData_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityPartnerGroupData". All fields are combined with a logical 'AND'. */
export type IdentityPartnerGroupData_Bool_Exp = {
  Group?: InputMaybe<Groups_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  IdentityByCreatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityPartnerGroupData_Bool_Exp>>;
  _not?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityPartnerGroupData_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityPartnerGroupData" */
export enum IdentityPartnerGroupData_Constraint {
  /** unique or primary key constraint on columns "identity_id", "group_id" */
  IdentityPartnerGroupDataGroupIdIdentityIdKey = 'IdentityPartnerGroupData_group_id_identity_id_key',
  /** unique or primary key constraint on columns "id" */
  IdentityPartnerGroupDataPkey = 'IdentityPartnerGroupData_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IdentityPartnerGroupData_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IdentityPartnerGroupData_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IdentityPartnerGroupData_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Insert_Input = {
  Group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  IdentityByCreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type IdentityPartnerGroupData_Max_Fields = {
  __typename?: 'IdentityPartnerGroupData_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityPartnerGroupData_Min_Fields = {
  __typename?: 'IdentityPartnerGroupData_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Mutation_Response = {
  __typename?: 'IdentityPartnerGroupData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityPartnerGroupData>;
};

/** on_conflict condition type for table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_On_Conflict = {
  constraint: IdentityPartnerGroupData_Constraint;
  update_columns?: Array<IdentityPartnerGroupData_Update_Column>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityPartnerGroupData". */
export type IdentityPartnerGroupData_Order_By = {
  Group?: InputMaybe<Groups_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  IdentityByCreatedBy?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityPartnerGroupData */
export type IdentityPartnerGroupData_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IdentityPartnerGroupData_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "IdentityPartnerGroupData" */
export enum IdentityPartnerGroupData_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata'
}

/** input type for updating data in table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type IdentityPartnerGroupData_Stddev_Fields = {
  __typename?: 'IdentityPartnerGroupData_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityPartnerGroupData_Stddev_Pop_Fields = {
  __typename?: 'IdentityPartnerGroupData_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityPartnerGroupData_Stddev_Samp_Fields = {
  __typename?: 'IdentityPartnerGroupData_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityPartnerGroupData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityPartnerGroupData_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type IdentityPartnerGroupData_Sum_Fields = {
  __typename?: 'IdentityPartnerGroupData_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityPartnerGroupData" */
export enum IdentityPartnerGroupData_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata'
}

export type IdentityPartnerGroupData_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IdentityPartnerGroupData_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IdentityPartnerGroupData_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IdentityPartnerGroupData_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IdentityPartnerGroupData_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityPartnerGroupData_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IdentityPartnerGroupData_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityPartnerGroupData_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityPartnerGroupData_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityPartnerGroupData_Var_Pop_Fields = {
  __typename?: 'IdentityPartnerGroupData_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityPartnerGroupData_Var_Samp_Fields = {
  __typename?: 'IdentityPartnerGroupData_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityPartnerGroupData_Variance_Fields = {
  __typename?: 'IdentityPartnerGroupData_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityPartnerGroupData" */
export type IdentityPartnerGroupData_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Types of relationships between Identity and OtherModel */
export type IdentityRelationshipType = {
  __typename?: 'IdentityRelationshipType';
  cardinality: Scalars['String'];
  description: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** aggregated selection of "IdentityRelationshipType" */
export type IdentityRelationshipType_Aggregate = {
  __typename?: 'IdentityRelationshipType_aggregate';
  aggregate?: Maybe<IdentityRelationshipType_Aggregate_Fields>;
  nodes: Array<IdentityRelationshipType>;
};

/** aggregate fields of "IdentityRelationshipType" */
export type IdentityRelationshipType_Aggregate_Fields = {
  __typename?: 'IdentityRelationshipType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IdentityRelationshipType_Max_Fields>;
  min?: Maybe<IdentityRelationshipType_Min_Fields>;
};


/** aggregate fields of "IdentityRelationshipType" */
export type IdentityRelationshipType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityRelationshipType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "IdentityRelationshipType". All fields are combined with a logical 'AND'. */
export type IdentityRelationshipType_Bool_Exp = {
  _and?: InputMaybe<Array<IdentityRelationshipType_Bool_Exp>>;
  _not?: InputMaybe<IdentityRelationshipType_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityRelationshipType_Bool_Exp>>;
  cardinality?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityRelationshipType" */
export enum IdentityRelationshipType_Constraint {
  /** unique or primary key constraint on columns "type" */
  IdentityRelationshipTypePkey = 'IdentityRelationshipType_pkey'
}

/** input type for inserting data into table "IdentityRelationshipType" */
export type IdentityRelationshipType_Insert_Input = {
  cardinality?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IdentityRelationshipType_Max_Fields = {
  __typename?: 'IdentityRelationshipType_max_fields';
  cardinality?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IdentityRelationshipType_Min_Fields = {
  __typename?: 'IdentityRelationshipType_min_fields';
  cardinality?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "IdentityRelationshipType" */
export type IdentityRelationshipType_Mutation_Response = {
  __typename?: 'IdentityRelationshipType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityRelationshipType>;
};

/** on_conflict condition type for table "IdentityRelationshipType" */
export type IdentityRelationshipType_On_Conflict = {
  constraint: IdentityRelationshipType_Constraint;
  update_columns?: Array<IdentityRelationshipType_Update_Column>;
  where?: InputMaybe<IdentityRelationshipType_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityRelationshipType". */
export type IdentityRelationshipType_Order_By = {
  cardinality?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityRelationshipType */
export type IdentityRelationshipType_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "IdentityRelationshipType" */
export enum IdentityRelationshipType_Select_Column {
  /** column name */
  Cardinality = 'cardinality',
  /** column name */
  Description = 'description',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "IdentityRelationshipType" */
export type IdentityRelationshipType_Set_Input = {
  cardinality?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "IdentityRelationshipType" */
export type IdentityRelationshipType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityRelationshipType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityRelationshipType_Stream_Cursor_Value_Input = {
  cardinality?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "IdentityRelationshipType" */
export enum IdentityRelationshipType_Update_Column {
  /** column name */
  Cardinality = 'cardinality',
  /** column name */
  Description = 'description',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type'
}

export type IdentityRelationshipType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityRelationshipType_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityRelationshipType_Bool_Exp;
};

/** columns and relationships of "IdentityRoles" */
export type IdentityRoles = {
  __typename?: 'IdentityRoles';
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Role: Roles;
  created_at?: Maybe<Scalars['timestamptz']>;
  identityId: Scalars['Int'];
  roleId: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "IdentityRoles" */
export type IdentityRoles_Aggregate = {
  __typename?: 'IdentityRoles_aggregate';
  aggregate?: Maybe<IdentityRoles_Aggregate_Fields>;
  nodes: Array<IdentityRoles>;
};

export type IdentityRoles_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityRoles_Aggregate_Bool_Exp_Count>;
};

export type IdentityRoles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityRoles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityRoles" */
export type IdentityRoles_Aggregate_Fields = {
  __typename?: 'IdentityRoles_aggregate_fields';
  avg?: Maybe<IdentityRoles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityRoles_Max_Fields>;
  min?: Maybe<IdentityRoles_Min_Fields>;
  stddev?: Maybe<IdentityRoles_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityRoles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityRoles_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityRoles_Sum_Fields>;
  var_pop?: Maybe<IdentityRoles_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityRoles_Var_Samp_Fields>;
  variance?: Maybe<IdentityRoles_Variance_Fields>;
};


/** aggregate fields of "IdentityRoles" */
export type IdentityRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityRoles" */
export type IdentityRoles_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityRoles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityRoles_Max_Order_By>;
  min?: InputMaybe<IdentityRoles_Min_Order_By>;
  stddev?: InputMaybe<IdentityRoles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityRoles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityRoles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityRoles_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityRoles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityRoles_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityRoles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityRoles" */
export type IdentityRoles_Arr_Rel_Insert_Input = {
  data: Array<IdentityRoles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityRoles_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityRoles_Avg_Fields = {
  __typename?: 'IdentityRoles_avg_fields';
  identityId?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityRoles" */
export type IdentityRoles_Avg_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityRoles". All fields are combined with a logical 'AND'. */
export type IdentityRoles_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Role?: InputMaybe<Roles_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityRoles_Bool_Exp>>;
  _not?: InputMaybe<IdentityRoles_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityRoles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  roleId?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityRoles" */
export enum IdentityRoles_Constraint {
  /** unique or primary key constraint on columns "roleId", "identityId" */
  IdentityRolesIdentityIdRoleIdKey = 'IdentityRoles_identityId_roleId_key'
}

/** input type for incrementing numeric columns in table "IdentityRoles" */
export type IdentityRoles_Inc_Input = {
  identityId?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityRoles" */
export type IdentityRoles_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identityId?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityRoles_Max_Fields = {
  __typename?: 'IdentityRoles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identityId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityRoles" */
export type IdentityRoles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityRoles_Min_Fields = {
  __typename?: 'IdentityRoles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identityId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityRoles" */
export type IdentityRoles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityRoles" */
export type IdentityRoles_Mutation_Response = {
  __typename?: 'IdentityRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityRoles>;
};

/** on_conflict condition type for table "IdentityRoles" */
export type IdentityRoles_On_Conflict = {
  constraint: IdentityRoles_Constraint;
  update_columns?: Array<IdentityRoles_Update_Column>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityRoles". */
export type IdentityRoles_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  Role?: InputMaybe<Roles_Order_By>;
  created_at?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "IdentityRoles" */
export enum IdentityRoles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentityRoles" */
export type IdentityRoles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identityId?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityRoles_Stddev_Fields = {
  __typename?: 'IdentityRoles_stddev_fields';
  identityId?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityRoles" */
export type IdentityRoles_Stddev_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityRoles_Stddev_Pop_Fields = {
  __typename?: 'IdentityRoles_stddev_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityRoles" */
export type IdentityRoles_Stddev_Pop_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityRoles_Stddev_Samp_Fields = {
  __typename?: 'IdentityRoles_stddev_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityRoles" */
export type IdentityRoles_Stddev_Samp_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityRoles" */
export type IdentityRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityRoles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identityId?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityRoles_Sum_Fields = {
  __typename?: 'IdentityRoles_sum_fields';
  identityId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityRoles" */
export type IdentityRoles_Sum_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityRoles" */
export enum IdentityRoles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityRoles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityRoles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityRoles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityRoles_Var_Pop_Fields = {
  __typename?: 'IdentityRoles_var_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityRoles" */
export type IdentityRoles_Var_Pop_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityRoles_Var_Samp_Fields = {
  __typename?: 'IdentityRoles_var_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityRoles" */
export type IdentityRoles_Var_Samp_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityRoles_Variance_Fields = {
  __typename?: 'IdentityRoles_variance_fields';
  identityId?: Maybe<Scalars['Float']>;
  roleId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityRoles" */
export type IdentityRoles_Variance_Order_By = {
  identityId?: InputMaybe<Order_By>;
  roleId?: InputMaybe<Order_By>;
};

/** columns and relationships of "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails = {
  __typename?: 'IdentitySubTypeEmails';
  /** An object relationship */
  Email: Emails;
  /** An object relationship */
  IdentitySubType: IdentitySubTypes;
  created_at: Scalars['timestamptz'];
  email_id: Scalars['Int'];
  id: Scalars['Int'];
  subtype_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Aggregate = {
  __typename?: 'IdentitySubTypeEmails_aggregate';
  aggregate?: Maybe<IdentitySubTypeEmails_Aggregate_Fields>;
  nodes: Array<IdentitySubTypeEmails>;
};

export type IdentitySubTypeEmails_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentitySubTypeEmails_Aggregate_Bool_Exp_Count>;
};

export type IdentitySubTypeEmails_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Aggregate_Fields = {
  __typename?: 'IdentitySubTypeEmails_aggregate_fields';
  avg?: Maybe<IdentitySubTypeEmails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentitySubTypeEmails_Max_Fields>;
  min?: Maybe<IdentitySubTypeEmails_Min_Fields>;
  stddev?: Maybe<IdentitySubTypeEmails_Stddev_Fields>;
  stddev_pop?: Maybe<IdentitySubTypeEmails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentitySubTypeEmails_Stddev_Samp_Fields>;
  sum?: Maybe<IdentitySubTypeEmails_Sum_Fields>;
  var_pop?: Maybe<IdentitySubTypeEmails_Var_Pop_Fields>;
  var_samp?: Maybe<IdentitySubTypeEmails_Var_Samp_Fields>;
  variance?: Maybe<IdentitySubTypeEmails_Variance_Fields>;
};


/** aggregate fields of "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Aggregate_Order_By = {
  avg?: InputMaybe<IdentitySubTypeEmails_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentitySubTypeEmails_Max_Order_By>;
  min?: InputMaybe<IdentitySubTypeEmails_Min_Order_By>;
  stddev?: InputMaybe<IdentitySubTypeEmails_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentitySubTypeEmails_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentitySubTypeEmails_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentitySubTypeEmails_Sum_Order_By>;
  var_pop?: InputMaybe<IdentitySubTypeEmails_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentitySubTypeEmails_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentitySubTypeEmails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Arr_Rel_Insert_Input = {
  data: Array<IdentitySubTypeEmails_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentitySubTypeEmails_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentitySubTypeEmails_Avg_Fields = {
  __typename?: 'IdentitySubTypeEmails_avg_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subtype_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Avg_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentitySubTypeEmails". All fields are combined with a logical 'AND'. */
export type IdentitySubTypeEmails_Bool_Exp = {
  Email?: InputMaybe<Emails_Bool_Exp>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Bool_Exp>;
  _and?: InputMaybe<Array<IdentitySubTypeEmails_Bool_Exp>>;
  _not?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
  _or?: InputMaybe<Array<IdentitySubTypeEmails_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  subtype_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentitySubTypeEmails" */
export enum IdentitySubTypeEmails_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentitySubTypeEmailsPkey = 'IdentitySubTypeEmails_pkey'
}

/** input type for incrementing numeric columns in table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Inc_Input = {
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subtype_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Insert_Input = {
  Email?: InputMaybe<Emails_Obj_Rel_Insert_Input>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subtype_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentitySubTypeEmails_Max_Fields = {
  __typename?: 'IdentitySubTypeEmails_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subtype_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentitySubTypeEmails_Min_Fields = {
  __typename?: 'IdentitySubTypeEmails_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subtype_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Mutation_Response = {
  __typename?: 'IdentitySubTypeEmails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentitySubTypeEmails>;
};

/** on_conflict condition type for table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_On_Conflict = {
  constraint: IdentitySubTypeEmails_Constraint;
  update_columns?: Array<IdentitySubTypeEmails_Update_Column>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentitySubTypeEmails". */
export type IdentitySubTypeEmails_Order_By = {
  Email?: InputMaybe<Emails_Order_By>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentitySubTypeEmails */
export type IdentitySubTypeEmails_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "IdentitySubTypeEmails" */
export enum IdentitySubTypeEmails_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubtypeId = 'subtype_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subtype_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentitySubTypeEmails_Stddev_Fields = {
  __typename?: 'IdentitySubTypeEmails_stddev_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subtype_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Stddev_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentitySubTypeEmails_Stddev_Pop_Fields = {
  __typename?: 'IdentitySubTypeEmails_stddev_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subtype_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Stddev_Pop_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentitySubTypeEmails_Stddev_Samp_Fields = {
  __typename?: 'IdentitySubTypeEmails_stddev_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subtype_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Stddev_Samp_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentitySubTypeEmails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentitySubTypeEmails_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  subtype_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentitySubTypeEmails_Sum_Fields = {
  __typename?: 'IdentitySubTypeEmails_sum_fields';
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  subtype_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Sum_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentitySubTypeEmails" */
export enum IdentitySubTypeEmails_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubtypeId = 'subtype_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentitySubTypeEmails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentitySubTypeEmails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentitySubTypeEmails_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentitySubTypeEmails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentitySubTypeEmails_Var_Pop_Fields = {
  __typename?: 'IdentitySubTypeEmails_var_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subtype_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Var_Pop_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentitySubTypeEmails_Var_Samp_Fields = {
  __typename?: 'IdentitySubTypeEmails_var_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subtype_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Var_Samp_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentitySubTypeEmails_Variance_Fields = {
  __typename?: 'IdentitySubTypeEmails_variance_fields';
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subtype_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentitySubTypeEmails" */
export type IdentitySubTypeEmails_Variance_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subtype_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypes = {
  __typename?: 'IdentitySubTypes';
  /** An array relationship */
  BenefitTypeIdentitySubTypes: Array<BenefitTypeIdentitySubTypes>;
  /** An aggregate relationship */
  BenefitTypeIdentitySubTypes_aggregate: BenefitTypeIdentitySubTypes_Aggregate;
  /** An array relationship */
  Emails: Array<Emails>;
  /** An aggregate relationship */
  Emails_aggregate: Emails_Aggregate;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  /** An array relationship */
  IdentitySubTypeEmails: Array<IdentitySubTypeEmails>;
  /** An aggregate relationship */
  IdentitySubTypeEmails_aggregate: IdentitySubTypeEmails_Aggregate;
  /** An array relationship */
  StageEmails: Array<StageEmails>;
  /** An aggregate relationship */
  StageEmails_aggregate: StageEmails_Aggregate;
  company_id: Scalars['Int'];
  default: Scalars['Boolean'];
  deletable: Scalars['Boolean'];
  hex_color?: Maybe<Scalars['String']>;
  hex_color2?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type_name: Scalars['String'];
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesBenefitTypeIdentitySubTypesArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesBenefitTypeIdentitySubTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesIdentitySubTypeEmailsArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesIdentitySubTypeEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesStageEmailsArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


/** columns and relationships of "IdentitySubTypes" */
export type IdentitySubTypesStageEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};

/** aggregated selection of "IdentitySubTypes" */
export type IdentitySubTypes_Aggregate = {
  __typename?: 'IdentitySubTypes_aggregate';
  aggregate?: Maybe<IdentitySubTypes_Aggregate_Fields>;
  nodes: Array<IdentitySubTypes>;
};

/** aggregate fields of "IdentitySubTypes" */
export type IdentitySubTypes_Aggregate_Fields = {
  __typename?: 'IdentitySubTypes_aggregate_fields';
  avg?: Maybe<IdentitySubTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentitySubTypes_Max_Fields>;
  min?: Maybe<IdentitySubTypes_Min_Fields>;
  stddev?: Maybe<IdentitySubTypes_Stddev_Fields>;
  stddev_pop?: Maybe<IdentitySubTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentitySubTypes_Stddev_Samp_Fields>;
  sum?: Maybe<IdentitySubTypes_Sum_Fields>;
  var_pop?: Maybe<IdentitySubTypes_Var_Pop_Fields>;
  var_samp?: Maybe<IdentitySubTypes_Var_Samp_Fields>;
  variance?: Maybe<IdentitySubTypes_Variance_Fields>;
};


/** aggregate fields of "IdentitySubTypes" */
export type IdentitySubTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentitySubTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IdentitySubTypes_Avg_Fields = {
  __typename?: 'IdentitySubTypes_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "IdentitySubTypes". All fields are combined with a logical 'AND'. */
export type IdentitySubTypes_Bool_Exp = {
  BenefitTypeIdentitySubTypes?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
  BenefitTypeIdentitySubTypes_aggregate?: InputMaybe<BenefitTypeIdentitySubTypes_Aggregate_Bool_Exp>;
  Emails?: InputMaybe<Emails_Bool_Exp>;
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Bool_Exp>;
  Identities?: InputMaybe<Identities_Bool_Exp>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Bool_Exp>;
  IdentitySubTypeEmails?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
  IdentitySubTypeEmails_aggregate?: InputMaybe<IdentitySubTypeEmails_Aggregate_Bool_Exp>;
  StageEmails?: InputMaybe<StageEmails_Bool_Exp>;
  StageEmails_aggregate?: InputMaybe<StageEmails_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<IdentitySubTypes_Bool_Exp>>;
  _not?: InputMaybe<IdentitySubTypes_Bool_Exp>;
  _or?: InputMaybe<Array<IdentitySubTypes_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  deletable?: InputMaybe<Boolean_Comparison_Exp>;
  hex_color?: InputMaybe<String_Comparison_Exp>;
  hex_color2?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type_name?: InputMaybe<String_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentitySubTypes" */
export enum IdentitySubTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateTypesPkey = 'CandidateTypes_pkey'
}

/** input type for incrementing numeric columns in table "IdentitySubTypes" */
export type IdentitySubTypes_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentitySubTypes" */
export type IdentitySubTypes_Insert_Input = {
  BenefitTypeIdentitySubTypes?: InputMaybe<BenefitTypeIdentitySubTypes_Arr_Rel_Insert_Input>;
  Emails?: InputMaybe<Emails_Arr_Rel_Insert_Input>;
  Identities?: InputMaybe<Identities_Arr_Rel_Insert_Input>;
  IdentitySubTypeEmails?: InputMaybe<IdentitySubTypeEmails_Arr_Rel_Insert_Input>;
  StageEmails?: InputMaybe<StageEmails_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  default?: InputMaybe<Scalars['Boolean']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  hex_color?: InputMaybe<Scalars['String']>;
  hex_color2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type_name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type IdentitySubTypes_Max_Fields = {
  __typename?: 'IdentitySubTypes_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  hex_color?: Maybe<Scalars['String']>;
  hex_color2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type IdentitySubTypes_Min_Fields = {
  __typename?: 'IdentitySubTypes_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  hex_color?: Maybe<Scalars['String']>;
  hex_color2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "IdentitySubTypes" */
export type IdentitySubTypes_Mutation_Response = {
  __typename?: 'IdentitySubTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentitySubTypes>;
};

/** input type for inserting object relation for remote table "IdentitySubTypes" */
export type IdentitySubTypes_Obj_Rel_Insert_Input = {
  data: IdentitySubTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentitySubTypes_On_Conflict>;
};

/** on_conflict condition type for table "IdentitySubTypes" */
export type IdentitySubTypes_On_Conflict = {
  constraint: IdentitySubTypes_Constraint;
  update_columns?: Array<IdentitySubTypes_Update_Column>;
  where?: InputMaybe<IdentitySubTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentitySubTypes". */
export type IdentitySubTypes_Order_By = {
  BenefitTypeIdentitySubTypes_aggregate?: InputMaybe<BenefitTypeIdentitySubTypes_Aggregate_Order_By>;
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Order_By>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Order_By>;
  IdentitySubTypeEmails_aggregate?: InputMaybe<IdentitySubTypeEmails_Aggregate_Order_By>;
  StageEmails_aggregate?: InputMaybe<StageEmails_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  deletable?: InputMaybe<Order_By>;
  hex_color?: InputMaybe<Order_By>;
  hex_color2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type_name?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentitySubTypes */
export type IdentitySubTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "IdentitySubTypes" */
export enum IdentitySubTypes_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Default = 'default',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  HexColor2 = 'hex_color2',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  TypeName = 'type_name',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "IdentitySubTypes" */
export type IdentitySubTypes_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  default?: InputMaybe<Scalars['Boolean']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  hex_color?: InputMaybe<Scalars['String']>;
  hex_color2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type_name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type IdentitySubTypes_Stddev_Fields = {
  __typename?: 'IdentitySubTypes_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IdentitySubTypes_Stddev_Pop_Fields = {
  __typename?: 'IdentitySubTypes_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IdentitySubTypes_Stddev_Samp_Fields = {
  __typename?: 'IdentitySubTypes_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "IdentitySubTypes" */
export type IdentitySubTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentitySubTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentitySubTypes_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  default?: InputMaybe<Scalars['Boolean']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  hex_color?: InputMaybe<Scalars['String']>;
  hex_color2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type_name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type IdentitySubTypes_Sum_Fields = {
  __typename?: 'IdentitySubTypes_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "IdentitySubTypes" */
export enum IdentitySubTypes_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Default = 'default',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  HexColor2 = 'hex_color2',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  TypeName = 'type_name',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type IdentitySubTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentitySubTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentitySubTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentitySubTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentitySubTypes_Var_Pop_Fields = {
  __typename?: 'IdentitySubTypes_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IdentitySubTypes_Var_Samp_Fields = {
  __typename?: 'IdentitySubTypes_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IdentitySubTypes_Variance_Fields = {
  __typename?: 'IdentitySubTypes_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "IdentityTags" */
export type IdentityTags = {
  __typename?: 'IdentityTags';
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Tag: Tags;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  tag_id: Scalars['uuid'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "IdentityTags" */
export type IdentityTags_Aggregate = {
  __typename?: 'IdentityTags_aggregate';
  aggregate?: Maybe<IdentityTags_Aggregate_Fields>;
  nodes: Array<IdentityTags>;
};

export type IdentityTags_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityTags_Aggregate_Bool_Exp_Count>;
};

export type IdentityTags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityTags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityTags" */
export type IdentityTags_Aggregate_Fields = {
  __typename?: 'IdentityTags_aggregate_fields';
  avg?: Maybe<IdentityTags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityTags_Max_Fields>;
  min?: Maybe<IdentityTags_Min_Fields>;
  stddev?: Maybe<IdentityTags_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityTags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityTags_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityTags_Sum_Fields>;
  var_pop?: Maybe<IdentityTags_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityTags_Var_Samp_Fields>;
  variance?: Maybe<IdentityTags_Variance_Fields>;
};


/** aggregate fields of "IdentityTags" */
export type IdentityTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityTags" */
export type IdentityTags_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityTags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityTags_Max_Order_By>;
  min?: InputMaybe<IdentityTags_Min_Order_By>;
  stddev?: InputMaybe<IdentityTags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityTags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityTags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityTags_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityTags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityTags_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityTags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityTags" */
export type IdentityTags_Arr_Rel_Insert_Input = {
  data: Array<IdentityTags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityTags_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityTags_Avg_Fields = {
  __typename?: 'IdentityTags_avg_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityTags" */
export type IdentityTags_Avg_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityTags". All fields are combined with a logical 'AND'. */
export type IdentityTags_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Tag?: InputMaybe<Tags_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityTags_Bool_Exp>>;
  _not?: InputMaybe<IdentityTags_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityTags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityTags" */
export enum IdentityTags_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentityTagsPkey = 'IdentityTags_pkey',
  /** unique or primary key constraint on columns "identity_id", "tag_id" */
  IdentityTagsTagIdIdentityIdKey = 'IdentityTags_tag_id_identity_id_key'
}

/** input type for incrementing numeric columns in table "IdentityTags" */
export type IdentityTags_Inc_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityTags" */
export type IdentityTags_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityTags_Max_Fields = {
  __typename?: 'IdentityTags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityTags" */
export type IdentityTags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityTags_Min_Fields = {
  __typename?: 'IdentityTags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityTags" */
export type IdentityTags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityTags" */
export type IdentityTags_Mutation_Response = {
  __typename?: 'IdentityTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityTags>;
};

/** on_conflict condition type for table "IdentityTags" */
export type IdentityTags_On_Conflict = {
  constraint: IdentityTags_Constraint;
  update_columns?: Array<IdentityTags_Update_Column>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityTags". */
export type IdentityTags_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  Tag?: InputMaybe<Tags_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityTags */
export type IdentityTags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "IdentityTags" */
export enum IdentityTags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentityTags" */
export type IdentityTags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityTags_Stddev_Fields = {
  __typename?: 'IdentityTags_stddev_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityTags" */
export type IdentityTags_Stddev_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityTags_Stddev_Pop_Fields = {
  __typename?: 'IdentityTags_stddev_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityTags" */
export type IdentityTags_Stddev_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityTags_Stddev_Samp_Fields = {
  __typename?: 'IdentityTags_stddev_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityTags" */
export type IdentityTags_Stddev_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityTags" */
export type IdentityTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityTags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityTags_Sum_Fields = {
  __typename?: 'IdentityTags_sum_fields';
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityTags" */
export type IdentityTags_Sum_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityTags" */
export enum IdentityTags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityTags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityTags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityTags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityTags_Var_Pop_Fields = {
  __typename?: 'IdentityTags_var_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityTags" */
export type IdentityTags_Var_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityTags_Var_Samp_Fields = {
  __typename?: 'IdentityTags_var_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityTags" */
export type IdentityTags_Var_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityTags_Variance_Fields = {
  __typename?: 'IdentityTags_variance_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityTags" */
export type IdentityTags_Variance_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "IdentityTokens" */
export type IdentityTokens = {
  __typename?: 'IdentityTokens';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity: Identities;
  accessToken: Scalars['String'];
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  expiresIn: Scalars['String'];
  id: Scalars['Int'];
  identity_id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  refreshToken: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
  service: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_info?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "IdentityTokens" */
export type IdentityTokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "IdentityTokens" */
export type IdentityTokensUser_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "IdentityTokens" */
export type IdentityTokens_Aggregate = {
  __typename?: 'IdentityTokens_aggregate';
  aggregate?: Maybe<IdentityTokens_Aggregate_Fields>;
  nodes: Array<IdentityTokens>;
};

export type IdentityTokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityTokens_Aggregate_Bool_Exp_Count>;
};

export type IdentityTokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityTokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityTokens" */
export type IdentityTokens_Aggregate_Fields = {
  __typename?: 'IdentityTokens_aggregate_fields';
  avg?: Maybe<IdentityTokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityTokens_Max_Fields>;
  min?: Maybe<IdentityTokens_Min_Fields>;
  stddev?: Maybe<IdentityTokens_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityTokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityTokens_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityTokens_Sum_Fields>;
  var_pop?: Maybe<IdentityTokens_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityTokens_Var_Samp_Fields>;
  variance?: Maybe<IdentityTokens_Variance_Fields>;
};


/** aggregate fields of "IdentityTokens" */
export type IdentityTokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityTokens" */
export type IdentityTokens_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityTokens_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityTokens_Max_Order_By>;
  min?: InputMaybe<IdentityTokens_Min_Order_By>;
  stddev?: InputMaybe<IdentityTokens_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityTokens_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityTokens_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityTokens_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityTokens_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityTokens_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityTokens_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IdentityTokens_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  user_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "IdentityTokens" */
export type IdentityTokens_Arr_Rel_Insert_Input = {
  data: Array<IdentityTokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityTokens_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityTokens_Avg_Fields = {
  __typename?: 'IdentityTokens_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityTokens" */
export type IdentityTokens_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityTokens". All fields are combined with a logical 'AND'. */
export type IdentityTokens_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityTokens_Bool_Exp>>;
  _not?: InputMaybe<IdentityTokens_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityTokens_Bool_Exp>>;
  accessToken?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresIn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  refreshToken?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityTokens" */
export enum IdentityTokens_Constraint {
  /** unique or primary key constraint on columns "identity_id", "service" */
  IdentityTokensIdentityIdServiceKey = 'IdentityTokens_identity_id_service_key',
  /** unique or primary key constraint on columns "id" */
  IdentityTokensPkey = 'IdentityTokens_pkey',
  /** unique or primary key constraint on columns "identity_id", "service" */
  IdentityTokensServiceIdentityIdKey = 'IdentityTokens_service_identity_id_key',
  /** unique or primary key constraint on columns "identity_id", "service" */
  IdentitytokensIdentityIdServiceKey = 'identitytokens_identity_id_service_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IdentityTokens_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
  user_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IdentityTokens_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IdentityTokens_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
  user_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "IdentityTokens" */
export type IdentityTokens_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityTokens" */
export type IdentityTokens_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  accessToken?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiresIn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type IdentityTokens_Max_Fields = {
  __typename?: 'IdentityTokens_max_fields';
  accessToken?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiresIn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "IdentityTokens" */
export type IdentityTokens_Max_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiresIn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityTokens_Min_Fields = {
  __typename?: 'IdentityTokens_min_fields';
  accessToken?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiresIn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "IdentityTokens" */
export type IdentityTokens_Min_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiresIn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityTokens" */
export type IdentityTokens_Mutation_Response = {
  __typename?: 'IdentityTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityTokens>;
};

/** on_conflict condition type for table "IdentityTokens" */
export type IdentityTokens_On_Conflict = {
  constraint: IdentityTokens_Constraint;
  update_columns?: Array<IdentityTokens_Update_Column>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityTokens". */
export type IdentityTokens_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  accessToken?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiresIn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityTokens */
export type IdentityTokens_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IdentityTokens_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
  user_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "IdentityTokens" */
export enum IdentityTokens_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Scope = 'scope',
  /** column name */
  Service = 'service',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfo = 'user_info'
}

/** input type for updating data in table "IdentityTokens" */
export type IdentityTokens_Set_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiresIn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type IdentityTokens_Stddev_Fields = {
  __typename?: 'IdentityTokens_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityTokens" */
export type IdentityTokens_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityTokens_Stddev_Pop_Fields = {
  __typename?: 'IdentityTokens_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityTokens" */
export type IdentityTokens_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityTokens_Stddev_Samp_Fields = {
  __typename?: 'IdentityTokens_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityTokens" */
export type IdentityTokens_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityTokens" */
export type IdentityTokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityTokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityTokens_Stream_Cursor_Value_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiresIn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type IdentityTokens_Sum_Fields = {
  __typename?: 'IdentityTokens_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityTokens" */
export type IdentityTokens_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityTokens" */
export enum IdentityTokens_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Scope = 'scope',
  /** column name */
  Service = 'service',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfo = 'user_info'
}

export type IdentityTokens_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IdentityTokens_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IdentityTokens_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IdentityTokens_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IdentityTokens_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityTokens_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IdentityTokens_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityTokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityTokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityTokens_Var_Pop_Fields = {
  __typename?: 'IdentityTokens_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityTokens" */
export type IdentityTokens_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityTokens_Var_Samp_Fields = {
  __typename?: 'IdentityTokens_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityTokens" */
export type IdentityTokens_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityTokens_Variance_Fields = {
  __typename?: 'IdentityTokens_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityTokens" */
export type IdentityTokens_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "IdentityTypes" */
export type IdentityTypes = {
  __typename?: 'IdentityTypes';
  /** An array relationship */
  Identities: Array<Identities>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "IdentityTypes" */
export type IdentityTypesIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "IdentityTypes" */
export type IdentityTypesIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};

/** aggregated selection of "IdentityTypes" */
export type IdentityTypes_Aggregate = {
  __typename?: 'IdentityTypes_aggregate';
  aggregate?: Maybe<IdentityTypes_Aggregate_Fields>;
  nodes: Array<IdentityTypes>;
};

/** aggregate fields of "IdentityTypes" */
export type IdentityTypes_Aggregate_Fields = {
  __typename?: 'IdentityTypes_aggregate_fields';
  avg?: Maybe<IdentityTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityTypes_Max_Fields>;
  min?: Maybe<IdentityTypes_Min_Fields>;
  stddev?: Maybe<IdentityTypes_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityTypes_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityTypes_Sum_Fields>;
  var_pop?: Maybe<IdentityTypes_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityTypes_Var_Samp_Fields>;
  variance?: Maybe<IdentityTypes_Variance_Fields>;
};


/** aggregate fields of "IdentityTypes" */
export type IdentityTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IdentityTypes_Avg_Fields = {
  __typename?: 'IdentityTypes_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "IdentityTypes". All fields are combined with a logical 'AND'. */
export type IdentityTypes_Bool_Exp = {
  Identities?: InputMaybe<Identities_Bool_Exp>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityTypes_Bool_Exp>>;
  _not?: InputMaybe<IdentityTypes_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityTypes_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityTypes" */
export enum IdentityTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  IdentityTypesPkey = 'IdentityTypes_pkey'
}

/** input type for incrementing numeric columns in table "IdentityTypes" */
export type IdentityTypes_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityTypes" */
export type IdentityTypes_Insert_Input = {
  Identities?: InputMaybe<Identities_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IdentityTypes_Max_Fields = {
  __typename?: 'IdentityTypes_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type IdentityTypes_Min_Fields = {
  __typename?: 'IdentityTypes_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "IdentityTypes" */
export type IdentityTypes_Mutation_Response = {
  __typename?: 'IdentityTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityTypes>;
};

/** input type for inserting object relation for remote table "IdentityTypes" */
export type IdentityTypes_Obj_Rel_Insert_Input = {
  data: IdentityTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityTypes_On_Conflict>;
};

/** on_conflict condition type for table "IdentityTypes" */
export type IdentityTypes_On_Conflict = {
  constraint: IdentityTypes_Constraint;
  update_columns?: Array<IdentityTypes_Update_Column>;
  where?: InputMaybe<IdentityTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityTypes". */
export type IdentityTypes_Order_By = {
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityTypes */
export type IdentityTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "IdentityTypes" */
export enum IdentityTypes_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "IdentityTypes" */
export type IdentityTypes_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type IdentityTypes_Stddev_Fields = {
  __typename?: 'IdentityTypes_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IdentityTypes_Stddev_Pop_Fields = {
  __typename?: 'IdentityTypes_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IdentityTypes_Stddev_Samp_Fields = {
  __typename?: 'IdentityTypes_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "IdentityTypes" */
export type IdentityTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityTypes_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IdentityTypes_Sum_Fields = {
  __typename?: 'IdentityTypes_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "IdentityTypes" */
export enum IdentityTypes_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type IdentityTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityTypes_Var_Pop_Fields = {
  __typename?: 'IdentityTypes_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IdentityTypes_Var_Samp_Fields = {
  __typename?: 'IdentityTypes_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IdentityTypes_Variance_Fields = {
  __typename?: 'IdentityTypes_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Identities tied to user access groups */
export type IdentityUserAccessGroup = {
  __typename?: 'IdentityUserAccessGroup';
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  UserAccessGroup: UserAccessGroups;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  identity_id: Scalars['Int'];
  user_access_group_id: Scalars['uuid'];
};

/** aggregated selection of "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Aggregate = {
  __typename?: 'IdentityUserAccessGroup_aggregate';
  aggregate?: Maybe<IdentityUserAccessGroup_Aggregate_Fields>;
  nodes: Array<IdentityUserAccessGroup>;
};

export type IdentityUserAccessGroup_Aggregate_Bool_Exp = {
  count?: InputMaybe<IdentityUserAccessGroup_Aggregate_Bool_Exp_Count>;
};

export type IdentityUserAccessGroup_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Aggregate_Fields = {
  __typename?: 'IdentityUserAccessGroup_aggregate_fields';
  avg?: Maybe<IdentityUserAccessGroup_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityUserAccessGroup_Max_Fields>;
  min?: Maybe<IdentityUserAccessGroup_Min_Fields>;
  stddev?: Maybe<IdentityUserAccessGroup_Stddev_Fields>;
  stddev_pop?: Maybe<IdentityUserAccessGroup_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IdentityUserAccessGroup_Stddev_Samp_Fields>;
  sum?: Maybe<IdentityUserAccessGroup_Sum_Fields>;
  var_pop?: Maybe<IdentityUserAccessGroup_Var_Pop_Fields>;
  var_samp?: Maybe<IdentityUserAccessGroup_Var_Samp_Fields>;
  variance?: Maybe<IdentityUserAccessGroup_Variance_Fields>;
};


/** aggregate fields of "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Aggregate_Order_By = {
  avg?: InputMaybe<IdentityUserAccessGroup_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IdentityUserAccessGroup_Max_Order_By>;
  min?: InputMaybe<IdentityUserAccessGroup_Min_Order_By>;
  stddev?: InputMaybe<IdentityUserAccessGroup_Stddev_Order_By>;
  stddev_pop?: InputMaybe<IdentityUserAccessGroup_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<IdentityUserAccessGroup_Stddev_Samp_Order_By>;
  sum?: InputMaybe<IdentityUserAccessGroup_Sum_Order_By>;
  var_pop?: InputMaybe<IdentityUserAccessGroup_Var_Pop_Order_By>;
  var_samp?: InputMaybe<IdentityUserAccessGroup_Var_Samp_Order_By>;
  variance?: InputMaybe<IdentityUserAccessGroup_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Arr_Rel_Insert_Input = {
  data: Array<IdentityUserAccessGroup_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IdentityUserAccessGroup_On_Conflict>;
};

/** aggregate avg on columns */
export type IdentityUserAccessGroup_Avg_Fields = {
  __typename?: 'IdentityUserAccessGroup_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "IdentityUserAccessGroup". All fields are combined with a logical 'AND'. */
export type IdentityUserAccessGroup_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  UserAccessGroup?: InputMaybe<UserAccessGroups_Bool_Exp>;
  _and?: InputMaybe<Array<IdentityUserAccessGroup_Bool_Exp>>;
  _not?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
  _or?: InputMaybe<Array<IdentityUserAccessGroup_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  user_access_group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "IdentityUserAccessGroup" */
export enum IdentityUserAccessGroup_Constraint {
  /** unique or primary key constraint on columns "identity_id", "user_access_group_id" */
  IdentityUserAccessGroupPkey = 'IdentityUserAccessGroup_pkey'
}

/** input type for incrementing numeric columns in table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  UserAccessGroup?: InputMaybe<UserAccessGroups_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  user_access_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IdentityUserAccessGroup_Max_Fields = {
  __typename?: 'IdentityUserAccessGroup_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  user_access_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  user_access_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IdentityUserAccessGroup_Min_Fields = {
  __typename?: 'IdentityUserAccessGroup_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  user_access_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  user_access_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Mutation_Response = {
  __typename?: 'IdentityUserAccessGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityUserAccessGroup>;
};

/** on_conflict condition type for table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_On_Conflict = {
  constraint: IdentityUserAccessGroup_Constraint;
  update_columns?: Array<IdentityUserAccessGroup_Update_Column>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "IdentityUserAccessGroup". */
export type IdentityUserAccessGroup_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  UserAccessGroup?: InputMaybe<UserAccessGroups_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  user_access_group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: IdentityUserAccessGroup */
export type IdentityUserAccessGroup_Pk_Columns_Input = {
  identity_id: Scalars['Int'];
  user_access_group_id: Scalars['uuid'];
};

/** select columns of table "IdentityUserAccessGroup" */
export enum IdentityUserAccessGroup_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  UserAccessGroupId = 'user_access_group_id'
}

/** input type for updating data in table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  user_access_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type IdentityUserAccessGroup_Stddev_Fields = {
  __typename?: 'IdentityUserAccessGroup_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type IdentityUserAccessGroup_Stddev_Pop_Fields = {
  __typename?: 'IdentityUserAccessGroup_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type IdentityUserAccessGroup_Stddev_Samp_Fields = {
  __typename?: 'IdentityUserAccessGroup_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IdentityUserAccessGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityUserAccessGroup_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  user_access_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type IdentityUserAccessGroup_Sum_Fields = {
  __typename?: 'IdentityUserAccessGroup_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "IdentityUserAccessGroup" */
export enum IdentityUserAccessGroup_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  UserAccessGroupId = 'user_access_group_id'
}

export type IdentityUserAccessGroup_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityUserAccessGroup_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityUserAccessGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: IdentityUserAccessGroup_Bool_Exp;
};

/** aggregate var_pop on columns */
export type IdentityUserAccessGroup_Var_Pop_Fields = {
  __typename?: 'IdentityUserAccessGroup_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type IdentityUserAccessGroup_Var_Samp_Fields = {
  __typename?: 'IdentityUserAccessGroup_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type IdentityUserAccessGroup_Variance_Fields = {
  __typename?: 'IdentityUserAccessGroup_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "IdentityUserAccessGroup" */
export type IdentityUserAccessGroup_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

export type IdibuAccountDetailsOutput = {
  __typename?: 'IdibuAccountDetailsOutput';
  clientHash?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryUserId?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['Int']>;
};

export type IdibuBoardDetailsField = {
  __typename?: 'IdibuBoardDetailsField';
  element?: Maybe<Scalars['String']>;
  maxchars?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  values?: Maybe<IdibuBoardDetailsFieldValues>;
};

export type IdibuBoardDetailsFieldValue = {
  __typename?: 'IdibuBoardDetailsFieldValue';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IdibuBoardDetailsFieldValues = {
  __typename?: 'IdibuBoardDetailsFieldValues';
  value?: Maybe<Array<Maybe<IdibuBoardDetailsFieldValue>>>;
};

export type IdibuBoardDetailsFields = {
  __typename?: 'IdibuBoardDetailsFields';
  field?: Maybe<Array<Maybe<IdibuBoardDetailsField>>>;
};

export type IdibuBoardDetailsOutput = {
  __typename?: 'IdibuBoardDetailsOutput';
  fields?: Maybe<IdibuBoardDetailsFields>;
  help?: Maybe<Scalars['String']>;
};

export type IdibuGenerateLiveAuthToken = {
  __typename?: 'IdibuGenerateLiveAuthToken';
  expires: Scalars['Int'];
  token: Scalars['String'];
};

export type IdibuSubscribeToBoardOutput = {
  __typename?: 'IdibuSubscribeToBoardOutput';
  message: Scalars['String'];
};

export type IdibuSubscribedBoardsOutput = {
  __typename?: 'IdibuSubscribedBoardsOutput';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['String']>;
  supportsAptrack?: Maybe<Scalars['String']>;
  supportsDelete?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type IdibuSyncLiveJobsOutput = {
  __typename?: 'IdibuSyncLiveJobsOutput';
  message: Scalars['String'];
};

export type IdibuUnpublishJobOutput = {
  __typename?: 'IdibuUnpublishJobOutput';
  message: Scalars['String'];
};

export type IdibuUnsubscribeFromBoardOutput = {
  __typename?: 'IdibuUnsubscribeFromBoardOutput';
  message: Scalars['String'];
};

/** columns and relationships of "Images" */
export type Images = {
  __typename?: 'Images';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  created_at: Scalars['timestamptz'];
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Images" */
export type Images_Aggregate = {
  __typename?: 'Images_aggregate';
  aggregate?: Maybe<Images_Aggregate_Fields>;
  nodes: Array<Images>;
};

export type Images_Aggregate_Bool_Exp = {
  count?: InputMaybe<Images_Aggregate_Bool_Exp_Count>;
};

export type Images_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Images_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Images" */
export type Images_Aggregate_Fields = {
  __typename?: 'Images_aggregate_fields';
  avg?: Maybe<Images_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Images_Max_Fields>;
  min?: Maybe<Images_Min_Fields>;
  stddev?: Maybe<Images_Stddev_Fields>;
  stddev_pop?: Maybe<Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Images_Stddev_Samp_Fields>;
  sum?: Maybe<Images_Sum_Fields>;
  var_pop?: Maybe<Images_Var_Pop_Fields>;
  var_samp?: Maybe<Images_Var_Samp_Fields>;
  variance?: Maybe<Images_Variance_Fields>;
};


/** aggregate fields of "Images" */
export type Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Images" */
export type Images_Aggregate_Order_By = {
  avg?: InputMaybe<Images_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Images_Max_Order_By>;
  min?: InputMaybe<Images_Min_Order_By>;
  stddev?: InputMaybe<Images_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Images_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Images_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Images_Sum_Order_By>;
  var_pop?: InputMaybe<Images_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Images_Var_Samp_Order_By>;
  variance?: InputMaybe<Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Images" */
export type Images_Arr_Rel_Insert_Input = {
  data: Array<Images_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Images_Avg_Fields = {
  __typename?: 'Images_avg_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Images" */
export type Images_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Images". All fields are combined with a logical 'AND'. */
export type Images_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Images_Bool_Exp>>;
  _not?: InputMaybe<Images_Bool_Exp>;
  _or?: InputMaybe<Array<Images_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Images" */
export enum Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImagesPkey = 'Images_pkey'
}

/** input type for incrementing numeric columns in table "Images" */
export type Images_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Images" */
export type Images_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Images_Max_Fields = {
  __typename?: 'Images_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Images" */
export type Images_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Images_Min_Fields = {
  __typename?: 'Images_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_type?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Images" */
export type Images_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Images" */
export type Images_Mutation_Response = {
  __typename?: 'Images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Images>;
};

/** on_conflict condition type for table "Images" */
export type Images_On_Conflict = {
  constraint: Images_Constraint;
  update_columns?: Array<Images_Update_Column>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** Ordering options when selecting data from "Images". */
export type Images_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Images */
export type Images_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Images" */
export enum Images_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Key = 'key',
  /** column name */
  Size = 'size',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Images" */
export type Images_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Images_Stddev_Fields = {
  __typename?: 'Images_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Images" */
export type Images_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Images_Stddev_Pop_Fields = {
  __typename?: 'Images_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Images" */
export type Images_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Images_Stddev_Samp_Fields = {
  __typename?: 'Images_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Images" */
export type Images_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Images" */
export type Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Images_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Images_Sum_Fields = {
  __typename?: 'Images_sum_fields';
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Images" */
export type Images_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** update columns of table "Images" */
export enum Images_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Key = 'key',
  /** column name */
  Size = 'size',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Images_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Images_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Images_Set_Input>;
  /** filter the rows which have to be updated */
  where: Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Images_Var_Pop_Fields = {
  __typename?: 'Images_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Images" */
export type Images_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Images_Var_Samp_Fields = {
  __typename?: 'Images_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Images" */
export type Images_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Images_Variance_Fields = {
  __typename?: 'Images_variance_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Images" */
export type Images_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** columns and relationships of "InputTypes" */
export type InputTypes = {
  __typename?: 'InputTypes';
  /** An array relationship */
  FormInputs: Array<FormInputs>;
  /** An aggregate relationship */
  FormInputs_aggregate: FormInputs_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "InputTypes" */
export type InputTypesFormInputsArgs = {
  distinct_on?: InputMaybe<Array<FormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormInputs_Order_By>>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};


/** columns and relationships of "InputTypes" */
export type InputTypesFormInputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormInputs_Order_By>>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};

/** aggregated selection of "InputTypes" */
export type InputTypes_Aggregate = {
  __typename?: 'InputTypes_aggregate';
  aggregate?: Maybe<InputTypes_Aggregate_Fields>;
  nodes: Array<InputTypes>;
};

/** aggregate fields of "InputTypes" */
export type InputTypes_Aggregate_Fields = {
  __typename?: 'InputTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<InputTypes_Max_Fields>;
  min?: Maybe<InputTypes_Min_Fields>;
};


/** aggregate fields of "InputTypes" */
export type InputTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InputTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "InputTypes". All fields are combined with a logical 'AND'. */
export type InputTypes_Bool_Exp = {
  FormInputs?: InputMaybe<FormInputs_Bool_Exp>;
  FormInputs_aggregate?: InputMaybe<FormInputs_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<InputTypes_Bool_Exp>>;
  _not?: InputMaybe<InputTypes_Bool_Exp>;
  _or?: InputMaybe<Array<InputTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "InputTypes" */
export enum InputTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  InputTypesPkey = 'InputTypes_pkey'
}

export enum InputTypes_Enum {
  /** This is an auto-populated field based on user selection. If the field is unable to be populated, the user will be able to manually input data. */
  AutoComplete = 'auto_complete',
  /** This is a single date input field */
  Date = 'date',
  /** This is a free input field */
  Number = 'number',
  /** This is a multi-option select field */
  Select = 'select',
  /** This is a single line text input */
  Text = 'text',
  /** This is a text area input field */
  Textarea = 'textarea'
}

/** Boolean expression to compare columns of type "InputTypes_enum". All fields are combined with logical 'AND'. */
export type InputTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<InputTypes_Enum>;
  _in?: InputMaybe<Array<InputTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<InputTypes_Enum>;
  _nin?: InputMaybe<Array<InputTypes_Enum>>;
};

/** input type for inserting data into table "InputTypes" */
export type InputTypes_Insert_Input = {
  FormInputs?: InputMaybe<FormInputs_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type InputTypes_Max_Fields = {
  __typename?: 'InputTypes_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type InputTypes_Min_Fields = {
  __typename?: 'InputTypes_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "InputTypes" */
export type InputTypes_Mutation_Response = {
  __typename?: 'InputTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InputTypes>;
};

/** input type for inserting object relation for remote table "InputTypes" */
export type InputTypes_Obj_Rel_Insert_Input = {
  data: InputTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<InputTypes_On_Conflict>;
};

/** on_conflict condition type for table "InputTypes" */
export type InputTypes_On_Conflict = {
  constraint: InputTypes_Constraint;
  update_columns?: Array<InputTypes_Update_Column>;
  where?: InputMaybe<InputTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "InputTypes". */
export type InputTypes_Order_By = {
  FormInputs_aggregate?: InputMaybe<FormInputs_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: InputTypes */
export type InputTypes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "InputTypes" */
export enum InputTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "InputTypes" */
export type InputTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "InputTypes" */
export type InputTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InputTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InputTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "InputTypes" */
export enum InputTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type InputTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InputTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: InputTypes_Bool_Exp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntegrationStatus = {
  __typename?: 'IntegrationStatus';
  accountId: Scalars['String'];
  authenticated: Scalars['Boolean'];
  email: Scalars['String'];
  identityId: Scalars['Int'];
  nylasAccessToken?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  provider: Scalars['String'];
  shared: Scalars['Boolean'];
};

/** columns and relationships of "InternalJobCategories" */
export type InternalJobCategories = {
  __typename?: 'InternalJobCategories';
  /** An object relationship */
  Company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "InternalJobCategories" */
export type InternalJobCategories_Aggregate = {
  __typename?: 'InternalJobCategories_aggregate';
  aggregate?: Maybe<InternalJobCategories_Aggregate_Fields>;
  nodes: Array<InternalJobCategories>;
};

/** aggregate fields of "InternalJobCategories" */
export type InternalJobCategories_Aggregate_Fields = {
  __typename?: 'InternalJobCategories_aggregate_fields';
  avg?: Maybe<InternalJobCategories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InternalJobCategories_Max_Fields>;
  min?: Maybe<InternalJobCategories_Min_Fields>;
  stddev?: Maybe<InternalJobCategories_Stddev_Fields>;
  stddev_pop?: Maybe<InternalJobCategories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InternalJobCategories_Stddev_Samp_Fields>;
  sum?: Maybe<InternalJobCategories_Sum_Fields>;
  var_pop?: Maybe<InternalJobCategories_Var_Pop_Fields>;
  var_samp?: Maybe<InternalJobCategories_Var_Samp_Fields>;
  variance?: Maybe<InternalJobCategories_Variance_Fields>;
};


/** aggregate fields of "InternalJobCategories" */
export type InternalJobCategories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InternalJobCategories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type InternalJobCategories_Avg_Fields = {
  __typename?: 'InternalJobCategories_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "InternalJobCategories". All fields are combined with a logical 'AND'. */
export type InternalJobCategories_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  _and?: InputMaybe<Array<InternalJobCategories_Bool_Exp>>;
  _not?: InputMaybe<InternalJobCategories_Bool_Exp>;
  _or?: InputMaybe<Array<InternalJobCategories_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "InternalJobCategories" */
export enum InternalJobCategories_Constraint {
  /** unique or primary key constraint on columns "id" */
  InternalJobCategoriesPkey = 'InternalJobCategories_pkey'
}

/** input type for incrementing numeric columns in table "InternalJobCategories" */
export type InternalJobCategories_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "InternalJobCategories" */
export type InternalJobCategories_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type InternalJobCategories_Max_Fields = {
  __typename?: 'InternalJobCategories_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type InternalJobCategories_Min_Fields = {
  __typename?: 'InternalJobCategories_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "InternalJobCategories" */
export type InternalJobCategories_Mutation_Response = {
  __typename?: 'InternalJobCategories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InternalJobCategories>;
};

/** on_conflict condition type for table "InternalJobCategories" */
export type InternalJobCategories_On_Conflict = {
  constraint: InternalJobCategories_Constraint;
  update_columns?: Array<InternalJobCategories_Update_Column>;
  where?: InputMaybe<InternalJobCategories_Bool_Exp>;
};

/** Ordering options when selecting data from "InternalJobCategories". */
export type InternalJobCategories_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: InternalJobCategories */
export type InternalJobCategories_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "InternalJobCategories" */
export enum InternalJobCategories_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "InternalJobCategories" */
export type InternalJobCategories_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type InternalJobCategories_Stddev_Fields = {
  __typename?: 'InternalJobCategories_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type InternalJobCategories_Stddev_Pop_Fields = {
  __typename?: 'InternalJobCategories_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type InternalJobCategories_Stddev_Samp_Fields = {
  __typename?: 'InternalJobCategories_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "InternalJobCategories" */
export type InternalJobCategories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InternalJobCategories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InternalJobCategories_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type InternalJobCategories_Sum_Fields = {
  __typename?: 'InternalJobCategories_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "InternalJobCategories" */
export enum InternalJobCategories_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type InternalJobCategories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InternalJobCategories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InternalJobCategories_Set_Input>;
  /** filter the rows which have to be updated */
  where: InternalJobCategories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InternalJobCategories_Var_Pop_Fields = {
  __typename?: 'InternalJobCategories_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type InternalJobCategories_Var_Samp_Fields = {
  __typename?: 'InternalJobCategories_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type InternalJobCategories_Variance_Fields = {
  __typename?: 'InternalJobCategories_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Interviews" */
export type Interviews = {
  __typename?: 'Interviews';
  /** An object relationship */
  BoardSnippet: BoardSnippets;
  /** An object relationship */
  Candidate: Identities;
  /** An object relationship */
  Email?: Maybe<Emails>;
  /** An object relationship */
  Interviewer?: Maybe<Identities>;
  candidate_chosen_time?: Maybe<Scalars['jsonb']>;
  candidate_id: Scalars['Int'];
  company_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  interviewer_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  proposed_time_slots?: Maybe<Scalars['jsonb']>;
  snippet_id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid: Scalars['uuid'];
};


/** columns and relationships of "Interviews" */
export type InterviewsCandidate_Chosen_TimeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Interviews" */
export type InterviewsProposed_Time_SlotsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Interviews" */
export type Interviews_Aggregate = {
  __typename?: 'Interviews_aggregate';
  aggregate?: Maybe<Interviews_Aggregate_Fields>;
  nodes: Array<Interviews>;
};

export type Interviews_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interviews_Aggregate_Bool_Exp_Count>;
};

export type Interviews_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Interviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Interviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Interviews" */
export type Interviews_Aggregate_Fields = {
  __typename?: 'Interviews_aggregate_fields';
  avg?: Maybe<Interviews_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Interviews_Max_Fields>;
  min?: Maybe<Interviews_Min_Fields>;
  stddev?: Maybe<Interviews_Stddev_Fields>;
  stddev_pop?: Maybe<Interviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Interviews_Stddev_Samp_Fields>;
  sum?: Maybe<Interviews_Sum_Fields>;
  var_pop?: Maybe<Interviews_Var_Pop_Fields>;
  var_samp?: Maybe<Interviews_Var_Samp_Fields>;
  variance?: Maybe<Interviews_Variance_Fields>;
};


/** aggregate fields of "Interviews" */
export type Interviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Interviews" */
export type Interviews_Aggregate_Order_By = {
  avg?: InputMaybe<Interviews_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interviews_Max_Order_By>;
  min?: InputMaybe<Interviews_Min_Order_By>;
  stddev?: InputMaybe<Interviews_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Interviews_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Interviews_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Interviews_Sum_Order_By>;
  var_pop?: InputMaybe<Interviews_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Interviews_Var_Samp_Order_By>;
  variance?: InputMaybe<Interviews_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Interviews_Append_Input = {
  candidate_chosen_time?: InputMaybe<Scalars['jsonb']>;
  proposed_time_slots?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Interviews" */
export type Interviews_Arr_Rel_Insert_Input = {
  data: Array<Interviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interviews_On_Conflict>;
};

/** aggregate avg on columns */
export type Interviews_Avg_Fields = {
  __typename?: 'Interviews_avg_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interviewer_id?: Maybe<Scalars['Float']>;
  snippet_id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Interviews" */
export type Interviews_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Interviews". All fields are combined with a logical 'AND'. */
export type Interviews_Bool_Exp = {
  BoardSnippet?: InputMaybe<BoardSnippets_Bool_Exp>;
  Candidate?: InputMaybe<Identities_Bool_Exp>;
  Email?: InputMaybe<Emails_Bool_Exp>;
  Interviewer?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Interviews_Bool_Exp>>;
  _not?: InputMaybe<Interviews_Bool_Exp>;
  _or?: InputMaybe<Array<Interviews_Bool_Exp>>;
  candidate_chosen_time?: InputMaybe<Jsonb_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interviewer_id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  proposed_time_slots?: InputMaybe<Jsonb_Comparison_Exp>;
  snippet_id?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  template_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Interviews" */
export enum Interviews_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewsPkey = 'Interviews_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Interviews_Delete_At_Path_Input = {
  candidate_chosen_time?: InputMaybe<Array<Scalars['String']>>;
  proposed_time_slots?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Interviews_Delete_Elem_Input = {
  candidate_chosen_time?: InputMaybe<Scalars['Int']>;
  proposed_time_slots?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Interviews_Delete_Key_Input = {
  candidate_chosen_time?: InputMaybe<Scalars['String']>;
  proposed_time_slots?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Interviews" */
export type Interviews_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_id?: InputMaybe<Scalars['Int']>;
  snippet_id?: InputMaybe<Scalars['Int']>;
  template_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Interviews" */
export type Interviews_Insert_Input = {
  BoardSnippet?: InputMaybe<BoardSnippets_Obj_Rel_Insert_Input>;
  Candidate?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Email?: InputMaybe<Emails_Obj_Rel_Insert_Input>;
  Interviewer?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  candidate_chosen_time?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  proposed_time_slots?: InputMaybe<Scalars['jsonb']>;
  snippet_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  template_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Interviews_Max_Fields = {
  __typename?: 'Interviews_max_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interviewer_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  snippet_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Interviews" */
export type Interviews_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interviews_Min_Fields = {
  __typename?: 'Interviews_min_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  interviewer_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  snippet_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Interviews" */
export type Interviews_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Interviews" */
export type Interviews_Mutation_Response = {
  __typename?: 'Interviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interviews>;
};

/** on_conflict condition type for table "Interviews" */
export type Interviews_On_Conflict = {
  constraint: Interviews_Constraint;
  update_columns?: Array<Interviews_Update_Column>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};

/** Ordering options when selecting data from "Interviews". */
export type Interviews_Order_By = {
  BoardSnippet?: InputMaybe<BoardSnippets_Order_By>;
  Candidate?: InputMaybe<Identities_Order_By>;
  Email?: InputMaybe<Emails_Order_By>;
  Interviewer?: InputMaybe<Identities_Order_By>;
  candidate_chosen_time?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  proposed_time_slots?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Interviews */
export type Interviews_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Interviews_Prepend_Input = {
  candidate_chosen_time?: InputMaybe<Scalars['jsonb']>;
  proposed_time_slots?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Interviews" */
export enum Interviews_Select_Column {
  /** column name */
  CandidateChosenTime = 'candidate_chosen_time',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewerId = 'interviewer_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProposedTimeSlots = 'proposed_time_slots',
  /** column name */
  SnippetId = 'snippet_id',
  /** column name */
  State = 'state',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "Interviews" */
export type Interviews_Set_Input = {
  candidate_chosen_time?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  proposed_time_slots?: InputMaybe<Scalars['jsonb']>;
  snippet_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  template_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Interviews_Stddev_Fields = {
  __typename?: 'Interviews_stddev_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interviewer_id?: Maybe<Scalars['Float']>;
  snippet_id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Interviews" */
export type Interviews_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Interviews_Stddev_Pop_Fields = {
  __typename?: 'Interviews_stddev_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interviewer_id?: Maybe<Scalars['Float']>;
  snippet_id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Interviews" */
export type Interviews_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Interviews_Stddev_Samp_Fields = {
  __typename?: 'Interviews_stddev_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interviewer_id?: Maybe<Scalars['Float']>;
  snippet_id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Interviews" */
export type Interviews_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Interviews" */
export type Interviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interviews_Stream_Cursor_Value_Input = {
  candidate_chosen_time?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  proposed_time_slots?: InputMaybe<Scalars['jsonb']>;
  snippet_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  template_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Interviews_Sum_Fields = {
  __typename?: 'Interviews_sum_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  interviewer_id?: Maybe<Scalars['Int']>;
  snippet_id?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Interviews" */
export type Interviews_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** update columns of table "Interviews" */
export enum Interviews_Update_Column {
  /** column name */
  CandidateChosenTime = 'candidate_chosen_time',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewerId = 'interviewer_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProposedTimeSlots = 'proposed_time_slots',
  /** column name */
  SnippetId = 'snippet_id',
  /** column name */
  State = 'state',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

export type Interviews_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Interviews_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Interviews_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Interviews_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Interviews_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interviews_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Interviews_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interviews_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interviews_Var_Pop_Fields = {
  __typename?: 'Interviews_var_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interviewer_id?: Maybe<Scalars['Float']>;
  snippet_id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Interviews" */
export type Interviews_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Interviews_Var_Samp_Fields = {
  __typename?: 'Interviews_var_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interviewer_id?: Maybe<Scalars['Float']>;
  snippet_id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Interviews" */
export type Interviews_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Interviews_Variance_Fields = {
  __typename?: 'Interviews_variance_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interviewer_id?: Maybe<Scalars['Float']>;
  snippet_id?: Maybe<Scalars['Float']>;
  template_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Interviews" */
export type Interviews_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_id?: InputMaybe<Order_By>;
  snippet_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
};

export type JobApplicationInput = {
  acknowledgement?: InputMaybe<Scalars['Boolean']>;
  bucket_key: Scalars['String'];
  city: Scalars['String'];
  clearance: Scalars['String'];
  clearance_id: Scalars['Int'];
  gender?: InputMaybe<Scalars['String']>;
  gov_contract_participant?: InputMaybe<Scalars['Boolean']>;
  gov_employment_status?: InputMaybe<Scalars['String']>;
  has_disability?: InputMaybe<Scalars['String']>;
  job_id: Scalars['Int'];
  linkedin_url: Scalars['String'];
  mobile_phone: Scalars['String'];
  name_first: Scalars['String'];
  name_last: Scalars['String'];
  name_middle?: InputMaybe<Scalars['String']>;
  personal_email: Scalars['String'];
  questionnaire_id?: InputMaybe<Scalars['String']>;
  questionnaire_model?: InputMaybe<Scalars['String']>;
  questionnaire_result?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  recaptcha_token: Scalars['String'];
  referred_by: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  subdomain: Scalars['String'];
  veteran_status?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type JobApplicationOutput = {
  __typename?: 'JobApplicationOutput';
  message: Scalars['String'];
};

/** columns and relationships of "JobAvailabilities" */
export type JobAvailabilities = {
  __typename?: 'JobAvailabilities';
  /** An object relationship */
  CreatedBy: Identities;
  /** An object relationship */
  UpdatedBy: Identities;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};

/** aggregated selection of "JobAvailabilities" */
export type JobAvailabilities_Aggregate = {
  __typename?: 'JobAvailabilities_aggregate';
  aggregate?: Maybe<JobAvailabilities_Aggregate_Fields>;
  nodes: Array<JobAvailabilities>;
};

/** aggregate fields of "JobAvailabilities" */
export type JobAvailabilities_Aggregate_Fields = {
  __typename?: 'JobAvailabilities_aggregate_fields';
  avg?: Maybe<JobAvailabilities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<JobAvailabilities_Max_Fields>;
  min?: Maybe<JobAvailabilities_Min_Fields>;
  stddev?: Maybe<JobAvailabilities_Stddev_Fields>;
  stddev_pop?: Maybe<JobAvailabilities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<JobAvailabilities_Stddev_Samp_Fields>;
  sum?: Maybe<JobAvailabilities_Sum_Fields>;
  var_pop?: Maybe<JobAvailabilities_Var_Pop_Fields>;
  var_samp?: Maybe<JobAvailabilities_Var_Samp_Fields>;
  variance?: Maybe<JobAvailabilities_Variance_Fields>;
};


/** aggregate fields of "JobAvailabilities" */
export type JobAvailabilities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobAvailabilities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type JobAvailabilities_Avg_Fields = {
  __typename?: 'JobAvailabilities_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "JobAvailabilities". All fields are combined with a logical 'AND'. */
export type JobAvailabilities_Bool_Exp = {
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<JobAvailabilities_Bool_Exp>>;
  _not?: InputMaybe<JobAvailabilities_Bool_Exp>;
  _or?: InputMaybe<Array<JobAvailabilities_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "JobAvailabilities" */
export enum JobAvailabilities_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobAvailabilitiesPkey = 'JobAvailabilities_pkey'
}

/** input type for incrementing numeric columns in table "JobAvailabilities" */
export type JobAvailabilities_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "JobAvailabilities" */
export type JobAvailabilities_Insert_Input = {
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type JobAvailabilities_Max_Fields = {
  __typename?: 'JobAvailabilities_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type JobAvailabilities_Min_Fields = {
  __typename?: 'JobAvailabilities_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "JobAvailabilities" */
export type JobAvailabilities_Mutation_Response = {
  __typename?: 'JobAvailabilities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobAvailabilities>;
};

/** input type for inserting object relation for remote table "JobAvailabilities" */
export type JobAvailabilities_Obj_Rel_Insert_Input = {
  data: JobAvailabilities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<JobAvailabilities_On_Conflict>;
};

/** on_conflict condition type for table "JobAvailabilities" */
export type JobAvailabilities_On_Conflict = {
  constraint: JobAvailabilities_Constraint;
  update_columns?: Array<JobAvailabilities_Update_Column>;
  where?: InputMaybe<JobAvailabilities_Bool_Exp>;
};

/** Ordering options when selecting data from "JobAvailabilities". */
export type JobAvailabilities_Order_By = {
  CreatedBy?: InputMaybe<Identities_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: JobAvailabilities */
export type JobAvailabilities_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "JobAvailabilities" */
export enum JobAvailabilities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "JobAvailabilities" */
export type JobAvailabilities_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type JobAvailabilities_Stddev_Fields = {
  __typename?: 'JobAvailabilities_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type JobAvailabilities_Stddev_Pop_Fields = {
  __typename?: 'JobAvailabilities_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type JobAvailabilities_Stddev_Samp_Fields = {
  __typename?: 'JobAvailabilities_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "JobAvailabilities" */
export type JobAvailabilities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobAvailabilities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobAvailabilities_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type JobAvailabilities_Sum_Fields = {
  __typename?: 'JobAvailabilities_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "JobAvailabilities" */
export enum JobAvailabilities_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type JobAvailabilities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<JobAvailabilities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobAvailabilities_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobAvailabilities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type JobAvailabilities_Var_Pop_Fields = {
  __typename?: 'JobAvailabilities_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type JobAvailabilities_Var_Samp_Fields = {
  __typename?: 'JobAvailabilities_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type JobAvailabilities_Variance_Fields = {
  __typename?: 'JobAvailabilities_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Join table between jobs and comments */
export type JobComments = {
  __typename?: 'JobComments';
  /** An object relationship */
  Comment: Comments;
  /** An object relationship */
  Job: Jobs;
  comment_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  job_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "JobComments" */
export type JobComments_Aggregate = {
  __typename?: 'JobComments_aggregate';
  aggregate?: Maybe<JobComments_Aggregate_Fields>;
  nodes: Array<JobComments>;
};

export type JobComments_Aggregate_Bool_Exp = {
  count?: InputMaybe<JobComments_Aggregate_Bool_Exp_Count>;
};

export type JobComments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<JobComments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JobComments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "JobComments" */
export type JobComments_Aggregate_Fields = {
  __typename?: 'JobComments_aggregate_fields';
  avg?: Maybe<JobComments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<JobComments_Max_Fields>;
  min?: Maybe<JobComments_Min_Fields>;
  stddev?: Maybe<JobComments_Stddev_Fields>;
  stddev_pop?: Maybe<JobComments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<JobComments_Stddev_Samp_Fields>;
  sum?: Maybe<JobComments_Sum_Fields>;
  var_pop?: Maybe<JobComments_Var_Pop_Fields>;
  var_samp?: Maybe<JobComments_Var_Samp_Fields>;
  variance?: Maybe<JobComments_Variance_Fields>;
};


/** aggregate fields of "JobComments" */
export type JobComments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobComments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "JobComments" */
export type JobComments_Aggregate_Order_By = {
  avg?: InputMaybe<JobComments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<JobComments_Max_Order_By>;
  min?: InputMaybe<JobComments_Min_Order_By>;
  stddev?: InputMaybe<JobComments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<JobComments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<JobComments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<JobComments_Sum_Order_By>;
  var_pop?: InputMaybe<JobComments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<JobComments_Var_Samp_Order_By>;
  variance?: InputMaybe<JobComments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "JobComments" */
export type JobComments_Arr_Rel_Insert_Input = {
  data: Array<JobComments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<JobComments_On_Conflict>;
};

/** aggregate avg on columns */
export type JobComments_Avg_Fields = {
  __typename?: 'JobComments_avg_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "JobComments" */
export type JobComments_Avg_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "JobComments". All fields are combined with a logical 'AND'. */
export type JobComments_Bool_Exp = {
  Comment?: InputMaybe<Comments_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<JobComments_Bool_Exp>>;
  _not?: InputMaybe<JobComments_Bool_Exp>;
  _or?: InputMaybe<Array<JobComments_Bool_Exp>>;
  comment_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "JobComments" */
export enum JobComments_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobCommentsPkey = 'JobComments_pkey'
}

/** input type for incrementing numeric columns in table "JobComments" */
export type JobComments_Inc_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "JobComments" */
export type JobComments_Insert_Input = {
  Comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type JobComments_Max_Fields = {
  __typename?: 'JobComments_max_fields';
  comment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "JobComments" */
export type JobComments_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type JobComments_Min_Fields = {
  __typename?: 'JobComments_min_fields';
  comment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "JobComments" */
export type JobComments_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "JobComments" */
export type JobComments_Mutation_Response = {
  __typename?: 'JobComments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobComments>;
};

/** on_conflict condition type for table "JobComments" */
export type JobComments_On_Conflict = {
  constraint: JobComments_Constraint;
  update_columns?: Array<JobComments_Update_Column>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};

/** Ordering options when selecting data from "JobComments". */
export type JobComments_Order_By = {
  Comment?: InputMaybe<Comments_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: JobComments */
export type JobComments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "JobComments" */
export enum JobComments_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "JobComments" */
export type JobComments_Set_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type JobComments_Stddev_Fields = {
  __typename?: 'JobComments_stddev_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "JobComments" */
export type JobComments_Stddev_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type JobComments_Stddev_Pop_Fields = {
  __typename?: 'JobComments_stddev_pop_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "JobComments" */
export type JobComments_Stddev_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type JobComments_Stddev_Samp_Fields = {
  __typename?: 'JobComments_stddev_samp_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "JobComments" */
export type JobComments_Stddev_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "JobComments" */
export type JobComments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobComments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobComments_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type JobComments_Sum_Fields = {
  __typename?: 'JobComments_sum_fields';
  comment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "JobComments" */
export type JobComments_Sum_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** update columns of table "JobComments" */
export enum JobComments_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type JobComments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<JobComments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobComments_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobComments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type JobComments_Var_Pop_Fields = {
  __typename?: 'JobComments_var_pop_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "JobComments" */
export type JobComments_Var_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type JobComments_Var_Samp_Fields = {
  __typename?: 'JobComments_var_samp_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "JobComments" */
export type JobComments_Var_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type JobComments_Variance_Fields = {
  __typename?: 'JobComments_variance_fields';
  comment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "JobComments" */
export type JobComments_Variance_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Files attached to jobs */
export type JobFiles = {
  __typename?: 'JobFiles';
  /** An object relationship */
  File: Files;
  /** An object relationship */
  Job: Jobs;
  created_at: Scalars['timestamptz'];
  file_id: Scalars['Int'];
  id: Scalars['uuid'];
  job_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "JobFiles" */
export type JobFiles_Aggregate = {
  __typename?: 'JobFiles_aggregate';
  aggregate?: Maybe<JobFiles_Aggregate_Fields>;
  nodes: Array<JobFiles>;
};

/** aggregate fields of "JobFiles" */
export type JobFiles_Aggregate_Fields = {
  __typename?: 'JobFiles_aggregate_fields';
  avg?: Maybe<JobFiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<JobFiles_Max_Fields>;
  min?: Maybe<JobFiles_Min_Fields>;
  stddev?: Maybe<JobFiles_Stddev_Fields>;
  stddev_pop?: Maybe<JobFiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<JobFiles_Stddev_Samp_Fields>;
  sum?: Maybe<JobFiles_Sum_Fields>;
  var_pop?: Maybe<JobFiles_Var_Pop_Fields>;
  var_samp?: Maybe<JobFiles_Var_Samp_Fields>;
  variance?: Maybe<JobFiles_Variance_Fields>;
};


/** aggregate fields of "JobFiles" */
export type JobFiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type JobFiles_Avg_Fields = {
  __typename?: 'JobFiles_avg_fields';
  file_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "JobFiles". All fields are combined with a logical 'AND'. */
export type JobFiles_Bool_Exp = {
  File?: InputMaybe<Files_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<JobFiles_Bool_Exp>>;
  _not?: InputMaybe<JobFiles_Bool_Exp>;
  _or?: InputMaybe<Array<JobFiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "JobFiles" */
export enum JobFiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobFilesPkey = 'JobFiles_pkey'
}

/** input type for incrementing numeric columns in table "JobFiles" */
export type JobFiles_Inc_Input = {
  file_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "JobFiles" */
export type JobFiles_Insert_Input = {
  File?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type JobFiles_Max_Fields = {
  __typename?: 'JobFiles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type JobFiles_Min_Fields = {
  __typename?: 'JobFiles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "JobFiles" */
export type JobFiles_Mutation_Response = {
  __typename?: 'JobFiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobFiles>;
};

/** input type for inserting object relation for remote table "JobFiles" */
export type JobFiles_Obj_Rel_Insert_Input = {
  data: JobFiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<JobFiles_On_Conflict>;
};

/** on_conflict condition type for table "JobFiles" */
export type JobFiles_On_Conflict = {
  constraint: JobFiles_Constraint;
  update_columns?: Array<JobFiles_Update_Column>;
  where?: InputMaybe<JobFiles_Bool_Exp>;
};

/** Ordering options when selecting data from "JobFiles". */
export type JobFiles_Order_By = {
  File?: InputMaybe<Files_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: JobFiles */
export type JobFiles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "JobFiles" */
export enum JobFiles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "JobFiles" */
export type JobFiles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type JobFiles_Stddev_Fields = {
  __typename?: 'JobFiles_stddev_fields';
  file_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type JobFiles_Stddev_Pop_Fields = {
  __typename?: 'JobFiles_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type JobFiles_Stddev_Samp_Fields = {
  __typename?: 'JobFiles_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "JobFiles" */
export type JobFiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobFiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobFiles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type JobFiles_Sum_Fields = {
  __typename?: 'JobFiles_sum_fields';
  file_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "JobFiles" */
export enum JobFiles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type JobFiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<JobFiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobFiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobFiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type JobFiles_Var_Pop_Fields = {
  __typename?: 'JobFiles_var_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type JobFiles_Var_Samp_Fields = {
  __typename?: 'JobFiles_var_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type JobFiles_Variance_Fields = {
  __typename?: 'JobFiles_variance_fields';
  file_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "JobFundingStatusEnum" */
export type JobFundingStatusEnum = {
  __typename?: 'JobFundingStatusEnum';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "JobFundingStatusEnum" */
export type JobFundingStatusEnum_Aggregate = {
  __typename?: 'JobFundingStatusEnum_aggregate';
  aggregate?: Maybe<JobFundingStatusEnum_Aggregate_Fields>;
  nodes: Array<JobFundingStatusEnum>;
};

/** aggregate fields of "JobFundingStatusEnum" */
export type JobFundingStatusEnum_Aggregate_Fields = {
  __typename?: 'JobFundingStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<JobFundingStatusEnum_Max_Fields>;
  min?: Maybe<JobFundingStatusEnum_Min_Fields>;
};


/** aggregate fields of "JobFundingStatusEnum" */
export type JobFundingStatusEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobFundingStatusEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "JobFundingStatusEnum". All fields are combined with a logical 'AND'. */
export type JobFundingStatusEnum_Bool_Exp = {
  _and?: InputMaybe<Array<JobFundingStatusEnum_Bool_Exp>>;
  _not?: InputMaybe<JobFundingStatusEnum_Bool_Exp>;
  _or?: InputMaybe<Array<JobFundingStatusEnum_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "JobFundingStatusEnum" */
export enum JobFundingStatusEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobFundingStatusEnumPkey = 'JobFundingStatusEnum_pkey'
}

export enum JobFundingStatusEnum_Enum {
  /** This job was once funded, but is no longer funded */
  Defunded = 'Defunded',
  /** This job is funded */
  Funded = 'Funded',
  /** This job is not funded */
  Unfunded = 'Unfunded',
  /** Funding for this job is unknown */
  Unspecified = 'Unspecified'
}

/** Boolean expression to compare columns of type "JobFundingStatusEnum_enum". All fields are combined with logical 'AND'. */
export type JobFundingStatusEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<JobFundingStatusEnum_Enum>;
  _in?: InputMaybe<Array<JobFundingStatusEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<JobFundingStatusEnum_Enum>;
  _nin?: InputMaybe<Array<JobFundingStatusEnum_Enum>>;
};

/** input type for inserting data into table "JobFundingStatusEnum" */
export type JobFundingStatusEnum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type JobFundingStatusEnum_Max_Fields = {
  __typename?: 'JobFundingStatusEnum_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type JobFundingStatusEnum_Min_Fields = {
  __typename?: 'JobFundingStatusEnum_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "JobFundingStatusEnum" */
export type JobFundingStatusEnum_Mutation_Response = {
  __typename?: 'JobFundingStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobFundingStatusEnum>;
};

/** on_conflict condition type for table "JobFundingStatusEnum" */
export type JobFundingStatusEnum_On_Conflict = {
  constraint: JobFundingStatusEnum_Constraint;
  update_columns?: Array<JobFundingStatusEnum_Update_Column>;
  where?: InputMaybe<JobFundingStatusEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "JobFundingStatusEnum". */
export type JobFundingStatusEnum_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: JobFundingStatusEnum */
export type JobFundingStatusEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "JobFundingStatusEnum" */
export enum JobFundingStatusEnum_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "JobFundingStatusEnum" */
export type JobFundingStatusEnum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "JobFundingStatusEnum" */
export type JobFundingStatusEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobFundingStatusEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobFundingStatusEnum_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "JobFundingStatusEnum" */
export enum JobFundingStatusEnum_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type JobFundingStatusEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobFundingStatusEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobFundingStatusEnum_Bool_Exp;
};

/** columns and relationships of "JobHireTypesEnum" */
export type JobHireTypesEnum = {
  __typename?: 'JobHireTypesEnum';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "JobHireTypesEnum" */
export type JobHireTypesEnum_Aggregate = {
  __typename?: 'JobHireTypesEnum_aggregate';
  aggregate?: Maybe<JobHireTypesEnum_Aggregate_Fields>;
  nodes: Array<JobHireTypesEnum>;
};

/** aggregate fields of "JobHireTypesEnum" */
export type JobHireTypesEnum_Aggregate_Fields = {
  __typename?: 'JobHireTypesEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<JobHireTypesEnum_Max_Fields>;
  min?: Maybe<JobHireTypesEnum_Min_Fields>;
};


/** aggregate fields of "JobHireTypesEnum" */
export type JobHireTypesEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobHireTypesEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "JobHireTypesEnum". All fields are combined with a logical 'AND'. */
export type JobHireTypesEnum_Bool_Exp = {
  _and?: InputMaybe<Array<JobHireTypesEnum_Bool_Exp>>;
  _not?: InputMaybe<JobHireTypesEnum_Bool_Exp>;
  _or?: InputMaybe<Array<JobHireTypesEnum_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "JobHireTypesEnum" */
export enum JobHireTypesEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobHireTypesEnumPkey = 'JobHireTypesEnum_pkey'
}

export enum JobHireTypesEnum_Enum {
  /** Jobs of this hire type convert candidates to employees and employees are tracked in your AAP and OFCCP Reports */
  ContractEmployeeHire = 'CONTRACT_EMPLOYEE_HIRE',
  /** Use this type when hiring candidates for another company, not yours.. Candidates hired for this job will be included in your analytics as a hire, but will not show up in your employees list. */
  DirectPlacement = 'DIRECT_PLACEMENT'
}

/** Boolean expression to compare columns of type "JobHireTypesEnum_enum". All fields are combined with logical 'AND'. */
export type JobHireTypesEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<JobHireTypesEnum_Enum>;
  _in?: InputMaybe<Array<JobHireTypesEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<JobHireTypesEnum_Enum>;
  _nin?: InputMaybe<Array<JobHireTypesEnum_Enum>>;
};

/** input type for inserting data into table "JobHireTypesEnum" */
export type JobHireTypesEnum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type JobHireTypesEnum_Max_Fields = {
  __typename?: 'JobHireTypesEnum_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type JobHireTypesEnum_Min_Fields = {
  __typename?: 'JobHireTypesEnum_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "JobHireTypesEnum" */
export type JobHireTypesEnum_Mutation_Response = {
  __typename?: 'JobHireTypesEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobHireTypesEnum>;
};

/** on_conflict condition type for table "JobHireTypesEnum" */
export type JobHireTypesEnum_On_Conflict = {
  constraint: JobHireTypesEnum_Constraint;
  update_columns?: Array<JobHireTypesEnum_Update_Column>;
  where?: InputMaybe<JobHireTypesEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "JobHireTypesEnum". */
export type JobHireTypesEnum_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: JobHireTypesEnum */
export type JobHireTypesEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "JobHireTypesEnum" */
export enum JobHireTypesEnum_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "JobHireTypesEnum" */
export type JobHireTypesEnum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "JobHireTypesEnum" */
export type JobHireTypesEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobHireTypesEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobHireTypesEnum_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "JobHireTypesEnum" */
export enum JobHireTypesEnum_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type JobHireTypesEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobHireTypesEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobHireTypesEnum_Bool_Exp;
};

/** columns and relationships of "JobPositions" */
export type JobPositions = {
  __typename?: 'JobPositions';
  available?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  filled?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  job_id?: Maybe<Scalars['Int']>;
  position_number?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "JobPositions" */
export type JobPositions_Aggregate = {
  __typename?: 'JobPositions_aggregate';
  aggregate?: Maybe<JobPositions_Aggregate_Fields>;
  nodes: Array<JobPositions>;
};

export type JobPositions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<JobPositions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<JobPositions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<JobPositions_Aggregate_Bool_Exp_Count>;
};

export type JobPositions_Aggregate_Bool_Exp_Bool_And = {
  arguments: JobPositions_Select_Column_JobPositions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JobPositions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type JobPositions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: JobPositions_Select_Column_JobPositions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JobPositions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type JobPositions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<JobPositions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JobPositions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "JobPositions" */
export type JobPositions_Aggregate_Fields = {
  __typename?: 'JobPositions_aggregate_fields';
  avg?: Maybe<JobPositions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<JobPositions_Max_Fields>;
  min?: Maybe<JobPositions_Min_Fields>;
  stddev?: Maybe<JobPositions_Stddev_Fields>;
  stddev_pop?: Maybe<JobPositions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<JobPositions_Stddev_Samp_Fields>;
  sum?: Maybe<JobPositions_Sum_Fields>;
  var_pop?: Maybe<JobPositions_Var_Pop_Fields>;
  var_samp?: Maybe<JobPositions_Var_Samp_Fields>;
  variance?: Maybe<JobPositions_Variance_Fields>;
};


/** aggregate fields of "JobPositions" */
export type JobPositions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobPositions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "JobPositions" */
export type JobPositions_Aggregate_Order_By = {
  avg?: InputMaybe<JobPositions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<JobPositions_Max_Order_By>;
  min?: InputMaybe<JobPositions_Min_Order_By>;
  stddev?: InputMaybe<JobPositions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<JobPositions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<JobPositions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<JobPositions_Sum_Order_By>;
  var_pop?: InputMaybe<JobPositions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<JobPositions_Var_Samp_Order_By>;
  variance?: InputMaybe<JobPositions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "JobPositions" */
export type JobPositions_Arr_Rel_Insert_Input = {
  data: Array<JobPositions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<JobPositions_On_Conflict>;
};

/** aggregate avg on columns */
export type JobPositions_Avg_Fields = {
  __typename?: 'JobPositions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  position_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "JobPositions" */
export type JobPositions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "JobPositions". All fields are combined with a logical 'AND'. */
export type JobPositions_Bool_Exp = {
  _and?: InputMaybe<Array<JobPositions_Bool_Exp>>;
  _not?: InputMaybe<JobPositions_Bool_Exp>;
  _or?: InputMaybe<Array<JobPositions_Bool_Exp>>;
  available?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  filled?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  position_number?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  unavailable?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "JobPositions" */
export enum JobPositions_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobPositionsPkey = 'JobPositions_pkey'
}

/** input type for incrementing numeric columns in table "JobPositions" */
export type JobPositions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  position_number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "JobPositions" */
export type JobPositions_Insert_Input = {
  available?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  filled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  position_number?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  unavailable?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type JobPositions_Max_Fields = {
  __typename?: 'JobPositions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
  position_number?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "JobPositions" */
export type JobPositions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type JobPositions_Min_Fields = {
  __typename?: 'JobPositions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
  position_number?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "JobPositions" */
export type JobPositions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "JobPositions" */
export type JobPositions_Mutation_Response = {
  __typename?: 'JobPositions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobPositions>;
};

/** on_conflict condition type for table "JobPositions" */
export type JobPositions_On_Conflict = {
  constraint: JobPositions_Constraint;
  update_columns?: Array<JobPositions_Update_Column>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};

/** Ordering options when selecting data from "JobPositions". */
export type JobPositions_Order_By = {
  available?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  filled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  unavailable?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: JobPositions */
export type JobPositions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "JobPositions" */
export enum JobPositions_Select_Column {
  /** column name */
  Available = 'available',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Filled = 'filled',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  PositionNumber = 'position_number',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Unavailable = 'unavailable',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "JobPositions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "JobPositions" */
export enum JobPositions_Select_Column_JobPositions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Available = 'available',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Filled = 'filled',
  /** column name */
  Unavailable = 'unavailable'
}

/** select "JobPositions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "JobPositions" */
export enum JobPositions_Select_Column_JobPositions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Available = 'available',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Filled = 'filled',
  /** column name */
  Unavailable = 'unavailable'
}

/** input type for updating data in table "JobPositions" */
export type JobPositions_Set_Input = {
  available?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  filled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  position_number?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  unavailable?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type JobPositions_Stddev_Fields = {
  __typename?: 'JobPositions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  position_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "JobPositions" */
export type JobPositions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type JobPositions_Stddev_Pop_Fields = {
  __typename?: 'JobPositions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  position_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "JobPositions" */
export type JobPositions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type JobPositions_Stddev_Samp_Fields = {
  __typename?: 'JobPositions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  position_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "JobPositions" */
export type JobPositions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "JobPositions" */
export type JobPositions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobPositions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobPositions_Stream_Cursor_Value_Input = {
  available?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  filled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  position_number?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  unavailable?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type JobPositions_Sum_Fields = {
  __typename?: 'JobPositions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
  position_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "JobPositions" */
export type JobPositions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** update columns of table "JobPositions" */
export enum JobPositions_Update_Column {
  /** column name */
  Available = 'available',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Filled = 'filled',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  PositionNumber = 'position_number',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Unavailable = 'unavailable',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type JobPositions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<JobPositions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobPositions_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobPositions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type JobPositions_Var_Pop_Fields = {
  __typename?: 'JobPositions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  position_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "JobPositions" */
export type JobPositions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type JobPositions_Var_Samp_Fields = {
  __typename?: 'JobPositions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  position_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "JobPositions" */
export type JobPositions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type JobPositions_Variance_Fields = {
  __typename?: 'JobPositions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  position_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "JobPositions" */
export type JobPositions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  position_number?: InputMaybe<Order_By>;
};

/** columns and relationships of "JobStatuses" */
export type JobStatuses = {
  __typename?: 'JobStatuses';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "JobStatuses" */
export type JobStatuses_Aggregate = {
  __typename?: 'JobStatuses_aggregate';
  aggregate?: Maybe<JobStatuses_Aggregate_Fields>;
  nodes: Array<JobStatuses>;
};

/** aggregate fields of "JobStatuses" */
export type JobStatuses_Aggregate_Fields = {
  __typename?: 'JobStatuses_aggregate_fields';
  avg?: Maybe<JobStatuses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<JobStatuses_Max_Fields>;
  min?: Maybe<JobStatuses_Min_Fields>;
  stddev?: Maybe<JobStatuses_Stddev_Fields>;
  stddev_pop?: Maybe<JobStatuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<JobStatuses_Stddev_Samp_Fields>;
  sum?: Maybe<JobStatuses_Sum_Fields>;
  var_pop?: Maybe<JobStatuses_Var_Pop_Fields>;
  var_samp?: Maybe<JobStatuses_Var_Samp_Fields>;
  variance?: Maybe<JobStatuses_Variance_Fields>;
};


/** aggregate fields of "JobStatuses" */
export type JobStatuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobStatuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type JobStatuses_Avg_Fields = {
  __typename?: 'JobStatuses_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "JobStatuses". All fields are combined with a logical 'AND'. */
export type JobStatuses_Bool_Exp = {
  _and?: InputMaybe<Array<JobStatuses_Bool_Exp>>;
  _not?: InputMaybe<JobStatuses_Bool_Exp>;
  _or?: InputMaybe<Array<JobStatuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "JobStatuses" */
export enum JobStatuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobStatusesPkey = 'JobStatuses_pkey'
}

/** input type for incrementing numeric columns in table "JobStatuses" */
export type JobStatuses_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "JobStatuses" */
export type JobStatuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type JobStatuses_Max_Fields = {
  __typename?: 'JobStatuses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type JobStatuses_Min_Fields = {
  __typename?: 'JobStatuses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "JobStatuses" */
export type JobStatuses_Mutation_Response = {
  __typename?: 'JobStatuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobStatuses>;
};

/** on_conflict condition type for table "JobStatuses" */
export type JobStatuses_On_Conflict = {
  constraint: JobStatuses_Constraint;
  update_columns?: Array<JobStatuses_Update_Column>;
  where?: InputMaybe<JobStatuses_Bool_Exp>;
};

/** Ordering options when selecting data from "JobStatuses". */
export type JobStatuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: JobStatuses */
export type JobStatuses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "JobStatuses" */
export enum JobStatuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "JobStatuses" */
export type JobStatuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type JobStatuses_Stddev_Fields = {
  __typename?: 'JobStatuses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type JobStatuses_Stddev_Pop_Fields = {
  __typename?: 'JobStatuses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type JobStatuses_Stddev_Samp_Fields = {
  __typename?: 'JobStatuses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "JobStatuses" */
export type JobStatuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobStatuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobStatuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type JobStatuses_Sum_Fields = {
  __typename?: 'JobStatuses_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "JobStatuses" */
export enum JobStatuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type JobStatuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<JobStatuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobStatuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobStatuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type JobStatuses_Var_Pop_Fields = {
  __typename?: 'JobStatuses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type JobStatuses_Var_Samp_Fields = {
  __typename?: 'JobStatuses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type JobStatuses_Variance_Fields = {
  __typename?: 'JobStatuses_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Jobs" */
export type Jobs = {
  __typename?: 'Jobs';
  /** An array relationship */
  Applications: Array<Applications>;
  /** An aggregate relationship */
  Applications_aggregate: Applications_Aggregate;
  /** An array relationship */
  AuditStages: Array<AuditStages>;
  /** An aggregate relationship */
  AuditStages_aggregate: AuditStages_Aggregate;
  /** An object relationship */
  BoardSnippetCounts?: Maybe<Jobboardsnippetscounts>;
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Contract?: Maybe<Contracts>;
  /** An object relationship */
  EEOJobCategoriesEnum?: Maybe<EeoJobCategoriesEnum>;
  /** An array relationship */
  Employments: Array<Employments>;
  /** An aggregate relationship */
  Employments_aggregate: Employments_Aggregate;
  /** An array relationship */
  ExternalJobBoardPublishings: Array<ExternalJobBoardPublishings>;
  /** An aggregate relationship */
  ExternalJobBoardPublishings_aggregate: ExternalJobBoardPublishings_Aggregate;
  /** An array relationship */
  GroupJobs: Array<GroupJobs>;
  /** An aggregate relationship */
  GroupJobs_aggregate: GroupJobs_Aggregate;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  /** An array relationship */
  IdentityJobRelationships: Array<IdentityJobRelationship>;
  /** An aggregate relationship */
  IdentityJobRelationships_aggregate: IdentityJobRelationship_Aggregate;
  /** An object relationship */
  JobAvailability?: Maybe<JobAvailabilities>;
  /** An object relationship */
  JobsAvailablePositionsView?: Maybe<Jobs_Available_Positions>;
  /** An array relationship */
  Jobs_JobPositions: Array<JobPositions>;
  /** An aggregate relationship */
  Jobs_JobPositions_aggregate: JobPositions_Aggregate;
  /** An array relationship */
  Matches: Array<Matches_Without_Author_With_Int_Uuid>;
  /** An aggregate relationship */
  Matches_aggregate: Matches_Without_Author_With_Int_Uuid_Aggregate;
  /** An object relationship */
  ProgramLead?: Maybe<Identities>;
  /** An object relationship */
  Questionnaire?: Maybe<Questionnaires>;
  /** An array relationship */
  Submissions: Array<Submissions>;
  /** An aggregate relationship */
  Submissions_aggregate: Submissions_Aggregate;
  /** An object relationship */
  UpdatedBy?: Maybe<Identities>;
  _search?: Maybe<Scalars['tsvector']>;
  account_manager_id?: Maybe<Scalars['Int']>;
  additional_details_publishable?: Maybe<Scalars['String']>;
  application_due_date?: Maybe<Scalars['date']>;
  available_positions: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  company_job_id?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_publishable?: Maybe<Scalars['String']>;
  desired_skills?: Maybe<Scalars['String']>;
  desired_skills_publishable?: Maybe<Scalars['String']>;
  eeo_category?: Maybe<EeoJobCategoriesEnum_Enum>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  filled_by?: Maybe<Scalars['String']>;
  global_search_idx?: Maybe<Scalars['tsvector']>;
  /** A computed field, executes function "has_active_employments" */
  has_active_employments?: Maybe<Scalars['Boolean']>;
  has_workflow?: Maybe<Scalars['Boolean']>;
  hire_type: JobHireTypesEnum_Enum;
  id: Scalars['Int'];
  /** A computed field, executes function "job_identifier" */
  identifier?: Maybe<Scalars['String']>;
  /** An object relationship */
  identityByAmId?: Maybe<Identities>;
  /** An object relationship */
  identityByRmId?: Maybe<Identities>;
  include_questionnaire_on_application: Scalars['Boolean'];
  job_availability_id?: Maybe<Scalars['uuid']>;
  job_funding: JobFundingStatusEnum_Enum;
  job_reference?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_geometry?: Maybe<Scalars['geometry']>;
  location_nickname?: Maybe<Scalars['String']>;
  max_rate?: Maybe<Scalars['String']>;
  max_salary?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  partner_target_rate?: Maybe<Scalars['Int']>;
  partner_target_salary?: Maybe<Scalars['Int']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  published_date?: Maybe<Scalars['date']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  required_skills?: Maybe<Scalars['String']>;
  required_skills_publishable?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  security_clearance?: Maybe<Scalars['String']>;
  security_clearance_publishable?: Maybe<Scalars['String']>;
  /** A computed field, executes function "shared_with_partners" */
  shared_with_partners?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  target_rate?: Maybe<Scalars['String']>;
  target_salary?: Maybe<Scalars['Int']>;
  task_order?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_positions: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Jobs" */
export type JobsApplicationsArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsApplications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsAuditStagesArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsAuditStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsEmploymentsArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsEmployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsExternalJobBoardPublishingsArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsExternalJobBoardPublishings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsGroupJobsArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsGroupJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsIdentityJobRelationshipsArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsIdentityJobRelationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsJobs_JobPositionsArgs = {
  distinct_on?: InputMaybe<Array<JobPositions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobPositions_Order_By>>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsJobs_JobPositions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobPositions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobPositions_Order_By>>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsMatchesArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsMatches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsSubmissionsArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsSubmissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


/** columns and relationships of "Jobs" */
export type JobsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Jobs" */
export type Jobs_Aggregate = {
  __typename?: 'Jobs_aggregate';
  aggregate?: Maybe<Jobs_Aggregate_Fields>;
  nodes: Array<Jobs>;
};

export type Jobs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Jobs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Jobs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Jobs_Aggregate_Bool_Exp_Count>;
};

export type Jobs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Jobs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Jobs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Jobs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Jobs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Jobs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Jobs" */
export type Jobs_Aggregate_Fields = {
  __typename?: 'Jobs_aggregate_fields';
  avg?: Maybe<Jobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Jobs_Max_Fields>;
  min?: Maybe<Jobs_Min_Fields>;
  stddev?: Maybe<Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Jobs_Sum_Fields>;
  var_pop?: Maybe<Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Jobs_Var_Samp_Fields>;
  variance?: Maybe<Jobs_Variance_Fields>;
};


/** aggregate fields of "Jobs" */
export type Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Jobs" */
export type Jobs_Aggregate_Order_By = {
  avg?: InputMaybe<Jobs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Jobs_Max_Order_By>;
  min?: InputMaybe<Jobs_Min_Order_By>;
  stddev?: InputMaybe<Jobs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Jobs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Jobs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Jobs_Sum_Order_By>;
  var_pop?: InputMaybe<Jobs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Jobs_Var_Samp_Order_By>;
  variance?: InputMaybe<Jobs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Jobs_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Jobs" */
export type Jobs_Arr_Rel_Insert_Input = {
  data: Array<Jobs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** aggregate avg on columns */
export type Jobs_Avg_Fields = {
  __typename?: 'Jobs_avg_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  partner_target_rate?: Maybe<Scalars['Float']>;
  partner_target_salary?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Jobs" */
export type Jobs_Avg_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Jobs". All fields are combined with a logical 'AND'. */
export type Jobs_Bool_Exp = {
  Applications?: InputMaybe<Applications_Bool_Exp>;
  Applications_aggregate?: InputMaybe<Applications_Aggregate_Bool_Exp>;
  AuditStages?: InputMaybe<AuditStages_Bool_Exp>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  BoardSnippetCounts?: InputMaybe<Jobboardsnippetscounts_Bool_Exp>;
  BoardSnippets?: InputMaybe<BoardSnippets_Bool_Exp>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  EEOJobCategoriesEnum?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
  Employments?: InputMaybe<Employments_Bool_Exp>;
  Employments_aggregate?: InputMaybe<Employments_Aggregate_Bool_Exp>;
  ExternalJobBoardPublishings?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
  ExternalJobBoardPublishings_aggregate?: InputMaybe<ExternalJobBoardPublishings_Aggregate_Bool_Exp>;
  GroupJobs?: InputMaybe<GroupJobs_Bool_Exp>;
  GroupJobs_aggregate?: InputMaybe<GroupJobs_Aggregate_Bool_Exp>;
  Identities?: InputMaybe<Identities_Bool_Exp>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Bool_Exp>;
  IdentityJobRelationships?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
  IdentityJobRelationships_aggregate?: InputMaybe<IdentityJobRelationship_Aggregate_Bool_Exp>;
  JobAvailability?: InputMaybe<JobAvailabilities_Bool_Exp>;
  JobsAvailablePositionsView?: InputMaybe<Jobs_Available_Positions_Bool_Exp>;
  Jobs_JobPositions?: InputMaybe<JobPositions_Bool_Exp>;
  Jobs_JobPositions_aggregate?: InputMaybe<JobPositions_Aggregate_Bool_Exp>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Bool_Exp>;
  ProgramLead?: InputMaybe<Identities_Bool_Exp>;
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  Submissions?: InputMaybe<Submissions_Bool_Exp>;
  Submissions_aggregate?: InputMaybe<Submissions_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Jobs_Bool_Exp>>;
  _not?: InputMaybe<Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Jobs_Bool_Exp>>;
  _search?: InputMaybe<Tsvector_Comparison_Exp>;
  account_manager_id?: InputMaybe<Int_Comparison_Exp>;
  additional_details_publishable?: InputMaybe<String_Comparison_Exp>;
  application_due_date?: InputMaybe<Date_Comparison_Exp>;
  available_positions?: InputMaybe<Int_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_job_id?: InputMaybe<String_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  description_publishable?: InputMaybe<String_Comparison_Exp>;
  desired_skills?: InputMaybe<String_Comparison_Exp>;
  desired_skills_publishable?: InputMaybe<String_Comparison_Exp>;
  eeo_category?: InputMaybe<EeoJobCategoriesEnum_Enum_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  etl_source_entity?: InputMaybe<String_Comparison_Exp>;
  filled_by?: InputMaybe<String_Comparison_Exp>;
  global_search_idx?: InputMaybe<Tsvector_Comparison_Exp>;
  has_active_employments?: InputMaybe<Boolean_Comparison_Exp>;
  has_workflow?: InputMaybe<Boolean_Comparison_Exp>;
  hire_type?: InputMaybe<JobHireTypesEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  identityByAmId?: InputMaybe<Identities_Bool_Exp>;
  identityByRmId?: InputMaybe<Identities_Bool_Exp>;
  include_questionnaire_on_application?: InputMaybe<Boolean_Comparison_Exp>;
  job_availability_id?: InputMaybe<Uuid_Comparison_Exp>;
  job_funding?: InputMaybe<JobFundingStatusEnum_Enum_Comparison_Exp>;
  job_reference?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  location_geometry?: InputMaybe<Geometry_Comparison_Exp>;
  location_nickname?: InputMaybe<String_Comparison_Exp>;
  max_rate?: InputMaybe<String_Comparison_Exp>;
  max_salary?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  partner_target_rate?: InputMaybe<Int_Comparison_Exp>;
  partner_target_salary?: InputMaybe<Int_Comparison_Exp>;
  position_target_count?: InputMaybe<Bigint_Comparison_Exp>;
  program_lead_id?: InputMaybe<Int_Comparison_Exp>;
  published?: InputMaybe<Boolean_Comparison_Exp>;
  published_date?: InputMaybe<Date_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  required_skills?: InputMaybe<String_Comparison_Exp>;
  required_skills_publishable?: InputMaybe<String_Comparison_Exp>;
  resource_manager_id?: InputMaybe<Int_Comparison_Exp>;
  security_clearance?: InputMaybe<String_Comparison_Exp>;
  security_clearance_publishable?: InputMaybe<String_Comparison_Exp>;
  shared_with_partners?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  status_precedence?: InputMaybe<Int_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  target_rate?: InputMaybe<String_Comparison_Exp>;
  target_salary?: InputMaybe<Int_Comparison_Exp>;
  task_order?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  total_positions?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Jobs" */
export enum Jobs_Constraint {
  /** unique or primary key constraint on columns "company_job_id" */
  JobsCompanyJobIdKey = 'Jobs_company_job_id_key',
  /** unique or primary key constraint on columns "etl_id_token" */
  JobsEtlIdTokenKey = 'Jobs_etl_id_token_key',
  /** unique or primary key constraint on columns "id" */
  JobsPkey = 'Jobs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Jobs_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Jobs_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Jobs_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Jobs" */
export type Jobs_Inc_Input = {
  account_manager_id?: InputMaybe<Scalars['Int']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  partner_target_rate?: InputMaybe<Scalars['Int']>;
  partner_target_salary?: InputMaybe<Scalars['Int']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  total_positions?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Jobs" */
export type Jobs_Insert_Input = {
  Applications?: InputMaybe<Applications_Arr_Rel_Insert_Input>;
  AuditStages?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  BoardSnippetCounts?: InputMaybe<Jobboardsnippetscounts_Obj_Rel_Insert_Input>;
  BoardSnippets?: InputMaybe<BoardSnippets_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  EEOJobCategoriesEnum?: InputMaybe<EeoJobCategoriesEnum_Obj_Rel_Insert_Input>;
  Employments?: InputMaybe<Employments_Arr_Rel_Insert_Input>;
  ExternalJobBoardPublishings?: InputMaybe<ExternalJobBoardPublishings_Arr_Rel_Insert_Input>;
  GroupJobs?: InputMaybe<GroupJobs_Arr_Rel_Insert_Input>;
  Identities?: InputMaybe<Identities_Arr_Rel_Insert_Input>;
  IdentityJobRelationships?: InputMaybe<IdentityJobRelationship_Arr_Rel_Insert_Input>;
  JobAvailability?: InputMaybe<JobAvailabilities_Obj_Rel_Insert_Input>;
  JobsAvailablePositionsView?: InputMaybe<Jobs_Available_Positions_Obj_Rel_Insert_Input>;
  Jobs_JobPositions?: InputMaybe<JobPositions_Arr_Rel_Insert_Input>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Arr_Rel_Insert_Input>;
  ProgramLead?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  Submissions?: InputMaybe<Submissions_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  additional_details_publishable?: InputMaybe<Scalars['String']>;
  application_due_date?: InputMaybe<Scalars['date']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_job_id?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_publishable?: InputMaybe<Scalars['String']>;
  desired_skills?: InputMaybe<Scalars['String']>;
  desired_skills_publishable?: InputMaybe<Scalars['String']>;
  eeo_category?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  filled_by?: InputMaybe<Scalars['String']>;
  has_workflow?: InputMaybe<Scalars['Boolean']>;
  hire_type?: InputMaybe<JobHireTypesEnum_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  identityByAmId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  identityByRmId?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  include_questionnaire_on_application?: InputMaybe<Scalars['Boolean']>;
  job_availability_id?: InputMaybe<Scalars['uuid']>;
  job_funding?: InputMaybe<JobFundingStatusEnum_Enum>;
  job_reference?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  location_nickname?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['String']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  partner_target_rate?: InputMaybe<Scalars['Int']>;
  partner_target_salary?: InputMaybe<Scalars['Int']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_date?: InputMaybe<Scalars['date']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  required_skills?: InputMaybe<Scalars['String']>;
  required_skills_publishable?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  security_clearance?: InputMaybe<Scalars['String']>;
  security_clearance_publishable?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  target_rate?: InputMaybe<Scalars['String']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  task_order?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_positions?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Jobs_Max_Fields = {
  __typename?: 'Jobs_max_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  additional_details_publishable?: Maybe<Scalars['String']>;
  application_due_date?: Maybe<Scalars['date']>;
  available_positions?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  company_job_id?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_publishable?: Maybe<Scalars['String']>;
  desired_skills?: Maybe<Scalars['String']>;
  desired_skills_publishable?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  filled_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "job_identifier" */
  identifier?: Maybe<Scalars['String']>;
  job_availability_id?: Maybe<Scalars['uuid']>;
  job_reference?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_nickname?: Maybe<Scalars['String']>;
  max_rate?: Maybe<Scalars['String']>;
  max_salary?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  partner_target_rate?: Maybe<Scalars['Int']>;
  partner_target_salary?: Maybe<Scalars['Int']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  published_date?: Maybe<Scalars['date']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  required_skills?: Maybe<Scalars['String']>;
  required_skills_publishable?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  security_clearance?: Maybe<Scalars['String']>;
  security_clearance_publishable?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  target_rate?: Maybe<Scalars['String']>;
  target_salary?: Maybe<Scalars['Int']>;
  task_order?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Jobs" */
export type Jobs_Max_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  additional_details_publishable?: InputMaybe<Order_By>;
  application_due_date?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_job_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  description_publishable?: InputMaybe<Order_By>;
  desired_skills?: InputMaybe<Order_By>;
  desired_skills_publishable?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  filled_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_availability_id?: InputMaybe<Order_By>;
  job_reference?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  location_nickname?: InputMaybe<Order_By>;
  max_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  published_date?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  required_skills?: InputMaybe<Order_By>;
  required_skills_publishable?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  security_clearance?: InputMaybe<Order_By>;
  security_clearance_publishable?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  target_rate?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  task_order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Jobs_Min_Fields = {
  __typename?: 'Jobs_min_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  additional_details_publishable?: Maybe<Scalars['String']>;
  application_due_date?: Maybe<Scalars['date']>;
  available_positions?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  company_job_id?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_publishable?: Maybe<Scalars['String']>;
  desired_skills?: Maybe<Scalars['String']>;
  desired_skills_publishable?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  filled_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "job_identifier" */
  identifier?: Maybe<Scalars['String']>;
  job_availability_id?: Maybe<Scalars['uuid']>;
  job_reference?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_nickname?: Maybe<Scalars['String']>;
  max_rate?: Maybe<Scalars['String']>;
  max_salary?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  partner_target_rate?: Maybe<Scalars['Int']>;
  partner_target_salary?: Maybe<Scalars['Int']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  published_date?: Maybe<Scalars['date']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  required_skills?: Maybe<Scalars['String']>;
  required_skills_publishable?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  security_clearance?: Maybe<Scalars['String']>;
  security_clearance_publishable?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  target_rate?: Maybe<Scalars['String']>;
  target_salary?: Maybe<Scalars['Int']>;
  task_order?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Jobs" */
export type Jobs_Min_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  additional_details_publishable?: InputMaybe<Order_By>;
  application_due_date?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_job_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  description_publishable?: InputMaybe<Order_By>;
  desired_skills?: InputMaybe<Order_By>;
  desired_skills_publishable?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  filled_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_availability_id?: InputMaybe<Order_By>;
  job_reference?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  location_nickname?: InputMaybe<Order_By>;
  max_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  published_date?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  required_skills?: InputMaybe<Order_By>;
  required_skills_publishable?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  security_clearance?: InputMaybe<Order_By>;
  security_clearance_publishable?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  target_rate?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  task_order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Jobs" */
export type Jobs_Mutation_Response = {
  __typename?: 'Jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Jobs>;
};

/** input type for inserting object relation for remote table "Jobs" */
export type Jobs_Obj_Rel_Insert_Input = {
  data: Jobs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** on_conflict condition type for table "Jobs" */
export type Jobs_On_Conflict = {
  constraint: Jobs_Constraint;
  update_columns?: Array<Jobs_Update_Column>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "Jobs". */
export type Jobs_Order_By = {
  Applications_aggregate?: InputMaybe<Applications_Aggregate_Order_By>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  BoardSnippetCounts?: InputMaybe<Jobboardsnippetscounts_Order_By>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  Contract?: InputMaybe<Contracts_Order_By>;
  EEOJobCategoriesEnum?: InputMaybe<EeoJobCategoriesEnum_Order_By>;
  Employments_aggregate?: InputMaybe<Employments_Aggregate_Order_By>;
  ExternalJobBoardPublishings_aggregate?: InputMaybe<ExternalJobBoardPublishings_Aggregate_Order_By>;
  GroupJobs_aggregate?: InputMaybe<GroupJobs_Aggregate_Order_By>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Order_By>;
  IdentityJobRelationships_aggregate?: InputMaybe<IdentityJobRelationship_Aggregate_Order_By>;
  JobAvailability?: InputMaybe<JobAvailabilities_Order_By>;
  JobsAvailablePositionsView?: InputMaybe<Jobs_Available_Positions_Order_By>;
  Jobs_JobPositions_aggregate?: InputMaybe<JobPositions_Aggregate_Order_By>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Order_By>;
  ProgramLead?: InputMaybe<Identities_Order_By>;
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  Submissions_aggregate?: InputMaybe<Submissions_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  _search?: InputMaybe<Order_By>;
  account_manager_id?: InputMaybe<Order_By>;
  additional_details_publishable?: InputMaybe<Order_By>;
  application_due_date?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_job_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  description_publishable?: InputMaybe<Order_By>;
  desired_skills?: InputMaybe<Order_By>;
  desired_skills_publishable?: InputMaybe<Order_By>;
  eeo_category?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  filled_by?: InputMaybe<Order_By>;
  global_search_idx?: InputMaybe<Order_By>;
  has_active_employments?: InputMaybe<Order_By>;
  has_workflow?: InputMaybe<Order_By>;
  hire_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  identityByAmId?: InputMaybe<Identities_Order_By>;
  identityByRmId?: InputMaybe<Identities_Order_By>;
  include_questionnaire_on_application?: InputMaybe<Order_By>;
  job_availability_id?: InputMaybe<Order_By>;
  job_funding?: InputMaybe<Order_By>;
  job_reference?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  location_geometry?: InputMaybe<Order_By>;
  location_nickname?: InputMaybe<Order_By>;
  max_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  published?: InputMaybe<Order_By>;
  published_date?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  required_skills?: InputMaybe<Order_By>;
  required_skills_publishable?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  security_clearance?: InputMaybe<Order_By>;
  security_clearance_publishable?: InputMaybe<Order_By>;
  shared_with_partners?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_precedence?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  target_rate?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  task_order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Jobs */
export type Jobs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Jobs_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Jobs" */
export enum Jobs_Select_Column {
  /** column name */
  Search = '_search',
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  AdditionalDetailsPublishable = 'additional_details_publishable',
  /** column name */
  ApplicationDueDate = 'application_due_date',
  /** column name */
  AvailablePositions = 'available_positions',
  /** column name */
  Category = 'category',
  /** column name */
  City = 'city',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyJobId = 'company_job_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionPublishable = 'description_publishable',
  /** column name */
  DesiredSkills = 'desired_skills',
  /** column name */
  DesiredSkillsPublishable = 'desired_skills_publishable',
  /** column name */
  EeoCategory = 'eeo_category',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  FilledBy = 'filled_by',
  /** column name */
  GlobalSearchIdx = 'global_search_idx',
  /** column name */
  HasWorkflow = 'has_workflow',
  /** column name */
  HireType = 'hire_type',
  /** column name */
  Id = 'id',
  /** column name */
  IncludeQuestionnaireOnApplication = 'include_questionnaire_on_application',
  /** column name */
  JobAvailabilityId = 'job_availability_id',
  /** column name */
  JobFunding = 'job_funding',
  /** column name */
  JobReference = 'job_reference',
  /** column name */
  Location = 'location',
  /** column name */
  LocationGeometry = 'location_geometry',
  /** column name */
  LocationNickname = 'location_nickname',
  /** column name */
  MaxRate = 'max_rate',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  Order = 'order',
  /** column name */
  PartnerTargetRate = 'partner_target_rate',
  /** column name */
  PartnerTargetSalary = 'partner_target_salary',
  /** column name */
  PositionTargetCount = 'position_target_count',
  /** column name */
  ProgramLeadId = 'program_lead_id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  RequiredSkills = 'required_skills',
  /** column name */
  RequiredSkillsPublishable = 'required_skills_publishable',
  /** column name */
  ResourceManagerId = 'resource_manager_id',
  /** column name */
  SecurityClearance = 'security_clearance',
  /** column name */
  SecurityClearancePublishable = 'security_clearance_publishable',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Street = 'street',
  /** column name */
  TargetRate = 'target_rate',
  /** column name */
  TargetSalary = 'target_salary',
  /** column name */
  TaskOrder = 'task_order',
  /** column name */
  Title = 'title',
  /** column name */
  TotalPositions = 'total_positions',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Zip = 'zip'
}

/** select "Jobs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Jobs" */
export enum Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted',
  /** column name */
  HasWorkflow = 'has_workflow',
  /** column name */
  IncludeQuestionnaireOnApplication = 'include_questionnaire_on_application',
  /** column name */
  Published = 'published'
}

/** select "Jobs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Jobs" */
export enum Jobs_Select_Column_Jobs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted',
  /** column name */
  HasWorkflow = 'has_workflow',
  /** column name */
  IncludeQuestionnaireOnApplication = 'include_questionnaire_on_application',
  /** column name */
  Published = 'published'
}

/** input type for updating data in table "Jobs" */
export type Jobs_Set_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  additional_details_publishable?: InputMaybe<Scalars['String']>;
  application_due_date?: InputMaybe<Scalars['date']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_job_id?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_publishable?: InputMaybe<Scalars['String']>;
  desired_skills?: InputMaybe<Scalars['String']>;
  desired_skills_publishable?: InputMaybe<Scalars['String']>;
  eeo_category?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  filled_by?: InputMaybe<Scalars['String']>;
  has_workflow?: InputMaybe<Scalars['Boolean']>;
  hire_type?: InputMaybe<JobHireTypesEnum_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  include_questionnaire_on_application?: InputMaybe<Scalars['Boolean']>;
  job_availability_id?: InputMaybe<Scalars['uuid']>;
  job_funding?: InputMaybe<JobFundingStatusEnum_Enum>;
  job_reference?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  location_nickname?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['String']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  partner_target_rate?: InputMaybe<Scalars['Int']>;
  partner_target_salary?: InputMaybe<Scalars['Int']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_date?: InputMaybe<Scalars['date']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  required_skills?: InputMaybe<Scalars['String']>;
  required_skills_publishable?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  security_clearance?: InputMaybe<Scalars['String']>;
  security_clearance_publishable?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  target_rate?: InputMaybe<Scalars['String']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  task_order?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_positions?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Jobs_Stddev_Fields = {
  __typename?: 'Jobs_stddev_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  partner_target_rate?: Maybe<Scalars['Float']>;
  partner_target_salary?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Jobs" */
export type Jobs_Stddev_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Jobs_Stddev_Pop_Fields = {
  __typename?: 'Jobs_stddev_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  partner_target_rate?: Maybe<Scalars['Float']>;
  partner_target_salary?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Jobs" */
export type Jobs_Stddev_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Jobs_Stddev_Samp_Fields = {
  __typename?: 'Jobs_stddev_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  partner_target_rate?: Maybe<Scalars['Float']>;
  partner_target_salary?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Jobs" */
export type Jobs_Stddev_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Jobs" */
export type Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jobs_Stream_Cursor_Value_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  additional_details_publishable?: InputMaybe<Scalars['String']>;
  application_due_date?: InputMaybe<Scalars['date']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_job_id?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_publishable?: InputMaybe<Scalars['String']>;
  desired_skills?: InputMaybe<Scalars['String']>;
  desired_skills_publishable?: InputMaybe<Scalars['String']>;
  eeo_category?: InputMaybe<EeoJobCategoriesEnum_Enum>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  filled_by?: InputMaybe<Scalars['String']>;
  global_search_idx?: InputMaybe<Scalars['tsvector']>;
  has_workflow?: InputMaybe<Scalars['Boolean']>;
  hire_type?: InputMaybe<JobHireTypesEnum_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  include_questionnaire_on_application?: InputMaybe<Scalars['Boolean']>;
  job_availability_id?: InputMaybe<Scalars['uuid']>;
  job_funding?: InputMaybe<JobFundingStatusEnum_Enum>;
  job_reference?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  location_nickname?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['String']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  partner_target_rate?: InputMaybe<Scalars['Int']>;
  partner_target_salary?: InputMaybe<Scalars['Int']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_date?: InputMaybe<Scalars['date']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  required_skills?: InputMaybe<Scalars['String']>;
  required_skills_publishable?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  security_clearance?: InputMaybe<Scalars['String']>;
  security_clearance_publishable?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  target_rate?: InputMaybe<Scalars['String']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  task_order?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_positions?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Jobs_Sum_Fields = {
  __typename?: 'Jobs_sum_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  available_positions?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  max_salary?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  partner_target_rate?: Maybe<Scalars['Int']>;
  partner_target_salary?: Maybe<Scalars['Int']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Int']>;
  total_positions?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Jobs" */
export type Jobs_Sum_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Jobs" */
export enum Jobs_Update_Column {
  /** column name */
  Search = '_search',
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  AdditionalDetailsPublishable = 'additional_details_publishable',
  /** column name */
  ApplicationDueDate = 'application_due_date',
  /** column name */
  AvailablePositions = 'available_positions',
  /** column name */
  Category = 'category',
  /** column name */
  City = 'city',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyJobId = 'company_job_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionPublishable = 'description_publishable',
  /** column name */
  DesiredSkills = 'desired_skills',
  /** column name */
  DesiredSkillsPublishable = 'desired_skills_publishable',
  /** column name */
  EeoCategory = 'eeo_category',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  FilledBy = 'filled_by',
  /** column name */
  HasWorkflow = 'has_workflow',
  /** column name */
  HireType = 'hire_type',
  /** column name */
  Id = 'id',
  /** column name */
  IncludeQuestionnaireOnApplication = 'include_questionnaire_on_application',
  /** column name */
  JobAvailabilityId = 'job_availability_id',
  /** column name */
  JobFunding = 'job_funding',
  /** column name */
  JobReference = 'job_reference',
  /** column name */
  Location = 'location',
  /** column name */
  LocationGeometry = 'location_geometry',
  /** column name */
  LocationNickname = 'location_nickname',
  /** column name */
  MaxRate = 'max_rate',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  Order = 'order',
  /** column name */
  PartnerTargetRate = 'partner_target_rate',
  /** column name */
  PartnerTargetSalary = 'partner_target_salary',
  /** column name */
  PositionTargetCount = 'position_target_count',
  /** column name */
  ProgramLeadId = 'program_lead_id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  RequiredSkills = 'required_skills',
  /** column name */
  RequiredSkillsPublishable = 'required_skills_publishable',
  /** column name */
  ResourceManagerId = 'resource_manager_id',
  /** column name */
  SecurityClearance = 'security_clearance',
  /** column name */
  SecurityClearancePublishable = 'security_clearance_publishable',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Street = 'street',
  /** column name */
  TargetRate = 'target_rate',
  /** column name */
  TargetSalary = 'target_salary',
  /** column name */
  TaskOrder = 'task_order',
  /** column name */
  Title = 'title',
  /** column name */
  TotalPositions = 'total_positions',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Zip = 'zip'
}

export type Jobs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Jobs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Jobs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Jobs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Jobs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Jobs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Jobs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Jobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Jobs_Var_Pop_Fields = {
  __typename?: 'Jobs_var_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  partner_target_rate?: Maybe<Scalars['Float']>;
  partner_target_salary?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Jobs" */
export type Jobs_Var_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Jobs_Var_Samp_Fields = {
  __typename?: 'Jobs_var_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  partner_target_rate?: Maybe<Scalars['Float']>;
  partner_target_salary?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Jobs" */
export type Jobs_Var_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Jobs_Variance_Fields = {
  __typename?: 'Jobs_variance_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  partner_target_rate?: Maybe<Scalars['Float']>;
  partner_target_salary?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "job_status_precedence" */
  status_precedence?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Jobs" */
export type Jobs_Variance_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  partner_target_rate?: InputMaybe<Order_By>;
  partner_target_salary?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "MagicLinks" */
export type MagicLinks = {
  __typename?: 'MagicLinks';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  destination_route_template?: Maybe<Scalars['String']>;
  expires_at: Scalars['timestamp'];
  hash: Scalars['String'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "MagicLinks" */
export type MagicLinks_Aggregate = {
  __typename?: 'MagicLinks_aggregate';
  aggregate?: Maybe<MagicLinks_Aggregate_Fields>;
  nodes: Array<MagicLinks>;
};

/** aggregate fields of "MagicLinks" */
export type MagicLinks_Aggregate_Fields = {
  __typename?: 'MagicLinks_aggregate_fields';
  avg?: Maybe<MagicLinks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MagicLinks_Max_Fields>;
  min?: Maybe<MagicLinks_Min_Fields>;
  stddev?: Maybe<MagicLinks_Stddev_Fields>;
  stddev_pop?: Maybe<MagicLinks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MagicLinks_Stddev_Samp_Fields>;
  sum?: Maybe<MagicLinks_Sum_Fields>;
  var_pop?: Maybe<MagicLinks_Var_Pop_Fields>;
  var_samp?: Maybe<MagicLinks_Var_Samp_Fields>;
  variance?: Maybe<MagicLinks_Variance_Fields>;
};


/** aggregate fields of "MagicLinks" */
export type MagicLinks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MagicLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MagicLinks_Avg_Fields = {
  __typename?: 'MagicLinks_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MagicLinks". All fields are combined with a logical 'AND'. */
export type MagicLinks_Bool_Exp = {
  _and?: InputMaybe<Array<MagicLinks_Bool_Exp>>;
  _not?: InputMaybe<MagicLinks_Bool_Exp>;
  _or?: InputMaybe<Array<MagicLinks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  destination_route_template?: InputMaybe<String_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "MagicLinks" */
export enum MagicLinks_Constraint {
  /** unique or primary key constraint on columns "hash" */
  MagicLinksHashKey = 'MagicLinks_hash_key',
  /** unique or primary key constraint on columns "id" */
  MagicLinksPkey = 'MagicLinks_pkey'
}

/** input type for incrementing numeric columns in table "MagicLinks" */
export type MagicLinks_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "MagicLinks" */
export type MagicLinks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  destination_route_template?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MagicLinks_Max_Fields = {
  __typename?: 'MagicLinks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  destination_route_template?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamp']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MagicLinks_Min_Fields = {
  __typename?: 'MagicLinks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  destination_route_template?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamp']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MagicLinks" */
export type MagicLinks_Mutation_Response = {
  __typename?: 'MagicLinks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MagicLinks>;
};

/** on_conflict condition type for table "MagicLinks" */
export type MagicLinks_On_Conflict = {
  constraint: MagicLinks_Constraint;
  update_columns?: Array<MagicLinks_Update_Column>;
  where?: InputMaybe<MagicLinks_Bool_Exp>;
};

/** Ordering options when selecting data from "MagicLinks". */
export type MagicLinks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  destination_route_template?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: MagicLinks */
export type MagicLinks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "MagicLinks" */
export enum MagicLinks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestinationRouteTemplate = 'destination_route_template',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "MagicLinks" */
export type MagicLinks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  destination_route_template?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MagicLinks_Stddev_Fields = {
  __typename?: 'MagicLinks_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MagicLinks_Stddev_Pop_Fields = {
  __typename?: 'MagicLinks_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MagicLinks_Stddev_Samp_Fields = {
  __typename?: 'MagicLinks_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "MagicLinks" */
export type MagicLinks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MagicLinks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MagicLinks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  destination_route_template?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type MagicLinks_Sum_Fields = {
  __typename?: 'MagicLinks_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "MagicLinks" */
export enum MagicLinks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestinationRouteTemplate = 'destination_route_template',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type MagicLinks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MagicLinks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MagicLinks_Set_Input>;
  /** filter the rows which have to be updated */
  where: MagicLinks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MagicLinks_Var_Pop_Fields = {
  __typename?: 'MagicLinks_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MagicLinks_Var_Samp_Fields = {
  __typename?: 'MagicLinks_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MagicLinks_Variance_Fields = {
  __typename?: 'MagicLinks_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

export type ManageEmailIntegrationInput = {
  action: Scalars['String'];
  email: Scalars['String'];
};

export type ManageEmailIntegrationOutput = {
  __typename?: 'ManageEmailIntegrationOutput';
  message: Scalars['String'];
};

export type MergeLinkTokenInput = {
  /** The specific integration to display in Merge's Link component. */
  integration?: InputMaybe<Scalars['String']>;
  tokenType: Scalars['String'];
};

export type MergeLinkTokenOutput = {
  __typename?: 'MergeLinkTokenOutput';
  linkToken: Scalars['String'];
};

export type MergeResult = {
  __typename?: 'MergeResult';
  merged_identity: Array<Identities>;
  merged_identity_aggregate: Identities_Aggregate;
  merged_identity_id: Scalars['Int'];
};


export type MergeResultMerged_IdentityArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type MergeResultMerged_Identity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};

/** columns and relationships of "MigrationData" */
export type MigrationData = {
  __typename?: 'MigrationData';
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  sha256?: Maybe<Scalars['String']>;
  source_entity_name: Scalars['String'];
  source_identifier: Scalars['String'];
  source_last_modified?: Maybe<Scalars['timestamptz']>;
  source_object: Scalars['jsonb'];
  source_system: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "MigrationData" */
export type MigrationDataSource_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "MigrationData" */
export type MigrationData_Aggregate = {
  __typename?: 'MigrationData_aggregate';
  aggregate?: Maybe<MigrationData_Aggregate_Fields>;
  nodes: Array<MigrationData>;
};

/** aggregate fields of "MigrationData" */
export type MigrationData_Aggregate_Fields = {
  __typename?: 'MigrationData_aggregate_fields';
  avg?: Maybe<MigrationData_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MigrationData_Max_Fields>;
  min?: Maybe<MigrationData_Min_Fields>;
  stddev?: Maybe<MigrationData_Stddev_Fields>;
  stddev_pop?: Maybe<MigrationData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MigrationData_Stddev_Samp_Fields>;
  sum?: Maybe<MigrationData_Sum_Fields>;
  var_pop?: Maybe<MigrationData_Var_Pop_Fields>;
  var_samp?: Maybe<MigrationData_Var_Samp_Fields>;
  variance?: Maybe<MigrationData_Variance_Fields>;
};


/** aggregate fields of "MigrationData" */
export type MigrationData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MigrationData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MigrationData_Append_Input = {
  source_object?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type MigrationData_Avg_Fields = {
  __typename?: 'MigrationData_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MigrationData". All fields are combined with a logical 'AND'. */
export type MigrationData_Bool_Exp = {
  _and?: InputMaybe<Array<MigrationData_Bool_Exp>>;
  _not?: InputMaybe<MigrationData_Bool_Exp>;
  _or?: InputMaybe<Array<MigrationData_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sha256?: InputMaybe<String_Comparison_Exp>;
  source_entity_name?: InputMaybe<String_Comparison_Exp>;
  source_identifier?: InputMaybe<String_Comparison_Exp>;
  source_last_modified?: InputMaybe<Timestamptz_Comparison_Exp>;
  source_object?: InputMaybe<Jsonb_Comparison_Exp>;
  source_system?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "MigrationData" */
export enum MigrationData_Constraint {
  /** unique or primary key constraint on columns "id" */
  MigrationDataPkey = 'MigrationData_pkey',
  /** unique or primary key constraint on columns "source_system", "source_identifier", "company_id", "source_entity_name" */
  MigrationDataSourceSystemCompanyIdSourceIdentifierSoKey = 'MigrationData_source_system_company_id_source_identifier_so_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MigrationData_Delete_At_Path_Input = {
  source_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MigrationData_Delete_Elem_Input = {
  source_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MigrationData_Delete_Key_Input = {
  source_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "MigrationData" */
export type MigrationData_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "MigrationData" */
export type MigrationData_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sha256?: InputMaybe<Scalars['String']>;
  source_entity_name?: InputMaybe<Scalars['String']>;
  source_identifier?: InputMaybe<Scalars['String']>;
  source_last_modified?: InputMaybe<Scalars['timestamptz']>;
  source_object?: InputMaybe<Scalars['jsonb']>;
  source_system?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MigrationData_Max_Fields = {
  __typename?: 'MigrationData_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sha256?: Maybe<Scalars['String']>;
  source_entity_name?: Maybe<Scalars['String']>;
  source_identifier?: Maybe<Scalars['String']>;
  source_last_modified?: Maybe<Scalars['timestamptz']>;
  source_system?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type MigrationData_Min_Fields = {
  __typename?: 'MigrationData_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sha256?: Maybe<Scalars['String']>;
  source_entity_name?: Maybe<Scalars['String']>;
  source_identifier?: Maybe<Scalars['String']>;
  source_last_modified?: Maybe<Scalars['timestamptz']>;
  source_system?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "MigrationData" */
export type MigrationData_Mutation_Response = {
  __typename?: 'MigrationData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MigrationData>;
};

/** on_conflict condition type for table "MigrationData" */
export type MigrationData_On_Conflict = {
  constraint: MigrationData_Constraint;
  update_columns?: Array<MigrationData_Update_Column>;
  where?: InputMaybe<MigrationData_Bool_Exp>;
};

/** Ordering options when selecting data from "MigrationData". */
export type MigrationData_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sha256?: InputMaybe<Order_By>;
  source_entity_name?: InputMaybe<Order_By>;
  source_identifier?: InputMaybe<Order_By>;
  source_last_modified?: InputMaybe<Order_By>;
  source_object?: InputMaybe<Order_By>;
  source_system?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: MigrationData */
export type MigrationData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MigrationData_Prepend_Input = {
  source_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "MigrationData" */
export enum MigrationData_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Sha256 = 'sha256',
  /** column name */
  SourceEntityName = 'source_entity_name',
  /** column name */
  SourceIdentifier = 'source_identifier',
  /** column name */
  SourceLastModified = 'source_last_modified',
  /** column name */
  SourceObject = 'source_object',
  /** column name */
  SourceSystem = 'source_system',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "MigrationData" */
export type MigrationData_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sha256?: InputMaybe<Scalars['String']>;
  source_entity_name?: InputMaybe<Scalars['String']>;
  source_identifier?: InputMaybe<Scalars['String']>;
  source_last_modified?: InputMaybe<Scalars['timestamptz']>;
  source_object?: InputMaybe<Scalars['jsonb']>;
  source_system?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MigrationData_Stddev_Fields = {
  __typename?: 'MigrationData_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MigrationData_Stddev_Pop_Fields = {
  __typename?: 'MigrationData_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MigrationData_Stddev_Samp_Fields = {
  __typename?: 'MigrationData_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "MigrationData" */
export type MigrationData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MigrationData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MigrationData_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sha256?: InputMaybe<Scalars['String']>;
  source_entity_name?: InputMaybe<Scalars['String']>;
  source_identifier?: InputMaybe<Scalars['String']>;
  source_last_modified?: InputMaybe<Scalars['timestamptz']>;
  source_object?: InputMaybe<Scalars['jsonb']>;
  source_system?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type MigrationData_Sum_Fields = {
  __typename?: 'MigrationData_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "MigrationData" */
export enum MigrationData_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Sha256 = 'sha256',
  /** column name */
  SourceEntityName = 'source_entity_name',
  /** column name */
  SourceIdentifier = 'source_identifier',
  /** column name */
  SourceLastModified = 'source_last_modified',
  /** column name */
  SourceObject = 'source_object',
  /** column name */
  SourceSystem = 'source_system',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type MigrationData_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MigrationData_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<MigrationData_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<MigrationData_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<MigrationData_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MigrationData_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MigrationData_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MigrationData_Set_Input>;
  /** filter the rows which have to be updated */
  where: MigrationData_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MigrationData_Var_Pop_Fields = {
  __typename?: 'MigrationData_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MigrationData_Var_Samp_Fields = {
  __typename?: 'MigrationData_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MigrationData_Variance_Fields = {
  __typename?: 'MigrationData_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ObjectZoo" */
export type ObjectZoo = {
  __typename?: 'ObjectZoo';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  data: Scalars['jsonb'];
  description: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  mime_type: Scalars['String'];
  tags: Scalars['jsonb'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "ObjectZoo" */
export type ObjectZooDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "ObjectZoo" */
export type ObjectZooMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "ObjectZoo" */
export type ObjectZooTagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ObjectZoo" */
export type ObjectZoo_Aggregate = {
  __typename?: 'ObjectZoo_aggregate';
  aggregate?: Maybe<ObjectZoo_Aggregate_Fields>;
  nodes: Array<ObjectZoo>;
};

/** aggregate fields of "ObjectZoo" */
export type ObjectZoo_Aggregate_Fields = {
  __typename?: 'ObjectZoo_aggregate_fields';
  avg?: Maybe<ObjectZoo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ObjectZoo_Max_Fields>;
  min?: Maybe<ObjectZoo_Min_Fields>;
  stddev?: Maybe<ObjectZoo_Stddev_Fields>;
  stddev_pop?: Maybe<ObjectZoo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ObjectZoo_Stddev_Samp_Fields>;
  sum?: Maybe<ObjectZoo_Sum_Fields>;
  var_pop?: Maybe<ObjectZoo_Var_Pop_Fields>;
  var_samp?: Maybe<ObjectZoo_Var_Samp_Fields>;
  variance?: Maybe<ObjectZoo_Variance_Fields>;
};


/** aggregate fields of "ObjectZoo" */
export type ObjectZoo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ObjectZoo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ObjectZoo_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ObjectZoo_Avg_Fields = {
  __typename?: 'ObjectZoo_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ObjectZoo". All fields are combined with a logical 'AND'. */
export type ObjectZoo_Bool_Exp = {
  _and?: InputMaybe<Array<ObjectZoo_Bool_Exp>>;
  _not?: InputMaybe<ObjectZoo_Bool_Exp>;
  _or?: InputMaybe<Array<ObjectZoo_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mime_type?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ObjectZoo" */
export enum ObjectZoo_Constraint {
  /** unique or primary key constraint on columns "id" */
  GqlGalleryPkey = 'GQLGallery_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ObjectZoo_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ObjectZoo_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ObjectZoo_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ObjectZoo" */
export type ObjectZoo_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ObjectZoo" */
export type ObjectZoo_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['jsonb']>;
  description?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mime_type?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ObjectZoo_Max_Fields = {
  __typename?: 'ObjectZoo_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mime_type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ObjectZoo_Min_Fields = {
  __typename?: 'ObjectZoo_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mime_type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ObjectZoo" */
export type ObjectZoo_Mutation_Response = {
  __typename?: 'ObjectZoo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ObjectZoo>;
};

/** on_conflict condition type for table "ObjectZoo" */
export type ObjectZoo_On_Conflict = {
  constraint: ObjectZoo_Constraint;
  update_columns?: Array<ObjectZoo_Update_Column>;
  where?: InputMaybe<ObjectZoo_Bool_Exp>;
};

/** Ordering options when selecting data from "ObjectZoo". */
export type ObjectZoo_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ObjectZoo */
export type ObjectZoo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ObjectZoo_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ObjectZoo" */
export enum ObjectZoo_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  Description = 'description',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ObjectZoo" */
export type ObjectZoo_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['jsonb']>;
  description?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mime_type?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ObjectZoo_Stddev_Fields = {
  __typename?: 'ObjectZoo_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ObjectZoo_Stddev_Pop_Fields = {
  __typename?: 'ObjectZoo_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ObjectZoo_Stddev_Samp_Fields = {
  __typename?: 'ObjectZoo_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ObjectZoo" */
export type ObjectZoo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ObjectZoo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ObjectZoo_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['jsonb']>;
  description?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mime_type?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ObjectZoo_Sum_Fields = {
  __typename?: 'ObjectZoo_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "ObjectZoo" */
export enum ObjectZoo_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  Description = 'description',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ObjectZoo_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ObjectZoo_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ObjectZoo_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ObjectZoo_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ObjectZoo_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ObjectZoo_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ObjectZoo_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObjectZoo_Set_Input>;
  /** filter the rows which have to be updated */
  where: ObjectZoo_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ObjectZoo_Var_Pop_Fields = {
  __typename?: 'ObjectZoo_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ObjectZoo_Var_Samp_Fields = {
  __typename?: 'ObjectZoo_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ObjectZoo_Variance_Fields = {
  __typename?: 'ObjectZoo_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Overlay" */
export type Overlay = {
  __typename?: 'Overlay';
  /** An array relationship */
  Fields: Array<Field>;
  /** An aggregate relationship */
  Fields_aggregate: Field_Aggregate;
  /** An array relationship */
  IdentitiesOverlaysLinks: Array<IdentitiesOverlaysLinks>;
  /** An aggregate relationship */
  IdentitiesOverlaysLinks_aggregate: IdentitiesOverlaysLinks_Aggregate;
  /** An array relationship */
  SubmissionsOverlaysLinks: Array<SubmissionsOverlaysLinks>;
  /** An aggregate relationship */
  SubmissionsOverlaysLinks_aggregate: SubmissionsOverlaysLinks_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Overlay" */
export type OverlayFieldsArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


/** columns and relationships of "Overlay" */
export type OverlayFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


/** columns and relationships of "Overlay" */
export type OverlayIdentitiesOverlaysLinksArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


/** columns and relationships of "Overlay" */
export type OverlayIdentitiesOverlaysLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


/** columns and relationships of "Overlay" */
export type OverlaySubmissionsOverlaysLinksArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


/** columns and relationships of "Overlay" */
export type OverlaySubmissionsOverlaysLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};

/** Overlay Definition to support Additional Fields  */
export type OverlayDefinition = {
  __typename?: 'OverlayDefinition';
  /** An array relationship */
  FieldDefs: Array<FieldDef>;
  /** An aggregate relationship */
  FieldDefs_aggregate: FieldDef_Aggregate;
  /** An array relationship */
  OverlayDefinitions: Array<OverlayDefinition>;
  /** An aggregate relationship */
  OverlayDefinitions_aggregate: OverlayDefinition_Aggregate;
  /** An object relationship */
  Parent?: Maybe<OverlayDefinition>;
  created_at: Scalars['timestamptz'];
  destination_path?: Maybe<Scalars['String']>;
  form_def?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  interaction_spec?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  object: Scalars['String'];
  parent?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  validation_grammar?: Maybe<Scalars['jsonb']>;
};


/** Overlay Definition to support Additional Fields  */
export type OverlayDefinitionFieldDefsArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


/** Overlay Definition to support Additional Fields  */
export type OverlayDefinitionFieldDefs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


/** Overlay Definition to support Additional Fields  */
export type OverlayDefinitionOverlayDefinitionsArgs = {
  distinct_on?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OverlayDefinition_Order_By>>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};


/** Overlay Definition to support Additional Fields  */
export type OverlayDefinitionOverlayDefinitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OverlayDefinition_Order_By>>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};


/** Overlay Definition to support Additional Fields  */
export type OverlayDefinitionForm_DefArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Overlay Definition to support Additional Fields  */
export type OverlayDefinitionInteraction_SpecArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Overlay Definition to support Additional Fields  */
export type OverlayDefinitionValidation_GrammarArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "OverlayDefinition" */
export type OverlayDefinition_Aggregate = {
  __typename?: 'OverlayDefinition_aggregate';
  aggregate?: Maybe<OverlayDefinition_Aggregate_Fields>;
  nodes: Array<OverlayDefinition>;
};

export type OverlayDefinition_Aggregate_Bool_Exp = {
  count?: InputMaybe<OverlayDefinition_Aggregate_Bool_Exp_Count>;
};

export type OverlayDefinition_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OverlayDefinition_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "OverlayDefinition" */
export type OverlayDefinition_Aggregate_Fields = {
  __typename?: 'OverlayDefinition_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OverlayDefinition_Max_Fields>;
  min?: Maybe<OverlayDefinition_Min_Fields>;
};


/** aggregate fields of "OverlayDefinition" */
export type OverlayDefinition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "OverlayDefinition" */
export type OverlayDefinition_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<OverlayDefinition_Max_Order_By>;
  min?: InputMaybe<OverlayDefinition_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OverlayDefinition_Append_Input = {
  form_def?: InputMaybe<Scalars['jsonb']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  validation_grammar?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "OverlayDefinition" */
export type OverlayDefinition_Arr_Rel_Insert_Input = {
  data: Array<OverlayDefinition_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<OverlayDefinition_On_Conflict>;
};

/** Boolean expression to filter rows from the table "OverlayDefinition". All fields are combined with a logical 'AND'. */
export type OverlayDefinition_Bool_Exp = {
  FieldDefs?: InputMaybe<FieldDef_Bool_Exp>;
  FieldDefs_aggregate?: InputMaybe<FieldDef_Aggregate_Bool_Exp>;
  OverlayDefinitions?: InputMaybe<OverlayDefinition_Bool_Exp>;
  OverlayDefinitions_aggregate?: InputMaybe<OverlayDefinition_Aggregate_Bool_Exp>;
  Parent?: InputMaybe<OverlayDefinition_Bool_Exp>;
  _and?: InputMaybe<Array<OverlayDefinition_Bool_Exp>>;
  _not?: InputMaybe<OverlayDefinition_Bool_Exp>;
  _or?: InputMaybe<Array<OverlayDefinition_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  destination_path?: InputMaybe<String_Comparison_Exp>;
  form_def?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction_spec?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  object?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Uuid_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  validation_grammar?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "OverlayDefinition" */
export enum OverlayDefinition_Constraint {
  /** unique or primary key constraint on columns "object", "name" */
  OverlayDefinitionNameObjectKey = 'OverlayDefinition_name_object_key',
  /** unique or primary key constraint on columns "id" */
  OverlayDefinitionPkey = 'OverlayDefinition_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OverlayDefinition_Delete_At_Path_Input = {
  form_def?: InputMaybe<Array<Scalars['String']>>;
  interaction_spec?: InputMaybe<Array<Scalars['String']>>;
  validation_grammar?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OverlayDefinition_Delete_Elem_Input = {
  form_def?: InputMaybe<Scalars['Int']>;
  interaction_spec?: InputMaybe<Scalars['Int']>;
  validation_grammar?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OverlayDefinition_Delete_Key_Input = {
  form_def?: InputMaybe<Scalars['String']>;
  interaction_spec?: InputMaybe<Scalars['String']>;
  validation_grammar?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "OverlayDefinition" */
export type OverlayDefinition_Insert_Input = {
  FieldDefs?: InputMaybe<FieldDef_Arr_Rel_Insert_Input>;
  OverlayDefinitions?: InputMaybe<OverlayDefinition_Arr_Rel_Insert_Input>;
  Parent?: InputMaybe<OverlayDefinition_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destination_path?: InputMaybe<Scalars['String']>;
  form_def?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  object?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validation_grammar?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type OverlayDefinition_Max_Fields = {
  __typename?: 'OverlayDefinition_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  destination_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "OverlayDefinition" */
export type OverlayDefinition_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  destination_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  object?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type OverlayDefinition_Min_Fields = {
  __typename?: 'OverlayDefinition_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  destination_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "OverlayDefinition" */
export type OverlayDefinition_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  destination_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  object?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "OverlayDefinition" */
export type OverlayDefinition_Mutation_Response = {
  __typename?: 'OverlayDefinition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OverlayDefinition>;
};

/** input type for inserting object relation for remote table "OverlayDefinition" */
export type OverlayDefinition_Obj_Rel_Insert_Input = {
  data: OverlayDefinition_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<OverlayDefinition_On_Conflict>;
};

/** on_conflict condition type for table "OverlayDefinition" */
export type OverlayDefinition_On_Conflict = {
  constraint: OverlayDefinition_Constraint;
  update_columns?: Array<OverlayDefinition_Update_Column>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};

/** Ordering options when selecting data from "OverlayDefinition". */
export type OverlayDefinition_Order_By = {
  FieldDefs_aggregate?: InputMaybe<FieldDef_Aggregate_Order_By>;
  OverlayDefinitions_aggregate?: InputMaybe<OverlayDefinition_Aggregate_Order_By>;
  Parent?: InputMaybe<OverlayDefinition_Order_By>;
  created_at?: InputMaybe<Order_By>;
  destination_path?: InputMaybe<Order_By>;
  form_def?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_spec?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  object?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validation_grammar?: InputMaybe<Order_By>;
};

/** primary key columns input for table: OverlayDefinition */
export type OverlayDefinition_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OverlayDefinition_Prepend_Input = {
  form_def?: InputMaybe<Scalars['jsonb']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  validation_grammar?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "OverlayDefinition" */
export enum OverlayDefinition_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationPath = 'destination_path',
  /** column name */
  FormDef = 'form_def',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionSpec = 'interaction_spec',
  /** column name */
  Name = 'name',
  /** column name */
  Object = 'object',
  /** column name */
  Parent = 'parent',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidationGrammar = 'validation_grammar'
}

/** input type for updating data in table "OverlayDefinition" */
export type OverlayDefinition_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destination_path?: InputMaybe<Scalars['String']>;
  form_def?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  object?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validation_grammar?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "OverlayDefinition" */
export type OverlayDefinition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OverlayDefinition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OverlayDefinition_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destination_path?: InputMaybe<Scalars['String']>;
  form_def?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  object?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  validation_grammar?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "OverlayDefinition" */
export enum OverlayDefinition_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationPath = 'destination_path',
  /** column name */
  FormDef = 'form_def',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionSpec = 'interaction_spec',
  /** column name */
  Name = 'name',
  /** column name */
  Object = 'object',
  /** column name */
  Parent = 'parent',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidationGrammar = 'validation_grammar'
}

export type OverlayDefinition_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<OverlayDefinition_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<OverlayDefinition_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<OverlayDefinition_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<OverlayDefinition_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<OverlayDefinition_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OverlayDefinition_Set_Input>;
  /** filter the rows which have to be updated */
  where: OverlayDefinition_Bool_Exp;
};

/** aggregated selection of "Overlay" */
export type Overlay_Aggregate = {
  __typename?: 'Overlay_aggregate';
  aggregate?: Maybe<Overlay_Aggregate_Fields>;
  nodes: Array<Overlay>;
};

/** aggregate fields of "Overlay" */
export type Overlay_Aggregate_Fields = {
  __typename?: 'Overlay_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Overlay_Max_Fields>;
  min?: Maybe<Overlay_Min_Fields>;
};


/** aggregate fields of "Overlay" */
export type Overlay_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Overlay_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Overlay". All fields are combined with a logical 'AND'. */
export type Overlay_Bool_Exp = {
  Fields?: InputMaybe<Field_Bool_Exp>;
  Fields_aggregate?: InputMaybe<Field_Aggregate_Bool_Exp>;
  IdentitiesOverlaysLinks?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
  IdentitiesOverlaysLinks_aggregate?: InputMaybe<IdentitiesOverlaysLinks_Aggregate_Bool_Exp>;
  SubmissionsOverlaysLinks?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
  SubmissionsOverlaysLinks_aggregate?: InputMaybe<SubmissionsOverlaysLinks_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Overlay_Bool_Exp>>;
  _not?: InputMaybe<Overlay_Bool_Exp>;
  _or?: InputMaybe<Array<Overlay_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Overlay" */
export enum Overlay_Constraint {
  /** unique or primary key constraint on columns "id" */
  OverlayPkey = 'Overlay_pkey'
}

/** input type for inserting data into table "Overlay" */
export type Overlay_Insert_Input = {
  Fields?: InputMaybe<Field_Arr_Rel_Insert_Input>;
  IdentitiesOverlaysLinks?: InputMaybe<IdentitiesOverlaysLinks_Arr_Rel_Insert_Input>;
  SubmissionsOverlaysLinks?: InputMaybe<SubmissionsOverlaysLinks_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Overlay_Max_Fields = {
  __typename?: 'Overlay_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Overlay_Min_Fields = {
  __typename?: 'Overlay_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Overlay" */
export type Overlay_Mutation_Response = {
  __typename?: 'Overlay_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Overlay>;
};

/** input type for inserting object relation for remote table "Overlay" */
export type Overlay_Obj_Rel_Insert_Input = {
  data: Overlay_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Overlay_On_Conflict>;
};

/** on_conflict condition type for table "Overlay" */
export type Overlay_On_Conflict = {
  constraint: Overlay_Constraint;
  update_columns?: Array<Overlay_Update_Column>;
  where?: InputMaybe<Overlay_Bool_Exp>;
};

/** Ordering options when selecting data from "Overlay". */
export type Overlay_Order_By = {
  Fields_aggregate?: InputMaybe<Field_Aggregate_Order_By>;
  IdentitiesOverlaysLinks_aggregate?: InputMaybe<IdentitiesOverlaysLinks_Aggregate_Order_By>;
  SubmissionsOverlaysLinks_aggregate?: InputMaybe<SubmissionsOverlaysLinks_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Overlay */
export type Overlay_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Overlay" */
export enum Overlay_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Overlay" */
export type Overlay_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Overlay" */
export type Overlay_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Overlay_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Overlay_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Overlay" */
export enum Overlay_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Overlay_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Overlay_Set_Input>;
  /** filter the rows which have to be updated */
  where: Overlay_Bool_Exp;
};

export type ParticipantsInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

/** columns and relationships of "PhoneNumbers" */
export type PhoneNumbers = {
  __typename?: 'PhoneNumbers';
  created_at: Scalars['timestamptz'];
  e164_form: Scalars['String'];
  metadata: Scalars['jsonb'];
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "PhoneNumbers" */
export type PhoneNumbersMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "PhoneNumbers" */
export type PhoneNumbers_Aggregate = {
  __typename?: 'PhoneNumbers_aggregate';
  aggregate?: Maybe<PhoneNumbers_Aggregate_Fields>;
  nodes: Array<PhoneNumbers>;
};

/** aggregate fields of "PhoneNumbers" */
export type PhoneNumbers_Aggregate_Fields = {
  __typename?: 'PhoneNumbers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PhoneNumbers_Max_Fields>;
  min?: Maybe<PhoneNumbers_Min_Fields>;
};


/** aggregate fields of "PhoneNumbers" */
export type PhoneNumbers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PhoneNumbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PhoneNumbers_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "PhoneNumbers". All fields are combined with a logical 'AND'. */
export type PhoneNumbers_Bool_Exp = {
  _and?: InputMaybe<Array<PhoneNumbers_Bool_Exp>>;
  _not?: InputMaybe<PhoneNumbers_Bool_Exp>;
  _or?: InputMaybe<Array<PhoneNumbers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  e164_form?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PhoneNumbers" */
export enum PhoneNumbers_Constraint {
  /** unique or primary key constraint on columns "e164_form" */
  PhoneNumbersPkey = 'PhoneNumbers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PhoneNumbers_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PhoneNumbers_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PhoneNumbers_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "PhoneNumbers" */
export type PhoneNumbers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  e164_form?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PhoneNumbers_Max_Fields = {
  __typename?: 'PhoneNumbers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  e164_form?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PhoneNumbers_Min_Fields = {
  __typename?: 'PhoneNumbers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  e164_form?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "PhoneNumbers" */
export type PhoneNumbers_Mutation_Response = {
  __typename?: 'PhoneNumbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PhoneNumbers>;
};

/** on_conflict condition type for table "PhoneNumbers" */
export type PhoneNumbers_On_Conflict = {
  constraint: PhoneNumbers_Constraint;
  update_columns?: Array<PhoneNumbers_Update_Column>;
  where?: InputMaybe<PhoneNumbers_Bool_Exp>;
};

/** Ordering options when selecting data from "PhoneNumbers". */
export type PhoneNumbers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  e164_form?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PhoneNumbers */
export type PhoneNumbers_Pk_Columns_Input = {
  e164_form: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PhoneNumbers_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "PhoneNumbers" */
export enum PhoneNumbers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  E164Form = 'e164_form',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "PhoneNumbers" */
export type PhoneNumbers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  e164_form?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "PhoneNumbers" */
export type PhoneNumbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PhoneNumbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PhoneNumbers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  e164_form?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** update columns of table "PhoneNumbers" */
export enum PhoneNumbers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  E164Form = 'e164_form',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type PhoneNumbers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PhoneNumbers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<PhoneNumbers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<PhoneNumbers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<PhoneNumbers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PhoneNumbers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PhoneNumbers_Set_Input>;
  /** filter the rows which have to be updated */
  where: PhoneNumbers_Bool_Exp;
};

/** User defined options which show up in the dropdown */
export type PicklistValues = {
  __typename?: 'PicklistValues';
  /** An array relationship */
  FieldPicklistValues: Array<FieldPicklistValues>;
  /** An aggregate relationship */
  FieldPicklistValues_aggregate: FieldPicklistValues_Aggregate;
  /** An object relationship */
  Picklist: Picklists;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  picklist_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};


/** User defined options which show up in the dropdown */
export type PicklistValuesFieldPicklistValuesArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};


/** User defined options which show up in the dropdown */
export type PicklistValuesFieldPicklistValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};

/** aggregated selection of "PicklistValues" */
export type PicklistValues_Aggregate = {
  __typename?: 'PicklistValues_aggregate';
  aggregate?: Maybe<PicklistValues_Aggregate_Fields>;
  nodes: Array<PicklistValues>;
};

export type PicklistValues_Aggregate_Bool_Exp = {
  count?: InputMaybe<PicklistValues_Aggregate_Bool_Exp_Count>;
};

export type PicklistValues_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PicklistValues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PicklistValues_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PicklistValues" */
export type PicklistValues_Aggregate_Fields = {
  __typename?: 'PicklistValues_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PicklistValues_Max_Fields>;
  min?: Maybe<PicklistValues_Min_Fields>;
};


/** aggregate fields of "PicklistValues" */
export type PicklistValues_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PicklistValues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PicklistValues" */
export type PicklistValues_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PicklistValues_Max_Order_By>;
  min?: InputMaybe<PicklistValues_Min_Order_By>;
};

/** input type for inserting array relation for remote table "PicklistValues" */
export type PicklistValues_Arr_Rel_Insert_Input = {
  data: Array<PicklistValues_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PicklistValues_On_Conflict>;
};

/** Boolean expression to filter rows from the table "PicklistValues". All fields are combined with a logical 'AND'. */
export type PicklistValues_Bool_Exp = {
  FieldPicklistValues?: InputMaybe<FieldPicklistValues_Bool_Exp>;
  FieldPicklistValues_aggregate?: InputMaybe<FieldPicklistValues_Aggregate_Bool_Exp>;
  Picklist?: InputMaybe<Picklists_Bool_Exp>;
  _and?: InputMaybe<Array<PicklistValues_Bool_Exp>>;
  _not?: InputMaybe<PicklistValues_Bool_Exp>;
  _or?: InputMaybe<Array<PicklistValues_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  picklist_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PicklistValues" */
export enum PicklistValues_Constraint {
  /** unique or primary key constraint on columns "id" */
  PicklistValuesPkey = 'PicklistValues_pkey'
}

/** input type for inserting data into table "PicklistValues" */
export type PicklistValues_Insert_Input = {
  FieldPicklistValues?: InputMaybe<FieldPicklistValues_Arr_Rel_Insert_Input>;
  Picklist?: InputMaybe<Picklists_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  picklist_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PicklistValues_Max_Fields = {
  __typename?: 'PicklistValues_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  picklist_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "PicklistValues" */
export type PicklistValues_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  picklist_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PicklistValues_Min_Fields = {
  __typename?: 'PicklistValues_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  picklist_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "PicklistValues" */
export type PicklistValues_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  picklist_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PicklistValues" */
export type PicklistValues_Mutation_Response = {
  __typename?: 'PicklistValues_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PicklistValues>;
};

/** input type for inserting object relation for remote table "PicklistValues" */
export type PicklistValues_Obj_Rel_Insert_Input = {
  data: PicklistValues_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PicklistValues_On_Conflict>;
};

/** on_conflict condition type for table "PicklistValues" */
export type PicklistValues_On_Conflict = {
  constraint: PicklistValues_Constraint;
  update_columns?: Array<PicklistValues_Update_Column>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};

/** Ordering options when selecting data from "PicklistValues". */
export type PicklistValues_Order_By = {
  FieldPicklistValues_aggregate?: InputMaybe<FieldPicklistValues_Aggregate_Order_By>;
  Picklist?: InputMaybe<Picklists_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  picklist_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PicklistValues */
export type PicklistValues_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PicklistValues" */
export enum PicklistValues_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PicklistId = 'picklist_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "PicklistValues" */
export type PicklistValues_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  picklist_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "PicklistValues" */
export type PicklistValues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PicklistValues_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PicklistValues_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  picklist_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "PicklistValues" */
export enum PicklistValues_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PicklistId = 'picklist_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type PicklistValues_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PicklistValues_Set_Input>;
  /** filter the rows which have to be updated */
  where: PicklistValues_Bool_Exp;
};

/** User defined custom dropdowns */
export type Picklists = {
  __typename?: 'Picklists';
  /** An array relationship */
  PicklistValues: Array<PicklistValues>;
  /** An aggregate relationship */
  PicklistValues_aggregate: PicklistValues_Aggregate;
  counts_gql?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_gql?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  interaction_spec?: Maybe<Scalars['jsonb']>;
  mutation_gql?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** User defined custom dropdowns */
export type PicklistsPicklistValuesArgs = {
  distinct_on?: InputMaybe<Array<PicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PicklistValues_Order_By>>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};


/** User defined custom dropdowns */
export type PicklistsPicklistValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PicklistValues_Order_By>>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};


/** User defined custom dropdowns */
export type PicklistsInteraction_SpecArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Picklists" */
export type Picklists_Aggregate = {
  __typename?: 'Picklists_aggregate';
  aggregate?: Maybe<Picklists_Aggregate_Fields>;
  nodes: Array<Picklists>;
};

/** aggregate fields of "Picklists" */
export type Picklists_Aggregate_Fields = {
  __typename?: 'Picklists_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Picklists_Max_Fields>;
  min?: Maybe<Picklists_Min_Fields>;
};


/** aggregate fields of "Picklists" */
export type Picklists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Picklists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Picklists_Append_Input = {
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "Picklists". All fields are combined with a logical 'AND'. */
export type Picklists_Bool_Exp = {
  PicklistValues?: InputMaybe<PicklistValues_Bool_Exp>;
  PicklistValues_aggregate?: InputMaybe<PicklistValues_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Picklists_Bool_Exp>>;
  _not?: InputMaybe<Picklists_Bool_Exp>;
  _or?: InputMaybe<Array<Picklists_Bool_Exp>>;
  counts_gql?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_gql?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interaction_spec?: InputMaybe<Jsonb_Comparison_Exp>;
  mutation_gql?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Picklists" */
export enum Picklists_Constraint {
  /** unique or primary key constraint on columns "id" */
  PicklistsPkey = 'Picklists_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Picklists_Delete_At_Path_Input = {
  interaction_spec?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Picklists_Delete_Elem_Input = {
  interaction_spec?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Picklists_Delete_Key_Input = {
  interaction_spec?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "Picklists" */
export type Picklists_Insert_Input = {
  PicklistValues?: InputMaybe<PicklistValues_Arr_Rel_Insert_Input>;
  counts_gql?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_gql?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  mutation_gql?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Picklists_Max_Fields = {
  __typename?: 'Picklists_max_fields';
  counts_gql?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_gql?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mutation_gql?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Picklists_Min_Fields = {
  __typename?: 'Picklists_min_fields';
  counts_gql?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data_gql?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mutation_gql?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Picklists" */
export type Picklists_Mutation_Response = {
  __typename?: 'Picklists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Picklists>;
};

/** input type for inserting object relation for remote table "Picklists" */
export type Picklists_Obj_Rel_Insert_Input = {
  data: Picklists_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Picklists_On_Conflict>;
};

/** on_conflict condition type for table "Picklists" */
export type Picklists_On_Conflict = {
  constraint: Picklists_Constraint;
  update_columns?: Array<Picklists_Update_Column>;
  where?: InputMaybe<Picklists_Bool_Exp>;
};

/** Ordering options when selecting data from "Picklists". */
export type Picklists_Order_By = {
  PicklistValues_aggregate?: InputMaybe<PicklistValues_Aggregate_Order_By>;
  counts_gql?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_gql?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_spec?: InputMaybe<Order_By>;
  mutation_gql?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Picklists */
export type Picklists_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Picklists_Prepend_Input = {
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Picklists" */
export enum Picklists_Select_Column {
  /** column name */
  CountsGql = 'counts_gql',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataGql = 'data_gql',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionSpec = 'interaction_spec',
  /** column name */
  MutationGql = 'mutation_gql',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Picklists" */
export type Picklists_Set_Input = {
  counts_gql?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_gql?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  mutation_gql?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Picklists" */
export type Picklists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Picklists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Picklists_Stream_Cursor_Value_Input = {
  counts_gql?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_gql?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  interaction_spec?: InputMaybe<Scalars['jsonb']>;
  mutation_gql?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Picklists" */
export enum Picklists_Update_Column {
  /** column name */
  CountsGql = 'counts_gql',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataGql = 'data_gql',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionSpec = 'interaction_spec',
  /** column name */
  MutationGql = 'mutation_gql',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Picklists_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Picklists_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Picklists_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Picklists_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Picklists_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Picklists_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Picklists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Picklists_Bool_Exp;
};

/** columns and relationships of "ProductUpdates" */
export type ProductUpdates = {
  __typename?: 'ProductUpdates';
  commit_hash?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  notes: Scalars['String'];
  overview: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  version_number: Scalars['String'];
};

/** aggregated selection of "ProductUpdates" */
export type ProductUpdates_Aggregate = {
  __typename?: 'ProductUpdates_aggregate';
  aggregate?: Maybe<ProductUpdates_Aggregate_Fields>;
  nodes: Array<ProductUpdates>;
};

/** aggregate fields of "ProductUpdates" */
export type ProductUpdates_Aggregate_Fields = {
  __typename?: 'ProductUpdates_aggregate_fields';
  avg?: Maybe<ProductUpdates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductUpdates_Max_Fields>;
  min?: Maybe<ProductUpdates_Min_Fields>;
  stddev?: Maybe<ProductUpdates_Stddev_Fields>;
  stddev_pop?: Maybe<ProductUpdates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductUpdates_Stddev_Samp_Fields>;
  sum?: Maybe<ProductUpdates_Sum_Fields>;
  var_pop?: Maybe<ProductUpdates_Var_Pop_Fields>;
  var_samp?: Maybe<ProductUpdates_Var_Samp_Fields>;
  variance?: Maybe<ProductUpdates_Variance_Fields>;
};


/** aggregate fields of "ProductUpdates" */
export type ProductUpdates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductUpdates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProductUpdates_Avg_Fields = {
  __typename?: 'ProductUpdates_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ProductUpdates". All fields are combined with a logical 'AND'. */
export type ProductUpdates_Bool_Exp = {
  _and?: InputMaybe<Array<ProductUpdates_Bool_Exp>>;
  _not?: InputMaybe<ProductUpdates_Bool_Exp>;
  _or?: InputMaybe<Array<ProductUpdates_Bool_Exp>>;
  commit_hash?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  overview?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProductUpdates" */
export enum ProductUpdates_Constraint {
  /** unique or primary key constraint on columns "commit_hash" */
  ProductUpdatesCommitHashKey = 'ProductUpdates_commit_hash_key',
  /** unique or primary key constraint on columns "id" */
  ProductUpdatesPkey = 'ProductUpdates_pkey',
  /** unique or primary key constraint on columns "version_number" */
  ProductUpdatesVersionNumberKey = 'ProductUpdates_version_number_key'
}

/** input type for incrementing numeric columns in table "ProductUpdates" */
export type ProductUpdates_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ProductUpdates" */
export type ProductUpdates_Insert_Input = {
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProductUpdates_Max_Fields = {
  __typename?: 'ProductUpdates_max_fields';
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProductUpdates_Min_Fields = {
  __typename?: 'ProductUpdates_min_fields';
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ProductUpdates" */
export type ProductUpdates_Mutation_Response = {
  __typename?: 'ProductUpdates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductUpdates>;
};

/** on_conflict condition type for table "ProductUpdates" */
export type ProductUpdates_On_Conflict = {
  constraint: ProductUpdates_Constraint;
  update_columns?: Array<ProductUpdates_Update_Column>;
  where?: InputMaybe<ProductUpdates_Bool_Exp>;
};

/** Ordering options when selecting data from "ProductUpdates". */
export type ProductUpdates_Order_By = {
  commit_hash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  overview?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ProductUpdates */
export type ProductUpdates_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ProductUpdates" */
export enum ProductUpdates_Select_Column {
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Overview = 'overview',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionNumber = 'version_number'
}

/** input type for updating data in table "ProductUpdates" */
export type ProductUpdates_Set_Input = {
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version_number?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProductUpdates_Stddev_Fields = {
  __typename?: 'ProductUpdates_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProductUpdates_Stddev_Pop_Fields = {
  __typename?: 'ProductUpdates_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProductUpdates_Stddev_Samp_Fields = {
  __typename?: 'ProductUpdates_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ProductUpdates" */
export type ProductUpdates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductUpdates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductUpdates_Stream_Cursor_Value_Input = {
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version_number?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProductUpdates_Sum_Fields = {
  __typename?: 'ProductUpdates_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ProductUpdates" */
export enum ProductUpdates_Update_Column {
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Overview = 'overview',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionNumber = 'version_number'
}

export type ProductUpdates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductUpdates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductUpdates_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductUpdates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProductUpdates_Var_Pop_Fields = {
  __typename?: 'ProductUpdates_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProductUpdates_Var_Samp_Fields = {
  __typename?: 'ProductUpdates_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProductUpdates_Variance_Fields = {
  __typename?: 'ProductUpdates_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables = {
  __typename?: 'QuestionnaireContactTemplateVariables';
  /** An object relationship */
  ContactTemplateVariable: ContactTemplateVariablesEnum;
  /** An object relationship */
  Questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid'];
  template_variable: ContactTemplateVariablesEnum_Enum;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Aggregate = {
  __typename?: 'QuestionnaireContactTemplateVariables_aggregate';
  aggregate?: Maybe<QuestionnaireContactTemplateVariables_Aggregate_Fields>;
  nodes: Array<QuestionnaireContactTemplateVariables>;
};

export type QuestionnaireContactTemplateVariables_Aggregate_Bool_Exp = {
  count?: InputMaybe<QuestionnaireContactTemplateVariables_Aggregate_Bool_Exp_Count>;
};

export type QuestionnaireContactTemplateVariables_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Aggregate_Fields = {
  __typename?: 'QuestionnaireContactTemplateVariables_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<QuestionnaireContactTemplateVariables_Max_Fields>;
  min?: Maybe<QuestionnaireContactTemplateVariables_Min_Fields>;
};


/** aggregate fields of "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QuestionnaireContactTemplateVariables_Max_Order_By>;
  min?: InputMaybe<QuestionnaireContactTemplateVariables_Min_Order_By>;
};

/** input type for inserting array relation for remote table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Arr_Rel_Insert_Input = {
  data: Array<QuestionnaireContactTemplateVariables_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireContactTemplateVariables_On_Conflict>;
};

/** Boolean expression to filter rows from the table "QuestionnaireContactTemplateVariables". All fields are combined with a logical 'AND'. */
export type QuestionnaireContactTemplateVariables_Bool_Exp = {
  ContactTemplateVariable?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  _and?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Bool_Exp>>;
  _not?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
  _or?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Bool_Exp>>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "QuestionnaireContactTemplateVariables" */
export enum QuestionnaireContactTemplateVariables_Constraint {
  /** unique or primary key constraint on columns "template_variable", "questionnaire_id" */
  QuestionnaireContactTemplateVariablesPkey = 'QuestionnaireContactTemplateVariables_pkey'
}

/** input type for inserting data into table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Insert_Input = {
  ContactTemplateVariable?: InputMaybe<ContactTemplateVariablesEnum_Obj_Rel_Insert_Input>;
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type QuestionnaireContactTemplateVariables_Max_Fields = {
  __typename?: 'QuestionnaireContactTemplateVariables_max_fields';
  questionnaire_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Max_Order_By = {
  questionnaire_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QuestionnaireContactTemplateVariables_Min_Fields = {
  __typename?: 'QuestionnaireContactTemplateVariables_min_fields';
  questionnaire_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Min_Order_By = {
  questionnaire_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Mutation_Response = {
  __typename?: 'QuestionnaireContactTemplateVariables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireContactTemplateVariables>;
};

/** on_conflict condition type for table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_On_Conflict = {
  constraint: QuestionnaireContactTemplateVariables_Constraint;
  update_columns?: Array<QuestionnaireContactTemplateVariables_Update_Column>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};

/** Ordering options when selecting data from "QuestionnaireContactTemplateVariables". */
export type QuestionnaireContactTemplateVariables_Order_By = {
  ContactTemplateVariable?: InputMaybe<ContactTemplateVariablesEnum_Order_By>;
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  template_variable?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: QuestionnaireContactTemplateVariables */
export type QuestionnaireContactTemplateVariables_Pk_Columns_Input = {
  questionnaire_id: Scalars['uuid'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};

/** select columns of table "QuestionnaireContactTemplateVariables" */
export enum QuestionnaireContactTemplateVariables_Select_Column {
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TemplateVariable = 'template_variable',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Set_Input = {
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "QuestionnaireContactTemplateVariables" */
export type QuestionnaireContactTemplateVariables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireContactTemplateVariables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireContactTemplateVariables_Stream_Cursor_Value_Input = {
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** update columns of table "QuestionnaireContactTemplateVariables" */
export enum QuestionnaireContactTemplateVariables_Update_Column {
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TemplateVariable = 'template_variable',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type QuestionnaireContactTemplateVariables_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireContactTemplateVariables_Set_Input>;
  /** filter the rows which have to be updated */
  where: QuestionnaireContactTemplateVariables_Bool_Exp;
};

/** columns and relationships of "QuestionnaireLinks" */
export type QuestionnaireLinks = {
  __typename?: 'QuestionnaireLinks';
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Questionnaire: Questionnaires;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  questionnaire_id: Scalars['uuid'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "QuestionnaireLinks" */
export type QuestionnaireLinks_Aggregate = {
  __typename?: 'QuestionnaireLinks_aggregate';
  aggregate?: Maybe<QuestionnaireLinks_Aggregate_Fields>;
  nodes: Array<QuestionnaireLinks>;
};

export type QuestionnaireLinks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<QuestionnaireLinks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<QuestionnaireLinks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<QuestionnaireLinks_Aggregate_Bool_Exp_Count>;
};

export type QuestionnaireLinks_Aggregate_Bool_Exp_Bool_And = {
  arguments: QuestionnaireLinks_Select_Column_QuestionnaireLinks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type QuestionnaireLinks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: QuestionnaireLinks_Select_Column_QuestionnaireLinks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type QuestionnaireLinks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "QuestionnaireLinks" */
export type QuestionnaireLinks_Aggregate_Fields = {
  __typename?: 'QuestionnaireLinks_aggregate_fields';
  avg?: Maybe<QuestionnaireLinks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<QuestionnaireLinks_Max_Fields>;
  min?: Maybe<QuestionnaireLinks_Min_Fields>;
  stddev?: Maybe<QuestionnaireLinks_Stddev_Fields>;
  stddev_pop?: Maybe<QuestionnaireLinks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QuestionnaireLinks_Stddev_Samp_Fields>;
  sum?: Maybe<QuestionnaireLinks_Sum_Fields>;
  var_pop?: Maybe<QuestionnaireLinks_Var_Pop_Fields>;
  var_samp?: Maybe<QuestionnaireLinks_Var_Samp_Fields>;
  variance?: Maybe<QuestionnaireLinks_Variance_Fields>;
};


/** aggregate fields of "QuestionnaireLinks" */
export type QuestionnaireLinks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Aggregate_Order_By = {
  avg?: InputMaybe<QuestionnaireLinks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QuestionnaireLinks_Max_Order_By>;
  min?: InputMaybe<QuestionnaireLinks_Min_Order_By>;
  stddev?: InputMaybe<QuestionnaireLinks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<QuestionnaireLinks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<QuestionnaireLinks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<QuestionnaireLinks_Sum_Order_By>;
  var_pop?: InputMaybe<QuestionnaireLinks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<QuestionnaireLinks_Var_Samp_Order_By>;
  variance?: InputMaybe<QuestionnaireLinks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "QuestionnaireLinks" */
export type QuestionnaireLinks_Arr_Rel_Insert_Input = {
  data: Array<QuestionnaireLinks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireLinks_On_Conflict>;
};

/** aggregate avg on columns */
export type QuestionnaireLinks_Avg_Fields = {
  __typename?: 'QuestionnaireLinks_avg_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Avg_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "QuestionnaireLinks". All fields are combined with a logical 'AND'. */
export type QuestionnaireLinks_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  _and?: InputMaybe<Array<QuestionnaireLinks_Bool_Exp>>;
  _not?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
  _or?: InputMaybe<Array<QuestionnaireLinks_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "QuestionnaireLinks" */
export enum QuestionnaireLinks_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireLinksPkey = 'QuestionnaireLinks_pkey'
}

/** input type for incrementing numeric columns in table "QuestionnaireLinks" */
export type QuestionnaireLinks_Inc_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "QuestionnaireLinks" */
export type QuestionnaireLinks_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type QuestionnaireLinks_Max_Fields = {
  __typename?: 'QuestionnaireLinks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QuestionnaireLinks_Min_Fields = {
  __typename?: 'QuestionnaireLinks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "QuestionnaireLinks" */
export type QuestionnaireLinks_Mutation_Response = {
  __typename?: 'QuestionnaireLinks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireLinks>;
};

/** on_conflict condition type for table "QuestionnaireLinks" */
export type QuestionnaireLinks_On_Conflict = {
  constraint: QuestionnaireLinks_Constraint;
  update_columns?: Array<QuestionnaireLinks_Update_Column>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};

/** Ordering options when selecting data from "QuestionnaireLinks". */
export type QuestionnaireLinks_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: QuestionnaireLinks */
export type QuestionnaireLinks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "QuestionnaireLinks" */
export enum QuestionnaireLinks_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** select "QuestionnaireLinks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "QuestionnaireLinks" */
export enum QuestionnaireLinks_Select_Column_QuestionnaireLinks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "QuestionnaireLinks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "QuestionnaireLinks" */
export enum QuestionnaireLinks_Select_Column_QuestionnaireLinks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "QuestionnaireLinks" */
export type QuestionnaireLinks_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type QuestionnaireLinks_Stddev_Fields = {
  __typename?: 'QuestionnaireLinks_stddev_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Stddev_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type QuestionnaireLinks_Stddev_Pop_Fields = {
  __typename?: 'QuestionnaireLinks_stddev_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Stddev_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type QuestionnaireLinks_Stddev_Samp_Fields = {
  __typename?: 'QuestionnaireLinks_stddev_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Stddev_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "QuestionnaireLinks" */
export type QuestionnaireLinks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireLinks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireLinks_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type QuestionnaireLinks_Sum_Fields = {
  __typename?: 'QuestionnaireLinks_sum_fields';
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Sum_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "QuestionnaireLinks" */
export enum QuestionnaireLinks_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type QuestionnaireLinks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<QuestionnaireLinks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireLinks_Set_Input>;
  /** filter the rows which have to be updated */
  where: QuestionnaireLinks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type QuestionnaireLinks_Var_Pop_Fields = {
  __typename?: 'QuestionnaireLinks_var_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Var_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type QuestionnaireLinks_Var_Samp_Fields = {
  __typename?: 'QuestionnaireLinks_var_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Var_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type QuestionnaireLinks_Variance_Fields = {
  __typename?: 'QuestionnaireLinks_variance_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "QuestionnaireLinks" */
export type QuestionnaireLinks_Variance_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "QuestionnaireResponses" */
export type QuestionnaireResponses = {
  __typename?: 'QuestionnaireResponses';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Questionnaire: Questionnaires;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  identity_id?: Maybe<Scalars['Int']>;
  model: Scalars['jsonb'];
  questionnaire_id: Scalars['uuid'];
  response?: Maybe<Scalars['jsonb']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "QuestionnaireResponses" */
export type QuestionnaireResponsesModelArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "QuestionnaireResponses" */
export type QuestionnaireResponsesResponseArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "QuestionnaireResponses" */
export type QuestionnaireResponses_Aggregate = {
  __typename?: 'QuestionnaireResponses_aggregate';
  aggregate?: Maybe<QuestionnaireResponses_Aggregate_Fields>;
  nodes: Array<QuestionnaireResponses>;
};

export type QuestionnaireResponses_Aggregate_Bool_Exp = {
  count?: InputMaybe<QuestionnaireResponses_Aggregate_Bool_Exp_Count>;
};

export type QuestionnaireResponses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "QuestionnaireResponses" */
export type QuestionnaireResponses_Aggregate_Fields = {
  __typename?: 'QuestionnaireResponses_aggregate_fields';
  avg?: Maybe<QuestionnaireResponses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<QuestionnaireResponses_Max_Fields>;
  min?: Maybe<QuestionnaireResponses_Min_Fields>;
  stddev?: Maybe<QuestionnaireResponses_Stddev_Fields>;
  stddev_pop?: Maybe<QuestionnaireResponses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QuestionnaireResponses_Stddev_Samp_Fields>;
  sum?: Maybe<QuestionnaireResponses_Sum_Fields>;
  var_pop?: Maybe<QuestionnaireResponses_Var_Pop_Fields>;
  var_samp?: Maybe<QuestionnaireResponses_Var_Samp_Fields>;
  variance?: Maybe<QuestionnaireResponses_Variance_Fields>;
};


/** aggregate fields of "QuestionnaireResponses" */
export type QuestionnaireResponses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Aggregate_Order_By = {
  avg?: InputMaybe<QuestionnaireResponses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QuestionnaireResponses_Max_Order_By>;
  min?: InputMaybe<QuestionnaireResponses_Min_Order_By>;
  stddev?: InputMaybe<QuestionnaireResponses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<QuestionnaireResponses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<QuestionnaireResponses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<QuestionnaireResponses_Sum_Order_By>;
  var_pop?: InputMaybe<QuestionnaireResponses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<QuestionnaireResponses_Var_Samp_Order_By>;
  variance?: InputMaybe<QuestionnaireResponses_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireResponses_Append_Input = {
  model?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "QuestionnaireResponses" */
export type QuestionnaireResponses_Arr_Rel_Insert_Input = {
  data: Array<QuestionnaireResponses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireResponses_On_Conflict>;
};

/** aggregate avg on columns */
export type QuestionnaireResponses_Avg_Fields = {
  __typename?: 'QuestionnaireResponses_avg_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Avg_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "QuestionnaireResponses". All fields are combined with a logical 'AND'. */
export type QuestionnaireResponses_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  _and?: InputMaybe<Array<QuestionnaireResponses_Bool_Exp>>;
  _not?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
  _or?: InputMaybe<Array<QuestionnaireResponses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  model?: InputMaybe<Jsonb_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  response?: InputMaybe<Jsonb_Comparison_Exp>;
  submitted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "QuestionnaireResponses" */
export enum QuestionnaireResponses_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireResponsesPkey = 'QuestionnaireResponses_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type QuestionnaireResponses_Delete_At_Path_Input = {
  model?: InputMaybe<Array<Scalars['String']>>;
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type QuestionnaireResponses_Delete_Elem_Input = {
  model?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type QuestionnaireResponses_Delete_Key_Input = {
  model?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "QuestionnaireResponses" */
export type QuestionnaireResponses_Inc_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "QuestionnaireResponses" */
export type QuestionnaireResponses_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['jsonb']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  response?: InputMaybe<Scalars['jsonb']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type QuestionnaireResponses_Max_Fields = {
  __typename?: 'QuestionnaireResponses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  submitted_at?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QuestionnaireResponses_Min_Fields = {
  __typename?: 'QuestionnaireResponses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  submitted_at?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "QuestionnaireResponses" */
export type QuestionnaireResponses_Mutation_Response = {
  __typename?: 'QuestionnaireResponses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireResponses>;
};

/** input type for inserting object relation for remote table "QuestionnaireResponses" */
export type QuestionnaireResponses_Obj_Rel_Insert_Input = {
  data: QuestionnaireResponses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireResponses_On_Conflict>;
};

/** on_conflict condition type for table "QuestionnaireResponses" */
export type QuestionnaireResponses_On_Conflict = {
  constraint: QuestionnaireResponses_Constraint;
  update_columns?: Array<QuestionnaireResponses_Update_Column>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};

/** Ordering options when selecting data from "QuestionnaireResponses". */
export type QuestionnaireResponses_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  submitted_at?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: QuestionnaireResponses */
export type QuestionnaireResponses_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireResponses_Prepend_Input = {
  model?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "QuestionnaireResponses" */
export enum QuestionnaireResponses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Model = 'model',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Response = 'response',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "QuestionnaireResponses" */
export type QuestionnaireResponses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['jsonb']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  response?: InputMaybe<Scalars['jsonb']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type QuestionnaireResponses_Stddev_Fields = {
  __typename?: 'QuestionnaireResponses_stddev_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Stddev_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type QuestionnaireResponses_Stddev_Pop_Fields = {
  __typename?: 'QuestionnaireResponses_stddev_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Stddev_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type QuestionnaireResponses_Stddev_Samp_Fields = {
  __typename?: 'QuestionnaireResponses_stddev_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Stddev_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "QuestionnaireResponses" */
export type QuestionnaireResponses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireResponses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireResponses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['jsonb']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  response?: InputMaybe<Scalars['jsonb']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type QuestionnaireResponses_Sum_Fields = {
  __typename?: 'QuestionnaireResponses_sum_fields';
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Sum_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "QuestionnaireResponses" */
export enum QuestionnaireResponses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Model = 'model',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Response = 'response',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type QuestionnaireResponses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<QuestionnaireResponses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<QuestionnaireResponses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<QuestionnaireResponses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<QuestionnaireResponses_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<QuestionnaireResponses_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<QuestionnaireResponses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireResponses_Set_Input>;
  /** filter the rows which have to be updated */
  where: QuestionnaireResponses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type QuestionnaireResponses_Var_Pop_Fields = {
  __typename?: 'QuestionnaireResponses_var_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Var_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type QuestionnaireResponses_Var_Samp_Fields = {
  __typename?: 'QuestionnaireResponses_var_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Var_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type QuestionnaireResponses_Variance_Fields = {
  __typename?: 'QuestionnaireResponses_variance_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "QuestionnaireResponses" */
export type QuestionnaireResponses_Variance_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Questionnaires" */
export type Questionnaires = {
  __typename?: 'Questionnaires';
  /** An array relationship */
  Contracts: Array<Contracts>;
  /** An aggregate relationship */
  Contracts_aggregate: Contracts_Aggregate;
  /** An object relationship */
  CreatedBy: Identities;
  /** An array relationship */
  Departments: Array<Departments>;
  /** An aggregate relationship */
  Departments_aggregate: Departments_Aggregate;
  /** An array relationship */
  Jobs: Array<Jobs>;
  /** An aggregate relationship */
  Jobs_aggregate: Jobs_Aggregate;
  /** An array relationship */
  QuestionnaireContactTemplateVariables: Array<QuestionnaireContactTemplateVariables>;
  /** An aggregate relationship */
  QuestionnaireContactTemplateVariables_aggregate: QuestionnaireContactTemplateVariables_Aggregate;
  /** An array relationship */
  QuestionnaireLinks: Array<QuestionnaireLinks>;
  /** An aggregate relationship */
  QuestionnaireLinks_aggregate: QuestionnaireLinks_Aggregate;
  /** An array relationship */
  Responses: Array<QuestionnaireResponses>;
  /** An aggregate relationship */
  Responses_aggregate: QuestionnaireResponses_Aggregate;
  /** An object relationship */
  UpdatedBy: Identities;
  archived: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['uuid'];
  model: Scalars['jsonb'];
  save_no: Scalars['Int'];
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesContractsArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Departments_Order_By>>;
  where?: InputMaybe<Departments_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesDepartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Departments_Order_By>>;
  where?: InputMaybe<Departments_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesQuestionnaireContactTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Order_By>>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesQuestionnaireContactTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Order_By>>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesQuestionnaireLinksArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireLinks_Order_By>>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesQuestionnaireLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireLinks_Order_By>>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesResponsesArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireResponses_Order_By>>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireResponses_Order_By>>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};


/** columns and relationships of "Questionnaires" */
export type QuestionnairesModelArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Questionnaires" */
export type Questionnaires_Aggregate = {
  __typename?: 'Questionnaires_aggregate';
  aggregate?: Maybe<Questionnaires_Aggregate_Fields>;
  nodes: Array<Questionnaires>;
};

/** aggregate fields of "Questionnaires" */
export type Questionnaires_Aggregate_Fields = {
  __typename?: 'Questionnaires_aggregate_fields';
  avg?: Maybe<Questionnaires_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questionnaires_Max_Fields>;
  min?: Maybe<Questionnaires_Min_Fields>;
  stddev?: Maybe<Questionnaires_Stddev_Fields>;
  stddev_pop?: Maybe<Questionnaires_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questionnaires_Stddev_Samp_Fields>;
  sum?: Maybe<Questionnaires_Sum_Fields>;
  var_pop?: Maybe<Questionnaires_Var_Pop_Fields>;
  var_samp?: Maybe<Questionnaires_Var_Samp_Fields>;
  variance?: Maybe<Questionnaires_Variance_Fields>;
};


/** aggregate fields of "Questionnaires" */
export type Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questionnaires_Append_Input = {
  model?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Questionnaires_Avg_Fields = {
  __typename?: 'Questionnaires_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  save_no?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Questionnaires". All fields are combined with a logical 'AND'. */
export type Questionnaires_Bool_Exp = {
  Contracts?: InputMaybe<Contracts_Bool_Exp>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  Departments?: InputMaybe<Departments_Bool_Exp>;
  Departments_aggregate?: InputMaybe<Departments_Aggregate_Bool_Exp>;
  Jobs?: InputMaybe<Jobs_Bool_Exp>;
  Jobs_aggregate?: InputMaybe<Jobs_Aggregate_Bool_Exp>;
  QuestionnaireContactTemplateVariables?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
  QuestionnaireContactTemplateVariables_aggregate?: InputMaybe<QuestionnaireContactTemplateVariables_Aggregate_Bool_Exp>;
  QuestionnaireLinks?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
  QuestionnaireLinks_aggregate?: InputMaybe<QuestionnaireLinks_Aggregate_Bool_Exp>;
  Responses?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
  Responses_aggregate?: InputMaybe<QuestionnaireResponses_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  model?: InputMaybe<Jsonb_Comparison_Exp>;
  save_no?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Questionnaires" */
export enum Questionnaires_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnairesPkey = 'Questionnaires_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questionnaires_Delete_At_Path_Input = {
  model?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questionnaires_Delete_Elem_Input = {
  model?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questionnaires_Delete_Key_Input = {
  model?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Questionnaires" */
export type Questionnaires_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  save_no?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Questionnaires" */
export type Questionnaires_Insert_Input = {
  Contracts?: InputMaybe<Contracts_Arr_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Departments?: InputMaybe<Departments_Arr_Rel_Insert_Input>;
  Jobs?: InputMaybe<Jobs_Arr_Rel_Insert_Input>;
  QuestionnaireContactTemplateVariables?: InputMaybe<QuestionnaireContactTemplateVariables_Arr_Rel_Insert_Input>;
  QuestionnaireLinks?: InputMaybe<QuestionnaireLinks_Arr_Rel_Insert_Input>;
  Responses?: InputMaybe<QuestionnaireResponses_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  archived?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  model?: InputMaybe<Scalars['jsonb']>;
  save_no?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Questionnaires_Max_Fields = {
  __typename?: 'Questionnaires_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  save_no?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Questionnaires_Min_Fields = {
  __typename?: 'Questionnaires_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  save_no?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Questionnaires" */
export type Questionnaires_Mutation_Response = {
  __typename?: 'Questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires>;
};

/** input type for inserting object relation for remote table "Questionnaires" */
export type Questionnaires_Obj_Rel_Insert_Input = {
  data: Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "Questionnaires" */
export type Questionnaires_On_Conflict = {
  constraint: Questionnaires_Constraint;
  update_columns?: Array<Questionnaires_Update_Column>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "Questionnaires". */
export type Questionnaires_Order_By = {
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  Departments_aggregate?: InputMaybe<Departments_Aggregate_Order_By>;
  Jobs_aggregate?: InputMaybe<Jobs_Aggregate_Order_By>;
  QuestionnaireContactTemplateVariables_aggregate?: InputMaybe<QuestionnaireContactTemplateVariables_Aggregate_Order_By>;
  QuestionnaireLinks_aggregate?: InputMaybe<QuestionnaireLinks_Aggregate_Order_By>;
  Responses_aggregate?: InputMaybe<QuestionnaireResponses_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  archived?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  save_no?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Questionnaires */
export type Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questionnaires_Prepend_Input = {
  model?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Questionnaires" */
export enum Questionnaires_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Model = 'model',
  /** column name */
  SaveNo = 'save_no',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "Questionnaires" */
export type Questionnaires_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  model?: InputMaybe<Scalars['jsonb']>;
  save_no?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Questionnaires_Stddev_Fields = {
  __typename?: 'Questionnaires_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  save_no?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Questionnaires_Stddev_Pop_Fields = {
  __typename?: 'Questionnaires_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  save_no?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Questionnaires_Stddev_Samp_Fields = {
  __typename?: 'Questionnaires_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  save_no?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Questionnaires" */
export type Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaires_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  model?: InputMaybe<Scalars['jsonb']>;
  save_no?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Questionnaires_Sum_Fields = {
  __typename?: 'Questionnaires_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  save_no?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "Questionnaires" */
export enum Questionnaires_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Model = 'model',
  /** column name */
  SaveNo = 'save_no',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Questionnaires_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questionnaires_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questionnaires_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questionnaires_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questionnaires_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questionnaires_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questionnaires_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaires_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questionnaires_Var_Pop_Fields = {
  __typename?: 'Questionnaires_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  save_no?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Questionnaires_Var_Samp_Fields = {
  __typename?: 'Questionnaires_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  save_no?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Questionnaires_Variance_Fields = {
  __typename?: 'Questionnaires_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  save_no?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "RRWebRecords" */
export type RrWebRecords = {
  __typename?: 'RRWebRecords';
  event: Scalars['jsonb'];
  event_ts: Scalars['timestamptz'];
  id: Scalars['bigint'];
  identity_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "RRWebRecords" */
export type RrWebRecordsEventArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "RRWebRecords" */
export type RrWebRecords_Aggregate = {
  __typename?: 'RRWebRecords_aggregate';
  aggregate?: Maybe<RrWebRecords_Aggregate_Fields>;
  nodes: Array<RrWebRecords>;
};

/** aggregate fields of "RRWebRecords" */
export type RrWebRecords_Aggregate_Fields = {
  __typename?: 'RRWebRecords_aggregate_fields';
  avg?: Maybe<RrWebRecords_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RrWebRecords_Max_Fields>;
  min?: Maybe<RrWebRecords_Min_Fields>;
  stddev?: Maybe<RrWebRecords_Stddev_Fields>;
  stddev_pop?: Maybe<RrWebRecords_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RrWebRecords_Stddev_Samp_Fields>;
  sum?: Maybe<RrWebRecords_Sum_Fields>;
  var_pop?: Maybe<RrWebRecords_Var_Pop_Fields>;
  var_samp?: Maybe<RrWebRecords_Var_Samp_Fields>;
  variance?: Maybe<RrWebRecords_Variance_Fields>;
};


/** aggregate fields of "RRWebRecords" */
export type RrWebRecords_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RrWebRecords_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RrWebRecords_Append_Input = {
  event?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type RrWebRecords_Avg_Fields = {
  __typename?: 'RRWebRecords_avg_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "RRWebRecords". All fields are combined with a logical 'AND'. */
export type RrWebRecords_Bool_Exp = {
  _and?: InputMaybe<Array<RrWebRecords_Bool_Exp>>;
  _not?: InputMaybe<RrWebRecords_Bool_Exp>;
  _or?: InputMaybe<Array<RrWebRecords_Bool_Exp>>;
  event?: InputMaybe<Jsonb_Comparison_Exp>;
  event_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "RRWebRecords" */
export enum RrWebRecords_Constraint {
  /** unique or primary key constraint on columns "id" */
  RrWebRecordsPkey = 'RRWebRecords_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RrWebRecords_Delete_At_Path_Input = {
  event?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RrWebRecords_Delete_Elem_Input = {
  event?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RrWebRecords_Delete_Key_Input = {
  event?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "RRWebRecords" */
export type RrWebRecords_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "RRWebRecords" */
export type RrWebRecords_Insert_Input = {
  event?: InputMaybe<Scalars['jsonb']>;
  event_ts?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RrWebRecords_Max_Fields = {
  __typename?: 'RRWebRecords_max_fields';
  event_ts?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RrWebRecords_Min_Fields = {
  __typename?: 'RRWebRecords_min_fields';
  event_ts?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  identity_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "RRWebRecords" */
export type RrWebRecords_Mutation_Response = {
  __typename?: 'RRWebRecords_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RrWebRecords>;
};

/** on_conflict condition type for table "RRWebRecords" */
export type RrWebRecords_On_Conflict = {
  constraint: RrWebRecords_Constraint;
  update_columns?: Array<RrWebRecords_Update_Column>;
  where?: InputMaybe<RrWebRecords_Bool_Exp>;
};

/** Ordering options when selecting data from "RRWebRecords". */
export type RrWebRecords_Order_By = {
  event?: InputMaybe<Order_By>;
  event_ts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RRWebRecords */
export type RrWebRecords_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RrWebRecords_Prepend_Input = {
  event?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "RRWebRecords" */
export enum RrWebRecords_Select_Column {
  /** column name */
  Event = 'event',
  /** column name */
  EventTs = 'event_ts',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "RRWebRecords" */
export type RrWebRecords_Set_Input = {
  event?: InputMaybe<Scalars['jsonb']>;
  event_ts?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RrWebRecords_Stddev_Fields = {
  __typename?: 'RRWebRecords_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RrWebRecords_Stddev_Pop_Fields = {
  __typename?: 'RRWebRecords_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RrWebRecords_Stddev_Samp_Fields = {
  __typename?: 'RRWebRecords_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "RRWebRecords" */
export type RrWebRecords_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RrWebRecords_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RrWebRecords_Stream_Cursor_Value_Input = {
  event?: InputMaybe<Scalars['jsonb']>;
  event_ts?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type RrWebRecords_Sum_Fields = {
  __typename?: 'RRWebRecords_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "RRWebRecords" */
export enum RrWebRecords_Update_Column {
  /** column name */
  Event = 'event',
  /** column name */
  EventTs = 'event_ts',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type RrWebRecords_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RrWebRecords_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RrWebRecords_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RrWebRecords_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RrWebRecords_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RrWebRecords_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RrWebRecords_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RrWebRecords_Set_Input>;
  /** filter the rows which have to be updated */
  where: RrWebRecords_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RrWebRecords_Var_Pop_Fields = {
  __typename?: 'RRWebRecords_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RrWebRecords_Var_Samp_Fields = {
  __typename?: 'RRWebRecords_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RrWebRecords_Variance_Fields = {
  __typename?: 'RRWebRecords_variance_fields';
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "RaceEnum" */
export type RaceEnum = {
  __typename?: 'RaceEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "RaceEnum" */
export type RaceEnum_Aggregate = {
  __typename?: 'RaceEnum_aggregate';
  aggregate?: Maybe<RaceEnum_Aggregate_Fields>;
  nodes: Array<RaceEnum>;
};

/** aggregate fields of "RaceEnum" */
export type RaceEnum_Aggregate_Fields = {
  __typename?: 'RaceEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RaceEnum_Max_Fields>;
  min?: Maybe<RaceEnum_Min_Fields>;
};


/** aggregate fields of "RaceEnum" */
export type RaceEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RaceEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "RaceEnum". All fields are combined with a logical 'AND'. */
export type RaceEnum_Bool_Exp = {
  _and?: InputMaybe<Array<RaceEnum_Bool_Exp>>;
  _not?: InputMaybe<RaceEnum_Bool_Exp>;
  _or?: InputMaybe<Array<RaceEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "RaceEnum" */
export enum RaceEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  RaceEnumPkey = 'RaceEnum_pkey'
}

export enum RaceEnum_Enum {
  /** Asian */
  Asian = 'ASIAN',
  /** Black or African American */
  Black = 'BLACK',
  /** Do Not Wish to Disclose */
  DoNotDisclose = 'DO_NOT_DISCLOSE',
  /** Hispanic or Latino */
  HispanicOrLatino = 'HISPANIC_OR_LATINO',
  /** Native American or Alaska Native */
  NativeAmericanOrAlaskaNative = 'NATIVE_AMERICAN_OR_ALASKA_NATIVE',
  /** Native Hawaiian or Other Pacific Islander */
  NativeHawaiianOrPacIslander = 'NATIVE_HAWAIIAN_OR_PAC_ISLANDER',
  /** OTHER */
  Other = 'OTHER',
  /** Two or more races */
  TwoOrMore = 'TWO_OR_MORE',
  /** White (Not Hispanic or Latino) */
  White = 'WHITE'
}

/** Boolean expression to compare columns of type "RaceEnum_enum". All fields are combined with logical 'AND'. */
export type RaceEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<RaceEnum_Enum>;
  _in?: InputMaybe<Array<RaceEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RaceEnum_Enum>;
  _nin?: InputMaybe<Array<RaceEnum_Enum>>;
};

/** input type for inserting data into table "RaceEnum" */
export type RaceEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RaceEnum_Max_Fields = {
  __typename?: 'RaceEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RaceEnum_Min_Fields = {
  __typename?: 'RaceEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "RaceEnum" */
export type RaceEnum_Mutation_Response = {
  __typename?: 'RaceEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RaceEnum>;
};

/** input type for inserting object relation for remote table "RaceEnum" */
export type RaceEnum_Obj_Rel_Insert_Input = {
  data: RaceEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RaceEnum_On_Conflict>;
};

/** on_conflict condition type for table "RaceEnum" */
export type RaceEnum_On_Conflict = {
  constraint: RaceEnum_Constraint;
  update_columns?: Array<RaceEnum_Update_Column>;
  where?: InputMaybe<RaceEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "RaceEnum". */
export type RaceEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RaceEnum */
export type RaceEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "RaceEnum" */
export enum RaceEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "RaceEnum" */
export type RaceEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "RaceEnum" */
export type RaceEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RaceEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RaceEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "RaceEnum" */
export enum RaceEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type RaceEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RaceEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: RaceEnum_Bool_Exp;
};

/** columns and relationships of "RangeValues" */
export type RangeValues = {
  __typename?: 'RangeValues';
  /** An object relationship */
  Company: Companies;
  /** An array relationship */
  FormulaOptions: Array<FormulaOptions>;
  /** An aggregate relationship */
  FormulaOptions_aggregate: FormulaOptions_Aggregate;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  map: Scalars['jsonb'];
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "RangeValues" */
export type RangeValuesFormulaOptionsArgs = {
  distinct_on?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormulaOptions_Order_By>>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};


/** columns and relationships of "RangeValues" */
export type RangeValuesFormulaOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormulaOptions_Order_By>>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};


/** columns and relationships of "RangeValues" */
export type RangeValuesMapArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "RangeValues" */
export type RangeValues_Aggregate = {
  __typename?: 'RangeValues_aggregate';
  aggregate?: Maybe<RangeValues_Aggregate_Fields>;
  nodes: Array<RangeValues>;
};

/** aggregate fields of "RangeValues" */
export type RangeValues_Aggregate_Fields = {
  __typename?: 'RangeValues_aggregate_fields';
  avg?: Maybe<RangeValues_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RangeValues_Max_Fields>;
  min?: Maybe<RangeValues_Min_Fields>;
  stddev?: Maybe<RangeValues_Stddev_Fields>;
  stddev_pop?: Maybe<RangeValues_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RangeValues_Stddev_Samp_Fields>;
  sum?: Maybe<RangeValues_Sum_Fields>;
  var_pop?: Maybe<RangeValues_Var_Pop_Fields>;
  var_samp?: Maybe<RangeValues_Var_Samp_Fields>;
  variance?: Maybe<RangeValues_Variance_Fields>;
};


/** aggregate fields of "RangeValues" */
export type RangeValues_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RangeValues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RangeValues_Append_Input = {
  map?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type RangeValues_Avg_Fields = {
  __typename?: 'RangeValues_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "RangeValues". All fields are combined with a logical 'AND'. */
export type RangeValues_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  FormulaOptions?: InputMaybe<FormulaOptions_Bool_Exp>;
  FormulaOptions_aggregate?: InputMaybe<FormulaOptions_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<RangeValues_Bool_Exp>>;
  _not?: InputMaybe<RangeValues_Bool_Exp>;
  _or?: InputMaybe<Array<RangeValues_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  map?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "RangeValues" */
export enum RangeValues_Constraint {
  /** unique or primary key constraint on columns "id" */
  RangeValuesPkey = 'RangeValues_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RangeValues_Delete_At_Path_Input = {
  map?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RangeValues_Delete_Elem_Input = {
  map?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RangeValues_Delete_Key_Input = {
  map?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "RangeValues" */
export type RangeValues_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "RangeValues" */
export type RangeValues_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  FormulaOptions?: InputMaybe<FormulaOptions_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  map?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RangeValues_Max_Fields = {
  __typename?: 'RangeValues_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type RangeValues_Min_Fields = {
  __typename?: 'RangeValues_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "RangeValues" */
export type RangeValues_Mutation_Response = {
  __typename?: 'RangeValues_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RangeValues>;
};

/** input type for inserting object relation for remote table "RangeValues" */
export type RangeValues_Obj_Rel_Insert_Input = {
  data: RangeValues_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RangeValues_On_Conflict>;
};

/** on_conflict condition type for table "RangeValues" */
export type RangeValues_On_Conflict = {
  constraint: RangeValues_Constraint;
  update_columns?: Array<RangeValues_Update_Column>;
  where?: InputMaybe<RangeValues_Bool_Exp>;
};

/** Ordering options when selecting data from "RangeValues". */
export type RangeValues_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  FormulaOptions_aggregate?: InputMaybe<FormulaOptions_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  map?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RangeValues */
export type RangeValues_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RangeValues_Prepend_Input = {
  map?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "RangeValues" */
export enum RangeValues_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Map = 'map',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "RangeValues" */
export type RangeValues_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  map?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RangeValues_Stddev_Fields = {
  __typename?: 'RangeValues_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RangeValues_Stddev_Pop_Fields = {
  __typename?: 'RangeValues_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RangeValues_Stddev_Samp_Fields = {
  __typename?: 'RangeValues_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "RangeValues" */
export type RangeValues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RangeValues_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RangeValues_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  map?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type RangeValues_Sum_Fields = {
  __typename?: 'RangeValues_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "RangeValues" */
export enum RangeValues_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Map = 'map',
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RangeValues_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RangeValues_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RangeValues_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RangeValues_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RangeValues_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RangeValues_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RangeValues_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RangeValues_Set_Input>;
  /** filter the rows which have to be updated */
  where: RangeValues_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RangeValues_Var_Pop_Fields = {
  __typename?: 'RangeValues_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RangeValues_Var_Samp_Fields = {
  __typename?: 'RangeValues_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RangeValues_Variance_Fields = {
  __typename?: 'RangeValues_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type RecordQuestionnaireResponseInput = {
  identityId?: InputMaybe<Scalars['Int']>;
  model: Scalars['String'];
  questionnaireId?: InputMaybe<Scalars['String']>;
  questionnaireLinkId?: InputMaybe<Scalars['String']>;
  response: Scalars['String'];
  subdomain: Scalars['String'];
};

export type RecordQuestionnaireResponseOutput = {
  __typename?: 'RecordQuestionnaireResponseOutput';
  message: Scalars['String'];
};

export type RefreshInput = {
  environmentId: Scalars['String'];
  worksheet: Scalars['String'];
};

export type RefreshOutput = {
  __typename?: 'RefreshOutput';
  accessToken: Scalars['String'];
  spaceId: Scalars['String'];
};

/** Rejected or Withdrawn  */
export type RejectionTypeEnum = {
  __typename?: 'RejectionTypeEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "RejectionTypeEnum" */
export type RejectionTypeEnum_Aggregate = {
  __typename?: 'RejectionTypeEnum_aggregate';
  aggregate?: Maybe<RejectionTypeEnum_Aggregate_Fields>;
  nodes: Array<RejectionTypeEnum>;
};

/** aggregate fields of "RejectionTypeEnum" */
export type RejectionTypeEnum_Aggregate_Fields = {
  __typename?: 'RejectionTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RejectionTypeEnum_Max_Fields>;
  min?: Maybe<RejectionTypeEnum_Min_Fields>;
};


/** aggregate fields of "RejectionTypeEnum" */
export type RejectionTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RejectionTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "RejectionTypeEnum". All fields are combined with a logical 'AND'. */
export type RejectionTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<RejectionTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<RejectionTypeEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "RejectionTypeEnum" */
export enum RejectionTypeEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  RejectionTypeEnumPkey = 'RejectionTypeEnum_pkey'
}

export enum RejectionTypeEnum_Enum {
  /** Pipeline */
  Pipeline = 'PIPELINE',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Withdrawn */
  Withdrawn = 'WITHDRAWN'
}

/** Boolean expression to compare columns of type "RejectionTypeEnum_enum". All fields are combined with logical 'AND'. */
export type RejectionTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<RejectionTypeEnum_Enum>;
  _in?: InputMaybe<Array<RejectionTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RejectionTypeEnum_Enum>;
  _nin?: InputMaybe<Array<RejectionTypeEnum_Enum>>;
};

/** input type for inserting data into table "RejectionTypeEnum" */
export type RejectionTypeEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RejectionTypeEnum_Max_Fields = {
  __typename?: 'RejectionTypeEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RejectionTypeEnum_Min_Fields = {
  __typename?: 'RejectionTypeEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "RejectionTypeEnum" */
export type RejectionTypeEnum_Mutation_Response = {
  __typename?: 'RejectionTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RejectionTypeEnum>;
};

/** input type for inserting object relation for remote table "RejectionTypeEnum" */
export type RejectionTypeEnum_Obj_Rel_Insert_Input = {
  data: RejectionTypeEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RejectionTypeEnum_On_Conflict>;
};

/** on_conflict condition type for table "RejectionTypeEnum" */
export type RejectionTypeEnum_On_Conflict = {
  constraint: RejectionTypeEnum_Constraint;
  update_columns?: Array<RejectionTypeEnum_Update_Column>;
  where?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "RejectionTypeEnum". */
export type RejectionTypeEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RejectionTypeEnum */
export type RejectionTypeEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "RejectionTypeEnum" */
export enum RejectionTypeEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "RejectionTypeEnum" */
export type RejectionTypeEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "RejectionTypeEnum" */
export type RejectionTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RejectionTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RejectionTypeEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "RejectionTypeEnum" */
export enum RejectionTypeEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type RejectionTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RejectionTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: RejectionTypeEnum_Bool_Exp;
};

/** columns and relationships of "Rejections" */
export type Rejections = {
  __typename?: 'Rejections';
  /** An object relationship */
  Email?: Maybe<Emails>;
  /** An object relationship */
  RejectionTypeEnum: RejectionTypeEnum;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deletable: Scalars['Boolean'];
  email_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  reason: Scalars['String'];
  rejection_type_enum: RejectionTypeEnum_Enum;
  sharedWithPartners: Scalars['Boolean'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Rejections" */
export type Rejections_Aggregate = {
  __typename?: 'Rejections_aggregate';
  aggregate?: Maybe<Rejections_Aggregate_Fields>;
  nodes: Array<Rejections>;
};

export type Rejections_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Rejections_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Rejections_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Rejections_Aggregate_Bool_Exp_Count>;
};

export type Rejections_Aggregate_Bool_Exp_Bool_And = {
  arguments: Rejections_Select_Column_Rejections_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rejections_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rejections_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Rejections_Select_Column_Rejections_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rejections_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rejections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rejections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rejections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Rejections" */
export type Rejections_Aggregate_Fields = {
  __typename?: 'Rejections_aggregate_fields';
  avg?: Maybe<Rejections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rejections_Max_Fields>;
  min?: Maybe<Rejections_Min_Fields>;
  stddev?: Maybe<Rejections_Stddev_Fields>;
  stddev_pop?: Maybe<Rejections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rejections_Stddev_Samp_Fields>;
  sum?: Maybe<Rejections_Sum_Fields>;
  var_pop?: Maybe<Rejections_Var_Pop_Fields>;
  var_samp?: Maybe<Rejections_Var_Samp_Fields>;
  variance?: Maybe<Rejections_Variance_Fields>;
};


/** aggregate fields of "Rejections" */
export type Rejections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rejections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Rejections" */
export type Rejections_Aggregate_Order_By = {
  avg?: InputMaybe<Rejections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rejections_Max_Order_By>;
  min?: InputMaybe<Rejections_Min_Order_By>;
  stddev?: InputMaybe<Rejections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rejections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rejections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rejections_Sum_Order_By>;
  var_pop?: InputMaybe<Rejections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rejections_Var_Samp_Order_By>;
  variance?: InputMaybe<Rejections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Rejections" */
export type Rejections_Arr_Rel_Insert_Input = {
  data: Array<Rejections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rejections_On_Conflict>;
};

/** aggregate avg on columns */
export type Rejections_Avg_Fields = {
  __typename?: 'Rejections_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Rejections" */
export type Rejections_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Rejections". All fields are combined with a logical 'AND'. */
export type Rejections_Bool_Exp = {
  Email?: InputMaybe<Emails_Bool_Exp>;
  RejectionTypeEnum?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
  _and?: InputMaybe<Array<Rejections_Bool_Exp>>;
  _not?: InputMaybe<Rejections_Bool_Exp>;
  _or?: InputMaybe<Array<Rejections_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletable?: InputMaybe<Boolean_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  rejection_type_enum?: InputMaybe<RejectionTypeEnum_Enum_Comparison_Exp>;
  sharedWithPartners?: InputMaybe<Boolean_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Rejections" */
export enum Rejections_Constraint {
  /** unique or primary key constraint on columns "id" */
  RejectionsPkey = 'Rejections_pkey',
  /** unique or primary key constraint on columns "reason" */
  RejectionsReasonKey = 'Rejections_reason_key'
}

/** input type for incrementing numeric columns in table "Rejections" */
export type Rejections_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Rejections" */
export type Rejections_Insert_Input = {
  Email?: InputMaybe<Emails_Obj_Rel_Insert_Input>;
  RejectionTypeEnum?: InputMaybe<RejectionTypeEnum_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  rejection_type_enum?: InputMaybe<RejectionTypeEnum_Enum>;
  sharedWithPartners?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rejections_Max_Fields = {
  __typename?: 'Rejections_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Rejections" */
export type Rejections_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rejections_Min_Fields = {
  __typename?: 'Rejections_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Rejections" */
export type Rejections_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Rejections" */
export type Rejections_Mutation_Response = {
  __typename?: 'Rejections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rejections>;
};

/** input type for inserting object relation for remote table "Rejections" */
export type Rejections_Obj_Rel_Insert_Input = {
  data: Rejections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rejections_On_Conflict>;
};

/** on_conflict condition type for table "Rejections" */
export type Rejections_On_Conflict = {
  constraint: Rejections_Constraint;
  update_columns?: Array<Rejections_Update_Column>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};

/** Ordering options when selecting data from "Rejections". */
export type Rejections_Order_By = {
  Email?: InputMaybe<Emails_Order_By>;
  RejectionTypeEnum?: InputMaybe<RejectionTypeEnum_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deletable?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  rejection_type_enum?: InputMaybe<Order_By>;
  sharedWithPartners?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Rejections */
export type Rejections_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Rejections" */
export enum Rejections_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  RejectionTypeEnum = 'rejection_type_enum',
  /** column name */
  SharedWithPartners = 'sharedWithPartners',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Rejections_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Rejections" */
export enum Rejections_Select_Column_Rejections_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deletable = 'deletable',
  /** column name */
  SharedWithPartners = 'sharedWithPartners'
}

/** select "Rejections_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Rejections" */
export enum Rejections_Select_Column_Rejections_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deletable = 'deletable',
  /** column name */
  SharedWithPartners = 'sharedWithPartners'
}

/** input type for updating data in table "Rejections" */
export type Rejections_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  rejection_type_enum?: InputMaybe<RejectionTypeEnum_Enum>;
  sharedWithPartners?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Rejections_Stddev_Fields = {
  __typename?: 'Rejections_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Rejections" */
export type Rejections_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rejections_Stddev_Pop_Fields = {
  __typename?: 'Rejections_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Rejections" */
export type Rejections_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rejections_Stddev_Samp_Fields = {
  __typename?: 'Rejections_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Rejections" */
export type Rejections_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Rejections" */
export type Rejections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rejections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rejections_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deletable?: InputMaybe<Scalars['Boolean']>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  rejection_type_enum?: InputMaybe<RejectionTypeEnum_Enum>;
  sharedWithPartners?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Rejections_Sum_Fields = {
  __typename?: 'Rejections_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Rejections" */
export type Rejections_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Rejections" */
export enum Rejections_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deletable = 'deletable',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  RejectionTypeEnum = 'rejection_type_enum',
  /** column name */
  SharedWithPartners = 'sharedWithPartners',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Rejections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rejections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rejections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rejections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rejections_Var_Pop_Fields = {
  __typename?: 'Rejections_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Rejections" */
export type Rejections_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rejections_Var_Samp_Fields = {
  __typename?: 'Rejections_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Rejections" */
export type Rejections_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rejections_Variance_Fields = {
  __typename?: 'Rejections_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Rejections" */
export type Rejections_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type RenderEmailInput = {
  bcc?: InputMaybe<Scalars['String']>;
  boardSnippetId?: InputMaybe<Scalars['Int']>;
  body: Scalars['String'];
  cc?: InputMaybe<Scalars['String']>;
  email_blurb?: InputMaybe<Scalars['String']>;
  fromEmail: Scalars['String'];
  identityId?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<RenderEmailInputMetadata>;
  options?: InputMaybe<Scalars['String']>;
  originalEmailId?: InputMaybe<Scalars['Int']>;
  subject: Scalars['String'];
  toEmail: Scalars['String'];
};

export type RenderEmailInputMetadata = {
  isBulk?: InputMaybe<Scalars['Boolean']>;
};

export type RenderEmailResponse = {
  __typename?: 'RenderEmailResponse';
  RenderedEmail?: Maybe<RenderedEmails>;
  rendered_email_id: Scalars['Int'];
};

/** columns and relationships of "RenderedEmails" */
export type RenderedEmails = {
  __typename?: 'RenderedEmails';
  /** An object relationship */
  BoardSnippet?: Maybe<BoardSnippets>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  attachment_keys?: Maybe<Scalars['json']>;
  bcc: Scalars['String'];
  body: Scalars['String'];
  bs_id?: Maybe<Scalars['Int']>;
  cc: Scalars['String'];
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  email_blurb?: Maybe<Scalars['String']>;
  email_send_result?: Maybe<Scalars['jsonb']>;
  fromEmail?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['json']>;
  missing_vars?: Maybe<Scalars['json']>;
  original_email_id?: Maybe<Scalars['Int']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  subject: Scalars['String'];
  toEmail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "RenderedEmails" */
export type RenderedEmailsAttachment_KeysArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "RenderedEmails" */
export type RenderedEmailsEmail_Send_ResultArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "RenderedEmails" */
export type RenderedEmailsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "RenderedEmails" */
export type RenderedEmailsMissing_VarsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "RenderedEmails" */
export type RenderedEmails_Aggregate = {
  __typename?: 'RenderedEmails_aggregate';
  aggregate?: Maybe<RenderedEmails_Aggregate_Fields>;
  nodes: Array<RenderedEmails>;
};

/** aggregate fields of "RenderedEmails" */
export type RenderedEmails_Aggregate_Fields = {
  __typename?: 'RenderedEmails_aggregate_fields';
  avg?: Maybe<RenderedEmails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RenderedEmails_Max_Fields>;
  min?: Maybe<RenderedEmails_Min_Fields>;
  stddev?: Maybe<RenderedEmails_Stddev_Fields>;
  stddev_pop?: Maybe<RenderedEmails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RenderedEmails_Stddev_Samp_Fields>;
  sum?: Maybe<RenderedEmails_Sum_Fields>;
  var_pop?: Maybe<RenderedEmails_Var_Pop_Fields>;
  var_samp?: Maybe<RenderedEmails_Var_Samp_Fields>;
  variance?: Maybe<RenderedEmails_Variance_Fields>;
};


/** aggregate fields of "RenderedEmails" */
export type RenderedEmails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RenderedEmails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RenderedEmails_Append_Input = {
  email_send_result?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type RenderedEmails_Avg_Fields = {
  __typename?: 'RenderedEmails_avg_fields';
  bs_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "RenderedEmails". All fields are combined with a logical 'AND'. */
export type RenderedEmails_Bool_Exp = {
  BoardSnippet?: InputMaybe<BoardSnippets_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<RenderedEmails_Bool_Exp>>;
  _not?: InputMaybe<RenderedEmails_Bool_Exp>;
  _or?: InputMaybe<Array<RenderedEmails_Bool_Exp>>;
  attachment_keys?: InputMaybe<Json_Comparison_Exp>;
  bcc?: InputMaybe<String_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  bs_id?: InputMaybe<Int_Comparison_Exp>;
  cc?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_blurb?: InputMaybe<String_Comparison_Exp>;
  email_send_result?: InputMaybe<Jsonb_Comparison_Exp>;
  fromEmail?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  message_id?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Json_Comparison_Exp>;
  missing_vars?: InputMaybe<Json_Comparison_Exp>;
  original_email_id?: InputMaybe<Int_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  toEmail?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "RenderedEmails" */
export enum RenderedEmails_Constraint {
  /** unique or primary key constraint on columns "id" */
  RenderedEmailsPkey = 'RenderedEmails_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RenderedEmails_Delete_At_Path_Input = {
  email_send_result?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RenderedEmails_Delete_Elem_Input = {
  email_send_result?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RenderedEmails_Delete_Key_Input = {
  email_send_result?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "RenderedEmails" */
export type RenderedEmails_Inc_Input = {
  bs_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  original_email_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "RenderedEmails" */
export type RenderedEmails_Insert_Input = {
  BoardSnippet?: InputMaybe<BoardSnippets_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  attachment_keys?: InputMaybe<Scalars['json']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  bs_id?: InputMaybe<Scalars['Int']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_blurb?: InputMaybe<Scalars['String']>;
  email_send_result?: InputMaybe<Scalars['jsonb']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['json']>;
  missing_vars?: InputMaybe<Scalars['json']>;
  original_email_id?: InputMaybe<Scalars['Int']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  toEmail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RenderedEmails_Max_Fields = {
  __typename?: 'RenderedEmails_max_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  bs_id?: Maybe<Scalars['Int']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_blurb?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['String']>;
  original_email_id?: Maybe<Scalars['Int']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type RenderedEmails_Min_Fields = {
  __typename?: 'RenderedEmails_min_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  bs_id?: Maybe<Scalars['Int']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_blurb?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['String']>;
  original_email_id?: Maybe<Scalars['Int']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "RenderedEmails" */
export type RenderedEmails_Mutation_Response = {
  __typename?: 'RenderedEmails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RenderedEmails>;
};

/** input type for inserting object relation for remote table "RenderedEmails" */
export type RenderedEmails_Obj_Rel_Insert_Input = {
  data: RenderedEmails_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RenderedEmails_On_Conflict>;
};

/** on_conflict condition type for table "RenderedEmails" */
export type RenderedEmails_On_Conflict = {
  constraint: RenderedEmails_Constraint;
  update_columns?: Array<RenderedEmails_Update_Column>;
  where?: InputMaybe<RenderedEmails_Bool_Exp>;
};

/** Ordering options when selecting data from "RenderedEmails". */
export type RenderedEmails_Order_By = {
  BoardSnippet?: InputMaybe<BoardSnippets_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  attachment_keys?: InputMaybe<Order_By>;
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  bs_id?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_blurb?: InputMaybe<Order_By>;
  email_send_result?: InputMaybe<Order_By>;
  fromEmail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  missing_vars?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  toEmail?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RenderedEmails */
export type RenderedEmails_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RenderedEmails_Prepend_Input = {
  email_send_result?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "RenderedEmails" */
export enum RenderedEmails_Select_Column {
  /** column name */
  AttachmentKeys = 'attachment_keys',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  BsId = 'bs_id',
  /** column name */
  Cc = 'cc',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailBlurb = 'email_blurb',
  /** column name */
  EmailSendResult = 'email_send_result',
  /** column name */
  FromEmail = 'fromEmail',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MissingVars = 'missing_vars',
  /** column name */
  OriginalEmailId = 'original_email_id',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToEmail = 'toEmail',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "RenderedEmails" */
export type RenderedEmails_Set_Input = {
  attachment_keys?: InputMaybe<Scalars['json']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  bs_id?: InputMaybe<Scalars['Int']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_blurb?: InputMaybe<Scalars['String']>;
  email_send_result?: InputMaybe<Scalars['jsonb']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['json']>;
  missing_vars?: InputMaybe<Scalars['json']>;
  original_email_id?: InputMaybe<Scalars['Int']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  toEmail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RenderedEmails_Stddev_Fields = {
  __typename?: 'RenderedEmails_stddev_fields';
  bs_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RenderedEmails_Stddev_Pop_Fields = {
  __typename?: 'RenderedEmails_stddev_pop_fields';
  bs_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RenderedEmails_Stddev_Samp_Fields = {
  __typename?: 'RenderedEmails_stddev_samp_fields';
  bs_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "RenderedEmails" */
export type RenderedEmails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RenderedEmails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RenderedEmails_Stream_Cursor_Value_Input = {
  attachment_keys?: InputMaybe<Scalars['json']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  bs_id?: InputMaybe<Scalars['Int']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_blurb?: InputMaybe<Scalars['String']>;
  email_send_result?: InputMaybe<Scalars['jsonb']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  message_id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['json']>;
  missing_vars?: InputMaybe<Scalars['json']>;
  original_email_id?: InputMaybe<Scalars['Int']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  toEmail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type RenderedEmails_Sum_Fields = {
  __typename?: 'RenderedEmails_sum_fields';
  bs_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  original_email_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "RenderedEmails" */
export enum RenderedEmails_Update_Column {
  /** column name */
  AttachmentKeys = 'attachment_keys',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  BsId = 'bs_id',
  /** column name */
  Cc = 'cc',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailBlurb = 'email_blurb',
  /** column name */
  EmailSendResult = 'email_send_result',
  /** column name */
  FromEmail = 'fromEmail',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MissingVars = 'missing_vars',
  /** column name */
  OriginalEmailId = 'original_email_id',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToEmail = 'toEmail',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RenderedEmails_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RenderedEmails_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RenderedEmails_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RenderedEmails_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RenderedEmails_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RenderedEmails_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RenderedEmails_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RenderedEmails_Set_Input>;
  /** filter the rows which have to be updated */
  where: RenderedEmails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RenderedEmails_Var_Pop_Fields = {
  __typename?: 'RenderedEmails_var_pop_fields';
  bs_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RenderedEmails_Var_Samp_Fields = {
  __typename?: 'RenderedEmails_var_samp_fields';
  bs_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RenderedEmails_Variance_Fields = {
  __typename?: 'RenderedEmails_variance_fields';
  bs_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

export type ResendOnboardingOutput = {
  __typename?: 'ResendOnboardingOutput';
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** Stores which users have access to view a specific resume file */
export type ResumeClaims = {
  __typename?: 'ResumeClaims';
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Resume: Resumes;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  resume_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ResumeClaims" */
export type ResumeClaims_Aggregate = {
  __typename?: 'ResumeClaims_aggregate';
  aggregate?: Maybe<ResumeClaims_Aggregate_Fields>;
  nodes: Array<ResumeClaims>;
};

export type ResumeClaims_Aggregate_Bool_Exp = {
  count?: InputMaybe<ResumeClaims_Aggregate_Bool_Exp_Count>;
};

export type ResumeClaims_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ResumeClaims_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ResumeClaims" */
export type ResumeClaims_Aggregate_Fields = {
  __typename?: 'ResumeClaims_aggregate_fields';
  avg?: Maybe<ResumeClaims_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ResumeClaims_Max_Fields>;
  min?: Maybe<ResumeClaims_Min_Fields>;
  stddev?: Maybe<ResumeClaims_Stddev_Fields>;
  stddev_pop?: Maybe<ResumeClaims_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ResumeClaims_Stddev_Samp_Fields>;
  sum?: Maybe<ResumeClaims_Sum_Fields>;
  var_pop?: Maybe<ResumeClaims_Var_Pop_Fields>;
  var_samp?: Maybe<ResumeClaims_Var_Samp_Fields>;
  variance?: Maybe<ResumeClaims_Variance_Fields>;
};


/** aggregate fields of "ResumeClaims" */
export type ResumeClaims_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ResumeClaims" */
export type ResumeClaims_Aggregate_Order_By = {
  avg?: InputMaybe<ResumeClaims_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ResumeClaims_Max_Order_By>;
  min?: InputMaybe<ResumeClaims_Min_Order_By>;
  stddev?: InputMaybe<ResumeClaims_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ResumeClaims_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ResumeClaims_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ResumeClaims_Sum_Order_By>;
  var_pop?: InputMaybe<ResumeClaims_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ResumeClaims_Var_Samp_Order_By>;
  variance?: InputMaybe<ResumeClaims_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ResumeClaims" */
export type ResumeClaims_Arr_Rel_Insert_Input = {
  data: Array<ResumeClaims_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ResumeClaims_On_Conflict>;
};

/** aggregate avg on columns */
export type ResumeClaims_Avg_Fields = {
  __typename?: 'ResumeClaims_avg_fields';
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ResumeClaims" */
export type ResumeClaims_Avg_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ResumeClaims". All fields are combined with a logical 'AND'. */
export type ResumeClaims_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Resume?: InputMaybe<Resumes_Bool_Exp>;
  _and?: InputMaybe<Array<ResumeClaims_Bool_Exp>>;
  _not?: InputMaybe<ResumeClaims_Bool_Exp>;
  _or?: InputMaybe<Array<ResumeClaims_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  resume_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ResumeClaims" */
export enum ResumeClaims_Constraint {
  /** unique or primary key constraint on columns "id" */
  ResumeClaimsPkey = 'ResumeClaims_pkey',
  /** unique or primary key constraint on columns "identity_id", "resume_id" */
  ResumeClaimsResumeIdIdentityIdKey = 'ResumeClaims_resume_id_identity_id_key'
}

/** input type for incrementing numeric columns in table "ResumeClaims" */
export type ResumeClaims_Inc_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ResumeClaims" */
export type ResumeClaims_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Resume?: InputMaybe<Resumes_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ResumeClaims_Max_Fields = {
  __typename?: 'ResumeClaims_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ResumeClaims" */
export type ResumeClaims_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ResumeClaims_Min_Fields = {
  __typename?: 'ResumeClaims_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ResumeClaims" */
export type ResumeClaims_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ResumeClaims" */
export type ResumeClaims_Mutation_Response = {
  __typename?: 'ResumeClaims_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ResumeClaims>;
};

/** on_conflict condition type for table "ResumeClaims" */
export type ResumeClaims_On_Conflict = {
  constraint: ResumeClaims_Constraint;
  update_columns?: Array<ResumeClaims_Update_Column>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};

/** Ordering options when selecting data from "ResumeClaims". */
export type ResumeClaims_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  Resume?: InputMaybe<Resumes_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ResumeClaims */
export type ResumeClaims_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ResumeClaims" */
export enum ResumeClaims_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ResumeClaims" */
export type ResumeClaims_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ResumeClaims_Stddev_Fields = {
  __typename?: 'ResumeClaims_stddev_fields';
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ResumeClaims" */
export type ResumeClaims_Stddev_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ResumeClaims_Stddev_Pop_Fields = {
  __typename?: 'ResumeClaims_stddev_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ResumeClaims" */
export type ResumeClaims_Stddev_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ResumeClaims_Stddev_Samp_Fields = {
  __typename?: 'ResumeClaims_stddev_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ResumeClaims" */
export type ResumeClaims_Stddev_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ResumeClaims" */
export type ResumeClaims_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ResumeClaims_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ResumeClaims_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ResumeClaims_Sum_Fields = {
  __typename?: 'ResumeClaims_sum_fields';
  identity_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ResumeClaims" */
export type ResumeClaims_Sum_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** update columns of table "ResumeClaims" */
export enum ResumeClaims_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ResumeClaims_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ResumeClaims_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResumeClaims_Set_Input>;
  /** filter the rows which have to be updated */
  where: ResumeClaims_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ResumeClaims_Var_Pop_Fields = {
  __typename?: 'ResumeClaims_var_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ResumeClaims" */
export type ResumeClaims_Var_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ResumeClaims_Var_Samp_Fields = {
  __typename?: 'ResumeClaims_var_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ResumeClaims" */
export type ResumeClaims_Var_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ResumeClaims_Variance_Fields = {
  __typename?: 'ResumeClaims_variance_fields';
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ResumeClaims" */
export type ResumeClaims_Variance_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ResumeEmailAddresses" */
export type ResumeEmailAddresses = {
  __typename?: 'ResumeEmailAddresses';
  created_at: Scalars['timestamptz'];
  normalized_email: Scalars['String'];
  resume_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Aggregate = {
  __typename?: 'ResumeEmailAddresses_aggregate';
  aggregate?: Maybe<ResumeEmailAddresses_Aggregate_Fields>;
  nodes: Array<ResumeEmailAddresses>;
};

/** aggregate fields of "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Aggregate_Fields = {
  __typename?: 'ResumeEmailAddresses_aggregate_fields';
  avg?: Maybe<ResumeEmailAddresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ResumeEmailAddresses_Max_Fields>;
  min?: Maybe<ResumeEmailAddresses_Min_Fields>;
  stddev?: Maybe<ResumeEmailAddresses_Stddev_Fields>;
  stddev_pop?: Maybe<ResumeEmailAddresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ResumeEmailAddresses_Stddev_Samp_Fields>;
  sum?: Maybe<ResumeEmailAddresses_Sum_Fields>;
  var_pop?: Maybe<ResumeEmailAddresses_Var_Pop_Fields>;
  var_samp?: Maybe<ResumeEmailAddresses_Var_Samp_Fields>;
  variance?: Maybe<ResumeEmailAddresses_Variance_Fields>;
};


/** aggregate fields of "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ResumeEmailAddresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ResumeEmailAddresses_Avg_Fields = {
  __typename?: 'ResumeEmailAddresses_avg_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ResumeEmailAddresses". All fields are combined with a logical 'AND'. */
export type ResumeEmailAddresses_Bool_Exp = {
  _and?: InputMaybe<Array<ResumeEmailAddresses_Bool_Exp>>;
  _not?: InputMaybe<ResumeEmailAddresses_Bool_Exp>;
  _or?: InputMaybe<Array<ResumeEmailAddresses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  normalized_email?: InputMaybe<String_Comparison_Exp>;
  resume_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ResumeEmailAddresses" */
export enum ResumeEmailAddresses_Constraint {
  /** unique or primary key constraint on columns "normalized_email", "resume_id" */
  ResumeEmailAddressesPkey = 'ResumeEmailAddresses_pkey'
}

/** input type for incrementing numeric columns in table "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Inc_Input = {
  resume_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  normalized_email?: InputMaybe<Scalars['String']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ResumeEmailAddresses_Max_Fields = {
  __typename?: 'ResumeEmailAddresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  normalized_email?: Maybe<Scalars['String']>;
  resume_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ResumeEmailAddresses_Min_Fields = {
  __typename?: 'ResumeEmailAddresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  normalized_email?: Maybe<Scalars['String']>;
  resume_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Mutation_Response = {
  __typename?: 'ResumeEmailAddresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ResumeEmailAddresses>;
};

/** on_conflict condition type for table "ResumeEmailAddresses" */
export type ResumeEmailAddresses_On_Conflict = {
  constraint: ResumeEmailAddresses_Constraint;
  update_columns?: Array<ResumeEmailAddresses_Update_Column>;
  where?: InputMaybe<ResumeEmailAddresses_Bool_Exp>;
};

/** Ordering options when selecting data from "ResumeEmailAddresses". */
export type ResumeEmailAddresses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  normalized_email?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ResumeEmailAddresses */
export type ResumeEmailAddresses_Pk_Columns_Input = {
  normalized_email: Scalars['String'];
  resume_id: Scalars['Int'];
};

/** select columns of table "ResumeEmailAddresses" */
export enum ResumeEmailAddresses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NormalizedEmail = 'normalized_email',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  normalized_email?: InputMaybe<Scalars['String']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ResumeEmailAddresses_Stddev_Fields = {
  __typename?: 'ResumeEmailAddresses_stddev_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ResumeEmailAddresses_Stddev_Pop_Fields = {
  __typename?: 'ResumeEmailAddresses_stddev_pop_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ResumeEmailAddresses_Stddev_Samp_Fields = {
  __typename?: 'ResumeEmailAddresses_stddev_samp_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ResumeEmailAddresses" */
export type ResumeEmailAddresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ResumeEmailAddresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ResumeEmailAddresses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  normalized_email?: InputMaybe<Scalars['String']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ResumeEmailAddresses_Sum_Fields = {
  __typename?: 'ResumeEmailAddresses_sum_fields';
  resume_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ResumeEmailAddresses" */
export enum ResumeEmailAddresses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NormalizedEmail = 'normalized_email',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ResumeEmailAddresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ResumeEmailAddresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResumeEmailAddresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: ResumeEmailAddresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ResumeEmailAddresses_Var_Pop_Fields = {
  __typename?: 'ResumeEmailAddresses_var_pop_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ResumeEmailAddresses_Var_Samp_Fields = {
  __typename?: 'ResumeEmailAddresses_var_samp_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ResumeEmailAddresses_Variance_Fields = {
  __typename?: 'ResumeEmailAddresses_variance_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ResumeIngestStatus" */
export type ResumeIngestStatus = {
  __typename?: 'ResumeIngestStatus';
  as_of: Scalars['timestamptz'];
  bucket: Scalars['String'];
  company_id: Scalars['Int'];
  data_hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  object_key: Scalars['String'];
  /** An object relationship */
  resume?: Maybe<Resumes>;
  status_code: Scalars['String'];
  status_detail: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ResumeIngestStatus" */
export type ResumeIngestStatus_Aggregate = {
  __typename?: 'ResumeIngestStatus_aggregate';
  aggregate?: Maybe<ResumeIngestStatus_Aggregate_Fields>;
  nodes: Array<ResumeIngestStatus>;
};

/** aggregate fields of "ResumeIngestStatus" */
export type ResumeIngestStatus_Aggregate_Fields = {
  __typename?: 'ResumeIngestStatus_aggregate_fields';
  avg?: Maybe<ResumeIngestStatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ResumeIngestStatus_Max_Fields>;
  min?: Maybe<ResumeIngestStatus_Min_Fields>;
  stddev?: Maybe<ResumeIngestStatus_Stddev_Fields>;
  stddev_pop?: Maybe<ResumeIngestStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ResumeIngestStatus_Stddev_Samp_Fields>;
  sum?: Maybe<ResumeIngestStatus_Sum_Fields>;
  var_pop?: Maybe<ResumeIngestStatus_Var_Pop_Fields>;
  var_samp?: Maybe<ResumeIngestStatus_Var_Samp_Fields>;
  variance?: Maybe<ResumeIngestStatus_Variance_Fields>;
};


/** aggregate fields of "ResumeIngestStatus" */
export type ResumeIngestStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ResumeIngestStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ResumeIngestStatus_Avg_Fields = {
  __typename?: 'ResumeIngestStatus_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ResumeIngestStatus". All fields are combined with a logical 'AND'. */
export type ResumeIngestStatus_Bool_Exp = {
  _and?: InputMaybe<Array<ResumeIngestStatus_Bool_Exp>>;
  _not?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
  _or?: InputMaybe<Array<ResumeIngestStatus_Bool_Exp>>;
  as_of?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  data_hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  object_key?: InputMaybe<String_Comparison_Exp>;
  resume?: InputMaybe<Resumes_Bool_Exp>;
  status_code?: InputMaybe<String_Comparison_Exp>;
  status_detail?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ResumeIngestStatus" */
export enum ResumeIngestStatus_Constraint {
  /** unique or primary key constraint on columns "object_key", "as_of", "bucket", "company_id", "data_hash" */
  ResumeIngestStatusPkey = 'ResumeIngestStatus_pkey',
  /** unique or primary key constraint on columns "id" */
  ResumeingeststatusPk = 'resumeingeststatus_pk'
}

/** input type for incrementing numeric columns in table "ResumeIngestStatus" */
export type ResumeIngestStatus_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ResumeIngestStatus" */
export type ResumeIngestStatus_Insert_Input = {
  as_of?: InputMaybe<Scalars['timestamptz']>;
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  data_hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  object_key?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Resumes_Obj_Rel_Insert_Input>;
  status_code?: InputMaybe<Scalars['String']>;
  status_detail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ResumeIngestStatus_Max_Fields = {
  __typename?: 'ResumeIngestStatus_max_fields';
  as_of?: Maybe<Scalars['timestamptz']>;
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  data_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  object_key?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['String']>;
  status_detail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ResumeIngestStatus_Min_Fields = {
  __typename?: 'ResumeIngestStatus_min_fields';
  as_of?: Maybe<Scalars['timestamptz']>;
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  data_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  object_key?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['String']>;
  status_detail?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ResumeIngestStatus" */
export type ResumeIngestStatus_Mutation_Response = {
  __typename?: 'ResumeIngestStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ResumeIngestStatus>;
};

/** input type for inserting object relation for remote table "ResumeIngestStatus" */
export type ResumeIngestStatus_Obj_Rel_Insert_Input = {
  data: ResumeIngestStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ResumeIngestStatus_On_Conflict>;
};

/** on_conflict condition type for table "ResumeIngestStatus" */
export type ResumeIngestStatus_On_Conflict = {
  constraint: ResumeIngestStatus_Constraint;
  update_columns?: Array<ResumeIngestStatus_Update_Column>;
  where?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "ResumeIngestStatus". */
export type ResumeIngestStatus_Order_By = {
  as_of?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  data_hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_key?: InputMaybe<Order_By>;
  resume?: InputMaybe<Resumes_Order_By>;
  status_code?: InputMaybe<Order_By>;
  status_detail?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ResumeIngestStatus */
export type ResumeIngestStatus_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ResumeIngestStatus" */
export enum ResumeIngestStatus_Select_Column {
  /** column name */
  AsOf = 'as_of',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  DataHash = 'data_hash',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectKey = 'object_key',
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  StatusDetail = 'status_detail',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ResumeIngestStatus" */
export type ResumeIngestStatus_Set_Input = {
  as_of?: InputMaybe<Scalars['timestamptz']>;
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  data_hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  object_key?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['String']>;
  status_detail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ResumeIngestStatus_Stddev_Fields = {
  __typename?: 'ResumeIngestStatus_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ResumeIngestStatus_Stddev_Pop_Fields = {
  __typename?: 'ResumeIngestStatus_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ResumeIngestStatus_Stddev_Samp_Fields = {
  __typename?: 'ResumeIngestStatus_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ResumeIngestStatus" */
export type ResumeIngestStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ResumeIngestStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ResumeIngestStatus_Stream_Cursor_Value_Input = {
  as_of?: InputMaybe<Scalars['timestamptz']>;
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  data_hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  object_key?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['String']>;
  status_detail?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ResumeIngestStatus_Sum_Fields = {
  __typename?: 'ResumeIngestStatus_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ResumeIngestStatus" */
export enum ResumeIngestStatus_Update_Column {
  /** column name */
  AsOf = 'as_of',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  DataHash = 'data_hash',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectKey = 'object_key',
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  StatusDetail = 'status_detail',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ResumeIngestStatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ResumeIngestStatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResumeIngestStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: ResumeIngestStatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ResumeIngestStatus_Var_Pop_Fields = {
  __typename?: 'ResumeIngestStatus_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ResumeIngestStatus_Var_Samp_Fields = {
  __typename?: 'ResumeIngestStatus_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ResumeIngestStatus_Variance_Fields = {
  __typename?: 'ResumeIngestStatus_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ResumePhoneNumbers" */
export type ResumePhoneNumbers = {
  __typename?: 'ResumePhoneNumbers';
  created_at: Scalars['timestamptz'];
  e164_form: Scalars['String'];
  resume_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Aggregate = {
  __typename?: 'ResumePhoneNumbers_aggregate';
  aggregate?: Maybe<ResumePhoneNumbers_Aggregate_Fields>;
  nodes: Array<ResumePhoneNumbers>;
};

/** aggregate fields of "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Aggregate_Fields = {
  __typename?: 'ResumePhoneNumbers_aggregate_fields';
  avg?: Maybe<ResumePhoneNumbers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ResumePhoneNumbers_Max_Fields>;
  min?: Maybe<ResumePhoneNumbers_Min_Fields>;
  stddev?: Maybe<ResumePhoneNumbers_Stddev_Fields>;
  stddev_pop?: Maybe<ResumePhoneNumbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ResumePhoneNumbers_Stddev_Samp_Fields>;
  sum?: Maybe<ResumePhoneNumbers_Sum_Fields>;
  var_pop?: Maybe<ResumePhoneNumbers_Var_Pop_Fields>;
  var_samp?: Maybe<ResumePhoneNumbers_Var_Samp_Fields>;
  variance?: Maybe<ResumePhoneNumbers_Variance_Fields>;
};


/** aggregate fields of "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ResumePhoneNumbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ResumePhoneNumbers_Avg_Fields = {
  __typename?: 'ResumePhoneNumbers_avg_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ResumePhoneNumbers". All fields are combined with a logical 'AND'. */
export type ResumePhoneNumbers_Bool_Exp = {
  _and?: InputMaybe<Array<ResumePhoneNumbers_Bool_Exp>>;
  _not?: InputMaybe<ResumePhoneNumbers_Bool_Exp>;
  _or?: InputMaybe<Array<ResumePhoneNumbers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  e164_form?: InputMaybe<String_Comparison_Exp>;
  resume_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ResumePhoneNumbers" */
export enum ResumePhoneNumbers_Constraint {
  /** unique or primary key constraint on columns "resume_id", "e164_form" */
  ResumePhoneNumbersPkey = 'ResumePhoneNumbers_pkey'
}

/** input type for incrementing numeric columns in table "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Inc_Input = {
  resume_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  e164_form?: InputMaybe<Scalars['String']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ResumePhoneNumbers_Max_Fields = {
  __typename?: 'ResumePhoneNumbers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  e164_form?: Maybe<Scalars['String']>;
  resume_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ResumePhoneNumbers_Min_Fields = {
  __typename?: 'ResumePhoneNumbers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  e164_form?: Maybe<Scalars['String']>;
  resume_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Mutation_Response = {
  __typename?: 'ResumePhoneNumbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ResumePhoneNumbers>;
};

/** on_conflict condition type for table "ResumePhoneNumbers" */
export type ResumePhoneNumbers_On_Conflict = {
  constraint: ResumePhoneNumbers_Constraint;
  update_columns?: Array<ResumePhoneNumbers_Update_Column>;
  where?: InputMaybe<ResumePhoneNumbers_Bool_Exp>;
};

/** Ordering options when selecting data from "ResumePhoneNumbers". */
export type ResumePhoneNumbers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  e164_form?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ResumePhoneNumbers */
export type ResumePhoneNumbers_Pk_Columns_Input = {
  e164_form: Scalars['String'];
  resume_id: Scalars['Int'];
};

/** select columns of table "ResumePhoneNumbers" */
export enum ResumePhoneNumbers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  E164Form = 'e164_form',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  e164_form?: InputMaybe<Scalars['String']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ResumePhoneNumbers_Stddev_Fields = {
  __typename?: 'ResumePhoneNumbers_stddev_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ResumePhoneNumbers_Stddev_Pop_Fields = {
  __typename?: 'ResumePhoneNumbers_stddev_pop_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ResumePhoneNumbers_Stddev_Samp_Fields = {
  __typename?: 'ResumePhoneNumbers_stddev_samp_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ResumePhoneNumbers" */
export type ResumePhoneNumbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ResumePhoneNumbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ResumePhoneNumbers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  e164_form?: InputMaybe<Scalars['String']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ResumePhoneNumbers_Sum_Fields = {
  __typename?: 'ResumePhoneNumbers_sum_fields';
  resume_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ResumePhoneNumbers" */
export enum ResumePhoneNumbers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  E164Form = 'e164_form',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ResumePhoneNumbers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ResumePhoneNumbers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResumePhoneNumbers_Set_Input>;
  /** filter the rows which have to be updated */
  where: ResumePhoneNumbers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ResumePhoneNumbers_Var_Pop_Fields = {
  __typename?: 'ResumePhoneNumbers_var_pop_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ResumePhoneNumbers_Var_Samp_Fields = {
  __typename?: 'ResumePhoneNumbers_var_samp_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ResumePhoneNumbers_Variance_Fields = {
  __typename?: 'ResumePhoneNumbers_variance_fields';
  resume_id?: Maybe<Scalars['Float']>;
};

export type ResumeUploadAction = {
  existing_identity_id?: InputMaybe<Scalars['Int']>;
  identity_to_insert?: InputMaybe<Scalars['String']>;
  target_resume_s3bucketkey: Scalars['String'];
};

export type ResumeUploadActionResult = {
  __typename?: 'ResumeUploadActionResult';
  Identity?: Maybe<Identities>;
  Resume?: Maybe<Resumes>;
  did_create_identity: Scalars['Boolean'];
  had_error: Scalars['Boolean'];
  is_duplicate?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  target_identity_id?: Maybe<Scalars['Int']>;
  target_resume_s3bucketkey: Scalars['String'];
};

/** columns and relationships of "Resumes" */
export type Resumes = {
  __typename?: 'Resumes';
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  CreatedBy?: Maybe<Identities>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An array relationship */
  ResumeClaims: Array<ResumeClaims>;
  /** An aggregate relationship */
  ResumeClaims_aggregate: ResumeClaims_Aggregate;
  /** An object relationship */
  Status?: Maybe<ResumeIngestStatus>;
  candidate_id?: Maybe<Scalars['Int']>;
  college_name?: Maybe<Array<Scalars['String']>>;
  company_id: Scalars['Int'];
  company_names?: Maybe<Array<Scalars['String']>>;
  comprehend?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  data_hash: Scalars['String'];
  degree?: Maybe<Array<Scalars['String']>>;
  designation?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  experience?: Maybe<Array<Scalars['String']>>;
  filename?: Maybe<Scalars['String']>;
  fts?: Maybe<Scalars['tsvector']>;
  html?: Maybe<Scalars['String']>;
  html_length?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  lastingested?: Maybe<Scalars['timestamp']>;
  location_geometry?: Maybe<Scalars['geometry']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  ner?: Maybe<Scalars['jsonb']>;
  no_of_pages?: Maybe<Scalars['Int']>;
  old_candidate_id?: Maybe<Scalars['Int']>;
  primary: Scalars['Boolean'];
  raw_location_txt?: Maybe<Scalars['String']>;
  raw_person_txt?: Maybe<Scalars['String']>;
  raw_text?: Maybe<Scalars['String']>;
  s3bucketkey?: Maybe<Scalars['String']>;
  s3bucketname?: Maybe<Scalars['String']>;
  s3bucketpdfkey?: Maybe<Scalars['String']>;
  s3metadata?: Maybe<Scalars['jsonb']>;
  s3tagging?: Maybe<Scalars['jsonb']>;
  skills?: Maybe<Array<Scalars['String']>>;
  total_experience?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "Resumes" */
export type ResumesResumeClaimsArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


/** columns and relationships of "Resumes" */
export type ResumesResumeClaims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


/** columns and relationships of "Resumes" */
export type ResumesComprehendArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Resumes" */
export type ResumesEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Resumes" */
export type ResumesNerArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Resumes" */
export type ResumesS3metadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Resumes" */
export type ResumesS3taggingArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Resumes" */
export type Resumes_Aggregate = {
  __typename?: 'Resumes_aggregate';
  aggregate?: Maybe<Resumes_Aggregate_Fields>;
  nodes: Array<Resumes>;
};

export type Resumes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Resumes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Resumes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Resumes_Aggregate_Bool_Exp_Count>;
};

export type Resumes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Resumes_Select_Column_Resumes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Resumes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Resumes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Resumes_Select_Column_Resumes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Resumes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Resumes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Resumes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Resumes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Resumes" */
export type Resumes_Aggregate_Fields = {
  __typename?: 'Resumes_aggregate_fields';
  avg?: Maybe<Resumes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Resumes_Max_Fields>;
  min?: Maybe<Resumes_Min_Fields>;
  stddev?: Maybe<Resumes_Stddev_Fields>;
  stddev_pop?: Maybe<Resumes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Resumes_Stddev_Samp_Fields>;
  sum?: Maybe<Resumes_Sum_Fields>;
  var_pop?: Maybe<Resumes_Var_Pop_Fields>;
  var_samp?: Maybe<Resumes_Var_Samp_Fields>;
  variance?: Maybe<Resumes_Variance_Fields>;
};


/** aggregate fields of "Resumes" */
export type Resumes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Resumes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Resumes" */
export type Resumes_Aggregate_Order_By = {
  avg?: InputMaybe<Resumes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Resumes_Max_Order_By>;
  min?: InputMaybe<Resumes_Min_Order_By>;
  stddev?: InputMaybe<Resumes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Resumes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Resumes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Resumes_Sum_Order_By>;
  var_pop?: InputMaybe<Resumes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Resumes_Var_Samp_Order_By>;
  variance?: InputMaybe<Resumes_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Resumes_Append_Input = {
  comprehend?: InputMaybe<Scalars['jsonb']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  ner?: InputMaybe<Scalars['jsonb']>;
  s3metadata?: InputMaybe<Scalars['jsonb']>;
  s3tagging?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Resumes" */
export type Resumes_Arr_Rel_Insert_Input = {
  data: Array<Resumes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Resumes_On_Conflict>;
};

/** aggregate avg on columns */
export type Resumes_Avg_Fields = {
  __typename?: 'Resumes_avg_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  html_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  no_of_pages?: Maybe<Scalars['Float']>;
  old_candidate_id?: Maybe<Scalars['Float']>;
  total_experience?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Resumes" */
export type Resumes_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Resumes". All fields are combined with a logical 'AND'. */
export type Resumes_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  ResumeClaims?: InputMaybe<ResumeClaims_Bool_Exp>;
  ResumeClaims_aggregate?: InputMaybe<ResumeClaims_Aggregate_Bool_Exp>;
  Status?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
  _and?: InputMaybe<Array<Resumes_Bool_Exp>>;
  _not?: InputMaybe<Resumes_Bool_Exp>;
  _or?: InputMaybe<Array<Resumes_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  college_name?: InputMaybe<String_Array_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_names?: InputMaybe<String_Array_Comparison_Exp>;
  comprehend?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  data_hash?: InputMaybe<String_Comparison_Exp>;
  degree?: InputMaybe<String_Array_Comparison_Exp>;
  designation?: InputMaybe<String_Array_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  etl_source_entity?: InputMaybe<String_Comparison_Exp>;
  experience?: InputMaybe<String_Array_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  fts?: InputMaybe<Tsvector_Comparison_Exp>;
  html?: InputMaybe<String_Comparison_Exp>;
  html_length?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastingested?: InputMaybe<Timestamp_Comparison_Exp>;
  location_geometry?: InputMaybe<Geometry_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  ner?: InputMaybe<Jsonb_Comparison_Exp>;
  no_of_pages?: InputMaybe<Int_Comparison_Exp>;
  old_candidate_id?: InputMaybe<Int_Comparison_Exp>;
  primary?: InputMaybe<Boolean_Comparison_Exp>;
  raw_location_txt?: InputMaybe<String_Comparison_Exp>;
  raw_person_txt?: InputMaybe<String_Comparison_Exp>;
  raw_text?: InputMaybe<String_Comparison_Exp>;
  s3bucketkey?: InputMaybe<String_Comparison_Exp>;
  s3bucketname?: InputMaybe<String_Comparison_Exp>;
  s3bucketpdfkey?: InputMaybe<String_Comparison_Exp>;
  s3metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  s3tagging?: InputMaybe<Jsonb_Comparison_Exp>;
  skills?: InputMaybe<String_Array_Comparison_Exp>;
  total_experience?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Resumes" */
export enum Resumes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ResumesIdKey = 'Resumes_id_key',
  /** unique or primary key constraint on columns "company_id", "data_hash" */
  ResumesPkey = 'Resumes_pkey',
  /** unique or primary key constraint on columns "s3bucketkey" */
  ResumesS3bucketkeyKey = 'Resumes_s3bucketkey_key',
  /** unique or primary key constraint on columns "company_id", "data_hash" */
  CidDhUnique = 'cid_dh_unique',
  /** unique or primary key constraint on columns "etl_id_token" */
  ResumesUniqueEtlIdToken = 'resumes_unique_etl_id_token'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Resumes_Delete_At_Path_Input = {
  comprehend?: InputMaybe<Array<Scalars['String']>>;
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
  ner?: InputMaybe<Array<Scalars['String']>>;
  s3metadata?: InputMaybe<Array<Scalars['String']>>;
  s3tagging?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Resumes_Delete_Elem_Input = {
  comprehend?: InputMaybe<Scalars['Int']>;
  etl_original_object?: InputMaybe<Scalars['Int']>;
  ner?: InputMaybe<Scalars['Int']>;
  s3metadata?: InputMaybe<Scalars['Int']>;
  s3tagging?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Resumes_Delete_Key_Input = {
  comprehend?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  ner?: InputMaybe<Scalars['String']>;
  s3metadata?: InputMaybe<Scalars['String']>;
  s3tagging?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Resumes" */
export type Resumes_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  no_of_pages?: InputMaybe<Scalars['Int']>;
  old_candidate_id?: InputMaybe<Scalars['Int']>;
  total_experience?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Resumes" */
export type Resumes_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  ResumeClaims?: InputMaybe<ResumeClaims_Arr_Rel_Insert_Input>;
  Status?: InputMaybe<ResumeIngestStatus_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  college_name?: InputMaybe<Array<Scalars['String']>>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_names?: InputMaybe<Array<Scalars['String']>>;
  comprehend?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  data_hash?: InputMaybe<Scalars['String']>;
  degree?: InputMaybe<Array<Scalars['String']>>;
  designation?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Array<Scalars['String']>>;
  filename?: InputMaybe<Scalars['String']>;
  fts?: InputMaybe<Scalars['tsvector']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastingested?: InputMaybe<Scalars['timestamp']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  ner?: InputMaybe<Scalars['jsonb']>;
  no_of_pages?: InputMaybe<Scalars['Int']>;
  old_candidate_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  raw_location_txt?: InputMaybe<Scalars['String']>;
  raw_person_txt?: InputMaybe<Scalars['String']>;
  raw_text?: InputMaybe<Scalars['String']>;
  s3bucketkey?: InputMaybe<Scalars['String']>;
  s3bucketname?: InputMaybe<Scalars['String']>;
  s3bucketpdfkey?: InputMaybe<Scalars['String']>;
  s3metadata?: InputMaybe<Scalars['jsonb']>;
  s3tagging?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Array<Scalars['String']>>;
  total_experience?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Resumes_Max_Fields = {
  __typename?: 'Resumes_max_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  college_name?: Maybe<Array<Scalars['String']>>;
  company_id?: Maybe<Scalars['Int']>;
  company_names?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  data_hash?: Maybe<Scalars['String']>;
  degree?: Maybe<Array<Scalars['String']>>;
  designation?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  experience?: Maybe<Array<Scalars['String']>>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  html_length?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  lastingested?: Maybe<Scalars['timestamp']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  no_of_pages?: Maybe<Scalars['Int']>;
  old_candidate_id?: Maybe<Scalars['Int']>;
  raw_location_txt?: Maybe<Scalars['String']>;
  raw_person_txt?: Maybe<Scalars['String']>;
  raw_text?: Maybe<Scalars['String']>;
  s3bucketkey?: Maybe<Scalars['String']>;
  s3bucketname?: Maybe<Scalars['String']>;
  s3bucketpdfkey?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['String']>>;
  total_experience?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Resumes" */
export type Resumes_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  college_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_names?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  data_hash?: InputMaybe<Order_By>;
  degree?: InputMaybe<Order_By>;
  designation?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  experience?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastingested?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  raw_location_txt?: InputMaybe<Order_By>;
  raw_person_txt?: InputMaybe<Order_By>;
  raw_text?: InputMaybe<Order_By>;
  s3bucketkey?: InputMaybe<Order_By>;
  s3bucketname?: InputMaybe<Order_By>;
  s3bucketpdfkey?: InputMaybe<Order_By>;
  skills?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Resumes_Min_Fields = {
  __typename?: 'Resumes_min_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  college_name?: Maybe<Array<Scalars['String']>>;
  company_id?: Maybe<Scalars['Int']>;
  company_names?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  data_hash?: Maybe<Scalars['String']>;
  degree?: Maybe<Array<Scalars['String']>>;
  designation?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  experience?: Maybe<Array<Scalars['String']>>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  html_length?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  lastingested?: Maybe<Scalars['timestamp']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  no_of_pages?: Maybe<Scalars['Int']>;
  old_candidate_id?: Maybe<Scalars['Int']>;
  raw_location_txt?: Maybe<Scalars['String']>;
  raw_person_txt?: Maybe<Scalars['String']>;
  raw_text?: Maybe<Scalars['String']>;
  s3bucketkey?: Maybe<Scalars['String']>;
  s3bucketname?: Maybe<Scalars['String']>;
  s3bucketpdfkey?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['String']>>;
  total_experience?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Resumes" */
export type Resumes_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  college_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_names?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  data_hash?: InputMaybe<Order_By>;
  degree?: InputMaybe<Order_By>;
  designation?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  experience?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastingested?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  raw_location_txt?: InputMaybe<Order_By>;
  raw_person_txt?: InputMaybe<Order_By>;
  raw_text?: InputMaybe<Order_By>;
  s3bucketkey?: InputMaybe<Order_By>;
  s3bucketname?: InputMaybe<Order_By>;
  s3bucketpdfkey?: InputMaybe<Order_By>;
  skills?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Resumes" */
export type Resumes_Mutation_Response = {
  __typename?: 'Resumes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resumes>;
};

/** input type for inserting object relation for remote table "Resumes" */
export type Resumes_Obj_Rel_Insert_Input = {
  data: Resumes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Resumes_On_Conflict>;
};

/** on_conflict condition type for table "Resumes" */
export type Resumes_On_Conflict = {
  constraint: Resumes_Constraint;
  update_columns?: Array<Resumes_Update_Column>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};

/** Ordering options when selecting data from "Resumes". */
export type Resumes_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  ResumeClaims_aggregate?: InputMaybe<ResumeClaims_Aggregate_Order_By>;
  Status?: InputMaybe<ResumeIngestStatus_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  college_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_names?: InputMaybe<Order_By>;
  comprehend?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  data_hash?: InputMaybe<Order_By>;
  degree?: InputMaybe<Order_By>;
  designation?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  experience?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  fts?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastingested?: InputMaybe<Order_By>;
  location_geometry?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  ner?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  primary?: InputMaybe<Order_By>;
  raw_location_txt?: InputMaybe<Order_By>;
  raw_person_txt?: InputMaybe<Order_By>;
  raw_text?: InputMaybe<Order_By>;
  s3bucketkey?: InputMaybe<Order_By>;
  s3bucketname?: InputMaybe<Order_By>;
  s3bucketpdfkey?: InputMaybe<Order_By>;
  s3metadata?: InputMaybe<Order_By>;
  s3tagging?: InputMaybe<Order_By>;
  skills?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Resumes */
export type Resumes_Pk_Columns_Input = {
  company_id: Scalars['Int'];
  data_hash: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Resumes_Prepend_Input = {
  comprehend?: InputMaybe<Scalars['jsonb']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  ner?: InputMaybe<Scalars['jsonb']>;
  s3metadata?: InputMaybe<Scalars['jsonb']>;
  s3tagging?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Resumes" */
export enum Resumes_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CollegeName = 'college_name',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyNames = 'company_names',
  /** column name */
  Comprehend = 'comprehend',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DataHash = 'data_hash',
  /** column name */
  Degree = 'degree',
  /** column name */
  Designation = 'designation',
  /** column name */
  Email = 'email',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  Experience = 'experience',
  /** column name */
  Filename = 'filename',
  /** column name */
  Fts = 'fts',
  /** column name */
  Html = 'html',
  /** column name */
  HtmlLength = 'html_length',
  /** column name */
  Id = 'id',
  /** column name */
  Lastingested = 'lastingested',
  /** column name */
  LocationGeometry = 'location_geometry',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  Ner = 'ner',
  /** column name */
  NoOfPages = 'no_of_pages',
  /** column name */
  OldCandidateId = 'old_candidate_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  RawLocationTxt = 'raw_location_txt',
  /** column name */
  RawPersonTxt = 'raw_person_txt',
  /** column name */
  RawText = 'raw_text',
  /** column name */
  S3bucketkey = 's3bucketkey',
  /** column name */
  S3bucketname = 's3bucketname',
  /** column name */
  S3bucketpdfkey = 's3bucketpdfkey',
  /** column name */
  S3metadata = 's3metadata',
  /** column name */
  S3tagging = 's3tagging',
  /** column name */
  Skills = 'skills',
  /** column name */
  TotalExperience = 'total_experience',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Resumes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Resumes" */
export enum Resumes_Select_Column_Resumes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Primary = 'primary'
}

/** select "Resumes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Resumes" */
export enum Resumes_Select_Column_Resumes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Primary = 'primary'
}

/** input type for updating data in table "Resumes" */
export type Resumes_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  college_name?: InputMaybe<Array<Scalars['String']>>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_names?: InputMaybe<Array<Scalars['String']>>;
  comprehend?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  data_hash?: InputMaybe<Scalars['String']>;
  degree?: InputMaybe<Array<Scalars['String']>>;
  designation?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Array<Scalars['String']>>;
  filename?: InputMaybe<Scalars['String']>;
  fts?: InputMaybe<Scalars['tsvector']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastingested?: InputMaybe<Scalars['timestamp']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  ner?: InputMaybe<Scalars['jsonb']>;
  no_of_pages?: InputMaybe<Scalars['Int']>;
  old_candidate_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  raw_location_txt?: InputMaybe<Scalars['String']>;
  raw_person_txt?: InputMaybe<Scalars['String']>;
  raw_text?: InputMaybe<Scalars['String']>;
  s3bucketkey?: InputMaybe<Scalars['String']>;
  s3bucketname?: InputMaybe<Scalars['String']>;
  s3bucketpdfkey?: InputMaybe<Scalars['String']>;
  s3metadata?: InputMaybe<Scalars['jsonb']>;
  s3tagging?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Array<Scalars['String']>>;
  total_experience?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Resumes_Stddev_Fields = {
  __typename?: 'Resumes_stddev_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  html_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  no_of_pages?: Maybe<Scalars['Float']>;
  old_candidate_id?: Maybe<Scalars['Float']>;
  total_experience?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Resumes" */
export type Resumes_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Resumes_Stddev_Pop_Fields = {
  __typename?: 'Resumes_stddev_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  html_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  no_of_pages?: Maybe<Scalars['Float']>;
  old_candidate_id?: Maybe<Scalars['Float']>;
  total_experience?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Resumes" */
export type Resumes_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Resumes_Stddev_Samp_Fields = {
  __typename?: 'Resumes_stddev_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  html_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  no_of_pages?: Maybe<Scalars['Float']>;
  old_candidate_id?: Maybe<Scalars['Float']>;
  total_experience?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Resumes" */
export type Resumes_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Resumes" */
export type Resumes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Resumes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Resumes_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  college_name?: InputMaybe<Array<Scalars['String']>>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_names?: InputMaybe<Array<Scalars['String']>>;
  comprehend?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  data_hash?: InputMaybe<Scalars['String']>;
  degree?: InputMaybe<Array<Scalars['String']>>;
  designation?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Array<Scalars['String']>>;
  filename?: InputMaybe<Scalars['String']>;
  fts?: InputMaybe<Scalars['tsvector']>;
  html?: InputMaybe<Scalars['String']>;
  html_length?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  lastingested?: InputMaybe<Scalars['timestamp']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  ner?: InputMaybe<Scalars['jsonb']>;
  no_of_pages?: InputMaybe<Scalars['Int']>;
  old_candidate_id?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  raw_location_txt?: InputMaybe<Scalars['String']>;
  raw_person_txt?: InputMaybe<Scalars['String']>;
  raw_text?: InputMaybe<Scalars['String']>;
  s3bucketkey?: InputMaybe<Scalars['String']>;
  s3bucketname?: InputMaybe<Scalars['String']>;
  s3bucketpdfkey?: InputMaybe<Scalars['String']>;
  s3metadata?: InputMaybe<Scalars['jsonb']>;
  s3tagging?: InputMaybe<Scalars['jsonb']>;
  skills?: InputMaybe<Array<Scalars['String']>>;
  total_experience?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Resumes_Sum_Fields = {
  __typename?: 'Resumes_sum_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  html_length?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  no_of_pages?: Maybe<Scalars['Int']>;
  old_candidate_id?: Maybe<Scalars['Int']>;
  total_experience?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Resumes" */
export type Resumes_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** update columns of table "Resumes" */
export enum Resumes_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CollegeName = 'college_name',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyNames = 'company_names',
  /** column name */
  Comprehend = 'comprehend',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DataHash = 'data_hash',
  /** column name */
  Degree = 'degree',
  /** column name */
  Designation = 'designation',
  /** column name */
  Email = 'email',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  Experience = 'experience',
  /** column name */
  Filename = 'filename',
  /** column name */
  Fts = 'fts',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Lastingested = 'lastingested',
  /** column name */
  LocationGeometry = 'location_geometry',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  Ner = 'ner',
  /** column name */
  NoOfPages = 'no_of_pages',
  /** column name */
  OldCandidateId = 'old_candidate_id',
  /** column name */
  Primary = 'primary',
  /** column name */
  RawLocationTxt = 'raw_location_txt',
  /** column name */
  RawPersonTxt = 'raw_person_txt',
  /** column name */
  RawText = 'raw_text',
  /** column name */
  S3bucketkey = 's3bucketkey',
  /** column name */
  S3bucketname = 's3bucketname',
  /** column name */
  S3bucketpdfkey = 's3bucketpdfkey',
  /** column name */
  S3metadata = 's3metadata',
  /** column name */
  S3tagging = 's3tagging',
  /** column name */
  Skills = 'skills',
  /** column name */
  TotalExperience = 'total_experience',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Resumes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Resumes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Resumes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Resumes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Resumes_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Resumes_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Resumes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Resumes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Resumes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Resumes_Var_Pop_Fields = {
  __typename?: 'Resumes_var_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  html_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  no_of_pages?: Maybe<Scalars['Float']>;
  old_candidate_id?: Maybe<Scalars['Float']>;
  total_experience?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Resumes" */
export type Resumes_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Resumes_Var_Samp_Fields = {
  __typename?: 'Resumes_var_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  html_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  no_of_pages?: Maybe<Scalars['Float']>;
  old_candidate_id?: Maybe<Scalars['Float']>;
  total_experience?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Resumes" */
export type Resumes_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Resumes_Variance_Fields = {
  __typename?: 'Resumes_variance_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  html_length?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  no_of_pages?: Maybe<Scalars['Float']>;
  old_candidate_id?: Maybe<Scalars['Float']>;
  total_experience?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Resumes" */
export type Resumes_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  html_length?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  no_of_pages?: InputMaybe<Order_By>;
  old_candidate_id?: InputMaybe<Order_By>;
  total_experience?: InputMaybe<Order_By>;
};

/** columns and relationships of "ReviewCriteria" */
export type ReviewCriteria = {
  __typename?: 'ReviewCriteria';
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  CreatedBy: Identities;
  /** An array relationship */
  ReviewCriteriaScores: Array<ReviewCriteriaScore>;
  /** An aggregate relationship */
  ReviewCriteriaScores_aggregate: ReviewCriteriaScore_Aggregate;
  /** An array relationship */
  ReviewGroupCriteria: Array<ReviewGroupCriteria>;
  /** An aggregate relationship */
  ReviewGroupCriteria_aggregate: ReviewGroupCriteria_Aggregate;
  /** An object relationship */
  UpdatedBy: Identities;
  archived: Scalars['Boolean'];
  company_default: Scalars['Boolean'];
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "ReviewCriteria" */
export type ReviewCriteriaReviewCriteriaScoresArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


/** columns and relationships of "ReviewCriteria" */
export type ReviewCriteriaReviewCriteriaScores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


/** columns and relationships of "ReviewCriteria" */
export type ReviewCriteriaReviewGroupCriteriaArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


/** columns and relationships of "ReviewCriteria" */
export type ReviewCriteriaReviewGroupCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};

/** Score for a specific criteria on a particular review */
export type ReviewCriteriaScore = {
  __typename?: 'ReviewCriteriaScore';
  /** An object relationship */
  CreatedBy: Identities;
  /** An object relationship */
  Review: Reviews;
  /** An object relationship */
  ReviewCriteria: ReviewCriteria;
  /** An object relationship */
  UpdatedBy: Identities;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  review_criteria_id: Scalars['uuid'];
  review_id: Scalars['Int'];
  score: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};

/** columns and relationships of "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates = {
  __typename?: 'ReviewCriteriaScoreAggregates';
  /** An object relationship */
  ReviewCriteria?: Maybe<ReviewCriteria>;
  /** An object relationship */
  ReviewGroup?: Maybe<ReviewGroups>;
  avg?: Maybe<Scalars['numeric']>;
  review_criteria_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Aggregate = {
  __typename?: 'ReviewCriteriaScoreAggregates_aggregate';
  aggregate?: Maybe<ReviewCriteriaScoreAggregates_Aggregate_Fields>;
  nodes: Array<ReviewCriteriaScoreAggregates>;
};

export type ReviewCriteriaScoreAggregates_Aggregate_Bool_Exp = {
  count?: InputMaybe<ReviewCriteriaScoreAggregates_Aggregate_Bool_Exp_Count>;
};

export type ReviewCriteriaScoreAggregates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Aggregate_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_aggregate_fields';
  avg?: Maybe<ReviewCriteriaScoreAggregates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewCriteriaScoreAggregates_Max_Fields>;
  min?: Maybe<ReviewCriteriaScoreAggregates_Min_Fields>;
  stddev?: Maybe<ReviewCriteriaScoreAggregates_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewCriteriaScoreAggregates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewCriteriaScoreAggregates_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewCriteriaScoreAggregates_Sum_Fields>;
  var_pop?: Maybe<ReviewCriteriaScoreAggregates_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewCriteriaScoreAggregates_Var_Samp_Fields>;
  variance?: Maybe<ReviewCriteriaScoreAggregates_Variance_Fields>;
};


/** aggregate fields of "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Aggregate_Order_By = {
  avg?: InputMaybe<ReviewCriteriaScoreAggregates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReviewCriteriaScoreAggregates_Max_Order_By>;
  min?: InputMaybe<ReviewCriteriaScoreAggregates_Min_Order_By>;
  stddev?: InputMaybe<ReviewCriteriaScoreAggregates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReviewCriteriaScoreAggregates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReviewCriteriaScoreAggregates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReviewCriteriaScoreAggregates_Sum_Order_By>;
  var_pop?: InputMaybe<ReviewCriteriaScoreAggregates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReviewCriteriaScoreAggregates_Var_Samp_Order_By>;
  variance?: InputMaybe<ReviewCriteriaScoreAggregates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Arr_Rel_Insert_Input = {
  data: Array<ReviewCriteriaScoreAggregates_Insert_Input>;
};

/** aggregate avg on columns */
export type ReviewCriteriaScoreAggregates_Avg_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_avg_fields';
  avg?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Avg_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewCriteriaScoreAggregates". All fields are combined with a logical 'AND'. */
export type ReviewCriteriaScoreAggregates_Bool_Exp = {
  ReviewCriteria?: InputMaybe<ReviewCriteria_Bool_Exp>;
  ReviewGroup?: InputMaybe<ReviewGroups_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Bool_Exp>>;
  _not?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Bool_Exp>>;
  avg?: InputMaybe<Numeric_Comparison_Exp>;
  review_criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  review_group_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Insert_Input = {
  ReviewCriteria?: InputMaybe<ReviewCriteria_Obj_Rel_Insert_Input>;
  ReviewGroup?: InputMaybe<ReviewGroups_Obj_Rel_Insert_Input>;
  avg?: InputMaybe<Scalars['numeric']>;
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ReviewCriteriaScoreAggregates_Max_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_max_fields';
  avg?: Maybe<Scalars['numeric']>;
  review_criteria_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Max_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_criteria_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewCriteriaScoreAggregates_Min_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_min_fields';
  avg?: Maybe<Scalars['numeric']>;
  review_criteria_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Min_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_criteria_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ReviewCriteriaScoreAggregates". */
export type ReviewCriteriaScoreAggregates_Order_By = {
  ReviewCriteria?: InputMaybe<ReviewCriteria_Order_By>;
  ReviewGroup?: InputMaybe<ReviewGroups_Order_By>;
  avg?: InputMaybe<Order_By>;
  review_criteria_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** select columns of table "ReviewCriteriaScoreAggregates" */
export enum ReviewCriteriaScoreAggregates_Select_Column {
  /** column name */
  Avg = 'avg',
  /** column name */
  ReviewCriteriaId = 'review_criteria_id',
  /** column name */
  ReviewGroupId = 'review_group_id'
}

/** aggregate stddev on columns */
export type ReviewCriteriaScoreAggregates_Stddev_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_stddev_fields';
  avg?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Stddev_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewCriteriaScoreAggregates_Stddev_Pop_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_stddev_pop_fields';
  avg?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Stddev_Pop_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewCriteriaScoreAggregates_Stddev_Samp_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_stddev_samp_fields';
  avg?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Stddev_Samp_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewCriteriaScoreAggregates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewCriteriaScoreAggregates_Stream_Cursor_Value_Input = {
  avg?: InputMaybe<Scalars['numeric']>;
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ReviewCriteriaScoreAggregates_Sum_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_sum_fields';
  avg?: Maybe<Scalars['numeric']>;
  review_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Sum_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ReviewCriteriaScoreAggregates_Var_Pop_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_var_pop_fields';
  avg?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Var_Pop_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewCriteriaScoreAggregates_Var_Samp_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_var_samp_fields';
  avg?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Var_Samp_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewCriteriaScoreAggregates_Variance_Fields = {
  __typename?: 'ReviewCriteriaScoreAggregates_variance_fields';
  avg?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewCriteriaScoreAggregates" */
export type ReviewCriteriaScoreAggregates_Variance_Order_By = {
  avg?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Aggregate = {
  __typename?: 'ReviewCriteriaScore_aggregate';
  aggregate?: Maybe<ReviewCriteriaScore_Aggregate_Fields>;
  nodes: Array<ReviewCriteriaScore>;
};

export type ReviewCriteriaScore_Aggregate_Bool_Exp = {
  count?: InputMaybe<ReviewCriteriaScore_Aggregate_Bool_Exp_Count>;
};

export type ReviewCriteriaScore_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Aggregate_Fields = {
  __typename?: 'ReviewCriteriaScore_aggregate_fields';
  avg?: Maybe<ReviewCriteriaScore_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewCriteriaScore_Max_Fields>;
  min?: Maybe<ReviewCriteriaScore_Min_Fields>;
  stddev?: Maybe<ReviewCriteriaScore_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewCriteriaScore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewCriteriaScore_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewCriteriaScore_Sum_Fields>;
  var_pop?: Maybe<ReviewCriteriaScore_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewCriteriaScore_Var_Samp_Fields>;
  variance?: Maybe<ReviewCriteriaScore_Variance_Fields>;
};


/** aggregate fields of "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Aggregate_Order_By = {
  avg?: InputMaybe<ReviewCriteriaScore_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReviewCriteriaScore_Max_Order_By>;
  min?: InputMaybe<ReviewCriteriaScore_Min_Order_By>;
  stddev?: InputMaybe<ReviewCriteriaScore_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReviewCriteriaScore_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReviewCriteriaScore_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReviewCriteriaScore_Sum_Order_By>;
  var_pop?: InputMaybe<ReviewCriteriaScore_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReviewCriteriaScore_Var_Samp_Order_By>;
  variance?: InputMaybe<ReviewCriteriaScore_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Arr_Rel_Insert_Input = {
  data: Array<ReviewCriteriaScore_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewCriteriaScore_On_Conflict>;
};

/** aggregate avg on columns */
export type ReviewCriteriaScore_Avg_Fields = {
  __typename?: 'ReviewCriteriaScore_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewCriteriaScore". All fields are combined with a logical 'AND'. */
export type ReviewCriteriaScore_Bool_Exp = {
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  Review?: InputMaybe<Reviews_Bool_Exp>;
  ReviewCriteria?: InputMaybe<ReviewCriteria_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewCriteriaScore_Bool_Exp>>;
  _not?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewCriteriaScore_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  review_criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  review_id?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewCriteriaScore" */
export enum ReviewCriteriaScore_Constraint {
  /** unique or primary key constraint on columns "review_id", "review_criteria_id" */
  ReviewCriteriaScorePkey = 'ReviewCriteriaScore_pkey'
}

/** input type for incrementing numeric columns in table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Insert_Input = {
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Review?: InputMaybe<Reviews_Obj_Rel_Insert_Input>;
  ReviewCriteria?: InputMaybe<ReviewCriteria_Obj_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ReviewCriteriaScore_Max_Fields = {
  __typename?: 'ReviewCriteriaScore_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  review_criteria_id?: Maybe<Scalars['uuid']>;
  review_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  review_criteria_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewCriteriaScore_Min_Fields = {
  __typename?: 'ReviewCriteriaScore_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  review_criteria_id?: Maybe<Scalars['uuid']>;
  review_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  review_criteria_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Mutation_Response = {
  __typename?: 'ReviewCriteriaScore_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewCriteriaScore>;
};

/** on_conflict condition type for table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_On_Conflict = {
  constraint: ReviewCriteriaScore_Constraint;
  update_columns?: Array<ReviewCriteriaScore_Update_Column>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewCriteriaScore". */
export type ReviewCriteriaScore_Order_By = {
  CreatedBy?: InputMaybe<Identities_Order_By>;
  Review?: InputMaybe<Reviews_Order_By>;
  ReviewCriteria?: InputMaybe<ReviewCriteria_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  review_criteria_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewCriteriaScore */
export type ReviewCriteriaScore_Pk_Columns_Input = {
  review_criteria_id: Scalars['uuid'];
  review_id: Scalars['Int'];
};

/** select columns of table "ReviewCriteriaScore" */
export enum ReviewCriteriaScore_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ReviewCriteriaId = 'review_criteria_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  Score = 'score',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ReviewCriteriaScore_Stddev_Fields = {
  __typename?: 'ReviewCriteriaScore_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewCriteriaScore_Stddev_Pop_Fields = {
  __typename?: 'ReviewCriteriaScore_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewCriteriaScore_Stddev_Samp_Fields = {
  __typename?: 'ReviewCriteriaScore_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewCriteriaScore_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewCriteriaScore_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ReviewCriteriaScore_Sum_Fields = {
  __typename?: 'ReviewCriteriaScore_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  review_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "ReviewCriteriaScore" */
export enum ReviewCriteriaScore_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ReviewCriteriaId = 'review_criteria_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  Score = 'score',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type ReviewCriteriaScore_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewCriteriaScore_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewCriteriaScore_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewCriteriaScore_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReviewCriteriaScore_Var_Pop_Fields = {
  __typename?: 'ReviewCriteriaScore_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewCriteriaScore_Var_Samp_Fields = {
  __typename?: 'ReviewCriteriaScore_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewCriteriaScore_Variance_Fields = {
  __typename?: 'ReviewCriteriaScore_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewCriteriaScore" */
export type ReviewCriteriaScore_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregated selection of "ReviewCriteria" */
export type ReviewCriteria_Aggregate = {
  __typename?: 'ReviewCriteria_aggregate';
  aggregate?: Maybe<ReviewCriteria_Aggregate_Fields>;
  nodes: Array<ReviewCriteria>;
};

/** aggregate fields of "ReviewCriteria" */
export type ReviewCriteria_Aggregate_Fields = {
  __typename?: 'ReviewCriteria_aggregate_fields';
  avg?: Maybe<ReviewCriteria_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewCriteria_Max_Fields>;
  min?: Maybe<ReviewCriteria_Min_Fields>;
  stddev?: Maybe<ReviewCriteria_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewCriteria_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewCriteria_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewCriteria_Sum_Fields>;
  var_pop?: Maybe<ReviewCriteria_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewCriteria_Var_Samp_Fields>;
  variance?: Maybe<ReviewCriteria_Variance_Fields>;
};


/** aggregate fields of "ReviewCriteria" */
export type ReviewCriteria_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewCriteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ReviewCriteria_Avg_Fields = {
  __typename?: 'ReviewCriteria_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ReviewCriteria". All fields are combined with a logical 'AND'. */
export type ReviewCriteria_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  ReviewCriteriaScores?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
  ReviewCriteriaScores_aggregate?: InputMaybe<ReviewCriteriaScore_Aggregate_Bool_Exp>;
  ReviewGroupCriteria?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
  ReviewGroupCriteria_aggregate?: InputMaybe<ReviewGroupCriteria_Aggregate_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewCriteria_Bool_Exp>>;
  _not?: InputMaybe<ReviewCriteria_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewCriteria_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  company_default?: InputMaybe<Boolean_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  required?: InputMaybe<Boolean_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewCriteria" */
export enum ReviewCriteria_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewCriteriaPkey = 'ReviewCriteria_pkey'
}

/** input type for incrementing numeric columns in table "ReviewCriteria" */
export type ReviewCriteria_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewCriteria" */
export type ReviewCriteria_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  ReviewCriteriaScores?: InputMaybe<ReviewCriteriaScore_Arr_Rel_Insert_Input>;
  ReviewGroupCriteria?: InputMaybe<ReviewGroupCriteria_Arr_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  archived?: InputMaybe<Scalars['Boolean']>;
  company_default?: InputMaybe<Scalars['Boolean']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ReviewCriteria_Max_Fields = {
  __typename?: 'ReviewCriteria_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ReviewCriteria_Min_Fields = {
  __typename?: 'ReviewCriteria_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ReviewCriteria" */
export type ReviewCriteria_Mutation_Response = {
  __typename?: 'ReviewCriteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewCriteria>;
};

/** input type for inserting object relation for remote table "ReviewCriteria" */
export type ReviewCriteria_Obj_Rel_Insert_Input = {
  data: ReviewCriteria_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewCriteria_On_Conflict>;
};

/** on_conflict condition type for table "ReviewCriteria" */
export type ReviewCriteria_On_Conflict = {
  constraint: ReviewCriteria_Constraint;
  update_columns?: Array<ReviewCriteria_Update_Column>;
  where?: InputMaybe<ReviewCriteria_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewCriteria". */
export type ReviewCriteria_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  ReviewCriteriaScores_aggregate?: InputMaybe<ReviewCriteriaScore_Aggregate_Order_By>;
  ReviewGroupCriteria_aggregate?: InputMaybe<ReviewGroupCriteria_Aggregate_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  archived?: InputMaybe<Order_By>;
  company_default?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  required?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewCriteria */
export type ReviewCriteria_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ReviewCriteria" */
export enum ReviewCriteria_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CompanyDefault = 'company_default',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Required = 'required',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ReviewCriteria" */
export type ReviewCriteria_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  company_default?: InputMaybe<Scalars['Boolean']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ReviewCriteria_Stddev_Fields = {
  __typename?: 'ReviewCriteria_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ReviewCriteria_Stddev_Pop_Fields = {
  __typename?: 'ReviewCriteria_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ReviewCriteria_Stddev_Samp_Fields = {
  __typename?: 'ReviewCriteria_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ReviewCriteria" */
export type ReviewCriteria_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewCriteria_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewCriteria_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  company_default?: InputMaybe<Scalars['Boolean']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ReviewCriteria_Sum_Fields = {
  __typename?: 'ReviewCriteria_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "ReviewCriteria" */
export enum ReviewCriteria_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CompanyDefault = 'company_default',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Required = 'required',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type ReviewCriteria_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewCriteria_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewCriteria_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewCriteria_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReviewCriteria_Var_Pop_Fields = {
  __typename?: 'ReviewCriteria_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ReviewCriteria_Var_Samp_Fields = {
  __typename?: 'ReviewCriteria_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ReviewCriteria_Variance_Fields = {
  __typename?: 'ReviewCriteria_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables = {
  __typename?: 'ReviewGroupContactTemplateVariables';
  /** An object relationship */
  ContactTemplateVariable: ContactTemplateVariablesEnum;
  /** An object relationship */
  ReviewGroup: ReviewGroups;
  review_group_id: Scalars['Int'];
  template_variable: ContactTemplateVariablesEnum_Enum;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Aggregate = {
  __typename?: 'ReviewGroupContactTemplateVariables_aggregate';
  aggregate?: Maybe<ReviewGroupContactTemplateVariables_Aggregate_Fields>;
  nodes: Array<ReviewGroupContactTemplateVariables>;
};

export type ReviewGroupContactTemplateVariables_Aggregate_Bool_Exp = {
  count?: InputMaybe<ReviewGroupContactTemplateVariables_Aggregate_Bool_Exp_Count>;
};

export type ReviewGroupContactTemplateVariables_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Aggregate_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_aggregate_fields';
  avg?: Maybe<ReviewGroupContactTemplateVariables_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewGroupContactTemplateVariables_Max_Fields>;
  min?: Maybe<ReviewGroupContactTemplateVariables_Min_Fields>;
  stddev?: Maybe<ReviewGroupContactTemplateVariables_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewGroupContactTemplateVariables_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewGroupContactTemplateVariables_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewGroupContactTemplateVariables_Sum_Fields>;
  var_pop?: Maybe<ReviewGroupContactTemplateVariables_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewGroupContactTemplateVariables_Var_Samp_Fields>;
  variance?: Maybe<ReviewGroupContactTemplateVariables_Variance_Fields>;
};


/** aggregate fields of "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Aggregate_Order_By = {
  avg?: InputMaybe<ReviewGroupContactTemplateVariables_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReviewGroupContactTemplateVariables_Max_Order_By>;
  min?: InputMaybe<ReviewGroupContactTemplateVariables_Min_Order_By>;
  stddev?: InputMaybe<ReviewGroupContactTemplateVariables_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReviewGroupContactTemplateVariables_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReviewGroupContactTemplateVariables_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReviewGroupContactTemplateVariables_Sum_Order_By>;
  var_pop?: InputMaybe<ReviewGroupContactTemplateVariables_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReviewGroupContactTemplateVariables_Var_Samp_Order_By>;
  variance?: InputMaybe<ReviewGroupContactTemplateVariables_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Arr_Rel_Insert_Input = {
  data: Array<ReviewGroupContactTemplateVariables_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewGroupContactTemplateVariables_On_Conflict>;
};

/** aggregate avg on columns */
export type ReviewGroupContactTemplateVariables_Avg_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_avg_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Avg_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewGroupContactTemplateVariables". All fields are combined with a logical 'AND'. */
export type ReviewGroupContactTemplateVariables_Bool_Exp = {
  ContactTemplateVariable?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
  ReviewGroup?: InputMaybe<ReviewGroups_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Bool_Exp>>;
  _not?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Bool_Exp>>;
  review_group_id?: InputMaybe<Int_Comparison_Exp>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewGroupContactTemplateVariables" */
export enum ReviewGroupContactTemplateVariables_Constraint {
  /** unique or primary key constraint on columns "review_group_id", "template_variable" */
  ReviewGroupContactTemplateVariablesPkey = 'ReviewGroupContactTemplateVariables_pkey'
}

/** input type for incrementing numeric columns in table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Inc_Input = {
  review_group_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Insert_Input = {
  ContactTemplateVariable?: InputMaybe<ContactTemplateVariablesEnum_Obj_Rel_Insert_Input>;
  ReviewGroup?: InputMaybe<ReviewGroups_Obj_Rel_Insert_Input>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReviewGroupContactTemplateVariables_Max_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_max_fields';
  review_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Max_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewGroupContactTemplateVariables_Min_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_min_fields';
  review_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Min_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Mutation_Response = {
  __typename?: 'ReviewGroupContactTemplateVariables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewGroupContactTemplateVariables>;
};

/** on_conflict condition type for table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_On_Conflict = {
  constraint: ReviewGroupContactTemplateVariables_Constraint;
  update_columns?: Array<ReviewGroupContactTemplateVariables_Update_Column>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewGroupContactTemplateVariables". */
export type ReviewGroupContactTemplateVariables_Order_By = {
  ContactTemplateVariable?: InputMaybe<ContactTemplateVariablesEnum_Order_By>;
  ReviewGroup?: InputMaybe<ReviewGroups_Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  template_variable?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewGroupContactTemplateVariables */
export type ReviewGroupContactTemplateVariables_Pk_Columns_Input = {
  review_group_id: Scalars['Int'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};

/** select columns of table "ReviewGroupContactTemplateVariables" */
export enum ReviewGroupContactTemplateVariables_Select_Column {
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  TemplateVariable = 'template_variable',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Set_Input = {
  review_group_id?: InputMaybe<Scalars['Int']>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ReviewGroupContactTemplateVariables_Stddev_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_stddev_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Stddev_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewGroupContactTemplateVariables_Stddev_Pop_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_stddev_pop_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Stddev_Pop_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewGroupContactTemplateVariables_Stddev_Samp_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_stddev_samp_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Stddev_Samp_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewGroupContactTemplateVariables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewGroupContactTemplateVariables_Stream_Cursor_Value_Input = {
  review_group_id?: InputMaybe<Scalars['Int']>;
  template_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ReviewGroupContactTemplateVariables_Sum_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_sum_fields';
  review_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Sum_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** update columns of table "ReviewGroupContactTemplateVariables" */
export enum ReviewGroupContactTemplateVariables_Update_Column {
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  TemplateVariable = 'template_variable',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ReviewGroupContactTemplateVariables_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewGroupContactTemplateVariables_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewGroupContactTemplateVariables_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewGroupContactTemplateVariables_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReviewGroupContactTemplateVariables_Var_Pop_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_var_pop_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Var_Pop_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewGroupContactTemplateVariables_Var_Samp_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_var_samp_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Var_Samp_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewGroupContactTemplateVariables_Variance_Fields = {
  __typename?: 'ReviewGroupContactTemplateVariables_variance_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewGroupContactTemplateVariables" */
export type ReviewGroupContactTemplateVariables_Variance_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** Join table for many to many relationship between ReviewGroups and ReviewCriteria */
export type ReviewGroupCriteria = {
  __typename?: 'ReviewGroupCriteria';
  /** An object relationship */
  ReviewCriteria: ReviewCriteria;
  /** An object relationship */
  ReviewGroup: ReviewGroups;
  review_criteria_id: Scalars['uuid'];
  review_group_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Aggregate = {
  __typename?: 'ReviewGroupCriteria_aggregate';
  aggregate?: Maybe<ReviewGroupCriteria_Aggregate_Fields>;
  nodes: Array<ReviewGroupCriteria>;
};

export type ReviewGroupCriteria_Aggregate_Bool_Exp = {
  count?: InputMaybe<ReviewGroupCriteria_Aggregate_Bool_Exp_Count>;
};

export type ReviewGroupCriteria_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Aggregate_Fields = {
  __typename?: 'ReviewGroupCriteria_aggregate_fields';
  avg?: Maybe<ReviewGroupCriteria_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewGroupCriteria_Max_Fields>;
  min?: Maybe<ReviewGroupCriteria_Min_Fields>;
  stddev?: Maybe<ReviewGroupCriteria_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewGroupCriteria_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewGroupCriteria_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewGroupCriteria_Sum_Fields>;
  var_pop?: Maybe<ReviewGroupCriteria_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewGroupCriteria_Var_Samp_Fields>;
  variance?: Maybe<ReviewGroupCriteria_Variance_Fields>;
};


/** aggregate fields of "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Aggregate_Order_By = {
  avg?: InputMaybe<ReviewGroupCriteria_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReviewGroupCriteria_Max_Order_By>;
  min?: InputMaybe<ReviewGroupCriteria_Min_Order_By>;
  stddev?: InputMaybe<ReviewGroupCriteria_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReviewGroupCriteria_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReviewGroupCriteria_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReviewGroupCriteria_Sum_Order_By>;
  var_pop?: InputMaybe<ReviewGroupCriteria_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReviewGroupCriteria_Var_Samp_Order_By>;
  variance?: InputMaybe<ReviewGroupCriteria_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Arr_Rel_Insert_Input = {
  data: Array<ReviewGroupCriteria_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewGroupCriteria_On_Conflict>;
};

/** aggregate avg on columns */
export type ReviewGroupCriteria_Avg_Fields = {
  __typename?: 'ReviewGroupCriteria_avg_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Avg_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewGroupCriteria". All fields are combined with a logical 'AND'. */
export type ReviewGroupCriteria_Bool_Exp = {
  ReviewCriteria?: InputMaybe<ReviewCriteria_Bool_Exp>;
  ReviewGroup?: InputMaybe<ReviewGroups_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewGroupCriteria_Bool_Exp>>;
  _not?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewGroupCriteria_Bool_Exp>>;
  review_criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  review_group_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewGroupCriteria" */
export enum ReviewGroupCriteria_Constraint {
  /** unique or primary key constraint on columns "review_group_id", "review_criteria_id" */
  ReviewGroupCriteriaPkey = 'ReviewGroupCriteria_pkey'
}

/** input type for incrementing numeric columns in table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Inc_Input = {
  review_group_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Insert_Input = {
  ReviewCriteria?: InputMaybe<ReviewCriteria_Obj_Rel_Insert_Input>;
  ReviewGroup?: InputMaybe<ReviewGroups_Obj_Rel_Insert_Input>;
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReviewGroupCriteria_Max_Fields = {
  __typename?: 'ReviewGroupCriteria_max_fields';
  review_criteria_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Max_Order_By = {
  review_criteria_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewGroupCriteria_Min_Fields = {
  __typename?: 'ReviewGroupCriteria_min_fields';
  review_criteria_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Min_Order_By = {
  review_criteria_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Mutation_Response = {
  __typename?: 'ReviewGroupCriteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewGroupCriteria>;
};

/** on_conflict condition type for table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_On_Conflict = {
  constraint: ReviewGroupCriteria_Constraint;
  update_columns?: Array<ReviewGroupCriteria_Update_Column>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewGroupCriteria". */
export type ReviewGroupCriteria_Order_By = {
  ReviewCriteria?: InputMaybe<ReviewCriteria_Order_By>;
  ReviewGroup?: InputMaybe<ReviewGroups_Order_By>;
  review_criteria_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewGroupCriteria */
export type ReviewGroupCriteria_Pk_Columns_Input = {
  review_criteria_id: Scalars['uuid'];
  review_group_id: Scalars['Int'];
};

/** select columns of table "ReviewGroupCriteria" */
export enum ReviewGroupCriteria_Select_Column {
  /** column name */
  ReviewCriteriaId = 'review_criteria_id',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Set_Input = {
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ReviewGroupCriteria_Stddev_Fields = {
  __typename?: 'ReviewGroupCriteria_stddev_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Stddev_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewGroupCriteria_Stddev_Pop_Fields = {
  __typename?: 'ReviewGroupCriteria_stddev_pop_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Stddev_Pop_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewGroupCriteria_Stddev_Samp_Fields = {
  __typename?: 'ReviewGroupCriteria_stddev_samp_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Stddev_Samp_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewGroupCriteria_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewGroupCriteria_Stream_Cursor_Value_Input = {
  review_criteria_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ReviewGroupCriteria_Sum_Fields = {
  __typename?: 'ReviewGroupCriteria_sum_fields';
  review_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Sum_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** update columns of table "ReviewGroupCriteria" */
export enum ReviewGroupCriteria_Update_Column {
  /** column name */
  ReviewCriteriaId = 'review_criteria_id',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ReviewGroupCriteria_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewGroupCriteria_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewGroupCriteria_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewGroupCriteria_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReviewGroupCriteria_Var_Pop_Fields = {
  __typename?: 'ReviewGroupCriteria_var_pop_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Var_Pop_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewGroupCriteria_Var_Samp_Fields = {
  __typename?: 'ReviewGroupCriteria_var_samp_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Var_Samp_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewGroupCriteria_Variance_Fields = {
  __typename?: 'ReviewGroupCriteria_variance_fields';
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewGroupCriteria" */
export type ReviewGroupCriteria_Variance_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ReviewGroupFiles" */
export type ReviewGroupFiles = {
  __typename?: 'ReviewGroupFiles';
  /** An object relationship */
  File: Files;
  /** An object relationship */
  ReviewGroup: ReviewGroups;
  created_at: Scalars['timestamptz'];
  file_id: Scalars['Int'];
  id: Scalars['uuid'];
  review_group_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ReviewGroupFiles" */
export type ReviewGroupFiles_Aggregate = {
  __typename?: 'ReviewGroupFiles_aggregate';
  aggregate?: Maybe<ReviewGroupFiles_Aggregate_Fields>;
  nodes: Array<ReviewGroupFiles>;
};

export type ReviewGroupFiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<ReviewGroupFiles_Aggregate_Bool_Exp_Count>;
};

export type ReviewGroupFiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ReviewGroupFiles" */
export type ReviewGroupFiles_Aggregate_Fields = {
  __typename?: 'ReviewGroupFiles_aggregate_fields';
  avg?: Maybe<ReviewGroupFiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewGroupFiles_Max_Fields>;
  min?: Maybe<ReviewGroupFiles_Min_Fields>;
  stddev?: Maybe<ReviewGroupFiles_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewGroupFiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewGroupFiles_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewGroupFiles_Sum_Fields>;
  var_pop?: Maybe<ReviewGroupFiles_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewGroupFiles_Var_Samp_Fields>;
  variance?: Maybe<ReviewGroupFiles_Variance_Fields>;
};


/** aggregate fields of "ReviewGroupFiles" */
export type ReviewGroupFiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Aggregate_Order_By = {
  avg?: InputMaybe<ReviewGroupFiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReviewGroupFiles_Max_Order_By>;
  min?: InputMaybe<ReviewGroupFiles_Min_Order_By>;
  stddev?: InputMaybe<ReviewGroupFiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReviewGroupFiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReviewGroupFiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReviewGroupFiles_Sum_Order_By>;
  var_pop?: InputMaybe<ReviewGroupFiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReviewGroupFiles_Var_Samp_Order_By>;
  variance?: InputMaybe<ReviewGroupFiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ReviewGroupFiles" */
export type ReviewGroupFiles_Arr_Rel_Insert_Input = {
  data: Array<ReviewGroupFiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewGroupFiles_On_Conflict>;
};

/** aggregate avg on columns */
export type ReviewGroupFiles_Avg_Fields = {
  __typename?: 'ReviewGroupFiles_avg_fields';
  file_id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Avg_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewGroupFiles". All fields are combined with a logical 'AND'. */
export type ReviewGroupFiles_Bool_Exp = {
  File?: InputMaybe<Files_Bool_Exp>;
  ReviewGroup?: InputMaybe<ReviewGroups_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewGroupFiles_Bool_Exp>>;
  _not?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewGroupFiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  review_group_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewGroupFiles" */
export enum ReviewGroupFiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewGroupFilesPkey = 'ReviewGroupFiles_pkey'
}

/** input type for incrementing numeric columns in table "ReviewGroupFiles" */
export type ReviewGroupFiles_Inc_Input = {
  file_id?: InputMaybe<Scalars['Int']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewGroupFiles" */
export type ReviewGroupFiles_Insert_Input = {
  File?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  ReviewGroup?: InputMaybe<ReviewGroups_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ReviewGroupFiles_Max_Fields = {
  __typename?: 'ReviewGroupFiles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewGroupFiles_Min_Fields = {
  __typename?: 'ReviewGroupFiles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ReviewGroupFiles" */
export type ReviewGroupFiles_Mutation_Response = {
  __typename?: 'ReviewGroupFiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewGroupFiles>;
};

/** on_conflict condition type for table "ReviewGroupFiles" */
export type ReviewGroupFiles_On_Conflict = {
  constraint: ReviewGroupFiles_Constraint;
  update_columns?: Array<ReviewGroupFiles_Update_Column>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewGroupFiles". */
export type ReviewGroupFiles_Order_By = {
  File?: InputMaybe<Files_Order_By>;
  ReviewGroup?: InputMaybe<ReviewGroups_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewGroupFiles */
export type ReviewGroupFiles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ReviewGroupFiles" */
export enum ReviewGroupFiles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ReviewGroupFiles" */
export type ReviewGroupFiles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ReviewGroupFiles_Stddev_Fields = {
  __typename?: 'ReviewGroupFiles_stddev_fields';
  file_id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Stddev_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewGroupFiles_Stddev_Pop_Fields = {
  __typename?: 'ReviewGroupFiles_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Stddev_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewGroupFiles_Stddev_Samp_Fields = {
  __typename?: 'ReviewGroupFiles_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Stddev_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ReviewGroupFiles" */
export type ReviewGroupFiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewGroupFiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewGroupFiles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ReviewGroupFiles_Sum_Fields = {
  __typename?: 'ReviewGroupFiles_sum_fields';
  file_id?: Maybe<Scalars['Int']>;
  review_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Sum_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** update columns of table "ReviewGroupFiles" */
export enum ReviewGroupFiles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ReviewGroupFiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewGroupFiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewGroupFiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewGroupFiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReviewGroupFiles_Var_Pop_Fields = {
  __typename?: 'ReviewGroupFiles_var_pop_fields';
  file_id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Var_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewGroupFiles_Var_Samp_Fields = {
  __typename?: 'ReviewGroupFiles_var_samp_fields';
  file_id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Var_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewGroupFiles_Variance_Fields = {
  __typename?: 'ReviewGroupFiles_variance_fields';
  file_id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewGroupFiles" */
export type ReviewGroupFiles_Variance_Order_By = {
  file_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ReviewGroupStages" */
export type ReviewGroupStages = {
  __typename?: 'ReviewGroupStages';
  /** An object relationship */
  ReviewGroup: ReviewGroups;
  /** An object relationship */
  Stage: Stages;
  review_group_id: Scalars['Int'];
  stage_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ReviewGroupStages" */
export type ReviewGroupStages_Aggregate = {
  __typename?: 'ReviewGroupStages_aggregate';
  aggregate?: Maybe<ReviewGroupStages_Aggregate_Fields>;
  nodes: Array<ReviewGroupStages>;
};

export type ReviewGroupStages_Aggregate_Bool_Exp = {
  count?: InputMaybe<ReviewGroupStages_Aggregate_Bool_Exp_Count>;
};

export type ReviewGroupStages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ReviewGroupStages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ReviewGroupStages" */
export type ReviewGroupStages_Aggregate_Fields = {
  __typename?: 'ReviewGroupStages_aggregate_fields';
  avg?: Maybe<ReviewGroupStages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewGroupStages_Max_Fields>;
  min?: Maybe<ReviewGroupStages_Min_Fields>;
  stddev?: Maybe<ReviewGroupStages_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewGroupStages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewGroupStages_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewGroupStages_Sum_Fields>;
  var_pop?: Maybe<ReviewGroupStages_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewGroupStages_Var_Samp_Fields>;
  variance?: Maybe<ReviewGroupStages_Variance_Fields>;
};


/** aggregate fields of "ReviewGroupStages" */
export type ReviewGroupStages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewGroupStages" */
export type ReviewGroupStages_Aggregate_Order_By = {
  avg?: InputMaybe<ReviewGroupStages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReviewGroupStages_Max_Order_By>;
  min?: InputMaybe<ReviewGroupStages_Min_Order_By>;
  stddev?: InputMaybe<ReviewGroupStages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReviewGroupStages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReviewGroupStages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReviewGroupStages_Sum_Order_By>;
  var_pop?: InputMaybe<ReviewGroupStages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReviewGroupStages_Var_Samp_Order_By>;
  variance?: InputMaybe<ReviewGroupStages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ReviewGroupStages" */
export type ReviewGroupStages_Arr_Rel_Insert_Input = {
  data: Array<ReviewGroupStages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewGroupStages_On_Conflict>;
};

/** aggregate avg on columns */
export type ReviewGroupStages_Avg_Fields = {
  __typename?: 'ReviewGroupStages_avg_fields';
  review_group_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Avg_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewGroupStages". All fields are combined with a logical 'AND'. */
export type ReviewGroupStages_Bool_Exp = {
  ReviewGroup?: InputMaybe<ReviewGroups_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewGroupStages_Bool_Exp>>;
  _not?: InputMaybe<ReviewGroupStages_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewGroupStages_Bool_Exp>>;
  review_group_id?: InputMaybe<Int_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewGroupStages" */
export enum ReviewGroupStages_Constraint {
  /** unique or primary key constraint on columns "review_group_id", "stage_id" */
  ReviewGroupStagesPkey = 'ReviewGroupStages_pkey'
}

/** input type for incrementing numeric columns in table "ReviewGroupStages" */
export type ReviewGroupStages_Inc_Input = {
  review_group_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewGroupStages" */
export type ReviewGroupStages_Insert_Input = {
  ReviewGroup?: InputMaybe<ReviewGroups_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReviewGroupStages_Max_Fields = {
  __typename?: 'ReviewGroupStages_max_fields';
  review_group_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Max_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewGroupStages_Min_Fields = {
  __typename?: 'ReviewGroupStages_min_fields';
  review_group_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Min_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ReviewGroupStages" */
export type ReviewGroupStages_Mutation_Response = {
  __typename?: 'ReviewGroupStages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewGroupStages>;
};

/** on_conflict condition type for table "ReviewGroupStages" */
export type ReviewGroupStages_On_Conflict = {
  constraint: ReviewGroupStages_Constraint;
  update_columns?: Array<ReviewGroupStages_Update_Column>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewGroupStages". */
export type ReviewGroupStages_Order_By = {
  ReviewGroup?: InputMaybe<ReviewGroups_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewGroupStages */
export type ReviewGroupStages_Pk_Columns_Input = {
  review_group_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};

/** select columns of table "ReviewGroupStages" */
export enum ReviewGroupStages_Select_Column {
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "ReviewGroupStages" */
export type ReviewGroupStages_Set_Input = {
  review_group_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ReviewGroupStages_Stddev_Fields = {
  __typename?: 'ReviewGroupStages_stddev_fields';
  review_group_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Stddev_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewGroupStages_Stddev_Pop_Fields = {
  __typename?: 'ReviewGroupStages_stddev_pop_fields';
  review_group_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Stddev_Pop_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewGroupStages_Stddev_Samp_Fields = {
  __typename?: 'ReviewGroupStages_stddev_samp_fields';
  review_group_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Stddev_Samp_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ReviewGroupStages" */
export type ReviewGroupStages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewGroupStages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewGroupStages_Stream_Cursor_Value_Input = {
  review_group_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ReviewGroupStages_Sum_Fields = {
  __typename?: 'ReviewGroupStages_sum_fields';
  review_group_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Sum_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** update columns of table "ReviewGroupStages" */
export enum ReviewGroupStages_Update_Column {
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type ReviewGroupStages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewGroupStages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewGroupStages_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewGroupStages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReviewGroupStages_Var_Pop_Fields = {
  __typename?: 'ReviewGroupStages_var_pop_fields';
  review_group_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Var_Pop_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewGroupStages_Var_Samp_Fields = {
  __typename?: 'ReviewGroupStages_var_samp_fields';
  review_group_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Var_Samp_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewGroupStages_Variance_Fields = {
  __typename?: 'ReviewGroupStages_variance_fields';
  review_group_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewGroupStages" */
export type ReviewGroupStages_Variance_Order_By = {
  review_group_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum = {
  __typename?: 'ReviewGroupSubTypeEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_Aggregate = {
  __typename?: 'ReviewGroupSubTypeEnum_aggregate';
  aggregate?: Maybe<ReviewGroupSubTypeEnum_Aggregate_Fields>;
  nodes: Array<ReviewGroupSubTypeEnum>;
};

/** aggregate fields of "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_Aggregate_Fields = {
  __typename?: 'ReviewGroupSubTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ReviewGroupSubTypeEnum_Max_Fields>;
  min?: Maybe<ReviewGroupSubTypeEnum_Min_Fields>;
};


/** aggregate fields of "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewGroupSubTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ReviewGroupSubTypeEnum". All fields are combined with a logical 'AND'. */
export type ReviewGroupSubTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ReviewGroupSubTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<ReviewGroupSubTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewGroupSubTypeEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewGroupSubTypeEnum" */
export enum ReviewGroupSubTypeEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  ReviewGroupSubTypeEnumPkey = 'ReviewGroupSubTypeEnum_pkey'
}

export enum ReviewGroupSubTypeEnum_Enum {
  /** General */
  General = 'GENERAL',
  /** Job */
  Job = 'JOB'
}

/** Boolean expression to compare columns of type "ReviewGroupSubTypeEnum_enum". All fields are combined with logical 'AND'. */
export type ReviewGroupSubTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ReviewGroupSubTypeEnum_Enum>;
  _in?: InputMaybe<Array<ReviewGroupSubTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReviewGroupSubTypeEnum_Enum>;
  _nin?: InputMaybe<Array<ReviewGroupSubTypeEnum_Enum>>;
};

/** input type for inserting data into table "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReviewGroupSubTypeEnum_Max_Fields = {
  __typename?: 'ReviewGroupSubTypeEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ReviewGroupSubTypeEnum_Min_Fields = {
  __typename?: 'ReviewGroupSubTypeEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_Mutation_Response = {
  __typename?: 'ReviewGroupSubTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewGroupSubTypeEnum>;
};

/** on_conflict condition type for table "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_On_Conflict = {
  constraint: ReviewGroupSubTypeEnum_Constraint;
  update_columns?: Array<ReviewGroupSubTypeEnum_Update_Column>;
  where?: InputMaybe<ReviewGroupSubTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewGroupSubTypeEnum". */
export type ReviewGroupSubTypeEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewGroupSubTypeEnum */
export type ReviewGroupSubTypeEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "ReviewGroupSubTypeEnum" */
export enum ReviewGroupSubTypeEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ReviewGroupSubTypeEnum" */
export type ReviewGroupSubTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewGroupSubTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewGroupSubTypeEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ReviewGroupSubTypeEnum" */
export enum ReviewGroupSubTypeEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type ReviewGroupSubTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewGroupSubTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewGroupSubTypeEnum_Bool_Exp;
};

/** columns and relationships of "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum = {
  __typename?: 'ReviewGroupTypeEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Aggregate = {
  __typename?: 'ReviewGroupTypeEnum_aggregate';
  aggregate?: Maybe<ReviewGroupTypeEnum_Aggregate_Fields>;
  nodes: Array<ReviewGroupTypeEnum>;
};

/** aggregate fields of "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Aggregate_Fields = {
  __typename?: 'ReviewGroupTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ReviewGroupTypeEnum_Max_Fields>;
  min?: Maybe<ReviewGroupTypeEnum_Min_Fields>;
};


/** aggregate fields of "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewGroupTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ReviewGroupTypeEnum". All fields are combined with a logical 'AND'. */
export type ReviewGroupTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ReviewGroupTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewGroupTypeEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewGroupTypeEnum" */
export enum ReviewGroupTypeEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  ReviewGroupTypeEnumPkey = 'ReviewGroupTypeEnum_pkey'
}

export enum ReviewGroupTypeEnum_Enum {
  /** Instance */
  Instance = 'INSTANCE',
  /** Template */
  Template = 'TEMPLATE'
}

/** Boolean expression to compare columns of type "ReviewGroupTypeEnum_enum". All fields are combined with logical 'AND'. */
export type ReviewGroupTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ReviewGroupTypeEnum_Enum>;
  _in?: InputMaybe<Array<ReviewGroupTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReviewGroupTypeEnum_Enum>;
  _nin?: InputMaybe<Array<ReviewGroupTypeEnum_Enum>>;
};

/** input type for inserting data into table "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReviewGroupTypeEnum_Max_Fields = {
  __typename?: 'ReviewGroupTypeEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ReviewGroupTypeEnum_Min_Fields = {
  __typename?: 'ReviewGroupTypeEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Mutation_Response = {
  __typename?: 'ReviewGroupTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewGroupTypeEnum>;
};

/** input type for inserting object relation for remote table "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Obj_Rel_Insert_Input = {
  data: ReviewGroupTypeEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewGroupTypeEnum_On_Conflict>;
};

/** on_conflict condition type for table "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_On_Conflict = {
  constraint: ReviewGroupTypeEnum_Constraint;
  update_columns?: Array<ReviewGroupTypeEnum_Update_Column>;
  where?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewGroupTypeEnum". */
export type ReviewGroupTypeEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewGroupTypeEnum */
export type ReviewGroupTypeEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "ReviewGroupTypeEnum" */
export enum ReviewGroupTypeEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ReviewGroupTypeEnum" */
export type ReviewGroupTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewGroupTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewGroupTypeEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ReviewGroupTypeEnum" */
export enum ReviewGroupTypeEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type ReviewGroupTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewGroupTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewGroupTypeEnum_Bool_Exp;
};

/** columns and relationships of "ReviewGroups" */
export type ReviewGroups = {
  __typename?: 'ReviewGroups';
  /** An object relationship */
  Author: Identities;
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An array relationship */
  ReviewCriteriaScoreAggregates: Array<ReviewCriteriaScoreAggregates>;
  /** An aggregate relationship */
  ReviewCriteriaScoreAggregates_aggregate: ReviewCriteriaScoreAggregates_Aggregate;
  /** An array relationship */
  ReviewGroupContactTemplateVariables: Array<ReviewGroupContactTemplateVariables>;
  /** An aggregate relationship */
  ReviewGroupContactTemplateVariables_aggregate: ReviewGroupContactTemplateVariables_Aggregate;
  /** An array relationship */
  ReviewGroupCriteria: Array<ReviewGroupCriteria>;
  /** An aggregate relationship */
  ReviewGroupCriteria_aggregate: ReviewGroupCriteria_Aggregate;
  /** An array relationship */
  ReviewGroupFiles: Array<ReviewGroupFiles>;
  /** An aggregate relationship */
  ReviewGroupFiles_aggregate: ReviewGroupFiles_Aggregate;
  /** An array relationship */
  ReviewGroupStages: Array<ReviewGroupStages>;
  /** An aggregate relationship */
  ReviewGroupStages_aggregate: ReviewGroupStages_Aggregate;
  /** An object relationship */
  ReviewGroupTypeEnum: ReviewGroupTypeEnum;
  /** An array relationship */
  Reviews: Array<Reviews>;
  /** An aggregate relationship */
  Reviews_aggregate: Reviews_Aggregate;
  /** An array relationship */
  Stages: Array<Stages>;
  /** An aggregate relationship */
  Stages_aggregate: Stages_Aggregate;
  /** An object relationship */
  Updater: Identities;
  author_id: Scalars['Int'];
  company_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  sub_type?: Maybe<ReviewGroupSubTypeEnum_Enum>;
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: ReviewGroupTypeEnum_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by: Scalars['Int'];
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewCriteriaScoreAggregatesArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewCriteriaScoreAggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupContactTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Order_By>>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupContactTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Order_By>>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupCriteriaArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupFilesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupStagesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupStages_Order_By>>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewGroupStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupStages_Order_By>>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsStagesArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


/** columns and relationships of "ReviewGroups" */
export type ReviewGroupsStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};

/** aggregated selection of "ReviewGroups" */
export type ReviewGroups_Aggregate = {
  __typename?: 'ReviewGroups_aggregate';
  aggregate?: Maybe<ReviewGroups_Aggregate_Fields>;
  nodes: Array<ReviewGroups>;
};

/** aggregate fields of "ReviewGroups" */
export type ReviewGroups_Aggregate_Fields = {
  __typename?: 'ReviewGroups_aggregate_fields';
  avg?: Maybe<ReviewGroups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewGroups_Max_Fields>;
  min?: Maybe<ReviewGroups_Min_Fields>;
  stddev?: Maybe<ReviewGroups_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewGroups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewGroups_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewGroups_Sum_Fields>;
  var_pop?: Maybe<ReviewGroups_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewGroups_Var_Samp_Fields>;
  variance?: Maybe<ReviewGroups_Variance_Fields>;
};


/** aggregate fields of "ReviewGroups" */
export type ReviewGroups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewGroups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ReviewGroups_Avg_Fields = {
  __typename?: 'ReviewGroups_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ReviewGroups". All fields are combined with a logical 'AND'. */
export type ReviewGroups_Bool_Exp = {
  Author?: InputMaybe<Identities_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  ReviewCriteriaScoreAggregates?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
  ReviewCriteriaScoreAggregates_aggregate?: InputMaybe<ReviewCriteriaScoreAggregates_Aggregate_Bool_Exp>;
  ReviewGroupContactTemplateVariables?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
  ReviewGroupContactTemplateVariables_aggregate?: InputMaybe<ReviewGroupContactTemplateVariables_Aggregate_Bool_Exp>;
  ReviewGroupCriteria?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
  ReviewGroupCriteria_aggregate?: InputMaybe<ReviewGroupCriteria_Aggregate_Bool_Exp>;
  ReviewGroupFiles?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
  ReviewGroupFiles_aggregate?: InputMaybe<ReviewGroupFiles_Aggregate_Bool_Exp>;
  ReviewGroupStages?: InputMaybe<ReviewGroupStages_Bool_Exp>;
  ReviewGroupStages_aggregate?: InputMaybe<ReviewGroupStages_Aggregate_Bool_Exp>;
  ReviewGroupTypeEnum?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
  Reviews?: InputMaybe<Reviews_Bool_Exp>;
  Reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  Stages?: InputMaybe<Stages_Bool_Exp>;
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Bool_Exp>;
  Updater?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewGroups_Bool_Exp>>;
  _not?: InputMaybe<ReviewGroups_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewGroups_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  sub_type?: InputMaybe<ReviewGroupSubTypeEnum_Enum_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<ReviewGroupTypeEnum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewGroups" */
export enum ReviewGroups_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewGroupsIdKey = 'ReviewGroups_id_key',
  /** unique or primary key constraint on columns "id" */
  TeamReviewsPkey = 'TeamReviews_pkey'
}

/** input type for incrementing numeric columns in table "ReviewGroups" */
export type ReviewGroups_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewGroups" */
export type ReviewGroups_Insert_Input = {
  Author?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  ReviewCriteriaScoreAggregates?: InputMaybe<ReviewCriteriaScoreAggregates_Arr_Rel_Insert_Input>;
  ReviewGroupContactTemplateVariables?: InputMaybe<ReviewGroupContactTemplateVariables_Arr_Rel_Insert_Input>;
  ReviewGroupCriteria?: InputMaybe<ReviewGroupCriteria_Arr_Rel_Insert_Input>;
  ReviewGroupFiles?: InputMaybe<ReviewGroupFiles_Arr_Rel_Insert_Input>;
  ReviewGroupStages?: InputMaybe<ReviewGroupStages_Arr_Rel_Insert_Input>;
  ReviewGroupTypeEnum?: InputMaybe<ReviewGroupTypeEnum_Obj_Rel_Insert_Input>;
  Reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  Stages?: InputMaybe<Stages_Arr_Rel_Insert_Input>;
  Updater?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_type?: InputMaybe<ReviewGroupSubTypeEnum_Enum>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReviewGroupTypeEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ReviewGroups_Max_Fields = {
  __typename?: 'ReviewGroups_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ReviewGroups_Min_Fields = {
  __typename?: 'ReviewGroups_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ReviewGroups" */
export type ReviewGroups_Mutation_Response = {
  __typename?: 'ReviewGroups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewGroups>;
};

/** input type for inserting object relation for remote table "ReviewGroups" */
export type ReviewGroups_Obj_Rel_Insert_Input = {
  data: ReviewGroups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewGroups_On_Conflict>;
};

/** on_conflict condition type for table "ReviewGroups" */
export type ReviewGroups_On_Conflict = {
  constraint: ReviewGroups_Constraint;
  update_columns?: Array<ReviewGroups_Update_Column>;
  where?: InputMaybe<ReviewGroups_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewGroups". */
export type ReviewGroups_Order_By = {
  Author?: InputMaybe<Identities_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  ReviewCriteriaScoreAggregates_aggregate?: InputMaybe<ReviewCriteriaScoreAggregates_Aggregate_Order_By>;
  ReviewGroupContactTemplateVariables_aggregate?: InputMaybe<ReviewGroupContactTemplateVariables_Aggregate_Order_By>;
  ReviewGroupCriteria_aggregate?: InputMaybe<ReviewGroupCriteria_Aggregate_Order_By>;
  ReviewGroupFiles_aggregate?: InputMaybe<ReviewGroupFiles_Aggregate_Order_By>;
  ReviewGroupStages_aggregate?: InputMaybe<ReviewGroupStages_Aggregate_Order_By>;
  ReviewGroupTypeEnum?: InputMaybe<ReviewGroupTypeEnum_Order_By>;
  Reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Order_By>;
  Updater?: InputMaybe<Identities_Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewGroups */
export type ReviewGroups_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ReviewGroups" */
export enum ReviewGroups_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ReviewGroups" */
export type ReviewGroups_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_type?: InputMaybe<ReviewGroupSubTypeEnum_Enum>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReviewGroupTypeEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ReviewGroups_Stddev_Fields = {
  __typename?: 'ReviewGroups_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ReviewGroups_Stddev_Pop_Fields = {
  __typename?: 'ReviewGroups_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ReviewGroups_Stddev_Samp_Fields = {
  __typename?: 'ReviewGroups_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ReviewGroups" */
export type ReviewGroups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewGroups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewGroups_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  sub_type?: InputMaybe<ReviewGroupSubTypeEnum_Enum>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReviewGroupTypeEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ReviewGroups_Sum_Fields = {
  __typename?: 'ReviewGroups_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "ReviewGroups" */
export enum ReviewGroups_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type ReviewGroups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReviewGroups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewGroups_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewGroups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ReviewGroups_Var_Pop_Fields = {
  __typename?: 'ReviewGroups_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ReviewGroups_Var_Samp_Fields = {
  __typename?: 'ReviewGroups_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ReviewGroups_Variance_Fields = {
  __typename?: 'ReviewGroups_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Reviews" */
export type Reviews = {
  __typename?: 'Reviews';
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  CreatedBy: Identities;
  /** An array relationship */
  ReviewCriteriaScores: Array<ReviewCriteriaScore>;
  /** An aggregate relationship */
  ReviewCriteriaScores_aggregate: ReviewCriteriaScore_Aggregate;
  /** An object relationship */
  ReviewGroup: ReviewGroups;
  /** An object relationship */
  Reviewer: Identities;
  /** An object relationship */
  ReviewsResultEnum: ReviewsResultEnum;
  /** An object relationship */
  UpdatedBy: Identities;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  due_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  message_to_reviewer?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  result: ReviewsResultEnum_Enum;
  review_group_id: Scalars['Int'];
  reviewer_id: Scalars['Int'];
  submitted_at?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "Reviews" */
export type ReviewsReviewCriteriaScoresArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


/** columns and relationships of "Reviews" */
export type ReviewsReviewCriteriaScores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};

/** columns and relationships of "ReviewsResultEnum" */
export type ReviewsResultEnum = {
  __typename?: 'ReviewsResultEnum';
  /** An array relationship */
  Reviews: Array<Reviews>;
  /** An aggregate relationship */
  Reviews_aggregate: Reviews_Aggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


/** columns and relationships of "ReviewsResultEnum" */
export type ReviewsResultEnumReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


/** columns and relationships of "ReviewsResultEnum" */
export type ReviewsResultEnumReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** aggregated selection of "ReviewsResultEnum" */
export type ReviewsResultEnum_Aggregate = {
  __typename?: 'ReviewsResultEnum_aggregate';
  aggregate?: Maybe<ReviewsResultEnum_Aggregate_Fields>;
  nodes: Array<ReviewsResultEnum>;
};

/** aggregate fields of "ReviewsResultEnum" */
export type ReviewsResultEnum_Aggregate_Fields = {
  __typename?: 'ReviewsResultEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ReviewsResultEnum_Max_Fields>;
  min?: Maybe<ReviewsResultEnum_Min_Fields>;
};


/** aggregate fields of "ReviewsResultEnum" */
export type ReviewsResultEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReviewsResultEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ReviewsResultEnum". All fields are combined with a logical 'AND'. */
export type ReviewsResultEnum_Bool_Exp = {
  Reviews?: InputMaybe<Reviews_Bool_Exp>;
  Reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<ReviewsResultEnum_Bool_Exp>>;
  _not?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ReviewsResultEnum_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ReviewsResultEnum" */
export enum ReviewsResultEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  ReviewsResultEnumPkey = 'ReviewsResultEnum_pkey'
}

export enum ReviewsResultEnum_Enum {
  Approved = 'APPROVED',
  NotReviewed = 'NOT_REVIEWED',
  Rejected = 'REJECTED',
  Unsure = 'UNSURE'
}

/** Boolean expression to compare columns of type "ReviewsResultEnum_enum". All fields are combined with logical 'AND'. */
export type ReviewsResultEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ReviewsResultEnum_Enum>;
  _in?: InputMaybe<Array<ReviewsResultEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReviewsResultEnum_Enum>;
  _nin?: InputMaybe<Array<ReviewsResultEnum_Enum>>;
};

/** input type for inserting data into table "ReviewsResultEnum" */
export type ReviewsResultEnum_Insert_Input = {
  Reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReviewsResultEnum_Max_Fields = {
  __typename?: 'ReviewsResultEnum_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ReviewsResultEnum_Min_Fields = {
  __typename?: 'ReviewsResultEnum_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ReviewsResultEnum" */
export type ReviewsResultEnum_Mutation_Response = {
  __typename?: 'ReviewsResultEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewsResultEnum>;
};

/** input type for inserting object relation for remote table "ReviewsResultEnum" */
export type ReviewsResultEnum_Obj_Rel_Insert_Input = {
  data: ReviewsResultEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ReviewsResultEnum_On_Conflict>;
};

/** on_conflict condition type for table "ReviewsResultEnum" */
export type ReviewsResultEnum_On_Conflict = {
  constraint: ReviewsResultEnum_Constraint;
  update_columns?: Array<ReviewsResultEnum_Update_Column>;
  where?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ReviewsResultEnum". */
export type ReviewsResultEnum_Order_By = {
  Reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ReviewsResultEnum */
export type ReviewsResultEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ReviewsResultEnum" */
export enum ReviewsResultEnum_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ReviewsResultEnum" */
export type ReviewsResultEnum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ReviewsResultEnum" */
export type ReviewsResultEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ReviewsResultEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ReviewsResultEnum_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ReviewsResultEnum" */
export enum ReviewsResultEnum_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type ReviewsResultEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReviewsResultEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReviewsResultEnum_Bool_Exp;
};

/** aggregated selection of "Reviews" */
export type Reviews_Aggregate = {
  __typename?: 'Reviews_aggregate';
  aggregate?: Maybe<Reviews_Aggregate_Fields>;
  nodes: Array<Reviews>;
};

export type Reviews_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reviews_Aggregate_Bool_Exp_Count>;
};

export type Reviews_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Reviews" */
export type Reviews_Aggregate_Fields = {
  __typename?: 'Reviews_aggregate_fields';
  avg?: Maybe<Reviews_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reviews_Max_Fields>;
  min?: Maybe<Reviews_Min_Fields>;
  stddev?: Maybe<Reviews_Stddev_Fields>;
  stddev_pop?: Maybe<Reviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reviews_Stddev_Samp_Fields>;
  sum?: Maybe<Reviews_Sum_Fields>;
  var_pop?: Maybe<Reviews_Var_Pop_Fields>;
  var_samp?: Maybe<Reviews_Var_Samp_Fields>;
  variance?: Maybe<Reviews_Variance_Fields>;
};


/** aggregate fields of "Reviews" */
export type Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Reviews" */
export type Reviews_Aggregate_Order_By = {
  avg?: InputMaybe<Reviews_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reviews_Max_Order_By>;
  min?: InputMaybe<Reviews_Min_Order_By>;
  stddev?: InputMaybe<Reviews_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reviews_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reviews_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reviews_Sum_Order_By>;
  var_pop?: InputMaybe<Reviews_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reviews_Var_Samp_Order_By>;
  variance?: InputMaybe<Reviews_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Reviews" */
export type Reviews_Arr_Rel_Insert_Input = {
  data: Array<Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** aggregate avg on columns */
export type Reviews_Avg_Fields = {
  __typename?: 'Reviews_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Reviews" */
export type Reviews_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Reviews". All fields are combined with a logical 'AND'. */
export type Reviews_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  ReviewCriteriaScores?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
  ReviewCriteriaScores_aggregate?: InputMaybe<ReviewCriteriaScore_Aggregate_Bool_Exp>;
  ReviewGroup?: InputMaybe<ReviewGroups_Bool_Exp>;
  Reviewer?: InputMaybe<Identities_Bool_Exp>;
  ReviewsResultEnum?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Reviews_Bool_Exp>>;
  _not?: InputMaybe<Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Reviews_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  due_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message_to_reviewer?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  result?: InputMaybe<ReviewsResultEnum_Enum_Comparison_Exp>;
  review_group_id?: InputMaybe<Int_Comparison_Exp>;
  reviewer_id?: InputMaybe<Int_Comparison_Exp>;
  submitted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Reviews" */
export enum Reviews_Constraint {
  /** unique or primary key constraint on columns "review_group_id", "reviewer_id" */
  ReviewsAuthorIdReviewGroupIdKey = 'Reviews_author_id_review_group_id_key',
  /** unique or primary key constraint on columns "id" */
  ReviewsIdKey = 'Reviews_id_key',
  /** unique or primary key constraint on columns "id" */
  ReviewsPkey = 'Reviews_pkey'
}

/** input type for incrementing numeric columns in table "Reviews" */
export type Reviews_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Reviews" */
export type Reviews_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  ReviewCriteriaScores?: InputMaybe<ReviewCriteriaScore_Arr_Rel_Insert_Input>;
  ReviewGroup?: InputMaybe<ReviewGroups_Obj_Rel_Insert_Input>;
  Reviewer?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  ReviewsResultEnum?: InputMaybe<ReviewsResultEnum_Obj_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  due_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  message_to_reviewer?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ReviewsResultEnum_Enum>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Reviews_Max_Fields = {
  __typename?: 'Reviews_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  message_to_reviewer?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  review_group_id?: Maybe<Scalars['Int']>;
  reviewer_id?: Maybe<Scalars['Int']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Reviews" */
export type Reviews_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_to_reviewer?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  submitted_at?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reviews_Min_Fields = {
  __typename?: 'Reviews_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  message_to_reviewer?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  review_group_id?: Maybe<Scalars['Int']>;
  reviewer_id?: Maybe<Scalars['Int']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Reviews" */
export type Reviews_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_to_reviewer?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  submitted_at?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Reviews" */
export type Reviews_Mutation_Response = {
  __typename?: 'Reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reviews>;
};

/** input type for inserting object relation for remote table "Reviews" */
export type Reviews_Obj_Rel_Insert_Input = {
  data: Reviews_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** on_conflict condition type for table "Reviews" */
export type Reviews_On_Conflict = {
  constraint: Reviews_Constraint;
  update_columns?: Array<Reviews_Update_Column>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "Reviews". */
export type Reviews_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  ReviewCriteriaScores_aggregate?: InputMaybe<ReviewCriteriaScore_Aggregate_Order_By>;
  ReviewGroup?: InputMaybe<ReviewGroups_Order_By>;
  Reviewer?: InputMaybe<Identities_Order_By>;
  ReviewsResultEnum?: InputMaybe<ReviewsResultEnum_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_to_reviewer?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  submitted_at?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Reviews */
export type Reviews_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Reviews" */
export enum Reviews_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  MessageToReviewer = 'message_to_reviewer',
  /** column name */
  Note = 'note',
  /** column name */
  Result = 'result',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  ReviewerId = 'reviewer_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "Reviews" */
export type Reviews_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  due_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  message_to_reviewer?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ReviewsResultEnum_Enum>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Reviews_Stddev_Fields = {
  __typename?: 'Reviews_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Reviews" */
export type Reviews_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reviews_Stddev_Pop_Fields = {
  __typename?: 'Reviews_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Reviews" */
export type Reviews_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reviews_Stddev_Samp_Fields = {
  __typename?: 'Reviews_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Reviews" */
export type Reviews_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Reviews" */
export type Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reviews_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  due_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  message_to_reviewer?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ReviewsResultEnum_Enum>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  submitted_at?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Reviews_Sum_Fields = {
  __typename?: 'Reviews_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  review_group_id?: Maybe<Scalars['Int']>;
  reviewer_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Reviews" */
export type Reviews_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Reviews" */
export enum Reviews_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  MessageToReviewer = 'message_to_reviewer',
  /** column name */
  Note = 'note',
  /** column name */
  Result = 'result',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  ReviewerId = 'reviewer_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Reviews_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reviews_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reviews_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reviews_Var_Pop_Fields = {
  __typename?: 'Reviews_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Reviews" */
export type Reviews_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reviews_Var_Samp_Fields = {
  __typename?: 'Reviews_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Reviews" */
export type Reviews_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reviews_Variance_Fields = {
  __typename?: 'Reviews_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Reviews" */
export type Reviews_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "RoleAccess" */
export type RoleAccess = {
  __typename?: 'RoleAccess';
  /** An object relationship */
  Role: Roles;
  access_type: AccessTypeEnum_Enum;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  role_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  ui_element: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "RoleAccess" */
export type RoleAccess_Aggregate = {
  __typename?: 'RoleAccess_aggregate';
  aggregate?: Maybe<RoleAccess_Aggregate_Fields>;
  nodes: Array<RoleAccess>;
};

/** aggregate fields of "RoleAccess" */
export type RoleAccess_Aggregate_Fields = {
  __typename?: 'RoleAccess_aggregate_fields';
  avg?: Maybe<RoleAccess_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RoleAccess_Max_Fields>;
  min?: Maybe<RoleAccess_Min_Fields>;
  stddev?: Maybe<RoleAccess_Stddev_Fields>;
  stddev_pop?: Maybe<RoleAccess_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RoleAccess_Stddev_Samp_Fields>;
  sum?: Maybe<RoleAccess_Sum_Fields>;
  var_pop?: Maybe<RoleAccess_Var_Pop_Fields>;
  var_samp?: Maybe<RoleAccess_Var_Samp_Fields>;
  variance?: Maybe<RoleAccess_Variance_Fields>;
};


/** aggregate fields of "RoleAccess" */
export type RoleAccess_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RoleAccess_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RoleAccess_Avg_Fields = {
  __typename?: 'RoleAccess_avg_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "RoleAccess". All fields are combined with a logical 'AND'. */
export type RoleAccess_Bool_Exp = {
  Role?: InputMaybe<Roles_Bool_Exp>;
  _and?: InputMaybe<Array<RoleAccess_Bool_Exp>>;
  _not?: InputMaybe<RoleAccess_Bool_Exp>;
  _or?: InputMaybe<Array<RoleAccess_Bool_Exp>>;
  access_type?: InputMaybe<AccessTypeEnum_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  ui_element?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "RoleAccess" */
export enum RoleAccess_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleAccessPkey = 'RoleAccess_pkey'
}

/** input type for incrementing numeric columns in table "RoleAccess" */
export type RoleAccess_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "RoleAccess" */
export type RoleAccess_Insert_Input = {
  Role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  access_type?: InputMaybe<AccessTypeEnum_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  ui_element?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RoleAccess_Max_Fields = {
  __typename?: 'RoleAccess_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  ui_element?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type RoleAccess_Min_Fields = {
  __typename?: 'RoleAccess_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  ui_element?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "RoleAccess" */
export type RoleAccess_Mutation_Response = {
  __typename?: 'RoleAccess_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoleAccess>;
};

/** on_conflict condition type for table "RoleAccess" */
export type RoleAccess_On_Conflict = {
  constraint: RoleAccess_Constraint;
  update_columns?: Array<RoleAccess_Update_Column>;
  where?: InputMaybe<RoleAccess_Bool_Exp>;
};

/** Ordering options when selecting data from "RoleAccess". */
export type RoleAccess_Order_By = {
  Role?: InputMaybe<Roles_Order_By>;
  access_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  ui_element?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RoleAccess */
export type RoleAccess_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "RoleAccess" */
export enum RoleAccess_Select_Column {
  /** column name */
  AccessType = 'access_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UiElement = 'ui_element',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "RoleAccess" */
export type RoleAccess_Set_Input = {
  access_type?: InputMaybe<AccessTypeEnum_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  ui_element?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RoleAccess_Stddev_Fields = {
  __typename?: 'RoleAccess_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RoleAccess_Stddev_Pop_Fields = {
  __typename?: 'RoleAccess_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RoleAccess_Stddev_Samp_Fields = {
  __typename?: 'RoleAccess_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "RoleAccess" */
export type RoleAccess_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RoleAccess_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RoleAccess_Stream_Cursor_Value_Input = {
  access_type?: InputMaybe<AccessTypeEnum_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  ui_element?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type RoleAccess_Sum_Fields = {
  __typename?: 'RoleAccess_sum_fields';
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "RoleAccess" */
export enum RoleAccess_Update_Column {
  /** column name */
  AccessType = 'access_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UiElement = 'ui_element',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RoleAccess_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RoleAccess_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RoleAccess_Set_Input>;
  /** filter the rows which have to be updated */
  where: RoleAccess_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RoleAccess_Var_Pop_Fields = {
  __typename?: 'RoleAccess_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RoleAccess_Var_Samp_Fields = {
  __typename?: 'RoleAccess_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RoleAccess_Variance_Fields = {
  __typename?: 'RoleAccess_variance_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Roles" */
export type Roles = {
  __typename?: 'Roles';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An array relationship */
  IdentityRoles: Array<IdentityRoles>;
  /** An aggregate relationship */
  IdentityRoles_aggregate: IdentityRoles_Aggregate;
  /** An array relationship */
  StageRoles: Array<StageRoles>;
  /** An aggregate relationship */
  StageRoles_aggregate: StageRoles_Aggregate;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  cognito_group_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  precedence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Roles" */
export type RolesIdentityRolesArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


/** columns and relationships of "Roles" */
export type RolesIdentityRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


/** columns and relationships of "Roles" */
export type RolesStageRolesArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


/** columns and relationships of "Roles" */
export type RolesStageRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


/** columns and relationships of "Roles" */
export type RolesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "Roles" */
export type RolesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "Roles" */
export type Roles_Aggregate = {
  __typename?: 'Roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

export type Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Roles_Aggregate_Bool_Exp_Count>;
};

export type Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'Roles_aggregate_fields';
  avg?: Maybe<Roles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
  stddev?: Maybe<Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Roles_Sum_Fields>;
  var_pop?: Maybe<Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Roles_Var_Samp_Fields>;
  variance?: Maybe<Roles_Variance_Fields>;
};


/** aggregate fields of "Roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Roles" */
export type Roles_Aggregate_Order_By = {
  avg?: InputMaybe<Roles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Roles_Max_Order_By>;
  min?: InputMaybe<Roles_Min_Order_By>;
  stddev?: InputMaybe<Roles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Roles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Roles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Roles_Sum_Order_By>;
  var_pop?: InputMaybe<Roles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Roles_Var_Samp_Order_By>;
  variance?: InputMaybe<Roles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Roles" */
export type Roles_Arr_Rel_Insert_Input = {
  data: Array<Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** aggregate avg on columns */
export type Roles_Avg_Fields = {
  __typename?: 'Roles_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  precedence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Roles" */
export type Roles_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  IdentityRoles?: InputMaybe<IdentityRoles_Bool_Exp>;
  IdentityRoles_aggregate?: InputMaybe<IdentityRoles_Aggregate_Bool_Exp>;
  StageRoles?: InputMaybe<StageRoles_Bool_Exp>;
  StageRoles_aggregate?: InputMaybe<StageRoles_Aggregate_Bool_Exp>;
  Users?: InputMaybe<Users_Bool_Exp>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  cognito_group_name?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  precedence?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "cognito_group_name" */
  RolesCognitoGroupNameKey = 'Roles_cognito_group_name_key',
  /** unique or primary key constraint on columns "company_id", "title" */
  RolesCompanyIdTitleKey = 'Roles_company_id_title_key',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'Roles_pkey'
}

/** input type for incrementing numeric columns in table "Roles" */
export type Roles_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  precedence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Roles" */
export type Roles_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  IdentityRoles?: InputMaybe<IdentityRoles_Arr_Rel_Insert_Input>;
  StageRoles?: InputMaybe<StageRoles_Arr_Rel_Insert_Input>;
  Users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  cognito_group_name?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  precedence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'Roles_max_fields';
  cognito_group_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  precedence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Roles" */
export type Roles_Max_Order_By = {
  cognito_group_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'Roles_min_fields';
  cognito_group_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  precedence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Roles" */
export type Roles_Min_Order_By = {
  cognito_group_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Roles" */
export type Roles_Mutation_Response = {
  __typename?: 'Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "Roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "Roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "Roles". */
export type Roles_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  IdentityRoles_aggregate?: InputMaybe<IdentityRoles_Aggregate_Order_By>;
  StageRoles_aggregate?: InputMaybe<StageRoles_Aggregate_Order_By>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  cognito_group_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Roles" */
export enum Roles_Select_Column {
  /** column name */
  CognitoGroupName = 'cognito_group_name',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Precedence = 'precedence',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Roles" */
export type Roles_Set_Input = {
  cognito_group_name?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  precedence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Roles_Stddev_Fields = {
  __typename?: 'Roles_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  precedence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Roles" */
export type Roles_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Roles_Stddev_Pop_Fields = {
  __typename?: 'Roles_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  precedence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Roles" */
export type Roles_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Roles_Stddev_Samp_Fields = {
  __typename?: 'Roles_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  precedence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Roles" */
export type Roles_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  cognito_group_name?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  precedence?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Roles_Sum_Fields = {
  __typename?: 'Roles_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  precedence?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Roles" */
export type Roles_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

/** update columns of table "Roles" */
export enum Roles_Update_Column {
  /** column name */
  CognitoGroupName = 'cognito_group_name',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Precedence = 'precedence',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Roles_Var_Pop_Fields = {
  __typename?: 'Roles_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  precedence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Roles" */
export type Roles_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Roles_Var_Samp_Fields = {
  __typename?: 'Roles_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  precedence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Roles" */
export type Roles_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Roles_Variance_Fields = {
  __typename?: 'Roles_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  precedence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Roles" */
export type Roles_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  precedence?: InputMaybe<Order_By>;
};

export type SmsPayload = {
  body: Scalars['String'];
  recipient_identity_id: Scalars['Int'];
  sender_identity_id: Scalars['Int'];
  to: Scalars['String'];
  twilio_from: Scalars['String'];
};

export type SmsResponse = {
  __typename?: 'SMSResponse';
  successful: Scalars['Boolean'];
};

export type SaveOnboardingAtsCredentialsInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SaveOnboardingAtsCredentialsOutput = {
  __typename?: 'SaveOnboardingATSCredentialsOutput';
  status: Scalars['String'];
};

/**  This is the Types Enum Table for the SearchFilters table */
export type SearchFilterTypesEnum = {
  __typename?: 'SearchFilterTypesEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Aggregate = {
  __typename?: 'SearchFilterTypesEnum_aggregate';
  aggregate?: Maybe<SearchFilterTypesEnum_Aggregate_Fields>;
  nodes: Array<SearchFilterTypesEnum>;
};

/** aggregate fields of "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Aggregate_Fields = {
  __typename?: 'SearchFilterTypesEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SearchFilterTypesEnum_Max_Fields>;
  min?: Maybe<SearchFilterTypesEnum_Min_Fields>;
};


/** aggregate fields of "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SearchFilterTypesEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "SearchFilterTypesEnum". All fields are combined with a logical 'AND'. */
export type SearchFilterTypesEnum_Bool_Exp = {
  _and?: InputMaybe<Array<SearchFilterTypesEnum_Bool_Exp>>;
  _not?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
  _or?: InputMaybe<Array<SearchFilterTypesEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SearchFilterTypesEnum" */
export enum SearchFilterTypesEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  SearchFilterTypesEnumPkey = 'SearchFilterTypesEnum_pkey'
}

export enum SearchFilterTypesEnum_Enum {
  /** Aap */
  Aap = 'AAP',
  /** Activity */
  Activity = 'ACTIVITY',
  /** Applicants */
  Applicants = 'APPLICANTS',
  /** Candidates */
  Candidates = 'CANDIDATES',
  /** Contacts */
  Contacts = 'CONTACTS',
  /** Contracts */
  Contracts = 'CONTRACTS',
  /** Contract Associated Candidates */
  ContractAssociatedCandidates = 'CONTRACT_ASSOCIATED_CANDIDATES',
  /** Employees */
  Employees = 'EMPLOYEES',
  /** External Partner Candidates */
  ExternalPartnerCandidates = 'EXTERNAL_PARTNER_CANDIDATES',
  /** External Partner Contracts */
  ExternalPartnerContracts = 'EXTERNAL_PARTNER_CONTRACTS',
  /** External Partner Jobs */
  ExternalPartnerJobs = 'EXTERNAL_PARTNER_JOBS',
  /** External Partner Submissions */
  ExternalPartnerSubmissions = 'EXTERNAL_PARTNER_SUBMISSIONS',
  /** Funnel Contacted */
  FunnelContacted = 'FUNNEL_CONTACTED',
  /** Funnel Interviews */
  FunnelInterviews = 'FUNNEL_INTERVIEWS',
  /** Funnel Matched */
  FunnelMatched = 'FUNNEL_MATCHED',
  /** Funnel Outreach */
  FunnelOutreach = 'FUNNEL_OUTREACH',
  /** Funnel Starts */
  FunnelStarts = 'FUNNEL_STARTS',
  /** Funnel Submitted */
  FunnelSubmitted = 'FUNNEL_SUBMITTED',
  /** Internal Partner Submissions */
  InternalPartnerSubmissions = 'INTERNAL_PARTNER_SUBMISSIONS',
  /** Jobs */
  Jobs = 'JOBS',
  /** Job Associated Candidates */
  JobAssociatedCandidates = 'JOB_ASSOCIATED_CANDIDATES',
  /** Milestones */
  Milestones = 'MILESTONES',
  /** Ofccp */
  Ofccp = 'OFCCP',
  /** Partners */
  Partners = 'PARTNERS',
  /** Partners Candidates */
  PartnersCandidates = 'PARTNERS_CANDIDATES',
  /** Starts */
  Starts = 'STARTS',
  /** Task Groups */
  TaskGroups = 'TASK_GROUPS'
}

/** Boolean expression to compare columns of type "SearchFilterTypesEnum_enum". All fields are combined with logical 'AND'. */
export type SearchFilterTypesEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<SearchFilterTypesEnum_Enum>;
  _in?: InputMaybe<Array<SearchFilterTypesEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SearchFilterTypesEnum_Enum>;
  _nin?: InputMaybe<Array<SearchFilterTypesEnum_Enum>>;
};

/** input type for inserting data into table "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SearchFilterTypesEnum_Max_Fields = {
  __typename?: 'SearchFilterTypesEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SearchFilterTypesEnum_Min_Fields = {
  __typename?: 'SearchFilterTypesEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Mutation_Response = {
  __typename?: 'SearchFilterTypesEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SearchFilterTypesEnum>;
};

/** input type for inserting object relation for remote table "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Obj_Rel_Insert_Input = {
  data: SearchFilterTypesEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SearchFilterTypesEnum_On_Conflict>;
};

/** on_conflict condition type for table "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_On_Conflict = {
  constraint: SearchFilterTypesEnum_Constraint;
  update_columns?: Array<SearchFilterTypesEnum_Update_Column>;
  where?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "SearchFilterTypesEnum". */
export type SearchFilterTypesEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SearchFilterTypesEnum */
export type SearchFilterTypesEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "SearchFilterTypesEnum" */
export enum SearchFilterTypesEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "SearchFilterTypesEnum" */
export type SearchFilterTypesEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SearchFilterTypesEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SearchFilterTypesEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "SearchFilterTypesEnum" */
export enum SearchFilterTypesEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type SearchFilterTypesEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SearchFilterTypesEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: SearchFilterTypesEnum_Bool_Exp;
};

/** columns and relationships of "SearchFilters" */
export type SearchFilters = {
  __typename?: 'SearchFilters';
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  SearchFilterTypesEnum: SearchFilterTypesEnum;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamp'];
  default: Scalars['Boolean'];
  id: Scalars['uuid'];
  identity_id: Scalars['Int'];
  name: Scalars['String'];
  search_data: Scalars['jsonb'];
  search_filter_type_enum: SearchFilterTypesEnum_Enum;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "SearchFilters" */
export type SearchFiltersSearch_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "SearchFilters" */
export type SearchFilters_Aggregate = {
  __typename?: 'SearchFilters_aggregate';
  aggregate?: Maybe<SearchFilters_Aggregate_Fields>;
  nodes: Array<SearchFilters>;
};

/** aggregate fields of "SearchFilters" */
export type SearchFilters_Aggregate_Fields = {
  __typename?: 'SearchFilters_aggregate_fields';
  avg?: Maybe<SearchFilters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SearchFilters_Max_Fields>;
  min?: Maybe<SearchFilters_Min_Fields>;
  stddev?: Maybe<SearchFilters_Stddev_Fields>;
  stddev_pop?: Maybe<SearchFilters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SearchFilters_Stddev_Samp_Fields>;
  sum?: Maybe<SearchFilters_Sum_Fields>;
  var_pop?: Maybe<SearchFilters_Var_Pop_Fields>;
  var_samp?: Maybe<SearchFilters_Var_Samp_Fields>;
  variance?: Maybe<SearchFilters_Variance_Fields>;
};


/** aggregate fields of "SearchFilters" */
export type SearchFilters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SearchFilters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SearchFilters_Append_Input = {
  search_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type SearchFilters_Avg_Fields = {
  __typename?: 'SearchFilters_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SearchFilters". All fields are combined with a logical 'AND'. */
export type SearchFilters_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  SearchFilterTypesEnum?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
  _and?: InputMaybe<Array<SearchFilters_Bool_Exp>>;
  _not?: InputMaybe<SearchFilters_Bool_Exp>;
  _or?: InputMaybe<Array<SearchFilters_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  search_data?: InputMaybe<Jsonb_Comparison_Exp>;
  search_filter_type_enum?: InputMaybe<SearchFilterTypesEnum_Enum_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "SearchFilters" */
export enum SearchFilters_Constraint {
  /** unique or primary key constraint on columns "id" */
  SearchFiltersPkey = 'SearchFilters_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SearchFilters_Delete_At_Path_Input = {
  search_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SearchFilters_Delete_Elem_Input = {
  search_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SearchFilters_Delete_Key_Input = {
  search_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "SearchFilters" */
export type SearchFilters_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "SearchFilters" */
export type SearchFilters_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  SearchFilterTypesEnum?: InputMaybe<SearchFilterTypesEnum_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  search_data?: InputMaybe<Scalars['jsonb']>;
  search_filter_type_enum?: InputMaybe<SearchFilterTypesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type SearchFilters_Max_Fields = {
  __typename?: 'SearchFilters_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type SearchFilters_Min_Fields = {
  __typename?: 'SearchFilters_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "SearchFilters" */
export type SearchFilters_Mutation_Response = {
  __typename?: 'SearchFilters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SearchFilters>;
};

/** on_conflict condition type for table "SearchFilters" */
export type SearchFilters_On_Conflict = {
  constraint: SearchFilters_Constraint;
  update_columns?: Array<SearchFilters_Update_Column>;
  where?: InputMaybe<SearchFilters_Bool_Exp>;
};

/** Ordering options when selecting data from "SearchFilters". */
export type SearchFilters_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  SearchFilterTypesEnum?: InputMaybe<SearchFilterTypesEnum_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  search_data?: InputMaybe<Order_By>;
  search_filter_type_enum?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SearchFilters */
export type SearchFilters_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SearchFilters_Prepend_Input = {
  search_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "SearchFilters" */
export enum SearchFilters_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Name = 'name',
  /** column name */
  SearchData = 'search_data',
  /** column name */
  SearchFilterTypeEnum = 'search_filter_type_enum',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "SearchFilters" */
export type SearchFilters_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  search_data?: InputMaybe<Scalars['jsonb']>;
  search_filter_type_enum?: InputMaybe<SearchFilterTypesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type SearchFilters_Stddev_Fields = {
  __typename?: 'SearchFilters_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SearchFilters_Stddev_Pop_Fields = {
  __typename?: 'SearchFilters_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SearchFilters_Stddev_Samp_Fields = {
  __typename?: 'SearchFilters_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "SearchFilters" */
export type SearchFilters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SearchFilters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SearchFilters_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  search_data?: InputMaybe<Scalars['jsonb']>;
  search_filter_type_enum?: InputMaybe<SearchFilterTypesEnum_Enum>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type SearchFilters_Sum_Fields = {
  __typename?: 'SearchFilters_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "SearchFilters" */
export enum SearchFilters_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Default = 'default',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Name = 'name',
  /** column name */
  SearchData = 'search_data',
  /** column name */
  SearchFilterTypeEnum = 'search_filter_type_enum',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SearchFilters_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SearchFilters_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SearchFilters_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SearchFilters_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SearchFilters_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SearchFilters_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SearchFilters_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SearchFilters_Set_Input>;
  /** filter the rows which have to be updated */
  where: SearchFilters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SearchFilters_Var_Pop_Fields = {
  __typename?: 'SearchFilters_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SearchFilters_Var_Samp_Fields = {
  __typename?: 'SearchFilters_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SearchFilters_Variance_Fields = {
  __typename?: 'SearchFilters_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SecurityClearances" */
export type SecurityClearances = {
  __typename?: 'SecurityClearances';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  rank: Scalars['Int'];
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "SecurityClearances" */
export type SecurityClearances_Aggregate = {
  __typename?: 'SecurityClearances_aggregate';
  aggregate?: Maybe<SecurityClearances_Aggregate_Fields>;
  nodes: Array<SecurityClearances>;
};

/** aggregate fields of "SecurityClearances" */
export type SecurityClearances_Aggregate_Fields = {
  __typename?: 'SecurityClearances_aggregate_fields';
  avg?: Maybe<SecurityClearances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SecurityClearances_Max_Fields>;
  min?: Maybe<SecurityClearances_Min_Fields>;
  stddev?: Maybe<SecurityClearances_Stddev_Fields>;
  stddev_pop?: Maybe<SecurityClearances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SecurityClearances_Stddev_Samp_Fields>;
  sum?: Maybe<SecurityClearances_Sum_Fields>;
  var_pop?: Maybe<SecurityClearances_Var_Pop_Fields>;
  var_samp?: Maybe<SecurityClearances_Var_Samp_Fields>;
  variance?: Maybe<SecurityClearances_Variance_Fields>;
};


/** aggregate fields of "SecurityClearances" */
export type SecurityClearances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SecurityClearances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SecurityClearances_Avg_Fields = {
  __typename?: 'SecurityClearances_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SecurityClearances". All fields are combined with a logical 'AND'. */
export type SecurityClearances_Bool_Exp = {
  _and?: InputMaybe<Array<SecurityClearances_Bool_Exp>>;
  _not?: InputMaybe<SecurityClearances_Bool_Exp>;
  _or?: InputMaybe<Array<SecurityClearances_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  rank?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "SecurityClearances" */
export enum SecurityClearances_Constraint {
  /** unique or primary key constraint on columns "id" */
  SecurityClearancesPkey = 'SecurityClearances_pkey'
}

/** input type for incrementing numeric columns in table "SecurityClearances" */
export type SecurityClearances_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "SecurityClearances" */
export type SecurityClearances_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SecurityClearances_Max_Fields = {
  __typename?: 'SecurityClearances_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SecurityClearances_Min_Fields = {
  __typename?: 'SecurityClearances_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "SecurityClearances" */
export type SecurityClearances_Mutation_Response = {
  __typename?: 'SecurityClearances_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SecurityClearances>;
};

/** on_conflict condition type for table "SecurityClearances" */
export type SecurityClearances_On_Conflict = {
  constraint: SecurityClearances_Constraint;
  update_columns?: Array<SecurityClearances_Update_Column>;
  where?: InputMaybe<SecurityClearances_Bool_Exp>;
};

/** Ordering options when selecting data from "SecurityClearances". */
export type SecurityClearances_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SecurityClearances */
export type SecurityClearances_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "SecurityClearances" */
export enum SecurityClearances_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rank = 'rank',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "SecurityClearances" */
export type SecurityClearances_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SecurityClearances_Stddev_Fields = {
  __typename?: 'SecurityClearances_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SecurityClearances_Stddev_Pop_Fields = {
  __typename?: 'SecurityClearances_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SecurityClearances_Stddev_Samp_Fields = {
  __typename?: 'SecurityClearances_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "SecurityClearances" */
export type SecurityClearances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SecurityClearances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SecurityClearances_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SecurityClearances_Sum_Fields = {
  __typename?: 'SecurityClearances_sum_fields';
  id?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
};

/** update columns of table "SecurityClearances" */
export enum SecurityClearances_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Rank = 'rank',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SecurityClearances_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SecurityClearances_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SecurityClearances_Set_Input>;
  /** filter the rows which have to be updated */
  where: SecurityClearances_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SecurityClearances_Var_Pop_Fields = {
  __typename?: 'SecurityClearances_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SecurityClearances_Var_Samp_Fields = {
  __typename?: 'SecurityClearances_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SecurityClearances_Variance_Fields = {
  __typename?: 'SecurityClearances_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

export type SendCommentInput = {
  comment: Scalars['String'];
  contractId?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  identityTypeId?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Scalars['Int']>;
  objectName?: InputMaybe<Scalars['String']>;
  senderName: Scalars['String'];
  toEmail: Scalars['String'];
};

export type SendCommentOutput = {
  __typename?: 'SendCommentOutput';
  message: Scalars['String'];
};

export type SendEmailAttachment = {
  email_id?: InputMaybe<Scalars['Int']>;
  file_type?: InputMaybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  key: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  temp_attachment?: InputMaybe<Scalars['Boolean']>;
  temporary?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['String']>;
};

export type SendEmailInput = {
  attachments?: InputMaybe<Array<SendEmailAttachment>>;
  bcc?: InputMaybe<Scalars['String']>;
  body: Scalars['String'];
  cc?: InputMaybe<Scalars['String']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  fromEmail: Scalars['String'];
  is_docusign?: InputMaybe<Scalars['Boolean']>;
  renderedEmailId?: InputMaybe<Scalars['Int']>;
  sendWithNylas?: InputMaybe<Scalars['Boolean']>;
  subject: Scalars['String'];
  toEmail: Scalars['String'];
  toId?: InputMaybe<Scalars['Int']>;
};

export type SendEmailOutput = {
  __typename?: 'SendEmailOutput';
  message: Scalars['String'];
};

/** columns and relationships of "SequelizeMeta" */
export type SequelizeMeta = {
  __typename?: 'SequelizeMeta';
  name: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "SequelizeMeta" */
export type SequelizeMeta_Aggregate = {
  __typename?: 'SequelizeMeta_aggregate';
  aggregate?: Maybe<SequelizeMeta_Aggregate_Fields>;
  nodes: Array<SequelizeMeta>;
};

/** aggregate fields of "SequelizeMeta" */
export type SequelizeMeta_Aggregate_Fields = {
  __typename?: 'SequelizeMeta_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SequelizeMeta_Max_Fields>;
  min?: Maybe<SequelizeMeta_Min_Fields>;
};


/** aggregate fields of "SequelizeMeta" */
export type SequelizeMeta_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SequelizeMeta_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "SequelizeMeta". All fields are combined with a logical 'AND'. */
export type SequelizeMeta_Bool_Exp = {
  _and?: InputMaybe<Array<SequelizeMeta_Bool_Exp>>;
  _not?: InputMaybe<SequelizeMeta_Bool_Exp>;
  _or?: InputMaybe<Array<SequelizeMeta_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SequelizeMeta" */
export enum SequelizeMeta_Constraint {
  /** unique or primary key constraint on columns "name" */
  SequelizeMetaPkey = 'SequelizeMeta_pkey'
}

/** input type for inserting data into table "SequelizeMeta" */
export type SequelizeMeta_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SequelizeMeta_Max_Fields = {
  __typename?: 'SequelizeMeta_max_fields';
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SequelizeMeta_Min_Fields = {
  __typename?: 'SequelizeMeta_min_fields';
  name?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "SequelizeMeta" */
export type SequelizeMeta_Mutation_Response = {
  __typename?: 'SequelizeMeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SequelizeMeta>;
};

/** on_conflict condition type for table "SequelizeMeta" */
export type SequelizeMeta_On_Conflict = {
  constraint: SequelizeMeta_Constraint;
  update_columns?: Array<SequelizeMeta_Update_Column>;
  where?: InputMaybe<SequelizeMeta_Bool_Exp>;
};

/** Ordering options when selecting data from "SequelizeMeta". */
export type SequelizeMeta_Order_By = {
  name?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SequelizeMeta */
export type SequelizeMeta_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "SequelizeMeta" */
export enum SequelizeMeta_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "SequelizeMeta" */
export type SequelizeMeta_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "SequelizeMeta" */
export type SequelizeMeta_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SequelizeMeta_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SequelizeMeta_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** update columns of table "SequelizeMeta" */
export enum SequelizeMeta_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type SequelizeMeta_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SequelizeMeta_Set_Input>;
  /** filter the rows which have to be updated */
  where: SequelizeMeta_Bool_Exp;
};

export type Signer = {
  signerEmail: Scalars['String'];
  signerId: Scalars['Int'];
  signerName: Scalars['String'];
};

/** Tie contracts to emails that get rendered at certain stages. */
export type StageEmailContracts = {
  __typename?: 'StageEmailContracts';
  contract_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  stage_email_id: Scalars['uuid'];
};

/** aggregated selection of "StageEmailContracts" */
export type StageEmailContracts_Aggregate = {
  __typename?: 'StageEmailContracts_aggregate';
  aggregate?: Maybe<StageEmailContracts_Aggregate_Fields>;
  nodes: Array<StageEmailContracts>;
};

export type StageEmailContracts_Aggregate_Bool_Exp = {
  count?: InputMaybe<StageEmailContracts_Aggregate_Bool_Exp_Count>;
};

export type StageEmailContracts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageEmailContracts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "StageEmailContracts" */
export type StageEmailContracts_Aggregate_Fields = {
  __typename?: 'StageEmailContracts_aggregate_fields';
  avg?: Maybe<StageEmailContracts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StageEmailContracts_Max_Fields>;
  min?: Maybe<StageEmailContracts_Min_Fields>;
  stddev?: Maybe<StageEmailContracts_Stddev_Fields>;
  stddev_pop?: Maybe<StageEmailContracts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StageEmailContracts_Stddev_Samp_Fields>;
  sum?: Maybe<StageEmailContracts_Sum_Fields>;
  var_pop?: Maybe<StageEmailContracts_Var_Pop_Fields>;
  var_samp?: Maybe<StageEmailContracts_Var_Samp_Fields>;
  variance?: Maybe<StageEmailContracts_Variance_Fields>;
};


/** aggregate fields of "StageEmailContracts" */
export type StageEmailContracts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StageEmailContracts" */
export type StageEmailContracts_Aggregate_Order_By = {
  avg?: InputMaybe<StageEmailContracts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StageEmailContracts_Max_Order_By>;
  min?: InputMaybe<StageEmailContracts_Min_Order_By>;
  stddev?: InputMaybe<StageEmailContracts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<StageEmailContracts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<StageEmailContracts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<StageEmailContracts_Sum_Order_By>;
  var_pop?: InputMaybe<StageEmailContracts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<StageEmailContracts_Var_Samp_Order_By>;
  variance?: InputMaybe<StageEmailContracts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "StageEmailContracts" */
export type StageEmailContracts_Arr_Rel_Insert_Input = {
  data: Array<StageEmailContracts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StageEmailContracts_On_Conflict>;
};

/** aggregate avg on columns */
export type StageEmailContracts_Avg_Fields = {
  __typename?: 'StageEmailContracts_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StageEmailContracts". All fields are combined with a logical 'AND'. */
export type StageEmailContracts_Bool_Exp = {
  _and?: InputMaybe<Array<StageEmailContracts_Bool_Exp>>;
  _not?: InputMaybe<StageEmailContracts_Bool_Exp>;
  _or?: InputMaybe<Array<StageEmailContracts_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  stage_email_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "StageEmailContracts" */
export enum StageEmailContracts_Constraint {
  /** unique or primary key constraint on columns "id" */
  StageEmailContractsPkey = 'StageEmailContracts_pkey',
  /** unique or primary key constraint on columns "stage_email_id", "contract_id" */
  StageEmailContractsStageEmailIdContractIdKey = 'StageEmailContracts_stage_email_id_contract_id_key'
}

/** input type for incrementing numeric columns in table "StageEmailContracts" */
export type StageEmailContracts_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "StageEmailContracts" */
export type StageEmailContracts_Insert_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  stage_email_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type StageEmailContracts_Max_Fields = {
  __typename?: 'StageEmailContracts_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  stage_email_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_email_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StageEmailContracts_Min_Fields = {
  __typename?: 'StageEmailContracts_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  stage_email_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_email_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "StageEmailContracts" */
export type StageEmailContracts_Mutation_Response = {
  __typename?: 'StageEmailContracts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StageEmailContracts>;
};

/** on_conflict condition type for table "StageEmailContracts" */
export type StageEmailContracts_On_Conflict = {
  constraint: StageEmailContracts_Constraint;
  update_columns?: Array<StageEmailContracts_Update_Column>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};

/** Ordering options when selecting data from "StageEmailContracts". */
export type StageEmailContracts_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage_email_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StageEmailContracts */
export type StageEmailContracts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "StageEmailContracts" */
export enum StageEmailContracts_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StageEmailId = 'stage_email_id'
}

/** input type for updating data in table "StageEmailContracts" */
export type StageEmailContracts_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  stage_email_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type StageEmailContracts_Stddev_Fields = {
  __typename?: 'StageEmailContracts_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StageEmailContracts_Stddev_Pop_Fields = {
  __typename?: 'StageEmailContracts_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StageEmailContracts_Stddev_Samp_Fields = {
  __typename?: 'StageEmailContracts_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "StageEmailContracts" */
export type StageEmailContracts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StageEmailContracts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StageEmailContracts_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  stage_email_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type StageEmailContracts_Sum_Fields = {
  __typename?: 'StageEmailContracts_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** update columns of table "StageEmailContracts" */
export enum StageEmailContracts_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StageEmailId = 'stage_email_id'
}

export type StageEmailContracts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StageEmailContracts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StageEmailContracts_Set_Input>;
  /** filter the rows which have to be updated */
  where: StageEmailContracts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type StageEmailContracts_Var_Pop_Fields = {
  __typename?: 'StageEmailContracts_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StageEmailContracts_Var_Samp_Fields = {
  __typename?: 'StageEmailContracts_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type StageEmailContracts_Variance_Fields = {
  __typename?: 'StageEmailContracts_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StageEmailContracts" */
export type StageEmailContracts_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
};

/** Tracks the emails that are associated with a particular stage */
export type StageEmails = {
  __typename?: 'StageEmails';
  /** An object relationship */
  Email: Emails;
  /** An object relationship */
  IdentitySubType?: Maybe<IdentitySubTypes>;
  /** An object relationship */
  Stage: Stages;
  /** An array relationship */
  StageEmailContracts: Array<StageEmailContracts>;
  /** An aggregate relationship */
  StageEmailContracts_aggregate: StageEmailContracts_Aggregate;
  email_id: Scalars['Int'];
  id: Scalars['uuid'];
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  stage_id: Scalars['Int'];
};


/** Tracks the emails that are associated with a particular stage */
export type StageEmailsStageEmailContractsArgs = {
  distinct_on?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmailContracts_Order_By>>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};


/** Tracks the emails that are associated with a particular stage */
export type StageEmailsStageEmailContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmailContracts_Order_By>>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};

/** aggregated selection of "StageEmails" */
export type StageEmails_Aggregate = {
  __typename?: 'StageEmails_aggregate';
  aggregate?: Maybe<StageEmails_Aggregate_Fields>;
  nodes: Array<StageEmails>;
};

export type StageEmails_Aggregate_Bool_Exp = {
  count?: InputMaybe<StageEmails_Aggregate_Bool_Exp_Count>;
};

export type StageEmails_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StageEmails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageEmails_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "StageEmails" */
export type StageEmails_Aggregate_Fields = {
  __typename?: 'StageEmails_aggregate_fields';
  avg?: Maybe<StageEmails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StageEmails_Max_Fields>;
  min?: Maybe<StageEmails_Min_Fields>;
  stddev?: Maybe<StageEmails_Stddev_Fields>;
  stddev_pop?: Maybe<StageEmails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StageEmails_Stddev_Samp_Fields>;
  sum?: Maybe<StageEmails_Sum_Fields>;
  var_pop?: Maybe<StageEmails_Var_Pop_Fields>;
  var_samp?: Maybe<StageEmails_Var_Samp_Fields>;
  variance?: Maybe<StageEmails_Variance_Fields>;
};


/** aggregate fields of "StageEmails" */
export type StageEmails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StageEmails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StageEmails" */
export type StageEmails_Aggregate_Order_By = {
  avg?: InputMaybe<StageEmails_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StageEmails_Max_Order_By>;
  min?: InputMaybe<StageEmails_Min_Order_By>;
  stddev?: InputMaybe<StageEmails_Stddev_Order_By>;
  stddev_pop?: InputMaybe<StageEmails_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<StageEmails_Stddev_Samp_Order_By>;
  sum?: InputMaybe<StageEmails_Sum_Order_By>;
  var_pop?: InputMaybe<StageEmails_Var_Pop_Order_By>;
  var_samp?: InputMaybe<StageEmails_Var_Samp_Order_By>;
  variance?: InputMaybe<StageEmails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "StageEmails" */
export type StageEmails_Arr_Rel_Insert_Input = {
  data: Array<StageEmails_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StageEmails_On_Conflict>;
};

/** aggregate avg on columns */
export type StageEmails_Avg_Fields = {
  __typename?: 'StageEmails_avg_fields';
  email_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StageEmails" */
export type StageEmails_Avg_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StageEmails". All fields are combined with a logical 'AND'. */
export type StageEmails_Bool_Exp = {
  Email?: InputMaybe<Emails_Bool_Exp>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  StageEmailContracts?: InputMaybe<StageEmailContracts_Bool_Exp>;
  StageEmailContracts_aggregate?: InputMaybe<StageEmailContracts_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<StageEmails_Bool_Exp>>;
  _not?: InputMaybe<StageEmails_Bool_Exp>;
  _or?: InputMaybe<Array<StageEmails_Bool_Exp>>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_sub_type_id?: InputMaybe<Int_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "StageEmails" */
export enum StageEmails_Constraint {
  /** unique or primary key constraint on columns "email_id", "stage_id" */
  StageEmailsEmailIdStageIdKey = 'StageEmails_email_id_stage_id_key',
  /** unique or primary key constraint on columns "id" */
  StageEmailsPkey = 'StageEmails_pkey'
}

/** input type for incrementing numeric columns in table "StageEmails" */
export type StageEmails_Inc_Input = {
  email_id?: InputMaybe<Scalars['Int']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "StageEmails" */
export type StageEmails_Insert_Input = {
  Email?: InputMaybe<Emails_Obj_Rel_Insert_Input>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  StageEmailContracts?: InputMaybe<StageEmailContracts_Arr_Rel_Insert_Input>;
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type StageEmails_Max_Fields = {
  __typename?: 'StageEmails_max_fields';
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "StageEmails" */
export type StageEmails_Max_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StageEmails_Min_Fields = {
  __typename?: 'StageEmails_min_fields';
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "StageEmails" */
export type StageEmails_Min_Order_By = {
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "StageEmails" */
export type StageEmails_Mutation_Response = {
  __typename?: 'StageEmails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StageEmails>;
};

/** on_conflict condition type for table "StageEmails" */
export type StageEmails_On_Conflict = {
  constraint: StageEmails_Constraint;
  update_columns?: Array<StageEmails_Update_Column>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};

/** Ordering options when selecting data from "StageEmails". */
export type StageEmails_Order_By = {
  Email?: InputMaybe<Emails_Order_By>;
  IdentitySubType?: InputMaybe<IdentitySubTypes_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  StageEmailContracts_aggregate?: InputMaybe<StageEmailContracts_Aggregate_Order_By>;
  email_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StageEmails */
export type StageEmails_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "StageEmails" */
export enum StageEmails_Select_Column {
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentitySubTypeId = 'identity_sub_type_id',
  /** column name */
  StageId = 'stage_id'
}

/** input type for updating data in table "StageEmails" */
export type StageEmails_Set_Input = {
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type StageEmails_Stddev_Fields = {
  __typename?: 'StageEmails_stddev_fields';
  email_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StageEmails" */
export type StageEmails_Stddev_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StageEmails_Stddev_Pop_Fields = {
  __typename?: 'StageEmails_stddev_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StageEmails" */
export type StageEmails_Stddev_Pop_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StageEmails_Stddev_Samp_Fields = {
  __typename?: 'StageEmails_stddev_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StageEmails" */
export type StageEmails_Stddev_Samp_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "StageEmails" */
export type StageEmails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StageEmails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StageEmails_Stream_Cursor_Value_Input = {
  email_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_sub_type_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type StageEmails_Sum_Fields = {
  __typename?: 'StageEmails_sum_fields';
  email_id?: Maybe<Scalars['Int']>;
  identity_sub_type_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StageEmails" */
export type StageEmails_Sum_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** update columns of table "StageEmails" */
export enum StageEmails_Update_Column {
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentitySubTypeId = 'identity_sub_type_id',
  /** column name */
  StageId = 'stage_id'
}

export type StageEmails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StageEmails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StageEmails_Set_Input>;
  /** filter the rows which have to be updated */
  where: StageEmails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type StageEmails_Var_Pop_Fields = {
  __typename?: 'StageEmails_var_pop_fields';
  email_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StageEmails" */
export type StageEmails_Var_Pop_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StageEmails_Var_Samp_Fields = {
  __typename?: 'StageEmails_var_samp_fields';
  email_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StageEmails" */
export type StageEmails_Var_Samp_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type StageEmails_Variance_Fields = {
  __typename?: 'StageEmails_variance_fields';
  email_id?: Maybe<Scalars['Float']>;
  identity_sub_type_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StageEmails" */
export type StageEmails_Variance_Order_By = {
  email_id?: InputMaybe<Order_By>;
  identity_sub_type_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Maps templates for Questionnaires to a Stage */
export type StageQuestionnaireMapping = {
  __typename?: 'StageQuestionnaireMapping';
  /** An object relationship */
  Questionnaire?: Maybe<Questionnaires>;
  /** An object relationship */
  Stage: Stages;
  /** An array relationship */
  StageQuestionnaireMappingIdenities: Array<StageQuestionnaireMappingIdenity>;
  /** An aggregate relationship */
  StageQuestionnaireMappingIdenities_aggregate: StageQuestionnaireMappingIdenity_Aggregate;
  /** An array relationship */
  StageQuestionnaireTemplateVariables: Array<StageQuestionnaireTemplateVariables>;
  /** An aggregate relationship */
  StageQuestionnaireTemplateVariables_aggregate: StageQuestionnaireTemplateVariables_Aggregate;
  id: Scalars['uuid'];
  is_matched_contract?: Maybe<Scalars['Boolean']>;
  is_matched_job: Scalars['Boolean'];
  questionnaire_id?: Maybe<Scalars['uuid']>;
  stage_id: Scalars['Int'];
};


/** Maps templates for Questionnaires to a Stage */
export type StageQuestionnaireMappingStageQuestionnaireMappingIdenitiesArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};


/** Maps templates for Questionnaires to a Stage */
export type StageQuestionnaireMappingStageQuestionnaireMappingIdenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};


/** Maps templates for Questionnaires to a Stage */
export type StageQuestionnaireMappingStageQuestionnaireTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Order_By>>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};


/** Maps templates for Questionnaires to a Stage */
export type StageQuestionnaireMappingStageQuestionnaireTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Order_By>>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};

/** Map Identity to StageQuestionnaireMapping table  */
export type StageQuestionnaireMappingIdenity = {
  __typename?: 'StageQuestionnaireMappingIdenity';
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  StageQuestionnaireMapping: StageQuestionnaireMapping;
  identity_id: Scalars['Int'];
  stage_questionnaire_mapping_id: Scalars['uuid'];
};

/** aggregated selection of "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Aggregate = {
  __typename?: 'StageQuestionnaireMappingIdenity_aggregate';
  aggregate?: Maybe<StageQuestionnaireMappingIdenity_Aggregate_Fields>;
  nodes: Array<StageQuestionnaireMappingIdenity>;
};

export type StageQuestionnaireMappingIdenity_Aggregate_Bool_Exp = {
  count?: InputMaybe<StageQuestionnaireMappingIdenity_Aggregate_Bool_Exp_Count>;
};

export type StageQuestionnaireMappingIdenity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Aggregate_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_aggregate_fields';
  avg?: Maybe<StageQuestionnaireMappingIdenity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StageQuestionnaireMappingIdenity_Max_Fields>;
  min?: Maybe<StageQuestionnaireMappingIdenity_Min_Fields>;
  stddev?: Maybe<StageQuestionnaireMappingIdenity_Stddev_Fields>;
  stddev_pop?: Maybe<StageQuestionnaireMappingIdenity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StageQuestionnaireMappingIdenity_Stddev_Samp_Fields>;
  sum?: Maybe<StageQuestionnaireMappingIdenity_Sum_Fields>;
  var_pop?: Maybe<StageQuestionnaireMappingIdenity_Var_Pop_Fields>;
  var_samp?: Maybe<StageQuestionnaireMappingIdenity_Var_Samp_Fields>;
  variance?: Maybe<StageQuestionnaireMappingIdenity_Variance_Fields>;
};


/** aggregate fields of "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Aggregate_Order_By = {
  avg?: InputMaybe<StageQuestionnaireMappingIdenity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StageQuestionnaireMappingIdenity_Max_Order_By>;
  min?: InputMaybe<StageQuestionnaireMappingIdenity_Min_Order_By>;
  stddev?: InputMaybe<StageQuestionnaireMappingIdenity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<StageQuestionnaireMappingIdenity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<StageQuestionnaireMappingIdenity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<StageQuestionnaireMappingIdenity_Sum_Order_By>;
  var_pop?: InputMaybe<StageQuestionnaireMappingIdenity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<StageQuestionnaireMappingIdenity_Var_Samp_Order_By>;
  variance?: InputMaybe<StageQuestionnaireMappingIdenity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Arr_Rel_Insert_Input = {
  data: Array<StageQuestionnaireMappingIdenity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StageQuestionnaireMappingIdenity_On_Conflict>;
};

/** aggregate avg on columns */
export type StageQuestionnaireMappingIdenity_Avg_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_avg_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Avg_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StageQuestionnaireMappingIdenity". All fields are combined with a logical 'AND'. */
export type StageQuestionnaireMappingIdenity_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  StageQuestionnaireMapping?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
  _and?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Bool_Exp>>;
  _not?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
  _or?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Bool_Exp>>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  stage_questionnaire_mapping_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "StageQuestionnaireMappingIdenity" */
export enum StageQuestionnaireMappingIdenity_Constraint {
  /** unique or primary key constraint on columns "identity_id", "stage_questionnaire_mapping_id" */
  StageQuestionnaireMappingIdenityPkey = 'StageQuestionnaireMappingIdenity_pkey'
}

/** input type for incrementing numeric columns in table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Inc_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  StageQuestionnaireMapping?: InputMaybe<StageQuestionnaireMapping_Obj_Rel_Insert_Input>;
  identity_id?: InputMaybe<Scalars['Int']>;
  stage_questionnaire_mapping_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type StageQuestionnaireMappingIdenity_Max_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_max_fields';
  identity_id?: Maybe<Scalars['Int']>;
  stage_questionnaire_mapping_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Max_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  stage_questionnaire_mapping_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StageQuestionnaireMappingIdenity_Min_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_min_fields';
  identity_id?: Maybe<Scalars['Int']>;
  stage_questionnaire_mapping_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Min_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  stage_questionnaire_mapping_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Mutation_Response = {
  __typename?: 'StageQuestionnaireMappingIdenity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StageQuestionnaireMappingIdenity>;
};

/** on_conflict condition type for table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_On_Conflict = {
  constraint: StageQuestionnaireMappingIdenity_Constraint;
  update_columns?: Array<StageQuestionnaireMappingIdenity_Update_Column>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};

/** Ordering options when selecting data from "StageQuestionnaireMappingIdenity". */
export type StageQuestionnaireMappingIdenity_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  StageQuestionnaireMapping?: InputMaybe<StageQuestionnaireMapping_Order_By>;
  identity_id?: InputMaybe<Order_By>;
  stage_questionnaire_mapping_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StageQuestionnaireMappingIdenity */
export type StageQuestionnaireMappingIdenity_Pk_Columns_Input = {
  identity_id: Scalars['Int'];
  stage_questionnaire_mapping_id: Scalars['uuid'];
};

/** select columns of table "StageQuestionnaireMappingIdenity" */
export enum StageQuestionnaireMappingIdenity_Select_Column {
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  StageQuestionnaireMappingId = 'stage_questionnaire_mapping_id'
}

/** input type for updating data in table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Set_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
  stage_questionnaire_mapping_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type StageQuestionnaireMappingIdenity_Stddev_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_stddev_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Stddev_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StageQuestionnaireMappingIdenity_Stddev_Pop_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_stddev_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Stddev_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StageQuestionnaireMappingIdenity_Stddev_Samp_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_stddev_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Stddev_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StageQuestionnaireMappingIdenity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StageQuestionnaireMappingIdenity_Stream_Cursor_Value_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
  stage_questionnaire_mapping_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type StageQuestionnaireMappingIdenity_Sum_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_sum_fields';
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Sum_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "StageQuestionnaireMappingIdenity" */
export enum StageQuestionnaireMappingIdenity_Update_Column {
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  StageQuestionnaireMappingId = 'stage_questionnaire_mapping_id'
}

export type StageQuestionnaireMappingIdenity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StageQuestionnaireMappingIdenity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StageQuestionnaireMappingIdenity_Set_Input>;
  /** filter the rows which have to be updated */
  where: StageQuestionnaireMappingIdenity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type StageQuestionnaireMappingIdenity_Var_Pop_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_var_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Var_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StageQuestionnaireMappingIdenity_Var_Samp_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_var_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Var_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type StageQuestionnaireMappingIdenity_Variance_Fields = {
  __typename?: 'StageQuestionnaireMappingIdenity_variance_fields';
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StageQuestionnaireMappingIdenity" */
export type StageQuestionnaireMappingIdenity_Variance_Order_By = {
  identity_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Aggregate = {
  __typename?: 'StageQuestionnaireMapping_aggregate';
  aggregate?: Maybe<StageQuestionnaireMapping_Aggregate_Fields>;
  nodes: Array<StageQuestionnaireMapping>;
};

export type StageQuestionnaireMapping_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<StageQuestionnaireMapping_Aggregate_Bool_Exp_Count>;
};

export type StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_And = {
  arguments: StageQuestionnaireMapping_Select_Column_StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_Or = {
  arguments: StageQuestionnaireMapping_Select_Column_StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type StageQuestionnaireMapping_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Aggregate_Fields = {
  __typename?: 'StageQuestionnaireMapping_aggregate_fields';
  avg?: Maybe<StageQuestionnaireMapping_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StageQuestionnaireMapping_Max_Fields>;
  min?: Maybe<StageQuestionnaireMapping_Min_Fields>;
  stddev?: Maybe<StageQuestionnaireMapping_Stddev_Fields>;
  stddev_pop?: Maybe<StageQuestionnaireMapping_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StageQuestionnaireMapping_Stddev_Samp_Fields>;
  sum?: Maybe<StageQuestionnaireMapping_Sum_Fields>;
  var_pop?: Maybe<StageQuestionnaireMapping_Var_Pop_Fields>;
  var_samp?: Maybe<StageQuestionnaireMapping_Var_Samp_Fields>;
  variance?: Maybe<StageQuestionnaireMapping_Variance_Fields>;
};


/** aggregate fields of "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Aggregate_Order_By = {
  avg?: InputMaybe<StageQuestionnaireMapping_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StageQuestionnaireMapping_Max_Order_By>;
  min?: InputMaybe<StageQuestionnaireMapping_Min_Order_By>;
  stddev?: InputMaybe<StageQuestionnaireMapping_Stddev_Order_By>;
  stddev_pop?: InputMaybe<StageQuestionnaireMapping_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<StageQuestionnaireMapping_Stddev_Samp_Order_By>;
  sum?: InputMaybe<StageQuestionnaireMapping_Sum_Order_By>;
  var_pop?: InputMaybe<StageQuestionnaireMapping_Var_Pop_Order_By>;
  var_samp?: InputMaybe<StageQuestionnaireMapping_Var_Samp_Order_By>;
  variance?: InputMaybe<StageQuestionnaireMapping_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Arr_Rel_Insert_Input = {
  data: Array<StageQuestionnaireMapping_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StageQuestionnaireMapping_On_Conflict>;
};

/** aggregate avg on columns */
export type StageQuestionnaireMapping_Avg_Fields = {
  __typename?: 'StageQuestionnaireMapping_avg_fields';
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Avg_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StageQuestionnaireMapping". All fields are combined with a logical 'AND'. */
export type StageQuestionnaireMapping_Bool_Exp = {
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  StageQuestionnaireMappingIdenities?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
  StageQuestionnaireMappingIdenities_aggregate?: InputMaybe<StageQuestionnaireMappingIdenity_Aggregate_Bool_Exp>;
  StageQuestionnaireTemplateVariables?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
  StageQuestionnaireTemplateVariables_aggregate?: InputMaybe<StageQuestionnaireTemplateVariables_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<StageQuestionnaireMapping_Bool_Exp>>;
  _not?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
  _or?: InputMaybe<Array<StageQuestionnaireMapping_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_matched_contract?: InputMaybe<Boolean_Comparison_Exp>;
  is_matched_job?: InputMaybe<Boolean_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "StageQuestionnaireMapping" */
export enum StageQuestionnaireMapping_Constraint {
  /** unique or primary key constraint on columns "id" */
  StageQuestionnaireMappingPkey = 'StageQuestionnaireMapping_pkey'
}

/** input type for incrementing numeric columns in table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Inc_Input = {
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Insert_Input = {
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  StageQuestionnaireMappingIdenities?: InputMaybe<StageQuestionnaireMappingIdenity_Arr_Rel_Insert_Input>;
  StageQuestionnaireTemplateVariables?: InputMaybe<StageQuestionnaireTemplateVariables_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_matched_contract?: InputMaybe<Scalars['Boolean']>;
  is_matched_job?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type StageQuestionnaireMapping_Max_Fields = {
  __typename?: 'StageQuestionnaireMapping_max_fields';
  id?: Maybe<Scalars['uuid']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StageQuestionnaireMapping_Min_Fields = {
  __typename?: 'StageQuestionnaireMapping_min_fields';
  id?: Maybe<Scalars['uuid']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Mutation_Response = {
  __typename?: 'StageQuestionnaireMapping_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StageQuestionnaireMapping>;
};

/** input type for inserting object relation for remote table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Obj_Rel_Insert_Input = {
  data: StageQuestionnaireMapping_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<StageQuestionnaireMapping_On_Conflict>;
};

/** on_conflict condition type for table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_On_Conflict = {
  constraint: StageQuestionnaireMapping_Constraint;
  update_columns?: Array<StageQuestionnaireMapping_Update_Column>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};

/** Ordering options when selecting data from "StageQuestionnaireMapping". */
export type StageQuestionnaireMapping_Order_By = {
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  StageQuestionnaireMappingIdenities_aggregate?: InputMaybe<StageQuestionnaireMappingIdenity_Aggregate_Order_By>;
  StageQuestionnaireTemplateVariables_aggregate?: InputMaybe<StageQuestionnaireTemplateVariables_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_matched_contract?: InputMaybe<Order_By>;
  is_matched_job?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StageQuestionnaireMapping */
export type StageQuestionnaireMapping_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "StageQuestionnaireMapping" */
export enum StageQuestionnaireMapping_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsMatchedContract = 'is_matched_contract',
  /** column name */
  IsMatchedJob = 'is_matched_job',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  StageId = 'stage_id'
}

/** select "StageQuestionnaireMapping_aggregate_bool_exp_bool_and_arguments_columns" columns of table "StageQuestionnaireMapping" */
export enum StageQuestionnaireMapping_Select_Column_StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsMatchedContract = 'is_matched_contract',
  /** column name */
  IsMatchedJob = 'is_matched_job'
}

/** select "StageQuestionnaireMapping_aggregate_bool_exp_bool_or_arguments_columns" columns of table "StageQuestionnaireMapping" */
export enum StageQuestionnaireMapping_Select_Column_StageQuestionnaireMapping_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsMatchedContract = 'is_matched_contract',
  /** column name */
  IsMatchedJob = 'is_matched_job'
}

/** input type for updating data in table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  is_matched_contract?: InputMaybe<Scalars['Boolean']>;
  is_matched_job?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type StageQuestionnaireMapping_Stddev_Fields = {
  __typename?: 'StageQuestionnaireMapping_stddev_fields';
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Stddev_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StageQuestionnaireMapping_Stddev_Pop_Fields = {
  __typename?: 'StageQuestionnaireMapping_stddev_pop_fields';
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Stddev_Pop_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StageQuestionnaireMapping_Stddev_Samp_Fields = {
  __typename?: 'StageQuestionnaireMapping_stddev_samp_fields';
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Stddev_Samp_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StageQuestionnaireMapping_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StageQuestionnaireMapping_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  is_matched_contract?: InputMaybe<Scalars['Boolean']>;
  is_matched_job?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type StageQuestionnaireMapping_Sum_Fields = {
  __typename?: 'StageQuestionnaireMapping_sum_fields';
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Sum_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** update columns of table "StageQuestionnaireMapping" */
export enum StageQuestionnaireMapping_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsMatchedContract = 'is_matched_contract',
  /** column name */
  IsMatchedJob = 'is_matched_job',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  StageId = 'stage_id'
}

export type StageQuestionnaireMapping_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StageQuestionnaireMapping_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StageQuestionnaireMapping_Set_Input>;
  /** filter the rows which have to be updated */
  where: StageQuestionnaireMapping_Bool_Exp;
};

/** aggregate var_pop on columns */
export type StageQuestionnaireMapping_Var_Pop_Fields = {
  __typename?: 'StageQuestionnaireMapping_var_pop_fields';
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Var_Pop_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StageQuestionnaireMapping_Var_Samp_Fields = {
  __typename?: 'StageQuestionnaireMapping_var_samp_fields';
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Var_Samp_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type StageQuestionnaireMapping_Variance_Fields = {
  __typename?: 'StageQuestionnaireMapping_variance_fields';
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StageQuestionnaireMapping" */
export type StageQuestionnaireMapping_Variance_Order_By = {
  stage_id?: InputMaybe<Order_By>;
};

/** Maps Template variables to stage questionnaires  */
export type StageQuestionnaireTemplateVariables = {
  __typename?: 'StageQuestionnaireTemplateVariables';
  /** An object relationship */
  ContactTemplateVariablesEnum: ContactTemplateVariablesEnum;
  /** An object relationship */
  StageQuestionnaireMapping: StageQuestionnaireMapping;
  contact_variable: ContactTemplateVariablesEnum_Enum;
  stage_questionnaire_mapping_id: Scalars['uuid'];
};

/** aggregated selection of "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Aggregate = {
  __typename?: 'StageQuestionnaireTemplateVariables_aggregate';
  aggregate?: Maybe<StageQuestionnaireTemplateVariables_Aggregate_Fields>;
  nodes: Array<StageQuestionnaireTemplateVariables>;
};

export type StageQuestionnaireTemplateVariables_Aggregate_Bool_Exp = {
  count?: InputMaybe<StageQuestionnaireTemplateVariables_Aggregate_Bool_Exp_Count>;
};

export type StageQuestionnaireTemplateVariables_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Aggregate_Fields = {
  __typename?: 'StageQuestionnaireTemplateVariables_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<StageQuestionnaireTemplateVariables_Max_Fields>;
  min?: Maybe<StageQuestionnaireTemplateVariables_Min_Fields>;
};


/** aggregate fields of "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StageQuestionnaireTemplateVariables_Max_Order_By>;
  min?: InputMaybe<StageQuestionnaireTemplateVariables_Min_Order_By>;
};

/** input type for inserting array relation for remote table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Arr_Rel_Insert_Input = {
  data: Array<StageQuestionnaireTemplateVariables_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StageQuestionnaireTemplateVariables_On_Conflict>;
};

/** Boolean expression to filter rows from the table "StageQuestionnaireTemplateVariables". All fields are combined with a logical 'AND'. */
export type StageQuestionnaireTemplateVariables_Bool_Exp = {
  ContactTemplateVariablesEnum?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
  StageQuestionnaireMapping?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
  _and?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Bool_Exp>>;
  _not?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
  _or?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Bool_Exp>>;
  contact_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum_Comparison_Exp>;
  stage_questionnaire_mapping_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "StageQuestionnaireTemplateVariables" */
export enum StageQuestionnaireTemplateVariables_Constraint {
  /** unique or primary key constraint on columns "stage_questionnaire_mapping_id", "contact_variable" */
  StageQuestionnaireTemplateVariablesPkey = 'StageQuestionnaireTemplateVariables_pkey'
}

/** input type for inserting data into table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Insert_Input = {
  ContactTemplateVariablesEnum?: InputMaybe<ContactTemplateVariablesEnum_Obj_Rel_Insert_Input>;
  StageQuestionnaireMapping?: InputMaybe<StageQuestionnaireMapping_Obj_Rel_Insert_Input>;
  contact_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  stage_questionnaire_mapping_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type StageQuestionnaireTemplateVariables_Max_Fields = {
  __typename?: 'StageQuestionnaireTemplateVariables_max_fields';
  stage_questionnaire_mapping_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Max_Order_By = {
  stage_questionnaire_mapping_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StageQuestionnaireTemplateVariables_Min_Fields = {
  __typename?: 'StageQuestionnaireTemplateVariables_min_fields';
  stage_questionnaire_mapping_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Min_Order_By = {
  stage_questionnaire_mapping_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Mutation_Response = {
  __typename?: 'StageQuestionnaireTemplateVariables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StageQuestionnaireTemplateVariables>;
};

/** on_conflict condition type for table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_On_Conflict = {
  constraint: StageQuestionnaireTemplateVariables_Constraint;
  update_columns?: Array<StageQuestionnaireTemplateVariables_Update_Column>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};

/** Ordering options when selecting data from "StageQuestionnaireTemplateVariables". */
export type StageQuestionnaireTemplateVariables_Order_By = {
  ContactTemplateVariablesEnum?: InputMaybe<ContactTemplateVariablesEnum_Order_By>;
  StageQuestionnaireMapping?: InputMaybe<StageQuestionnaireMapping_Order_By>;
  contact_variable?: InputMaybe<Order_By>;
  stage_questionnaire_mapping_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StageQuestionnaireTemplateVariables */
export type StageQuestionnaireTemplateVariables_Pk_Columns_Input = {
  contact_variable: ContactTemplateVariablesEnum_Enum;
  stage_questionnaire_mapping_id: Scalars['uuid'];
};

/** select columns of table "StageQuestionnaireTemplateVariables" */
export enum StageQuestionnaireTemplateVariables_Select_Column {
  /** column name */
  ContactVariable = 'contact_variable',
  /** column name */
  StageQuestionnaireMappingId = 'stage_questionnaire_mapping_id'
}

/** input type for updating data in table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Set_Input = {
  contact_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  stage_questionnaire_mapping_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "StageQuestionnaireTemplateVariables" */
export type StageQuestionnaireTemplateVariables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StageQuestionnaireTemplateVariables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StageQuestionnaireTemplateVariables_Stream_Cursor_Value_Input = {
  contact_variable?: InputMaybe<ContactTemplateVariablesEnum_Enum>;
  stage_questionnaire_mapping_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "StageQuestionnaireTemplateVariables" */
export enum StageQuestionnaireTemplateVariables_Update_Column {
  /** column name */
  ContactVariable = 'contact_variable',
  /** column name */
  StageQuestionnaireMappingId = 'stage_questionnaire_mapping_id'
}

export type StageQuestionnaireTemplateVariables_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StageQuestionnaireTemplateVariables_Set_Input>;
  /** filter the rows which have to be updated */
  where: StageQuestionnaireTemplateVariables_Bool_Exp;
};

/** columns and relationships of "StageRoles" */
export type StageRoles = {
  __typename?: 'StageRoles';
  /** An object relationship */
  Role?: Maybe<Roles>;
  /** An object relationship */
  Stage?: Maybe<Stages>;
  cognito_group?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  role_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "StageRoles" */
export type StageRoles_Aggregate = {
  __typename?: 'StageRoles_aggregate';
  aggregate?: Maybe<StageRoles_Aggregate_Fields>;
  nodes: Array<StageRoles>;
};

export type StageRoles_Aggregate_Bool_Exp = {
  count?: InputMaybe<StageRoles_Aggregate_Bool_Exp_Count>;
};

export type StageRoles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StageRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StageRoles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "StageRoles" */
export type StageRoles_Aggregate_Fields = {
  __typename?: 'StageRoles_aggregate_fields';
  avg?: Maybe<StageRoles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StageRoles_Max_Fields>;
  min?: Maybe<StageRoles_Min_Fields>;
  stddev?: Maybe<StageRoles_Stddev_Fields>;
  stddev_pop?: Maybe<StageRoles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StageRoles_Stddev_Samp_Fields>;
  sum?: Maybe<StageRoles_Sum_Fields>;
  var_pop?: Maybe<StageRoles_Var_Pop_Fields>;
  var_samp?: Maybe<StageRoles_Var_Samp_Fields>;
  variance?: Maybe<StageRoles_Variance_Fields>;
};


/** aggregate fields of "StageRoles" */
export type StageRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StageRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StageRoles" */
export type StageRoles_Aggregate_Order_By = {
  avg?: InputMaybe<StageRoles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<StageRoles_Max_Order_By>;
  min?: InputMaybe<StageRoles_Min_Order_By>;
  stddev?: InputMaybe<StageRoles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<StageRoles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<StageRoles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<StageRoles_Sum_Order_By>;
  var_pop?: InputMaybe<StageRoles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<StageRoles_Var_Samp_Order_By>;
  variance?: InputMaybe<StageRoles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "StageRoles" */
export type StageRoles_Arr_Rel_Insert_Input = {
  data: Array<StageRoles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<StageRoles_On_Conflict>;
};

/** aggregate avg on columns */
export type StageRoles_Avg_Fields = {
  __typename?: 'StageRoles_avg_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StageRoles" */
export type StageRoles_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StageRoles". All fields are combined with a logical 'AND'. */
export type StageRoles_Bool_Exp = {
  Role?: InputMaybe<Roles_Bool_Exp>;
  Stage?: InputMaybe<Stages_Bool_Exp>;
  _and?: InputMaybe<Array<StageRoles_Bool_Exp>>;
  _not?: InputMaybe<StageRoles_Bool_Exp>;
  _or?: InputMaybe<Array<StageRoles_Bool_Exp>>;
  cognito_group?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "StageRoles" */
export enum StageRoles_Constraint {
  /** unique or primary key constraint on columns "id" */
  StageRolesPkey = 'StageRoles_pkey'
}

/** input type for incrementing numeric columns in table "StageRoles" */
export type StageRoles_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "StageRoles" */
export type StageRoles_Insert_Input = {
  Role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  Stage?: InputMaybe<Stages_Obj_Rel_Insert_Input>;
  cognito_group?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type StageRoles_Max_Fields = {
  __typename?: 'StageRoles_max_fields';
  cognito_group?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "StageRoles" */
export type StageRoles_Max_Order_By = {
  cognito_group?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type StageRoles_Min_Fields = {
  __typename?: 'StageRoles_min_fields';
  cognito_group?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "StageRoles" */
export type StageRoles_Min_Order_By = {
  cognito_group?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "StageRoles" */
export type StageRoles_Mutation_Response = {
  __typename?: 'StageRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StageRoles>;
};

/** on_conflict condition type for table "StageRoles" */
export type StageRoles_On_Conflict = {
  constraint: StageRoles_Constraint;
  update_columns?: Array<StageRoles_Update_Column>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "StageRoles". */
export type StageRoles_Order_By = {
  Role?: InputMaybe<Roles_Order_By>;
  Stage?: InputMaybe<Stages_Order_By>;
  cognito_group?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StageRoles */
export type StageRoles_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "StageRoles" */
export enum StageRoles_Select_Column {
  /** column name */
  CognitoGroup = 'cognito_group',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "StageRoles" */
export type StageRoles_Set_Input = {
  cognito_group?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type StageRoles_Stddev_Fields = {
  __typename?: 'StageRoles_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StageRoles" */
export type StageRoles_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StageRoles_Stddev_Pop_Fields = {
  __typename?: 'StageRoles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StageRoles" */
export type StageRoles_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StageRoles_Stddev_Samp_Fields = {
  __typename?: 'StageRoles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StageRoles" */
export type StageRoles_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "StageRoles" */
export type StageRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StageRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StageRoles_Stream_Cursor_Value_Input = {
  cognito_group?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type StageRoles_Sum_Fields = {
  __typename?: 'StageRoles_sum_fields';
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StageRoles" */
export type StageRoles_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** update columns of table "StageRoles" */
export enum StageRoles_Update_Column {
  /** column name */
  CognitoGroup = 'cognito_group',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type StageRoles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StageRoles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StageRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: StageRoles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type StageRoles_Var_Pop_Fields = {
  __typename?: 'StageRoles_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StageRoles" */
export type StageRoles_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StageRoles_Var_Samp_Fields = {
  __typename?: 'StageRoles_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StageRoles" */
export type StageRoles_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type StageRoles_Variance_Fields = {
  __typename?: 'StageRoles_variance_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StageRoles" */
export type StageRoles_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "StageTransitions" */
export type StageTransitions = {
  __typename?: 'StageTransitions';
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  from_stage_id?: Maybe<Scalars['Int']>;
  humanized_duration?: Maybe<Scalars['String']>;
  identity_id: Scalars['Int'];
  in_time: Scalars['timestamptz'];
  job_id: Scalars['Int'];
  out_time: Scalars['timestamptz'];
  reason?: Maybe<Scalars['String']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "StageTransitions" */
export type StageTransitions_Aggregate = {
  __typename?: 'StageTransitions_aggregate';
  aggregate?: Maybe<StageTransitions_Aggregate_Fields>;
  nodes: Array<StageTransitions>;
};

/** aggregate fields of "StageTransitions" */
export type StageTransitions_Aggregate_Fields = {
  __typename?: 'StageTransitions_aggregate_fields';
  avg?: Maybe<StageTransitions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StageTransitions_Max_Fields>;
  min?: Maybe<StageTransitions_Min_Fields>;
  stddev?: Maybe<StageTransitions_Stddev_Fields>;
  stddev_pop?: Maybe<StageTransitions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StageTransitions_Stddev_Samp_Fields>;
  sum?: Maybe<StageTransitions_Sum_Fields>;
  var_pop?: Maybe<StageTransitions_Var_Pop_Fields>;
  var_samp?: Maybe<StageTransitions_Var_Samp_Fields>;
  variance?: Maybe<StageTransitions_Variance_Fields>;
};


/** aggregate fields of "StageTransitions" */
export type StageTransitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StageTransitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type StageTransitions_Avg_Fields = {
  __typename?: 'StageTransitions_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "StageTransitions". All fields are combined with a logical 'AND'. */
export type StageTransitions_Bool_Exp = {
  _and?: InputMaybe<Array<StageTransitions_Bool_Exp>>;
  _not?: InputMaybe<StageTransitions_Bool_Exp>;
  _or?: InputMaybe<Array<StageTransitions_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_stage_id?: InputMaybe<Int_Comparison_Exp>;
  humanized_duration?: InputMaybe<String_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  in_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  out_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  time_in_stage?: InputMaybe<Bigint_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "StageTransitions" */
export enum StageTransitions_Constraint {
  /** unique or primary key constraint on columns "identity_id", "out_time", "in_time", "job_id" */
  StageTransitionsPkey = 'StageTransitions_pkey'
}

/** input type for incrementing numeric columns in table "StageTransitions" */
export type StageTransitions_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "StageTransitions" */
export type StageTransitions_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  humanized_duration?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  in_time?: InputMaybe<Scalars['timestamptz']>;
  job_id?: InputMaybe<Scalars['Int']>;
  out_time?: InputMaybe<Scalars['timestamptz']>;
  reason?: InputMaybe<Scalars['String']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type StageTransitions_Max_Fields = {
  __typename?: 'StageTransitions_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  humanized_duration?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  in_time?: Maybe<Scalars['timestamptz']>;
  job_id?: Maybe<Scalars['Int']>;
  out_time?: Maybe<Scalars['timestamptz']>;
  reason?: Maybe<Scalars['String']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type StageTransitions_Min_Fields = {
  __typename?: 'StageTransitions_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  humanized_duration?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  in_time?: Maybe<Scalars['timestamptz']>;
  job_id?: Maybe<Scalars['Int']>;
  out_time?: Maybe<Scalars['timestamptz']>;
  reason?: Maybe<Scalars['String']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "StageTransitions" */
export type StageTransitions_Mutation_Response = {
  __typename?: 'StageTransitions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StageTransitions>;
};

/** on_conflict condition type for table "StageTransitions" */
export type StageTransitions_On_Conflict = {
  constraint: StageTransitions_Constraint;
  update_columns?: Array<StageTransitions_Update_Column>;
  where?: InputMaybe<StageTransitions_Bool_Exp>;
};

/** Ordering options when selecting data from "StageTransitions". */
export type StageTransitions_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  humanized_duration?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  in_time?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  out_time?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  time_in_stage?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StageTransitions */
export type StageTransitions_Pk_Columns_Input = {
  identity_id: Scalars['Int'];
  in_time: Scalars['timestamptz'];
  job_id: Scalars['Int'];
  out_time: Scalars['timestamptz'];
};

/** select columns of table "StageTransitions" */
export enum StageTransitions_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  HumanizedDuration = 'humanized_duration',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InTime = 'in_time',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OutTime = 'out_time',
  /** column name */
  Reason = 'reason',
  /** column name */
  TimeInStage = 'time_in_stage',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "StageTransitions" */
export type StageTransitions_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  humanized_duration?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  in_time?: InputMaybe<Scalars['timestamptz']>;
  job_id?: InputMaybe<Scalars['Int']>;
  out_time?: InputMaybe<Scalars['timestamptz']>;
  reason?: InputMaybe<Scalars['String']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type StageTransitions_Stddev_Fields = {
  __typename?: 'StageTransitions_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type StageTransitions_Stddev_Pop_Fields = {
  __typename?: 'StageTransitions_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type StageTransitions_Stddev_Samp_Fields = {
  __typename?: 'StageTransitions_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "StageTransitions" */
export type StageTransitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StageTransitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StageTransitions_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  humanized_duration?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  in_time?: InputMaybe<Scalars['timestamptz']>;
  job_id?: InputMaybe<Scalars['Int']>;
  out_time?: InputMaybe<Scalars['timestamptz']>;
  reason?: InputMaybe<Scalars['String']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type StageTransitions_Sum_Fields = {
  __typename?: 'StageTransitions_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "StageTransitions" */
export enum StageTransitions_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  HumanizedDuration = 'humanized_duration',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InTime = 'in_time',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OutTime = 'out_time',
  /** column name */
  Reason = 'reason',
  /** column name */
  TimeInStage = 'time_in_stage',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type StageTransitions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StageTransitions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StageTransitions_Set_Input>;
  /** filter the rows which have to be updated */
  where: StageTransitions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type StageTransitions_Var_Pop_Fields = {
  __typename?: 'StageTransitions_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type StageTransitions_Var_Samp_Fields = {
  __typename?: 'StageTransitions_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type StageTransitions_Variance_Fields = {
  __typename?: 'StageTransitions_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Stages" */
export type Stages = {
  __typename?: 'Stages';
  /** An array relationship */
  AuditStages: Array<AuditStages>;
  /** An aggregate relationship */
  AuditStages_aggregate: AuditStages_Aggregate;
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An array relationship */
  ContractStages: Array<ContractStages>;
  /** An aggregate relationship */
  ContractStages_aggregate: ContractStages_Aggregate;
  /** An array relationship */
  Contracts: Array<Contracts>;
  /** An aggregate relationship */
  Contracts_aggregate: Contracts_Aggregate;
  /** An array relationship */
  Emails: Array<Emails>;
  /** An aggregate relationship */
  Emails_aggregate: Emails_Aggregate;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  /** An object relationship */
  Questionnaire?: Maybe<Questionnaires>;
  /** An object relationship */
  ReviewGroup?: Maybe<ReviewGroups>;
  /** An array relationship */
  StageEmails: Array<StageEmails>;
  /** An aggregate relationship */
  StageEmails_aggregate: StageEmails_Aggregate;
  /** An array relationship */
  StageQuestionnaireMappings: Array<StageQuestionnaireMapping>;
  /** An aggregate relationship */
  StageQuestionnaireMappings_aggregate: StageQuestionnaireMapping_Aggregate;
  /** An array relationship */
  StageRoles: Array<StageRoles>;
  /** An aggregate relationship */
  StageRoles_aggregate: StageRoles_Aggregate;
  /** An object relationship */
  StateMachine?: Maybe<StateMachines>;
  /** An array relationship */
  auditStagesByToStageId: Array<AuditStages>;
  /** An aggregate relationship */
  auditStagesByToStageId_aggregate: AuditStages_Aggregate;
  company_id?: Maybe<Scalars['Int']>;
  conversion?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  deleted?: Maybe<Scalars['Boolean']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  initiate_job_matched_questionnaire?: Maybe<Scalars['Boolean']>;
  initiate_team_review: Scalars['Boolean'];
  is_interview_funnel_stage?: Maybe<Scalars['Boolean']>;
  is_submit_funnel_stage: Scalars['Boolean'];
  milestone?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  permanently_deleted: Scalars['Boolean'];
  questionnaire_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
  sharedWithPartners: Scalars['Boolean'];
  state_machine_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Stages" */
export type StagesAuditStagesArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesAuditStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesContractStagesArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesContractStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesContractsArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesStageEmailsArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesStageEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesStageQuestionnaireMappingsArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMapping_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesStageQuestionnaireMappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMapping_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesStageRolesArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesStageRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesAuditStagesByToStageIdArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesAuditStagesByToStageId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


/** columns and relationships of "Stages" */
export type StagesEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Stages" */
export type Stages_Aggregate = {
  __typename?: 'Stages_aggregate';
  aggregate?: Maybe<Stages_Aggregate_Fields>;
  nodes: Array<Stages>;
};

export type Stages_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Stages_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Stages_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Stages_Aggregate_Bool_Exp_Count>;
};

export type Stages_Aggregate_Bool_Exp_Bool_And = {
  arguments: Stages_Select_Column_Stages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stages_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Stages_Select_Column_Stages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Stages" */
export type Stages_Aggregate_Fields = {
  __typename?: 'Stages_aggregate_fields';
  avg?: Maybe<Stages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stages_Max_Fields>;
  min?: Maybe<Stages_Min_Fields>;
  stddev?: Maybe<Stages_Stddev_Fields>;
  stddev_pop?: Maybe<Stages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stages_Stddev_Samp_Fields>;
  sum?: Maybe<Stages_Sum_Fields>;
  var_pop?: Maybe<Stages_Var_Pop_Fields>;
  var_samp?: Maybe<Stages_Var_Samp_Fields>;
  variance?: Maybe<Stages_Variance_Fields>;
};


/** aggregate fields of "Stages" */
export type Stages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Stages" */
export type Stages_Aggregate_Order_By = {
  avg?: InputMaybe<Stages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stages_Max_Order_By>;
  min?: InputMaybe<Stages_Min_Order_By>;
  stddev?: InputMaybe<Stages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stages_Sum_Order_By>;
  var_pop?: InputMaybe<Stages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stages_Var_Samp_Order_By>;
  variance?: InputMaybe<Stages_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stages_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Stages" */
export type Stages_Arr_Rel_Insert_Input = {
  data: Array<Stages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stages_On_Conflict>;
};

/** aggregate avg on columns */
export type Stages_Avg_Fields = {
  __typename?: 'Stages_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  state_machine_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Stages" */
export type Stages_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Stages". All fields are combined with a logical 'AND'. */
export type Stages_Bool_Exp = {
  AuditStages?: InputMaybe<AuditStages_Bool_Exp>;
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  BoardSnippets?: InputMaybe<BoardSnippets_Bool_Exp>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp>;
  Company?: InputMaybe<Companies_Bool_Exp>;
  ContractStages?: InputMaybe<ContractStages_Bool_Exp>;
  ContractStages_aggregate?: InputMaybe<ContractStages_Aggregate_Bool_Exp>;
  Contracts?: InputMaybe<Contracts_Bool_Exp>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Bool_Exp>;
  Emails?: InputMaybe<Emails_Bool_Exp>;
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Bool_Exp>;
  Identities?: InputMaybe<Identities_Bool_Exp>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Bool_Exp>;
  Questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  ReviewGroup?: InputMaybe<ReviewGroups_Bool_Exp>;
  StageEmails?: InputMaybe<StageEmails_Bool_Exp>;
  StageEmails_aggregate?: InputMaybe<StageEmails_Aggregate_Bool_Exp>;
  StageQuestionnaireMappings?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
  StageQuestionnaireMappings_aggregate?: InputMaybe<StageQuestionnaireMapping_Aggregate_Bool_Exp>;
  StageRoles?: InputMaybe<StageRoles_Bool_Exp>;
  StageRoles_aggregate?: InputMaybe<StageRoles_Aggregate_Bool_Exp>;
  StateMachine?: InputMaybe<StateMachines_Bool_Exp>;
  _and?: InputMaybe<Array<Stages_Bool_Exp>>;
  _not?: InputMaybe<Stages_Bool_Exp>;
  _or?: InputMaybe<Array<Stages_Bool_Exp>>;
  auditStagesByToStageId?: InputMaybe<AuditStages_Bool_Exp>;
  auditStagesByToStageId_aggregate?: InputMaybe<AuditStages_Aggregate_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  conversion?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  initiate_job_matched_questionnaire?: InputMaybe<Boolean_Comparison_Exp>;
  initiate_team_review?: InputMaybe<Boolean_Comparison_Exp>;
  is_interview_funnel_stage?: InputMaybe<Boolean_Comparison_Exp>;
  is_submit_funnel_stage?: InputMaybe<Boolean_Comparison_Exp>;
  milestone?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  permanently_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  review_group_id?: InputMaybe<Int_Comparison_Exp>;
  sharedWithPartners?: InputMaybe<Boolean_Comparison_Exp>;
  state_machine_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Stages" */
export enum Stages_Constraint {
  /** unique or primary key constraint on columns "id" */
  StagesPkey = 'Stages_pkey',
  /** unique or primary key constraint on columns "etl_id_token" */
  StagesUniqueEtlIdToken = 'stages_unique_etl_id_token'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stages_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stages_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stages_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Stages" */
export type Stages_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  state_machine_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Stages" */
export type Stages_Insert_Input = {
  AuditStages?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  BoardSnippets?: InputMaybe<BoardSnippets_Arr_Rel_Insert_Input>;
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  ContractStages?: InputMaybe<ContractStages_Arr_Rel_Insert_Input>;
  Contracts?: InputMaybe<Contracts_Arr_Rel_Insert_Input>;
  Emails?: InputMaybe<Emails_Arr_Rel_Insert_Input>;
  Identities?: InputMaybe<Identities_Arr_Rel_Insert_Input>;
  Questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  ReviewGroup?: InputMaybe<ReviewGroups_Obj_Rel_Insert_Input>;
  StageEmails?: InputMaybe<StageEmails_Arr_Rel_Insert_Input>;
  StageQuestionnaireMappings?: InputMaybe<StageQuestionnaireMapping_Arr_Rel_Insert_Input>;
  StageRoles?: InputMaybe<StageRoles_Arr_Rel_Insert_Input>;
  StateMachine?: InputMaybe<StateMachines_Obj_Rel_Insert_Input>;
  auditStagesByToStageId?: InputMaybe<AuditStages_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  conversion?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  initiate_job_matched_questionnaire?: InputMaybe<Scalars['Boolean']>;
  initiate_team_review?: InputMaybe<Scalars['Boolean']>;
  is_interview_funnel_stage?: InputMaybe<Scalars['Boolean']>;
  is_submit_funnel_stage?: InputMaybe<Scalars['Boolean']>;
  milestone?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  permanently_deleted?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  sharedWithPartners?: InputMaybe<Scalars['Boolean']>;
  state_machine_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stages_Max_Fields = {
  __typename?: 'Stages_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
  state_machine_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Stages" */
export type Stages_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stages_Min_Fields = {
  __typename?: 'Stages_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  review_group_id?: Maybe<Scalars['Int']>;
  state_machine_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Stages" */
export type Stages_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Stages" */
export type Stages_Mutation_Response = {
  __typename?: 'Stages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stages>;
};

/** input type for inserting object relation for remote table "Stages" */
export type Stages_Obj_Rel_Insert_Input = {
  data: Stages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stages_On_Conflict>;
};

/** on_conflict condition type for table "Stages" */
export type Stages_On_Conflict = {
  constraint: Stages_Constraint;
  update_columns?: Array<Stages_Update_Column>;
  where?: InputMaybe<Stages_Bool_Exp>;
};

/** Ordering options when selecting data from "Stages". */
export type Stages_Order_By = {
  AuditStages_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Order_By>;
  Company?: InputMaybe<Companies_Order_By>;
  ContractStages_aggregate?: InputMaybe<ContractStages_Aggregate_Order_By>;
  Contracts_aggregate?: InputMaybe<Contracts_Aggregate_Order_By>;
  Emails_aggregate?: InputMaybe<Emails_Aggregate_Order_By>;
  Identities_aggregate?: InputMaybe<Identities_Aggregate_Order_By>;
  Questionnaire?: InputMaybe<Questionnaires_Order_By>;
  ReviewGroup?: InputMaybe<ReviewGroups_Order_By>;
  StageEmails_aggregate?: InputMaybe<StageEmails_Aggregate_Order_By>;
  StageQuestionnaireMappings_aggregate?: InputMaybe<StageQuestionnaireMapping_Aggregate_Order_By>;
  StageRoles_aggregate?: InputMaybe<StageRoles_Aggregate_Order_By>;
  StateMachine?: InputMaybe<StateMachines_Order_By>;
  auditStagesByToStageId_aggregate?: InputMaybe<AuditStages_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  conversion?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initiate_job_matched_questionnaire?: InputMaybe<Order_By>;
  initiate_team_review?: InputMaybe<Order_By>;
  is_interview_funnel_stage?: InputMaybe<Order_By>;
  is_submit_funnel_stage?: InputMaybe<Order_By>;
  milestone?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  permanently_deleted?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  sharedWithPartners?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Stages */
export type Stages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stages_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Stages" */
export enum Stages_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Conversion = 'conversion',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  InitiateJobMatchedQuestionnaire = 'initiate_job_matched_questionnaire',
  /** column name */
  InitiateTeamReview = 'initiate_team_review',
  /** column name */
  IsInterviewFunnelStage = 'is_interview_funnel_stage',
  /** column name */
  IsSubmitFunnelStage = 'is_submit_funnel_stage',
  /** column name */
  Milestone = 'milestone',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  PermanentlyDeleted = 'permanently_deleted',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  SharedWithPartners = 'sharedWithPartners',
  /** column name */
  StateMachineId = 'state_machine_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Stages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Stages" */
export enum Stages_Select_Column_Stages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Conversion = 'conversion',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  InitiateJobMatchedQuestionnaire = 'initiate_job_matched_questionnaire',
  /** column name */
  InitiateTeamReview = 'initiate_team_review',
  /** column name */
  IsInterviewFunnelStage = 'is_interview_funnel_stage',
  /** column name */
  IsSubmitFunnelStage = 'is_submit_funnel_stage',
  /** column name */
  Milestone = 'milestone',
  /** column name */
  PermanentlyDeleted = 'permanently_deleted',
  /** column name */
  SharedWithPartners = 'sharedWithPartners'
}

/** select "Stages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Stages" */
export enum Stages_Select_Column_Stages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Conversion = 'conversion',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  InitiateJobMatchedQuestionnaire = 'initiate_job_matched_questionnaire',
  /** column name */
  InitiateTeamReview = 'initiate_team_review',
  /** column name */
  IsInterviewFunnelStage = 'is_interview_funnel_stage',
  /** column name */
  IsSubmitFunnelStage = 'is_submit_funnel_stage',
  /** column name */
  Milestone = 'milestone',
  /** column name */
  PermanentlyDeleted = 'permanently_deleted',
  /** column name */
  SharedWithPartners = 'sharedWithPartners'
}

/** input type for updating data in table "Stages" */
export type Stages_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  conversion?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  initiate_job_matched_questionnaire?: InputMaybe<Scalars['Boolean']>;
  initiate_team_review?: InputMaybe<Scalars['Boolean']>;
  is_interview_funnel_stage?: InputMaybe<Scalars['Boolean']>;
  is_submit_funnel_stage?: InputMaybe<Scalars['Boolean']>;
  milestone?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  permanently_deleted?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  sharedWithPartners?: InputMaybe<Scalars['Boolean']>;
  state_machine_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Stages_Stddev_Fields = {
  __typename?: 'Stages_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  state_machine_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Stages" */
export type Stages_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stages_Stddev_Pop_Fields = {
  __typename?: 'Stages_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  state_machine_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Stages" */
export type Stages_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stages_Stddev_Samp_Fields = {
  __typename?: 'Stages_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  state_machine_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Stages" */
export type Stages_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Stages" */
export type Stages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stages_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  conversion?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  initiate_job_matched_questionnaire?: InputMaybe<Scalars['Boolean']>;
  initiate_team_review?: InputMaybe<Scalars['Boolean']>;
  is_interview_funnel_stage?: InputMaybe<Scalars['Boolean']>;
  is_submit_funnel_stage?: InputMaybe<Scalars['Boolean']>;
  milestone?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  permanently_deleted?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  review_group_id?: InputMaybe<Scalars['Int']>;
  sharedWithPartners?: InputMaybe<Scalars['Boolean']>;
  state_machine_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Stages_Sum_Fields = {
  __typename?: 'Stages_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  review_group_id?: Maybe<Scalars['Int']>;
  state_machine_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Stages" */
export type Stages_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** update columns of table "Stages" */
export enum Stages_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Conversion = 'conversion',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  InitiateJobMatchedQuestionnaire = 'initiate_job_matched_questionnaire',
  /** column name */
  InitiateTeamReview = 'initiate_team_review',
  /** column name */
  IsInterviewFunnelStage = 'is_interview_funnel_stage',
  /** column name */
  IsSubmitFunnelStage = 'is_submit_funnel_stage',
  /** column name */
  Milestone = 'milestone',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  PermanentlyDeleted = 'permanently_deleted',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  ReviewGroupId = 'review_group_id',
  /** column name */
  SharedWithPartners = 'sharedWithPartners',
  /** column name */
  StateMachineId = 'state_machine_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Stages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stages_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stages_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stages_Var_Pop_Fields = {
  __typename?: 'Stages_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  state_machine_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Stages" */
export type Stages_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stages_Var_Samp_Fields = {
  __typename?: 'Stages_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  state_machine_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Stages" */
export type Stages_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stages_Variance_Fields = {
  __typename?: 'Stages_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  review_group_id?: Maybe<Scalars['Float']>;
  state_machine_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Stages" */
export type Stages_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_group_id?: InputMaybe<Order_By>;
  state_machine_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "StateMachines" */
export type StateMachines = {
  __typename?: 'StateMachines';
  /** An array relationship */
  Stages: Array<Stages>;
  /** An aggregate relationship */
  Stages_aggregate: Stages_Aggregate;
  /** An array relationship */
  WorkflowContracts: Array<Workflow_Contracts>;
  /** An aggregate relationship */
  WorkflowContracts_aggregate: Workflow_Contracts_Aggregate;
  /** An array relationship */
  WorkflowDepartments: Array<Workflow_Departments>;
  /** An aggregate relationship */
  WorkflowDepartments_aggregate: Workflow_Departments_Aggregate;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted?: Maybe<Scalars['Boolean']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  order: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  title_string?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "StateMachines" */
export type StateMachinesStagesArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


/** columns and relationships of "StateMachines" */
export type StateMachinesStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


/** columns and relationships of "StateMachines" */
export type StateMachinesWorkflowContractsArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Contracts_Order_By>>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};


/** columns and relationships of "StateMachines" */
export type StateMachinesWorkflowContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Contracts_Order_By>>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};


/** columns and relationships of "StateMachines" */
export type StateMachinesWorkflowDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};


/** columns and relationships of "StateMachines" */
export type StateMachinesWorkflowDepartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};

/** aggregated selection of "StateMachines" */
export type StateMachines_Aggregate = {
  __typename?: 'StateMachines_aggregate';
  aggregate?: Maybe<StateMachines_Aggregate_Fields>;
  nodes: Array<StateMachines>;
};

/** aggregate fields of "StateMachines" */
export type StateMachines_Aggregate_Fields = {
  __typename?: 'StateMachines_aggregate_fields';
  avg?: Maybe<StateMachines_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StateMachines_Max_Fields>;
  min?: Maybe<StateMachines_Min_Fields>;
  stddev?: Maybe<StateMachines_Stddev_Fields>;
  stddev_pop?: Maybe<StateMachines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StateMachines_Stddev_Samp_Fields>;
  sum?: Maybe<StateMachines_Sum_Fields>;
  var_pop?: Maybe<StateMachines_Var_Pop_Fields>;
  var_samp?: Maybe<StateMachines_Var_Samp_Fields>;
  variance?: Maybe<StateMachines_Variance_Fields>;
};


/** aggregate fields of "StateMachines" */
export type StateMachines_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StateMachines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type StateMachines_Avg_Fields = {
  __typename?: 'StateMachines_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "StateMachines". All fields are combined with a logical 'AND'. */
export type StateMachines_Bool_Exp = {
  Stages?: InputMaybe<Stages_Bool_Exp>;
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Bool_Exp>;
  WorkflowContracts?: InputMaybe<Workflow_Contracts_Bool_Exp>;
  WorkflowContracts_aggregate?: InputMaybe<Workflow_Contracts_Aggregate_Bool_Exp>;
  WorkflowDepartments?: InputMaybe<Workflow_Departments_Bool_Exp>;
  WorkflowDepartments_aggregate?: InputMaybe<Workflow_Departments_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<StateMachines_Bool_Exp>>;
  _not?: InputMaybe<StateMachines_Bool_Exp>;
  _or?: InputMaybe<Array<StateMachines_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_string?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "StateMachines" */
export enum StateMachines_Constraint {
  /** unique or primary key constraint on columns "company_id", "title" */
  StateMachinesCompanyIdTitleKey = 'StateMachines_company_id_title_key',
  /** unique or primary key constraint on columns "etl_id_token" */
  StateMachinesEtlIdTokenKey = 'StateMachines_etl_id_token_key',
  /** unique or primary key constraint on columns "id" */
  StateMachinesPkey = 'StateMachines_pkey'
}

/** input type for incrementing numeric columns in table "StateMachines" */
export type StateMachines_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "StateMachines" */
export type StateMachines_Insert_Input = {
  Stages?: InputMaybe<Stages_Arr_Rel_Insert_Input>;
  WorkflowContracts?: InputMaybe<Workflow_Contracts_Arr_Rel_Insert_Input>;
  WorkflowDepartments?: InputMaybe<Workflow_Departments_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  title_string?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type StateMachines_Max_Fields = {
  __typename?: 'StateMachines_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_string?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type StateMachines_Min_Fields = {
  __typename?: 'StateMachines_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_string?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "StateMachines" */
export type StateMachines_Mutation_Response = {
  __typename?: 'StateMachines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StateMachines>;
};

/** input type for inserting object relation for remote table "StateMachines" */
export type StateMachines_Obj_Rel_Insert_Input = {
  data: StateMachines_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<StateMachines_On_Conflict>;
};

/** on_conflict condition type for table "StateMachines" */
export type StateMachines_On_Conflict = {
  constraint: StateMachines_Constraint;
  update_columns?: Array<StateMachines_Update_Column>;
  where?: InputMaybe<StateMachines_Bool_Exp>;
};

/** Ordering options when selecting data from "StateMachines". */
export type StateMachines_Order_By = {
  Stages_aggregate?: InputMaybe<Stages_Aggregate_Order_By>;
  WorkflowContracts_aggregate?: InputMaybe<Workflow_Contracts_Aggregate_Order_By>;
  WorkflowDepartments_aggregate?: InputMaybe<Workflow_Departments_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_string?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StateMachines */
export type StateMachines_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "StateMachines" */
export enum StateMachines_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title',
  /** column name */
  TitleString = 'title_string',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "StateMachines" */
export type StateMachines_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  title_string?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type StateMachines_Stddev_Fields = {
  __typename?: 'StateMachines_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type StateMachines_Stddev_Pop_Fields = {
  __typename?: 'StateMachines_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type StateMachines_Stddev_Samp_Fields = {
  __typename?: 'StateMachines_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "StateMachines" */
export type StateMachines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StateMachines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StateMachines_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  title_string?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type StateMachines_Sum_Fields = {
  __typename?: 'StateMachines_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "StateMachines" */
export enum StateMachines_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title',
  /** column name */
  TitleString = 'title_string',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type StateMachines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StateMachines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StateMachines_Set_Input>;
  /** filter the rows which have to be updated */
  where: StateMachines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type StateMachines_Var_Pop_Fields = {
  __typename?: 'StateMachines_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type StateMachines_Var_Samp_Fields = {
  __typename?: 'StateMachines_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type StateMachines_Variance_Fields = {
  __typename?: 'StateMachines_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Identities who have been submitted for a position by a partner */
export type Submissions = {
  __typename?: 'Submissions';
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** An object relationship */
  CreatedBy: Identities;
  /** An object relationship */
  Group?: Maybe<Groups>;
  /** An object relationship */
  Identity: Identities;
  /** An object relationship */
  Job: Jobs;
  /** An array relationship */
  OverlayLinks: Array<SubmissionsOverlaysLinks>;
  /** An aggregate relationship */
  OverlayLinks_aggregate: SubmissionsOverlaysLinks_Aggregate;
  /** An object relationship */
  Rejection?: Maybe<Rejections>;
  /** An object relationship */
  UpdatedBy: Identities;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['Int'];
  custom_form_data: Scalars['jsonb'];
  explanation: Scalars['String'];
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  identityId: Scalars['Int'];
  jobId: Scalars['Int'];
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  /** In-Progress | On the board. Rejected | have rejection_id. Submitted | Default not on board but not rejected. */
  status?: Maybe<Scalars['String']>;
  submitRate: Scalars['money'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy: Scalars['Int'];
};


/** Identities who have been submitted for a position by a partner */
export type SubmissionsBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** Identities who have been submitted for a position by a partner */
export type SubmissionsBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


/** Identities who have been submitted for a position by a partner */
export type SubmissionsOverlayLinksArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


/** Identities who have been submitted for a position by a partner */
export type SubmissionsOverlayLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


/** Identities who have been submitted for a position by a partner */
export type SubmissionsCustom_Form_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks = {
  __typename?: 'SubmissionsOverlaysLinks';
  /** An object relationship */
  Overlay: Overlay;
  /** An object relationship */
  OverlayDefinition: OverlayDefinition;
  /** An object relationship */
  Submission: Submissions;
  created_at: Scalars['timestamptz'];
  entity_id: Scalars['uuid'];
  id: Scalars['uuid'];
  overlay_def_id: Scalars['uuid'];
  overlay_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Aggregate = {
  __typename?: 'SubmissionsOverlaysLinks_aggregate';
  aggregate?: Maybe<SubmissionsOverlaysLinks_Aggregate_Fields>;
  nodes: Array<SubmissionsOverlaysLinks>;
};

export type SubmissionsOverlaysLinks_Aggregate_Bool_Exp = {
  count?: InputMaybe<SubmissionsOverlaysLinks_Aggregate_Bool_Exp_Count>;
};

export type SubmissionsOverlaysLinks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Aggregate_Fields = {
  __typename?: 'SubmissionsOverlaysLinks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SubmissionsOverlaysLinks_Max_Fields>;
  min?: Maybe<SubmissionsOverlaysLinks_Min_Fields>;
};


/** aggregate fields of "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SubmissionsOverlaysLinks_Max_Order_By>;
  min?: InputMaybe<SubmissionsOverlaysLinks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Arr_Rel_Insert_Input = {
  data: Array<SubmissionsOverlaysLinks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SubmissionsOverlaysLinks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SubmissionsOverlaysLinks". All fields are combined with a logical 'AND'. */
export type SubmissionsOverlaysLinks_Bool_Exp = {
  Overlay?: InputMaybe<Overlay_Bool_Exp>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Bool_Exp>;
  Submission?: InputMaybe<Submissions_Bool_Exp>;
  _and?: InputMaybe<Array<SubmissionsOverlaysLinks_Bool_Exp>>;
  _not?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
  _or?: InputMaybe<Array<SubmissionsOverlaysLinks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  overlay_def_id?: InputMaybe<Uuid_Comparison_Exp>;
  overlay_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "SubmissionsOverlaysLinks" */
export enum SubmissionsOverlaysLinks_Constraint {
  /** unique or primary key constraint on columns "entity_id", "overlay_def_id" */
  SubmissionsOverlaysLinksOverlayDefIdEntityIdKey = 'SubmissionsOverlaysLinks_overlay_def_id_entity_id_key',
  /** unique or primary key constraint on columns "id" */
  SubmissionsOverlaysLinksPkey = 'SubmissionsOverlaysLinks_pkey'
}

/** input type for inserting data into table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Insert_Input = {
  Overlay?: InputMaybe<Overlay_Obj_Rel_Insert_Input>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Obj_Rel_Insert_Input>;
  Submission?: InputMaybe<Submissions_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SubmissionsOverlaysLinks_Max_Fields = {
  __typename?: 'SubmissionsOverlaysLinks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  overlay_def_id?: Maybe<Scalars['uuid']>;
  overlay_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SubmissionsOverlaysLinks_Min_Fields = {
  __typename?: 'SubmissionsOverlaysLinks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  overlay_def_id?: Maybe<Scalars['uuid']>;
  overlay_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Mutation_Response = {
  __typename?: 'SubmissionsOverlaysLinks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SubmissionsOverlaysLinks>;
};

/** on_conflict condition type for table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_On_Conflict = {
  constraint: SubmissionsOverlaysLinks_Constraint;
  update_columns?: Array<SubmissionsOverlaysLinks_Update_Column>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};

/** Ordering options when selecting data from "SubmissionsOverlaysLinks". */
export type SubmissionsOverlaysLinks_Order_By = {
  Overlay?: InputMaybe<Overlay_Order_By>;
  OverlayDefinition?: InputMaybe<OverlayDefinition_Order_By>;
  Submission?: InputMaybe<Submissions_Order_By>;
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  overlay_def_id?: InputMaybe<Order_By>;
  overlay_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: SubmissionsOverlaysLinks */
export type SubmissionsOverlaysLinks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "SubmissionsOverlaysLinks" */
export enum SubmissionsOverlaysLinks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  OverlayDefId = 'overlay_def_id',
  /** column name */
  OverlayId = 'overlay_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "SubmissionsOverlaysLinks" */
export type SubmissionsOverlaysLinks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SubmissionsOverlaysLinks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SubmissionsOverlaysLinks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  overlay_def_id?: InputMaybe<Scalars['uuid']>;
  overlay_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "SubmissionsOverlaysLinks" */
export enum SubmissionsOverlaysLinks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  OverlayDefId = 'overlay_def_id',
  /** column name */
  OverlayId = 'overlay_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SubmissionsOverlaysLinks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SubmissionsOverlaysLinks_Set_Input>;
  /** filter the rows which have to be updated */
  where: SubmissionsOverlaysLinks_Bool_Exp;
};

/** aggregated selection of "Submissions" */
export type Submissions_Aggregate = {
  __typename?: 'Submissions_aggregate';
  aggregate?: Maybe<Submissions_Aggregate_Fields>;
  nodes: Array<Submissions>;
};

export type Submissions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Submissions_Aggregate_Bool_Exp_Count>;
};

export type Submissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Submissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Submissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Submissions" */
export type Submissions_Aggregate_Fields = {
  __typename?: 'Submissions_aggregate_fields';
  avg?: Maybe<Submissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Submissions_Max_Fields>;
  min?: Maybe<Submissions_Min_Fields>;
  stddev?: Maybe<Submissions_Stddev_Fields>;
  stddev_pop?: Maybe<Submissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Submissions_Stddev_Samp_Fields>;
  sum?: Maybe<Submissions_Sum_Fields>;
  var_pop?: Maybe<Submissions_Var_Pop_Fields>;
  var_samp?: Maybe<Submissions_Var_Samp_Fields>;
  variance?: Maybe<Submissions_Variance_Fields>;
};


/** aggregate fields of "Submissions" */
export type Submissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Submissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Submissions" */
export type Submissions_Aggregate_Order_By = {
  avg?: InputMaybe<Submissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Submissions_Max_Order_By>;
  min?: InputMaybe<Submissions_Min_Order_By>;
  stddev?: InputMaybe<Submissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Submissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Submissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Submissions_Sum_Order_By>;
  var_pop?: InputMaybe<Submissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Submissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Submissions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Submissions_Append_Input = {
  custom_form_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Submissions" */
export type Submissions_Arr_Rel_Insert_Input = {
  data: Array<Submissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Submissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Submissions_Avg_Fields = {
  __typename?: 'Submissions_avg_fields';
  createdBy?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  submitRate?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Submissions" */
export type Submissions_Avg_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Submissions". All fields are combined with a logical 'AND'. */
export type Submissions_Bool_Exp = {
  BoardSnippets?: InputMaybe<BoardSnippets_Bool_Exp>;
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  Group?: InputMaybe<Groups_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  OverlayLinks?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
  OverlayLinks_aggregate?: InputMaybe<SubmissionsOverlaysLinks_Aggregate_Bool_Exp>;
  Rejection?: InputMaybe<Rejections_Bool_Exp>;
  UpdatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Submissions_Bool_Exp>>;
  _not?: InputMaybe<Submissions_Bool_Exp>;
  _or?: InputMaybe<Array<Submissions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdBy?: InputMaybe<Int_Comparison_Exp>;
  custom_form_data?: InputMaybe<Jsonb_Comparison_Exp>;
  explanation?: InputMaybe<String_Comparison_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  jobId?: InputMaybe<Int_Comparison_Exp>;
  rejection_id?: InputMaybe<Int_Comparison_Exp>;
  relevance_last_calced?: InputMaybe<Timestamptz_Comparison_Exp>;
  relevance_score?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  submitRate?: InputMaybe<Money_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedBy?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Submissions" */
export enum Submissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubmissionsPkey = 'Submissions_pkey',
  /** unique or primary key constraint on columns "identityId", "jobId" */
  SubmissionsJobidIdentityidRejectionidIdx = 'submissions_jobid_identityid_rejectionid_idx'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Submissions_Delete_At_Path_Input = {
  custom_form_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Submissions_Delete_Elem_Input = {
  custom_form_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Submissions_Delete_Key_Input = {
  custom_form_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Submissions" */
export type Submissions_Inc_Input = {
  createdBy?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  submitRate?: InputMaybe<Scalars['money']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Submissions" */
export type Submissions_Insert_Input = {
  BoardSnippets?: InputMaybe<BoardSnippets_Arr_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  OverlayLinks?: InputMaybe<SubmissionsOverlaysLinks_Arr_Rel_Insert_Input>;
  Rejection?: InputMaybe<Rejections_Obj_Rel_Insert_Input>;
  UpdatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  custom_form_data?: InputMaybe<Scalars['jsonb']>;
  explanation?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identityId?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  submitRate?: InputMaybe<Scalars['money']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Submissions_Max_Fields = {
  __typename?: 'Submissions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['Int']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  /** In-Progress | On the board. Rejected | have rejection_id. Submitted | Default not on board but not rejected. */
  status?: Maybe<Scalars['String']>;
  submitRate?: Maybe<Scalars['money']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Submissions" */
export type Submissions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Submissions_Min_Fields = {
  __typename?: 'Submissions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identityId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['Int']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_last_calced?: Maybe<Scalars['timestamptz']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  /** In-Progress | On the board. Rejected | have rejection_id. Submitted | Default not on board but not rejected. */
  status?: Maybe<Scalars['String']>;
  submitRate?: Maybe<Scalars['money']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Submissions" */
export type Submissions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Submissions" */
export type Submissions_Mutation_Response = {
  __typename?: 'Submissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submissions>;
};

/** input type for inserting object relation for remote table "Submissions" */
export type Submissions_Obj_Rel_Insert_Input = {
  data: Submissions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Submissions_On_Conflict>;
};

/** on_conflict condition type for table "Submissions" */
export type Submissions_On_Conflict = {
  constraint: Submissions_Constraint;
  update_columns?: Array<Submissions_Update_Column>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};

/** Ordering options when selecting data from "Submissions". */
export type Submissions_Order_By = {
  BoardSnippets_aggregate?: InputMaybe<BoardSnippets_Aggregate_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  Group?: InputMaybe<Groups_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  OverlayLinks_aggregate?: InputMaybe<SubmissionsOverlaysLinks_Aggregate_Order_By>;
  Rejection?: InputMaybe<Rejections_Order_By>;
  UpdatedBy?: InputMaybe<Identities_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  custom_form_data?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_last_calced?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Submissions */
export type Submissions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Submissions_Prepend_Input = {
  custom_form_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Submissions" */
export enum Submissions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  CustomFormData = 'custom_form_data',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  JobId = 'jobId',
  /** column name */
  RejectionId = 'rejection_id',
  /** column name */
  RelevanceLastCalced = 'relevance_last_calced',
  /** column name */
  RelevanceScore = 'relevance_score',
  /** column name */
  SubmitRate = 'submitRate',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "Submissions" */
export type Submissions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  custom_form_data?: InputMaybe<Scalars['jsonb']>;
  explanation?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identityId?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  submitRate?: InputMaybe<Scalars['money']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Submissions_Stddev_Fields = {
  __typename?: 'Submissions_stddev_fields';
  createdBy?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  submitRate?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Submissions" */
export type Submissions_Stddev_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Submissions_Stddev_Pop_Fields = {
  __typename?: 'Submissions_stddev_pop_fields';
  createdBy?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  submitRate?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Submissions" */
export type Submissions_Stddev_Pop_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Submissions_Stddev_Samp_Fields = {
  __typename?: 'Submissions_stddev_samp_fields';
  createdBy?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  submitRate?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Submissions" */
export type Submissions_Stddev_Samp_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Submissions" */
export type Submissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Submissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Submissions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  custom_form_data?: InputMaybe<Scalars['jsonb']>;
  explanation?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identityId?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Scalars['Int']>;
  rejection_id?: InputMaybe<Scalars['Int']>;
  relevance_last_calced?: InputMaybe<Scalars['timestamptz']>;
  relevance_score?: InputMaybe<Scalars['numeric']>;
  submitRate?: InputMaybe<Scalars['money']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Submissions_Sum_Fields = {
  __typename?: 'Submissions_sum_fields';
  createdBy?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['Int']>;
  rejection_id?: Maybe<Scalars['Int']>;
  relevance_score?: Maybe<Scalars['numeric']>;
  submitRate?: Maybe<Scalars['money']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Submissions" */
export type Submissions_Sum_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** update columns of table "Submissions" */
export enum Submissions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  CustomFormData = 'custom_form_data',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  JobId = 'jobId',
  /** column name */
  RejectionId = 'rejection_id',
  /** column name */
  RelevanceLastCalced = 'relevance_last_calced',
  /** column name */
  RelevanceScore = 'relevance_score',
  /** column name */
  SubmitRate = 'submitRate',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Submissions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Submissions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Submissions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Submissions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Submissions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Submissions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Submissions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Submissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Submissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Submissions_Var_Pop_Fields = {
  __typename?: 'Submissions_var_pop_fields';
  createdBy?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  submitRate?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Submissions" */
export type Submissions_Var_Pop_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Submissions_Var_Samp_Fields = {
  __typename?: 'Submissions_var_samp_fields';
  createdBy?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  submitRate?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Submissions" */
export type Submissions_Var_Samp_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Submissions_Variance_Fields = {
  __typename?: 'Submissions_variance_fields';
  createdBy?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  jobId?: Maybe<Scalars['Float']>;
  rejection_id?: Maybe<Scalars['Float']>;
  relevance_score?: Maybe<Scalars['Float']>;
  submitRate?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Submissions" */
export type Submissions_Variance_Order_By = {
  createdBy?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  rejection_id?: InputMaybe<Order_By>;
  relevance_score?: InputMaybe<Order_By>;
  submitRate?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

export type SubmitOutput = {
  __typename?: 'SubmitOutput';
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type SyncGSheetCalcInputsOutput = {
  __typename?: 'SyncGSheetCalcInputsOutput';
  GoogleSheetCalcTemplate?: Maybe<GoogleSheetCalcTemplates>;
  calcTemplateId: Scalars['uuid'];
};

/** columns and relationships of "TableConfigTypes" */
export type TableConfigTypes = {
  __typename?: 'TableConfigTypes';
  /** An array relationship */
  TableConfigs: Array<TableConfigs>;
  /** An aggregate relationship */
  TableConfigs_aggregate: TableConfigs_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "TableConfigTypes" */
export type TableConfigTypesTableConfigsArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


/** columns and relationships of "TableConfigTypes" */
export type TableConfigTypesTableConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};

/** aggregated selection of "TableConfigTypes" */
export type TableConfigTypes_Aggregate = {
  __typename?: 'TableConfigTypes_aggregate';
  aggregate?: Maybe<TableConfigTypes_Aggregate_Fields>;
  nodes: Array<TableConfigTypes>;
};

/** aggregate fields of "TableConfigTypes" */
export type TableConfigTypes_Aggregate_Fields = {
  __typename?: 'TableConfigTypes_aggregate_fields';
  avg?: Maybe<TableConfigTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TableConfigTypes_Max_Fields>;
  min?: Maybe<TableConfigTypes_Min_Fields>;
  stddev?: Maybe<TableConfigTypes_Stddev_Fields>;
  stddev_pop?: Maybe<TableConfigTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TableConfigTypes_Stddev_Samp_Fields>;
  sum?: Maybe<TableConfigTypes_Sum_Fields>;
  var_pop?: Maybe<TableConfigTypes_Var_Pop_Fields>;
  var_samp?: Maybe<TableConfigTypes_Var_Samp_Fields>;
  variance?: Maybe<TableConfigTypes_Variance_Fields>;
};


/** aggregate fields of "TableConfigTypes" */
export type TableConfigTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TableConfigTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TableConfigTypes_Avg_Fields = {
  __typename?: 'TableConfigTypes_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TableConfigTypes". All fields are combined with a logical 'AND'. */
export type TableConfigTypes_Bool_Exp = {
  TableConfigs?: InputMaybe<TableConfigs_Bool_Exp>;
  TableConfigs_aggregate?: InputMaybe<TableConfigs_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<TableConfigTypes_Bool_Exp>>;
  _not?: InputMaybe<TableConfigTypes_Bool_Exp>;
  _or?: InputMaybe<Array<TableConfigTypes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "TableConfigTypes" */
export enum TableConfigTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  TableConfigTypesPkey = 'TableConfigTypes_pkey'
}

/** input type for incrementing numeric columns in table "TableConfigTypes" */
export type TableConfigTypes_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "TableConfigTypes" */
export type TableConfigTypes_Insert_Input = {
  TableConfigs?: InputMaybe<TableConfigs_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TableConfigTypes_Max_Fields = {
  __typename?: 'TableConfigTypes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TableConfigTypes_Min_Fields = {
  __typename?: 'TableConfigTypes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "TableConfigTypes" */
export type TableConfigTypes_Mutation_Response = {
  __typename?: 'TableConfigTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TableConfigTypes>;
};

/** input type for inserting object relation for remote table "TableConfigTypes" */
export type TableConfigTypes_Obj_Rel_Insert_Input = {
  data: TableConfigTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TableConfigTypes_On_Conflict>;
};

/** on_conflict condition type for table "TableConfigTypes" */
export type TableConfigTypes_On_Conflict = {
  constraint: TableConfigTypes_Constraint;
  update_columns?: Array<TableConfigTypes_Update_Column>;
  where?: InputMaybe<TableConfigTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "TableConfigTypes". */
export type TableConfigTypes_Order_By = {
  TableConfigs_aggregate?: InputMaybe<TableConfigs_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TableConfigTypes */
export type TableConfigTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "TableConfigTypes" */
export enum TableConfigTypes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "TableConfigTypes" */
export type TableConfigTypes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TableConfigTypes_Stddev_Fields = {
  __typename?: 'TableConfigTypes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TableConfigTypes_Stddev_Pop_Fields = {
  __typename?: 'TableConfigTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TableConfigTypes_Stddev_Samp_Fields = {
  __typename?: 'TableConfigTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "TableConfigTypes" */
export type TableConfigTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TableConfigTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TableConfigTypes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TableConfigTypes_Sum_Fields = {
  __typename?: 'TableConfigTypes_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "TableConfigTypes" */
export enum TableConfigTypes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type TableConfigTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TableConfigTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TableConfigTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: TableConfigTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TableConfigTypes_Var_Pop_Fields = {
  __typename?: 'TableConfigTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TableConfigTypes_Var_Samp_Fields = {
  __typename?: 'TableConfigTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TableConfigTypes_Variance_Fields = {
  __typename?: 'TableConfigTypes_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "TableConfigs" */
export type TableConfigs = {
  __typename?: 'TableConfigs';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  TableConfigType?: Maybe<TableConfigTypes>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  table_config_type_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "TableConfigs" */
export type TableConfigsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "TableConfigs" */
export type TableConfigs_Aggregate = {
  __typename?: 'TableConfigs_aggregate';
  aggregate?: Maybe<TableConfigs_Aggregate_Fields>;
  nodes: Array<TableConfigs>;
};

export type TableConfigs_Aggregate_Bool_Exp = {
  count?: InputMaybe<TableConfigs_Aggregate_Bool_Exp_Count>;
};

export type TableConfigs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TableConfigs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TableConfigs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TableConfigs" */
export type TableConfigs_Aggregate_Fields = {
  __typename?: 'TableConfigs_aggregate_fields';
  avg?: Maybe<TableConfigs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TableConfigs_Max_Fields>;
  min?: Maybe<TableConfigs_Min_Fields>;
  stddev?: Maybe<TableConfigs_Stddev_Fields>;
  stddev_pop?: Maybe<TableConfigs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TableConfigs_Stddev_Samp_Fields>;
  sum?: Maybe<TableConfigs_Sum_Fields>;
  var_pop?: Maybe<TableConfigs_Var_Pop_Fields>;
  var_samp?: Maybe<TableConfigs_Var_Samp_Fields>;
  variance?: Maybe<TableConfigs_Variance_Fields>;
};


/** aggregate fields of "TableConfigs" */
export type TableConfigs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TableConfigs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TableConfigs" */
export type TableConfigs_Aggregate_Order_By = {
  avg?: InputMaybe<TableConfigs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TableConfigs_Max_Order_By>;
  min?: InputMaybe<TableConfigs_Min_Order_By>;
  stddev?: InputMaybe<TableConfigs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TableConfigs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TableConfigs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TableConfigs_Sum_Order_By>;
  var_pop?: InputMaybe<TableConfigs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TableConfigs_Var_Samp_Order_By>;
  variance?: InputMaybe<TableConfigs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TableConfigs_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "TableConfigs" */
export type TableConfigs_Arr_Rel_Insert_Input = {
  data: Array<TableConfigs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TableConfigs_On_Conflict>;
};

/** aggregate avg on columns */
export type TableConfigs_Avg_Fields = {
  __typename?: 'TableConfigs_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  table_config_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TableConfigs" */
export type TableConfigs_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TableConfigs". All fields are combined with a logical 'AND'. */
export type TableConfigs_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  TableConfigType?: InputMaybe<TableConfigTypes_Bool_Exp>;
  _and?: InputMaybe<Array<TableConfigs_Bool_Exp>>;
  _not?: InputMaybe<TableConfigs_Bool_Exp>;
  _or?: InputMaybe<Array<TableConfigs_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  table_config_type_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "TableConfigs" */
export enum TableConfigs_Constraint {
  /** unique or primary key constraint on columns "id" */
  TableConfigsPkey = 'TableConfigs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TableConfigs_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TableConfigs_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TableConfigs_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "TableConfigs" */
export type TableConfigs_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  table_config_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "TableConfigs" */
export type TableConfigs_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  TableConfigType?: InputMaybe<TableConfigTypes_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  table_config_type_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TableConfigs_Max_Fields = {
  __typename?: 'TableConfigs_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  table_config_type_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "TableConfigs" */
export type TableConfigs_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TableConfigs_Min_Fields = {
  __typename?: 'TableConfigs_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  table_config_type_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "TableConfigs" */
export type TableConfigs_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TableConfigs" */
export type TableConfigs_Mutation_Response = {
  __typename?: 'TableConfigs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TableConfigs>;
};

/** on_conflict condition type for table "TableConfigs" */
export type TableConfigs_On_Conflict = {
  constraint: TableConfigs_Constraint;
  update_columns?: Array<TableConfigs_Update_Column>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};

/** Ordering options when selecting data from "TableConfigs". */
export type TableConfigs_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  TableConfigType?: InputMaybe<TableConfigTypes_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TableConfigs */
export type TableConfigs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TableConfigs_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "TableConfigs" */
export enum TableConfigs_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  TableConfigTypeId = 'table_config_type_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "TableConfigs" */
export type TableConfigs_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  table_config_type_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TableConfigs_Stddev_Fields = {
  __typename?: 'TableConfigs_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  table_config_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TableConfigs" */
export type TableConfigs_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TableConfigs_Stddev_Pop_Fields = {
  __typename?: 'TableConfigs_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  table_config_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "TableConfigs" */
export type TableConfigs_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TableConfigs_Stddev_Samp_Fields = {
  __typename?: 'TableConfigs_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  table_config_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "TableConfigs" */
export type TableConfigs_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TableConfigs" */
export type TableConfigs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TableConfigs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TableConfigs_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  table_config_type_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TableConfigs_Sum_Fields = {
  __typename?: 'TableConfigs_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  table_config_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TableConfigs" */
export type TableConfigs_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** update columns of table "TableConfigs" */
export enum TableConfigs_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  TableConfigTypeId = 'table_config_type_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type TableConfigs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TableConfigs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TableConfigs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TableConfigs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TableConfigs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TableConfigs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TableConfigs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TableConfigs_Set_Input>;
  /** filter the rows which have to be updated */
  where: TableConfigs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TableConfigs_Var_Pop_Fields = {
  __typename?: 'TableConfigs_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  table_config_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "TableConfigs" */
export type TableConfigs_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TableConfigs_Var_Samp_Fields = {
  __typename?: 'TableConfigs_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  table_config_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "TableConfigs" */
export type TableConfigs_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TableConfigs_Variance_Fields = {
  __typename?: 'TableConfigs_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  table_config_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TableConfigs" */
export type TableConfigs_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  table_config_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Tags" */
export type Tags = {
  __typename?: 'Tags';
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  CreatedBy?: Maybe<Identities>;
  /** An array relationship */
  FileTags: Array<FileTags>;
  /** An aggregate relationship */
  FileTags_aggregate: FileTags_Aggregate;
  /** An array relationship */
  IdentityTags: Array<IdentityTags>;
  /** An aggregate relationship */
  IdentityTags_aggregate: IdentityTags_Aggregate;
  /** An object relationship */
  Updated: Identities;
  company_id: Scalars['Int'];
  con_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  title: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "Tags" */
export type TagsFileTagsArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


/** columns and relationships of "Tags" */
export type TagsFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


/** columns and relationships of "Tags" */
export type TagsIdentityTagsArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


/** columns and relationships of "Tags" */
export type TagsIdentityTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};

/** aggregated selection of "Tags" */
export type Tags_Aggregate = {
  __typename?: 'Tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "Tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'Tags_aggregate_fields';
  avg?: Maybe<Tags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
  stddev?: Maybe<Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Tags_Sum_Fields>;
  var_pop?: Maybe<Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Tags_Var_Samp_Fields>;
  variance?: Maybe<Tags_Variance_Fields>;
};


/** aggregate fields of "Tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tags_Avg_Fields = {
  __typename?: 'Tags_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  CreatedBy?: InputMaybe<Identities_Bool_Exp>;
  FileTags?: InputMaybe<FileTags_Bool_Exp>;
  FileTags_aggregate?: InputMaybe<FileTags_Aggregate_Bool_Exp>;
  IdentityTags?: InputMaybe<IdentityTags_Bool_Exp>;
  IdentityTags_aggregate?: InputMaybe<IdentityTags_Aggregate_Bool_Exp>;
  Updated?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  con_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "company_id", "con_id" */
  TagsConIdCompanyIdKey = 'Tags_con_id_company_id_key',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'Tags_pkey'
}

/** input type for incrementing numeric columns in table "Tags" */
export type Tags_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Tags" */
export type Tags_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  CreatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  FileTags?: InputMaybe<FileTags_Arr_Rel_Insert_Input>;
  IdentityTags?: InputMaybe<IdentityTags_Arr_Rel_Insert_Input>;
  Updated?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  con_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'Tags_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  con_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'Tags_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  con_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Tags" */
export type Tags_Mutation_Response = {
  __typename?: 'Tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "Tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "Tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "Tags". */
export type Tags_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  CreatedBy?: InputMaybe<Identities_Order_By>;
  FileTags_aggregate?: InputMaybe<FileTags_Aggregate_Order_By>;
  IdentityTags_aggregate?: InputMaybe<IdentityTags_Aggregate_Order_By>;
  Updated?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  con_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Tags" */
export enum Tags_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConId = 'con_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "Tags" */
export type Tags_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  con_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Tags_Stddev_Fields = {
  __typename?: 'Tags_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tags_Stddev_Pop_Fields = {
  __typename?: 'Tags_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tags_Stddev_Samp_Fields = {
  __typename?: 'Tags_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  con_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Tags_Sum_Fields = {
  __typename?: 'Tags_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "Tags" */
export enum Tags_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConId = 'con_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tags_Var_Pop_Fields = {
  __typename?: 'Tags_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tags_Var_Samp_Fields = {
  __typename?: 'Tags_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tags_Variance_Fields = {
  __typename?: 'Tags_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "TaskGroupAssignees" */
export type TaskGroupAssignees = {
  __typename?: 'TaskGroupAssignees';
  /** An object relationship */
  Assignee: Identities;
  /** An object relationship */
  TaskGroup: TaskGroups;
  assignee_id: Scalars['Int'];
  id: Scalars['uuid'];
  task_group_id: Scalars['Int'];
};

/** aggregated selection of "TaskGroupAssignees" */
export type TaskGroupAssignees_Aggregate = {
  __typename?: 'TaskGroupAssignees_aggregate';
  aggregate?: Maybe<TaskGroupAssignees_Aggregate_Fields>;
  nodes: Array<TaskGroupAssignees>;
};

export type TaskGroupAssignees_Aggregate_Bool_Exp = {
  count?: InputMaybe<TaskGroupAssignees_Aggregate_Bool_Exp_Count>;
};

export type TaskGroupAssignees_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TaskGroupAssignees" */
export type TaskGroupAssignees_Aggregate_Fields = {
  __typename?: 'TaskGroupAssignees_aggregate_fields';
  avg?: Maybe<TaskGroupAssignees_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TaskGroupAssignees_Max_Fields>;
  min?: Maybe<TaskGroupAssignees_Min_Fields>;
  stddev?: Maybe<TaskGroupAssignees_Stddev_Fields>;
  stddev_pop?: Maybe<TaskGroupAssignees_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TaskGroupAssignees_Stddev_Samp_Fields>;
  sum?: Maybe<TaskGroupAssignees_Sum_Fields>;
  var_pop?: Maybe<TaskGroupAssignees_Var_Pop_Fields>;
  var_samp?: Maybe<TaskGroupAssignees_Var_Samp_Fields>;
  variance?: Maybe<TaskGroupAssignees_Variance_Fields>;
};


/** aggregate fields of "TaskGroupAssignees" */
export type TaskGroupAssignees_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Aggregate_Order_By = {
  avg?: InputMaybe<TaskGroupAssignees_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TaskGroupAssignees_Max_Order_By>;
  min?: InputMaybe<TaskGroupAssignees_Min_Order_By>;
  stddev?: InputMaybe<TaskGroupAssignees_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TaskGroupAssignees_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TaskGroupAssignees_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TaskGroupAssignees_Sum_Order_By>;
  var_pop?: InputMaybe<TaskGroupAssignees_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TaskGroupAssignees_Var_Samp_Order_By>;
  variance?: InputMaybe<TaskGroupAssignees_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TaskGroupAssignees" */
export type TaskGroupAssignees_Arr_Rel_Insert_Input = {
  data: Array<TaskGroupAssignees_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskGroupAssignees_On_Conflict>;
};

/** aggregate avg on columns */
export type TaskGroupAssignees_Avg_Fields = {
  __typename?: 'TaskGroupAssignees_avg_fields';
  assignee_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Avg_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TaskGroupAssignees". All fields are combined with a logical 'AND'. */
export type TaskGroupAssignees_Bool_Exp = {
  Assignee?: InputMaybe<Identities_Bool_Exp>;
  TaskGroup?: InputMaybe<TaskGroups_Bool_Exp>;
  _and?: InputMaybe<Array<TaskGroupAssignees_Bool_Exp>>;
  _not?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
  _or?: InputMaybe<Array<TaskGroupAssignees_Bool_Exp>>;
  assignee_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task_group_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "TaskGroupAssignees" */
export enum TaskGroupAssignees_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskGroupAssigneesPkey = 'TaskGroupAssignees_pkey',
  /** unique or primary key constraint on columns "assignee_id", "task_group_id" */
  TaskGroupAssigneesTaskGroupIdAssigneeIdKey = 'TaskGroupAssignees_task_group_id_assignee_id_key'
}

/** input type for incrementing numeric columns in table "TaskGroupAssignees" */
export type TaskGroupAssignees_Inc_Input = {
  assignee_id?: InputMaybe<Scalars['Int']>;
  task_group_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "TaskGroupAssignees" */
export type TaskGroupAssignees_Insert_Input = {
  Assignee?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  TaskGroup?: InputMaybe<TaskGroups_Obj_Rel_Insert_Input>;
  assignee_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  task_group_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TaskGroupAssignees_Max_Fields = {
  __typename?: 'TaskGroupAssignees_max_fields';
  assignee_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  task_group_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Max_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TaskGroupAssignees_Min_Fields = {
  __typename?: 'TaskGroupAssignees_min_fields';
  assignee_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  task_group_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Min_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TaskGroupAssignees" */
export type TaskGroupAssignees_Mutation_Response = {
  __typename?: 'TaskGroupAssignees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskGroupAssignees>;
};

/** on_conflict condition type for table "TaskGroupAssignees" */
export type TaskGroupAssignees_On_Conflict = {
  constraint: TaskGroupAssignees_Constraint;
  update_columns?: Array<TaskGroupAssignees_Update_Column>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};

/** Ordering options when selecting data from "TaskGroupAssignees". */
export type TaskGroupAssignees_Order_By = {
  Assignee?: InputMaybe<Identities_Order_By>;
  TaskGroup?: InputMaybe<TaskGroups_Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TaskGroupAssignees */
export type TaskGroupAssignees_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "TaskGroupAssignees" */
export enum TaskGroupAssignees_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskGroupId = 'task_group_id'
}

/** input type for updating data in table "TaskGroupAssignees" */
export type TaskGroupAssignees_Set_Input = {
  assignee_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  task_group_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TaskGroupAssignees_Stddev_Fields = {
  __typename?: 'TaskGroupAssignees_stddev_fields';
  assignee_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Stddev_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TaskGroupAssignees_Stddev_Pop_Fields = {
  __typename?: 'TaskGroupAssignees_stddev_pop_fields';
  assignee_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Stddev_Pop_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TaskGroupAssignees_Stddev_Samp_Fields = {
  __typename?: 'TaskGroupAssignees_stddev_samp_fields';
  assignee_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Stddev_Samp_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TaskGroupAssignees" */
export type TaskGroupAssignees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TaskGroupAssignees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TaskGroupAssignees_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  task_group_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TaskGroupAssignees_Sum_Fields = {
  __typename?: 'TaskGroupAssignees_sum_fields';
  assignee_id?: Maybe<Scalars['Int']>;
  task_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Sum_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** update columns of table "TaskGroupAssignees" */
export enum TaskGroupAssignees_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskGroupId = 'task_group_id'
}

export type TaskGroupAssignees_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TaskGroupAssignees_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskGroupAssignees_Set_Input>;
  /** filter the rows which have to be updated */
  where: TaskGroupAssignees_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TaskGroupAssignees_Var_Pop_Fields = {
  __typename?: 'TaskGroupAssignees_var_pop_fields';
  assignee_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Var_Pop_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TaskGroupAssignees_Var_Samp_Fields = {
  __typename?: 'TaskGroupAssignees_var_samp_fields';
  assignee_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Var_Samp_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TaskGroupAssignees_Variance_Fields = {
  __typename?: 'TaskGroupAssignees_variance_fields';
  assignee_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TaskGroupAssignees" */
export type TaskGroupAssignees_Variance_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "TaskGroups" */
export type TaskGroups = {
  __typename?: 'TaskGroups';
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  Identity: Identities;
  /** An array relationship */
  TaskGroupAssignees: Array<TaskGroupAssignees>;
  /** An aggregate relationship */
  TaskGroupAssignees_aggregate: TaskGroupAssignees_Aggregate;
  /** An array relationship */
  Tasks: Array<Tasks>;
  /** An aggregate relationship */
  Tasks_aggregate: Tasks_Aggregate;
  company_id: Scalars['Int'];
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  owner_id: Scalars['Int'];
  pinned: Scalars['Boolean'];
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "TaskGroups" */
export type TaskGroupsTaskGroupAssigneesArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


/** columns and relationships of "TaskGroups" */
export type TaskGroupsTaskGroupAssignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


/** columns and relationships of "TaskGroups" */
export type TaskGroupsTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "TaskGroups" */
export type TaskGroupsTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "TaskGroups" */
export type TaskGroups_Aggregate = {
  __typename?: 'TaskGroups_aggregate';
  aggregate?: Maybe<TaskGroups_Aggregate_Fields>;
  nodes: Array<TaskGroups>;
};

export type TaskGroups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<TaskGroups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<TaskGroups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<TaskGroups_Aggregate_Bool_Exp_Count>;
};

export type TaskGroups_Aggregate_Bool_Exp_Bool_And = {
  arguments: TaskGroups_Select_Column_TaskGroups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TaskGroups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type TaskGroups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: TaskGroups_Select_Column_TaskGroups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TaskGroups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type TaskGroups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TaskGroups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TaskGroups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TaskGroups" */
export type TaskGroups_Aggregate_Fields = {
  __typename?: 'TaskGroups_aggregate_fields';
  avg?: Maybe<TaskGroups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TaskGroups_Max_Fields>;
  min?: Maybe<TaskGroups_Min_Fields>;
  stddev?: Maybe<TaskGroups_Stddev_Fields>;
  stddev_pop?: Maybe<TaskGroups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TaskGroups_Stddev_Samp_Fields>;
  sum?: Maybe<TaskGroups_Sum_Fields>;
  var_pop?: Maybe<TaskGroups_Var_Pop_Fields>;
  var_samp?: Maybe<TaskGroups_Var_Samp_Fields>;
  variance?: Maybe<TaskGroups_Variance_Fields>;
};


/** aggregate fields of "TaskGroups" */
export type TaskGroups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TaskGroups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TaskGroups" */
export type TaskGroups_Aggregate_Order_By = {
  avg?: InputMaybe<TaskGroups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TaskGroups_Max_Order_By>;
  min?: InputMaybe<TaskGroups_Min_Order_By>;
  stddev?: InputMaybe<TaskGroups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TaskGroups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TaskGroups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TaskGroups_Sum_Order_By>;
  var_pop?: InputMaybe<TaskGroups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TaskGroups_Var_Samp_Order_By>;
  variance?: InputMaybe<TaskGroups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TaskGroups" */
export type TaskGroups_Arr_Rel_Insert_Input = {
  data: Array<TaskGroups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskGroups_On_Conflict>;
};

/** aggregate avg on columns */
export type TaskGroups_Avg_Fields = {
  __typename?: 'TaskGroups_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by avg() on columns of table "TaskGroups" */
export type TaskGroups_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TaskGroups". All fields are combined with a logical 'AND'. */
export type TaskGroups_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  TaskGroupAssignees?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
  TaskGroupAssignees_aggregate?: InputMaybe<TaskGroupAssignees_Aggregate_Bool_Exp>;
  Tasks?: InputMaybe<Tasks_Bool_Exp>;
  Tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<TaskGroups_Bool_Exp>>;
  _not?: InputMaybe<TaskGroups_Bool_Exp>;
  _or?: InputMaybe<Array<TaskGroups_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  complete_tasks?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  incomplete_tasks?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  pinned?: InputMaybe<Boolean_Comparison_Exp>;
  total_tasks?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "TaskGroups" */
export enum TaskGroups_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskGroupPkey = 'TaskGroup_pkey'
}

/** input type for incrementing numeric columns in table "TaskGroups" */
export type TaskGroups_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "TaskGroups" */
export type TaskGroups_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  TaskGroupAssignees?: InputMaybe<TaskGroupAssignees_Arr_Rel_Insert_Input>;
  Tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TaskGroups_Max_Fields = {
  __typename?: 'TaskGroups_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "TaskGroups" */
export type TaskGroups_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TaskGroups_Min_Fields = {
  __typename?: 'TaskGroups_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "TaskGroups" */
export type TaskGroups_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TaskGroups" */
export type TaskGroups_Mutation_Response = {
  __typename?: 'TaskGroups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskGroups>;
};

/** input type for inserting object relation for remote table "TaskGroups" */
export type TaskGroups_Obj_Rel_Insert_Input = {
  data: TaskGroups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskGroups_On_Conflict>;
};

/** on_conflict condition type for table "TaskGroups" */
export type TaskGroups_On_Conflict = {
  constraint: TaskGroups_Constraint;
  update_columns?: Array<TaskGroups_Update_Column>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};

/** Ordering options when selecting data from "TaskGroups". */
export type TaskGroups_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  TaskGroupAssignees_aggregate?: InputMaybe<TaskGroupAssignees_Aggregate_Order_By>;
  Tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  complete_tasks?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incomplete_tasks?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  pinned?: InputMaybe<Order_By>;
  total_tasks?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TaskGroups */
export type TaskGroups_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "TaskGroups" */
export enum TaskGroups_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "TaskGroups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TaskGroups" */
export enum TaskGroups_Select_Column_TaskGroups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Pinned = 'pinned'
}

/** select "TaskGroups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TaskGroups" */
export enum TaskGroups_Select_Column_TaskGroups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Pinned = 'pinned'
}

/** input type for updating data in table "TaskGroups" */
export type TaskGroups_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TaskGroups_Stddev_Fields = {
  __typename?: 'TaskGroups_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "TaskGroups" */
export type TaskGroups_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TaskGroups_Stddev_Pop_Fields = {
  __typename?: 'TaskGroups_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by stddev_pop() on columns of table "TaskGroups" */
export type TaskGroups_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TaskGroups_Stddev_Samp_Fields = {
  __typename?: 'TaskGroups_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by stddev_samp() on columns of table "TaskGroups" */
export type TaskGroups_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TaskGroups" */
export type TaskGroups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TaskGroups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TaskGroups_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TaskGroups_Sum_Fields = {
  __typename?: 'TaskGroups_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TaskGroups" */
export type TaskGroups_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** update columns of table "TaskGroups" */
export enum TaskGroups_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type TaskGroups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TaskGroups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskGroups_Set_Input>;
  /** filter the rows which have to be updated */
  where: TaskGroups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TaskGroups_Var_Pop_Fields = {
  __typename?: 'TaskGroups_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by var_pop() on columns of table "TaskGroups" */
export type TaskGroups_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TaskGroups_Var_Samp_Fields = {
  __typename?: 'TaskGroups_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by var_samp() on columns of table "TaskGroups" */
export type TaskGroups_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TaskGroups_Variance_Fields = {
  __typename?: 'TaskGroups_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "complete_tasks" */
  complete_tasks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "incomplete_tasks" */
  incomplete_tasks?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_tasks" */
  total_tasks?: Maybe<Scalars['Int']>;
};

/** order by variance() on columns of table "TaskGroups" */
export type TaskGroups_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** Enum table used for task priority */
export type TaskPriorityTypes = {
  __typename?: 'TaskPriorityTypes';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "TaskPriorityTypes" */
export type TaskPriorityTypes_Aggregate = {
  __typename?: 'TaskPriorityTypes_aggregate';
  aggregate?: Maybe<TaskPriorityTypes_Aggregate_Fields>;
  nodes: Array<TaskPriorityTypes>;
};

/** aggregate fields of "TaskPriorityTypes" */
export type TaskPriorityTypes_Aggregate_Fields = {
  __typename?: 'TaskPriorityTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TaskPriorityTypes_Max_Fields>;
  min?: Maybe<TaskPriorityTypes_Min_Fields>;
};


/** aggregate fields of "TaskPriorityTypes" */
export type TaskPriorityTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TaskPriorityTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "TaskPriorityTypes". All fields are combined with a logical 'AND'. */
export type TaskPriorityTypes_Bool_Exp = {
  _and?: InputMaybe<Array<TaskPriorityTypes_Bool_Exp>>;
  _not?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
  _or?: InputMaybe<Array<TaskPriorityTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TaskPriorityTypes" */
export enum TaskPriorityTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  TaskPriorityTypePkey = 'TaskPriorityType_pkey'
}

export enum TaskPriorityTypes_Enum {
  /** Highest priority tasks must be completed first */
  High = 'High',
  /** Lowest priority, all other tasks should be completed prior */
  Low = 'Low',
  /** Medium priority tasks should be completed before low */
  Medium = 'Medium'
}

/** Boolean expression to compare columns of type "TaskPriorityTypes_enum". All fields are combined with logical 'AND'. */
export type TaskPriorityTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<TaskPriorityTypes_Enum>;
  _in?: InputMaybe<Array<TaskPriorityTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TaskPriorityTypes_Enum>;
  _nin?: InputMaybe<Array<TaskPriorityTypes_Enum>>;
};

/** input type for inserting data into table "TaskPriorityTypes" */
export type TaskPriorityTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TaskPriorityTypes_Max_Fields = {
  __typename?: 'TaskPriorityTypes_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TaskPriorityTypes_Min_Fields = {
  __typename?: 'TaskPriorityTypes_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "TaskPriorityTypes" */
export type TaskPriorityTypes_Mutation_Response = {
  __typename?: 'TaskPriorityTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskPriorityTypes>;
};

/** input type for inserting object relation for remote table "TaskPriorityTypes" */
export type TaskPriorityTypes_Obj_Rel_Insert_Input = {
  data: TaskPriorityTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskPriorityTypes_On_Conflict>;
};

/** on_conflict condition type for table "TaskPriorityTypes" */
export type TaskPriorityTypes_On_Conflict = {
  constraint: TaskPriorityTypes_Constraint;
  update_columns?: Array<TaskPriorityTypes_Update_Column>;
  where?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "TaskPriorityTypes". */
export type TaskPriorityTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TaskPriorityTypes */
export type TaskPriorityTypes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "TaskPriorityTypes" */
export enum TaskPriorityTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "TaskPriorityTypes" */
export type TaskPriorityTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "TaskPriorityTypes" */
export type TaskPriorityTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TaskPriorityTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TaskPriorityTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "TaskPriorityTypes" */
export enum TaskPriorityTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type TaskPriorityTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskPriorityTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: TaskPriorityTypes_Bool_Exp;
};

/** Enum used for the Tasks relation */
export type TaskTypes = {
  __typename?: 'TaskTypes';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "TaskTypes" */
export type TaskTypes_Aggregate = {
  __typename?: 'TaskTypes_aggregate';
  aggregate?: Maybe<TaskTypes_Aggregate_Fields>;
  nodes: Array<TaskTypes>;
};

/** aggregate fields of "TaskTypes" */
export type TaskTypes_Aggregate_Fields = {
  __typename?: 'TaskTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TaskTypes_Max_Fields>;
  min?: Maybe<TaskTypes_Min_Fields>;
};


/** aggregate fields of "TaskTypes" */
export type TaskTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TaskTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "TaskTypes". All fields are combined with a logical 'AND'. */
export type TaskTypes_Bool_Exp = {
  _and?: InputMaybe<Array<TaskTypes_Bool_Exp>>;
  _not?: InputMaybe<TaskTypes_Bool_Exp>;
  _or?: InputMaybe<Array<TaskTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TaskTypes" */
export enum TaskTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  TaskTypesPkey = 'TaskTypes_pkey'
}

export enum TaskTypes_Enum {
  /** Call the associated candidate */
  Call = 'call',
  /** Send an email to the associated candidate */
  Email = 'email',
  /** Follow up with the associated candidate */
  FollowUp = 'follow_up',
  /** General task */
  Na = 'na',
  /** Conduct a phone screen with the candidate */
  PhoneScreen = 'phone_screen'
}

/** Boolean expression to compare columns of type "TaskTypes_enum". All fields are combined with logical 'AND'. */
export type TaskTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<TaskTypes_Enum>;
  _in?: InputMaybe<Array<TaskTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TaskTypes_Enum>;
  _nin?: InputMaybe<Array<TaskTypes_Enum>>;
};

/** input type for inserting data into table "TaskTypes" */
export type TaskTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TaskTypes_Max_Fields = {
  __typename?: 'TaskTypes_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TaskTypes_Min_Fields = {
  __typename?: 'TaskTypes_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "TaskTypes" */
export type TaskTypes_Mutation_Response = {
  __typename?: 'TaskTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskTypes>;
};

/** on_conflict condition type for table "TaskTypes" */
export type TaskTypes_On_Conflict = {
  constraint: TaskTypes_Constraint;
  update_columns?: Array<TaskTypes_Update_Column>;
  where?: InputMaybe<TaskTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "TaskTypes". */
export type TaskTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TaskTypes */
export type TaskTypes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "TaskTypes" */
export enum TaskTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "TaskTypes" */
export type TaskTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "TaskTypes" */
export type TaskTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TaskTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TaskTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "TaskTypes" */
export enum TaskTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type TaskTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: TaskTypes_Bool_Exp;
};

/** columns and relationships of "Tasks" */
export type Tasks = {
  __typename?: 'Tasks';
  /** An object relationship */
  Candidate?: Maybe<Identities>;
  /** An object relationship */
  CompletedBy?: Maybe<Identities>;
  /** An object relationship */
  Contract?: Maybe<Contracts>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An array relationship */
  Matches: Array<Matches_Without_Author_With_Int_Uuid>;
  /** An aggregate relationship */
  Matches_aggregate: Matches_Without_Author_With_Int_Uuid_Aggregate;
  /** An object relationship */
  TaskGroup: TaskGroups;
  /** An object relationship */
  TaskPriorityType: TaskPriorityTypes;
  candidate_id: Scalars['Int'];
  completed: Scalars['Boolean'];
  completed_at?: Maybe<Scalars['date']>;
  completed_by?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deleted: Scalars['Boolean'];
  due_date: Scalars['timestamptz'];
  /** A computed field, executes function "duplicate_task_within_taskgroup" */
  duplicate_within_group?: Maybe<Scalars['Boolean']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  job_id?: Maybe<Scalars['Int']>;
  priority: TaskPriorityTypes_Enum;
  task_group_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: TaskTypes_Enum;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Tasks" */
export type TasksMatchesArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Tasks" */
export type TasksMatches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


/** columns and relationships of "Tasks" */
export type TasksEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Tasks" */
export type Tasks_Aggregate = {
  __typename?: 'Tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

export type Tasks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tasks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tasks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tasks_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'Tasks_aggregate_fields';
  avg?: Maybe<Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
  stddev?: Maybe<Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Sum_Fields>;
  var_pop?: Maybe<Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Variance_Fields>;
};


/** aggregate fields of "Tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Tasks" */
export type Tasks_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Max_Order_By>;
  min?: InputMaybe<Tasks_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tasks_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Tasks" */
export type Tasks_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Avg_Fields = {
  __typename?: 'Tasks_avg_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Tasks" */
export type Tasks_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  Candidate?: InputMaybe<Identities_Bool_Exp>;
  CompletedBy?: InputMaybe<Identities_Bool_Exp>;
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Bool_Exp>;
  TaskGroup?: InputMaybe<TaskGroups_Bool_Exp>;
  TaskPriorityType?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
  _and?: InputMaybe<Array<Tasks_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  completed_at?: InputMaybe<Date_Comparison_Exp>;
  completed_by?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  duplicate_within_group?: InputMaybe<Boolean_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  priority?: InputMaybe<TaskPriorityTypes_Enum_Comparison_Exp>;
  task_group_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<TaskTypes_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  TasksPkey = 'Tasks_pkey',
  /** unique or primary key constraint on columns "etl_id_token" */
  TasksUniqueEtlIdToken = 'tasks_unique_etl_id_token'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tasks_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tasks_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tasks_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Tasks" */
export type Tasks_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  completed_by?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  task_group_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Tasks" */
export type Tasks_Insert_Input = {
  Candidate?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  CompletedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  Matches?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Arr_Rel_Insert_Input>;
  TaskGroup?: InputMaybe<TaskGroups_Obj_Rel_Insert_Input>;
  TaskPriorityType?: InputMaybe<TaskPriorityTypes_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  completed_at?: InputMaybe<Scalars['date']>;
  completed_by?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<TaskPriorityTypes_Enum>;
  task_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TaskTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'Tasks_max_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  completed_at?: Maybe<Scalars['date']>;
  completed_by?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  task_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Tasks" */
export type Tasks_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'Tasks_min_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  completed_at?: Maybe<Scalars['date']>;
  completed_by?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  task_group_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Tasks" */
export type Tasks_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'Tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** on_conflict condition type for table "Tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "Tasks". */
export type Tasks_Order_By = {
  Candidate?: InputMaybe<Identities_Order_By>;
  CompletedBy?: InputMaybe<Identities_Order_By>;
  Contract?: InputMaybe<Contracts_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  Matches_aggregate?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Order_By>;
  TaskGroup?: InputMaybe<TaskGroups_Order_By>;
  TaskPriorityType?: InputMaybe<TaskPriorityTypes_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  completed?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  duplicate_within_group?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tasks_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Tasks" */
export enum Tasks_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CompletedBy = 'completed_by',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  TaskGroupId = 'task_group_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Tasks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Completed = 'completed',
  /** column name */
  Deleted = 'deleted'
}

/** select "Tasks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Completed = 'completed',
  /** column name */
  Deleted = 'deleted'
}

/** input type for updating data in table "Tasks" */
export type Tasks_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  completed_at?: InputMaybe<Scalars['date']>;
  completed_by?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<TaskPriorityTypes_Enum>;
  task_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TaskTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Stddev_Fields = {
  __typename?: 'Tasks_stddev_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Tasks" */
export type Tasks_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Stddev_Pop_Fields = {
  __typename?: 'Tasks_stddev_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Tasks" */
export type Tasks_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Stddev_Samp_Fields = {
  __typename?: 'Tasks_stddev_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Tasks" */
export type Tasks_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  completed_at?: InputMaybe<Scalars['date']>;
  completed_by?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<TaskPriorityTypes_Enum>;
  task_group_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TaskTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Sum_Fields = {
  __typename?: 'Tasks_sum_fields';
  candidate_id?: Maybe<Scalars['Int']>;
  completed_by?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  task_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Tasks" */
export type Tasks_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** update columns of table "Tasks" */
export enum Tasks_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CompletedBy = 'completed_by',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  TaskGroupId = 'task_group_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tasks_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tasks_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tasks_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tasks_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tasks_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tasks_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Var_Pop_Fields = {
  __typename?: 'Tasks_var_pop_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Tasks" */
export type Tasks_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Var_Samp_Fields = {
  __typename?: 'Tasks_var_samp_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Tasks" */
export type Tasks_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Variance_Fields = {
  __typename?: 'Tasks_variance_fields';
  candidate_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  task_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Tasks" */
export type Tasks_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  completed_by?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  task_group_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Terminations" */
export type Terminations = {
  __typename?: 'Terminations';
  /** An array relationship */
  Employments: Array<Employments>;
  /** An aggregate relationship */
  Employments_aggregate: Employments_Aggregate;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  /** An object relationship */
  Status?: Maybe<CandidateStatuses>;
  /** An object relationship */
  TerminatedBy?: Maybe<Identities>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  identityId?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  rehireable: Scalars['Boolean'];
  status_id?: Maybe<Scalars['Int']>;
  terminatedBy?: Maybe<Scalars['Int']>;
  termination_date?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Terminations" */
export type TerminationsEmploymentsArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


/** columns and relationships of "Terminations" */
export type TerminationsEmployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


/** columns and relationships of "Terminations" */
export type TerminationsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Terminations" */
export type Terminations_Aggregate = {
  __typename?: 'Terminations_aggregate';
  aggregate?: Maybe<Terminations_Aggregate_Fields>;
  nodes: Array<Terminations>;
};

export type Terminations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Terminations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Terminations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Terminations_Aggregate_Bool_Exp_Count>;
};

export type Terminations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Terminations_Select_Column_Terminations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Terminations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Terminations_Select_Column_Terminations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Terminations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Terminations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Terminations" */
export type Terminations_Aggregate_Fields = {
  __typename?: 'Terminations_aggregate_fields';
  avg?: Maybe<Terminations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Terminations_Max_Fields>;
  min?: Maybe<Terminations_Min_Fields>;
  stddev?: Maybe<Terminations_Stddev_Fields>;
  stddev_pop?: Maybe<Terminations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Terminations_Stddev_Samp_Fields>;
  sum?: Maybe<Terminations_Sum_Fields>;
  var_pop?: Maybe<Terminations_Var_Pop_Fields>;
  var_samp?: Maybe<Terminations_Var_Samp_Fields>;
  variance?: Maybe<Terminations_Variance_Fields>;
};


/** aggregate fields of "Terminations" */
export type Terminations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Terminations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Terminations" */
export type Terminations_Aggregate_Order_By = {
  avg?: InputMaybe<Terminations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Terminations_Max_Order_By>;
  min?: InputMaybe<Terminations_Min_Order_By>;
  stddev?: InputMaybe<Terminations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Terminations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Terminations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Terminations_Sum_Order_By>;
  var_pop?: InputMaybe<Terminations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Terminations_Var_Samp_Order_By>;
  variance?: InputMaybe<Terminations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Terminations_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Terminations" */
export type Terminations_Arr_Rel_Insert_Input = {
  data: Array<Terminations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Terminations_On_Conflict>;
};

/** aggregate avg on columns */
export type Terminations_Avg_Fields = {
  __typename?: 'Terminations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  terminatedBy?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Terminations" */
export type Terminations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Terminations". All fields are combined with a logical 'AND'. */
export type Terminations_Bool_Exp = {
  Employments?: InputMaybe<Employments_Bool_Exp>;
  Employments_aggregate?: InputMaybe<Employments_Aggregate_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  Status?: InputMaybe<CandidateStatuses_Bool_Exp>;
  TerminatedBy?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Terminations_Bool_Exp>>;
  _not?: InputMaybe<Terminations_Bool_Exp>;
  _or?: InputMaybe<Array<Terminations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  rehireable?: InputMaybe<Boolean_Comparison_Exp>;
  status_id?: InputMaybe<Int_Comparison_Exp>;
  terminatedBy?: InputMaybe<Int_Comparison_Exp>;
  termination_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Terminations" */
export enum Terminations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerminationsPkey = 'Terminations_pkey',
  /** unique or primary key constraint on columns "etl_id_token" */
  TerminationsUniqueEtlIdToken = 'terminations_unique_etl_id_token'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Terminations_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Terminations_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Terminations_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Terminations" */
export type Terminations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  status_id?: InputMaybe<Scalars['Int']>;
  terminatedBy?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Terminations" */
export type Terminations_Insert_Input = {
  Employments?: InputMaybe<Employments_Arr_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  Status?: InputMaybe<CandidateStatuses_Obj_Rel_Insert_Input>;
  TerminatedBy?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  rehireable?: InputMaybe<Scalars['Boolean']>;
  status_id?: InputMaybe<Scalars['Int']>;
  terminatedBy?: InputMaybe<Scalars['Int']>;
  termination_date?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Terminations_Max_Fields = {
  __typename?: 'Terminations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  status_id?: Maybe<Scalars['Int']>;
  terminatedBy?: Maybe<Scalars['Int']>;
  termination_date?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Terminations" */
export type Terminations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  termination_date?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Terminations_Min_Fields = {
  __typename?: 'Terminations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  status_id?: Maybe<Scalars['Int']>;
  terminatedBy?: Maybe<Scalars['Int']>;
  termination_date?: Maybe<Scalars['timestamptz']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Terminations" */
export type Terminations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  termination_date?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Terminations" */
export type Terminations_Mutation_Response = {
  __typename?: 'Terminations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Terminations>;
};

/** input type for inserting object relation for remote table "Terminations" */
export type Terminations_Obj_Rel_Insert_Input = {
  data: Terminations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Terminations_On_Conflict>;
};

/** on_conflict condition type for table "Terminations" */
export type Terminations_On_Conflict = {
  constraint: Terminations_Constraint;
  update_columns?: Array<Terminations_Update_Column>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};

/** Ordering options when selecting data from "Terminations". */
export type Terminations_Order_By = {
  Employments_aggregate?: InputMaybe<Employments_Aggregate_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  Status?: InputMaybe<CandidateStatuses_Order_By>;
  TerminatedBy?: InputMaybe<Identities_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  rehireable?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  termination_date?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Terminations */
export type Terminations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Terminations_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Terminations" */
export enum Terminations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Rehireable = 'rehireable',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TerminatedBy = 'terminatedBy',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "Terminations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Terminations" */
export enum Terminations_Select_Column_Terminations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Rehireable = 'rehireable'
}

/** select "Terminations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Terminations" */
export enum Terminations_Select_Column_Terminations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Rehireable = 'rehireable'
}

/** input type for updating data in table "Terminations" */
export type Terminations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  rehireable?: InputMaybe<Scalars['Boolean']>;
  status_id?: InputMaybe<Scalars['Int']>;
  terminatedBy?: InputMaybe<Scalars['Int']>;
  termination_date?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Terminations_Stddev_Fields = {
  __typename?: 'Terminations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  terminatedBy?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Terminations" */
export type Terminations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Terminations_Stddev_Pop_Fields = {
  __typename?: 'Terminations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  terminatedBy?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Terminations" */
export type Terminations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Terminations_Stddev_Samp_Fields = {
  __typename?: 'Terminations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  terminatedBy?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Terminations" */
export type Terminations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Terminations" */
export type Terminations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Terminations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Terminations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  rehireable?: InputMaybe<Scalars['Boolean']>;
  status_id?: InputMaybe<Scalars['Int']>;
  terminatedBy?: InputMaybe<Scalars['Int']>;
  termination_date?: InputMaybe<Scalars['timestamptz']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Terminations_Sum_Fields = {
  __typename?: 'Terminations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  status_id?: Maybe<Scalars['Int']>;
  terminatedBy?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Terminations" */
export type Terminations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "Terminations" */
export enum Terminations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Rehireable = 'rehireable',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TerminatedBy = 'terminatedBy',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Terminations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Terminations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Terminations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Terminations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Terminations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Terminations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Terminations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Terminations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Terminations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Terminations_Var_Pop_Fields = {
  __typename?: 'Terminations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  terminatedBy?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Terminations" */
export type Terminations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Terminations_Var_Samp_Fields = {
  __typename?: 'Terminations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  terminatedBy?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Terminations" */
export type Terminations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Terminations_Variance_Fields = {
  __typename?: 'Terminations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  terminatedBy?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Terminations" */
export type Terminations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  terminatedBy?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "TimeInStages" */
export type TimeInStages = {
  __typename?: 'TimeInStages';
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  from_stage_id?: Maybe<Scalars['Int']>;
  humanized_duration?: Maybe<Scalars['String']>;
  identity_id: Scalars['Int'];
  in_time?: Maybe<Scalars['timestamptz']>;
  job_id: Scalars['Int'];
  out_time?: Maybe<Scalars['timestamptz']>;
  reason?: Maybe<Scalars['String']>;
  stage_id: Scalars['Int'];
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "TimeInStages" */
export type TimeInStages_Aggregate = {
  __typename?: 'TimeInStages_aggregate';
  aggregate?: Maybe<TimeInStages_Aggregate_Fields>;
  nodes: Array<TimeInStages>;
};

/** aggregate fields of "TimeInStages" */
export type TimeInStages_Aggregate_Fields = {
  __typename?: 'TimeInStages_aggregate_fields';
  avg?: Maybe<TimeInStages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TimeInStages_Max_Fields>;
  min?: Maybe<TimeInStages_Min_Fields>;
  stddev?: Maybe<TimeInStages_Stddev_Fields>;
  stddev_pop?: Maybe<TimeInStages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TimeInStages_Stddev_Samp_Fields>;
  sum?: Maybe<TimeInStages_Sum_Fields>;
  var_pop?: Maybe<TimeInStages_Var_Pop_Fields>;
  var_samp?: Maybe<TimeInStages_Var_Samp_Fields>;
  variance?: Maybe<TimeInStages_Variance_Fields>;
};


/** aggregate fields of "TimeInStages" */
export type TimeInStages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TimeInStages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TimeInStages_Avg_Fields = {
  __typename?: 'TimeInStages_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TimeInStages". All fields are combined with a logical 'AND'. */
export type TimeInStages_Bool_Exp = {
  _and?: InputMaybe<Array<TimeInStages_Bool_Exp>>;
  _not?: InputMaybe<TimeInStages_Bool_Exp>;
  _or?: InputMaybe<Array<TimeInStages_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_stage_id?: InputMaybe<Int_Comparison_Exp>;
  humanized_duration?: InputMaybe<String_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  in_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  out_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  stage_id?: InputMaybe<Int_Comparison_Exp>;
  time_in_stage?: InputMaybe<Bigint_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "TimeInStages" */
export enum TimeInStages_Constraint {
  /** unique or primary key constraint on columns "identity_id", "stage_id", "job_id" */
  TimeInStagesPkey = 'TimeInStages_pkey'
}

/** input type for incrementing numeric columns in table "TimeInStages" */
export type TimeInStages_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "TimeInStages" */
export type TimeInStages_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  humanized_duration?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  in_time?: InputMaybe<Scalars['timestamptz']>;
  job_id?: InputMaybe<Scalars['Int']>;
  out_time?: InputMaybe<Scalars['timestamptz']>;
  reason?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TimeInStages_Max_Fields = {
  __typename?: 'TimeInStages_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  humanized_duration?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  in_time?: Maybe<Scalars['timestamptz']>;
  job_id?: Maybe<Scalars['Int']>;
  out_time?: Maybe<Scalars['timestamptz']>;
  reason?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TimeInStages_Min_Fields = {
  __typename?: 'TimeInStages_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  humanized_duration?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  in_time?: Maybe<Scalars['timestamptz']>;
  job_id?: Maybe<Scalars['Int']>;
  out_time?: Maybe<Scalars['timestamptz']>;
  reason?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['Int']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "TimeInStages" */
export type TimeInStages_Mutation_Response = {
  __typename?: 'TimeInStages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TimeInStages>;
};

/** on_conflict condition type for table "TimeInStages" */
export type TimeInStages_On_Conflict = {
  constraint: TimeInStages_Constraint;
  update_columns?: Array<TimeInStages_Update_Column>;
  where?: InputMaybe<TimeInStages_Bool_Exp>;
};

/** Ordering options when selecting data from "TimeInStages". */
export type TimeInStages_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  humanized_duration?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  in_time?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  out_time?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  time_in_stage?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TimeInStages */
export type TimeInStages_Pk_Columns_Input = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};

/** select columns of table "TimeInStages" */
export enum TimeInStages_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  HumanizedDuration = 'humanized_duration',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InTime = 'in_time',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OutTime = 'out_time',
  /** column name */
  Reason = 'reason',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  TimeInStage = 'time_in_stage',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "TimeInStages" */
export type TimeInStages_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  humanized_duration?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  in_time?: InputMaybe<Scalars['timestamptz']>;
  job_id?: InputMaybe<Scalars['Int']>;
  out_time?: InputMaybe<Scalars['timestamptz']>;
  reason?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TimeInStages_Stddev_Fields = {
  __typename?: 'TimeInStages_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TimeInStages_Stddev_Pop_Fields = {
  __typename?: 'TimeInStages_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TimeInStages_Stddev_Samp_Fields = {
  __typename?: 'TimeInStages_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "TimeInStages" */
export type TimeInStages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TimeInStages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TimeInStages_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  humanized_duration?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  in_time?: InputMaybe<Scalars['timestamptz']>;
  job_id?: InputMaybe<Scalars['Int']>;
  out_time?: InputMaybe<Scalars['timestamptz']>;
  reason?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['Int']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TimeInStages_Sum_Fields = {
  __typename?: 'TimeInStages_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "TimeInStages" */
export enum TimeInStages_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  HumanizedDuration = 'humanized_duration',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  InTime = 'in_time',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OutTime = 'out_time',
  /** column name */
  Reason = 'reason',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  TimeInStage = 'time_in_stage',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type TimeInStages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TimeInStages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TimeInStages_Set_Input>;
  /** filter the rows which have to be updated */
  where: TimeInStages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TimeInStages_Var_Pop_Fields = {
  __typename?: 'TimeInStages_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TimeInStages_Var_Samp_Fields = {
  __typename?: 'TimeInStages_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TimeInStages_Variance_Fields = {
  __typename?: 'TimeInStages_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  stage_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "TimelineEvents" */
export type TimelineEvents = {
  __typename?: 'TimelineEvents';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  category?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identity_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "TimelineEvents" */
export type TimelineEvents_Aggregate = {
  __typename?: 'TimelineEvents_aggregate';
  aggregate?: Maybe<TimelineEvents_Aggregate_Fields>;
  nodes: Array<TimelineEvents>;
};

export type TimelineEvents_Aggregate_Bool_Exp = {
  count?: InputMaybe<TimelineEvents_Aggregate_Bool_Exp_Count>;
};

export type TimelineEvents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TimelineEvents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TimelineEvents" */
export type TimelineEvents_Aggregate_Fields = {
  __typename?: 'TimelineEvents_aggregate_fields';
  avg?: Maybe<TimelineEvents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TimelineEvents_Max_Fields>;
  min?: Maybe<TimelineEvents_Min_Fields>;
  stddev?: Maybe<TimelineEvents_Stddev_Fields>;
  stddev_pop?: Maybe<TimelineEvents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TimelineEvents_Stddev_Samp_Fields>;
  sum?: Maybe<TimelineEvents_Sum_Fields>;
  var_pop?: Maybe<TimelineEvents_Var_Pop_Fields>;
  var_samp?: Maybe<TimelineEvents_Var_Samp_Fields>;
  variance?: Maybe<TimelineEvents_Variance_Fields>;
};


/** aggregate fields of "TimelineEvents" */
export type TimelineEvents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TimelineEvents" */
export type TimelineEvents_Aggregate_Order_By = {
  avg?: InputMaybe<TimelineEvents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TimelineEvents_Max_Order_By>;
  min?: InputMaybe<TimelineEvents_Min_Order_By>;
  stddev?: InputMaybe<TimelineEvents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TimelineEvents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TimelineEvents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TimelineEvents_Sum_Order_By>;
  var_pop?: InputMaybe<TimelineEvents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TimelineEvents_Var_Samp_Order_By>;
  variance?: InputMaybe<TimelineEvents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TimelineEvents" */
export type TimelineEvents_Arr_Rel_Insert_Input = {
  data: Array<TimelineEvents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TimelineEvents_On_Conflict>;
};

/** aggregate avg on columns */
export type TimelineEvents_Avg_Fields = {
  __typename?: 'TimelineEvents_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TimelineEvents" */
export type TimelineEvents_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TimelineEvents". All fields are combined with a logical 'AND'. */
export type TimelineEvents_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<TimelineEvents_Bool_Exp>>;
  _not?: InputMaybe<TimelineEvents_Bool_Exp>;
  _or?: InputMaybe<Array<TimelineEvents_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "TimelineEvents" */
export enum TimelineEvents_Constraint {
  /** unique or primary key constraint on columns "id" */
  TimelineEventsPkey = 'TimelineEvents_pkey'
}

/** input type for incrementing numeric columns in table "TimelineEvents" */
export type TimelineEvents_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "TimelineEvents" */
export type TimelineEvents_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  category?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TimelineEvents_Max_Fields = {
  __typename?: 'TimelineEvents_max_fields';
  category?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "TimelineEvents" */
export type TimelineEvents_Max_Order_By = {
  category?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TimelineEvents_Min_Fields = {
  __typename?: 'TimelineEvents_min_fields';
  category?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "TimelineEvents" */
export type TimelineEvents_Min_Order_By = {
  category?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TimelineEvents" */
export type TimelineEvents_Mutation_Response = {
  __typename?: 'TimelineEvents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TimelineEvents>;
};

/** on_conflict condition type for table "TimelineEvents" */
export type TimelineEvents_On_Conflict = {
  constraint: TimelineEvents_Constraint;
  update_columns?: Array<TimelineEvents_Update_Column>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};

/** Ordering options when selecting data from "TimelineEvents". */
export type TimelineEvents_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  category?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TimelineEvents */
export type TimelineEvents_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "TimelineEvents" */
export enum TimelineEvents_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "TimelineEvents" */
export type TimelineEvents_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TimelineEvents_Stddev_Fields = {
  __typename?: 'TimelineEvents_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TimelineEvents" */
export type TimelineEvents_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TimelineEvents_Stddev_Pop_Fields = {
  __typename?: 'TimelineEvents_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "TimelineEvents" */
export type TimelineEvents_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TimelineEvents_Stddev_Samp_Fields = {
  __typename?: 'TimelineEvents_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "TimelineEvents" */
export type TimelineEvents_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TimelineEvents" */
export type TimelineEvents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TimelineEvents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TimelineEvents_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TimelineEvents_Sum_Fields = {
  __typename?: 'TimelineEvents_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TimelineEvents" */
export type TimelineEvents_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** update columns of table "TimelineEvents" */
export enum TimelineEvents_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type TimelineEvents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TimelineEvents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TimelineEvents_Set_Input>;
  /** filter the rows which have to be updated */
  where: TimelineEvents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TimelineEvents_Var_Pop_Fields = {
  __typename?: 'TimelineEvents_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "TimelineEvents" */
export type TimelineEvents_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TimelineEvents_Var_Samp_Fields = {
  __typename?: 'TimelineEvents_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "TimelineEvents" */
export type TimelineEvents_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TimelineEvents_Variance_Fields = {
  __typename?: 'TimelineEvents_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TimelineEvents" */
export type TimelineEvents_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Tokens" */
export type Tokens = {
  __typename?: 'Tokens';
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  object?: Maybe<Scalars['jsonb']>;
  owner_id: Scalars['Int'];
  system_id: Scalars['uuid'];
  token: Scalars['jsonb'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['Int'];
};


/** columns and relationships of "Tokens" */
export type TokensObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Tokens" */
export type TokensTokenArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Tokens" */
export type Tokens_Aggregate = {
  __typename?: 'Tokens_aggregate';
  aggregate?: Maybe<Tokens_Aggregate_Fields>;
  nodes: Array<Tokens>;
};

/** aggregate fields of "Tokens" */
export type Tokens_Aggregate_Fields = {
  __typename?: 'Tokens_aggregate_fields';
  avg?: Maybe<Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tokens_Max_Fields>;
  min?: Maybe<Tokens_Min_Fields>;
  stddev?: Maybe<Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Tokens_Sum_Fields>;
  var_pop?: Maybe<Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Tokens_Var_Samp_Fields>;
  variance?: Maybe<Tokens_Variance_Fields>;
};


/** aggregate fields of "Tokens" */
export type Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tokens_Append_Input = {
  object?: InputMaybe<Scalars['jsonb']>;
  token?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Tokens_Avg_Fields = {
  __typename?: 'Tokens_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Tokens". All fields are combined with a logical 'AND'. */
export type Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Tokens_Bool_Exp>>;
  _not?: InputMaybe<Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Tokens_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  object?: InputMaybe<Jsonb_Comparison_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  system_id?: InputMaybe<Uuid_Comparison_Exp>;
  token?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tokens" */
export enum Tokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  TokensPkey = 'Tokens_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tokens_Delete_At_Path_Input = {
  object?: InputMaybe<Array<Scalars['String']>>;
  token?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tokens_Delete_Elem_Input = {
  object?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tokens_Delete_Key_Input = {
  object?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Tokens" */
export type Tokens_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Tokens" */
export type Tokens_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  object?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  system_id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Tokens_Max_Fields = {
  __typename?: 'Tokens_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  system_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Tokens_Min_Fields = {
  __typename?: 'Tokens_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['Int']>;
  system_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Tokens" */
export type Tokens_Mutation_Response = {
  __typename?: 'Tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tokens>;
};

/** on_conflict condition type for table "Tokens" */
export type Tokens_On_Conflict = {
  constraint: Tokens_Constraint;
  update_columns?: Array<Tokens_Update_Column>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "Tokens". */
export type Tokens_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  system_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Tokens */
export type Tokens_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tokens_Prepend_Input = {
  object?: InputMaybe<Scalars['jsonb']>;
  token?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Tokens" */
export enum Tokens_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Object = 'object',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  SystemId = 'system_id',
  /** column name */
  Token = 'token',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "Tokens" */
export type Tokens_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  object?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  system_id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Tokens_Stddev_Fields = {
  __typename?: 'Tokens_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tokens_Stddev_Pop_Fields = {
  __typename?: 'Tokens_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tokens_Stddev_Samp_Fields = {
  __typename?: 'Tokens_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Tokens" */
export type Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tokens_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  object?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  system_id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Tokens_Sum_Fields = {
  __typename?: 'Tokens_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "Tokens" */
export enum Tokens_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Object = 'object',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  SystemId = 'system_id',
  /** column name */
  Token = 'token',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Tokens_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tokens_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tokens_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tokens_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tokens_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tokens_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tokens_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tokens_Var_Pop_Fields = {
  __typename?: 'Tokens_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tokens_Var_Samp_Fields = {
  __typename?: 'Tokens_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tokens_Variance_Fields = {
  __typename?: 'Tokens_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

export type UploadLogoOutput = {
  __typename?: 'UploadLogoOutput';
  signedUrl: Scalars['String'];
};

export type UploadProfilePictureOutput = {
  __typename?: 'UploadProfilePictureOutput';
  signedUrl: Scalars['String'];
};

/** Groups of users for access control */
export type UserAccessGroups = {
  __typename?: 'UserAccessGroups';
  /** An array relationship */
  UserAccessGroups_IdentityUserAccessGroups: Array<IdentityUserAccessGroup>;
  /** An aggregate relationship */
  UserAccessGroups_IdentityUserAccessGroups_aggregate: IdentityUserAccessGroup_Aggregate;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Groups of users for access control */
export type UserAccessGroupsUserAccessGroups_IdentityUserAccessGroupsArgs = {
  distinct_on?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityUserAccessGroup_Order_By>>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};


/** Groups of users for access control */
export type UserAccessGroupsUserAccessGroups_IdentityUserAccessGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityUserAccessGroup_Order_By>>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};

/** aggregated selection of "UserAccessGroups" */
export type UserAccessGroups_Aggregate = {
  __typename?: 'UserAccessGroups_aggregate';
  aggregate?: Maybe<UserAccessGroups_Aggregate_Fields>;
  nodes: Array<UserAccessGroups>;
};

/** aggregate fields of "UserAccessGroups" */
export type UserAccessGroups_Aggregate_Fields = {
  __typename?: 'UserAccessGroups_aggregate_fields';
  avg?: Maybe<UserAccessGroups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserAccessGroups_Max_Fields>;
  min?: Maybe<UserAccessGroups_Min_Fields>;
  stddev?: Maybe<UserAccessGroups_Stddev_Fields>;
  stddev_pop?: Maybe<UserAccessGroups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserAccessGroups_Stddev_Samp_Fields>;
  sum?: Maybe<UserAccessGroups_Sum_Fields>;
  var_pop?: Maybe<UserAccessGroups_Var_Pop_Fields>;
  var_samp?: Maybe<UserAccessGroups_Var_Samp_Fields>;
  variance?: Maybe<UserAccessGroups_Variance_Fields>;
};


/** aggregate fields of "UserAccessGroups" */
export type UserAccessGroups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserAccessGroups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserAccessGroups_Avg_Fields = {
  __typename?: 'UserAccessGroups_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "UserAccessGroups". All fields are combined with a logical 'AND'. */
export type UserAccessGroups_Bool_Exp = {
  UserAccessGroups_IdentityUserAccessGroups?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
  UserAccessGroups_IdentityUserAccessGroups_aggregate?: InputMaybe<IdentityUserAccessGroup_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<UserAccessGroups_Bool_Exp>>;
  _not?: InputMaybe<UserAccessGroups_Bool_Exp>;
  _or?: InputMaybe<Array<UserAccessGroups_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserAccessGroups" */
export enum UserAccessGroups_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAccessGroupsPkey = 'UserAccessGroups_pkey'
}

/** input type for incrementing numeric columns in table "UserAccessGroups" */
export type UserAccessGroups_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "UserAccessGroups" */
export type UserAccessGroups_Insert_Input = {
  UserAccessGroups_IdentityUserAccessGroups?: InputMaybe<IdentityUserAccessGroup_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type UserAccessGroups_Max_Fields = {
  __typename?: 'UserAccessGroups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type UserAccessGroups_Min_Fields = {
  __typename?: 'UserAccessGroups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "UserAccessGroups" */
export type UserAccessGroups_Mutation_Response = {
  __typename?: 'UserAccessGroups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAccessGroups>;
};

/** input type for inserting object relation for remote table "UserAccessGroups" */
export type UserAccessGroups_Obj_Rel_Insert_Input = {
  data: UserAccessGroups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserAccessGroups_On_Conflict>;
};

/** on_conflict condition type for table "UserAccessGroups" */
export type UserAccessGroups_On_Conflict = {
  constraint: UserAccessGroups_Constraint;
  update_columns?: Array<UserAccessGroups_Update_Column>;
  where?: InputMaybe<UserAccessGroups_Bool_Exp>;
};

/** Ordering options when selecting data from "UserAccessGroups". */
export type UserAccessGroups_Order_By = {
  UserAccessGroups_IdentityUserAccessGroups_aggregate?: InputMaybe<IdentityUserAccessGroup_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserAccessGroups */
export type UserAccessGroups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "UserAccessGroups" */
export enum UserAccessGroups_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "UserAccessGroups" */
export type UserAccessGroups_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type UserAccessGroups_Stddev_Fields = {
  __typename?: 'UserAccessGroups_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserAccessGroups_Stddev_Pop_Fields = {
  __typename?: 'UserAccessGroups_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserAccessGroups_Stddev_Samp_Fields = {
  __typename?: 'UserAccessGroups_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "UserAccessGroups" */
export type UserAccessGroups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserAccessGroups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserAccessGroups_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type UserAccessGroups_Sum_Fields = {
  __typename?: 'UserAccessGroups_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "UserAccessGroups" */
export enum UserAccessGroups_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type UserAccessGroups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserAccessGroups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAccessGroups_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserAccessGroups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserAccessGroups_Var_Pop_Fields = {
  __typename?: 'UserAccessGroups_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserAccessGroups_Var_Samp_Fields = {
  __typename?: 'UserAccessGroups_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserAccessGroups_Variance_Fields = {
  __typename?: 'UserAccessGroups_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "UserInterfaceState" */
export type UserInterfaceState = {
  __typename?: 'UserInterfaceState';
  client_id?: Maybe<Scalars['String']>;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  owner_id: Scalars['Int'];
  route: Scalars['String'];
  state: Scalars['jsonb'];
  tags?: Maybe<Scalars['jsonb']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};


/** columns and relationships of "UserInterfaceState" */
export type UserInterfaceStateStateArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "UserInterfaceState" */
export type UserInterfaceStateTagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "UserInterfaceState" */
export type UserInterfaceState_Aggregate = {
  __typename?: 'UserInterfaceState_aggregate';
  aggregate?: Maybe<UserInterfaceState_Aggregate_Fields>;
  nodes: Array<UserInterfaceState>;
};

/** aggregate fields of "UserInterfaceState" */
export type UserInterfaceState_Aggregate_Fields = {
  __typename?: 'UserInterfaceState_aggregate_fields';
  avg?: Maybe<UserInterfaceState_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserInterfaceState_Max_Fields>;
  min?: Maybe<UserInterfaceState_Min_Fields>;
  stddev?: Maybe<UserInterfaceState_Stddev_Fields>;
  stddev_pop?: Maybe<UserInterfaceState_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserInterfaceState_Stddev_Samp_Fields>;
  sum?: Maybe<UserInterfaceState_Sum_Fields>;
  var_pop?: Maybe<UserInterfaceState_Var_Pop_Fields>;
  var_samp?: Maybe<UserInterfaceState_Var_Samp_Fields>;
  variance?: Maybe<UserInterfaceState_Variance_Fields>;
};


/** aggregate fields of "UserInterfaceState" */
export type UserInterfaceState_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserInterfaceState_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserInterfaceState_Append_Input = {
  state?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type UserInterfaceState_Avg_Fields = {
  __typename?: 'UserInterfaceState_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "UserInterfaceState". All fields are combined with a logical 'AND'. */
export type UserInterfaceState_Bool_Exp = {
  _and?: InputMaybe<Array<UserInterfaceState_Bool_Exp>>;
  _not?: InputMaybe<UserInterfaceState_Bool_Exp>;
  _or?: InputMaybe<Array<UserInterfaceState_Bool_Exp>>;
  client_id?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  route?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<Jsonb_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserInterfaceState" */
export enum UserInterfaceState_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserInterfaceStatePkey = 'UserInterfaceState_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserInterfaceState_Delete_At_Path_Input = {
  state?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserInterfaceState_Delete_Elem_Input = {
  state?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserInterfaceState_Delete_Key_Input = {
  state?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "UserInterfaceState" */
export type UserInterfaceState_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  owner_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "UserInterfaceState" */
export type UserInterfaceState_Insert_Input = {
  client_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  route?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserInterfaceState_Max_Fields = {
  __typename?: 'UserInterfaceState_max_fields';
  client_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  route?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserInterfaceState_Min_Fields = {
  __typename?: 'UserInterfaceState_min_fields';
  client_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  route?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "UserInterfaceState" */
export type UserInterfaceState_Mutation_Response = {
  __typename?: 'UserInterfaceState_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserInterfaceState>;
};

/** on_conflict condition type for table "UserInterfaceState" */
export type UserInterfaceState_On_Conflict = {
  constraint: UserInterfaceState_Constraint;
  update_columns?: Array<UserInterfaceState_Update_Column>;
  where?: InputMaybe<UserInterfaceState_Bool_Exp>;
};

/** Ordering options when selecting data from "UserInterfaceState". */
export type UserInterfaceState_Order_By = {
  client_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  route?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserInterfaceState */
export type UserInterfaceState_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserInterfaceState_Prepend_Input = {
  state?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "UserInterfaceState" */
export enum UserInterfaceState_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Route = 'route',
  /** column name */
  State = 'state',
  /** column name */
  Tags = 'tags',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "UserInterfaceState" */
export type UserInterfaceState_Set_Input = {
  client_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  route?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserInterfaceState_Stddev_Fields = {
  __typename?: 'UserInterfaceState_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserInterfaceState_Stddev_Pop_Fields = {
  __typename?: 'UserInterfaceState_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserInterfaceState_Stddev_Samp_Fields = {
  __typename?: 'UserInterfaceState_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "UserInterfaceState" */
export type UserInterfaceState_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserInterfaceState_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserInterfaceState_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  route?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type UserInterfaceState_Sum_Fields = {
  __typename?: 'UserInterfaceState_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  owner_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "UserInterfaceState" */
export enum UserInterfaceState_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Route = 'route',
  /** column name */
  State = 'state',
  /** column name */
  Tags = 'tags',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type UserInterfaceState_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserInterfaceState_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserInterfaceState_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserInterfaceState_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserInterfaceState_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserInterfaceState_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserInterfaceState_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserInterfaceState_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserInterfaceState_Bool_Exp;
};

/** aggregate var_pop on columns */
export type UserInterfaceState_Var_Pop_Fields = {
  __typename?: 'UserInterfaceState_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserInterfaceState_Var_Samp_Fields = {
  __typename?: 'UserInterfaceState_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserInterfaceState_Variance_Fields = {
  __typename?: 'UserInterfaceState_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Users" */
export type Users = {
  __typename?: 'Users';
  /** An object relationship */
  Company?: Maybe<Companies>;
  /** An object relationship */
  Role?: Maybe<Roles>;
  cog_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pool_id?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Users" */
export type Users_Aggregate = {
  __typename?: 'Users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Users" */
export type Users_Aggregate_Fields = {
  __typename?: 'Users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "Users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'Users_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Users" */
export type Users_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Role?: InputMaybe<Roles_Bool_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  cog_id?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  img?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pool_id?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'Users_pkey'
}

/** input type for incrementing numeric columns in table "Users" */
export type Users_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Users" */
export type Users_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  cog_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pool_id?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'Users_max_fields';
  cog_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pool_id?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Users" */
export type Users_Max_Order_By = {
  cog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pool_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'Users_min_fields';
  cog_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pool_id?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Users" */
export type Users_Min_Order_By = {
  cog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pool_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Users" */
export type Users_Mutation_Response = {
  __typename?: 'Users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "Users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "Users". */
export type Users_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Role?: InputMaybe<Roles_Order_By>;
  cog_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pool_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Users" */
export enum Users_Select_Column {
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Name = 'name',
  /** column name */
  PoolId = 'pool_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Users" */
export type Users_Set_Input = {
  cog_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pool_id?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'Users_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Users" */
export type Users_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'Users_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Users" */
export type Users_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'Users_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Users" */
export type Users_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  cog_id?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pool_id?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'Users_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Users" */
export type Users_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** update columns of table "Users" */
export enum Users_Update_Column {
  /** column name */
  CogId = 'cog_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Name = 'name',
  /** column name */
  PoolId = 'pool_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'Users_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Users" */
export type Users_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'Users_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Users" */
export type Users_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'Users_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Users" */
export type Users_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** Enum used for form input and equation result types */
export type ValueTypes = {
  __typename?: 'ValueTypes';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ValueTypes" */
export type ValueTypes_Aggregate = {
  __typename?: 'ValueTypes_aggregate';
  aggregate?: Maybe<ValueTypes_Aggregate_Fields>;
  nodes: Array<ValueTypes>;
};

/** aggregate fields of "ValueTypes" */
export type ValueTypes_Aggregate_Fields = {
  __typename?: 'ValueTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ValueTypes_Max_Fields>;
  min?: Maybe<ValueTypes_Min_Fields>;
};


/** aggregate fields of "ValueTypes" */
export type ValueTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ValueTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ValueTypes". All fields are combined with a logical 'AND'. */
export type ValueTypes_Bool_Exp = {
  _and?: InputMaybe<Array<ValueTypes_Bool_Exp>>;
  _not?: InputMaybe<ValueTypes_Bool_Exp>;
  _or?: InputMaybe<Array<ValueTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ValueTypes" */
export enum ValueTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  ValueTypesPkey = 'ValueTypes_pkey'
}

export enum ValueTypes_Enum {
  /** used for money values */
  Currency = 'currency',
  /** standard number */
  Number = 'number',
  /** used for percentage values */
  Percent = 'percent'
}

/** Boolean expression to compare columns of type "ValueTypes_enum". All fields are combined with logical 'AND'. */
export type ValueTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ValueTypes_Enum>;
  _in?: InputMaybe<Array<ValueTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ValueTypes_Enum>;
  _nin?: InputMaybe<Array<ValueTypes_Enum>>;
};

/** input type for inserting data into table "ValueTypes" */
export type ValueTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ValueTypes_Max_Fields = {
  __typename?: 'ValueTypes_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ValueTypes_Min_Fields = {
  __typename?: 'ValueTypes_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ValueTypes" */
export type ValueTypes_Mutation_Response = {
  __typename?: 'ValueTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ValueTypes>;
};

/** input type for inserting object relation for remote table "ValueTypes" */
export type ValueTypes_Obj_Rel_Insert_Input = {
  data: ValueTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ValueTypes_On_Conflict>;
};

/** on_conflict condition type for table "ValueTypes" */
export type ValueTypes_On_Conflict = {
  constraint: ValueTypes_Constraint;
  update_columns?: Array<ValueTypes_Update_Column>;
  where?: InputMaybe<ValueTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "ValueTypes". */
export type ValueTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ValueTypes */
export type ValueTypes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ValueTypes" */
export enum ValueTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ValueTypes" */
export type ValueTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ValueTypes" */
export type ValueTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ValueTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ValueTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ValueTypes" */
export enum ValueTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type ValueTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ValueTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: ValueTypes_Bool_Exp;
};

/** columns and relationships of "VeteranStatusEnum" */
export type VeteranStatusEnum = {
  __typename?: 'VeteranStatusEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "VeteranStatusEnum" */
export type VeteranStatusEnum_Aggregate = {
  __typename?: 'VeteranStatusEnum_aggregate';
  aggregate?: Maybe<VeteranStatusEnum_Aggregate_Fields>;
  nodes: Array<VeteranStatusEnum>;
};

/** aggregate fields of "VeteranStatusEnum" */
export type VeteranStatusEnum_Aggregate_Fields = {
  __typename?: 'VeteranStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<VeteranStatusEnum_Max_Fields>;
  min?: Maybe<VeteranStatusEnum_Min_Fields>;
};


/** aggregate fields of "VeteranStatusEnum" */
export type VeteranStatusEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VeteranStatusEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "VeteranStatusEnum". All fields are combined with a logical 'AND'. */
export type VeteranStatusEnum_Bool_Exp = {
  _and?: InputMaybe<Array<VeteranStatusEnum_Bool_Exp>>;
  _not?: InputMaybe<VeteranStatusEnum_Bool_Exp>;
  _or?: InputMaybe<Array<VeteranStatusEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "VeteranStatusEnum" */
export enum VeteranStatusEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  VeteranStatusEnumPkey = 'VeteranStatusEnum_pkey'
}

export enum VeteranStatusEnum_Enum {
  /** Declined */
  Declined = 'DECLINED',
  /** No */
  No = 'NO',
  /** Yes */
  Yes = 'YES'
}

/** Boolean expression to compare columns of type "VeteranStatusEnum_enum". All fields are combined with logical 'AND'. */
export type VeteranStatusEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<VeteranStatusEnum_Enum>;
  _in?: InputMaybe<Array<VeteranStatusEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<VeteranStatusEnum_Enum>;
  _nin?: InputMaybe<Array<VeteranStatusEnum_Enum>>;
};

/** input type for inserting data into table "VeteranStatusEnum" */
export type VeteranStatusEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type VeteranStatusEnum_Max_Fields = {
  __typename?: 'VeteranStatusEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type VeteranStatusEnum_Min_Fields = {
  __typename?: 'VeteranStatusEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "VeteranStatusEnum" */
export type VeteranStatusEnum_Mutation_Response = {
  __typename?: 'VeteranStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VeteranStatusEnum>;
};

/** on_conflict condition type for table "VeteranStatusEnum" */
export type VeteranStatusEnum_On_Conflict = {
  constraint: VeteranStatusEnum_Constraint;
  update_columns?: Array<VeteranStatusEnum_Update_Column>;
  where?: InputMaybe<VeteranStatusEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "VeteranStatusEnum". */
export type VeteranStatusEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: VeteranStatusEnum */
export type VeteranStatusEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "VeteranStatusEnum" */
export enum VeteranStatusEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "VeteranStatusEnum" */
export type VeteranStatusEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "VeteranStatusEnum" */
export type VeteranStatusEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VeteranStatusEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VeteranStatusEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "VeteranStatusEnum" */
export enum VeteranStatusEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type VeteranStatusEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VeteranStatusEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: VeteranStatusEnum_Bool_Exp;
};

export type WhenInput = {
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

/** columns and relationships of "WhiteLabeling" */
export type WhiteLabeling = {
  __typename?: 'WhiteLabeling';
  allow_public_access?: Maybe<Scalars['Boolean']>;
  company_id: Scalars['Int'];
  id: Scalars['uuid'];
  input: Scalars['String'];
  output: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WhiteLabeling" */
export type WhiteLabeling_Aggregate = {
  __typename?: 'WhiteLabeling_aggregate';
  aggregate?: Maybe<WhiteLabeling_Aggregate_Fields>;
  nodes: Array<WhiteLabeling>;
};

/** aggregate fields of "WhiteLabeling" */
export type WhiteLabeling_Aggregate_Fields = {
  __typename?: 'WhiteLabeling_aggregate_fields';
  avg?: Maybe<WhiteLabeling_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WhiteLabeling_Max_Fields>;
  min?: Maybe<WhiteLabeling_Min_Fields>;
  stddev?: Maybe<WhiteLabeling_Stddev_Fields>;
  stddev_pop?: Maybe<WhiteLabeling_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WhiteLabeling_Stddev_Samp_Fields>;
  sum?: Maybe<WhiteLabeling_Sum_Fields>;
  var_pop?: Maybe<WhiteLabeling_Var_Pop_Fields>;
  var_samp?: Maybe<WhiteLabeling_Var_Samp_Fields>;
  variance?: Maybe<WhiteLabeling_Variance_Fields>;
};


/** aggregate fields of "WhiteLabeling" */
export type WhiteLabeling_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WhiteLabeling_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WhiteLabeling_Avg_Fields = {
  __typename?: 'WhiteLabeling_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WhiteLabeling". All fields are combined with a logical 'AND'. */
export type WhiteLabeling_Bool_Exp = {
  _and?: InputMaybe<Array<WhiteLabeling_Bool_Exp>>;
  _not?: InputMaybe<WhiteLabeling_Bool_Exp>;
  _or?: InputMaybe<Array<WhiteLabeling_Bool_Exp>>;
  allow_public_access?: InputMaybe<Boolean_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  input?: InputMaybe<String_Comparison_Exp>;
  output?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WhiteLabeling" */
export enum WhiteLabeling_Constraint {
  /** unique or primary key constraint on columns "input", "company_id" */
  WhiteLabelingCompanyIdInputKey = 'WhiteLabeling_company_id_input_key',
  /** unique or primary key constraint on columns "id" */
  WhiteLabelingPkey = 'WhiteLabeling_pkey'
}

/** input type for incrementing numeric columns in table "WhiteLabeling" */
export type WhiteLabeling_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "WhiteLabeling" */
export type WhiteLabeling_Insert_Input = {
  allow_public_access?: InputMaybe<Scalars['Boolean']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhiteLabeling_Max_Fields = {
  __typename?: 'WhiteLabeling_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  input?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhiteLabeling_Min_Fields = {
  __typename?: 'WhiteLabeling_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  input?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WhiteLabeling" */
export type WhiteLabeling_Mutation_Response = {
  __typename?: 'WhiteLabeling_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhiteLabeling>;
};

/** on_conflict condition type for table "WhiteLabeling" */
export type WhiteLabeling_On_Conflict = {
  constraint: WhiteLabeling_Constraint;
  update_columns?: Array<WhiteLabeling_Update_Column>;
  where?: InputMaybe<WhiteLabeling_Bool_Exp>;
};

/** Ordering options when selecting data from "WhiteLabeling". */
export type WhiteLabeling_Order_By = {
  allow_public_access?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  input?: InputMaybe<Order_By>;
  output?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WhiteLabeling */
export type WhiteLabeling_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "WhiteLabeling" */
export enum WhiteLabeling_Select_Column {
  /** column name */
  AllowPublicAccess = 'allow_public_access',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  Output = 'output',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "WhiteLabeling" */
export type WhiteLabeling_Set_Input = {
  allow_public_access?: InputMaybe<Scalars['Boolean']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type WhiteLabeling_Stddev_Fields = {
  __typename?: 'WhiteLabeling_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WhiteLabeling_Stddev_Pop_Fields = {
  __typename?: 'WhiteLabeling_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WhiteLabeling_Stddev_Samp_Fields = {
  __typename?: 'WhiteLabeling_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WhiteLabeling" */
export type WhiteLabeling_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WhiteLabeling_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WhiteLabeling_Stream_Cursor_Value_Input = {
  allow_public_access?: InputMaybe<Scalars['Boolean']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type WhiteLabeling_Sum_Fields = {
  __typename?: 'WhiteLabeling_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "WhiteLabeling" */
export enum WhiteLabeling_Update_Column {
  /** column name */
  AllowPublicAccess = 'allow_public_access',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  Output = 'output',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type WhiteLabeling_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WhiteLabeling_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhiteLabeling_Set_Input>;
  /** filter the rows which have to be updated */
  where: WhiteLabeling_Bool_Exp;
};

/** aggregate var_pop on columns */
export type WhiteLabeling_Var_Pop_Fields = {
  __typename?: 'WhiteLabeling_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WhiteLabeling_Var_Samp_Fields = {
  __typename?: 'WhiteLabeling_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WhiteLabeling_Variance_Fields = {
  __typename?: 'WhiteLabeling_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WorkflowRegressions" */
export type WorkflowRegressions = {
  __typename?: 'WorkflowRegressions';
  company_id: Scalars['Int'];
  id: Scalars['bigint'];
  reason: Scalars['String'];
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "WorkflowRegressions" */
export type WorkflowRegressions_Aggregate = {
  __typename?: 'WorkflowRegressions_aggregate';
  aggregate?: Maybe<WorkflowRegressions_Aggregate_Fields>;
  nodes: Array<WorkflowRegressions>;
};

/** aggregate fields of "WorkflowRegressions" */
export type WorkflowRegressions_Aggregate_Fields = {
  __typename?: 'WorkflowRegressions_aggregate_fields';
  avg?: Maybe<WorkflowRegressions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WorkflowRegressions_Max_Fields>;
  min?: Maybe<WorkflowRegressions_Min_Fields>;
  stddev?: Maybe<WorkflowRegressions_Stddev_Fields>;
  stddev_pop?: Maybe<WorkflowRegressions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WorkflowRegressions_Stddev_Samp_Fields>;
  sum?: Maybe<WorkflowRegressions_Sum_Fields>;
  var_pop?: Maybe<WorkflowRegressions_Var_Pop_Fields>;
  var_samp?: Maybe<WorkflowRegressions_Var_Samp_Fields>;
  variance?: Maybe<WorkflowRegressions_Variance_Fields>;
};


/** aggregate fields of "WorkflowRegressions" */
export type WorkflowRegressions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkflowRegressions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WorkflowRegressions_Avg_Fields = {
  __typename?: 'WorkflowRegressions_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WorkflowRegressions". All fields are combined with a logical 'AND'. */
export type WorkflowRegressions_Bool_Exp = {
  _and?: InputMaybe<Array<WorkflowRegressions_Bool_Exp>>;
  _not?: InputMaybe<WorkflowRegressions_Bool_Exp>;
  _or?: InputMaybe<Array<WorkflowRegressions_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "WorkflowRegressions" */
export enum WorkflowRegressions_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkflowRegressionsPkey = 'WorkflowRegressions_pkey'
}

/** input type for incrementing numeric columns in table "WorkflowRegressions" */
export type WorkflowRegressions_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "WorkflowRegressions" */
export type WorkflowRegressions_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WorkflowRegressions_Max_Fields = {
  __typename?: 'WorkflowRegressions_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WorkflowRegressions_Min_Fields = {
  __typename?: 'WorkflowRegressions_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "WorkflowRegressions" */
export type WorkflowRegressions_Mutation_Response = {
  __typename?: 'WorkflowRegressions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkflowRegressions>;
};

/** input type for inserting object relation for remote table "WorkflowRegressions" */
export type WorkflowRegressions_Obj_Rel_Insert_Input = {
  data: WorkflowRegressions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<WorkflowRegressions_On_Conflict>;
};

/** on_conflict condition type for table "WorkflowRegressions" */
export type WorkflowRegressions_On_Conflict = {
  constraint: WorkflowRegressions_Constraint;
  update_columns?: Array<WorkflowRegressions_Update_Column>;
  where?: InputMaybe<WorkflowRegressions_Bool_Exp>;
};

/** Ordering options when selecting data from "WorkflowRegressions". */
export type WorkflowRegressions_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: WorkflowRegressions */
export type WorkflowRegressions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "WorkflowRegressions" */
export enum WorkflowRegressions_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

/** input type for updating data in table "WorkflowRegressions" */
export type WorkflowRegressions_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type WorkflowRegressions_Stddev_Fields = {
  __typename?: 'WorkflowRegressions_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WorkflowRegressions_Stddev_Pop_Fields = {
  __typename?: 'WorkflowRegressions_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WorkflowRegressions_Stddev_Samp_Fields = {
  __typename?: 'WorkflowRegressions_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WorkflowRegressions" */
export type WorkflowRegressions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkflowRegressions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkflowRegressions_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  reason?: InputMaybe<Scalars['String']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type WorkflowRegressions_Sum_Fields = {
  __typename?: 'WorkflowRegressions_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "WorkflowRegressions" */
export enum WorkflowRegressions_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Tracingspancontext = 'tracingspancontext'
}

export type WorkflowRegressions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WorkflowRegressions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkflowRegressions_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkflowRegressions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type WorkflowRegressions_Var_Pop_Fields = {
  __typename?: 'WorkflowRegressions_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WorkflowRegressions_Var_Samp_Fields = {
  __typename?: 'WorkflowRegressions_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WorkflowRegressions_Variance_Fields = {
  __typename?: 'WorkflowRegressions_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Activity_Count_Stat_Args = {
  am_ids?: InputMaybe<Scalars['_int4']>;
  author_ids?: InputMaybe<Scalars['_int4']>;
  contract_ids?: InputMaybe<Scalars['_int4']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  job_ids?: InputMaybe<Scalars['_int4']>;
  rm_ids?: InputMaybe<Scalars['_int4']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

export type Activity_Counts_Rm_Totals_Only_Args = {
  am_ids?: InputMaybe<Scalars['_int4']>;
  author_ids?: InputMaybe<Scalars['_int4']>;
  contract_ids?: InputMaybe<Scalars['_int4']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  job_ids?: InputMaybe<Scalars['_int4']>;
  rm_ids?: InputMaybe<Scalars['_int4']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "ams_for_analytics_ui" */
export type Ams_For_Analytics_Ui = {
  __typename?: 'ams_for_analytics_ui';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  am_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "ams_for_analytics_ui" */
export type Ams_For_Analytics_Ui_Aggregate = {
  __typename?: 'ams_for_analytics_ui_aggregate';
  aggregate?: Maybe<Ams_For_Analytics_Ui_Aggregate_Fields>;
  nodes: Array<Ams_For_Analytics_Ui>;
};

/** aggregate fields of "ams_for_analytics_ui" */
export type Ams_For_Analytics_Ui_Aggregate_Fields = {
  __typename?: 'ams_for_analytics_ui_aggregate_fields';
  avg?: Maybe<Ams_For_Analytics_Ui_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ams_For_Analytics_Ui_Max_Fields>;
  min?: Maybe<Ams_For_Analytics_Ui_Min_Fields>;
  stddev?: Maybe<Ams_For_Analytics_Ui_Stddev_Fields>;
  stddev_pop?: Maybe<Ams_For_Analytics_Ui_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ams_For_Analytics_Ui_Stddev_Samp_Fields>;
  sum?: Maybe<Ams_For_Analytics_Ui_Sum_Fields>;
  var_pop?: Maybe<Ams_For_Analytics_Ui_Var_Pop_Fields>;
  var_samp?: Maybe<Ams_For_Analytics_Ui_Var_Samp_Fields>;
  variance?: Maybe<Ams_For_Analytics_Ui_Variance_Fields>;
};


/** aggregate fields of "ams_for_analytics_ui" */
export type Ams_For_Analytics_Ui_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ams_For_Analytics_Ui_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ams_For_Analytics_Ui_Avg_Fields = {
  __typename?: 'ams_for_analytics_ui_avg_fields';
  am_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ams_for_analytics_ui". All fields are combined with a logical 'AND'. */
export type Ams_For_Analytics_Ui_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Ams_For_Analytics_Ui_Bool_Exp>>;
  _not?: InputMaybe<Ams_For_Analytics_Ui_Bool_Exp>;
  _or?: InputMaybe<Array<Ams_For_Analytics_Ui_Bool_Exp>>;
  am_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Ams_For_Analytics_Ui_Max_Fields = {
  __typename?: 'ams_for_analytics_ui_max_fields';
  am_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Ams_For_Analytics_Ui_Min_Fields = {
  __typename?: 'ams_for_analytics_ui_min_fields';
  am_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "ams_for_analytics_ui". */
export type Ams_For_Analytics_Ui_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  am_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
};

/** select columns of table "ams_for_analytics_ui" */
export enum Ams_For_Analytics_Ui_Select_Column {
  /** column name */
  AmId = 'am_id',
  /** column name */
  CompanyId = 'company_id'
}

/** aggregate stddev on columns */
export type Ams_For_Analytics_Ui_Stddev_Fields = {
  __typename?: 'ams_for_analytics_ui_stddev_fields';
  am_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ams_For_Analytics_Ui_Stddev_Pop_Fields = {
  __typename?: 'ams_for_analytics_ui_stddev_pop_fields';
  am_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ams_For_Analytics_Ui_Stddev_Samp_Fields = {
  __typename?: 'ams_for_analytics_ui_stddev_samp_fields';
  am_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ams_for_analytics_ui" */
export type Ams_For_Analytics_Ui_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ams_For_Analytics_Ui_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ams_For_Analytics_Ui_Stream_Cursor_Value_Input = {
  am_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ams_For_Analytics_Ui_Sum_Fields = {
  __typename?: 'ams_for_analytics_ui_sum_fields';
  am_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Ams_For_Analytics_Ui_Var_Pop_Fields = {
  __typename?: 'ams_for_analytics_ui_var_pop_fields';
  am_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ams_For_Analytics_Ui_Var_Samp_Fields = {
  __typename?: 'ams_for_analytics_ui_var_samp_fields';
  am_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ams_For_Analytics_Ui_Variance_Fields = {
  __typename?: 'ams_for_analytics_ui_variance_fields';
  am_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "analytics_view" */
export type Analytics_View = {
  __typename?: 'analytics_view';
  AccountManager?: Maybe<Scalars['String']>;
  AnalyticsStage?: Maybe<Scalars['String']>;
  AnalyticsStageID?: Maybe<Scalars['Int']>;
  AnalyticsStageOrder?: Maybe<Scalars['Int']>;
  Author?: Maybe<Scalars['String']>;
  Candidate?: Maybe<Scalars['String']>;
  Contract?: Maybe<Scalars['String']>;
  ContractAccountManager?: Maybe<Scalars['String']>;
  /** An object relationship */
  ContractObj?: Maybe<Contracts>;
  IdAccountManager?: Maybe<Scalars['String']>;
  IdResourceManager?: Maybe<Scalars['String']>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Jobs?: Maybe<Jobs>;
  Position?: Maybe<Scalars['String']>;
  PreviousStage?: Maybe<Scalars['String']>;
  ResourceManager?: Maybe<Scalars['String']>;
  StageCurrent?: Maybe<Scalars['String']>;
  StageTime?: Maybe<Scalars['String']>;
  ToStage?: Maybe<Scalars['String']>;
  ToStageIsMilestone?: Maybe<Scalars['Boolean']>;
  When?: Maybe<Scalars['timestamptz']>;
  am_id?: Maybe<Scalars['Int']>;
  am_name?: Maybe<Scalars['String']>;
  am_name_first?: Maybe<Scalars['String']>;
  am_name_last?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_name_first?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  bucket_year?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_am_id?: Maybe<Scalars['Int']>;
  contract_am_name_first?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_stage?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  id_am_id?: Maybe<Scalars['Int']>;
  id_rm_id?: Maybe<Scalars['Int']>;
  id_type_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  is_employee?: Maybe<Scalars['bit']>;
  is_milestone_stage?: Maybe<Scalars['Boolean']>;
  is_off_board?: Maybe<Scalars['bit']>;
  is_off_board_stage?: Maybe<Scalars['Boolean']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  name_first?: Maybe<Scalars['String']>;
  removal_reason?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  rm_name_first?: Maybe<Scalars['String']>;
  rm_name_last?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  started_dt?: Maybe<Scalars['timestamptz']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  time_so_far?: Maybe<Scalars['interval']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  total_workflow_time?: Maybe<Scalars['float8']>;
  workflow_begin_dt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "analytics_view" */
export type Analytics_View_Aggregate = {
  __typename?: 'analytics_view_aggregate';
  aggregate?: Maybe<Analytics_View_Aggregate_Fields>;
  nodes: Array<Analytics_View>;
};

/** aggregate fields of "analytics_view" */
export type Analytics_View_Aggregate_Fields = {
  __typename?: 'analytics_view_aggregate_fields';
  avg?: Maybe<Analytics_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Analytics_View_Max_Fields>;
  min?: Maybe<Analytics_View_Min_Fields>;
  stddev?: Maybe<Analytics_View_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_View_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_View_Sum_Fields>;
  var_pop?: Maybe<Analytics_View_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_View_Var_Samp_Fields>;
  variance?: Maybe<Analytics_View_Variance_Fields>;
};


/** aggregate fields of "analytics_view" */
export type Analytics_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Analytics_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Analytics_View_Avg_Fields = {
  __typename?: 'analytics_view_avg_fields';
  AnalyticsStageID?: Maybe<Scalars['Float']>;
  AnalyticsStageOrder?: Maybe<Scalars['Float']>;
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_am_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  id_am_id?: Maybe<Scalars['Float']>;
  id_rm_id?: Maybe<Scalars['Float']>;
  id_type_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
  total_workflow_time?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "analytics_view". All fields are combined with a logical 'AND'. */
export type Analytics_View_Bool_Exp = {
  AccountManager?: InputMaybe<String_Comparison_Exp>;
  AnalyticsStage?: InputMaybe<String_Comparison_Exp>;
  AnalyticsStageID?: InputMaybe<Int_Comparison_Exp>;
  AnalyticsStageOrder?: InputMaybe<Int_Comparison_Exp>;
  Author?: InputMaybe<String_Comparison_Exp>;
  Candidate?: InputMaybe<String_Comparison_Exp>;
  Contract?: InputMaybe<String_Comparison_Exp>;
  ContractAccountManager?: InputMaybe<String_Comparison_Exp>;
  ContractObj?: InputMaybe<Contracts_Bool_Exp>;
  IdAccountManager?: InputMaybe<String_Comparison_Exp>;
  IdResourceManager?: InputMaybe<String_Comparison_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Jobs?: InputMaybe<Jobs_Bool_Exp>;
  Position?: InputMaybe<String_Comparison_Exp>;
  PreviousStage?: InputMaybe<String_Comparison_Exp>;
  ResourceManager?: InputMaybe<String_Comparison_Exp>;
  StageCurrent?: InputMaybe<String_Comparison_Exp>;
  StageTime?: InputMaybe<String_Comparison_Exp>;
  ToStage?: InputMaybe<String_Comparison_Exp>;
  ToStageIsMilestone?: InputMaybe<Boolean_Comparison_Exp>;
  When?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Analytics_View_Bool_Exp>>;
  _not?: InputMaybe<Analytics_View_Bool_Exp>;
  _or?: InputMaybe<Array<Analytics_View_Bool_Exp>>;
  am_id?: InputMaybe<Int_Comparison_Exp>;
  am_name?: InputMaybe<String_Comparison_Exp>;
  am_name_first?: InputMaybe<String_Comparison_Exp>;
  am_name_last?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  author_name_first?: InputMaybe<String_Comparison_Exp>;
  board_snippet_id?: InputMaybe<Int_Comparison_Exp>;
  bucket_day?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_month?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_week?: InputMaybe<Timestamp_Comparison_Exp>;
  bucket_year?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contract_am_id?: InputMaybe<Int_Comparison_Exp>;
  contract_am_name_first?: InputMaybe<String_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_stage?: InputMaybe<Int_Comparison_Exp>;
  from_stage_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_am_id?: InputMaybe<Int_Comparison_Exp>;
  id_rm_id?: InputMaybe<Int_Comparison_Exp>;
  id_type_id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  is_employee?: InputMaybe<Bit_Comparison_Exp>;
  is_milestone_stage?: InputMaybe<Boolean_Comparison_Exp>;
  is_off_board?: InputMaybe<Bit_Comparison_Exp>;
  is_off_board_stage?: InputMaybe<Boolean_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  last_update_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  removal_reason?: InputMaybe<String_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  rm_name?: InputMaybe<String_Comparison_Exp>;
  rm_name_first?: InputMaybe<String_Comparison_Exp>;
  rm_name_last?: InputMaybe<String_Comparison_Exp>;
  stage_exit_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  started_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  time_in_stage?: InputMaybe<Bigint_Comparison_Exp>;
  time_so_far?: InputMaybe<Interval_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
  total_workflow_time?: InputMaybe<Float8_Comparison_Exp>;
  workflow_begin_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Analytics_View_Max_Fields = {
  __typename?: 'analytics_view_max_fields';
  AccountManager?: Maybe<Scalars['String']>;
  AnalyticsStage?: Maybe<Scalars['String']>;
  AnalyticsStageID?: Maybe<Scalars['Int']>;
  AnalyticsStageOrder?: Maybe<Scalars['Int']>;
  Author?: Maybe<Scalars['String']>;
  Candidate?: Maybe<Scalars['String']>;
  Contract?: Maybe<Scalars['String']>;
  ContractAccountManager?: Maybe<Scalars['String']>;
  IdAccountManager?: Maybe<Scalars['String']>;
  IdResourceManager?: Maybe<Scalars['String']>;
  Position?: Maybe<Scalars['String']>;
  PreviousStage?: Maybe<Scalars['String']>;
  ResourceManager?: Maybe<Scalars['String']>;
  StageCurrent?: Maybe<Scalars['String']>;
  StageTime?: Maybe<Scalars['String']>;
  ToStage?: Maybe<Scalars['String']>;
  When?: Maybe<Scalars['timestamptz']>;
  am_id?: Maybe<Scalars['Int']>;
  am_name?: Maybe<Scalars['String']>;
  am_name_first?: Maybe<Scalars['String']>;
  am_name_last?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_name_first?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  bucket_year?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_am_id?: Maybe<Scalars['Int']>;
  contract_am_name_first?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_stage?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  id_am_id?: Maybe<Scalars['Int']>;
  id_rm_id?: Maybe<Scalars['Int']>;
  id_type_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  name_first?: Maybe<Scalars['String']>;
  removal_reason?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  rm_name_first?: Maybe<Scalars['String']>;
  rm_name_last?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  started_dt?: Maybe<Scalars['timestamptz']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  total_workflow_time?: Maybe<Scalars['float8']>;
  workflow_begin_dt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Analytics_View_Min_Fields = {
  __typename?: 'analytics_view_min_fields';
  AccountManager?: Maybe<Scalars['String']>;
  AnalyticsStage?: Maybe<Scalars['String']>;
  AnalyticsStageID?: Maybe<Scalars['Int']>;
  AnalyticsStageOrder?: Maybe<Scalars['Int']>;
  Author?: Maybe<Scalars['String']>;
  Candidate?: Maybe<Scalars['String']>;
  Contract?: Maybe<Scalars['String']>;
  ContractAccountManager?: Maybe<Scalars['String']>;
  IdAccountManager?: Maybe<Scalars['String']>;
  IdResourceManager?: Maybe<Scalars['String']>;
  Position?: Maybe<Scalars['String']>;
  PreviousStage?: Maybe<Scalars['String']>;
  ResourceManager?: Maybe<Scalars['String']>;
  StageCurrent?: Maybe<Scalars['String']>;
  StageTime?: Maybe<Scalars['String']>;
  ToStage?: Maybe<Scalars['String']>;
  When?: Maybe<Scalars['timestamptz']>;
  am_id?: Maybe<Scalars['Int']>;
  am_name?: Maybe<Scalars['String']>;
  am_name_first?: Maybe<Scalars['String']>;
  am_name_last?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_name_first?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  bucket_year?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_am_id?: Maybe<Scalars['Int']>;
  contract_am_name_first?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_stage?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  id_am_id?: Maybe<Scalars['Int']>;
  id_rm_id?: Maybe<Scalars['Int']>;
  id_type_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  last_update_ts?: Maybe<Scalars['timestamptz']>;
  name_first?: Maybe<Scalars['String']>;
  removal_reason?: Maybe<Scalars['String']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  rm_name_first?: Maybe<Scalars['String']>;
  rm_name_last?: Maybe<Scalars['String']>;
  stage_exit_time?: Maybe<Scalars['timestamptz']>;
  started_dt?: Maybe<Scalars['timestamptz']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  total_workflow_time?: Maybe<Scalars['float8']>;
  workflow_begin_dt?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "analytics_view". */
export type Analytics_View_Order_By = {
  AccountManager?: InputMaybe<Order_By>;
  AnalyticsStage?: InputMaybe<Order_By>;
  AnalyticsStageID?: InputMaybe<Order_By>;
  AnalyticsStageOrder?: InputMaybe<Order_By>;
  Author?: InputMaybe<Order_By>;
  Candidate?: InputMaybe<Order_By>;
  Contract?: InputMaybe<Order_By>;
  ContractAccountManager?: InputMaybe<Order_By>;
  ContractObj?: InputMaybe<Contracts_Order_By>;
  IdAccountManager?: InputMaybe<Order_By>;
  IdResourceManager?: InputMaybe<Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  Jobs?: InputMaybe<Jobs_Order_By>;
  Position?: InputMaybe<Order_By>;
  PreviousStage?: InputMaybe<Order_By>;
  ResourceManager?: InputMaybe<Order_By>;
  StageCurrent?: InputMaybe<Order_By>;
  StageTime?: InputMaybe<Order_By>;
  ToStage?: InputMaybe<Order_By>;
  ToStageIsMilestone?: InputMaybe<Order_By>;
  When?: InputMaybe<Order_By>;
  am_id?: InputMaybe<Order_By>;
  am_name?: InputMaybe<Order_By>;
  am_name_first?: InputMaybe<Order_By>;
  am_name_last?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  author_name_first?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  bucket_day?: InputMaybe<Order_By>;
  bucket_month?: InputMaybe<Order_By>;
  bucket_week?: InputMaybe<Order_By>;
  bucket_year?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_am_id?: InputMaybe<Order_By>;
  contract_am_name_first?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_stage?: InputMaybe<Order_By>;
  from_stage_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_am_id?: InputMaybe<Order_By>;
  id_rm_id?: InputMaybe<Order_By>;
  id_type_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  is_employee?: InputMaybe<Order_By>;
  is_milestone_stage?: InputMaybe<Order_By>;
  is_off_board?: InputMaybe<Order_By>;
  is_off_board_stage?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_update_ts?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  removal_reason?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  rm_name?: InputMaybe<Order_By>;
  rm_name_first?: InputMaybe<Order_By>;
  rm_name_last?: InputMaybe<Order_By>;
  stage_exit_time?: InputMaybe<Order_By>;
  started_dt?: InputMaybe<Order_By>;
  time_in_stage?: InputMaybe<Order_By>;
  time_so_far?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
  total_workflow_time?: InputMaybe<Order_By>;
  workflow_begin_dt?: InputMaybe<Order_By>;
};

/** select columns of table "analytics_view" */
export enum Analytics_View_Select_Column {
  /** column name */
  AccountManager = 'AccountManager',
  /** column name */
  AnalyticsStage = 'AnalyticsStage',
  /** column name */
  AnalyticsStageId = 'AnalyticsStageID',
  /** column name */
  AnalyticsStageOrder = 'AnalyticsStageOrder',
  /** column name */
  Author = 'Author',
  /** column name */
  Candidate = 'Candidate',
  /** column name */
  Contract = 'Contract',
  /** column name */
  ContractAccountManager = 'ContractAccountManager',
  /** column name */
  IdAccountManager = 'IdAccountManager',
  /** column name */
  IdResourceManager = 'IdResourceManager',
  /** column name */
  Position = 'Position',
  /** column name */
  PreviousStage = 'PreviousStage',
  /** column name */
  ResourceManager = 'ResourceManager',
  /** column name */
  StageCurrent = 'StageCurrent',
  /** column name */
  StageTime = 'StageTime',
  /** column name */
  ToStage = 'ToStage',
  /** column name */
  ToStageIsMilestone = 'ToStageIsMilestone',
  /** column name */
  When = 'When',
  /** column name */
  AmId = 'am_id',
  /** column name */
  AmName = 'am_name',
  /** column name */
  AmNameFirst = 'am_name_first',
  /** column name */
  AmNameLast = 'am_name_last',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  AuthorNameFirst = 'author_name_first',
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  BucketDay = 'bucket_day',
  /** column name */
  BucketMonth = 'bucket_month',
  /** column name */
  BucketWeek = 'bucket_week',
  /** column name */
  BucketYear = 'bucket_year',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractAmId = 'contract_am_id',
  /** column name */
  ContractAmNameFirst = 'contract_am_name_first',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStage = 'current_stage',
  /** column name */
  FromStageId = 'from_stage_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdAmId = 'id_am_id',
  /** column name */
  IdRmId = 'id_rm_id',
  /** column name */
  IdTypeId = 'id_type_id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsEmployee = 'is_employee',
  /** column name */
  IsMilestoneStage = 'is_milestone_stage',
  /** column name */
  IsOffBoard = 'is_off_board',
  /** column name */
  IsOffBoardStage = 'is_off_board_stage',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastUpdateTs = 'last_update_ts',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  RemovalReason = 'removal_reason',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  RmName = 'rm_name',
  /** column name */
  RmNameFirst = 'rm_name_first',
  /** column name */
  RmNameLast = 'rm_name_last',
  /** column name */
  StageExitTime = 'stage_exit_time',
  /** column name */
  StartedDt = 'started_dt',
  /** column name */
  TimeInStage = 'time_in_stage',
  /** column name */
  TimeSoFar = 'time_so_far',
  /** column name */
  ToStageId = 'to_stage_id',
  /** column name */
  TotalWorkflowTime = 'total_workflow_time',
  /** column name */
  WorkflowBeginDt = 'workflow_begin_dt'
}

/** aggregate stddev on columns */
export type Analytics_View_Stddev_Fields = {
  __typename?: 'analytics_view_stddev_fields';
  AnalyticsStageID?: Maybe<Scalars['Float']>;
  AnalyticsStageOrder?: Maybe<Scalars['Float']>;
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_am_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  id_am_id?: Maybe<Scalars['Float']>;
  id_rm_id?: Maybe<Scalars['Float']>;
  id_type_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
  total_workflow_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Analytics_View_Stddev_Pop_Fields = {
  __typename?: 'analytics_view_stddev_pop_fields';
  AnalyticsStageID?: Maybe<Scalars['Float']>;
  AnalyticsStageOrder?: Maybe<Scalars['Float']>;
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_am_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  id_am_id?: Maybe<Scalars['Float']>;
  id_rm_id?: Maybe<Scalars['Float']>;
  id_type_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
  total_workflow_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Analytics_View_Stddev_Samp_Fields = {
  __typename?: 'analytics_view_stddev_samp_fields';
  AnalyticsStageID?: Maybe<Scalars['Float']>;
  AnalyticsStageOrder?: Maybe<Scalars['Float']>;
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_am_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  id_am_id?: Maybe<Scalars['Float']>;
  id_rm_id?: Maybe<Scalars['Float']>;
  id_type_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
  total_workflow_time?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "analytics_view" */
export type Analytics_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Analytics_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Analytics_View_Stream_Cursor_Value_Input = {
  AccountManager?: InputMaybe<Scalars['String']>;
  AnalyticsStage?: InputMaybe<Scalars['String']>;
  AnalyticsStageID?: InputMaybe<Scalars['Int']>;
  AnalyticsStageOrder?: InputMaybe<Scalars['Int']>;
  Author?: InputMaybe<Scalars['String']>;
  Candidate?: InputMaybe<Scalars['String']>;
  Contract?: InputMaybe<Scalars['String']>;
  ContractAccountManager?: InputMaybe<Scalars['String']>;
  IdAccountManager?: InputMaybe<Scalars['String']>;
  IdResourceManager?: InputMaybe<Scalars['String']>;
  Position?: InputMaybe<Scalars['String']>;
  PreviousStage?: InputMaybe<Scalars['String']>;
  ResourceManager?: InputMaybe<Scalars['String']>;
  StageCurrent?: InputMaybe<Scalars['String']>;
  StageTime?: InputMaybe<Scalars['String']>;
  ToStage?: InputMaybe<Scalars['String']>;
  ToStageIsMilestone?: InputMaybe<Scalars['Boolean']>;
  When?: InputMaybe<Scalars['timestamptz']>;
  am_id?: InputMaybe<Scalars['Int']>;
  am_name?: InputMaybe<Scalars['String']>;
  am_name_first?: InputMaybe<Scalars['String']>;
  am_name_last?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  author_name_first?: InputMaybe<Scalars['String']>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  bucket_day?: InputMaybe<Scalars['timestamptz']>;
  bucket_month?: InputMaybe<Scalars['timestamptz']>;
  bucket_week?: InputMaybe<Scalars['timestamp']>;
  bucket_year?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_am_id?: InputMaybe<Scalars['Int']>;
  contract_am_name_first?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_stage?: InputMaybe<Scalars['Int']>;
  from_stage_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  id_am_id?: InputMaybe<Scalars['Int']>;
  id_rm_id?: InputMaybe<Scalars['Int']>;
  id_type_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  is_employee?: InputMaybe<Scalars['bit']>;
  is_milestone_stage?: InputMaybe<Scalars['Boolean']>;
  is_off_board?: InputMaybe<Scalars['bit']>;
  is_off_board_stage?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['Int']>;
  last_update_ts?: InputMaybe<Scalars['timestamptz']>;
  name_first?: InputMaybe<Scalars['String']>;
  removal_reason?: InputMaybe<Scalars['String']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  rm_name?: InputMaybe<Scalars['String']>;
  rm_name_first?: InputMaybe<Scalars['String']>;
  rm_name_last?: InputMaybe<Scalars['String']>;
  stage_exit_time?: InputMaybe<Scalars['timestamptz']>;
  started_dt?: InputMaybe<Scalars['timestamptz']>;
  time_in_stage?: InputMaybe<Scalars['bigint']>;
  time_so_far?: InputMaybe<Scalars['interval']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
  total_workflow_time?: InputMaybe<Scalars['float8']>;
  workflow_begin_dt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Analytics_View_Sum_Fields = {
  __typename?: 'analytics_view_sum_fields';
  AnalyticsStageID?: Maybe<Scalars['Int']>;
  AnalyticsStageOrder?: Maybe<Scalars['Int']>;
  am_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_am_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  current_stage?: Maybe<Scalars['Int']>;
  from_stage_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  id_am_id?: Maybe<Scalars['Int']>;
  id_rm_id?: Maybe<Scalars['Int']>;
  id_type_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  rm_id?: Maybe<Scalars['Int']>;
  time_in_stage?: Maybe<Scalars['bigint']>;
  to_stage_id?: Maybe<Scalars['Int']>;
  total_workflow_time?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Analytics_View_Var_Pop_Fields = {
  __typename?: 'analytics_view_var_pop_fields';
  AnalyticsStageID?: Maybe<Scalars['Float']>;
  AnalyticsStageOrder?: Maybe<Scalars['Float']>;
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_am_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  id_am_id?: Maybe<Scalars['Float']>;
  id_rm_id?: Maybe<Scalars['Float']>;
  id_type_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
  total_workflow_time?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Analytics_View_Var_Samp_Fields = {
  __typename?: 'analytics_view_var_samp_fields';
  AnalyticsStageID?: Maybe<Scalars['Float']>;
  AnalyticsStageOrder?: Maybe<Scalars['Float']>;
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_am_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  id_am_id?: Maybe<Scalars['Float']>;
  id_rm_id?: Maybe<Scalars['Float']>;
  id_type_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
  total_workflow_time?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Analytics_View_Variance_Fields = {
  __typename?: 'analytics_view_variance_fields';
  AnalyticsStageID?: Maybe<Scalars['Float']>;
  AnalyticsStageOrder?: Maybe<Scalars['Float']>;
  am_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_am_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  current_stage?: Maybe<Scalars['Float']>;
  from_stage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  id_am_id?: Maybe<Scalars['Float']>;
  id_rm_id?: Maybe<Scalars['Float']>;
  id_type_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  time_in_stage?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
  total_workflow_time?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bit". All fields are combined with logical 'AND'. */
export type Bit_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bit']>;
  _gt?: InputMaybe<Scalars['bit']>;
  _gte?: InputMaybe<Scalars['bit']>;
  _in?: InputMaybe<Array<Scalars['bit']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bit']>;
  _lte?: InputMaybe<Scalars['bit']>;
  _neq?: InputMaybe<Scalars['bit']>;
  _nin?: InputMaybe<Array<Scalars['bit']>>;
};

/** columns and relationships of "candidate_account_managers" */
export type Candidate_Account_Managers = {
  __typename?: 'candidate_account_managers';
  /** An object relationship */
  AccountManager?: Maybe<Identities>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  account_manager_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "candidate_account_managers" */
export type Candidate_Account_Managers_Aggregate = {
  __typename?: 'candidate_account_managers_aggregate';
  aggregate?: Maybe<Candidate_Account_Managers_Aggregate_Fields>;
  nodes: Array<Candidate_Account_Managers>;
};

export type Candidate_Account_Managers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Account_Managers_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Account_Managers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_account_managers" */
export type Candidate_Account_Managers_Aggregate_Fields = {
  __typename?: 'candidate_account_managers_aggregate_fields';
  avg?: Maybe<Candidate_Account_Managers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Candidate_Account_Managers_Max_Fields>;
  min?: Maybe<Candidate_Account_Managers_Min_Fields>;
  stddev?: Maybe<Candidate_Account_Managers_Stddev_Fields>;
  stddev_pop?: Maybe<Candidate_Account_Managers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Candidate_Account_Managers_Stddev_Samp_Fields>;
  sum?: Maybe<Candidate_Account_Managers_Sum_Fields>;
  var_pop?: Maybe<Candidate_Account_Managers_Var_Pop_Fields>;
  var_samp?: Maybe<Candidate_Account_Managers_Var_Samp_Fields>;
  variance?: Maybe<Candidate_Account_Managers_Variance_Fields>;
};


/** aggregate fields of "candidate_account_managers" */
export type Candidate_Account_Managers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_account_managers" */
export type Candidate_Account_Managers_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Account_Managers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Account_Managers_Max_Order_By>;
  min?: InputMaybe<Candidate_Account_Managers_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Account_Managers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Account_Managers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Account_Managers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Account_Managers_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Account_Managers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Account_Managers_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Account_Managers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_account_managers" */
export type Candidate_Account_Managers_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Account_Managers_Insert_Input>;
};

/** aggregate avg on columns */
export type Candidate_Account_Managers_Avg_Fields = {
  __typename?: 'candidate_account_managers_avg_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Avg_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_account_managers". All fields are combined with a logical 'AND'. */
export type Candidate_Account_Managers_Bool_Exp = {
  AccountManager?: InputMaybe<Identities_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Candidate_Account_Managers_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Account_Managers_Bool_Exp>>;
  account_manager_id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "candidate_account_managers" */
export type Candidate_Account_Managers_Insert_Input = {
  AccountManager?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Account_Managers_Max_Fields = {
  __typename?: 'candidate_account_managers_max_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Max_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Account_Managers_Min_Fields = {
  __typename?: 'candidate_account_managers_min_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Min_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "candidate_account_managers". */
export type Candidate_Account_Managers_Order_By = {
  AccountManager?: InputMaybe<Identities_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** select columns of table "candidate_account_managers" */
export enum Candidate_Account_Managers_Select_Column {
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  IdentityId = 'identity_id'
}

/** aggregate stddev on columns */
export type Candidate_Account_Managers_Stddev_Fields = {
  __typename?: 'candidate_account_managers_stddev_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Stddev_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Account_Managers_Stddev_Pop_Fields = {
  __typename?: 'candidate_account_managers_stddev_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Stddev_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Account_Managers_Stddev_Samp_Fields = {
  __typename?: 'candidate_account_managers_stddev_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Stddev_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_account_managers" */
export type Candidate_Account_Managers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Account_Managers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Account_Managers_Stream_Cursor_Value_Input = {
  account_manager_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Account_Managers_Sum_Fields = {
  __typename?: 'candidate_account_managers_sum_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Sum_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Candidate_Account_Managers_Var_Pop_Fields = {
  __typename?: 'candidate_account_managers_var_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Var_Pop_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Account_Managers_Var_Samp_Fields = {
  __typename?: 'candidate_account_managers_var_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Var_Samp_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Account_Managers_Variance_Fields = {
  __typename?: 'candidate_account_managers_variance_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_account_managers" */
export type Candidate_Account_Managers_Variance_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
};

/** table that holds citizenship statuses */
export type CitizenshipEnum = {
  __typename?: 'citizenshipEnum';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "citizenshipEnum" */
export type CitizenshipEnum_Aggregate = {
  __typename?: 'citizenshipEnum_aggregate';
  aggregate?: Maybe<CitizenshipEnum_Aggregate_Fields>;
  nodes: Array<CitizenshipEnum>;
};

/** aggregate fields of "citizenshipEnum" */
export type CitizenshipEnum_Aggregate_Fields = {
  __typename?: 'citizenshipEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CitizenshipEnum_Max_Fields>;
  min?: Maybe<CitizenshipEnum_Min_Fields>;
};


/** aggregate fields of "citizenshipEnum" */
export type CitizenshipEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CitizenshipEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "citizenshipEnum". All fields are combined with a logical 'AND'. */
export type CitizenshipEnum_Bool_Exp = {
  _and?: InputMaybe<Array<CitizenshipEnum_Bool_Exp>>;
  _not?: InputMaybe<CitizenshipEnum_Bool_Exp>;
  _or?: InputMaybe<Array<CitizenshipEnum_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "citizenshipEnum" */
export enum CitizenshipEnum_Constraint {
  /** unique or primary key constraint on columns "key" */
  CitizenshipEnumEnumPkey = 'citizenshipEnum_enum_pkey'
}

export enum CitizenshipEnum_Enum {
  /** Non US Citizen */
  NonUsCitizen = 'NON_US_CITIZEN',
  /** US Citizen */
  UsCitizen = 'US_CITIZEN'
}

/** Boolean expression to compare columns of type "citizenshipEnum_enum". All fields are combined with logical 'AND'. */
export type CitizenshipEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CitizenshipEnum_Enum>;
  _in?: InputMaybe<Array<CitizenshipEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CitizenshipEnum_Enum>;
  _nin?: InputMaybe<Array<CitizenshipEnum_Enum>>;
};

/** input type for inserting data into table "citizenshipEnum" */
export type CitizenshipEnum_Insert_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CitizenshipEnum_Max_Fields = {
  __typename?: 'citizenshipEnum_max_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CitizenshipEnum_Min_Fields = {
  __typename?: 'citizenshipEnum_min_fields';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "citizenshipEnum" */
export type CitizenshipEnum_Mutation_Response = {
  __typename?: 'citizenshipEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CitizenshipEnum>;
};

/** input type for inserting object relation for remote table "citizenshipEnum" */
export type CitizenshipEnum_Obj_Rel_Insert_Input = {
  data: CitizenshipEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CitizenshipEnum_On_Conflict>;
};

/** on_conflict condition type for table "citizenshipEnum" */
export type CitizenshipEnum_On_Conflict = {
  constraint: CitizenshipEnum_Constraint;
  update_columns?: Array<CitizenshipEnum_Update_Column>;
  where?: InputMaybe<CitizenshipEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "citizenshipEnum". */
export type CitizenshipEnum_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: citizenshipEnum */
export type CitizenshipEnum_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "citizenshipEnum" */
export enum CitizenshipEnum_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "citizenshipEnum" */
export type CitizenshipEnum_Set_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "citizenshipEnum" */
export type CitizenshipEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CitizenshipEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CitizenshipEnum_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "citizenshipEnum" */
export enum CitizenshipEnum_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type CitizenshipEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CitizenshipEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: CitizenshipEnum_Bool_Exp;
};

/** columns and relationships of "comments_for_analytics" */
export type Comments_For_Analytics = {
  __typename?: 'comments_for_analytics';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  author?: Maybe<Scalars['String']>;
  author_first?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  author_last?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_hour?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  candidate?: Maybe<Scalars['String']>;
  candidate_first?: Maybe<Scalars['String']>;
  candidate_id?: Maybe<Scalars['Int']>;
  candidate_last?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  log_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "comments_for_analytics" */
export type Comments_For_Analytics_Aggregate = {
  __typename?: 'comments_for_analytics_aggregate';
  aggregate?: Maybe<Comments_For_Analytics_Aggregate_Fields>;
  nodes: Array<Comments_For_Analytics>;
};

/** aggregate fields of "comments_for_analytics" */
export type Comments_For_Analytics_Aggregate_Fields = {
  __typename?: 'comments_for_analytics_aggregate_fields';
  avg?: Maybe<Comments_For_Analytics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Comments_For_Analytics_Max_Fields>;
  min?: Maybe<Comments_For_Analytics_Min_Fields>;
  stddev?: Maybe<Comments_For_Analytics_Stddev_Fields>;
  stddev_pop?: Maybe<Comments_For_Analytics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comments_For_Analytics_Stddev_Samp_Fields>;
  sum?: Maybe<Comments_For_Analytics_Sum_Fields>;
  var_pop?: Maybe<Comments_For_Analytics_Var_Pop_Fields>;
  var_samp?: Maybe<Comments_For_Analytics_Var_Samp_Fields>;
  variance?: Maybe<Comments_For_Analytics_Variance_Fields>;
};


/** aggregate fields of "comments_for_analytics" */
export type Comments_For_Analytics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comments_For_Analytics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Comments_For_Analytics_Avg_Fields = {
  __typename?: 'comments_for_analytics_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "comments_for_analytics". All fields are combined with a logical 'AND'. */
export type Comments_For_Analytics_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Comments_For_Analytics_Bool_Exp>>;
  _not?: InputMaybe<Comments_For_Analytics_Bool_Exp>;
  _or?: InputMaybe<Array<Comments_For_Analytics_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_first?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  author_is_rm?: InputMaybe<Bigint_Comparison_Exp>;
  author_last?: InputMaybe<String_Comparison_Exp>;
  board_snippet_id?: InputMaybe<Int_Comparison_Exp>;
  bucket_day?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_hour?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_month?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_week?: InputMaybe<Timestamp_Comparison_Exp>;
  candidate?: InputMaybe<String_Comparison_Exp>;
  candidate_first?: InputMaybe<String_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_last?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  contract_title?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_comment_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_title?: InputMaybe<String_Comparison_Exp>;
  last_comment_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  log_type?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "comments_for_analytics_join_underlying_view" */
export type Comments_For_Analytics_Join_Underlying_View = {
  __typename?: 'comments_for_analytics_join_underlying_view';
  author?: Maybe<Scalars['String']>;
  author_first?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  author_last?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_hour?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  candidate?: Maybe<Scalars['String']>;
  candidate_first?: Maybe<Scalars['String']>;
  candidate_id?: Maybe<Scalars['Int']>;
  candidate_last?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  log_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "comments_for_analytics_join_underlying_view" */
export type Comments_For_Analytics_Join_Underlying_View_Aggregate = {
  __typename?: 'comments_for_analytics_join_underlying_view_aggregate';
  aggregate?: Maybe<Comments_For_Analytics_Join_Underlying_View_Aggregate_Fields>;
  nodes: Array<Comments_For_Analytics_Join_Underlying_View>;
};

/** aggregate fields of "comments_for_analytics_join_underlying_view" */
export type Comments_For_Analytics_Join_Underlying_View_Aggregate_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_aggregate_fields';
  avg?: Maybe<Comments_For_Analytics_Join_Underlying_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Comments_For_Analytics_Join_Underlying_View_Max_Fields>;
  min?: Maybe<Comments_For_Analytics_Join_Underlying_View_Min_Fields>;
  stddev?: Maybe<Comments_For_Analytics_Join_Underlying_View_Stddev_Fields>;
  stddev_pop?: Maybe<Comments_For_Analytics_Join_Underlying_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comments_For_Analytics_Join_Underlying_View_Stddev_Samp_Fields>;
  sum?: Maybe<Comments_For_Analytics_Join_Underlying_View_Sum_Fields>;
  var_pop?: Maybe<Comments_For_Analytics_Join_Underlying_View_Var_Pop_Fields>;
  var_samp?: Maybe<Comments_For_Analytics_Join_Underlying_View_Var_Samp_Fields>;
  variance?: Maybe<Comments_For_Analytics_Join_Underlying_View_Variance_Fields>;
};


/** aggregate fields of "comments_for_analytics_join_underlying_view" */
export type Comments_For_Analytics_Join_Underlying_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Comments_For_Analytics_Join_Underlying_View_Avg_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "comments_for_analytics_join_underlying_view". All fields are combined with a logical 'AND'. */
export type Comments_For_Analytics_Join_Underlying_View_Bool_Exp = {
  _and?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>>;
  _not?: InputMaybe<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>;
  _or?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_first?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  author_is_rm?: InputMaybe<Bigint_Comparison_Exp>;
  author_last?: InputMaybe<String_Comparison_Exp>;
  board_snippet_id?: InputMaybe<Int_Comparison_Exp>;
  bucket_day?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_hour?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_month?: InputMaybe<Timestamptz_Comparison_Exp>;
  bucket_week?: InputMaybe<Timestamp_Comparison_Exp>;
  candidate?: InputMaybe<String_Comparison_Exp>;
  candidate_first?: InputMaybe<String_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_last?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  contract_title?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_comment_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_title?: InputMaybe<String_Comparison_Exp>;
  last_comment_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  log_type?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Comments_For_Analytics_Join_Underlying_View_Max_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_max_fields';
  author?: Maybe<Scalars['String']>;
  author_first?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  author_last?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_hour?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  candidate?: Maybe<Scalars['String']>;
  candidate_first?: Maybe<Scalars['String']>;
  candidate_id?: Maybe<Scalars['Int']>;
  candidate_last?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  log_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Comments_For_Analytics_Join_Underlying_View_Min_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_min_fields';
  author?: Maybe<Scalars['String']>;
  author_first?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  author_last?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_hour?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  candidate?: Maybe<Scalars['String']>;
  candidate_first?: Maybe<Scalars['String']>;
  candidate_id?: Maybe<Scalars['Int']>;
  candidate_last?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  log_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "comments_for_analytics_join_underlying_view". */
export type Comments_For_Analytics_Join_Underlying_View_Order_By = {
  author?: InputMaybe<Order_By>;
  author_first?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  author_is_rm?: InputMaybe<Order_By>;
  author_last?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  bucket_day?: InputMaybe<Order_By>;
  bucket_hour?: InputMaybe<Order_By>;
  bucket_month?: InputMaybe<Order_By>;
  bucket_week?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Order_By>;
  candidate_first?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_last?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  contract_title?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_comment_dt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  last_comment_dt?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "comments_for_analytics_join_underlying_view" */
export enum Comments_For_Analytics_Join_Underlying_View_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorFirst = 'author_first',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  AuthorIsRm = 'author_is_rm',
  /** column name */
  AuthorLast = 'author_last',
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  BucketDay = 'bucket_day',
  /** column name */
  BucketHour = 'bucket_hour',
  /** column name */
  BucketMonth = 'bucket_month',
  /** column name */
  BucketWeek = 'bucket_week',
  /** column name */
  Candidate = 'candidate',
  /** column name */
  CandidateFirst = 'candidate_first',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateLast = 'candidate_last',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Content = 'content',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  ContractTitle = 'contract_title',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstCommentDt = 'first_comment_dt',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  LastCommentDt = 'last_comment_dt',
  /** column name */
  LogType = 'log_type',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Comments_For_Analytics_Join_Underlying_View_Stddev_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Comments_For_Analytics_Join_Underlying_View_Stddev_Pop_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Comments_For_Analytics_Join_Underlying_View_Stddev_Samp_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "comments_for_analytics_join_underlying_view" */
export type Comments_For_Analytics_Join_Underlying_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comments_For_Analytics_Join_Underlying_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_For_Analytics_Join_Underlying_View_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_first?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  author_is_rm?: InputMaybe<Scalars['bigint']>;
  author_last?: InputMaybe<Scalars['String']>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  bucket_day?: InputMaybe<Scalars['timestamptz']>;
  bucket_hour?: InputMaybe<Scalars['timestamptz']>;
  bucket_month?: InputMaybe<Scalars['timestamptz']>;
  bucket_week?: InputMaybe<Scalars['timestamp']>;
  candidate?: InputMaybe<Scalars['String']>;
  candidate_first?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_last?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  contract_title?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_comment_dt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  last_comment_dt?: InputMaybe<Scalars['timestamptz']>;
  log_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Comments_For_Analytics_Join_Underlying_View_Sum_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Comments_For_Analytics_Join_Underlying_View_Var_Pop_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Comments_For_Analytics_Join_Underlying_View_Var_Samp_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Comments_For_Analytics_Join_Underlying_View_Variance_Fields = {
  __typename?: 'comments_for_analytics_join_underlying_view_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Comments_For_Analytics_Max_Fields = {
  __typename?: 'comments_for_analytics_max_fields';
  author?: Maybe<Scalars['String']>;
  author_first?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  author_last?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_hour?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  candidate?: Maybe<Scalars['String']>;
  candidate_first?: Maybe<Scalars['String']>;
  candidate_id?: Maybe<Scalars['Int']>;
  candidate_last?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  log_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Comments_For_Analytics_Min_Fields = {
  __typename?: 'comments_for_analytics_min_fields';
  author?: Maybe<Scalars['String']>;
  author_first?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  author_last?: Maybe<Scalars['String']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  bucket_day?: Maybe<Scalars['timestamptz']>;
  bucket_hour?: Maybe<Scalars['timestamptz']>;
  bucket_month?: Maybe<Scalars['timestamptz']>;
  bucket_week?: Maybe<Scalars['timestamp']>;
  candidate?: Maybe<Scalars['String']>;
  candidate_first?: Maybe<Scalars['String']>;
  candidate_id?: Maybe<Scalars['Int']>;
  candidate_last?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_comment_dt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_title?: Maybe<Scalars['String']>;
  last_comment_dt?: Maybe<Scalars['timestamptz']>;
  log_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "comments_for_analytics". */
export type Comments_For_Analytics_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  author?: InputMaybe<Order_By>;
  author_first?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  author_is_rm?: InputMaybe<Order_By>;
  author_last?: InputMaybe<Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  bucket_day?: InputMaybe<Order_By>;
  bucket_hour?: InputMaybe<Order_By>;
  bucket_month?: InputMaybe<Order_By>;
  bucket_week?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Order_By>;
  candidate_first?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_last?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  contract_title?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_comment_dt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  last_comment_dt?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "comments_for_analytics" */
export enum Comments_For_Analytics_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorFirst = 'author_first',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  AuthorIsRm = 'author_is_rm',
  /** column name */
  AuthorLast = 'author_last',
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  BucketDay = 'bucket_day',
  /** column name */
  BucketHour = 'bucket_hour',
  /** column name */
  BucketMonth = 'bucket_month',
  /** column name */
  BucketWeek = 'bucket_week',
  /** column name */
  Candidate = 'candidate',
  /** column name */
  CandidateFirst = 'candidate_first',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateLast = 'candidate_last',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Content = 'content',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  ContractTitle = 'contract_title',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstCommentDt = 'first_comment_dt',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  LastCommentDt = 'last_comment_dt',
  /** column name */
  LogType = 'log_type',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Comments_For_Analytics_Stddev_Fields = {
  __typename?: 'comments_for_analytics_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Comments_For_Analytics_Stddev_Pop_Fields = {
  __typename?: 'comments_for_analytics_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Comments_For_Analytics_Stddev_Samp_Fields = {
  __typename?: 'comments_for_analytics_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "comments_for_analytics" */
export type Comments_For_Analytics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comments_For_Analytics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_For_Analytics_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_first?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  author_is_rm?: InputMaybe<Scalars['bigint']>;
  author_last?: InputMaybe<Scalars['String']>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  bucket_day?: InputMaybe<Scalars['timestamptz']>;
  bucket_hour?: InputMaybe<Scalars['timestamptz']>;
  bucket_month?: InputMaybe<Scalars['timestamptz']>;
  bucket_week?: InputMaybe<Scalars['timestamp']>;
  candidate?: InputMaybe<Scalars['String']>;
  candidate_first?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_last?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  contract_title?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_comment_dt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  last_comment_dt?: InputMaybe<Scalars['timestamptz']>;
  log_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Comments_For_Analytics_Sum_Fields = {
  __typename?: 'comments_for_analytics_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  author_is_rm?: Maybe<Scalars['bigint']>;
  board_snippet_id?: Maybe<Scalars['Int']>;
  candidate_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Comments_For_Analytics_Var_Pop_Fields = {
  __typename?: 'comments_for_analytics_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Comments_For_Analytics_Var_Samp_Fields = {
  __typename?: 'comments_for_analytics_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Comments_For_Analytics_Variance_Fields = {
  __typename?: 'comments_for_analytics_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  author_is_rm?: Maybe<Scalars['Float']>;
  board_snippet_id?: Maybe<Scalars['Float']>;
  candidate_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "contract_emails" */
export type Contract_Emails = {
  __typename?: 'contract_emails';
  /** An object relationship */
  Contract: Contracts;
  /** An object relationship */
  Email: Emails;
  contract_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  email_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "contract_emails" */
export type Contract_Emails_Aggregate = {
  __typename?: 'contract_emails_aggregate';
  aggregate?: Maybe<Contract_Emails_Aggregate_Fields>;
  nodes: Array<Contract_Emails>;
};

export type Contract_Emails_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contract_Emails_Aggregate_Bool_Exp_Count>;
};

export type Contract_Emails_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contract_Emails_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contract_emails" */
export type Contract_Emails_Aggregate_Fields = {
  __typename?: 'contract_emails_aggregate_fields';
  avg?: Maybe<Contract_Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contract_Emails_Max_Fields>;
  min?: Maybe<Contract_Emails_Min_Fields>;
  stddev?: Maybe<Contract_Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Contract_Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contract_Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Contract_Emails_Sum_Fields>;
  var_pop?: Maybe<Contract_Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Contract_Emails_Var_Samp_Fields>;
  variance?: Maybe<Contract_Emails_Variance_Fields>;
};


/** aggregate fields of "contract_emails" */
export type Contract_Emails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract_emails" */
export type Contract_Emails_Aggregate_Order_By = {
  avg?: InputMaybe<Contract_Emails_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contract_Emails_Max_Order_By>;
  min?: InputMaybe<Contract_Emails_Min_Order_By>;
  stddev?: InputMaybe<Contract_Emails_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contract_Emails_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contract_Emails_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contract_Emails_Sum_Order_By>;
  var_pop?: InputMaybe<Contract_Emails_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contract_Emails_Var_Samp_Order_By>;
  variance?: InputMaybe<Contract_Emails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contract_emails" */
export type Contract_Emails_Arr_Rel_Insert_Input = {
  data: Array<Contract_Emails_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contract_Emails_On_Conflict>;
};

/** aggregate avg on columns */
export type Contract_Emails_Avg_Fields = {
  __typename?: 'contract_emails_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract_emails" */
export type Contract_Emails_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contract_emails". All fields are combined with a logical 'AND'. */
export type Contract_Emails_Bool_Exp = {
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Email?: InputMaybe<Emails_Bool_Exp>;
  _and?: InputMaybe<Array<Contract_Emails_Bool_Exp>>;
  _not?: InputMaybe<Contract_Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_Emails_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contract_emails" */
export enum Contract_Emails_Constraint {
  /** unique or primary key constraint on columns "email_id", "contract_id" */
  ContractEmailsPkey = 'contract_emails_pkey'
}

/** input type for incrementing numeric columns in table "contract_emails" */
export type Contract_Emails_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  email_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "contract_emails" */
export type Contract_Emails_Insert_Input = {
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Email?: InputMaybe<Emails_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contract_Emails_Max_Fields = {
  __typename?: 'contract_emails_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contract_emails" */
export type Contract_Emails_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contract_Emails_Min_Fields = {
  __typename?: 'contract_emails_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contract_emails" */
export type Contract_Emails_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contract_emails" */
export type Contract_Emails_Mutation_Response = {
  __typename?: 'contract_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contract_Emails>;
};

/** on_conflict condition type for table "contract_emails" */
export type Contract_Emails_On_Conflict = {
  constraint: Contract_Emails_Constraint;
  update_columns?: Array<Contract_Emails_Update_Column>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "contract_emails". */
export type Contract_Emails_Order_By = {
  Contract?: InputMaybe<Contracts_Order_By>;
  Email?: InputMaybe<Emails_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contract_emails */
export type Contract_Emails_Pk_Columns_Input = {
  contract_id: Scalars['Int'];
  email_id: Scalars['Int'];
};

/** select columns of table "contract_emails" */
export enum Contract_Emails_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contract_emails" */
export type Contract_Emails_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Contract_Emails_Stddev_Fields = {
  __typename?: 'contract_emails_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract_emails" */
export type Contract_Emails_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contract_Emails_Stddev_Pop_Fields = {
  __typename?: 'contract_emails_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract_emails" */
export type Contract_Emails_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contract_Emails_Stddev_Samp_Fields = {
  __typename?: 'contract_emails_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract_emails" */
export type Contract_Emails_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contract_emails" */
export type Contract_Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contract_Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contract_Emails_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Contract_Emails_Sum_Fields = {
  __typename?: 'contract_emails_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contract_emails" */
export type Contract_Emails_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** update columns of table "contract_emails" */
export enum Contract_Emails_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contract_Emails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contract_Emails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contract_Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contract_Emails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contract_Emails_Var_Pop_Fields = {
  __typename?: 'contract_emails_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract_emails" */
export type Contract_Emails_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contract_Emails_Var_Samp_Fields = {
  __typename?: 'contract_emails_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract_emails" */
export type Contract_Emails_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contract_Emails_Variance_Fields = {
  __typename?: 'contract_emails_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract_emails" */
export type Contract_Emails_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "current_wk_activity" */
export type Current_Wk_Activity = {
  __typename?: 'current_wk_activity';
  activitylogtype_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "current_wk_activity" */
export type Current_Wk_Activity_Aggregate = {
  __typename?: 'current_wk_activity_aggregate';
  aggregate?: Maybe<Current_Wk_Activity_Aggregate_Fields>;
  nodes: Array<Current_Wk_Activity>;
};

/** aggregate fields of "current_wk_activity" */
export type Current_Wk_Activity_Aggregate_Fields = {
  __typename?: 'current_wk_activity_aggregate_fields';
  avg?: Maybe<Current_Wk_Activity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Current_Wk_Activity_Max_Fields>;
  min?: Maybe<Current_Wk_Activity_Min_Fields>;
  stddev?: Maybe<Current_Wk_Activity_Stddev_Fields>;
  stddev_pop?: Maybe<Current_Wk_Activity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Current_Wk_Activity_Stddev_Samp_Fields>;
  sum?: Maybe<Current_Wk_Activity_Sum_Fields>;
  var_pop?: Maybe<Current_Wk_Activity_Var_Pop_Fields>;
  var_samp?: Maybe<Current_Wk_Activity_Var_Samp_Fields>;
  variance?: Maybe<Current_Wk_Activity_Variance_Fields>;
};


/** aggregate fields of "current_wk_activity" */
export type Current_Wk_Activity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Current_Wk_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Current_Wk_Activity_Avg_Fields = {
  __typename?: 'current_wk_activity_avg_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "current_wk_activity". All fields are combined with a logical 'AND'. */
export type Current_Wk_Activity_Bool_Exp = {
  _and?: InputMaybe<Array<Current_Wk_Activity_Bool_Exp>>;
  _not?: InputMaybe<Current_Wk_Activity_Bool_Exp>;
  _or?: InputMaybe<Array<Current_Wk_Activity_Bool_Exp>>;
  activitylogtype_id?: InputMaybe<Int_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Current_Wk_Activity_Max_Fields = {
  __typename?: 'current_wk_activity_max_fields';
  activitylogtype_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Current_Wk_Activity_Min_Fields = {
  __typename?: 'current_wk_activity_min_fields';
  activitylogtype_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "current_wk_activity". */
export type Current_Wk_Activity_Order_By = {
  activitylogtype_id?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
};

/** select columns of table "current_wk_activity" */
export enum Current_Wk_Activity_Select_Column {
  /** column name */
  ActivitylogtypeId = 'activitylogtype_id',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Current_Wk_Activity_Stddev_Fields = {
  __typename?: 'current_wk_activity_stddev_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Current_Wk_Activity_Stddev_Pop_Fields = {
  __typename?: 'current_wk_activity_stddev_pop_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Current_Wk_Activity_Stddev_Samp_Fields = {
  __typename?: 'current_wk_activity_stddev_samp_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "current_wk_activity" */
export type Current_Wk_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Current_Wk_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Current_Wk_Activity_Stream_Cursor_Value_Input = {
  activitylogtype_id?: InputMaybe<Scalars['Int']>;
  author_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Current_Wk_Activity_Sum_Fields = {
  __typename?: 'current_wk_activity_sum_fields';
  activitylogtype_id?: Maybe<Scalars['Int']>;
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Current_Wk_Activity_Var_Pop_Fields = {
  __typename?: 'current_wk_activity_var_pop_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Current_Wk_Activity_Var_Samp_Fields = {
  __typename?: 'current_wk_activity_var_samp_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Current_Wk_Activity_Variance_Fields = {
  __typename?: 'current_wk_activity_variance_fields';
  activitylogtype_id?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "current_workflows_for_identity" */
export type Current_Workflows_For_Identity = {
  __typename?: 'current_workflows_for_identity';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  Workflow?: Maybe<BoardSnippets>;
  identity_id?: Maybe<Scalars['Int']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Aggregate = {
  __typename?: 'current_workflows_for_identity_aggregate';
  aggregate?: Maybe<Current_Workflows_For_Identity_Aggregate_Fields>;
  nodes: Array<Current_Workflows_For_Identity>;
};

export type Current_Workflows_For_Identity_Aggregate_Bool_Exp = {
  count?: InputMaybe<Current_Workflows_For_Identity_Aggregate_Bool_Exp_Count>;
};

export type Current_Workflows_For_Identity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Aggregate_Fields = {
  __typename?: 'current_workflows_for_identity_aggregate_fields';
  avg?: Maybe<Current_Workflows_For_Identity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Current_Workflows_For_Identity_Max_Fields>;
  min?: Maybe<Current_Workflows_For_Identity_Min_Fields>;
  stddev?: Maybe<Current_Workflows_For_Identity_Stddev_Fields>;
  stddev_pop?: Maybe<Current_Workflows_For_Identity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Current_Workflows_For_Identity_Stddev_Samp_Fields>;
  sum?: Maybe<Current_Workflows_For_Identity_Sum_Fields>;
  var_pop?: Maybe<Current_Workflows_For_Identity_Var_Pop_Fields>;
  var_samp?: Maybe<Current_Workflows_For_Identity_Var_Samp_Fields>;
  variance?: Maybe<Current_Workflows_For_Identity_Variance_Fields>;
};


/** aggregate fields of "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Aggregate_Order_By = {
  avg?: InputMaybe<Current_Workflows_For_Identity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Current_Workflows_For_Identity_Max_Order_By>;
  min?: InputMaybe<Current_Workflows_For_Identity_Min_Order_By>;
  stddev?: InputMaybe<Current_Workflows_For_Identity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Current_Workflows_For_Identity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Current_Workflows_For_Identity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Current_Workflows_For_Identity_Sum_Order_By>;
  var_pop?: InputMaybe<Current_Workflows_For_Identity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Current_Workflows_For_Identity_Var_Samp_Order_By>;
  variance?: InputMaybe<Current_Workflows_For_Identity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Arr_Rel_Insert_Input = {
  data: Array<Current_Workflows_For_Identity_Insert_Input>;
};

/** aggregate avg on columns */
export type Current_Workflows_For_Identity_Avg_Fields = {
  __typename?: 'current_workflows_for_identity_avg_fields';
  identity_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Avg_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "current_workflows_for_identity". All fields are combined with a logical 'AND'. */
export type Current_Workflows_For_Identity_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  Workflow?: InputMaybe<BoardSnippets_Bool_Exp>;
  _and?: InputMaybe<Array<Current_Workflows_For_Identity_Bool_Exp>>;
  _not?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
  _or?: InputMaybe<Array<Current_Workflows_For_Identity_Bool_Exp>>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  workflow_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Workflow?: InputMaybe<BoardSnippets_Obj_Rel_Insert_Input>;
  identity_id?: InputMaybe<Scalars['Int']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Current_Workflows_For_Identity_Max_Fields = {
  __typename?: 'current_workflows_for_identity_max_fields';
  identity_id?: Maybe<Scalars['Int']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Max_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Current_Workflows_For_Identity_Min_Fields = {
  __typename?: 'current_workflows_for_identity_min_fields';
  identity_id?: Maybe<Scalars['Int']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Min_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "current_workflows_for_identity". */
export type Current_Workflows_For_Identity_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  Workflow?: InputMaybe<BoardSnippets_Order_By>;
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** select columns of table "current_workflows_for_identity" */
export enum Current_Workflows_For_Identity_Select_Column {
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  WorkflowId = 'workflow_id'
}

/** aggregate stddev on columns */
export type Current_Workflows_For_Identity_Stddev_Fields = {
  __typename?: 'current_workflows_for_identity_stddev_fields';
  identity_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Stddev_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Current_Workflows_For_Identity_Stddev_Pop_Fields = {
  __typename?: 'current_workflows_for_identity_stddev_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Stddev_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Current_Workflows_For_Identity_Stddev_Samp_Fields = {
  __typename?: 'current_workflows_for_identity_stddev_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Stddev_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Current_Workflows_For_Identity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Current_Workflows_For_Identity_Stream_Cursor_Value_Input = {
  identity_id?: InputMaybe<Scalars['Int']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Current_Workflows_For_Identity_Sum_Fields = {
  __typename?: 'current_workflows_for_identity_sum_fields';
  identity_id?: Maybe<Scalars['Int']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Sum_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Current_Workflows_For_Identity_Var_Pop_Fields = {
  __typename?: 'current_workflows_for_identity_var_pop_fields';
  identity_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Var_Pop_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Current_Workflows_For_Identity_Var_Samp_Fields = {
  __typename?: 'current_workflows_for_identity_var_samp_fields';
  identity_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Var_Samp_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Current_Workflows_For_Identity_Variance_Fields = {
  __typename?: 'current_workflows_for_identity_variance_fields';
  identity_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "current_workflows_for_identity" */
export type Current_Workflows_For_Identity_Variance_Order_By = {
  identity_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "dashboard_activity_counts_by_day" */
export type Dashboard_Activity_Counts_By_Day = {
  __typename?: 'dashboard_activity_counts_by_day';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "dashboard_activity_counts_by_day" */
export type Dashboard_Activity_Counts_By_Day_Aggregate = {
  __typename?: 'dashboard_activity_counts_by_day_aggregate';
  aggregate?: Maybe<Dashboard_Activity_Counts_By_Day_Aggregate_Fields>;
  nodes: Array<Dashboard_Activity_Counts_By_Day>;
};

/** aggregate fields of "dashboard_activity_counts_by_day" */
export type Dashboard_Activity_Counts_By_Day_Aggregate_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_aggregate_fields';
  avg?: Maybe<Dashboard_Activity_Counts_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Activity_Counts_By_Day_Max_Fields>;
  min?: Maybe<Dashboard_Activity_Counts_By_Day_Min_Fields>;
  stddev?: Maybe<Dashboard_Activity_Counts_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Activity_Counts_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Activity_Counts_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Activity_Counts_By_Day_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Activity_Counts_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Activity_Counts_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Activity_Counts_By_Day_Variance_Fields>;
};


/** aggregate fields of "dashboard_activity_counts_by_day" */
export type Dashboard_Activity_Counts_By_Day_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Activity_Counts_By_Day_Avg_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_activity_counts_by_day". All fields are combined with a logical 'AND'. */
export type Dashboard_Activity_Counts_By_Day_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Activity_Counts_By_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  bucket?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Activity_Counts_By_Day_Max_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_max_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Dashboard_Activity_Counts_By_Day_Min_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_min_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "dashboard_activity_counts_by_day". */
export type Dashboard_Activity_Counts_By_Day_Order_By = {
  author?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_activity_counts_by_day" */
export enum Dashboard_Activity_Counts_By_Day_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Dashboard_Activity_Counts_By_Day_Stddev_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Activity_Counts_By_Day_Stddev_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Activity_Counts_By_Day_Stddev_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_activity_counts_by_day" */
export type Dashboard_Activity_Counts_By_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Activity_Counts_By_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Activity_Counts_By_Day_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  bucket?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dashboard_Activity_Counts_By_Day_Sum_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Activity_Counts_By_Day_Var_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Activity_Counts_By_Day_Var_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Activity_Counts_By_Day_Variance_Fields = {
  __typename?: 'dashboard_activity_counts_by_day_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_activity_counts_by_hour" */
export type Dashboard_Activity_Counts_By_Hour = {
  __typename?: 'dashboard_activity_counts_by_hour';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "dashboard_activity_counts_by_hour" */
export type Dashboard_Activity_Counts_By_Hour_Aggregate = {
  __typename?: 'dashboard_activity_counts_by_hour_aggregate';
  aggregate?: Maybe<Dashboard_Activity_Counts_By_Hour_Aggregate_Fields>;
  nodes: Array<Dashboard_Activity_Counts_By_Hour>;
};

/** aggregate fields of "dashboard_activity_counts_by_hour" */
export type Dashboard_Activity_Counts_By_Hour_Aggregate_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_aggregate_fields';
  avg?: Maybe<Dashboard_Activity_Counts_By_Hour_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Activity_Counts_By_Hour_Max_Fields>;
  min?: Maybe<Dashboard_Activity_Counts_By_Hour_Min_Fields>;
  stddev?: Maybe<Dashboard_Activity_Counts_By_Hour_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Activity_Counts_By_Hour_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Activity_Counts_By_Hour_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Activity_Counts_By_Hour_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Activity_Counts_By_Hour_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Activity_Counts_By_Hour_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Activity_Counts_By_Hour_Variance_Fields>;
};


/** aggregate fields of "dashboard_activity_counts_by_hour" */
export type Dashboard_Activity_Counts_By_Hour_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Activity_Counts_By_Hour_Avg_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_activity_counts_by_hour". All fields are combined with a logical 'AND'. */
export type Dashboard_Activity_Counts_By_Hour_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Activity_Counts_By_Hour_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  bucket?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Activity_Counts_By_Hour_Max_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_max_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Dashboard_Activity_Counts_By_Hour_Min_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_min_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "dashboard_activity_counts_by_hour". */
export type Dashboard_Activity_Counts_By_Hour_Order_By = {
  author?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_activity_counts_by_hour" */
export enum Dashboard_Activity_Counts_By_Hour_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Dashboard_Activity_Counts_By_Hour_Stddev_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Activity_Counts_By_Hour_Stddev_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Activity_Counts_By_Hour_Stddev_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_activity_counts_by_hour" */
export type Dashboard_Activity_Counts_By_Hour_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Activity_Counts_By_Hour_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Activity_Counts_By_Hour_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  bucket?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dashboard_Activity_Counts_By_Hour_Sum_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Activity_Counts_By_Hour_Var_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Activity_Counts_By_Hour_Var_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Activity_Counts_By_Hour_Variance_Fields = {
  __typename?: 'dashboard_activity_counts_by_hour_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_activity_counts_by_month" */
export type Dashboard_Activity_Counts_By_Month = {
  __typename?: 'dashboard_activity_counts_by_month';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "dashboard_activity_counts_by_month" */
export type Dashboard_Activity_Counts_By_Month_Aggregate = {
  __typename?: 'dashboard_activity_counts_by_month_aggregate';
  aggregate?: Maybe<Dashboard_Activity_Counts_By_Month_Aggregate_Fields>;
  nodes: Array<Dashboard_Activity_Counts_By_Month>;
};

/** aggregate fields of "dashboard_activity_counts_by_month" */
export type Dashboard_Activity_Counts_By_Month_Aggregate_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_aggregate_fields';
  avg?: Maybe<Dashboard_Activity_Counts_By_Month_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Activity_Counts_By_Month_Max_Fields>;
  min?: Maybe<Dashboard_Activity_Counts_By_Month_Min_Fields>;
  stddev?: Maybe<Dashboard_Activity_Counts_By_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Activity_Counts_By_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Activity_Counts_By_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Activity_Counts_By_Month_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Activity_Counts_By_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Activity_Counts_By_Month_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Activity_Counts_By_Month_Variance_Fields>;
};


/** aggregate fields of "dashboard_activity_counts_by_month" */
export type Dashboard_Activity_Counts_By_Month_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Activity_Counts_By_Month_Avg_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_activity_counts_by_month". All fields are combined with a logical 'AND'. */
export type Dashboard_Activity_Counts_By_Month_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Activity_Counts_By_Month_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  bucket?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Activity_Counts_By_Month_Max_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_max_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Dashboard_Activity_Counts_By_Month_Min_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_min_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "dashboard_activity_counts_by_month". */
export type Dashboard_Activity_Counts_By_Month_Order_By = {
  author?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_activity_counts_by_month" */
export enum Dashboard_Activity_Counts_By_Month_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Dashboard_Activity_Counts_By_Month_Stddev_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Activity_Counts_By_Month_Stddev_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Activity_Counts_By_Month_Stddev_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_activity_counts_by_month" */
export type Dashboard_Activity_Counts_By_Month_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Activity_Counts_By_Month_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Activity_Counts_By_Month_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  bucket?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dashboard_Activity_Counts_By_Month_Sum_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Activity_Counts_By_Month_Var_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Activity_Counts_By_Month_Var_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Activity_Counts_By_Month_Variance_Fields = {
  __typename?: 'dashboard_activity_counts_by_month_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_activity_counts_by_week" */
export type Dashboard_Activity_Counts_By_Week = {
  __typename?: 'dashboard_activity_counts_by_week';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamp']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "dashboard_activity_counts_by_week" */
export type Dashboard_Activity_Counts_By_Week_Aggregate = {
  __typename?: 'dashboard_activity_counts_by_week_aggregate';
  aggregate?: Maybe<Dashboard_Activity_Counts_By_Week_Aggregate_Fields>;
  nodes: Array<Dashboard_Activity_Counts_By_Week>;
};

/** aggregate fields of "dashboard_activity_counts_by_week" */
export type Dashboard_Activity_Counts_By_Week_Aggregate_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_aggregate_fields';
  avg?: Maybe<Dashboard_Activity_Counts_By_Week_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Activity_Counts_By_Week_Max_Fields>;
  min?: Maybe<Dashboard_Activity_Counts_By_Week_Min_Fields>;
  stddev?: Maybe<Dashboard_Activity_Counts_By_Week_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Activity_Counts_By_Week_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Activity_Counts_By_Week_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Activity_Counts_By_Week_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Activity_Counts_By_Week_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Activity_Counts_By_Week_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Activity_Counts_By_Week_Variance_Fields>;
};


/** aggregate fields of "dashboard_activity_counts_by_week" */
export type Dashboard_Activity_Counts_By_Week_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Activity_Counts_By_Week_Avg_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_activity_counts_by_week". All fields are combined with a logical 'AND'. */
export type Dashboard_Activity_Counts_By_Week_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Activity_Counts_By_Week_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  bucket?: InputMaybe<Timestamp_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Activity_Counts_By_Week_Max_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_max_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamp']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Dashboard_Activity_Counts_By_Week_Min_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_min_fields';
  author?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['Int']>;
  bucket?: Maybe<Scalars['timestamp']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "dashboard_activity_counts_by_week". */
export type Dashboard_Activity_Counts_By_Week_Order_By = {
  author?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_activity_counts_by_week" */
export enum Dashboard_Activity_Counts_By_Week_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Dashboard_Activity_Counts_By_Week_Stddev_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Activity_Counts_By_Week_Stddev_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Activity_Counts_By_Week_Stddev_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_activity_counts_by_week" */
export type Dashboard_Activity_Counts_By_Week_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Activity_Counts_By_Week_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Activity_Counts_By_Week_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['Int']>;
  bucket?: InputMaybe<Scalars['timestamp']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dashboard_Activity_Counts_By_Week_Sum_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Activity_Counts_By_Week_Var_Pop_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Activity_Counts_By_Week_Var_Samp_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Activity_Counts_By_Week_Variance_Fields = {
  __typename?: 'dashboard_activity_counts_by_week_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_stage_counts_by_day" */
export type Dashboard_Stage_Counts_By_Day = {
  __typename?: 'dashboard_stage_counts_by_day';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "dashboard_stage_counts_by_day" */
export type Dashboard_Stage_Counts_By_Day_Aggregate = {
  __typename?: 'dashboard_stage_counts_by_day_aggregate';
  aggregate?: Maybe<Dashboard_Stage_Counts_By_Day_Aggregate_Fields>;
  nodes: Array<Dashboard_Stage_Counts_By_Day>;
};

/** aggregate fields of "dashboard_stage_counts_by_day" */
export type Dashboard_Stage_Counts_By_Day_Aggregate_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_aggregate_fields';
  avg?: Maybe<Dashboard_Stage_Counts_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Stage_Counts_By_Day_Max_Fields>;
  min?: Maybe<Dashboard_Stage_Counts_By_Day_Min_Fields>;
  stddev?: Maybe<Dashboard_Stage_Counts_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Stage_Counts_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Stage_Counts_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Stage_Counts_By_Day_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Stage_Counts_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Stage_Counts_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Stage_Counts_By_Day_Variance_Fields>;
};


/** aggregate fields of "dashboard_stage_counts_by_day" */
export type Dashboard_Stage_Counts_By_Day_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Stage_Counts_By_Day_Avg_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_stage_counts_by_day". All fields are combined with a logical 'AND'. */
export type Dashboard_Stage_Counts_By_Day_Bool_Exp = {
  ToStage?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Stage_Counts_By_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Bool_Exp>>;
  bucket?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  rm_name?: InputMaybe<String_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Stage_Counts_By_Day_Max_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_max_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Dashboard_Stage_Counts_By_Day_Min_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_min_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "dashboard_stage_counts_by_day". */
export type Dashboard_Stage_Counts_By_Day_Order_By = {
  ToStage?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  rm_name?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_stage_counts_by_day" */
export enum Dashboard_Stage_Counts_By_Day_Select_Column {
  /** column name */
  ToStage = 'ToStage',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  RmName = 'rm_name',
  /** column name */
  ToStageId = 'to_stage_id'
}

/** aggregate stddev on columns */
export type Dashboard_Stage_Counts_By_Day_Stddev_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Stage_Counts_By_Day_Stddev_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Stage_Counts_By_Day_Stddev_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_stage_counts_by_day" */
export type Dashboard_Stage_Counts_By_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Stage_Counts_By_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Stage_Counts_By_Day_Stream_Cursor_Value_Input = {
  ToStage?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  rm_name?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dashboard_Stage_Counts_By_Day_Sum_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Stage_Counts_By_Day_Var_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Stage_Counts_By_Day_Var_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Stage_Counts_By_Day_Variance_Fields = {
  __typename?: 'dashboard_stage_counts_by_day_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_stage_counts_by_month" */
export type Dashboard_Stage_Counts_By_Month = {
  __typename?: 'dashboard_stage_counts_by_month';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "dashboard_stage_counts_by_month" */
export type Dashboard_Stage_Counts_By_Month_Aggregate = {
  __typename?: 'dashboard_stage_counts_by_month_aggregate';
  aggregate?: Maybe<Dashboard_Stage_Counts_By_Month_Aggregate_Fields>;
  nodes: Array<Dashboard_Stage_Counts_By_Month>;
};

/** aggregate fields of "dashboard_stage_counts_by_month" */
export type Dashboard_Stage_Counts_By_Month_Aggregate_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_aggregate_fields';
  avg?: Maybe<Dashboard_Stage_Counts_By_Month_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Stage_Counts_By_Month_Max_Fields>;
  min?: Maybe<Dashboard_Stage_Counts_By_Month_Min_Fields>;
  stddev?: Maybe<Dashboard_Stage_Counts_By_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Stage_Counts_By_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Stage_Counts_By_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Stage_Counts_By_Month_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Stage_Counts_By_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Stage_Counts_By_Month_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Stage_Counts_By_Month_Variance_Fields>;
};


/** aggregate fields of "dashboard_stage_counts_by_month" */
export type Dashboard_Stage_Counts_By_Month_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Stage_Counts_By_Month_Avg_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_stage_counts_by_month". All fields are combined with a logical 'AND'. */
export type Dashboard_Stage_Counts_By_Month_Bool_Exp = {
  ToStage?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Stage_Counts_By_Month_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Bool_Exp>>;
  bucket?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  rm_name?: InputMaybe<String_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Stage_Counts_By_Month_Max_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_max_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Dashboard_Stage_Counts_By_Month_Min_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_min_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "dashboard_stage_counts_by_month". */
export type Dashboard_Stage_Counts_By_Month_Order_By = {
  ToStage?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  rm_name?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_stage_counts_by_month" */
export enum Dashboard_Stage_Counts_By_Month_Select_Column {
  /** column name */
  ToStage = 'ToStage',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  RmName = 'rm_name',
  /** column name */
  ToStageId = 'to_stage_id'
}

/** aggregate stddev on columns */
export type Dashboard_Stage_Counts_By_Month_Stddev_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Stage_Counts_By_Month_Stddev_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Stage_Counts_By_Month_Stddev_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_stage_counts_by_month" */
export type Dashboard_Stage_Counts_By_Month_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Stage_Counts_By_Month_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Stage_Counts_By_Month_Stream_Cursor_Value_Input = {
  ToStage?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  rm_name?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dashboard_Stage_Counts_By_Month_Sum_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Stage_Counts_By_Month_Var_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Stage_Counts_By_Month_Var_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Stage_Counts_By_Month_Variance_Fields = {
  __typename?: 'dashboard_stage_counts_by_month_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_stage_counts_by_week" */
export type Dashboard_Stage_Counts_By_Week = {
  __typename?: 'dashboard_stage_counts_by_week';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamp']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "dashboard_stage_counts_by_week" */
export type Dashboard_Stage_Counts_By_Week_Aggregate = {
  __typename?: 'dashboard_stage_counts_by_week_aggregate';
  aggregate?: Maybe<Dashboard_Stage_Counts_By_Week_Aggregate_Fields>;
  nodes: Array<Dashboard_Stage_Counts_By_Week>;
};

/** aggregate fields of "dashboard_stage_counts_by_week" */
export type Dashboard_Stage_Counts_By_Week_Aggregate_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_aggregate_fields';
  avg?: Maybe<Dashboard_Stage_Counts_By_Week_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Stage_Counts_By_Week_Max_Fields>;
  min?: Maybe<Dashboard_Stage_Counts_By_Week_Min_Fields>;
  stddev?: Maybe<Dashboard_Stage_Counts_By_Week_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Stage_Counts_By_Week_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Stage_Counts_By_Week_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Stage_Counts_By_Week_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Stage_Counts_By_Week_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Stage_Counts_By_Week_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Stage_Counts_By_Week_Variance_Fields>;
};


/** aggregate fields of "dashboard_stage_counts_by_week" */
export type Dashboard_Stage_Counts_By_Week_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Stage_Counts_By_Week_Avg_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_stage_counts_by_week". All fields are combined with a logical 'AND'. */
export type Dashboard_Stage_Counts_By_Week_Bool_Exp = {
  ToStage?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Stage_Counts_By_Week_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Bool_Exp>>;
  bucket?: InputMaybe<Timestamp_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  rm_name?: InputMaybe<String_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Stage_Counts_By_Week_Max_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_max_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamp']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Dashboard_Stage_Counts_By_Week_Min_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_min_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamp']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "dashboard_stage_counts_by_week". */
export type Dashboard_Stage_Counts_By_Week_Order_By = {
  ToStage?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  rm_name?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_stage_counts_by_week" */
export enum Dashboard_Stage_Counts_By_Week_Select_Column {
  /** column name */
  ToStage = 'ToStage',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  RmName = 'rm_name',
  /** column name */
  ToStageId = 'to_stage_id'
}

/** aggregate stddev on columns */
export type Dashboard_Stage_Counts_By_Week_Stddev_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Stage_Counts_By_Week_Stddev_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Stage_Counts_By_Week_Stddev_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_stage_counts_by_week" */
export type Dashboard_Stage_Counts_By_Week_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Stage_Counts_By_Week_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Stage_Counts_By_Week_Stream_Cursor_Value_Input = {
  ToStage?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['timestamp']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  rm_name?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dashboard_Stage_Counts_By_Week_Sum_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Stage_Counts_By_Week_Var_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Stage_Counts_By_Week_Var_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Stage_Counts_By_Week_Variance_Fields = {
  __typename?: 'dashboard_stage_counts_by_week_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_stage_counts_by_year" */
export type Dashboard_Stage_Counts_By_Year = {
  __typename?: 'dashboard_stage_counts_by_year';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "dashboard_stage_counts_by_year" */
export type Dashboard_Stage_Counts_By_Year_Aggregate = {
  __typename?: 'dashboard_stage_counts_by_year_aggregate';
  aggregate?: Maybe<Dashboard_Stage_Counts_By_Year_Aggregate_Fields>;
  nodes: Array<Dashboard_Stage_Counts_By_Year>;
};

/** aggregate fields of "dashboard_stage_counts_by_year" */
export type Dashboard_Stage_Counts_By_Year_Aggregate_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_aggregate_fields';
  avg?: Maybe<Dashboard_Stage_Counts_By_Year_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Stage_Counts_By_Year_Max_Fields>;
  min?: Maybe<Dashboard_Stage_Counts_By_Year_Min_Fields>;
  stddev?: Maybe<Dashboard_Stage_Counts_By_Year_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Stage_Counts_By_Year_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Stage_Counts_By_Year_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Stage_Counts_By_Year_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Stage_Counts_By_Year_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Stage_Counts_By_Year_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Stage_Counts_By_Year_Variance_Fields>;
};


/** aggregate fields of "dashboard_stage_counts_by_year" */
export type Dashboard_Stage_Counts_By_Year_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Stage_Counts_By_Year_Avg_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_stage_counts_by_year". All fields are combined with a logical 'AND'. */
export type Dashboard_Stage_Counts_By_Year_Bool_Exp = {
  ToStage?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Stage_Counts_By_Year_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Bool_Exp>>;
  bucket?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  rm_name?: InputMaybe<String_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Stage_Counts_By_Year_Max_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_max_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Dashboard_Stage_Counts_By_Year_Min_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_min_fields';
  ToStage?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "dashboard_stage_counts_by_year". */
export type Dashboard_Stage_Counts_By_Year_Order_By = {
  ToStage?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  rm_name?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_stage_counts_by_year" */
export enum Dashboard_Stage_Counts_By_Year_Select_Column {
  /** column name */
  ToStage = 'ToStage',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  RmName = 'rm_name',
  /** column name */
  ToStageId = 'to_stage_id'
}

/** aggregate stddev on columns */
export type Dashboard_Stage_Counts_By_Year_Stddev_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Stage_Counts_By_Year_Stddev_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Stage_Counts_By_Year_Stddev_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_stage_counts_by_year" */
export type Dashboard_Stage_Counts_By_Year_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Stage_Counts_By_Year_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Stage_Counts_By_Year_Stream_Cursor_Value_Input = {
  ToStage?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  rm_name?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dashboard_Stage_Counts_By_Year_Sum_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Stage_Counts_By_Year_Var_Pop_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Stage_Counts_By_Year_Var_Samp_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Stage_Counts_By_Year_Variance_Fields = {
  __typename?: 'dashboard_stage_counts_by_year_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dashboard_stages_counts" */
export type Dashboard_Stages_Counts = {
  __typename?: 'dashboard_stages_counts';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  stage_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "dashboard_stages_counts" */
export type Dashboard_Stages_Counts_Aggregate = {
  __typename?: 'dashboard_stages_counts_aggregate';
  aggregate?: Maybe<Dashboard_Stages_Counts_Aggregate_Fields>;
  nodes: Array<Dashboard_Stages_Counts>;
};

/** aggregate fields of "dashboard_stages_counts" */
export type Dashboard_Stages_Counts_Aggregate_Fields = {
  __typename?: 'dashboard_stages_counts_aggregate_fields';
  avg?: Maybe<Dashboard_Stages_Counts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dashboard_Stages_Counts_Max_Fields>;
  min?: Maybe<Dashboard_Stages_Counts_Min_Fields>;
  stddev?: Maybe<Dashboard_Stages_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Stages_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Stages_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Stages_Counts_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Stages_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Stages_Counts_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Stages_Counts_Variance_Fields>;
};


/** aggregate fields of "dashboard_stages_counts" */
export type Dashboard_Stages_Counts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dashboard_Stages_Counts_Avg_Fields = {
  __typename?: 'dashboard_stages_counts_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dashboard_stages_counts". All fields are combined with a logical 'AND'. */
export type Dashboard_Stages_Counts_Bool_Exp = {
  _and?: InputMaybe<Array<Dashboard_Stages_Counts_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Stages_Counts_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
  rm_name?: InputMaybe<String_Comparison_Exp>;
  stage_name?: InputMaybe<String_Comparison_Exp>;
  to_stage_id?: InputMaybe<Int_Comparison_Exp>;
};

export type Dashboard_Stages_Counts_For_Date_Range_Args = {
  end_date?: InputMaybe<Scalars['timestamptz']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dashboard_Stages_Counts_Max_Fields = {
  __typename?: 'dashboard_stages_counts_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  stage_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Dashboard_Stages_Counts_Min_Fields = {
  __typename?: 'dashboard_stages_counts_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  rm_name?: Maybe<Scalars['String']>;
  stage_name?: Maybe<Scalars['String']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "dashboard_stages_counts". */
export type Dashboard_Stages_Counts_Order_By = {
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
  rm_name?: InputMaybe<Order_By>;
  stage_name?: InputMaybe<Order_By>;
  to_stage_id?: InputMaybe<Order_By>;
};

/** select columns of table "dashboard_stages_counts" */
export enum Dashboard_Stages_Counts_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  RmId = 'rm_id',
  /** column name */
  RmName = 'rm_name',
  /** column name */
  StageName = 'stage_name',
  /** column name */
  ToStageId = 'to_stage_id'
}

/** aggregate stddev on columns */
export type Dashboard_Stages_Counts_Stddev_Fields = {
  __typename?: 'dashboard_stages_counts_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Stages_Counts_Stddev_Pop_Fields = {
  __typename?: 'dashboard_stages_counts_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Stages_Counts_Stddev_Samp_Fields = {
  __typename?: 'dashboard_stages_counts_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dashboard_stages_counts" */
export type Dashboard_Stages_Counts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Stages_Counts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Stages_Counts_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  rm_id?: InputMaybe<Scalars['Int']>;
  rm_name?: InputMaybe<Scalars['String']>;
  stage_name?: InputMaybe<Scalars['String']>;
  to_stage_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dashboard_Stages_Counts_Sum_Fields = {
  __typename?: 'dashboard_stages_counts_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  rm_id?: Maybe<Scalars['Int']>;
  to_stage_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Dashboard_Stages_Counts_Var_Pop_Fields = {
  __typename?: 'dashboard_stages_counts_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dashboard_Stages_Counts_Var_Samp_Fields = {
  __typename?: 'dashboard_stages_counts_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dashboard_Stages_Counts_Variance_Fields = {
  __typename?: 'dashboard_stages_counts_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
  to_stage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "deleted_ppl_names" */
export type Deleted_Ppl_Names = {
  __typename?: 'deleted_ppl_names';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "deleted_ppl_names" */
export type Deleted_Ppl_Names_Aggregate = {
  __typename?: 'deleted_ppl_names_aggregate';
  aggregate?: Maybe<Deleted_Ppl_Names_Aggregate_Fields>;
  nodes: Array<Deleted_Ppl_Names>;
};

/** aggregate fields of "deleted_ppl_names" */
export type Deleted_Ppl_Names_Aggregate_Fields = {
  __typename?: 'deleted_ppl_names_aggregate_fields';
  avg?: Maybe<Deleted_Ppl_Names_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deleted_Ppl_Names_Max_Fields>;
  min?: Maybe<Deleted_Ppl_Names_Min_Fields>;
  stddev?: Maybe<Deleted_Ppl_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Deleted_Ppl_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deleted_Ppl_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Deleted_Ppl_Names_Sum_Fields>;
  var_pop?: Maybe<Deleted_Ppl_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Deleted_Ppl_Names_Var_Samp_Fields>;
  variance?: Maybe<Deleted_Ppl_Names_Variance_Fields>;
};


/** aggregate fields of "deleted_ppl_names" */
export type Deleted_Ppl_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deleted_Ppl_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Deleted_Ppl_Names_Avg_Fields = {
  __typename?: 'deleted_ppl_names_avg_fields';
  id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "deleted_ppl_names". All fields are combined with a logical 'AND'. */
export type Deleted_Ppl_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Deleted_Ppl_Names_Bool_Exp>>;
  _not?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Deleted_Ppl_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  name_middle?: InputMaybe<String_Comparison_Exp>;
  type_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "deleted_ppl_names" */
export type Deleted_Ppl_Names_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "deleted_ppl_names" */
export type Deleted_Ppl_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  name_middle?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Deleted_Ppl_Names_Max_Fields = {
  __typename?: 'deleted_ppl_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Deleted_Ppl_Names_Min_Fields = {
  __typename?: 'deleted_ppl_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deleted_ppl_names" */
export type Deleted_Ppl_Names_Mutation_Response = {
  __typename?: 'deleted_ppl_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deleted_Ppl_Names>;
};

/** input type for inserting object relation for remote table "deleted_ppl_names" */
export type Deleted_Ppl_Names_Obj_Rel_Insert_Input = {
  data: Deleted_Ppl_Names_Insert_Input;
};

/** Ordering options when selecting data from "deleted_ppl_names". */
export type Deleted_Ppl_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  name_middle?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "deleted_ppl_names" */
export enum Deleted_Ppl_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "deleted_ppl_names" */
export type Deleted_Ppl_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  name_middle?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Deleted_Ppl_Names_Stddev_Fields = {
  __typename?: 'deleted_ppl_names_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Deleted_Ppl_Names_Stddev_Pop_Fields = {
  __typename?: 'deleted_ppl_names_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Deleted_Ppl_Names_Stddev_Samp_Fields = {
  __typename?: 'deleted_ppl_names_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "deleted_ppl_names" */
export type Deleted_Ppl_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deleted_Ppl_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deleted_Ppl_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  name_middle?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Deleted_Ppl_Names_Sum_Fields = {
  __typename?: 'deleted_ppl_names_sum_fields';
  id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

export type Deleted_Ppl_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deleted_Ppl_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deleted_Ppl_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deleted_Ppl_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deleted_Ppl_Names_Var_Pop_Fields = {
  __typename?: 'deleted_ppl_names_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Deleted_Ppl_Names_Var_Samp_Fields = {
  __typename?: 'deleted_ppl_names_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Deleted_Ppl_Names_Variance_Fields = {
  __typename?: 'deleted_ppl_names_variance_fields';
  id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

export type DocuSignSendDocumentOutput = {
  __typename?: 'docuSignSendDocumentOutput';
  message: Scalars['String'];
  status: Scalars['String'];
};

/** columns and relationships of "eeo_count_by_comp_bucket_disability" */
export type Eeo_Count_By_Comp_Bucket_Disability = {
  __typename?: 'eeo_count_by_comp_bucket_disability';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_disability" */
export type Eeo_Count_By_Comp_Bucket_Disability_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_disability_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Disability>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_disability" */
export type Eeo_Count_By_Comp_Bucket_Disability_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_disability" */
export type Eeo_Count_By_Comp_Bucket_Disability_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_disability". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_disability_mat" */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_disability_mat" */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Disability_Mat>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_disability_mat" */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_disability_mat" */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_disability_mat". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_disability_mat". */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_disability_mat" */
export enum Eeo_Count_By_Comp_Bucket_Disability_Mat_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_disability_mat" */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Disability_Mat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Mat_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_mat_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_disability". */
export type Eeo_Count_By_Comp_Bucket_Disability_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_disability" */
export enum Eeo_Count_By_Comp_Bucket_Disability_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_disability" */
export type Eeo_Count_By_Comp_Bucket_Disability_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Disability_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Disability_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Disability_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_disability_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_eeo_category" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_eeo_category" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_eeo_category" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_eeo_category" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_eeo_category". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_eeo_category_mat" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_eeo_category_mat" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_eeo_category_mat" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_eeo_category_mat" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_eeo_category_mat". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_eeo_category_mat". */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_eeo_category_mat" */
export enum Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_eeo_category_mat" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_mat_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_eeo_category". */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_eeo_category" */
export enum Eeo_Count_By_Comp_Bucket_Eeo_Category_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_eeo_category" */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Eeo_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Eeo_Category_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_eeo_category_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_gender" */
export type Eeo_Count_By_Comp_Bucket_Gender = {
  __typename?: 'eeo_count_by_comp_bucket_gender';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_gender" */
export type Eeo_Count_By_Comp_Bucket_Gender_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_gender_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Gender>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_gender" */
export type Eeo_Count_By_Comp_Bucket_Gender_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_gender" */
export type Eeo_Count_By_Comp_Bucket_Gender_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_gender". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_gender_mat" */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_gender_mat" */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Gender_Mat>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_gender_mat" */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_gender_mat" */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_gender_mat". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_gender_mat". */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_gender_mat" */
export enum Eeo_Count_By_Comp_Bucket_Gender_Mat_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_gender_mat" */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Gender_Mat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Mat_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_mat_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_gender". */
export type Eeo_Count_By_Comp_Bucket_Gender_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_gender" */
export enum Eeo_Count_By_Comp_Bucket_Gender_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_gender" */
export type Eeo_Count_By_Comp_Bucket_Gender_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Gender_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Gender_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Gender_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_gender_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_race" */
export type Eeo_Count_By_Comp_Bucket_Race = {
  __typename?: 'eeo_count_by_comp_bucket_race';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_race" */
export type Eeo_Count_By_Comp_Bucket_Race_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_race_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Race>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_race" */
export type Eeo_Count_By_Comp_Bucket_Race_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_race" */
export type Eeo_Count_By_Comp_Bucket_Race_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_race". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Race_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_race_mat" */
export type Eeo_Count_By_Comp_Bucket_Race_Mat = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_race_mat" */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Race_Mat>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_race_mat" */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_race_mat" */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_race_mat". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_race_mat". */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_race_mat" */
export enum Eeo_Count_By_Comp_Bucket_Race_Mat_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_race_mat" */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Race_Mat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Mat_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_mat_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_race". */
export type Eeo_Count_By_Comp_Bucket_Race_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_race" */
export enum Eeo_Count_By_Comp_Bucket_Race_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_race" */
export type Eeo_Count_By_Comp_Bucket_Race_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Race_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Race_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Race_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_race_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_vet_status" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_vet_status" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Vet_Status>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_vet_status" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_vet_status" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_vet_status". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "eeo_count_by_comp_bucket_vet_status_mat" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_count_by_comp_bucket_vet_status_mat" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Aggregate = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat>;
};

/** aggregate fields of "eeo_count_by_comp_bucket_vet_status_mat" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Aggregate_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_comp_bucket_vet_status_mat" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Avg_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_comp_bucket_vet_status_mat". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  dimension?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_vet_status_mat". */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_vet_status_mat" */
export enum Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_vet_status_mat" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_mat_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Max_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Min_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  dimension?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_count_by_comp_bucket_vet_status". */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  dimension?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_comp_bucket_vet_status" */
export enum Eeo_Count_By_Comp_Bucket_Vet_Status_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Stddev_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_comp_bucket_vet_status" */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Comp_Bucket_Vet_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  dimension?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Sum_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Comp_Bucket_Vet_Status_Variance_Fields = {
  __typename?: 'eeo_count_by_comp_bucket_vet_status_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_count_by_race_gender_hisp_by_cat_pivot" */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  is_hispanic_latino?: Maybe<Scalars['Boolean']>;
  labor_category?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_count_by_race_gender_hisp_by_cat_pivot" */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Aggregate = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_aggregate';
  aggregate?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Aggregate_Fields>;
  nodes: Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot>;
};

/** aggregate fields of "eeo_count_by_race_gender_hisp_by_cat_pivot" */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Aggregate_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_aggregate_fields';
  avg?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Max_Fields>;
  min?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Min_Fields>;
  stddev?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Sum_Fields>;
  var_pop?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Variance_Fields>;
};


/** aggregate fields of "eeo_count_by_race_gender_hisp_by_cat_pivot" */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Avg_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_count_by_race_gender_hisp_by_cat_pivot". All fields are combined with a logical 'AND'. */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  is_hispanic_latino?: InputMaybe<Boolean_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Max_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Min_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "eeo_count_by_race_gender_hisp_by_cat_pivot". */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Order_By = {
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  is_hispanic_latino?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_count_by_race_gender_hisp_by_cat_pivot" */
export enum Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Gender = 'gender',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  Race = 'race'
}

/** aggregate stddev on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stddev_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stddev_Pop_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stddev_Samp_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_count_by_race_gender_hisp_by_cat_pivot" */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  gender?: InputMaybe<Scalars['String']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Sum_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Var_Pop_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Var_Samp_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Variance_Fields = {
  __typename?: 'eeo_count_by_race_gender_hisp_by_cat_pivot_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View = {
  __typename?: 'eeo_data_point_status_by_identity_view';
  company_id?: Maybe<Scalars['Int']>;
  disability_status_declined?: Maybe<Scalars['Boolean']>;
  disability_status_provided?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  gender_declined?: Maybe<Scalars['Boolean']>;
  gender_provided?: Maybe<Scalars['Boolean']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_hispanic_latino?: Maybe<Scalars['Boolean']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  race_declined?: Maybe<Scalars['Boolean']>;
  race_provided?: Maybe<Scalars['Boolean']>;
  veteran_status?: Maybe<Scalars['String']>;
  veteran_status_declined?: Maybe<Scalars['Boolean']>;
  veteran_status_provided?: Maybe<Scalars['Boolean']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Aggregate = {
  __typename?: 'eeo_data_point_status_by_identity_view_aggregate';
  aggregate?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Aggregate_Fields>;
  nodes: Array<Eeo_Data_Point_Status_By_Identity_View>;
};

/** aggregate fields of "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Aggregate_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_aggregate_fields';
  avg?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Max_Fields>;
  min?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Min_Fields>;
  stddev?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Sum_Fields>;
  var_pop?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Variance_Fields>;
};


/** aggregate fields of "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Avg_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_data_point_status_by_identity_view". All fields are combined with a logical 'AND'. */
export type Eeo_Data_Point_Status_By_Identity_View_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  disability_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  disability_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  gender_declined?: InputMaybe<Boolean_Comparison_Exp>;
  gender_provided?: InputMaybe<Boolean_Comparison_Exp>;
  has_disability?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_hispanic_latino?: InputMaybe<Boolean_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  personal_email?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
  race_declined?: InputMaybe<Boolean_Comparison_Exp>;
  race_provided?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status?: InputMaybe<String_Comparison_Exp>;
  veteran_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  work_email?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Max_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Min_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Mutation_Response = {
  __typename?: 'eeo_data_point_status_by_identity_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Eeo_Data_Point_Status_By_Identity_View>;
};

/** Ordering options when selecting data from "eeo_data_point_status_by_identity_view". */
export type Eeo_Data_Point_Status_By_Identity_View_Order_By = {
  company_id?: InputMaybe<Order_By>;
  disability_status_declined?: InputMaybe<Order_By>;
  disability_status_provided?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_declined?: InputMaybe<Order_By>;
  gender_provided?: InputMaybe<Order_By>;
  has_disability?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_hispanic_latino?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  personal_email?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
  race_declined?: InputMaybe<Order_By>;
  race_provided?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
  veteran_status_declined?: InputMaybe<Order_By>;
  veteran_status_provided?: InputMaybe<Order_By>;
  work_email?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_data_point_status_by_identity_view" */
export enum Eeo_Data_Point_Status_By_Identity_View_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  DisabilityStatusDeclined = 'disability_status_declined',
  /** column name */
  DisabilityStatusProvided = 'disability_status_provided',
  /** column name */
  Gender = 'gender',
  /** column name */
  GenderDeclined = 'gender_declined',
  /** column name */
  GenderProvided = 'gender_provided',
  /** column name */
  HasDisability = 'has_disability',
  /** column name */
  Id = 'id',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  PersonalEmail = 'personal_email',
  /** column name */
  Race = 'race',
  /** column name */
  RaceDeclined = 'race_declined',
  /** column name */
  RaceProvided = 'race_provided',
  /** column name */
  VeteranStatus = 'veteran_status',
  /** column name */
  VeteranStatusDeclined = 'veteran_status_declined',
  /** column name */
  VeteranStatusProvided = 'veteran_status_provided',
  /** column name */
  WorkEmail = 'work_email'
}

/** input type for updating data in table "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Stddev_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Stddev_Pop_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Stddev_Samp_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_data_point_status_by_identity_view" */
export type Eeo_Data_Point_Status_By_Identity_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Data_Point_Status_By_Identity_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Data_Point_Status_By_Identity_View_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Sum_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Eeo_Data_Point_Status_By_Identity_View_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Eeo_Data_Point_Status_By_Identity_View_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Var_Pop_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Var_Samp_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Data_Point_Status_By_Identity_View_Variance_Fields = {
  __typename?: 'eeo_data_point_status_by_identity_view_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view';
  company_id?: Maybe<Scalars['Int']>;
  disability_status_declined?: Maybe<Scalars['Boolean']>;
  disability_status_provided?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  gender_declined?: Maybe<Scalars['Boolean']>;
  gender_provided?: Maybe<Scalars['Boolean']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_hispanic_latino?: Maybe<Scalars['Boolean']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  race_declined?: Maybe<Scalars['Boolean']>;
  race_provided?: Maybe<Scalars['Boolean']>;
  veteran_status?: Maybe<Scalars['String']>;
  veteran_status_declined?: Maybe<Scalars['Boolean']>;
  veteran_status_provided?: Maybe<Scalars['Boolean']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Aggregate = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_aggregate';
  aggregate?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Aggregate_Fields>;
  nodes: Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View>;
};

/** aggregate fields of "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Aggregate_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_aggregate_fields';
  avg?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Max_Fields>;
  min?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Min_Fields>;
  stddev?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Sum_Fields>;
  var_pop?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Variance_Fields>;
};


/** aggregate fields of "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Avg_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_data_points_unknown_as_other_by_identity_view". All fields are combined with a logical 'AND'. */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  disability_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  disability_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  gender_declined?: InputMaybe<Boolean_Comparison_Exp>;
  gender_provided?: InputMaybe<Boolean_Comparison_Exp>;
  has_disability?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_hispanic_latino?: InputMaybe<Boolean_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  personal_email?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
  race_declined?: InputMaybe<Boolean_Comparison_Exp>;
  race_provided?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status?: InputMaybe<String_Comparison_Exp>;
  veteran_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  work_email?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Max_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Min_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Mutation_Response = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View>;
};

/** Ordering options when selecting data from "eeo_data_points_unknown_as_other_by_identity_view". */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Order_By = {
  company_id?: InputMaybe<Order_By>;
  disability_status_declined?: InputMaybe<Order_By>;
  disability_status_provided?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_declined?: InputMaybe<Order_By>;
  gender_provided?: InputMaybe<Order_By>;
  has_disability?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_hispanic_latino?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  personal_email?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
  race_declined?: InputMaybe<Order_By>;
  race_provided?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
  veteran_status_declined?: InputMaybe<Order_By>;
  veteran_status_provided?: InputMaybe<Order_By>;
  work_email?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_data_points_unknown_as_other_by_identity_view" */
export enum Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  DisabilityStatusDeclined = 'disability_status_declined',
  /** column name */
  DisabilityStatusProvided = 'disability_status_provided',
  /** column name */
  Gender = 'gender',
  /** column name */
  GenderDeclined = 'gender_declined',
  /** column name */
  GenderProvided = 'gender_provided',
  /** column name */
  HasDisability = 'has_disability',
  /** column name */
  Id = 'id',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  PersonalEmail = 'personal_email',
  /** column name */
  Race = 'race',
  /** column name */
  RaceDeclined = 'race_declined',
  /** column name */
  RaceProvided = 'race_provided',
  /** column name */
  VeteranStatus = 'veteran_status',
  /** column name */
  VeteranStatusDeclined = 'veteran_status_declined',
  /** column name */
  VeteranStatusProvided = 'veteran_status_provided',
  /** column name */
  WorkEmail = 'work_email'
}

/** input type for updating data in table "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stddev_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stddev_Pop_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stddev_Samp_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_data_points_unknown_as_other_by_identity_view" */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Sum_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Var_Pop_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Var_Samp_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Variance_Fields = {
  __typename?: 'eeo_data_points_unknown_as_other_by_identity_view_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_labor_categories_comp_buckets" */
export type Eeo_Labor_Categories_Comp_Buckets = {
  __typename?: 'eeo_labor_categories_comp_buckets';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  sort_tag?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "eeo_labor_categories_comp_buckets" */
export type Eeo_Labor_Categories_Comp_Buckets_Aggregate = {
  __typename?: 'eeo_labor_categories_comp_buckets_aggregate';
  aggregate?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Aggregate_Fields>;
  nodes: Array<Eeo_Labor_Categories_Comp_Buckets>;
};

/** aggregate fields of "eeo_labor_categories_comp_buckets" */
export type Eeo_Labor_Categories_Comp_Buckets_Aggregate_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_aggregate_fields';
  avg?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Max_Fields>;
  min?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Min_Fields>;
  stddev?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Sum_Fields>;
  var_pop?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Labor_Categories_Comp_Buckets_Variance_Fields>;
};


/** aggregate fields of "eeo_labor_categories_comp_buckets" */
export type Eeo_Labor_Categories_Comp_Buckets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Avg_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  sort_tag?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_labor_categories_comp_buckets". All fields are combined with a logical 'AND'. */
export type Eeo_Labor_Categories_Comp_Buckets_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  pct_of_total?: InputMaybe<Float8_Comparison_Exp>;
  sort_tag?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Max_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  sort_tag?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Min_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  sort_tag?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "eeo_labor_categories_comp_buckets". */
export type Eeo_Labor_Categories_Comp_Buckets_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  pct_of_total?: InputMaybe<Order_By>;
  sort_tag?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_labor_categories_comp_buckets" */
export enum Eeo_Labor_Categories_Comp_Buckets_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  PctOfTotal = 'pct_of_total',
  /** column name */
  SortTag = 'sort_tag'
}

/** aggregate stddev on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Stddev_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  sort_tag?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Stddev_Pop_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  sort_tag?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Stddev_Samp_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  sort_tag?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_labor_categories_comp_buckets" */
export type Eeo_Labor_Categories_Comp_Buckets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Labor_Categories_Comp_Buckets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Labor_Categories_Comp_Buckets_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  labor_category?: InputMaybe<Scalars['String']>;
  pct_of_total?: InputMaybe<Scalars['float8']>;
  sort_tag?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Sum_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  pct_of_total?: Maybe<Scalars['float8']>;
  sort_tag?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Var_Pop_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  sort_tag?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Var_Samp_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  sort_tag?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Labor_Categories_Comp_Buckets_Variance_Fields = {
  __typename?: 'eeo_labor_categories_comp_buckets_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  pct_of_total?: Maybe<Scalars['Float']>;
  sort_tag?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_pivot_data_by_comp_bucket" */
export type Eeo_Pivot_Data_By_Comp_Bucket = {
  __typename?: 'eeo_pivot_data_by_comp_bucket';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  is_hispanic_latino?: Maybe<Scalars['Boolean']>;
  labor_category?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_pivot_data_by_comp_bucket" */
export type Eeo_Pivot_Data_By_Comp_Bucket_Aggregate = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_aggregate';
  aggregate?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Aggregate_Fields>;
  nodes: Array<Eeo_Pivot_Data_By_Comp_Bucket>;
};

/** aggregate fields of "eeo_pivot_data_by_comp_bucket" */
export type Eeo_Pivot_Data_By_Comp_Bucket_Aggregate_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_aggregate_fields';
  avg?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Max_Fields>;
  min?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Min_Fields>;
  stddev?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Sum_Fields>;
  var_pop?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Pivot_Data_By_Comp_Bucket_Variance_Fields>;
};


/** aggregate fields of "eeo_pivot_data_by_comp_bucket" */
export type Eeo_Pivot_Data_By_Comp_Bucket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Avg_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_pivot_data_by_comp_bucket". All fields are combined with a logical 'AND'. */
export type Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  is_hispanic_latino?: InputMaybe<Boolean_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Max_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Min_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "eeo_pivot_data_by_comp_bucket". */
export type Eeo_Pivot_Data_By_Comp_Bucket_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  is_hispanic_latino?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_pivot_data_by_comp_bucket" */
export enum Eeo_Pivot_Data_By_Comp_Bucket_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Gender = 'gender',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  Race = 'race'
}

/** aggregate stddev on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Stddev_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Stddev_Pop_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Stddev_Samp_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_pivot_data_by_comp_bucket" */
export type Eeo_Pivot_Data_By_Comp_Bucket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Pivot_Data_By_Comp_Bucket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Pivot_Data_By_Comp_Bucket_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  gender?: InputMaybe<Scalars['String']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Sum_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Var_Pop_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Var_Samp_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Pivot_Data_By_Comp_Bucket_Variance_Fields = {
  __typename?: 'eeo_pivot_data_by_comp_bucket_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_status_by_identity" */
export type Eeo_Status_By_Identity = {
  __typename?: 'eeo_status_by_identity';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  company_id?: Maybe<Scalars['Int']>;
  disability_status_declined?: Maybe<Scalars['Boolean']>;
  disability_status_provided?: Maybe<Scalars['Boolean']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  gender?: Maybe<Scalars['String']>;
  gender_declined?: Maybe<Scalars['Boolean']>;
  gender_provided?: Maybe<Scalars['Boolean']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_hispanic_latino?: Maybe<Scalars['Boolean']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  race_declined?: Maybe<Scalars['Boolean']>;
  race_provided?: Maybe<Scalars['Boolean']>;
  veteran_status?: Maybe<Scalars['String']>;
  veteran_status_declined?: Maybe<Scalars['Boolean']>;
  veteran_status_provided?: Maybe<Scalars['Boolean']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Aggregate = {
  __typename?: 'eeo_status_by_identity_aggregate';
  aggregate?: Maybe<Eeo_Status_By_Identity_Aggregate_Fields>;
  nodes: Array<Eeo_Status_By_Identity>;
};

/** aggregate fields of "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Aggregate_Fields = {
  __typename?: 'eeo_status_by_identity_aggregate_fields';
  avg?: Maybe<Eeo_Status_By_Identity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Status_By_Identity_Max_Fields>;
  min?: Maybe<Eeo_Status_By_Identity_Min_Fields>;
  stddev?: Maybe<Eeo_Status_By_Identity_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Status_By_Identity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Status_By_Identity_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Status_By_Identity_Sum_Fields>;
  var_pop?: Maybe<Eeo_Status_By_Identity_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Status_By_Identity_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Status_By_Identity_Variance_Fields>;
};


/** aggregate fields of "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Status_By_Identity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Status_By_Identity_Avg_Fields = {
  __typename?: 'eeo_status_by_identity_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_status_by_identity". All fields are combined with a logical 'AND'. */
export type Eeo_Status_By_Identity_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Eeo_Status_By_Identity_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Status_By_Identity_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Status_By_Identity_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  disability_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  disability_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  eeo_data_points_declined_count?: InputMaybe<Int_Comparison_Exp>;
  eeo_data_points_declined_pct?: InputMaybe<Float8_Comparison_Exp>;
  eeo_data_points_provided_count?: InputMaybe<Int_Comparison_Exp>;
  eeo_data_points_provided_pct?: InputMaybe<Float8_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  gender_declined?: InputMaybe<Boolean_Comparison_Exp>;
  gender_provided?: InputMaybe<Boolean_Comparison_Exp>;
  has_disability?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_hispanic_latino?: InputMaybe<Boolean_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  personal_email?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
  race_declined?: InputMaybe<Boolean_Comparison_Exp>;
  race_provided?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status?: InputMaybe<String_Comparison_Exp>;
  veteran_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  work_email?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  eeo_data_points_declined_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: InputMaybe<Scalars['float8']>;
  eeo_data_points_provided_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Insert_Input = {
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  eeo_data_points_declined_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: InputMaybe<Scalars['float8']>;
  eeo_data_points_provided_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: InputMaybe<Scalars['float8']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Eeo_Status_By_Identity_Max_Fields = {
  __typename?: 'eeo_status_by_identity_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Status_By_Identity_Min_Fields = {
  __typename?: 'eeo_status_by_identity_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Mutation_Response = {
  __typename?: 'eeo_status_by_identity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Eeo_Status_By_Identity>;
};

/** Ordering options when selecting data from "eeo_status_by_identity". */
export type Eeo_Status_By_Identity_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  disability_status_declined?: InputMaybe<Order_By>;
  disability_status_provided?: InputMaybe<Order_By>;
  eeo_data_points_declined_count?: InputMaybe<Order_By>;
  eeo_data_points_declined_pct?: InputMaybe<Order_By>;
  eeo_data_points_provided_count?: InputMaybe<Order_By>;
  eeo_data_points_provided_pct?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_declined?: InputMaybe<Order_By>;
  gender_provided?: InputMaybe<Order_By>;
  has_disability?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_hispanic_latino?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  personal_email?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
  race_declined?: InputMaybe<Order_By>;
  race_provided?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
  veteran_status_declined?: InputMaybe<Order_By>;
  veteran_status_provided?: InputMaybe<Order_By>;
  work_email?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_status_by_identity" */
export enum Eeo_Status_By_Identity_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  DisabilityStatusDeclined = 'disability_status_declined',
  /** column name */
  DisabilityStatusProvided = 'disability_status_provided',
  /** column name */
  EeoDataPointsDeclinedCount = 'eeo_data_points_declined_count',
  /** column name */
  EeoDataPointsDeclinedPct = 'eeo_data_points_declined_pct',
  /** column name */
  EeoDataPointsProvidedCount = 'eeo_data_points_provided_count',
  /** column name */
  EeoDataPointsProvidedPct = 'eeo_data_points_provided_pct',
  /** column name */
  Gender = 'gender',
  /** column name */
  GenderDeclined = 'gender_declined',
  /** column name */
  GenderProvided = 'gender_provided',
  /** column name */
  HasDisability = 'has_disability',
  /** column name */
  Id = 'id',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  PersonalEmail = 'personal_email',
  /** column name */
  Race = 'race',
  /** column name */
  RaceDeclined = 'race_declined',
  /** column name */
  RaceProvided = 'race_provided',
  /** column name */
  VeteranStatus = 'veteran_status',
  /** column name */
  VeteranStatusDeclined = 'veteran_status_declined',
  /** column name */
  VeteranStatusProvided = 'veteran_status_provided',
  /** column name */
  WorkEmail = 'work_email'
}

/** input type for updating data in table "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  eeo_data_points_declined_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: InputMaybe<Scalars['float8']>;
  eeo_data_points_provided_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: InputMaybe<Scalars['float8']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Eeo_Status_By_Identity_Stddev_Fields = {
  __typename?: 'eeo_status_by_identity_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Status_By_Identity_Stddev_Pop_Fields = {
  __typename?: 'eeo_status_by_identity_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Status_By_Identity_Stddev_Samp_Fields = {
  __typename?: 'eeo_status_by_identity_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_status_by_identity" */
export type Eeo_Status_By_Identity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Status_By_Identity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Status_By_Identity_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  eeo_data_points_declined_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: InputMaybe<Scalars['float8']>;
  eeo_data_points_provided_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: InputMaybe<Scalars['float8']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Status_By_Identity_Sum_Fields = {
  __typename?: 'eeo_status_by_identity_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

export type Eeo_Status_By_Identity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Eeo_Status_By_Identity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Eeo_Status_By_Identity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Eeo_Status_By_Identity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Eeo_Status_By_Identity_Var_Pop_Fields = {
  __typename?: 'eeo_status_by_identity_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Status_By_Identity_Var_Samp_Fields = {
  __typename?: 'eeo_status_by_identity_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Status_By_Identity_Variance_Fields = {
  __typename?: 'eeo_status_by_identity_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_status_by_identity_with_compensation_and_bucket" */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['numeric']>;
  disability_status_declined?: Maybe<Scalars['Boolean']>;
  disability_status_provided?: Maybe<Scalars['Boolean']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  gender?: Maybe<Scalars['String']>;
  gender_declined?: Maybe<Scalars['Boolean']>;
  gender_provided?: Maybe<Scalars['Boolean']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_hispanic_latino?: Maybe<Scalars['Boolean']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  race_declined?: Maybe<Scalars['Boolean']>;
  race_provided?: Maybe<Scalars['Boolean']>;
  veteran_status?: Maybe<Scalars['String']>;
  veteran_status_declined?: Maybe<Scalars['Boolean']>;
  veteran_status_provided?: Maybe<Scalars['Boolean']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_status_by_identity_with_compensation_and_bucket" */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Aggregate = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_aggregate';
  aggregate?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Aggregate_Fields>;
  nodes: Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket>;
};

/** aggregate fields of "eeo_status_by_identity_with_compensation_and_bucket" */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Aggregate_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_aggregate_fields';
  avg?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Max_Fields>;
  min?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Min_Fields>;
  stddev?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Sum_Fields>;
  var_pop?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Variance_Fields>;
};


/** aggregate fields of "eeo_status_by_identity_with_compensation_and_bucket" */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Avg_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_status_by_identity_with_compensation_and_bucket". All fields are combined with a logical 'AND'. */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  compensation?: InputMaybe<Numeric_Comparison_Exp>;
  disability_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  disability_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  eeo_data_points_declined_count?: InputMaybe<Int_Comparison_Exp>;
  eeo_data_points_declined_pct?: InputMaybe<Float8_Comparison_Exp>;
  eeo_data_points_provided_count?: InputMaybe<Int_Comparison_Exp>;
  eeo_data_points_provided_pct?: InputMaybe<Float8_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  gender_declined?: InputMaybe<Boolean_Comparison_Exp>;
  gender_provided?: InputMaybe<Boolean_Comparison_Exp>;
  has_disability?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_hispanic_latino?: InputMaybe<Boolean_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  personal_email?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
  race_declined?: InputMaybe<Boolean_Comparison_Exp>;
  race_provided?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status?: InputMaybe<String_Comparison_Exp>;
  veteran_status_declined?: InputMaybe<Boolean_Comparison_Exp>;
  veteran_status_provided?: InputMaybe<Boolean_Comparison_Exp>;
  work_email?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Max_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_max_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['numeric']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Min_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_min_fields';
  bucket?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['numeric']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  gender?: Maybe<Scalars['String']>;
  has_disability?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  labor_category?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  personal_email?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
  work_email?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "eeo_status_by_identity_with_compensation_and_bucket". */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Order_By = {
  bucket?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  compensation?: InputMaybe<Order_By>;
  disability_status_declined?: InputMaybe<Order_By>;
  disability_status_provided?: InputMaybe<Order_By>;
  eeo_data_points_declined_count?: InputMaybe<Order_By>;
  eeo_data_points_declined_pct?: InputMaybe<Order_By>;
  eeo_data_points_provided_count?: InputMaybe<Order_By>;
  eeo_data_points_provided_pct?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_declined?: InputMaybe<Order_By>;
  gender_provided?: InputMaybe<Order_By>;
  has_disability?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_hispanic_latino?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  personal_email?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
  race_declined?: InputMaybe<Order_By>;
  race_provided?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
  veteran_status_declined?: InputMaybe<Order_By>;
  veteran_status_provided?: InputMaybe<Order_By>;
  work_email?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_status_by_identity_with_compensation_and_bucket" */
export enum Eeo_Status_By_Identity_With_Compensation_And_Bucket_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Compensation = 'compensation',
  /** column name */
  DisabilityStatusDeclined = 'disability_status_declined',
  /** column name */
  DisabilityStatusProvided = 'disability_status_provided',
  /** column name */
  EeoDataPointsDeclinedCount = 'eeo_data_points_declined_count',
  /** column name */
  EeoDataPointsDeclinedPct = 'eeo_data_points_declined_pct',
  /** column name */
  EeoDataPointsProvidedCount = 'eeo_data_points_provided_count',
  /** column name */
  EeoDataPointsProvidedPct = 'eeo_data_points_provided_pct',
  /** column name */
  Gender = 'gender',
  /** column name */
  GenderDeclined = 'gender_declined',
  /** column name */
  GenderProvided = 'gender_provided',
  /** column name */
  HasDisability = 'has_disability',
  /** column name */
  Id = 'id',
  /** column name */
  IsHispanicLatino = 'is_hispanic_latino',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  PersonalEmail = 'personal_email',
  /** column name */
  Race = 'race',
  /** column name */
  RaceDeclined = 'race_declined',
  /** column name */
  RaceProvided = 'race_provided',
  /** column name */
  VeteranStatus = 'veteran_status',
  /** column name */
  VeteranStatusDeclined = 'veteran_status_declined',
  /** column name */
  VeteranStatusProvided = 'veteran_status_provided',
  /** column name */
  WorkEmail = 'work_email'
}

/** aggregate stddev on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stddev_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stddev_Pop_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stddev_Samp_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_status_by_identity_with_compensation_and_bucket" */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stream_Cursor_Value_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  compensation?: InputMaybe<Scalars['numeric']>;
  disability_status_declined?: InputMaybe<Scalars['Boolean']>;
  disability_status_provided?: InputMaybe<Scalars['Boolean']>;
  eeo_data_points_declined_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: InputMaybe<Scalars['float8']>;
  eeo_data_points_provided_count?: InputMaybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: InputMaybe<Scalars['float8']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_declined?: InputMaybe<Scalars['Boolean']>;
  gender_provided?: InputMaybe<Scalars['Boolean']>;
  has_disability?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_hispanic_latino?: InputMaybe<Scalars['Boolean']>;
  labor_category?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  personal_email?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  race_declined?: InputMaybe<Scalars['Boolean']>;
  race_provided?: InputMaybe<Scalars['Boolean']>;
  veteran_status?: InputMaybe<Scalars['String']>;
  veteran_status_declined?: InputMaybe<Scalars['Boolean']>;
  veteran_status_provided?: InputMaybe<Scalars['Boolean']>;
  work_email?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Sum_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  compensation?: Maybe<Scalars['numeric']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Int']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['float8']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Int']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Var_Pop_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Var_Samp_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Status_By_Identity_With_Compensation_And_Bucket_Variance_Fields = {
  __typename?: 'eeo_status_by_identity_with_compensation_and_bucket_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_count?: Maybe<Scalars['Float']>;
  eeo_data_points_declined_pct?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_count?: Maybe<Scalars['Float']>;
  eeo_data_points_provided_pct?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_summary_by_gender" */
export type Eeo_Summary_By_Gender = {
  __typename?: 'eeo_summary_by_gender';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "eeo_summary_by_gender" */
export type Eeo_Summary_By_Gender_Aggregate = {
  __typename?: 'eeo_summary_by_gender_aggregate';
  aggregate?: Maybe<Eeo_Summary_By_Gender_Aggregate_Fields>;
  nodes: Array<Eeo_Summary_By_Gender>;
};

/** aggregate fields of "eeo_summary_by_gender" */
export type Eeo_Summary_By_Gender_Aggregate_Fields = {
  __typename?: 'eeo_summary_by_gender_aggregate_fields';
  avg?: Maybe<Eeo_Summary_By_Gender_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Summary_By_Gender_Max_Fields>;
  min?: Maybe<Eeo_Summary_By_Gender_Min_Fields>;
  stddev?: Maybe<Eeo_Summary_By_Gender_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Summary_By_Gender_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Summary_By_Gender_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Summary_By_Gender_Sum_Fields>;
  var_pop?: Maybe<Eeo_Summary_By_Gender_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Summary_By_Gender_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Summary_By_Gender_Variance_Fields>;
};


/** aggregate fields of "eeo_summary_by_gender" */
export type Eeo_Summary_By_Gender_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Summary_By_Gender_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Summary_By_Gender_Avg_Fields = {
  __typename?: 'eeo_summary_by_gender_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_summary_by_gender". All fields are combined with a logical 'AND'. */
export type Eeo_Summary_By_Gender_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Summary_By_Gender_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Summary_By_Gender_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Summary_By_Gender_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  percent_of_total?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Summary_By_Gender_Max_Fields = {
  __typename?: 'eeo_summary_by_gender_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Eeo_Summary_By_Gender_Min_Fields = {
  __typename?: 'eeo_summary_by_gender_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  gender?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "eeo_summary_by_gender". */
export type Eeo_Summary_By_Gender_Order_By = {
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  percent_of_total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_summary_by_gender" */
export enum Eeo_Summary_By_Gender_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  Gender = 'gender',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  PercentOfTotal = 'percent_of_total'
}

/** aggregate stddev on columns */
export type Eeo_Summary_By_Gender_Stddev_Fields = {
  __typename?: 'eeo_summary_by_gender_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Summary_By_Gender_Stddev_Pop_Fields = {
  __typename?: 'eeo_summary_by_gender_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Summary_By_Gender_Stddev_Samp_Fields = {
  __typename?: 'eeo_summary_by_gender_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_summary_by_gender" */
export type Eeo_Summary_By_Gender_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Summary_By_Gender_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Summary_By_Gender_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  gender?: InputMaybe<Scalars['String']>;
  labor_category?: InputMaybe<Scalars['String']>;
  percent_of_total?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Eeo_Summary_By_Gender_Sum_Fields = {
  __typename?: 'eeo_summary_by_gender_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Eeo_Summary_By_Gender_Var_Pop_Fields = {
  __typename?: 'eeo_summary_by_gender_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Summary_By_Gender_Var_Samp_Fields = {
  __typename?: 'eeo_summary_by_gender_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Summary_By_Gender_Variance_Fields = {
  __typename?: 'eeo_summary_by_gender_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_summary_by_has_disability" */
export type Eeo_Summary_By_Has_Disability = {
  __typename?: 'eeo_summary_by_has_disability';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  has_disability?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "eeo_summary_by_has_disability" */
export type Eeo_Summary_By_Has_Disability_Aggregate = {
  __typename?: 'eeo_summary_by_has_disability_aggregate';
  aggregate?: Maybe<Eeo_Summary_By_Has_Disability_Aggregate_Fields>;
  nodes: Array<Eeo_Summary_By_Has_Disability>;
};

/** aggregate fields of "eeo_summary_by_has_disability" */
export type Eeo_Summary_By_Has_Disability_Aggregate_Fields = {
  __typename?: 'eeo_summary_by_has_disability_aggregate_fields';
  avg?: Maybe<Eeo_Summary_By_Has_Disability_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Summary_By_Has_Disability_Max_Fields>;
  min?: Maybe<Eeo_Summary_By_Has_Disability_Min_Fields>;
  stddev?: Maybe<Eeo_Summary_By_Has_Disability_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Summary_By_Has_Disability_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Summary_By_Has_Disability_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Summary_By_Has_Disability_Sum_Fields>;
  var_pop?: Maybe<Eeo_Summary_By_Has_Disability_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Summary_By_Has_Disability_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Summary_By_Has_Disability_Variance_Fields>;
};


/** aggregate fields of "eeo_summary_by_has_disability" */
export type Eeo_Summary_By_Has_Disability_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Summary_By_Has_Disability_Avg_Fields = {
  __typename?: 'eeo_summary_by_has_disability_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_summary_by_has_disability". All fields are combined with a logical 'AND'. */
export type Eeo_Summary_By_Has_Disability_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Summary_By_Has_Disability_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  has_disability?: InputMaybe<String_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  percent_of_total?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Summary_By_Has_Disability_Max_Fields = {
  __typename?: 'eeo_summary_by_has_disability_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  has_disability?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Eeo_Summary_By_Has_Disability_Min_Fields = {
  __typename?: 'eeo_summary_by_has_disability_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  has_disability?: Maybe<Scalars['String']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "eeo_summary_by_has_disability". */
export type Eeo_Summary_By_Has_Disability_Order_By = {
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  has_disability?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  percent_of_total?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_summary_by_has_disability" */
export enum Eeo_Summary_By_Has_Disability_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  HasDisability = 'has_disability',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  PercentOfTotal = 'percent_of_total'
}

/** aggregate stddev on columns */
export type Eeo_Summary_By_Has_Disability_Stddev_Fields = {
  __typename?: 'eeo_summary_by_has_disability_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Summary_By_Has_Disability_Stddev_Pop_Fields = {
  __typename?: 'eeo_summary_by_has_disability_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Summary_By_Has_Disability_Stddev_Samp_Fields = {
  __typename?: 'eeo_summary_by_has_disability_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_summary_by_has_disability" */
export type Eeo_Summary_By_Has_Disability_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Summary_By_Has_Disability_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Summary_By_Has_Disability_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  has_disability?: InputMaybe<Scalars['String']>;
  labor_category?: InputMaybe<Scalars['String']>;
  percent_of_total?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Eeo_Summary_By_Has_Disability_Sum_Fields = {
  __typename?: 'eeo_summary_by_has_disability_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Eeo_Summary_By_Has_Disability_Var_Pop_Fields = {
  __typename?: 'eeo_summary_by_has_disability_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Summary_By_Has_Disability_Var_Samp_Fields = {
  __typename?: 'eeo_summary_by_has_disability_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Summary_By_Has_Disability_Variance_Fields = {
  __typename?: 'eeo_summary_by_has_disability_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_summary_by_race" */
export type Eeo_Summary_By_Race = {
  __typename?: 'eeo_summary_by_race';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
  race?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_summary_by_race" */
export type Eeo_Summary_By_Race_Aggregate = {
  __typename?: 'eeo_summary_by_race_aggregate';
  aggregate?: Maybe<Eeo_Summary_By_Race_Aggregate_Fields>;
  nodes: Array<Eeo_Summary_By_Race>;
};

/** aggregate fields of "eeo_summary_by_race" */
export type Eeo_Summary_By_Race_Aggregate_Fields = {
  __typename?: 'eeo_summary_by_race_aggregate_fields';
  avg?: Maybe<Eeo_Summary_By_Race_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Summary_By_Race_Max_Fields>;
  min?: Maybe<Eeo_Summary_By_Race_Min_Fields>;
  stddev?: Maybe<Eeo_Summary_By_Race_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Summary_By_Race_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Summary_By_Race_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Summary_By_Race_Sum_Fields>;
  var_pop?: Maybe<Eeo_Summary_By_Race_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Summary_By_Race_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Summary_By_Race_Variance_Fields>;
};


/** aggregate fields of "eeo_summary_by_race" */
export type Eeo_Summary_By_Race_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Summary_By_Race_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Summary_By_Race_Avg_Fields = {
  __typename?: 'eeo_summary_by_race_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_summary_by_race". All fields are combined with a logical 'AND'. */
export type Eeo_Summary_By_Race_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Summary_By_Race_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Summary_By_Race_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Summary_By_Race_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  percent_of_total?: InputMaybe<Float8_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Summary_By_Race_Max_Fields = {
  __typename?: 'eeo_summary_by_race_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
  race?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Summary_By_Race_Min_Fields = {
  __typename?: 'eeo_summary_by_race_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
  race?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "eeo_summary_by_race". */
export type Eeo_Summary_By_Race_Order_By = {
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  percent_of_total?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_summary_by_race" */
export enum Eeo_Summary_By_Race_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  PercentOfTotal = 'percent_of_total',
  /** column name */
  Race = 'race'
}

/** aggregate stddev on columns */
export type Eeo_Summary_By_Race_Stddev_Fields = {
  __typename?: 'eeo_summary_by_race_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Summary_By_Race_Stddev_Pop_Fields = {
  __typename?: 'eeo_summary_by_race_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Summary_By_Race_Stddev_Samp_Fields = {
  __typename?: 'eeo_summary_by_race_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_summary_by_race" */
export type Eeo_Summary_By_Race_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Summary_By_Race_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Summary_By_Race_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  labor_category?: InputMaybe<Scalars['String']>;
  percent_of_total?: InputMaybe<Scalars['float8']>;
  race?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Summary_By_Race_Sum_Fields = {
  __typename?: 'eeo_summary_by_race_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Eeo_Summary_By_Race_Var_Pop_Fields = {
  __typename?: 'eeo_summary_by_race_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Summary_By_Race_Var_Samp_Fields = {
  __typename?: 'eeo_summary_by_race_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Summary_By_Race_Variance_Fields = {
  __typename?: 'eeo_summary_by_race_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "eeo_summary_by_veteran_status" */
export type Eeo_Summary_By_Veteran_Status = {
  __typename?: 'eeo_summary_by_veteran_status';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
  veteran_status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "eeo_summary_by_veteran_status" */
export type Eeo_Summary_By_Veteran_Status_Aggregate = {
  __typename?: 'eeo_summary_by_veteran_status_aggregate';
  aggregate?: Maybe<Eeo_Summary_By_Veteran_Status_Aggregate_Fields>;
  nodes: Array<Eeo_Summary_By_Veteran_Status>;
};

/** aggregate fields of "eeo_summary_by_veteran_status" */
export type Eeo_Summary_By_Veteran_Status_Aggregate_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_aggregate_fields';
  avg?: Maybe<Eeo_Summary_By_Veteran_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eeo_Summary_By_Veteran_Status_Max_Fields>;
  min?: Maybe<Eeo_Summary_By_Veteran_Status_Min_Fields>;
  stddev?: Maybe<Eeo_Summary_By_Veteran_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Eeo_Summary_By_Veteran_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eeo_Summary_By_Veteran_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Eeo_Summary_By_Veteran_Status_Sum_Fields>;
  var_pop?: Maybe<Eeo_Summary_By_Veteran_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Eeo_Summary_By_Veteran_Status_Var_Samp_Fields>;
  variance?: Maybe<Eeo_Summary_By_Veteran_Status_Variance_Fields>;
};


/** aggregate fields of "eeo_summary_by_veteran_status" */
export type Eeo_Summary_By_Veteran_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eeo_Summary_By_Veteran_Status_Avg_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eeo_summary_by_veteran_status". All fields are combined with a logical 'AND'. */
export type Eeo_Summary_By_Veteran_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Bool_Exp>>;
  _not?: InputMaybe<Eeo_Summary_By_Veteran_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  labor_category?: InputMaybe<String_Comparison_Exp>;
  percent_of_total?: InputMaybe<Float8_Comparison_Exp>;
  veteran_status?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Eeo_Summary_By_Veteran_Status_Max_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
  veteran_status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Eeo_Summary_By_Veteran_Status_Min_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  labor_category?: Maybe<Scalars['String']>;
  percent_of_total?: Maybe<Scalars['float8']>;
  veteran_status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "eeo_summary_by_veteran_status". */
export type Eeo_Summary_By_Veteran_Status_Order_By = {
  company_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  labor_category?: InputMaybe<Order_By>;
  percent_of_total?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
};

/** select columns of table "eeo_summary_by_veteran_status" */
export enum Eeo_Summary_By_Veteran_Status_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Count = 'count',
  /** column name */
  LaborCategory = 'labor_category',
  /** column name */
  PercentOfTotal = 'percent_of_total',
  /** column name */
  VeteranStatus = 'veteran_status'
}

/** aggregate stddev on columns */
export type Eeo_Summary_By_Veteran_Status_Stddev_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eeo_Summary_By_Veteran_Status_Stddev_Pop_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eeo_Summary_By_Veteran_Status_Stddev_Samp_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eeo_summary_by_veteran_status" */
export type Eeo_Summary_By_Veteran_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eeo_Summary_By_Veteran_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eeo_Summary_By_Veteran_Status_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  labor_category?: InputMaybe<Scalars['String']>;
  percent_of_total?: InputMaybe<Scalars['float8']>;
  veteran_status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Eeo_Summary_By_Veteran_Status_Sum_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  percent_of_total?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Eeo_Summary_By_Veteran_Status_Var_Pop_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eeo_Summary_By_Veteran_Status_Var_Samp_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eeo_Summary_By_Veteran_Status_Variance_Fields = {
  __typename?: 'eeo_summary_by_veteran_status_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  percent_of_total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "email_events" */
export type Email_Events = {
  __typename?: 'email_events';
  attachment_keys?: Maybe<Scalars['json']>;
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  email_created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  email_updated_at?: Maybe<Scalars['timestamptz']>;
  event_dt?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['String']>;
  original_email_id?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsAttachment_KeysArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "email_events" */
export type Email_EventsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "email_events" */
export type Email_Events_Aggregate = {
  __typename?: 'email_events_aggregate';
  aggregate?: Maybe<Email_Events_Aggregate_Fields>;
  nodes: Array<Email_Events>;
};

export type Email_Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Email_Events_Aggregate_Bool_Exp_Count>;
};

export type Email_Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Email_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Email_Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "email_events" */
export type Email_Events_Aggregate_Fields = {
  __typename?: 'email_events_aggregate_fields';
  avg?: Maybe<Email_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Email_Events_Max_Fields>;
  min?: Maybe<Email_Events_Min_Fields>;
  stddev?: Maybe<Email_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Events_Sum_Fields>;
  var_pop?: Maybe<Email_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Events_Var_Samp_Fields>;
  variance?: Maybe<Email_Events_Variance_Fields>;
};


/** aggregate fields of "email_events" */
export type Email_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_events" */
export type Email_Events_Aggregate_Order_By = {
  avg?: InputMaybe<Email_Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Email_Events_Max_Order_By>;
  min?: InputMaybe<Email_Events_Min_Order_By>;
  stddev?: InputMaybe<Email_Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Email_Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Email_Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Email_Events_Sum_Order_By>;
  var_pop?: InputMaybe<Email_Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Email_Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Email_Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "email_events" */
export type Email_Events_Arr_Rel_Insert_Input = {
  data: Array<Email_Events_Insert_Input>;
};

/** aggregate avg on columns */
export type Email_Events_Avg_Fields = {
  __typename?: 'email_events_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "email_events" */
export type Email_Events_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "email_events". All fields are combined with a logical 'AND'. */
export type Email_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Events_Bool_Exp>>;
  _not?: InputMaybe<Email_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Events_Bool_Exp>>;
  attachment_keys?: InputMaybe<Json_Comparison_Exp>;
  bcc?: InputMaybe<String_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  cc?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  email_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_id?: InputMaybe<Int_Comparison_Exp>;
  email_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_dt?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  message_id?: InputMaybe<String_Comparison_Exp>;
  original_email_id?: InputMaybe<Int_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "email_events" */
export type Email_Events_Insert_Input = {
  attachment_keys?: InputMaybe<Scalars['json']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  email_created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  email_updated_at?: InputMaybe<Scalars['timestamptz']>;
  event_dt?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  message_id?: InputMaybe<Scalars['String']>;
  original_email_id?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Events_Max_Fields = {
  __typename?: 'email_events_max_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  email_created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  email_updated_at?: Maybe<Scalars['timestamptz']>;
  event_dt?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['String']>;
  original_email_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_events" */
export type Email_Events_Max_Order_By = {
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  email_created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  email_updated_at?: InputMaybe<Order_By>;
  event_dt?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Events_Min_Fields = {
  __typename?: 'email_events_min_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  email_created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['Int']>;
  email_updated_at?: Maybe<Scalars['timestamptz']>;
  event_dt?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['String']>;
  original_email_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_events" */
export type Email_Events_Min_Order_By = {
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  email_created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  email_updated_at?: InputMaybe<Order_By>;
  event_dt?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "email_events". */
export type Email_Events_Order_By = {
  attachment_keys?: InputMaybe<Order_By>;
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  email_created_at?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  email_updated_at?: InputMaybe<Order_By>;
  event_dt?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** select columns of table "email_events" */
export enum Email_Events_Select_Column {
  /** column name */
  AttachmentKeys = 'attachment_keys',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  Cc = 'cc',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  EmailCreatedAt = 'email_created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  EmailUpdatedAt = 'email_updated_at',
  /** column name */
  EventDt = 'event_dt',
  /** column name */
  EventId = 'event_id',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  OriginalEmailId = 'original_email_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Status = 'status',
  /** column name */
  To = 'to'
}

/** aggregate stddev on columns */
export type Email_Events_Stddev_Fields = {
  __typename?: 'email_events_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "email_events" */
export type Email_Events_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Email_Events_Stddev_Pop_Fields = {
  __typename?: 'email_events_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "email_events" */
export type Email_Events_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Email_Events_Stddev_Samp_Fields = {
  __typename?: 'email_events_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "email_events" */
export type Email_Events_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "email_events" */
export type Email_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Events_Stream_Cursor_Value_Input = {
  attachment_keys?: InputMaybe<Scalars['json']>;
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  email_created_at?: InputMaybe<Scalars['timestamptz']>;
  email_id?: InputMaybe<Scalars['Int']>;
  email_updated_at?: InputMaybe<Scalars['timestamptz']>;
  event_dt?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  message_id?: InputMaybe<Scalars['String']>;
  original_email_id?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Email_Events_Sum_Fields = {
  __typename?: 'email_events_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  original_email_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "email_events" */
export type Email_Events_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Email_Events_Var_Pop_Fields = {
  __typename?: 'email_events_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "email_events" */
export type Email_Events_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Email_Events_Var_Samp_Fields = {
  __typename?: 'email_events_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "email_events" */
export type Email_Events_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Email_Events_Variance_Fields = {
  __typename?: 'email_events_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  original_email_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "email_events" */
export type Email_Events_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  original_email_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "filled_jobs_report" */
export type Filled_Jobs_Report = {
  __typename?: 'filled_jobs_report';
  available_positions?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_name?: Maybe<Scalars['String']>;
  eeo_job_category?: Maybe<Scalars['String']>;
  employment_date?: Maybe<Scalars['timestamptz']>;
  hire_type?: Maybe<Scalars['String']>;
  internal_category?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  number_of_applicants?: Maybe<Scalars['bigint']>;
  position_filled_by?: Maybe<Scalars['String']>;
  position_id?: Maybe<Scalars['Int']>;
  position_name?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "filled_jobs_report" */
export type Filled_Jobs_Report_Aggregate = {
  __typename?: 'filled_jobs_report_aggregate';
  aggregate?: Maybe<Filled_Jobs_Report_Aggregate_Fields>;
  nodes: Array<Filled_Jobs_Report>;
};

/** aggregate fields of "filled_jobs_report" */
export type Filled_Jobs_Report_Aggregate_Fields = {
  __typename?: 'filled_jobs_report_aggregate_fields';
  avg?: Maybe<Filled_Jobs_Report_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Filled_Jobs_Report_Max_Fields>;
  min?: Maybe<Filled_Jobs_Report_Min_Fields>;
  stddev?: Maybe<Filled_Jobs_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Filled_Jobs_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Filled_Jobs_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Filled_Jobs_Report_Sum_Fields>;
  var_pop?: Maybe<Filled_Jobs_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Filled_Jobs_Report_Var_Samp_Fields>;
  variance?: Maybe<Filled_Jobs_Report_Variance_Fields>;
};


/** aggregate fields of "filled_jobs_report" */
export type Filled_Jobs_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Filled_Jobs_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Filled_Jobs_Report_Avg_Fields = {
  __typename?: 'filled_jobs_report_avg_fields';
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  number_of_applicants?: Maybe<Scalars['Float']>;
  position_id?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "filled_jobs_report". All fields are combined with a logical 'AND'. */
export type Filled_Jobs_Report_Bool_Exp = {
  _and?: InputMaybe<Array<Filled_Jobs_Report_Bool_Exp>>;
  _not?: InputMaybe<Filled_Jobs_Report_Bool_Exp>;
  _or?: InputMaybe<Array<Filled_Jobs_Report_Bool_Exp>>;
  available_positions?: InputMaybe<Int_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  contract_name?: InputMaybe<String_Comparison_Exp>;
  eeo_job_category?: InputMaybe<String_Comparison_Exp>;
  employment_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  hire_type?: InputMaybe<String_Comparison_Exp>;
  internal_category?: InputMaybe<String_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_position_number?: InputMaybe<Int_Comparison_Exp>;
  number_of_applicants?: InputMaybe<Bigint_Comparison_Exp>;
  position_filled_by?: InputMaybe<String_Comparison_Exp>;
  position_id?: InputMaybe<Int_Comparison_Exp>;
  position_name?: InputMaybe<String_Comparison_Exp>;
  total_positions?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Filled_Jobs_Report_Max_Fields = {
  __typename?: 'filled_jobs_report_max_fields';
  available_positions?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_name?: Maybe<Scalars['String']>;
  eeo_job_category?: Maybe<Scalars['String']>;
  employment_date?: Maybe<Scalars['timestamptz']>;
  hire_type?: Maybe<Scalars['String']>;
  internal_category?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  number_of_applicants?: Maybe<Scalars['bigint']>;
  position_filled_by?: Maybe<Scalars['String']>;
  position_id?: Maybe<Scalars['Int']>;
  position_name?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Filled_Jobs_Report_Min_Fields = {
  __typename?: 'filled_jobs_report_min_fields';
  available_positions?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_name?: Maybe<Scalars['String']>;
  eeo_job_category?: Maybe<Scalars['String']>;
  employment_date?: Maybe<Scalars['timestamptz']>;
  hire_type?: Maybe<Scalars['String']>;
  internal_category?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  number_of_applicants?: Maybe<Scalars['bigint']>;
  position_filled_by?: Maybe<Scalars['String']>;
  position_id?: Maybe<Scalars['Int']>;
  position_name?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "filled_jobs_report". */
export type Filled_Jobs_Report_Order_By = {
  available_positions?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  contract_name?: InputMaybe<Order_By>;
  eeo_job_category?: InputMaybe<Order_By>;
  employment_date?: InputMaybe<Order_By>;
  hire_type?: InputMaybe<Order_By>;
  internal_category?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  number_of_applicants?: InputMaybe<Order_By>;
  position_filled_by?: InputMaybe<Order_By>;
  position_id?: InputMaybe<Order_By>;
  position_name?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
};

/** select columns of table "filled_jobs_report" */
export enum Filled_Jobs_Report_Select_Column {
  /** column name */
  AvailablePositions = 'available_positions',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  ContractName = 'contract_name',
  /** column name */
  EeoJobCategory = 'eeo_job_category',
  /** column name */
  EmploymentDate = 'employment_date',
  /** column name */
  HireType = 'hire_type',
  /** column name */
  InternalCategory = 'internal_category',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobPositionNumber = 'job_position_number',
  /** column name */
  NumberOfApplicants = 'number_of_applicants',
  /** column name */
  PositionFilledBy = 'position_filled_by',
  /** column name */
  PositionId = 'position_id',
  /** column name */
  PositionName = 'position_name',
  /** column name */
  TotalPositions = 'total_positions'
}

/** aggregate stddev on columns */
export type Filled_Jobs_Report_Stddev_Fields = {
  __typename?: 'filled_jobs_report_stddev_fields';
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  number_of_applicants?: Maybe<Scalars['Float']>;
  position_id?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Filled_Jobs_Report_Stddev_Pop_Fields = {
  __typename?: 'filled_jobs_report_stddev_pop_fields';
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  number_of_applicants?: Maybe<Scalars['Float']>;
  position_id?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Filled_Jobs_Report_Stddev_Samp_Fields = {
  __typename?: 'filled_jobs_report_stddev_samp_fields';
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  number_of_applicants?: Maybe<Scalars['Float']>;
  position_id?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "filled_jobs_report" */
export type Filled_Jobs_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Filled_Jobs_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filled_Jobs_Report_Stream_Cursor_Value_Input = {
  available_positions?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  contract_name?: InputMaybe<Scalars['String']>;
  eeo_job_category?: InputMaybe<Scalars['String']>;
  employment_date?: InputMaybe<Scalars['timestamptz']>;
  hire_type?: InputMaybe<Scalars['String']>;
  internal_category?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_position_number?: InputMaybe<Scalars['Int']>;
  number_of_applicants?: InputMaybe<Scalars['bigint']>;
  position_filled_by?: InputMaybe<Scalars['String']>;
  position_id?: InputMaybe<Scalars['Int']>;
  position_name?: InputMaybe<Scalars['String']>;
  total_positions?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Filled_Jobs_Report_Sum_Fields = {
  __typename?: 'filled_jobs_report_sum_fields';
  available_positions?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  number_of_applicants?: Maybe<Scalars['bigint']>;
  position_id?: Maybe<Scalars['Int']>;
  total_positions?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Filled_Jobs_Report_Var_Pop_Fields = {
  __typename?: 'filled_jobs_report_var_pop_fields';
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  number_of_applicants?: Maybe<Scalars['Float']>;
  position_id?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Filled_Jobs_Report_Var_Samp_Fields = {
  __typename?: 'filled_jobs_report_var_samp_fields';
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  number_of_applicants?: Maybe<Scalars['Float']>;
  position_id?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Filled_Jobs_Report_Variance_Fields = {
  __typename?: 'filled_jobs_report_variance_fields';
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
  number_of_applicants?: Maybe<Scalars['Float']>;
  position_id?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "filled_jobs_subreport" */
export type Filled_Jobs_Subreport = {
  __typename?: 'filled_jobs_subreport';
  /** An object relationship */
  Termination?: Maybe<Terminations>;
  applicant_name?: Maybe<Scalars['String']>;
  applicant_source?: Maybe<Scalars['String']>;
  application_date?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  disability_status?: Maybe<Scalars['String']>;
  employment_start_date?: Maybe<Scalars['date']>;
  employment_termination_id?: Maybe<Scalars['Int']>;
  hire_type?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  rejected_by?: Maybe<Scalars['String']>;
  rejection_date?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "filled_jobs_subreport" */
export type Filled_Jobs_Subreport_Aggregate = {
  __typename?: 'filled_jobs_subreport_aggregate';
  aggregate?: Maybe<Filled_Jobs_Subreport_Aggregate_Fields>;
  nodes: Array<Filled_Jobs_Subreport>;
};

/** aggregate fields of "filled_jobs_subreport" */
export type Filled_Jobs_Subreport_Aggregate_Fields = {
  __typename?: 'filled_jobs_subreport_aggregate_fields';
  avg?: Maybe<Filled_Jobs_Subreport_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Filled_Jobs_Subreport_Max_Fields>;
  min?: Maybe<Filled_Jobs_Subreport_Min_Fields>;
  stddev?: Maybe<Filled_Jobs_Subreport_Stddev_Fields>;
  stddev_pop?: Maybe<Filled_Jobs_Subreport_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Filled_Jobs_Subreport_Stddev_Samp_Fields>;
  sum?: Maybe<Filled_Jobs_Subreport_Sum_Fields>;
  var_pop?: Maybe<Filled_Jobs_Subreport_Var_Pop_Fields>;
  var_samp?: Maybe<Filled_Jobs_Subreport_Var_Samp_Fields>;
  variance?: Maybe<Filled_Jobs_Subreport_Variance_Fields>;
};


/** aggregate fields of "filled_jobs_subreport" */
export type Filled_Jobs_Subreport_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Filled_Jobs_Subreport_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Filled_Jobs_Subreport_Avg_Fields = {
  __typename?: 'filled_jobs_subreport_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  employment_termination_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "filled_jobs_subreport". All fields are combined with a logical 'AND'. */
export type Filled_Jobs_Subreport_Bool_Exp = {
  Termination?: InputMaybe<Terminations_Bool_Exp>;
  _and?: InputMaybe<Array<Filled_Jobs_Subreport_Bool_Exp>>;
  _not?: InputMaybe<Filled_Jobs_Subreport_Bool_Exp>;
  _or?: InputMaybe<Array<Filled_Jobs_Subreport_Bool_Exp>>;
  applicant_name?: InputMaybe<String_Comparison_Exp>;
  applicant_source?: InputMaybe<String_Comparison_Exp>;
  application_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  disability_status?: InputMaybe<String_Comparison_Exp>;
  employment_start_date?: InputMaybe<Date_Comparison_Exp>;
  employment_termination_id?: InputMaybe<Int_Comparison_Exp>;
  hire_type?: InputMaybe<String_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  identity_type_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_position_number?: InputMaybe<Int_Comparison_Exp>;
  race_ethnicity?: InputMaybe<String_Comparison_Exp>;
  rejected_by?: InputMaybe<String_Comparison_Exp>;
  rejection_date?: InputMaybe<Timestamp_Comparison_Exp>;
  rejection_reason?: InputMaybe<String_Comparison_Exp>;
  sex?: InputMaybe<String_Comparison_Exp>;
  veteran_status?: InputMaybe<Boolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type Filled_Jobs_Subreport_Max_Fields = {
  __typename?: 'filled_jobs_subreport_max_fields';
  applicant_name?: Maybe<Scalars['String']>;
  applicant_source?: Maybe<Scalars['String']>;
  application_date?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  disability_status?: Maybe<Scalars['String']>;
  employment_start_date?: Maybe<Scalars['date']>;
  employment_termination_id?: Maybe<Scalars['Int']>;
  hire_type?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  rejected_by?: Maybe<Scalars['String']>;
  rejection_date?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filled_Jobs_Subreport_Min_Fields = {
  __typename?: 'filled_jobs_subreport_min_fields';
  applicant_name?: Maybe<Scalars['String']>;
  applicant_source?: Maybe<Scalars['String']>;
  application_date?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['Int']>;
  disability_status?: Maybe<Scalars['String']>;
  employment_start_date?: Maybe<Scalars['date']>;
  employment_termination_id?: Maybe<Scalars['Int']>;
  hire_type?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  rejected_by?: Maybe<Scalars['String']>;
  rejection_date?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "filled_jobs_subreport". */
export type Filled_Jobs_Subreport_Order_By = {
  Termination?: InputMaybe<Terminations_Order_By>;
  applicant_name?: InputMaybe<Order_By>;
  applicant_source?: InputMaybe<Order_By>;
  application_date?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  disability_status?: InputMaybe<Order_By>;
  employment_start_date?: InputMaybe<Order_By>;
  employment_termination_id?: InputMaybe<Order_By>;
  hire_type?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_type_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_position_number?: InputMaybe<Order_By>;
  race_ethnicity?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  rejection_date?: InputMaybe<Order_By>;
  rejection_reason?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
};

/** select columns of table "filled_jobs_subreport" */
export enum Filled_Jobs_Subreport_Select_Column {
  /** column name */
  ApplicantName = 'applicant_name',
  /** column name */
  ApplicantSource = 'applicant_source',
  /** column name */
  ApplicationDate = 'application_date',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  DisabilityStatus = 'disability_status',
  /** column name */
  EmploymentStartDate = 'employment_start_date',
  /** column name */
  EmploymentTerminationId = 'employment_termination_id',
  /** column name */
  HireType = 'hire_type',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IdentityTypeId = 'identity_type_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobPositionNumber = 'job_position_number',
  /** column name */
  RaceEthnicity = 'race_ethnicity',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  RejectionDate = 'rejection_date',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Sex = 'sex',
  /** column name */
  VeteranStatus = 'veteran_status'
}

/** aggregate stddev on columns */
export type Filled_Jobs_Subreport_Stddev_Fields = {
  __typename?: 'filled_jobs_subreport_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  employment_termination_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Filled_Jobs_Subreport_Stddev_Pop_Fields = {
  __typename?: 'filled_jobs_subreport_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  employment_termination_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Filled_Jobs_Subreport_Stddev_Samp_Fields = {
  __typename?: 'filled_jobs_subreport_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  employment_termination_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "filled_jobs_subreport" */
export type Filled_Jobs_Subreport_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Filled_Jobs_Subreport_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filled_Jobs_Subreport_Stream_Cursor_Value_Input = {
  applicant_name?: InputMaybe<Scalars['String']>;
  applicant_source?: InputMaybe<Scalars['String']>;
  application_date?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['Int']>;
  disability_status?: InputMaybe<Scalars['String']>;
  employment_start_date?: InputMaybe<Scalars['date']>;
  employment_termination_id?: InputMaybe<Scalars['Int']>;
  hire_type?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_type_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_position_number?: InputMaybe<Scalars['Int']>;
  race_ethnicity?: InputMaybe<Scalars['String']>;
  rejected_by?: InputMaybe<Scalars['String']>;
  rejection_date?: InputMaybe<Scalars['timestamp']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  veteran_status?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Filled_Jobs_Subreport_Sum_Fields = {
  __typename?: 'filled_jobs_subreport_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  employment_termination_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_position_number?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Filled_Jobs_Subreport_Var_Pop_Fields = {
  __typename?: 'filled_jobs_subreport_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  employment_termination_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Filled_Jobs_Subreport_Var_Samp_Fields = {
  __typename?: 'filled_jobs_subreport_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  employment_termination_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Filled_Jobs_Subreport_Variance_Fields = {
  __typename?: 'filled_jobs_subreport_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  employment_termination_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  job_position_number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** columns and relationships of "geography_columns" */
export type Geography_Columns = {
  __typename?: 'geography_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geography_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['name']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geography_columns" */
export type Geography_Columns_Aggregate = {
  __typename?: 'geography_columns_aggregate';
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_Fields = {
  __typename?: 'geography_columns_aggregate_fields';
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
};


/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geography_Columns_Avg_Fields = {
  __typename?: 'geography_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type Geography_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geography_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geography_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<Name_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Geography_Columns_Max_Fields = {
  __typename?: 'geography_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geography_Columns_Min_Fields = {
  __typename?: 'geography_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "geography_columns". */
export type Geography_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geography_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geography_columns" */
export enum Geography_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeographyColumn = 'f_geography_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Geography_Columns_Stddev_Fields = {
  __typename?: 'geography_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geography_Columns_Stddev_Pop_Fields = {
  __typename?: 'geography_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geography_Columns_Stddev_Samp_Fields = {
  __typename?: 'geography_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "geography_columns" */
export type Geography_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geography_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geography_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']>;
  f_geography_column?: InputMaybe<Scalars['name']>;
  f_table_catalog?: InputMaybe<Scalars['name']>;
  f_table_name?: InputMaybe<Scalars['name']>;
  f_table_schema?: InputMaybe<Scalars['name']>;
  srid?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Geography_Columns_Sum_Fields = {
  __typename?: 'geography_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Geography_Columns_Var_Pop_Fields = {
  __typename?: 'geography_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geography_Columns_Var_Samp_Fields = {
  __typename?: 'geography_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geography_Columns_Variance_Fields = {
  __typename?: 'geography_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** columns and relationships of "geometry_columns" */
export type Geometry_Columns = {
  __typename?: 'geometry_columns';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_geometry_column?: Maybe<Scalars['name']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  f_table_name?: Maybe<Scalars['name']>;
  f_table_schema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geometry_columns" */
export type Geometry_Columns_Aggregate = {
  __typename?: 'geometry_columns_aggregate';
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_Fields = {
  __typename?: 'geometry_columns_aggregate_fields';
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
};


/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Geometry_Columns_Avg_Fields = {
  __typename?: 'geometry_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type Geometry_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geometry_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geometry_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<String_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type Geometry_Columns_Inc_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']>;
  srid?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "geometry_columns" */
export type Geometry_Columns_Insert_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']>;
  f_geometry_column?: InputMaybe<Scalars['name']>;
  f_table_catalog?: InputMaybe<Scalars['String']>;
  f_table_name?: InputMaybe<Scalars['name']>;
  f_table_schema?: InputMaybe<Scalars['name']>;
  srid?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Geometry_Columns_Max_Fields = {
  __typename?: 'geometry_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Geometry_Columns_Min_Fields = {
  __typename?: 'geometry_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  f_table_catalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "geometry_columns" */
export type Geometry_Columns_Mutation_Response = {
  __typename?: 'geometry_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type Geometry_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geometry_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geometry_columns" */
export enum Geometry_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeometryColumn = 'f_geometry_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "geometry_columns" */
export type Geometry_Columns_Set_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']>;
  f_geometry_column?: InputMaybe<Scalars['name']>;
  f_table_catalog?: InputMaybe<Scalars['String']>;
  f_table_name?: InputMaybe<Scalars['name']>;
  f_table_schema?: InputMaybe<Scalars['name']>;
  srid?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Geometry_Columns_Stddev_Fields = {
  __typename?: 'geometry_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Geometry_Columns_Stddev_Pop_Fields = {
  __typename?: 'geometry_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Geometry_Columns_Stddev_Samp_Fields = {
  __typename?: 'geometry_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "geometry_columns" */
export type Geometry_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geometry_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geometry_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']>;
  f_geometry_column?: InputMaybe<Scalars['name']>;
  f_table_catalog?: InputMaybe<Scalars['String']>;
  f_table_name?: InputMaybe<Scalars['name']>;
  f_table_schema?: InputMaybe<Scalars['name']>;
  srid?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Geometry_Columns_Sum_Fields = {
  __typename?: 'geometry_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

export type Geometry_Columns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geometry_Columns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geometry_Columns_Var_Pop_Fields = {
  __typename?: 'geometry_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Geometry_Columns_Var_Samp_Fields = {
  __typename?: 'geometry_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Geometry_Columns_Variance_Fields = {
  __typename?: 'geometry_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

export type Global_Search_Function_Contracts_Args = {
  search_text?: InputMaybe<Scalars['String']>;
};

export type Global_Search_Function_Identities_Args = {
  search_text?: InputMaybe<Scalars['String']>;
};

export type Global_Search_Function_Jobs_Args = {
  search_text?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "identities_for_external_reviews" */
export type Identities_For_External_Reviews = {
  __typename?: 'identities_for_external_reviews';
  company_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  resume_id?: Maybe<Scalars['Int']>;
  s3bucketkey?: Maybe<Scalars['String']>;
};

/** aggregated selection of "identities_for_external_reviews" */
export type Identities_For_External_Reviews_Aggregate = {
  __typename?: 'identities_for_external_reviews_aggregate';
  aggregate?: Maybe<Identities_For_External_Reviews_Aggregate_Fields>;
  nodes: Array<Identities_For_External_Reviews>;
};

/** aggregate fields of "identities_for_external_reviews" */
export type Identities_For_External_Reviews_Aggregate_Fields = {
  __typename?: 'identities_for_external_reviews_aggregate_fields';
  avg?: Maybe<Identities_For_External_Reviews_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identities_For_External_Reviews_Max_Fields>;
  min?: Maybe<Identities_For_External_Reviews_Min_Fields>;
  stddev?: Maybe<Identities_For_External_Reviews_Stddev_Fields>;
  stddev_pop?: Maybe<Identities_For_External_Reviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identities_For_External_Reviews_Stddev_Samp_Fields>;
  sum?: Maybe<Identities_For_External_Reviews_Sum_Fields>;
  var_pop?: Maybe<Identities_For_External_Reviews_Var_Pop_Fields>;
  var_samp?: Maybe<Identities_For_External_Reviews_Var_Samp_Fields>;
  variance?: Maybe<Identities_For_External_Reviews_Variance_Fields>;
};


/** aggregate fields of "identities_for_external_reviews" */
export type Identities_For_External_Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Identities_For_External_Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Identities_For_External_Reviews_Avg_Fields = {
  __typename?: 'identities_for_external_reviews_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "identities_for_external_reviews". All fields are combined with a logical 'AND'. */
export type Identities_For_External_Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Identities_For_External_Reviews_Bool_Exp>>;
  _not?: InputMaybe<Identities_For_External_Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Identities_For_External_Reviews_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  html?: InputMaybe<String_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  resume_id?: InputMaybe<Int_Comparison_Exp>;
  s3bucketkey?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Identities_For_External_Reviews_Max_Fields = {
  __typename?: 'identities_for_external_reviews_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  resume_id?: Maybe<Scalars['Int']>;
  s3bucketkey?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Identities_For_External_Reviews_Min_Fields = {
  __typename?: 'identities_for_external_reviews_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  resume_id?: Maybe<Scalars['Int']>;
  s3bucketkey?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "identities_for_external_reviews". */
export type Identities_For_External_Reviews_Order_By = {
  company_id?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
  s3bucketkey?: InputMaybe<Order_By>;
};

/** select columns of table "identities_for_external_reviews" */
export enum Identities_For_External_Reviews_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  Html = 'html',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  S3bucketkey = 's3bucketkey'
}

/** aggregate stddev on columns */
export type Identities_For_External_Reviews_Stddev_Fields = {
  __typename?: 'identities_for_external_reviews_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Identities_For_External_Reviews_Stddev_Pop_Fields = {
  __typename?: 'identities_for_external_reviews_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Identities_For_External_Reviews_Stddev_Samp_Fields = {
  __typename?: 'identities_for_external_reviews_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "identities_for_external_reviews" */
export type Identities_For_External_Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identities_For_External_Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identities_For_External_Reviews_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  filename?: InputMaybe<Scalars['String']>;
  html?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  s3bucketkey?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Identities_For_External_Reviews_Sum_Fields = {
  __typename?: 'identities_for_external_reviews_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Identities_For_External_Reviews_Var_Pop_Fields = {
  __typename?: 'identities_for_external_reviews_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Identities_For_External_Reviews_Var_Samp_Fields = {
  __typename?: 'identities_for_external_reviews_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Identities_For_External_Reviews_Variance_Fields = {
  __typename?: 'identities_for_external_reviews_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "identity_roles_arr" */
export type Identity_Roles_Arr = {
  __typename?: 'identity_roles_arr';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  hr?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  kudos?: Maybe<Scalars['Int']>;
  left_msg?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['Int']>;
  one_on_one?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['String']>>;
  talked_to?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

export type Identity_Roles_Arr_Aggregate = {
  __typename?: 'identity_roles_arr_aggregate';
  aggregate?: Maybe<Identity_Roles_Arr_Aggregate_Fields>;
  nodes: Array<Identity_Roles_Arr>;
};

/** aggregate fields of "identity_roles_arr" */
export type Identity_Roles_Arr_Aggregate_Fields = {
  __typename?: 'identity_roles_arr_aggregate_fields';
  avg?: Maybe<Identity_Roles_Arr_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identity_Roles_Arr_Max_Fields>;
  min?: Maybe<Identity_Roles_Arr_Min_Fields>;
  stddev?: Maybe<Identity_Roles_Arr_Stddev_Fields>;
  stddev_pop?: Maybe<Identity_Roles_Arr_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identity_Roles_Arr_Stddev_Samp_Fields>;
  sum?: Maybe<Identity_Roles_Arr_Sum_Fields>;
  var_pop?: Maybe<Identity_Roles_Arr_Var_Pop_Fields>;
  var_samp?: Maybe<Identity_Roles_Arr_Var_Samp_Fields>;
  variance?: Maybe<Identity_Roles_Arr_Variance_Fields>;
};


/** aggregate fields of "identity_roles_arr" */
export type Identity_Roles_Arr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Identity_Roles_Arr_Avg_Fields = {
  __typename?: 'identity_roles_arr_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "identity_roles_arr". All fields are combined with a logical 'AND'. */
export type Identity_Roles_Arr_Bool_Exp = {
  _and?: InputMaybe<Array<Identity_Roles_Arr_Bool_Exp>>;
  _not?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
  _or?: InputMaybe<Array<Identity_Roles_Arr_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<Int_Comparison_Exp>;
  hr?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  img?: InputMaybe<String_Comparison_Exp>;
  kudos?: InputMaybe<Int_Comparison_Exp>;
  left_msg?: InputMaybe<Int_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_first_last?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Int_Comparison_Exp>;
  one_on_one?: InputMaybe<Int_Comparison_Exp>;
  roles?: InputMaybe<String_Array_Comparison_Exp>;
  talked_to?: InputMaybe<Int_Comparison_Exp>;
  total?: InputMaybe<Int_Comparison_Exp>;
  type_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "identity_roles_arr" */
export type Identity_Roles_Arr_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Int']>;
  hr?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  kudos?: InputMaybe<Scalars['Int']>;
  left_msg?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['Int']>;
  one_on_one?: InputMaybe<Scalars['Int']>;
  talked_to?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "identity_roles_arr" */
export type Identity_Roles_Arr_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Int']>;
  hr?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  kudos?: InputMaybe<Scalars['Int']>;
  left_msg?: InputMaybe<Scalars['Int']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_first_last?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['Int']>;
  one_on_one?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  talked_to?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Identity_Roles_Arr_Max_Fields = {
  __typename?: 'identity_roles_arr_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  hr?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  kudos?: Maybe<Scalars['Int']>;
  left_msg?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['Int']>;
  one_on_one?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['String']>>;
  talked_to?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Identity_Roles_Arr_Min_Fields = {
  __typename?: 'identity_roles_arr_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  hr?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  kudos?: Maybe<Scalars['Int']>;
  left_msg?: Maybe<Scalars['Int']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['Int']>;
  one_on_one?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Scalars['String']>>;
  talked_to?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "identity_roles_arr" */
export type Identity_Roles_Arr_Mutation_Response = {
  __typename?: 'identity_roles_arr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Identity_Roles_Arr>;
};

/** Ordering options when selecting data from "identity_roles_arr". */
export type Identity_Roles_Arr_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  hr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  kudos?: InputMaybe<Order_By>;
  left_msg?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_first_last?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  one_on_one?: InputMaybe<Order_By>;
  roles?: InputMaybe<Order_By>;
  talked_to?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** select columns of table "identity_roles_arr" */
export enum Identity_Roles_Arr_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Email = 'email',
  /** column name */
  Hr = 'hr',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Kudos = 'kudos',
  /** column name */
  LeftMsg = 'left_msg',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameFirstLast = 'name_first_last',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  Notes = 'notes',
  /** column name */
  OneOnOne = 'one_on_one',
  /** column name */
  Roles = 'roles',
  /** column name */
  TalkedTo = 'talked_to',
  /** column name */
  Total = 'total',
  /** column name */
  TypeId = 'type_id'
}

/** input type for updating data in table "identity_roles_arr" */
export type Identity_Roles_Arr_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Int']>;
  hr?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  kudos?: InputMaybe<Scalars['Int']>;
  left_msg?: InputMaybe<Scalars['Int']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_first_last?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['Int']>;
  one_on_one?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  talked_to?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Identity_Roles_Arr_Stddev_Fields = {
  __typename?: 'identity_roles_arr_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Identity_Roles_Arr_Stddev_Pop_Fields = {
  __typename?: 'identity_roles_arr_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Identity_Roles_Arr_Stddev_Samp_Fields = {
  __typename?: 'identity_roles_arr_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "identity_roles_arr" */
export type Identity_Roles_Arr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identity_Roles_Arr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identity_Roles_Arr_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Int']>;
  hr?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  kudos?: InputMaybe<Scalars['Int']>;
  left_msg?: InputMaybe<Scalars['Int']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_first_last?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['Int']>;
  one_on_one?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  talked_to?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Identity_Roles_Arr_Sum_Fields = {
  __typename?: 'identity_roles_arr_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  hr?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kudos?: Maybe<Scalars['Int']>;
  left_msg?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['Int']>;
  one_on_one?: Maybe<Scalars['Int']>;
  talked_to?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

export type Identity_Roles_Arr_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Identity_Roles_Arr_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Identity_Roles_Arr_Set_Input>;
  /** filter the rows which have to be updated */
  where: Identity_Roles_Arr_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Identity_Roles_Arr_Var_Pop_Fields = {
  __typename?: 'identity_roles_arr_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Identity_Roles_Arr_Var_Samp_Fields = {
  __typename?: 'identity_roles_arr_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Identity_Roles_Arr_Variance_Fields = {
  __typename?: 'identity_roles_arr_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "identity_roles_joined" */
export type Identity_Roles_Joined = {
  __typename?: 'identity_roles_joined';
  company_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  role_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "identity_roles_joined" */
export type Identity_Roles_Joined_Aggregate = {
  __typename?: 'identity_roles_joined_aggregate';
  aggregate?: Maybe<Identity_Roles_Joined_Aggregate_Fields>;
  nodes: Array<Identity_Roles_Joined>;
};

/** aggregate fields of "identity_roles_joined" */
export type Identity_Roles_Joined_Aggregate_Fields = {
  __typename?: 'identity_roles_joined_aggregate_fields';
  avg?: Maybe<Identity_Roles_Joined_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identity_Roles_Joined_Max_Fields>;
  min?: Maybe<Identity_Roles_Joined_Min_Fields>;
  stddev?: Maybe<Identity_Roles_Joined_Stddev_Fields>;
  stddev_pop?: Maybe<Identity_Roles_Joined_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identity_Roles_Joined_Stddev_Samp_Fields>;
  sum?: Maybe<Identity_Roles_Joined_Sum_Fields>;
  var_pop?: Maybe<Identity_Roles_Joined_Var_Pop_Fields>;
  var_samp?: Maybe<Identity_Roles_Joined_Var_Samp_Fields>;
  variance?: Maybe<Identity_Roles_Joined_Variance_Fields>;
};


/** aggregate fields of "identity_roles_joined" */
export type Identity_Roles_Joined_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Identity_Roles_Joined_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Identity_Roles_Joined_Avg_Fields = {
  __typename?: 'identity_roles_joined_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "identity_roles_joined". All fields are combined with a logical 'AND'. */
export type Identity_Roles_Joined_Bool_Exp = {
  _and?: InputMaybe<Array<Identity_Roles_Joined_Bool_Exp>>;
  _not?: InputMaybe<Identity_Roles_Joined_Bool_Exp>;
  _or?: InputMaybe<Array<Identity_Roles_Joined_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  img?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  role_name?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Identity_Roles_Joined_Max_Fields = {
  __typename?: 'identity_roles_joined_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  role_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Identity_Roles_Joined_Min_Fields = {
  __typename?: 'identity_roles_joined_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['Int']>;
  role_name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "identity_roles_joined". */
export type Identity_Roles_Joined_Order_By = {
  company_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  role_name?: InputMaybe<Order_By>;
};

/** select columns of table "identity_roles_joined" */
export enum Identity_Roles_Joined_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Img = 'img',
  /** column name */
  Name = 'name',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  RoleName = 'role_name'
}

/** aggregate stddev on columns */
export type Identity_Roles_Joined_Stddev_Fields = {
  __typename?: 'identity_roles_joined_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Identity_Roles_Joined_Stddev_Pop_Fields = {
  __typename?: 'identity_roles_joined_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Identity_Roles_Joined_Stddev_Samp_Fields = {
  __typename?: 'identity_roles_joined_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "identity_roles_joined" */
export type Identity_Roles_Joined_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identity_Roles_Joined_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identity_Roles_Joined_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  img?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['Int']>;
  role_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Identity_Roles_Joined_Sum_Fields = {
  __typename?: 'identity_roles_joined_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Identity_Roles_Joined_Var_Pop_Fields = {
  __typename?: 'identity_roles_joined_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Identity_Roles_Joined_Var_Samp_Fields = {
  __typename?: 'identity_roles_joined_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Identity_Roles_Joined_Variance_Fields = {
  __typename?: 'identity_roles_joined_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "identity_roles_stat" */
export type Identity_Roles_Stat = {
  __typename?: 'identity_roles_stat';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['bigint']>;
  emailstdv?: Maybe<Scalars['numeric']>;
  hr?: Maybe<Scalars['bigint']>;
  hrstdv?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  kudos?: Maybe<Scalars['bigint']>;
  kudosstdv?: Maybe<Scalars['numeric']>;
  left_msg?: Maybe<Scalars['bigint']>;
  left_msgstdv?: Maybe<Scalars['numeric']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['bigint']>;
  notesstdv?: Maybe<Scalars['numeric']>;
  one_on_one?: Maybe<Scalars['bigint']>;
  one_on_onestdv?: Maybe<Scalars['numeric']>;
  roles?: Maybe<Array<Scalars['String']>>;
  talked_to?: Maybe<Scalars['bigint']>;
  talked_tostdv?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['bigint']>;
  totalstdv?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['Int']>;
};

export type Identity_Roles_Stat_Aggregate = {
  __typename?: 'identity_roles_stat_aggregate';
  aggregate?: Maybe<Identity_Roles_Stat_Aggregate_Fields>;
  nodes: Array<Identity_Roles_Stat>;
};

/** aggregate fields of "identity_roles_stat" */
export type Identity_Roles_Stat_Aggregate_Fields = {
  __typename?: 'identity_roles_stat_aggregate_fields';
  avg?: Maybe<Identity_Roles_Stat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identity_Roles_Stat_Max_Fields>;
  min?: Maybe<Identity_Roles_Stat_Min_Fields>;
  stddev?: Maybe<Identity_Roles_Stat_Stddev_Fields>;
  stddev_pop?: Maybe<Identity_Roles_Stat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identity_Roles_Stat_Stddev_Samp_Fields>;
  sum?: Maybe<Identity_Roles_Stat_Sum_Fields>;
  var_pop?: Maybe<Identity_Roles_Stat_Var_Pop_Fields>;
  var_samp?: Maybe<Identity_Roles_Stat_Var_Samp_Fields>;
  variance?: Maybe<Identity_Roles_Stat_Variance_Fields>;
};


/** aggregate fields of "identity_roles_stat" */
export type Identity_Roles_Stat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Identity_Roles_Stat_Avg_Fields = {
  __typename?: 'identity_roles_stat_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  emailstdv?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  hrstdv?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  kudosstdv?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  left_msgstdv?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  notesstdv?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  one_on_onestdv?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  talked_tostdv?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalstdv?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "identity_roles_stat". All fields are combined with a logical 'AND'. */
export type Identity_Roles_Stat_Bool_Exp = {
  _and?: InputMaybe<Array<Identity_Roles_Stat_Bool_Exp>>;
  _not?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
  _or?: InputMaybe<Array<Identity_Roles_Stat_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<Bigint_Comparison_Exp>;
  emailstdv?: InputMaybe<Numeric_Comparison_Exp>;
  hr?: InputMaybe<Bigint_Comparison_Exp>;
  hrstdv?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  kudos?: InputMaybe<Bigint_Comparison_Exp>;
  kudosstdv?: InputMaybe<Numeric_Comparison_Exp>;
  left_msg?: InputMaybe<Bigint_Comparison_Exp>;
  left_msgstdv?: InputMaybe<Numeric_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_first_last?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Bigint_Comparison_Exp>;
  notesstdv?: InputMaybe<Numeric_Comparison_Exp>;
  one_on_one?: InputMaybe<Bigint_Comparison_Exp>;
  one_on_onestdv?: InputMaybe<Numeric_Comparison_Exp>;
  roles?: InputMaybe<String_Array_Comparison_Exp>;
  talked_to?: InputMaybe<Bigint_Comparison_Exp>;
  talked_tostdv?: InputMaybe<Numeric_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
  totalstdv?: InputMaybe<Numeric_Comparison_Exp>;
  type_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "identity_roles_stat" */
export type Identity_Roles_Stat_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['bigint']>;
  emailstdv?: InputMaybe<Scalars['numeric']>;
  hr?: InputMaybe<Scalars['bigint']>;
  hrstdv?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  kudos?: InputMaybe<Scalars['bigint']>;
  kudosstdv?: InputMaybe<Scalars['numeric']>;
  left_msg?: InputMaybe<Scalars['bigint']>;
  left_msgstdv?: InputMaybe<Scalars['numeric']>;
  notes?: InputMaybe<Scalars['bigint']>;
  notesstdv?: InputMaybe<Scalars['numeric']>;
  one_on_one?: InputMaybe<Scalars['bigint']>;
  one_on_onestdv?: InputMaybe<Scalars['numeric']>;
  talked_to?: InputMaybe<Scalars['bigint']>;
  talked_tostdv?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['bigint']>;
  totalstdv?: InputMaybe<Scalars['numeric']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "identity_roles_stat" */
export type Identity_Roles_Stat_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['bigint']>;
  emailstdv?: InputMaybe<Scalars['numeric']>;
  hr?: InputMaybe<Scalars['bigint']>;
  hrstdv?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  kudos?: InputMaybe<Scalars['bigint']>;
  kudosstdv?: InputMaybe<Scalars['numeric']>;
  left_msg?: InputMaybe<Scalars['bigint']>;
  left_msgstdv?: InputMaybe<Scalars['numeric']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_first_last?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['bigint']>;
  notesstdv?: InputMaybe<Scalars['numeric']>;
  one_on_one?: InputMaybe<Scalars['bigint']>;
  one_on_onestdv?: InputMaybe<Scalars['numeric']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  talked_to?: InputMaybe<Scalars['bigint']>;
  talked_tostdv?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['bigint']>;
  totalstdv?: InputMaybe<Scalars['numeric']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Identity_Roles_Stat_Max_Fields = {
  __typename?: 'identity_roles_stat_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['bigint']>;
  emailstdv?: Maybe<Scalars['numeric']>;
  hr?: Maybe<Scalars['bigint']>;
  hrstdv?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  kudos?: Maybe<Scalars['bigint']>;
  kudosstdv?: Maybe<Scalars['numeric']>;
  left_msg?: Maybe<Scalars['bigint']>;
  left_msgstdv?: Maybe<Scalars['numeric']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['bigint']>;
  notesstdv?: Maybe<Scalars['numeric']>;
  one_on_one?: Maybe<Scalars['bigint']>;
  one_on_onestdv?: Maybe<Scalars['numeric']>;
  roles?: Maybe<Array<Scalars['String']>>;
  talked_to?: Maybe<Scalars['bigint']>;
  talked_tostdv?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['bigint']>;
  totalstdv?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Identity_Roles_Stat_Min_Fields = {
  __typename?: 'identity_roles_stat_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['bigint']>;
  emailstdv?: Maybe<Scalars['numeric']>;
  hr?: Maybe<Scalars['bigint']>;
  hrstdv?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  kudos?: Maybe<Scalars['bigint']>;
  kudosstdv?: Maybe<Scalars['numeric']>;
  left_msg?: Maybe<Scalars['bigint']>;
  left_msgstdv?: Maybe<Scalars['numeric']>;
  name_first?: Maybe<Scalars['String']>;
  name_first_last?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['bigint']>;
  notesstdv?: Maybe<Scalars['numeric']>;
  one_on_one?: Maybe<Scalars['bigint']>;
  one_on_onestdv?: Maybe<Scalars['numeric']>;
  roles?: Maybe<Array<Scalars['String']>>;
  talked_to?: Maybe<Scalars['bigint']>;
  talked_tostdv?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['bigint']>;
  totalstdv?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "identity_roles_stat" */
export type Identity_Roles_Stat_Mutation_Response = {
  __typename?: 'identity_roles_stat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Identity_Roles_Stat>;
};

/** Ordering options when selecting data from "identity_roles_stat". */
export type Identity_Roles_Stat_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailstdv?: InputMaybe<Order_By>;
  hr?: InputMaybe<Order_By>;
  hrstdv?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kudos?: InputMaybe<Order_By>;
  kudosstdv?: InputMaybe<Order_By>;
  left_msg?: InputMaybe<Order_By>;
  left_msgstdv?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_first_last?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesstdv?: InputMaybe<Order_By>;
  one_on_one?: InputMaybe<Order_By>;
  one_on_onestdv?: InputMaybe<Order_By>;
  roles?: InputMaybe<Order_By>;
  talked_to?: InputMaybe<Order_By>;
  talked_tostdv?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  totalstdv?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** select columns of table "identity_roles_stat" */
export enum Identity_Roles_Stat_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Email = 'email',
  /** column name */
  Emailstdv = 'emailstdv',
  /** column name */
  Hr = 'hr',
  /** column name */
  Hrstdv = 'hrstdv',
  /** column name */
  Id = 'id',
  /** column name */
  Kudos = 'kudos',
  /** column name */
  Kudosstdv = 'kudosstdv',
  /** column name */
  LeftMsg = 'left_msg',
  /** column name */
  LeftMsgstdv = 'left_msgstdv',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameFirstLast = 'name_first_last',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  Notes = 'notes',
  /** column name */
  Notesstdv = 'notesstdv',
  /** column name */
  OneOnOne = 'one_on_one',
  /** column name */
  OneOnOnestdv = 'one_on_onestdv',
  /** column name */
  Roles = 'roles',
  /** column name */
  TalkedTo = 'talked_to',
  /** column name */
  TalkedTostdv = 'talked_tostdv',
  /** column name */
  Total = 'total',
  /** column name */
  Totalstdv = 'totalstdv',
  /** column name */
  TypeId = 'type_id'
}

/** input type for updating data in table "identity_roles_stat" */
export type Identity_Roles_Stat_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['bigint']>;
  emailstdv?: InputMaybe<Scalars['numeric']>;
  hr?: InputMaybe<Scalars['bigint']>;
  hrstdv?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  kudos?: InputMaybe<Scalars['bigint']>;
  kudosstdv?: InputMaybe<Scalars['numeric']>;
  left_msg?: InputMaybe<Scalars['bigint']>;
  left_msgstdv?: InputMaybe<Scalars['numeric']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_first_last?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['bigint']>;
  notesstdv?: InputMaybe<Scalars['numeric']>;
  one_on_one?: InputMaybe<Scalars['bigint']>;
  one_on_onestdv?: InputMaybe<Scalars['numeric']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  talked_to?: InputMaybe<Scalars['bigint']>;
  talked_tostdv?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['bigint']>;
  totalstdv?: InputMaybe<Scalars['numeric']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Identity_Roles_Stat_Stddev_Fields = {
  __typename?: 'identity_roles_stat_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  emailstdv?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  hrstdv?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  kudosstdv?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  left_msgstdv?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  notesstdv?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  one_on_onestdv?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  talked_tostdv?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalstdv?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Identity_Roles_Stat_Stddev_Pop_Fields = {
  __typename?: 'identity_roles_stat_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  emailstdv?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  hrstdv?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  kudosstdv?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  left_msgstdv?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  notesstdv?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  one_on_onestdv?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  talked_tostdv?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalstdv?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Identity_Roles_Stat_Stddev_Samp_Fields = {
  __typename?: 'identity_roles_stat_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  emailstdv?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  hrstdv?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  kudosstdv?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  left_msgstdv?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  notesstdv?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  one_on_onestdv?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  talked_tostdv?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalstdv?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "identity_roles_stat" */
export type Identity_Roles_Stat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identity_Roles_Stat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identity_Roles_Stat_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['bigint']>;
  emailstdv?: InputMaybe<Scalars['numeric']>;
  hr?: InputMaybe<Scalars['bigint']>;
  hrstdv?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  kudos?: InputMaybe<Scalars['bigint']>;
  kudosstdv?: InputMaybe<Scalars['numeric']>;
  left_msg?: InputMaybe<Scalars['bigint']>;
  left_msgstdv?: InputMaybe<Scalars['numeric']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_first_last?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['bigint']>;
  notesstdv?: InputMaybe<Scalars['numeric']>;
  one_on_one?: InputMaybe<Scalars['bigint']>;
  one_on_onestdv?: InputMaybe<Scalars['numeric']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  talked_to?: InputMaybe<Scalars['bigint']>;
  talked_tostdv?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['bigint']>;
  totalstdv?: InputMaybe<Scalars['numeric']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Identity_Roles_Stat_Sum_Fields = {
  __typename?: 'identity_roles_stat_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['bigint']>;
  emailstdv?: Maybe<Scalars['numeric']>;
  hr?: Maybe<Scalars['bigint']>;
  hrstdv?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  kudos?: Maybe<Scalars['bigint']>;
  kudosstdv?: Maybe<Scalars['numeric']>;
  left_msg?: Maybe<Scalars['bigint']>;
  left_msgstdv?: Maybe<Scalars['numeric']>;
  notes?: Maybe<Scalars['bigint']>;
  notesstdv?: Maybe<Scalars['numeric']>;
  one_on_one?: Maybe<Scalars['bigint']>;
  one_on_onestdv?: Maybe<Scalars['numeric']>;
  talked_to?: Maybe<Scalars['bigint']>;
  talked_tostdv?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['bigint']>;
  totalstdv?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['Int']>;
};

export type Identity_Roles_Stat_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Identity_Roles_Stat_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Identity_Roles_Stat_Set_Input>;
  /** filter the rows which have to be updated */
  where: Identity_Roles_Stat_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Identity_Roles_Stat_Var_Pop_Fields = {
  __typename?: 'identity_roles_stat_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  emailstdv?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  hrstdv?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  kudosstdv?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  left_msgstdv?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  notesstdv?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  one_on_onestdv?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  talked_tostdv?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalstdv?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Identity_Roles_Stat_Var_Samp_Fields = {
  __typename?: 'identity_roles_stat_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  emailstdv?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  hrstdv?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  kudosstdv?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  left_msgstdv?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  notesstdv?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  one_on_onestdv?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  talked_tostdv?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalstdv?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Identity_Roles_Stat_Variance_Fields = {
  __typename?: 'identity_roles_stat_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  emailstdv?: Maybe<Scalars['Float']>;
  hr?: Maybe<Scalars['Float']>;
  hrstdv?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kudos?: Maybe<Scalars['Float']>;
  kudosstdv?: Maybe<Scalars['Float']>;
  left_msg?: Maybe<Scalars['Float']>;
  left_msgstdv?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['Float']>;
  notesstdv?: Maybe<Scalars['Float']>;
  one_on_one?: Maybe<Scalars['Float']>;
  one_on_onestdv?: Maybe<Scalars['Float']>;
  talked_to?: Maybe<Scalars['Float']>;
  talked_tostdv?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalstdv?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "identity_roles_view" */
export type Identity_Roles_View = {
  __typename?: 'identity_roles_view';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "identity_roles_view" */
export type Identity_Roles_View_Aggregate = {
  __typename?: 'identity_roles_view_aggregate';
  aggregate?: Maybe<Identity_Roles_View_Aggregate_Fields>;
  nodes: Array<Identity_Roles_View>;
};

export type Identity_Roles_View_Aggregate_Bool_Exp = {
  count?: InputMaybe<Identity_Roles_View_Aggregate_Bool_Exp_Count>;
};

export type Identity_Roles_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Identity_Roles_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "identity_roles_view" */
export type Identity_Roles_View_Aggregate_Fields = {
  __typename?: 'identity_roles_view_aggregate_fields';
  avg?: Maybe<Identity_Roles_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Identity_Roles_View_Max_Fields>;
  min?: Maybe<Identity_Roles_View_Min_Fields>;
  stddev?: Maybe<Identity_Roles_View_Stddev_Fields>;
  stddev_pop?: Maybe<Identity_Roles_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identity_Roles_View_Stddev_Samp_Fields>;
  sum?: Maybe<Identity_Roles_View_Sum_Fields>;
  var_pop?: Maybe<Identity_Roles_View_Var_Pop_Fields>;
  var_samp?: Maybe<Identity_Roles_View_Var_Samp_Fields>;
  variance?: Maybe<Identity_Roles_View_Variance_Fields>;
};


/** aggregate fields of "identity_roles_view" */
export type Identity_Roles_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "identity_roles_view" */
export type Identity_Roles_View_Aggregate_Order_By = {
  avg?: InputMaybe<Identity_Roles_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Identity_Roles_View_Max_Order_By>;
  min?: InputMaybe<Identity_Roles_View_Min_Order_By>;
  stddev?: InputMaybe<Identity_Roles_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Identity_Roles_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Identity_Roles_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Identity_Roles_View_Sum_Order_By>;
  var_pop?: InputMaybe<Identity_Roles_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Identity_Roles_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Identity_Roles_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "identity_roles_view" */
export type Identity_Roles_View_Arr_Rel_Insert_Input = {
  data: Array<Identity_Roles_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Identity_Roles_View_Avg_Fields = {
  __typename?: 'identity_roles_view_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "identity_roles_view". All fields are combined with a logical 'AND'. */
export type Identity_Roles_View_Bool_Exp = {
  _and?: InputMaybe<Array<Identity_Roles_View_Bool_Exp>>;
  _not?: InputMaybe<Identity_Roles_View_Bool_Exp>;
  _or?: InputMaybe<Array<Identity_Roles_View_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "identity_roles_view" */
export type Identity_Roles_View_Insert_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Identity_Roles_View_Max_Fields = {
  __typename?: 'identity_roles_view_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Identity_Roles_View_Min_Fields = {
  __typename?: 'identity_roles_view_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "identity_roles_view". */
export type Identity_Roles_View_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "identity_roles_view" */
export enum Identity_Roles_View_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Identity_Roles_View_Stddev_Fields = {
  __typename?: 'identity_roles_view_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Identity_Roles_View_Stddev_Pop_Fields = {
  __typename?: 'identity_roles_view_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Identity_Roles_View_Stddev_Samp_Fields = {
  __typename?: 'identity_roles_view_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "identity_roles_view" */
export type Identity_Roles_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identity_Roles_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identity_Roles_View_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  identityId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Identity_Roles_View_Sum_Fields = {
  __typename?: 'identity_roles_view_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identityId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Identity_Roles_View_Var_Pop_Fields = {
  __typename?: 'identity_roles_view_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Identity_Roles_View_Var_Samp_Fields = {
  __typename?: 'identity_roles_view_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Identity_Roles_View_Variance_Fields = {
  __typename?: 'identity_roles_view_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identityId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "identity_roles_view" */
export type Identity_Roles_View_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityId?: InputMaybe<Order_By>;
};

/** columns and relationships of "internal_users" */
export type Internal_Users = {
  __typename?: 'internal_users';
  identityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
};

/** aggregated selection of "internal_users" */
export type Internal_Users_Aggregate = {
  __typename?: 'internal_users_aggregate';
  aggregate?: Maybe<Internal_Users_Aggregate_Fields>;
  nodes: Array<Internal_Users>;
};

/** aggregate fields of "internal_users" */
export type Internal_Users_Aggregate_Fields = {
  __typename?: 'internal_users_aggregate_fields';
  avg?: Maybe<Internal_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Internal_Users_Max_Fields>;
  min?: Maybe<Internal_Users_Min_Fields>;
  stddev?: Maybe<Internal_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Users_Sum_Fields>;
  var_pop?: Maybe<Internal_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Users_Var_Samp_Fields>;
  variance?: Maybe<Internal_Users_Variance_Fields>;
};


/** aggregate fields of "internal_users" */
export type Internal_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Internal_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Internal_Users_Avg_Fields = {
  __typename?: 'internal_users_avg_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "internal_users". All fields are combined with a logical 'AND'. */
export type Internal_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Internal_Users_Bool_Exp>>;
  _not?: InputMaybe<Internal_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Internal_Users_Bool_Exp>>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Internal_Users_Max_Fields = {
  __typename?: 'internal_users_max_fields';
  identityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Internal_Users_Min_Fields = {
  __typename?: 'internal_users_min_fields';
  identityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "internal_users". */
export type Internal_Users_Order_By = {
  identityId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
};

/** select columns of table "internal_users" */
export enum Internal_Users_Select_Column {
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  Name = 'name',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last'
}

/** aggregate stddev on columns */
export type Internal_Users_Stddev_Fields = {
  __typename?: 'internal_users_stddev_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Internal_Users_Stddev_Pop_Fields = {
  __typename?: 'internal_users_stddev_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Internal_Users_Stddev_Samp_Fields = {
  __typename?: 'internal_users_stddev_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "internal_users" */
export type Internal_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Users_Stream_Cursor_Value_Input = {
  identityId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Internal_Users_Sum_Fields = {
  __typename?: 'internal_users_sum_fields';
  identityId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Internal_Users_Var_Pop_Fields = {
  __typename?: 'internal_users_var_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Internal_Users_Var_Samp_Fields = {
  __typename?: 'internal_users_var_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Internal_Users_Variance_Fields = {
  __typename?: 'internal_users_variance_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['interval']>;
  _gt?: InputMaybe<Scalars['interval']>;
  _gte?: InputMaybe<Scalars['interval']>;
  _in?: InputMaybe<Array<Scalars['interval']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['interval']>;
  _lte?: InputMaybe<Scalars['interval']>;
  _neq?: InputMaybe<Scalars['interval']>;
  _nin?: InputMaybe<Array<Scalars['interval']>>;
};

/** columns and relationships of "job_contracts_group_id" */
export type Job_Contracts_Group_Id = {
  __typename?: 'job_contracts_group_id';
  /** An object relationship */
  Contract?: Maybe<Contracts>;
  /** An object relationship */
  Group?: Maybe<Groups>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  contract_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Aggregate = {
  __typename?: 'job_contracts_group_id_aggregate';
  aggregate?: Maybe<Job_Contracts_Group_Id_Aggregate_Fields>;
  nodes: Array<Job_Contracts_Group_Id>;
};

export type Job_Contracts_Group_Id_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Contracts_Group_Id_Aggregate_Bool_Exp_Count>;
};

export type Job_Contracts_Group_Id_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Aggregate_Fields = {
  __typename?: 'job_contracts_group_id_aggregate_fields';
  avg?: Maybe<Job_Contracts_Group_Id_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Job_Contracts_Group_Id_Max_Fields>;
  min?: Maybe<Job_Contracts_Group_Id_Min_Fields>;
  stddev?: Maybe<Job_Contracts_Group_Id_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Contracts_Group_Id_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Contracts_Group_Id_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Contracts_Group_Id_Sum_Fields>;
  var_pop?: Maybe<Job_Contracts_Group_Id_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Contracts_Group_Id_Var_Samp_Fields>;
  variance?: Maybe<Job_Contracts_Group_Id_Variance_Fields>;
};


/** aggregate fields of "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Contracts_Group_Id_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Contracts_Group_Id_Max_Order_By>;
  min?: InputMaybe<Job_Contracts_Group_Id_Min_Order_By>;
  stddev?: InputMaybe<Job_Contracts_Group_Id_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Contracts_Group_Id_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Contracts_Group_Id_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Contracts_Group_Id_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Contracts_Group_Id_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Contracts_Group_Id_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Contracts_Group_Id_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Arr_Rel_Insert_Input = {
  data: Array<Job_Contracts_Group_Id_Insert_Input>;
};

/** aggregate avg on columns */
export type Job_Contracts_Group_Id_Avg_Fields = {
  __typename?: 'job_contracts_group_id_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_contracts_group_id". All fields are combined with a logical 'AND'. */
export type Job_Contracts_Group_Id_Bool_Exp = {
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Group?: InputMaybe<Groups_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<Job_Contracts_Group_Id_Bool_Exp>>;
  _not?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Contracts_Group_Id_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Insert_Input = {
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Job_Contracts_Group_Id_Max_Fields = {
  __typename?: 'job_contracts_group_id_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Contracts_Group_Id_Min_Fields = {
  __typename?: 'job_contracts_group_id_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "job_contracts_group_id". */
export type Job_Contracts_Group_Id_Order_By = {
  Contract?: InputMaybe<Contracts_Order_By>;
  Group?: InputMaybe<Groups_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** select columns of table "job_contracts_group_id" */
export enum Job_Contracts_Group_Id_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  JobId = 'job_id'
}

/** aggregate stddev on columns */
export type Job_Contracts_Group_Id_Stddev_Fields = {
  __typename?: 'job_contracts_group_id_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Contracts_Group_Id_Stddev_Pop_Fields = {
  __typename?: 'job_contracts_group_id_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Contracts_Group_Id_Stddev_Samp_Fields = {
  __typename?: 'job_contracts_group_id_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Contracts_Group_Id_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Contracts_Group_Id_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Job_Contracts_Group_Id_Sum_Fields = {
  __typename?: 'job_contracts_group_id_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Job_Contracts_Group_Id_Var_Pop_Fields = {
  __typename?: 'job_contracts_group_id_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Contracts_Group_Id_Var_Samp_Fields = {
  __typename?: 'job_contracts_group_id_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Contracts_Group_Id_Variance_Fields = {
  __typename?: 'job_contracts_group_id_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_contracts_group_id" */
export type Job_Contracts_Group_Id_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "jobboardsnippetscounts" */
export type Jobboardsnippetscounts = {
  __typename?: 'jobboardsnippetscounts';
  all_cnt?: Maybe<Scalars['bigint']>;
  company_id?: Maybe<Scalars['Int']>;
  cur_cnt?: Maybe<Scalars['bigint']>;
  del_cnt?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "jobboardsnippetscounts" */
export type Jobboardsnippetscounts_Aggregate = {
  __typename?: 'jobboardsnippetscounts_aggregate';
  aggregate?: Maybe<Jobboardsnippetscounts_Aggregate_Fields>;
  nodes: Array<Jobboardsnippetscounts>;
};

/** aggregate fields of "jobboardsnippetscounts" */
export type Jobboardsnippetscounts_Aggregate_Fields = {
  __typename?: 'jobboardsnippetscounts_aggregate_fields';
  avg?: Maybe<Jobboardsnippetscounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Jobboardsnippetscounts_Max_Fields>;
  min?: Maybe<Jobboardsnippetscounts_Min_Fields>;
  stddev?: Maybe<Jobboardsnippetscounts_Stddev_Fields>;
  stddev_pop?: Maybe<Jobboardsnippetscounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jobboardsnippetscounts_Stddev_Samp_Fields>;
  sum?: Maybe<Jobboardsnippetscounts_Sum_Fields>;
  var_pop?: Maybe<Jobboardsnippetscounts_Var_Pop_Fields>;
  var_samp?: Maybe<Jobboardsnippetscounts_Var_Samp_Fields>;
  variance?: Maybe<Jobboardsnippetscounts_Variance_Fields>;
};


/** aggregate fields of "jobboardsnippetscounts" */
export type Jobboardsnippetscounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jobboardsnippetscounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Jobboardsnippetscounts_Avg_Fields = {
  __typename?: 'jobboardsnippetscounts_avg_fields';
  all_cnt?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cur_cnt?: Maybe<Scalars['Float']>;
  del_cnt?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "jobboardsnippetscounts". All fields are combined with a logical 'AND'. */
export type Jobboardsnippetscounts_Bool_Exp = {
  _and?: InputMaybe<Array<Jobboardsnippetscounts_Bool_Exp>>;
  _not?: InputMaybe<Jobboardsnippetscounts_Bool_Exp>;
  _or?: InputMaybe<Array<Jobboardsnippetscounts_Bool_Exp>>;
  all_cnt?: InputMaybe<Bigint_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  cur_cnt?: InputMaybe<Bigint_Comparison_Exp>;
  del_cnt?: InputMaybe<Bigint_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "jobboardsnippetscounts" */
export type Jobboardsnippetscounts_Insert_Input = {
  all_cnt?: InputMaybe<Scalars['bigint']>;
  company_id?: InputMaybe<Scalars['Int']>;
  cur_cnt?: InputMaybe<Scalars['bigint']>;
  del_cnt?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Jobboardsnippetscounts_Max_Fields = {
  __typename?: 'jobboardsnippetscounts_max_fields';
  all_cnt?: Maybe<Scalars['bigint']>;
  company_id?: Maybe<Scalars['Int']>;
  cur_cnt?: Maybe<Scalars['bigint']>;
  del_cnt?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Jobboardsnippetscounts_Min_Fields = {
  __typename?: 'jobboardsnippetscounts_min_fields';
  all_cnt?: Maybe<Scalars['bigint']>;
  company_id?: Maybe<Scalars['Int']>;
  cur_cnt?: Maybe<Scalars['bigint']>;
  del_cnt?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "jobboardsnippetscounts" */
export type Jobboardsnippetscounts_Obj_Rel_Insert_Input = {
  data: Jobboardsnippetscounts_Insert_Input;
};

/** Ordering options when selecting data from "jobboardsnippetscounts". */
export type Jobboardsnippetscounts_Order_By = {
  all_cnt?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cur_cnt?: InputMaybe<Order_By>;
  del_cnt?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** select columns of table "jobboardsnippetscounts" */
export enum Jobboardsnippetscounts_Select_Column {
  /** column name */
  AllCnt = 'all_cnt',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurCnt = 'cur_cnt',
  /** column name */
  DelCnt = 'del_cnt',
  /** column name */
  JobId = 'job_id'
}

/** aggregate stddev on columns */
export type Jobboardsnippetscounts_Stddev_Fields = {
  __typename?: 'jobboardsnippetscounts_stddev_fields';
  all_cnt?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cur_cnt?: Maybe<Scalars['Float']>;
  del_cnt?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Jobboardsnippetscounts_Stddev_Pop_Fields = {
  __typename?: 'jobboardsnippetscounts_stddev_pop_fields';
  all_cnt?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cur_cnt?: Maybe<Scalars['Float']>;
  del_cnt?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Jobboardsnippetscounts_Stddev_Samp_Fields = {
  __typename?: 'jobboardsnippetscounts_stddev_samp_fields';
  all_cnt?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cur_cnt?: Maybe<Scalars['Float']>;
  del_cnt?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "jobboardsnippetscounts" */
export type Jobboardsnippetscounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jobboardsnippetscounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jobboardsnippetscounts_Stream_Cursor_Value_Input = {
  all_cnt?: InputMaybe<Scalars['bigint']>;
  company_id?: InputMaybe<Scalars['Int']>;
  cur_cnt?: InputMaybe<Scalars['bigint']>;
  del_cnt?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Jobboardsnippetscounts_Sum_Fields = {
  __typename?: 'jobboardsnippetscounts_sum_fields';
  all_cnt?: Maybe<Scalars['bigint']>;
  company_id?: Maybe<Scalars['Int']>;
  cur_cnt?: Maybe<Scalars['bigint']>;
  del_cnt?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Jobboardsnippetscounts_Var_Pop_Fields = {
  __typename?: 'jobboardsnippetscounts_var_pop_fields';
  all_cnt?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cur_cnt?: Maybe<Scalars['Float']>;
  del_cnt?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Jobboardsnippetscounts_Var_Samp_Fields = {
  __typename?: 'jobboardsnippetscounts_var_samp_fields';
  all_cnt?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cur_cnt?: Maybe<Scalars['Float']>;
  del_cnt?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Jobboardsnippetscounts_Variance_Fields = {
  __typename?: 'jobboardsnippetscounts_variance_fields';
  all_cnt?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  cur_cnt?: Maybe<Scalars['Float']>;
  del_cnt?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "jobs_available_positions" */
export type Jobs_Available_Positions = {
  __typename?: 'jobs_available_positions';
  _search?: Maybe<Scalars['tsvector']>;
  account_manager_id?: Maybe<Scalars['Int']>;
  additional_details_publishable?: Maybe<Scalars['String']>;
  application_due_date?: Maybe<Scalars['date']>;
  available_positions?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_publishable?: Maybe<Scalars['String']>;
  desired_skills?: Maybe<Scalars['String']>;
  desired_skills_publishable?: Maybe<Scalars['String']>;
  eeo_category?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_original_object?: Maybe<Scalars['jsonb']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  filled_by?: Maybe<Scalars['String']>;
  has_workflow?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  job_reference?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_geometry?: Maybe<Scalars['geometry']>;
  location_nickname?: Maybe<Scalars['String']>;
  max_rate?: Maybe<Scalars['String']>;
  max_salary?: Maybe<Scalars['Int']>;
  next_available_position?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  published_date?: Maybe<Scalars['date']>;
  required_skills?: Maybe<Scalars['String']>;
  required_skills_publishable?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  security_clearance?: Maybe<Scalars['String']>;
  security_clearance_publishable?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  target_rate?: Maybe<Scalars['String']>;
  target_salary?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "jobs_available_positions" */
export type Jobs_Available_PositionsEtl_Original_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "jobs_available_positions" */
export type Jobs_Available_Positions_Aggregate = {
  __typename?: 'jobs_available_positions_aggregate';
  aggregate?: Maybe<Jobs_Available_Positions_Aggregate_Fields>;
  nodes: Array<Jobs_Available_Positions>;
};

/** aggregate fields of "jobs_available_positions" */
export type Jobs_Available_Positions_Aggregate_Fields = {
  __typename?: 'jobs_available_positions_aggregate_fields';
  avg?: Maybe<Jobs_Available_Positions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Jobs_Available_Positions_Max_Fields>;
  min?: Maybe<Jobs_Available_Positions_Min_Fields>;
  stddev?: Maybe<Jobs_Available_Positions_Stddev_Fields>;
  stddev_pop?: Maybe<Jobs_Available_Positions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jobs_Available_Positions_Stddev_Samp_Fields>;
  sum?: Maybe<Jobs_Available_Positions_Sum_Fields>;
  var_pop?: Maybe<Jobs_Available_Positions_Var_Pop_Fields>;
  var_samp?: Maybe<Jobs_Available_Positions_Var_Samp_Fields>;
  variance?: Maybe<Jobs_Available_Positions_Variance_Fields>;
};


/** aggregate fields of "jobs_available_positions" */
export type Jobs_Available_Positions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jobs_Available_Positions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Jobs_Available_Positions_Append_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Jobs_Available_Positions_Avg_Fields = {
  __typename?: 'jobs_available_positions_avg_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  next_available_position?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "jobs_available_positions". All fields are combined with a logical 'AND'. */
export type Jobs_Available_Positions_Bool_Exp = {
  _and?: InputMaybe<Array<Jobs_Available_Positions_Bool_Exp>>;
  _not?: InputMaybe<Jobs_Available_Positions_Bool_Exp>;
  _or?: InputMaybe<Array<Jobs_Available_Positions_Bool_Exp>>;
  _search?: InputMaybe<Tsvector_Comparison_Exp>;
  account_manager_id?: InputMaybe<Int_Comparison_Exp>;
  additional_details_publishable?: InputMaybe<String_Comparison_Exp>;
  application_due_date?: InputMaybe<Date_Comparison_Exp>;
  available_positions?: InputMaybe<Int_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  description_publishable?: InputMaybe<String_Comparison_Exp>;
  desired_skills?: InputMaybe<String_Comparison_Exp>;
  desired_skills_publishable?: InputMaybe<String_Comparison_Exp>;
  eeo_category?: InputMaybe<String_Comparison_Exp>;
  etl_id_token?: InputMaybe<String_Comparison_Exp>;
  etl_original_object?: InputMaybe<Jsonb_Comparison_Exp>;
  etl_source_entity?: InputMaybe<String_Comparison_Exp>;
  filled_by?: InputMaybe<String_Comparison_Exp>;
  has_workflow?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_reference?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  location_geometry?: InputMaybe<Geometry_Comparison_Exp>;
  location_nickname?: InputMaybe<String_Comparison_Exp>;
  max_rate?: InputMaybe<String_Comparison_Exp>;
  max_salary?: InputMaybe<Int_Comparison_Exp>;
  next_available_position?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  program_lead_id?: InputMaybe<Int_Comparison_Exp>;
  published?: InputMaybe<Boolean_Comparison_Exp>;
  published_date?: InputMaybe<Date_Comparison_Exp>;
  required_skills?: InputMaybe<String_Comparison_Exp>;
  required_skills_publishable?: InputMaybe<String_Comparison_Exp>;
  resource_manager_id?: InputMaybe<Int_Comparison_Exp>;
  security_clearance?: InputMaybe<String_Comparison_Exp>;
  security_clearance_publishable?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  target_rate?: InputMaybe<String_Comparison_Exp>;
  target_salary?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  total_positions?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Jobs_Available_Positions_Delete_At_Path_Input = {
  etl_original_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Jobs_Available_Positions_Delete_Elem_Input = {
  etl_original_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Jobs_Available_Positions_Delete_Key_Input = {
  etl_original_object?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "jobs_available_positions" */
export type Jobs_Available_Positions_Inc_Input = {
  account_manager_id?: InputMaybe<Scalars['Int']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  next_available_position?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  total_positions?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "jobs_available_positions" */
export type Jobs_Available_Positions_Insert_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  additional_details_publishable?: InputMaybe<Scalars['String']>;
  application_due_date?: InputMaybe<Scalars['date']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_publishable?: InputMaybe<Scalars['String']>;
  desired_skills?: InputMaybe<Scalars['String']>;
  desired_skills_publishable?: InputMaybe<Scalars['String']>;
  eeo_category?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  filled_by?: InputMaybe<Scalars['String']>;
  has_workflow?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  job_reference?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  location_nickname?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['String']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  next_available_position?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_date?: InputMaybe<Scalars['date']>;
  required_skills?: InputMaybe<Scalars['String']>;
  required_skills_publishable?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  security_clearance?: InputMaybe<Scalars['String']>;
  security_clearance_publishable?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  target_rate?: InputMaybe<Scalars['String']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  total_positions?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Jobs_Available_Positions_Max_Fields = {
  __typename?: 'jobs_available_positions_max_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  additional_details_publishable?: Maybe<Scalars['String']>;
  application_due_date?: Maybe<Scalars['date']>;
  available_positions?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_publishable?: Maybe<Scalars['String']>;
  desired_skills?: Maybe<Scalars['String']>;
  desired_skills_publishable?: Maybe<Scalars['String']>;
  eeo_category?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  filled_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_reference?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_nickname?: Maybe<Scalars['String']>;
  max_rate?: Maybe<Scalars['String']>;
  max_salary?: Maybe<Scalars['Int']>;
  next_available_position?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  published_date?: Maybe<Scalars['date']>;
  required_skills?: Maybe<Scalars['String']>;
  required_skills_publishable?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  security_clearance?: Maybe<Scalars['String']>;
  security_clearance_publishable?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  target_rate?: Maybe<Scalars['String']>;
  target_salary?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Jobs_Available_Positions_Min_Fields = {
  __typename?: 'jobs_available_positions_min_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  additional_details_publishable?: Maybe<Scalars['String']>;
  application_due_date?: Maybe<Scalars['date']>;
  available_positions?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_publishable?: Maybe<Scalars['String']>;
  desired_skills?: Maybe<Scalars['String']>;
  desired_skills_publishable?: Maybe<Scalars['String']>;
  eeo_category?: Maybe<Scalars['String']>;
  etl_id_token?: Maybe<Scalars['String']>;
  etl_source_entity?: Maybe<Scalars['String']>;
  filled_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_reference?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_nickname?: Maybe<Scalars['String']>;
  max_rate?: Maybe<Scalars['String']>;
  max_salary?: Maybe<Scalars['Int']>;
  next_available_position?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  published_date?: Maybe<Scalars['date']>;
  required_skills?: Maybe<Scalars['String']>;
  required_skills_publishable?: Maybe<Scalars['String']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  security_clearance?: Maybe<Scalars['String']>;
  security_clearance_publishable?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  target_rate?: Maybe<Scalars['String']>;
  target_salary?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_positions?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "jobs_available_positions" */
export type Jobs_Available_Positions_Mutation_Response = {
  __typename?: 'jobs_available_positions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Jobs_Available_Positions>;
};

/** input type for inserting object relation for remote table "jobs_available_positions" */
export type Jobs_Available_Positions_Obj_Rel_Insert_Input = {
  data: Jobs_Available_Positions_Insert_Input;
};

/** Ordering options when selecting data from "jobs_available_positions". */
export type Jobs_Available_Positions_Order_By = {
  _search?: InputMaybe<Order_By>;
  account_manager_id?: InputMaybe<Order_By>;
  additional_details_publishable?: InputMaybe<Order_By>;
  application_due_date?: InputMaybe<Order_By>;
  available_positions?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  description_publishable?: InputMaybe<Order_By>;
  desired_skills?: InputMaybe<Order_By>;
  desired_skills_publishable?: InputMaybe<Order_By>;
  eeo_category?: InputMaybe<Order_By>;
  etl_id_token?: InputMaybe<Order_By>;
  etl_original_object?: InputMaybe<Order_By>;
  etl_source_entity?: InputMaybe<Order_By>;
  filled_by?: InputMaybe<Order_By>;
  has_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_reference?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  location_geometry?: InputMaybe<Order_By>;
  location_nickname?: InputMaybe<Order_By>;
  max_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  next_available_position?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  program_lead_id?: InputMaybe<Order_By>;
  published?: InputMaybe<Order_By>;
  published_date?: InputMaybe<Order_By>;
  required_skills?: InputMaybe<Order_By>;
  required_skills_publishable?: InputMaybe<Order_By>;
  resource_manager_id?: InputMaybe<Order_By>;
  security_clearance?: InputMaybe<Order_By>;
  security_clearance_publishable?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  target_rate?: InputMaybe<Order_By>;
  target_salary?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_positions?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Jobs_Available_Positions_Prepend_Input = {
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "jobs_available_positions" */
export enum Jobs_Available_Positions_Select_Column {
  /** column name */
  Search = '_search',
  /** column name */
  AccountManagerId = 'account_manager_id',
  /** column name */
  AdditionalDetailsPublishable = 'additional_details_publishable',
  /** column name */
  ApplicationDueDate = 'application_due_date',
  /** column name */
  AvailablePositions = 'available_positions',
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionPublishable = 'description_publishable',
  /** column name */
  DesiredSkills = 'desired_skills',
  /** column name */
  DesiredSkillsPublishable = 'desired_skills_publishable',
  /** column name */
  EeoCategory = 'eeo_category',
  /** column name */
  EtlIdToken = 'etl_id_token',
  /** column name */
  EtlOriginalObject = 'etl_original_object',
  /** column name */
  EtlSourceEntity = 'etl_source_entity',
  /** column name */
  FilledBy = 'filled_by',
  /** column name */
  HasWorkflow = 'has_workflow',
  /** column name */
  Id = 'id',
  /** column name */
  JobReference = 'job_reference',
  /** column name */
  Location = 'location',
  /** column name */
  LocationGeometry = 'location_geometry',
  /** column name */
  LocationNickname = 'location_nickname',
  /** column name */
  MaxRate = 'max_rate',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  NextAvailablePosition = 'next_available_position',
  /** column name */
  Order = 'order',
  /** column name */
  ProgramLeadId = 'program_lead_id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  RequiredSkills = 'required_skills',
  /** column name */
  RequiredSkillsPublishable = 'required_skills_publishable',
  /** column name */
  ResourceManagerId = 'resource_manager_id',
  /** column name */
  SecurityClearance = 'security_clearance',
  /** column name */
  SecurityClearancePublishable = 'security_clearance_publishable',
  /** column name */
  Status = 'status',
  /** column name */
  TargetRate = 'target_rate',
  /** column name */
  TargetSalary = 'target_salary',
  /** column name */
  Title = 'title',
  /** column name */
  TotalPositions = 'total_positions',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "jobs_available_positions" */
export type Jobs_Available_Positions_Set_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  additional_details_publishable?: InputMaybe<Scalars['String']>;
  application_due_date?: InputMaybe<Scalars['date']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_publishable?: InputMaybe<Scalars['String']>;
  desired_skills?: InputMaybe<Scalars['String']>;
  desired_skills_publishable?: InputMaybe<Scalars['String']>;
  eeo_category?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  filled_by?: InputMaybe<Scalars['String']>;
  has_workflow?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  job_reference?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  location_nickname?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['String']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  next_available_position?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_date?: InputMaybe<Scalars['date']>;
  required_skills?: InputMaybe<Scalars['String']>;
  required_skills_publishable?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  security_clearance?: InputMaybe<Scalars['String']>;
  security_clearance_publishable?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  target_rate?: InputMaybe<Scalars['String']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  total_positions?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Jobs_Available_Positions_Stddev_Fields = {
  __typename?: 'jobs_available_positions_stddev_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  next_available_position?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Jobs_Available_Positions_Stddev_Pop_Fields = {
  __typename?: 'jobs_available_positions_stddev_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  next_available_position?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Jobs_Available_Positions_Stddev_Samp_Fields = {
  __typename?: 'jobs_available_positions_stddev_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  next_available_position?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "jobs_available_positions" */
export type Jobs_Available_Positions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jobs_Available_Positions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jobs_Available_Positions_Stream_Cursor_Value_Input = {
  _search?: InputMaybe<Scalars['tsvector']>;
  account_manager_id?: InputMaybe<Scalars['Int']>;
  additional_details_publishable?: InputMaybe<Scalars['String']>;
  application_due_date?: InputMaybe<Scalars['date']>;
  available_positions?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_publishable?: InputMaybe<Scalars['String']>;
  desired_skills?: InputMaybe<Scalars['String']>;
  desired_skills_publishable?: InputMaybe<Scalars['String']>;
  eeo_category?: InputMaybe<Scalars['String']>;
  etl_id_token?: InputMaybe<Scalars['String']>;
  etl_original_object?: InputMaybe<Scalars['jsonb']>;
  etl_source_entity?: InputMaybe<Scalars['String']>;
  filled_by?: InputMaybe<Scalars['String']>;
  has_workflow?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  job_reference?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_geometry?: InputMaybe<Scalars['geometry']>;
  location_nickname?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['String']>;
  max_salary?: InputMaybe<Scalars['Int']>;
  next_available_position?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  program_lead_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  published_date?: InputMaybe<Scalars['date']>;
  required_skills?: InputMaybe<Scalars['String']>;
  required_skills_publishable?: InputMaybe<Scalars['String']>;
  resource_manager_id?: InputMaybe<Scalars['Int']>;
  security_clearance?: InputMaybe<Scalars['String']>;
  security_clearance_publishable?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  target_rate?: InputMaybe<Scalars['String']>;
  target_salary?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  total_positions?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Jobs_Available_Positions_Sum_Fields = {
  __typename?: 'jobs_available_positions_sum_fields';
  account_manager_id?: Maybe<Scalars['Int']>;
  available_positions?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  max_salary?: Maybe<Scalars['Int']>;
  next_available_position?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  program_lead_id?: Maybe<Scalars['Int']>;
  resource_manager_id?: Maybe<Scalars['Int']>;
  target_salary?: Maybe<Scalars['Int']>;
  total_positions?: Maybe<Scalars['Int']>;
};

export type Jobs_Available_Positions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Jobs_Available_Positions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Jobs_Available_Positions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Jobs_Available_Positions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Jobs_Available_Positions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Jobs_Available_Positions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Jobs_Available_Positions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Jobs_Available_Positions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Jobs_Available_Positions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Jobs_Available_Positions_Var_Pop_Fields = {
  __typename?: 'jobs_available_positions_var_pop_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  next_available_position?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Jobs_Available_Positions_Var_Samp_Fields = {
  __typename?: 'jobs_available_positions_var_samp_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  next_available_position?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Jobs_Available_Positions_Variance_Fields = {
  __typename?: 'jobs_available_positions_variance_fields';
  account_manager_id?: Maybe<Scalars['Float']>;
  available_positions?: Maybe<Scalars['Float']>;
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  next_available_position?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  program_lead_id?: Maybe<Scalars['Float']>;
  resource_manager_id?: Maybe<Scalars['Float']>;
  target_salary?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "matches" */
export type Matches = {
  __typename?: 'matches';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "matches" */
export type Matches_Aggregate = {
  __typename?: 'matches_aggregate';
  aggregate?: Maybe<Matches_Aggregate_Fields>;
  nodes: Array<Matches>;
};

/** aggregate fields of "matches" */
export type Matches_Aggregate_Fields = {
  __typename?: 'matches_aggregate_fields';
  avg?: Maybe<Matches_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_Max_Fields>;
  min?: Maybe<Matches_Min_Fields>;
  stddev?: Maybe<Matches_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_Sum_Fields>;
  var_pop?: Maybe<Matches_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_Var_Samp_Fields>;
  variance?: Maybe<Matches_Variance_Fields>;
};


/** aggregate fields of "matches" */
export type Matches_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matches_Avg_Fields = {
  __typename?: 'matches_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matches". All fields are combined with a logical 'AND'. */
export type Matches_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Matches_Bool_Exp>>;
  _not?: InputMaybe<Matches_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id?: InputMaybe<String_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
  serial?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "matches_from_applications" */
export type Matches_From_Applications = {
  __typename?: 'matches_from_applications';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matches_from_applications" */
export type Matches_From_Applications_Aggregate = {
  __typename?: 'matches_from_applications_aggregate';
  aggregate?: Maybe<Matches_From_Applications_Aggregate_Fields>;
  nodes: Array<Matches_From_Applications>;
};

/** aggregate fields of "matches_from_applications" */
export type Matches_From_Applications_Aggregate_Fields = {
  __typename?: 'matches_from_applications_aggregate_fields';
  avg?: Maybe<Matches_From_Applications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_From_Applications_Max_Fields>;
  min?: Maybe<Matches_From_Applications_Min_Fields>;
  stddev?: Maybe<Matches_From_Applications_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_From_Applications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_From_Applications_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_From_Applications_Sum_Fields>;
  var_pop?: Maybe<Matches_From_Applications_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_From_Applications_Var_Samp_Fields>;
  variance?: Maybe<Matches_From_Applications_Variance_Fields>;
};


/** aggregate fields of "matches_from_applications" */
export type Matches_From_Applications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_From_Applications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matches_From_Applications_Avg_Fields = {
  __typename?: 'matches_from_applications_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matches_from_applications". All fields are combined with a logical 'AND'. */
export type Matches_From_Applications_Bool_Exp = {
  _and?: InputMaybe<Array<Matches_From_Applications_Bool_Exp>>;
  _not?: InputMaybe<Matches_From_Applications_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_From_Applications_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id?: InputMaybe<String_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "matches_from_applications" */
export type Matches_From_Applications_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "matches_from_applications" */
export type Matches_From_Applications_Insert_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Matches_From_Applications_Max_Fields = {
  __typename?: 'matches_from_applications_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matches_From_Applications_Min_Fields = {
  __typename?: 'matches_from_applications_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "matches_from_applications" */
export type Matches_From_Applications_Mutation_Response = {
  __typename?: 'matches_from_applications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Matches_From_Applications>;
};

/** Ordering options when selecting data from "matches_from_applications". */
export type Matches_From_Applications_Order_By = {
  author_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
};

/** select columns of table "matches_from_applications" */
export enum Matches_From_Applications_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjId = 'match_obj_id',
  /** column name */
  MatchReason = 'match_reason'
}

/** input type for updating data in table "matches_from_applications" */
export type Matches_From_Applications_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Matches_From_Applications_Stddev_Fields = {
  __typename?: 'matches_from_applications_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matches_From_Applications_Stddev_Pop_Fields = {
  __typename?: 'matches_from_applications_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matches_From_Applications_Stddev_Samp_Fields = {
  __typename?: 'matches_from_applications_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matches_from_applications" */
export type Matches_From_Applications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_From_Applications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_From_Applications_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matches_From_Applications_Sum_Fields = {
  __typename?: 'matches_from_applications_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

export type Matches_From_Applications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Matches_From_Applications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Matches_From_Applications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Matches_From_Applications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Matches_From_Applications_Var_Pop_Fields = {
  __typename?: 'matches_from_applications_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matches_From_Applications_Var_Samp_Fields = {
  __typename?: 'matches_from_applications_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matches_From_Applications_Variance_Fields = {
  __typename?: 'matches_from_applications_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matches_from_board" */
export type Matches_From_Board = {
  __typename?: 'matches_from_board';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matches_from_board" */
export type Matches_From_Board_Aggregate = {
  __typename?: 'matches_from_board_aggregate';
  aggregate?: Maybe<Matches_From_Board_Aggregate_Fields>;
  nodes: Array<Matches_From_Board>;
};

/** aggregate fields of "matches_from_board" */
export type Matches_From_Board_Aggregate_Fields = {
  __typename?: 'matches_from_board_aggregate_fields';
  avg?: Maybe<Matches_From_Board_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_From_Board_Max_Fields>;
  min?: Maybe<Matches_From_Board_Min_Fields>;
  stddev?: Maybe<Matches_From_Board_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_From_Board_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_From_Board_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_From_Board_Sum_Fields>;
  var_pop?: Maybe<Matches_From_Board_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_From_Board_Var_Samp_Fields>;
  variance?: Maybe<Matches_From_Board_Variance_Fields>;
};


/** aggregate fields of "matches_from_board" */
export type Matches_From_Board_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_From_Board_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matches_From_Board_Avg_Fields = {
  __typename?: 'matches_from_board_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matches_from_board". All fields are combined with a logical 'AND'. */
export type Matches_From_Board_Bool_Exp = {
  _and?: InputMaybe<Array<Matches_From_Board_Bool_Exp>>;
  _not?: InputMaybe<Matches_From_Board_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_From_Board_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id?: InputMaybe<String_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "matches_from_board" */
export type Matches_From_Board_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "matches_from_board" */
export type Matches_From_Board_Insert_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Matches_From_Board_Max_Fields = {
  __typename?: 'matches_from_board_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matches_From_Board_Min_Fields = {
  __typename?: 'matches_from_board_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "matches_from_board" */
export type Matches_From_Board_Mutation_Response = {
  __typename?: 'matches_from_board_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Matches_From_Board>;
};

/** Ordering options when selecting data from "matches_from_board". */
export type Matches_From_Board_Order_By = {
  author_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
};

/** select columns of table "matches_from_board" */
export enum Matches_From_Board_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjId = 'match_obj_id',
  /** column name */
  MatchReason = 'match_reason'
}

/** input type for updating data in table "matches_from_board" */
export type Matches_From_Board_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Matches_From_Board_Stddev_Fields = {
  __typename?: 'matches_from_board_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matches_From_Board_Stddev_Pop_Fields = {
  __typename?: 'matches_from_board_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matches_From_Board_Stddev_Samp_Fields = {
  __typename?: 'matches_from_board_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matches_from_board" */
export type Matches_From_Board_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_From_Board_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_From_Board_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matches_From_Board_Sum_Fields = {
  __typename?: 'matches_from_board_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

export type Matches_From_Board_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Matches_From_Board_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Matches_From_Board_Set_Input>;
  /** filter the rows which have to be updated */
  where: Matches_From_Board_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Matches_From_Board_Var_Pop_Fields = {
  __typename?: 'matches_from_board_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matches_From_Board_Var_Samp_Fields = {
  __typename?: 'matches_from_board_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matches_From_Board_Variance_Fields = {
  __typename?: 'matches_from_board_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matches_from_identities" */
export type Matches_From_Identities = {
  __typename?: 'matches_from_identities';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matches_from_identities" */
export type Matches_From_Identities_Aggregate = {
  __typename?: 'matches_from_identities_aggregate';
  aggregate?: Maybe<Matches_From_Identities_Aggregate_Fields>;
  nodes: Array<Matches_From_Identities>;
};

/** aggregate fields of "matches_from_identities" */
export type Matches_From_Identities_Aggregate_Fields = {
  __typename?: 'matches_from_identities_aggregate_fields';
  avg?: Maybe<Matches_From_Identities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_From_Identities_Max_Fields>;
  min?: Maybe<Matches_From_Identities_Min_Fields>;
  stddev?: Maybe<Matches_From_Identities_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_From_Identities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_From_Identities_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_From_Identities_Sum_Fields>;
  var_pop?: Maybe<Matches_From_Identities_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_From_Identities_Var_Samp_Fields>;
  variance?: Maybe<Matches_From_Identities_Variance_Fields>;
};


/** aggregate fields of "matches_from_identities" */
export type Matches_From_Identities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_From_Identities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matches_From_Identities_Avg_Fields = {
  __typename?: 'matches_from_identities_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matches_from_identities". All fields are combined with a logical 'AND'. */
export type Matches_From_Identities_Bool_Exp = {
  _and?: InputMaybe<Array<Matches_From_Identities_Bool_Exp>>;
  _not?: InputMaybe<Matches_From_Identities_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_From_Identities_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id?: InputMaybe<String_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matches_From_Identities_Max_Fields = {
  __typename?: 'matches_from_identities_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matches_From_Identities_Min_Fields = {
  __typename?: 'matches_from_identities_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "matches_from_identities". */
export type Matches_From_Identities_Order_By = {
  author_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
};

/** select columns of table "matches_from_identities" */
export enum Matches_From_Identities_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjId = 'match_obj_id',
  /** column name */
  MatchReason = 'match_reason'
}

/** aggregate stddev on columns */
export type Matches_From_Identities_Stddev_Fields = {
  __typename?: 'matches_from_identities_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matches_From_Identities_Stddev_Pop_Fields = {
  __typename?: 'matches_from_identities_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matches_From_Identities_Stddev_Samp_Fields = {
  __typename?: 'matches_from_identities_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matches_from_identities" */
export type Matches_From_Identities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_From_Identities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_From_Identities_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matches_From_Identities_Sum_Fields = {
  __typename?: 'matches_from_identities_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Matches_From_Identities_Var_Pop_Fields = {
  __typename?: 'matches_from_identities_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matches_From_Identities_Var_Samp_Fields = {
  __typename?: 'matches_from_identities_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matches_From_Identities_Variance_Fields = {
  __typename?: 'matches_from_identities_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matches_from_tasks" */
export type Matches_From_Tasks = {
  __typename?: 'matches_from_tasks';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matches_from_tasks" */
export type Matches_From_Tasks_Aggregate = {
  __typename?: 'matches_from_tasks_aggregate';
  aggregate?: Maybe<Matches_From_Tasks_Aggregate_Fields>;
  nodes: Array<Matches_From_Tasks>;
};

/** aggregate fields of "matches_from_tasks" */
export type Matches_From_Tasks_Aggregate_Fields = {
  __typename?: 'matches_from_tasks_aggregate_fields';
  avg?: Maybe<Matches_From_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_From_Tasks_Max_Fields>;
  min?: Maybe<Matches_From_Tasks_Min_Fields>;
  stddev?: Maybe<Matches_From_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_From_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_From_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_From_Tasks_Sum_Fields>;
  var_pop?: Maybe<Matches_From_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_From_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Matches_From_Tasks_Variance_Fields>;
};


/** aggregate fields of "matches_from_tasks" */
export type Matches_From_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_From_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matches_From_Tasks_Avg_Fields = {
  __typename?: 'matches_from_tasks_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matches_from_tasks". All fields are combined with a logical 'AND'. */
export type Matches_From_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Matches_From_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Matches_From_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_From_Tasks_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id?: InputMaybe<String_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "matches_from_tasks" */
export type Matches_From_Tasks_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "matches_from_tasks" */
export type Matches_From_Tasks_Insert_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Matches_From_Tasks_Max_Fields = {
  __typename?: 'matches_from_tasks_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matches_From_Tasks_Min_Fields = {
  __typename?: 'matches_from_tasks_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "matches_from_tasks" */
export type Matches_From_Tasks_Mutation_Response = {
  __typename?: 'matches_from_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Matches_From_Tasks>;
};

/** Ordering options when selecting data from "matches_from_tasks". */
export type Matches_From_Tasks_Order_By = {
  author_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
};

/** select columns of table "matches_from_tasks" */
export enum Matches_From_Tasks_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjId = 'match_obj_id',
  /** column name */
  MatchReason = 'match_reason'
}

/** input type for updating data in table "matches_from_tasks" */
export type Matches_From_Tasks_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Matches_From_Tasks_Stddev_Fields = {
  __typename?: 'matches_from_tasks_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matches_From_Tasks_Stddev_Pop_Fields = {
  __typename?: 'matches_from_tasks_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matches_From_Tasks_Stddev_Samp_Fields = {
  __typename?: 'matches_from_tasks_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matches_from_tasks" */
export type Matches_From_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_From_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_From_Tasks_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matches_From_Tasks_Sum_Fields = {
  __typename?: 'matches_from_tasks_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

export type Matches_From_Tasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Matches_From_Tasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Matches_From_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Matches_From_Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Matches_From_Tasks_Var_Pop_Fields = {
  __typename?: 'matches_from_tasks_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matches_From_Tasks_Var_Samp_Fields = {
  __typename?: 'matches_from_tasks_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matches_From_Tasks_Variance_Fields = {
  __typename?: 'matches_from_tasks_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matches_grouped" */
export type Matches_Grouped = {
  __typename?: 'matches_grouped';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "matches_grouped" */
export type Matches_Grouped_Aggregate = {
  __typename?: 'matches_grouped_aggregate';
  aggregate?: Maybe<Matches_Grouped_Aggregate_Fields>;
  nodes: Array<Matches_Grouped>;
};

/** aggregate fields of "matches_grouped" */
export type Matches_Grouped_Aggregate_Fields = {
  __typename?: 'matches_grouped_aggregate_fields';
  avg?: Maybe<Matches_Grouped_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_Grouped_Max_Fields>;
  min?: Maybe<Matches_Grouped_Min_Fields>;
  stddev?: Maybe<Matches_Grouped_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_Grouped_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_Grouped_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_Grouped_Sum_Fields>;
  var_pop?: Maybe<Matches_Grouped_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_Grouped_Var_Samp_Fields>;
  variance?: Maybe<Matches_Grouped_Variance_Fields>;
};


/** aggregate fields of "matches_grouped" */
export type Matches_Grouped_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_Grouped_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matches_Grouped_Avg_Fields = {
  __typename?: 'matches_grouped_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matches_grouped". All fields are combined with a logical 'AND'. */
export type Matches_Grouped_Bool_Exp = {
  _and?: InputMaybe<Array<Matches_Grouped_Bool_Exp>>;
  _not?: InputMaybe<Matches_Grouped_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_Grouped_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id?: InputMaybe<String_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
  serial?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matches_Grouped_Max_Fields = {
  __typename?: 'matches_grouped_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Matches_Grouped_Min_Fields = {
  __typename?: 'matches_grouped_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "matches_grouped". */
export type Matches_Grouped_Order_By = {
  author_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** select columns of table "matches_grouped" */
export enum Matches_Grouped_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjId = 'match_obj_id',
  /** column name */
  MatchReason = 'match_reason',
  /** column name */
  Serial = 'serial'
}

/** aggregate stddev on columns */
export type Matches_Grouped_Stddev_Fields = {
  __typename?: 'matches_grouped_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matches_Grouped_Stddev_Pop_Fields = {
  __typename?: 'matches_grouped_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matches_Grouped_Stddev_Samp_Fields = {
  __typename?: 'matches_grouped_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matches_grouped" */
export type Matches_Grouped_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_Grouped_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_Grouped_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Matches_Grouped_Sum_Fields = {
  __typename?: 'matches_grouped_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Matches_Grouped_Var_Pop_Fields = {
  __typename?: 'matches_grouped_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matches_Grouped_Var_Samp_Fields = {
  __typename?: 'matches_grouped_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matches_Grouped_Variance_Fields = {
  __typename?: 'matches_grouped_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Matches_Max_Fields = {
  __typename?: 'matches_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Matches_Min_Fields = {
  __typename?: 'matches_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "matches". */
export type Matches_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  author_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** select columns of table "matches" */
export enum Matches_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjId = 'match_obj_id',
  /** column name */
  MatchReason = 'match_reason',
  /** column name */
  Serial = 'serial'
}

/** aggregate stddev on columns */
export type Matches_Stddev_Fields = {
  __typename?: 'matches_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matches_Stddev_Pop_Fields = {
  __typename?: 'matches_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matches_Stddev_Samp_Fields = {
  __typename?: 'matches_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matches" */
export type Matches_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Matches_Sum_Fields = {
  __typename?: 'matches_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "matches_union" */
export type Matches_Union = {
  __typename?: 'matches_union';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregated selection of "matches_union" */
export type Matches_Union_Aggregate = {
  __typename?: 'matches_union_aggregate';
  aggregate?: Maybe<Matches_Union_Aggregate_Fields>;
  nodes: Array<Matches_Union>;
};

/** aggregate fields of "matches_union" */
export type Matches_Union_Aggregate_Fields = {
  __typename?: 'matches_union_aggregate_fields';
  avg?: Maybe<Matches_Union_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_Union_Max_Fields>;
  min?: Maybe<Matches_Union_Min_Fields>;
  stddev?: Maybe<Matches_Union_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_Union_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_Union_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_Union_Sum_Fields>;
  var_pop?: Maybe<Matches_Union_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_Union_Var_Samp_Fields>;
  variance?: Maybe<Matches_Union_Variance_Fields>;
};


/** aggregate fields of "matches_union" */
export type Matches_Union_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_Union_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Matches_Union_Avg_Fields = {
  __typename?: 'matches_union_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "matches_union". All fields are combined with a logical 'AND'. */
export type Matches_Union_Bool_Exp = {
  _and?: InputMaybe<Array<Matches_Union_Bool_Exp>>;
  _not?: InputMaybe<Matches_Union_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_Union_Bool_Exp>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id?: InputMaybe<String_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Matches_Union_Max_Fields = {
  __typename?: 'matches_union_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Matches_Union_Min_Fields = {
  __typename?: 'matches_union_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id?: Maybe<Scalars['String']>;
  match_reason?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "matches_union". */
export type Matches_Union_Order_By = {
  author_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
};

/** select columns of table "matches_union" */
export enum Matches_Union_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjId = 'match_obj_id',
  /** column name */
  MatchReason = 'match_reason'
}

/** aggregate stddev on columns */
export type Matches_Union_Stddev_Fields = {
  __typename?: 'matches_union_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Matches_Union_Stddev_Pop_Fields = {
  __typename?: 'matches_union_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Matches_Union_Stddev_Samp_Fields = {
  __typename?: 'matches_union_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "matches_union" */
export type Matches_Union_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_Union_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_Union_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id?: InputMaybe<Scalars['String']>;
  match_reason?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Matches_Union_Sum_Fields = {
  __typename?: 'matches_union_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Matches_Union_Var_Pop_Fields = {
  __typename?: 'matches_union_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matches_Union_Var_Samp_Fields = {
  __typename?: 'matches_union_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matches_Union_Variance_Fields = {
  __typename?: 'matches_union_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Matches_Var_Pop_Fields = {
  __typename?: 'matches_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Matches_Var_Samp_Fields = {
  __typename?: 'matches_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Matches_Variance_Fields = {
  __typename?: 'matches_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid = {
  __typename?: 'matches_without_author_with_int_uuid';
  /** An object relationship */
  AuditStage?: Maybe<AuditStages>;
  /** An object relationship */
  Contract?: Maybe<Contracts>;
  /** An object relationship */
  Identity?: Maybe<Identities>;
  /** An object relationship */
  IdentityJobRelationship?: Maybe<IdentityJobRelationship>;
  /** An object relationship */
  Job?: Maybe<Jobs>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id_int?: Maybe<Scalars['Int']>;
  match_obj_id_uuid?: Maybe<Scalars['uuid']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Aggregate = {
  __typename?: 'matches_without_author_with_int_uuid_aggregate';
  aggregate?: Maybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Fields>;
  nodes: Array<Matches_Without_Author_With_Int_Uuid>;
};

export type Matches_Without_Author_With_Int_Uuid_Aggregate_Bool_Exp = {
  count?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Aggregate_Bool_Exp_Count>;
};

export type Matches_Without_Author_With_Int_Uuid_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Aggregate_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_aggregate_fields';
  avg?: Maybe<Matches_Without_Author_With_Int_Uuid_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Matches_Without_Author_With_Int_Uuid_Max_Fields>;
  min?: Maybe<Matches_Without_Author_With_Int_Uuid_Min_Fields>;
  stddev?: Maybe<Matches_Without_Author_With_Int_Uuid_Stddev_Fields>;
  stddev_pop?: Maybe<Matches_Without_Author_With_Int_Uuid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matches_Without_Author_With_Int_Uuid_Stddev_Samp_Fields>;
  sum?: Maybe<Matches_Without_Author_With_Int_Uuid_Sum_Fields>;
  var_pop?: Maybe<Matches_Without_Author_With_Int_Uuid_Var_Pop_Fields>;
  var_samp?: Maybe<Matches_Without_Author_With_Int_Uuid_Var_Samp_Fields>;
  variance?: Maybe<Matches_Without_Author_With_Int_Uuid_Variance_Fields>;
};


/** aggregate fields of "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Aggregate_Order_By = {
  avg?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Max_Order_By>;
  min?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Min_Order_By>;
  stddev?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Sum_Order_By>;
  var_pop?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Var_Samp_Order_By>;
  variance?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Arr_Rel_Insert_Input = {
  data: Array<Matches_Without_Author_With_Int_Uuid_Insert_Input>;
};

/** aggregate avg on columns */
export type Matches_Without_Author_With_Int_Uuid_Avg_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  match_obj_id_int?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "matches_without_author_with_int_uuid". All fields are combined with a logical 'AND'. */
export type Matches_Without_Author_With_Int_Uuid_Bool_Exp = {
  AuditStage?: InputMaybe<AuditStages_Bool_Exp>;
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Identity?: InputMaybe<Identities_Bool_Exp>;
  IdentityJobRelationship?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
  Job?: InputMaybe<Jobs_Bool_Exp>;
  _and?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Bool_Exp>>;
  _not?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
  _or?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id_int?: InputMaybe<Int_Comparison_Exp>;
  match_obj_id_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  match_reason?: InputMaybe<String_Comparison_Exp>;
  serial?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Insert_Input = {
  AuditStage?: InputMaybe<AuditStages_Obj_Rel_Insert_Input>;
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Identity?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  IdentityJobRelationship?: InputMaybe<IdentityJobRelationship_Obj_Rel_Insert_Input>;
  Job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id_int?: InputMaybe<Scalars['Int']>;
  match_obj_id_uuid?: InputMaybe<Scalars['uuid']>;
  match_reason?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Matches_Without_Author_With_Int_Uuid_Max_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id_int?: Maybe<Scalars['Int']>;
  match_obj_id_uuid?: Maybe<Scalars['uuid']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  match_obj_id_uuid?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Matches_Without_Author_With_Int_Uuid_Min_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id_int?: Maybe<Scalars['Int']>;
  match_obj_id_uuid?: Maybe<Scalars['uuid']>;
  match_reason?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  match_obj_id_uuid?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "matches_without_author_with_int_uuid". */
export type Matches_Without_Author_With_Int_Uuid_Order_By = {
  AuditStage?: InputMaybe<AuditStages_Order_By>;
  Contract?: InputMaybe<Contracts_Order_By>;
  Identity?: InputMaybe<Identities_Order_By>;
  IdentityJobRelationship?: InputMaybe<IdentityJobRelationship_Order_By>;
  Job?: InputMaybe<Jobs_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  match_obj_id_uuid?: InputMaybe<Order_By>;
  match_reason?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** select columns of table "matches_without_author_with_int_uuid" */
export enum Matches_Without_Author_With_Int_Uuid_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MatchObjIdInt = 'match_obj_id_int',
  /** column name */
  MatchObjIdUuid = 'match_obj_id_uuid',
  /** column name */
  MatchReason = 'match_reason',
  /** column name */
  Serial = 'serial'
}

/** aggregate stddev on columns */
export type Matches_Without_Author_With_Int_Uuid_Stddev_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  match_obj_id_int?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Matches_Without_Author_With_Int_Uuid_Stddev_Pop_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  match_obj_id_int?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Matches_Without_Author_With_Int_Uuid_Stddev_Samp_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  match_obj_id_int?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matches_Without_Author_With_Int_Uuid_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matches_Without_Author_With_Int_Uuid_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  match_obj_id_int?: InputMaybe<Scalars['Int']>;
  match_obj_id_uuid?: InputMaybe<Scalars['uuid']>;
  match_reason?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Matches_Without_Author_With_Int_Uuid_Sum_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  match_obj_id_int?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Matches_Without_Author_With_Int_Uuid_Var_Pop_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  match_obj_id_int?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Matches_Without_Author_With_Int_Uuid_Var_Samp_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  match_obj_id_int?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Matches_Without_Author_With_Int_Uuid_Variance_Fields = {
  __typename?: 'matches_without_author_with_int_uuid_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  match_obj_id_int?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "matches_without_author_with_int_uuid" */
export type Matches_Without_Author_With_Int_Uuid_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  match_obj_id_int?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "money". All fields are combined with logical 'AND'. */
export type Money_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['money']>;
  _gt?: InputMaybe<Scalars['money']>;
  _gte?: InputMaybe<Scalars['money']>;
  _in?: InputMaybe<Array<Scalars['money']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['money']>;
  _lte?: InputMaybe<Scalars['money']>;
  _neq?: InputMaybe<Scalars['money']>;
  _nin?: InputMaybe<Array<Scalars['money']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  BulkResumeIdentityUpsert?: Maybe<Array<Maybe<ResumeUploadActionResult>>>;
  cloneGSheetCalcTemplate: CloneGSheetCalcTemplateOutput;
  computeGSheetCalcResults: ComputeGSheetCalcResultsOutput;
  conversion?: Maybe<ConversionHandlerOutput>;
  createCalendarEvent?: Maybe<CreateEventOutput>;
  createGSheetCalcTemplate?: Maybe<CreateGSheetCalcTemplateOutput>;
  createJobApplication?: Maybe<JobApplicationOutput>;
  createPartnerUser?: Maybe<AccountsApiOutput>;
  deleteFile?: Maybe<DeleteFileOutput>;
  /** delete data from the table: "APITokens" */
  delete_APITokens?: Maybe<ApiTokens_Mutation_Response>;
  /** delete single row from the table: "APITokens" */
  delete_APITokens_by_pk?: Maybe<ApiTokens>;
  /** delete data from the table: "AccessIntents" */
  delete_AccessIntents?: Maybe<AccessIntents_Mutation_Response>;
  /** delete single row from the table: "AccessIntents" */
  delete_AccessIntents_by_pk?: Maybe<AccessIntents>;
  /** delete data from the table: "AccessTypeEnum" */
  delete_AccessTypeEnum?: Maybe<AccessTypeEnum_Mutation_Response>;
  /** delete single row from the table: "AccessTypeEnum" */
  delete_AccessTypeEnum_by_pk?: Maybe<AccessTypeEnum>;
  /** delete data from the table: "ActiveEntitlements" */
  delete_ActiveEntitlements?: Maybe<ActiveEntitlements_Mutation_Response>;
  /** delete data from the table: "ActivityLogTypes" */
  delete_ActivityLogTypes?: Maybe<ActivityLogTypes_Mutation_Response>;
  /** delete single row from the table: "ActivityLogTypes" */
  delete_ActivityLogTypes_by_pk?: Maybe<ActivityLogTypes>;
  /** delete data from the table: "ActivityTemplates" */
  delete_ActivityTemplates?: Maybe<ActivityTemplates_Mutation_Response>;
  /** delete single row from the table: "ActivityTemplates" */
  delete_ActivityTemplates_by_pk?: Maybe<ActivityTemplates>;
  /** delete data from the table: "AoIdentifierTypes" */
  delete_AoIdentifierTypes?: Maybe<AoIdentifierTypes_Mutation_Response>;
  /** delete single row from the table: "AoIdentifierTypes" */
  delete_AoIdentifierTypes_by_pk?: Maybe<AoIdentifierTypes>;
  /** delete data from the table: "ApplicationStatus" */
  delete_ApplicationStatus?: Maybe<ApplicationStatus_Mutation_Response>;
  /** delete single row from the table: "ApplicationStatus" */
  delete_ApplicationStatus_by_pk?: Maybe<ApplicationStatus>;
  /** delete data from the table: "ApplicationType" */
  delete_ApplicationType?: Maybe<ApplicationType_Mutation_Response>;
  /** delete single row from the table: "ApplicationType" */
  delete_ApplicationType_by_pk?: Maybe<ApplicationType>;
  /** delete data from the table: "Applications" */
  delete_Applications?: Maybe<Applications_Mutation_Response>;
  /** delete single row from the table: "Applications" */
  delete_Applications_by_pk?: Maybe<Applications>;
  /** delete data from the table: "Assignments" */
  delete_Assignments?: Maybe<Assignments_Mutation_Response>;
  /** delete single row from the table: "Assignments" */
  delete_Assignments_by_pk?: Maybe<Assignments>;
  /** delete data from the table: "AsyncOperations" */
  delete_AsyncOperations?: Maybe<AsyncOperations_Mutation_Response>;
  /** delete data from the table: "AsyncOperationsStates" */
  delete_AsyncOperationsStates?: Maybe<AsyncOperationsStates_Mutation_Response>;
  /** delete single row from the table: "AsyncOperationsStates" */
  delete_AsyncOperationsStates_by_pk?: Maybe<AsyncOperationsStates>;
  /** delete single row from the table: "AsyncOperations" */
  delete_AsyncOperations_by_pk?: Maybe<AsyncOperations>;
  /** delete data from the table: "Attachments" */
  delete_Attachments?: Maybe<Attachments_Mutation_Response>;
  /** delete single row from the table: "Attachments" */
  delete_Attachments_by_pk?: Maybe<Attachments>;
  /** delete data from the table: "AuditCreates" */
  delete_AuditCreates?: Maybe<AuditCreates_Mutation_Response>;
  /** delete single row from the table: "AuditCreates" */
  delete_AuditCreates_by_pk?: Maybe<AuditCreates>;
  /** delete data from the table: "AuditStages" */
  delete_AuditStages?: Maybe<AuditStages_Mutation_Response>;
  /** delete single row from the table: "AuditStages" */
  delete_AuditStages_by_pk?: Maybe<AuditStages>;
  /** delete data from the table: "AuthAudits" */
  delete_AuthAudits?: Maybe<AuthAudits_Mutation_Response>;
  /** delete single row from the table: "AuthAudits" */
  delete_AuthAudits_by_pk?: Maybe<AuthAudits>;
  /** delete data from the table: "Badges" */
  delete_Badges?: Maybe<Badges_Mutation_Response>;
  /** delete single row from the table: "Badges" */
  delete_Badges_by_pk?: Maybe<Badges>;
  /** delete data from the table: "Benefit" */
  delete_Benefit?: Maybe<Benefit_Mutation_Response>;
  /** delete data from the table: "BenefitTypeIdentitySubTypes" */
  delete_BenefitTypeIdentitySubTypes?: Maybe<BenefitTypeIdentitySubTypes_Mutation_Response>;
  /** delete single row from the table: "BenefitTypeIdentitySubTypes" */
  delete_BenefitTypeIdentitySubTypes_by_pk?: Maybe<BenefitTypeIdentitySubTypes>;
  /** delete data from the table: "BenefitTypes" */
  delete_BenefitTypes?: Maybe<BenefitTypes_Mutation_Response>;
  /** delete single row from the table: "BenefitTypes" */
  delete_BenefitTypes_by_pk?: Maybe<BenefitTypes>;
  /** delete single row from the table: "Benefit" */
  delete_Benefit_by_pk?: Maybe<Benefit>;
  /** delete data from the table: "BoardFilters" */
  delete_BoardFilters?: Maybe<BoardFilters_Mutation_Response>;
  /** delete single row from the table: "BoardFilters" */
  delete_BoardFilters_by_pk?: Maybe<BoardFilters>;
  /** delete data from the table: "BoardSnippets" */
  delete_BoardSnippets?: Maybe<BoardSnippets_Mutation_Response>;
  /** delete single row from the table: "BoardSnippets" */
  delete_BoardSnippets_by_pk?: Maybe<BoardSnippets>;
  /** delete data from the table: "BullhornSyncStatus" */
  delete_BullhornSyncStatus?: Maybe<BullhornSyncStatus_Mutation_Response>;
  /** delete single row from the table: "BullhornSyncStatus" */
  delete_BullhornSyncStatus_by_pk?: Maybe<BullhornSyncStatus>;
  /** delete data from the table: "CalcEquationResults" */
  delete_CalcEquationResults?: Maybe<CalcEquationResults_Mutation_Response>;
  /** delete single row from the table: "CalcEquationResults" */
  delete_CalcEquationResults_by_pk?: Maybe<CalcEquationResults>;
  /** delete data from the table: "CalcFormValues" */
  delete_CalcFormValues?: Maybe<CalcFormValues_Mutation_Response>;
  /** delete single row from the table: "CalcFormValues" */
  delete_CalcFormValues_by_pk?: Maybe<CalcFormValues>;
  /** delete data from the table: "Calcs" */
  delete_Calcs?: Maybe<Calcs_Mutation_Response>;
  /** delete single row from the table: "Calcs" */
  delete_Calcs_by_pk?: Maybe<Calcs>;
  /** delete data from the table: "CalculationTemplateVersions" */
  delete_CalculationTemplateVersions?: Maybe<CalculationTemplateVersions_Mutation_Response>;
  /** delete single row from the table: "CalculationTemplateVersions" */
  delete_CalculationTemplateVersions_by_pk?: Maybe<CalculationTemplateVersions>;
  /** delete data from the table: "CalculationTemplates" */
  delete_CalculationTemplates?: Maybe<CalculationTemplates_Mutation_Response>;
  /** delete single row from the table: "CalculationTemplates" */
  delete_CalculationTemplates_by_pk?: Maybe<CalculationTemplates>;
  /** delete data from the table: "CalculationVersionFormInputs" */
  delete_CalculationVersionFormInputs?: Maybe<CalculationVersionFormInputs_Mutation_Response>;
  /** delete single row from the table: "CalculationVersionFormInputs" */
  delete_CalculationVersionFormInputs_by_pk?: Maybe<CalculationVersionFormInputs>;
  /** delete data from the table: "Calculations" */
  delete_Calculations?: Maybe<Calculations_Mutation_Response>;
  /** delete single row from the table: "Calculations" */
  delete_Calculations_by_pk?: Maybe<Calculations>;
  /** delete data from the table: "CandidateSources" */
  delete_CandidateSources?: Maybe<CandidateSources_Mutation_Response>;
  /** delete single row from the table: "CandidateSources" */
  delete_CandidateSources_by_pk?: Maybe<CandidateSources>;
  /** delete data from the table: "CandidateStatuses" */
  delete_CandidateStatuses?: Maybe<CandidateStatuses_Mutation_Response>;
  /** delete single row from the table: "CandidateStatuses" */
  delete_CandidateStatuses_by_pk?: Maybe<CandidateStatuses>;
  /** delete data from the table: "CaptureNodes" */
  delete_CaptureNodes?: Maybe<CaptureNodes_Mutation_Response>;
  /** delete single row from the table: "CaptureNodes" */
  delete_CaptureNodes_by_pk?: Maybe<CaptureNodes>;
  /** delete data from the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  delete_ClientSideExternalSystemIntegrationPluginRecord?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Mutation_Response>;
  /** delete single row from the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  delete_ClientSideExternalSystemIntegrationPluginRecord_by_pk?: Maybe<ClientSideExternalSystemIntegrationPluginRecord>;
  /** delete data from the table: "CognitoUserData" */
  delete_CognitoUserData?: Maybe<CognitoUserData_Mutation_Response>;
  /** delete single row from the table: "CognitoUserData" */
  delete_CognitoUserData_by_pk?: Maybe<CognitoUserData>;
  /** delete data from the table: "CommentTypes" */
  delete_CommentTypes?: Maybe<CommentTypes_Mutation_Response>;
  /** delete single row from the table: "CommentTypes" */
  delete_CommentTypes_by_pk?: Maybe<CommentTypes>;
  /** delete data from the table: "Comments" */
  delete_Comments?: Maybe<Comments_Mutation_Response>;
  /** delete single row from the table: "Comments" */
  delete_Comments_by_pk?: Maybe<Comments>;
  /** delete data from the table: "Companies" */
  delete_Companies?: Maybe<Companies_Mutation_Response>;
  /** delete single row from the table: "Companies" */
  delete_Companies_by_pk?: Maybe<Companies>;
  /** delete data from the table: "CompanyEvents" */
  delete_CompanyEvents?: Maybe<CompanyEvents_Mutation_Response>;
  /** delete single row from the table: "CompanyEvents" */
  delete_CompanyEvents_by_pk?: Maybe<CompanyEvents>;
  /** delete data from the table: "CompanyStatusEnum" */
  delete_CompanyStatusEnum?: Maybe<CompanyStatusEnum_Mutation_Response>;
  /** delete single row from the table: "CompanyStatusEnum" */
  delete_CompanyStatusEnum_by_pk?: Maybe<CompanyStatusEnum>;
  /** delete data from the table: "Compensations" */
  delete_Compensations?: Maybe<Compensations_Mutation_Response>;
  /** delete single row from the table: "Compensations" */
  delete_Compensations_by_pk?: Maybe<Compensations>;
  /** delete data from the table: "ContactTemplateVariablesEnum" */
  delete_ContactTemplateVariablesEnum?: Maybe<ContactTemplateVariablesEnum_Mutation_Response>;
  /** delete single row from the table: "ContactTemplateVariablesEnum" */
  delete_ContactTemplateVariablesEnum_by_pk?: Maybe<ContactTemplateVariablesEnum>;
  /** delete data from the table: "ContractComments" */
  delete_ContractComments?: Maybe<ContractComments_Mutation_Response>;
  /** delete single row from the table: "ContractComments" */
  delete_ContractComments_by_pk?: Maybe<ContractComments>;
  /** delete data from the table: "ContractFiles" */
  delete_ContractFiles?: Maybe<ContractFiles_Mutation_Response>;
  /** delete single row from the table: "ContractFiles" */
  delete_ContractFiles_by_pk?: Maybe<ContractFiles>;
  /** delete data from the table: "ContractStages" */
  delete_ContractStages?: Maybe<ContractStages_Mutation_Response>;
  /** delete single row from the table: "ContractStages" */
  delete_ContractStages_by_pk?: Maybe<ContractStages>;
  /** delete data from the table: "ContractStatusEnum" */
  delete_ContractStatusEnum?: Maybe<ContractStatusEnum_Mutation_Response>;
  /** delete single row from the table: "ContractStatusEnum" */
  delete_ContractStatusEnum_by_pk?: Maybe<ContractStatusEnum>;
  /** delete data from the table: "Contracts" */
  delete_Contracts?: Maybe<Contracts_Mutation_Response>;
  /** delete single row from the table: "Contracts" */
  delete_Contracts_by_pk?: Maybe<Contracts>;
  /** delete data from the table: "CurrentStages" */
  delete_CurrentStages?: Maybe<CurrentStages_Mutation_Response>;
  /** delete single row from the table: "CurrentStages" */
  delete_CurrentStages_by_pk?: Maybe<CurrentStages>;
  /** delete data from the table: "DataHistory" */
  delete_DataHistory?: Maybe<DataHistory_Mutation_Response>;
  /** delete single row from the table: "DataHistory" */
  delete_DataHistory_by_pk?: Maybe<DataHistory>;
  /** delete data from the table: "Departments" */
  delete_Departments?: Maybe<Departments_Mutation_Response>;
  /** delete single row from the table: "Departments" */
  delete_Departments_by_pk?: Maybe<Departments>;
  /** delete data from the table: "DisabilityStatusEnum" */
  delete_DisabilityStatusEnum?: Maybe<DisabilityStatusEnum_Mutation_Response>;
  /** delete single row from the table: "DisabilityStatusEnum" */
  delete_DisabilityStatusEnum_by_pk?: Maybe<DisabilityStatusEnum>;
  /** delete data from the table: "EEOJobCategoriesEnum" */
  delete_EEOJobCategoriesEnum?: Maybe<EeoJobCategoriesEnum_Mutation_Response>;
  /** delete single row from the table: "EEOJobCategoriesEnum" */
  delete_EEOJobCategoriesEnum_by_pk?: Maybe<EeoJobCategoriesEnum>;
  /** delete data from the table: "EarnedBadges" */
  delete_EarnedBadges?: Maybe<EarnedBadges_Mutation_Response>;
  /** delete single row from the table: "EarnedBadges" */
  delete_EarnedBadges_by_pk?: Maybe<EarnedBadges>;
  /** delete data from the table: "EmailAddresses" */
  delete_EmailAddresses?: Maybe<EmailAddresses_Mutation_Response>;
  /** delete single row from the table: "EmailAddresses" */
  delete_EmailAddresses_by_pk?: Maybe<EmailAddresses>;
  /** delete data from the table: "EmailEvents" */
  delete_EmailEvents?: Maybe<EmailEvents_Mutation_Response>;
  /** delete single row from the table: "EmailEvents" */
  delete_EmailEvents_by_pk?: Maybe<EmailEvents>;
  /** delete data from the table: "EmailRecords" */
  delete_EmailRecords?: Maybe<EmailRecords_Mutation_Response>;
  /** delete single row from the table: "EmailRecords" */
  delete_EmailRecords_by_pk?: Maybe<EmailRecords>;
  /** delete data from the table: "EmailSignatures" */
  delete_EmailSignatures?: Maybe<EmailSignatures_Mutation_Response>;
  /** delete single row from the table: "EmailSignatures" */
  delete_EmailSignatures_by_pk?: Maybe<EmailSignatures>;
  /** delete data from the table: "EmailSubFolders" */
  delete_EmailSubFolders?: Maybe<EmailSubFolders_Mutation_Response>;
  /** delete single row from the table: "EmailSubFolders" */
  delete_EmailSubFolders_by_pk?: Maybe<EmailSubFolders>;
  /** delete data from the table: "EmailTypeEnum" */
  delete_EmailTypeEnum?: Maybe<EmailTypeEnum_Mutation_Response>;
  /** delete single row from the table: "EmailTypeEnum" */
  delete_EmailTypeEnum_by_pk?: Maybe<EmailTypeEnum>;
  /** delete data from the table: "EmailTypes" */
  delete_EmailTypes?: Maybe<EmailTypes_Mutation_Response>;
  /** delete single row from the table: "EmailTypes" */
  delete_EmailTypes_by_pk?: Maybe<EmailTypes>;
  /** delete data from the table: "Emails" */
  delete_Emails?: Maybe<Emails_Mutation_Response>;
  /** delete single row from the table: "Emails" */
  delete_Emails_by_pk?: Maybe<Emails>;
  /** delete data from the table: "EmploymentHireType" */
  delete_EmploymentHireType?: Maybe<EmploymentHireType_Mutation_Response>;
  /** delete single row from the table: "EmploymentHireType" */
  delete_EmploymentHireType_by_pk?: Maybe<EmploymentHireType>;
  /** delete data from the table: "EmploymentTypes" */
  delete_EmploymentTypes?: Maybe<EmploymentTypes_Mutation_Response>;
  /** delete single row from the table: "EmploymentTypes" */
  delete_EmploymentTypes_by_pk?: Maybe<EmploymentTypes>;
  /** delete data from the table: "Employments" */
  delete_Employments?: Maybe<Employments_Mutation_Response>;
  /** delete single row from the table: "Employments" */
  delete_Employments_by_pk?: Maybe<Employments>;
  /** delete data from the table: "EntitlementCategories" */
  delete_EntitlementCategories?: Maybe<EntitlementCategories_Mutation_Response>;
  /** delete single row from the table: "EntitlementCategories" */
  delete_EntitlementCategories_by_pk?: Maybe<EntitlementCategories>;
  /** delete data from the table: "Entitlements" */
  delete_Entitlements?: Maybe<Entitlements_Mutation_Response>;
  /** delete single row from the table: "Entitlements" */
  delete_Entitlements_by_pk?: Maybe<Entitlements>;
  /** delete data from the table: "EquationFormulaOption" */
  delete_EquationFormulaOption?: Maybe<EquationFormulaOption_Mutation_Response>;
  /** delete single row from the table: "EquationFormulaOption" */
  delete_EquationFormulaOption_by_pk?: Maybe<EquationFormulaOption>;
  /** delete data from the table: "Equations" */
  delete_Equations?: Maybe<Equations_Mutation_Response>;
  /** delete single row from the table: "Equations" */
  delete_Equations_by_pk?: Maybe<Equations>;
  /** delete data from the table: "Events" */
  delete_Events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "Events" */
  delete_Events_by_pk?: Maybe<Events>;
  /** delete data from the table: "Expense" */
  delete_Expense?: Maybe<Expense_Mutation_Response>;
  /** delete single row from the table: "Expense" */
  delete_Expense_by_pk?: Maybe<Expense>;
  /** delete data from the table: "ExternalIntegrationTypes" */
  delete_ExternalIntegrationTypes?: Maybe<ExternalIntegrationTypes_Mutation_Response>;
  /** delete single row from the table: "ExternalIntegrationTypes" */
  delete_ExternalIntegrationTypes_by_pk?: Maybe<ExternalIntegrationTypes>;
  /** delete data from the table: "ExternalIntegrations" */
  delete_ExternalIntegrations?: Maybe<ExternalIntegrations_Mutation_Response>;
  /** delete single row from the table: "ExternalIntegrations" */
  delete_ExternalIntegrations_by_pk?: Maybe<ExternalIntegrations>;
  /** delete data from the table: "ExternalJobBoardPublishings" */
  delete_ExternalJobBoardPublishings?: Maybe<ExternalJobBoardPublishings_Mutation_Response>;
  /** delete single row from the table: "ExternalJobBoardPublishings" */
  delete_ExternalJobBoardPublishings_by_pk?: Maybe<ExternalJobBoardPublishings>;
  /** delete data from the table: "ExternalJobBoards" */
  delete_ExternalJobBoards?: Maybe<ExternalJobBoards_Mutation_Response>;
  /** delete single row from the table: "ExternalJobBoards" */
  delete_ExternalJobBoards_by_pk?: Maybe<ExternalJobBoards>;
  /** delete data from the table: "ExternalSystem" */
  delete_ExternalSystem?: Maybe<ExternalSystem_Mutation_Response>;
  /** delete data from the table: "ExternalSystemInstance" */
  delete_ExternalSystemInstance?: Maybe<ExternalSystemInstance_Mutation_Response>;
  /** delete single row from the table: "ExternalSystemInstance" */
  delete_ExternalSystemInstance_by_pk?: Maybe<ExternalSystemInstance>;
  /** delete single row from the table: "ExternalSystem" */
  delete_ExternalSystem_by_pk?: Maybe<ExternalSystem>;
  /** delete data from the table: "FeatureFlags" */
  delete_FeatureFlags?: Maybe<FeatureFlags_Mutation_Response>;
  /** delete single row from the table: "FeatureFlags" */
  delete_FeatureFlags_by_pk?: Maybe<FeatureFlags>;
  /** delete data from the table: "Field" */
  delete_Field?: Maybe<Field_Mutation_Response>;
  /** delete data from the table: "FieldDef" */
  delete_FieldDef?: Maybe<FieldDef_Mutation_Response>;
  /** delete single row from the table: "FieldDef" */
  delete_FieldDef_by_pk?: Maybe<FieldDef>;
  /** delete data from the table: "FieldPicklistValues" */
  delete_FieldPicklistValues?: Maybe<FieldPicklistValues_Mutation_Response>;
  /** delete single row from the table: "FieldPicklistValues" */
  delete_FieldPicklistValues_by_pk?: Maybe<FieldPicklistValues>;
  /** delete data from the table: "FieldTypeDef" */
  delete_FieldTypeDef?: Maybe<FieldTypeDef_Mutation_Response>;
  /** delete single row from the table: "FieldTypeDef" */
  delete_FieldTypeDef_by_pk?: Maybe<FieldTypeDef>;
  /** delete single row from the table: "Field" */
  delete_Field_by_pk?: Maybe<Field>;
  /** delete data from the table: "FileTags" */
  delete_FileTags?: Maybe<FileTags_Mutation_Response>;
  /** delete single row from the table: "FileTags" */
  delete_FileTags_by_pk?: Maybe<FileTags>;
  /** delete data from the table: "Files" */
  delete_Files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "Files" */
  delete_Files_by_pk?: Maybe<Files>;
  /** delete data from the table: "FormInputs" */
  delete_FormInputs?: Maybe<FormInputs_Mutation_Response>;
  /** delete single row from the table: "FormInputs" */
  delete_FormInputs_by_pk?: Maybe<FormInputs>;
  /** delete data from the table: "FormSelectOptions" */
  delete_FormSelectOptions?: Maybe<FormSelectOptions_Mutation_Response>;
  /** delete single row from the table: "FormSelectOptions" */
  delete_FormSelectOptions_by_pk?: Maybe<FormSelectOptions>;
  /** delete data from the table: "FormulaOptions" */
  delete_FormulaOptions?: Maybe<FormulaOptions_Mutation_Response>;
  /** delete single row from the table: "FormulaOptions" */
  delete_FormulaOptions_by_pk?: Maybe<FormulaOptions>;
  /** delete data from the table: "GenderEnum" */
  delete_GenderEnum?: Maybe<GenderEnum_Mutation_Response>;
  /** delete single row from the table: "GenderEnum" */
  delete_GenderEnum_by_pk?: Maybe<GenderEnum>;
  /** delete data from the table: "GeocodingHistory" */
  delete_GeocodingHistory?: Maybe<GeocodingHistory_Mutation_Response>;
  /** delete single row from the table: "GeocodingHistory" */
  delete_GeocodingHistory_by_pk?: Maybe<GeocodingHistory>;
  /** delete data from the table: "Goals" */
  delete_Goals?: Maybe<Goals_Mutation_Response>;
  /** delete single row from the table: "Goals" */
  delete_Goals_by_pk?: Maybe<Goals>;
  /** delete data from the table: "GoogleSheetCalcTemplates" */
  delete_GoogleSheetCalcTemplates?: Maybe<GoogleSheetCalcTemplates_Mutation_Response>;
  /** delete single row from the table: "GoogleSheetCalcTemplates" */
  delete_GoogleSheetCalcTemplates_by_pk?: Maybe<GoogleSheetCalcTemplates>;
  /** delete data from the table: "GoogleSheetCalculations" */
  delete_GoogleSheetCalculations?: Maybe<GoogleSheetCalculations_Mutation_Response>;
  /** delete single row from the table: "GoogleSheetCalculations" */
  delete_GoogleSheetCalculations_by_pk?: Maybe<GoogleSheetCalculations>;
  /** delete data from the table: "GroupJobs" */
  delete_GroupJobs?: Maybe<GroupJobs_Mutation_Response>;
  /** delete single row from the table: "GroupJobs" */
  delete_GroupJobs_by_pk?: Maybe<GroupJobs>;
  /** delete data from the table: "Groups" */
  delete_Groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "Groups" */
  delete_Groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "HighestStages" */
  delete_HighestStages?: Maybe<HighestStages_Mutation_Response>;
  /** delete single row from the table: "HighestStages" */
  delete_HighestStages_by_pk?: Maybe<HighestStages>;
  /** delete data from the table: "HireTypesEnum" */
  delete_HireTypesEnum?: Maybe<HireTypesEnum_Mutation_Response>;
  /** delete single row from the table: "HireTypesEnum" */
  delete_HireTypesEnum_by_pk?: Maybe<HireTypesEnum>;
  /** delete data from the table: "ITRequestContractEmails" */
  delete_ITRequestContractEmails?: Maybe<ItRequestContractEmails_Mutation_Response>;
  /** delete single row from the table: "ITRequestContractEmails" */
  delete_ITRequestContractEmails_by_pk?: Maybe<ItRequestContractEmails>;
  /** delete data from the table: "Identities" */
  delete_Identities?: Maybe<Identities_Mutation_Response>;
  /** delete data from the table: "IdentitiesOverlaysLinks" */
  delete_IdentitiesOverlaysLinks?: Maybe<IdentitiesOverlaysLinks_Mutation_Response>;
  /** delete single row from the table: "IdentitiesOverlaysLinks" */
  delete_IdentitiesOverlaysLinks_by_pk?: Maybe<IdentitiesOverlaysLinks>;
  /** delete single row from the table: "Identities" */
  delete_Identities_by_pk?: Maybe<Identities>;
  /** delete data from the table: "IdentityAssignments" */
  delete_IdentityAssignments?: Maybe<IdentityAssignments_Mutation_Response>;
  /** delete single row from the table: "IdentityAssignments" */
  delete_IdentityAssignments_by_pk?: Maybe<IdentityAssignments>;
  /** delete data from the table: "IdentityComments" */
  delete_IdentityComments?: Maybe<IdentityComments_Mutation_Response>;
  /** delete single row from the table: "IdentityComments" */
  delete_IdentityComments_by_pk?: Maybe<IdentityComments>;
  /** delete data from the table: "IdentityContractRelationship" */
  delete_IdentityContractRelationship?: Maybe<IdentityContractRelationship_Mutation_Response>;
  /** delete single row from the table: "IdentityContractRelationship" */
  delete_IdentityContractRelationship_by_pk?: Maybe<IdentityContractRelationship>;
  /** delete data from the table: "IdentityDepartmentRelationship" */
  delete_IdentityDepartmentRelationship?: Maybe<IdentityDepartmentRelationship_Mutation_Response>;
  /** delete single row from the table: "IdentityDepartmentRelationship" */
  delete_IdentityDepartmentRelationship_by_pk?: Maybe<IdentityDepartmentRelationship>;
  /** delete data from the table: "IdentityGroups" */
  delete_IdentityGroups?: Maybe<IdentityGroups_Mutation_Response>;
  /** delete single row from the table: "IdentityGroups" */
  delete_IdentityGroups_by_pk?: Maybe<IdentityGroups>;
  /** delete data from the table: "IdentityJobRelationship" */
  delete_IdentityJobRelationship?: Maybe<IdentityJobRelationship_Mutation_Response>;
  /** delete single row from the table: "IdentityJobRelationship" */
  delete_IdentityJobRelationship_by_pk?: Maybe<IdentityJobRelationship>;
  /** delete data from the table: "IdentityPartnerGroupData" */
  delete_IdentityPartnerGroupData?: Maybe<IdentityPartnerGroupData_Mutation_Response>;
  /** delete single row from the table: "IdentityPartnerGroupData" */
  delete_IdentityPartnerGroupData_by_pk?: Maybe<IdentityPartnerGroupData>;
  /** delete data from the table: "IdentityRelationshipType" */
  delete_IdentityRelationshipType?: Maybe<IdentityRelationshipType_Mutation_Response>;
  /** delete single row from the table: "IdentityRelationshipType" */
  delete_IdentityRelationshipType_by_pk?: Maybe<IdentityRelationshipType>;
  /** delete data from the table: "IdentityRoles" */
  delete_IdentityRoles?: Maybe<IdentityRoles_Mutation_Response>;
  /** delete data from the table: "IdentitySubTypeEmails" */
  delete_IdentitySubTypeEmails?: Maybe<IdentitySubTypeEmails_Mutation_Response>;
  /** delete single row from the table: "IdentitySubTypeEmails" */
  delete_IdentitySubTypeEmails_by_pk?: Maybe<IdentitySubTypeEmails>;
  /** delete data from the table: "IdentitySubTypes" */
  delete_IdentitySubTypes?: Maybe<IdentitySubTypes_Mutation_Response>;
  /** delete single row from the table: "IdentitySubTypes" */
  delete_IdentitySubTypes_by_pk?: Maybe<IdentitySubTypes>;
  /** delete data from the table: "IdentityTags" */
  delete_IdentityTags?: Maybe<IdentityTags_Mutation_Response>;
  /** delete single row from the table: "IdentityTags" */
  delete_IdentityTags_by_pk?: Maybe<IdentityTags>;
  /** delete data from the table: "IdentityTokens" */
  delete_IdentityTokens?: Maybe<IdentityTokens_Mutation_Response>;
  /** delete single row from the table: "IdentityTokens" */
  delete_IdentityTokens_by_pk?: Maybe<IdentityTokens>;
  /** delete data from the table: "IdentityTypes" */
  delete_IdentityTypes?: Maybe<IdentityTypes_Mutation_Response>;
  /** delete single row from the table: "IdentityTypes" */
  delete_IdentityTypes_by_pk?: Maybe<IdentityTypes>;
  /** delete data from the table: "IdentityUserAccessGroup" */
  delete_IdentityUserAccessGroup?: Maybe<IdentityUserAccessGroup_Mutation_Response>;
  /** delete single row from the table: "IdentityUserAccessGroup" */
  delete_IdentityUserAccessGroup_by_pk?: Maybe<IdentityUserAccessGroup>;
  /** delete data from the table: "Images" */
  delete_Images?: Maybe<Images_Mutation_Response>;
  /** delete single row from the table: "Images" */
  delete_Images_by_pk?: Maybe<Images>;
  /** delete data from the table: "InputTypes" */
  delete_InputTypes?: Maybe<InputTypes_Mutation_Response>;
  /** delete single row from the table: "InputTypes" */
  delete_InputTypes_by_pk?: Maybe<InputTypes>;
  /** delete data from the table: "InternalJobCategories" */
  delete_InternalJobCategories?: Maybe<InternalJobCategories_Mutation_Response>;
  /** delete single row from the table: "InternalJobCategories" */
  delete_InternalJobCategories_by_pk?: Maybe<InternalJobCategories>;
  /** delete data from the table: "Interviews" */
  delete_Interviews?: Maybe<Interviews_Mutation_Response>;
  /** delete single row from the table: "Interviews" */
  delete_Interviews_by_pk?: Maybe<Interviews>;
  /** delete data from the table: "JobAvailabilities" */
  delete_JobAvailabilities?: Maybe<JobAvailabilities_Mutation_Response>;
  /** delete single row from the table: "JobAvailabilities" */
  delete_JobAvailabilities_by_pk?: Maybe<JobAvailabilities>;
  /** delete data from the table: "JobComments" */
  delete_JobComments?: Maybe<JobComments_Mutation_Response>;
  /** delete single row from the table: "JobComments" */
  delete_JobComments_by_pk?: Maybe<JobComments>;
  /** delete data from the table: "JobFiles" */
  delete_JobFiles?: Maybe<JobFiles_Mutation_Response>;
  /** delete single row from the table: "JobFiles" */
  delete_JobFiles_by_pk?: Maybe<JobFiles>;
  /** delete data from the table: "JobFundingStatusEnum" */
  delete_JobFundingStatusEnum?: Maybe<JobFundingStatusEnum_Mutation_Response>;
  /** delete single row from the table: "JobFundingStatusEnum" */
  delete_JobFundingStatusEnum_by_pk?: Maybe<JobFundingStatusEnum>;
  /** delete data from the table: "JobHireTypesEnum" */
  delete_JobHireTypesEnum?: Maybe<JobHireTypesEnum_Mutation_Response>;
  /** delete single row from the table: "JobHireTypesEnum" */
  delete_JobHireTypesEnum_by_pk?: Maybe<JobHireTypesEnum>;
  /** delete data from the table: "JobPositions" */
  delete_JobPositions?: Maybe<JobPositions_Mutation_Response>;
  /** delete single row from the table: "JobPositions" */
  delete_JobPositions_by_pk?: Maybe<JobPositions>;
  /** delete data from the table: "JobStatuses" */
  delete_JobStatuses?: Maybe<JobStatuses_Mutation_Response>;
  /** delete single row from the table: "JobStatuses" */
  delete_JobStatuses_by_pk?: Maybe<JobStatuses>;
  /** delete data from the table: "Jobs" */
  delete_Jobs?: Maybe<Jobs_Mutation_Response>;
  /** delete single row from the table: "Jobs" */
  delete_Jobs_by_pk?: Maybe<Jobs>;
  /** delete data from the table: "MagicLinks" */
  delete_MagicLinks?: Maybe<MagicLinks_Mutation_Response>;
  /** delete single row from the table: "MagicLinks" */
  delete_MagicLinks_by_pk?: Maybe<MagicLinks>;
  /** delete data from the table: "MigrationData" */
  delete_MigrationData?: Maybe<MigrationData_Mutation_Response>;
  /** delete single row from the table: "MigrationData" */
  delete_MigrationData_by_pk?: Maybe<MigrationData>;
  /** delete data from the table: "ObjectZoo" */
  delete_ObjectZoo?: Maybe<ObjectZoo_Mutation_Response>;
  /** delete single row from the table: "ObjectZoo" */
  delete_ObjectZoo_by_pk?: Maybe<ObjectZoo>;
  /** delete data from the table: "Overlay" */
  delete_Overlay?: Maybe<Overlay_Mutation_Response>;
  /** delete data from the table: "OverlayDefinition" */
  delete_OverlayDefinition?: Maybe<OverlayDefinition_Mutation_Response>;
  /** delete single row from the table: "OverlayDefinition" */
  delete_OverlayDefinition_by_pk?: Maybe<OverlayDefinition>;
  /** delete single row from the table: "Overlay" */
  delete_Overlay_by_pk?: Maybe<Overlay>;
  /** delete data from the table: "PhoneNumbers" */
  delete_PhoneNumbers?: Maybe<PhoneNumbers_Mutation_Response>;
  /** delete single row from the table: "PhoneNumbers" */
  delete_PhoneNumbers_by_pk?: Maybe<PhoneNumbers>;
  /** delete data from the table: "PicklistValues" */
  delete_PicklistValues?: Maybe<PicklistValues_Mutation_Response>;
  /** delete single row from the table: "PicklistValues" */
  delete_PicklistValues_by_pk?: Maybe<PicklistValues>;
  /** delete data from the table: "Picklists" */
  delete_Picklists?: Maybe<Picklists_Mutation_Response>;
  /** delete single row from the table: "Picklists" */
  delete_Picklists_by_pk?: Maybe<Picklists>;
  /** delete data from the table: "ProductUpdates" */
  delete_ProductUpdates?: Maybe<ProductUpdates_Mutation_Response>;
  /** delete single row from the table: "ProductUpdates" */
  delete_ProductUpdates_by_pk?: Maybe<ProductUpdates>;
  /** delete data from the table: "QuestionnaireContactTemplateVariables" */
  delete_QuestionnaireContactTemplateVariables?: Maybe<QuestionnaireContactTemplateVariables_Mutation_Response>;
  /** delete single row from the table: "QuestionnaireContactTemplateVariables" */
  delete_QuestionnaireContactTemplateVariables_by_pk?: Maybe<QuestionnaireContactTemplateVariables>;
  /** delete data from the table: "QuestionnaireLinks" */
  delete_QuestionnaireLinks?: Maybe<QuestionnaireLinks_Mutation_Response>;
  /** delete single row from the table: "QuestionnaireLinks" */
  delete_QuestionnaireLinks_by_pk?: Maybe<QuestionnaireLinks>;
  /** delete data from the table: "QuestionnaireResponses" */
  delete_QuestionnaireResponses?: Maybe<QuestionnaireResponses_Mutation_Response>;
  /** delete single row from the table: "QuestionnaireResponses" */
  delete_QuestionnaireResponses_by_pk?: Maybe<QuestionnaireResponses>;
  /** delete data from the table: "Questionnaires" */
  delete_Questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** delete single row from the table: "Questionnaires" */
  delete_Questionnaires_by_pk?: Maybe<Questionnaires>;
  /** delete data from the table: "RRWebRecords" */
  delete_RRWebRecords?: Maybe<RrWebRecords_Mutation_Response>;
  /** delete single row from the table: "RRWebRecords" */
  delete_RRWebRecords_by_pk?: Maybe<RrWebRecords>;
  /** delete data from the table: "RaceEnum" */
  delete_RaceEnum?: Maybe<RaceEnum_Mutation_Response>;
  /** delete single row from the table: "RaceEnum" */
  delete_RaceEnum_by_pk?: Maybe<RaceEnum>;
  /** delete data from the table: "RangeValues" */
  delete_RangeValues?: Maybe<RangeValues_Mutation_Response>;
  /** delete single row from the table: "RangeValues" */
  delete_RangeValues_by_pk?: Maybe<RangeValues>;
  /** delete data from the table: "RejectionTypeEnum" */
  delete_RejectionTypeEnum?: Maybe<RejectionTypeEnum_Mutation_Response>;
  /** delete single row from the table: "RejectionTypeEnum" */
  delete_RejectionTypeEnum_by_pk?: Maybe<RejectionTypeEnum>;
  /** delete data from the table: "Rejections" */
  delete_Rejections?: Maybe<Rejections_Mutation_Response>;
  /** delete single row from the table: "Rejections" */
  delete_Rejections_by_pk?: Maybe<Rejections>;
  /** delete data from the table: "RenderedEmails" */
  delete_RenderedEmails?: Maybe<RenderedEmails_Mutation_Response>;
  /** delete single row from the table: "RenderedEmails" */
  delete_RenderedEmails_by_pk?: Maybe<RenderedEmails>;
  /** delete data from the table: "ResumeClaims" */
  delete_ResumeClaims?: Maybe<ResumeClaims_Mutation_Response>;
  /** delete single row from the table: "ResumeClaims" */
  delete_ResumeClaims_by_pk?: Maybe<ResumeClaims>;
  /** delete data from the table: "ResumeEmailAddresses" */
  delete_ResumeEmailAddresses?: Maybe<ResumeEmailAddresses_Mutation_Response>;
  /** delete single row from the table: "ResumeEmailAddresses" */
  delete_ResumeEmailAddresses_by_pk?: Maybe<ResumeEmailAddresses>;
  /** delete data from the table: "ResumeIngestStatus" */
  delete_ResumeIngestStatus?: Maybe<ResumeIngestStatus_Mutation_Response>;
  /** delete single row from the table: "ResumeIngestStatus" */
  delete_ResumeIngestStatus_by_pk?: Maybe<ResumeIngestStatus>;
  /** delete data from the table: "ResumePhoneNumbers" */
  delete_ResumePhoneNumbers?: Maybe<ResumePhoneNumbers_Mutation_Response>;
  /** delete single row from the table: "ResumePhoneNumbers" */
  delete_ResumePhoneNumbers_by_pk?: Maybe<ResumePhoneNumbers>;
  /** delete data from the table: "Resumes" */
  delete_Resumes?: Maybe<Resumes_Mutation_Response>;
  /** delete single row from the table: "Resumes" */
  delete_Resumes_by_pk?: Maybe<Resumes>;
  /** delete data from the table: "ReviewCriteria" */
  delete_ReviewCriteria?: Maybe<ReviewCriteria_Mutation_Response>;
  /** delete data from the table: "ReviewCriteriaScore" */
  delete_ReviewCriteriaScore?: Maybe<ReviewCriteriaScore_Mutation_Response>;
  /** delete single row from the table: "ReviewCriteriaScore" */
  delete_ReviewCriteriaScore_by_pk?: Maybe<ReviewCriteriaScore>;
  /** delete single row from the table: "ReviewCriteria" */
  delete_ReviewCriteria_by_pk?: Maybe<ReviewCriteria>;
  /** delete data from the table: "ReviewGroupContactTemplateVariables" */
  delete_ReviewGroupContactTemplateVariables?: Maybe<ReviewGroupContactTemplateVariables_Mutation_Response>;
  /** delete single row from the table: "ReviewGroupContactTemplateVariables" */
  delete_ReviewGroupContactTemplateVariables_by_pk?: Maybe<ReviewGroupContactTemplateVariables>;
  /** delete data from the table: "ReviewGroupCriteria" */
  delete_ReviewGroupCriteria?: Maybe<ReviewGroupCriteria_Mutation_Response>;
  /** delete single row from the table: "ReviewGroupCriteria" */
  delete_ReviewGroupCriteria_by_pk?: Maybe<ReviewGroupCriteria>;
  /** delete data from the table: "ReviewGroupFiles" */
  delete_ReviewGroupFiles?: Maybe<ReviewGroupFiles_Mutation_Response>;
  /** delete single row from the table: "ReviewGroupFiles" */
  delete_ReviewGroupFiles_by_pk?: Maybe<ReviewGroupFiles>;
  /** delete data from the table: "ReviewGroupStages" */
  delete_ReviewGroupStages?: Maybe<ReviewGroupStages_Mutation_Response>;
  /** delete single row from the table: "ReviewGroupStages" */
  delete_ReviewGroupStages_by_pk?: Maybe<ReviewGroupStages>;
  /** delete data from the table: "ReviewGroupSubTypeEnum" */
  delete_ReviewGroupSubTypeEnum?: Maybe<ReviewGroupSubTypeEnum_Mutation_Response>;
  /** delete single row from the table: "ReviewGroupSubTypeEnum" */
  delete_ReviewGroupSubTypeEnum_by_pk?: Maybe<ReviewGroupSubTypeEnum>;
  /** delete data from the table: "ReviewGroupTypeEnum" */
  delete_ReviewGroupTypeEnum?: Maybe<ReviewGroupTypeEnum_Mutation_Response>;
  /** delete single row from the table: "ReviewGroupTypeEnum" */
  delete_ReviewGroupTypeEnum_by_pk?: Maybe<ReviewGroupTypeEnum>;
  /** delete data from the table: "ReviewGroups" */
  delete_ReviewGroups?: Maybe<ReviewGroups_Mutation_Response>;
  /** delete single row from the table: "ReviewGroups" */
  delete_ReviewGroups_by_pk?: Maybe<ReviewGroups>;
  /** delete data from the table: "Reviews" */
  delete_Reviews?: Maybe<Reviews_Mutation_Response>;
  /** delete data from the table: "ReviewsResultEnum" */
  delete_ReviewsResultEnum?: Maybe<ReviewsResultEnum_Mutation_Response>;
  /** delete single row from the table: "ReviewsResultEnum" */
  delete_ReviewsResultEnum_by_pk?: Maybe<ReviewsResultEnum>;
  /** delete single row from the table: "Reviews" */
  delete_Reviews_by_pk?: Maybe<Reviews>;
  /** delete data from the table: "RoleAccess" */
  delete_RoleAccess?: Maybe<RoleAccess_Mutation_Response>;
  /** delete single row from the table: "RoleAccess" */
  delete_RoleAccess_by_pk?: Maybe<RoleAccess>;
  /** delete data from the table: "Roles" */
  delete_Roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "Roles" */
  delete_Roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "SearchFilterTypesEnum" */
  delete_SearchFilterTypesEnum?: Maybe<SearchFilterTypesEnum_Mutation_Response>;
  /** delete single row from the table: "SearchFilterTypesEnum" */
  delete_SearchFilterTypesEnum_by_pk?: Maybe<SearchFilterTypesEnum>;
  /** delete data from the table: "SearchFilters" */
  delete_SearchFilters?: Maybe<SearchFilters_Mutation_Response>;
  /** delete single row from the table: "SearchFilters" */
  delete_SearchFilters_by_pk?: Maybe<SearchFilters>;
  /** delete data from the table: "SecurityClearances" */
  delete_SecurityClearances?: Maybe<SecurityClearances_Mutation_Response>;
  /** delete single row from the table: "SecurityClearances" */
  delete_SecurityClearances_by_pk?: Maybe<SecurityClearances>;
  /** delete data from the table: "SequelizeMeta" */
  delete_SequelizeMeta?: Maybe<SequelizeMeta_Mutation_Response>;
  /** delete single row from the table: "SequelizeMeta" */
  delete_SequelizeMeta_by_pk?: Maybe<SequelizeMeta>;
  /** delete data from the table: "StageEmailContracts" */
  delete_StageEmailContracts?: Maybe<StageEmailContracts_Mutation_Response>;
  /** delete single row from the table: "StageEmailContracts" */
  delete_StageEmailContracts_by_pk?: Maybe<StageEmailContracts>;
  /** delete data from the table: "StageEmails" */
  delete_StageEmails?: Maybe<StageEmails_Mutation_Response>;
  /** delete single row from the table: "StageEmails" */
  delete_StageEmails_by_pk?: Maybe<StageEmails>;
  /** delete data from the table: "StageQuestionnaireMapping" */
  delete_StageQuestionnaireMapping?: Maybe<StageQuestionnaireMapping_Mutation_Response>;
  /** delete data from the table: "StageQuestionnaireMappingIdenity" */
  delete_StageQuestionnaireMappingIdenity?: Maybe<StageQuestionnaireMappingIdenity_Mutation_Response>;
  /** delete single row from the table: "StageQuestionnaireMappingIdenity" */
  delete_StageQuestionnaireMappingIdenity_by_pk?: Maybe<StageQuestionnaireMappingIdenity>;
  /** delete single row from the table: "StageQuestionnaireMapping" */
  delete_StageQuestionnaireMapping_by_pk?: Maybe<StageQuestionnaireMapping>;
  /** delete data from the table: "StageQuestionnaireTemplateVariables" */
  delete_StageQuestionnaireTemplateVariables?: Maybe<StageQuestionnaireTemplateVariables_Mutation_Response>;
  /** delete single row from the table: "StageQuestionnaireTemplateVariables" */
  delete_StageQuestionnaireTemplateVariables_by_pk?: Maybe<StageQuestionnaireTemplateVariables>;
  /** delete data from the table: "StageRoles" */
  delete_StageRoles?: Maybe<StageRoles_Mutation_Response>;
  /** delete single row from the table: "StageRoles" */
  delete_StageRoles_by_pk?: Maybe<StageRoles>;
  /** delete data from the table: "StageTransitions" */
  delete_StageTransitions?: Maybe<StageTransitions_Mutation_Response>;
  /** delete single row from the table: "StageTransitions" */
  delete_StageTransitions_by_pk?: Maybe<StageTransitions>;
  /** delete data from the table: "Stages" */
  delete_Stages?: Maybe<Stages_Mutation_Response>;
  /** delete single row from the table: "Stages" */
  delete_Stages_by_pk?: Maybe<Stages>;
  /** delete data from the table: "StateMachines" */
  delete_StateMachines?: Maybe<StateMachines_Mutation_Response>;
  /** delete single row from the table: "StateMachines" */
  delete_StateMachines_by_pk?: Maybe<StateMachines>;
  /** delete data from the table: "Submissions" */
  delete_Submissions?: Maybe<Submissions_Mutation_Response>;
  /** delete data from the table: "SubmissionsOverlaysLinks" */
  delete_SubmissionsOverlaysLinks?: Maybe<SubmissionsOverlaysLinks_Mutation_Response>;
  /** delete single row from the table: "SubmissionsOverlaysLinks" */
  delete_SubmissionsOverlaysLinks_by_pk?: Maybe<SubmissionsOverlaysLinks>;
  /** delete single row from the table: "Submissions" */
  delete_Submissions_by_pk?: Maybe<Submissions>;
  /** delete data from the table: "TableConfigTypes" */
  delete_TableConfigTypes?: Maybe<TableConfigTypes_Mutation_Response>;
  /** delete single row from the table: "TableConfigTypes" */
  delete_TableConfigTypes_by_pk?: Maybe<TableConfigTypes>;
  /** delete data from the table: "TableConfigs" */
  delete_TableConfigs?: Maybe<TableConfigs_Mutation_Response>;
  /** delete single row from the table: "TableConfigs" */
  delete_TableConfigs_by_pk?: Maybe<TableConfigs>;
  /** delete data from the table: "Tags" */
  delete_Tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "Tags" */
  delete_Tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "TaskGroupAssignees" */
  delete_TaskGroupAssignees?: Maybe<TaskGroupAssignees_Mutation_Response>;
  /** delete single row from the table: "TaskGroupAssignees" */
  delete_TaskGroupAssignees_by_pk?: Maybe<TaskGroupAssignees>;
  /** delete data from the table: "TaskGroups" */
  delete_TaskGroups?: Maybe<TaskGroups_Mutation_Response>;
  /** delete single row from the table: "TaskGroups" */
  delete_TaskGroups_by_pk?: Maybe<TaskGroups>;
  /** delete data from the table: "TaskPriorityTypes" */
  delete_TaskPriorityTypes?: Maybe<TaskPriorityTypes_Mutation_Response>;
  /** delete single row from the table: "TaskPriorityTypes" */
  delete_TaskPriorityTypes_by_pk?: Maybe<TaskPriorityTypes>;
  /** delete data from the table: "TaskTypes" */
  delete_TaskTypes?: Maybe<TaskTypes_Mutation_Response>;
  /** delete single row from the table: "TaskTypes" */
  delete_TaskTypes_by_pk?: Maybe<TaskTypes>;
  /** delete data from the table: "Tasks" */
  delete_Tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "Tasks" */
  delete_Tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "Terminations" */
  delete_Terminations?: Maybe<Terminations_Mutation_Response>;
  /** delete single row from the table: "Terminations" */
  delete_Terminations_by_pk?: Maybe<Terminations>;
  /** delete data from the table: "TimeInStages" */
  delete_TimeInStages?: Maybe<TimeInStages_Mutation_Response>;
  /** delete single row from the table: "TimeInStages" */
  delete_TimeInStages_by_pk?: Maybe<TimeInStages>;
  /** delete data from the table: "TimelineEvents" */
  delete_TimelineEvents?: Maybe<TimelineEvents_Mutation_Response>;
  /** delete single row from the table: "TimelineEvents" */
  delete_TimelineEvents_by_pk?: Maybe<TimelineEvents>;
  /** delete data from the table: "Tokens" */
  delete_Tokens?: Maybe<Tokens_Mutation_Response>;
  /** delete single row from the table: "Tokens" */
  delete_Tokens_by_pk?: Maybe<Tokens>;
  /** delete data from the table: "UserAccessGroups" */
  delete_UserAccessGroups?: Maybe<UserAccessGroups_Mutation_Response>;
  /** delete single row from the table: "UserAccessGroups" */
  delete_UserAccessGroups_by_pk?: Maybe<UserAccessGroups>;
  /** delete data from the table: "UserInterfaceState" */
  delete_UserInterfaceState?: Maybe<UserInterfaceState_Mutation_Response>;
  /** delete single row from the table: "UserInterfaceState" */
  delete_UserInterfaceState_by_pk?: Maybe<UserInterfaceState>;
  /** delete data from the table: "Users" */
  delete_Users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "Users" */
  delete_Users_by_pk?: Maybe<Users>;
  /** delete data from the table: "ValueTypes" */
  delete_ValueTypes?: Maybe<ValueTypes_Mutation_Response>;
  /** delete single row from the table: "ValueTypes" */
  delete_ValueTypes_by_pk?: Maybe<ValueTypes>;
  /** delete data from the table: "VeteranStatusEnum" */
  delete_VeteranStatusEnum?: Maybe<VeteranStatusEnum_Mutation_Response>;
  /** delete single row from the table: "VeteranStatusEnum" */
  delete_VeteranStatusEnum_by_pk?: Maybe<VeteranStatusEnum>;
  /** delete data from the table: "WhiteLabeling" */
  delete_WhiteLabeling?: Maybe<WhiteLabeling_Mutation_Response>;
  /** delete single row from the table: "WhiteLabeling" */
  delete_WhiteLabeling_by_pk?: Maybe<WhiteLabeling>;
  /** delete data from the table: "WorkflowRegressions" */
  delete_WorkflowRegressions?: Maybe<WorkflowRegressions_Mutation_Response>;
  /** delete single row from the table: "WorkflowRegressions" */
  delete_WorkflowRegressions_by_pk?: Maybe<WorkflowRegressions>;
  /** delete data from the table: "citizenshipEnum" */
  delete_citizenshipEnum?: Maybe<CitizenshipEnum_Mutation_Response>;
  /** delete single row from the table: "citizenshipEnum" */
  delete_citizenshipEnum_by_pk?: Maybe<CitizenshipEnum>;
  /** delete data from the table: "contract_emails" */
  delete_contract_emails?: Maybe<Contract_Emails_Mutation_Response>;
  /** delete single row from the table: "contract_emails" */
  delete_contract_emails_by_pk?: Maybe<Contract_Emails>;
  /** delete data from the table: "deleted_ppl_names" */
  delete_deleted_ppl_names?: Maybe<Deleted_Ppl_Names_Mutation_Response>;
  /** delete data from the table: "eeo_data_point_status_by_identity_view" */
  delete_eeo_data_point_status_by_identity_view?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Mutation_Response>;
  /** delete data from the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  delete_eeo_data_points_unknown_as_other_by_identity_view?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Mutation_Response>;
  /** delete data from the table: "eeo_status_by_identity" */
  delete_eeo_status_by_identity?: Maybe<Eeo_Status_By_Identity_Mutation_Response>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "identity_roles_arr" */
  delete_identity_roles_arr?: Maybe<Identity_Roles_Arr_Mutation_Response>;
  /** delete data from the table: "identity_roles_stat" */
  delete_identity_roles_stat?: Maybe<Identity_Roles_Stat_Mutation_Response>;
  /** delete data from the table: "jobs_available_positions" */
  delete_jobs_available_positions?: Maybe<Jobs_Available_Positions_Mutation_Response>;
  /** delete data from the table: "matches_from_applications" */
  delete_matches_from_applications?: Maybe<Matches_From_Applications_Mutation_Response>;
  /** delete data from the table: "matches_from_board" */
  delete_matches_from_board?: Maybe<Matches_From_Board_Mutation_Response>;
  /** delete data from the table: "matches_from_tasks" */
  delete_matches_from_tasks?: Maybe<Matches_From_Tasks_Mutation_Response>;
  /** delete data from the table: "nylas_events" */
  delete_nylas_events?: Maybe<Nylas_Events_Mutation_Response>;
  /** delete single row from the table: "nylas_events" */
  delete_nylas_events_by_pk?: Maybe<Nylas_Events>;
  /** delete data from the table: "search_spec_results" */
  delete_search_spec_results?: Maybe<Search_Spec_Results_Mutation_Response>;
  /** delete single row from the table: "search_spec_results" */
  delete_search_spec_results_by_pk?: Maybe<Search_Spec_Results>;
  /** delete data from the table: "search_specs" */
  delete_search_specs?: Maybe<Search_Specs_Mutation_Response>;
  /** delete single row from the table: "search_specs" */
  delete_search_specs_by_pk?: Maybe<Search_Specs>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "staffing_status" */
  delete_staffing_status?: Maybe<Staffing_Status_Mutation_Response>;
  /** delete data from the table: "text_messages" */
  delete_text_messages?: Maybe<Text_Messages_Mutation_Response>;
  /** delete single row from the table: "text_messages" */
  delete_text_messages_by_pk?: Maybe<Text_Messages>;
  /** delete data from the table: "util.deps_saved_ddl" */
  delete_util_deps_saved_ddl?: Maybe<Util_Deps_Saved_Ddl_Mutation_Response>;
  /** delete single row from the table: "util.deps_saved_ddl" */
  delete_util_deps_saved_ddl_by_pk?: Maybe<Util_Deps_Saved_Ddl>;
  /** delete data from the table: "workflow_contracts" */
  delete_workflow_contracts?: Maybe<Workflow_Contracts_Mutation_Response>;
  /** delete single row from the table: "workflow_contracts" */
  delete_workflow_contracts_by_pk?: Maybe<Workflow_Contracts>;
  /** delete data from the table: "workflow_departments" */
  delete_workflow_departments?: Maybe<Workflow_Departments_Mutation_Response>;
  /** delete single row from the table: "workflow_departments" */
  delete_workflow_departments_by_pk?: Maybe<Workflow_Departments>;
  docuSignAuth?: Maybe<DocuSignAuthOutput>;
  docuSignSendDocument?: Maybe<DocuSignSendDocumentOutput>;
  ensureUser?: Maybe<EnsureUserResult>;
  exchangePublicToken?: Maybe<ExchangePublicTokenOutput>;
  getSignedUrl?: Maybe<GetSignedUrlOutput>;
  grantEntitlement?: Maybe<EntitlementGrantOutput>;
  /** Create a new Idibu account */
  idibuCreateAccount?: Maybe<CreateIdibuAccountOutput>;
  idibuGenerateLiveAuthToken?: Maybe<IdibuGenerateLiveAuthToken>;
  idibuSubscribeToBoard?: Maybe<IdibuSubscribeToBoardOutput>;
  /** idibuSyncLiveJobs syncs the ExternalJobBoradPostings table with the idibu live posted jobs api */
  idibuSyncLiveJobs?: Maybe<IdibuSyncLiveJobsOutput>;
  idibuUnpublishJob?: Maybe<IdibuUnpublishJobOutput>;
  idibuUnsubscribeFromBoard?: Maybe<IdibuUnsubscribeFromBoardOutput>;
  /** insert data into the table: "APITokens" */
  insert_APITokens?: Maybe<ApiTokens_Mutation_Response>;
  /** insert a single row into the table: "APITokens" */
  insert_APITokens_one?: Maybe<ApiTokens>;
  /** insert data into the table: "AccessIntents" */
  insert_AccessIntents?: Maybe<AccessIntents_Mutation_Response>;
  /** insert a single row into the table: "AccessIntents" */
  insert_AccessIntents_one?: Maybe<AccessIntents>;
  /** insert data into the table: "AccessTypeEnum" */
  insert_AccessTypeEnum?: Maybe<AccessTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "AccessTypeEnum" */
  insert_AccessTypeEnum_one?: Maybe<AccessTypeEnum>;
  /** insert data into the table: "ActiveEntitlements" */
  insert_ActiveEntitlements?: Maybe<ActiveEntitlements_Mutation_Response>;
  /** insert a single row into the table: "ActiveEntitlements" */
  insert_ActiveEntitlements_one?: Maybe<ActiveEntitlements>;
  /** insert data into the table: "ActivityLogTypes" */
  insert_ActivityLogTypes?: Maybe<ActivityLogTypes_Mutation_Response>;
  /** insert a single row into the table: "ActivityLogTypes" */
  insert_ActivityLogTypes_one?: Maybe<ActivityLogTypes>;
  /** insert data into the table: "ActivityTemplates" */
  insert_ActivityTemplates?: Maybe<ActivityTemplates_Mutation_Response>;
  /** insert a single row into the table: "ActivityTemplates" */
  insert_ActivityTemplates_one?: Maybe<ActivityTemplates>;
  /** insert data into the table: "AoIdentifierTypes" */
  insert_AoIdentifierTypes?: Maybe<AoIdentifierTypes_Mutation_Response>;
  /** insert a single row into the table: "AoIdentifierTypes" */
  insert_AoIdentifierTypes_one?: Maybe<AoIdentifierTypes>;
  /** insert data into the table: "ApplicationStatus" */
  insert_ApplicationStatus?: Maybe<ApplicationStatus_Mutation_Response>;
  /** insert a single row into the table: "ApplicationStatus" */
  insert_ApplicationStatus_one?: Maybe<ApplicationStatus>;
  /** insert data into the table: "ApplicationType" */
  insert_ApplicationType?: Maybe<ApplicationType_Mutation_Response>;
  /** insert a single row into the table: "ApplicationType" */
  insert_ApplicationType_one?: Maybe<ApplicationType>;
  /** insert data into the table: "Applications" */
  insert_Applications?: Maybe<Applications_Mutation_Response>;
  /** insert a single row into the table: "Applications" */
  insert_Applications_one?: Maybe<Applications>;
  /** insert data into the table: "Assignments" */
  insert_Assignments?: Maybe<Assignments_Mutation_Response>;
  /** insert a single row into the table: "Assignments" */
  insert_Assignments_one?: Maybe<Assignments>;
  /** insert data into the table: "AsyncOperations" */
  insert_AsyncOperations?: Maybe<AsyncOperations_Mutation_Response>;
  /** insert data into the table: "AsyncOperationsStates" */
  insert_AsyncOperationsStates?: Maybe<AsyncOperationsStates_Mutation_Response>;
  /** insert a single row into the table: "AsyncOperationsStates" */
  insert_AsyncOperationsStates_one?: Maybe<AsyncOperationsStates>;
  /** insert a single row into the table: "AsyncOperations" */
  insert_AsyncOperations_one?: Maybe<AsyncOperations>;
  /** insert data into the table: "Attachments" */
  insert_Attachments?: Maybe<Attachments_Mutation_Response>;
  /** insert a single row into the table: "Attachments" */
  insert_Attachments_one?: Maybe<Attachments>;
  /** insert data into the table: "AuditCreates" */
  insert_AuditCreates?: Maybe<AuditCreates_Mutation_Response>;
  /** insert a single row into the table: "AuditCreates" */
  insert_AuditCreates_one?: Maybe<AuditCreates>;
  /** insert data into the table: "AuditStages" */
  insert_AuditStages?: Maybe<AuditStages_Mutation_Response>;
  /** insert a single row into the table: "AuditStages" */
  insert_AuditStages_one?: Maybe<AuditStages>;
  /** insert data into the table: "AuthAudits" */
  insert_AuthAudits?: Maybe<AuthAudits_Mutation_Response>;
  /** insert a single row into the table: "AuthAudits" */
  insert_AuthAudits_one?: Maybe<AuthAudits>;
  /** insert data into the table: "Badges" */
  insert_Badges?: Maybe<Badges_Mutation_Response>;
  /** insert a single row into the table: "Badges" */
  insert_Badges_one?: Maybe<Badges>;
  /** insert data into the table: "Benefit" */
  insert_Benefit?: Maybe<Benefit_Mutation_Response>;
  /** insert data into the table: "BenefitTypeIdentitySubTypes" */
  insert_BenefitTypeIdentitySubTypes?: Maybe<BenefitTypeIdentitySubTypes_Mutation_Response>;
  /** insert a single row into the table: "BenefitTypeIdentitySubTypes" */
  insert_BenefitTypeIdentitySubTypes_one?: Maybe<BenefitTypeIdentitySubTypes>;
  /** insert data into the table: "BenefitTypes" */
  insert_BenefitTypes?: Maybe<BenefitTypes_Mutation_Response>;
  /** insert a single row into the table: "BenefitTypes" */
  insert_BenefitTypes_one?: Maybe<BenefitTypes>;
  /** insert a single row into the table: "Benefit" */
  insert_Benefit_one?: Maybe<Benefit>;
  /** insert data into the table: "BoardFilters" */
  insert_BoardFilters?: Maybe<BoardFilters_Mutation_Response>;
  /** insert a single row into the table: "BoardFilters" */
  insert_BoardFilters_one?: Maybe<BoardFilters>;
  /** insert data into the table: "BoardSnippets" */
  insert_BoardSnippets?: Maybe<BoardSnippets_Mutation_Response>;
  /** insert a single row into the table: "BoardSnippets" */
  insert_BoardSnippets_one?: Maybe<BoardSnippets>;
  /** insert data into the table: "BullhornSyncStatus" */
  insert_BullhornSyncStatus?: Maybe<BullhornSyncStatus_Mutation_Response>;
  /** insert a single row into the table: "BullhornSyncStatus" */
  insert_BullhornSyncStatus_one?: Maybe<BullhornSyncStatus>;
  /** insert data into the table: "CalcEquationResults" */
  insert_CalcEquationResults?: Maybe<CalcEquationResults_Mutation_Response>;
  /** insert a single row into the table: "CalcEquationResults" */
  insert_CalcEquationResults_one?: Maybe<CalcEquationResults>;
  /** insert data into the table: "CalcFormValues" */
  insert_CalcFormValues?: Maybe<CalcFormValues_Mutation_Response>;
  /** insert a single row into the table: "CalcFormValues" */
  insert_CalcFormValues_one?: Maybe<CalcFormValues>;
  /** insert data into the table: "Calcs" */
  insert_Calcs?: Maybe<Calcs_Mutation_Response>;
  /** insert a single row into the table: "Calcs" */
  insert_Calcs_one?: Maybe<Calcs>;
  /** insert data into the table: "CalculationTemplateVersions" */
  insert_CalculationTemplateVersions?: Maybe<CalculationTemplateVersions_Mutation_Response>;
  /** insert a single row into the table: "CalculationTemplateVersions" */
  insert_CalculationTemplateVersions_one?: Maybe<CalculationTemplateVersions>;
  /** insert data into the table: "CalculationTemplates" */
  insert_CalculationTemplates?: Maybe<CalculationTemplates_Mutation_Response>;
  /** insert a single row into the table: "CalculationTemplates" */
  insert_CalculationTemplates_one?: Maybe<CalculationTemplates>;
  /** insert data into the table: "CalculationVersionFormInputs" */
  insert_CalculationVersionFormInputs?: Maybe<CalculationVersionFormInputs_Mutation_Response>;
  /** insert a single row into the table: "CalculationVersionFormInputs" */
  insert_CalculationVersionFormInputs_one?: Maybe<CalculationVersionFormInputs>;
  /** insert data into the table: "Calculations" */
  insert_Calculations?: Maybe<Calculations_Mutation_Response>;
  /** insert a single row into the table: "Calculations" */
  insert_Calculations_one?: Maybe<Calculations>;
  /** insert data into the table: "CandidateSources" */
  insert_CandidateSources?: Maybe<CandidateSources_Mutation_Response>;
  /** insert a single row into the table: "CandidateSources" */
  insert_CandidateSources_one?: Maybe<CandidateSources>;
  /** insert data into the table: "CandidateStatuses" */
  insert_CandidateStatuses?: Maybe<CandidateStatuses_Mutation_Response>;
  /** insert a single row into the table: "CandidateStatuses" */
  insert_CandidateStatuses_one?: Maybe<CandidateStatuses>;
  /** insert data into the table: "CaptureNodes" */
  insert_CaptureNodes?: Maybe<CaptureNodes_Mutation_Response>;
  /** insert a single row into the table: "CaptureNodes" */
  insert_CaptureNodes_one?: Maybe<CaptureNodes>;
  /** insert data into the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  insert_ClientSideExternalSystemIntegrationPluginRecord?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Mutation_Response>;
  /** insert a single row into the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  insert_ClientSideExternalSystemIntegrationPluginRecord_one?: Maybe<ClientSideExternalSystemIntegrationPluginRecord>;
  /** insert data into the table: "CognitoUserData" */
  insert_CognitoUserData?: Maybe<CognitoUserData_Mutation_Response>;
  /** insert a single row into the table: "CognitoUserData" */
  insert_CognitoUserData_one?: Maybe<CognitoUserData>;
  /** insert data into the table: "CommentTypes" */
  insert_CommentTypes?: Maybe<CommentTypes_Mutation_Response>;
  /** insert a single row into the table: "CommentTypes" */
  insert_CommentTypes_one?: Maybe<CommentTypes>;
  /** insert data into the table: "Comments" */
  insert_Comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "Comments" */
  insert_Comments_one?: Maybe<Comments>;
  /** insert data into the table: "Companies" */
  insert_Companies?: Maybe<Companies_Mutation_Response>;
  /** insert a single row into the table: "Companies" */
  insert_Companies_one?: Maybe<Companies>;
  /** insert data into the table: "CompanyEvents" */
  insert_CompanyEvents?: Maybe<CompanyEvents_Mutation_Response>;
  /** insert a single row into the table: "CompanyEvents" */
  insert_CompanyEvents_one?: Maybe<CompanyEvents>;
  /** insert data into the table: "CompanyStatusEnum" */
  insert_CompanyStatusEnum?: Maybe<CompanyStatusEnum_Mutation_Response>;
  /** insert a single row into the table: "CompanyStatusEnum" */
  insert_CompanyStatusEnum_one?: Maybe<CompanyStatusEnum>;
  /** insert data into the table: "Compensations" */
  insert_Compensations?: Maybe<Compensations_Mutation_Response>;
  /** insert a single row into the table: "Compensations" */
  insert_Compensations_one?: Maybe<Compensations>;
  /** insert data into the table: "ContactTemplateVariablesEnum" */
  insert_ContactTemplateVariablesEnum?: Maybe<ContactTemplateVariablesEnum_Mutation_Response>;
  /** insert a single row into the table: "ContactTemplateVariablesEnum" */
  insert_ContactTemplateVariablesEnum_one?: Maybe<ContactTemplateVariablesEnum>;
  /** insert data into the table: "ContractComments" */
  insert_ContractComments?: Maybe<ContractComments_Mutation_Response>;
  /** insert a single row into the table: "ContractComments" */
  insert_ContractComments_one?: Maybe<ContractComments>;
  /** insert data into the table: "ContractFiles" */
  insert_ContractFiles?: Maybe<ContractFiles_Mutation_Response>;
  /** insert a single row into the table: "ContractFiles" */
  insert_ContractFiles_one?: Maybe<ContractFiles>;
  /** insert data into the table: "ContractStages" */
  insert_ContractStages?: Maybe<ContractStages_Mutation_Response>;
  /** insert a single row into the table: "ContractStages" */
  insert_ContractStages_one?: Maybe<ContractStages>;
  /** insert data into the table: "ContractStatusEnum" */
  insert_ContractStatusEnum?: Maybe<ContractStatusEnum_Mutation_Response>;
  /** insert a single row into the table: "ContractStatusEnum" */
  insert_ContractStatusEnum_one?: Maybe<ContractStatusEnum>;
  /** insert data into the table: "Contracts" */
  insert_Contracts?: Maybe<Contracts_Mutation_Response>;
  /** insert a single row into the table: "Contracts" */
  insert_Contracts_one?: Maybe<Contracts>;
  /** insert data into the table: "CurrentStages" */
  insert_CurrentStages?: Maybe<CurrentStages_Mutation_Response>;
  /** insert a single row into the table: "CurrentStages" */
  insert_CurrentStages_one?: Maybe<CurrentStages>;
  /** insert data into the table: "DataHistory" */
  insert_DataHistory?: Maybe<DataHistory_Mutation_Response>;
  /** insert a single row into the table: "DataHistory" */
  insert_DataHistory_one?: Maybe<DataHistory>;
  /** insert data into the table: "Departments" */
  insert_Departments?: Maybe<Departments_Mutation_Response>;
  /** insert a single row into the table: "Departments" */
  insert_Departments_one?: Maybe<Departments>;
  /** insert data into the table: "DisabilityStatusEnum" */
  insert_DisabilityStatusEnum?: Maybe<DisabilityStatusEnum_Mutation_Response>;
  /** insert a single row into the table: "DisabilityStatusEnum" */
  insert_DisabilityStatusEnum_one?: Maybe<DisabilityStatusEnum>;
  /** insert data into the table: "EEOJobCategoriesEnum" */
  insert_EEOJobCategoriesEnum?: Maybe<EeoJobCategoriesEnum_Mutation_Response>;
  /** insert a single row into the table: "EEOJobCategoriesEnum" */
  insert_EEOJobCategoriesEnum_one?: Maybe<EeoJobCategoriesEnum>;
  /** insert data into the table: "EarnedBadges" */
  insert_EarnedBadges?: Maybe<EarnedBadges_Mutation_Response>;
  /** insert a single row into the table: "EarnedBadges" */
  insert_EarnedBadges_one?: Maybe<EarnedBadges>;
  /** insert data into the table: "EmailAddresses" */
  insert_EmailAddresses?: Maybe<EmailAddresses_Mutation_Response>;
  /** insert a single row into the table: "EmailAddresses" */
  insert_EmailAddresses_one?: Maybe<EmailAddresses>;
  /** insert data into the table: "EmailEvents" */
  insert_EmailEvents?: Maybe<EmailEvents_Mutation_Response>;
  /** insert a single row into the table: "EmailEvents" */
  insert_EmailEvents_one?: Maybe<EmailEvents>;
  /** insert data into the table: "EmailRecords" */
  insert_EmailRecords?: Maybe<EmailRecords_Mutation_Response>;
  /** insert a single row into the table: "EmailRecords" */
  insert_EmailRecords_one?: Maybe<EmailRecords>;
  /** insert data into the table: "EmailSignatures" */
  insert_EmailSignatures?: Maybe<EmailSignatures_Mutation_Response>;
  /** insert a single row into the table: "EmailSignatures" */
  insert_EmailSignatures_one?: Maybe<EmailSignatures>;
  /** insert data into the table: "EmailSubFolders" */
  insert_EmailSubFolders?: Maybe<EmailSubFolders_Mutation_Response>;
  /** insert a single row into the table: "EmailSubFolders" */
  insert_EmailSubFolders_one?: Maybe<EmailSubFolders>;
  /** insert data into the table: "EmailTypeEnum" */
  insert_EmailTypeEnum?: Maybe<EmailTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "EmailTypeEnum" */
  insert_EmailTypeEnum_one?: Maybe<EmailTypeEnum>;
  /** insert data into the table: "EmailTypes" */
  insert_EmailTypes?: Maybe<EmailTypes_Mutation_Response>;
  /** insert a single row into the table: "EmailTypes" */
  insert_EmailTypes_one?: Maybe<EmailTypes>;
  /** insert data into the table: "Emails" */
  insert_Emails?: Maybe<Emails_Mutation_Response>;
  /** insert a single row into the table: "Emails" */
  insert_Emails_one?: Maybe<Emails>;
  /** insert data into the table: "EmploymentHireType" */
  insert_EmploymentHireType?: Maybe<EmploymentHireType_Mutation_Response>;
  /** insert a single row into the table: "EmploymentHireType" */
  insert_EmploymentHireType_one?: Maybe<EmploymentHireType>;
  /** insert data into the table: "EmploymentTypes" */
  insert_EmploymentTypes?: Maybe<EmploymentTypes_Mutation_Response>;
  /** insert a single row into the table: "EmploymentTypes" */
  insert_EmploymentTypes_one?: Maybe<EmploymentTypes>;
  /** insert data into the table: "Employments" */
  insert_Employments?: Maybe<Employments_Mutation_Response>;
  /** insert a single row into the table: "Employments" */
  insert_Employments_one?: Maybe<Employments>;
  /** insert data into the table: "EntitlementCategories" */
  insert_EntitlementCategories?: Maybe<EntitlementCategories_Mutation_Response>;
  /** insert a single row into the table: "EntitlementCategories" */
  insert_EntitlementCategories_one?: Maybe<EntitlementCategories>;
  /** insert data into the table: "Entitlements" */
  insert_Entitlements?: Maybe<Entitlements_Mutation_Response>;
  /** insert a single row into the table: "Entitlements" */
  insert_Entitlements_one?: Maybe<Entitlements>;
  /** insert data into the table: "EquationFormulaOption" */
  insert_EquationFormulaOption?: Maybe<EquationFormulaOption_Mutation_Response>;
  /** insert a single row into the table: "EquationFormulaOption" */
  insert_EquationFormulaOption_one?: Maybe<EquationFormulaOption>;
  /** insert data into the table: "Equations" */
  insert_Equations?: Maybe<Equations_Mutation_Response>;
  /** insert a single row into the table: "Equations" */
  insert_Equations_one?: Maybe<Equations>;
  /** insert data into the table: "Events" */
  insert_Events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "Events" */
  insert_Events_one?: Maybe<Events>;
  /** insert data into the table: "Expense" */
  insert_Expense?: Maybe<Expense_Mutation_Response>;
  /** insert a single row into the table: "Expense" */
  insert_Expense_one?: Maybe<Expense>;
  /** insert data into the table: "ExternalIntegrationTypes" */
  insert_ExternalIntegrationTypes?: Maybe<ExternalIntegrationTypes_Mutation_Response>;
  /** insert a single row into the table: "ExternalIntegrationTypes" */
  insert_ExternalIntegrationTypes_one?: Maybe<ExternalIntegrationTypes>;
  /** insert data into the table: "ExternalIntegrations" */
  insert_ExternalIntegrations?: Maybe<ExternalIntegrations_Mutation_Response>;
  /** insert a single row into the table: "ExternalIntegrations" */
  insert_ExternalIntegrations_one?: Maybe<ExternalIntegrations>;
  /** insert data into the table: "ExternalJobBoardPublishings" */
  insert_ExternalJobBoardPublishings?: Maybe<ExternalJobBoardPublishings_Mutation_Response>;
  /** insert a single row into the table: "ExternalJobBoardPublishings" */
  insert_ExternalJobBoardPublishings_one?: Maybe<ExternalJobBoardPublishings>;
  /** insert data into the table: "ExternalJobBoards" */
  insert_ExternalJobBoards?: Maybe<ExternalJobBoards_Mutation_Response>;
  /** insert a single row into the table: "ExternalJobBoards" */
  insert_ExternalJobBoards_one?: Maybe<ExternalJobBoards>;
  /** insert data into the table: "ExternalSystem" */
  insert_ExternalSystem?: Maybe<ExternalSystem_Mutation_Response>;
  /** insert data into the table: "ExternalSystemInstance" */
  insert_ExternalSystemInstance?: Maybe<ExternalSystemInstance_Mutation_Response>;
  /** insert a single row into the table: "ExternalSystemInstance" */
  insert_ExternalSystemInstance_one?: Maybe<ExternalSystemInstance>;
  /** insert a single row into the table: "ExternalSystem" */
  insert_ExternalSystem_one?: Maybe<ExternalSystem>;
  /** insert data into the table: "FeatureFlags" */
  insert_FeatureFlags?: Maybe<FeatureFlags_Mutation_Response>;
  /** insert a single row into the table: "FeatureFlags" */
  insert_FeatureFlags_one?: Maybe<FeatureFlags>;
  /** insert data into the table: "Field" */
  insert_Field?: Maybe<Field_Mutation_Response>;
  /** insert data into the table: "FieldDef" */
  insert_FieldDef?: Maybe<FieldDef_Mutation_Response>;
  /** insert a single row into the table: "FieldDef" */
  insert_FieldDef_one?: Maybe<FieldDef>;
  /** insert data into the table: "FieldPicklistValues" */
  insert_FieldPicklistValues?: Maybe<FieldPicklistValues_Mutation_Response>;
  /** insert a single row into the table: "FieldPicklistValues" */
  insert_FieldPicklistValues_one?: Maybe<FieldPicklistValues>;
  /** insert data into the table: "FieldTypeDef" */
  insert_FieldTypeDef?: Maybe<FieldTypeDef_Mutation_Response>;
  /** insert a single row into the table: "FieldTypeDef" */
  insert_FieldTypeDef_one?: Maybe<FieldTypeDef>;
  /** insert a single row into the table: "Field" */
  insert_Field_one?: Maybe<Field>;
  /** insert data into the table: "FileTags" */
  insert_FileTags?: Maybe<FileTags_Mutation_Response>;
  /** insert a single row into the table: "FileTags" */
  insert_FileTags_one?: Maybe<FileTags>;
  /** insert data into the table: "Files" */
  insert_Files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "Files" */
  insert_Files_one?: Maybe<Files>;
  /** insert data into the table: "FormInputs" */
  insert_FormInputs?: Maybe<FormInputs_Mutation_Response>;
  /** insert a single row into the table: "FormInputs" */
  insert_FormInputs_one?: Maybe<FormInputs>;
  /** insert data into the table: "FormSelectOptions" */
  insert_FormSelectOptions?: Maybe<FormSelectOptions_Mutation_Response>;
  /** insert a single row into the table: "FormSelectOptions" */
  insert_FormSelectOptions_one?: Maybe<FormSelectOptions>;
  /** insert data into the table: "FormulaOptions" */
  insert_FormulaOptions?: Maybe<FormulaOptions_Mutation_Response>;
  /** insert a single row into the table: "FormulaOptions" */
  insert_FormulaOptions_one?: Maybe<FormulaOptions>;
  /** insert data into the table: "GenderEnum" */
  insert_GenderEnum?: Maybe<GenderEnum_Mutation_Response>;
  /** insert a single row into the table: "GenderEnum" */
  insert_GenderEnum_one?: Maybe<GenderEnum>;
  /** insert data into the table: "GeocodingHistory" */
  insert_GeocodingHistory?: Maybe<GeocodingHistory_Mutation_Response>;
  /** insert a single row into the table: "GeocodingHistory" */
  insert_GeocodingHistory_one?: Maybe<GeocodingHistory>;
  /** insert data into the table: "Goals" */
  insert_Goals?: Maybe<Goals_Mutation_Response>;
  /** insert a single row into the table: "Goals" */
  insert_Goals_one?: Maybe<Goals>;
  /** insert data into the table: "GoogleSheetCalcTemplates" */
  insert_GoogleSheetCalcTemplates?: Maybe<GoogleSheetCalcTemplates_Mutation_Response>;
  /** insert a single row into the table: "GoogleSheetCalcTemplates" */
  insert_GoogleSheetCalcTemplates_one?: Maybe<GoogleSheetCalcTemplates>;
  /** insert data into the table: "GoogleSheetCalculations" */
  insert_GoogleSheetCalculations?: Maybe<GoogleSheetCalculations_Mutation_Response>;
  /** insert a single row into the table: "GoogleSheetCalculations" */
  insert_GoogleSheetCalculations_one?: Maybe<GoogleSheetCalculations>;
  /** insert data into the table: "GroupJobs" */
  insert_GroupJobs?: Maybe<GroupJobs_Mutation_Response>;
  /** insert a single row into the table: "GroupJobs" */
  insert_GroupJobs_one?: Maybe<GroupJobs>;
  /** insert data into the table: "Groups" */
  insert_Groups?: Maybe<Groups_Mutation_Response>;
  /** insert a single row into the table: "Groups" */
  insert_Groups_one?: Maybe<Groups>;
  /** insert data into the table: "HighestStages" */
  insert_HighestStages?: Maybe<HighestStages_Mutation_Response>;
  /** insert a single row into the table: "HighestStages" */
  insert_HighestStages_one?: Maybe<HighestStages>;
  /** insert data into the table: "HireTypesEnum" */
  insert_HireTypesEnum?: Maybe<HireTypesEnum_Mutation_Response>;
  /** insert a single row into the table: "HireTypesEnum" */
  insert_HireTypesEnum_one?: Maybe<HireTypesEnum>;
  /** insert data into the table: "ITRequestContractEmails" */
  insert_ITRequestContractEmails?: Maybe<ItRequestContractEmails_Mutation_Response>;
  /** insert a single row into the table: "ITRequestContractEmails" */
  insert_ITRequestContractEmails_one?: Maybe<ItRequestContractEmails>;
  /** insert data into the table: "Identities" */
  insert_Identities?: Maybe<Identities_Mutation_Response>;
  /** insert data into the table: "IdentitiesOverlaysLinks" */
  insert_IdentitiesOverlaysLinks?: Maybe<IdentitiesOverlaysLinks_Mutation_Response>;
  /** insert a single row into the table: "IdentitiesOverlaysLinks" */
  insert_IdentitiesOverlaysLinks_one?: Maybe<IdentitiesOverlaysLinks>;
  /** insert a single row into the table: "Identities" */
  insert_Identities_one?: Maybe<Identities>;
  /** insert data into the table: "IdentityAssignments" */
  insert_IdentityAssignments?: Maybe<IdentityAssignments_Mutation_Response>;
  /** insert a single row into the table: "IdentityAssignments" */
  insert_IdentityAssignments_one?: Maybe<IdentityAssignments>;
  /** insert data into the table: "IdentityComments" */
  insert_IdentityComments?: Maybe<IdentityComments_Mutation_Response>;
  /** insert a single row into the table: "IdentityComments" */
  insert_IdentityComments_one?: Maybe<IdentityComments>;
  /** insert data into the table: "IdentityContractRelationship" */
  insert_IdentityContractRelationship?: Maybe<IdentityContractRelationship_Mutation_Response>;
  /** insert a single row into the table: "IdentityContractRelationship" */
  insert_IdentityContractRelationship_one?: Maybe<IdentityContractRelationship>;
  /** insert data into the table: "IdentityDepartmentRelationship" */
  insert_IdentityDepartmentRelationship?: Maybe<IdentityDepartmentRelationship_Mutation_Response>;
  /** insert a single row into the table: "IdentityDepartmentRelationship" */
  insert_IdentityDepartmentRelationship_one?: Maybe<IdentityDepartmentRelationship>;
  /** insert data into the table: "IdentityGroups" */
  insert_IdentityGroups?: Maybe<IdentityGroups_Mutation_Response>;
  /** insert a single row into the table: "IdentityGroups" */
  insert_IdentityGroups_one?: Maybe<IdentityGroups>;
  /** insert data into the table: "IdentityJobRelationship" */
  insert_IdentityJobRelationship?: Maybe<IdentityJobRelationship_Mutation_Response>;
  /** insert a single row into the table: "IdentityJobRelationship" */
  insert_IdentityJobRelationship_one?: Maybe<IdentityJobRelationship>;
  /** insert data into the table: "IdentityPartnerGroupData" */
  insert_IdentityPartnerGroupData?: Maybe<IdentityPartnerGroupData_Mutation_Response>;
  /** insert a single row into the table: "IdentityPartnerGroupData" */
  insert_IdentityPartnerGroupData_one?: Maybe<IdentityPartnerGroupData>;
  /** insert data into the table: "IdentityRelationshipType" */
  insert_IdentityRelationshipType?: Maybe<IdentityRelationshipType_Mutation_Response>;
  /** insert a single row into the table: "IdentityRelationshipType" */
  insert_IdentityRelationshipType_one?: Maybe<IdentityRelationshipType>;
  /** insert data into the table: "IdentityRoles" */
  insert_IdentityRoles?: Maybe<IdentityRoles_Mutation_Response>;
  /** insert a single row into the table: "IdentityRoles" */
  insert_IdentityRoles_one?: Maybe<IdentityRoles>;
  /** insert data into the table: "IdentitySubTypeEmails" */
  insert_IdentitySubTypeEmails?: Maybe<IdentitySubTypeEmails_Mutation_Response>;
  /** insert a single row into the table: "IdentitySubTypeEmails" */
  insert_IdentitySubTypeEmails_one?: Maybe<IdentitySubTypeEmails>;
  /** insert data into the table: "IdentitySubTypes" */
  insert_IdentitySubTypes?: Maybe<IdentitySubTypes_Mutation_Response>;
  /** insert a single row into the table: "IdentitySubTypes" */
  insert_IdentitySubTypes_one?: Maybe<IdentitySubTypes>;
  /** insert data into the table: "IdentityTags" */
  insert_IdentityTags?: Maybe<IdentityTags_Mutation_Response>;
  /** insert a single row into the table: "IdentityTags" */
  insert_IdentityTags_one?: Maybe<IdentityTags>;
  /** insert data into the table: "IdentityTokens" */
  insert_IdentityTokens?: Maybe<IdentityTokens_Mutation_Response>;
  /** insert a single row into the table: "IdentityTokens" */
  insert_IdentityTokens_one?: Maybe<IdentityTokens>;
  /** insert data into the table: "IdentityTypes" */
  insert_IdentityTypes?: Maybe<IdentityTypes_Mutation_Response>;
  /** insert a single row into the table: "IdentityTypes" */
  insert_IdentityTypes_one?: Maybe<IdentityTypes>;
  /** insert data into the table: "IdentityUserAccessGroup" */
  insert_IdentityUserAccessGroup?: Maybe<IdentityUserAccessGroup_Mutation_Response>;
  /** insert a single row into the table: "IdentityUserAccessGroup" */
  insert_IdentityUserAccessGroup_one?: Maybe<IdentityUserAccessGroup>;
  /** insert data into the table: "Images" */
  insert_Images?: Maybe<Images_Mutation_Response>;
  /** insert a single row into the table: "Images" */
  insert_Images_one?: Maybe<Images>;
  /** insert data into the table: "InputTypes" */
  insert_InputTypes?: Maybe<InputTypes_Mutation_Response>;
  /** insert a single row into the table: "InputTypes" */
  insert_InputTypes_one?: Maybe<InputTypes>;
  /** insert data into the table: "InternalJobCategories" */
  insert_InternalJobCategories?: Maybe<InternalJobCategories_Mutation_Response>;
  /** insert a single row into the table: "InternalJobCategories" */
  insert_InternalJobCategories_one?: Maybe<InternalJobCategories>;
  /** insert data into the table: "Interviews" */
  insert_Interviews?: Maybe<Interviews_Mutation_Response>;
  /** insert a single row into the table: "Interviews" */
  insert_Interviews_one?: Maybe<Interviews>;
  /** insert data into the table: "JobAvailabilities" */
  insert_JobAvailabilities?: Maybe<JobAvailabilities_Mutation_Response>;
  /** insert a single row into the table: "JobAvailabilities" */
  insert_JobAvailabilities_one?: Maybe<JobAvailabilities>;
  /** insert data into the table: "JobComments" */
  insert_JobComments?: Maybe<JobComments_Mutation_Response>;
  /** insert a single row into the table: "JobComments" */
  insert_JobComments_one?: Maybe<JobComments>;
  /** insert data into the table: "JobFiles" */
  insert_JobFiles?: Maybe<JobFiles_Mutation_Response>;
  /** insert a single row into the table: "JobFiles" */
  insert_JobFiles_one?: Maybe<JobFiles>;
  /** insert data into the table: "JobFundingStatusEnum" */
  insert_JobFundingStatusEnum?: Maybe<JobFundingStatusEnum_Mutation_Response>;
  /** insert a single row into the table: "JobFundingStatusEnum" */
  insert_JobFundingStatusEnum_one?: Maybe<JobFundingStatusEnum>;
  /** insert data into the table: "JobHireTypesEnum" */
  insert_JobHireTypesEnum?: Maybe<JobHireTypesEnum_Mutation_Response>;
  /** insert a single row into the table: "JobHireTypesEnum" */
  insert_JobHireTypesEnum_one?: Maybe<JobHireTypesEnum>;
  /** insert data into the table: "JobPositions" */
  insert_JobPositions?: Maybe<JobPositions_Mutation_Response>;
  /** insert a single row into the table: "JobPositions" */
  insert_JobPositions_one?: Maybe<JobPositions>;
  /** insert data into the table: "JobStatuses" */
  insert_JobStatuses?: Maybe<JobStatuses_Mutation_Response>;
  /** insert a single row into the table: "JobStatuses" */
  insert_JobStatuses_one?: Maybe<JobStatuses>;
  /** insert data into the table: "Jobs" */
  insert_Jobs?: Maybe<Jobs_Mutation_Response>;
  /** insert a single row into the table: "Jobs" */
  insert_Jobs_one?: Maybe<Jobs>;
  /** insert data into the table: "MagicLinks" */
  insert_MagicLinks?: Maybe<MagicLinks_Mutation_Response>;
  /** insert a single row into the table: "MagicLinks" */
  insert_MagicLinks_one?: Maybe<MagicLinks>;
  /** insert data into the table: "MigrationData" */
  insert_MigrationData?: Maybe<MigrationData_Mutation_Response>;
  /** insert a single row into the table: "MigrationData" */
  insert_MigrationData_one?: Maybe<MigrationData>;
  /** insert data into the table: "ObjectZoo" */
  insert_ObjectZoo?: Maybe<ObjectZoo_Mutation_Response>;
  /** insert a single row into the table: "ObjectZoo" */
  insert_ObjectZoo_one?: Maybe<ObjectZoo>;
  /** insert data into the table: "Overlay" */
  insert_Overlay?: Maybe<Overlay_Mutation_Response>;
  /** insert data into the table: "OverlayDefinition" */
  insert_OverlayDefinition?: Maybe<OverlayDefinition_Mutation_Response>;
  /** insert a single row into the table: "OverlayDefinition" */
  insert_OverlayDefinition_one?: Maybe<OverlayDefinition>;
  /** insert a single row into the table: "Overlay" */
  insert_Overlay_one?: Maybe<Overlay>;
  /** insert data into the table: "PhoneNumbers" */
  insert_PhoneNumbers?: Maybe<PhoneNumbers_Mutation_Response>;
  /** insert a single row into the table: "PhoneNumbers" */
  insert_PhoneNumbers_one?: Maybe<PhoneNumbers>;
  /** insert data into the table: "PicklistValues" */
  insert_PicklistValues?: Maybe<PicklistValues_Mutation_Response>;
  /** insert a single row into the table: "PicklistValues" */
  insert_PicklistValues_one?: Maybe<PicklistValues>;
  /** insert data into the table: "Picklists" */
  insert_Picklists?: Maybe<Picklists_Mutation_Response>;
  /** insert a single row into the table: "Picklists" */
  insert_Picklists_one?: Maybe<Picklists>;
  /** insert data into the table: "ProductUpdates" */
  insert_ProductUpdates?: Maybe<ProductUpdates_Mutation_Response>;
  /** insert a single row into the table: "ProductUpdates" */
  insert_ProductUpdates_one?: Maybe<ProductUpdates>;
  /** insert data into the table: "QuestionnaireContactTemplateVariables" */
  insert_QuestionnaireContactTemplateVariables?: Maybe<QuestionnaireContactTemplateVariables_Mutation_Response>;
  /** insert a single row into the table: "QuestionnaireContactTemplateVariables" */
  insert_QuestionnaireContactTemplateVariables_one?: Maybe<QuestionnaireContactTemplateVariables>;
  /** insert data into the table: "QuestionnaireLinks" */
  insert_QuestionnaireLinks?: Maybe<QuestionnaireLinks_Mutation_Response>;
  /** insert a single row into the table: "QuestionnaireLinks" */
  insert_QuestionnaireLinks_one?: Maybe<QuestionnaireLinks>;
  /** insert data into the table: "QuestionnaireResponses" */
  insert_QuestionnaireResponses?: Maybe<QuestionnaireResponses_Mutation_Response>;
  /** insert a single row into the table: "QuestionnaireResponses" */
  insert_QuestionnaireResponses_one?: Maybe<QuestionnaireResponses>;
  /** insert data into the table: "Questionnaires" */
  insert_Questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "Questionnaires" */
  insert_Questionnaires_one?: Maybe<Questionnaires>;
  /** insert data into the table: "RRWebRecords" */
  insert_RRWebRecords?: Maybe<RrWebRecords_Mutation_Response>;
  /** insert a single row into the table: "RRWebRecords" */
  insert_RRWebRecords_one?: Maybe<RrWebRecords>;
  /** insert data into the table: "RaceEnum" */
  insert_RaceEnum?: Maybe<RaceEnum_Mutation_Response>;
  /** insert a single row into the table: "RaceEnum" */
  insert_RaceEnum_one?: Maybe<RaceEnum>;
  /** insert data into the table: "RangeValues" */
  insert_RangeValues?: Maybe<RangeValues_Mutation_Response>;
  /** insert a single row into the table: "RangeValues" */
  insert_RangeValues_one?: Maybe<RangeValues>;
  /** insert data into the table: "RejectionTypeEnum" */
  insert_RejectionTypeEnum?: Maybe<RejectionTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "RejectionTypeEnum" */
  insert_RejectionTypeEnum_one?: Maybe<RejectionTypeEnum>;
  /** insert data into the table: "Rejections" */
  insert_Rejections?: Maybe<Rejections_Mutation_Response>;
  /** insert a single row into the table: "Rejections" */
  insert_Rejections_one?: Maybe<Rejections>;
  /** insert data into the table: "RenderedEmails" */
  insert_RenderedEmails?: Maybe<RenderedEmails_Mutation_Response>;
  /** insert a single row into the table: "RenderedEmails" */
  insert_RenderedEmails_one?: Maybe<RenderedEmails>;
  /** insert data into the table: "ResumeClaims" */
  insert_ResumeClaims?: Maybe<ResumeClaims_Mutation_Response>;
  /** insert a single row into the table: "ResumeClaims" */
  insert_ResumeClaims_one?: Maybe<ResumeClaims>;
  /** insert data into the table: "ResumeEmailAddresses" */
  insert_ResumeEmailAddresses?: Maybe<ResumeEmailAddresses_Mutation_Response>;
  /** insert a single row into the table: "ResumeEmailAddresses" */
  insert_ResumeEmailAddresses_one?: Maybe<ResumeEmailAddresses>;
  /** insert data into the table: "ResumeIngestStatus" */
  insert_ResumeIngestStatus?: Maybe<ResumeIngestStatus_Mutation_Response>;
  /** insert a single row into the table: "ResumeIngestStatus" */
  insert_ResumeIngestStatus_one?: Maybe<ResumeIngestStatus>;
  /** insert data into the table: "ResumePhoneNumbers" */
  insert_ResumePhoneNumbers?: Maybe<ResumePhoneNumbers_Mutation_Response>;
  /** insert a single row into the table: "ResumePhoneNumbers" */
  insert_ResumePhoneNumbers_one?: Maybe<ResumePhoneNumbers>;
  /** insert data into the table: "Resumes" */
  insert_Resumes?: Maybe<Resumes_Mutation_Response>;
  /** insert a single row into the table: "Resumes" */
  insert_Resumes_one?: Maybe<Resumes>;
  /** insert data into the table: "ReviewCriteria" */
  insert_ReviewCriteria?: Maybe<ReviewCriteria_Mutation_Response>;
  /** insert data into the table: "ReviewCriteriaScore" */
  insert_ReviewCriteriaScore?: Maybe<ReviewCriteriaScore_Mutation_Response>;
  /** insert a single row into the table: "ReviewCriteriaScore" */
  insert_ReviewCriteriaScore_one?: Maybe<ReviewCriteriaScore>;
  /** insert a single row into the table: "ReviewCriteria" */
  insert_ReviewCriteria_one?: Maybe<ReviewCriteria>;
  /** insert data into the table: "ReviewGroupContactTemplateVariables" */
  insert_ReviewGroupContactTemplateVariables?: Maybe<ReviewGroupContactTemplateVariables_Mutation_Response>;
  /** insert a single row into the table: "ReviewGroupContactTemplateVariables" */
  insert_ReviewGroupContactTemplateVariables_one?: Maybe<ReviewGroupContactTemplateVariables>;
  /** insert data into the table: "ReviewGroupCriteria" */
  insert_ReviewGroupCriteria?: Maybe<ReviewGroupCriteria_Mutation_Response>;
  /** insert a single row into the table: "ReviewGroupCriteria" */
  insert_ReviewGroupCriteria_one?: Maybe<ReviewGroupCriteria>;
  /** insert data into the table: "ReviewGroupFiles" */
  insert_ReviewGroupFiles?: Maybe<ReviewGroupFiles_Mutation_Response>;
  /** insert a single row into the table: "ReviewGroupFiles" */
  insert_ReviewGroupFiles_one?: Maybe<ReviewGroupFiles>;
  /** insert data into the table: "ReviewGroupStages" */
  insert_ReviewGroupStages?: Maybe<ReviewGroupStages_Mutation_Response>;
  /** insert a single row into the table: "ReviewGroupStages" */
  insert_ReviewGroupStages_one?: Maybe<ReviewGroupStages>;
  /** insert data into the table: "ReviewGroupSubTypeEnum" */
  insert_ReviewGroupSubTypeEnum?: Maybe<ReviewGroupSubTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "ReviewGroupSubTypeEnum" */
  insert_ReviewGroupSubTypeEnum_one?: Maybe<ReviewGroupSubTypeEnum>;
  /** insert data into the table: "ReviewGroupTypeEnum" */
  insert_ReviewGroupTypeEnum?: Maybe<ReviewGroupTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "ReviewGroupTypeEnum" */
  insert_ReviewGroupTypeEnum_one?: Maybe<ReviewGroupTypeEnum>;
  /** insert data into the table: "ReviewGroups" */
  insert_ReviewGroups?: Maybe<ReviewGroups_Mutation_Response>;
  /** insert a single row into the table: "ReviewGroups" */
  insert_ReviewGroups_one?: Maybe<ReviewGroups>;
  /** insert data into the table: "Reviews" */
  insert_Reviews?: Maybe<Reviews_Mutation_Response>;
  /** insert data into the table: "ReviewsResultEnum" */
  insert_ReviewsResultEnum?: Maybe<ReviewsResultEnum_Mutation_Response>;
  /** insert a single row into the table: "ReviewsResultEnum" */
  insert_ReviewsResultEnum_one?: Maybe<ReviewsResultEnum>;
  /** insert a single row into the table: "Reviews" */
  insert_Reviews_one?: Maybe<Reviews>;
  /** insert data into the table: "RoleAccess" */
  insert_RoleAccess?: Maybe<RoleAccess_Mutation_Response>;
  /** insert a single row into the table: "RoleAccess" */
  insert_RoleAccess_one?: Maybe<RoleAccess>;
  /** insert data into the table: "Roles" */
  insert_Roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "Roles" */
  insert_Roles_one?: Maybe<Roles>;
  /** insert data into the table: "SearchFilterTypesEnum" */
  insert_SearchFilterTypesEnum?: Maybe<SearchFilterTypesEnum_Mutation_Response>;
  /** insert a single row into the table: "SearchFilterTypesEnum" */
  insert_SearchFilterTypesEnum_one?: Maybe<SearchFilterTypesEnum>;
  /** insert data into the table: "SearchFilters" */
  insert_SearchFilters?: Maybe<SearchFilters_Mutation_Response>;
  /** insert a single row into the table: "SearchFilters" */
  insert_SearchFilters_one?: Maybe<SearchFilters>;
  /** insert data into the table: "SecurityClearances" */
  insert_SecurityClearances?: Maybe<SecurityClearances_Mutation_Response>;
  /** insert a single row into the table: "SecurityClearances" */
  insert_SecurityClearances_one?: Maybe<SecurityClearances>;
  /** insert data into the table: "SequelizeMeta" */
  insert_SequelizeMeta?: Maybe<SequelizeMeta_Mutation_Response>;
  /** insert a single row into the table: "SequelizeMeta" */
  insert_SequelizeMeta_one?: Maybe<SequelizeMeta>;
  /** insert data into the table: "StageEmailContracts" */
  insert_StageEmailContracts?: Maybe<StageEmailContracts_Mutation_Response>;
  /** insert a single row into the table: "StageEmailContracts" */
  insert_StageEmailContracts_one?: Maybe<StageEmailContracts>;
  /** insert data into the table: "StageEmails" */
  insert_StageEmails?: Maybe<StageEmails_Mutation_Response>;
  /** insert a single row into the table: "StageEmails" */
  insert_StageEmails_one?: Maybe<StageEmails>;
  /** insert data into the table: "StageQuestionnaireMapping" */
  insert_StageQuestionnaireMapping?: Maybe<StageQuestionnaireMapping_Mutation_Response>;
  /** insert data into the table: "StageQuestionnaireMappingIdenity" */
  insert_StageQuestionnaireMappingIdenity?: Maybe<StageQuestionnaireMappingIdenity_Mutation_Response>;
  /** insert a single row into the table: "StageQuestionnaireMappingIdenity" */
  insert_StageQuestionnaireMappingIdenity_one?: Maybe<StageQuestionnaireMappingIdenity>;
  /** insert a single row into the table: "StageQuestionnaireMapping" */
  insert_StageQuestionnaireMapping_one?: Maybe<StageQuestionnaireMapping>;
  /** insert data into the table: "StageQuestionnaireTemplateVariables" */
  insert_StageQuestionnaireTemplateVariables?: Maybe<StageQuestionnaireTemplateVariables_Mutation_Response>;
  /** insert a single row into the table: "StageQuestionnaireTemplateVariables" */
  insert_StageQuestionnaireTemplateVariables_one?: Maybe<StageQuestionnaireTemplateVariables>;
  /** insert data into the table: "StageRoles" */
  insert_StageRoles?: Maybe<StageRoles_Mutation_Response>;
  /** insert a single row into the table: "StageRoles" */
  insert_StageRoles_one?: Maybe<StageRoles>;
  /** insert data into the table: "StageTransitions" */
  insert_StageTransitions?: Maybe<StageTransitions_Mutation_Response>;
  /** insert a single row into the table: "StageTransitions" */
  insert_StageTransitions_one?: Maybe<StageTransitions>;
  /** insert data into the table: "Stages" */
  insert_Stages?: Maybe<Stages_Mutation_Response>;
  /** insert a single row into the table: "Stages" */
  insert_Stages_one?: Maybe<Stages>;
  /** insert data into the table: "StateMachines" */
  insert_StateMachines?: Maybe<StateMachines_Mutation_Response>;
  /** insert a single row into the table: "StateMachines" */
  insert_StateMachines_one?: Maybe<StateMachines>;
  /** insert data into the table: "Submissions" */
  insert_Submissions?: Maybe<Submissions_Mutation_Response>;
  /** insert data into the table: "SubmissionsOverlaysLinks" */
  insert_SubmissionsOverlaysLinks?: Maybe<SubmissionsOverlaysLinks_Mutation_Response>;
  /** insert a single row into the table: "SubmissionsOverlaysLinks" */
  insert_SubmissionsOverlaysLinks_one?: Maybe<SubmissionsOverlaysLinks>;
  /** insert a single row into the table: "Submissions" */
  insert_Submissions_one?: Maybe<Submissions>;
  /** insert data into the table: "TableConfigTypes" */
  insert_TableConfigTypes?: Maybe<TableConfigTypes_Mutation_Response>;
  /** insert a single row into the table: "TableConfigTypes" */
  insert_TableConfigTypes_one?: Maybe<TableConfigTypes>;
  /** insert data into the table: "TableConfigs" */
  insert_TableConfigs?: Maybe<TableConfigs_Mutation_Response>;
  /** insert a single row into the table: "TableConfigs" */
  insert_TableConfigs_one?: Maybe<TableConfigs>;
  /** insert data into the table: "Tags" */
  insert_Tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "Tags" */
  insert_Tags_one?: Maybe<Tags>;
  /** insert data into the table: "TaskGroupAssignees" */
  insert_TaskGroupAssignees?: Maybe<TaskGroupAssignees_Mutation_Response>;
  /** insert a single row into the table: "TaskGroupAssignees" */
  insert_TaskGroupAssignees_one?: Maybe<TaskGroupAssignees>;
  /** insert data into the table: "TaskGroups" */
  insert_TaskGroups?: Maybe<TaskGroups_Mutation_Response>;
  /** insert a single row into the table: "TaskGroups" */
  insert_TaskGroups_one?: Maybe<TaskGroups>;
  /** insert data into the table: "TaskPriorityTypes" */
  insert_TaskPriorityTypes?: Maybe<TaskPriorityTypes_Mutation_Response>;
  /** insert a single row into the table: "TaskPriorityTypes" */
  insert_TaskPriorityTypes_one?: Maybe<TaskPriorityTypes>;
  /** insert data into the table: "TaskTypes" */
  insert_TaskTypes?: Maybe<TaskTypes_Mutation_Response>;
  /** insert a single row into the table: "TaskTypes" */
  insert_TaskTypes_one?: Maybe<TaskTypes>;
  /** insert data into the table: "Tasks" */
  insert_Tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "Tasks" */
  insert_Tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "Terminations" */
  insert_Terminations?: Maybe<Terminations_Mutation_Response>;
  /** insert a single row into the table: "Terminations" */
  insert_Terminations_one?: Maybe<Terminations>;
  /** insert data into the table: "TimeInStages" */
  insert_TimeInStages?: Maybe<TimeInStages_Mutation_Response>;
  /** insert a single row into the table: "TimeInStages" */
  insert_TimeInStages_one?: Maybe<TimeInStages>;
  /** insert data into the table: "TimelineEvents" */
  insert_TimelineEvents?: Maybe<TimelineEvents_Mutation_Response>;
  /** insert a single row into the table: "TimelineEvents" */
  insert_TimelineEvents_one?: Maybe<TimelineEvents>;
  /** insert data into the table: "Tokens" */
  insert_Tokens?: Maybe<Tokens_Mutation_Response>;
  /** insert a single row into the table: "Tokens" */
  insert_Tokens_one?: Maybe<Tokens>;
  /** insert data into the table: "UserAccessGroups" */
  insert_UserAccessGroups?: Maybe<UserAccessGroups_Mutation_Response>;
  /** insert a single row into the table: "UserAccessGroups" */
  insert_UserAccessGroups_one?: Maybe<UserAccessGroups>;
  /** insert data into the table: "UserInterfaceState" */
  insert_UserInterfaceState?: Maybe<UserInterfaceState_Mutation_Response>;
  /** insert a single row into the table: "UserInterfaceState" */
  insert_UserInterfaceState_one?: Maybe<UserInterfaceState>;
  /** insert data into the table: "Users" */
  insert_Users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "Users" */
  insert_Users_one?: Maybe<Users>;
  /** insert data into the table: "ValueTypes" */
  insert_ValueTypes?: Maybe<ValueTypes_Mutation_Response>;
  /** insert a single row into the table: "ValueTypes" */
  insert_ValueTypes_one?: Maybe<ValueTypes>;
  /** insert data into the table: "VeteranStatusEnum" */
  insert_VeteranStatusEnum?: Maybe<VeteranStatusEnum_Mutation_Response>;
  /** insert a single row into the table: "VeteranStatusEnum" */
  insert_VeteranStatusEnum_one?: Maybe<VeteranStatusEnum>;
  /** insert data into the table: "WhiteLabeling" */
  insert_WhiteLabeling?: Maybe<WhiteLabeling_Mutation_Response>;
  /** insert a single row into the table: "WhiteLabeling" */
  insert_WhiteLabeling_one?: Maybe<WhiteLabeling>;
  /** insert data into the table: "WorkflowRegressions" */
  insert_WorkflowRegressions?: Maybe<WorkflowRegressions_Mutation_Response>;
  /** insert a single row into the table: "WorkflowRegressions" */
  insert_WorkflowRegressions_one?: Maybe<WorkflowRegressions>;
  /** insert data into the table: "citizenshipEnum" */
  insert_citizenshipEnum?: Maybe<CitizenshipEnum_Mutation_Response>;
  /** insert a single row into the table: "citizenshipEnum" */
  insert_citizenshipEnum_one?: Maybe<CitizenshipEnum>;
  /** insert data into the table: "contract_emails" */
  insert_contract_emails?: Maybe<Contract_Emails_Mutation_Response>;
  /** insert a single row into the table: "contract_emails" */
  insert_contract_emails_one?: Maybe<Contract_Emails>;
  /** insert data into the table: "deleted_ppl_names" */
  insert_deleted_ppl_names?: Maybe<Deleted_Ppl_Names_Mutation_Response>;
  /** insert a single row into the table: "deleted_ppl_names" */
  insert_deleted_ppl_names_one?: Maybe<Deleted_Ppl_Names>;
  /** insert data into the table: "eeo_data_point_status_by_identity_view" */
  insert_eeo_data_point_status_by_identity_view?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Mutation_Response>;
  /** insert a single row into the table: "eeo_data_point_status_by_identity_view" */
  insert_eeo_data_point_status_by_identity_view_one?: Maybe<Eeo_Data_Point_Status_By_Identity_View>;
  /** insert data into the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  insert_eeo_data_points_unknown_as_other_by_identity_view?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Mutation_Response>;
  /** insert a single row into the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  insert_eeo_data_points_unknown_as_other_by_identity_view_one?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View>;
  /** insert data into the table: "eeo_status_by_identity" */
  insert_eeo_status_by_identity?: Maybe<Eeo_Status_By_Identity_Mutation_Response>;
  /** insert a single row into the table: "eeo_status_by_identity" */
  insert_eeo_status_by_identity_one?: Maybe<Eeo_Status_By_Identity>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "identity_roles_arr" */
  insert_identity_roles_arr?: Maybe<Identity_Roles_Arr_Mutation_Response>;
  /** insert a single row into the table: "identity_roles_arr" */
  insert_identity_roles_arr_one?: Maybe<Identity_Roles_Arr>;
  /** insert data into the table: "identity_roles_stat" */
  insert_identity_roles_stat?: Maybe<Identity_Roles_Stat_Mutation_Response>;
  /** insert a single row into the table: "identity_roles_stat" */
  insert_identity_roles_stat_one?: Maybe<Identity_Roles_Stat>;
  /** insert data into the table: "jobs_available_positions" */
  insert_jobs_available_positions?: Maybe<Jobs_Available_Positions_Mutation_Response>;
  /** insert a single row into the table: "jobs_available_positions" */
  insert_jobs_available_positions_one?: Maybe<Jobs_Available_Positions>;
  /** insert data into the table: "matches_from_applications" */
  insert_matches_from_applications?: Maybe<Matches_From_Applications_Mutation_Response>;
  /** insert a single row into the table: "matches_from_applications" */
  insert_matches_from_applications_one?: Maybe<Matches_From_Applications>;
  /** insert data into the table: "matches_from_board" */
  insert_matches_from_board?: Maybe<Matches_From_Board_Mutation_Response>;
  /** insert a single row into the table: "matches_from_board" */
  insert_matches_from_board_one?: Maybe<Matches_From_Board>;
  /** insert data into the table: "matches_from_tasks" */
  insert_matches_from_tasks?: Maybe<Matches_From_Tasks_Mutation_Response>;
  /** insert a single row into the table: "matches_from_tasks" */
  insert_matches_from_tasks_one?: Maybe<Matches_From_Tasks>;
  /** insert data into the table: "nylas_events" */
  insert_nylas_events?: Maybe<Nylas_Events_Mutation_Response>;
  /** insert a single row into the table: "nylas_events" */
  insert_nylas_events_one?: Maybe<Nylas_Events>;
  /** insert data into the table: "search_spec_results" */
  insert_search_spec_results?: Maybe<Search_Spec_Results_Mutation_Response>;
  /** insert a single row into the table: "search_spec_results" */
  insert_search_spec_results_one?: Maybe<Search_Spec_Results>;
  /** insert data into the table: "search_specs" */
  insert_search_specs?: Maybe<Search_Specs_Mutation_Response>;
  /** insert a single row into the table: "search_specs" */
  insert_search_specs_one?: Maybe<Search_Specs>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "staffing_status" */
  insert_staffing_status?: Maybe<Staffing_Status_Mutation_Response>;
  /** insert a single row into the table: "staffing_status" */
  insert_staffing_status_one?: Maybe<Staffing_Status>;
  /** insert data into the table: "text_messages" */
  insert_text_messages?: Maybe<Text_Messages_Mutation_Response>;
  /** insert a single row into the table: "text_messages" */
  insert_text_messages_one?: Maybe<Text_Messages>;
  /** insert data into the table: "util.deps_saved_ddl" */
  insert_util_deps_saved_ddl?: Maybe<Util_Deps_Saved_Ddl_Mutation_Response>;
  /** insert a single row into the table: "util.deps_saved_ddl" */
  insert_util_deps_saved_ddl_one?: Maybe<Util_Deps_Saved_Ddl>;
  /** insert data into the table: "workflow_contracts" */
  insert_workflow_contracts?: Maybe<Workflow_Contracts_Mutation_Response>;
  /** insert a single row into the table: "workflow_contracts" */
  insert_workflow_contracts_one?: Maybe<Workflow_Contracts>;
  /** insert data into the table: "workflow_departments" */
  insert_workflow_departments?: Maybe<Workflow_Departments_Mutation_Response>;
  /** insert a single row into the table: "workflow_departments" */
  insert_workflow_departments_one?: Maybe<Workflow_Departments>;
  issueIntegrationMergeLinkToken?: Maybe<MergeLinkTokenOutput>;
  manageEmailIntegration: ManageEmailIntegrationOutput;
  mergeDupes?: Maybe<MergeResult>;
  recordQuestionnaireResponse?: Maybe<RecordQuestionnaireResponseOutput>;
  refreshOnboarding?: Maybe<RefreshOutput>;
  /** execute VOLATILE function "refresh_analytics_materialized_views" which returns "analytics_view" */
  refresh_analytics_materialized_views: Array<Analytics_View>;
  /** execute VOLATILE function "reindex_identities" which returns "Identities" */
  reindex_identities: Array<Identities>;
  /** execute VOLATILE function "reindex_identity" which returns "Identities" */
  reindex_identity: Array<Identities>;
  renderEmail?: Maybe<RenderEmailResponse>;
  requestNum?: Maybe<Response>;
  resendOnboarding?: Maybe<ResendOnboardingOutput>;
  saveOnboardingATSCredentials?: Maybe<SaveOnboardingAtsCredentialsOutput>;
  sendCommentTaggingNotification?: Maybe<SendCommentOutput>;
  sendEmail: SendEmailOutput;
  sendSMS?: Maybe<SmsResponse>;
  startAsyncOperation?: Maybe<AsyncOperationOutput>;
  submitOnboarding?: Maybe<SubmitOutput>;
  syncGSheetCalcInputs: SyncGSheetCalcInputsOutput;
  updateCompanyConfig?: Maybe<CompanyConfigResult>;
  /** update data of the table: "APITokens" */
  update_APITokens?: Maybe<ApiTokens_Mutation_Response>;
  /** update single row of the table: "APITokens" */
  update_APITokens_by_pk?: Maybe<ApiTokens>;
  /** update multiples rows of table: "APITokens" */
  update_APITokens_many?: Maybe<Array<Maybe<ApiTokens_Mutation_Response>>>;
  /** update data of the table: "AccessIntents" */
  update_AccessIntents?: Maybe<AccessIntents_Mutation_Response>;
  /** update single row of the table: "AccessIntents" */
  update_AccessIntents_by_pk?: Maybe<AccessIntents>;
  /** update multiples rows of table: "AccessIntents" */
  update_AccessIntents_many?: Maybe<Array<Maybe<AccessIntents_Mutation_Response>>>;
  /** update data of the table: "AccessTypeEnum" */
  update_AccessTypeEnum?: Maybe<AccessTypeEnum_Mutation_Response>;
  /** update single row of the table: "AccessTypeEnum" */
  update_AccessTypeEnum_by_pk?: Maybe<AccessTypeEnum>;
  /** update multiples rows of table: "AccessTypeEnum" */
  update_AccessTypeEnum_many?: Maybe<Array<Maybe<AccessTypeEnum_Mutation_Response>>>;
  /** update data of the table: "ActiveEntitlements" */
  update_ActiveEntitlements?: Maybe<ActiveEntitlements_Mutation_Response>;
  /** update multiples rows of table: "ActiveEntitlements" */
  update_ActiveEntitlements_many?: Maybe<Array<Maybe<ActiveEntitlements_Mutation_Response>>>;
  /** update data of the table: "ActivityLogTypes" */
  update_ActivityLogTypes?: Maybe<ActivityLogTypes_Mutation_Response>;
  /** update single row of the table: "ActivityLogTypes" */
  update_ActivityLogTypes_by_pk?: Maybe<ActivityLogTypes>;
  /** update multiples rows of table: "ActivityLogTypes" */
  update_ActivityLogTypes_many?: Maybe<Array<Maybe<ActivityLogTypes_Mutation_Response>>>;
  /** update data of the table: "ActivityTemplates" */
  update_ActivityTemplates?: Maybe<ActivityTemplates_Mutation_Response>;
  /** update single row of the table: "ActivityTemplates" */
  update_ActivityTemplates_by_pk?: Maybe<ActivityTemplates>;
  /** update multiples rows of table: "ActivityTemplates" */
  update_ActivityTemplates_many?: Maybe<Array<Maybe<ActivityTemplates_Mutation_Response>>>;
  /** update data of the table: "AoIdentifierTypes" */
  update_AoIdentifierTypes?: Maybe<AoIdentifierTypes_Mutation_Response>;
  /** update single row of the table: "AoIdentifierTypes" */
  update_AoIdentifierTypes_by_pk?: Maybe<AoIdentifierTypes>;
  /** update multiples rows of table: "AoIdentifierTypes" */
  update_AoIdentifierTypes_many?: Maybe<Array<Maybe<AoIdentifierTypes_Mutation_Response>>>;
  /** update data of the table: "ApplicationStatus" */
  update_ApplicationStatus?: Maybe<ApplicationStatus_Mutation_Response>;
  /** update single row of the table: "ApplicationStatus" */
  update_ApplicationStatus_by_pk?: Maybe<ApplicationStatus>;
  /** update multiples rows of table: "ApplicationStatus" */
  update_ApplicationStatus_many?: Maybe<Array<Maybe<ApplicationStatus_Mutation_Response>>>;
  /** update data of the table: "ApplicationType" */
  update_ApplicationType?: Maybe<ApplicationType_Mutation_Response>;
  /** update single row of the table: "ApplicationType" */
  update_ApplicationType_by_pk?: Maybe<ApplicationType>;
  /** update multiples rows of table: "ApplicationType" */
  update_ApplicationType_many?: Maybe<Array<Maybe<ApplicationType_Mutation_Response>>>;
  /** update data of the table: "Applications" */
  update_Applications?: Maybe<Applications_Mutation_Response>;
  /** update single row of the table: "Applications" */
  update_Applications_by_pk?: Maybe<Applications>;
  /** update multiples rows of table: "Applications" */
  update_Applications_many?: Maybe<Array<Maybe<Applications_Mutation_Response>>>;
  /** update data of the table: "Assignments" */
  update_Assignments?: Maybe<Assignments_Mutation_Response>;
  /** update single row of the table: "Assignments" */
  update_Assignments_by_pk?: Maybe<Assignments>;
  /** update multiples rows of table: "Assignments" */
  update_Assignments_many?: Maybe<Array<Maybe<Assignments_Mutation_Response>>>;
  /** update data of the table: "AsyncOperations" */
  update_AsyncOperations?: Maybe<AsyncOperations_Mutation_Response>;
  /** update data of the table: "AsyncOperationsStates" */
  update_AsyncOperationsStates?: Maybe<AsyncOperationsStates_Mutation_Response>;
  /** update single row of the table: "AsyncOperationsStates" */
  update_AsyncOperationsStates_by_pk?: Maybe<AsyncOperationsStates>;
  /** update multiples rows of table: "AsyncOperationsStates" */
  update_AsyncOperationsStates_many?: Maybe<Array<Maybe<AsyncOperationsStates_Mutation_Response>>>;
  /** update single row of the table: "AsyncOperations" */
  update_AsyncOperations_by_pk?: Maybe<AsyncOperations>;
  /** update multiples rows of table: "AsyncOperations" */
  update_AsyncOperations_many?: Maybe<Array<Maybe<AsyncOperations_Mutation_Response>>>;
  /** update data of the table: "Attachments" */
  update_Attachments?: Maybe<Attachments_Mutation_Response>;
  /** update single row of the table: "Attachments" */
  update_Attachments_by_pk?: Maybe<Attachments>;
  /** update multiples rows of table: "Attachments" */
  update_Attachments_many?: Maybe<Array<Maybe<Attachments_Mutation_Response>>>;
  /** update data of the table: "AuditCreates" */
  update_AuditCreates?: Maybe<AuditCreates_Mutation_Response>;
  /** update single row of the table: "AuditCreates" */
  update_AuditCreates_by_pk?: Maybe<AuditCreates>;
  /** update multiples rows of table: "AuditCreates" */
  update_AuditCreates_many?: Maybe<Array<Maybe<AuditCreates_Mutation_Response>>>;
  /** update data of the table: "AuditStages" */
  update_AuditStages?: Maybe<AuditStages_Mutation_Response>;
  /** update single row of the table: "AuditStages" */
  update_AuditStages_by_pk?: Maybe<AuditStages>;
  /** update multiples rows of table: "AuditStages" */
  update_AuditStages_many?: Maybe<Array<Maybe<AuditStages_Mutation_Response>>>;
  /** update data of the table: "AuthAudits" */
  update_AuthAudits?: Maybe<AuthAudits_Mutation_Response>;
  /** update single row of the table: "AuthAudits" */
  update_AuthAudits_by_pk?: Maybe<AuthAudits>;
  /** update multiples rows of table: "AuthAudits" */
  update_AuthAudits_many?: Maybe<Array<Maybe<AuthAudits_Mutation_Response>>>;
  /** update data of the table: "Badges" */
  update_Badges?: Maybe<Badges_Mutation_Response>;
  /** update single row of the table: "Badges" */
  update_Badges_by_pk?: Maybe<Badges>;
  /** update multiples rows of table: "Badges" */
  update_Badges_many?: Maybe<Array<Maybe<Badges_Mutation_Response>>>;
  /** update data of the table: "Benefit" */
  update_Benefit?: Maybe<Benefit_Mutation_Response>;
  /** update data of the table: "BenefitTypeIdentitySubTypes" */
  update_BenefitTypeIdentitySubTypes?: Maybe<BenefitTypeIdentitySubTypes_Mutation_Response>;
  /** update single row of the table: "BenefitTypeIdentitySubTypes" */
  update_BenefitTypeIdentitySubTypes_by_pk?: Maybe<BenefitTypeIdentitySubTypes>;
  /** update multiples rows of table: "BenefitTypeIdentitySubTypes" */
  update_BenefitTypeIdentitySubTypes_many?: Maybe<Array<Maybe<BenefitTypeIdentitySubTypes_Mutation_Response>>>;
  /** update data of the table: "BenefitTypes" */
  update_BenefitTypes?: Maybe<BenefitTypes_Mutation_Response>;
  /** update single row of the table: "BenefitTypes" */
  update_BenefitTypes_by_pk?: Maybe<BenefitTypes>;
  /** update multiples rows of table: "BenefitTypes" */
  update_BenefitTypes_many?: Maybe<Array<Maybe<BenefitTypes_Mutation_Response>>>;
  /** update single row of the table: "Benefit" */
  update_Benefit_by_pk?: Maybe<Benefit>;
  /** update multiples rows of table: "Benefit" */
  update_Benefit_many?: Maybe<Array<Maybe<Benefit_Mutation_Response>>>;
  /** update data of the table: "BoardFilters" */
  update_BoardFilters?: Maybe<BoardFilters_Mutation_Response>;
  /** update single row of the table: "BoardFilters" */
  update_BoardFilters_by_pk?: Maybe<BoardFilters>;
  /** update multiples rows of table: "BoardFilters" */
  update_BoardFilters_many?: Maybe<Array<Maybe<BoardFilters_Mutation_Response>>>;
  /** update data of the table: "BoardSnippets" */
  update_BoardSnippets?: Maybe<BoardSnippets_Mutation_Response>;
  /** update single row of the table: "BoardSnippets" */
  update_BoardSnippets_by_pk?: Maybe<BoardSnippets>;
  /** update multiples rows of table: "BoardSnippets" */
  update_BoardSnippets_many?: Maybe<Array<Maybe<BoardSnippets_Mutation_Response>>>;
  /** update data of the table: "BullhornSyncStatus" */
  update_BullhornSyncStatus?: Maybe<BullhornSyncStatus_Mutation_Response>;
  /** update single row of the table: "BullhornSyncStatus" */
  update_BullhornSyncStatus_by_pk?: Maybe<BullhornSyncStatus>;
  /** update multiples rows of table: "BullhornSyncStatus" */
  update_BullhornSyncStatus_many?: Maybe<Array<Maybe<BullhornSyncStatus_Mutation_Response>>>;
  /** update data of the table: "CalcEquationResults" */
  update_CalcEquationResults?: Maybe<CalcEquationResults_Mutation_Response>;
  /** update single row of the table: "CalcEquationResults" */
  update_CalcEquationResults_by_pk?: Maybe<CalcEquationResults>;
  /** update multiples rows of table: "CalcEquationResults" */
  update_CalcEquationResults_many?: Maybe<Array<Maybe<CalcEquationResults_Mutation_Response>>>;
  /** update data of the table: "CalcFormValues" */
  update_CalcFormValues?: Maybe<CalcFormValues_Mutation_Response>;
  /** update single row of the table: "CalcFormValues" */
  update_CalcFormValues_by_pk?: Maybe<CalcFormValues>;
  /** update multiples rows of table: "CalcFormValues" */
  update_CalcFormValues_many?: Maybe<Array<Maybe<CalcFormValues_Mutation_Response>>>;
  /** update data of the table: "Calcs" */
  update_Calcs?: Maybe<Calcs_Mutation_Response>;
  /** update single row of the table: "Calcs" */
  update_Calcs_by_pk?: Maybe<Calcs>;
  /** update multiples rows of table: "Calcs" */
  update_Calcs_many?: Maybe<Array<Maybe<Calcs_Mutation_Response>>>;
  /** update data of the table: "CalculationTemplateVersions" */
  update_CalculationTemplateVersions?: Maybe<CalculationTemplateVersions_Mutation_Response>;
  /** update single row of the table: "CalculationTemplateVersions" */
  update_CalculationTemplateVersions_by_pk?: Maybe<CalculationTemplateVersions>;
  /** update multiples rows of table: "CalculationTemplateVersions" */
  update_CalculationTemplateVersions_many?: Maybe<Array<Maybe<CalculationTemplateVersions_Mutation_Response>>>;
  /** update data of the table: "CalculationTemplates" */
  update_CalculationTemplates?: Maybe<CalculationTemplates_Mutation_Response>;
  /** update single row of the table: "CalculationTemplates" */
  update_CalculationTemplates_by_pk?: Maybe<CalculationTemplates>;
  /** update multiples rows of table: "CalculationTemplates" */
  update_CalculationTemplates_many?: Maybe<Array<Maybe<CalculationTemplates_Mutation_Response>>>;
  /** update data of the table: "CalculationVersionFormInputs" */
  update_CalculationVersionFormInputs?: Maybe<CalculationVersionFormInputs_Mutation_Response>;
  /** update single row of the table: "CalculationVersionFormInputs" */
  update_CalculationVersionFormInputs_by_pk?: Maybe<CalculationVersionFormInputs>;
  /** update multiples rows of table: "CalculationVersionFormInputs" */
  update_CalculationVersionFormInputs_many?: Maybe<Array<Maybe<CalculationVersionFormInputs_Mutation_Response>>>;
  /** update data of the table: "Calculations" */
  update_Calculations?: Maybe<Calculations_Mutation_Response>;
  /** update single row of the table: "Calculations" */
  update_Calculations_by_pk?: Maybe<Calculations>;
  /** update multiples rows of table: "Calculations" */
  update_Calculations_many?: Maybe<Array<Maybe<Calculations_Mutation_Response>>>;
  /** update data of the table: "CandidateSources" */
  update_CandidateSources?: Maybe<CandidateSources_Mutation_Response>;
  /** update single row of the table: "CandidateSources" */
  update_CandidateSources_by_pk?: Maybe<CandidateSources>;
  /** update multiples rows of table: "CandidateSources" */
  update_CandidateSources_many?: Maybe<Array<Maybe<CandidateSources_Mutation_Response>>>;
  /** update data of the table: "CandidateStatuses" */
  update_CandidateStatuses?: Maybe<CandidateStatuses_Mutation_Response>;
  /** update single row of the table: "CandidateStatuses" */
  update_CandidateStatuses_by_pk?: Maybe<CandidateStatuses>;
  /** update multiples rows of table: "CandidateStatuses" */
  update_CandidateStatuses_many?: Maybe<Array<Maybe<CandidateStatuses_Mutation_Response>>>;
  /** update data of the table: "CaptureNodes" */
  update_CaptureNodes?: Maybe<CaptureNodes_Mutation_Response>;
  /** update single row of the table: "CaptureNodes" */
  update_CaptureNodes_by_pk?: Maybe<CaptureNodes>;
  /** update multiples rows of table: "CaptureNodes" */
  update_CaptureNodes_many?: Maybe<Array<Maybe<CaptureNodes_Mutation_Response>>>;
  /** update data of the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  update_ClientSideExternalSystemIntegrationPluginRecord?: Maybe<ClientSideExternalSystemIntegrationPluginRecord_Mutation_Response>;
  /** update single row of the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  update_ClientSideExternalSystemIntegrationPluginRecord_by_pk?: Maybe<ClientSideExternalSystemIntegrationPluginRecord>;
  /** update multiples rows of table: "ClientSideExternalSystemIntegrationPluginRecord" */
  update_ClientSideExternalSystemIntegrationPluginRecord_many?: Maybe<Array<Maybe<ClientSideExternalSystemIntegrationPluginRecord_Mutation_Response>>>;
  /** update data of the table: "CognitoUserData" */
  update_CognitoUserData?: Maybe<CognitoUserData_Mutation_Response>;
  /** update single row of the table: "CognitoUserData" */
  update_CognitoUserData_by_pk?: Maybe<CognitoUserData>;
  /** update multiples rows of table: "CognitoUserData" */
  update_CognitoUserData_many?: Maybe<Array<Maybe<CognitoUserData_Mutation_Response>>>;
  /** update data of the table: "CommentTypes" */
  update_CommentTypes?: Maybe<CommentTypes_Mutation_Response>;
  /** update single row of the table: "CommentTypes" */
  update_CommentTypes_by_pk?: Maybe<CommentTypes>;
  /** update multiples rows of table: "CommentTypes" */
  update_CommentTypes_many?: Maybe<Array<Maybe<CommentTypes_Mutation_Response>>>;
  /** update data of the table: "Comments" */
  update_Comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "Comments" */
  update_Comments_by_pk?: Maybe<Comments>;
  /** update multiples rows of table: "Comments" */
  update_Comments_many?: Maybe<Array<Maybe<Comments_Mutation_Response>>>;
  /** update data of the table: "Companies" */
  update_Companies?: Maybe<Companies_Mutation_Response>;
  /** update single row of the table: "Companies" */
  update_Companies_by_pk?: Maybe<Companies>;
  /** update multiples rows of table: "Companies" */
  update_Companies_many?: Maybe<Array<Maybe<Companies_Mutation_Response>>>;
  /** update data of the table: "CompanyEvents" */
  update_CompanyEvents?: Maybe<CompanyEvents_Mutation_Response>;
  /** update single row of the table: "CompanyEvents" */
  update_CompanyEvents_by_pk?: Maybe<CompanyEvents>;
  /** update multiples rows of table: "CompanyEvents" */
  update_CompanyEvents_many?: Maybe<Array<Maybe<CompanyEvents_Mutation_Response>>>;
  /** update data of the table: "CompanyStatusEnum" */
  update_CompanyStatusEnum?: Maybe<CompanyStatusEnum_Mutation_Response>;
  /** update single row of the table: "CompanyStatusEnum" */
  update_CompanyStatusEnum_by_pk?: Maybe<CompanyStatusEnum>;
  /** update multiples rows of table: "CompanyStatusEnum" */
  update_CompanyStatusEnum_many?: Maybe<Array<Maybe<CompanyStatusEnum_Mutation_Response>>>;
  /** update data of the table: "Compensations" */
  update_Compensations?: Maybe<Compensations_Mutation_Response>;
  /** update single row of the table: "Compensations" */
  update_Compensations_by_pk?: Maybe<Compensations>;
  /** update multiples rows of table: "Compensations" */
  update_Compensations_many?: Maybe<Array<Maybe<Compensations_Mutation_Response>>>;
  /** update data of the table: "ContactTemplateVariablesEnum" */
  update_ContactTemplateVariablesEnum?: Maybe<ContactTemplateVariablesEnum_Mutation_Response>;
  /** update single row of the table: "ContactTemplateVariablesEnum" */
  update_ContactTemplateVariablesEnum_by_pk?: Maybe<ContactTemplateVariablesEnum>;
  /** update multiples rows of table: "ContactTemplateVariablesEnum" */
  update_ContactTemplateVariablesEnum_many?: Maybe<Array<Maybe<ContactTemplateVariablesEnum_Mutation_Response>>>;
  /** update data of the table: "ContractComments" */
  update_ContractComments?: Maybe<ContractComments_Mutation_Response>;
  /** update single row of the table: "ContractComments" */
  update_ContractComments_by_pk?: Maybe<ContractComments>;
  /** update multiples rows of table: "ContractComments" */
  update_ContractComments_many?: Maybe<Array<Maybe<ContractComments_Mutation_Response>>>;
  /** update data of the table: "ContractFiles" */
  update_ContractFiles?: Maybe<ContractFiles_Mutation_Response>;
  /** update single row of the table: "ContractFiles" */
  update_ContractFiles_by_pk?: Maybe<ContractFiles>;
  /** update multiples rows of table: "ContractFiles" */
  update_ContractFiles_many?: Maybe<Array<Maybe<ContractFiles_Mutation_Response>>>;
  /** update data of the table: "ContractStages" */
  update_ContractStages?: Maybe<ContractStages_Mutation_Response>;
  /** update single row of the table: "ContractStages" */
  update_ContractStages_by_pk?: Maybe<ContractStages>;
  /** update multiples rows of table: "ContractStages" */
  update_ContractStages_many?: Maybe<Array<Maybe<ContractStages_Mutation_Response>>>;
  /** update data of the table: "ContractStatusEnum" */
  update_ContractStatusEnum?: Maybe<ContractStatusEnum_Mutation_Response>;
  /** update single row of the table: "ContractStatusEnum" */
  update_ContractStatusEnum_by_pk?: Maybe<ContractStatusEnum>;
  /** update multiples rows of table: "ContractStatusEnum" */
  update_ContractStatusEnum_many?: Maybe<Array<Maybe<ContractStatusEnum_Mutation_Response>>>;
  /** update data of the table: "Contracts" */
  update_Contracts?: Maybe<Contracts_Mutation_Response>;
  /** update single row of the table: "Contracts" */
  update_Contracts_by_pk?: Maybe<Contracts>;
  /** update multiples rows of table: "Contracts" */
  update_Contracts_many?: Maybe<Array<Maybe<Contracts_Mutation_Response>>>;
  /** update data of the table: "CurrentStages" */
  update_CurrentStages?: Maybe<CurrentStages_Mutation_Response>;
  /** update single row of the table: "CurrentStages" */
  update_CurrentStages_by_pk?: Maybe<CurrentStages>;
  /** update multiples rows of table: "CurrentStages" */
  update_CurrentStages_many?: Maybe<Array<Maybe<CurrentStages_Mutation_Response>>>;
  /** update data of the table: "DataHistory" */
  update_DataHistory?: Maybe<DataHistory_Mutation_Response>;
  /** update single row of the table: "DataHistory" */
  update_DataHistory_by_pk?: Maybe<DataHistory>;
  /** update multiples rows of table: "DataHistory" */
  update_DataHistory_many?: Maybe<Array<Maybe<DataHistory_Mutation_Response>>>;
  /** update data of the table: "Departments" */
  update_Departments?: Maybe<Departments_Mutation_Response>;
  /** update single row of the table: "Departments" */
  update_Departments_by_pk?: Maybe<Departments>;
  /** update multiples rows of table: "Departments" */
  update_Departments_many?: Maybe<Array<Maybe<Departments_Mutation_Response>>>;
  /** update data of the table: "DisabilityStatusEnum" */
  update_DisabilityStatusEnum?: Maybe<DisabilityStatusEnum_Mutation_Response>;
  /** update single row of the table: "DisabilityStatusEnum" */
  update_DisabilityStatusEnum_by_pk?: Maybe<DisabilityStatusEnum>;
  /** update multiples rows of table: "DisabilityStatusEnum" */
  update_DisabilityStatusEnum_many?: Maybe<Array<Maybe<DisabilityStatusEnum_Mutation_Response>>>;
  /** update data of the table: "EEOJobCategoriesEnum" */
  update_EEOJobCategoriesEnum?: Maybe<EeoJobCategoriesEnum_Mutation_Response>;
  /** update single row of the table: "EEOJobCategoriesEnum" */
  update_EEOJobCategoriesEnum_by_pk?: Maybe<EeoJobCategoriesEnum>;
  /** update multiples rows of table: "EEOJobCategoriesEnum" */
  update_EEOJobCategoriesEnum_many?: Maybe<Array<Maybe<EeoJobCategoriesEnum_Mutation_Response>>>;
  /** update data of the table: "EarnedBadges" */
  update_EarnedBadges?: Maybe<EarnedBadges_Mutation_Response>;
  /** update single row of the table: "EarnedBadges" */
  update_EarnedBadges_by_pk?: Maybe<EarnedBadges>;
  /** update multiples rows of table: "EarnedBadges" */
  update_EarnedBadges_many?: Maybe<Array<Maybe<EarnedBadges_Mutation_Response>>>;
  /** update data of the table: "EmailAddresses" */
  update_EmailAddresses?: Maybe<EmailAddresses_Mutation_Response>;
  /** update single row of the table: "EmailAddresses" */
  update_EmailAddresses_by_pk?: Maybe<EmailAddresses>;
  /** update multiples rows of table: "EmailAddresses" */
  update_EmailAddresses_many?: Maybe<Array<Maybe<EmailAddresses_Mutation_Response>>>;
  /** update data of the table: "EmailEvents" */
  update_EmailEvents?: Maybe<EmailEvents_Mutation_Response>;
  /** update single row of the table: "EmailEvents" */
  update_EmailEvents_by_pk?: Maybe<EmailEvents>;
  /** update multiples rows of table: "EmailEvents" */
  update_EmailEvents_many?: Maybe<Array<Maybe<EmailEvents_Mutation_Response>>>;
  /** update data of the table: "EmailRecords" */
  update_EmailRecords?: Maybe<EmailRecords_Mutation_Response>;
  /** update single row of the table: "EmailRecords" */
  update_EmailRecords_by_pk?: Maybe<EmailRecords>;
  /** update multiples rows of table: "EmailRecords" */
  update_EmailRecords_many?: Maybe<Array<Maybe<EmailRecords_Mutation_Response>>>;
  /** update data of the table: "EmailSignatures" */
  update_EmailSignatures?: Maybe<EmailSignatures_Mutation_Response>;
  /** update single row of the table: "EmailSignatures" */
  update_EmailSignatures_by_pk?: Maybe<EmailSignatures>;
  /** update multiples rows of table: "EmailSignatures" */
  update_EmailSignatures_many?: Maybe<Array<Maybe<EmailSignatures_Mutation_Response>>>;
  /** update data of the table: "EmailSubFolders" */
  update_EmailSubFolders?: Maybe<EmailSubFolders_Mutation_Response>;
  /** update single row of the table: "EmailSubFolders" */
  update_EmailSubFolders_by_pk?: Maybe<EmailSubFolders>;
  /** update multiples rows of table: "EmailSubFolders" */
  update_EmailSubFolders_many?: Maybe<Array<Maybe<EmailSubFolders_Mutation_Response>>>;
  /** update data of the table: "EmailTypeEnum" */
  update_EmailTypeEnum?: Maybe<EmailTypeEnum_Mutation_Response>;
  /** update single row of the table: "EmailTypeEnum" */
  update_EmailTypeEnum_by_pk?: Maybe<EmailTypeEnum>;
  /** update multiples rows of table: "EmailTypeEnum" */
  update_EmailTypeEnum_many?: Maybe<Array<Maybe<EmailTypeEnum_Mutation_Response>>>;
  /** update data of the table: "EmailTypes" */
  update_EmailTypes?: Maybe<EmailTypes_Mutation_Response>;
  /** update single row of the table: "EmailTypes" */
  update_EmailTypes_by_pk?: Maybe<EmailTypes>;
  /** update multiples rows of table: "EmailTypes" */
  update_EmailTypes_many?: Maybe<Array<Maybe<EmailTypes_Mutation_Response>>>;
  /** update data of the table: "Emails" */
  update_Emails?: Maybe<Emails_Mutation_Response>;
  /** update single row of the table: "Emails" */
  update_Emails_by_pk?: Maybe<Emails>;
  /** update multiples rows of table: "Emails" */
  update_Emails_many?: Maybe<Array<Maybe<Emails_Mutation_Response>>>;
  /** update data of the table: "EmploymentHireType" */
  update_EmploymentHireType?: Maybe<EmploymentHireType_Mutation_Response>;
  /** update single row of the table: "EmploymentHireType" */
  update_EmploymentHireType_by_pk?: Maybe<EmploymentHireType>;
  /** update multiples rows of table: "EmploymentHireType" */
  update_EmploymentHireType_many?: Maybe<Array<Maybe<EmploymentHireType_Mutation_Response>>>;
  /** update data of the table: "EmploymentTypes" */
  update_EmploymentTypes?: Maybe<EmploymentTypes_Mutation_Response>;
  /** update single row of the table: "EmploymentTypes" */
  update_EmploymentTypes_by_pk?: Maybe<EmploymentTypes>;
  /** update multiples rows of table: "EmploymentTypes" */
  update_EmploymentTypes_many?: Maybe<Array<Maybe<EmploymentTypes_Mutation_Response>>>;
  /** update data of the table: "Employments" */
  update_Employments?: Maybe<Employments_Mutation_Response>;
  /** update single row of the table: "Employments" */
  update_Employments_by_pk?: Maybe<Employments>;
  /** update multiples rows of table: "Employments" */
  update_Employments_many?: Maybe<Array<Maybe<Employments_Mutation_Response>>>;
  /** update data of the table: "EntitlementCategories" */
  update_EntitlementCategories?: Maybe<EntitlementCategories_Mutation_Response>;
  /** update single row of the table: "EntitlementCategories" */
  update_EntitlementCategories_by_pk?: Maybe<EntitlementCategories>;
  /** update multiples rows of table: "EntitlementCategories" */
  update_EntitlementCategories_many?: Maybe<Array<Maybe<EntitlementCategories_Mutation_Response>>>;
  /** update data of the table: "Entitlements" */
  update_Entitlements?: Maybe<Entitlements_Mutation_Response>;
  /** update single row of the table: "Entitlements" */
  update_Entitlements_by_pk?: Maybe<Entitlements>;
  /** update multiples rows of table: "Entitlements" */
  update_Entitlements_many?: Maybe<Array<Maybe<Entitlements_Mutation_Response>>>;
  /** update data of the table: "EquationFormulaOption" */
  update_EquationFormulaOption?: Maybe<EquationFormulaOption_Mutation_Response>;
  /** update single row of the table: "EquationFormulaOption" */
  update_EquationFormulaOption_by_pk?: Maybe<EquationFormulaOption>;
  /** update multiples rows of table: "EquationFormulaOption" */
  update_EquationFormulaOption_many?: Maybe<Array<Maybe<EquationFormulaOption_Mutation_Response>>>;
  /** update data of the table: "Equations" */
  update_Equations?: Maybe<Equations_Mutation_Response>;
  /** update single row of the table: "Equations" */
  update_Equations_by_pk?: Maybe<Equations>;
  /** update multiples rows of table: "Equations" */
  update_Equations_many?: Maybe<Array<Maybe<Equations_Mutation_Response>>>;
  /** update data of the table: "Events" */
  update_Events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "Events" */
  update_Events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "Events" */
  update_Events_many?: Maybe<Array<Maybe<Events_Mutation_Response>>>;
  /** update data of the table: "Expense" */
  update_Expense?: Maybe<Expense_Mutation_Response>;
  /** update single row of the table: "Expense" */
  update_Expense_by_pk?: Maybe<Expense>;
  /** update multiples rows of table: "Expense" */
  update_Expense_many?: Maybe<Array<Maybe<Expense_Mutation_Response>>>;
  /** update data of the table: "ExternalIntegrationTypes" */
  update_ExternalIntegrationTypes?: Maybe<ExternalIntegrationTypes_Mutation_Response>;
  /** update single row of the table: "ExternalIntegrationTypes" */
  update_ExternalIntegrationTypes_by_pk?: Maybe<ExternalIntegrationTypes>;
  /** update multiples rows of table: "ExternalIntegrationTypes" */
  update_ExternalIntegrationTypes_many?: Maybe<Array<Maybe<ExternalIntegrationTypes_Mutation_Response>>>;
  /** update data of the table: "ExternalIntegrations" */
  update_ExternalIntegrations?: Maybe<ExternalIntegrations_Mutation_Response>;
  /** update single row of the table: "ExternalIntegrations" */
  update_ExternalIntegrations_by_pk?: Maybe<ExternalIntegrations>;
  /** update multiples rows of table: "ExternalIntegrations" */
  update_ExternalIntegrations_many?: Maybe<Array<Maybe<ExternalIntegrations_Mutation_Response>>>;
  /** update data of the table: "ExternalJobBoardPublishings" */
  update_ExternalJobBoardPublishings?: Maybe<ExternalJobBoardPublishings_Mutation_Response>;
  /** update single row of the table: "ExternalJobBoardPublishings" */
  update_ExternalJobBoardPublishings_by_pk?: Maybe<ExternalJobBoardPublishings>;
  /** update multiples rows of table: "ExternalJobBoardPublishings" */
  update_ExternalJobBoardPublishings_many?: Maybe<Array<Maybe<ExternalJobBoardPublishings_Mutation_Response>>>;
  /** update data of the table: "ExternalJobBoards" */
  update_ExternalJobBoards?: Maybe<ExternalJobBoards_Mutation_Response>;
  /** update single row of the table: "ExternalJobBoards" */
  update_ExternalJobBoards_by_pk?: Maybe<ExternalJobBoards>;
  /** update multiples rows of table: "ExternalJobBoards" */
  update_ExternalJobBoards_many?: Maybe<Array<Maybe<ExternalJobBoards_Mutation_Response>>>;
  /** update data of the table: "ExternalSystem" */
  update_ExternalSystem?: Maybe<ExternalSystem_Mutation_Response>;
  /** update data of the table: "ExternalSystemInstance" */
  update_ExternalSystemInstance?: Maybe<ExternalSystemInstance_Mutation_Response>;
  /** update single row of the table: "ExternalSystemInstance" */
  update_ExternalSystemInstance_by_pk?: Maybe<ExternalSystemInstance>;
  /** update multiples rows of table: "ExternalSystemInstance" */
  update_ExternalSystemInstance_many?: Maybe<Array<Maybe<ExternalSystemInstance_Mutation_Response>>>;
  /** update single row of the table: "ExternalSystem" */
  update_ExternalSystem_by_pk?: Maybe<ExternalSystem>;
  /** update multiples rows of table: "ExternalSystem" */
  update_ExternalSystem_many?: Maybe<Array<Maybe<ExternalSystem_Mutation_Response>>>;
  /** update data of the table: "FeatureFlags" */
  update_FeatureFlags?: Maybe<FeatureFlags_Mutation_Response>;
  /** update single row of the table: "FeatureFlags" */
  update_FeatureFlags_by_pk?: Maybe<FeatureFlags>;
  /** update multiples rows of table: "FeatureFlags" */
  update_FeatureFlags_many?: Maybe<Array<Maybe<FeatureFlags_Mutation_Response>>>;
  /** update data of the table: "Field" */
  update_Field?: Maybe<Field_Mutation_Response>;
  /** update data of the table: "FieldDef" */
  update_FieldDef?: Maybe<FieldDef_Mutation_Response>;
  /** update single row of the table: "FieldDef" */
  update_FieldDef_by_pk?: Maybe<FieldDef>;
  /** update multiples rows of table: "FieldDef" */
  update_FieldDef_many?: Maybe<Array<Maybe<FieldDef_Mutation_Response>>>;
  /** update data of the table: "FieldPicklistValues" */
  update_FieldPicklistValues?: Maybe<FieldPicklistValues_Mutation_Response>;
  /** update single row of the table: "FieldPicklistValues" */
  update_FieldPicklistValues_by_pk?: Maybe<FieldPicklistValues>;
  /** update multiples rows of table: "FieldPicklistValues" */
  update_FieldPicklistValues_many?: Maybe<Array<Maybe<FieldPicklistValues_Mutation_Response>>>;
  /** update data of the table: "FieldTypeDef" */
  update_FieldTypeDef?: Maybe<FieldTypeDef_Mutation_Response>;
  /** update single row of the table: "FieldTypeDef" */
  update_FieldTypeDef_by_pk?: Maybe<FieldTypeDef>;
  /** update multiples rows of table: "FieldTypeDef" */
  update_FieldTypeDef_many?: Maybe<Array<Maybe<FieldTypeDef_Mutation_Response>>>;
  /** update single row of the table: "Field" */
  update_Field_by_pk?: Maybe<Field>;
  /** update multiples rows of table: "Field" */
  update_Field_many?: Maybe<Array<Maybe<Field_Mutation_Response>>>;
  /** update data of the table: "FileTags" */
  update_FileTags?: Maybe<FileTags_Mutation_Response>;
  /** update single row of the table: "FileTags" */
  update_FileTags_by_pk?: Maybe<FileTags>;
  /** update multiples rows of table: "FileTags" */
  update_FileTags_many?: Maybe<Array<Maybe<FileTags_Mutation_Response>>>;
  /** update data of the table: "Files" */
  update_Files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "Files" */
  update_Files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "Files" */
  update_Files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "FormInputs" */
  update_FormInputs?: Maybe<FormInputs_Mutation_Response>;
  /** update single row of the table: "FormInputs" */
  update_FormInputs_by_pk?: Maybe<FormInputs>;
  /** update multiples rows of table: "FormInputs" */
  update_FormInputs_many?: Maybe<Array<Maybe<FormInputs_Mutation_Response>>>;
  /** update data of the table: "FormSelectOptions" */
  update_FormSelectOptions?: Maybe<FormSelectOptions_Mutation_Response>;
  /** update single row of the table: "FormSelectOptions" */
  update_FormSelectOptions_by_pk?: Maybe<FormSelectOptions>;
  /** update multiples rows of table: "FormSelectOptions" */
  update_FormSelectOptions_many?: Maybe<Array<Maybe<FormSelectOptions_Mutation_Response>>>;
  /** update data of the table: "FormulaOptions" */
  update_FormulaOptions?: Maybe<FormulaOptions_Mutation_Response>;
  /** update single row of the table: "FormulaOptions" */
  update_FormulaOptions_by_pk?: Maybe<FormulaOptions>;
  /** update multiples rows of table: "FormulaOptions" */
  update_FormulaOptions_many?: Maybe<Array<Maybe<FormulaOptions_Mutation_Response>>>;
  /** update data of the table: "GenderEnum" */
  update_GenderEnum?: Maybe<GenderEnum_Mutation_Response>;
  /** update single row of the table: "GenderEnum" */
  update_GenderEnum_by_pk?: Maybe<GenderEnum>;
  /** update multiples rows of table: "GenderEnum" */
  update_GenderEnum_many?: Maybe<Array<Maybe<GenderEnum_Mutation_Response>>>;
  /** update data of the table: "GeocodingHistory" */
  update_GeocodingHistory?: Maybe<GeocodingHistory_Mutation_Response>;
  /** update single row of the table: "GeocodingHistory" */
  update_GeocodingHistory_by_pk?: Maybe<GeocodingHistory>;
  /** update multiples rows of table: "GeocodingHistory" */
  update_GeocodingHistory_many?: Maybe<Array<Maybe<GeocodingHistory_Mutation_Response>>>;
  /** update data of the table: "Goals" */
  update_Goals?: Maybe<Goals_Mutation_Response>;
  /** update single row of the table: "Goals" */
  update_Goals_by_pk?: Maybe<Goals>;
  /** update multiples rows of table: "Goals" */
  update_Goals_many?: Maybe<Array<Maybe<Goals_Mutation_Response>>>;
  /** update data of the table: "GoogleSheetCalcTemplates" */
  update_GoogleSheetCalcTemplates?: Maybe<GoogleSheetCalcTemplates_Mutation_Response>;
  /** update single row of the table: "GoogleSheetCalcTemplates" */
  update_GoogleSheetCalcTemplates_by_pk?: Maybe<GoogleSheetCalcTemplates>;
  /** update multiples rows of table: "GoogleSheetCalcTemplates" */
  update_GoogleSheetCalcTemplates_many?: Maybe<Array<Maybe<GoogleSheetCalcTemplates_Mutation_Response>>>;
  /** update data of the table: "GoogleSheetCalculations" */
  update_GoogleSheetCalculations?: Maybe<GoogleSheetCalculations_Mutation_Response>;
  /** update single row of the table: "GoogleSheetCalculations" */
  update_GoogleSheetCalculations_by_pk?: Maybe<GoogleSheetCalculations>;
  /** update multiples rows of table: "GoogleSheetCalculations" */
  update_GoogleSheetCalculations_many?: Maybe<Array<Maybe<GoogleSheetCalculations_Mutation_Response>>>;
  /** update data of the table: "GroupJobs" */
  update_GroupJobs?: Maybe<GroupJobs_Mutation_Response>;
  /** update single row of the table: "GroupJobs" */
  update_GroupJobs_by_pk?: Maybe<GroupJobs>;
  /** update multiples rows of table: "GroupJobs" */
  update_GroupJobs_many?: Maybe<Array<Maybe<GroupJobs_Mutation_Response>>>;
  /** update data of the table: "Groups" */
  update_Groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "Groups" */
  update_Groups_by_pk?: Maybe<Groups>;
  /** update multiples rows of table: "Groups" */
  update_Groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
  /** update data of the table: "HighestStages" */
  update_HighestStages?: Maybe<HighestStages_Mutation_Response>;
  /** update single row of the table: "HighestStages" */
  update_HighestStages_by_pk?: Maybe<HighestStages>;
  /** update multiples rows of table: "HighestStages" */
  update_HighestStages_many?: Maybe<Array<Maybe<HighestStages_Mutation_Response>>>;
  /** update data of the table: "HireTypesEnum" */
  update_HireTypesEnum?: Maybe<HireTypesEnum_Mutation_Response>;
  /** update single row of the table: "HireTypesEnum" */
  update_HireTypesEnum_by_pk?: Maybe<HireTypesEnum>;
  /** update multiples rows of table: "HireTypesEnum" */
  update_HireTypesEnum_many?: Maybe<Array<Maybe<HireTypesEnum_Mutation_Response>>>;
  /** update data of the table: "ITRequestContractEmails" */
  update_ITRequestContractEmails?: Maybe<ItRequestContractEmails_Mutation_Response>;
  /** update single row of the table: "ITRequestContractEmails" */
  update_ITRequestContractEmails_by_pk?: Maybe<ItRequestContractEmails>;
  /** update multiples rows of table: "ITRequestContractEmails" */
  update_ITRequestContractEmails_many?: Maybe<Array<Maybe<ItRequestContractEmails_Mutation_Response>>>;
  /** update data of the table: "Identities" */
  update_Identities?: Maybe<Identities_Mutation_Response>;
  /** update data of the table: "IdentitiesOverlaysLinks" */
  update_IdentitiesOverlaysLinks?: Maybe<IdentitiesOverlaysLinks_Mutation_Response>;
  /** update single row of the table: "IdentitiesOverlaysLinks" */
  update_IdentitiesOverlaysLinks_by_pk?: Maybe<IdentitiesOverlaysLinks>;
  /** update multiples rows of table: "IdentitiesOverlaysLinks" */
  update_IdentitiesOverlaysLinks_many?: Maybe<Array<Maybe<IdentitiesOverlaysLinks_Mutation_Response>>>;
  /** update single row of the table: "Identities" */
  update_Identities_by_pk?: Maybe<Identities>;
  /** update multiples rows of table: "Identities" */
  update_Identities_many?: Maybe<Array<Maybe<Identities_Mutation_Response>>>;
  /** update data of the table: "IdentityAssignments" */
  update_IdentityAssignments?: Maybe<IdentityAssignments_Mutation_Response>;
  /** update single row of the table: "IdentityAssignments" */
  update_IdentityAssignments_by_pk?: Maybe<IdentityAssignments>;
  /** update multiples rows of table: "IdentityAssignments" */
  update_IdentityAssignments_many?: Maybe<Array<Maybe<IdentityAssignments_Mutation_Response>>>;
  /** update data of the table: "IdentityComments" */
  update_IdentityComments?: Maybe<IdentityComments_Mutation_Response>;
  /** update single row of the table: "IdentityComments" */
  update_IdentityComments_by_pk?: Maybe<IdentityComments>;
  /** update multiples rows of table: "IdentityComments" */
  update_IdentityComments_many?: Maybe<Array<Maybe<IdentityComments_Mutation_Response>>>;
  /** update data of the table: "IdentityContractRelationship" */
  update_IdentityContractRelationship?: Maybe<IdentityContractRelationship_Mutation_Response>;
  /** update single row of the table: "IdentityContractRelationship" */
  update_IdentityContractRelationship_by_pk?: Maybe<IdentityContractRelationship>;
  /** update multiples rows of table: "IdentityContractRelationship" */
  update_IdentityContractRelationship_many?: Maybe<Array<Maybe<IdentityContractRelationship_Mutation_Response>>>;
  /** update data of the table: "IdentityDepartmentRelationship" */
  update_IdentityDepartmentRelationship?: Maybe<IdentityDepartmentRelationship_Mutation_Response>;
  /** update single row of the table: "IdentityDepartmentRelationship" */
  update_IdentityDepartmentRelationship_by_pk?: Maybe<IdentityDepartmentRelationship>;
  /** update multiples rows of table: "IdentityDepartmentRelationship" */
  update_IdentityDepartmentRelationship_many?: Maybe<Array<Maybe<IdentityDepartmentRelationship_Mutation_Response>>>;
  /** update data of the table: "IdentityGroups" */
  update_IdentityGroups?: Maybe<IdentityGroups_Mutation_Response>;
  /** update single row of the table: "IdentityGroups" */
  update_IdentityGroups_by_pk?: Maybe<IdentityGroups>;
  /** update multiples rows of table: "IdentityGroups" */
  update_IdentityGroups_many?: Maybe<Array<Maybe<IdentityGroups_Mutation_Response>>>;
  /** update data of the table: "IdentityJobRelationship" */
  update_IdentityJobRelationship?: Maybe<IdentityJobRelationship_Mutation_Response>;
  /** update single row of the table: "IdentityJobRelationship" */
  update_IdentityJobRelationship_by_pk?: Maybe<IdentityJobRelationship>;
  /** update multiples rows of table: "IdentityJobRelationship" */
  update_IdentityJobRelationship_many?: Maybe<Array<Maybe<IdentityJobRelationship_Mutation_Response>>>;
  /** update data of the table: "IdentityPartnerGroupData" */
  update_IdentityPartnerGroupData?: Maybe<IdentityPartnerGroupData_Mutation_Response>;
  /** update single row of the table: "IdentityPartnerGroupData" */
  update_IdentityPartnerGroupData_by_pk?: Maybe<IdentityPartnerGroupData>;
  /** update multiples rows of table: "IdentityPartnerGroupData" */
  update_IdentityPartnerGroupData_many?: Maybe<Array<Maybe<IdentityPartnerGroupData_Mutation_Response>>>;
  /** update data of the table: "IdentityRelationshipType" */
  update_IdentityRelationshipType?: Maybe<IdentityRelationshipType_Mutation_Response>;
  /** update single row of the table: "IdentityRelationshipType" */
  update_IdentityRelationshipType_by_pk?: Maybe<IdentityRelationshipType>;
  /** update multiples rows of table: "IdentityRelationshipType" */
  update_IdentityRelationshipType_many?: Maybe<Array<Maybe<IdentityRelationshipType_Mutation_Response>>>;
  /** update data of the table: "IdentityRoles" */
  update_IdentityRoles?: Maybe<IdentityRoles_Mutation_Response>;
  /** update multiples rows of table: "IdentityRoles" */
  update_IdentityRoles_many?: Maybe<Array<Maybe<IdentityRoles_Mutation_Response>>>;
  /** update data of the table: "IdentitySubTypeEmails" */
  update_IdentitySubTypeEmails?: Maybe<IdentitySubTypeEmails_Mutation_Response>;
  /** update single row of the table: "IdentitySubTypeEmails" */
  update_IdentitySubTypeEmails_by_pk?: Maybe<IdentitySubTypeEmails>;
  /** update multiples rows of table: "IdentitySubTypeEmails" */
  update_IdentitySubTypeEmails_many?: Maybe<Array<Maybe<IdentitySubTypeEmails_Mutation_Response>>>;
  /** update data of the table: "IdentitySubTypes" */
  update_IdentitySubTypes?: Maybe<IdentitySubTypes_Mutation_Response>;
  /** update single row of the table: "IdentitySubTypes" */
  update_IdentitySubTypes_by_pk?: Maybe<IdentitySubTypes>;
  /** update multiples rows of table: "IdentitySubTypes" */
  update_IdentitySubTypes_many?: Maybe<Array<Maybe<IdentitySubTypes_Mutation_Response>>>;
  /** update data of the table: "IdentityTags" */
  update_IdentityTags?: Maybe<IdentityTags_Mutation_Response>;
  /** update single row of the table: "IdentityTags" */
  update_IdentityTags_by_pk?: Maybe<IdentityTags>;
  /** update multiples rows of table: "IdentityTags" */
  update_IdentityTags_many?: Maybe<Array<Maybe<IdentityTags_Mutation_Response>>>;
  /** update data of the table: "IdentityTokens" */
  update_IdentityTokens?: Maybe<IdentityTokens_Mutation_Response>;
  /** update single row of the table: "IdentityTokens" */
  update_IdentityTokens_by_pk?: Maybe<IdentityTokens>;
  /** update multiples rows of table: "IdentityTokens" */
  update_IdentityTokens_many?: Maybe<Array<Maybe<IdentityTokens_Mutation_Response>>>;
  /** update data of the table: "IdentityTypes" */
  update_IdentityTypes?: Maybe<IdentityTypes_Mutation_Response>;
  /** update single row of the table: "IdentityTypes" */
  update_IdentityTypes_by_pk?: Maybe<IdentityTypes>;
  /** update multiples rows of table: "IdentityTypes" */
  update_IdentityTypes_many?: Maybe<Array<Maybe<IdentityTypes_Mutation_Response>>>;
  /** update data of the table: "IdentityUserAccessGroup" */
  update_IdentityUserAccessGroup?: Maybe<IdentityUserAccessGroup_Mutation_Response>;
  /** update single row of the table: "IdentityUserAccessGroup" */
  update_IdentityUserAccessGroup_by_pk?: Maybe<IdentityUserAccessGroup>;
  /** update multiples rows of table: "IdentityUserAccessGroup" */
  update_IdentityUserAccessGroup_many?: Maybe<Array<Maybe<IdentityUserAccessGroup_Mutation_Response>>>;
  /** update data of the table: "Images" */
  update_Images?: Maybe<Images_Mutation_Response>;
  /** update single row of the table: "Images" */
  update_Images_by_pk?: Maybe<Images>;
  /** update multiples rows of table: "Images" */
  update_Images_many?: Maybe<Array<Maybe<Images_Mutation_Response>>>;
  /** update data of the table: "InputTypes" */
  update_InputTypes?: Maybe<InputTypes_Mutation_Response>;
  /** update single row of the table: "InputTypes" */
  update_InputTypes_by_pk?: Maybe<InputTypes>;
  /** update multiples rows of table: "InputTypes" */
  update_InputTypes_many?: Maybe<Array<Maybe<InputTypes_Mutation_Response>>>;
  /** update data of the table: "InternalJobCategories" */
  update_InternalJobCategories?: Maybe<InternalJobCategories_Mutation_Response>;
  /** update single row of the table: "InternalJobCategories" */
  update_InternalJobCategories_by_pk?: Maybe<InternalJobCategories>;
  /** update multiples rows of table: "InternalJobCategories" */
  update_InternalJobCategories_many?: Maybe<Array<Maybe<InternalJobCategories_Mutation_Response>>>;
  /** update data of the table: "Interviews" */
  update_Interviews?: Maybe<Interviews_Mutation_Response>;
  /** update single row of the table: "Interviews" */
  update_Interviews_by_pk?: Maybe<Interviews>;
  /** update multiples rows of table: "Interviews" */
  update_Interviews_many?: Maybe<Array<Maybe<Interviews_Mutation_Response>>>;
  /** update data of the table: "JobAvailabilities" */
  update_JobAvailabilities?: Maybe<JobAvailabilities_Mutation_Response>;
  /** update single row of the table: "JobAvailabilities" */
  update_JobAvailabilities_by_pk?: Maybe<JobAvailabilities>;
  /** update multiples rows of table: "JobAvailabilities" */
  update_JobAvailabilities_many?: Maybe<Array<Maybe<JobAvailabilities_Mutation_Response>>>;
  /** update data of the table: "JobComments" */
  update_JobComments?: Maybe<JobComments_Mutation_Response>;
  /** update single row of the table: "JobComments" */
  update_JobComments_by_pk?: Maybe<JobComments>;
  /** update multiples rows of table: "JobComments" */
  update_JobComments_many?: Maybe<Array<Maybe<JobComments_Mutation_Response>>>;
  /** update data of the table: "JobFiles" */
  update_JobFiles?: Maybe<JobFiles_Mutation_Response>;
  /** update single row of the table: "JobFiles" */
  update_JobFiles_by_pk?: Maybe<JobFiles>;
  /** update multiples rows of table: "JobFiles" */
  update_JobFiles_many?: Maybe<Array<Maybe<JobFiles_Mutation_Response>>>;
  /** update data of the table: "JobFundingStatusEnum" */
  update_JobFundingStatusEnum?: Maybe<JobFundingStatusEnum_Mutation_Response>;
  /** update single row of the table: "JobFundingStatusEnum" */
  update_JobFundingStatusEnum_by_pk?: Maybe<JobFundingStatusEnum>;
  /** update multiples rows of table: "JobFundingStatusEnum" */
  update_JobFundingStatusEnum_many?: Maybe<Array<Maybe<JobFundingStatusEnum_Mutation_Response>>>;
  /** update data of the table: "JobHireTypesEnum" */
  update_JobHireTypesEnum?: Maybe<JobHireTypesEnum_Mutation_Response>;
  /** update single row of the table: "JobHireTypesEnum" */
  update_JobHireTypesEnum_by_pk?: Maybe<JobHireTypesEnum>;
  /** update multiples rows of table: "JobHireTypesEnum" */
  update_JobHireTypesEnum_many?: Maybe<Array<Maybe<JobHireTypesEnum_Mutation_Response>>>;
  /** update data of the table: "JobPositions" */
  update_JobPositions?: Maybe<JobPositions_Mutation_Response>;
  /** update single row of the table: "JobPositions" */
  update_JobPositions_by_pk?: Maybe<JobPositions>;
  /** update multiples rows of table: "JobPositions" */
  update_JobPositions_many?: Maybe<Array<Maybe<JobPositions_Mutation_Response>>>;
  /** update data of the table: "JobStatuses" */
  update_JobStatuses?: Maybe<JobStatuses_Mutation_Response>;
  /** update single row of the table: "JobStatuses" */
  update_JobStatuses_by_pk?: Maybe<JobStatuses>;
  /** update multiples rows of table: "JobStatuses" */
  update_JobStatuses_many?: Maybe<Array<Maybe<JobStatuses_Mutation_Response>>>;
  /** update data of the table: "Jobs" */
  update_Jobs?: Maybe<Jobs_Mutation_Response>;
  /** update single row of the table: "Jobs" */
  update_Jobs_by_pk?: Maybe<Jobs>;
  /** update multiples rows of table: "Jobs" */
  update_Jobs_many?: Maybe<Array<Maybe<Jobs_Mutation_Response>>>;
  /** update data of the table: "MagicLinks" */
  update_MagicLinks?: Maybe<MagicLinks_Mutation_Response>;
  /** update single row of the table: "MagicLinks" */
  update_MagicLinks_by_pk?: Maybe<MagicLinks>;
  /** update multiples rows of table: "MagicLinks" */
  update_MagicLinks_many?: Maybe<Array<Maybe<MagicLinks_Mutation_Response>>>;
  /** update data of the table: "MigrationData" */
  update_MigrationData?: Maybe<MigrationData_Mutation_Response>;
  /** update single row of the table: "MigrationData" */
  update_MigrationData_by_pk?: Maybe<MigrationData>;
  /** update multiples rows of table: "MigrationData" */
  update_MigrationData_many?: Maybe<Array<Maybe<MigrationData_Mutation_Response>>>;
  /** update data of the table: "ObjectZoo" */
  update_ObjectZoo?: Maybe<ObjectZoo_Mutation_Response>;
  /** update single row of the table: "ObjectZoo" */
  update_ObjectZoo_by_pk?: Maybe<ObjectZoo>;
  /** update multiples rows of table: "ObjectZoo" */
  update_ObjectZoo_many?: Maybe<Array<Maybe<ObjectZoo_Mutation_Response>>>;
  /** update data of the table: "Overlay" */
  update_Overlay?: Maybe<Overlay_Mutation_Response>;
  /** update data of the table: "OverlayDefinition" */
  update_OverlayDefinition?: Maybe<OverlayDefinition_Mutation_Response>;
  /** update single row of the table: "OverlayDefinition" */
  update_OverlayDefinition_by_pk?: Maybe<OverlayDefinition>;
  /** update multiples rows of table: "OverlayDefinition" */
  update_OverlayDefinition_many?: Maybe<Array<Maybe<OverlayDefinition_Mutation_Response>>>;
  /** update single row of the table: "Overlay" */
  update_Overlay_by_pk?: Maybe<Overlay>;
  /** update multiples rows of table: "Overlay" */
  update_Overlay_many?: Maybe<Array<Maybe<Overlay_Mutation_Response>>>;
  /** update data of the table: "PhoneNumbers" */
  update_PhoneNumbers?: Maybe<PhoneNumbers_Mutation_Response>;
  /** update single row of the table: "PhoneNumbers" */
  update_PhoneNumbers_by_pk?: Maybe<PhoneNumbers>;
  /** update multiples rows of table: "PhoneNumbers" */
  update_PhoneNumbers_many?: Maybe<Array<Maybe<PhoneNumbers_Mutation_Response>>>;
  /** update data of the table: "PicklistValues" */
  update_PicklistValues?: Maybe<PicklistValues_Mutation_Response>;
  /** update single row of the table: "PicklistValues" */
  update_PicklistValues_by_pk?: Maybe<PicklistValues>;
  /** update multiples rows of table: "PicklistValues" */
  update_PicklistValues_many?: Maybe<Array<Maybe<PicklistValues_Mutation_Response>>>;
  /** update data of the table: "Picklists" */
  update_Picklists?: Maybe<Picklists_Mutation_Response>;
  /** update single row of the table: "Picklists" */
  update_Picklists_by_pk?: Maybe<Picklists>;
  /** update multiples rows of table: "Picklists" */
  update_Picklists_many?: Maybe<Array<Maybe<Picklists_Mutation_Response>>>;
  /** update data of the table: "ProductUpdates" */
  update_ProductUpdates?: Maybe<ProductUpdates_Mutation_Response>;
  /** update single row of the table: "ProductUpdates" */
  update_ProductUpdates_by_pk?: Maybe<ProductUpdates>;
  /** update multiples rows of table: "ProductUpdates" */
  update_ProductUpdates_many?: Maybe<Array<Maybe<ProductUpdates_Mutation_Response>>>;
  /** update data of the table: "QuestionnaireContactTemplateVariables" */
  update_QuestionnaireContactTemplateVariables?: Maybe<QuestionnaireContactTemplateVariables_Mutation_Response>;
  /** update single row of the table: "QuestionnaireContactTemplateVariables" */
  update_QuestionnaireContactTemplateVariables_by_pk?: Maybe<QuestionnaireContactTemplateVariables>;
  /** update multiples rows of table: "QuestionnaireContactTemplateVariables" */
  update_QuestionnaireContactTemplateVariables_many?: Maybe<Array<Maybe<QuestionnaireContactTemplateVariables_Mutation_Response>>>;
  /** update data of the table: "QuestionnaireLinks" */
  update_QuestionnaireLinks?: Maybe<QuestionnaireLinks_Mutation_Response>;
  /** update single row of the table: "QuestionnaireLinks" */
  update_QuestionnaireLinks_by_pk?: Maybe<QuestionnaireLinks>;
  /** update multiples rows of table: "QuestionnaireLinks" */
  update_QuestionnaireLinks_many?: Maybe<Array<Maybe<QuestionnaireLinks_Mutation_Response>>>;
  /** update data of the table: "QuestionnaireResponses" */
  update_QuestionnaireResponses?: Maybe<QuestionnaireResponses_Mutation_Response>;
  /** update single row of the table: "QuestionnaireResponses" */
  update_QuestionnaireResponses_by_pk?: Maybe<QuestionnaireResponses>;
  /** update multiples rows of table: "QuestionnaireResponses" */
  update_QuestionnaireResponses_many?: Maybe<Array<Maybe<QuestionnaireResponses_Mutation_Response>>>;
  /** update data of the table: "Questionnaires" */
  update_Questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** update single row of the table: "Questionnaires" */
  update_Questionnaires_by_pk?: Maybe<Questionnaires>;
  /** update multiples rows of table: "Questionnaires" */
  update_Questionnaires_many?: Maybe<Array<Maybe<Questionnaires_Mutation_Response>>>;
  /** update data of the table: "RRWebRecords" */
  update_RRWebRecords?: Maybe<RrWebRecords_Mutation_Response>;
  /** update single row of the table: "RRWebRecords" */
  update_RRWebRecords_by_pk?: Maybe<RrWebRecords>;
  /** update multiples rows of table: "RRWebRecords" */
  update_RRWebRecords_many?: Maybe<Array<Maybe<RrWebRecords_Mutation_Response>>>;
  /** update data of the table: "RaceEnum" */
  update_RaceEnum?: Maybe<RaceEnum_Mutation_Response>;
  /** update single row of the table: "RaceEnum" */
  update_RaceEnum_by_pk?: Maybe<RaceEnum>;
  /** update multiples rows of table: "RaceEnum" */
  update_RaceEnum_many?: Maybe<Array<Maybe<RaceEnum_Mutation_Response>>>;
  /** update data of the table: "RangeValues" */
  update_RangeValues?: Maybe<RangeValues_Mutation_Response>;
  /** update single row of the table: "RangeValues" */
  update_RangeValues_by_pk?: Maybe<RangeValues>;
  /** update multiples rows of table: "RangeValues" */
  update_RangeValues_many?: Maybe<Array<Maybe<RangeValues_Mutation_Response>>>;
  /** update data of the table: "RejectionTypeEnum" */
  update_RejectionTypeEnum?: Maybe<RejectionTypeEnum_Mutation_Response>;
  /** update single row of the table: "RejectionTypeEnum" */
  update_RejectionTypeEnum_by_pk?: Maybe<RejectionTypeEnum>;
  /** update multiples rows of table: "RejectionTypeEnum" */
  update_RejectionTypeEnum_many?: Maybe<Array<Maybe<RejectionTypeEnum_Mutation_Response>>>;
  /** update data of the table: "Rejections" */
  update_Rejections?: Maybe<Rejections_Mutation_Response>;
  /** update single row of the table: "Rejections" */
  update_Rejections_by_pk?: Maybe<Rejections>;
  /** update multiples rows of table: "Rejections" */
  update_Rejections_many?: Maybe<Array<Maybe<Rejections_Mutation_Response>>>;
  /** update data of the table: "RenderedEmails" */
  update_RenderedEmails?: Maybe<RenderedEmails_Mutation_Response>;
  /** update single row of the table: "RenderedEmails" */
  update_RenderedEmails_by_pk?: Maybe<RenderedEmails>;
  /** update multiples rows of table: "RenderedEmails" */
  update_RenderedEmails_many?: Maybe<Array<Maybe<RenderedEmails_Mutation_Response>>>;
  /** update data of the table: "ResumeClaims" */
  update_ResumeClaims?: Maybe<ResumeClaims_Mutation_Response>;
  /** update single row of the table: "ResumeClaims" */
  update_ResumeClaims_by_pk?: Maybe<ResumeClaims>;
  /** update multiples rows of table: "ResumeClaims" */
  update_ResumeClaims_many?: Maybe<Array<Maybe<ResumeClaims_Mutation_Response>>>;
  /** update data of the table: "ResumeEmailAddresses" */
  update_ResumeEmailAddresses?: Maybe<ResumeEmailAddresses_Mutation_Response>;
  /** update single row of the table: "ResumeEmailAddresses" */
  update_ResumeEmailAddresses_by_pk?: Maybe<ResumeEmailAddresses>;
  /** update multiples rows of table: "ResumeEmailAddresses" */
  update_ResumeEmailAddresses_many?: Maybe<Array<Maybe<ResumeEmailAddresses_Mutation_Response>>>;
  /** update data of the table: "ResumeIngestStatus" */
  update_ResumeIngestStatus?: Maybe<ResumeIngestStatus_Mutation_Response>;
  /** update single row of the table: "ResumeIngestStatus" */
  update_ResumeIngestStatus_by_pk?: Maybe<ResumeIngestStatus>;
  /** update multiples rows of table: "ResumeIngestStatus" */
  update_ResumeIngestStatus_many?: Maybe<Array<Maybe<ResumeIngestStatus_Mutation_Response>>>;
  /** update data of the table: "ResumePhoneNumbers" */
  update_ResumePhoneNumbers?: Maybe<ResumePhoneNumbers_Mutation_Response>;
  /** update single row of the table: "ResumePhoneNumbers" */
  update_ResumePhoneNumbers_by_pk?: Maybe<ResumePhoneNumbers>;
  /** update multiples rows of table: "ResumePhoneNumbers" */
  update_ResumePhoneNumbers_many?: Maybe<Array<Maybe<ResumePhoneNumbers_Mutation_Response>>>;
  /** update data of the table: "Resumes" */
  update_Resumes?: Maybe<Resumes_Mutation_Response>;
  /** update single row of the table: "Resumes" */
  update_Resumes_by_pk?: Maybe<Resumes>;
  /** update multiples rows of table: "Resumes" */
  update_Resumes_many?: Maybe<Array<Maybe<Resumes_Mutation_Response>>>;
  /** update data of the table: "ReviewCriteria" */
  update_ReviewCriteria?: Maybe<ReviewCriteria_Mutation_Response>;
  /** update data of the table: "ReviewCriteriaScore" */
  update_ReviewCriteriaScore?: Maybe<ReviewCriteriaScore_Mutation_Response>;
  /** update single row of the table: "ReviewCriteriaScore" */
  update_ReviewCriteriaScore_by_pk?: Maybe<ReviewCriteriaScore>;
  /** update multiples rows of table: "ReviewCriteriaScore" */
  update_ReviewCriteriaScore_many?: Maybe<Array<Maybe<ReviewCriteriaScore_Mutation_Response>>>;
  /** update single row of the table: "ReviewCriteria" */
  update_ReviewCriteria_by_pk?: Maybe<ReviewCriteria>;
  /** update multiples rows of table: "ReviewCriteria" */
  update_ReviewCriteria_many?: Maybe<Array<Maybe<ReviewCriteria_Mutation_Response>>>;
  /** update data of the table: "ReviewGroupContactTemplateVariables" */
  update_ReviewGroupContactTemplateVariables?: Maybe<ReviewGroupContactTemplateVariables_Mutation_Response>;
  /** update single row of the table: "ReviewGroupContactTemplateVariables" */
  update_ReviewGroupContactTemplateVariables_by_pk?: Maybe<ReviewGroupContactTemplateVariables>;
  /** update multiples rows of table: "ReviewGroupContactTemplateVariables" */
  update_ReviewGroupContactTemplateVariables_many?: Maybe<Array<Maybe<ReviewGroupContactTemplateVariables_Mutation_Response>>>;
  /** update data of the table: "ReviewGroupCriteria" */
  update_ReviewGroupCriteria?: Maybe<ReviewGroupCriteria_Mutation_Response>;
  /** update single row of the table: "ReviewGroupCriteria" */
  update_ReviewGroupCriteria_by_pk?: Maybe<ReviewGroupCriteria>;
  /** update multiples rows of table: "ReviewGroupCriteria" */
  update_ReviewGroupCriteria_many?: Maybe<Array<Maybe<ReviewGroupCriteria_Mutation_Response>>>;
  /** update data of the table: "ReviewGroupFiles" */
  update_ReviewGroupFiles?: Maybe<ReviewGroupFiles_Mutation_Response>;
  /** update single row of the table: "ReviewGroupFiles" */
  update_ReviewGroupFiles_by_pk?: Maybe<ReviewGroupFiles>;
  /** update multiples rows of table: "ReviewGroupFiles" */
  update_ReviewGroupFiles_many?: Maybe<Array<Maybe<ReviewGroupFiles_Mutation_Response>>>;
  /** update data of the table: "ReviewGroupStages" */
  update_ReviewGroupStages?: Maybe<ReviewGroupStages_Mutation_Response>;
  /** update single row of the table: "ReviewGroupStages" */
  update_ReviewGroupStages_by_pk?: Maybe<ReviewGroupStages>;
  /** update multiples rows of table: "ReviewGroupStages" */
  update_ReviewGroupStages_many?: Maybe<Array<Maybe<ReviewGroupStages_Mutation_Response>>>;
  /** update data of the table: "ReviewGroupSubTypeEnum" */
  update_ReviewGroupSubTypeEnum?: Maybe<ReviewGroupSubTypeEnum_Mutation_Response>;
  /** update single row of the table: "ReviewGroupSubTypeEnum" */
  update_ReviewGroupSubTypeEnum_by_pk?: Maybe<ReviewGroupSubTypeEnum>;
  /** update multiples rows of table: "ReviewGroupSubTypeEnum" */
  update_ReviewGroupSubTypeEnum_many?: Maybe<Array<Maybe<ReviewGroupSubTypeEnum_Mutation_Response>>>;
  /** update data of the table: "ReviewGroupTypeEnum" */
  update_ReviewGroupTypeEnum?: Maybe<ReviewGroupTypeEnum_Mutation_Response>;
  /** update single row of the table: "ReviewGroupTypeEnum" */
  update_ReviewGroupTypeEnum_by_pk?: Maybe<ReviewGroupTypeEnum>;
  /** update multiples rows of table: "ReviewGroupTypeEnum" */
  update_ReviewGroupTypeEnum_many?: Maybe<Array<Maybe<ReviewGroupTypeEnum_Mutation_Response>>>;
  /** update data of the table: "ReviewGroups" */
  update_ReviewGroups?: Maybe<ReviewGroups_Mutation_Response>;
  /** update single row of the table: "ReviewGroups" */
  update_ReviewGroups_by_pk?: Maybe<ReviewGroups>;
  /** update multiples rows of table: "ReviewGroups" */
  update_ReviewGroups_many?: Maybe<Array<Maybe<ReviewGroups_Mutation_Response>>>;
  /** update data of the table: "Reviews" */
  update_Reviews?: Maybe<Reviews_Mutation_Response>;
  /** update data of the table: "ReviewsResultEnum" */
  update_ReviewsResultEnum?: Maybe<ReviewsResultEnum_Mutation_Response>;
  /** update single row of the table: "ReviewsResultEnum" */
  update_ReviewsResultEnum_by_pk?: Maybe<ReviewsResultEnum>;
  /** update multiples rows of table: "ReviewsResultEnum" */
  update_ReviewsResultEnum_many?: Maybe<Array<Maybe<ReviewsResultEnum_Mutation_Response>>>;
  /** update single row of the table: "Reviews" */
  update_Reviews_by_pk?: Maybe<Reviews>;
  /** update multiples rows of table: "Reviews" */
  update_Reviews_many?: Maybe<Array<Maybe<Reviews_Mutation_Response>>>;
  /** update data of the table: "RoleAccess" */
  update_RoleAccess?: Maybe<RoleAccess_Mutation_Response>;
  /** update single row of the table: "RoleAccess" */
  update_RoleAccess_by_pk?: Maybe<RoleAccess>;
  /** update multiples rows of table: "RoleAccess" */
  update_RoleAccess_many?: Maybe<Array<Maybe<RoleAccess_Mutation_Response>>>;
  /** update data of the table: "Roles" */
  update_Roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "Roles" */
  update_Roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "Roles" */
  update_Roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "SearchFilterTypesEnum" */
  update_SearchFilterTypesEnum?: Maybe<SearchFilterTypesEnum_Mutation_Response>;
  /** update single row of the table: "SearchFilterTypesEnum" */
  update_SearchFilterTypesEnum_by_pk?: Maybe<SearchFilterTypesEnum>;
  /** update multiples rows of table: "SearchFilterTypesEnum" */
  update_SearchFilterTypesEnum_many?: Maybe<Array<Maybe<SearchFilterTypesEnum_Mutation_Response>>>;
  /** update data of the table: "SearchFilters" */
  update_SearchFilters?: Maybe<SearchFilters_Mutation_Response>;
  /** update single row of the table: "SearchFilters" */
  update_SearchFilters_by_pk?: Maybe<SearchFilters>;
  /** update multiples rows of table: "SearchFilters" */
  update_SearchFilters_many?: Maybe<Array<Maybe<SearchFilters_Mutation_Response>>>;
  /** update data of the table: "SecurityClearances" */
  update_SecurityClearances?: Maybe<SecurityClearances_Mutation_Response>;
  /** update single row of the table: "SecurityClearances" */
  update_SecurityClearances_by_pk?: Maybe<SecurityClearances>;
  /** update multiples rows of table: "SecurityClearances" */
  update_SecurityClearances_many?: Maybe<Array<Maybe<SecurityClearances_Mutation_Response>>>;
  /** update data of the table: "SequelizeMeta" */
  update_SequelizeMeta?: Maybe<SequelizeMeta_Mutation_Response>;
  /** update single row of the table: "SequelizeMeta" */
  update_SequelizeMeta_by_pk?: Maybe<SequelizeMeta>;
  /** update multiples rows of table: "SequelizeMeta" */
  update_SequelizeMeta_many?: Maybe<Array<Maybe<SequelizeMeta_Mutation_Response>>>;
  /** update data of the table: "StageEmailContracts" */
  update_StageEmailContracts?: Maybe<StageEmailContracts_Mutation_Response>;
  /** update single row of the table: "StageEmailContracts" */
  update_StageEmailContracts_by_pk?: Maybe<StageEmailContracts>;
  /** update multiples rows of table: "StageEmailContracts" */
  update_StageEmailContracts_many?: Maybe<Array<Maybe<StageEmailContracts_Mutation_Response>>>;
  /** update data of the table: "StageEmails" */
  update_StageEmails?: Maybe<StageEmails_Mutation_Response>;
  /** update single row of the table: "StageEmails" */
  update_StageEmails_by_pk?: Maybe<StageEmails>;
  /** update multiples rows of table: "StageEmails" */
  update_StageEmails_many?: Maybe<Array<Maybe<StageEmails_Mutation_Response>>>;
  /** update data of the table: "StageQuestionnaireMapping" */
  update_StageQuestionnaireMapping?: Maybe<StageQuestionnaireMapping_Mutation_Response>;
  /** update data of the table: "StageQuestionnaireMappingIdenity" */
  update_StageQuestionnaireMappingIdenity?: Maybe<StageQuestionnaireMappingIdenity_Mutation_Response>;
  /** update single row of the table: "StageQuestionnaireMappingIdenity" */
  update_StageQuestionnaireMappingIdenity_by_pk?: Maybe<StageQuestionnaireMappingIdenity>;
  /** update multiples rows of table: "StageQuestionnaireMappingIdenity" */
  update_StageQuestionnaireMappingIdenity_many?: Maybe<Array<Maybe<StageQuestionnaireMappingIdenity_Mutation_Response>>>;
  /** update single row of the table: "StageQuestionnaireMapping" */
  update_StageQuestionnaireMapping_by_pk?: Maybe<StageQuestionnaireMapping>;
  /** update multiples rows of table: "StageQuestionnaireMapping" */
  update_StageQuestionnaireMapping_many?: Maybe<Array<Maybe<StageQuestionnaireMapping_Mutation_Response>>>;
  /** update data of the table: "StageQuestionnaireTemplateVariables" */
  update_StageQuestionnaireTemplateVariables?: Maybe<StageQuestionnaireTemplateVariables_Mutation_Response>;
  /** update single row of the table: "StageQuestionnaireTemplateVariables" */
  update_StageQuestionnaireTemplateVariables_by_pk?: Maybe<StageQuestionnaireTemplateVariables>;
  /** update multiples rows of table: "StageQuestionnaireTemplateVariables" */
  update_StageQuestionnaireTemplateVariables_many?: Maybe<Array<Maybe<StageQuestionnaireTemplateVariables_Mutation_Response>>>;
  /** update data of the table: "StageRoles" */
  update_StageRoles?: Maybe<StageRoles_Mutation_Response>;
  /** update single row of the table: "StageRoles" */
  update_StageRoles_by_pk?: Maybe<StageRoles>;
  /** update multiples rows of table: "StageRoles" */
  update_StageRoles_many?: Maybe<Array<Maybe<StageRoles_Mutation_Response>>>;
  /** update data of the table: "StageTransitions" */
  update_StageTransitions?: Maybe<StageTransitions_Mutation_Response>;
  /** update single row of the table: "StageTransitions" */
  update_StageTransitions_by_pk?: Maybe<StageTransitions>;
  /** update multiples rows of table: "StageTransitions" */
  update_StageTransitions_many?: Maybe<Array<Maybe<StageTransitions_Mutation_Response>>>;
  /** update data of the table: "Stages" */
  update_Stages?: Maybe<Stages_Mutation_Response>;
  /** update single row of the table: "Stages" */
  update_Stages_by_pk?: Maybe<Stages>;
  /** update multiples rows of table: "Stages" */
  update_Stages_many?: Maybe<Array<Maybe<Stages_Mutation_Response>>>;
  /** update data of the table: "StateMachines" */
  update_StateMachines?: Maybe<StateMachines_Mutation_Response>;
  /** update single row of the table: "StateMachines" */
  update_StateMachines_by_pk?: Maybe<StateMachines>;
  /** update multiples rows of table: "StateMachines" */
  update_StateMachines_many?: Maybe<Array<Maybe<StateMachines_Mutation_Response>>>;
  /** update data of the table: "Submissions" */
  update_Submissions?: Maybe<Submissions_Mutation_Response>;
  /** update data of the table: "SubmissionsOverlaysLinks" */
  update_SubmissionsOverlaysLinks?: Maybe<SubmissionsOverlaysLinks_Mutation_Response>;
  /** update single row of the table: "SubmissionsOverlaysLinks" */
  update_SubmissionsOverlaysLinks_by_pk?: Maybe<SubmissionsOverlaysLinks>;
  /** update multiples rows of table: "SubmissionsOverlaysLinks" */
  update_SubmissionsOverlaysLinks_many?: Maybe<Array<Maybe<SubmissionsOverlaysLinks_Mutation_Response>>>;
  /** update single row of the table: "Submissions" */
  update_Submissions_by_pk?: Maybe<Submissions>;
  /** update multiples rows of table: "Submissions" */
  update_Submissions_many?: Maybe<Array<Maybe<Submissions_Mutation_Response>>>;
  /** update data of the table: "TableConfigTypes" */
  update_TableConfigTypes?: Maybe<TableConfigTypes_Mutation_Response>;
  /** update single row of the table: "TableConfigTypes" */
  update_TableConfigTypes_by_pk?: Maybe<TableConfigTypes>;
  /** update multiples rows of table: "TableConfigTypes" */
  update_TableConfigTypes_many?: Maybe<Array<Maybe<TableConfigTypes_Mutation_Response>>>;
  /** update data of the table: "TableConfigs" */
  update_TableConfigs?: Maybe<TableConfigs_Mutation_Response>;
  /** update single row of the table: "TableConfigs" */
  update_TableConfigs_by_pk?: Maybe<TableConfigs>;
  /** update multiples rows of table: "TableConfigs" */
  update_TableConfigs_many?: Maybe<Array<Maybe<TableConfigs_Mutation_Response>>>;
  /** update data of the table: "Tags" */
  update_Tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "Tags" */
  update_Tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "Tags" */
  update_Tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "TaskGroupAssignees" */
  update_TaskGroupAssignees?: Maybe<TaskGroupAssignees_Mutation_Response>;
  /** update single row of the table: "TaskGroupAssignees" */
  update_TaskGroupAssignees_by_pk?: Maybe<TaskGroupAssignees>;
  /** update multiples rows of table: "TaskGroupAssignees" */
  update_TaskGroupAssignees_many?: Maybe<Array<Maybe<TaskGroupAssignees_Mutation_Response>>>;
  /** update data of the table: "TaskGroups" */
  update_TaskGroups?: Maybe<TaskGroups_Mutation_Response>;
  /** update single row of the table: "TaskGroups" */
  update_TaskGroups_by_pk?: Maybe<TaskGroups>;
  /** update multiples rows of table: "TaskGroups" */
  update_TaskGroups_many?: Maybe<Array<Maybe<TaskGroups_Mutation_Response>>>;
  /** update data of the table: "TaskPriorityTypes" */
  update_TaskPriorityTypes?: Maybe<TaskPriorityTypes_Mutation_Response>;
  /** update single row of the table: "TaskPriorityTypes" */
  update_TaskPriorityTypes_by_pk?: Maybe<TaskPriorityTypes>;
  /** update multiples rows of table: "TaskPriorityTypes" */
  update_TaskPriorityTypes_many?: Maybe<Array<Maybe<TaskPriorityTypes_Mutation_Response>>>;
  /** update data of the table: "TaskTypes" */
  update_TaskTypes?: Maybe<TaskTypes_Mutation_Response>;
  /** update single row of the table: "TaskTypes" */
  update_TaskTypes_by_pk?: Maybe<TaskTypes>;
  /** update multiples rows of table: "TaskTypes" */
  update_TaskTypes_many?: Maybe<Array<Maybe<TaskTypes_Mutation_Response>>>;
  /** update data of the table: "Tasks" */
  update_Tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "Tasks" */
  update_Tasks_by_pk?: Maybe<Tasks>;
  /** update multiples rows of table: "Tasks" */
  update_Tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "Terminations" */
  update_Terminations?: Maybe<Terminations_Mutation_Response>;
  /** update single row of the table: "Terminations" */
  update_Terminations_by_pk?: Maybe<Terminations>;
  /** update multiples rows of table: "Terminations" */
  update_Terminations_many?: Maybe<Array<Maybe<Terminations_Mutation_Response>>>;
  /** update data of the table: "TimeInStages" */
  update_TimeInStages?: Maybe<TimeInStages_Mutation_Response>;
  /** update single row of the table: "TimeInStages" */
  update_TimeInStages_by_pk?: Maybe<TimeInStages>;
  /** update multiples rows of table: "TimeInStages" */
  update_TimeInStages_many?: Maybe<Array<Maybe<TimeInStages_Mutation_Response>>>;
  /** update data of the table: "TimelineEvents" */
  update_TimelineEvents?: Maybe<TimelineEvents_Mutation_Response>;
  /** update single row of the table: "TimelineEvents" */
  update_TimelineEvents_by_pk?: Maybe<TimelineEvents>;
  /** update multiples rows of table: "TimelineEvents" */
  update_TimelineEvents_many?: Maybe<Array<Maybe<TimelineEvents_Mutation_Response>>>;
  /** update data of the table: "Tokens" */
  update_Tokens?: Maybe<Tokens_Mutation_Response>;
  /** update single row of the table: "Tokens" */
  update_Tokens_by_pk?: Maybe<Tokens>;
  /** update multiples rows of table: "Tokens" */
  update_Tokens_many?: Maybe<Array<Maybe<Tokens_Mutation_Response>>>;
  /** update data of the table: "UserAccessGroups" */
  update_UserAccessGroups?: Maybe<UserAccessGroups_Mutation_Response>;
  /** update single row of the table: "UserAccessGroups" */
  update_UserAccessGroups_by_pk?: Maybe<UserAccessGroups>;
  /** update multiples rows of table: "UserAccessGroups" */
  update_UserAccessGroups_many?: Maybe<Array<Maybe<UserAccessGroups_Mutation_Response>>>;
  /** update data of the table: "UserInterfaceState" */
  update_UserInterfaceState?: Maybe<UserInterfaceState_Mutation_Response>;
  /** update single row of the table: "UserInterfaceState" */
  update_UserInterfaceState_by_pk?: Maybe<UserInterfaceState>;
  /** update multiples rows of table: "UserInterfaceState" */
  update_UserInterfaceState_many?: Maybe<Array<Maybe<UserInterfaceState_Mutation_Response>>>;
  /** update data of the table: "Users" */
  update_Users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "Users" */
  update_Users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "Users" */
  update_Users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "ValueTypes" */
  update_ValueTypes?: Maybe<ValueTypes_Mutation_Response>;
  /** update single row of the table: "ValueTypes" */
  update_ValueTypes_by_pk?: Maybe<ValueTypes>;
  /** update multiples rows of table: "ValueTypes" */
  update_ValueTypes_many?: Maybe<Array<Maybe<ValueTypes_Mutation_Response>>>;
  /** update data of the table: "VeteranStatusEnum" */
  update_VeteranStatusEnum?: Maybe<VeteranStatusEnum_Mutation_Response>;
  /** update single row of the table: "VeteranStatusEnum" */
  update_VeteranStatusEnum_by_pk?: Maybe<VeteranStatusEnum>;
  /** update multiples rows of table: "VeteranStatusEnum" */
  update_VeteranStatusEnum_many?: Maybe<Array<Maybe<VeteranStatusEnum_Mutation_Response>>>;
  /** update data of the table: "WhiteLabeling" */
  update_WhiteLabeling?: Maybe<WhiteLabeling_Mutation_Response>;
  /** update single row of the table: "WhiteLabeling" */
  update_WhiteLabeling_by_pk?: Maybe<WhiteLabeling>;
  /** update multiples rows of table: "WhiteLabeling" */
  update_WhiteLabeling_many?: Maybe<Array<Maybe<WhiteLabeling_Mutation_Response>>>;
  /** update data of the table: "WorkflowRegressions" */
  update_WorkflowRegressions?: Maybe<WorkflowRegressions_Mutation_Response>;
  /** update single row of the table: "WorkflowRegressions" */
  update_WorkflowRegressions_by_pk?: Maybe<WorkflowRegressions>;
  /** update multiples rows of table: "WorkflowRegressions" */
  update_WorkflowRegressions_many?: Maybe<Array<Maybe<WorkflowRegressions_Mutation_Response>>>;
  /** update data of the table: "citizenshipEnum" */
  update_citizenshipEnum?: Maybe<CitizenshipEnum_Mutation_Response>;
  /** update single row of the table: "citizenshipEnum" */
  update_citizenshipEnum_by_pk?: Maybe<CitizenshipEnum>;
  /** update multiples rows of table: "citizenshipEnum" */
  update_citizenshipEnum_many?: Maybe<Array<Maybe<CitizenshipEnum_Mutation_Response>>>;
  /** update data of the table: "contract_emails" */
  update_contract_emails?: Maybe<Contract_Emails_Mutation_Response>;
  /** update single row of the table: "contract_emails" */
  update_contract_emails_by_pk?: Maybe<Contract_Emails>;
  /** update multiples rows of table: "contract_emails" */
  update_contract_emails_many?: Maybe<Array<Maybe<Contract_Emails_Mutation_Response>>>;
  /** update data of the table: "deleted_ppl_names" */
  update_deleted_ppl_names?: Maybe<Deleted_Ppl_Names_Mutation_Response>;
  /** update multiples rows of table: "deleted_ppl_names" */
  update_deleted_ppl_names_many?: Maybe<Array<Maybe<Deleted_Ppl_Names_Mutation_Response>>>;
  /** update data of the table: "eeo_data_point_status_by_identity_view" */
  update_eeo_data_point_status_by_identity_view?: Maybe<Eeo_Data_Point_Status_By_Identity_View_Mutation_Response>;
  /** update multiples rows of table: "eeo_data_point_status_by_identity_view" */
  update_eeo_data_point_status_by_identity_view_many?: Maybe<Array<Maybe<Eeo_Data_Point_Status_By_Identity_View_Mutation_Response>>>;
  /** update data of the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  update_eeo_data_points_unknown_as_other_by_identity_view?: Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Mutation_Response>;
  /** update multiples rows of table: "eeo_data_points_unknown_as_other_by_identity_view" */
  update_eeo_data_points_unknown_as_other_by_identity_view_many?: Maybe<Array<Maybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Mutation_Response>>>;
  /** update data of the table: "eeo_status_by_identity" */
  update_eeo_status_by_identity?: Maybe<Eeo_Status_By_Identity_Mutation_Response>;
  /** update multiples rows of table: "eeo_status_by_identity" */
  update_eeo_status_by_identity_many?: Maybe<Array<Maybe<Eeo_Status_By_Identity_Mutation_Response>>>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update multiples rows of table: "geometry_columns" */
  update_geometry_columns_many?: Maybe<Array<Maybe<Geometry_Columns_Mutation_Response>>>;
  /** update data of the table: "identity_roles_arr" */
  update_identity_roles_arr?: Maybe<Identity_Roles_Arr_Mutation_Response>;
  /** update multiples rows of table: "identity_roles_arr" */
  update_identity_roles_arr_many?: Maybe<Array<Maybe<Identity_Roles_Arr_Mutation_Response>>>;
  /** update data of the table: "identity_roles_stat" */
  update_identity_roles_stat?: Maybe<Identity_Roles_Stat_Mutation_Response>;
  /** update multiples rows of table: "identity_roles_stat" */
  update_identity_roles_stat_many?: Maybe<Array<Maybe<Identity_Roles_Stat_Mutation_Response>>>;
  /** update data of the table: "jobs_available_positions" */
  update_jobs_available_positions?: Maybe<Jobs_Available_Positions_Mutation_Response>;
  /** update multiples rows of table: "jobs_available_positions" */
  update_jobs_available_positions_many?: Maybe<Array<Maybe<Jobs_Available_Positions_Mutation_Response>>>;
  /** update data of the table: "matches_from_applications" */
  update_matches_from_applications?: Maybe<Matches_From_Applications_Mutation_Response>;
  /** update multiples rows of table: "matches_from_applications" */
  update_matches_from_applications_many?: Maybe<Array<Maybe<Matches_From_Applications_Mutation_Response>>>;
  /** update data of the table: "matches_from_board" */
  update_matches_from_board?: Maybe<Matches_From_Board_Mutation_Response>;
  /** update multiples rows of table: "matches_from_board" */
  update_matches_from_board_many?: Maybe<Array<Maybe<Matches_From_Board_Mutation_Response>>>;
  /** update data of the table: "matches_from_tasks" */
  update_matches_from_tasks?: Maybe<Matches_From_Tasks_Mutation_Response>;
  /** update multiples rows of table: "matches_from_tasks" */
  update_matches_from_tasks_many?: Maybe<Array<Maybe<Matches_From_Tasks_Mutation_Response>>>;
  /** update data of the table: "nylas_events" */
  update_nylas_events?: Maybe<Nylas_Events_Mutation_Response>;
  /** update single row of the table: "nylas_events" */
  update_nylas_events_by_pk?: Maybe<Nylas_Events>;
  /** update multiples rows of table: "nylas_events" */
  update_nylas_events_many?: Maybe<Array<Maybe<Nylas_Events_Mutation_Response>>>;
  /** update data of the table: "search_spec_results" */
  update_search_spec_results?: Maybe<Search_Spec_Results_Mutation_Response>;
  /** update single row of the table: "search_spec_results" */
  update_search_spec_results_by_pk?: Maybe<Search_Spec_Results>;
  /** update multiples rows of table: "search_spec_results" */
  update_search_spec_results_many?: Maybe<Array<Maybe<Search_Spec_Results_Mutation_Response>>>;
  /** update data of the table: "search_specs" */
  update_search_specs?: Maybe<Search_Specs_Mutation_Response>;
  /** update single row of the table: "search_specs" */
  update_search_specs_by_pk?: Maybe<Search_Specs>;
  /** update multiples rows of table: "search_specs" */
  update_search_specs_many?: Maybe<Array<Maybe<Search_Specs_Mutation_Response>>>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  update_spatial_ref_sys_many?: Maybe<Array<Maybe<Spatial_Ref_Sys_Mutation_Response>>>;
  /** update data of the table: "staffing_status" */
  update_staffing_status?: Maybe<Staffing_Status_Mutation_Response>;
  /** update multiples rows of table: "staffing_status" */
  update_staffing_status_many?: Maybe<Array<Maybe<Staffing_Status_Mutation_Response>>>;
  /** update data of the table: "text_messages" */
  update_text_messages?: Maybe<Text_Messages_Mutation_Response>;
  /** update single row of the table: "text_messages" */
  update_text_messages_by_pk?: Maybe<Text_Messages>;
  /** update multiples rows of table: "text_messages" */
  update_text_messages_many?: Maybe<Array<Maybe<Text_Messages_Mutation_Response>>>;
  /** update data of the table: "util.deps_saved_ddl" */
  update_util_deps_saved_ddl?: Maybe<Util_Deps_Saved_Ddl_Mutation_Response>;
  /** update single row of the table: "util.deps_saved_ddl" */
  update_util_deps_saved_ddl_by_pk?: Maybe<Util_Deps_Saved_Ddl>;
  /** update multiples rows of table: "util.deps_saved_ddl" */
  update_util_deps_saved_ddl_many?: Maybe<Array<Maybe<Util_Deps_Saved_Ddl_Mutation_Response>>>;
  /** update data of the table: "workflow_contracts" */
  update_workflow_contracts?: Maybe<Workflow_Contracts_Mutation_Response>;
  /** update single row of the table: "workflow_contracts" */
  update_workflow_contracts_by_pk?: Maybe<Workflow_Contracts>;
  /** update multiples rows of table: "workflow_contracts" */
  update_workflow_contracts_many?: Maybe<Array<Maybe<Workflow_Contracts_Mutation_Response>>>;
  /** update data of the table: "workflow_departments" */
  update_workflow_departments?: Maybe<Workflow_Departments_Mutation_Response>;
  /** update single row of the table: "workflow_departments" */
  update_workflow_departments_by_pk?: Maybe<Workflow_Departments>;
  /** update multiples rows of table: "workflow_departments" */
  update_workflow_departments_many?: Maybe<Array<Maybe<Workflow_Departments_Mutation_Response>>>;
  uploadLogo?: Maybe<UploadLogoOutput>;
  uploadProfilePicture?: Maybe<UploadProfilePictureOutput>;
};


/** mutation root */
export type Mutation_RootBulkResumeIdentityUpsertArgs = {
  resumeUploadActions: Array<InputMaybe<ResumeUploadAction>>;
};


/** mutation root */
export type Mutation_RootCloneGSheetCalcTemplateArgs = {
  gSheetIdToClone: Scalars['uuid'];
  identityId: Scalars['Int'];
  jobId?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootComputeGSheetCalcResultsArgs = {
  gSheetCalcId: Scalars['uuid'];
  inputValues: Array<GSheetCalcInputValue>;
};


/** mutation root */
export type Mutation_RootConversionArgs = {
  conversionInput: ConversionHandlerInput;
};


/** mutation root */
export type Mutation_RootCreateCalendarEventArgs = {
  eventObject: CreateCalendarEventInput;
};


/** mutation root */
export type Mutation_RootCreateGSheetCalcTemplateArgs = {
  excelTemplate?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateJobApplicationArgs = {
  applicant: JobApplicationInput;
};


/** mutation root */
export type Mutation_RootCreatePartnerUserArgs = {
  identity: AccountsApiInsertIdentity;
};


/** mutation root */
export type Mutation_RootDeleteFileArgs = {
  fileId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ApiTokensArgs = {
  where: ApiTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ApiTokens_By_PkArgs = {
  key: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AccessIntentsArgs = {
  where: AccessIntents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AccessIntents_By_PkArgs = {
  intent: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AccessTypeEnumArgs = {
  where: AccessTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AccessTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ActiveEntitlementsArgs = {
  where: ActiveEntitlements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivityLogTypesArgs = {
  where: ActivityLogTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivityLogTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ActivityTemplatesArgs = {
  where: ActivityTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivityTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AoIdentifierTypesArgs = {
  where: AoIdentifierTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AoIdentifierTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ApplicationStatusArgs = {
  where: ApplicationStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ApplicationStatus_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ApplicationTypeArgs = {
  where: ApplicationType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ApplicationType_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ApplicationsArgs = {
  where: Applications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Applications_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AssignmentsArgs = {
  where: Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AsyncOperationsArgs = {
  where: AsyncOperations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AsyncOperationsStatesArgs = {
  where: AsyncOperationsStates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AsyncOperationsStates_By_PkArgs = {
  state: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AsyncOperations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AttachmentsArgs = {
  where: Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attachments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AuditCreatesArgs = {
  where: AuditCreates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AuditCreates_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AuditStagesArgs = {
  where: AuditStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AuditStages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AuthAuditsArgs = {
  where: AuthAudits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AuthAudits_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_BadgesArgs = {
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badges_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BenefitArgs = {
  where: Benefit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BenefitTypeIdentitySubTypesArgs = {
  where: BenefitTypeIdentitySubTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BenefitTypeIdentitySubTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BenefitTypesArgs = {
  where: BenefitTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BenefitTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Benefit_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BoardFiltersArgs = {
  where: BoardFilters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardFilters_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BoardSnippetsArgs = {
  where: BoardSnippets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardSnippets_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BullhornSyncStatusArgs = {
  where: BullhornSyncStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BullhornSyncStatus_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CalcEquationResultsArgs = {
  where: CalcEquationResults_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalcEquationResults_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CalcFormValuesArgs = {
  where: CalcFormValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalcFormValues_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CalcsArgs = {
  where: Calcs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calcs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CalculationTemplateVersionsArgs = {
  where: CalculationTemplateVersions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalculationTemplateVersions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CalculationTemplatesArgs = {
  where: CalculationTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalculationTemplates_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CalculationVersionFormInputsArgs = {
  where: CalculationVersionFormInputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CalculationVersionFormInputs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CalculationsArgs = {
  where: Calculations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calculations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CandidateSourcesArgs = {
  where: CandidateSources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CandidateSources_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CandidateStatusesArgs = {
  where: CandidateStatuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CandidateStatuses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CaptureNodesArgs = {
  where: CaptureNodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CaptureNodes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ClientSideExternalSystemIntegrationPluginRecordArgs = {
  where: ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClientSideExternalSystemIntegrationPluginRecord_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CognitoUserDataArgs = {
  where: CognitoUserData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CognitoUserData_By_PkArgs = {
  cog_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CommentTypesArgs = {
  where: CommentTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CommentTypes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CommentsArgs = {
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CompaniesArgs = {
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Companies_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyEventsArgs = {
  where: CompanyEvents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompanyEvents_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyStatusEnumArgs = {
  where: CompanyStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompanyStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationsArgs = {
  where: Compensations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compensations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContactTemplateVariablesEnumArgs = {
  where: ContactTemplateVariablesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContactTemplateVariablesEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ContractCommentsArgs = {
  where: ContractComments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContractComments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContractFilesArgs = {
  where: ContractFiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContractFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContractStagesArgs = {
  where: ContractStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContractStages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContractStatusEnumArgs = {
  where: ContractStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContractStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ContractsArgs = {
  where: Contracts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contracts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CurrentStagesArgs = {
  where: CurrentStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CurrentStages_By_PkArgs = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DataHistoryArgs = {
  where: DataHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DataHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DepartmentsArgs = {
  where: Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Departments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DisabilityStatusEnumArgs = {
  where: DisabilityStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DisabilityStatusEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EeoJobCategoriesEnumArgs = {
  where: EeoJobCategoriesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EeoJobCategoriesEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EarnedBadgesArgs = {
  where: EarnedBadges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EarnedBadges_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmailAddressesArgs = {
  where: EmailAddresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailAddresses_By_PkArgs = {
  normalized_email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmailEventsArgs = {
  where: EmailEvents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmailRecordsArgs = {
  where: EmailRecords_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailRecords_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmailSignaturesArgs = {
  where: EmailSignatures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailSignatures_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmailSubFoldersArgs = {
  where: EmailSubFolders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailSubFolders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmailTypeEnumArgs = {
  where: EmailTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmailTypesArgs = {
  where: EmailTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmailsArgs = {
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Emails_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmploymentHireTypeArgs = {
  where: EmploymentHireType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmploymentHireType_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmploymentTypesArgs = {
  where: EmploymentTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmploymentTypes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmploymentsArgs = {
  where: Employments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EntitlementCategoriesArgs = {
  where: EntitlementCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EntitlementCategories_By_PkArgs = {
  category: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EntitlementsArgs = {
  where: Entitlements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entitlements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EquationFormulaOptionArgs = {
  where: EquationFormulaOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EquationFormulaOption_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EquationsArgs = {
  where: Equations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Equations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExpenseArgs = {
  where: Expense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Expense_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalIntegrationTypesArgs = {
  where: ExternalIntegrationTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalIntegrationTypes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalIntegrationsArgs = {
  where: ExternalIntegrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalIntegrations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalJobBoardPublishingsArgs = {
  where: ExternalJobBoardPublishings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalJobBoardPublishings_By_PkArgs = {
  external_job_board_id: Scalars['uuid'];
  job_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalJobBoardsArgs = {
  where: ExternalJobBoards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalJobBoards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalSystemArgs = {
  where: ExternalSystem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalSystemInstanceArgs = {
  where: ExternalSystemInstance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalSystemInstance_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalSystem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FeatureFlagsArgs = {
  where: FeatureFlags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FeatureFlags_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FieldArgs = {
  where: Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FieldDefArgs = {
  where: FieldDef_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FieldDef_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FieldPicklistValuesArgs = {
  where: FieldPicklistValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FieldPicklistValues_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FieldTypeDefArgs = {
  where: FieldTypeDef_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FieldTypeDef_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FileTagsArgs = {
  where: FileTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FileTags_By_PkArgs = {
  file_id: Scalars['Int'];
  tag_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FormInputsArgs = {
  where: FormInputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FormInputs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FormSelectOptionsArgs = {
  where: FormSelectOptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FormSelectOptions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FormulaOptionsArgs = {
  where: FormulaOptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FormulaOptions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GenderEnumArgs = {
  where: GenderEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GenderEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_GeocodingHistoryArgs = {
  where: GeocodingHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GeocodingHistory_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GoalsArgs = {
  where: Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Goals_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GoogleSheetCalcTemplatesArgs = {
  where: GoogleSheetCalcTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GoogleSheetCalcTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GoogleSheetCalculationsArgs = {
  where: GoogleSheetCalculations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GoogleSheetCalculations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GroupJobsArgs = {
  where: GroupJobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GroupJobs_By_PkArgs = {
  groupId: Scalars['uuid'];
  jobId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_HighestStagesArgs = {
  where: HighestStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HighestStages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_HireTypesEnumArgs = {
  where: HireTypesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HireTypesEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ItRequestContractEmailsArgs = {
  where: ItRequestContractEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ItRequestContractEmails_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentitiesArgs = {
  where: Identities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentitiesOverlaysLinksArgs = {
  where: IdentitiesOverlaysLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentitiesOverlaysLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Identities_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityAssignmentsArgs = {
  where: IdentityAssignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityAssignments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityCommentsArgs = {
  where: IdentityComments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityComments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityContractRelationshipArgs = {
  where: IdentityContractRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityContractRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityDepartmentRelationshipArgs = {
  where: IdentityDepartmentRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityDepartmentRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityGroupsArgs = {
  where: IdentityGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityGroups_By_PkArgs = {
  groupId: Scalars['uuid'];
  identityId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityJobRelationshipArgs = {
  where: IdentityJobRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityJobRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityPartnerGroupDataArgs = {
  where: IdentityPartnerGroupData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityPartnerGroupData_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityRelationshipTypeArgs = {
  where: IdentityRelationshipType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityRelationshipType_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityRolesArgs = {
  where: IdentityRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentitySubTypeEmailsArgs = {
  where: IdentitySubTypeEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentitySubTypeEmails_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentitySubTypesArgs = {
  where: IdentitySubTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentitySubTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityTagsArgs = {
  where: IdentityTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityTags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityTokensArgs = {
  where: IdentityTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityTokens_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityTypesArgs = {
  where: IdentityTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IdentityUserAccessGroupArgs = {
  where: IdentityUserAccessGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IdentityUserAccessGroup_By_PkArgs = {
  identity_id: Scalars['Int'];
  user_access_group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImagesArgs = {
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Images_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InputTypesArgs = {
  where: InputTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InputTypes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_InternalJobCategoriesArgs = {
  where: InternalJobCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InternalJobCategories_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InterviewsArgs = {
  where: Interviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Interviews_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_JobAvailabilitiesArgs = {
  where: JobAvailabilities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_JobCommentsArgs = {
  where: JobComments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobComments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_JobFilesArgs = {
  where: JobFiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_JobFundingStatusEnumArgs = {
  where: JobFundingStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobFundingStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_JobHireTypesEnumArgs = {
  where: JobHireTypesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobHireTypesEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_JobPositionsArgs = {
  where: JobPositions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobPositions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_JobStatusesArgs = {
  where: JobStatuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobStatuses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_JobsArgs = {
  where: Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Jobs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MagicLinksArgs = {
  where: MagicLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MagicLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MigrationDataArgs = {
  where: MigrationData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MigrationData_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ObjectZooArgs = {
  where: ObjectZoo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ObjectZoo_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OverlayArgs = {
  where: Overlay_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OverlayDefinitionArgs = {
  where: OverlayDefinition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OverlayDefinition_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Overlay_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PhoneNumbersArgs = {
  where: PhoneNumbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PhoneNumbers_By_PkArgs = {
  e164_form: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PicklistValuesArgs = {
  where: PicklistValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PicklistValues_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PicklistsArgs = {
  where: Picklists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Picklists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductUpdatesArgs = {
  where: ProductUpdates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductUpdates_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionnaireContactTemplateVariablesArgs = {
  where: QuestionnaireContactTemplateVariables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuestionnaireContactTemplateVariables_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};


/** mutation root */
export type Mutation_RootDelete_QuestionnaireLinksArgs = {
  where: QuestionnaireLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuestionnaireLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionnaireResponsesArgs = {
  where: QuestionnaireResponses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuestionnaireResponses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionnairesArgs = {
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RrWebRecordsArgs = {
  where: RrWebRecords_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RrWebRecords_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_RaceEnumArgs = {
  where: RaceEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RaceEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RangeValuesArgs = {
  where: RangeValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RangeValues_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RejectionTypeEnumArgs = {
  where: RejectionTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RejectionTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RejectionsArgs = {
  where: Rejections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rejections_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RenderedEmailsArgs = {
  where: RenderedEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RenderedEmails_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ResumeClaimsArgs = {
  where: ResumeClaims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ResumeClaims_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ResumeEmailAddressesArgs = {
  where: ResumeEmailAddresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ResumeEmailAddresses_By_PkArgs = {
  normalized_email: Scalars['String'];
  resume_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ResumeIngestStatusArgs = {
  where: ResumeIngestStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ResumeIngestStatus_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ResumePhoneNumbersArgs = {
  where: ResumePhoneNumbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ResumePhoneNumbers_By_PkArgs = {
  e164_form: Scalars['String'];
  resume_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ResumesArgs = {
  where: Resumes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resumes_By_PkArgs = {
  company_id: Scalars['Int'];
  data_hash: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewCriteriaArgs = {
  where: ReviewCriteria_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewCriteriaScoreArgs = {
  where: ReviewCriteriaScore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewCriteriaScore_By_PkArgs = {
  review_criteria_id: Scalars['uuid'];
  review_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewCriteria_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupContactTemplateVariablesArgs = {
  where: ReviewGroupContactTemplateVariables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupContactTemplateVariables_By_PkArgs = {
  review_group_id: Scalars['Int'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupCriteriaArgs = {
  where: ReviewGroupCriteria_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupCriteria_By_PkArgs = {
  review_criteria_id: Scalars['uuid'];
  review_group_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupFilesArgs = {
  where: ReviewGroupFiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupStagesArgs = {
  where: ReviewGroupStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupStages_By_PkArgs = {
  review_group_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupSubTypeEnumArgs = {
  where: ReviewGroupSubTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupSubTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupTypeEnumArgs = {
  where: ReviewGroupTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroupsArgs = {
  where: ReviewGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewGroups_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewsArgs = {
  where: Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewsResultEnumArgs = {
  where: ReviewsResultEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewsResultEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reviews_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RoleAccessArgs = {
  where: RoleAccess_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RoleAccess_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SearchFilterTypesEnumArgs = {
  where: SearchFilterTypesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SearchFilterTypesEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SearchFiltersArgs = {
  where: SearchFilters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SearchFilters_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SecurityClearancesArgs = {
  where: SecurityClearances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SecurityClearances_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SequelizeMetaArgs = {
  where: SequelizeMeta_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SequelizeMeta_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_StageEmailContractsArgs = {
  where: StageEmailContracts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StageEmailContracts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StageEmailsArgs = {
  where: StageEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StageEmails_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StageQuestionnaireMappingArgs = {
  where: StageQuestionnaireMapping_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StageQuestionnaireMappingIdenityArgs = {
  where: StageQuestionnaireMappingIdenity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StageQuestionnaireMappingIdenity_By_PkArgs = {
  identity_id: Scalars['Int'];
  stage_questionnaire_mapping_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StageQuestionnaireMapping_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StageQuestionnaireTemplateVariablesArgs = {
  where: StageQuestionnaireTemplateVariables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StageQuestionnaireTemplateVariables_By_PkArgs = {
  contact_variable: ContactTemplateVariablesEnum_Enum;
  stage_questionnaire_mapping_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StageRolesArgs = {
  where: StageRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StageRoles_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StageTransitionsArgs = {
  where: StageTransitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StageTransitions_By_PkArgs = {
  identity_id: Scalars['Int'];
  in_time: Scalars['timestamptz'];
  job_id: Scalars['Int'];
  out_time: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_StagesArgs = {
  where: Stages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StateMachinesArgs = {
  where: StateMachines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StateMachines_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubmissionsArgs = {
  where: Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubmissionsOverlaysLinksArgs = {
  where: SubmissionsOverlaysLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubmissionsOverlaysLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TableConfigTypesArgs = {
  where: TableConfigTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TableConfigTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TableConfigsArgs = {
  where: TableConfigs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TableConfigs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TaskGroupAssigneesArgs = {
  where: TaskGroupAssignees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TaskGroupAssignees_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TaskGroupsArgs = {
  where: TaskGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TaskGroups_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TaskPriorityTypesArgs = {
  where: TaskPriorityTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TaskPriorityTypes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TaskTypesArgs = {
  where: TaskTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TaskTypes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TerminationsArgs = {
  where: Terminations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Terminations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TimeInStagesArgs = {
  where: TimeInStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TimeInStages_By_PkArgs = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TimelineEventsArgs = {
  where: TimelineEvents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TimelineEvents_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TokensArgs = {
  where: Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tokens_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserAccessGroupsArgs = {
  where: UserAccessGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserAccessGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserInterfaceStateArgs = {
  where: UserInterfaceState_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserInterfaceState_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ValueTypesArgs = {
  where: ValueTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ValueTypes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_VeteranStatusEnumArgs = {
  where: VeteranStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VeteranStatusEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_WhiteLabelingArgs = {
  where: WhiteLabeling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WhiteLabeling_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_WorkflowRegressionsArgs = {
  where: WorkflowRegressions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorkflowRegressions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CitizenshipEnumArgs = {
  where: CitizenshipEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CitizenshipEnum_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Contract_EmailsArgs = {
  where: Contract_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contract_Emails_By_PkArgs = {
  contract_id: Scalars['Int'];
  email_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Deleted_Ppl_NamesArgs = {
  where: Deleted_Ppl_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Eeo_Data_Point_Status_By_Identity_ViewArgs = {
  where: Eeo_Data_Point_Status_By_Identity_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Eeo_Data_Points_Unknown_As_Other_By_Identity_ViewArgs = {
  where: Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Eeo_Status_By_IdentityArgs = {
  where: Eeo_Status_By_Identity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Geometry_ColumnsArgs = {
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Identity_Roles_ArrArgs = {
  where: Identity_Roles_Arr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Identity_Roles_StatArgs = {
  where: Identity_Roles_Stat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Jobs_Available_PositionsArgs = {
  where: Jobs_Available_Positions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Matches_From_ApplicationsArgs = {
  where: Matches_From_Applications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Matches_From_BoardArgs = {
  where: Matches_From_Board_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Matches_From_TasksArgs = {
  where: Matches_From_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nylas_EventsArgs = {
  where: Nylas_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nylas_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Search_Spec_ResultsArgs = {
  where: Search_Spec_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Search_Spec_Results_By_PkArgs = {
  combined_id: Scalars['String'];
  search_spec_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Search_SpecsArgs = {
  where: Search_Specs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Search_Specs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_SysArgs = {
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_StatusArgs = {
  where: Staffing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Text_MessagesArgs = {
  where: Text_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Text_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Util_Deps_Saved_DdlArgs = {
  where: Util_Deps_Saved_Ddl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Util_Deps_Saved_Ddl_By_PkArgs = {
  deps_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Workflow_ContractsArgs = {
  where: Workflow_Contracts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workflow_Contracts_By_PkArgs = {
  contract_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Workflow_DepartmentsArgs = {
  where: Workflow_Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workflow_Departments_By_PkArgs = {
  department_id: Scalars['uuid'];
  workflow_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDocuSignAuthArgs = {
  authorizationCode?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootDocuSignSendDocumentArgs = {
  attachments?: InputMaybe<Array<InputMaybe<SendEmailAttachment>>>;
  bcc?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cc?: InputMaybe<Array<InputMaybe<Signer>>>;
  emailBlurb: Scalars['String'];
  emailSubject: Scalars['String'];
  htmlDocument: Scalars['String'];
  signers?: InputMaybe<Array<Signer>>;
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootEnsureUserArgs = {
  userInfo: EnsureUserInput;
};


/** mutation root */
export type Mutation_RootExchangePublicTokenArgs = {
  publicTokenInfo: ExchangePublicTokenInput;
};


/** mutation root */
export type Mutation_RootGetSignedUrlArgs = {
  getSignedUrlInput: GetSignedUrlInput;
};


/** mutation root */
export type Mutation_RootGrantEntitlementArgs = {
  entitlements: Array<InputMaybe<EntitlementGrantInput>>;
};


/** mutation root */
export type Mutation_RootIdibuCreateAccountArgs = {
  account: CreateIdibuAccountInput;
};


/** mutation root */
export type Mutation_RootIdibuSubscribeToBoardArgs = {
  boardId: Scalars['Int'];
  data: Scalars['json'];
};


/** mutation root */
export type Mutation_RootIdibuUnpublishJobArgs = {
  boardIds: Array<Scalars['Int']>;
  externalJobId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootIdibuUnsubscribeFromBoardArgs = {
  boardId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_ApiTokensArgs = {
  objects: Array<ApiTokens_Insert_Input>;
  on_conflict?: InputMaybe<ApiTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApiTokens_OneArgs = {
  object: ApiTokens_Insert_Input;
  on_conflict?: InputMaybe<ApiTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccessIntentsArgs = {
  objects: Array<AccessIntents_Insert_Input>;
  on_conflict?: InputMaybe<AccessIntents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccessIntents_OneArgs = {
  object: AccessIntents_Insert_Input;
  on_conflict?: InputMaybe<AccessIntents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccessTypeEnumArgs = {
  objects: Array<AccessTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<AccessTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccessTypeEnum_OneArgs = {
  object: AccessTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<AccessTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActiveEntitlementsArgs = {
  objects: Array<ActiveEntitlements_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ActiveEntitlements_OneArgs = {
  object: ActiveEntitlements_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ActivityLogTypesArgs = {
  objects: Array<ActivityLogTypes_Insert_Input>;
  on_conflict?: InputMaybe<ActivityLogTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityLogTypes_OneArgs = {
  object: ActivityLogTypes_Insert_Input;
  on_conflict?: InputMaybe<ActivityLogTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityTemplatesArgs = {
  objects: Array<ActivityTemplates_Insert_Input>;
  on_conflict?: InputMaybe<ActivityTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityTemplates_OneArgs = {
  object: ActivityTemplates_Insert_Input;
  on_conflict?: InputMaybe<ActivityTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AoIdentifierTypesArgs = {
  objects: Array<AoIdentifierTypes_Insert_Input>;
  on_conflict?: InputMaybe<AoIdentifierTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AoIdentifierTypes_OneArgs = {
  object: AoIdentifierTypes_Insert_Input;
  on_conflict?: InputMaybe<AoIdentifierTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApplicationStatusArgs = {
  objects: Array<ApplicationStatus_Insert_Input>;
  on_conflict?: InputMaybe<ApplicationStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApplicationStatus_OneArgs = {
  object: ApplicationStatus_Insert_Input;
  on_conflict?: InputMaybe<ApplicationStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApplicationTypeArgs = {
  objects: Array<ApplicationType_Insert_Input>;
  on_conflict?: InputMaybe<ApplicationType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApplicationType_OneArgs = {
  object: ApplicationType_Insert_Input;
  on_conflict?: InputMaybe<ApplicationType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApplicationsArgs = {
  objects: Array<Applications_Insert_Input>;
  on_conflict?: InputMaybe<Applications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Applications_OneArgs = {
  object: Applications_Insert_Input;
  on_conflict?: InputMaybe<Applications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AssignmentsArgs = {
  objects: Array<Assignments_Insert_Input>;
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignments_OneArgs = {
  object: Assignments_Insert_Input;
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AsyncOperationsArgs = {
  objects: Array<AsyncOperations_Insert_Input>;
  on_conflict?: InputMaybe<AsyncOperations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AsyncOperationsStatesArgs = {
  objects: Array<AsyncOperationsStates_Insert_Input>;
  on_conflict?: InputMaybe<AsyncOperationsStates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AsyncOperationsStates_OneArgs = {
  object: AsyncOperationsStates_Insert_Input;
  on_conflict?: InputMaybe<AsyncOperationsStates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AsyncOperations_OneArgs = {
  object: AsyncOperations_Insert_Input;
  on_conflict?: InputMaybe<AsyncOperations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AttachmentsArgs = {
  objects: Array<Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachments_OneArgs = {
  object: Attachments_Insert_Input;
  on_conflict?: InputMaybe<Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuditCreatesArgs = {
  objects: Array<AuditCreates_Insert_Input>;
  on_conflict?: InputMaybe<AuditCreates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuditCreates_OneArgs = {
  object: AuditCreates_Insert_Input;
  on_conflict?: InputMaybe<AuditCreates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuditStagesArgs = {
  objects: Array<AuditStages_Insert_Input>;
  on_conflict?: InputMaybe<AuditStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuditStages_OneArgs = {
  object: AuditStages_Insert_Input;
  on_conflict?: InputMaybe<AuditStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuthAuditsArgs = {
  objects: Array<AuthAudits_Insert_Input>;
  on_conflict?: InputMaybe<AuthAudits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuthAudits_OneArgs = {
  object: AuthAudits_Insert_Input;
  on_conflict?: InputMaybe<AuthAudits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BadgesArgs = {
  objects: Array<Badges_Insert_Input>;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badges_OneArgs = {
  object: Badges_Insert_Input;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BenefitArgs = {
  objects: Array<Benefit_Insert_Input>;
  on_conflict?: InputMaybe<Benefit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BenefitTypeIdentitySubTypesArgs = {
  objects: Array<BenefitTypeIdentitySubTypes_Insert_Input>;
  on_conflict?: InputMaybe<BenefitTypeIdentitySubTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BenefitTypeIdentitySubTypes_OneArgs = {
  object: BenefitTypeIdentitySubTypes_Insert_Input;
  on_conflict?: InputMaybe<BenefitTypeIdentitySubTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BenefitTypesArgs = {
  objects: Array<BenefitTypes_Insert_Input>;
  on_conflict?: InputMaybe<BenefitTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BenefitTypes_OneArgs = {
  object: BenefitTypes_Insert_Input;
  on_conflict?: InputMaybe<BenefitTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Benefit_OneArgs = {
  object: Benefit_Insert_Input;
  on_conflict?: InputMaybe<Benefit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BoardFiltersArgs = {
  objects: Array<BoardFilters_Insert_Input>;
  on_conflict?: InputMaybe<BoardFilters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BoardFilters_OneArgs = {
  object: BoardFilters_Insert_Input;
  on_conflict?: InputMaybe<BoardFilters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BoardSnippetsArgs = {
  objects: Array<BoardSnippets_Insert_Input>;
  on_conflict?: InputMaybe<BoardSnippets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BoardSnippets_OneArgs = {
  object: BoardSnippets_Insert_Input;
  on_conflict?: InputMaybe<BoardSnippets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BullhornSyncStatusArgs = {
  objects: Array<BullhornSyncStatus_Insert_Input>;
  on_conflict?: InputMaybe<BullhornSyncStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BullhornSyncStatus_OneArgs = {
  object: BullhornSyncStatus_Insert_Input;
  on_conflict?: InputMaybe<BullhornSyncStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalcEquationResultsArgs = {
  objects: Array<CalcEquationResults_Insert_Input>;
  on_conflict?: InputMaybe<CalcEquationResults_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalcEquationResults_OneArgs = {
  object: CalcEquationResults_Insert_Input;
  on_conflict?: InputMaybe<CalcEquationResults_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalcFormValuesArgs = {
  objects: Array<CalcFormValues_Insert_Input>;
  on_conflict?: InputMaybe<CalcFormValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalcFormValues_OneArgs = {
  object: CalcFormValues_Insert_Input;
  on_conflict?: InputMaybe<CalcFormValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalcsArgs = {
  objects: Array<Calcs_Insert_Input>;
  on_conflict?: InputMaybe<Calcs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calcs_OneArgs = {
  object: Calcs_Insert_Input;
  on_conflict?: InputMaybe<Calcs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalculationTemplateVersionsArgs = {
  objects: Array<CalculationTemplateVersions_Insert_Input>;
  on_conflict?: InputMaybe<CalculationTemplateVersions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalculationTemplateVersions_OneArgs = {
  object: CalculationTemplateVersions_Insert_Input;
  on_conflict?: InputMaybe<CalculationTemplateVersions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalculationTemplatesArgs = {
  objects: Array<CalculationTemplates_Insert_Input>;
  on_conflict?: InputMaybe<CalculationTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalculationTemplates_OneArgs = {
  object: CalculationTemplates_Insert_Input;
  on_conflict?: InputMaybe<CalculationTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalculationVersionFormInputsArgs = {
  objects: Array<CalculationVersionFormInputs_Insert_Input>;
  on_conflict?: InputMaybe<CalculationVersionFormInputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalculationVersionFormInputs_OneArgs = {
  object: CalculationVersionFormInputs_Insert_Input;
  on_conflict?: InputMaybe<CalculationVersionFormInputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalculationsArgs = {
  objects: Array<Calculations_Insert_Input>;
  on_conflict?: InputMaybe<Calculations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calculations_OneArgs = {
  object: Calculations_Insert_Input;
  on_conflict?: InputMaybe<Calculations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CandidateSourcesArgs = {
  objects: Array<CandidateSources_Insert_Input>;
  on_conflict?: InputMaybe<CandidateSources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CandidateSources_OneArgs = {
  object: CandidateSources_Insert_Input;
  on_conflict?: InputMaybe<CandidateSources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CandidateStatusesArgs = {
  objects: Array<CandidateStatuses_Insert_Input>;
  on_conflict?: InputMaybe<CandidateStatuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CandidateStatuses_OneArgs = {
  object: CandidateStatuses_Insert_Input;
  on_conflict?: InputMaybe<CandidateStatuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CaptureNodesArgs = {
  objects: Array<CaptureNodes_Insert_Input>;
  on_conflict?: InputMaybe<CaptureNodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CaptureNodes_OneArgs = {
  object: CaptureNodes_Insert_Input;
  on_conflict?: InputMaybe<CaptureNodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientSideExternalSystemIntegrationPluginRecordArgs = {
  objects: Array<ClientSideExternalSystemIntegrationPluginRecord_Insert_Input>;
  on_conflict?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientSideExternalSystemIntegrationPluginRecord_OneArgs = {
  object: ClientSideExternalSystemIntegrationPluginRecord_Insert_Input;
  on_conflict?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CognitoUserDataArgs = {
  objects: Array<CognitoUserData_Insert_Input>;
  on_conflict?: InputMaybe<CognitoUserData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CognitoUserData_OneArgs = {
  object: CognitoUserData_Insert_Input;
  on_conflict?: InputMaybe<CognitoUserData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentTypesArgs = {
  objects: Array<CommentTypes_Insert_Input>;
  on_conflict?: InputMaybe<CommentTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentTypes_OneArgs = {
  object: CommentTypes_Insert_Input;
  on_conflict?: InputMaybe<CommentTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: Array<Comments_Insert_Input>;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompaniesArgs = {
  objects: Array<Companies_Insert_Input>;
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Companies_OneArgs = {
  object: Companies_Insert_Input;
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyEventsArgs = {
  objects: Array<CompanyEvents_Insert_Input>;
  on_conflict?: InputMaybe<CompanyEvents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyEvents_OneArgs = {
  object: CompanyEvents_Insert_Input;
  on_conflict?: InputMaybe<CompanyEvents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyStatusEnumArgs = {
  objects: Array<CompanyStatusEnum_Insert_Input>;
  on_conflict?: InputMaybe<CompanyStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyStatusEnum_OneArgs = {
  object: CompanyStatusEnum_Insert_Input;
  on_conflict?: InputMaybe<CompanyStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationsArgs = {
  objects: Array<Compensations_Insert_Input>;
  on_conflict?: InputMaybe<Compensations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compensations_OneArgs = {
  object: Compensations_Insert_Input;
  on_conflict?: InputMaybe<Compensations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactTemplateVariablesEnumArgs = {
  objects: Array<ContactTemplateVariablesEnum_Insert_Input>;
  on_conflict?: InputMaybe<ContactTemplateVariablesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactTemplateVariablesEnum_OneArgs = {
  object: ContactTemplateVariablesEnum_Insert_Input;
  on_conflict?: InputMaybe<ContactTemplateVariablesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractCommentsArgs = {
  objects: Array<ContractComments_Insert_Input>;
  on_conflict?: InputMaybe<ContractComments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractComments_OneArgs = {
  object: ContractComments_Insert_Input;
  on_conflict?: InputMaybe<ContractComments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractFilesArgs = {
  objects: Array<ContractFiles_Insert_Input>;
  on_conflict?: InputMaybe<ContractFiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractFiles_OneArgs = {
  object: ContractFiles_Insert_Input;
  on_conflict?: InputMaybe<ContractFiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractStagesArgs = {
  objects: Array<ContractStages_Insert_Input>;
  on_conflict?: InputMaybe<ContractStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractStages_OneArgs = {
  object: ContractStages_Insert_Input;
  on_conflict?: InputMaybe<ContractStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractStatusEnumArgs = {
  objects: Array<ContractStatusEnum_Insert_Input>;
  on_conflict?: InputMaybe<ContractStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractStatusEnum_OneArgs = {
  object: ContractStatusEnum_Insert_Input;
  on_conflict?: InputMaybe<ContractStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractsArgs = {
  objects: Array<Contracts_Insert_Input>;
  on_conflict?: InputMaybe<Contracts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contracts_OneArgs = {
  object: Contracts_Insert_Input;
  on_conflict?: InputMaybe<Contracts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CurrentStagesArgs = {
  objects: Array<CurrentStages_Insert_Input>;
  on_conflict?: InputMaybe<CurrentStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CurrentStages_OneArgs = {
  object: CurrentStages_Insert_Input;
  on_conflict?: InputMaybe<CurrentStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DataHistoryArgs = {
  objects: Array<DataHistory_Insert_Input>;
  on_conflict?: InputMaybe<DataHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DataHistory_OneArgs = {
  object: DataHistory_Insert_Input;
  on_conflict?: InputMaybe<DataHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DepartmentsArgs = {
  objects: Array<Departments_Insert_Input>;
  on_conflict?: InputMaybe<Departments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Departments_OneArgs = {
  object: Departments_Insert_Input;
  on_conflict?: InputMaybe<Departments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DisabilityStatusEnumArgs = {
  objects: Array<DisabilityStatusEnum_Insert_Input>;
  on_conflict?: InputMaybe<DisabilityStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DisabilityStatusEnum_OneArgs = {
  object: DisabilityStatusEnum_Insert_Input;
  on_conflict?: InputMaybe<DisabilityStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EeoJobCategoriesEnumArgs = {
  objects: Array<EeoJobCategoriesEnum_Insert_Input>;
  on_conflict?: InputMaybe<EeoJobCategoriesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EeoJobCategoriesEnum_OneArgs = {
  object: EeoJobCategoriesEnum_Insert_Input;
  on_conflict?: InputMaybe<EeoJobCategoriesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EarnedBadgesArgs = {
  objects: Array<EarnedBadges_Insert_Input>;
  on_conflict?: InputMaybe<EarnedBadges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EarnedBadges_OneArgs = {
  object: EarnedBadges_Insert_Input;
  on_conflict?: InputMaybe<EarnedBadges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailAddressesArgs = {
  objects: Array<EmailAddresses_Insert_Input>;
  on_conflict?: InputMaybe<EmailAddresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailAddresses_OneArgs = {
  object: EmailAddresses_Insert_Input;
  on_conflict?: InputMaybe<EmailAddresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailEventsArgs = {
  objects: Array<EmailEvents_Insert_Input>;
  on_conflict?: InputMaybe<EmailEvents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailEvents_OneArgs = {
  object: EmailEvents_Insert_Input;
  on_conflict?: InputMaybe<EmailEvents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailRecordsArgs = {
  objects: Array<EmailRecords_Insert_Input>;
  on_conflict?: InputMaybe<EmailRecords_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailRecords_OneArgs = {
  object: EmailRecords_Insert_Input;
  on_conflict?: InputMaybe<EmailRecords_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailSignaturesArgs = {
  objects: Array<EmailSignatures_Insert_Input>;
  on_conflict?: InputMaybe<EmailSignatures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailSignatures_OneArgs = {
  object: EmailSignatures_Insert_Input;
  on_conflict?: InputMaybe<EmailSignatures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailSubFoldersArgs = {
  objects: Array<EmailSubFolders_Insert_Input>;
  on_conflict?: InputMaybe<EmailSubFolders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailSubFolders_OneArgs = {
  object: EmailSubFolders_Insert_Input;
  on_conflict?: InputMaybe<EmailSubFolders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTypeEnumArgs = {
  objects: Array<EmailTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<EmailTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTypeEnum_OneArgs = {
  object: EmailTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<EmailTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTypesArgs = {
  objects: Array<EmailTypes_Insert_Input>;
  on_conflict?: InputMaybe<EmailTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTypes_OneArgs = {
  object: EmailTypes_Insert_Input;
  on_conflict?: InputMaybe<EmailTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailsArgs = {
  objects: Array<Emails_Insert_Input>;
  on_conflict?: InputMaybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Emails_OneArgs = {
  object: Emails_Insert_Input;
  on_conflict?: InputMaybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmploymentHireTypeArgs = {
  objects: Array<EmploymentHireType_Insert_Input>;
  on_conflict?: InputMaybe<EmploymentHireType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmploymentHireType_OneArgs = {
  object: EmploymentHireType_Insert_Input;
  on_conflict?: InputMaybe<EmploymentHireType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmploymentTypesArgs = {
  objects: Array<EmploymentTypes_Insert_Input>;
  on_conflict?: InputMaybe<EmploymentTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmploymentTypes_OneArgs = {
  object: EmploymentTypes_Insert_Input;
  on_conflict?: InputMaybe<EmploymentTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmploymentsArgs = {
  objects: Array<Employments_Insert_Input>;
  on_conflict?: InputMaybe<Employments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employments_OneArgs = {
  object: Employments_Insert_Input;
  on_conflict?: InputMaybe<Employments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntitlementCategoriesArgs = {
  objects: Array<EntitlementCategories_Insert_Input>;
  on_conflict?: InputMaybe<EntitlementCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntitlementCategories_OneArgs = {
  object: EntitlementCategories_Insert_Input;
  on_conflict?: InputMaybe<EntitlementCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntitlementsArgs = {
  objects: Array<Entitlements_Insert_Input>;
  on_conflict?: InputMaybe<Entitlements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entitlements_OneArgs = {
  object: Entitlements_Insert_Input;
  on_conflict?: InputMaybe<Entitlements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EquationFormulaOptionArgs = {
  objects: Array<EquationFormulaOption_Insert_Input>;
  on_conflict?: InputMaybe<EquationFormulaOption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EquationFormulaOption_OneArgs = {
  object: EquationFormulaOption_Insert_Input;
  on_conflict?: InputMaybe<EquationFormulaOption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EquationsArgs = {
  objects: Array<Equations_Insert_Input>;
  on_conflict?: InputMaybe<Equations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Equations_OneArgs = {
  object: Equations_Insert_Input;
  on_conflict?: InputMaybe<Equations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExpenseArgs = {
  objects: Array<Expense_Insert_Input>;
  on_conflict?: InputMaybe<Expense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Expense_OneArgs = {
  object: Expense_Insert_Input;
  on_conflict?: InputMaybe<Expense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalIntegrationTypesArgs = {
  objects: Array<ExternalIntegrationTypes_Insert_Input>;
  on_conflict?: InputMaybe<ExternalIntegrationTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalIntegrationTypes_OneArgs = {
  object: ExternalIntegrationTypes_Insert_Input;
  on_conflict?: InputMaybe<ExternalIntegrationTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalIntegrationsArgs = {
  objects: Array<ExternalIntegrations_Insert_Input>;
  on_conflict?: InputMaybe<ExternalIntegrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalIntegrations_OneArgs = {
  object: ExternalIntegrations_Insert_Input;
  on_conflict?: InputMaybe<ExternalIntegrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalJobBoardPublishingsArgs = {
  objects: Array<ExternalJobBoardPublishings_Insert_Input>;
  on_conflict?: InputMaybe<ExternalJobBoardPublishings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalJobBoardPublishings_OneArgs = {
  object: ExternalJobBoardPublishings_Insert_Input;
  on_conflict?: InputMaybe<ExternalJobBoardPublishings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalJobBoardsArgs = {
  objects: Array<ExternalJobBoards_Insert_Input>;
  on_conflict?: InputMaybe<ExternalJobBoards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalJobBoards_OneArgs = {
  object: ExternalJobBoards_Insert_Input;
  on_conflict?: InputMaybe<ExternalJobBoards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalSystemArgs = {
  objects: Array<ExternalSystem_Insert_Input>;
  on_conflict?: InputMaybe<ExternalSystem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalSystemInstanceArgs = {
  objects: Array<ExternalSystemInstance_Insert_Input>;
  on_conflict?: InputMaybe<ExternalSystemInstance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalSystemInstance_OneArgs = {
  object: ExternalSystemInstance_Insert_Input;
  on_conflict?: InputMaybe<ExternalSystemInstance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalSystem_OneArgs = {
  object: ExternalSystem_Insert_Input;
  on_conflict?: InputMaybe<ExternalSystem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeatureFlagsArgs = {
  objects: Array<FeatureFlags_Insert_Input>;
  on_conflict?: InputMaybe<FeatureFlags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeatureFlags_OneArgs = {
  object: FeatureFlags_Insert_Input;
  on_conflict?: InputMaybe<FeatureFlags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldArgs = {
  objects: Array<Field_Insert_Input>;
  on_conflict?: InputMaybe<Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldDefArgs = {
  objects: Array<FieldDef_Insert_Input>;
  on_conflict?: InputMaybe<FieldDef_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldDef_OneArgs = {
  object: FieldDef_Insert_Input;
  on_conflict?: InputMaybe<FieldDef_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldPicklistValuesArgs = {
  objects: Array<FieldPicklistValues_Insert_Input>;
  on_conflict?: InputMaybe<FieldPicklistValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldPicklistValues_OneArgs = {
  object: FieldPicklistValues_Insert_Input;
  on_conflict?: InputMaybe<FieldPicklistValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldTypeDefArgs = {
  objects: Array<FieldTypeDef_Insert_Input>;
  on_conflict?: InputMaybe<FieldTypeDef_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldTypeDef_OneArgs = {
  object: FieldTypeDef_Insert_Input;
  on_conflict?: InputMaybe<FieldTypeDef_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_OneArgs = {
  object: Field_Insert_Input;
  on_conflict?: InputMaybe<Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileTagsArgs = {
  objects: Array<FileTags_Insert_Input>;
  on_conflict?: InputMaybe<FileTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileTags_OneArgs = {
  object: FileTags_Insert_Input;
  on_conflict?: InputMaybe<FileTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormInputsArgs = {
  objects: Array<FormInputs_Insert_Input>;
  on_conflict?: InputMaybe<FormInputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormInputs_OneArgs = {
  object: FormInputs_Insert_Input;
  on_conflict?: InputMaybe<FormInputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormSelectOptionsArgs = {
  objects: Array<FormSelectOptions_Insert_Input>;
  on_conflict?: InputMaybe<FormSelectOptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormSelectOptions_OneArgs = {
  object: FormSelectOptions_Insert_Input;
  on_conflict?: InputMaybe<FormSelectOptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormulaOptionsArgs = {
  objects: Array<FormulaOptions_Insert_Input>;
  on_conflict?: InputMaybe<FormulaOptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormulaOptions_OneArgs = {
  object: FormulaOptions_Insert_Input;
  on_conflict?: InputMaybe<FormulaOptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenderEnumArgs = {
  objects: Array<GenderEnum_Insert_Input>;
  on_conflict?: InputMaybe<GenderEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenderEnum_OneArgs = {
  object: GenderEnum_Insert_Input;
  on_conflict?: InputMaybe<GenderEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GeocodingHistoryArgs = {
  objects: Array<GeocodingHistory_Insert_Input>;
  on_conflict?: InputMaybe<GeocodingHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GeocodingHistory_OneArgs = {
  object: GeocodingHistory_Insert_Input;
  on_conflict?: InputMaybe<GeocodingHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoalsArgs = {
  objects: Array<Goals_Insert_Input>;
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goals_OneArgs = {
  object: Goals_Insert_Input;
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleSheetCalcTemplatesArgs = {
  objects: Array<GoogleSheetCalcTemplates_Insert_Input>;
  on_conflict?: InputMaybe<GoogleSheetCalcTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleSheetCalcTemplates_OneArgs = {
  object: GoogleSheetCalcTemplates_Insert_Input;
  on_conflict?: InputMaybe<GoogleSheetCalcTemplates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleSheetCalculationsArgs = {
  objects: Array<GoogleSheetCalculations_Insert_Input>;
  on_conflict?: InputMaybe<GoogleSheetCalculations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleSheetCalculations_OneArgs = {
  object: GoogleSheetCalculations_Insert_Input;
  on_conflict?: InputMaybe<GoogleSheetCalculations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupJobsArgs = {
  objects: Array<GroupJobs_Insert_Input>;
  on_conflict?: InputMaybe<GroupJobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupJobs_OneArgs = {
  object: GroupJobs_Insert_Input;
  on_conflict?: InputMaybe<GroupJobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HighestStagesArgs = {
  objects: Array<HighestStages_Insert_Input>;
  on_conflict?: InputMaybe<HighestStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HighestStages_OneArgs = {
  object: HighestStages_Insert_Input;
  on_conflict?: InputMaybe<HighestStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HireTypesEnumArgs = {
  objects: Array<HireTypesEnum_Insert_Input>;
  on_conflict?: InputMaybe<HireTypesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HireTypesEnum_OneArgs = {
  object: HireTypesEnum_Insert_Input;
  on_conflict?: InputMaybe<HireTypesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItRequestContractEmailsArgs = {
  objects: Array<ItRequestContractEmails_Insert_Input>;
  on_conflict?: InputMaybe<ItRequestContractEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItRequestContractEmails_OneArgs = {
  object: ItRequestContractEmails_Insert_Input;
  on_conflict?: InputMaybe<ItRequestContractEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentitiesArgs = {
  objects: Array<Identities_Insert_Input>;
  on_conflict?: InputMaybe<Identities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentitiesOverlaysLinksArgs = {
  objects: Array<IdentitiesOverlaysLinks_Insert_Input>;
  on_conflict?: InputMaybe<IdentitiesOverlaysLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentitiesOverlaysLinks_OneArgs = {
  object: IdentitiesOverlaysLinks_Insert_Input;
  on_conflict?: InputMaybe<IdentitiesOverlaysLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Identities_OneArgs = {
  object: Identities_Insert_Input;
  on_conflict?: InputMaybe<Identities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityAssignmentsArgs = {
  objects: Array<IdentityAssignments_Insert_Input>;
  on_conflict?: InputMaybe<IdentityAssignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityAssignments_OneArgs = {
  object: IdentityAssignments_Insert_Input;
  on_conflict?: InputMaybe<IdentityAssignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityCommentsArgs = {
  objects: Array<IdentityComments_Insert_Input>;
  on_conflict?: InputMaybe<IdentityComments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityComments_OneArgs = {
  object: IdentityComments_Insert_Input;
  on_conflict?: InputMaybe<IdentityComments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityContractRelationshipArgs = {
  objects: Array<IdentityContractRelationship_Insert_Input>;
  on_conflict?: InputMaybe<IdentityContractRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityContractRelationship_OneArgs = {
  object: IdentityContractRelationship_Insert_Input;
  on_conflict?: InputMaybe<IdentityContractRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityDepartmentRelationshipArgs = {
  objects: Array<IdentityDepartmentRelationship_Insert_Input>;
  on_conflict?: InputMaybe<IdentityDepartmentRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityDepartmentRelationship_OneArgs = {
  object: IdentityDepartmentRelationship_Insert_Input;
  on_conflict?: InputMaybe<IdentityDepartmentRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityGroupsArgs = {
  objects: Array<IdentityGroups_Insert_Input>;
  on_conflict?: InputMaybe<IdentityGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityGroups_OneArgs = {
  object: IdentityGroups_Insert_Input;
  on_conflict?: InputMaybe<IdentityGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityJobRelationshipArgs = {
  objects: Array<IdentityJobRelationship_Insert_Input>;
  on_conflict?: InputMaybe<IdentityJobRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityJobRelationship_OneArgs = {
  object: IdentityJobRelationship_Insert_Input;
  on_conflict?: InputMaybe<IdentityJobRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityPartnerGroupDataArgs = {
  objects: Array<IdentityPartnerGroupData_Insert_Input>;
  on_conflict?: InputMaybe<IdentityPartnerGroupData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityPartnerGroupData_OneArgs = {
  object: IdentityPartnerGroupData_Insert_Input;
  on_conflict?: InputMaybe<IdentityPartnerGroupData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityRelationshipTypeArgs = {
  objects: Array<IdentityRelationshipType_Insert_Input>;
  on_conflict?: InputMaybe<IdentityRelationshipType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityRelationshipType_OneArgs = {
  object: IdentityRelationshipType_Insert_Input;
  on_conflict?: InputMaybe<IdentityRelationshipType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityRolesArgs = {
  objects: Array<IdentityRoles_Insert_Input>;
  on_conflict?: InputMaybe<IdentityRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityRoles_OneArgs = {
  object: IdentityRoles_Insert_Input;
  on_conflict?: InputMaybe<IdentityRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentitySubTypeEmailsArgs = {
  objects: Array<IdentitySubTypeEmails_Insert_Input>;
  on_conflict?: InputMaybe<IdentitySubTypeEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentitySubTypeEmails_OneArgs = {
  object: IdentitySubTypeEmails_Insert_Input;
  on_conflict?: InputMaybe<IdentitySubTypeEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentitySubTypesArgs = {
  objects: Array<IdentitySubTypes_Insert_Input>;
  on_conflict?: InputMaybe<IdentitySubTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentitySubTypes_OneArgs = {
  object: IdentitySubTypes_Insert_Input;
  on_conflict?: InputMaybe<IdentitySubTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityTagsArgs = {
  objects: Array<IdentityTags_Insert_Input>;
  on_conflict?: InputMaybe<IdentityTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityTags_OneArgs = {
  object: IdentityTags_Insert_Input;
  on_conflict?: InputMaybe<IdentityTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityTokensArgs = {
  objects: Array<IdentityTokens_Insert_Input>;
  on_conflict?: InputMaybe<IdentityTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityTokens_OneArgs = {
  object: IdentityTokens_Insert_Input;
  on_conflict?: InputMaybe<IdentityTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityTypesArgs = {
  objects: Array<IdentityTypes_Insert_Input>;
  on_conflict?: InputMaybe<IdentityTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityTypes_OneArgs = {
  object: IdentityTypes_Insert_Input;
  on_conflict?: InputMaybe<IdentityTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityUserAccessGroupArgs = {
  objects: Array<IdentityUserAccessGroup_Insert_Input>;
  on_conflict?: InputMaybe<IdentityUserAccessGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IdentityUserAccessGroup_OneArgs = {
  object: IdentityUserAccessGroup_Insert_Input;
  on_conflict?: InputMaybe<IdentityUserAccessGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImagesArgs = {
  objects: Array<Images_Insert_Input>;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Images_OneArgs = {
  object: Images_Insert_Input;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InputTypesArgs = {
  objects: Array<InputTypes_Insert_Input>;
  on_conflict?: InputMaybe<InputTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InputTypes_OneArgs = {
  object: InputTypes_Insert_Input;
  on_conflict?: InputMaybe<InputTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalJobCategoriesArgs = {
  objects: Array<InternalJobCategories_Insert_Input>;
  on_conflict?: InputMaybe<InternalJobCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalJobCategories_OneArgs = {
  object: InternalJobCategories_Insert_Input;
  on_conflict?: InputMaybe<InternalJobCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InterviewsArgs = {
  objects: Array<Interviews_Insert_Input>;
  on_conflict?: InputMaybe<Interviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Interviews_OneArgs = {
  object: Interviews_Insert_Input;
  on_conflict?: InputMaybe<Interviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobAvailabilitiesArgs = {
  objects: Array<JobAvailabilities_Insert_Input>;
  on_conflict?: InputMaybe<JobAvailabilities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobAvailabilities_OneArgs = {
  object: JobAvailabilities_Insert_Input;
  on_conflict?: InputMaybe<JobAvailabilities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobCommentsArgs = {
  objects: Array<JobComments_Insert_Input>;
  on_conflict?: InputMaybe<JobComments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobComments_OneArgs = {
  object: JobComments_Insert_Input;
  on_conflict?: InputMaybe<JobComments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobFilesArgs = {
  objects: Array<JobFiles_Insert_Input>;
  on_conflict?: InputMaybe<JobFiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobFiles_OneArgs = {
  object: JobFiles_Insert_Input;
  on_conflict?: InputMaybe<JobFiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobFundingStatusEnumArgs = {
  objects: Array<JobFundingStatusEnum_Insert_Input>;
  on_conflict?: InputMaybe<JobFundingStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobFundingStatusEnum_OneArgs = {
  object: JobFundingStatusEnum_Insert_Input;
  on_conflict?: InputMaybe<JobFundingStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobHireTypesEnumArgs = {
  objects: Array<JobHireTypesEnum_Insert_Input>;
  on_conflict?: InputMaybe<JobHireTypesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobHireTypesEnum_OneArgs = {
  object: JobHireTypesEnum_Insert_Input;
  on_conflict?: InputMaybe<JobHireTypesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobPositionsArgs = {
  objects: Array<JobPositions_Insert_Input>;
  on_conflict?: InputMaybe<JobPositions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobPositions_OneArgs = {
  object: JobPositions_Insert_Input;
  on_conflict?: InputMaybe<JobPositions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobStatusesArgs = {
  objects: Array<JobStatuses_Insert_Input>;
  on_conflict?: InputMaybe<JobStatuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobStatuses_OneArgs = {
  object: JobStatuses_Insert_Input;
  on_conflict?: InputMaybe<JobStatuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobsArgs = {
  objects: Array<Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Jobs_OneArgs = {
  object: Jobs_Insert_Input;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MagicLinksArgs = {
  objects: Array<MagicLinks_Insert_Input>;
  on_conflict?: InputMaybe<MagicLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MagicLinks_OneArgs = {
  object: MagicLinks_Insert_Input;
  on_conflict?: InputMaybe<MagicLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MigrationDataArgs = {
  objects: Array<MigrationData_Insert_Input>;
  on_conflict?: InputMaybe<MigrationData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MigrationData_OneArgs = {
  object: MigrationData_Insert_Input;
  on_conflict?: InputMaybe<MigrationData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ObjectZooArgs = {
  objects: Array<ObjectZoo_Insert_Input>;
  on_conflict?: InputMaybe<ObjectZoo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ObjectZoo_OneArgs = {
  object: ObjectZoo_Insert_Input;
  on_conflict?: InputMaybe<ObjectZoo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OverlayArgs = {
  objects: Array<Overlay_Insert_Input>;
  on_conflict?: InputMaybe<Overlay_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OverlayDefinitionArgs = {
  objects: Array<OverlayDefinition_Insert_Input>;
  on_conflict?: InputMaybe<OverlayDefinition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OverlayDefinition_OneArgs = {
  object: OverlayDefinition_Insert_Input;
  on_conflict?: InputMaybe<OverlayDefinition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Overlay_OneArgs = {
  object: Overlay_Insert_Input;
  on_conflict?: InputMaybe<Overlay_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PhoneNumbersArgs = {
  objects: Array<PhoneNumbers_Insert_Input>;
  on_conflict?: InputMaybe<PhoneNumbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PhoneNumbers_OneArgs = {
  object: PhoneNumbers_Insert_Input;
  on_conflict?: InputMaybe<PhoneNumbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PicklistValuesArgs = {
  objects: Array<PicklistValues_Insert_Input>;
  on_conflict?: InputMaybe<PicklistValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PicklistValues_OneArgs = {
  object: PicklistValues_Insert_Input;
  on_conflict?: InputMaybe<PicklistValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PicklistsArgs = {
  objects: Array<Picklists_Insert_Input>;
  on_conflict?: InputMaybe<Picklists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Picklists_OneArgs = {
  object: Picklists_Insert_Input;
  on_conflict?: InputMaybe<Picklists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductUpdatesArgs = {
  objects: Array<ProductUpdates_Insert_Input>;
  on_conflict?: InputMaybe<ProductUpdates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductUpdates_OneArgs = {
  object: ProductUpdates_Insert_Input;
  on_conflict?: InputMaybe<ProductUpdates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnaireContactTemplateVariablesArgs = {
  objects: Array<QuestionnaireContactTemplateVariables_Insert_Input>;
  on_conflict?: InputMaybe<QuestionnaireContactTemplateVariables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnaireContactTemplateVariables_OneArgs = {
  object: QuestionnaireContactTemplateVariables_Insert_Input;
  on_conflict?: InputMaybe<QuestionnaireContactTemplateVariables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnaireLinksArgs = {
  objects: Array<QuestionnaireLinks_Insert_Input>;
  on_conflict?: InputMaybe<QuestionnaireLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnaireLinks_OneArgs = {
  object: QuestionnaireLinks_Insert_Input;
  on_conflict?: InputMaybe<QuestionnaireLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnaireResponsesArgs = {
  objects: Array<QuestionnaireResponses_Insert_Input>;
  on_conflict?: InputMaybe<QuestionnaireResponses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnaireResponses_OneArgs = {
  object: QuestionnaireResponses_Insert_Input;
  on_conflict?: InputMaybe<QuestionnaireResponses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnairesArgs = {
  objects: Array<Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_OneArgs = {
  object: Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RrWebRecordsArgs = {
  objects: Array<RrWebRecords_Insert_Input>;
  on_conflict?: InputMaybe<RrWebRecords_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RrWebRecords_OneArgs = {
  object: RrWebRecords_Insert_Input;
  on_conflict?: InputMaybe<RrWebRecords_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RaceEnumArgs = {
  objects: Array<RaceEnum_Insert_Input>;
  on_conflict?: InputMaybe<RaceEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RaceEnum_OneArgs = {
  object: RaceEnum_Insert_Input;
  on_conflict?: InputMaybe<RaceEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RangeValuesArgs = {
  objects: Array<RangeValues_Insert_Input>;
  on_conflict?: InputMaybe<RangeValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RangeValues_OneArgs = {
  object: RangeValues_Insert_Input;
  on_conflict?: InputMaybe<RangeValues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RejectionTypeEnumArgs = {
  objects: Array<RejectionTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<RejectionTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RejectionTypeEnum_OneArgs = {
  object: RejectionTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<RejectionTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RejectionsArgs = {
  objects: Array<Rejections_Insert_Input>;
  on_conflict?: InputMaybe<Rejections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rejections_OneArgs = {
  object: Rejections_Insert_Input;
  on_conflict?: InputMaybe<Rejections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RenderedEmailsArgs = {
  objects: Array<RenderedEmails_Insert_Input>;
  on_conflict?: InputMaybe<RenderedEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RenderedEmails_OneArgs = {
  object: RenderedEmails_Insert_Input;
  on_conflict?: InputMaybe<RenderedEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumeClaimsArgs = {
  objects: Array<ResumeClaims_Insert_Input>;
  on_conflict?: InputMaybe<ResumeClaims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumeClaims_OneArgs = {
  object: ResumeClaims_Insert_Input;
  on_conflict?: InputMaybe<ResumeClaims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumeEmailAddressesArgs = {
  objects: Array<ResumeEmailAddresses_Insert_Input>;
  on_conflict?: InputMaybe<ResumeEmailAddresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumeEmailAddresses_OneArgs = {
  object: ResumeEmailAddresses_Insert_Input;
  on_conflict?: InputMaybe<ResumeEmailAddresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumeIngestStatusArgs = {
  objects: Array<ResumeIngestStatus_Insert_Input>;
  on_conflict?: InputMaybe<ResumeIngestStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumeIngestStatus_OneArgs = {
  object: ResumeIngestStatus_Insert_Input;
  on_conflict?: InputMaybe<ResumeIngestStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumePhoneNumbersArgs = {
  objects: Array<ResumePhoneNumbers_Insert_Input>;
  on_conflict?: InputMaybe<ResumePhoneNumbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumePhoneNumbers_OneArgs = {
  object: ResumePhoneNumbers_Insert_Input;
  on_conflict?: InputMaybe<ResumePhoneNumbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResumesArgs = {
  objects: Array<Resumes_Insert_Input>;
  on_conflict?: InputMaybe<Resumes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resumes_OneArgs = {
  object: Resumes_Insert_Input;
  on_conflict?: InputMaybe<Resumes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewCriteriaArgs = {
  objects: Array<ReviewCriteria_Insert_Input>;
  on_conflict?: InputMaybe<ReviewCriteria_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewCriteriaScoreArgs = {
  objects: Array<ReviewCriteriaScore_Insert_Input>;
  on_conflict?: InputMaybe<ReviewCriteriaScore_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewCriteriaScore_OneArgs = {
  object: ReviewCriteriaScore_Insert_Input;
  on_conflict?: InputMaybe<ReviewCriteriaScore_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewCriteria_OneArgs = {
  object: ReviewCriteria_Insert_Input;
  on_conflict?: InputMaybe<ReviewCriteria_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupContactTemplateVariablesArgs = {
  objects: Array<ReviewGroupContactTemplateVariables_Insert_Input>;
  on_conflict?: InputMaybe<ReviewGroupContactTemplateVariables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupContactTemplateVariables_OneArgs = {
  object: ReviewGroupContactTemplateVariables_Insert_Input;
  on_conflict?: InputMaybe<ReviewGroupContactTemplateVariables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupCriteriaArgs = {
  objects: Array<ReviewGroupCriteria_Insert_Input>;
  on_conflict?: InputMaybe<ReviewGroupCriteria_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupCriteria_OneArgs = {
  object: ReviewGroupCriteria_Insert_Input;
  on_conflict?: InputMaybe<ReviewGroupCriteria_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupFilesArgs = {
  objects: Array<ReviewGroupFiles_Insert_Input>;
  on_conflict?: InputMaybe<ReviewGroupFiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupFiles_OneArgs = {
  object: ReviewGroupFiles_Insert_Input;
  on_conflict?: InputMaybe<ReviewGroupFiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupStagesArgs = {
  objects: Array<ReviewGroupStages_Insert_Input>;
  on_conflict?: InputMaybe<ReviewGroupStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupStages_OneArgs = {
  object: ReviewGroupStages_Insert_Input;
  on_conflict?: InputMaybe<ReviewGroupStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupSubTypeEnumArgs = {
  objects: Array<ReviewGroupSubTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<ReviewGroupSubTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupSubTypeEnum_OneArgs = {
  object: ReviewGroupSubTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<ReviewGroupSubTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupTypeEnumArgs = {
  objects: Array<ReviewGroupTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<ReviewGroupTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupTypeEnum_OneArgs = {
  object: ReviewGroupTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<ReviewGroupTypeEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroupsArgs = {
  objects: Array<ReviewGroups_Insert_Input>;
  on_conflict?: InputMaybe<ReviewGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewGroups_OneArgs = {
  object: ReviewGroups_Insert_Input;
  on_conflict?: InputMaybe<ReviewGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewsArgs = {
  objects: Array<Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewsResultEnumArgs = {
  objects: Array<ReviewsResultEnum_Insert_Input>;
  on_conflict?: InputMaybe<ReviewsResultEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewsResultEnum_OneArgs = {
  object: ReviewsResultEnum_Insert_Input;
  on_conflict?: InputMaybe<ReviewsResultEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reviews_OneArgs = {
  object: Reviews_Insert_Input;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleAccessArgs = {
  objects: Array<RoleAccess_Insert_Input>;
  on_conflict?: InputMaybe<RoleAccess_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleAccess_OneArgs = {
  object: RoleAccess_Insert_Input;
  on_conflict?: InputMaybe<RoleAccess_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SearchFilterTypesEnumArgs = {
  objects: Array<SearchFilterTypesEnum_Insert_Input>;
  on_conflict?: InputMaybe<SearchFilterTypesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SearchFilterTypesEnum_OneArgs = {
  object: SearchFilterTypesEnum_Insert_Input;
  on_conflict?: InputMaybe<SearchFilterTypesEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SearchFiltersArgs = {
  objects: Array<SearchFilters_Insert_Input>;
  on_conflict?: InputMaybe<SearchFilters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SearchFilters_OneArgs = {
  object: SearchFilters_Insert_Input;
  on_conflict?: InputMaybe<SearchFilters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SecurityClearancesArgs = {
  objects: Array<SecurityClearances_Insert_Input>;
  on_conflict?: InputMaybe<SecurityClearances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SecurityClearances_OneArgs = {
  object: SecurityClearances_Insert_Input;
  on_conflict?: InputMaybe<SecurityClearances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SequelizeMetaArgs = {
  objects: Array<SequelizeMeta_Insert_Input>;
  on_conflict?: InputMaybe<SequelizeMeta_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SequelizeMeta_OneArgs = {
  object: SequelizeMeta_Insert_Input;
  on_conflict?: InputMaybe<SequelizeMeta_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageEmailContractsArgs = {
  objects: Array<StageEmailContracts_Insert_Input>;
  on_conflict?: InputMaybe<StageEmailContracts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageEmailContracts_OneArgs = {
  object: StageEmailContracts_Insert_Input;
  on_conflict?: InputMaybe<StageEmailContracts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageEmailsArgs = {
  objects: Array<StageEmails_Insert_Input>;
  on_conflict?: InputMaybe<StageEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageEmails_OneArgs = {
  object: StageEmails_Insert_Input;
  on_conflict?: InputMaybe<StageEmails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageQuestionnaireMappingArgs = {
  objects: Array<StageQuestionnaireMapping_Insert_Input>;
  on_conflict?: InputMaybe<StageQuestionnaireMapping_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageQuestionnaireMappingIdenityArgs = {
  objects: Array<StageQuestionnaireMappingIdenity_Insert_Input>;
  on_conflict?: InputMaybe<StageQuestionnaireMappingIdenity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageQuestionnaireMappingIdenity_OneArgs = {
  object: StageQuestionnaireMappingIdenity_Insert_Input;
  on_conflict?: InputMaybe<StageQuestionnaireMappingIdenity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageQuestionnaireMapping_OneArgs = {
  object: StageQuestionnaireMapping_Insert_Input;
  on_conflict?: InputMaybe<StageQuestionnaireMapping_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageQuestionnaireTemplateVariablesArgs = {
  objects: Array<StageQuestionnaireTemplateVariables_Insert_Input>;
  on_conflict?: InputMaybe<StageQuestionnaireTemplateVariables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageQuestionnaireTemplateVariables_OneArgs = {
  object: StageQuestionnaireTemplateVariables_Insert_Input;
  on_conflict?: InputMaybe<StageQuestionnaireTemplateVariables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageRolesArgs = {
  objects: Array<StageRoles_Insert_Input>;
  on_conflict?: InputMaybe<StageRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageRoles_OneArgs = {
  object: StageRoles_Insert_Input;
  on_conflict?: InputMaybe<StageRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageTransitionsArgs = {
  objects: Array<StageTransitions_Insert_Input>;
  on_conflict?: InputMaybe<StageTransitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StageTransitions_OneArgs = {
  object: StageTransitions_Insert_Input;
  on_conflict?: InputMaybe<StageTransitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StagesArgs = {
  objects: Array<Stages_Insert_Input>;
  on_conflict?: InputMaybe<Stages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stages_OneArgs = {
  object: Stages_Insert_Input;
  on_conflict?: InputMaybe<Stages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StateMachinesArgs = {
  objects: Array<StateMachines_Insert_Input>;
  on_conflict?: InputMaybe<StateMachines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StateMachines_OneArgs = {
  object: StateMachines_Insert_Input;
  on_conflict?: InputMaybe<StateMachines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubmissionsArgs = {
  objects: Array<Submissions_Insert_Input>;
  on_conflict?: InputMaybe<Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubmissionsOverlaysLinksArgs = {
  objects: Array<SubmissionsOverlaysLinks_Insert_Input>;
  on_conflict?: InputMaybe<SubmissionsOverlaysLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubmissionsOverlaysLinks_OneArgs = {
  object: SubmissionsOverlaysLinks_Insert_Input;
  on_conflict?: InputMaybe<SubmissionsOverlaysLinks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submissions_OneArgs = {
  object: Submissions_Insert_Input;
  on_conflict?: InputMaybe<Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TableConfigTypesArgs = {
  objects: Array<TableConfigTypes_Insert_Input>;
  on_conflict?: InputMaybe<TableConfigTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TableConfigTypes_OneArgs = {
  object: TableConfigTypes_Insert_Input;
  on_conflict?: InputMaybe<TableConfigTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TableConfigsArgs = {
  objects: Array<TableConfigs_Insert_Input>;
  on_conflict?: InputMaybe<TableConfigs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TableConfigs_OneArgs = {
  object: TableConfigs_Insert_Input;
  on_conflict?: InputMaybe<TableConfigs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskGroupAssigneesArgs = {
  objects: Array<TaskGroupAssignees_Insert_Input>;
  on_conflict?: InputMaybe<TaskGroupAssignees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskGroupAssignees_OneArgs = {
  object: TaskGroupAssignees_Insert_Input;
  on_conflict?: InputMaybe<TaskGroupAssignees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskGroupsArgs = {
  objects: Array<TaskGroups_Insert_Input>;
  on_conflict?: InputMaybe<TaskGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskGroups_OneArgs = {
  object: TaskGroups_Insert_Input;
  on_conflict?: InputMaybe<TaskGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskPriorityTypesArgs = {
  objects: Array<TaskPriorityTypes_Insert_Input>;
  on_conflict?: InputMaybe<TaskPriorityTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskPriorityTypes_OneArgs = {
  object: TaskPriorityTypes_Insert_Input;
  on_conflict?: InputMaybe<TaskPriorityTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskTypesArgs = {
  objects: Array<TaskTypes_Insert_Input>;
  on_conflict?: InputMaybe<TaskTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskTypes_OneArgs = {
  object: TaskTypes_Insert_Input;
  on_conflict?: InputMaybe<TaskTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TerminationsArgs = {
  objects: Array<Terminations_Insert_Input>;
  on_conflict?: InputMaybe<Terminations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Terminations_OneArgs = {
  object: Terminations_Insert_Input;
  on_conflict?: InputMaybe<Terminations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TimeInStagesArgs = {
  objects: Array<TimeInStages_Insert_Input>;
  on_conflict?: InputMaybe<TimeInStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TimeInStages_OneArgs = {
  object: TimeInStages_Insert_Input;
  on_conflict?: InputMaybe<TimeInStages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TimelineEventsArgs = {
  objects: Array<TimelineEvents_Insert_Input>;
  on_conflict?: InputMaybe<TimelineEvents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TimelineEvents_OneArgs = {
  object: TimelineEvents_Insert_Input;
  on_conflict?: InputMaybe<TimelineEvents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TokensArgs = {
  objects: Array<Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tokens_OneArgs = {
  object: Tokens_Insert_Input;
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAccessGroupsArgs = {
  objects: Array<UserAccessGroups_Insert_Input>;
  on_conflict?: InputMaybe<UserAccessGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAccessGroups_OneArgs = {
  object: UserAccessGroups_Insert_Input;
  on_conflict?: InputMaybe<UserAccessGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserInterfaceStateArgs = {
  objects: Array<UserInterfaceState_Insert_Input>;
  on_conflict?: InputMaybe<UserInterfaceState_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserInterfaceState_OneArgs = {
  object: UserInterfaceState_Insert_Input;
  on_conflict?: InputMaybe<UserInterfaceState_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ValueTypesArgs = {
  objects: Array<ValueTypes_Insert_Input>;
  on_conflict?: InputMaybe<ValueTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ValueTypes_OneArgs = {
  object: ValueTypes_Insert_Input;
  on_conflict?: InputMaybe<ValueTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VeteranStatusEnumArgs = {
  objects: Array<VeteranStatusEnum_Insert_Input>;
  on_conflict?: InputMaybe<VeteranStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VeteranStatusEnum_OneArgs = {
  object: VeteranStatusEnum_Insert_Input;
  on_conflict?: InputMaybe<VeteranStatusEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WhiteLabelingArgs = {
  objects: Array<WhiteLabeling_Insert_Input>;
  on_conflict?: InputMaybe<WhiteLabeling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WhiteLabeling_OneArgs = {
  object: WhiteLabeling_Insert_Input;
  on_conflict?: InputMaybe<WhiteLabeling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkflowRegressionsArgs = {
  objects: Array<WorkflowRegressions_Insert_Input>;
  on_conflict?: InputMaybe<WorkflowRegressions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkflowRegressions_OneArgs = {
  object: WorkflowRegressions_Insert_Input;
  on_conflict?: InputMaybe<WorkflowRegressions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CitizenshipEnumArgs = {
  objects: Array<CitizenshipEnum_Insert_Input>;
  on_conflict?: InputMaybe<CitizenshipEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CitizenshipEnum_OneArgs = {
  object: CitizenshipEnum_Insert_Input;
  on_conflict?: InputMaybe<CitizenshipEnum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contract_EmailsArgs = {
  objects: Array<Contract_Emails_Insert_Input>;
  on_conflict?: InputMaybe<Contract_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contract_Emails_OneArgs = {
  object: Contract_Emails_Insert_Input;
  on_conflict?: InputMaybe<Contract_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deleted_Ppl_NamesArgs = {
  objects: Array<Deleted_Ppl_Names_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Deleted_Ppl_Names_OneArgs = {
  object: Deleted_Ppl_Names_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Eeo_Data_Point_Status_By_Identity_ViewArgs = {
  objects: Array<Eeo_Data_Point_Status_By_Identity_View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Eeo_Data_Point_Status_By_Identity_View_OneArgs = {
  object: Eeo_Data_Point_Status_By_Identity_View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Eeo_Data_Points_Unknown_As_Other_By_Identity_ViewArgs = {
  objects: Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Eeo_Data_Points_Unknown_As_Other_By_Identity_View_OneArgs = {
  object: Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Eeo_Status_By_IdentityArgs = {
  objects: Array<Eeo_Status_By_Identity_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Eeo_Status_By_Identity_OneArgs = {
  object: Eeo_Status_By_Identity_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_ColumnsArgs = {
  objects: Array<Geometry_Columns_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_Columns_OneArgs = {
  object: Geometry_Columns_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Identity_Roles_ArrArgs = {
  objects: Array<Identity_Roles_Arr_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Identity_Roles_Arr_OneArgs = {
  object: Identity_Roles_Arr_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Identity_Roles_StatArgs = {
  objects: Array<Identity_Roles_Stat_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Identity_Roles_Stat_OneArgs = {
  object: Identity_Roles_Stat_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Jobs_Available_PositionsArgs = {
  objects: Array<Jobs_Available_Positions_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Jobs_Available_Positions_OneArgs = {
  object: Jobs_Available_Positions_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Matches_From_ApplicationsArgs = {
  objects: Array<Matches_From_Applications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Matches_From_Applications_OneArgs = {
  object: Matches_From_Applications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Matches_From_BoardArgs = {
  objects: Array<Matches_From_Board_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Matches_From_Board_OneArgs = {
  object: Matches_From_Board_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Matches_From_TasksArgs = {
  objects: Array<Matches_From_Tasks_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Matches_From_Tasks_OneArgs = {
  object: Matches_From_Tasks_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nylas_EventsArgs = {
  objects: Array<Nylas_Events_Insert_Input>;
  on_conflict?: InputMaybe<Nylas_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nylas_Events_OneArgs = {
  object: Nylas_Events_Insert_Input;
  on_conflict?: InputMaybe<Nylas_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_Spec_ResultsArgs = {
  objects: Array<Search_Spec_Results_Insert_Input>;
  on_conflict?: InputMaybe<Search_Spec_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_Spec_Results_OneArgs = {
  object: Search_Spec_Results_Insert_Input;
  on_conflict?: InputMaybe<Search_Spec_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_SpecsArgs = {
  objects: Array<Search_Specs_Insert_Input>;
  on_conflict?: InputMaybe<Search_Specs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_Specs_OneArgs = {
  object: Search_Specs_Insert_Input;
  on_conflict?: InputMaybe<Search_Specs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_SysArgs = {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_Sys_OneArgs = {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_StatusArgs = {
  objects: Array<Staffing_Status_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Status_OneArgs = {
  object: Staffing_Status_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Text_MessagesArgs = {
  objects: Array<Text_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Text_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Text_Messages_OneArgs = {
  object: Text_Messages_Insert_Input;
  on_conflict?: InputMaybe<Text_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Util_Deps_Saved_DdlArgs = {
  objects: Array<Util_Deps_Saved_Ddl_Insert_Input>;
  on_conflict?: InputMaybe<Util_Deps_Saved_Ddl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Util_Deps_Saved_Ddl_OneArgs = {
  object: Util_Deps_Saved_Ddl_Insert_Input;
  on_conflict?: InputMaybe<Util_Deps_Saved_Ddl_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_ContractsArgs = {
  objects: Array<Workflow_Contracts_Insert_Input>;
  on_conflict?: InputMaybe<Workflow_Contracts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_Contracts_OneArgs = {
  object: Workflow_Contracts_Insert_Input;
  on_conflict?: InputMaybe<Workflow_Contracts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_DepartmentsArgs = {
  objects: Array<Workflow_Departments_Insert_Input>;
  on_conflict?: InputMaybe<Workflow_Departments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workflow_Departments_OneArgs = {
  object: Workflow_Departments_Insert_Input;
  on_conflict?: InputMaybe<Workflow_Departments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootIssueIntegrationMergeLinkTokenArgs = {
  linkTokenOptions: MergeLinkTokenInput;
};


/** mutation root */
export type Mutation_RootManageEmailIntegrationArgs = {
  input?: InputMaybe<ManageEmailIntegrationInput>;
};


/** mutation root */
export type Mutation_RootMergeDupesArgs = {
  dupeDef: DuplicateDefinition;
};


/** mutation root */
export type Mutation_RootRecordQuestionnaireResponseArgs = {
  input: RecordQuestionnaireResponseInput;
};


/** mutation root */
export type Mutation_RootRefreshOnboardingArgs = {
  data: RefreshInput;
};


/** mutation root */
export type Mutation_RootRefresh_Analytics_Materialized_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Analytics_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_View_Order_By>>;
  where?: InputMaybe<Analytics_View_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootReindex_IdentitiesArgs = {
  args: Reindex_Identities_Args;
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootReindex_IdentityArgs = {
  args: Reindex_Identity_Args;
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootRenderEmailArgs = {
  email: RenderEmailInput;
};


/** mutation root */
export type Mutation_RootRequestNumArgs = {
  requestor_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSaveOnboardingAtsCredentialsArgs = {
  creds: SaveOnboardingAtsCredentialsInput;
};


/** mutation root */
export type Mutation_RootSendCommentTaggingNotificationArgs = {
  input: SendCommentInput;
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  email: SendEmailInput;
};


/** mutation root */
export type Mutation_RootSendSmsArgs = {
  payload: SmsPayload;
};


/** mutation root */
export type Mutation_RootStartAsyncOperationArgs = {
  op: AsyncOperationInput;
};


/** mutation root */
export type Mutation_RootSyncGSheetCalcInputsArgs = {
  gSheetTemplateId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUpdateCompanyConfigArgs = {
  key: Scalars['String'];
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_ApiTokensArgs = {
  _inc?: InputMaybe<ApiTokens_Inc_Input>;
  _set?: InputMaybe<ApiTokens_Set_Input>;
  where: ApiTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ApiTokens_By_PkArgs = {
  _inc?: InputMaybe<ApiTokens_Inc_Input>;
  _set?: InputMaybe<ApiTokens_Set_Input>;
  pk_columns: ApiTokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApiTokens_ManyArgs = {
  updates: Array<ApiTokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AccessIntentsArgs = {
  _set?: InputMaybe<AccessIntents_Set_Input>;
  where: AccessIntents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AccessIntents_By_PkArgs = {
  _set?: InputMaybe<AccessIntents_Set_Input>;
  pk_columns: AccessIntents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AccessIntents_ManyArgs = {
  updates: Array<AccessIntents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AccessTypeEnumArgs = {
  _set?: InputMaybe<AccessTypeEnum_Set_Input>;
  where: AccessTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AccessTypeEnum_By_PkArgs = {
  _set?: InputMaybe<AccessTypeEnum_Set_Input>;
  pk_columns: AccessTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AccessTypeEnum_ManyArgs = {
  updates: Array<AccessTypeEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ActiveEntitlementsArgs = {
  _inc?: InputMaybe<ActiveEntitlements_Inc_Input>;
  _set?: InputMaybe<ActiveEntitlements_Set_Input>;
  where: ActiveEntitlements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ActiveEntitlements_ManyArgs = {
  updates: Array<ActiveEntitlements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityLogTypesArgs = {
  _inc?: InputMaybe<ActivityLogTypes_Inc_Input>;
  _set?: InputMaybe<ActivityLogTypes_Set_Input>;
  where: ActivityLogTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityLogTypes_By_PkArgs = {
  _inc?: InputMaybe<ActivityLogTypes_Inc_Input>;
  _set?: InputMaybe<ActivityLogTypes_Set_Input>;
  pk_columns: ActivityLogTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityLogTypes_ManyArgs = {
  updates: Array<ActivityLogTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityTemplatesArgs = {
  _inc?: InputMaybe<ActivityTemplates_Inc_Input>;
  _set?: InputMaybe<ActivityTemplates_Set_Input>;
  where: ActivityTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityTemplates_By_PkArgs = {
  _inc?: InputMaybe<ActivityTemplates_Inc_Input>;
  _set?: InputMaybe<ActivityTemplates_Set_Input>;
  pk_columns: ActivityTemplates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityTemplates_ManyArgs = {
  updates: Array<ActivityTemplates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AoIdentifierTypesArgs = {
  _set?: InputMaybe<AoIdentifierTypes_Set_Input>;
  where: AoIdentifierTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AoIdentifierTypes_By_PkArgs = {
  _set?: InputMaybe<AoIdentifierTypes_Set_Input>;
  pk_columns: AoIdentifierTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AoIdentifierTypes_ManyArgs = {
  updates: Array<AoIdentifierTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicationStatusArgs = {
  _set?: InputMaybe<ApplicationStatus_Set_Input>;
  where: ApplicationStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicationStatus_By_PkArgs = {
  _set?: InputMaybe<ApplicationStatus_Set_Input>;
  pk_columns: ApplicationStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicationStatus_ManyArgs = {
  updates: Array<ApplicationStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicationTypeArgs = {
  _set?: InputMaybe<ApplicationType_Set_Input>;
  where: ApplicationType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicationType_By_PkArgs = {
  _set?: InputMaybe<ApplicationType_Set_Input>;
  pk_columns: ApplicationType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicationType_ManyArgs = {
  updates: Array<ApplicationType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ApplicationsArgs = {
  _append?: InputMaybe<Applications_Append_Input>;
  _delete_at_path?: InputMaybe<Applications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Applications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Applications_Delete_Key_Input>;
  _inc?: InputMaybe<Applications_Inc_Input>;
  _prepend?: InputMaybe<Applications_Prepend_Input>;
  _set?: InputMaybe<Applications_Set_Input>;
  where: Applications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Applications_By_PkArgs = {
  _append?: InputMaybe<Applications_Append_Input>;
  _delete_at_path?: InputMaybe<Applications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Applications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Applications_Delete_Key_Input>;
  _inc?: InputMaybe<Applications_Inc_Input>;
  _prepend?: InputMaybe<Applications_Prepend_Input>;
  _set?: InputMaybe<Applications_Set_Input>;
  pk_columns: Applications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Applications_ManyArgs = {
  updates: Array<Applications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AssignmentsArgs = {
  _inc?: InputMaybe<Assignments_Inc_Input>;
  _set?: InputMaybe<Assignments_Set_Input>;
  where: Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignments_By_PkArgs = {
  _inc?: InputMaybe<Assignments_Inc_Input>;
  _set?: InputMaybe<Assignments_Set_Input>;
  pk_columns: Assignments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignments_ManyArgs = {
  updates: Array<Assignments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AsyncOperationsArgs = {
  _append?: InputMaybe<AsyncOperations_Append_Input>;
  _delete_at_path?: InputMaybe<AsyncOperations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AsyncOperations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AsyncOperations_Delete_Key_Input>;
  _inc?: InputMaybe<AsyncOperations_Inc_Input>;
  _prepend?: InputMaybe<AsyncOperations_Prepend_Input>;
  _set?: InputMaybe<AsyncOperations_Set_Input>;
  where: AsyncOperations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AsyncOperationsStatesArgs = {
  _set?: InputMaybe<AsyncOperationsStates_Set_Input>;
  where: AsyncOperationsStates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AsyncOperationsStates_By_PkArgs = {
  _set?: InputMaybe<AsyncOperationsStates_Set_Input>;
  pk_columns: AsyncOperationsStates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AsyncOperationsStates_ManyArgs = {
  updates: Array<AsyncOperationsStates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AsyncOperations_By_PkArgs = {
  _append?: InputMaybe<AsyncOperations_Append_Input>;
  _delete_at_path?: InputMaybe<AsyncOperations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AsyncOperations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AsyncOperations_Delete_Key_Input>;
  _inc?: InputMaybe<AsyncOperations_Inc_Input>;
  _prepend?: InputMaybe<AsyncOperations_Prepend_Input>;
  _set?: InputMaybe<AsyncOperations_Set_Input>;
  pk_columns: AsyncOperations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AsyncOperations_ManyArgs = {
  updates: Array<AsyncOperations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AttachmentsArgs = {
  _inc?: InputMaybe<Attachments_Inc_Input>;
  _set?: InputMaybe<Attachments_Set_Input>;
  where: Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attachments_By_PkArgs = {
  _inc?: InputMaybe<Attachments_Inc_Input>;
  _set?: InputMaybe<Attachments_Set_Input>;
  pk_columns: Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attachments_ManyArgs = {
  updates: Array<Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuditCreatesArgs = {
  _inc?: InputMaybe<AuditCreates_Inc_Input>;
  _set?: InputMaybe<AuditCreates_Set_Input>;
  where: AuditCreates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AuditCreates_By_PkArgs = {
  _inc?: InputMaybe<AuditCreates_Inc_Input>;
  _set?: InputMaybe<AuditCreates_Set_Input>;
  pk_columns: AuditCreates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AuditCreates_ManyArgs = {
  updates: Array<AuditCreates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuditStagesArgs = {
  _inc?: InputMaybe<AuditStages_Inc_Input>;
  _set?: InputMaybe<AuditStages_Set_Input>;
  where: AuditStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AuditStages_By_PkArgs = {
  _inc?: InputMaybe<AuditStages_Inc_Input>;
  _set?: InputMaybe<AuditStages_Set_Input>;
  pk_columns: AuditStages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AuditStages_ManyArgs = {
  updates: Array<AuditStages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthAuditsArgs = {
  _append?: InputMaybe<AuthAudits_Append_Input>;
  _delete_at_path?: InputMaybe<AuthAudits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthAudits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthAudits_Delete_Key_Input>;
  _inc?: InputMaybe<AuthAudits_Inc_Input>;
  _prepend?: InputMaybe<AuthAudits_Prepend_Input>;
  _set?: InputMaybe<AuthAudits_Set_Input>;
  where: AuthAudits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AuthAudits_By_PkArgs = {
  _append?: InputMaybe<AuthAudits_Append_Input>;
  _delete_at_path?: InputMaybe<AuthAudits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthAudits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthAudits_Delete_Key_Input>;
  _inc?: InputMaybe<AuthAudits_Inc_Input>;
  _prepend?: InputMaybe<AuthAudits_Prepend_Input>;
  _set?: InputMaybe<AuthAudits_Set_Input>;
  pk_columns: AuthAudits_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AuthAudits_ManyArgs = {
  updates: Array<AuthAudits_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BadgesArgs = {
  _inc?: InputMaybe<Badges_Inc_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_By_PkArgs = {
  _inc?: InputMaybe<Badges_Inc_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  pk_columns: Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_ManyArgs = {
  updates: Array<Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BenefitArgs = {
  _inc?: InputMaybe<Benefit_Inc_Input>;
  _set?: InputMaybe<Benefit_Set_Input>;
  where: Benefit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BenefitTypeIdentitySubTypesArgs = {
  _inc?: InputMaybe<BenefitTypeIdentitySubTypes_Inc_Input>;
  _set?: InputMaybe<BenefitTypeIdentitySubTypes_Set_Input>;
  where: BenefitTypeIdentitySubTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BenefitTypeIdentitySubTypes_By_PkArgs = {
  _inc?: InputMaybe<BenefitTypeIdentitySubTypes_Inc_Input>;
  _set?: InputMaybe<BenefitTypeIdentitySubTypes_Set_Input>;
  pk_columns: BenefitTypeIdentitySubTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BenefitTypeIdentitySubTypes_ManyArgs = {
  updates: Array<BenefitTypeIdentitySubTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BenefitTypesArgs = {
  _inc?: InputMaybe<BenefitTypes_Inc_Input>;
  _set?: InputMaybe<BenefitTypes_Set_Input>;
  where: BenefitTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BenefitTypes_By_PkArgs = {
  _inc?: InputMaybe<BenefitTypes_Inc_Input>;
  _set?: InputMaybe<BenefitTypes_Set_Input>;
  pk_columns: BenefitTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BenefitTypes_ManyArgs = {
  updates: Array<BenefitTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Benefit_By_PkArgs = {
  _inc?: InputMaybe<Benefit_Inc_Input>;
  _set?: InputMaybe<Benefit_Set_Input>;
  pk_columns: Benefit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Benefit_ManyArgs = {
  updates: Array<Benefit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BoardFiltersArgs = {
  _inc?: InputMaybe<BoardFilters_Inc_Input>;
  _set?: InputMaybe<BoardFilters_Set_Input>;
  where: BoardFilters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardFilters_By_PkArgs = {
  _inc?: InputMaybe<BoardFilters_Inc_Input>;
  _set?: InputMaybe<BoardFilters_Set_Input>;
  pk_columns: BoardFilters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BoardFilters_ManyArgs = {
  updates: Array<BoardFilters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BoardSnippetsArgs = {
  _append?: InputMaybe<BoardSnippets_Append_Input>;
  _delete_at_path?: InputMaybe<BoardSnippets_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<BoardSnippets_Delete_Elem_Input>;
  _delete_key?: InputMaybe<BoardSnippets_Delete_Key_Input>;
  _inc?: InputMaybe<BoardSnippets_Inc_Input>;
  _prepend?: InputMaybe<BoardSnippets_Prepend_Input>;
  _set?: InputMaybe<BoardSnippets_Set_Input>;
  where: BoardSnippets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardSnippets_By_PkArgs = {
  _append?: InputMaybe<BoardSnippets_Append_Input>;
  _delete_at_path?: InputMaybe<BoardSnippets_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<BoardSnippets_Delete_Elem_Input>;
  _delete_key?: InputMaybe<BoardSnippets_Delete_Key_Input>;
  _inc?: InputMaybe<BoardSnippets_Inc_Input>;
  _prepend?: InputMaybe<BoardSnippets_Prepend_Input>;
  _set?: InputMaybe<BoardSnippets_Set_Input>;
  pk_columns: BoardSnippets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BoardSnippets_ManyArgs = {
  updates: Array<BoardSnippets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BullhornSyncStatusArgs = {
  _append?: InputMaybe<BullhornSyncStatus_Append_Input>;
  _delete_at_path?: InputMaybe<BullhornSyncStatus_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<BullhornSyncStatus_Delete_Elem_Input>;
  _delete_key?: InputMaybe<BullhornSyncStatus_Delete_Key_Input>;
  _inc?: InputMaybe<BullhornSyncStatus_Inc_Input>;
  _prepend?: InputMaybe<BullhornSyncStatus_Prepend_Input>;
  _set?: InputMaybe<BullhornSyncStatus_Set_Input>;
  where: BullhornSyncStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BullhornSyncStatus_By_PkArgs = {
  _append?: InputMaybe<BullhornSyncStatus_Append_Input>;
  _delete_at_path?: InputMaybe<BullhornSyncStatus_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<BullhornSyncStatus_Delete_Elem_Input>;
  _delete_key?: InputMaybe<BullhornSyncStatus_Delete_Key_Input>;
  _inc?: InputMaybe<BullhornSyncStatus_Inc_Input>;
  _prepend?: InputMaybe<BullhornSyncStatus_Prepend_Input>;
  _set?: InputMaybe<BullhornSyncStatus_Set_Input>;
  pk_columns: BullhornSyncStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BullhornSyncStatus_ManyArgs = {
  updates: Array<BullhornSyncStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CalcEquationResultsArgs = {
  _inc?: InputMaybe<CalcEquationResults_Inc_Input>;
  _set?: InputMaybe<CalcEquationResults_Set_Input>;
  where: CalcEquationResults_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalcEquationResults_By_PkArgs = {
  _inc?: InputMaybe<CalcEquationResults_Inc_Input>;
  _set?: InputMaybe<CalcEquationResults_Set_Input>;
  pk_columns: CalcEquationResults_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalcEquationResults_ManyArgs = {
  updates: Array<CalcEquationResults_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CalcFormValuesArgs = {
  _inc?: InputMaybe<CalcFormValues_Inc_Input>;
  _set?: InputMaybe<CalcFormValues_Set_Input>;
  where: CalcFormValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalcFormValues_By_PkArgs = {
  _inc?: InputMaybe<CalcFormValues_Inc_Input>;
  _set?: InputMaybe<CalcFormValues_Set_Input>;
  pk_columns: CalcFormValues_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalcFormValues_ManyArgs = {
  updates: Array<CalcFormValues_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CalcsArgs = {
  _inc?: InputMaybe<Calcs_Inc_Input>;
  _set?: InputMaybe<Calcs_Set_Input>;
  where: Calcs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calcs_By_PkArgs = {
  _inc?: InputMaybe<Calcs_Inc_Input>;
  _set?: InputMaybe<Calcs_Set_Input>;
  pk_columns: Calcs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Calcs_ManyArgs = {
  updates: Array<Calcs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationTemplateVersionsArgs = {
  _inc?: InputMaybe<CalculationTemplateVersions_Inc_Input>;
  _set?: InputMaybe<CalculationTemplateVersions_Set_Input>;
  where: CalculationTemplateVersions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationTemplateVersions_By_PkArgs = {
  _inc?: InputMaybe<CalculationTemplateVersions_Inc_Input>;
  _set?: InputMaybe<CalculationTemplateVersions_Set_Input>;
  pk_columns: CalculationTemplateVersions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationTemplateVersions_ManyArgs = {
  updates: Array<CalculationTemplateVersions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationTemplatesArgs = {
  _inc?: InputMaybe<CalculationTemplates_Inc_Input>;
  _set?: InputMaybe<CalculationTemplates_Set_Input>;
  where: CalculationTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationTemplates_By_PkArgs = {
  _inc?: InputMaybe<CalculationTemplates_Inc_Input>;
  _set?: InputMaybe<CalculationTemplates_Set_Input>;
  pk_columns: CalculationTemplates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationTemplates_ManyArgs = {
  updates: Array<CalculationTemplates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationVersionFormInputsArgs = {
  _append?: InputMaybe<CalculationVersionFormInputs_Append_Input>;
  _delete_at_path?: InputMaybe<CalculationVersionFormInputs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CalculationVersionFormInputs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CalculationVersionFormInputs_Delete_Key_Input>;
  _inc?: InputMaybe<CalculationVersionFormInputs_Inc_Input>;
  _prepend?: InputMaybe<CalculationVersionFormInputs_Prepend_Input>;
  _set?: InputMaybe<CalculationVersionFormInputs_Set_Input>;
  where: CalculationVersionFormInputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationVersionFormInputs_By_PkArgs = {
  _append?: InputMaybe<CalculationVersionFormInputs_Append_Input>;
  _delete_at_path?: InputMaybe<CalculationVersionFormInputs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CalculationVersionFormInputs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CalculationVersionFormInputs_Delete_Key_Input>;
  _inc?: InputMaybe<CalculationVersionFormInputs_Inc_Input>;
  _prepend?: InputMaybe<CalculationVersionFormInputs_Prepend_Input>;
  _set?: InputMaybe<CalculationVersionFormInputs_Set_Input>;
  pk_columns: CalculationVersionFormInputs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationVersionFormInputs_ManyArgs = {
  updates: Array<CalculationVersionFormInputs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CalculationsArgs = {
  _append?: InputMaybe<Calculations_Append_Input>;
  _delete_at_path?: InputMaybe<Calculations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calculations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calculations_Delete_Key_Input>;
  _inc?: InputMaybe<Calculations_Inc_Input>;
  _prepend?: InputMaybe<Calculations_Prepend_Input>;
  _set?: InputMaybe<Calculations_Set_Input>;
  where: Calculations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calculations_By_PkArgs = {
  _append?: InputMaybe<Calculations_Append_Input>;
  _delete_at_path?: InputMaybe<Calculations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calculations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calculations_Delete_Key_Input>;
  _inc?: InputMaybe<Calculations_Inc_Input>;
  _prepend?: InputMaybe<Calculations_Prepend_Input>;
  _set?: InputMaybe<Calculations_Set_Input>;
  pk_columns: Calculations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Calculations_ManyArgs = {
  updates: Array<Calculations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CandidateSourcesArgs = {
  _inc?: InputMaybe<CandidateSources_Inc_Input>;
  _set?: InputMaybe<CandidateSources_Set_Input>;
  where: CandidateSources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CandidateSources_By_PkArgs = {
  _inc?: InputMaybe<CandidateSources_Inc_Input>;
  _set?: InputMaybe<CandidateSources_Set_Input>;
  pk_columns: CandidateSources_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CandidateSources_ManyArgs = {
  updates: Array<CandidateSources_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CandidateStatusesArgs = {
  _inc?: InputMaybe<CandidateStatuses_Inc_Input>;
  _set?: InputMaybe<CandidateStatuses_Set_Input>;
  where: CandidateStatuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CandidateStatuses_By_PkArgs = {
  _inc?: InputMaybe<CandidateStatuses_Inc_Input>;
  _set?: InputMaybe<CandidateStatuses_Set_Input>;
  pk_columns: CandidateStatuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CandidateStatuses_ManyArgs = {
  updates: Array<CandidateStatuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CaptureNodesArgs = {
  _append?: InputMaybe<CaptureNodes_Append_Input>;
  _delete_at_path?: InputMaybe<CaptureNodes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CaptureNodes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CaptureNodes_Delete_Key_Input>;
  _inc?: InputMaybe<CaptureNodes_Inc_Input>;
  _prepend?: InputMaybe<CaptureNodes_Prepend_Input>;
  _set?: InputMaybe<CaptureNodes_Set_Input>;
  where: CaptureNodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CaptureNodes_By_PkArgs = {
  _append?: InputMaybe<CaptureNodes_Append_Input>;
  _delete_at_path?: InputMaybe<CaptureNodes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CaptureNodes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CaptureNodes_Delete_Key_Input>;
  _inc?: InputMaybe<CaptureNodes_Inc_Input>;
  _prepend?: InputMaybe<CaptureNodes_Prepend_Input>;
  _set?: InputMaybe<CaptureNodes_Set_Input>;
  pk_columns: CaptureNodes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CaptureNodes_ManyArgs = {
  updates: Array<CaptureNodes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientSideExternalSystemIntegrationPluginRecordArgs = {
  _append?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Append_Input>;
  _delete_at_path?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_Key_Input>;
  _inc?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Inc_Input>;
  _prepend?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Prepend_Input>;
  _set?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Set_Input>;
  where: ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClientSideExternalSystemIntegrationPluginRecord_By_PkArgs = {
  _append?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Append_Input>;
  _delete_at_path?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Delete_Key_Input>;
  _inc?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Inc_Input>;
  _prepend?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Prepend_Input>;
  _set?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Set_Input>;
  pk_columns: ClientSideExternalSystemIntegrationPluginRecord_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClientSideExternalSystemIntegrationPluginRecord_ManyArgs = {
  updates: Array<ClientSideExternalSystemIntegrationPluginRecord_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CognitoUserDataArgs = {
  _append?: InputMaybe<CognitoUserData_Append_Input>;
  _delete_at_path?: InputMaybe<CognitoUserData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CognitoUserData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CognitoUserData_Delete_Key_Input>;
  _prepend?: InputMaybe<CognitoUserData_Prepend_Input>;
  _set?: InputMaybe<CognitoUserData_Set_Input>;
  where: CognitoUserData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CognitoUserData_By_PkArgs = {
  _append?: InputMaybe<CognitoUserData_Append_Input>;
  _delete_at_path?: InputMaybe<CognitoUserData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CognitoUserData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CognitoUserData_Delete_Key_Input>;
  _prepend?: InputMaybe<CognitoUserData_Prepend_Input>;
  _set?: InputMaybe<CognitoUserData_Set_Input>;
  pk_columns: CognitoUserData_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CognitoUserData_ManyArgs = {
  updates: Array<CognitoUserData_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentTypesArgs = {
  _set?: InputMaybe<CommentTypes_Set_Input>;
  where: CommentTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CommentTypes_By_PkArgs = {
  _set?: InputMaybe<CommentTypes_Set_Input>;
  pk_columns: CommentTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CommentTypes_ManyArgs = {
  updates: Array<CommentTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _append?: InputMaybe<Comments_Append_Input>;
  _delete_at_path?: InputMaybe<Comments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Comments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Comments_Delete_Key_Input>;
  _inc?: InputMaybe<Comments_Inc_Input>;
  _prepend?: InputMaybe<Comments_Prepend_Input>;
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _append?: InputMaybe<Comments_Append_Input>;
  _delete_at_path?: InputMaybe<Comments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Comments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Comments_Delete_Key_Input>;
  _inc?: InputMaybe<Comments_Inc_Input>;
  _prepend?: InputMaybe<Comments_Prepend_Input>;
  _set?: InputMaybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_ManyArgs = {
  updates: Array<Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompaniesArgs = {
  _append?: InputMaybe<Companies_Append_Input>;
  _delete_at_path?: InputMaybe<Companies_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Companies_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Companies_Delete_Key_Input>;
  _inc?: InputMaybe<Companies_Inc_Input>;
  _prepend?: InputMaybe<Companies_Prepend_Input>;
  _set?: InputMaybe<Companies_Set_Input>;
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_By_PkArgs = {
  _append?: InputMaybe<Companies_Append_Input>;
  _delete_at_path?: InputMaybe<Companies_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Companies_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Companies_Delete_Key_Input>;
  _inc?: InputMaybe<Companies_Inc_Input>;
  _prepend?: InputMaybe<Companies_Prepend_Input>;
  _set?: InputMaybe<Companies_Set_Input>;
  pk_columns: Companies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_ManyArgs = {
  updates: Array<Companies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyEventsArgs = {
  _inc?: InputMaybe<CompanyEvents_Inc_Input>;
  _set?: InputMaybe<CompanyEvents_Set_Input>;
  where: CompanyEvents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyEvents_By_PkArgs = {
  _inc?: InputMaybe<CompanyEvents_Inc_Input>;
  _set?: InputMaybe<CompanyEvents_Set_Input>;
  pk_columns: CompanyEvents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyEvents_ManyArgs = {
  updates: Array<CompanyEvents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyStatusEnumArgs = {
  _set?: InputMaybe<CompanyStatusEnum_Set_Input>;
  where: CompanyStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyStatusEnum_By_PkArgs = {
  _set?: InputMaybe<CompanyStatusEnum_Set_Input>;
  pk_columns: CompanyStatusEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyStatusEnum_ManyArgs = {
  updates: Array<CompanyStatusEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationsArgs = {
  _inc?: InputMaybe<Compensations_Inc_Input>;
  _set?: InputMaybe<Compensations_Set_Input>;
  where: Compensations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compensations_By_PkArgs = {
  _inc?: InputMaybe<Compensations_Inc_Input>;
  _set?: InputMaybe<Compensations_Set_Input>;
  pk_columns: Compensations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compensations_ManyArgs = {
  updates: Array<Compensations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContactTemplateVariablesEnumArgs = {
  _set?: InputMaybe<ContactTemplateVariablesEnum_Set_Input>;
  where: ContactTemplateVariablesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContactTemplateVariablesEnum_By_PkArgs = {
  _set?: InputMaybe<ContactTemplateVariablesEnum_Set_Input>;
  pk_columns: ContactTemplateVariablesEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContactTemplateVariablesEnum_ManyArgs = {
  updates: Array<ContactTemplateVariablesEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContractCommentsArgs = {
  _inc?: InputMaybe<ContractComments_Inc_Input>;
  _set?: InputMaybe<ContractComments_Set_Input>;
  where: ContractComments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContractComments_By_PkArgs = {
  _inc?: InputMaybe<ContractComments_Inc_Input>;
  _set?: InputMaybe<ContractComments_Set_Input>;
  pk_columns: ContractComments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContractComments_ManyArgs = {
  updates: Array<ContractComments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContractFilesArgs = {
  _inc?: InputMaybe<ContractFiles_Inc_Input>;
  _set?: InputMaybe<ContractFiles_Set_Input>;
  where: ContractFiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContractFiles_By_PkArgs = {
  _inc?: InputMaybe<ContractFiles_Inc_Input>;
  _set?: InputMaybe<ContractFiles_Set_Input>;
  pk_columns: ContractFiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContractFiles_ManyArgs = {
  updates: Array<ContractFiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContractStagesArgs = {
  _inc?: InputMaybe<ContractStages_Inc_Input>;
  _set?: InputMaybe<ContractStages_Set_Input>;
  where: ContractStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContractStages_By_PkArgs = {
  _inc?: InputMaybe<ContractStages_Inc_Input>;
  _set?: InputMaybe<ContractStages_Set_Input>;
  pk_columns: ContractStages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContractStages_ManyArgs = {
  updates: Array<ContractStages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContractStatusEnumArgs = {
  _set?: InputMaybe<ContractStatusEnum_Set_Input>;
  where: ContractStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContractStatusEnum_By_PkArgs = {
  _set?: InputMaybe<ContractStatusEnum_Set_Input>;
  pk_columns: ContractStatusEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContractStatusEnum_ManyArgs = {
  updates: Array<ContractStatusEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContractsArgs = {
  _append?: InputMaybe<Contracts_Append_Input>;
  _delete_at_path?: InputMaybe<Contracts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Contracts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Contracts_Delete_Key_Input>;
  _inc?: InputMaybe<Contracts_Inc_Input>;
  _prepend?: InputMaybe<Contracts_Prepend_Input>;
  _set?: InputMaybe<Contracts_Set_Input>;
  where: Contracts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contracts_By_PkArgs = {
  _append?: InputMaybe<Contracts_Append_Input>;
  _delete_at_path?: InputMaybe<Contracts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Contracts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Contracts_Delete_Key_Input>;
  _inc?: InputMaybe<Contracts_Inc_Input>;
  _prepend?: InputMaybe<Contracts_Prepend_Input>;
  _set?: InputMaybe<Contracts_Set_Input>;
  pk_columns: Contracts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contracts_ManyArgs = {
  updates: Array<Contracts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CurrentStagesArgs = {
  _inc?: InputMaybe<CurrentStages_Inc_Input>;
  _set?: InputMaybe<CurrentStages_Set_Input>;
  where: CurrentStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CurrentStages_By_PkArgs = {
  _inc?: InputMaybe<CurrentStages_Inc_Input>;
  _set?: InputMaybe<CurrentStages_Set_Input>;
  pk_columns: CurrentStages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CurrentStages_ManyArgs = {
  updates: Array<CurrentStages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DataHistoryArgs = {
  _append?: InputMaybe<DataHistory_Append_Input>;
  _delete_at_path?: InputMaybe<DataHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<DataHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<DataHistory_Delete_Key_Input>;
  _inc?: InputMaybe<DataHistory_Inc_Input>;
  _prepend?: InputMaybe<DataHistory_Prepend_Input>;
  _set?: InputMaybe<DataHistory_Set_Input>;
  where: DataHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DataHistory_By_PkArgs = {
  _append?: InputMaybe<DataHistory_Append_Input>;
  _delete_at_path?: InputMaybe<DataHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<DataHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<DataHistory_Delete_Key_Input>;
  _inc?: InputMaybe<DataHistory_Inc_Input>;
  _prepend?: InputMaybe<DataHistory_Prepend_Input>;
  _set?: InputMaybe<DataHistory_Set_Input>;
  pk_columns: DataHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DataHistory_ManyArgs = {
  updates: Array<DataHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DepartmentsArgs = {
  _inc?: InputMaybe<Departments_Inc_Input>;
  _set?: InputMaybe<Departments_Set_Input>;
  where: Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Departments_By_PkArgs = {
  _inc?: InputMaybe<Departments_Inc_Input>;
  _set?: InputMaybe<Departments_Set_Input>;
  pk_columns: Departments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Departments_ManyArgs = {
  updates: Array<Departments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DisabilityStatusEnumArgs = {
  _set?: InputMaybe<DisabilityStatusEnum_Set_Input>;
  where: DisabilityStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DisabilityStatusEnum_By_PkArgs = {
  _set?: InputMaybe<DisabilityStatusEnum_Set_Input>;
  pk_columns: DisabilityStatusEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DisabilityStatusEnum_ManyArgs = {
  updates: Array<DisabilityStatusEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EeoJobCategoriesEnumArgs = {
  _set?: InputMaybe<EeoJobCategoriesEnum_Set_Input>;
  where: EeoJobCategoriesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EeoJobCategoriesEnum_By_PkArgs = {
  _set?: InputMaybe<EeoJobCategoriesEnum_Set_Input>;
  pk_columns: EeoJobCategoriesEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EeoJobCategoriesEnum_ManyArgs = {
  updates: Array<EeoJobCategoriesEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EarnedBadgesArgs = {
  _inc?: InputMaybe<EarnedBadges_Inc_Input>;
  _set?: InputMaybe<EarnedBadges_Set_Input>;
  where: EarnedBadges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EarnedBadges_By_PkArgs = {
  _inc?: InputMaybe<EarnedBadges_Inc_Input>;
  _set?: InputMaybe<EarnedBadges_Set_Input>;
  pk_columns: EarnedBadges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EarnedBadges_ManyArgs = {
  updates: Array<EarnedBadges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailAddressesArgs = {
  _append?: InputMaybe<EmailAddresses_Append_Input>;
  _delete_at_path?: InputMaybe<EmailAddresses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<EmailAddresses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<EmailAddresses_Delete_Key_Input>;
  _prepend?: InputMaybe<EmailAddresses_Prepend_Input>;
  _set?: InputMaybe<EmailAddresses_Set_Input>;
  where: EmailAddresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailAddresses_By_PkArgs = {
  _append?: InputMaybe<EmailAddresses_Append_Input>;
  _delete_at_path?: InputMaybe<EmailAddresses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<EmailAddresses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<EmailAddresses_Delete_Key_Input>;
  _prepend?: InputMaybe<EmailAddresses_Prepend_Input>;
  _set?: InputMaybe<EmailAddresses_Set_Input>;
  pk_columns: EmailAddresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailAddresses_ManyArgs = {
  updates: Array<EmailAddresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailEventsArgs = {
  _append?: InputMaybe<EmailEvents_Append_Input>;
  _delete_at_path?: InputMaybe<EmailEvents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<EmailEvents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<EmailEvents_Delete_Key_Input>;
  _prepend?: InputMaybe<EmailEvents_Prepend_Input>;
  _set?: InputMaybe<EmailEvents_Set_Input>;
  where: EmailEvents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailEvents_By_PkArgs = {
  _append?: InputMaybe<EmailEvents_Append_Input>;
  _delete_at_path?: InputMaybe<EmailEvents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<EmailEvents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<EmailEvents_Delete_Key_Input>;
  _prepend?: InputMaybe<EmailEvents_Prepend_Input>;
  _set?: InputMaybe<EmailEvents_Set_Input>;
  pk_columns: EmailEvents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailEvents_ManyArgs = {
  updates: Array<EmailEvents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailRecordsArgs = {
  _inc?: InputMaybe<EmailRecords_Inc_Input>;
  _set?: InputMaybe<EmailRecords_Set_Input>;
  where: EmailRecords_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailRecords_By_PkArgs = {
  _inc?: InputMaybe<EmailRecords_Inc_Input>;
  _set?: InputMaybe<EmailRecords_Set_Input>;
  pk_columns: EmailRecords_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailRecords_ManyArgs = {
  updates: Array<EmailRecords_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailSignaturesArgs = {
  _inc?: InputMaybe<EmailSignatures_Inc_Input>;
  _set?: InputMaybe<EmailSignatures_Set_Input>;
  where: EmailSignatures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailSignatures_By_PkArgs = {
  _inc?: InputMaybe<EmailSignatures_Inc_Input>;
  _set?: InputMaybe<EmailSignatures_Set_Input>;
  pk_columns: EmailSignatures_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailSignatures_ManyArgs = {
  updates: Array<EmailSignatures_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailSubFoldersArgs = {
  _inc?: InputMaybe<EmailSubFolders_Inc_Input>;
  _set?: InputMaybe<EmailSubFolders_Set_Input>;
  where: EmailSubFolders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailSubFolders_By_PkArgs = {
  _inc?: InputMaybe<EmailSubFolders_Inc_Input>;
  _set?: InputMaybe<EmailSubFolders_Set_Input>;
  pk_columns: EmailSubFolders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailSubFolders_ManyArgs = {
  updates: Array<EmailSubFolders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTypeEnumArgs = {
  _set?: InputMaybe<EmailTypeEnum_Set_Input>;
  where: EmailTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTypeEnum_By_PkArgs = {
  _set?: InputMaybe<EmailTypeEnum_Set_Input>;
  pk_columns: EmailTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTypeEnum_ManyArgs = {
  updates: Array<EmailTypeEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTypesArgs = {
  _inc?: InputMaybe<EmailTypes_Inc_Input>;
  _set?: InputMaybe<EmailTypes_Set_Input>;
  where: EmailTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTypes_By_PkArgs = {
  _inc?: InputMaybe<EmailTypes_Inc_Input>;
  _set?: InputMaybe<EmailTypes_Set_Input>;
  pk_columns: EmailTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTypes_ManyArgs = {
  updates: Array<EmailTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailsArgs = {
  _inc?: InputMaybe<Emails_Inc_Input>;
  _set?: InputMaybe<Emails_Set_Input>;
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_By_PkArgs = {
  _inc?: InputMaybe<Emails_Inc_Input>;
  _set?: InputMaybe<Emails_Set_Input>;
  pk_columns: Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_ManyArgs = {
  updates: Array<Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmploymentHireTypeArgs = {
  _set?: InputMaybe<EmploymentHireType_Set_Input>;
  where: EmploymentHireType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmploymentHireType_By_PkArgs = {
  _set?: InputMaybe<EmploymentHireType_Set_Input>;
  pk_columns: EmploymentHireType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmploymentHireType_ManyArgs = {
  updates: Array<EmploymentHireType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmploymentTypesArgs = {
  _set?: InputMaybe<EmploymentTypes_Set_Input>;
  where: EmploymentTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmploymentTypes_By_PkArgs = {
  _set?: InputMaybe<EmploymentTypes_Set_Input>;
  pk_columns: EmploymentTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmploymentTypes_ManyArgs = {
  updates: Array<EmploymentTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmploymentsArgs = {
  _append?: InputMaybe<Employments_Append_Input>;
  _delete_at_path?: InputMaybe<Employments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employments_Delete_Key_Input>;
  _inc?: InputMaybe<Employments_Inc_Input>;
  _prepend?: InputMaybe<Employments_Prepend_Input>;
  _set?: InputMaybe<Employments_Set_Input>;
  where: Employments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employments_By_PkArgs = {
  _append?: InputMaybe<Employments_Append_Input>;
  _delete_at_path?: InputMaybe<Employments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employments_Delete_Key_Input>;
  _inc?: InputMaybe<Employments_Inc_Input>;
  _prepend?: InputMaybe<Employments_Prepend_Input>;
  _set?: InputMaybe<Employments_Set_Input>;
  pk_columns: Employments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employments_ManyArgs = {
  updates: Array<Employments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EntitlementCategoriesArgs = {
  _set?: InputMaybe<EntitlementCategories_Set_Input>;
  where: EntitlementCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EntitlementCategories_By_PkArgs = {
  _set?: InputMaybe<EntitlementCategories_Set_Input>;
  pk_columns: EntitlementCategories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EntitlementCategories_ManyArgs = {
  updates: Array<EntitlementCategories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EntitlementsArgs = {
  _inc?: InputMaybe<Entitlements_Inc_Input>;
  _set?: InputMaybe<Entitlements_Set_Input>;
  where: Entitlements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entitlements_By_PkArgs = {
  _inc?: InputMaybe<Entitlements_Inc_Input>;
  _set?: InputMaybe<Entitlements_Set_Input>;
  pk_columns: Entitlements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Entitlements_ManyArgs = {
  updates: Array<Entitlements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EquationFormulaOptionArgs = {
  _inc?: InputMaybe<EquationFormulaOption_Inc_Input>;
  _set?: InputMaybe<EquationFormulaOption_Set_Input>;
  where: EquationFormulaOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EquationFormulaOption_By_PkArgs = {
  _inc?: InputMaybe<EquationFormulaOption_Inc_Input>;
  _set?: InputMaybe<EquationFormulaOption_Set_Input>;
  pk_columns: EquationFormulaOption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EquationFormulaOption_ManyArgs = {
  updates: Array<EquationFormulaOption_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EquationsArgs = {
  _append?: InputMaybe<Equations_Append_Input>;
  _delete_at_path?: InputMaybe<Equations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Equations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Equations_Delete_Key_Input>;
  _inc?: InputMaybe<Equations_Inc_Input>;
  _prepend?: InputMaybe<Equations_Prepend_Input>;
  _set?: InputMaybe<Equations_Set_Input>;
  where: Equations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Equations_By_PkArgs = {
  _append?: InputMaybe<Equations_Append_Input>;
  _delete_at_path?: InputMaybe<Equations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Equations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Equations_Delete_Key_Input>;
  _inc?: InputMaybe<Equations_Inc_Input>;
  _prepend?: InputMaybe<Equations_Prepend_Input>;
  _set?: InputMaybe<Equations_Set_Input>;
  pk_columns: Equations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Equations_ManyArgs = {
  updates: Array<Equations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Events_ManyArgs = {
  updates: Array<Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExpenseArgs = {
  _inc?: InputMaybe<Expense_Inc_Input>;
  _set?: InputMaybe<Expense_Set_Input>;
  where: Expense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Expense_By_PkArgs = {
  _inc?: InputMaybe<Expense_Inc_Input>;
  _set?: InputMaybe<Expense_Set_Input>;
  pk_columns: Expense_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Expense_ManyArgs = {
  updates: Array<Expense_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalIntegrationTypesArgs = {
  _set?: InputMaybe<ExternalIntegrationTypes_Set_Input>;
  where: ExternalIntegrationTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalIntegrationTypes_By_PkArgs = {
  _set?: InputMaybe<ExternalIntegrationTypes_Set_Input>;
  pk_columns: ExternalIntegrationTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalIntegrationTypes_ManyArgs = {
  updates: Array<ExternalIntegrationTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalIntegrationsArgs = {
  _append?: InputMaybe<ExternalIntegrations_Append_Input>;
  _delete_at_path?: InputMaybe<ExternalIntegrations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ExternalIntegrations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ExternalIntegrations_Delete_Key_Input>;
  _prepend?: InputMaybe<ExternalIntegrations_Prepend_Input>;
  _set?: InputMaybe<ExternalIntegrations_Set_Input>;
  where: ExternalIntegrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalIntegrations_By_PkArgs = {
  _append?: InputMaybe<ExternalIntegrations_Append_Input>;
  _delete_at_path?: InputMaybe<ExternalIntegrations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ExternalIntegrations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ExternalIntegrations_Delete_Key_Input>;
  _prepend?: InputMaybe<ExternalIntegrations_Prepend_Input>;
  _set?: InputMaybe<ExternalIntegrations_Set_Input>;
  pk_columns: ExternalIntegrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalIntegrations_ManyArgs = {
  updates: Array<ExternalIntegrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalJobBoardPublishingsArgs = {
  _inc?: InputMaybe<ExternalJobBoardPublishings_Inc_Input>;
  _set?: InputMaybe<ExternalJobBoardPublishings_Set_Input>;
  where: ExternalJobBoardPublishings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalJobBoardPublishings_By_PkArgs = {
  _inc?: InputMaybe<ExternalJobBoardPublishings_Inc_Input>;
  _set?: InputMaybe<ExternalJobBoardPublishings_Set_Input>;
  pk_columns: ExternalJobBoardPublishings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalJobBoardPublishings_ManyArgs = {
  updates: Array<ExternalJobBoardPublishings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalJobBoardsArgs = {
  _inc?: InputMaybe<ExternalJobBoards_Inc_Input>;
  _set?: InputMaybe<ExternalJobBoards_Set_Input>;
  where: ExternalJobBoards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalJobBoards_By_PkArgs = {
  _inc?: InputMaybe<ExternalJobBoards_Inc_Input>;
  _set?: InputMaybe<ExternalJobBoards_Set_Input>;
  pk_columns: ExternalJobBoards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalJobBoards_ManyArgs = {
  updates: Array<ExternalJobBoards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalSystemArgs = {
  _append?: InputMaybe<ExternalSystem_Append_Input>;
  _delete_at_path?: InputMaybe<ExternalSystem_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ExternalSystem_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ExternalSystem_Delete_Key_Input>;
  _prepend?: InputMaybe<ExternalSystem_Prepend_Input>;
  _set?: InputMaybe<ExternalSystem_Set_Input>;
  where: ExternalSystem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalSystemInstanceArgs = {
  _append?: InputMaybe<ExternalSystemInstance_Append_Input>;
  _delete_at_path?: InputMaybe<ExternalSystemInstance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ExternalSystemInstance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ExternalSystemInstance_Delete_Key_Input>;
  _inc?: InputMaybe<ExternalSystemInstance_Inc_Input>;
  _prepend?: InputMaybe<ExternalSystemInstance_Prepend_Input>;
  _set?: InputMaybe<ExternalSystemInstance_Set_Input>;
  where: ExternalSystemInstance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalSystemInstance_By_PkArgs = {
  _append?: InputMaybe<ExternalSystemInstance_Append_Input>;
  _delete_at_path?: InputMaybe<ExternalSystemInstance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ExternalSystemInstance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ExternalSystemInstance_Delete_Key_Input>;
  _inc?: InputMaybe<ExternalSystemInstance_Inc_Input>;
  _prepend?: InputMaybe<ExternalSystemInstance_Prepend_Input>;
  _set?: InputMaybe<ExternalSystemInstance_Set_Input>;
  pk_columns: ExternalSystemInstance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalSystemInstance_ManyArgs = {
  updates: Array<ExternalSystemInstance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalSystem_By_PkArgs = {
  _append?: InputMaybe<ExternalSystem_Append_Input>;
  _delete_at_path?: InputMaybe<ExternalSystem_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ExternalSystem_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ExternalSystem_Delete_Key_Input>;
  _prepend?: InputMaybe<ExternalSystem_Prepend_Input>;
  _set?: InputMaybe<ExternalSystem_Set_Input>;
  pk_columns: ExternalSystem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalSystem_ManyArgs = {
  updates: Array<ExternalSystem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeatureFlagsArgs = {
  _inc?: InputMaybe<FeatureFlags_Inc_Input>;
  _set?: InputMaybe<FeatureFlags_Set_Input>;
  where: FeatureFlags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FeatureFlags_By_PkArgs = {
  _inc?: InputMaybe<FeatureFlags_Inc_Input>;
  _set?: InputMaybe<FeatureFlags_Set_Input>;
  pk_columns: FeatureFlags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeatureFlags_ManyArgs = {
  updates: Array<FeatureFlags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FieldArgs = {
  _set?: InputMaybe<Field_Set_Input>;
  where: Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FieldDefArgs = {
  _append?: InputMaybe<FieldDef_Append_Input>;
  _delete_at_path?: InputMaybe<FieldDef_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<FieldDef_Delete_Elem_Input>;
  _delete_key?: InputMaybe<FieldDef_Delete_Key_Input>;
  _inc?: InputMaybe<FieldDef_Inc_Input>;
  _prepend?: InputMaybe<FieldDef_Prepend_Input>;
  _set?: InputMaybe<FieldDef_Set_Input>;
  where: FieldDef_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FieldDef_By_PkArgs = {
  _append?: InputMaybe<FieldDef_Append_Input>;
  _delete_at_path?: InputMaybe<FieldDef_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<FieldDef_Delete_Elem_Input>;
  _delete_key?: InputMaybe<FieldDef_Delete_Key_Input>;
  _inc?: InputMaybe<FieldDef_Inc_Input>;
  _prepend?: InputMaybe<FieldDef_Prepend_Input>;
  _set?: InputMaybe<FieldDef_Set_Input>;
  pk_columns: FieldDef_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FieldDef_ManyArgs = {
  updates: Array<FieldDef_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FieldPicklistValuesArgs = {
  _inc?: InputMaybe<FieldPicklistValues_Inc_Input>;
  _set?: InputMaybe<FieldPicklistValues_Set_Input>;
  where: FieldPicklistValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FieldPicklistValues_By_PkArgs = {
  _inc?: InputMaybe<FieldPicklistValues_Inc_Input>;
  _set?: InputMaybe<FieldPicklistValues_Set_Input>;
  pk_columns: FieldPicklistValues_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FieldPicklistValues_ManyArgs = {
  updates: Array<FieldPicklistValues_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FieldTypeDefArgs = {
  _set?: InputMaybe<FieldTypeDef_Set_Input>;
  where: FieldTypeDef_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FieldTypeDef_By_PkArgs = {
  _set?: InputMaybe<FieldTypeDef_Set_Input>;
  pk_columns: FieldTypeDef_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FieldTypeDef_ManyArgs = {
  updates: Array<FieldTypeDef_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_By_PkArgs = {
  _set?: InputMaybe<Field_Set_Input>;
  pk_columns: Field_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_ManyArgs = {
  updates: Array<Field_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FileTagsArgs = {
  _inc?: InputMaybe<FileTags_Inc_Input>;
  _set?: InputMaybe<FileTags_Set_Input>;
  where: FileTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FileTags_By_PkArgs = {
  _inc?: InputMaybe<FileTags_Inc_Input>;
  _set?: InputMaybe<FileTags_Set_Input>;
  pk_columns: FileTags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FileTags_ManyArgs = {
  updates: Array<FileTags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _inc?: InputMaybe<Files_Inc_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _inc?: InputMaybe<Files_Inc_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FormInputsArgs = {
  _inc?: InputMaybe<FormInputs_Inc_Input>;
  _set?: InputMaybe<FormInputs_Set_Input>;
  where: FormInputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FormInputs_By_PkArgs = {
  _inc?: InputMaybe<FormInputs_Inc_Input>;
  _set?: InputMaybe<FormInputs_Set_Input>;
  pk_columns: FormInputs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormInputs_ManyArgs = {
  updates: Array<FormInputs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FormSelectOptionsArgs = {
  _inc?: InputMaybe<FormSelectOptions_Inc_Input>;
  _set?: InputMaybe<FormSelectOptions_Set_Input>;
  where: FormSelectOptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FormSelectOptions_By_PkArgs = {
  _inc?: InputMaybe<FormSelectOptions_Inc_Input>;
  _set?: InputMaybe<FormSelectOptions_Set_Input>;
  pk_columns: FormSelectOptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormSelectOptions_ManyArgs = {
  updates: Array<FormSelectOptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FormulaOptionsArgs = {
  _inc?: InputMaybe<FormulaOptions_Inc_Input>;
  _set?: InputMaybe<FormulaOptions_Set_Input>;
  where: FormulaOptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FormulaOptions_By_PkArgs = {
  _inc?: InputMaybe<FormulaOptions_Inc_Input>;
  _set?: InputMaybe<FormulaOptions_Set_Input>;
  pk_columns: FormulaOptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormulaOptions_ManyArgs = {
  updates: Array<FormulaOptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GenderEnumArgs = {
  _set?: InputMaybe<GenderEnum_Set_Input>;
  where: GenderEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GenderEnum_By_PkArgs = {
  _set?: InputMaybe<GenderEnum_Set_Input>;
  pk_columns: GenderEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GenderEnum_ManyArgs = {
  updates: Array<GenderEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GeocodingHistoryArgs = {
  _append?: InputMaybe<GeocodingHistory_Append_Input>;
  _delete_at_path?: InputMaybe<GeocodingHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GeocodingHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GeocodingHistory_Delete_Key_Input>;
  _inc?: InputMaybe<GeocodingHistory_Inc_Input>;
  _prepend?: InputMaybe<GeocodingHistory_Prepend_Input>;
  _set?: InputMaybe<GeocodingHistory_Set_Input>;
  where: GeocodingHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GeocodingHistory_By_PkArgs = {
  _append?: InputMaybe<GeocodingHistory_Append_Input>;
  _delete_at_path?: InputMaybe<GeocodingHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GeocodingHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GeocodingHistory_Delete_Key_Input>;
  _inc?: InputMaybe<GeocodingHistory_Inc_Input>;
  _prepend?: InputMaybe<GeocodingHistory_Prepend_Input>;
  _set?: InputMaybe<GeocodingHistory_Set_Input>;
  pk_columns: GeocodingHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GeocodingHistory_ManyArgs = {
  updates: Array<GeocodingHistory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoalsArgs = {
  _inc?: InputMaybe<Goals_Inc_Input>;
  _set?: InputMaybe<Goals_Set_Input>;
  where: Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Goals_By_PkArgs = {
  _inc?: InputMaybe<Goals_Inc_Input>;
  _set?: InputMaybe<Goals_Set_Input>;
  pk_columns: Goals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Goals_ManyArgs = {
  updates: Array<Goals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleSheetCalcTemplatesArgs = {
  _append?: InputMaybe<GoogleSheetCalcTemplates_Append_Input>;
  _delete_at_path?: InputMaybe<GoogleSheetCalcTemplates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GoogleSheetCalcTemplates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GoogleSheetCalcTemplates_Delete_Key_Input>;
  _inc?: InputMaybe<GoogleSheetCalcTemplates_Inc_Input>;
  _prepend?: InputMaybe<GoogleSheetCalcTemplates_Prepend_Input>;
  _set?: InputMaybe<GoogleSheetCalcTemplates_Set_Input>;
  where: GoogleSheetCalcTemplates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleSheetCalcTemplates_By_PkArgs = {
  _append?: InputMaybe<GoogleSheetCalcTemplates_Append_Input>;
  _delete_at_path?: InputMaybe<GoogleSheetCalcTemplates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GoogleSheetCalcTemplates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GoogleSheetCalcTemplates_Delete_Key_Input>;
  _inc?: InputMaybe<GoogleSheetCalcTemplates_Inc_Input>;
  _prepend?: InputMaybe<GoogleSheetCalcTemplates_Prepend_Input>;
  _set?: InputMaybe<GoogleSheetCalcTemplates_Set_Input>;
  pk_columns: GoogleSheetCalcTemplates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleSheetCalcTemplates_ManyArgs = {
  updates: Array<GoogleSheetCalcTemplates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleSheetCalculationsArgs = {
  _append?: InputMaybe<GoogleSheetCalculations_Append_Input>;
  _delete_at_path?: InputMaybe<GoogleSheetCalculations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GoogleSheetCalculations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GoogleSheetCalculations_Delete_Key_Input>;
  _inc?: InputMaybe<GoogleSheetCalculations_Inc_Input>;
  _prepend?: InputMaybe<GoogleSheetCalculations_Prepend_Input>;
  _set?: InputMaybe<GoogleSheetCalculations_Set_Input>;
  where: GoogleSheetCalculations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleSheetCalculations_By_PkArgs = {
  _append?: InputMaybe<GoogleSheetCalculations_Append_Input>;
  _delete_at_path?: InputMaybe<GoogleSheetCalculations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<GoogleSheetCalculations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<GoogleSheetCalculations_Delete_Key_Input>;
  _inc?: InputMaybe<GoogleSheetCalculations_Inc_Input>;
  _prepend?: InputMaybe<GoogleSheetCalculations_Prepend_Input>;
  _set?: InputMaybe<GoogleSheetCalculations_Set_Input>;
  pk_columns: GoogleSheetCalculations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleSheetCalculations_ManyArgs = {
  updates: Array<GoogleSheetCalculations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GroupJobsArgs = {
  _inc?: InputMaybe<GroupJobs_Inc_Input>;
  _set?: InputMaybe<GroupJobs_Set_Input>;
  where: GroupJobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GroupJobs_By_PkArgs = {
  _inc?: InputMaybe<GroupJobs_Inc_Input>;
  _set?: InputMaybe<GroupJobs_Set_Input>;
  pk_columns: GroupJobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GroupJobs_ManyArgs = {
  updates: Array<GroupJobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _inc?: InputMaybe<Groups_Inc_Input>;
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _inc?: InputMaybe<Groups_Inc_Input>;
  _set?: InputMaybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HighestStagesArgs = {
  _inc?: InputMaybe<HighestStages_Inc_Input>;
  _set?: InputMaybe<HighestStages_Set_Input>;
  where: HighestStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HighestStages_By_PkArgs = {
  _inc?: InputMaybe<HighestStages_Inc_Input>;
  _set?: InputMaybe<HighestStages_Set_Input>;
  pk_columns: HighestStages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HighestStages_ManyArgs = {
  updates: Array<HighestStages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HireTypesEnumArgs = {
  _set?: InputMaybe<HireTypesEnum_Set_Input>;
  where: HireTypesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HireTypesEnum_By_PkArgs = {
  _set?: InputMaybe<HireTypesEnum_Set_Input>;
  pk_columns: HireTypesEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HireTypesEnum_ManyArgs = {
  updates: Array<HireTypesEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ItRequestContractEmailsArgs = {
  _inc?: InputMaybe<ItRequestContractEmails_Inc_Input>;
  _set?: InputMaybe<ItRequestContractEmails_Set_Input>;
  where: ItRequestContractEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ItRequestContractEmails_By_PkArgs = {
  _inc?: InputMaybe<ItRequestContractEmails_Inc_Input>;
  _set?: InputMaybe<ItRequestContractEmails_Set_Input>;
  pk_columns: ItRequestContractEmails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ItRequestContractEmails_ManyArgs = {
  updates: Array<ItRequestContractEmails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitiesArgs = {
  _append?: InputMaybe<Identities_Append_Input>;
  _delete_at_path?: InputMaybe<Identities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Identities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Identities_Delete_Key_Input>;
  _inc?: InputMaybe<Identities_Inc_Input>;
  _prepend?: InputMaybe<Identities_Prepend_Input>;
  _set?: InputMaybe<Identities_Set_Input>;
  where: Identities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitiesOverlaysLinksArgs = {
  _inc?: InputMaybe<IdentitiesOverlaysLinks_Inc_Input>;
  _set?: InputMaybe<IdentitiesOverlaysLinks_Set_Input>;
  where: IdentitiesOverlaysLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitiesOverlaysLinks_By_PkArgs = {
  _inc?: InputMaybe<IdentitiesOverlaysLinks_Inc_Input>;
  _set?: InputMaybe<IdentitiesOverlaysLinks_Set_Input>;
  pk_columns: IdentitiesOverlaysLinks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitiesOverlaysLinks_ManyArgs = {
  updates: Array<IdentitiesOverlaysLinks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Identities_By_PkArgs = {
  _append?: InputMaybe<Identities_Append_Input>;
  _delete_at_path?: InputMaybe<Identities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Identities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Identities_Delete_Key_Input>;
  _inc?: InputMaybe<Identities_Inc_Input>;
  _prepend?: InputMaybe<Identities_Prepend_Input>;
  _set?: InputMaybe<Identities_Set_Input>;
  pk_columns: Identities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Identities_ManyArgs = {
  updates: Array<Identities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityAssignmentsArgs = {
  _inc?: InputMaybe<IdentityAssignments_Inc_Input>;
  _set?: InputMaybe<IdentityAssignments_Set_Input>;
  where: IdentityAssignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityAssignments_By_PkArgs = {
  _inc?: InputMaybe<IdentityAssignments_Inc_Input>;
  _set?: InputMaybe<IdentityAssignments_Set_Input>;
  pk_columns: IdentityAssignments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityAssignments_ManyArgs = {
  updates: Array<IdentityAssignments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityCommentsArgs = {
  _inc?: InputMaybe<IdentityComments_Inc_Input>;
  _set?: InputMaybe<IdentityComments_Set_Input>;
  where: IdentityComments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityComments_By_PkArgs = {
  _inc?: InputMaybe<IdentityComments_Inc_Input>;
  _set?: InputMaybe<IdentityComments_Set_Input>;
  pk_columns: IdentityComments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityComments_ManyArgs = {
  updates: Array<IdentityComments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityContractRelationshipArgs = {
  _inc?: InputMaybe<IdentityContractRelationship_Inc_Input>;
  _set?: InputMaybe<IdentityContractRelationship_Set_Input>;
  where: IdentityContractRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityContractRelationship_By_PkArgs = {
  _inc?: InputMaybe<IdentityContractRelationship_Inc_Input>;
  _set?: InputMaybe<IdentityContractRelationship_Set_Input>;
  pk_columns: IdentityContractRelationship_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityContractRelationship_ManyArgs = {
  updates: Array<IdentityContractRelationship_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityDepartmentRelationshipArgs = {
  _inc?: InputMaybe<IdentityDepartmentRelationship_Inc_Input>;
  _set?: InputMaybe<IdentityDepartmentRelationship_Set_Input>;
  where: IdentityDepartmentRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityDepartmentRelationship_By_PkArgs = {
  _inc?: InputMaybe<IdentityDepartmentRelationship_Inc_Input>;
  _set?: InputMaybe<IdentityDepartmentRelationship_Set_Input>;
  pk_columns: IdentityDepartmentRelationship_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityDepartmentRelationship_ManyArgs = {
  updates: Array<IdentityDepartmentRelationship_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityGroupsArgs = {
  _inc?: InputMaybe<IdentityGroups_Inc_Input>;
  _set?: InputMaybe<IdentityGroups_Set_Input>;
  where: IdentityGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityGroups_By_PkArgs = {
  _inc?: InputMaybe<IdentityGroups_Inc_Input>;
  _set?: InputMaybe<IdentityGroups_Set_Input>;
  pk_columns: IdentityGroups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityGroups_ManyArgs = {
  updates: Array<IdentityGroups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityJobRelationshipArgs = {
  _inc?: InputMaybe<IdentityJobRelationship_Inc_Input>;
  _set?: InputMaybe<IdentityJobRelationship_Set_Input>;
  where: IdentityJobRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityJobRelationship_By_PkArgs = {
  _inc?: InputMaybe<IdentityJobRelationship_Inc_Input>;
  _set?: InputMaybe<IdentityJobRelationship_Set_Input>;
  pk_columns: IdentityJobRelationship_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityJobRelationship_ManyArgs = {
  updates: Array<IdentityJobRelationship_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityPartnerGroupDataArgs = {
  _append?: InputMaybe<IdentityPartnerGroupData_Append_Input>;
  _delete_at_path?: InputMaybe<IdentityPartnerGroupData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<IdentityPartnerGroupData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<IdentityPartnerGroupData_Delete_Key_Input>;
  _inc?: InputMaybe<IdentityPartnerGroupData_Inc_Input>;
  _prepend?: InputMaybe<IdentityPartnerGroupData_Prepend_Input>;
  _set?: InputMaybe<IdentityPartnerGroupData_Set_Input>;
  where: IdentityPartnerGroupData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityPartnerGroupData_By_PkArgs = {
  _append?: InputMaybe<IdentityPartnerGroupData_Append_Input>;
  _delete_at_path?: InputMaybe<IdentityPartnerGroupData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<IdentityPartnerGroupData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<IdentityPartnerGroupData_Delete_Key_Input>;
  _inc?: InputMaybe<IdentityPartnerGroupData_Inc_Input>;
  _prepend?: InputMaybe<IdentityPartnerGroupData_Prepend_Input>;
  _set?: InputMaybe<IdentityPartnerGroupData_Set_Input>;
  pk_columns: IdentityPartnerGroupData_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityPartnerGroupData_ManyArgs = {
  updates: Array<IdentityPartnerGroupData_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityRelationshipTypeArgs = {
  _set?: InputMaybe<IdentityRelationshipType_Set_Input>;
  where: IdentityRelationshipType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityRelationshipType_By_PkArgs = {
  _set?: InputMaybe<IdentityRelationshipType_Set_Input>;
  pk_columns: IdentityRelationshipType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityRelationshipType_ManyArgs = {
  updates: Array<IdentityRelationshipType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityRolesArgs = {
  _inc?: InputMaybe<IdentityRoles_Inc_Input>;
  _set?: InputMaybe<IdentityRoles_Set_Input>;
  where: IdentityRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityRoles_ManyArgs = {
  updates: Array<IdentityRoles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitySubTypeEmailsArgs = {
  _inc?: InputMaybe<IdentitySubTypeEmails_Inc_Input>;
  _set?: InputMaybe<IdentitySubTypeEmails_Set_Input>;
  where: IdentitySubTypeEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitySubTypeEmails_By_PkArgs = {
  _inc?: InputMaybe<IdentitySubTypeEmails_Inc_Input>;
  _set?: InputMaybe<IdentitySubTypeEmails_Set_Input>;
  pk_columns: IdentitySubTypeEmails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitySubTypeEmails_ManyArgs = {
  updates: Array<IdentitySubTypeEmails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitySubTypesArgs = {
  _inc?: InputMaybe<IdentitySubTypes_Inc_Input>;
  _set?: InputMaybe<IdentitySubTypes_Set_Input>;
  where: IdentitySubTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitySubTypes_By_PkArgs = {
  _inc?: InputMaybe<IdentitySubTypes_Inc_Input>;
  _set?: InputMaybe<IdentitySubTypes_Set_Input>;
  pk_columns: IdentitySubTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentitySubTypes_ManyArgs = {
  updates: Array<IdentitySubTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTagsArgs = {
  _inc?: InputMaybe<IdentityTags_Inc_Input>;
  _set?: InputMaybe<IdentityTags_Set_Input>;
  where: IdentityTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTags_By_PkArgs = {
  _inc?: InputMaybe<IdentityTags_Inc_Input>;
  _set?: InputMaybe<IdentityTags_Set_Input>;
  pk_columns: IdentityTags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTags_ManyArgs = {
  updates: Array<IdentityTags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTokensArgs = {
  _append?: InputMaybe<IdentityTokens_Append_Input>;
  _delete_at_path?: InputMaybe<IdentityTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<IdentityTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<IdentityTokens_Delete_Key_Input>;
  _inc?: InputMaybe<IdentityTokens_Inc_Input>;
  _prepend?: InputMaybe<IdentityTokens_Prepend_Input>;
  _set?: InputMaybe<IdentityTokens_Set_Input>;
  where: IdentityTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTokens_By_PkArgs = {
  _append?: InputMaybe<IdentityTokens_Append_Input>;
  _delete_at_path?: InputMaybe<IdentityTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<IdentityTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<IdentityTokens_Delete_Key_Input>;
  _inc?: InputMaybe<IdentityTokens_Inc_Input>;
  _prepend?: InputMaybe<IdentityTokens_Prepend_Input>;
  _set?: InputMaybe<IdentityTokens_Set_Input>;
  pk_columns: IdentityTokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTokens_ManyArgs = {
  updates: Array<IdentityTokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTypesArgs = {
  _inc?: InputMaybe<IdentityTypes_Inc_Input>;
  _set?: InputMaybe<IdentityTypes_Set_Input>;
  where: IdentityTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTypes_By_PkArgs = {
  _inc?: InputMaybe<IdentityTypes_Inc_Input>;
  _set?: InputMaybe<IdentityTypes_Set_Input>;
  pk_columns: IdentityTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityTypes_ManyArgs = {
  updates: Array<IdentityTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityUserAccessGroupArgs = {
  _inc?: InputMaybe<IdentityUserAccessGroup_Inc_Input>;
  _set?: InputMaybe<IdentityUserAccessGroup_Set_Input>;
  where: IdentityUserAccessGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityUserAccessGroup_By_PkArgs = {
  _inc?: InputMaybe<IdentityUserAccessGroup_Inc_Input>;
  _set?: InputMaybe<IdentityUserAccessGroup_Set_Input>;
  pk_columns: IdentityUserAccessGroup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IdentityUserAccessGroup_ManyArgs = {
  updates: Array<IdentityUserAccessGroup_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImagesArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Images_By_PkArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  pk_columns: Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Images_ManyArgs = {
  updates: Array<Images_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InputTypesArgs = {
  _set?: InputMaybe<InputTypes_Set_Input>;
  where: InputTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InputTypes_By_PkArgs = {
  _set?: InputMaybe<InputTypes_Set_Input>;
  pk_columns: InputTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InputTypes_ManyArgs = {
  updates: Array<InputTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InternalJobCategoriesArgs = {
  _inc?: InputMaybe<InternalJobCategories_Inc_Input>;
  _set?: InputMaybe<InternalJobCategories_Set_Input>;
  where: InternalJobCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InternalJobCategories_By_PkArgs = {
  _inc?: InputMaybe<InternalJobCategories_Inc_Input>;
  _set?: InputMaybe<InternalJobCategories_Set_Input>;
  pk_columns: InternalJobCategories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InternalJobCategories_ManyArgs = {
  updates: Array<InternalJobCategories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InterviewsArgs = {
  _append?: InputMaybe<Interviews_Append_Input>;
  _delete_at_path?: InputMaybe<Interviews_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Interviews_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Interviews_Delete_Key_Input>;
  _inc?: InputMaybe<Interviews_Inc_Input>;
  _prepend?: InputMaybe<Interviews_Prepend_Input>;
  _set?: InputMaybe<Interviews_Set_Input>;
  where: Interviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Interviews_By_PkArgs = {
  _append?: InputMaybe<Interviews_Append_Input>;
  _delete_at_path?: InputMaybe<Interviews_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Interviews_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Interviews_Delete_Key_Input>;
  _inc?: InputMaybe<Interviews_Inc_Input>;
  _prepend?: InputMaybe<Interviews_Prepend_Input>;
  _set?: InputMaybe<Interviews_Set_Input>;
  pk_columns: Interviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Interviews_ManyArgs = {
  updates: Array<Interviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobAvailabilitiesArgs = {
  _inc?: InputMaybe<JobAvailabilities_Inc_Input>;
  _set?: InputMaybe<JobAvailabilities_Set_Input>;
  where: JobAvailabilities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobAvailabilities_By_PkArgs = {
  _inc?: InputMaybe<JobAvailabilities_Inc_Input>;
  _set?: InputMaybe<JobAvailabilities_Set_Input>;
  pk_columns: JobAvailabilities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobAvailabilities_ManyArgs = {
  updates: Array<JobAvailabilities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobCommentsArgs = {
  _inc?: InputMaybe<JobComments_Inc_Input>;
  _set?: InputMaybe<JobComments_Set_Input>;
  where: JobComments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobComments_By_PkArgs = {
  _inc?: InputMaybe<JobComments_Inc_Input>;
  _set?: InputMaybe<JobComments_Set_Input>;
  pk_columns: JobComments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobComments_ManyArgs = {
  updates: Array<JobComments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobFilesArgs = {
  _inc?: InputMaybe<JobFiles_Inc_Input>;
  _set?: InputMaybe<JobFiles_Set_Input>;
  where: JobFiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobFiles_By_PkArgs = {
  _inc?: InputMaybe<JobFiles_Inc_Input>;
  _set?: InputMaybe<JobFiles_Set_Input>;
  pk_columns: JobFiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobFiles_ManyArgs = {
  updates: Array<JobFiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobFundingStatusEnumArgs = {
  _set?: InputMaybe<JobFundingStatusEnum_Set_Input>;
  where: JobFundingStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobFundingStatusEnum_By_PkArgs = {
  _set?: InputMaybe<JobFundingStatusEnum_Set_Input>;
  pk_columns: JobFundingStatusEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobFundingStatusEnum_ManyArgs = {
  updates: Array<JobFundingStatusEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobHireTypesEnumArgs = {
  _set?: InputMaybe<JobHireTypesEnum_Set_Input>;
  where: JobHireTypesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobHireTypesEnum_By_PkArgs = {
  _set?: InputMaybe<JobHireTypesEnum_Set_Input>;
  pk_columns: JobHireTypesEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobHireTypesEnum_ManyArgs = {
  updates: Array<JobHireTypesEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobPositionsArgs = {
  _inc?: InputMaybe<JobPositions_Inc_Input>;
  _set?: InputMaybe<JobPositions_Set_Input>;
  where: JobPositions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobPositions_By_PkArgs = {
  _inc?: InputMaybe<JobPositions_Inc_Input>;
  _set?: InputMaybe<JobPositions_Set_Input>;
  pk_columns: JobPositions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobPositions_ManyArgs = {
  updates: Array<JobPositions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobStatusesArgs = {
  _inc?: InputMaybe<JobStatuses_Inc_Input>;
  _set?: InputMaybe<JobStatuses_Set_Input>;
  where: JobStatuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobStatuses_By_PkArgs = {
  _inc?: InputMaybe<JobStatuses_Inc_Input>;
  _set?: InputMaybe<JobStatuses_Set_Input>;
  pk_columns: JobStatuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobStatuses_ManyArgs = {
  updates: Array<JobStatuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobsArgs = {
  _append?: InputMaybe<Jobs_Append_Input>;
  _delete_at_path?: InputMaybe<Jobs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Jobs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Jobs_Delete_Key_Input>;
  _inc?: InputMaybe<Jobs_Inc_Input>;
  _prepend?: InputMaybe<Jobs_Prepend_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  where: Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_By_PkArgs = {
  _append?: InputMaybe<Jobs_Append_Input>;
  _delete_at_path?: InputMaybe<Jobs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Jobs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Jobs_Delete_Key_Input>;
  _inc?: InputMaybe<Jobs_Inc_Input>;
  _prepend?: InputMaybe<Jobs_Prepend_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  pk_columns: Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_ManyArgs = {
  updates: Array<Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MagicLinksArgs = {
  _inc?: InputMaybe<MagicLinks_Inc_Input>;
  _set?: InputMaybe<MagicLinks_Set_Input>;
  where: MagicLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MagicLinks_By_PkArgs = {
  _inc?: InputMaybe<MagicLinks_Inc_Input>;
  _set?: InputMaybe<MagicLinks_Set_Input>;
  pk_columns: MagicLinks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MagicLinks_ManyArgs = {
  updates: Array<MagicLinks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MigrationDataArgs = {
  _append?: InputMaybe<MigrationData_Append_Input>;
  _delete_at_path?: InputMaybe<MigrationData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<MigrationData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<MigrationData_Delete_Key_Input>;
  _inc?: InputMaybe<MigrationData_Inc_Input>;
  _prepend?: InputMaybe<MigrationData_Prepend_Input>;
  _set?: InputMaybe<MigrationData_Set_Input>;
  where: MigrationData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MigrationData_By_PkArgs = {
  _append?: InputMaybe<MigrationData_Append_Input>;
  _delete_at_path?: InputMaybe<MigrationData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<MigrationData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<MigrationData_Delete_Key_Input>;
  _inc?: InputMaybe<MigrationData_Inc_Input>;
  _prepend?: InputMaybe<MigrationData_Prepend_Input>;
  _set?: InputMaybe<MigrationData_Set_Input>;
  pk_columns: MigrationData_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MigrationData_ManyArgs = {
  updates: Array<MigrationData_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ObjectZooArgs = {
  _append?: InputMaybe<ObjectZoo_Append_Input>;
  _delete_at_path?: InputMaybe<ObjectZoo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ObjectZoo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ObjectZoo_Delete_Key_Input>;
  _inc?: InputMaybe<ObjectZoo_Inc_Input>;
  _prepend?: InputMaybe<ObjectZoo_Prepend_Input>;
  _set?: InputMaybe<ObjectZoo_Set_Input>;
  where: ObjectZoo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ObjectZoo_By_PkArgs = {
  _append?: InputMaybe<ObjectZoo_Append_Input>;
  _delete_at_path?: InputMaybe<ObjectZoo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ObjectZoo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ObjectZoo_Delete_Key_Input>;
  _inc?: InputMaybe<ObjectZoo_Inc_Input>;
  _prepend?: InputMaybe<ObjectZoo_Prepend_Input>;
  _set?: InputMaybe<ObjectZoo_Set_Input>;
  pk_columns: ObjectZoo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ObjectZoo_ManyArgs = {
  updates: Array<ObjectZoo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OverlayArgs = {
  _set?: InputMaybe<Overlay_Set_Input>;
  where: Overlay_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OverlayDefinitionArgs = {
  _append?: InputMaybe<OverlayDefinition_Append_Input>;
  _delete_at_path?: InputMaybe<OverlayDefinition_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<OverlayDefinition_Delete_Elem_Input>;
  _delete_key?: InputMaybe<OverlayDefinition_Delete_Key_Input>;
  _prepend?: InputMaybe<OverlayDefinition_Prepend_Input>;
  _set?: InputMaybe<OverlayDefinition_Set_Input>;
  where: OverlayDefinition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OverlayDefinition_By_PkArgs = {
  _append?: InputMaybe<OverlayDefinition_Append_Input>;
  _delete_at_path?: InputMaybe<OverlayDefinition_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<OverlayDefinition_Delete_Elem_Input>;
  _delete_key?: InputMaybe<OverlayDefinition_Delete_Key_Input>;
  _prepend?: InputMaybe<OverlayDefinition_Prepend_Input>;
  _set?: InputMaybe<OverlayDefinition_Set_Input>;
  pk_columns: OverlayDefinition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OverlayDefinition_ManyArgs = {
  updates: Array<OverlayDefinition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Overlay_By_PkArgs = {
  _set?: InputMaybe<Overlay_Set_Input>;
  pk_columns: Overlay_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Overlay_ManyArgs = {
  updates: Array<Overlay_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PhoneNumbersArgs = {
  _append?: InputMaybe<PhoneNumbers_Append_Input>;
  _delete_at_path?: InputMaybe<PhoneNumbers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<PhoneNumbers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<PhoneNumbers_Delete_Key_Input>;
  _prepend?: InputMaybe<PhoneNumbers_Prepend_Input>;
  _set?: InputMaybe<PhoneNumbers_Set_Input>;
  where: PhoneNumbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PhoneNumbers_By_PkArgs = {
  _append?: InputMaybe<PhoneNumbers_Append_Input>;
  _delete_at_path?: InputMaybe<PhoneNumbers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<PhoneNumbers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<PhoneNumbers_Delete_Key_Input>;
  _prepend?: InputMaybe<PhoneNumbers_Prepend_Input>;
  _set?: InputMaybe<PhoneNumbers_Set_Input>;
  pk_columns: PhoneNumbers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PhoneNumbers_ManyArgs = {
  updates: Array<PhoneNumbers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PicklistValuesArgs = {
  _set?: InputMaybe<PicklistValues_Set_Input>;
  where: PicklistValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PicklistValues_By_PkArgs = {
  _set?: InputMaybe<PicklistValues_Set_Input>;
  pk_columns: PicklistValues_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PicklistValues_ManyArgs = {
  updates: Array<PicklistValues_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PicklistsArgs = {
  _append?: InputMaybe<Picklists_Append_Input>;
  _delete_at_path?: InputMaybe<Picklists_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Picklists_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Picklists_Delete_Key_Input>;
  _prepend?: InputMaybe<Picklists_Prepend_Input>;
  _set?: InputMaybe<Picklists_Set_Input>;
  where: Picklists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Picklists_By_PkArgs = {
  _append?: InputMaybe<Picklists_Append_Input>;
  _delete_at_path?: InputMaybe<Picklists_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Picklists_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Picklists_Delete_Key_Input>;
  _prepend?: InputMaybe<Picklists_Prepend_Input>;
  _set?: InputMaybe<Picklists_Set_Input>;
  pk_columns: Picklists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Picklists_ManyArgs = {
  updates: Array<Picklists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductUpdatesArgs = {
  _inc?: InputMaybe<ProductUpdates_Inc_Input>;
  _set?: InputMaybe<ProductUpdates_Set_Input>;
  where: ProductUpdates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductUpdates_By_PkArgs = {
  _inc?: InputMaybe<ProductUpdates_Inc_Input>;
  _set?: InputMaybe<ProductUpdates_Set_Input>;
  pk_columns: ProductUpdates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductUpdates_ManyArgs = {
  updates: Array<ProductUpdates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireContactTemplateVariablesArgs = {
  _set?: InputMaybe<QuestionnaireContactTemplateVariables_Set_Input>;
  where: QuestionnaireContactTemplateVariables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireContactTemplateVariables_By_PkArgs = {
  _set?: InputMaybe<QuestionnaireContactTemplateVariables_Set_Input>;
  pk_columns: QuestionnaireContactTemplateVariables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireContactTemplateVariables_ManyArgs = {
  updates: Array<QuestionnaireContactTemplateVariables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireLinksArgs = {
  _inc?: InputMaybe<QuestionnaireLinks_Inc_Input>;
  _set?: InputMaybe<QuestionnaireLinks_Set_Input>;
  where: QuestionnaireLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireLinks_By_PkArgs = {
  _inc?: InputMaybe<QuestionnaireLinks_Inc_Input>;
  _set?: InputMaybe<QuestionnaireLinks_Set_Input>;
  pk_columns: QuestionnaireLinks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireLinks_ManyArgs = {
  updates: Array<QuestionnaireLinks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireResponsesArgs = {
  _append?: InputMaybe<QuestionnaireResponses_Append_Input>;
  _delete_at_path?: InputMaybe<QuestionnaireResponses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<QuestionnaireResponses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<QuestionnaireResponses_Delete_Key_Input>;
  _inc?: InputMaybe<QuestionnaireResponses_Inc_Input>;
  _prepend?: InputMaybe<QuestionnaireResponses_Prepend_Input>;
  _set?: InputMaybe<QuestionnaireResponses_Set_Input>;
  where: QuestionnaireResponses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireResponses_By_PkArgs = {
  _append?: InputMaybe<QuestionnaireResponses_Append_Input>;
  _delete_at_path?: InputMaybe<QuestionnaireResponses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<QuestionnaireResponses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<QuestionnaireResponses_Delete_Key_Input>;
  _inc?: InputMaybe<QuestionnaireResponses_Inc_Input>;
  _prepend?: InputMaybe<QuestionnaireResponses_Prepend_Input>;
  _set?: InputMaybe<QuestionnaireResponses_Set_Input>;
  pk_columns: QuestionnaireResponses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnaireResponses_ManyArgs = {
  updates: Array<QuestionnaireResponses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnairesArgs = {
  _append?: InputMaybe<Questionnaires_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaires_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaires_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaires_Delete_Key_Input>;
  _inc?: InputMaybe<Questionnaires_Inc_Input>;
  _prepend?: InputMaybe<Questionnaires_Prepend_Input>;
  _set?: InputMaybe<Questionnaires_Set_Input>;
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_By_PkArgs = {
  _append?: InputMaybe<Questionnaires_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaires_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaires_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaires_Delete_Key_Input>;
  _inc?: InputMaybe<Questionnaires_Inc_Input>;
  _prepend?: InputMaybe<Questionnaires_Prepend_Input>;
  _set?: InputMaybe<Questionnaires_Set_Input>;
  pk_columns: Questionnaires_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_ManyArgs = {
  updates: Array<Questionnaires_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RrWebRecordsArgs = {
  _append?: InputMaybe<RrWebRecords_Append_Input>;
  _delete_at_path?: InputMaybe<RrWebRecords_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<RrWebRecords_Delete_Elem_Input>;
  _delete_key?: InputMaybe<RrWebRecords_Delete_Key_Input>;
  _inc?: InputMaybe<RrWebRecords_Inc_Input>;
  _prepend?: InputMaybe<RrWebRecords_Prepend_Input>;
  _set?: InputMaybe<RrWebRecords_Set_Input>;
  where: RrWebRecords_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RrWebRecords_By_PkArgs = {
  _append?: InputMaybe<RrWebRecords_Append_Input>;
  _delete_at_path?: InputMaybe<RrWebRecords_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<RrWebRecords_Delete_Elem_Input>;
  _delete_key?: InputMaybe<RrWebRecords_Delete_Key_Input>;
  _inc?: InputMaybe<RrWebRecords_Inc_Input>;
  _prepend?: InputMaybe<RrWebRecords_Prepend_Input>;
  _set?: InputMaybe<RrWebRecords_Set_Input>;
  pk_columns: RrWebRecords_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RrWebRecords_ManyArgs = {
  updates: Array<RrWebRecords_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RaceEnumArgs = {
  _set?: InputMaybe<RaceEnum_Set_Input>;
  where: RaceEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RaceEnum_By_PkArgs = {
  _set?: InputMaybe<RaceEnum_Set_Input>;
  pk_columns: RaceEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RaceEnum_ManyArgs = {
  updates: Array<RaceEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RangeValuesArgs = {
  _append?: InputMaybe<RangeValues_Append_Input>;
  _delete_at_path?: InputMaybe<RangeValues_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<RangeValues_Delete_Elem_Input>;
  _delete_key?: InputMaybe<RangeValues_Delete_Key_Input>;
  _inc?: InputMaybe<RangeValues_Inc_Input>;
  _prepend?: InputMaybe<RangeValues_Prepend_Input>;
  _set?: InputMaybe<RangeValues_Set_Input>;
  where: RangeValues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RangeValues_By_PkArgs = {
  _append?: InputMaybe<RangeValues_Append_Input>;
  _delete_at_path?: InputMaybe<RangeValues_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<RangeValues_Delete_Elem_Input>;
  _delete_key?: InputMaybe<RangeValues_Delete_Key_Input>;
  _inc?: InputMaybe<RangeValues_Inc_Input>;
  _prepend?: InputMaybe<RangeValues_Prepend_Input>;
  _set?: InputMaybe<RangeValues_Set_Input>;
  pk_columns: RangeValues_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RangeValues_ManyArgs = {
  updates: Array<RangeValues_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RejectionTypeEnumArgs = {
  _set?: InputMaybe<RejectionTypeEnum_Set_Input>;
  where: RejectionTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RejectionTypeEnum_By_PkArgs = {
  _set?: InputMaybe<RejectionTypeEnum_Set_Input>;
  pk_columns: RejectionTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RejectionTypeEnum_ManyArgs = {
  updates: Array<RejectionTypeEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RejectionsArgs = {
  _inc?: InputMaybe<Rejections_Inc_Input>;
  _set?: InputMaybe<Rejections_Set_Input>;
  where: Rejections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rejections_By_PkArgs = {
  _inc?: InputMaybe<Rejections_Inc_Input>;
  _set?: InputMaybe<Rejections_Set_Input>;
  pk_columns: Rejections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rejections_ManyArgs = {
  updates: Array<Rejections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RenderedEmailsArgs = {
  _append?: InputMaybe<RenderedEmails_Append_Input>;
  _delete_at_path?: InputMaybe<RenderedEmails_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<RenderedEmails_Delete_Elem_Input>;
  _delete_key?: InputMaybe<RenderedEmails_Delete_Key_Input>;
  _inc?: InputMaybe<RenderedEmails_Inc_Input>;
  _prepend?: InputMaybe<RenderedEmails_Prepend_Input>;
  _set?: InputMaybe<RenderedEmails_Set_Input>;
  where: RenderedEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RenderedEmails_By_PkArgs = {
  _append?: InputMaybe<RenderedEmails_Append_Input>;
  _delete_at_path?: InputMaybe<RenderedEmails_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<RenderedEmails_Delete_Elem_Input>;
  _delete_key?: InputMaybe<RenderedEmails_Delete_Key_Input>;
  _inc?: InputMaybe<RenderedEmails_Inc_Input>;
  _prepend?: InputMaybe<RenderedEmails_Prepend_Input>;
  _set?: InputMaybe<RenderedEmails_Set_Input>;
  pk_columns: RenderedEmails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RenderedEmails_ManyArgs = {
  updates: Array<RenderedEmails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeClaimsArgs = {
  _inc?: InputMaybe<ResumeClaims_Inc_Input>;
  _set?: InputMaybe<ResumeClaims_Set_Input>;
  where: ResumeClaims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeClaims_By_PkArgs = {
  _inc?: InputMaybe<ResumeClaims_Inc_Input>;
  _set?: InputMaybe<ResumeClaims_Set_Input>;
  pk_columns: ResumeClaims_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeClaims_ManyArgs = {
  updates: Array<ResumeClaims_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeEmailAddressesArgs = {
  _inc?: InputMaybe<ResumeEmailAddresses_Inc_Input>;
  _set?: InputMaybe<ResumeEmailAddresses_Set_Input>;
  where: ResumeEmailAddresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeEmailAddresses_By_PkArgs = {
  _inc?: InputMaybe<ResumeEmailAddresses_Inc_Input>;
  _set?: InputMaybe<ResumeEmailAddresses_Set_Input>;
  pk_columns: ResumeEmailAddresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeEmailAddresses_ManyArgs = {
  updates: Array<ResumeEmailAddresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeIngestStatusArgs = {
  _inc?: InputMaybe<ResumeIngestStatus_Inc_Input>;
  _set?: InputMaybe<ResumeIngestStatus_Set_Input>;
  where: ResumeIngestStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeIngestStatus_By_PkArgs = {
  _inc?: InputMaybe<ResumeIngestStatus_Inc_Input>;
  _set?: InputMaybe<ResumeIngestStatus_Set_Input>;
  pk_columns: ResumeIngestStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ResumeIngestStatus_ManyArgs = {
  updates: Array<ResumeIngestStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ResumePhoneNumbersArgs = {
  _inc?: InputMaybe<ResumePhoneNumbers_Inc_Input>;
  _set?: InputMaybe<ResumePhoneNumbers_Set_Input>;
  where: ResumePhoneNumbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ResumePhoneNumbers_By_PkArgs = {
  _inc?: InputMaybe<ResumePhoneNumbers_Inc_Input>;
  _set?: InputMaybe<ResumePhoneNumbers_Set_Input>;
  pk_columns: ResumePhoneNumbers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ResumePhoneNumbers_ManyArgs = {
  updates: Array<ResumePhoneNumbers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ResumesArgs = {
  _append?: InputMaybe<Resumes_Append_Input>;
  _delete_at_path?: InputMaybe<Resumes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Resumes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Resumes_Delete_Key_Input>;
  _inc?: InputMaybe<Resumes_Inc_Input>;
  _prepend?: InputMaybe<Resumes_Prepend_Input>;
  _set?: InputMaybe<Resumes_Set_Input>;
  where: Resumes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resumes_By_PkArgs = {
  _append?: InputMaybe<Resumes_Append_Input>;
  _delete_at_path?: InputMaybe<Resumes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Resumes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Resumes_Delete_Key_Input>;
  _inc?: InputMaybe<Resumes_Inc_Input>;
  _prepend?: InputMaybe<Resumes_Prepend_Input>;
  _set?: InputMaybe<Resumes_Set_Input>;
  pk_columns: Resumes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Resumes_ManyArgs = {
  updates: Array<Resumes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewCriteriaArgs = {
  _inc?: InputMaybe<ReviewCriteria_Inc_Input>;
  _set?: InputMaybe<ReviewCriteria_Set_Input>;
  where: ReviewCriteria_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewCriteriaScoreArgs = {
  _inc?: InputMaybe<ReviewCriteriaScore_Inc_Input>;
  _set?: InputMaybe<ReviewCriteriaScore_Set_Input>;
  where: ReviewCriteriaScore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewCriteriaScore_By_PkArgs = {
  _inc?: InputMaybe<ReviewCriteriaScore_Inc_Input>;
  _set?: InputMaybe<ReviewCriteriaScore_Set_Input>;
  pk_columns: ReviewCriteriaScore_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewCriteriaScore_ManyArgs = {
  updates: Array<ReviewCriteriaScore_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewCriteria_By_PkArgs = {
  _inc?: InputMaybe<ReviewCriteria_Inc_Input>;
  _set?: InputMaybe<ReviewCriteria_Set_Input>;
  pk_columns: ReviewCriteria_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewCriteria_ManyArgs = {
  updates: Array<ReviewCriteria_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupContactTemplateVariablesArgs = {
  _inc?: InputMaybe<ReviewGroupContactTemplateVariables_Inc_Input>;
  _set?: InputMaybe<ReviewGroupContactTemplateVariables_Set_Input>;
  where: ReviewGroupContactTemplateVariables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupContactTemplateVariables_By_PkArgs = {
  _inc?: InputMaybe<ReviewGroupContactTemplateVariables_Inc_Input>;
  _set?: InputMaybe<ReviewGroupContactTemplateVariables_Set_Input>;
  pk_columns: ReviewGroupContactTemplateVariables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupContactTemplateVariables_ManyArgs = {
  updates: Array<ReviewGroupContactTemplateVariables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupCriteriaArgs = {
  _inc?: InputMaybe<ReviewGroupCriteria_Inc_Input>;
  _set?: InputMaybe<ReviewGroupCriteria_Set_Input>;
  where: ReviewGroupCriteria_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupCriteria_By_PkArgs = {
  _inc?: InputMaybe<ReviewGroupCriteria_Inc_Input>;
  _set?: InputMaybe<ReviewGroupCriteria_Set_Input>;
  pk_columns: ReviewGroupCriteria_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupCriteria_ManyArgs = {
  updates: Array<ReviewGroupCriteria_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupFilesArgs = {
  _inc?: InputMaybe<ReviewGroupFiles_Inc_Input>;
  _set?: InputMaybe<ReviewGroupFiles_Set_Input>;
  where: ReviewGroupFiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupFiles_By_PkArgs = {
  _inc?: InputMaybe<ReviewGroupFiles_Inc_Input>;
  _set?: InputMaybe<ReviewGroupFiles_Set_Input>;
  pk_columns: ReviewGroupFiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupFiles_ManyArgs = {
  updates: Array<ReviewGroupFiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupStagesArgs = {
  _inc?: InputMaybe<ReviewGroupStages_Inc_Input>;
  _set?: InputMaybe<ReviewGroupStages_Set_Input>;
  where: ReviewGroupStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupStages_By_PkArgs = {
  _inc?: InputMaybe<ReviewGroupStages_Inc_Input>;
  _set?: InputMaybe<ReviewGroupStages_Set_Input>;
  pk_columns: ReviewGroupStages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupStages_ManyArgs = {
  updates: Array<ReviewGroupStages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupSubTypeEnumArgs = {
  _set?: InputMaybe<ReviewGroupSubTypeEnum_Set_Input>;
  where: ReviewGroupSubTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupSubTypeEnum_By_PkArgs = {
  _set?: InputMaybe<ReviewGroupSubTypeEnum_Set_Input>;
  pk_columns: ReviewGroupSubTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupSubTypeEnum_ManyArgs = {
  updates: Array<ReviewGroupSubTypeEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupTypeEnumArgs = {
  _set?: InputMaybe<ReviewGroupTypeEnum_Set_Input>;
  where: ReviewGroupTypeEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupTypeEnum_By_PkArgs = {
  _set?: InputMaybe<ReviewGroupTypeEnum_Set_Input>;
  pk_columns: ReviewGroupTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupTypeEnum_ManyArgs = {
  updates: Array<ReviewGroupTypeEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroupsArgs = {
  _inc?: InputMaybe<ReviewGroups_Inc_Input>;
  _set?: InputMaybe<ReviewGroups_Set_Input>;
  where: ReviewGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroups_By_PkArgs = {
  _inc?: InputMaybe<ReviewGroups_Inc_Input>;
  _set?: InputMaybe<ReviewGroups_Set_Input>;
  pk_columns: ReviewGroups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewGroups_ManyArgs = {
  updates: Array<ReviewGroups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewsArgs = {
  _inc?: InputMaybe<Reviews_Inc_Input>;
  _set?: InputMaybe<Reviews_Set_Input>;
  where: Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewsResultEnumArgs = {
  _set?: InputMaybe<ReviewsResultEnum_Set_Input>;
  where: ReviewsResultEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewsResultEnum_By_PkArgs = {
  _set?: InputMaybe<ReviewsResultEnum_Set_Input>;
  pk_columns: ReviewsResultEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewsResultEnum_ManyArgs = {
  updates: Array<ReviewsResultEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reviews_By_PkArgs = {
  _inc?: InputMaybe<Reviews_Inc_Input>;
  _set?: InputMaybe<Reviews_Set_Input>;
  pk_columns: Reviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reviews_ManyArgs = {
  updates: Array<Reviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoleAccessArgs = {
  _inc?: InputMaybe<RoleAccess_Inc_Input>;
  _set?: InputMaybe<RoleAccess_Set_Input>;
  where: RoleAccess_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RoleAccess_By_PkArgs = {
  _inc?: InputMaybe<RoleAccess_Inc_Input>;
  _set?: InputMaybe<RoleAccess_Set_Input>;
  pk_columns: RoleAccess_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoleAccess_ManyArgs = {
  updates: Array<RoleAccess_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SearchFilterTypesEnumArgs = {
  _set?: InputMaybe<SearchFilterTypesEnum_Set_Input>;
  where: SearchFilterTypesEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SearchFilterTypesEnum_By_PkArgs = {
  _set?: InputMaybe<SearchFilterTypesEnum_Set_Input>;
  pk_columns: SearchFilterTypesEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SearchFilterTypesEnum_ManyArgs = {
  updates: Array<SearchFilterTypesEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SearchFiltersArgs = {
  _append?: InputMaybe<SearchFilters_Append_Input>;
  _delete_at_path?: InputMaybe<SearchFilters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SearchFilters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SearchFilters_Delete_Key_Input>;
  _inc?: InputMaybe<SearchFilters_Inc_Input>;
  _prepend?: InputMaybe<SearchFilters_Prepend_Input>;
  _set?: InputMaybe<SearchFilters_Set_Input>;
  where: SearchFilters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SearchFilters_By_PkArgs = {
  _append?: InputMaybe<SearchFilters_Append_Input>;
  _delete_at_path?: InputMaybe<SearchFilters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SearchFilters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SearchFilters_Delete_Key_Input>;
  _inc?: InputMaybe<SearchFilters_Inc_Input>;
  _prepend?: InputMaybe<SearchFilters_Prepend_Input>;
  _set?: InputMaybe<SearchFilters_Set_Input>;
  pk_columns: SearchFilters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SearchFilters_ManyArgs = {
  updates: Array<SearchFilters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SecurityClearancesArgs = {
  _inc?: InputMaybe<SecurityClearances_Inc_Input>;
  _set?: InputMaybe<SecurityClearances_Set_Input>;
  where: SecurityClearances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SecurityClearances_By_PkArgs = {
  _inc?: InputMaybe<SecurityClearances_Inc_Input>;
  _set?: InputMaybe<SecurityClearances_Set_Input>;
  pk_columns: SecurityClearances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SecurityClearances_ManyArgs = {
  updates: Array<SecurityClearances_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SequelizeMetaArgs = {
  _set?: InputMaybe<SequelizeMeta_Set_Input>;
  where: SequelizeMeta_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SequelizeMeta_By_PkArgs = {
  _set?: InputMaybe<SequelizeMeta_Set_Input>;
  pk_columns: SequelizeMeta_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SequelizeMeta_ManyArgs = {
  updates: Array<SequelizeMeta_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StageEmailContractsArgs = {
  _inc?: InputMaybe<StageEmailContracts_Inc_Input>;
  _set?: InputMaybe<StageEmailContracts_Set_Input>;
  where: StageEmailContracts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StageEmailContracts_By_PkArgs = {
  _inc?: InputMaybe<StageEmailContracts_Inc_Input>;
  _set?: InputMaybe<StageEmailContracts_Set_Input>;
  pk_columns: StageEmailContracts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StageEmailContracts_ManyArgs = {
  updates: Array<StageEmailContracts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StageEmailsArgs = {
  _inc?: InputMaybe<StageEmails_Inc_Input>;
  _set?: InputMaybe<StageEmails_Set_Input>;
  where: StageEmails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StageEmails_By_PkArgs = {
  _inc?: InputMaybe<StageEmails_Inc_Input>;
  _set?: InputMaybe<StageEmails_Set_Input>;
  pk_columns: StageEmails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StageEmails_ManyArgs = {
  updates: Array<StageEmails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireMappingArgs = {
  _inc?: InputMaybe<StageQuestionnaireMapping_Inc_Input>;
  _set?: InputMaybe<StageQuestionnaireMapping_Set_Input>;
  where: StageQuestionnaireMapping_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireMappingIdenityArgs = {
  _inc?: InputMaybe<StageQuestionnaireMappingIdenity_Inc_Input>;
  _set?: InputMaybe<StageQuestionnaireMappingIdenity_Set_Input>;
  where: StageQuestionnaireMappingIdenity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireMappingIdenity_By_PkArgs = {
  _inc?: InputMaybe<StageQuestionnaireMappingIdenity_Inc_Input>;
  _set?: InputMaybe<StageQuestionnaireMappingIdenity_Set_Input>;
  pk_columns: StageQuestionnaireMappingIdenity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireMappingIdenity_ManyArgs = {
  updates: Array<StageQuestionnaireMappingIdenity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireMapping_By_PkArgs = {
  _inc?: InputMaybe<StageQuestionnaireMapping_Inc_Input>;
  _set?: InputMaybe<StageQuestionnaireMapping_Set_Input>;
  pk_columns: StageQuestionnaireMapping_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireMapping_ManyArgs = {
  updates: Array<StageQuestionnaireMapping_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireTemplateVariablesArgs = {
  _set?: InputMaybe<StageQuestionnaireTemplateVariables_Set_Input>;
  where: StageQuestionnaireTemplateVariables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireTemplateVariables_By_PkArgs = {
  _set?: InputMaybe<StageQuestionnaireTemplateVariables_Set_Input>;
  pk_columns: StageQuestionnaireTemplateVariables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StageQuestionnaireTemplateVariables_ManyArgs = {
  updates: Array<StageQuestionnaireTemplateVariables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StageRolesArgs = {
  _inc?: InputMaybe<StageRoles_Inc_Input>;
  _set?: InputMaybe<StageRoles_Set_Input>;
  where: StageRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StageRoles_By_PkArgs = {
  _inc?: InputMaybe<StageRoles_Inc_Input>;
  _set?: InputMaybe<StageRoles_Set_Input>;
  pk_columns: StageRoles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StageRoles_ManyArgs = {
  updates: Array<StageRoles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StageTransitionsArgs = {
  _inc?: InputMaybe<StageTransitions_Inc_Input>;
  _set?: InputMaybe<StageTransitions_Set_Input>;
  where: StageTransitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StageTransitions_By_PkArgs = {
  _inc?: InputMaybe<StageTransitions_Inc_Input>;
  _set?: InputMaybe<StageTransitions_Set_Input>;
  pk_columns: StageTransitions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StageTransitions_ManyArgs = {
  updates: Array<StageTransitions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StagesArgs = {
  _append?: InputMaybe<Stages_Append_Input>;
  _delete_at_path?: InputMaybe<Stages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stages_Delete_Key_Input>;
  _inc?: InputMaybe<Stages_Inc_Input>;
  _prepend?: InputMaybe<Stages_Prepend_Input>;
  _set?: InputMaybe<Stages_Set_Input>;
  where: Stages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stages_By_PkArgs = {
  _append?: InputMaybe<Stages_Append_Input>;
  _delete_at_path?: InputMaybe<Stages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stages_Delete_Key_Input>;
  _inc?: InputMaybe<Stages_Inc_Input>;
  _prepend?: InputMaybe<Stages_Prepend_Input>;
  _set?: InputMaybe<Stages_Set_Input>;
  pk_columns: Stages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stages_ManyArgs = {
  updates: Array<Stages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StateMachinesArgs = {
  _inc?: InputMaybe<StateMachines_Inc_Input>;
  _set?: InputMaybe<StateMachines_Set_Input>;
  where: StateMachines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StateMachines_By_PkArgs = {
  _inc?: InputMaybe<StateMachines_Inc_Input>;
  _set?: InputMaybe<StateMachines_Set_Input>;
  pk_columns: StateMachines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StateMachines_ManyArgs = {
  updates: Array<StateMachines_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubmissionsArgs = {
  _append?: InputMaybe<Submissions_Append_Input>;
  _delete_at_path?: InputMaybe<Submissions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Submissions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Submissions_Delete_Key_Input>;
  _inc?: InputMaybe<Submissions_Inc_Input>;
  _prepend?: InputMaybe<Submissions_Prepend_Input>;
  _set?: InputMaybe<Submissions_Set_Input>;
  where: Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubmissionsOverlaysLinksArgs = {
  _set?: InputMaybe<SubmissionsOverlaysLinks_Set_Input>;
  where: SubmissionsOverlaysLinks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubmissionsOverlaysLinks_By_PkArgs = {
  _set?: InputMaybe<SubmissionsOverlaysLinks_Set_Input>;
  pk_columns: SubmissionsOverlaysLinks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubmissionsOverlaysLinks_ManyArgs = {
  updates: Array<SubmissionsOverlaysLinks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Submissions_By_PkArgs = {
  _append?: InputMaybe<Submissions_Append_Input>;
  _delete_at_path?: InputMaybe<Submissions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Submissions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Submissions_Delete_Key_Input>;
  _inc?: InputMaybe<Submissions_Inc_Input>;
  _prepend?: InputMaybe<Submissions_Prepend_Input>;
  _set?: InputMaybe<Submissions_Set_Input>;
  pk_columns: Submissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Submissions_ManyArgs = {
  updates: Array<Submissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TableConfigTypesArgs = {
  _inc?: InputMaybe<TableConfigTypes_Inc_Input>;
  _set?: InputMaybe<TableConfigTypes_Set_Input>;
  where: TableConfigTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TableConfigTypes_By_PkArgs = {
  _inc?: InputMaybe<TableConfigTypes_Inc_Input>;
  _set?: InputMaybe<TableConfigTypes_Set_Input>;
  pk_columns: TableConfigTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TableConfigTypes_ManyArgs = {
  updates: Array<TableConfigTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TableConfigsArgs = {
  _append?: InputMaybe<TableConfigs_Append_Input>;
  _delete_at_path?: InputMaybe<TableConfigs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<TableConfigs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<TableConfigs_Delete_Key_Input>;
  _inc?: InputMaybe<TableConfigs_Inc_Input>;
  _prepend?: InputMaybe<TableConfigs_Prepend_Input>;
  _set?: InputMaybe<TableConfigs_Set_Input>;
  where: TableConfigs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TableConfigs_By_PkArgs = {
  _append?: InputMaybe<TableConfigs_Append_Input>;
  _delete_at_path?: InputMaybe<TableConfigs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<TableConfigs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<TableConfigs_Delete_Key_Input>;
  _inc?: InputMaybe<TableConfigs_Inc_Input>;
  _prepend?: InputMaybe<TableConfigs_Prepend_Input>;
  _set?: InputMaybe<TableConfigs_Set_Input>;
  pk_columns: TableConfigs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TableConfigs_ManyArgs = {
  updates: Array<TableConfigs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _inc?: InputMaybe<Tags_Inc_Input>;
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _inc?: InputMaybe<Tags_Inc_Input>;
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaskGroupAssigneesArgs = {
  _inc?: InputMaybe<TaskGroupAssignees_Inc_Input>;
  _set?: InputMaybe<TaskGroupAssignees_Set_Input>;
  where: TaskGroupAssignees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TaskGroupAssignees_By_PkArgs = {
  _inc?: InputMaybe<TaskGroupAssignees_Inc_Input>;
  _set?: InputMaybe<TaskGroupAssignees_Set_Input>;
  pk_columns: TaskGroupAssignees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TaskGroupAssignees_ManyArgs = {
  updates: Array<TaskGroupAssignees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaskGroupsArgs = {
  _inc?: InputMaybe<TaskGroups_Inc_Input>;
  _set?: InputMaybe<TaskGroups_Set_Input>;
  where: TaskGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TaskGroups_By_PkArgs = {
  _inc?: InputMaybe<TaskGroups_Inc_Input>;
  _set?: InputMaybe<TaskGroups_Set_Input>;
  pk_columns: TaskGroups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TaskGroups_ManyArgs = {
  updates: Array<TaskGroups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaskPriorityTypesArgs = {
  _set?: InputMaybe<TaskPriorityTypes_Set_Input>;
  where: TaskPriorityTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TaskPriorityTypes_By_PkArgs = {
  _set?: InputMaybe<TaskPriorityTypes_Set_Input>;
  pk_columns: TaskPriorityTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TaskPriorityTypes_ManyArgs = {
  updates: Array<TaskPriorityTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaskTypesArgs = {
  _set?: InputMaybe<TaskTypes_Set_Input>;
  where: TaskTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TaskTypes_By_PkArgs = {
  _set?: InputMaybe<TaskTypes_Set_Input>;
  pk_columns: TaskTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TaskTypes_ManyArgs = {
  updates: Array<TaskTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _append?: InputMaybe<Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _prepend?: InputMaybe<Tasks_Prepend_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _append?: InputMaybe<Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _prepend?: InputMaybe<Tasks_Prepend_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TerminationsArgs = {
  _append?: InputMaybe<Terminations_Append_Input>;
  _delete_at_path?: InputMaybe<Terminations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Terminations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Terminations_Delete_Key_Input>;
  _inc?: InputMaybe<Terminations_Inc_Input>;
  _prepend?: InputMaybe<Terminations_Prepend_Input>;
  _set?: InputMaybe<Terminations_Set_Input>;
  where: Terminations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Terminations_By_PkArgs = {
  _append?: InputMaybe<Terminations_Append_Input>;
  _delete_at_path?: InputMaybe<Terminations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Terminations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Terminations_Delete_Key_Input>;
  _inc?: InputMaybe<Terminations_Inc_Input>;
  _prepend?: InputMaybe<Terminations_Prepend_Input>;
  _set?: InputMaybe<Terminations_Set_Input>;
  pk_columns: Terminations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Terminations_ManyArgs = {
  updates: Array<Terminations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TimeInStagesArgs = {
  _inc?: InputMaybe<TimeInStages_Inc_Input>;
  _set?: InputMaybe<TimeInStages_Set_Input>;
  where: TimeInStages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TimeInStages_By_PkArgs = {
  _inc?: InputMaybe<TimeInStages_Inc_Input>;
  _set?: InputMaybe<TimeInStages_Set_Input>;
  pk_columns: TimeInStages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TimeInStages_ManyArgs = {
  updates: Array<TimeInStages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TimelineEventsArgs = {
  _inc?: InputMaybe<TimelineEvents_Inc_Input>;
  _set?: InputMaybe<TimelineEvents_Set_Input>;
  where: TimelineEvents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TimelineEvents_By_PkArgs = {
  _inc?: InputMaybe<TimelineEvents_Inc_Input>;
  _set?: InputMaybe<TimelineEvents_Set_Input>;
  pk_columns: TimelineEvents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TimelineEvents_ManyArgs = {
  updates: Array<TimelineEvents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TokensArgs = {
  _append?: InputMaybe<Tokens_Append_Input>;
  _delete_at_path?: InputMaybe<Tokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tokens_Delete_Key_Input>;
  _inc?: InputMaybe<Tokens_Inc_Input>;
  _prepend?: InputMaybe<Tokens_Prepend_Input>;
  _set?: InputMaybe<Tokens_Set_Input>;
  where: Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tokens_By_PkArgs = {
  _append?: InputMaybe<Tokens_Append_Input>;
  _delete_at_path?: InputMaybe<Tokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tokens_Delete_Key_Input>;
  _inc?: InputMaybe<Tokens_Inc_Input>;
  _prepend?: InputMaybe<Tokens_Prepend_Input>;
  _set?: InputMaybe<Tokens_Set_Input>;
  pk_columns: Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tokens_ManyArgs = {
  updates: Array<Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserAccessGroupsArgs = {
  _inc?: InputMaybe<UserAccessGroups_Inc_Input>;
  _set?: InputMaybe<UserAccessGroups_Set_Input>;
  where: UserAccessGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserAccessGroups_By_PkArgs = {
  _inc?: InputMaybe<UserAccessGroups_Inc_Input>;
  _set?: InputMaybe<UserAccessGroups_Set_Input>;
  pk_columns: UserAccessGroups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserAccessGroups_ManyArgs = {
  updates: Array<UserAccessGroups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserInterfaceStateArgs = {
  _append?: InputMaybe<UserInterfaceState_Append_Input>;
  _delete_at_path?: InputMaybe<UserInterfaceState_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserInterfaceState_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserInterfaceState_Delete_Key_Input>;
  _inc?: InputMaybe<UserInterfaceState_Inc_Input>;
  _prepend?: InputMaybe<UserInterfaceState_Prepend_Input>;
  _set?: InputMaybe<UserInterfaceState_Set_Input>;
  where: UserInterfaceState_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserInterfaceState_By_PkArgs = {
  _append?: InputMaybe<UserInterfaceState_Append_Input>;
  _delete_at_path?: InputMaybe<UserInterfaceState_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<UserInterfaceState_Delete_Elem_Input>;
  _delete_key?: InputMaybe<UserInterfaceState_Delete_Key_Input>;
  _inc?: InputMaybe<UserInterfaceState_Inc_Input>;
  _prepend?: InputMaybe<UserInterfaceState_Prepend_Input>;
  _set?: InputMaybe<UserInterfaceState_Set_Input>;
  pk_columns: UserInterfaceState_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserInterfaceState_ManyArgs = {
  updates: Array<UserInterfaceState_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ValueTypesArgs = {
  _set?: InputMaybe<ValueTypes_Set_Input>;
  where: ValueTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ValueTypes_By_PkArgs = {
  _set?: InputMaybe<ValueTypes_Set_Input>;
  pk_columns: ValueTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ValueTypes_ManyArgs = {
  updates: Array<ValueTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VeteranStatusEnumArgs = {
  _set?: InputMaybe<VeteranStatusEnum_Set_Input>;
  where: VeteranStatusEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VeteranStatusEnum_By_PkArgs = {
  _set?: InputMaybe<VeteranStatusEnum_Set_Input>;
  pk_columns: VeteranStatusEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VeteranStatusEnum_ManyArgs = {
  updates: Array<VeteranStatusEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WhiteLabelingArgs = {
  _inc?: InputMaybe<WhiteLabeling_Inc_Input>;
  _set?: InputMaybe<WhiteLabeling_Set_Input>;
  where: WhiteLabeling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WhiteLabeling_By_PkArgs = {
  _inc?: InputMaybe<WhiteLabeling_Inc_Input>;
  _set?: InputMaybe<WhiteLabeling_Set_Input>;
  pk_columns: WhiteLabeling_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WhiteLabeling_ManyArgs = {
  updates: Array<WhiteLabeling_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WorkflowRegressionsArgs = {
  _inc?: InputMaybe<WorkflowRegressions_Inc_Input>;
  _set?: InputMaybe<WorkflowRegressions_Set_Input>;
  where: WorkflowRegressions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorkflowRegressions_By_PkArgs = {
  _inc?: InputMaybe<WorkflowRegressions_Inc_Input>;
  _set?: InputMaybe<WorkflowRegressions_Set_Input>;
  pk_columns: WorkflowRegressions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WorkflowRegressions_ManyArgs = {
  updates: Array<WorkflowRegressions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CitizenshipEnumArgs = {
  _set?: InputMaybe<CitizenshipEnum_Set_Input>;
  where: CitizenshipEnum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CitizenshipEnum_By_PkArgs = {
  _set?: InputMaybe<CitizenshipEnum_Set_Input>;
  pk_columns: CitizenshipEnum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CitizenshipEnum_ManyArgs = {
  updates: Array<CitizenshipEnum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_EmailsArgs = {
  _inc?: InputMaybe<Contract_Emails_Inc_Input>;
  _set?: InputMaybe<Contract_Emails_Set_Input>;
  where: Contract_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_Emails_By_PkArgs = {
  _inc?: InputMaybe<Contract_Emails_Inc_Input>;
  _set?: InputMaybe<Contract_Emails_Set_Input>;
  pk_columns: Contract_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contract_Emails_ManyArgs = {
  updates: Array<Contract_Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deleted_Ppl_NamesArgs = {
  _inc?: InputMaybe<Deleted_Ppl_Names_Inc_Input>;
  _set?: InputMaybe<Deleted_Ppl_Names_Set_Input>;
  where: Deleted_Ppl_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deleted_Ppl_Names_ManyArgs = {
  updates: Array<Deleted_Ppl_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Eeo_Data_Point_Status_By_Identity_ViewArgs = {
  _inc?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Inc_Input>;
  _set?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Set_Input>;
  where: Eeo_Data_Point_Status_By_Identity_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Eeo_Data_Point_Status_By_Identity_View_ManyArgs = {
  updates: Array<Eeo_Data_Point_Status_By_Identity_View_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Eeo_Data_Points_Unknown_As_Other_By_Identity_ViewArgs = {
  _inc?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Inc_Input>;
  _set?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Set_Input>;
  where: Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Eeo_Data_Points_Unknown_As_Other_By_Identity_View_ManyArgs = {
  updates: Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Eeo_Status_By_IdentityArgs = {
  _inc?: InputMaybe<Eeo_Status_By_Identity_Inc_Input>;
  _set?: InputMaybe<Eeo_Status_By_Identity_Set_Input>;
  where: Eeo_Status_By_Identity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Eeo_Status_By_Identity_ManyArgs = {
  updates: Array<Eeo_Status_By_Identity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_ColumnsArgs = {
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_Columns_ManyArgs = {
  updates: Array<Geometry_Columns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Identity_Roles_ArrArgs = {
  _inc?: InputMaybe<Identity_Roles_Arr_Inc_Input>;
  _set?: InputMaybe<Identity_Roles_Arr_Set_Input>;
  where: Identity_Roles_Arr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Identity_Roles_Arr_ManyArgs = {
  updates: Array<Identity_Roles_Arr_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Identity_Roles_StatArgs = {
  _inc?: InputMaybe<Identity_Roles_Stat_Inc_Input>;
  _set?: InputMaybe<Identity_Roles_Stat_Set_Input>;
  where: Identity_Roles_Stat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Identity_Roles_Stat_ManyArgs = {
  updates: Array<Identity_Roles_Stat_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_Available_PositionsArgs = {
  _append?: InputMaybe<Jobs_Available_Positions_Append_Input>;
  _delete_at_path?: InputMaybe<Jobs_Available_Positions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Jobs_Available_Positions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Jobs_Available_Positions_Delete_Key_Input>;
  _inc?: InputMaybe<Jobs_Available_Positions_Inc_Input>;
  _prepend?: InputMaybe<Jobs_Available_Positions_Prepend_Input>;
  _set?: InputMaybe<Jobs_Available_Positions_Set_Input>;
  where: Jobs_Available_Positions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_Available_Positions_ManyArgs = {
  updates: Array<Jobs_Available_Positions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Matches_From_ApplicationsArgs = {
  _inc?: InputMaybe<Matches_From_Applications_Inc_Input>;
  _set?: InputMaybe<Matches_From_Applications_Set_Input>;
  where: Matches_From_Applications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Matches_From_Applications_ManyArgs = {
  updates: Array<Matches_From_Applications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Matches_From_BoardArgs = {
  _inc?: InputMaybe<Matches_From_Board_Inc_Input>;
  _set?: InputMaybe<Matches_From_Board_Set_Input>;
  where: Matches_From_Board_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Matches_From_Board_ManyArgs = {
  updates: Array<Matches_From_Board_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Matches_From_TasksArgs = {
  _inc?: InputMaybe<Matches_From_Tasks_Inc_Input>;
  _set?: InputMaybe<Matches_From_Tasks_Set_Input>;
  where: Matches_From_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Matches_From_Tasks_ManyArgs = {
  updates: Array<Matches_From_Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nylas_EventsArgs = {
  _append?: InputMaybe<Nylas_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Nylas_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nylas_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nylas_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Nylas_Events_Prepend_Input>;
  _set?: InputMaybe<Nylas_Events_Set_Input>;
  where: Nylas_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nylas_Events_By_PkArgs = {
  _append?: InputMaybe<Nylas_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Nylas_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nylas_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nylas_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Nylas_Events_Prepend_Input>;
  _set?: InputMaybe<Nylas_Events_Set_Input>;
  pk_columns: Nylas_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nylas_Events_ManyArgs = {
  updates: Array<Nylas_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Spec_ResultsArgs = {
  _inc?: InputMaybe<Search_Spec_Results_Inc_Input>;
  _set?: InputMaybe<Search_Spec_Results_Set_Input>;
  where: Search_Spec_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Spec_Results_By_PkArgs = {
  _inc?: InputMaybe<Search_Spec_Results_Inc_Input>;
  _set?: InputMaybe<Search_Spec_Results_Set_Input>;
  pk_columns: Search_Spec_Results_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Spec_Results_ManyArgs = {
  updates: Array<Search_Spec_Results_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Search_SpecsArgs = {
  _append?: InputMaybe<Search_Specs_Append_Input>;
  _delete_at_path?: InputMaybe<Search_Specs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Search_Specs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Search_Specs_Delete_Key_Input>;
  _inc?: InputMaybe<Search_Specs_Inc_Input>;
  _prepend?: InputMaybe<Search_Specs_Prepend_Input>;
  _set?: InputMaybe<Search_Specs_Set_Input>;
  where: Search_Specs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Specs_By_PkArgs = {
  _append?: InputMaybe<Search_Specs_Append_Input>;
  _delete_at_path?: InputMaybe<Search_Specs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Search_Specs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Search_Specs_Delete_Key_Input>;
  _inc?: InputMaybe<Search_Specs_Inc_Input>;
  _prepend?: InputMaybe<Search_Specs_Prepend_Input>;
  _set?: InputMaybe<Search_Specs_Set_Input>;
  pk_columns: Search_Specs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Specs_ManyArgs = {
  updates: Array<Search_Specs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_SysArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_ManyArgs = {
  updates: Array<Spatial_Ref_Sys_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_StatusArgs = {
  _inc?: InputMaybe<Staffing_Status_Inc_Input>;
  _set?: InputMaybe<Staffing_Status_Set_Input>;
  where: Staffing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Status_ManyArgs = {
  updates: Array<Staffing_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Text_MessagesArgs = {
  _inc?: InputMaybe<Text_Messages_Inc_Input>;
  _set?: InputMaybe<Text_Messages_Set_Input>;
  where: Text_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Messages_By_PkArgs = {
  _inc?: InputMaybe<Text_Messages_Inc_Input>;
  _set?: InputMaybe<Text_Messages_Set_Input>;
  pk_columns: Text_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Messages_ManyArgs = {
  updates: Array<Text_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Util_Deps_Saved_DdlArgs = {
  _inc?: InputMaybe<Util_Deps_Saved_Ddl_Inc_Input>;
  _set?: InputMaybe<Util_Deps_Saved_Ddl_Set_Input>;
  where: Util_Deps_Saved_Ddl_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Util_Deps_Saved_Ddl_By_PkArgs = {
  _inc?: InputMaybe<Util_Deps_Saved_Ddl_Inc_Input>;
  _set?: InputMaybe<Util_Deps_Saved_Ddl_Set_Input>;
  pk_columns: Util_Deps_Saved_Ddl_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Util_Deps_Saved_Ddl_ManyArgs = {
  updates: Array<Util_Deps_Saved_Ddl_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_ContractsArgs = {
  _inc?: InputMaybe<Workflow_Contracts_Inc_Input>;
  _set?: InputMaybe<Workflow_Contracts_Set_Input>;
  where: Workflow_Contracts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Contracts_By_PkArgs = {
  _inc?: InputMaybe<Workflow_Contracts_Inc_Input>;
  _set?: InputMaybe<Workflow_Contracts_Set_Input>;
  pk_columns: Workflow_Contracts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Contracts_ManyArgs = {
  updates: Array<Workflow_Contracts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_DepartmentsArgs = {
  _inc?: InputMaybe<Workflow_Departments_Inc_Input>;
  _set?: InputMaybe<Workflow_Departments_Set_Input>;
  where: Workflow_Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Departments_By_PkArgs = {
  _inc?: InputMaybe<Workflow_Departments_Inc_Input>;
  _set?: InputMaybe<Workflow_Departments_Set_Input>;
  pk_columns: Workflow_Departments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workflow_Departments_ManyArgs = {
  updates: Array<Workflow_Departments_Updates>;
};


/** mutation root */
export type Mutation_RootUploadLogoArgs = {
  logoS3Key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadProfilePictureArgs = {
  pictureS3Key: Scalars['String'];
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['name']>;
  _gt?: InputMaybe<Scalars['name']>;
  _gte?: InputMaybe<Scalars['name']>;
  _in?: InputMaybe<Array<Scalars['name']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['name']>;
  _lte?: InputMaybe<Scalars['name']>;
  _neq?: InputMaybe<Scalars['name']>;
  _nin?: InputMaybe<Array<Scalars['name']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "nylas_events" */
export type Nylas_Events = {
  __typename?: 'nylas_events';
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  object: Scalars['String'];
  object_data: Scalars['jsonb'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


/** columns and relationships of "nylas_events" */
export type Nylas_EventsObject_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nylas_events" */
export type Nylas_Events_Aggregate = {
  __typename?: 'nylas_events_aggregate';
  aggregate?: Maybe<Nylas_Events_Aggregate_Fields>;
  nodes: Array<Nylas_Events>;
};

/** aggregate fields of "nylas_events" */
export type Nylas_Events_Aggregate_Fields = {
  __typename?: 'nylas_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nylas_Events_Max_Fields>;
  min?: Maybe<Nylas_Events_Min_Fields>;
};


/** aggregate fields of "nylas_events" */
export type Nylas_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nylas_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nylas_Events_Append_Input = {
  object_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "nylas_events". All fields are combined with a logical 'AND'. */
export type Nylas_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Nylas_Events_Bool_Exp>>;
  _not?: InputMaybe<Nylas_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Nylas_Events_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  object?: InputMaybe<String_Comparison_Exp>;
  object_data?: InputMaybe<Jsonb_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nylas_events" */
export enum Nylas_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  NylasEventsPkey = 'nylas_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nylas_Events_Delete_At_Path_Input = {
  object_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nylas_Events_Delete_Elem_Input = {
  object_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nylas_Events_Delete_Key_Input = {
  object_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "nylas_events" */
export type Nylas_Events_Insert_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  object?: InputMaybe<Scalars['String']>;
  object_data?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nylas_Events_Max_Fields = {
  __typename?: 'nylas_events_max_fields';
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  object?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nylas_Events_Min_Fields = {
  __typename?: 'nylas_events_min_fields';
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  object?: Maybe<Scalars['String']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nylas_events" */
export type Nylas_Events_Mutation_Response = {
  __typename?: 'nylas_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nylas_Events>;
};

/** on_conflict condition type for table "nylas_events" */
export type Nylas_Events_On_Conflict = {
  constraint: Nylas_Events_Constraint;
  update_columns?: Array<Nylas_Events_Update_Column>;
  where?: InputMaybe<Nylas_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "nylas_events". */
export type Nylas_Events_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object?: InputMaybe<Order_By>;
  object_data?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nylas_events */
export type Nylas_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nylas_Events_Prepend_Input = {
  object_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nylas_events" */
export enum Nylas_Events_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Object = 'object',
  /** column name */
  ObjectData = 'object_data',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "nylas_events" */
export type Nylas_Events_Set_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  object?: InputMaybe<Scalars['String']>;
  object_data?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nylas_events" */
export type Nylas_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nylas_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nylas_Events_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  object?: InputMaybe<Scalars['String']>;
  object_data?: InputMaybe<Scalars['jsonb']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nylas_events" */
export enum Nylas_Events_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Object = 'object',
  /** column name */
  ObjectData = 'object_data',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type'
}

export type Nylas_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nylas_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nylas_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nylas_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nylas_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nylas_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nylas_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nylas_Events_Bool_Exp;
};

/** columns and relationships of "ofccp_compliance" */
export type Ofccp_Compliance = {
  __typename?: 'ofccp_compliance';
  applicant_name?: Maybe<Scalars['String']>;
  applicant_type?: Maybe<Scalars['String']>;
  application_status?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_name?: Maybe<Scalars['String']>;
  date_applied?: Maybe<Scalars['timestamptz']>;
  disability_status?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hire_type?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_location?: Maybe<Scalars['String']>;
  job_name?: Maybe<Scalars['String']>;
  job_status?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ofccp_compliance" */
export type Ofccp_Compliance_Aggregate = {
  __typename?: 'ofccp_compliance_aggregate';
  aggregate?: Maybe<Ofccp_Compliance_Aggregate_Fields>;
  nodes: Array<Ofccp_Compliance>;
};

/** aggregate fields of "ofccp_compliance" */
export type Ofccp_Compliance_Aggregate_Fields = {
  __typename?: 'ofccp_compliance_aggregate_fields';
  avg?: Maybe<Ofccp_Compliance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ofccp_Compliance_Max_Fields>;
  min?: Maybe<Ofccp_Compliance_Min_Fields>;
  stddev?: Maybe<Ofccp_Compliance_Stddev_Fields>;
  stddev_pop?: Maybe<Ofccp_Compliance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ofccp_Compliance_Stddev_Samp_Fields>;
  sum?: Maybe<Ofccp_Compliance_Sum_Fields>;
  var_pop?: Maybe<Ofccp_Compliance_Var_Pop_Fields>;
  var_samp?: Maybe<Ofccp_Compliance_Var_Samp_Fields>;
  variance?: Maybe<Ofccp_Compliance_Variance_Fields>;
};


/** aggregate fields of "ofccp_compliance" */
export type Ofccp_Compliance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ofccp_Compliance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ofccp_Compliance_Avg_Fields = {
  __typename?: 'ofccp_compliance_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ofccp_compliance". All fields are combined with a logical 'AND'. */
export type Ofccp_Compliance_Bool_Exp = {
  _and?: InputMaybe<Array<Ofccp_Compliance_Bool_Exp>>;
  _not?: InputMaybe<Ofccp_Compliance_Bool_Exp>;
  _or?: InputMaybe<Array<Ofccp_Compliance_Bool_Exp>>;
  applicant_name?: InputMaybe<String_Comparison_Exp>;
  applicant_type?: InputMaybe<String_Comparison_Exp>;
  application_status?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  contract_name?: InputMaybe<String_Comparison_Exp>;
  date_applied?: InputMaybe<Timestamptz_Comparison_Exp>;
  disability_status?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  hire_type?: InputMaybe<String_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  identity_type_id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_location?: InputMaybe<String_Comparison_Exp>;
  job_name?: InputMaybe<String_Comparison_Exp>;
  job_status?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
  rejection_reason?: InputMaybe<String_Comparison_Exp>;
  veteran_status?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Ofccp_Compliance_Max_Fields = {
  __typename?: 'ofccp_compliance_max_fields';
  applicant_name?: Maybe<Scalars['String']>;
  applicant_type?: Maybe<Scalars['String']>;
  application_status?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_name?: Maybe<Scalars['String']>;
  date_applied?: Maybe<Scalars['timestamptz']>;
  disability_status?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hire_type?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_location?: Maybe<Scalars['String']>;
  job_name?: Maybe<Scalars['String']>;
  job_status?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ofccp_Compliance_Min_Fields = {
  __typename?: 'ofccp_compliance_min_fields';
  applicant_name?: Maybe<Scalars['String']>;
  applicant_type?: Maybe<Scalars['String']>;
  application_status?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  contract_name?: Maybe<Scalars['String']>;
  date_applied?: Maybe<Scalars['timestamptz']>;
  disability_status?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hire_type?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_location?: Maybe<Scalars['String']>;
  job_name?: Maybe<Scalars['String']>;
  job_status?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  veteran_status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "ofccp_compliance". */
export type Ofccp_Compliance_Order_By = {
  applicant_name?: InputMaybe<Order_By>;
  applicant_type?: InputMaybe<Order_By>;
  application_status?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  contract_name?: InputMaybe<Order_By>;
  date_applied?: InputMaybe<Order_By>;
  disability_status?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hire_type?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  identity_type_id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_location?: InputMaybe<Order_By>;
  job_name?: InputMaybe<Order_By>;
  job_status?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
  rejection_reason?: InputMaybe<Order_By>;
  veteran_status?: InputMaybe<Order_By>;
};

/** select columns of table "ofccp_compliance" */
export enum Ofccp_Compliance_Select_Column {
  /** column name */
  ApplicantName = 'applicant_name',
  /** column name */
  ApplicantType = 'applicant_type',
  /** column name */
  ApplicationStatus = 'application_status',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  ContractName = 'contract_name',
  /** column name */
  DateApplied = 'date_applied',
  /** column name */
  DisabilityStatus = 'disability_status',
  /** column name */
  Gender = 'gender',
  /** column name */
  HireType = 'hire_type',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IdentityTypeId = 'identity_type_id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobLocation = 'job_location',
  /** column name */
  JobName = 'job_name',
  /** column name */
  JobStatus = 'job_status',
  /** column name */
  Race = 'race',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  VeteranStatus = 'veteran_status'
}

/** aggregate stddev on columns */
export type Ofccp_Compliance_Stddev_Fields = {
  __typename?: 'ofccp_compliance_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ofccp_Compliance_Stddev_Pop_Fields = {
  __typename?: 'ofccp_compliance_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ofccp_Compliance_Stddev_Samp_Fields = {
  __typename?: 'ofccp_compliance_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ofccp_compliance" */
export type Ofccp_Compliance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ofccp_Compliance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ofccp_Compliance_Stream_Cursor_Value_Input = {
  applicant_name?: InputMaybe<Scalars['String']>;
  applicant_type?: InputMaybe<Scalars['String']>;
  application_status?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  contract_name?: InputMaybe<Scalars['String']>;
  date_applied?: InputMaybe<Scalars['timestamptz']>;
  disability_status?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  hire_type?: InputMaybe<Scalars['String']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  identity_type_id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_location?: InputMaybe<Scalars['String']>;
  job_name?: InputMaybe<Scalars['String']>;
  job_status?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  veteran_status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ofccp_Compliance_Sum_Fields = {
  __typename?: 'ofccp_compliance_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  identity_type_id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Ofccp_Compliance_Var_Pop_Fields = {
  __typename?: 'ofccp_compliance_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ofccp_Compliance_Var_Samp_Fields = {
  __typename?: 'ofccp_compliance_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ofccp_Compliance_Variance_Fields = {
  __typename?: 'ofccp_compliance_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  identity_type_id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  APITokens: Array<ApiTokens>;
  /** An aggregate relationship */
  APITokens_aggregate: ApiTokens_Aggregate;
  /** fetch data from the table: "APITokens" using primary key columns */
  APITokens_by_pk?: Maybe<ApiTokens>;
  /** fetch data from the table: "AccessIntents" */
  AccessIntents: Array<AccessIntents>;
  /** fetch aggregated fields from the table: "AccessIntents" */
  AccessIntents_aggregate: AccessIntents_Aggregate;
  /** fetch data from the table: "AccessIntents" using primary key columns */
  AccessIntents_by_pk?: Maybe<AccessIntents>;
  /** fetch data from the table: "AccessTypeEnum" */
  AccessTypeEnum: Array<AccessTypeEnum>;
  /** fetch aggregated fields from the table: "AccessTypeEnum" */
  AccessTypeEnum_aggregate: AccessTypeEnum_Aggregate;
  /** fetch data from the table: "AccessTypeEnum" using primary key columns */
  AccessTypeEnum_by_pk?: Maybe<AccessTypeEnum>;
  /** fetch data from the table: "ActiveEntitlements" */
  ActiveEntitlements: Array<ActiveEntitlements>;
  /** fetch aggregated fields from the table: "ActiveEntitlements" */
  ActiveEntitlements_aggregate: ActiveEntitlements_Aggregate;
  /** fetch data from the table: "ActivityLogTypes" */
  ActivityLogTypes: Array<ActivityLogTypes>;
  /** fetch aggregated fields from the table: "ActivityLogTypes" */
  ActivityLogTypes_aggregate: ActivityLogTypes_Aggregate;
  /** fetch data from the table: "ActivityLogTypes" using primary key columns */
  ActivityLogTypes_by_pk?: Maybe<ActivityLogTypes>;
  /** fetch data from the table: "ActivityTemplates" */
  ActivityTemplates: Array<ActivityTemplates>;
  /** fetch aggregated fields from the table: "ActivityTemplates" */
  ActivityTemplates_aggregate: ActivityTemplates_Aggregate;
  /** fetch data from the table: "ActivityTemplates" using primary key columns */
  ActivityTemplates_by_pk?: Maybe<ActivityTemplates>;
  /** fetch data from the table: "AoIdentifierTypes" */
  AoIdentifierTypes: Array<AoIdentifierTypes>;
  /** fetch aggregated fields from the table: "AoIdentifierTypes" */
  AoIdentifierTypes_aggregate: AoIdentifierTypes_Aggregate;
  /** fetch data from the table: "AoIdentifierTypes" using primary key columns */
  AoIdentifierTypes_by_pk?: Maybe<AoIdentifierTypes>;
  /** fetch data from the table: "ApplicationStatus" */
  ApplicationStatus: Array<ApplicationStatus>;
  /** fetch aggregated fields from the table: "ApplicationStatus" */
  ApplicationStatus_aggregate: ApplicationStatus_Aggregate;
  /** fetch data from the table: "ApplicationStatus" using primary key columns */
  ApplicationStatus_by_pk?: Maybe<ApplicationStatus>;
  /** fetch data from the table: "ApplicationType" */
  ApplicationType: Array<ApplicationType>;
  /** fetch aggregated fields from the table: "ApplicationType" */
  ApplicationType_aggregate: ApplicationType_Aggregate;
  /** fetch data from the table: "ApplicationType" using primary key columns */
  ApplicationType_by_pk?: Maybe<ApplicationType>;
  /** An array relationship */
  Applications: Array<Applications>;
  /** An aggregate relationship */
  Applications_aggregate: Applications_Aggregate;
  /** fetch data from the table: "Applications" using primary key columns */
  Applications_by_pk?: Maybe<Applications>;
  /** An array relationship */
  Assignments: Array<Assignments>;
  /** An aggregate relationship */
  Assignments_aggregate: Assignments_Aggregate;
  /** fetch data from the table: "Assignments" using primary key columns */
  Assignments_by_pk?: Maybe<Assignments>;
  /** fetch data from the table: "AsyncOperations" */
  AsyncOperations: Array<AsyncOperations>;
  /** fetch data from the table: "AsyncOperationsStates" */
  AsyncOperationsStates: Array<AsyncOperationsStates>;
  /** fetch aggregated fields from the table: "AsyncOperationsStates" */
  AsyncOperationsStates_aggregate: AsyncOperationsStates_Aggregate;
  /** fetch data from the table: "AsyncOperationsStates" using primary key columns */
  AsyncOperationsStates_by_pk?: Maybe<AsyncOperationsStates>;
  /** fetch aggregated fields from the table: "AsyncOperations" */
  AsyncOperations_aggregate: AsyncOperations_Aggregate;
  /** fetch data from the table: "AsyncOperations" using primary key columns */
  AsyncOperations_by_pk?: Maybe<AsyncOperations>;
  /** An array relationship */
  Attachments: Array<Attachments>;
  /** An aggregate relationship */
  Attachments_aggregate: Attachments_Aggregate;
  /** fetch data from the table: "Attachments" using primary key columns */
  Attachments_by_pk?: Maybe<Attachments>;
  /** An array relationship */
  AuditCreates: Array<AuditCreates>;
  /** An aggregate relationship */
  AuditCreates_aggregate: AuditCreates_Aggregate;
  /** fetch data from the table: "AuditCreates" using primary key columns */
  AuditCreates_by_pk?: Maybe<AuditCreates>;
  /** An array relationship */
  AuditStages: Array<AuditStages>;
  /** fetch data from the table: "AuditStagesWithWorkflowBeginAndStartedDates" */
  AuditStagesWithWorkflowBeginAndStartedDates: Array<AuditStagesWithWorkflowBeginAndStartedDates>;
  /** fetch aggregated fields from the table: "AuditStagesWithWorkflowBeginAndStartedDates" */
  AuditStagesWithWorkflowBeginAndStartedDates_aggregate: AuditStagesWithWorkflowBeginAndStartedDates_Aggregate;
  /** An aggregate relationship */
  AuditStages_aggregate: AuditStages_Aggregate;
  /** fetch data from the table: "AuditStages" using primary key columns */
  AuditStages_by_pk?: Maybe<AuditStages>;
  /** fetch data from the table: "AuthAudits" */
  AuthAudits: Array<AuthAudits>;
  /** fetch aggregated fields from the table: "AuthAudits" */
  AuthAudits_aggregate: AuthAudits_Aggregate;
  /** fetch data from the table: "AuthAudits" using primary key columns */
  AuthAudits_by_pk?: Maybe<AuthAudits>;
  /** fetch data from the table: "Badges" */
  Badges: Array<Badges>;
  /** fetch aggregated fields from the table: "Badges" */
  Badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "Badges" using primary key columns */
  Badges_by_pk?: Maybe<Badges>;
  /** fetch data from the table: "Benefit" */
  Benefit: Array<Benefit>;
  /** An array relationship */
  BenefitTypeIdentitySubTypes: Array<BenefitTypeIdentitySubTypes>;
  /** An aggregate relationship */
  BenefitTypeIdentitySubTypes_aggregate: BenefitTypeIdentitySubTypes_Aggregate;
  /** fetch data from the table: "BenefitTypeIdentitySubTypes" using primary key columns */
  BenefitTypeIdentitySubTypes_by_pk?: Maybe<BenefitTypeIdentitySubTypes>;
  /** fetch data from the table: "BenefitTypes" */
  BenefitTypes: Array<BenefitTypes>;
  /** fetch aggregated fields from the table: "BenefitTypes" */
  BenefitTypes_aggregate: BenefitTypes_Aggregate;
  /** fetch data from the table: "BenefitTypes" using primary key columns */
  BenefitTypes_by_pk?: Maybe<BenefitTypes>;
  /** fetch aggregated fields from the table: "Benefit" */
  Benefit_aggregate: Benefit_Aggregate;
  /** fetch data from the table: "Benefit" using primary key columns */
  Benefit_by_pk?: Maybe<Benefit>;
  /** fetch data from the table: "BoardFilters" */
  BoardFilters: Array<BoardFilters>;
  /** fetch aggregated fields from the table: "BoardFilters" */
  BoardFilters_aggregate: BoardFilters_Aggregate;
  /** fetch data from the table: "BoardFilters" using primary key columns */
  BoardFilters_by_pk?: Maybe<BoardFilters>;
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** fetch data from the table: "BoardSnippets" using primary key columns */
  BoardSnippets_by_pk?: Maybe<BoardSnippets>;
  /** fetch data from the table: "BullhornSyncStatus" */
  BullhornSyncStatus: Array<BullhornSyncStatus>;
  /** fetch aggregated fields from the table: "BullhornSyncStatus" */
  BullhornSyncStatus_aggregate: BullhornSyncStatus_Aggregate;
  /** fetch data from the table: "BullhornSyncStatus" using primary key columns */
  BullhornSyncStatus_by_pk?: Maybe<BullhornSyncStatus>;
  /** An array relationship */
  CalcEquationResults: Array<CalcEquationResults>;
  /** An aggregate relationship */
  CalcEquationResults_aggregate: CalcEquationResults_Aggregate;
  /** fetch data from the table: "CalcEquationResults" using primary key columns */
  CalcEquationResults_by_pk?: Maybe<CalcEquationResults>;
  /** An array relationship */
  CalcFormValues: Array<CalcFormValues>;
  /** An aggregate relationship */
  CalcFormValues_aggregate: CalcFormValues_Aggregate;
  /** fetch data from the table: "CalcFormValues" using primary key columns */
  CalcFormValues_by_pk?: Maybe<CalcFormValues>;
  /** fetch data from the table: "Calcs" */
  Calcs: Array<Calcs>;
  /** fetch aggregated fields from the table: "Calcs" */
  Calcs_aggregate: Calcs_Aggregate;
  /** fetch data from the table: "Calcs" using primary key columns */
  Calcs_by_pk?: Maybe<Calcs>;
  /** An array relationship */
  CalculationTemplateVersions: Array<CalculationTemplateVersions>;
  /** An aggregate relationship */
  CalculationTemplateVersions_aggregate: CalculationTemplateVersions_Aggregate;
  /** fetch data from the table: "CalculationTemplateVersions" using primary key columns */
  CalculationTemplateVersions_by_pk?: Maybe<CalculationTemplateVersions>;
  /** fetch data from the table: "CalculationTemplates" */
  CalculationTemplates: Array<CalculationTemplates>;
  /** fetch aggregated fields from the table: "CalculationTemplates" */
  CalculationTemplates_aggregate: CalculationTemplates_Aggregate;
  /** fetch data from the table: "CalculationTemplates" using primary key columns */
  CalculationTemplates_by_pk?: Maybe<CalculationTemplates>;
  /** An array relationship */
  CalculationVersionFormInputs: Array<CalculationVersionFormInputs>;
  /** An aggregate relationship */
  CalculationVersionFormInputs_aggregate: CalculationVersionFormInputs_Aggregate;
  /** fetch data from the table: "CalculationVersionFormInputs" using primary key columns */
  CalculationVersionFormInputs_by_pk?: Maybe<CalculationVersionFormInputs>;
  /** An array relationship */
  Calculations: Array<Calculations>;
  /** An aggregate relationship */
  Calculations_aggregate: Calculations_Aggregate;
  /** fetch data from the table: "Calculations" using primary key columns */
  Calculations_by_pk?: Maybe<Calculations>;
  /** fetch data from the table: "CandidateSources" */
  CandidateSources: Array<CandidateSources>;
  /** fetch aggregated fields from the table: "CandidateSources" */
  CandidateSources_aggregate: CandidateSources_Aggregate;
  /** fetch data from the table: "CandidateSources" using primary key columns */
  CandidateSources_by_pk?: Maybe<CandidateSources>;
  /** fetch data from the table: "CandidateStatuses" */
  CandidateStatuses: Array<CandidateStatuses>;
  /** fetch aggregated fields from the table: "CandidateStatuses" */
  CandidateStatuses_aggregate: CandidateStatuses_Aggregate;
  /** fetch data from the table: "CandidateStatuses" using primary key columns */
  CandidateStatuses_by_pk?: Maybe<CandidateStatuses>;
  /** fetch data from the table: "CaptureNodes" */
  CaptureNodes: Array<CaptureNodes>;
  /** fetch aggregated fields from the table: "CaptureNodes" */
  CaptureNodes_aggregate: CaptureNodes_Aggregate;
  /** fetch data from the table: "CaptureNodes" using primary key columns */
  CaptureNodes_by_pk?: Maybe<CaptureNodes>;
  /** fetch data from the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  ClientSideExternalSystemIntegrationPluginRecord: Array<ClientSideExternalSystemIntegrationPluginRecord>;
  /** fetch aggregated fields from the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  ClientSideExternalSystemIntegrationPluginRecord_aggregate: ClientSideExternalSystemIntegrationPluginRecord_Aggregate;
  /** fetch data from the table: "ClientSideExternalSystemIntegrationPluginRecord" using primary key columns */
  ClientSideExternalSystemIntegrationPluginRecord_by_pk?: Maybe<ClientSideExternalSystemIntegrationPluginRecord>;
  /** fetch data from the table: "CognitoUserData" */
  CognitoUserData: Array<CognitoUserData>;
  /** fetch aggregated fields from the table: "CognitoUserData" */
  CognitoUserData_aggregate: CognitoUserData_Aggregate;
  /** fetch data from the table: "CognitoUserData" using primary key columns */
  CognitoUserData_by_pk?: Maybe<CognitoUserData>;
  /** fetch data from the table: "CommentTypes" */
  CommentTypes: Array<CommentTypes>;
  /** fetch aggregated fields from the table: "CommentTypes" */
  CommentTypes_aggregate: CommentTypes_Aggregate;
  /** fetch data from the table: "CommentTypes" using primary key columns */
  CommentTypes_by_pk?: Maybe<CommentTypes>;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "Comments" using primary key columns */
  Comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table: "Companies" */
  Companies: Array<Companies>;
  /** fetch aggregated fields from the table: "Companies" */
  Companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "Companies" using primary key columns */
  Companies_by_pk?: Maybe<Companies>;
  /** An array relationship */
  CompanyEvents: Array<CompanyEvents>;
  /** An aggregate relationship */
  CompanyEvents_aggregate: CompanyEvents_Aggregate;
  /** fetch data from the table: "CompanyEvents" using primary key columns */
  CompanyEvents_by_pk?: Maybe<CompanyEvents>;
  /** fetch data from the table: "CompanyStatusEnum" */
  CompanyStatusEnum: Array<CompanyStatusEnum>;
  /** fetch aggregated fields from the table: "CompanyStatusEnum" */
  CompanyStatusEnum_aggregate: CompanyStatusEnum_Aggregate;
  /** fetch data from the table: "CompanyStatusEnum" using primary key columns */
  CompanyStatusEnum_by_pk?: Maybe<CompanyStatusEnum>;
  /** An array relationship */
  Compensations: Array<Compensations>;
  /** An aggregate relationship */
  Compensations_aggregate: Compensations_Aggregate;
  /** fetch data from the table: "Compensations" using primary key columns */
  Compensations_by_pk?: Maybe<Compensations>;
  /** fetch data from the table: "ContactTemplateVariablesEnum" */
  ContactTemplateVariablesEnum: Array<ContactTemplateVariablesEnum>;
  /** fetch aggregated fields from the table: "ContactTemplateVariablesEnum" */
  ContactTemplateVariablesEnum_aggregate: ContactTemplateVariablesEnum_Aggregate;
  /** fetch data from the table: "ContactTemplateVariablesEnum" using primary key columns */
  ContactTemplateVariablesEnum_by_pk?: Maybe<ContactTemplateVariablesEnum>;
  /** An array relationship */
  ContractComments: Array<ContractComments>;
  /** An aggregate relationship */
  ContractComments_aggregate: ContractComments_Aggregate;
  /** fetch data from the table: "ContractComments" using primary key columns */
  ContractComments_by_pk?: Maybe<ContractComments>;
  /** fetch data from the table: "ContractFiles" */
  ContractFiles: Array<ContractFiles>;
  /** fetch aggregated fields from the table: "ContractFiles" */
  ContractFiles_aggregate: ContractFiles_Aggregate;
  /** fetch data from the table: "ContractFiles" using primary key columns */
  ContractFiles_by_pk?: Maybe<ContractFiles>;
  /** An array relationship */
  ContractStages: Array<ContractStages>;
  /** An aggregate relationship */
  ContractStages_aggregate: ContractStages_Aggregate;
  /** fetch data from the table: "ContractStages" using primary key columns */
  ContractStages_by_pk?: Maybe<ContractStages>;
  /** fetch data from the table: "ContractStatusEnum" */
  ContractStatusEnum: Array<ContractStatusEnum>;
  /** fetch aggregated fields from the table: "ContractStatusEnum" */
  ContractStatusEnum_aggregate: ContractStatusEnum_Aggregate;
  /** fetch data from the table: "ContractStatusEnum" using primary key columns */
  ContractStatusEnum_by_pk?: Maybe<ContractStatusEnum>;
  /** An array relationship */
  Contracts: Array<Contracts>;
  /** An aggregate relationship */
  Contracts_aggregate: Contracts_Aggregate;
  /** fetch data from the table: "Contracts" using primary key columns */
  Contracts_by_pk?: Maybe<Contracts>;
  /** fetch data from the table: "CurrentStages" */
  CurrentStages: Array<CurrentStages>;
  /** fetch aggregated fields from the table: "CurrentStages" */
  CurrentStages_aggregate: CurrentStages_Aggregate;
  /** fetch data from the table: "CurrentStages" using primary key columns */
  CurrentStages_by_pk?: Maybe<CurrentStages>;
  /** fetch data from the table: "DataHistory" */
  DataHistory: Array<DataHistory>;
  /** fetch aggregated fields from the table: "DataHistory" */
  DataHistory_aggregate: DataHistory_Aggregate;
  /** fetch data from the table: "DataHistory" using primary key columns */
  DataHistory_by_pk?: Maybe<DataHistory>;
  /** An array relationship */
  Departments: Array<Departments>;
  /** An aggregate relationship */
  Departments_aggregate: Departments_Aggregate;
  /** fetch data from the table: "Departments" using primary key columns */
  Departments_by_pk?: Maybe<Departments>;
  /** fetch data from the table: "DisabilityStatusEnum" */
  DisabilityStatusEnum: Array<DisabilityStatusEnum>;
  /** fetch aggregated fields from the table: "DisabilityStatusEnum" */
  DisabilityStatusEnum_aggregate: DisabilityStatusEnum_Aggregate;
  /** fetch data from the table: "DisabilityStatusEnum" using primary key columns */
  DisabilityStatusEnum_by_pk?: Maybe<DisabilityStatusEnum>;
  /** fetch data from the table: "EEOJobCategoriesEnum" */
  EEOJobCategoriesEnum: Array<EeoJobCategoriesEnum>;
  /** fetch aggregated fields from the table: "EEOJobCategoriesEnum" */
  EEOJobCategoriesEnum_aggregate: EeoJobCategoriesEnum_Aggregate;
  /** fetch data from the table: "EEOJobCategoriesEnum" using primary key columns */
  EEOJobCategoriesEnum_by_pk?: Maybe<EeoJobCategoriesEnum>;
  /** An array relationship */
  EarnedBadges: Array<EarnedBadges>;
  /** An aggregate relationship */
  EarnedBadges_aggregate: EarnedBadges_Aggregate;
  /** fetch data from the table: "EarnedBadges" using primary key columns */
  EarnedBadges_by_pk?: Maybe<EarnedBadges>;
  /** fetch data from the table: "EmailAddresses" */
  EmailAddresses: Array<EmailAddresses>;
  /** fetch aggregated fields from the table: "EmailAddresses" */
  EmailAddresses_aggregate: EmailAddresses_Aggregate;
  /** fetch data from the table: "EmailAddresses" using primary key columns */
  EmailAddresses_by_pk?: Maybe<EmailAddresses>;
  /** fetch data from the table: "EmailEvents" */
  EmailEvents: Array<EmailEvents>;
  /** fetch aggregated fields from the table: "EmailEvents" */
  EmailEvents_aggregate: EmailEvents_Aggregate;
  /** fetch data from the table: "EmailEvents" using primary key columns */
  EmailEvents_by_pk?: Maybe<EmailEvents>;
  /** An array relationship */
  EmailRecords: Array<EmailRecords>;
  /** An aggregate relationship */
  EmailRecords_aggregate: EmailRecords_Aggregate;
  /** fetch data from the table: "EmailRecords" using primary key columns */
  EmailRecords_by_pk?: Maybe<EmailRecords>;
  /** An array relationship */
  EmailSignatures: Array<EmailSignatures>;
  /** An aggregate relationship */
  EmailSignatures_aggregate: EmailSignatures_Aggregate;
  /** fetch data from the table: "EmailSignatures" using primary key columns */
  EmailSignatures_by_pk?: Maybe<EmailSignatures>;
  /** fetch data from the table: "EmailSubFolders" */
  EmailSubFolders: Array<EmailSubFolders>;
  /** fetch aggregated fields from the table: "EmailSubFolders" */
  EmailSubFolders_aggregate: EmailSubFolders_Aggregate;
  /** fetch data from the table: "EmailSubFolders" using primary key columns */
  EmailSubFolders_by_pk?: Maybe<EmailSubFolders>;
  /** fetch data from the table: "EmailTypeEnum" */
  EmailTypeEnum: Array<EmailTypeEnum>;
  /** fetch aggregated fields from the table: "EmailTypeEnum" */
  EmailTypeEnum_aggregate: EmailTypeEnum_Aggregate;
  /** fetch data from the table: "EmailTypeEnum" using primary key columns */
  EmailTypeEnum_by_pk?: Maybe<EmailTypeEnum>;
  /** fetch data from the table: "EmailTypes" */
  EmailTypes: Array<EmailTypes>;
  /** fetch aggregated fields from the table: "EmailTypes" */
  EmailTypes_aggregate: EmailTypes_Aggregate;
  /** fetch data from the table: "EmailTypes" using primary key columns */
  EmailTypes_by_pk?: Maybe<EmailTypes>;
  /** An array relationship */
  Emails: Array<Emails>;
  /** An aggregate relationship */
  Emails_aggregate: Emails_Aggregate;
  /** fetch data from the table: "Emails" using primary key columns */
  Emails_by_pk?: Maybe<Emails>;
  /** fetch data from the table: "EmploymentHireType" */
  EmploymentHireType: Array<EmploymentHireType>;
  /** fetch aggregated fields from the table: "EmploymentHireType" */
  EmploymentHireType_aggregate: EmploymentHireType_Aggregate;
  /** fetch data from the table: "EmploymentHireType" using primary key columns */
  EmploymentHireType_by_pk?: Maybe<EmploymentHireType>;
  /** fetch data from the table: "EmploymentTypes" */
  EmploymentTypes: Array<EmploymentTypes>;
  /** fetch aggregated fields from the table: "EmploymentTypes" */
  EmploymentTypes_aggregate: EmploymentTypes_Aggregate;
  /** fetch data from the table: "EmploymentTypes" using primary key columns */
  EmploymentTypes_by_pk?: Maybe<EmploymentTypes>;
  /** An array relationship */
  Employments: Array<Employments>;
  /** An aggregate relationship */
  Employments_aggregate: Employments_Aggregate;
  /** fetch data from the table: "Employments" using primary key columns */
  Employments_by_pk?: Maybe<Employments>;
  /** fetch data from the table: "EntitlementCategories" */
  EntitlementCategories: Array<EntitlementCategories>;
  /** fetch aggregated fields from the table: "EntitlementCategories" */
  EntitlementCategories_aggregate: EntitlementCategories_Aggregate;
  /** fetch data from the table: "EntitlementCategories" using primary key columns */
  EntitlementCategories_by_pk?: Maybe<EntitlementCategories>;
  /** An array relationship */
  Entitlements: Array<Entitlements>;
  /** An aggregate relationship */
  Entitlements_aggregate: Entitlements_Aggregate;
  /** fetch data from the table: "Entitlements" using primary key columns */
  Entitlements_by_pk?: Maybe<Entitlements>;
  /** fetch data from the table: "EquationFormulaOption" */
  EquationFormulaOption: Array<EquationFormulaOption>;
  /** fetch aggregated fields from the table: "EquationFormulaOption" */
  EquationFormulaOption_aggregate: EquationFormulaOption_Aggregate;
  /** fetch data from the table: "EquationFormulaOption" using primary key columns */
  EquationFormulaOption_by_pk?: Maybe<EquationFormulaOption>;
  /** An array relationship */
  Equations: Array<Equations>;
  /** An aggregate relationship */
  Equations_aggregate: Equations_Aggregate;
  /** fetch data from the table: "Equations" using primary key columns */
  Equations_by_pk?: Maybe<Equations>;
  /** An array relationship */
  Events: Array<Events>;
  /** An aggregate relationship */
  Events_aggregate: Events_Aggregate;
  /** fetch data from the table: "Events" using primary key columns */
  Events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "Expense" */
  Expense: Array<Expense>;
  /** fetch aggregated fields from the table: "Expense" */
  Expense_aggregate: Expense_Aggregate;
  /** fetch data from the table: "Expense" using primary key columns */
  Expense_by_pk?: Maybe<Expense>;
  /** fetch data from the table: "ExternalIntegrationTypes" */
  ExternalIntegrationTypes: Array<ExternalIntegrationTypes>;
  /** fetch aggregated fields from the table: "ExternalIntegrationTypes" */
  ExternalIntegrationTypes_aggregate: ExternalIntegrationTypes_Aggregate;
  /** fetch data from the table: "ExternalIntegrationTypes" using primary key columns */
  ExternalIntegrationTypes_by_pk?: Maybe<ExternalIntegrationTypes>;
  /** fetch data from the table: "ExternalIntegrations" */
  ExternalIntegrations: Array<ExternalIntegrations>;
  /** fetch aggregated fields from the table: "ExternalIntegrations" */
  ExternalIntegrations_aggregate: ExternalIntegrations_Aggregate;
  /** fetch data from the table: "ExternalIntegrations" using primary key columns */
  ExternalIntegrations_by_pk?: Maybe<ExternalIntegrations>;
  /** An array relationship */
  ExternalJobBoardPublishings: Array<ExternalJobBoardPublishings>;
  /** An aggregate relationship */
  ExternalJobBoardPublishings_aggregate: ExternalJobBoardPublishings_Aggregate;
  /** fetch data from the table: "ExternalJobBoardPublishings" using primary key columns */
  ExternalJobBoardPublishings_by_pk?: Maybe<ExternalJobBoardPublishings>;
  /** fetch data from the table: "ExternalJobBoards" */
  ExternalJobBoards: Array<ExternalJobBoards>;
  /** fetch aggregated fields from the table: "ExternalJobBoards" */
  ExternalJobBoards_aggregate: ExternalJobBoards_Aggregate;
  /** fetch data from the table: "ExternalJobBoards" using primary key columns */
  ExternalJobBoards_by_pk?: Maybe<ExternalJobBoards>;
  /** fetch data from the table: "ExternalSystem" */
  ExternalSystem: Array<ExternalSystem>;
  /** fetch data from the table: "ExternalSystemInstance" */
  ExternalSystemInstance: Array<ExternalSystemInstance>;
  /** fetch aggregated fields from the table: "ExternalSystemInstance" */
  ExternalSystemInstance_aggregate: ExternalSystemInstance_Aggregate;
  /** fetch data from the table: "ExternalSystemInstance" using primary key columns */
  ExternalSystemInstance_by_pk?: Maybe<ExternalSystemInstance>;
  /** fetch aggregated fields from the table: "ExternalSystem" */
  ExternalSystem_aggregate: ExternalSystem_Aggregate;
  /** fetch data from the table: "ExternalSystem" using primary key columns */
  ExternalSystem_by_pk?: Maybe<ExternalSystem>;
  /** fetch data from the table: "FeatureFlags" */
  FeatureFlags: Array<FeatureFlags>;
  /** fetch aggregated fields from the table: "FeatureFlags" */
  FeatureFlags_aggregate: FeatureFlags_Aggregate;
  /** fetch data from the table: "FeatureFlags" using primary key columns */
  FeatureFlags_by_pk?: Maybe<FeatureFlags>;
  /** fetch data from the table: "Field" */
  Field: Array<Field>;
  /** fetch data from the table: "FieldDef" */
  FieldDef: Array<FieldDef>;
  /** fetch aggregated fields from the table: "FieldDef" */
  FieldDef_aggregate: FieldDef_Aggregate;
  /** fetch data from the table: "FieldDef" using primary key columns */
  FieldDef_by_pk?: Maybe<FieldDef>;
  /** An array relationship */
  FieldPicklistValues: Array<FieldPicklistValues>;
  /** An aggregate relationship */
  FieldPicklistValues_aggregate: FieldPicklistValues_Aggregate;
  /** fetch data from the table: "FieldPicklistValues" using primary key columns */
  FieldPicklistValues_by_pk?: Maybe<FieldPicklistValues>;
  /** fetch data from the table: "FieldTypeDef" */
  FieldTypeDef: Array<FieldTypeDef>;
  /** fetch aggregated fields from the table: "FieldTypeDef" */
  FieldTypeDef_aggregate: FieldTypeDef_Aggregate;
  /** fetch data from the table: "FieldTypeDef" using primary key columns */
  FieldTypeDef_by_pk?: Maybe<FieldTypeDef>;
  /** fetch aggregated fields from the table: "Field" */
  Field_aggregate: Field_Aggregate;
  /** fetch data from the table: "Field" using primary key columns */
  Field_by_pk?: Maybe<Field>;
  /** An array relationship */
  FileTags: Array<FileTags>;
  /** An aggregate relationship */
  FileTags_aggregate: FileTags_Aggregate;
  /** fetch data from the table: "FileTags" using primary key columns */
  FileTags_by_pk?: Maybe<FileTags>;
  /** An array relationship */
  Files: Array<Files>;
  /** An aggregate relationship */
  Files_aggregate: Files_Aggregate;
  /** fetch data from the table: "Files" using primary key columns */
  Files_by_pk?: Maybe<Files>;
  /** An array relationship */
  FormInputs: Array<FormInputs>;
  /** An aggregate relationship */
  FormInputs_aggregate: FormInputs_Aggregate;
  /** fetch data from the table: "FormInputs" using primary key columns */
  FormInputs_by_pk?: Maybe<FormInputs>;
  /** An array relationship */
  FormSelectOptions: Array<FormSelectOptions>;
  /** An aggregate relationship */
  FormSelectOptions_aggregate: FormSelectOptions_Aggregate;
  /** fetch data from the table: "FormSelectOptions" using primary key columns */
  FormSelectOptions_by_pk?: Maybe<FormSelectOptions>;
  /** An array relationship */
  FormulaOptions: Array<FormulaOptions>;
  /** An aggregate relationship */
  FormulaOptions_aggregate: FormulaOptions_Aggregate;
  /** fetch data from the table: "FormulaOptions" using primary key columns */
  FormulaOptions_by_pk?: Maybe<FormulaOptions>;
  /** fetch data from the table: "GenderEnum" */
  GenderEnum: Array<GenderEnum>;
  /** fetch aggregated fields from the table: "GenderEnum" */
  GenderEnum_aggregate: GenderEnum_Aggregate;
  /** fetch data from the table: "GenderEnum" using primary key columns */
  GenderEnum_by_pk?: Maybe<GenderEnum>;
  /** fetch data from the table: "GeocodingHistory" */
  GeocodingHistory: Array<GeocodingHistory>;
  /** fetch aggregated fields from the table: "GeocodingHistory" */
  GeocodingHistory_aggregate: GeocodingHistory_Aggregate;
  /** fetch data from the table: "GeocodingHistory" using primary key columns */
  GeocodingHistory_by_pk?: Maybe<GeocodingHistory>;
  /** An array relationship */
  Goals: Array<Goals>;
  /** An aggregate relationship */
  Goals_aggregate: Goals_Aggregate;
  /** fetch data from the table: "Goals" using primary key columns */
  Goals_by_pk?: Maybe<Goals>;
  /** fetch data from the table: "GoogleSheetCalcTemplates" */
  GoogleSheetCalcTemplates: Array<GoogleSheetCalcTemplates>;
  /** fetch aggregated fields from the table: "GoogleSheetCalcTemplates" */
  GoogleSheetCalcTemplates_aggregate: GoogleSheetCalcTemplates_Aggregate;
  /** fetch data from the table: "GoogleSheetCalcTemplates" using primary key columns */
  GoogleSheetCalcTemplates_by_pk?: Maybe<GoogleSheetCalcTemplates>;
  /** fetch data from the table: "GoogleSheetCalculations" */
  GoogleSheetCalculations: Array<GoogleSheetCalculations>;
  /** fetch aggregated fields from the table: "GoogleSheetCalculations" */
  GoogleSheetCalculations_aggregate: GoogleSheetCalculations_Aggregate;
  /** fetch data from the table: "GoogleSheetCalculations" using primary key columns */
  GoogleSheetCalculations_by_pk?: Maybe<GoogleSheetCalculations>;
  /** An array relationship */
  GroupJobs: Array<GroupJobs>;
  /** An aggregate relationship */
  GroupJobs_aggregate: GroupJobs_Aggregate;
  /** fetch data from the table: "GroupJobs" using primary key columns */
  GroupJobs_by_pk?: Maybe<GroupJobs>;
  /** fetch data from the table: "Groups" */
  Groups: Array<Groups>;
  /** fetch aggregated fields from the table: "Groups" */
  Groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "Groups" using primary key columns */
  Groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "HighestStages" */
  HighestStages: Array<HighestStages>;
  /** fetch aggregated fields from the table: "HighestStages" */
  HighestStages_aggregate: HighestStages_Aggregate;
  /** fetch data from the table: "HighestStages" using primary key columns */
  HighestStages_by_pk?: Maybe<HighestStages>;
  /** fetch data from the table: "HireTypesEnum" */
  HireTypesEnum: Array<HireTypesEnum>;
  /** fetch aggregated fields from the table: "HireTypesEnum" */
  HireTypesEnum_aggregate: HireTypesEnum_Aggregate;
  /** fetch data from the table: "HireTypesEnum" using primary key columns */
  HireTypesEnum_by_pk?: Maybe<HireTypesEnum>;
  /** An array relationship */
  ITRequestContractEmails: Array<ItRequestContractEmails>;
  /** An aggregate relationship */
  ITRequestContractEmails_aggregate: ItRequestContractEmails_Aggregate;
  /** fetch data from the table: "ITRequestContractEmails" using primary key columns */
  ITRequestContractEmails_by_pk?: Maybe<ItRequestContractEmails>;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An array relationship */
  IdentitiesOverlaysLinks: Array<IdentitiesOverlaysLinks>;
  /** An aggregate relationship */
  IdentitiesOverlaysLinks_aggregate: IdentitiesOverlaysLinks_Aggregate;
  /** fetch data from the table: "IdentitiesOverlaysLinks" using primary key columns */
  IdentitiesOverlaysLinks_by_pk?: Maybe<IdentitiesOverlaysLinks>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  /** fetch data from the table: "Identities" using primary key columns */
  Identities_by_pk?: Maybe<Identities>;
  /** An array relationship */
  IdentityAssignments: Array<IdentityAssignments>;
  /** An aggregate relationship */
  IdentityAssignments_aggregate: IdentityAssignments_Aggregate;
  /** fetch data from the table: "IdentityAssignments" using primary key columns */
  IdentityAssignments_by_pk?: Maybe<IdentityAssignments>;
  /** An array relationship */
  IdentityComments: Array<IdentityComments>;
  /** An aggregate relationship */
  IdentityComments_aggregate: IdentityComments_Aggregate;
  /** fetch data from the table: "IdentityComments" using primary key columns */
  IdentityComments_by_pk?: Maybe<IdentityComments>;
  /** fetch data from the table: "IdentityContractRelationship" */
  IdentityContractRelationship: Array<IdentityContractRelationship>;
  /** fetch aggregated fields from the table: "IdentityContractRelationship" */
  IdentityContractRelationship_aggregate: IdentityContractRelationship_Aggregate;
  /** fetch data from the table: "IdentityContractRelationship" using primary key columns */
  IdentityContractRelationship_by_pk?: Maybe<IdentityContractRelationship>;
  /** fetch data from the table: "IdentityDepartmentRelationship" */
  IdentityDepartmentRelationship: Array<IdentityDepartmentRelationship>;
  /** fetch aggregated fields from the table: "IdentityDepartmentRelationship" */
  IdentityDepartmentRelationship_aggregate: IdentityDepartmentRelationship_Aggregate;
  /** fetch data from the table: "IdentityDepartmentRelationship" using primary key columns */
  IdentityDepartmentRelationship_by_pk?: Maybe<IdentityDepartmentRelationship>;
  /** An array relationship */
  IdentityGroups: Array<IdentityGroups>;
  /** An aggregate relationship */
  IdentityGroups_aggregate: IdentityGroups_Aggregate;
  /** fetch data from the table: "IdentityGroups" using primary key columns */
  IdentityGroups_by_pk?: Maybe<IdentityGroups>;
  /** fetch data from the table: "IdentityJobRelationship" */
  IdentityJobRelationship: Array<IdentityJobRelationship>;
  /** fetch aggregated fields from the table: "IdentityJobRelationship" */
  IdentityJobRelationship_aggregate: IdentityJobRelationship_Aggregate;
  /** fetch data from the table: "IdentityJobRelationship" using primary key columns */
  IdentityJobRelationship_by_pk?: Maybe<IdentityJobRelationship>;
  /** An array relationship */
  IdentityPartnerGroupData: Array<IdentityPartnerGroupData>;
  /** An aggregate relationship */
  IdentityPartnerGroupData_aggregate: IdentityPartnerGroupData_Aggregate;
  /** fetch data from the table: "IdentityPartnerGroupData" using primary key columns */
  IdentityPartnerGroupData_by_pk?: Maybe<IdentityPartnerGroupData>;
  /** fetch data from the table: "IdentityRelationshipType" */
  IdentityRelationshipType: Array<IdentityRelationshipType>;
  /** fetch aggregated fields from the table: "IdentityRelationshipType" */
  IdentityRelationshipType_aggregate: IdentityRelationshipType_Aggregate;
  /** fetch data from the table: "IdentityRelationshipType" using primary key columns */
  IdentityRelationshipType_by_pk?: Maybe<IdentityRelationshipType>;
  /** An array relationship */
  IdentityRoles: Array<IdentityRoles>;
  /** An aggregate relationship */
  IdentityRoles_aggregate: IdentityRoles_Aggregate;
  /** An array relationship */
  IdentitySubTypeEmails: Array<IdentitySubTypeEmails>;
  /** An aggregate relationship */
  IdentitySubTypeEmails_aggregate: IdentitySubTypeEmails_Aggregate;
  /** fetch data from the table: "IdentitySubTypeEmails" using primary key columns */
  IdentitySubTypeEmails_by_pk?: Maybe<IdentitySubTypeEmails>;
  /** fetch data from the table: "IdentitySubTypes" */
  IdentitySubTypes: Array<IdentitySubTypes>;
  /** fetch aggregated fields from the table: "IdentitySubTypes" */
  IdentitySubTypes_aggregate: IdentitySubTypes_Aggregate;
  /** fetch data from the table: "IdentitySubTypes" using primary key columns */
  IdentitySubTypes_by_pk?: Maybe<IdentitySubTypes>;
  /** An array relationship */
  IdentityTags: Array<IdentityTags>;
  /** An aggregate relationship */
  IdentityTags_aggregate: IdentityTags_Aggregate;
  /** fetch data from the table: "IdentityTags" using primary key columns */
  IdentityTags_by_pk?: Maybe<IdentityTags>;
  /** An array relationship */
  IdentityTokens: Array<IdentityTokens>;
  /** An aggregate relationship */
  IdentityTokens_aggregate: IdentityTokens_Aggregate;
  /** fetch data from the table: "IdentityTokens" using primary key columns */
  IdentityTokens_by_pk?: Maybe<IdentityTokens>;
  /** fetch data from the table: "IdentityTypes" */
  IdentityTypes: Array<IdentityTypes>;
  /** fetch aggregated fields from the table: "IdentityTypes" */
  IdentityTypes_aggregate: IdentityTypes_Aggregate;
  /** fetch data from the table: "IdentityTypes" using primary key columns */
  IdentityTypes_by_pk?: Maybe<IdentityTypes>;
  /** fetch data from the table: "IdentityUserAccessGroup" */
  IdentityUserAccessGroup: Array<IdentityUserAccessGroup>;
  /** fetch aggregated fields from the table: "IdentityUserAccessGroup" */
  IdentityUserAccessGroup_aggregate: IdentityUserAccessGroup_Aggregate;
  /** fetch data from the table: "IdentityUserAccessGroup" using primary key columns */
  IdentityUserAccessGroup_by_pk?: Maybe<IdentityUserAccessGroup>;
  /** An array relationship */
  Images: Array<Images>;
  /** An aggregate relationship */
  Images_aggregate: Images_Aggregate;
  /** fetch data from the table: "Images" using primary key columns */
  Images_by_pk?: Maybe<Images>;
  /** fetch data from the table: "InputTypes" */
  InputTypes: Array<InputTypes>;
  /** fetch aggregated fields from the table: "InputTypes" */
  InputTypes_aggregate: InputTypes_Aggregate;
  /** fetch data from the table: "InputTypes" using primary key columns */
  InputTypes_by_pk?: Maybe<InputTypes>;
  /** fetch data from the table: "InternalJobCategories" */
  InternalJobCategories: Array<InternalJobCategories>;
  /** fetch aggregated fields from the table: "InternalJobCategories" */
  InternalJobCategories_aggregate: InternalJobCategories_Aggregate;
  /** fetch data from the table: "InternalJobCategories" using primary key columns */
  InternalJobCategories_by_pk?: Maybe<InternalJobCategories>;
  /** An array relationship */
  Interviews: Array<Interviews>;
  /** An aggregate relationship */
  Interviews_aggregate: Interviews_Aggregate;
  /** fetch data from the table: "Interviews" using primary key columns */
  Interviews_by_pk?: Maybe<Interviews>;
  /** fetch data from the table: "JobAvailabilities" */
  JobAvailabilities: Array<JobAvailabilities>;
  /** fetch aggregated fields from the table: "JobAvailabilities" */
  JobAvailabilities_aggregate: JobAvailabilities_Aggregate;
  /** fetch data from the table: "JobAvailabilities" using primary key columns */
  JobAvailabilities_by_pk?: Maybe<JobAvailabilities>;
  /** An array relationship */
  JobComments: Array<JobComments>;
  /** An aggregate relationship */
  JobComments_aggregate: JobComments_Aggregate;
  /** fetch data from the table: "JobComments" using primary key columns */
  JobComments_by_pk?: Maybe<JobComments>;
  /** fetch data from the table: "JobFiles" */
  JobFiles: Array<JobFiles>;
  /** fetch aggregated fields from the table: "JobFiles" */
  JobFiles_aggregate: JobFiles_Aggregate;
  /** fetch data from the table: "JobFiles" using primary key columns */
  JobFiles_by_pk?: Maybe<JobFiles>;
  /** fetch data from the table: "JobFundingStatusEnum" */
  JobFundingStatusEnum: Array<JobFundingStatusEnum>;
  /** fetch aggregated fields from the table: "JobFundingStatusEnum" */
  JobFundingStatusEnum_aggregate: JobFundingStatusEnum_Aggregate;
  /** fetch data from the table: "JobFundingStatusEnum" using primary key columns */
  JobFundingStatusEnum_by_pk?: Maybe<JobFundingStatusEnum>;
  /** fetch data from the table: "JobHireTypesEnum" */
  JobHireTypesEnum: Array<JobHireTypesEnum>;
  /** fetch aggregated fields from the table: "JobHireTypesEnum" */
  JobHireTypesEnum_aggregate: JobHireTypesEnum_Aggregate;
  /** fetch data from the table: "JobHireTypesEnum" using primary key columns */
  JobHireTypesEnum_by_pk?: Maybe<JobHireTypesEnum>;
  /** fetch data from the table: "JobPositions" */
  JobPositions: Array<JobPositions>;
  /** fetch aggregated fields from the table: "JobPositions" */
  JobPositions_aggregate: JobPositions_Aggregate;
  /** fetch data from the table: "JobPositions" using primary key columns */
  JobPositions_by_pk?: Maybe<JobPositions>;
  /** fetch data from the table: "JobStatuses" */
  JobStatuses: Array<JobStatuses>;
  /** fetch aggregated fields from the table: "JobStatuses" */
  JobStatuses_aggregate: JobStatuses_Aggregate;
  /** fetch data from the table: "JobStatuses" using primary key columns */
  JobStatuses_by_pk?: Maybe<JobStatuses>;
  /** An array relationship */
  Jobs: Array<Jobs>;
  /** An aggregate relationship */
  Jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "Jobs" using primary key columns */
  Jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table: "MagicLinks" */
  MagicLinks: Array<MagicLinks>;
  /** fetch aggregated fields from the table: "MagicLinks" */
  MagicLinks_aggregate: MagicLinks_Aggregate;
  /** fetch data from the table: "MagicLinks" using primary key columns */
  MagicLinks_by_pk?: Maybe<MagicLinks>;
  /** fetch data from the table: "MigrationData" */
  MigrationData: Array<MigrationData>;
  /** fetch aggregated fields from the table: "MigrationData" */
  MigrationData_aggregate: MigrationData_Aggregate;
  /** fetch data from the table: "MigrationData" using primary key columns */
  MigrationData_by_pk?: Maybe<MigrationData>;
  /** fetch data from the table: "ObjectZoo" */
  ObjectZoo: Array<ObjectZoo>;
  /** fetch aggregated fields from the table: "ObjectZoo" */
  ObjectZoo_aggregate: ObjectZoo_Aggregate;
  /** fetch data from the table: "ObjectZoo" using primary key columns */
  ObjectZoo_by_pk?: Maybe<ObjectZoo>;
  /** fetch data from the table: "Overlay" */
  Overlay: Array<Overlay>;
  /** fetch data from the table: "OverlayDefinition" */
  OverlayDefinition: Array<OverlayDefinition>;
  /** fetch aggregated fields from the table: "OverlayDefinition" */
  OverlayDefinition_aggregate: OverlayDefinition_Aggregate;
  /** fetch data from the table: "OverlayDefinition" using primary key columns */
  OverlayDefinition_by_pk?: Maybe<OverlayDefinition>;
  /** fetch aggregated fields from the table: "Overlay" */
  Overlay_aggregate: Overlay_Aggregate;
  /** fetch data from the table: "Overlay" using primary key columns */
  Overlay_by_pk?: Maybe<Overlay>;
  /** fetch data from the table: "PhoneNumbers" */
  PhoneNumbers: Array<PhoneNumbers>;
  /** fetch aggregated fields from the table: "PhoneNumbers" */
  PhoneNumbers_aggregate: PhoneNumbers_Aggregate;
  /** fetch data from the table: "PhoneNumbers" using primary key columns */
  PhoneNumbers_by_pk?: Maybe<PhoneNumbers>;
  /** An array relationship */
  PicklistValues: Array<PicklistValues>;
  /** An aggregate relationship */
  PicklistValues_aggregate: PicklistValues_Aggregate;
  /** fetch data from the table: "PicklistValues" using primary key columns */
  PicklistValues_by_pk?: Maybe<PicklistValues>;
  /** fetch data from the table: "Picklists" */
  Picklists: Array<Picklists>;
  /** fetch aggregated fields from the table: "Picklists" */
  Picklists_aggregate: Picklists_Aggregate;
  /** fetch data from the table: "Picklists" using primary key columns */
  Picklists_by_pk?: Maybe<Picklists>;
  /** fetch data from the table: "ProductUpdates" */
  ProductUpdates: Array<ProductUpdates>;
  /** fetch aggregated fields from the table: "ProductUpdates" */
  ProductUpdates_aggregate: ProductUpdates_Aggregate;
  /** fetch data from the table: "ProductUpdates" using primary key columns */
  ProductUpdates_by_pk?: Maybe<ProductUpdates>;
  /** An array relationship */
  QuestionnaireContactTemplateVariables: Array<QuestionnaireContactTemplateVariables>;
  /** An aggregate relationship */
  QuestionnaireContactTemplateVariables_aggregate: QuestionnaireContactTemplateVariables_Aggregate;
  /** fetch data from the table: "QuestionnaireContactTemplateVariables" using primary key columns */
  QuestionnaireContactTemplateVariables_by_pk?: Maybe<QuestionnaireContactTemplateVariables>;
  /** An array relationship */
  QuestionnaireLinks: Array<QuestionnaireLinks>;
  /** An aggregate relationship */
  QuestionnaireLinks_aggregate: QuestionnaireLinks_Aggregate;
  /** fetch data from the table: "QuestionnaireLinks" using primary key columns */
  QuestionnaireLinks_by_pk?: Maybe<QuestionnaireLinks>;
  /** fetch data from the table: "QuestionnaireResponses" */
  QuestionnaireResponses: Array<QuestionnaireResponses>;
  /** fetch aggregated fields from the table: "QuestionnaireResponses" */
  QuestionnaireResponses_aggregate: QuestionnaireResponses_Aggregate;
  /** fetch data from the table: "QuestionnaireResponses" using primary key columns */
  QuestionnaireResponses_by_pk?: Maybe<QuestionnaireResponses>;
  /** fetch data from the table: "Questionnaires" */
  Questionnaires: Array<Questionnaires>;
  /** fetch aggregated fields from the table: "Questionnaires" */
  Questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "Questionnaires" using primary key columns */
  Questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table: "RRWebRecords" */
  RRWebRecords: Array<RrWebRecords>;
  /** fetch aggregated fields from the table: "RRWebRecords" */
  RRWebRecords_aggregate: RrWebRecords_Aggregate;
  /** fetch data from the table: "RRWebRecords" using primary key columns */
  RRWebRecords_by_pk?: Maybe<RrWebRecords>;
  /** fetch data from the table: "RaceEnum" */
  RaceEnum: Array<RaceEnum>;
  /** fetch aggregated fields from the table: "RaceEnum" */
  RaceEnum_aggregate: RaceEnum_Aggregate;
  /** fetch data from the table: "RaceEnum" using primary key columns */
  RaceEnum_by_pk?: Maybe<RaceEnum>;
  /** fetch data from the table: "RangeValues" */
  RangeValues: Array<RangeValues>;
  /** fetch aggregated fields from the table: "RangeValues" */
  RangeValues_aggregate: RangeValues_Aggregate;
  /** fetch data from the table: "RangeValues" using primary key columns */
  RangeValues_by_pk?: Maybe<RangeValues>;
  /** fetch data from the table: "RejectionTypeEnum" */
  RejectionTypeEnum: Array<RejectionTypeEnum>;
  /** fetch aggregated fields from the table: "RejectionTypeEnum" */
  RejectionTypeEnum_aggregate: RejectionTypeEnum_Aggregate;
  /** fetch data from the table: "RejectionTypeEnum" using primary key columns */
  RejectionTypeEnum_by_pk?: Maybe<RejectionTypeEnum>;
  /** An array relationship */
  Rejections: Array<Rejections>;
  /** An aggregate relationship */
  Rejections_aggregate: Rejections_Aggregate;
  /** fetch data from the table: "Rejections" using primary key columns */
  Rejections_by_pk?: Maybe<Rejections>;
  /** fetch data from the table: "RenderedEmails" */
  RenderedEmails: Array<RenderedEmails>;
  /** fetch aggregated fields from the table: "RenderedEmails" */
  RenderedEmails_aggregate: RenderedEmails_Aggregate;
  /** fetch data from the table: "RenderedEmails" using primary key columns */
  RenderedEmails_by_pk?: Maybe<RenderedEmails>;
  /** An array relationship */
  ResumeClaims: Array<ResumeClaims>;
  /** An aggregate relationship */
  ResumeClaims_aggregate: ResumeClaims_Aggregate;
  /** fetch data from the table: "ResumeClaims" using primary key columns */
  ResumeClaims_by_pk?: Maybe<ResumeClaims>;
  /** fetch data from the table: "ResumeEmailAddresses" */
  ResumeEmailAddresses: Array<ResumeEmailAddresses>;
  /** fetch aggregated fields from the table: "ResumeEmailAddresses" */
  ResumeEmailAddresses_aggregate: ResumeEmailAddresses_Aggregate;
  /** fetch data from the table: "ResumeEmailAddresses" using primary key columns */
  ResumeEmailAddresses_by_pk?: Maybe<ResumeEmailAddresses>;
  /** fetch data from the table: "ResumeIngestStatus" */
  ResumeIngestStatus: Array<ResumeIngestStatus>;
  /** fetch aggregated fields from the table: "ResumeIngestStatus" */
  ResumeIngestStatus_aggregate: ResumeIngestStatus_Aggregate;
  /** fetch data from the table: "ResumeIngestStatus" using primary key columns */
  ResumeIngestStatus_by_pk?: Maybe<ResumeIngestStatus>;
  /** fetch data from the table: "ResumePhoneNumbers" */
  ResumePhoneNumbers: Array<ResumePhoneNumbers>;
  /** fetch aggregated fields from the table: "ResumePhoneNumbers" */
  ResumePhoneNumbers_aggregate: ResumePhoneNumbers_Aggregate;
  /** fetch data from the table: "ResumePhoneNumbers" using primary key columns */
  ResumePhoneNumbers_by_pk?: Maybe<ResumePhoneNumbers>;
  /** An array relationship */
  Resumes: Array<Resumes>;
  /** An aggregate relationship */
  Resumes_aggregate: Resumes_Aggregate;
  /** fetch data from the table: "Resumes" using primary key columns */
  Resumes_by_pk?: Maybe<Resumes>;
  /** fetch data from the table: "ReviewCriteria" */
  ReviewCriteria: Array<ReviewCriteria>;
  /** fetch data from the table: "ReviewCriteriaScore" */
  ReviewCriteriaScore: Array<ReviewCriteriaScore>;
  /** An array relationship */
  ReviewCriteriaScoreAggregates: Array<ReviewCriteriaScoreAggregates>;
  /** An aggregate relationship */
  ReviewCriteriaScoreAggregates_aggregate: ReviewCriteriaScoreAggregates_Aggregate;
  /** fetch aggregated fields from the table: "ReviewCriteriaScore" */
  ReviewCriteriaScore_aggregate: ReviewCriteriaScore_Aggregate;
  /** fetch data from the table: "ReviewCriteriaScore" using primary key columns */
  ReviewCriteriaScore_by_pk?: Maybe<ReviewCriteriaScore>;
  /** fetch aggregated fields from the table: "ReviewCriteria" */
  ReviewCriteria_aggregate: ReviewCriteria_Aggregate;
  /** fetch data from the table: "ReviewCriteria" using primary key columns */
  ReviewCriteria_by_pk?: Maybe<ReviewCriteria>;
  /** An array relationship */
  ReviewGroupContactTemplateVariables: Array<ReviewGroupContactTemplateVariables>;
  /** An aggregate relationship */
  ReviewGroupContactTemplateVariables_aggregate: ReviewGroupContactTemplateVariables_Aggregate;
  /** fetch data from the table: "ReviewGroupContactTemplateVariables" using primary key columns */
  ReviewGroupContactTemplateVariables_by_pk?: Maybe<ReviewGroupContactTemplateVariables>;
  /** An array relationship */
  ReviewGroupCriteria: Array<ReviewGroupCriteria>;
  /** An aggregate relationship */
  ReviewGroupCriteria_aggregate: ReviewGroupCriteria_Aggregate;
  /** fetch data from the table: "ReviewGroupCriteria" using primary key columns */
  ReviewGroupCriteria_by_pk?: Maybe<ReviewGroupCriteria>;
  /** An array relationship */
  ReviewGroupFiles: Array<ReviewGroupFiles>;
  /** An aggregate relationship */
  ReviewGroupFiles_aggregate: ReviewGroupFiles_Aggregate;
  /** fetch data from the table: "ReviewGroupFiles" using primary key columns */
  ReviewGroupFiles_by_pk?: Maybe<ReviewGroupFiles>;
  /** An array relationship */
  ReviewGroupStages: Array<ReviewGroupStages>;
  /** An aggregate relationship */
  ReviewGroupStages_aggregate: ReviewGroupStages_Aggregate;
  /** fetch data from the table: "ReviewGroupStages" using primary key columns */
  ReviewGroupStages_by_pk?: Maybe<ReviewGroupStages>;
  /** fetch data from the table: "ReviewGroupSubTypeEnum" */
  ReviewGroupSubTypeEnum: Array<ReviewGroupSubTypeEnum>;
  /** fetch aggregated fields from the table: "ReviewGroupSubTypeEnum" */
  ReviewGroupSubTypeEnum_aggregate: ReviewGroupSubTypeEnum_Aggregate;
  /** fetch data from the table: "ReviewGroupSubTypeEnum" using primary key columns */
  ReviewGroupSubTypeEnum_by_pk?: Maybe<ReviewGroupSubTypeEnum>;
  /** fetch data from the table: "ReviewGroupTypeEnum" */
  ReviewGroupTypeEnum: Array<ReviewGroupTypeEnum>;
  /** fetch aggregated fields from the table: "ReviewGroupTypeEnum" */
  ReviewGroupTypeEnum_aggregate: ReviewGroupTypeEnum_Aggregate;
  /** fetch data from the table: "ReviewGroupTypeEnum" using primary key columns */
  ReviewGroupTypeEnum_by_pk?: Maybe<ReviewGroupTypeEnum>;
  /** fetch data from the table: "ReviewGroups" */
  ReviewGroups: Array<ReviewGroups>;
  /** fetch aggregated fields from the table: "ReviewGroups" */
  ReviewGroups_aggregate: ReviewGroups_Aggregate;
  /** fetch data from the table: "ReviewGroups" using primary key columns */
  ReviewGroups_by_pk?: Maybe<ReviewGroups>;
  /** An array relationship */
  Reviews: Array<Reviews>;
  /** fetch data from the table: "ReviewsResultEnum" */
  ReviewsResultEnum: Array<ReviewsResultEnum>;
  /** fetch aggregated fields from the table: "ReviewsResultEnum" */
  ReviewsResultEnum_aggregate: ReviewsResultEnum_Aggregate;
  /** fetch data from the table: "ReviewsResultEnum" using primary key columns */
  ReviewsResultEnum_by_pk?: Maybe<ReviewsResultEnum>;
  /** An aggregate relationship */
  Reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "Reviews" using primary key columns */
  Reviews_by_pk?: Maybe<Reviews>;
  /** fetch data from the table: "RoleAccess" */
  RoleAccess: Array<RoleAccess>;
  /** fetch aggregated fields from the table: "RoleAccess" */
  RoleAccess_aggregate: RoleAccess_Aggregate;
  /** fetch data from the table: "RoleAccess" using primary key columns */
  RoleAccess_by_pk?: Maybe<RoleAccess>;
  /** An array relationship */
  Roles: Array<Roles>;
  /** An aggregate relationship */
  Roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "Roles" using primary key columns */
  Roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "SearchFilterTypesEnum" */
  SearchFilterTypesEnum: Array<SearchFilterTypesEnum>;
  /** fetch aggregated fields from the table: "SearchFilterTypesEnum" */
  SearchFilterTypesEnum_aggregate: SearchFilterTypesEnum_Aggregate;
  /** fetch data from the table: "SearchFilterTypesEnum" using primary key columns */
  SearchFilterTypesEnum_by_pk?: Maybe<SearchFilterTypesEnum>;
  /** fetch data from the table: "SearchFilters" */
  SearchFilters: Array<SearchFilters>;
  /** fetch aggregated fields from the table: "SearchFilters" */
  SearchFilters_aggregate: SearchFilters_Aggregate;
  /** fetch data from the table: "SearchFilters" using primary key columns */
  SearchFilters_by_pk?: Maybe<SearchFilters>;
  /** fetch data from the table: "SecurityClearances" */
  SecurityClearances: Array<SecurityClearances>;
  /** fetch aggregated fields from the table: "SecurityClearances" */
  SecurityClearances_aggregate: SecurityClearances_Aggregate;
  /** fetch data from the table: "SecurityClearances" using primary key columns */
  SecurityClearances_by_pk?: Maybe<SecurityClearances>;
  /** fetch data from the table: "SequelizeMeta" */
  SequelizeMeta: Array<SequelizeMeta>;
  /** fetch aggregated fields from the table: "SequelizeMeta" */
  SequelizeMeta_aggregate: SequelizeMeta_Aggregate;
  /** fetch data from the table: "SequelizeMeta" using primary key columns */
  SequelizeMeta_by_pk?: Maybe<SequelizeMeta>;
  /** An array relationship */
  StageEmailContracts: Array<StageEmailContracts>;
  /** An aggregate relationship */
  StageEmailContracts_aggregate: StageEmailContracts_Aggregate;
  /** fetch data from the table: "StageEmailContracts" using primary key columns */
  StageEmailContracts_by_pk?: Maybe<StageEmailContracts>;
  /** An array relationship */
  StageEmails: Array<StageEmails>;
  /** An aggregate relationship */
  StageEmails_aggregate: StageEmails_Aggregate;
  /** fetch data from the table: "StageEmails" using primary key columns */
  StageEmails_by_pk?: Maybe<StageEmails>;
  /** fetch data from the table: "StageQuestionnaireMapping" */
  StageQuestionnaireMapping: Array<StageQuestionnaireMapping>;
  /** fetch data from the table: "StageQuestionnaireMappingIdenity" */
  StageQuestionnaireMappingIdenity: Array<StageQuestionnaireMappingIdenity>;
  /** fetch aggregated fields from the table: "StageQuestionnaireMappingIdenity" */
  StageQuestionnaireMappingIdenity_aggregate: StageQuestionnaireMappingIdenity_Aggregate;
  /** fetch data from the table: "StageQuestionnaireMappingIdenity" using primary key columns */
  StageQuestionnaireMappingIdenity_by_pk?: Maybe<StageQuestionnaireMappingIdenity>;
  /** fetch aggregated fields from the table: "StageQuestionnaireMapping" */
  StageQuestionnaireMapping_aggregate: StageQuestionnaireMapping_Aggregate;
  /** fetch data from the table: "StageQuestionnaireMapping" using primary key columns */
  StageQuestionnaireMapping_by_pk?: Maybe<StageQuestionnaireMapping>;
  /** An array relationship */
  StageQuestionnaireTemplateVariables: Array<StageQuestionnaireTemplateVariables>;
  /** An aggregate relationship */
  StageQuestionnaireTemplateVariables_aggregate: StageQuestionnaireTemplateVariables_Aggregate;
  /** fetch data from the table: "StageQuestionnaireTemplateVariables" using primary key columns */
  StageQuestionnaireTemplateVariables_by_pk?: Maybe<StageQuestionnaireTemplateVariables>;
  /** An array relationship */
  StageRoles: Array<StageRoles>;
  /** An aggregate relationship */
  StageRoles_aggregate: StageRoles_Aggregate;
  /** fetch data from the table: "StageRoles" using primary key columns */
  StageRoles_by_pk?: Maybe<StageRoles>;
  /** fetch data from the table: "StageTransitions" */
  StageTransitions: Array<StageTransitions>;
  /** fetch aggregated fields from the table: "StageTransitions" */
  StageTransitions_aggregate: StageTransitions_Aggregate;
  /** fetch data from the table: "StageTransitions" using primary key columns */
  StageTransitions_by_pk?: Maybe<StageTransitions>;
  /** An array relationship */
  Stages: Array<Stages>;
  /** An aggregate relationship */
  Stages_aggregate: Stages_Aggregate;
  /** fetch data from the table: "Stages" using primary key columns */
  Stages_by_pk?: Maybe<Stages>;
  /** fetch data from the table: "StateMachines" */
  StateMachines: Array<StateMachines>;
  /** fetch aggregated fields from the table: "StateMachines" */
  StateMachines_aggregate: StateMachines_Aggregate;
  /** fetch data from the table: "StateMachines" using primary key columns */
  StateMachines_by_pk?: Maybe<StateMachines>;
  /** An array relationship */
  Submissions: Array<Submissions>;
  /** An array relationship */
  SubmissionsOverlaysLinks: Array<SubmissionsOverlaysLinks>;
  /** An aggregate relationship */
  SubmissionsOverlaysLinks_aggregate: SubmissionsOverlaysLinks_Aggregate;
  /** fetch data from the table: "SubmissionsOverlaysLinks" using primary key columns */
  SubmissionsOverlaysLinks_by_pk?: Maybe<SubmissionsOverlaysLinks>;
  /** An aggregate relationship */
  Submissions_aggregate: Submissions_Aggregate;
  /** fetch data from the table: "Submissions" using primary key columns */
  Submissions_by_pk?: Maybe<Submissions>;
  /** fetch data from the table: "TableConfigTypes" */
  TableConfigTypes: Array<TableConfigTypes>;
  /** fetch aggregated fields from the table: "TableConfigTypes" */
  TableConfigTypes_aggregate: TableConfigTypes_Aggregate;
  /** fetch data from the table: "TableConfigTypes" using primary key columns */
  TableConfigTypes_by_pk?: Maybe<TableConfigTypes>;
  /** An array relationship */
  TableConfigs: Array<TableConfigs>;
  /** An aggregate relationship */
  TableConfigs_aggregate: TableConfigs_Aggregate;
  /** fetch data from the table: "TableConfigs" using primary key columns */
  TableConfigs_by_pk?: Maybe<TableConfigs>;
  /** fetch data from the table: "Tags" */
  Tags: Array<Tags>;
  /** fetch aggregated fields from the table: "Tags" */
  Tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "Tags" using primary key columns */
  Tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  TaskGroupAssignees: Array<TaskGroupAssignees>;
  /** An aggregate relationship */
  TaskGroupAssignees_aggregate: TaskGroupAssignees_Aggregate;
  /** fetch data from the table: "TaskGroupAssignees" using primary key columns */
  TaskGroupAssignees_by_pk?: Maybe<TaskGroupAssignees>;
  /** An array relationship */
  TaskGroups: Array<TaskGroups>;
  /** An aggregate relationship */
  TaskGroups_aggregate: TaskGroups_Aggregate;
  /** fetch data from the table: "TaskGroups" using primary key columns */
  TaskGroups_by_pk?: Maybe<TaskGroups>;
  /** fetch data from the table: "TaskPriorityTypes" */
  TaskPriorityTypes: Array<TaskPriorityTypes>;
  /** fetch aggregated fields from the table: "TaskPriorityTypes" */
  TaskPriorityTypes_aggregate: TaskPriorityTypes_Aggregate;
  /** fetch data from the table: "TaskPriorityTypes" using primary key columns */
  TaskPriorityTypes_by_pk?: Maybe<TaskPriorityTypes>;
  /** fetch data from the table: "TaskTypes" */
  TaskTypes: Array<TaskTypes>;
  /** fetch aggregated fields from the table: "TaskTypes" */
  TaskTypes_aggregate: TaskTypes_Aggregate;
  /** fetch data from the table: "TaskTypes" using primary key columns */
  TaskTypes_by_pk?: Maybe<TaskTypes>;
  /** An array relationship */
  Tasks: Array<Tasks>;
  /** An aggregate relationship */
  Tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "Tasks" using primary key columns */
  Tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  Terminations: Array<Terminations>;
  /** An aggregate relationship */
  Terminations_aggregate: Terminations_Aggregate;
  /** fetch data from the table: "Terminations" using primary key columns */
  Terminations_by_pk?: Maybe<Terminations>;
  /** fetch data from the table: "TimeInStages" */
  TimeInStages: Array<TimeInStages>;
  /** fetch aggregated fields from the table: "TimeInStages" */
  TimeInStages_aggregate: TimeInStages_Aggregate;
  /** fetch data from the table: "TimeInStages" using primary key columns */
  TimeInStages_by_pk?: Maybe<TimeInStages>;
  /** An array relationship */
  TimelineEvents: Array<TimelineEvents>;
  /** An aggregate relationship */
  TimelineEvents_aggregate: TimelineEvents_Aggregate;
  /** fetch data from the table: "TimelineEvents" using primary key columns */
  TimelineEvents_by_pk?: Maybe<TimelineEvents>;
  /** fetch data from the table: "Tokens" */
  Tokens: Array<Tokens>;
  /** fetch aggregated fields from the table: "Tokens" */
  Tokens_aggregate: Tokens_Aggregate;
  /** fetch data from the table: "Tokens" using primary key columns */
  Tokens_by_pk?: Maybe<Tokens>;
  /** fetch data from the table: "UserAccessGroups" */
  UserAccessGroups: Array<UserAccessGroups>;
  /** fetch aggregated fields from the table: "UserAccessGroups" */
  UserAccessGroups_aggregate: UserAccessGroups_Aggregate;
  /** fetch data from the table: "UserAccessGroups" using primary key columns */
  UserAccessGroups_by_pk?: Maybe<UserAccessGroups>;
  /** fetch data from the table: "UserInterfaceState" */
  UserInterfaceState: Array<UserInterfaceState>;
  /** fetch aggregated fields from the table: "UserInterfaceState" */
  UserInterfaceState_aggregate: UserInterfaceState_Aggregate;
  /** fetch data from the table: "UserInterfaceState" using primary key columns */
  UserInterfaceState_by_pk?: Maybe<UserInterfaceState>;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  /** fetch data from the table: "Users" using primary key columns */
  Users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "ValueTypes" */
  ValueTypes: Array<ValueTypes>;
  /** fetch aggregated fields from the table: "ValueTypes" */
  ValueTypes_aggregate: ValueTypes_Aggregate;
  /** fetch data from the table: "ValueTypes" using primary key columns */
  ValueTypes_by_pk?: Maybe<ValueTypes>;
  /** fetch data from the table: "VeteranStatusEnum" */
  VeteranStatusEnum: Array<VeteranStatusEnum>;
  /** fetch aggregated fields from the table: "VeteranStatusEnum" */
  VeteranStatusEnum_aggregate: VeteranStatusEnum_Aggregate;
  /** fetch data from the table: "VeteranStatusEnum" using primary key columns */
  VeteranStatusEnum_by_pk?: Maybe<VeteranStatusEnum>;
  /** fetch data from the table: "WhiteLabeling" */
  WhiteLabeling: Array<WhiteLabeling>;
  /** fetch aggregated fields from the table: "WhiteLabeling" */
  WhiteLabeling_aggregate: WhiteLabeling_Aggregate;
  /** fetch data from the table: "WhiteLabeling" using primary key columns */
  WhiteLabeling_by_pk?: Maybe<WhiteLabeling>;
  /** fetch data from the table: "WorkflowRegressions" */
  WorkflowRegressions: Array<WorkflowRegressions>;
  /** fetch aggregated fields from the table: "WorkflowRegressions" */
  WorkflowRegressions_aggregate: WorkflowRegressions_Aggregate;
  /** fetch data from the table: "WorkflowRegressions" using primary key columns */
  WorkflowRegressions_by_pk?: Maybe<WorkflowRegressions>;
  activityTotals?: Maybe<Array<Maybe<ActivityTotals>>>;
  /** execute function "activity_count_stat" which returns "identity_roles_stat" */
  activity_count_stat: Array<Identity_Roles_Stat>;
  /** execute function "activity_count_stat" and query aggregates on result of table type "identity_roles_stat" */
  activity_count_stat_aggregate: Identity_Roles_Stat_Aggregate;
  /** execute function "activity_counts_rm_totals_only" which returns "identity_roles_arr" */
  activity_counts_rm_totals_only: Array<Identity_Roles_Arr>;
  /** execute function "activity_counts_rm_totals_only" and query aggregates on result of table type "identity_roles_arr" */
  activity_counts_rm_totals_only_aggregate: Identity_Roles_Arr_Aggregate;
  /** fetch data from the table: "ams_for_analytics_ui" */
  ams_for_analytics_ui: Array<Ams_For_Analytics_Ui>;
  /** fetch aggregated fields from the table: "ams_for_analytics_ui" */
  ams_for_analytics_ui_aggregate: Ams_For_Analytics_Ui_Aggregate;
  /** fetch data from the table: "analytics_view" */
  analytics_view: Array<Analytics_View>;
  /** fetch aggregated fields from the table: "analytics_view" */
  analytics_view_aggregate: Analytics_View_Aggregate;
  /** fetch data from the table: "candidate_account_managers" */
  candidate_account_managers: Array<Candidate_Account_Managers>;
  /** fetch aggregated fields from the table: "candidate_account_managers" */
  candidate_account_managers_aggregate: Candidate_Account_Managers_Aggregate;
  /** fetch data from the table: "citizenshipEnum" */
  citizenshipEnum: Array<CitizenshipEnum>;
  /** fetch aggregated fields from the table: "citizenshipEnum" */
  citizenshipEnum_aggregate: CitizenshipEnum_Aggregate;
  /** fetch data from the table: "citizenshipEnum" using primary key columns */
  citizenshipEnum_by_pk?: Maybe<CitizenshipEnum>;
  /** fetch data from the table: "comments_for_analytics" */
  comments_for_analytics: Array<Comments_For_Analytics>;
  /** fetch aggregated fields from the table: "comments_for_analytics" */
  comments_for_analytics_aggregate: Comments_For_Analytics_Aggregate;
  /** fetch data from the table: "comments_for_analytics_join_underlying_view" */
  comments_for_analytics_join_underlying_view: Array<Comments_For_Analytics_Join_Underlying_View>;
  /** fetch aggregated fields from the table: "comments_for_analytics_join_underlying_view" */
  comments_for_analytics_join_underlying_view_aggregate: Comments_For_Analytics_Join_Underlying_View_Aggregate;
  /** An array relationship */
  contract_emails: Array<Contract_Emails>;
  /** An aggregate relationship */
  contract_emails_aggregate: Contract_Emails_Aggregate;
  /** fetch data from the table: "contract_emails" using primary key columns */
  contract_emails_by_pk?: Maybe<Contract_Emails>;
  conversionReportQuery?: Maybe<ConversionReportQueryOutput>;
  /** fetch data from the table: "current_wk_activity" */
  current_wk_activity: Array<Current_Wk_Activity>;
  /** fetch aggregated fields from the table: "current_wk_activity" */
  current_wk_activity_aggregate: Current_Wk_Activity_Aggregate;
  /** fetch data from the table: "current_workflows_for_identity" */
  current_workflows_for_identity: Array<Current_Workflows_For_Identity>;
  /** fetch aggregated fields from the table: "current_workflows_for_identity" */
  current_workflows_for_identity_aggregate: Current_Workflows_For_Identity_Aggregate;
  /** fetch data from the table: "dashboard_activity_counts_by_day" */
  dashboard_activity_counts_by_day: Array<Dashboard_Activity_Counts_By_Day>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_day" */
  dashboard_activity_counts_by_day_aggregate: Dashboard_Activity_Counts_By_Day_Aggregate;
  /** fetch data from the table: "dashboard_activity_counts_by_hour" */
  dashboard_activity_counts_by_hour: Array<Dashboard_Activity_Counts_By_Hour>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_hour" */
  dashboard_activity_counts_by_hour_aggregate: Dashboard_Activity_Counts_By_Hour_Aggregate;
  /** fetch data from the table: "dashboard_activity_counts_by_month" */
  dashboard_activity_counts_by_month: Array<Dashboard_Activity_Counts_By_Month>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_month" */
  dashboard_activity_counts_by_month_aggregate: Dashboard_Activity_Counts_By_Month_Aggregate;
  /** fetch data from the table: "dashboard_activity_counts_by_week" */
  dashboard_activity_counts_by_week: Array<Dashboard_Activity_Counts_By_Week>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_week" */
  dashboard_activity_counts_by_week_aggregate: Dashboard_Activity_Counts_By_Week_Aggregate;
  /** fetch data from the table: "dashboard_stage_counts_by_day" */
  dashboard_stage_counts_by_day: Array<Dashboard_Stage_Counts_By_Day>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_day" */
  dashboard_stage_counts_by_day_aggregate: Dashboard_Stage_Counts_By_Day_Aggregate;
  /** fetch data from the table: "dashboard_stage_counts_by_month" */
  dashboard_stage_counts_by_month: Array<Dashboard_Stage_Counts_By_Month>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_month" */
  dashboard_stage_counts_by_month_aggregate: Dashboard_Stage_Counts_By_Month_Aggregate;
  /** fetch data from the table: "dashboard_stage_counts_by_week" */
  dashboard_stage_counts_by_week: Array<Dashboard_Stage_Counts_By_Week>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_week" */
  dashboard_stage_counts_by_week_aggregate: Dashboard_Stage_Counts_By_Week_Aggregate;
  /** fetch data from the table: "dashboard_stage_counts_by_year" */
  dashboard_stage_counts_by_year: Array<Dashboard_Stage_Counts_By_Year>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_year" */
  dashboard_stage_counts_by_year_aggregate: Dashboard_Stage_Counts_By_Year_Aggregate;
  /** fetch data from the table: "dashboard_stages_counts" */
  dashboard_stages_counts: Array<Dashboard_Stages_Counts>;
  /** fetch aggregated fields from the table: "dashboard_stages_counts" */
  dashboard_stages_counts_aggregate: Dashboard_Stages_Counts_Aggregate;
  /** execute function "dashboard_stages_counts_for_date_range" which returns "dashboard_stages_counts" */
  dashboard_stages_counts_for_date_range: Array<Dashboard_Stages_Counts>;
  /** execute function "dashboard_stages_counts_for_date_range" and query aggregates on result of table type "dashboard_stages_counts" */
  dashboard_stages_counts_for_date_range_aggregate: Dashboard_Stages_Counts_Aggregate;
  /** fetch data from the table: "deleted_ppl_names" */
  deleted_ppl_names: Array<Deleted_Ppl_Names>;
  /** fetch aggregated fields from the table: "deleted_ppl_names" */
  deleted_ppl_names_aggregate: Deleted_Ppl_Names_Aggregate;
  duplicateIdentitiesByMobilePhone?: Maybe<Array<Maybe<DuplicateIdentitiesOutput>>>;
  /** fetch data from the table: "eeo_count_by_comp_bucket_disability" */
  eeo_count_by_comp_bucket_disability: Array<Eeo_Count_By_Comp_Bucket_Disability>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_disability" */
  eeo_count_by_comp_bucket_disability_aggregate: Eeo_Count_By_Comp_Bucket_Disability_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_disability_mat" */
  eeo_count_by_comp_bucket_disability_mat: Array<Eeo_Count_By_Comp_Bucket_Disability_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_disability_mat" */
  eeo_count_by_comp_bucket_disability_mat_aggregate: Eeo_Count_By_Comp_Bucket_Disability_Mat_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_eeo_category" */
  eeo_count_by_comp_bucket_eeo_category: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_eeo_category" */
  eeo_count_by_comp_bucket_eeo_category_aggregate: Eeo_Count_By_Comp_Bucket_Eeo_Category_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_eeo_category_mat" */
  eeo_count_by_comp_bucket_eeo_category_mat: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_eeo_category_mat" */
  eeo_count_by_comp_bucket_eeo_category_mat_aggregate: Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_gender" */
  eeo_count_by_comp_bucket_gender: Array<Eeo_Count_By_Comp_Bucket_Gender>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_gender" */
  eeo_count_by_comp_bucket_gender_aggregate: Eeo_Count_By_Comp_Bucket_Gender_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_gender_mat" */
  eeo_count_by_comp_bucket_gender_mat: Array<Eeo_Count_By_Comp_Bucket_Gender_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_gender_mat" */
  eeo_count_by_comp_bucket_gender_mat_aggregate: Eeo_Count_By_Comp_Bucket_Gender_Mat_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_race" */
  eeo_count_by_comp_bucket_race: Array<Eeo_Count_By_Comp_Bucket_Race>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_race" */
  eeo_count_by_comp_bucket_race_aggregate: Eeo_Count_By_Comp_Bucket_Race_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_race_mat" */
  eeo_count_by_comp_bucket_race_mat: Array<Eeo_Count_By_Comp_Bucket_Race_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_race_mat" */
  eeo_count_by_comp_bucket_race_mat_aggregate: Eeo_Count_By_Comp_Bucket_Race_Mat_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_vet_status" */
  eeo_count_by_comp_bucket_vet_status: Array<Eeo_Count_By_Comp_Bucket_Vet_Status>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_vet_status" */
  eeo_count_by_comp_bucket_vet_status_aggregate: Eeo_Count_By_Comp_Bucket_Vet_Status_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_vet_status_mat" */
  eeo_count_by_comp_bucket_vet_status_mat: Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_vet_status_mat" */
  eeo_count_by_comp_bucket_vet_status_mat_aggregate: Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Aggregate;
  /** fetch data from the table: "eeo_count_by_race_gender_hisp_by_cat_pivot" */
  eeo_count_by_race_gender_hisp_by_cat_pivot: Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot>;
  /** fetch aggregated fields from the table: "eeo_count_by_race_gender_hisp_by_cat_pivot" */
  eeo_count_by_race_gender_hisp_by_cat_pivot_aggregate: Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Aggregate;
  /** fetch data from the table: "eeo_data_point_status_by_identity_view" */
  eeo_data_point_status_by_identity_view: Array<Eeo_Data_Point_Status_By_Identity_View>;
  /** fetch aggregated fields from the table: "eeo_data_point_status_by_identity_view" */
  eeo_data_point_status_by_identity_view_aggregate: Eeo_Data_Point_Status_By_Identity_View_Aggregate;
  /** fetch data from the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  eeo_data_points_unknown_as_other_by_identity_view: Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View>;
  /** fetch aggregated fields from the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  eeo_data_points_unknown_as_other_by_identity_view_aggregate: Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Aggregate;
  /** fetch data from the table: "eeo_labor_categories_comp_buckets" */
  eeo_labor_categories_comp_buckets: Array<Eeo_Labor_Categories_Comp_Buckets>;
  /** fetch aggregated fields from the table: "eeo_labor_categories_comp_buckets" */
  eeo_labor_categories_comp_buckets_aggregate: Eeo_Labor_Categories_Comp_Buckets_Aggregate;
  /** fetch data from the table: "eeo_pivot_data_by_comp_bucket" */
  eeo_pivot_data_by_comp_bucket: Array<Eeo_Pivot_Data_By_Comp_Bucket>;
  /** fetch aggregated fields from the table: "eeo_pivot_data_by_comp_bucket" */
  eeo_pivot_data_by_comp_bucket_aggregate: Eeo_Pivot_Data_By_Comp_Bucket_Aggregate;
  /** fetch data from the table: "eeo_status_by_identity" */
  eeo_status_by_identity: Array<Eeo_Status_By_Identity>;
  /** fetch aggregated fields from the table: "eeo_status_by_identity" */
  eeo_status_by_identity_aggregate: Eeo_Status_By_Identity_Aggregate;
  /** fetch data from the table: "eeo_status_by_identity_with_compensation_and_bucket" */
  eeo_status_by_identity_with_compensation_and_bucket: Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket>;
  /** fetch aggregated fields from the table: "eeo_status_by_identity_with_compensation_and_bucket" */
  eeo_status_by_identity_with_compensation_and_bucket_aggregate: Eeo_Status_By_Identity_With_Compensation_And_Bucket_Aggregate;
  /** fetch data from the table: "eeo_summary_by_gender" */
  eeo_summary_by_gender: Array<Eeo_Summary_By_Gender>;
  /** fetch aggregated fields from the table: "eeo_summary_by_gender" */
  eeo_summary_by_gender_aggregate: Eeo_Summary_By_Gender_Aggregate;
  /** fetch data from the table: "eeo_summary_by_has_disability" */
  eeo_summary_by_has_disability: Array<Eeo_Summary_By_Has_Disability>;
  /** fetch aggregated fields from the table: "eeo_summary_by_has_disability" */
  eeo_summary_by_has_disability_aggregate: Eeo_Summary_By_Has_Disability_Aggregate;
  /** fetch data from the table: "eeo_summary_by_race" */
  eeo_summary_by_race: Array<Eeo_Summary_By_Race>;
  /** fetch aggregated fields from the table: "eeo_summary_by_race" */
  eeo_summary_by_race_aggregate: Eeo_Summary_By_Race_Aggregate;
  /** fetch data from the table: "eeo_summary_by_veteran_status" */
  eeo_summary_by_veteran_status: Array<Eeo_Summary_By_Veteran_Status>;
  /** fetch aggregated fields from the table: "eeo_summary_by_veteran_status" */
  eeo_summary_by_veteran_status_aggregate: Eeo_Summary_By_Veteran_Status_Aggregate;
  /** fetch data from the table: "email_events" */
  email_events: Array<Email_Events>;
  /** fetch aggregated fields from the table: "email_events" */
  email_events_aggregate: Email_Events_Aggregate;
  /** fetch data from the table: "filled_jobs_report" */
  filled_jobs_report: Array<Filled_Jobs_Report>;
  /** fetch aggregated fields from the table: "filled_jobs_report" */
  filled_jobs_report_aggregate: Filled_Jobs_Report_Aggregate;
  /** fetch data from the table: "filled_jobs_subreport" */
  filled_jobs_subreport: Array<Filled_Jobs_Subreport>;
  /** fetch aggregated fields from the table: "filled_jobs_subreport" */
  filled_jobs_subreport_aggregate: Filled_Jobs_Subreport_Aggregate;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** Gets company config from parameter store */
  getCompanyConfig?: Maybe<ConfigResponse>;
  /** execute function "global_search_function_contracts" which returns "Contracts" */
  global_search_function_contracts: Array<Contracts>;
  /** execute function "global_search_function_contracts" and query aggregates on result of table type "Contracts" */
  global_search_function_contracts_aggregate: Contracts_Aggregate;
  /** execute function "global_search_function_identities" which returns "Identities" */
  global_search_function_identities: Array<Identities>;
  /** execute function "global_search_function_identities" and query aggregates on result of table type "Identities" */
  global_search_function_identities_aggregate: Identities_Aggregate;
  /** execute function "global_search_function_jobs" which returns "Jobs" */
  global_search_function_jobs: Array<Jobs>;
  /** execute function "global_search_function_jobs" and query aggregates on result of table type "Jobs" */
  global_search_function_jobs_aggregate: Jobs_Aggregate;
  identitiesFTS: Array<IdentityFtsHit>;
  /** fetch data from the table: "identities_for_external_reviews" */
  identities_for_external_reviews: Array<Identities_For_External_Reviews>;
  /** fetch aggregated fields from the table: "identities_for_external_reviews" */
  identities_for_external_reviews_aggregate: Identities_For_External_Reviews_Aggregate;
  identityForExternalReviews?: Maybe<IdentityForExternalReviewsOutput>;
  /** fetch data from the table: "identity_roles_arr" */
  identity_roles_arr: Array<Identity_Roles_Arr>;
  /** fetch aggregated fields from the table: "identity_roles_arr" */
  identity_roles_arr_aggregate: Identity_Roles_Arr_Aggregate;
  /** fetch data from the table: "identity_roles_joined" */
  identity_roles_joined: Array<Identity_Roles_Joined>;
  /** fetch aggregated fields from the table: "identity_roles_joined" */
  identity_roles_joined_aggregate: Identity_Roles_Joined_Aggregate;
  /** fetch data from the table: "identity_roles_stat" */
  identity_roles_stat: Array<Identity_Roles_Stat>;
  /** fetch aggregated fields from the table: "identity_roles_stat" */
  identity_roles_stat_aggregate: Identity_Roles_Stat_Aggregate;
  /** fetch data from the table: "identity_roles_view" */
  identity_roles_view: Array<Identity_Roles_View>;
  /** fetch aggregated fields from the table: "identity_roles_view" */
  identity_roles_view_aggregate: Identity_Roles_View_Aggregate;
  idibuAccountDetails?: Maybe<IdibuAccountDetailsOutput>;
  idibuBoardDetails?: Maybe<IdibuBoardDetailsOutput>;
  idibuSubscribedBoards: Array<IdibuSubscribedBoardsOutput>;
  /** fetch data from the table: "internal_users" */
  internal_users: Array<Internal_Users>;
  /** fetch aggregated fields from the table: "internal_users" */
  internal_users_aggregate: Internal_Users_Aggregate;
  /** fetch data from the table: "job_contracts_group_id" */
  job_contracts_group_id: Array<Job_Contracts_Group_Id>;
  /** fetch aggregated fields from the table: "job_contracts_group_id" */
  job_contracts_group_id_aggregate: Job_Contracts_Group_Id_Aggregate;
  /** fetch data from the table: "jobboardsnippetscounts" */
  jobboardsnippetscounts: Array<Jobboardsnippetscounts>;
  /** fetch aggregated fields from the table: "jobboardsnippetscounts" */
  jobboardsnippetscounts_aggregate: Jobboardsnippetscounts_Aggregate;
  /** fetch data from the table: "jobs_available_positions" */
  jobs_available_positions: Array<Jobs_Available_Positions>;
  /** fetch aggregated fields from the table: "jobs_available_positions" */
  jobs_available_positions_aggregate: Jobs_Available_Positions_Aggregate;
  /** fetch data from the table: "matches" */
  matches: Array<Matches>;
  /** fetch aggregated fields from the table: "matches" */
  matches_aggregate: Matches_Aggregate;
  /** fetch data from the table: "matches_from_applications" */
  matches_from_applications: Array<Matches_From_Applications>;
  /** fetch aggregated fields from the table: "matches_from_applications" */
  matches_from_applications_aggregate: Matches_From_Applications_Aggregate;
  /** fetch data from the table: "matches_from_board" */
  matches_from_board: Array<Matches_From_Board>;
  /** fetch aggregated fields from the table: "matches_from_board" */
  matches_from_board_aggregate: Matches_From_Board_Aggregate;
  /** fetch data from the table: "matches_from_identities" */
  matches_from_identities: Array<Matches_From_Identities>;
  /** fetch aggregated fields from the table: "matches_from_identities" */
  matches_from_identities_aggregate: Matches_From_Identities_Aggregate;
  /** fetch data from the table: "matches_from_tasks" */
  matches_from_tasks: Array<Matches_From_Tasks>;
  /** fetch aggregated fields from the table: "matches_from_tasks" */
  matches_from_tasks_aggregate: Matches_From_Tasks_Aggregate;
  /** fetch data from the table: "matches_grouped" */
  matches_grouped: Array<Matches_Grouped>;
  /** fetch aggregated fields from the table: "matches_grouped" */
  matches_grouped_aggregate: Matches_Grouped_Aggregate;
  /** fetch data from the table: "matches_union" */
  matches_union: Array<Matches_Union>;
  /** fetch aggregated fields from the table: "matches_union" */
  matches_union_aggregate: Matches_Union_Aggregate;
  /** fetch data from the table: "matches_without_author_with_int_uuid" */
  matches_without_author_with_int_uuid: Array<Matches_Without_Author_With_Int_Uuid>;
  /** fetch aggregated fields from the table: "matches_without_author_with_int_uuid" */
  matches_without_author_with_int_uuid_aggregate: Matches_Without_Author_With_Int_Uuid_Aggregate;
  nylasIntegrationStatus?: Maybe<Array<Maybe<IntegrationStatus>>>;
  /** fetch data from the table: "nylas_events" */
  nylas_events: Array<Nylas_Events>;
  /** fetch aggregated fields from the table: "nylas_events" */
  nylas_events_aggregate: Nylas_Events_Aggregate;
  /** fetch data from the table: "nylas_events" using primary key columns */
  nylas_events_by_pk?: Maybe<Nylas_Events>;
  /** fetch data from the table: "ofccp_compliance" */
  ofccp_compliance: Array<Ofccp_Compliance>;
  /** fetch aggregated fields from the table: "ofccp_compliance" */
  ofccp_compliance_aggregate: Ofccp_Compliance_Aggregate;
  /** fetch data from the table: "rms" */
  rms: Array<Rms>;
  /** fetch aggregated fields from the table: "rms" */
  rms_aggregate: Rms_Aggregate;
  /** fetch data from the table: "rms_for_analytics_ui" */
  rms_for_analytics_ui: Array<Rms_For_Analytics_Ui>;
  /** fetch aggregated fields from the table: "rms_for_analytics_ui" */
  rms_for_analytics_ui_aggregate: Rms_For_Analytics_Ui_Aggregate;
  /** fetch data from the table: "search_spec_results" */
  search_spec_results: Array<Search_Spec_Results>;
  /** fetch aggregated fields from the table: "search_spec_results" */
  search_spec_results_aggregate: Search_Spec_Results_Aggregate;
  /** fetch data from the table: "search_spec_results" using primary key columns */
  search_spec_results_by_pk?: Maybe<Search_Spec_Results>;
  /** fetch data from the table: "search_specs" */
  search_specs: Array<Search_Specs>;
  /** fetch aggregated fields from the table: "search_specs" */
  search_specs_aggregate: Search_Specs_Aggregate;
  /** fetch data from the table: "search_specs" using primary key columns */
  search_specs_by_pk?: Maybe<Search_Specs>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table: "staffing_status" */
  staffing_status: Array<Staffing_Status>;
  /** fetch aggregated fields from the table: "staffing_status" */
  staffing_status_aggregate: Staffing_Status_Aggregate;
  /** fetch data from the table: "stages_conversion_mview" */
  stages_conversion_mview: Array<Stages_Conversion_Mview>;
  /** fetch aggregated fields from the table: "stages_conversion_mview" */
  stages_conversion_mview_aggregate: Stages_Conversion_Mview_Aggregate;
  /** fetch data from the table: "text_messages" */
  text_messages: Array<Text_Messages>;
  /** fetch aggregated fields from the table: "text_messages" */
  text_messages_aggregate: Text_Messages_Aggregate;
  /** fetch data from the table: "text_messages" using primary key columns */
  text_messages_by_pk?: Maybe<Text_Messages>;
  /** fetch data from the table: "util.deps_saved_ddl" */
  util_deps_saved_ddl: Array<Util_Deps_Saved_Ddl>;
  /** fetch aggregated fields from the table: "util.deps_saved_ddl" */
  util_deps_saved_ddl_aggregate: Util_Deps_Saved_Ddl_Aggregate;
  /** fetch data from the table: "util.deps_saved_ddl" using primary key columns */
  util_deps_saved_ddl_by_pk?: Maybe<Util_Deps_Saved_Ddl>;
  /** execute function "verify_api_token" which returns "APITokens" */
  verify_api_token: Array<ApiTokens>;
  /** execute function "verify_api_token" and query aggregates on result of table type "APITokens" */
  verify_api_token_aggregate: ApiTokens_Aggregate;
  /** fetch data from the table: "workflow_contracts" */
  workflow_contracts: Array<Workflow_Contracts>;
  /** fetch aggregated fields from the table: "workflow_contracts" */
  workflow_contracts_aggregate: Workflow_Contracts_Aggregate;
  /** fetch data from the table: "workflow_contracts" using primary key columns */
  workflow_contracts_by_pk?: Maybe<Workflow_Contracts>;
  /** An array relationship */
  workflow_departments: Array<Workflow_Departments>;
  /** An aggregate relationship */
  workflow_departments_aggregate: Workflow_Departments_Aggregate;
  /** fetch data from the table: "workflow_departments" using primary key columns */
  workflow_departments_by_pk?: Maybe<Workflow_Departments>;
};


export type Query_RootApiTokensArgs = {
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Query_RootApiTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Query_RootApiTokens_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Query_RootAccessIntentsArgs = {
  distinct_on?: InputMaybe<Array<AccessIntents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessIntents_Order_By>>;
  where?: InputMaybe<AccessIntents_Bool_Exp>;
};


export type Query_RootAccessIntents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccessIntents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessIntents_Order_By>>;
  where?: InputMaybe<AccessIntents_Bool_Exp>;
};


export type Query_RootAccessIntents_By_PkArgs = {
  intent: Scalars['String'];
};


export type Query_RootAccessTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessTypeEnum_Order_By>>;
  where?: InputMaybe<AccessTypeEnum_Bool_Exp>;
};


export type Query_RootAccessTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessTypeEnum_Order_By>>;
  where?: InputMaybe<AccessTypeEnum_Bool_Exp>;
};


export type Query_RootAccessTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootActiveEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<ActiveEntitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActiveEntitlements_Order_By>>;
  where?: InputMaybe<ActiveEntitlements_Bool_Exp>;
};


export type Query_RootActiveEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActiveEntitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActiveEntitlements_Order_By>>;
  where?: InputMaybe<ActiveEntitlements_Bool_Exp>;
};


export type Query_RootActivityLogTypesArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLogTypes_Order_By>>;
  where?: InputMaybe<ActivityLogTypes_Bool_Exp>;
};


export type Query_RootActivityLogTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLogTypes_Order_By>>;
  where?: InputMaybe<ActivityLogTypes_Bool_Exp>;
};


export type Query_RootActivityLogTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootActivityTemplatesArgs = {
  distinct_on?: InputMaybe<Array<ActivityTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityTemplates_Order_By>>;
  where?: InputMaybe<ActivityTemplates_Bool_Exp>;
};


export type Query_RootActivityTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityTemplates_Order_By>>;
  where?: InputMaybe<ActivityTemplates_Bool_Exp>;
};


export type Query_RootActivityTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAoIdentifierTypesArgs = {
  distinct_on?: InputMaybe<Array<AoIdentifierTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AoIdentifierTypes_Order_By>>;
  where?: InputMaybe<AoIdentifierTypes_Bool_Exp>;
};


export type Query_RootAoIdentifierTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AoIdentifierTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AoIdentifierTypes_Order_By>>;
  where?: InputMaybe<AoIdentifierTypes_Bool_Exp>;
};


export type Query_RootAoIdentifierTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApplicationStatusArgs = {
  distinct_on?: InputMaybe<Array<ApplicationStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationStatus_Order_By>>;
  where?: InputMaybe<ApplicationStatus_Bool_Exp>;
};


export type Query_RootApplicationStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ApplicationStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationStatus_Order_By>>;
  where?: InputMaybe<ApplicationStatus_Bool_Exp>;
};


export type Query_RootApplicationStatus_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootApplicationTypeArgs = {
  distinct_on?: InputMaybe<Array<ApplicationType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationType_Order_By>>;
  where?: InputMaybe<ApplicationType_Bool_Exp>;
};


export type Query_RootApplicationType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ApplicationType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationType_Order_By>>;
  where?: InputMaybe<ApplicationType_Bool_Exp>;
};


export type Query_RootApplicationType_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootApplicationsArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


export type Query_RootApplications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


export type Query_RootApplications_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Query_RootAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Query_RootAssignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAsyncOperationsArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperations_Order_By>>;
  where?: InputMaybe<AsyncOperations_Bool_Exp>;
};


export type Query_RootAsyncOperationsStatesArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperationsStates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperationsStates_Order_By>>;
  where?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
};


export type Query_RootAsyncOperationsStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperationsStates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperationsStates_Order_By>>;
  where?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
};


export type Query_RootAsyncOperationsStates_By_PkArgs = {
  state: Scalars['String'];
};


export type Query_RootAsyncOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperations_Order_By>>;
  where?: InputMaybe<AsyncOperations_Bool_Exp>;
};


export type Query_RootAsyncOperations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Query_RootAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Query_RootAttachments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAuditCreatesArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


export type Query_RootAuditCreates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


export type Query_RootAuditCreates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAuditStagesArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


export type Query_RootAuditStagesWithWorkflowBeginAndStartedDatesArgs = {
  distinct_on?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Order_By>>;
  where?: InputMaybe<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>;
};


export type Query_RootAuditStagesWithWorkflowBeginAndStartedDates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Order_By>>;
  where?: InputMaybe<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>;
};


export type Query_RootAuditStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


export type Query_RootAuditStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAuthAuditsArgs = {
  distinct_on?: InputMaybe<Array<AuthAudits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthAudits_Order_By>>;
  where?: InputMaybe<AuthAudits_Bool_Exp>;
};


export type Query_RootAuthAudits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthAudits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthAudits_Order_By>>;
  where?: InputMaybe<AuthAudits_Bool_Exp>;
};


export type Query_RootAuthAudits_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBenefitArgs = {
  distinct_on?: InputMaybe<Array<Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Benefit_Order_By>>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};


export type Query_RootBenefitTypeIdentitySubTypesArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


export type Query_RootBenefitTypeIdentitySubTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


export type Query_RootBenefitTypeIdentitySubTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBenefitTypesArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypes_Order_By>>;
  where?: InputMaybe<BenefitTypes_Bool_Exp>;
};


export type Query_RootBenefitTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypes_Order_By>>;
  where?: InputMaybe<BenefitTypes_Bool_Exp>;
};


export type Query_RootBenefitTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBenefit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Benefit_Order_By>>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};


export type Query_RootBenefit_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBoardFiltersArgs = {
  distinct_on?: InputMaybe<Array<BoardFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardFilters_Order_By>>;
  where?: InputMaybe<BoardFilters_Bool_Exp>;
};


export type Query_RootBoardFilters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardFilters_Order_By>>;
  where?: InputMaybe<BoardFilters_Bool_Exp>;
};


export type Query_RootBoardFilters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


export type Query_RootBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


export type Query_RootBoardSnippets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBullhornSyncStatusArgs = {
  distinct_on?: InputMaybe<Array<BullhornSyncStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BullhornSyncStatus_Order_By>>;
  where?: InputMaybe<BullhornSyncStatus_Bool_Exp>;
};


export type Query_RootBullhornSyncStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BullhornSyncStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BullhornSyncStatus_Order_By>>;
  where?: InputMaybe<BullhornSyncStatus_Bool_Exp>;
};


export type Query_RootBullhornSyncStatus_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCalcEquationResultsArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


export type Query_RootCalcEquationResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


export type Query_RootCalcEquationResults_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCalcFormValuesArgs = {
  distinct_on?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcFormValues_Order_By>>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};


export type Query_RootCalcFormValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcFormValues_Order_By>>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};


export type Query_RootCalcFormValues_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCalcsArgs = {
  distinct_on?: InputMaybe<Array<Calcs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calcs_Order_By>>;
  where?: InputMaybe<Calcs_Bool_Exp>;
};


export type Query_RootCalcs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calcs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calcs_Order_By>>;
  where?: InputMaybe<Calcs_Bool_Exp>;
};


export type Query_RootCalcs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCalculationTemplateVersionsArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplateVersions_Order_By>>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};


export type Query_RootCalculationTemplateVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplateVersions_Order_By>>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};


export type Query_RootCalculationTemplateVersions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCalculationTemplatesArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplates_Order_By>>;
  where?: InputMaybe<CalculationTemplates_Bool_Exp>;
};


export type Query_RootCalculationTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplates_Order_By>>;
  where?: InputMaybe<CalculationTemplates_Bool_Exp>;
};


export type Query_RootCalculationTemplates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCalculationVersionFormInputsArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


export type Query_RootCalculationVersionFormInputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


export type Query_RootCalculationVersionFormInputs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCalculationsArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


export type Query_RootCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


export type Query_RootCalculations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCandidateSourcesArgs = {
  distinct_on?: InputMaybe<Array<CandidateSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateSources_Order_By>>;
  where?: InputMaybe<CandidateSources_Bool_Exp>;
};


export type Query_RootCandidateSources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CandidateSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateSources_Order_By>>;
  where?: InputMaybe<CandidateSources_Bool_Exp>;
};


export type Query_RootCandidateSources_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCandidateStatusesArgs = {
  distinct_on?: InputMaybe<Array<CandidateStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateStatuses_Order_By>>;
  where?: InputMaybe<CandidateStatuses_Bool_Exp>;
};


export type Query_RootCandidateStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CandidateStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateStatuses_Order_By>>;
  where?: InputMaybe<CandidateStatuses_Bool_Exp>;
};


export type Query_RootCandidateStatuses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCaptureNodesArgs = {
  distinct_on?: InputMaybe<Array<CaptureNodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CaptureNodes_Order_By>>;
  where?: InputMaybe<CaptureNodes_Bool_Exp>;
};


export type Query_RootCaptureNodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CaptureNodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CaptureNodes_Order_By>>;
  where?: InputMaybe<CaptureNodes_Bool_Exp>;
};


export type Query_RootCaptureNodes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClientSideExternalSystemIntegrationPluginRecordArgs = {
  distinct_on?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Order_By>>;
  where?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>;
};


export type Query_RootClientSideExternalSystemIntegrationPluginRecord_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Order_By>>;
  where?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>;
};


export type Query_RootClientSideExternalSystemIntegrationPluginRecord_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCognitoUserDataArgs = {
  distinct_on?: InputMaybe<Array<CognitoUserData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CognitoUserData_Order_By>>;
  where?: InputMaybe<CognitoUserData_Bool_Exp>;
};


export type Query_RootCognitoUserData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CognitoUserData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CognitoUserData_Order_By>>;
  where?: InputMaybe<CognitoUserData_Bool_Exp>;
};


export type Query_RootCognitoUserData_By_PkArgs = {
  cog_id: Scalars['String'];
};


export type Query_RootCommentTypesArgs = {
  distinct_on?: InputMaybe<Array<CommentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommentTypes_Order_By>>;
  where?: InputMaybe<CommentTypes_Bool_Exp>;
};


export type Query_RootCommentTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommentTypes_Order_By>>;
  where?: InputMaybe<CommentTypes_Bool_Exp>;
};


export type Query_RootCommentTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Query_RootCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Query_RootCompanies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompanyEventsArgs = {
  distinct_on?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyEvents_Order_By>>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};


export type Query_RootCompanyEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyEvents_Order_By>>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};


export type Query_RootCompanyEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompanyStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<CompanyStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyStatusEnum_Order_By>>;
  where?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
};


export type Query_RootCompanyStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyStatusEnum_Order_By>>;
  where?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
};


export type Query_RootCompanyStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCompensationsArgs = {
  distinct_on?: InputMaybe<Array<Compensations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compensations_Order_By>>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};


export type Query_RootCompensations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compensations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compensations_Order_By>>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};


export type Query_RootCompensations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContactTemplateVariablesEnumArgs = {
  distinct_on?: InputMaybe<Array<ContactTemplateVariablesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactTemplateVariablesEnum_Order_By>>;
  where?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
};


export type Query_RootContactTemplateVariablesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactTemplateVariablesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactTemplateVariablesEnum_Order_By>>;
  where?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
};


export type Query_RootContactTemplateVariablesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootContractCommentsArgs = {
  distinct_on?: InputMaybe<Array<ContractComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractComments_Order_By>>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};


export type Query_RootContractComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractComments_Order_By>>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};


export type Query_RootContractComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContractFilesArgs = {
  distinct_on?: InputMaybe<Array<ContractFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFiles_Order_By>>;
  where?: InputMaybe<ContractFiles_Bool_Exp>;
};


export type Query_RootContractFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFiles_Order_By>>;
  where?: InputMaybe<ContractFiles_Bool_Exp>;
};


export type Query_RootContractFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContractStagesArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


export type Query_RootContractStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


export type Query_RootContractStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContractStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<ContractStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStatusEnum_Order_By>>;
  where?: InputMaybe<ContractStatusEnum_Bool_Exp>;
};


export type Query_RootContractStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStatusEnum_Order_By>>;
  where?: InputMaybe<ContractStatusEnum_Bool_Exp>;
};


export type Query_RootContractStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootContractsArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Query_RootContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Query_RootContracts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCurrentStagesArgs = {
  distinct_on?: InputMaybe<Array<CurrentStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentStages_Order_By>>;
  where?: InputMaybe<CurrentStages_Bool_Exp>;
};


export type Query_RootCurrentStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CurrentStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentStages_Order_By>>;
  where?: InputMaybe<CurrentStages_Bool_Exp>;
};


export type Query_RootCurrentStages_By_PkArgs = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
};


export type Query_RootDataHistoryArgs = {
  distinct_on?: InputMaybe<Array<DataHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataHistory_Order_By>>;
  where?: InputMaybe<DataHistory_Bool_Exp>;
};


export type Query_RootDataHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DataHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataHistory_Order_By>>;
  where?: InputMaybe<DataHistory_Bool_Exp>;
};


export type Query_RootDataHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Departments_Order_By>>;
  where?: InputMaybe<Departments_Bool_Exp>;
};


export type Query_RootDepartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Departments_Order_By>>;
  where?: InputMaybe<Departments_Bool_Exp>;
};


export type Query_RootDepartments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDisabilityStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<DisabilityStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DisabilityStatusEnum_Order_By>>;
  where?: InputMaybe<DisabilityStatusEnum_Bool_Exp>;
};


export type Query_RootDisabilityStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DisabilityStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DisabilityStatusEnum_Order_By>>;
  where?: InputMaybe<DisabilityStatusEnum_Bool_Exp>;
};


export type Query_RootDisabilityStatusEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootEeoJobCategoriesEnumArgs = {
  distinct_on?: InputMaybe<Array<EeoJobCategoriesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EeoJobCategoriesEnum_Order_By>>;
  where?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
};


export type Query_RootEeoJobCategoriesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EeoJobCategoriesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EeoJobCategoriesEnum_Order_By>>;
  where?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
};


export type Query_RootEeoJobCategoriesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootEarnedBadgesArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


export type Query_RootEarnedBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


export type Query_RootEarnedBadges_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmailAddressesArgs = {
  distinct_on?: InputMaybe<Array<EmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailAddresses_Order_By>>;
  where?: InputMaybe<EmailAddresses_Bool_Exp>;
};


export type Query_RootEmailAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailAddresses_Order_By>>;
  where?: InputMaybe<EmailAddresses_Bool_Exp>;
};


export type Query_RootEmailAddresses_By_PkArgs = {
  normalized_email: Scalars['String'];
};


export type Query_RootEmailEventsArgs = {
  distinct_on?: InputMaybe<Array<EmailEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailEvents_Order_By>>;
  where?: InputMaybe<EmailEvents_Bool_Exp>;
};


export type Query_RootEmailEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailEvents_Order_By>>;
  where?: InputMaybe<EmailEvents_Bool_Exp>;
};


export type Query_RootEmailEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmailRecordsArgs = {
  distinct_on?: InputMaybe<Array<EmailRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailRecords_Order_By>>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};


export type Query_RootEmailRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailRecords_Order_By>>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};


export type Query_RootEmailRecords_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmailSignaturesArgs = {
  distinct_on?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSignatures_Order_By>>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};


export type Query_RootEmailSignatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSignatures_Order_By>>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};


export type Query_RootEmailSignatures_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmailSubFoldersArgs = {
  distinct_on?: InputMaybe<Array<EmailSubFolders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSubFolders_Order_By>>;
  where?: InputMaybe<EmailSubFolders_Bool_Exp>;
};


export type Query_RootEmailSubFolders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailSubFolders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSubFolders_Order_By>>;
  where?: InputMaybe<EmailSubFolders_Bool_Exp>;
};


export type Query_RootEmailSubFolders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmailTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<EmailTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypeEnum_Order_By>>;
  where?: InputMaybe<EmailTypeEnum_Bool_Exp>;
};


export type Query_RootEmailTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypeEnum_Order_By>>;
  where?: InputMaybe<EmailTypeEnum_Bool_Exp>;
};


export type Query_RootEmailTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEmailTypesArgs = {
  distinct_on?: InputMaybe<Array<EmailTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypes_Order_By>>;
  where?: InputMaybe<EmailTypes_Bool_Exp>;
};


export type Query_RootEmailTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypes_Order_By>>;
  where?: InputMaybe<EmailTypes_Bool_Exp>;
};


export type Query_RootEmailTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Query_RootEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Query_RootEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmploymentHireTypeArgs = {
  distinct_on?: InputMaybe<Array<EmploymentHireType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentHireType_Order_By>>;
  where?: InputMaybe<EmploymentHireType_Bool_Exp>;
};


export type Query_RootEmploymentHireType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmploymentHireType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentHireType_Order_By>>;
  where?: InputMaybe<EmploymentHireType_Bool_Exp>;
};


export type Query_RootEmploymentHireType_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootEmploymentTypesArgs = {
  distinct_on?: InputMaybe<Array<EmploymentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentTypes_Order_By>>;
  where?: InputMaybe<EmploymentTypes_Bool_Exp>;
};


export type Query_RootEmploymentTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmploymentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentTypes_Order_By>>;
  where?: InputMaybe<EmploymentTypes_Bool_Exp>;
};


export type Query_RootEmploymentTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEmploymentsArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


export type Query_RootEmployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


export type Query_RootEmployments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEntitlementCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EntitlementCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntitlementCategories_Order_By>>;
  where?: InputMaybe<EntitlementCategories_Bool_Exp>;
};


export type Query_RootEntitlementCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntitlementCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntitlementCategories_Order_By>>;
  where?: InputMaybe<EntitlementCategories_Bool_Exp>;
};


export type Query_RootEntitlementCategories_By_PkArgs = {
  category: Scalars['String'];
};


export type Query_RootEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entitlements_Order_By>>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};


export type Query_RootEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entitlements_Order_By>>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};


export type Query_RootEntitlements_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEquationFormulaOptionArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


export type Query_RootEquationFormulaOption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


export type Query_RootEquationFormulaOption_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEquationsArgs = {
  distinct_on?: InputMaybe<Array<Equations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equations_Order_By>>;
  where?: InputMaybe<Equations_Bool_Exp>;
};


export type Query_RootEquations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Equations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equations_Order_By>>;
  where?: InputMaybe<Equations_Bool_Exp>;
};


export type Query_RootEquations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootExpenseArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Query_RootExpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Query_RootExpense_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootExternalIntegrationTypesArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrationTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrationTypes_Order_By>>;
  where?: InputMaybe<ExternalIntegrationTypes_Bool_Exp>;
};


export type Query_RootExternalIntegrationTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrationTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrationTypes_Order_By>>;
  where?: InputMaybe<ExternalIntegrationTypes_Bool_Exp>;
};


export type Query_RootExternalIntegrationTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootExternalIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrations_Order_By>>;
  where?: InputMaybe<ExternalIntegrations_Bool_Exp>;
};


export type Query_RootExternalIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrations_Order_By>>;
  where?: InputMaybe<ExternalIntegrations_Bool_Exp>;
};


export type Query_RootExternalIntegrations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExternalJobBoardPublishingsArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


export type Query_RootExternalJobBoardPublishings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


export type Query_RootExternalJobBoardPublishings_By_PkArgs = {
  external_job_board_id: Scalars['uuid'];
  job_id: Scalars['Int'];
};


export type Query_RootExternalJobBoardsArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoards_Order_By>>;
  where?: InputMaybe<ExternalJobBoards_Bool_Exp>;
};


export type Query_RootExternalJobBoards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoards_Order_By>>;
  where?: InputMaybe<ExternalJobBoards_Bool_Exp>;
};


export type Query_RootExternalJobBoards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExternalSystemArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystem_Order_By>>;
  where?: InputMaybe<ExternalSystem_Bool_Exp>;
};


export type Query_RootExternalSystemInstanceArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystemInstance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystemInstance_Order_By>>;
  where?: InputMaybe<ExternalSystemInstance_Bool_Exp>;
};


export type Query_RootExternalSystemInstance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystemInstance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystemInstance_Order_By>>;
  where?: InputMaybe<ExternalSystemInstance_Bool_Exp>;
};


export type Query_RootExternalSystemInstance_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExternalSystem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystem_Order_By>>;
  where?: InputMaybe<ExternalSystem_Bool_Exp>;
};


export type Query_RootExternalSystem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFeatureFlagsArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlags_Order_By>>;
  where?: InputMaybe<FeatureFlags_Bool_Exp>;
};


export type Query_RootFeatureFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlags_Order_By>>;
  where?: InputMaybe<FeatureFlags_Bool_Exp>;
};


export type Query_RootFeatureFlags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFieldArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


export type Query_RootFieldDefArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


export type Query_RootFieldDef_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


export type Query_RootFieldDef_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFieldPicklistValuesArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};


export type Query_RootFieldPicklistValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};


export type Query_RootFieldPicklistValues_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFieldTypeDefArgs = {
  distinct_on?: InputMaybe<Array<FieldTypeDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldTypeDef_Order_By>>;
  where?: InputMaybe<FieldTypeDef_Bool_Exp>;
};


export type Query_RootFieldTypeDef_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldTypeDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldTypeDef_Order_By>>;
  where?: InputMaybe<FieldTypeDef_Bool_Exp>;
};


export type Query_RootFieldTypeDef_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


export type Query_RootField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFileTagsArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


export type Query_RootFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


export type Query_RootFileTags_By_PkArgs = {
  file_id: Scalars['Int'];
  tag_id: Scalars['uuid'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFormInputsArgs = {
  distinct_on?: InputMaybe<Array<FormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormInputs_Order_By>>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};


export type Query_RootFormInputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormInputs_Order_By>>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};


export type Query_RootFormInputs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFormSelectOptionsArgs = {
  distinct_on?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormSelectOptions_Order_By>>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};


export type Query_RootFormSelectOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormSelectOptions_Order_By>>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};


export type Query_RootFormSelectOptions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFormulaOptionsArgs = {
  distinct_on?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormulaOptions_Order_By>>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};


export type Query_RootFormulaOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormulaOptions_Order_By>>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};


export type Query_RootFormulaOptions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGenderEnumArgs = {
  distinct_on?: InputMaybe<Array<GenderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenderEnum_Order_By>>;
  where?: InputMaybe<GenderEnum_Bool_Exp>;
};


export type Query_RootGenderEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenderEnum_Order_By>>;
  where?: InputMaybe<GenderEnum_Bool_Exp>;
};


export type Query_RootGenderEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootGeocodingHistoryArgs = {
  distinct_on?: InputMaybe<Array<GeocodingHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GeocodingHistory_Order_By>>;
  where?: InputMaybe<GeocodingHistory_Bool_Exp>;
};


export type Query_RootGeocodingHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeocodingHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GeocodingHistory_Order_By>>;
  where?: InputMaybe<GeocodingHistory_Bool_Exp>;
};


export type Query_RootGeocodingHistory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Query_RootGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Query_RootGoals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGoogleSheetCalcTemplatesArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalcTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalcTemplates_Order_By>>;
  where?: InputMaybe<GoogleSheetCalcTemplates_Bool_Exp>;
};


export type Query_RootGoogleSheetCalcTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalcTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalcTemplates_Order_By>>;
  where?: InputMaybe<GoogleSheetCalcTemplates_Bool_Exp>;
};


export type Query_RootGoogleSheetCalcTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGoogleSheetCalculationsArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalculations_Order_By>>;
  where?: InputMaybe<GoogleSheetCalculations_Bool_Exp>;
};


export type Query_RootGoogleSheetCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalculations_Order_By>>;
  where?: InputMaybe<GoogleSheetCalculations_Bool_Exp>;
};


export type Query_RootGoogleSheetCalculations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroupJobsArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


export type Query_RootGroupJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


export type Query_RootGroupJobs_By_PkArgs = {
  groupId: Scalars['uuid'];
  jobId: Scalars['Int'];
};


export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootHighestStagesArgs = {
  distinct_on?: InputMaybe<Array<HighestStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HighestStages_Order_By>>;
  where?: InputMaybe<HighestStages_Bool_Exp>;
};


export type Query_RootHighestStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HighestStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HighestStages_Order_By>>;
  where?: InputMaybe<HighestStages_Bool_Exp>;
};


export type Query_RootHighestStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootHireTypesEnumArgs = {
  distinct_on?: InputMaybe<Array<HireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HireTypesEnum_Order_By>>;
  where?: InputMaybe<HireTypesEnum_Bool_Exp>;
};


export type Query_RootHireTypesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HireTypesEnum_Order_By>>;
  where?: InputMaybe<HireTypesEnum_Bool_Exp>;
};


export type Query_RootHireTypesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootItRequestContractEmailsArgs = {
  distinct_on?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItRequestContractEmails_Order_By>>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};


export type Query_RootItRequestContractEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItRequestContractEmails_Order_By>>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};


export type Query_RootItRequestContractEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Query_RootIdentitiesOverlaysLinksArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


export type Query_RootIdentitiesOverlaysLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


export type Query_RootIdentitiesOverlaysLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Query_RootIdentities_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentityAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


export type Query_RootIdentityAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


export type Query_RootIdentityAssignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentityCommentsArgs = {
  distinct_on?: InputMaybe<Array<IdentityComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityComments_Order_By>>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};


export type Query_RootIdentityComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityComments_Order_By>>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};


export type Query_RootIdentityComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentityContractRelationshipArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


export type Query_RootIdentityContractRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


export type Query_RootIdentityContractRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIdentityDepartmentRelationshipArgs = {
  distinct_on?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityDepartmentRelationship_Order_By>>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};


export type Query_RootIdentityDepartmentRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityDepartmentRelationship_Order_By>>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};


export type Query_RootIdentityDepartmentRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIdentityGroupsArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


export type Query_RootIdentityGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


export type Query_RootIdentityGroups_By_PkArgs = {
  groupId: Scalars['uuid'];
  identityId: Scalars['Int'];
};


export type Query_RootIdentityJobRelationshipArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


export type Query_RootIdentityJobRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


export type Query_RootIdentityJobRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIdentityPartnerGroupDataArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


export type Query_RootIdentityPartnerGroupData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


export type Query_RootIdentityPartnerGroupData_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentityRelationshipTypeArgs = {
  distinct_on?: InputMaybe<Array<IdentityRelationshipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRelationshipType_Order_By>>;
  where?: InputMaybe<IdentityRelationshipType_Bool_Exp>;
};


export type Query_RootIdentityRelationshipType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityRelationshipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRelationshipType_Order_By>>;
  where?: InputMaybe<IdentityRelationshipType_Bool_Exp>;
};


export type Query_RootIdentityRelationshipType_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootIdentityRolesArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


export type Query_RootIdentityRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


export type Query_RootIdentitySubTypeEmailsArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


export type Query_RootIdentitySubTypeEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


export type Query_RootIdentitySubTypeEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentitySubTypesArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypes_Order_By>>;
  where?: InputMaybe<IdentitySubTypes_Bool_Exp>;
};


export type Query_RootIdentitySubTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypes_Order_By>>;
  where?: InputMaybe<IdentitySubTypes_Bool_Exp>;
};


export type Query_RootIdentitySubTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentityTagsArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


export type Query_RootIdentityTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


export type Query_RootIdentityTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIdentityTokensArgs = {
  distinct_on?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTokens_Order_By>>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};


export type Query_RootIdentityTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTokens_Order_By>>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};


export type Query_RootIdentityTokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentityTypesArgs = {
  distinct_on?: InputMaybe<Array<IdentityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTypes_Order_By>>;
  where?: InputMaybe<IdentityTypes_Bool_Exp>;
};


export type Query_RootIdentityTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTypes_Order_By>>;
  where?: InputMaybe<IdentityTypes_Bool_Exp>;
};


export type Query_RootIdentityTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIdentityUserAccessGroupArgs = {
  distinct_on?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityUserAccessGroup_Order_By>>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};


export type Query_RootIdentityUserAccessGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityUserAccessGroup_Order_By>>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};


export type Query_RootIdentityUserAccessGroup_By_PkArgs = {
  identity_id: Scalars['Int'];
  user_access_group_id: Scalars['uuid'];
};


export type Query_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInputTypesArgs = {
  distinct_on?: InputMaybe<Array<InputTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InputTypes_Order_By>>;
  where?: InputMaybe<InputTypes_Bool_Exp>;
};


export type Query_RootInputTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InputTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InputTypes_Order_By>>;
  where?: InputMaybe<InputTypes_Bool_Exp>;
};


export type Query_RootInputTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootInternalJobCategoriesArgs = {
  distinct_on?: InputMaybe<Array<InternalJobCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalJobCategories_Order_By>>;
  where?: InputMaybe<InternalJobCategories_Bool_Exp>;
};


export type Query_RootInternalJobCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalJobCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalJobCategories_Order_By>>;
  where?: InputMaybe<InternalJobCategories_Bool_Exp>;
};


export type Query_RootInternalJobCategories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInterviewsArgs = {
  distinct_on?: InputMaybe<Array<Interviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interviews_Order_By>>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};


export type Query_RootInterviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interviews_Order_By>>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};


export type Query_RootInterviews_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootJobAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<JobAvailabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobAvailabilities_Order_By>>;
  where?: InputMaybe<JobAvailabilities_Bool_Exp>;
};


export type Query_RootJobAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobAvailabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobAvailabilities_Order_By>>;
  where?: InputMaybe<JobAvailabilities_Bool_Exp>;
};


export type Query_RootJobAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJobCommentsArgs = {
  distinct_on?: InputMaybe<Array<JobComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobComments_Order_By>>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};


export type Query_RootJobComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobComments_Order_By>>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};


export type Query_RootJobComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootJobFilesArgs = {
  distinct_on?: InputMaybe<Array<JobFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFiles_Order_By>>;
  where?: InputMaybe<JobFiles_Bool_Exp>;
};


export type Query_RootJobFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFiles_Order_By>>;
  where?: InputMaybe<JobFiles_Bool_Exp>;
};


export type Query_RootJobFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJobFundingStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<JobFundingStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFundingStatusEnum_Order_By>>;
  where?: InputMaybe<JobFundingStatusEnum_Bool_Exp>;
};


export type Query_RootJobFundingStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobFundingStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFundingStatusEnum_Order_By>>;
  where?: InputMaybe<JobFundingStatusEnum_Bool_Exp>;
};


export type Query_RootJobFundingStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootJobHireTypesEnumArgs = {
  distinct_on?: InputMaybe<Array<JobHireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobHireTypesEnum_Order_By>>;
  where?: InputMaybe<JobHireTypesEnum_Bool_Exp>;
};


export type Query_RootJobHireTypesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobHireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobHireTypesEnum_Order_By>>;
  where?: InputMaybe<JobHireTypesEnum_Bool_Exp>;
};


export type Query_RootJobHireTypesEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootJobPositionsArgs = {
  distinct_on?: InputMaybe<Array<JobPositions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobPositions_Order_By>>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};


export type Query_RootJobPositions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobPositions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobPositions_Order_By>>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};


export type Query_RootJobPositions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootJobStatusesArgs = {
  distinct_on?: InputMaybe<Array<JobStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatuses_Order_By>>;
  where?: InputMaybe<JobStatuses_Bool_Exp>;
};


export type Query_RootJobStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatuses_Order_By>>;
  where?: InputMaybe<JobStatuses_Bool_Exp>;
};


export type Query_RootJobStatuses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Query_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Query_RootJobs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMagicLinksArgs = {
  distinct_on?: InputMaybe<Array<MagicLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MagicLinks_Order_By>>;
  where?: InputMaybe<MagicLinks_Bool_Exp>;
};


export type Query_RootMagicLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MagicLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MagicLinks_Order_By>>;
  where?: InputMaybe<MagicLinks_Bool_Exp>;
};


export type Query_RootMagicLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMigrationDataArgs = {
  distinct_on?: InputMaybe<Array<MigrationData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MigrationData_Order_By>>;
  where?: InputMaybe<MigrationData_Bool_Exp>;
};


export type Query_RootMigrationData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MigrationData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MigrationData_Order_By>>;
  where?: InputMaybe<MigrationData_Bool_Exp>;
};


export type Query_RootMigrationData_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootObjectZooArgs = {
  distinct_on?: InputMaybe<Array<ObjectZoo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ObjectZoo_Order_By>>;
  where?: InputMaybe<ObjectZoo_Bool_Exp>;
};


export type Query_RootObjectZoo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ObjectZoo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ObjectZoo_Order_By>>;
  where?: InputMaybe<ObjectZoo_Bool_Exp>;
};


export type Query_RootObjectZoo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOverlayArgs = {
  distinct_on?: InputMaybe<Array<Overlay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Overlay_Order_By>>;
  where?: InputMaybe<Overlay_Bool_Exp>;
};


export type Query_RootOverlayDefinitionArgs = {
  distinct_on?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OverlayDefinition_Order_By>>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};


export type Query_RootOverlayDefinition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OverlayDefinition_Order_By>>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};


export type Query_RootOverlayDefinition_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOverlay_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Overlay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Overlay_Order_By>>;
  where?: InputMaybe<Overlay_Bool_Exp>;
};


export type Query_RootOverlay_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPhoneNumbersArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumbers_Order_By>>;
  where?: InputMaybe<PhoneNumbers_Bool_Exp>;
};


export type Query_RootPhoneNumbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumbers_Order_By>>;
  where?: InputMaybe<PhoneNumbers_Bool_Exp>;
};


export type Query_RootPhoneNumbers_By_PkArgs = {
  e164_form: Scalars['String'];
};


export type Query_RootPicklistValuesArgs = {
  distinct_on?: InputMaybe<Array<PicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PicklistValues_Order_By>>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};


export type Query_RootPicklistValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PicklistValues_Order_By>>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};


export type Query_RootPicklistValues_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPicklistsArgs = {
  distinct_on?: InputMaybe<Array<Picklists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picklists_Order_By>>;
  where?: InputMaybe<Picklists_Bool_Exp>;
};


export type Query_RootPicklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picklists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picklists_Order_By>>;
  where?: InputMaybe<Picklists_Bool_Exp>;
};


export type Query_RootPicklists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductUpdatesArgs = {
  distinct_on?: InputMaybe<Array<ProductUpdates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductUpdates_Order_By>>;
  where?: InputMaybe<ProductUpdates_Bool_Exp>;
};


export type Query_RootProductUpdates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductUpdates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductUpdates_Order_By>>;
  where?: InputMaybe<ProductUpdates_Bool_Exp>;
};


export type Query_RootProductUpdates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootQuestionnaireContactTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Order_By>>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};


export type Query_RootQuestionnaireContactTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Order_By>>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};


export type Query_RootQuestionnaireContactTemplateVariables_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};


export type Query_RootQuestionnaireLinksArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireLinks_Order_By>>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};


export type Query_RootQuestionnaireLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireLinks_Order_By>>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};


export type Query_RootQuestionnaireLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestionnaireResponsesArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireResponses_Order_By>>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};


export type Query_RootQuestionnaireResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireResponses_Order_By>>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};


export type Query_RootQuestionnaireResponses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRrWebRecordsArgs = {
  distinct_on?: InputMaybe<Array<RrWebRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RrWebRecords_Order_By>>;
  where?: InputMaybe<RrWebRecords_Bool_Exp>;
};


export type Query_RootRrWebRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RrWebRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RrWebRecords_Order_By>>;
  where?: InputMaybe<RrWebRecords_Bool_Exp>;
};


export type Query_RootRrWebRecords_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRaceEnumArgs = {
  distinct_on?: InputMaybe<Array<RaceEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RaceEnum_Order_By>>;
  where?: InputMaybe<RaceEnum_Bool_Exp>;
};


export type Query_RootRaceEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RaceEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RaceEnum_Order_By>>;
  where?: InputMaybe<RaceEnum_Bool_Exp>;
};


export type Query_RootRaceEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootRangeValuesArgs = {
  distinct_on?: InputMaybe<Array<RangeValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RangeValues_Order_By>>;
  where?: InputMaybe<RangeValues_Bool_Exp>;
};


export type Query_RootRangeValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RangeValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RangeValues_Order_By>>;
  where?: InputMaybe<RangeValues_Bool_Exp>;
};


export type Query_RootRangeValues_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRejectionTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<RejectionTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RejectionTypeEnum_Order_By>>;
  where?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
};


export type Query_RootRejectionTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RejectionTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RejectionTypeEnum_Order_By>>;
  where?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
};


export type Query_RootRejectionTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootRejectionsArgs = {
  distinct_on?: InputMaybe<Array<Rejections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rejections_Order_By>>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};


export type Query_RootRejections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rejections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rejections_Order_By>>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};


export type Query_RootRejections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRenderedEmailsArgs = {
  distinct_on?: InputMaybe<Array<RenderedEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RenderedEmails_Order_By>>;
  where?: InputMaybe<RenderedEmails_Bool_Exp>;
};


export type Query_RootRenderedEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RenderedEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RenderedEmails_Order_By>>;
  where?: InputMaybe<RenderedEmails_Bool_Exp>;
};


export type Query_RootRenderedEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootResumeClaimsArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


export type Query_RootResumeClaims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


export type Query_RootResumeClaims_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootResumeEmailAddressesArgs = {
  distinct_on?: InputMaybe<Array<ResumeEmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeEmailAddresses_Order_By>>;
  where?: InputMaybe<ResumeEmailAddresses_Bool_Exp>;
};


export type Query_RootResumeEmailAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeEmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeEmailAddresses_Order_By>>;
  where?: InputMaybe<ResumeEmailAddresses_Bool_Exp>;
};


export type Query_RootResumeEmailAddresses_By_PkArgs = {
  normalized_email: Scalars['String'];
  resume_id: Scalars['Int'];
};


export type Query_RootResumeIngestStatusArgs = {
  distinct_on?: InputMaybe<Array<ResumeIngestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeIngestStatus_Order_By>>;
  where?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
};


export type Query_RootResumeIngestStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeIngestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeIngestStatus_Order_By>>;
  where?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
};


export type Query_RootResumeIngestStatus_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootResumePhoneNumbersArgs = {
  distinct_on?: InputMaybe<Array<ResumePhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumePhoneNumbers_Order_By>>;
  where?: InputMaybe<ResumePhoneNumbers_Bool_Exp>;
};


export type Query_RootResumePhoneNumbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumePhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumePhoneNumbers_Order_By>>;
  where?: InputMaybe<ResumePhoneNumbers_Bool_Exp>;
};


export type Query_RootResumePhoneNumbers_By_PkArgs = {
  e164_form: Scalars['String'];
  resume_id: Scalars['Int'];
};


export type Query_RootResumesArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


export type Query_RootResumes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


export type Query_RootResumes_By_PkArgs = {
  company_id: Scalars['Int'];
  data_hash: Scalars['String'];
};


export type Query_RootReviewCriteriaArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteria_Order_By>>;
  where?: InputMaybe<ReviewCriteria_Bool_Exp>;
};


export type Query_RootReviewCriteriaScoreArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


export type Query_RootReviewCriteriaScoreAggregatesArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
};


export type Query_RootReviewCriteriaScoreAggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
};


export type Query_RootReviewCriteriaScore_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


export type Query_RootReviewCriteriaScore_By_PkArgs = {
  review_criteria_id: Scalars['uuid'];
  review_id: Scalars['Int'];
};


export type Query_RootReviewCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteria_Order_By>>;
  where?: InputMaybe<ReviewCriteria_Bool_Exp>;
};


export type Query_RootReviewCriteria_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReviewGroupContactTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Order_By>>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};


export type Query_RootReviewGroupContactTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Order_By>>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};


export type Query_RootReviewGroupContactTemplateVariables_By_PkArgs = {
  review_group_id: Scalars['Int'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};


export type Query_RootReviewGroupCriteriaArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


export type Query_RootReviewGroupCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


export type Query_RootReviewGroupCriteria_By_PkArgs = {
  review_criteria_id: Scalars['uuid'];
  review_group_id: Scalars['Int'];
};


export type Query_RootReviewGroupFilesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


export type Query_RootReviewGroupFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


export type Query_RootReviewGroupFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReviewGroupStagesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupStages_Order_By>>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};


export type Query_RootReviewGroupStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupStages_Order_By>>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};


export type Query_RootReviewGroupStages_By_PkArgs = {
  review_group_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};


export type Query_RootReviewGroupSubTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupSubTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupSubTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupSubTypeEnum_Bool_Exp>;
};


export type Query_RootReviewGroupSubTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupSubTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupSubTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupSubTypeEnum_Bool_Exp>;
};


export type Query_RootReviewGroupSubTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootReviewGroupTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
};


export type Query_RootReviewGroupTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
};


export type Query_RootReviewGroupTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootReviewGroupsArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroups_Order_By>>;
  where?: InputMaybe<ReviewGroups_Bool_Exp>;
};


export type Query_RootReviewGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroups_Order_By>>;
  where?: InputMaybe<ReviewGroups_Bool_Exp>;
};


export type Query_RootReviewGroups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Query_RootReviewsResultEnumArgs = {
  distinct_on?: InputMaybe<Array<ReviewsResultEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewsResultEnum_Order_By>>;
  where?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
};


export type Query_RootReviewsResultEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewsResultEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewsResultEnum_Order_By>>;
  where?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
};


export type Query_RootReviewsResultEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Query_RootReviews_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRoleAccessArgs = {
  distinct_on?: InputMaybe<Array<RoleAccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleAccess_Order_By>>;
  where?: InputMaybe<RoleAccess_Bool_Exp>;
};


export type Query_RootRoleAccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RoleAccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleAccess_Order_By>>;
  where?: InputMaybe<RoleAccess_Bool_Exp>;
};


export type Query_RootRoleAccess_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSearchFilterTypesEnumArgs = {
  distinct_on?: InputMaybe<Array<SearchFilterTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilterTypesEnum_Order_By>>;
  where?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
};


export type Query_RootSearchFilterTypesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SearchFilterTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilterTypesEnum_Order_By>>;
  where?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
};


export type Query_RootSearchFilterTypesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootSearchFiltersArgs = {
  distinct_on?: InputMaybe<Array<SearchFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilters_Order_By>>;
  where?: InputMaybe<SearchFilters_Bool_Exp>;
};


export type Query_RootSearchFilters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SearchFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilters_Order_By>>;
  where?: InputMaybe<SearchFilters_Bool_Exp>;
};


export type Query_RootSearchFilters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSecurityClearancesArgs = {
  distinct_on?: InputMaybe<Array<SecurityClearances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecurityClearances_Order_By>>;
  where?: InputMaybe<SecurityClearances_Bool_Exp>;
};


export type Query_RootSecurityClearances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SecurityClearances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecurityClearances_Order_By>>;
  where?: InputMaybe<SecurityClearances_Bool_Exp>;
};


export type Query_RootSecurityClearances_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSequelizeMetaArgs = {
  distinct_on?: InputMaybe<Array<SequelizeMeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SequelizeMeta_Order_By>>;
  where?: InputMaybe<SequelizeMeta_Bool_Exp>;
};


export type Query_RootSequelizeMeta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SequelizeMeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SequelizeMeta_Order_By>>;
  where?: InputMaybe<SequelizeMeta_Bool_Exp>;
};


export type Query_RootSequelizeMeta_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootStageEmailContractsArgs = {
  distinct_on?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmailContracts_Order_By>>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};


export type Query_RootStageEmailContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmailContracts_Order_By>>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};


export type Query_RootStageEmailContracts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStageEmailsArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


export type Query_RootStageEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


export type Query_RootStageEmails_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStageQuestionnaireMappingArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMapping_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};


export type Query_RootStageQuestionnaireMappingIdenityArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};


export type Query_RootStageQuestionnaireMappingIdenity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};


export type Query_RootStageQuestionnaireMappingIdenity_By_PkArgs = {
  identity_id: Scalars['Int'];
  stage_questionnaire_mapping_id: Scalars['uuid'];
};


export type Query_RootStageQuestionnaireMapping_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMapping_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};


export type Query_RootStageQuestionnaireMapping_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStageQuestionnaireTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Order_By>>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};


export type Query_RootStageQuestionnaireTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Order_By>>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};


export type Query_RootStageQuestionnaireTemplateVariables_By_PkArgs = {
  contact_variable: ContactTemplateVariablesEnum_Enum;
  stage_questionnaire_mapping_id: Scalars['uuid'];
};


export type Query_RootStageRolesArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


export type Query_RootStageRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


export type Query_RootStageRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStageTransitionsArgs = {
  distinct_on?: InputMaybe<Array<StageTransitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageTransitions_Order_By>>;
  where?: InputMaybe<StageTransitions_Bool_Exp>;
};


export type Query_RootStageTransitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageTransitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageTransitions_Order_By>>;
  where?: InputMaybe<StageTransitions_Bool_Exp>;
};


export type Query_RootStageTransitions_By_PkArgs = {
  identity_id: Scalars['Int'];
  in_time: Scalars['timestamptz'];
  job_id: Scalars['Int'];
  out_time: Scalars['timestamptz'];
};


export type Query_RootStagesArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


export type Query_RootStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


export type Query_RootStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStateMachinesArgs = {
  distinct_on?: InputMaybe<Array<StateMachines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StateMachines_Order_By>>;
  where?: InputMaybe<StateMachines_Bool_Exp>;
};


export type Query_RootStateMachines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StateMachines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StateMachines_Order_By>>;
  where?: InputMaybe<StateMachines_Bool_Exp>;
};


export type Query_RootStateMachines_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSubmissionsArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


export type Query_RootSubmissionsOverlaysLinksArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


export type Query_RootSubmissionsOverlaysLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


export type Query_RootSubmissionsOverlaysLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSubmissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


export type Query_RootSubmissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTableConfigTypesArgs = {
  distinct_on?: InputMaybe<Array<TableConfigTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigTypes_Order_By>>;
  where?: InputMaybe<TableConfigTypes_Bool_Exp>;
};


export type Query_RootTableConfigTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TableConfigTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigTypes_Order_By>>;
  where?: InputMaybe<TableConfigTypes_Bool_Exp>;
};


export type Query_RootTableConfigTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTableConfigsArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


export type Query_RootTableConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


export type Query_RootTableConfigs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTaskGroupAssigneesArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


export type Query_RootTaskGroupAssignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


export type Query_RootTaskGroupAssignees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTaskGroupsArgs = {
  distinct_on?: InputMaybe<Array<TaskGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroups_Order_By>>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};


export type Query_RootTaskGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroups_Order_By>>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};


export type Query_RootTaskGroups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTaskPriorityTypesArgs = {
  distinct_on?: InputMaybe<Array<TaskPriorityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskPriorityTypes_Order_By>>;
  where?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
};


export type Query_RootTaskPriorityTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskPriorityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskPriorityTypes_Order_By>>;
  where?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
};


export type Query_RootTaskPriorityTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTaskTypesArgs = {
  distinct_on?: InputMaybe<Array<TaskTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskTypes_Order_By>>;
  where?: InputMaybe<TaskTypes_Bool_Exp>;
};


export type Query_RootTaskTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskTypes_Order_By>>;
  where?: InputMaybe<TaskTypes_Bool_Exp>;
};


export type Query_RootTaskTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTerminationsArgs = {
  distinct_on?: InputMaybe<Array<Terminations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminations_Order_By>>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};


export type Query_RootTerminations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminations_Order_By>>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};


export type Query_RootTerminations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTimeInStagesArgs = {
  distinct_on?: InputMaybe<Array<TimeInStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimeInStages_Order_By>>;
  where?: InputMaybe<TimeInStages_Bool_Exp>;
};


export type Query_RootTimeInStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TimeInStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimeInStages_Order_By>>;
  where?: InputMaybe<TimeInStages_Bool_Exp>;
};


export type Query_RootTimeInStages_By_PkArgs = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};


export type Query_RootTimelineEventsArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


export type Query_RootTimelineEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


export type Query_RootTimelineEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Query_RootTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Query_RootTokens_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserAccessGroupsArgs = {
  distinct_on?: InputMaybe<Array<UserAccessGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAccessGroups_Order_By>>;
  where?: InputMaybe<UserAccessGroups_Bool_Exp>;
};


export type Query_RootUserAccessGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAccessGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAccessGroups_Order_By>>;
  where?: InputMaybe<UserAccessGroups_Bool_Exp>;
};


export type Query_RootUserAccessGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserInterfaceStateArgs = {
  distinct_on?: InputMaybe<Array<UserInterfaceState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInterfaceState_Order_By>>;
  where?: InputMaybe<UserInterfaceState_Bool_Exp>;
};


export type Query_RootUserInterfaceState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInterfaceState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInterfaceState_Order_By>>;
  where?: InputMaybe<UserInterfaceState_Bool_Exp>;
};


export type Query_RootUserInterfaceState_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootValueTypesArgs = {
  distinct_on?: InputMaybe<Array<ValueTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ValueTypes_Order_By>>;
  where?: InputMaybe<ValueTypes_Bool_Exp>;
};


export type Query_RootValueTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ValueTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ValueTypes_Order_By>>;
  where?: InputMaybe<ValueTypes_Bool_Exp>;
};


export type Query_RootValueTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootVeteranStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<VeteranStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VeteranStatusEnum_Order_By>>;
  where?: InputMaybe<VeteranStatusEnum_Bool_Exp>;
};


export type Query_RootVeteranStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VeteranStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VeteranStatusEnum_Order_By>>;
  where?: InputMaybe<VeteranStatusEnum_Bool_Exp>;
};


export type Query_RootVeteranStatusEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootWhiteLabelingArgs = {
  distinct_on?: InputMaybe<Array<WhiteLabeling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhiteLabeling_Order_By>>;
  where?: InputMaybe<WhiteLabeling_Bool_Exp>;
};


export type Query_RootWhiteLabeling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WhiteLabeling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhiteLabeling_Order_By>>;
  where?: InputMaybe<WhiteLabeling_Bool_Exp>;
};


export type Query_RootWhiteLabeling_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWorkflowRegressionsArgs = {
  distinct_on?: InputMaybe<Array<WorkflowRegressions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkflowRegressions_Order_By>>;
  where?: InputMaybe<WorkflowRegressions_Bool_Exp>;
};


export type Query_RootWorkflowRegressions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkflowRegressions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkflowRegressions_Order_By>>;
  where?: InputMaybe<WorkflowRegressions_Bool_Exp>;
};


export type Query_RootWorkflowRegressions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootActivityTotalsArgs = {
  doCounts?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ActivityTotalsOrderBy>;
  searchCriteria: ActivityTotalsSearchCriteria;
};


export type Query_RootActivity_Count_StatArgs = {
  args: Activity_Count_Stat_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Query_RootActivity_Count_Stat_AggregateArgs = {
  args: Activity_Count_Stat_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Query_RootActivity_Counts_Rm_Totals_OnlyArgs = {
  args: Activity_Counts_Rm_Totals_Only_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Query_RootActivity_Counts_Rm_Totals_Only_AggregateArgs = {
  args: Activity_Counts_Rm_Totals_Only_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Query_RootAms_For_Analytics_UiArgs = {
  distinct_on?: InputMaybe<Array<Ams_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ams_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Ams_For_Analytics_Ui_Bool_Exp>;
};


export type Query_RootAms_For_Analytics_Ui_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ams_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ams_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Ams_For_Analytics_Ui_Bool_Exp>;
};


export type Query_RootAnalytics_ViewArgs = {
  distinct_on?: InputMaybe<Array<Analytics_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_View_Order_By>>;
  where?: InputMaybe<Analytics_View_Bool_Exp>;
};


export type Query_RootAnalytics_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Analytics_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_View_Order_By>>;
  where?: InputMaybe<Analytics_View_Bool_Exp>;
};


export type Query_RootCandidate_Account_ManagersArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Account_Managers_Order_By>>;
  where?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
};


export type Query_RootCandidate_Account_Managers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Account_Managers_Order_By>>;
  where?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
};


export type Query_RootCitizenshipEnumArgs = {
  distinct_on?: InputMaybe<Array<CitizenshipEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CitizenshipEnum_Order_By>>;
  where?: InputMaybe<CitizenshipEnum_Bool_Exp>;
};


export type Query_RootCitizenshipEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CitizenshipEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CitizenshipEnum_Order_By>>;
  where?: InputMaybe<CitizenshipEnum_Bool_Exp>;
};


export type Query_RootCitizenshipEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootComments_For_AnalyticsArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Bool_Exp>;
};


export type Query_RootComments_For_Analytics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Bool_Exp>;
};


export type Query_RootComments_For_Analytics_Join_Underlying_ViewArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>;
};


export type Query_RootComments_For_Analytics_Join_Underlying_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>;
};


export type Query_RootContract_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


export type Query_RootContract_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


export type Query_RootContract_Emails_By_PkArgs = {
  contract_id: Scalars['Int'];
  email_id: Scalars['Int'];
};


export type Query_RootConversionReportQueryArgs = {
  queryParams: ConversionReportQueryInput;
};


export type Query_RootCurrent_Wk_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Current_Wk_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Wk_Activity_Order_By>>;
  where?: InputMaybe<Current_Wk_Activity_Bool_Exp>;
};


export type Query_RootCurrent_Wk_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Wk_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Wk_Activity_Order_By>>;
  where?: InputMaybe<Current_Wk_Activity_Bool_Exp>;
};


export type Query_RootCurrent_Workflows_For_IdentityArgs = {
  distinct_on?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Workflows_For_Identity_Order_By>>;
  where?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
};


export type Query_RootCurrent_Workflows_For_Identity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Workflows_For_Identity_Order_By>>;
  where?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_DayArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Day_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Day_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_HourArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Hour_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_Hour_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Hour_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_MonthArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Month_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_Month_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Month_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_WeekArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Week_Bool_Exp>;
};


export type Query_RootDashboard_Activity_Counts_By_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Week_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_DayArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Day_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Day_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_MonthArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Month_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_Month_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Month_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_WeekArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Week_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Week_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_YearArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Year_Bool_Exp>;
};


export type Query_RootDashboard_Stage_Counts_By_Year_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Year_Bool_Exp>;
};


export type Query_RootDashboard_Stages_CountsArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Query_RootDashboard_Stages_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Query_RootDashboard_Stages_Counts_For_Date_RangeArgs = {
  args: Dashboard_Stages_Counts_For_Date_Range_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Query_RootDashboard_Stages_Counts_For_Date_Range_AggregateArgs = {
  args: Dashboard_Stages_Counts_For_Date_Range_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Query_RootDeleted_Ppl_NamesArgs = {
  distinct_on?: InputMaybe<Array<Deleted_Ppl_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deleted_Ppl_Names_Order_By>>;
  where?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
};


export type Query_RootDeleted_Ppl_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deleted_Ppl_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deleted_Ppl_Names_Order_By>>;
  where?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_DisabilityArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Disability_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Disability_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Disability_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Eeo_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Eeo_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Eeo_Category_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Eeo_Category_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_GenderArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Gender_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Gender_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Gender_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_RaceArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Race_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Race_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Race_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Vet_StatusArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Vet_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Vet_Status_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Comp_Bucket_Vet_Status_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Race_Gender_Hisp_By_Cat_PivotArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>;
};


export type Query_RootEeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>;
};


export type Query_RootEeo_Data_Point_Status_By_Identity_ViewArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>;
};


export type Query_RootEeo_Data_Point_Status_By_Identity_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>;
};


export type Query_RootEeo_Data_Points_Unknown_As_Other_By_Identity_ViewArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>;
};


export type Query_RootEeo_Data_Points_Unknown_As_Other_By_Identity_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>;
};


export type Query_RootEeo_Labor_Categories_Comp_BucketsArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Order_By>>;
  where?: InputMaybe<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>;
};


export type Query_RootEeo_Labor_Categories_Comp_Buckets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Order_By>>;
  where?: InputMaybe<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>;
};


export type Query_RootEeo_Pivot_Data_By_Comp_BucketArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>;
};


export type Query_RootEeo_Pivot_Data_By_Comp_Bucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>;
};


export type Query_RootEeo_Status_By_IdentityArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_Bool_Exp>;
};


export type Query_RootEeo_Status_By_Identity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_Bool_Exp>;
};


export type Query_RootEeo_Status_By_Identity_With_Compensation_And_BucketArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>;
};


export type Query_RootEeo_Status_By_Identity_With_Compensation_And_Bucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_GenderArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Gender_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_Gender_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Gender_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_Has_DisabilityArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Has_Disability_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_Has_Disability_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Has_Disability_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_RaceArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Race_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Race_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_Race_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Race_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Race_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_Veteran_StatusArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Veteran_Status_Bool_Exp>;
};


export type Query_RootEeo_Summary_By_Veteran_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Veteran_Status_Bool_Exp>;
};


export type Query_RootEmail_EventsArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Query_RootEmail_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Query_RootFilled_Jobs_ReportArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Report_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Report_Bool_Exp>;
};


export type Query_RootFilled_Jobs_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Report_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Report_Bool_Exp>;
};


export type Query_RootFilled_Jobs_SubreportArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Subreport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Subreport_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Subreport_Bool_Exp>;
};


export type Query_RootFilled_Jobs_Subreport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Subreport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Subreport_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Subreport_Bool_Exp>;
};


export type Query_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGlobal_Search_Function_ContractsArgs = {
  args: Global_Search_Function_Contracts_Args;
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Query_RootGlobal_Search_Function_Contracts_AggregateArgs = {
  args: Global_Search_Function_Contracts_Args;
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Query_RootGlobal_Search_Function_IdentitiesArgs = {
  args: Global_Search_Function_Identities_Args;
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Query_RootGlobal_Search_Function_Identities_AggregateArgs = {
  args: Global_Search_Function_Identities_Args;
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Query_RootGlobal_Search_Function_JobsArgs = {
  args: Global_Search_Function_Jobs_Args;
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Query_RootGlobal_Search_Function_Jobs_AggregateArgs = {
  args: Global_Search_Function_Jobs_Args;
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Query_RootIdentitiesFtsArgs = {
  identitiesFTSParams: IdentitiesFtsParams;
};


export type Query_RootIdentities_For_External_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Identities_For_External_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_For_External_Reviews_Order_By>>;
  where?: InputMaybe<Identities_For_External_Reviews_Bool_Exp>;
};


export type Query_RootIdentities_For_External_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_For_External_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_For_External_Reviews_Order_By>>;
  where?: InputMaybe<Identities_For_External_Reviews_Bool_Exp>;
};


export type Query_RootIdentityForExternalReviewsArgs = {
  requestingIdentityId: Scalars['Int'];
};


export type Query_RootIdentity_Roles_ArrArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Query_RootIdentity_Roles_Arr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Query_RootIdentity_Roles_JoinedArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Joined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Joined_Order_By>>;
  where?: InputMaybe<Identity_Roles_Joined_Bool_Exp>;
};


export type Query_RootIdentity_Roles_Joined_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Joined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Joined_Order_By>>;
  where?: InputMaybe<Identity_Roles_Joined_Bool_Exp>;
};


export type Query_RootIdentity_Roles_StatArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Query_RootIdentity_Roles_Stat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Query_RootIdentity_Roles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_View_Order_By>>;
  where?: InputMaybe<Identity_Roles_View_Bool_Exp>;
};


export type Query_RootIdentity_Roles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_View_Order_By>>;
  where?: InputMaybe<Identity_Roles_View_Bool_Exp>;
};


export type Query_RootIdibuBoardDetailsArgs = {
  boardId: Scalars['Int'];
};


export type Query_RootInternal_UsersArgs = {
  distinct_on?: InputMaybe<Array<Internal_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Users_Order_By>>;
  where?: InputMaybe<Internal_Users_Bool_Exp>;
};


export type Query_RootInternal_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Users_Order_By>>;
  where?: InputMaybe<Internal_Users_Bool_Exp>;
};


export type Query_RootJob_Contracts_Group_IdArgs = {
  distinct_on?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Contracts_Group_Id_Order_By>>;
  where?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
};


export type Query_RootJob_Contracts_Group_Id_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Contracts_Group_Id_Order_By>>;
  where?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
};


export type Query_RootJobboardsnippetscountsArgs = {
  distinct_on?: InputMaybe<Array<Jobboardsnippetscounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobboardsnippetscounts_Order_By>>;
  where?: InputMaybe<Jobboardsnippetscounts_Bool_Exp>;
};


export type Query_RootJobboardsnippetscounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobboardsnippetscounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobboardsnippetscounts_Order_By>>;
  where?: InputMaybe<Jobboardsnippetscounts_Bool_Exp>;
};


export type Query_RootJobs_Available_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Available_Positions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Available_Positions_Order_By>>;
  where?: InputMaybe<Jobs_Available_Positions_Bool_Exp>;
};


export type Query_RootJobs_Available_Positions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Available_Positions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Available_Positions_Order_By>>;
  where?: InputMaybe<Jobs_Available_Positions_Bool_Exp>;
};


export type Query_RootMatchesArgs = {
  distinct_on?: InputMaybe<Array<Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Order_By>>;
  where?: InputMaybe<Matches_Bool_Exp>;
};


export type Query_RootMatches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Order_By>>;
  where?: InputMaybe<Matches_Bool_Exp>;
};


export type Query_RootMatches_From_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Applications_Order_By>>;
  where?: InputMaybe<Matches_From_Applications_Bool_Exp>;
};


export type Query_RootMatches_From_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Applications_Order_By>>;
  where?: InputMaybe<Matches_From_Applications_Bool_Exp>;
};


export type Query_RootMatches_From_BoardArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Board_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Board_Order_By>>;
  where?: InputMaybe<Matches_From_Board_Bool_Exp>;
};


export type Query_RootMatches_From_Board_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Board_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Board_Order_By>>;
  where?: InputMaybe<Matches_From_Board_Bool_Exp>;
};


export type Query_RootMatches_From_IdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Identities_Order_By>>;
  where?: InputMaybe<Matches_From_Identities_Bool_Exp>;
};


export type Query_RootMatches_From_Identities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Identities_Order_By>>;
  where?: InputMaybe<Matches_From_Identities_Bool_Exp>;
};


export type Query_RootMatches_From_TasksArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Tasks_Order_By>>;
  where?: InputMaybe<Matches_From_Tasks_Bool_Exp>;
};


export type Query_RootMatches_From_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Tasks_Order_By>>;
  where?: InputMaybe<Matches_From_Tasks_Bool_Exp>;
};


export type Query_RootMatches_GroupedArgs = {
  distinct_on?: InputMaybe<Array<Matches_Grouped_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Grouped_Order_By>>;
  where?: InputMaybe<Matches_Grouped_Bool_Exp>;
};


export type Query_RootMatches_Grouped_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Grouped_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Grouped_Order_By>>;
  where?: InputMaybe<Matches_Grouped_Bool_Exp>;
};


export type Query_RootMatches_UnionArgs = {
  distinct_on?: InputMaybe<Array<Matches_Union_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Union_Order_By>>;
  where?: InputMaybe<Matches_Union_Bool_Exp>;
};


export type Query_RootMatches_Union_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Union_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Union_Order_By>>;
  where?: InputMaybe<Matches_Union_Bool_Exp>;
};


export type Query_RootMatches_Without_Author_With_Int_UuidArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


export type Query_RootMatches_Without_Author_With_Int_Uuid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


export type Query_RootNylas_EventsArgs = {
  distinct_on?: InputMaybe<Array<Nylas_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nylas_Events_Order_By>>;
  where?: InputMaybe<Nylas_Events_Bool_Exp>;
};


export type Query_RootNylas_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nylas_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nylas_Events_Order_By>>;
  where?: InputMaybe<Nylas_Events_Bool_Exp>;
};


export type Query_RootNylas_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOfccp_ComplianceArgs = {
  distinct_on?: InputMaybe<Array<Ofccp_Compliance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ofccp_Compliance_Order_By>>;
  where?: InputMaybe<Ofccp_Compliance_Bool_Exp>;
};


export type Query_RootOfccp_Compliance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ofccp_Compliance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ofccp_Compliance_Order_By>>;
  where?: InputMaybe<Ofccp_Compliance_Bool_Exp>;
};


export type Query_RootRmsArgs = {
  distinct_on?: InputMaybe<Array<Rms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_Order_By>>;
  where?: InputMaybe<Rms_Bool_Exp>;
};


export type Query_RootRms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_Order_By>>;
  where?: InputMaybe<Rms_Bool_Exp>;
};


export type Query_RootRms_For_Analytics_UiArgs = {
  distinct_on?: InputMaybe<Array<Rms_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Rms_For_Analytics_Ui_Bool_Exp>;
};


export type Query_RootRms_For_Analytics_Ui_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rms_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Rms_For_Analytics_Ui_Bool_Exp>;
};


export type Query_RootSearch_Spec_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Spec_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Spec_Results_Order_By>>;
  where?: InputMaybe<Search_Spec_Results_Bool_Exp>;
};


export type Query_RootSearch_Spec_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Spec_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Spec_Results_Order_By>>;
  where?: InputMaybe<Search_Spec_Results_Bool_Exp>;
};


export type Query_RootSearch_Spec_Results_By_PkArgs = {
  combined_id: Scalars['String'];
  search_spec_id: Scalars['uuid'];
};


export type Query_RootSearch_SpecsArgs = {
  distinct_on?: InputMaybe<Array<Search_Specs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Specs_Order_By>>;
  where?: InputMaybe<Search_Specs_Bool_Exp>;
};


export type Query_RootSearch_Specs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Specs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Specs_Order_By>>;
  where?: InputMaybe<Search_Specs_Bool_Exp>;
};


export type Query_RootSearch_Specs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Query_RootStaffing_StatusArgs = {
  distinct_on?: InputMaybe<Array<Staffing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staffing_Status_Order_By>>;
  where?: InputMaybe<Staffing_Status_Bool_Exp>;
};


export type Query_RootStaffing_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staffing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staffing_Status_Order_By>>;
  where?: InputMaybe<Staffing_Status_Bool_Exp>;
};


export type Query_RootStages_Conversion_MviewArgs = {
  distinct_on?: InputMaybe<Array<Stages_Conversion_Mview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Conversion_Mview_Order_By>>;
  where?: InputMaybe<Stages_Conversion_Mview_Bool_Exp>;
};


export type Query_RootStages_Conversion_Mview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Conversion_Mview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Conversion_Mview_Order_By>>;
  where?: InputMaybe<Stages_Conversion_Mview_Bool_Exp>;
};


export type Query_RootText_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Query_RootText_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Query_RootText_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUtil_Deps_Saved_DdlArgs = {
  distinct_on?: InputMaybe<Array<Util_Deps_Saved_Ddl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Util_Deps_Saved_Ddl_Order_By>>;
  where?: InputMaybe<Util_Deps_Saved_Ddl_Bool_Exp>;
};


export type Query_RootUtil_Deps_Saved_Ddl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Util_Deps_Saved_Ddl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Util_Deps_Saved_Ddl_Order_By>>;
  where?: InputMaybe<Util_Deps_Saved_Ddl_Bool_Exp>;
};


export type Query_RootUtil_Deps_Saved_Ddl_By_PkArgs = {
  deps_id: Scalars['Int'];
};


export type Query_RootVerify_Api_TokenArgs = {
  args: Verify_Api_Token_Args;
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Query_RootVerify_Api_Token_AggregateArgs = {
  args: Verify_Api_Token_Args;
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Query_RootWorkflow_ContractsArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Contracts_Order_By>>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};


export type Query_RootWorkflow_Contracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Contracts_Order_By>>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};


export type Query_RootWorkflow_Contracts_By_PkArgs = {
  contract_id: Scalars['Int'];
};


export type Query_RootWorkflow_DepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};


export type Query_RootWorkflow_Departments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};


export type Query_RootWorkflow_Departments_By_PkArgs = {
  department_id: Scalars['uuid'];
  workflow_id: Scalars['Int'];
};

export type Reindex_Identities_Args = {
  identity_ids?: InputMaybe<Scalars['_int4']>;
};

export type Reindex_Identity_Args = {
  identity_id?: InputMaybe<Scalars['Int']>;
};

export type Response = {
  __typename?: 'response';
  errMsg?: Maybe<Scalars['String']>;
  new_number: Scalars['String'];
  success: Scalars['Boolean'];
};

/** columns and relationships of "rms" */
export type Rms = {
  __typename?: 'rms';
  identityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
};

/** aggregated selection of "rms" */
export type Rms_Aggregate = {
  __typename?: 'rms_aggregate';
  aggregate?: Maybe<Rms_Aggregate_Fields>;
  nodes: Array<Rms>;
};

/** aggregate fields of "rms" */
export type Rms_Aggregate_Fields = {
  __typename?: 'rms_aggregate_fields';
  avg?: Maybe<Rms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rms_Max_Fields>;
  min?: Maybe<Rms_Min_Fields>;
  stddev?: Maybe<Rms_Stddev_Fields>;
  stddev_pop?: Maybe<Rms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rms_Stddev_Samp_Fields>;
  sum?: Maybe<Rms_Sum_Fields>;
  var_pop?: Maybe<Rms_Var_Pop_Fields>;
  var_samp?: Maybe<Rms_Var_Samp_Fields>;
  variance?: Maybe<Rms_Variance_Fields>;
};


/** aggregate fields of "rms" */
export type Rms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rms_Avg_Fields = {
  __typename?: 'rms_avg_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rms". All fields are combined with a logical 'AND'. */
export type Rms_Bool_Exp = {
  _and?: InputMaybe<Array<Rms_Bool_Exp>>;
  _not?: InputMaybe<Rms_Bool_Exp>;
  _or?: InputMaybe<Array<Rms_Bool_Exp>>;
  identityId?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_first?: InputMaybe<String_Comparison_Exp>;
  name_last?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "rms_for_analytics_ui" */
export type Rms_For_Analytics_Ui = {
  __typename?: 'rms_for_analytics_ui';
  /** An object relationship */
  Identity?: Maybe<Identities>;
  company_id?: Maybe<Scalars['Int']>;
  rm_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "rms_for_analytics_ui" */
export type Rms_For_Analytics_Ui_Aggregate = {
  __typename?: 'rms_for_analytics_ui_aggregate';
  aggregate?: Maybe<Rms_For_Analytics_Ui_Aggregate_Fields>;
  nodes: Array<Rms_For_Analytics_Ui>;
};

/** aggregate fields of "rms_for_analytics_ui" */
export type Rms_For_Analytics_Ui_Aggregate_Fields = {
  __typename?: 'rms_for_analytics_ui_aggregate_fields';
  avg?: Maybe<Rms_For_Analytics_Ui_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rms_For_Analytics_Ui_Max_Fields>;
  min?: Maybe<Rms_For_Analytics_Ui_Min_Fields>;
  stddev?: Maybe<Rms_For_Analytics_Ui_Stddev_Fields>;
  stddev_pop?: Maybe<Rms_For_Analytics_Ui_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rms_For_Analytics_Ui_Stddev_Samp_Fields>;
  sum?: Maybe<Rms_For_Analytics_Ui_Sum_Fields>;
  var_pop?: Maybe<Rms_For_Analytics_Ui_Var_Pop_Fields>;
  var_samp?: Maybe<Rms_For_Analytics_Ui_Var_Samp_Fields>;
  variance?: Maybe<Rms_For_Analytics_Ui_Variance_Fields>;
};


/** aggregate fields of "rms_for_analytics_ui" */
export type Rms_For_Analytics_Ui_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rms_For_Analytics_Ui_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rms_For_Analytics_Ui_Avg_Fields = {
  __typename?: 'rms_for_analytics_ui_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rms_for_analytics_ui". All fields are combined with a logical 'AND'. */
export type Rms_For_Analytics_Ui_Bool_Exp = {
  Identity?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Rms_For_Analytics_Ui_Bool_Exp>>;
  _not?: InputMaybe<Rms_For_Analytics_Ui_Bool_Exp>;
  _or?: InputMaybe<Array<Rms_For_Analytics_Ui_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  rm_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rms_For_Analytics_Ui_Max_Fields = {
  __typename?: 'rms_for_analytics_ui_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  rm_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Rms_For_Analytics_Ui_Min_Fields = {
  __typename?: 'rms_for_analytics_ui_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  rm_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "rms_for_analytics_ui". */
export type Rms_For_Analytics_Ui_Order_By = {
  Identity?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  rm_id?: InputMaybe<Order_By>;
};

/** select columns of table "rms_for_analytics_ui" */
export enum Rms_For_Analytics_Ui_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  RmId = 'rm_id'
}

/** aggregate stddev on columns */
export type Rms_For_Analytics_Ui_Stddev_Fields = {
  __typename?: 'rms_for_analytics_ui_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rms_For_Analytics_Ui_Stddev_Pop_Fields = {
  __typename?: 'rms_for_analytics_ui_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rms_For_Analytics_Ui_Stddev_Samp_Fields = {
  __typename?: 'rms_for_analytics_ui_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rms_for_analytics_ui" */
export type Rms_For_Analytics_Ui_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rms_For_Analytics_Ui_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rms_For_Analytics_Ui_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  rm_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Rms_For_Analytics_Ui_Sum_Fields = {
  __typename?: 'rms_for_analytics_ui_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  rm_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Rms_For_Analytics_Ui_Var_Pop_Fields = {
  __typename?: 'rms_for_analytics_ui_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rms_For_Analytics_Ui_Var_Samp_Fields = {
  __typename?: 'rms_for_analytics_ui_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rms_For_Analytics_Ui_Variance_Fields = {
  __typename?: 'rms_for_analytics_ui_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  rm_id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Rms_Max_Fields = {
  __typename?: 'rms_max_fields';
  identityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rms_Min_Fields = {
  __typename?: 'rms_min_fields';
  identityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "rms". */
export type Rms_Order_By = {
  identityId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_first?: InputMaybe<Order_By>;
  name_last?: InputMaybe<Order_By>;
};

/** select columns of table "rms" */
export enum Rms_Select_Column {
  /** column name */
  IdentityId = 'identityId',
  /** column name */
  Name = 'name',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last'
}

/** aggregate stddev on columns */
export type Rms_Stddev_Fields = {
  __typename?: 'rms_stddev_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rms_Stddev_Pop_Fields = {
  __typename?: 'rms_stddev_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rms_Stddev_Samp_Fields = {
  __typename?: 'rms_stddev_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rms" */
export type Rms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rms_Stream_Cursor_Value_Input = {
  identityId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_first?: InputMaybe<Scalars['String']>;
  name_last?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Rms_Sum_Fields = {
  __typename?: 'rms_sum_fields';
  identityId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Rms_Var_Pop_Fields = {
  __typename?: 'rms_var_pop_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rms_Var_Samp_Fields = {
  __typename?: 'rms_var_samp_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rms_Variance_Fields = {
  __typename?: 'rms_variance_fields';
  identityId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "search_spec_results" */
export type Search_Spec_Results = {
  __typename?: 'search_spec_results';
  /** An object relationship */
  SearchSpec: Search_Specs;
  board_snippet_id?: Maybe<Scalars['Int']>;
  combined_id: Scalars['String'];
  comment_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  identity_id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
  search_spec_id: Scalars['uuid'];
};

/** aggregated selection of "search_spec_results" */
export type Search_Spec_Results_Aggregate = {
  __typename?: 'search_spec_results_aggregate';
  aggregate?: Maybe<Search_Spec_Results_Aggregate_Fields>;
  nodes: Array<Search_Spec_Results>;
};

/** aggregate fields of "search_spec_results" */
export type Search_Spec_Results_Aggregate_Fields = {
  __typename?: 'search_spec_results_aggregate_fields';
  avg?: Maybe<Search_Spec_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Search_Spec_Results_Max_Fields>;
  min?: Maybe<Search_Spec_Results_Min_Fields>;
  stddev?: Maybe<Search_Spec_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Search_Spec_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Search_Spec_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Search_Spec_Results_Sum_Fields>;
  var_pop?: Maybe<Search_Spec_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Search_Spec_Results_Var_Samp_Fields>;
  variance?: Maybe<Search_Spec_Results_Variance_Fields>;
};


/** aggregate fields of "search_spec_results" */
export type Search_Spec_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Search_Spec_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Search_Spec_Results_Avg_Fields = {
  __typename?: 'search_spec_results_avg_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "search_spec_results". All fields are combined with a logical 'AND'. */
export type Search_Spec_Results_Bool_Exp = {
  SearchSpec?: InputMaybe<Search_Specs_Bool_Exp>;
  _and?: InputMaybe<Array<Search_Spec_Results_Bool_Exp>>;
  _not?: InputMaybe<Search_Spec_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Spec_Results_Bool_Exp>>;
  board_snippet_id?: InputMaybe<Int_Comparison_Exp>;
  combined_id?: InputMaybe<String_Comparison_Exp>;
  comment_id?: InputMaybe<Int_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identity_id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Bigint_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  resume_id?: InputMaybe<Int_Comparison_Exp>;
  search_spec_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "search_spec_results" */
export enum Search_Spec_Results_Constraint {
  /** unique or primary key constraint on columns "search_spec_id", "combined_id" */
  SearchSpecResultsPkey = 'search_spec_results_pkey'
}

/** input type for incrementing numeric columns in table "search_spec_results" */
export type Search_Spec_Results_Inc_Input = {
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "search_spec_results" */
export type Search_Spec_Results_Insert_Input = {
  SearchSpec?: InputMaybe<Search_Specs_Obj_Rel_Insert_Input>;
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  combined_id?: InputMaybe<Scalars['String']>;
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  search_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Search_Spec_Results_Max_Fields = {
  __typename?: 'search_spec_results_max_fields';
  board_snippet_id?: Maybe<Scalars['Int']>;
  combined_id?: Maybe<Scalars['String']>;
  comment_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
  search_spec_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Search_Spec_Results_Min_Fields = {
  __typename?: 'search_spec_results_min_fields';
  board_snippet_id?: Maybe<Scalars['Int']>;
  combined_id?: Maybe<Scalars['String']>;
  comment_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
  search_spec_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "search_spec_results" */
export type Search_Spec_Results_Mutation_Response = {
  __typename?: 'search_spec_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Search_Spec_Results>;
};

/** on_conflict condition type for table "search_spec_results" */
export type Search_Spec_Results_On_Conflict = {
  constraint: Search_Spec_Results_Constraint;
  update_columns?: Array<Search_Spec_Results_Update_Column>;
  where?: InputMaybe<Search_Spec_Results_Bool_Exp>;
};

/** Ordering options when selecting data from "search_spec_results". */
export type Search_Spec_Results_Order_By = {
  SearchSpec?: InputMaybe<Search_Specs_Order_By>;
  board_snippet_id?: InputMaybe<Order_By>;
  combined_id?: InputMaybe<Order_By>;
  comment_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  identity_id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  resume_id?: InputMaybe<Order_By>;
  search_spec_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: search_spec_results */
export type Search_Spec_Results_Pk_Columns_Input = {
  combined_id: Scalars['String'];
  search_spec_id: Scalars['uuid'];
};

/** select columns of table "search_spec_results" */
export enum Search_Spec_Results_Select_Column {
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  CombinedId = 'combined_id',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Index = 'index',
  /** column name */
  JobId = 'job_id',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  SearchSpecId = 'search_spec_id'
}

/** input type for updating data in table "search_spec_results" */
export type Search_Spec_Results_Set_Input = {
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  combined_id?: InputMaybe<Scalars['String']>;
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  search_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Search_Spec_Results_Stddev_Fields = {
  __typename?: 'search_spec_results_stddev_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Search_Spec_Results_Stddev_Pop_Fields = {
  __typename?: 'search_spec_results_stddev_pop_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Search_Spec_Results_Stddev_Samp_Fields = {
  __typename?: 'search_spec_results_stddev_samp_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "search_spec_results" */
export type Search_Spec_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Spec_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Spec_Results_Stream_Cursor_Value_Input = {
  board_snippet_id?: InputMaybe<Scalars['Int']>;
  combined_id?: InputMaybe<Scalars['String']>;
  comment_id?: InputMaybe<Scalars['Int']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identity_id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['Int']>;
  resume_id?: InputMaybe<Scalars['Int']>;
  search_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Search_Spec_Results_Sum_Fields = {
  __typename?: 'search_spec_results_sum_fields';
  board_snippet_id?: Maybe<Scalars['Int']>;
  comment_id?: Maybe<Scalars['Int']>;
  contract_id?: Maybe<Scalars['Int']>;
  identity_id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['Int']>;
  resume_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "search_spec_results" */
export enum Search_Spec_Results_Update_Column {
  /** column name */
  BoardSnippetId = 'board_snippet_id',
  /** column name */
  CombinedId = 'combined_id',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  Index = 'index',
  /** column name */
  JobId = 'job_id',
  /** column name */
  ResumeId = 'resume_id',
  /** column name */
  SearchSpecId = 'search_spec_id'
}

export type Search_Spec_Results_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Search_Spec_Results_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Search_Spec_Results_Set_Input>;
  /** filter the rows which have to be updated */
  where: Search_Spec_Results_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Search_Spec_Results_Var_Pop_Fields = {
  __typename?: 'search_spec_results_var_pop_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Search_Spec_Results_Var_Samp_Fields = {
  __typename?: 'search_spec_results_var_samp_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Search_Spec_Results_Variance_Fields = {
  __typename?: 'search_spec_results_variance_fields';
  board_snippet_id?: Maybe<Scalars['Float']>;
  comment_id?: Maybe<Scalars['Float']>;
  contract_id?: Maybe<Scalars['Float']>;
  identity_id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  resume_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "search_specs" */
export type Search_Specs = {
  __typename?: 'search_specs';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  grammar: Scalars['jsonb'];
  id: Scalars['uuid'];
  last_user_heartbeat: Scalars['timestamptz'];
  ui_state?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "search_specs" */
export type Search_SpecsGrammarArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "search_specs" */
export type Search_SpecsUi_StateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "search_specs" */
export type Search_Specs_Aggregate = {
  __typename?: 'search_specs_aggregate';
  aggregate?: Maybe<Search_Specs_Aggregate_Fields>;
  nodes: Array<Search_Specs>;
};

/** aggregate fields of "search_specs" */
export type Search_Specs_Aggregate_Fields = {
  __typename?: 'search_specs_aggregate_fields';
  avg?: Maybe<Search_Specs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Search_Specs_Max_Fields>;
  min?: Maybe<Search_Specs_Min_Fields>;
  stddev?: Maybe<Search_Specs_Stddev_Fields>;
  stddev_pop?: Maybe<Search_Specs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Search_Specs_Stddev_Samp_Fields>;
  sum?: Maybe<Search_Specs_Sum_Fields>;
  var_pop?: Maybe<Search_Specs_Var_Pop_Fields>;
  var_samp?: Maybe<Search_Specs_Var_Samp_Fields>;
  variance?: Maybe<Search_Specs_Variance_Fields>;
};


/** aggregate fields of "search_specs" */
export type Search_Specs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Search_Specs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Search_Specs_Append_Input = {
  grammar?: InputMaybe<Scalars['jsonb']>;
  ui_state?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Search_Specs_Avg_Fields = {
  __typename?: 'search_specs_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "search_specs". All fields are combined with a logical 'AND'. */
export type Search_Specs_Bool_Exp = {
  _and?: InputMaybe<Array<Search_Specs_Bool_Exp>>;
  _not?: InputMaybe<Search_Specs_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Specs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  grammar?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_user_heartbeat?: InputMaybe<Timestamptz_Comparison_Exp>;
  ui_state?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "search_specs" */
export enum Search_Specs_Constraint {
  /** unique or primary key constraint on columns "id" */
  SearchSpecsPkey = 'search_specs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Search_Specs_Delete_At_Path_Input = {
  grammar?: InputMaybe<Array<Scalars['String']>>;
  ui_state?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Search_Specs_Delete_Elem_Input = {
  grammar?: InputMaybe<Scalars['Int']>;
  ui_state?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Search_Specs_Delete_Key_Input = {
  grammar?: InputMaybe<Scalars['String']>;
  ui_state?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "search_specs" */
export type Search_Specs_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "search_specs" */
export type Search_Specs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  grammar?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_user_heartbeat?: InputMaybe<Scalars['timestamptz']>;
  ui_state?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Search_Specs_Max_Fields = {
  __typename?: 'search_specs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last_user_heartbeat?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Search_Specs_Min_Fields = {
  __typename?: 'search_specs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last_user_heartbeat?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "search_specs" */
export type Search_Specs_Mutation_Response = {
  __typename?: 'search_specs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Search_Specs>;
};

/** input type for inserting object relation for remote table "search_specs" */
export type Search_Specs_Obj_Rel_Insert_Input = {
  data: Search_Specs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Search_Specs_On_Conflict>;
};

/** on_conflict condition type for table "search_specs" */
export type Search_Specs_On_Conflict = {
  constraint: Search_Specs_Constraint;
  update_columns?: Array<Search_Specs_Update_Column>;
  where?: InputMaybe<Search_Specs_Bool_Exp>;
};

/** Ordering options when selecting data from "search_specs". */
export type Search_Specs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  grammar?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_user_heartbeat?: InputMaybe<Order_By>;
  ui_state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: search_specs */
export type Search_Specs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Search_Specs_Prepend_Input = {
  grammar?: InputMaybe<Scalars['jsonb']>;
  ui_state?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "search_specs" */
export enum Search_Specs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Grammar = 'grammar',
  /** column name */
  Id = 'id',
  /** column name */
  LastUserHeartbeat = 'last_user_heartbeat',
  /** column name */
  UiState = 'ui_state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "search_specs" */
export type Search_Specs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  grammar?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_user_heartbeat?: InputMaybe<Scalars['timestamptz']>;
  ui_state?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Search_Specs_Stddev_Fields = {
  __typename?: 'search_specs_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Search_Specs_Stddev_Pop_Fields = {
  __typename?: 'search_specs_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Search_Specs_Stddev_Samp_Fields = {
  __typename?: 'search_specs_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "search_specs" */
export type Search_Specs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Specs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Specs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  grammar?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_user_heartbeat?: InputMaybe<Scalars['timestamptz']>;
  ui_state?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Search_Specs_Sum_Fields = {
  __typename?: 'search_specs_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "search_specs" */
export enum Search_Specs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Grammar = 'grammar',
  /** column name */
  Id = 'id',
  /** column name */
  LastUserHeartbeat = 'last_user_heartbeat',
  /** column name */
  UiState = 'ui_state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Search_Specs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Search_Specs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Search_Specs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Search_Specs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Search_Specs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Search_Specs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Search_Specs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Search_Specs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Search_Specs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Search_Specs_Var_Pop_Fields = {
  __typename?: 'search_specs_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Search_Specs_Var_Samp_Fields = {
  __typename?: 'search_specs_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Search_Specs_Variance_Fields = {
  __typename?: 'search_specs_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "spatial_ref_sys" */
export type Spatial_Ref_Sys = {
  __typename?: 'spatial_ref_sys';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid: Scalars['Int'];
  srtext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate = {
  __typename?: 'spatial_ref_sys_aggregate';
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_Fields = {
  __typename?: 'spatial_ref_sys_aggregate_fields';
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
};


/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spatial_Ref_Sys_Avg_Fields = {
  __typename?: 'spatial_ref_sys_avg_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type Spatial_Ref_Sys_Bool_Exp = {
  _and?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: InputMaybe<String_Comparison_Exp>;
  auth_srid?: InputMaybe<Int_Comparison_Exp>;
  proj4text?: InputMaybe<String_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  srtext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Constraint {
  /** unique or primary key constraint on columns "srid" */
  SpatialRefSysPkey = 'spatial_ref_sys_pkey'
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Inc_Input = {
  auth_srid?: InputMaybe<Scalars['Int']>;
  srid?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Insert_Input = {
  auth_name?: InputMaybe<Scalars['String']>;
  auth_srid?: InputMaybe<Scalars['Int']>;
  proj4text?: InputMaybe<Scalars['String']>;
  srid?: InputMaybe<Scalars['Int']>;
  srtext?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Spatial_Ref_Sys_Max_Fields = {
  __typename?: 'spatial_ref_sys_max_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spatial_Ref_Sys_Min_Fields = {
  __typename?: 'spatial_ref_sys_min_fields';
  auth_name?: Maybe<Scalars['String']>;
  auth_srid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Mutation_Response = {
  __typename?: 'spatial_ref_sys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
};

/** on_conflict condition type for table "spatial_ref_sys" */
export type Spatial_Ref_Sys_On_Conflict = {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns?: Array<Spatial_Ref_Sys_Update_Column>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type Spatial_Ref_Sys_Order_By = {
  auth_name?: InputMaybe<Order_By>;
  auth_srid?: InputMaybe<Order_By>;
  proj4text?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  srtext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spatial_ref_sys */
export type Spatial_Ref_Sys_Pk_Columns_Input = {
  srid: Scalars['Int'];
};

/** select columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Select_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** input type for updating data in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Set_Input = {
  auth_name?: InputMaybe<Scalars['String']>;
  auth_srid?: InputMaybe<Scalars['Int']>;
  proj4text?: InputMaybe<Scalars['String']>;
  srid?: InputMaybe<Scalars['Int']>;
  srtext?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Spatial_Ref_Sys_Stddev_Fields = {
  __typename?: 'spatial_ref_sys_stddev_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spatial_Ref_Sys_Stddev_Pop_Fields = {
  __typename?: 'spatial_ref_sys_stddev_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spatial_Ref_Sys_Stddev_Samp_Fields = {
  __typename?: 'spatial_ref_sys_stddev_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spatial_Ref_Sys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spatial_Ref_Sys_Stream_Cursor_Value_Input = {
  auth_name?: InputMaybe<Scalars['String']>;
  auth_srid?: InputMaybe<Scalars['Int']>;
  proj4text?: InputMaybe<Scalars['String']>;
  srid?: InputMaybe<Scalars['Int']>;
  srtext?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Spatial_Ref_Sys_Sum_Fields = {
  __typename?: 'spatial_ref_sys_sum_fields';
  auth_srid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** update columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Update_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

export type Spatial_Ref_Sys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spatial_Ref_Sys_Var_Pop_Fields = {
  __typename?: 'spatial_ref_sys_var_pop_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spatial_Ref_Sys_Var_Samp_Fields = {
  __typename?: 'spatial_ref_sys_var_samp_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spatial_Ref_Sys_Variance_Fields = {
  __typename?: 'spatial_ref_sys_variance_fields';
  auth_srid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/** columns and relationships of "staffing_status" */
export type Staffing_Status = {
  __typename?: 'staffing_status';
  as_of?: Maybe<Scalars['timestamptz']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  internal_cands?: Maybe<Scalars['bigint']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  sub_cands?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "staffing_status" */
export type Staffing_Status_Aggregate = {
  __typename?: 'staffing_status_aggregate';
  aggregate?: Maybe<Staffing_Status_Aggregate_Fields>;
  nodes: Array<Staffing_Status>;
};

/** aggregate fields of "staffing_status" */
export type Staffing_Status_Aggregate_Fields = {
  __typename?: 'staffing_status_aggregate_fields';
  avg?: Maybe<Staffing_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Staffing_Status_Max_Fields>;
  min?: Maybe<Staffing_Status_Min_Fields>;
  stddev?: Maybe<Staffing_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Staffing_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Staffing_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Staffing_Status_Sum_Fields>;
  var_pop?: Maybe<Staffing_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Staffing_Status_Var_Samp_Fields>;
  variance?: Maybe<Staffing_Status_Variance_Fields>;
};


/** aggregate fields of "staffing_status" */
export type Staffing_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staffing_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Staffing_Status_Avg_Fields = {
  __typename?: 'staffing_status_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_cands?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  sub_cands?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "staffing_status". All fields are combined with a logical 'AND'. */
export type Staffing_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Staffing_Status_Bool_Exp>>;
  _not?: InputMaybe<Staffing_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Staffing_Status_Bool_Exp>>;
  as_of?: InputMaybe<Timestamptz_Comparison_Exp>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  internal_cands?: InputMaybe<Bigint_Comparison_Exp>;
  position_target_count?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  sub_cands?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "staffing_status" */
export type Staffing_Status_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_cands?: InputMaybe<Scalars['bigint']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  sub_cands?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "staffing_status" */
export type Staffing_Status_Insert_Input = {
  as_of?: InputMaybe<Scalars['timestamptz']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_cands?: InputMaybe<Scalars['bigint']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  sub_cands?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Staffing_Status_Max_Fields = {
  __typename?: 'staffing_status_max_fields';
  as_of?: Maybe<Scalars['timestamptz']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  internal_cands?: Maybe<Scalars['bigint']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  sub_cands?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Staffing_Status_Min_Fields = {
  __typename?: 'staffing_status_min_fields';
  as_of?: Maybe<Scalars['timestamptz']>;
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  internal_cands?: Maybe<Scalars['bigint']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  sub_cands?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "staffing_status" */
export type Staffing_Status_Mutation_Response = {
  __typename?: 'staffing_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staffing_Status>;
};

/** Ordering options when selecting data from "staffing_status". */
export type Staffing_Status_Order_By = {
  as_of?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_cands?: InputMaybe<Order_By>;
  position_target_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_cands?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** select columns of table "staffing_status" */
export enum Staffing_Status_Select_Column {
  /** column name */
  AsOf = 'as_of',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalCands = 'internal_cands',
  /** column name */
  PositionTargetCount = 'position_target_count',
  /** column name */
  Status = 'status',
  /** column name */
  SubCands = 'sub_cands',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "staffing_status" */
export type Staffing_Status_Set_Input = {
  as_of?: InputMaybe<Scalars['timestamptz']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_cands?: InputMaybe<Scalars['bigint']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  sub_cands?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Staffing_Status_Stddev_Fields = {
  __typename?: 'staffing_status_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_cands?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  sub_cands?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Staffing_Status_Stddev_Pop_Fields = {
  __typename?: 'staffing_status_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_cands?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  sub_cands?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Staffing_Status_Stddev_Samp_Fields = {
  __typename?: 'staffing_status_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_cands?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  sub_cands?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "staffing_status" */
export type Staffing_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staffing_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Status_Stream_Cursor_Value_Input = {
  as_of?: InputMaybe<Scalars['timestamptz']>;
  contract_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  internal_cands?: InputMaybe<Scalars['bigint']>;
  position_target_count?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  sub_cands?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Staffing_Status_Sum_Fields = {
  __typename?: 'staffing_status_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  internal_cands?: Maybe<Scalars['bigint']>;
  position_target_count?: Maybe<Scalars['bigint']>;
  sub_cands?: Maybe<Scalars['bigint']>;
};

export type Staffing_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Staffing_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staffing_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staffing_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staffing_Status_Var_Pop_Fields = {
  __typename?: 'staffing_status_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_cands?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  sub_cands?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Staffing_Status_Var_Samp_Fields = {
  __typename?: 'staffing_status_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_cands?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  sub_cands?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Staffing_Status_Variance_Fields = {
  __typename?: 'staffing_status_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  internal_cands?: Maybe<Scalars['Float']>;
  position_target_count?: Maybe<Scalars['Float']>;
  sub_cands?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "stages_conversion_mview" */
export type Stages_Conversion_Mview = {
  __typename?: 'stages_conversion_mview';
  app_created_date?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['Int']>;
  company_name?: Maybe<Scalars['String']>;
  contract_name?: Maybe<Scalars['String']>;
  rm_name?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  stage_count?: Maybe<Scalars['bigint']>;
  stage_name?: Maybe<Scalars['String']>;
  stage_order?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "stages_conversion_mview" */
export type Stages_Conversion_Mview_Aggregate = {
  __typename?: 'stages_conversion_mview_aggregate';
  aggregate?: Maybe<Stages_Conversion_Mview_Aggregate_Fields>;
  nodes: Array<Stages_Conversion_Mview>;
};

/** aggregate fields of "stages_conversion_mview" */
export type Stages_Conversion_Mview_Aggregate_Fields = {
  __typename?: 'stages_conversion_mview_aggregate_fields';
  avg?: Maybe<Stages_Conversion_Mview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stages_Conversion_Mview_Max_Fields>;
  min?: Maybe<Stages_Conversion_Mview_Min_Fields>;
  stddev?: Maybe<Stages_Conversion_Mview_Stddev_Fields>;
  stddev_pop?: Maybe<Stages_Conversion_Mview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stages_Conversion_Mview_Stddev_Samp_Fields>;
  sum?: Maybe<Stages_Conversion_Mview_Sum_Fields>;
  var_pop?: Maybe<Stages_Conversion_Mview_Var_Pop_Fields>;
  var_samp?: Maybe<Stages_Conversion_Mview_Var_Samp_Fields>;
  variance?: Maybe<Stages_Conversion_Mview_Variance_Fields>;
};


/** aggregate fields of "stages_conversion_mview" */
export type Stages_Conversion_Mview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stages_Conversion_Mview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Stages_Conversion_Mview_Avg_Fields = {
  __typename?: 'stages_conversion_mview_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  stage_count?: Maybe<Scalars['Float']>;
  stage_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "stages_conversion_mview". All fields are combined with a logical 'AND'. */
export type Stages_Conversion_Mview_Bool_Exp = {
  _and?: InputMaybe<Array<Stages_Conversion_Mview_Bool_Exp>>;
  _not?: InputMaybe<Stages_Conversion_Mview_Bool_Exp>;
  _or?: InputMaybe<Array<Stages_Conversion_Mview_Bool_Exp>>;
  app_created_date?: InputMaybe<Date_Comparison_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  contract_name?: InputMaybe<String_Comparison_Exp>;
  rm_name?: InputMaybe<String_Comparison_Exp>;
  source_name?: InputMaybe<String_Comparison_Exp>;
  stage_count?: InputMaybe<Bigint_Comparison_Exp>;
  stage_name?: InputMaybe<String_Comparison_Exp>;
  stage_order?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Stages_Conversion_Mview_Max_Fields = {
  __typename?: 'stages_conversion_mview_max_fields';
  app_created_date?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['Int']>;
  company_name?: Maybe<Scalars['String']>;
  contract_name?: Maybe<Scalars['String']>;
  rm_name?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  stage_count?: Maybe<Scalars['bigint']>;
  stage_name?: Maybe<Scalars['String']>;
  stage_order?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Stages_Conversion_Mview_Min_Fields = {
  __typename?: 'stages_conversion_mview_min_fields';
  app_created_date?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['Int']>;
  company_name?: Maybe<Scalars['String']>;
  contract_name?: Maybe<Scalars['String']>;
  rm_name?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  stage_count?: Maybe<Scalars['bigint']>;
  stage_name?: Maybe<Scalars['String']>;
  stage_order?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "stages_conversion_mview". */
export type Stages_Conversion_Mview_Order_By = {
  app_created_date?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  contract_name?: InputMaybe<Order_By>;
  rm_name?: InputMaybe<Order_By>;
  source_name?: InputMaybe<Order_By>;
  stage_count?: InputMaybe<Order_By>;
  stage_name?: InputMaybe<Order_By>;
  stage_order?: InputMaybe<Order_By>;
};

/** select columns of table "stages_conversion_mview" */
export enum Stages_Conversion_Mview_Select_Column {
  /** column name */
  AppCreatedDate = 'app_created_date',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  ContractName = 'contract_name',
  /** column name */
  RmName = 'rm_name',
  /** column name */
  SourceName = 'source_name',
  /** column name */
  StageCount = 'stage_count',
  /** column name */
  StageName = 'stage_name',
  /** column name */
  StageOrder = 'stage_order'
}

/** aggregate stddev on columns */
export type Stages_Conversion_Mview_Stddev_Fields = {
  __typename?: 'stages_conversion_mview_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  stage_count?: Maybe<Scalars['Float']>;
  stage_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Stages_Conversion_Mview_Stddev_Pop_Fields = {
  __typename?: 'stages_conversion_mview_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  stage_count?: Maybe<Scalars['Float']>;
  stage_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Stages_Conversion_Mview_Stddev_Samp_Fields = {
  __typename?: 'stages_conversion_mview_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  stage_count?: Maybe<Scalars['Float']>;
  stage_order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "stages_conversion_mview" */
export type Stages_Conversion_Mview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stages_Conversion_Mview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stages_Conversion_Mview_Stream_Cursor_Value_Input = {
  app_created_date?: InputMaybe<Scalars['date']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_name?: InputMaybe<Scalars['String']>;
  contract_name?: InputMaybe<Scalars['String']>;
  rm_name?: InputMaybe<Scalars['String']>;
  source_name?: InputMaybe<Scalars['String']>;
  stage_count?: InputMaybe<Scalars['bigint']>;
  stage_name?: InputMaybe<Scalars['String']>;
  stage_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Stages_Conversion_Mview_Sum_Fields = {
  __typename?: 'stages_conversion_mview_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  stage_count?: Maybe<Scalars['bigint']>;
  stage_order?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Stages_Conversion_Mview_Var_Pop_Fields = {
  __typename?: 'stages_conversion_mview_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  stage_count?: Maybe<Scalars['Float']>;
  stage_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Stages_Conversion_Mview_Var_Samp_Fields = {
  __typename?: 'stages_conversion_mview_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  stage_count?: Maybe<Scalars['Float']>;
  stage_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Stages_Conversion_Mview_Variance_Fields = {
  __typename?: 'stages_conversion_mview_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  stage_count?: Maybe<Scalars['Float']>;
  stage_order?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  APITokens: Array<ApiTokens>;
  /** An aggregate relationship */
  APITokens_aggregate: ApiTokens_Aggregate;
  /** fetch data from the table: "APITokens" using primary key columns */
  APITokens_by_pk?: Maybe<ApiTokens>;
  /** fetch data from the table in a streaming manner: "APITokens" */
  APITokens_stream: Array<ApiTokens>;
  /** fetch data from the table: "AccessIntents" */
  AccessIntents: Array<AccessIntents>;
  /** fetch aggregated fields from the table: "AccessIntents" */
  AccessIntents_aggregate: AccessIntents_Aggregate;
  /** fetch data from the table: "AccessIntents" using primary key columns */
  AccessIntents_by_pk?: Maybe<AccessIntents>;
  /** fetch data from the table in a streaming manner: "AccessIntents" */
  AccessIntents_stream: Array<AccessIntents>;
  /** fetch data from the table: "AccessTypeEnum" */
  AccessTypeEnum: Array<AccessTypeEnum>;
  /** fetch aggregated fields from the table: "AccessTypeEnum" */
  AccessTypeEnum_aggregate: AccessTypeEnum_Aggregate;
  /** fetch data from the table: "AccessTypeEnum" using primary key columns */
  AccessTypeEnum_by_pk?: Maybe<AccessTypeEnum>;
  /** fetch data from the table in a streaming manner: "AccessTypeEnum" */
  AccessTypeEnum_stream: Array<AccessTypeEnum>;
  /** fetch data from the table: "ActiveEntitlements" */
  ActiveEntitlements: Array<ActiveEntitlements>;
  /** fetch aggregated fields from the table: "ActiveEntitlements" */
  ActiveEntitlements_aggregate: ActiveEntitlements_Aggregate;
  /** fetch data from the table in a streaming manner: "ActiveEntitlements" */
  ActiveEntitlements_stream: Array<ActiveEntitlements>;
  /** fetch data from the table: "ActivityLogTypes" */
  ActivityLogTypes: Array<ActivityLogTypes>;
  /** fetch aggregated fields from the table: "ActivityLogTypes" */
  ActivityLogTypes_aggregate: ActivityLogTypes_Aggregate;
  /** fetch data from the table: "ActivityLogTypes" using primary key columns */
  ActivityLogTypes_by_pk?: Maybe<ActivityLogTypes>;
  /** fetch data from the table in a streaming manner: "ActivityLogTypes" */
  ActivityLogTypes_stream: Array<ActivityLogTypes>;
  /** fetch data from the table: "ActivityTemplates" */
  ActivityTemplates: Array<ActivityTemplates>;
  /** fetch aggregated fields from the table: "ActivityTemplates" */
  ActivityTemplates_aggregate: ActivityTemplates_Aggregate;
  /** fetch data from the table: "ActivityTemplates" using primary key columns */
  ActivityTemplates_by_pk?: Maybe<ActivityTemplates>;
  /** fetch data from the table in a streaming manner: "ActivityTemplates" */
  ActivityTemplates_stream: Array<ActivityTemplates>;
  /** fetch data from the table: "AoIdentifierTypes" */
  AoIdentifierTypes: Array<AoIdentifierTypes>;
  /** fetch aggregated fields from the table: "AoIdentifierTypes" */
  AoIdentifierTypes_aggregate: AoIdentifierTypes_Aggregate;
  /** fetch data from the table: "AoIdentifierTypes" using primary key columns */
  AoIdentifierTypes_by_pk?: Maybe<AoIdentifierTypes>;
  /** fetch data from the table in a streaming manner: "AoIdentifierTypes" */
  AoIdentifierTypes_stream: Array<AoIdentifierTypes>;
  /** fetch data from the table: "ApplicationStatus" */
  ApplicationStatus: Array<ApplicationStatus>;
  /** fetch aggregated fields from the table: "ApplicationStatus" */
  ApplicationStatus_aggregate: ApplicationStatus_Aggregate;
  /** fetch data from the table: "ApplicationStatus" using primary key columns */
  ApplicationStatus_by_pk?: Maybe<ApplicationStatus>;
  /** fetch data from the table in a streaming manner: "ApplicationStatus" */
  ApplicationStatus_stream: Array<ApplicationStatus>;
  /** fetch data from the table: "ApplicationType" */
  ApplicationType: Array<ApplicationType>;
  /** fetch aggregated fields from the table: "ApplicationType" */
  ApplicationType_aggregate: ApplicationType_Aggregate;
  /** fetch data from the table: "ApplicationType" using primary key columns */
  ApplicationType_by_pk?: Maybe<ApplicationType>;
  /** fetch data from the table in a streaming manner: "ApplicationType" */
  ApplicationType_stream: Array<ApplicationType>;
  /** An array relationship */
  Applications: Array<Applications>;
  /** An aggregate relationship */
  Applications_aggregate: Applications_Aggregate;
  /** fetch data from the table: "Applications" using primary key columns */
  Applications_by_pk?: Maybe<Applications>;
  /** fetch data from the table in a streaming manner: "Applications" */
  Applications_stream: Array<Applications>;
  /** An array relationship */
  Assignments: Array<Assignments>;
  /** An aggregate relationship */
  Assignments_aggregate: Assignments_Aggregate;
  /** fetch data from the table: "Assignments" using primary key columns */
  Assignments_by_pk?: Maybe<Assignments>;
  /** fetch data from the table in a streaming manner: "Assignments" */
  Assignments_stream: Array<Assignments>;
  /** fetch data from the table: "AsyncOperations" */
  AsyncOperations: Array<AsyncOperations>;
  /** fetch data from the table: "AsyncOperationsStates" */
  AsyncOperationsStates: Array<AsyncOperationsStates>;
  /** fetch aggregated fields from the table: "AsyncOperationsStates" */
  AsyncOperationsStates_aggregate: AsyncOperationsStates_Aggregate;
  /** fetch data from the table: "AsyncOperationsStates" using primary key columns */
  AsyncOperationsStates_by_pk?: Maybe<AsyncOperationsStates>;
  /** fetch data from the table in a streaming manner: "AsyncOperationsStates" */
  AsyncOperationsStates_stream: Array<AsyncOperationsStates>;
  /** fetch aggregated fields from the table: "AsyncOperations" */
  AsyncOperations_aggregate: AsyncOperations_Aggregate;
  /** fetch data from the table: "AsyncOperations" using primary key columns */
  AsyncOperations_by_pk?: Maybe<AsyncOperations>;
  /** fetch data from the table in a streaming manner: "AsyncOperations" */
  AsyncOperations_stream: Array<AsyncOperations>;
  /** An array relationship */
  Attachments: Array<Attachments>;
  /** An aggregate relationship */
  Attachments_aggregate: Attachments_Aggregate;
  /** fetch data from the table: "Attachments" using primary key columns */
  Attachments_by_pk?: Maybe<Attachments>;
  /** fetch data from the table in a streaming manner: "Attachments" */
  Attachments_stream: Array<Attachments>;
  /** An array relationship */
  AuditCreates: Array<AuditCreates>;
  /** An aggregate relationship */
  AuditCreates_aggregate: AuditCreates_Aggregate;
  /** fetch data from the table: "AuditCreates" using primary key columns */
  AuditCreates_by_pk?: Maybe<AuditCreates>;
  /** fetch data from the table in a streaming manner: "AuditCreates" */
  AuditCreates_stream: Array<AuditCreates>;
  /** An array relationship */
  AuditStages: Array<AuditStages>;
  /** fetch data from the table: "AuditStagesWithWorkflowBeginAndStartedDates" */
  AuditStagesWithWorkflowBeginAndStartedDates: Array<AuditStagesWithWorkflowBeginAndStartedDates>;
  /** fetch aggregated fields from the table: "AuditStagesWithWorkflowBeginAndStartedDates" */
  AuditStagesWithWorkflowBeginAndStartedDates_aggregate: AuditStagesWithWorkflowBeginAndStartedDates_Aggregate;
  /** fetch data from the table in a streaming manner: "AuditStagesWithWorkflowBeginAndStartedDates" */
  AuditStagesWithWorkflowBeginAndStartedDates_stream: Array<AuditStagesWithWorkflowBeginAndStartedDates>;
  /** An aggregate relationship */
  AuditStages_aggregate: AuditStages_Aggregate;
  /** fetch data from the table: "AuditStages" using primary key columns */
  AuditStages_by_pk?: Maybe<AuditStages>;
  /** fetch data from the table in a streaming manner: "AuditStages" */
  AuditStages_stream: Array<AuditStages>;
  /** fetch data from the table: "AuthAudits" */
  AuthAudits: Array<AuthAudits>;
  /** fetch aggregated fields from the table: "AuthAudits" */
  AuthAudits_aggregate: AuthAudits_Aggregate;
  /** fetch data from the table: "AuthAudits" using primary key columns */
  AuthAudits_by_pk?: Maybe<AuthAudits>;
  /** fetch data from the table in a streaming manner: "AuthAudits" */
  AuthAudits_stream: Array<AuthAudits>;
  /** fetch data from the table: "Badges" */
  Badges: Array<Badges>;
  /** fetch aggregated fields from the table: "Badges" */
  Badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "Badges" using primary key columns */
  Badges_by_pk?: Maybe<Badges>;
  /** fetch data from the table in a streaming manner: "Badges" */
  Badges_stream: Array<Badges>;
  /** fetch data from the table: "Benefit" */
  Benefit: Array<Benefit>;
  /** An array relationship */
  BenefitTypeIdentitySubTypes: Array<BenefitTypeIdentitySubTypes>;
  /** An aggregate relationship */
  BenefitTypeIdentitySubTypes_aggregate: BenefitTypeIdentitySubTypes_Aggregate;
  /** fetch data from the table: "BenefitTypeIdentitySubTypes" using primary key columns */
  BenefitTypeIdentitySubTypes_by_pk?: Maybe<BenefitTypeIdentitySubTypes>;
  /** fetch data from the table in a streaming manner: "BenefitTypeIdentitySubTypes" */
  BenefitTypeIdentitySubTypes_stream: Array<BenefitTypeIdentitySubTypes>;
  /** fetch data from the table: "BenefitTypes" */
  BenefitTypes: Array<BenefitTypes>;
  /** fetch aggregated fields from the table: "BenefitTypes" */
  BenefitTypes_aggregate: BenefitTypes_Aggregate;
  /** fetch data from the table: "BenefitTypes" using primary key columns */
  BenefitTypes_by_pk?: Maybe<BenefitTypes>;
  /** fetch data from the table in a streaming manner: "BenefitTypes" */
  BenefitTypes_stream: Array<BenefitTypes>;
  /** fetch aggregated fields from the table: "Benefit" */
  Benefit_aggregate: Benefit_Aggregate;
  /** fetch data from the table: "Benefit" using primary key columns */
  Benefit_by_pk?: Maybe<Benefit>;
  /** fetch data from the table in a streaming manner: "Benefit" */
  Benefit_stream: Array<Benefit>;
  /** fetch data from the table: "BoardFilters" */
  BoardFilters: Array<BoardFilters>;
  /** fetch aggregated fields from the table: "BoardFilters" */
  BoardFilters_aggregate: BoardFilters_Aggregate;
  /** fetch data from the table: "BoardFilters" using primary key columns */
  BoardFilters_by_pk?: Maybe<BoardFilters>;
  /** fetch data from the table in a streaming manner: "BoardFilters" */
  BoardFilters_stream: Array<BoardFilters>;
  /** An array relationship */
  BoardSnippets: Array<BoardSnippets>;
  /** An aggregate relationship */
  BoardSnippets_aggregate: BoardSnippets_Aggregate;
  /** fetch data from the table: "BoardSnippets" using primary key columns */
  BoardSnippets_by_pk?: Maybe<BoardSnippets>;
  /** fetch data from the table in a streaming manner: "BoardSnippets" */
  BoardSnippets_stream: Array<BoardSnippets>;
  /** fetch data from the table: "BullhornSyncStatus" */
  BullhornSyncStatus: Array<BullhornSyncStatus>;
  /** fetch aggregated fields from the table: "BullhornSyncStatus" */
  BullhornSyncStatus_aggregate: BullhornSyncStatus_Aggregate;
  /** fetch data from the table: "BullhornSyncStatus" using primary key columns */
  BullhornSyncStatus_by_pk?: Maybe<BullhornSyncStatus>;
  /** fetch data from the table in a streaming manner: "BullhornSyncStatus" */
  BullhornSyncStatus_stream: Array<BullhornSyncStatus>;
  /** An array relationship */
  CalcEquationResults: Array<CalcEquationResults>;
  /** An aggregate relationship */
  CalcEquationResults_aggregate: CalcEquationResults_Aggregate;
  /** fetch data from the table: "CalcEquationResults" using primary key columns */
  CalcEquationResults_by_pk?: Maybe<CalcEquationResults>;
  /** fetch data from the table in a streaming manner: "CalcEquationResults" */
  CalcEquationResults_stream: Array<CalcEquationResults>;
  /** An array relationship */
  CalcFormValues: Array<CalcFormValues>;
  /** An aggregate relationship */
  CalcFormValues_aggregate: CalcFormValues_Aggregate;
  /** fetch data from the table: "CalcFormValues" using primary key columns */
  CalcFormValues_by_pk?: Maybe<CalcFormValues>;
  /** fetch data from the table in a streaming manner: "CalcFormValues" */
  CalcFormValues_stream: Array<CalcFormValues>;
  /** fetch data from the table: "Calcs" */
  Calcs: Array<Calcs>;
  /** fetch aggregated fields from the table: "Calcs" */
  Calcs_aggregate: Calcs_Aggregate;
  /** fetch data from the table: "Calcs" using primary key columns */
  Calcs_by_pk?: Maybe<Calcs>;
  /** fetch data from the table in a streaming manner: "Calcs" */
  Calcs_stream: Array<Calcs>;
  /** An array relationship */
  CalculationTemplateVersions: Array<CalculationTemplateVersions>;
  /** An aggregate relationship */
  CalculationTemplateVersions_aggregate: CalculationTemplateVersions_Aggregate;
  /** fetch data from the table: "CalculationTemplateVersions" using primary key columns */
  CalculationTemplateVersions_by_pk?: Maybe<CalculationTemplateVersions>;
  /** fetch data from the table in a streaming manner: "CalculationTemplateVersions" */
  CalculationTemplateVersions_stream: Array<CalculationTemplateVersions>;
  /** fetch data from the table: "CalculationTemplates" */
  CalculationTemplates: Array<CalculationTemplates>;
  /** fetch aggregated fields from the table: "CalculationTemplates" */
  CalculationTemplates_aggregate: CalculationTemplates_Aggregate;
  /** fetch data from the table: "CalculationTemplates" using primary key columns */
  CalculationTemplates_by_pk?: Maybe<CalculationTemplates>;
  /** fetch data from the table in a streaming manner: "CalculationTemplates" */
  CalculationTemplates_stream: Array<CalculationTemplates>;
  /** An array relationship */
  CalculationVersionFormInputs: Array<CalculationVersionFormInputs>;
  /** An aggregate relationship */
  CalculationVersionFormInputs_aggregate: CalculationVersionFormInputs_Aggregate;
  /** fetch data from the table: "CalculationVersionFormInputs" using primary key columns */
  CalculationVersionFormInputs_by_pk?: Maybe<CalculationVersionFormInputs>;
  /** fetch data from the table in a streaming manner: "CalculationVersionFormInputs" */
  CalculationVersionFormInputs_stream: Array<CalculationVersionFormInputs>;
  /** An array relationship */
  Calculations: Array<Calculations>;
  /** An aggregate relationship */
  Calculations_aggregate: Calculations_Aggregate;
  /** fetch data from the table: "Calculations" using primary key columns */
  Calculations_by_pk?: Maybe<Calculations>;
  /** fetch data from the table in a streaming manner: "Calculations" */
  Calculations_stream: Array<Calculations>;
  /** fetch data from the table: "CandidateSources" */
  CandidateSources: Array<CandidateSources>;
  /** fetch aggregated fields from the table: "CandidateSources" */
  CandidateSources_aggregate: CandidateSources_Aggregate;
  /** fetch data from the table: "CandidateSources" using primary key columns */
  CandidateSources_by_pk?: Maybe<CandidateSources>;
  /** fetch data from the table in a streaming manner: "CandidateSources" */
  CandidateSources_stream: Array<CandidateSources>;
  /** fetch data from the table: "CandidateStatuses" */
  CandidateStatuses: Array<CandidateStatuses>;
  /** fetch aggregated fields from the table: "CandidateStatuses" */
  CandidateStatuses_aggregate: CandidateStatuses_Aggregate;
  /** fetch data from the table: "CandidateStatuses" using primary key columns */
  CandidateStatuses_by_pk?: Maybe<CandidateStatuses>;
  /** fetch data from the table in a streaming manner: "CandidateStatuses" */
  CandidateStatuses_stream: Array<CandidateStatuses>;
  /** fetch data from the table: "CaptureNodes" */
  CaptureNodes: Array<CaptureNodes>;
  /** fetch aggregated fields from the table: "CaptureNodes" */
  CaptureNodes_aggregate: CaptureNodes_Aggregate;
  /** fetch data from the table: "CaptureNodes" using primary key columns */
  CaptureNodes_by_pk?: Maybe<CaptureNodes>;
  /** fetch data from the table in a streaming manner: "CaptureNodes" */
  CaptureNodes_stream: Array<CaptureNodes>;
  /** fetch data from the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  ClientSideExternalSystemIntegrationPluginRecord: Array<ClientSideExternalSystemIntegrationPluginRecord>;
  /** fetch aggregated fields from the table: "ClientSideExternalSystemIntegrationPluginRecord" */
  ClientSideExternalSystemIntegrationPluginRecord_aggregate: ClientSideExternalSystemIntegrationPluginRecord_Aggregate;
  /** fetch data from the table: "ClientSideExternalSystemIntegrationPluginRecord" using primary key columns */
  ClientSideExternalSystemIntegrationPluginRecord_by_pk?: Maybe<ClientSideExternalSystemIntegrationPluginRecord>;
  /** fetch data from the table in a streaming manner: "ClientSideExternalSystemIntegrationPluginRecord" */
  ClientSideExternalSystemIntegrationPluginRecord_stream: Array<ClientSideExternalSystemIntegrationPluginRecord>;
  /** fetch data from the table: "CognitoUserData" */
  CognitoUserData: Array<CognitoUserData>;
  /** fetch aggregated fields from the table: "CognitoUserData" */
  CognitoUserData_aggregate: CognitoUserData_Aggregate;
  /** fetch data from the table: "CognitoUserData" using primary key columns */
  CognitoUserData_by_pk?: Maybe<CognitoUserData>;
  /** fetch data from the table in a streaming manner: "CognitoUserData" */
  CognitoUserData_stream: Array<CognitoUserData>;
  /** fetch data from the table: "CommentTypes" */
  CommentTypes: Array<CommentTypes>;
  /** fetch aggregated fields from the table: "CommentTypes" */
  CommentTypes_aggregate: CommentTypes_Aggregate;
  /** fetch data from the table: "CommentTypes" using primary key columns */
  CommentTypes_by_pk?: Maybe<CommentTypes>;
  /** fetch data from the table in a streaming manner: "CommentTypes" */
  CommentTypes_stream: Array<CommentTypes>;
  /** An array relationship */
  Comments: Array<Comments>;
  /** An aggregate relationship */
  Comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "Comments" using primary key columns */
  Comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table in a streaming manner: "Comments" */
  Comments_stream: Array<Comments>;
  /** fetch data from the table: "Companies" */
  Companies: Array<Companies>;
  /** fetch aggregated fields from the table: "Companies" */
  Companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "Companies" using primary key columns */
  Companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table in a streaming manner: "Companies" */
  Companies_stream: Array<Companies>;
  /** An array relationship */
  CompanyEvents: Array<CompanyEvents>;
  /** An aggregate relationship */
  CompanyEvents_aggregate: CompanyEvents_Aggregate;
  /** fetch data from the table: "CompanyEvents" using primary key columns */
  CompanyEvents_by_pk?: Maybe<CompanyEvents>;
  /** fetch data from the table in a streaming manner: "CompanyEvents" */
  CompanyEvents_stream: Array<CompanyEvents>;
  /** fetch data from the table: "CompanyStatusEnum" */
  CompanyStatusEnum: Array<CompanyStatusEnum>;
  /** fetch aggregated fields from the table: "CompanyStatusEnum" */
  CompanyStatusEnum_aggregate: CompanyStatusEnum_Aggregate;
  /** fetch data from the table: "CompanyStatusEnum" using primary key columns */
  CompanyStatusEnum_by_pk?: Maybe<CompanyStatusEnum>;
  /** fetch data from the table in a streaming manner: "CompanyStatusEnum" */
  CompanyStatusEnum_stream: Array<CompanyStatusEnum>;
  /** An array relationship */
  Compensations: Array<Compensations>;
  /** An aggregate relationship */
  Compensations_aggregate: Compensations_Aggregate;
  /** fetch data from the table: "Compensations" using primary key columns */
  Compensations_by_pk?: Maybe<Compensations>;
  /** fetch data from the table in a streaming manner: "Compensations" */
  Compensations_stream: Array<Compensations>;
  /** fetch data from the table: "ContactTemplateVariablesEnum" */
  ContactTemplateVariablesEnum: Array<ContactTemplateVariablesEnum>;
  /** fetch aggregated fields from the table: "ContactTemplateVariablesEnum" */
  ContactTemplateVariablesEnum_aggregate: ContactTemplateVariablesEnum_Aggregate;
  /** fetch data from the table: "ContactTemplateVariablesEnum" using primary key columns */
  ContactTemplateVariablesEnum_by_pk?: Maybe<ContactTemplateVariablesEnum>;
  /** fetch data from the table in a streaming manner: "ContactTemplateVariablesEnum" */
  ContactTemplateVariablesEnum_stream: Array<ContactTemplateVariablesEnum>;
  /** An array relationship */
  ContractComments: Array<ContractComments>;
  /** An aggregate relationship */
  ContractComments_aggregate: ContractComments_Aggregate;
  /** fetch data from the table: "ContractComments" using primary key columns */
  ContractComments_by_pk?: Maybe<ContractComments>;
  /** fetch data from the table in a streaming manner: "ContractComments" */
  ContractComments_stream: Array<ContractComments>;
  /** fetch data from the table: "ContractFiles" */
  ContractFiles: Array<ContractFiles>;
  /** fetch aggregated fields from the table: "ContractFiles" */
  ContractFiles_aggregate: ContractFiles_Aggregate;
  /** fetch data from the table: "ContractFiles" using primary key columns */
  ContractFiles_by_pk?: Maybe<ContractFiles>;
  /** fetch data from the table in a streaming manner: "ContractFiles" */
  ContractFiles_stream: Array<ContractFiles>;
  /** An array relationship */
  ContractStages: Array<ContractStages>;
  /** An aggregate relationship */
  ContractStages_aggregate: ContractStages_Aggregate;
  /** fetch data from the table: "ContractStages" using primary key columns */
  ContractStages_by_pk?: Maybe<ContractStages>;
  /** fetch data from the table in a streaming manner: "ContractStages" */
  ContractStages_stream: Array<ContractStages>;
  /** fetch data from the table: "ContractStatusEnum" */
  ContractStatusEnum: Array<ContractStatusEnum>;
  /** fetch aggregated fields from the table: "ContractStatusEnum" */
  ContractStatusEnum_aggregate: ContractStatusEnum_Aggregate;
  /** fetch data from the table: "ContractStatusEnum" using primary key columns */
  ContractStatusEnum_by_pk?: Maybe<ContractStatusEnum>;
  /** fetch data from the table in a streaming manner: "ContractStatusEnum" */
  ContractStatusEnum_stream: Array<ContractStatusEnum>;
  /** An array relationship */
  Contracts: Array<Contracts>;
  /** An aggregate relationship */
  Contracts_aggregate: Contracts_Aggregate;
  /** fetch data from the table: "Contracts" using primary key columns */
  Contracts_by_pk?: Maybe<Contracts>;
  /** fetch data from the table in a streaming manner: "Contracts" */
  Contracts_stream: Array<Contracts>;
  /** fetch data from the table: "CurrentStages" */
  CurrentStages: Array<CurrentStages>;
  /** fetch aggregated fields from the table: "CurrentStages" */
  CurrentStages_aggregate: CurrentStages_Aggregate;
  /** fetch data from the table: "CurrentStages" using primary key columns */
  CurrentStages_by_pk?: Maybe<CurrentStages>;
  /** fetch data from the table in a streaming manner: "CurrentStages" */
  CurrentStages_stream: Array<CurrentStages>;
  /** fetch data from the table: "DataHistory" */
  DataHistory: Array<DataHistory>;
  /** fetch aggregated fields from the table: "DataHistory" */
  DataHistory_aggregate: DataHistory_Aggregate;
  /** fetch data from the table: "DataHistory" using primary key columns */
  DataHistory_by_pk?: Maybe<DataHistory>;
  /** fetch data from the table in a streaming manner: "DataHistory" */
  DataHistory_stream: Array<DataHistory>;
  /** An array relationship */
  Departments: Array<Departments>;
  /** An aggregate relationship */
  Departments_aggregate: Departments_Aggregate;
  /** fetch data from the table: "Departments" using primary key columns */
  Departments_by_pk?: Maybe<Departments>;
  /** fetch data from the table in a streaming manner: "Departments" */
  Departments_stream: Array<Departments>;
  /** fetch data from the table: "DisabilityStatusEnum" */
  DisabilityStatusEnum: Array<DisabilityStatusEnum>;
  /** fetch aggregated fields from the table: "DisabilityStatusEnum" */
  DisabilityStatusEnum_aggregate: DisabilityStatusEnum_Aggregate;
  /** fetch data from the table: "DisabilityStatusEnum" using primary key columns */
  DisabilityStatusEnum_by_pk?: Maybe<DisabilityStatusEnum>;
  /** fetch data from the table in a streaming manner: "DisabilityStatusEnum" */
  DisabilityStatusEnum_stream: Array<DisabilityStatusEnum>;
  /** fetch data from the table: "EEOJobCategoriesEnum" */
  EEOJobCategoriesEnum: Array<EeoJobCategoriesEnum>;
  /** fetch aggregated fields from the table: "EEOJobCategoriesEnum" */
  EEOJobCategoriesEnum_aggregate: EeoJobCategoriesEnum_Aggregate;
  /** fetch data from the table: "EEOJobCategoriesEnum" using primary key columns */
  EEOJobCategoriesEnum_by_pk?: Maybe<EeoJobCategoriesEnum>;
  /** fetch data from the table in a streaming manner: "EEOJobCategoriesEnum" */
  EEOJobCategoriesEnum_stream: Array<EeoJobCategoriesEnum>;
  /** An array relationship */
  EarnedBadges: Array<EarnedBadges>;
  /** An aggregate relationship */
  EarnedBadges_aggregate: EarnedBadges_Aggregate;
  /** fetch data from the table: "EarnedBadges" using primary key columns */
  EarnedBadges_by_pk?: Maybe<EarnedBadges>;
  /** fetch data from the table in a streaming manner: "EarnedBadges" */
  EarnedBadges_stream: Array<EarnedBadges>;
  /** fetch data from the table: "EmailAddresses" */
  EmailAddresses: Array<EmailAddresses>;
  /** fetch aggregated fields from the table: "EmailAddresses" */
  EmailAddresses_aggregate: EmailAddresses_Aggregate;
  /** fetch data from the table: "EmailAddresses" using primary key columns */
  EmailAddresses_by_pk?: Maybe<EmailAddresses>;
  /** fetch data from the table in a streaming manner: "EmailAddresses" */
  EmailAddresses_stream: Array<EmailAddresses>;
  /** fetch data from the table: "EmailEvents" */
  EmailEvents: Array<EmailEvents>;
  /** fetch aggregated fields from the table: "EmailEvents" */
  EmailEvents_aggregate: EmailEvents_Aggregate;
  /** fetch data from the table: "EmailEvents" using primary key columns */
  EmailEvents_by_pk?: Maybe<EmailEvents>;
  /** fetch data from the table in a streaming manner: "EmailEvents" */
  EmailEvents_stream: Array<EmailEvents>;
  /** An array relationship */
  EmailRecords: Array<EmailRecords>;
  /** An aggregate relationship */
  EmailRecords_aggregate: EmailRecords_Aggregate;
  /** fetch data from the table: "EmailRecords" using primary key columns */
  EmailRecords_by_pk?: Maybe<EmailRecords>;
  /** fetch data from the table in a streaming manner: "EmailRecords" */
  EmailRecords_stream: Array<EmailRecords>;
  /** An array relationship */
  EmailSignatures: Array<EmailSignatures>;
  /** An aggregate relationship */
  EmailSignatures_aggregate: EmailSignatures_Aggregate;
  /** fetch data from the table: "EmailSignatures" using primary key columns */
  EmailSignatures_by_pk?: Maybe<EmailSignatures>;
  /** fetch data from the table in a streaming manner: "EmailSignatures" */
  EmailSignatures_stream: Array<EmailSignatures>;
  /** fetch data from the table: "EmailSubFolders" */
  EmailSubFolders: Array<EmailSubFolders>;
  /** fetch aggregated fields from the table: "EmailSubFolders" */
  EmailSubFolders_aggregate: EmailSubFolders_Aggregate;
  /** fetch data from the table: "EmailSubFolders" using primary key columns */
  EmailSubFolders_by_pk?: Maybe<EmailSubFolders>;
  /** fetch data from the table in a streaming manner: "EmailSubFolders" */
  EmailSubFolders_stream: Array<EmailSubFolders>;
  /** fetch data from the table: "EmailTypeEnum" */
  EmailTypeEnum: Array<EmailTypeEnum>;
  /** fetch aggregated fields from the table: "EmailTypeEnum" */
  EmailTypeEnum_aggregate: EmailTypeEnum_Aggregate;
  /** fetch data from the table: "EmailTypeEnum" using primary key columns */
  EmailTypeEnum_by_pk?: Maybe<EmailTypeEnum>;
  /** fetch data from the table in a streaming manner: "EmailTypeEnum" */
  EmailTypeEnum_stream: Array<EmailTypeEnum>;
  /** fetch data from the table: "EmailTypes" */
  EmailTypes: Array<EmailTypes>;
  /** fetch aggregated fields from the table: "EmailTypes" */
  EmailTypes_aggregate: EmailTypes_Aggregate;
  /** fetch data from the table: "EmailTypes" using primary key columns */
  EmailTypes_by_pk?: Maybe<EmailTypes>;
  /** fetch data from the table in a streaming manner: "EmailTypes" */
  EmailTypes_stream: Array<EmailTypes>;
  /** An array relationship */
  Emails: Array<Emails>;
  /** An aggregate relationship */
  Emails_aggregate: Emails_Aggregate;
  /** fetch data from the table: "Emails" using primary key columns */
  Emails_by_pk?: Maybe<Emails>;
  /** fetch data from the table in a streaming manner: "Emails" */
  Emails_stream: Array<Emails>;
  /** fetch data from the table: "EmploymentHireType" */
  EmploymentHireType: Array<EmploymentHireType>;
  /** fetch aggregated fields from the table: "EmploymentHireType" */
  EmploymentHireType_aggregate: EmploymentHireType_Aggregate;
  /** fetch data from the table: "EmploymentHireType" using primary key columns */
  EmploymentHireType_by_pk?: Maybe<EmploymentHireType>;
  /** fetch data from the table in a streaming manner: "EmploymentHireType" */
  EmploymentHireType_stream: Array<EmploymentHireType>;
  /** fetch data from the table: "EmploymentTypes" */
  EmploymentTypes: Array<EmploymentTypes>;
  /** fetch aggregated fields from the table: "EmploymentTypes" */
  EmploymentTypes_aggregate: EmploymentTypes_Aggregate;
  /** fetch data from the table: "EmploymentTypes" using primary key columns */
  EmploymentTypes_by_pk?: Maybe<EmploymentTypes>;
  /** fetch data from the table in a streaming manner: "EmploymentTypes" */
  EmploymentTypes_stream: Array<EmploymentTypes>;
  /** An array relationship */
  Employments: Array<Employments>;
  /** An aggregate relationship */
  Employments_aggregate: Employments_Aggregate;
  /** fetch data from the table: "Employments" using primary key columns */
  Employments_by_pk?: Maybe<Employments>;
  /** fetch data from the table in a streaming manner: "Employments" */
  Employments_stream: Array<Employments>;
  /** fetch data from the table: "EntitlementCategories" */
  EntitlementCategories: Array<EntitlementCategories>;
  /** fetch aggregated fields from the table: "EntitlementCategories" */
  EntitlementCategories_aggregate: EntitlementCategories_Aggregate;
  /** fetch data from the table: "EntitlementCategories" using primary key columns */
  EntitlementCategories_by_pk?: Maybe<EntitlementCategories>;
  /** fetch data from the table in a streaming manner: "EntitlementCategories" */
  EntitlementCategories_stream: Array<EntitlementCategories>;
  /** An array relationship */
  Entitlements: Array<Entitlements>;
  /** An aggregate relationship */
  Entitlements_aggregate: Entitlements_Aggregate;
  /** fetch data from the table: "Entitlements" using primary key columns */
  Entitlements_by_pk?: Maybe<Entitlements>;
  /** fetch data from the table in a streaming manner: "Entitlements" */
  Entitlements_stream: Array<Entitlements>;
  /** fetch data from the table: "EquationFormulaOption" */
  EquationFormulaOption: Array<EquationFormulaOption>;
  /** fetch aggregated fields from the table: "EquationFormulaOption" */
  EquationFormulaOption_aggregate: EquationFormulaOption_Aggregate;
  /** fetch data from the table: "EquationFormulaOption" using primary key columns */
  EquationFormulaOption_by_pk?: Maybe<EquationFormulaOption>;
  /** fetch data from the table in a streaming manner: "EquationFormulaOption" */
  EquationFormulaOption_stream: Array<EquationFormulaOption>;
  /** An array relationship */
  Equations: Array<Equations>;
  /** An aggregate relationship */
  Equations_aggregate: Equations_Aggregate;
  /** fetch data from the table: "Equations" using primary key columns */
  Equations_by_pk?: Maybe<Equations>;
  /** fetch data from the table in a streaming manner: "Equations" */
  Equations_stream: Array<Equations>;
  /** An array relationship */
  Events: Array<Events>;
  /** An aggregate relationship */
  Events_aggregate: Events_Aggregate;
  /** fetch data from the table: "Events" using primary key columns */
  Events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner: "Events" */
  Events_stream: Array<Events>;
  /** fetch data from the table: "Expense" */
  Expense: Array<Expense>;
  /** fetch aggregated fields from the table: "Expense" */
  Expense_aggregate: Expense_Aggregate;
  /** fetch data from the table: "Expense" using primary key columns */
  Expense_by_pk?: Maybe<Expense>;
  /** fetch data from the table in a streaming manner: "Expense" */
  Expense_stream: Array<Expense>;
  /** fetch data from the table: "ExternalIntegrationTypes" */
  ExternalIntegrationTypes: Array<ExternalIntegrationTypes>;
  /** fetch aggregated fields from the table: "ExternalIntegrationTypes" */
  ExternalIntegrationTypes_aggregate: ExternalIntegrationTypes_Aggregate;
  /** fetch data from the table: "ExternalIntegrationTypes" using primary key columns */
  ExternalIntegrationTypes_by_pk?: Maybe<ExternalIntegrationTypes>;
  /** fetch data from the table in a streaming manner: "ExternalIntegrationTypes" */
  ExternalIntegrationTypes_stream: Array<ExternalIntegrationTypes>;
  /** fetch data from the table: "ExternalIntegrations" */
  ExternalIntegrations: Array<ExternalIntegrations>;
  /** fetch aggregated fields from the table: "ExternalIntegrations" */
  ExternalIntegrations_aggregate: ExternalIntegrations_Aggregate;
  /** fetch data from the table: "ExternalIntegrations" using primary key columns */
  ExternalIntegrations_by_pk?: Maybe<ExternalIntegrations>;
  /** fetch data from the table in a streaming manner: "ExternalIntegrations" */
  ExternalIntegrations_stream: Array<ExternalIntegrations>;
  /** An array relationship */
  ExternalJobBoardPublishings: Array<ExternalJobBoardPublishings>;
  /** An aggregate relationship */
  ExternalJobBoardPublishings_aggregate: ExternalJobBoardPublishings_Aggregate;
  /** fetch data from the table: "ExternalJobBoardPublishings" using primary key columns */
  ExternalJobBoardPublishings_by_pk?: Maybe<ExternalJobBoardPublishings>;
  /** fetch data from the table in a streaming manner: "ExternalJobBoardPublishings" */
  ExternalJobBoardPublishings_stream: Array<ExternalJobBoardPublishings>;
  /** fetch data from the table: "ExternalJobBoards" */
  ExternalJobBoards: Array<ExternalJobBoards>;
  /** fetch aggregated fields from the table: "ExternalJobBoards" */
  ExternalJobBoards_aggregate: ExternalJobBoards_Aggregate;
  /** fetch data from the table: "ExternalJobBoards" using primary key columns */
  ExternalJobBoards_by_pk?: Maybe<ExternalJobBoards>;
  /** fetch data from the table in a streaming manner: "ExternalJobBoards" */
  ExternalJobBoards_stream: Array<ExternalJobBoards>;
  /** fetch data from the table: "ExternalSystem" */
  ExternalSystem: Array<ExternalSystem>;
  /** fetch data from the table: "ExternalSystemInstance" */
  ExternalSystemInstance: Array<ExternalSystemInstance>;
  /** fetch aggregated fields from the table: "ExternalSystemInstance" */
  ExternalSystemInstance_aggregate: ExternalSystemInstance_Aggregate;
  /** fetch data from the table: "ExternalSystemInstance" using primary key columns */
  ExternalSystemInstance_by_pk?: Maybe<ExternalSystemInstance>;
  /** fetch data from the table in a streaming manner: "ExternalSystemInstance" */
  ExternalSystemInstance_stream: Array<ExternalSystemInstance>;
  /** fetch aggregated fields from the table: "ExternalSystem" */
  ExternalSystem_aggregate: ExternalSystem_Aggregate;
  /** fetch data from the table: "ExternalSystem" using primary key columns */
  ExternalSystem_by_pk?: Maybe<ExternalSystem>;
  /** fetch data from the table in a streaming manner: "ExternalSystem" */
  ExternalSystem_stream: Array<ExternalSystem>;
  /** fetch data from the table: "FeatureFlags" */
  FeatureFlags: Array<FeatureFlags>;
  /** fetch aggregated fields from the table: "FeatureFlags" */
  FeatureFlags_aggregate: FeatureFlags_Aggregate;
  /** fetch data from the table: "FeatureFlags" using primary key columns */
  FeatureFlags_by_pk?: Maybe<FeatureFlags>;
  /** fetch data from the table in a streaming manner: "FeatureFlags" */
  FeatureFlags_stream: Array<FeatureFlags>;
  /** fetch data from the table: "Field" */
  Field: Array<Field>;
  /** fetch data from the table: "FieldDef" */
  FieldDef: Array<FieldDef>;
  /** fetch aggregated fields from the table: "FieldDef" */
  FieldDef_aggregate: FieldDef_Aggregate;
  /** fetch data from the table: "FieldDef" using primary key columns */
  FieldDef_by_pk?: Maybe<FieldDef>;
  /** fetch data from the table in a streaming manner: "FieldDef" */
  FieldDef_stream: Array<FieldDef>;
  /** An array relationship */
  FieldPicklistValues: Array<FieldPicklistValues>;
  /** An aggregate relationship */
  FieldPicklistValues_aggregate: FieldPicklistValues_Aggregate;
  /** fetch data from the table: "FieldPicklistValues" using primary key columns */
  FieldPicklistValues_by_pk?: Maybe<FieldPicklistValues>;
  /** fetch data from the table in a streaming manner: "FieldPicklistValues" */
  FieldPicklistValues_stream: Array<FieldPicklistValues>;
  /** fetch data from the table: "FieldTypeDef" */
  FieldTypeDef: Array<FieldTypeDef>;
  /** fetch aggregated fields from the table: "FieldTypeDef" */
  FieldTypeDef_aggregate: FieldTypeDef_Aggregate;
  /** fetch data from the table: "FieldTypeDef" using primary key columns */
  FieldTypeDef_by_pk?: Maybe<FieldTypeDef>;
  /** fetch data from the table in a streaming manner: "FieldTypeDef" */
  FieldTypeDef_stream: Array<FieldTypeDef>;
  /** fetch aggregated fields from the table: "Field" */
  Field_aggregate: Field_Aggregate;
  /** fetch data from the table: "Field" using primary key columns */
  Field_by_pk?: Maybe<Field>;
  /** fetch data from the table in a streaming manner: "Field" */
  Field_stream: Array<Field>;
  /** An array relationship */
  FileTags: Array<FileTags>;
  /** An aggregate relationship */
  FileTags_aggregate: FileTags_Aggregate;
  /** fetch data from the table: "FileTags" using primary key columns */
  FileTags_by_pk?: Maybe<FileTags>;
  /** fetch data from the table in a streaming manner: "FileTags" */
  FileTags_stream: Array<FileTags>;
  /** An array relationship */
  Files: Array<Files>;
  /** An aggregate relationship */
  Files_aggregate: Files_Aggregate;
  /** fetch data from the table: "Files" using primary key columns */
  Files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "Files" */
  Files_stream: Array<Files>;
  /** An array relationship */
  FormInputs: Array<FormInputs>;
  /** An aggregate relationship */
  FormInputs_aggregate: FormInputs_Aggregate;
  /** fetch data from the table: "FormInputs" using primary key columns */
  FormInputs_by_pk?: Maybe<FormInputs>;
  /** fetch data from the table in a streaming manner: "FormInputs" */
  FormInputs_stream: Array<FormInputs>;
  /** An array relationship */
  FormSelectOptions: Array<FormSelectOptions>;
  /** An aggregate relationship */
  FormSelectOptions_aggregate: FormSelectOptions_Aggregate;
  /** fetch data from the table: "FormSelectOptions" using primary key columns */
  FormSelectOptions_by_pk?: Maybe<FormSelectOptions>;
  /** fetch data from the table in a streaming manner: "FormSelectOptions" */
  FormSelectOptions_stream: Array<FormSelectOptions>;
  /** An array relationship */
  FormulaOptions: Array<FormulaOptions>;
  /** An aggregate relationship */
  FormulaOptions_aggregate: FormulaOptions_Aggregate;
  /** fetch data from the table: "FormulaOptions" using primary key columns */
  FormulaOptions_by_pk?: Maybe<FormulaOptions>;
  /** fetch data from the table in a streaming manner: "FormulaOptions" */
  FormulaOptions_stream: Array<FormulaOptions>;
  /** fetch data from the table: "GenderEnum" */
  GenderEnum: Array<GenderEnum>;
  /** fetch aggregated fields from the table: "GenderEnum" */
  GenderEnum_aggregate: GenderEnum_Aggregate;
  /** fetch data from the table: "GenderEnum" using primary key columns */
  GenderEnum_by_pk?: Maybe<GenderEnum>;
  /** fetch data from the table in a streaming manner: "GenderEnum" */
  GenderEnum_stream: Array<GenderEnum>;
  /** fetch data from the table: "GeocodingHistory" */
  GeocodingHistory: Array<GeocodingHistory>;
  /** fetch aggregated fields from the table: "GeocodingHistory" */
  GeocodingHistory_aggregate: GeocodingHistory_Aggregate;
  /** fetch data from the table: "GeocodingHistory" using primary key columns */
  GeocodingHistory_by_pk?: Maybe<GeocodingHistory>;
  /** fetch data from the table in a streaming manner: "GeocodingHistory" */
  GeocodingHistory_stream: Array<GeocodingHistory>;
  /** An array relationship */
  Goals: Array<Goals>;
  /** An aggregate relationship */
  Goals_aggregate: Goals_Aggregate;
  /** fetch data from the table: "Goals" using primary key columns */
  Goals_by_pk?: Maybe<Goals>;
  /** fetch data from the table in a streaming manner: "Goals" */
  Goals_stream: Array<Goals>;
  /** fetch data from the table: "GoogleSheetCalcTemplates" */
  GoogleSheetCalcTemplates: Array<GoogleSheetCalcTemplates>;
  /** fetch aggregated fields from the table: "GoogleSheetCalcTemplates" */
  GoogleSheetCalcTemplates_aggregate: GoogleSheetCalcTemplates_Aggregate;
  /** fetch data from the table: "GoogleSheetCalcTemplates" using primary key columns */
  GoogleSheetCalcTemplates_by_pk?: Maybe<GoogleSheetCalcTemplates>;
  /** fetch data from the table in a streaming manner: "GoogleSheetCalcTemplates" */
  GoogleSheetCalcTemplates_stream: Array<GoogleSheetCalcTemplates>;
  /** fetch data from the table: "GoogleSheetCalculations" */
  GoogleSheetCalculations: Array<GoogleSheetCalculations>;
  /** fetch aggregated fields from the table: "GoogleSheetCalculations" */
  GoogleSheetCalculations_aggregate: GoogleSheetCalculations_Aggregate;
  /** fetch data from the table: "GoogleSheetCalculations" using primary key columns */
  GoogleSheetCalculations_by_pk?: Maybe<GoogleSheetCalculations>;
  /** fetch data from the table in a streaming manner: "GoogleSheetCalculations" */
  GoogleSheetCalculations_stream: Array<GoogleSheetCalculations>;
  /** An array relationship */
  GroupJobs: Array<GroupJobs>;
  /** An aggregate relationship */
  GroupJobs_aggregate: GroupJobs_Aggregate;
  /** fetch data from the table: "GroupJobs" using primary key columns */
  GroupJobs_by_pk?: Maybe<GroupJobs>;
  /** fetch data from the table in a streaming manner: "GroupJobs" */
  GroupJobs_stream: Array<GroupJobs>;
  /** fetch data from the table: "Groups" */
  Groups: Array<Groups>;
  /** fetch aggregated fields from the table: "Groups" */
  Groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "Groups" using primary key columns */
  Groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table in a streaming manner: "Groups" */
  Groups_stream: Array<Groups>;
  /** fetch data from the table: "HighestStages" */
  HighestStages: Array<HighestStages>;
  /** fetch aggregated fields from the table: "HighestStages" */
  HighestStages_aggregate: HighestStages_Aggregate;
  /** fetch data from the table: "HighestStages" using primary key columns */
  HighestStages_by_pk?: Maybe<HighestStages>;
  /** fetch data from the table in a streaming manner: "HighestStages" */
  HighestStages_stream: Array<HighestStages>;
  /** fetch data from the table: "HireTypesEnum" */
  HireTypesEnum: Array<HireTypesEnum>;
  /** fetch aggregated fields from the table: "HireTypesEnum" */
  HireTypesEnum_aggregate: HireTypesEnum_Aggregate;
  /** fetch data from the table: "HireTypesEnum" using primary key columns */
  HireTypesEnum_by_pk?: Maybe<HireTypesEnum>;
  /** fetch data from the table in a streaming manner: "HireTypesEnum" */
  HireTypesEnum_stream: Array<HireTypesEnum>;
  /** An array relationship */
  ITRequestContractEmails: Array<ItRequestContractEmails>;
  /** An aggregate relationship */
  ITRequestContractEmails_aggregate: ItRequestContractEmails_Aggregate;
  /** fetch data from the table: "ITRequestContractEmails" using primary key columns */
  ITRequestContractEmails_by_pk?: Maybe<ItRequestContractEmails>;
  /** fetch data from the table in a streaming manner: "ITRequestContractEmails" */
  ITRequestContractEmails_stream: Array<ItRequestContractEmails>;
  /** An array relationship */
  Identities: Array<Identities>;
  /** An array relationship */
  IdentitiesOverlaysLinks: Array<IdentitiesOverlaysLinks>;
  /** An aggregate relationship */
  IdentitiesOverlaysLinks_aggregate: IdentitiesOverlaysLinks_Aggregate;
  /** fetch data from the table: "IdentitiesOverlaysLinks" using primary key columns */
  IdentitiesOverlaysLinks_by_pk?: Maybe<IdentitiesOverlaysLinks>;
  /** fetch data from the table in a streaming manner: "IdentitiesOverlaysLinks" */
  IdentitiesOverlaysLinks_stream: Array<IdentitiesOverlaysLinks>;
  /** An aggregate relationship */
  Identities_aggregate: Identities_Aggregate;
  /** fetch data from the table: "Identities" using primary key columns */
  Identities_by_pk?: Maybe<Identities>;
  /** fetch data from the table in a streaming manner: "Identities" */
  Identities_stream: Array<Identities>;
  /** An array relationship */
  IdentityAssignments: Array<IdentityAssignments>;
  /** An aggregate relationship */
  IdentityAssignments_aggregate: IdentityAssignments_Aggregate;
  /** fetch data from the table: "IdentityAssignments" using primary key columns */
  IdentityAssignments_by_pk?: Maybe<IdentityAssignments>;
  /** fetch data from the table in a streaming manner: "IdentityAssignments" */
  IdentityAssignments_stream: Array<IdentityAssignments>;
  /** An array relationship */
  IdentityComments: Array<IdentityComments>;
  /** An aggregate relationship */
  IdentityComments_aggregate: IdentityComments_Aggregate;
  /** fetch data from the table: "IdentityComments" using primary key columns */
  IdentityComments_by_pk?: Maybe<IdentityComments>;
  /** fetch data from the table in a streaming manner: "IdentityComments" */
  IdentityComments_stream: Array<IdentityComments>;
  /** fetch data from the table: "IdentityContractRelationship" */
  IdentityContractRelationship: Array<IdentityContractRelationship>;
  /** fetch aggregated fields from the table: "IdentityContractRelationship" */
  IdentityContractRelationship_aggregate: IdentityContractRelationship_Aggregate;
  /** fetch data from the table: "IdentityContractRelationship" using primary key columns */
  IdentityContractRelationship_by_pk?: Maybe<IdentityContractRelationship>;
  /** fetch data from the table in a streaming manner: "IdentityContractRelationship" */
  IdentityContractRelationship_stream: Array<IdentityContractRelationship>;
  /** fetch data from the table: "IdentityDepartmentRelationship" */
  IdentityDepartmentRelationship: Array<IdentityDepartmentRelationship>;
  /** fetch aggregated fields from the table: "IdentityDepartmentRelationship" */
  IdentityDepartmentRelationship_aggregate: IdentityDepartmentRelationship_Aggregate;
  /** fetch data from the table: "IdentityDepartmentRelationship" using primary key columns */
  IdentityDepartmentRelationship_by_pk?: Maybe<IdentityDepartmentRelationship>;
  /** fetch data from the table in a streaming manner: "IdentityDepartmentRelationship" */
  IdentityDepartmentRelationship_stream: Array<IdentityDepartmentRelationship>;
  /** An array relationship */
  IdentityGroups: Array<IdentityGroups>;
  /** An aggregate relationship */
  IdentityGroups_aggregate: IdentityGroups_Aggregate;
  /** fetch data from the table: "IdentityGroups" using primary key columns */
  IdentityGroups_by_pk?: Maybe<IdentityGroups>;
  /** fetch data from the table in a streaming manner: "IdentityGroups" */
  IdentityGroups_stream: Array<IdentityGroups>;
  /** fetch data from the table: "IdentityJobRelationship" */
  IdentityJobRelationship: Array<IdentityJobRelationship>;
  /** fetch aggregated fields from the table: "IdentityJobRelationship" */
  IdentityJobRelationship_aggregate: IdentityJobRelationship_Aggregate;
  /** fetch data from the table: "IdentityJobRelationship" using primary key columns */
  IdentityJobRelationship_by_pk?: Maybe<IdentityJobRelationship>;
  /** fetch data from the table in a streaming manner: "IdentityJobRelationship" */
  IdentityJobRelationship_stream: Array<IdentityJobRelationship>;
  /** An array relationship */
  IdentityPartnerGroupData: Array<IdentityPartnerGroupData>;
  /** An aggregate relationship */
  IdentityPartnerGroupData_aggregate: IdentityPartnerGroupData_Aggregate;
  /** fetch data from the table: "IdentityPartnerGroupData" using primary key columns */
  IdentityPartnerGroupData_by_pk?: Maybe<IdentityPartnerGroupData>;
  /** fetch data from the table in a streaming manner: "IdentityPartnerGroupData" */
  IdentityPartnerGroupData_stream: Array<IdentityPartnerGroupData>;
  /** fetch data from the table: "IdentityRelationshipType" */
  IdentityRelationshipType: Array<IdentityRelationshipType>;
  /** fetch aggregated fields from the table: "IdentityRelationshipType" */
  IdentityRelationshipType_aggregate: IdentityRelationshipType_Aggregate;
  /** fetch data from the table: "IdentityRelationshipType" using primary key columns */
  IdentityRelationshipType_by_pk?: Maybe<IdentityRelationshipType>;
  /** fetch data from the table in a streaming manner: "IdentityRelationshipType" */
  IdentityRelationshipType_stream: Array<IdentityRelationshipType>;
  /** An array relationship */
  IdentityRoles: Array<IdentityRoles>;
  /** An aggregate relationship */
  IdentityRoles_aggregate: IdentityRoles_Aggregate;
  /** fetch data from the table in a streaming manner: "IdentityRoles" */
  IdentityRoles_stream: Array<IdentityRoles>;
  /** An array relationship */
  IdentitySubTypeEmails: Array<IdentitySubTypeEmails>;
  /** An aggregate relationship */
  IdentitySubTypeEmails_aggregate: IdentitySubTypeEmails_Aggregate;
  /** fetch data from the table: "IdentitySubTypeEmails" using primary key columns */
  IdentitySubTypeEmails_by_pk?: Maybe<IdentitySubTypeEmails>;
  /** fetch data from the table in a streaming manner: "IdentitySubTypeEmails" */
  IdentitySubTypeEmails_stream: Array<IdentitySubTypeEmails>;
  /** fetch data from the table: "IdentitySubTypes" */
  IdentitySubTypes: Array<IdentitySubTypes>;
  /** fetch aggregated fields from the table: "IdentitySubTypes" */
  IdentitySubTypes_aggregate: IdentitySubTypes_Aggregate;
  /** fetch data from the table: "IdentitySubTypes" using primary key columns */
  IdentitySubTypes_by_pk?: Maybe<IdentitySubTypes>;
  /** fetch data from the table in a streaming manner: "IdentitySubTypes" */
  IdentitySubTypes_stream: Array<IdentitySubTypes>;
  /** An array relationship */
  IdentityTags: Array<IdentityTags>;
  /** An aggregate relationship */
  IdentityTags_aggregate: IdentityTags_Aggregate;
  /** fetch data from the table: "IdentityTags" using primary key columns */
  IdentityTags_by_pk?: Maybe<IdentityTags>;
  /** fetch data from the table in a streaming manner: "IdentityTags" */
  IdentityTags_stream: Array<IdentityTags>;
  /** An array relationship */
  IdentityTokens: Array<IdentityTokens>;
  /** An aggregate relationship */
  IdentityTokens_aggregate: IdentityTokens_Aggregate;
  /** fetch data from the table: "IdentityTokens" using primary key columns */
  IdentityTokens_by_pk?: Maybe<IdentityTokens>;
  /** fetch data from the table in a streaming manner: "IdentityTokens" */
  IdentityTokens_stream: Array<IdentityTokens>;
  /** fetch data from the table: "IdentityTypes" */
  IdentityTypes: Array<IdentityTypes>;
  /** fetch aggregated fields from the table: "IdentityTypes" */
  IdentityTypes_aggregate: IdentityTypes_Aggregate;
  /** fetch data from the table: "IdentityTypes" using primary key columns */
  IdentityTypes_by_pk?: Maybe<IdentityTypes>;
  /** fetch data from the table in a streaming manner: "IdentityTypes" */
  IdentityTypes_stream: Array<IdentityTypes>;
  /** fetch data from the table: "IdentityUserAccessGroup" */
  IdentityUserAccessGroup: Array<IdentityUserAccessGroup>;
  /** fetch aggregated fields from the table: "IdentityUserAccessGroup" */
  IdentityUserAccessGroup_aggregate: IdentityUserAccessGroup_Aggregate;
  /** fetch data from the table: "IdentityUserAccessGroup" using primary key columns */
  IdentityUserAccessGroup_by_pk?: Maybe<IdentityUserAccessGroup>;
  /** fetch data from the table in a streaming manner: "IdentityUserAccessGroup" */
  IdentityUserAccessGroup_stream: Array<IdentityUserAccessGroup>;
  /** An array relationship */
  Images: Array<Images>;
  /** An aggregate relationship */
  Images_aggregate: Images_Aggregate;
  /** fetch data from the table: "Images" using primary key columns */
  Images_by_pk?: Maybe<Images>;
  /** fetch data from the table in a streaming manner: "Images" */
  Images_stream: Array<Images>;
  /** fetch data from the table: "InputTypes" */
  InputTypes: Array<InputTypes>;
  /** fetch aggregated fields from the table: "InputTypes" */
  InputTypes_aggregate: InputTypes_Aggregate;
  /** fetch data from the table: "InputTypes" using primary key columns */
  InputTypes_by_pk?: Maybe<InputTypes>;
  /** fetch data from the table in a streaming manner: "InputTypes" */
  InputTypes_stream: Array<InputTypes>;
  /** fetch data from the table: "InternalJobCategories" */
  InternalJobCategories: Array<InternalJobCategories>;
  /** fetch aggregated fields from the table: "InternalJobCategories" */
  InternalJobCategories_aggregate: InternalJobCategories_Aggregate;
  /** fetch data from the table: "InternalJobCategories" using primary key columns */
  InternalJobCategories_by_pk?: Maybe<InternalJobCategories>;
  /** fetch data from the table in a streaming manner: "InternalJobCategories" */
  InternalJobCategories_stream: Array<InternalJobCategories>;
  /** An array relationship */
  Interviews: Array<Interviews>;
  /** An aggregate relationship */
  Interviews_aggregate: Interviews_Aggregate;
  /** fetch data from the table: "Interviews" using primary key columns */
  Interviews_by_pk?: Maybe<Interviews>;
  /** fetch data from the table in a streaming manner: "Interviews" */
  Interviews_stream: Array<Interviews>;
  /** fetch data from the table: "JobAvailabilities" */
  JobAvailabilities: Array<JobAvailabilities>;
  /** fetch aggregated fields from the table: "JobAvailabilities" */
  JobAvailabilities_aggregate: JobAvailabilities_Aggregate;
  /** fetch data from the table: "JobAvailabilities" using primary key columns */
  JobAvailabilities_by_pk?: Maybe<JobAvailabilities>;
  /** fetch data from the table in a streaming manner: "JobAvailabilities" */
  JobAvailabilities_stream: Array<JobAvailabilities>;
  /** An array relationship */
  JobComments: Array<JobComments>;
  /** An aggregate relationship */
  JobComments_aggregate: JobComments_Aggregate;
  /** fetch data from the table: "JobComments" using primary key columns */
  JobComments_by_pk?: Maybe<JobComments>;
  /** fetch data from the table in a streaming manner: "JobComments" */
  JobComments_stream: Array<JobComments>;
  /** fetch data from the table: "JobFiles" */
  JobFiles: Array<JobFiles>;
  /** fetch aggregated fields from the table: "JobFiles" */
  JobFiles_aggregate: JobFiles_Aggregate;
  /** fetch data from the table: "JobFiles" using primary key columns */
  JobFiles_by_pk?: Maybe<JobFiles>;
  /** fetch data from the table in a streaming manner: "JobFiles" */
  JobFiles_stream: Array<JobFiles>;
  /** fetch data from the table: "JobFundingStatusEnum" */
  JobFundingStatusEnum: Array<JobFundingStatusEnum>;
  /** fetch aggregated fields from the table: "JobFundingStatusEnum" */
  JobFundingStatusEnum_aggregate: JobFundingStatusEnum_Aggregate;
  /** fetch data from the table: "JobFundingStatusEnum" using primary key columns */
  JobFundingStatusEnum_by_pk?: Maybe<JobFundingStatusEnum>;
  /** fetch data from the table in a streaming manner: "JobFundingStatusEnum" */
  JobFundingStatusEnum_stream: Array<JobFundingStatusEnum>;
  /** fetch data from the table: "JobHireTypesEnum" */
  JobHireTypesEnum: Array<JobHireTypesEnum>;
  /** fetch aggregated fields from the table: "JobHireTypesEnum" */
  JobHireTypesEnum_aggregate: JobHireTypesEnum_Aggregate;
  /** fetch data from the table: "JobHireTypesEnum" using primary key columns */
  JobHireTypesEnum_by_pk?: Maybe<JobHireTypesEnum>;
  /** fetch data from the table in a streaming manner: "JobHireTypesEnum" */
  JobHireTypesEnum_stream: Array<JobHireTypesEnum>;
  /** fetch data from the table: "JobPositions" */
  JobPositions: Array<JobPositions>;
  /** fetch aggregated fields from the table: "JobPositions" */
  JobPositions_aggregate: JobPositions_Aggregate;
  /** fetch data from the table: "JobPositions" using primary key columns */
  JobPositions_by_pk?: Maybe<JobPositions>;
  /** fetch data from the table in a streaming manner: "JobPositions" */
  JobPositions_stream: Array<JobPositions>;
  /** fetch data from the table: "JobStatuses" */
  JobStatuses: Array<JobStatuses>;
  /** fetch aggregated fields from the table: "JobStatuses" */
  JobStatuses_aggregate: JobStatuses_Aggregate;
  /** fetch data from the table: "JobStatuses" using primary key columns */
  JobStatuses_by_pk?: Maybe<JobStatuses>;
  /** fetch data from the table in a streaming manner: "JobStatuses" */
  JobStatuses_stream: Array<JobStatuses>;
  /** An array relationship */
  Jobs: Array<Jobs>;
  /** An aggregate relationship */
  Jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "Jobs" using primary key columns */
  Jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table in a streaming manner: "Jobs" */
  Jobs_stream: Array<Jobs>;
  /** fetch data from the table: "MagicLinks" */
  MagicLinks: Array<MagicLinks>;
  /** fetch aggregated fields from the table: "MagicLinks" */
  MagicLinks_aggregate: MagicLinks_Aggregate;
  /** fetch data from the table: "MagicLinks" using primary key columns */
  MagicLinks_by_pk?: Maybe<MagicLinks>;
  /** fetch data from the table in a streaming manner: "MagicLinks" */
  MagicLinks_stream: Array<MagicLinks>;
  /** fetch data from the table: "MigrationData" */
  MigrationData: Array<MigrationData>;
  /** fetch aggregated fields from the table: "MigrationData" */
  MigrationData_aggregate: MigrationData_Aggregate;
  /** fetch data from the table: "MigrationData" using primary key columns */
  MigrationData_by_pk?: Maybe<MigrationData>;
  /** fetch data from the table in a streaming manner: "MigrationData" */
  MigrationData_stream: Array<MigrationData>;
  /** fetch data from the table: "ObjectZoo" */
  ObjectZoo: Array<ObjectZoo>;
  /** fetch aggregated fields from the table: "ObjectZoo" */
  ObjectZoo_aggregate: ObjectZoo_Aggregate;
  /** fetch data from the table: "ObjectZoo" using primary key columns */
  ObjectZoo_by_pk?: Maybe<ObjectZoo>;
  /** fetch data from the table in a streaming manner: "ObjectZoo" */
  ObjectZoo_stream: Array<ObjectZoo>;
  /** fetch data from the table: "Overlay" */
  Overlay: Array<Overlay>;
  /** fetch data from the table: "OverlayDefinition" */
  OverlayDefinition: Array<OverlayDefinition>;
  /** fetch aggregated fields from the table: "OverlayDefinition" */
  OverlayDefinition_aggregate: OverlayDefinition_Aggregate;
  /** fetch data from the table: "OverlayDefinition" using primary key columns */
  OverlayDefinition_by_pk?: Maybe<OverlayDefinition>;
  /** fetch data from the table in a streaming manner: "OverlayDefinition" */
  OverlayDefinition_stream: Array<OverlayDefinition>;
  /** fetch aggregated fields from the table: "Overlay" */
  Overlay_aggregate: Overlay_Aggregate;
  /** fetch data from the table: "Overlay" using primary key columns */
  Overlay_by_pk?: Maybe<Overlay>;
  /** fetch data from the table in a streaming manner: "Overlay" */
  Overlay_stream: Array<Overlay>;
  /** fetch data from the table: "PhoneNumbers" */
  PhoneNumbers: Array<PhoneNumbers>;
  /** fetch aggregated fields from the table: "PhoneNumbers" */
  PhoneNumbers_aggregate: PhoneNumbers_Aggregate;
  /** fetch data from the table: "PhoneNumbers" using primary key columns */
  PhoneNumbers_by_pk?: Maybe<PhoneNumbers>;
  /** fetch data from the table in a streaming manner: "PhoneNumbers" */
  PhoneNumbers_stream: Array<PhoneNumbers>;
  /** An array relationship */
  PicklistValues: Array<PicklistValues>;
  /** An aggregate relationship */
  PicklistValues_aggregate: PicklistValues_Aggregate;
  /** fetch data from the table: "PicklistValues" using primary key columns */
  PicklistValues_by_pk?: Maybe<PicklistValues>;
  /** fetch data from the table in a streaming manner: "PicklistValues" */
  PicklistValues_stream: Array<PicklistValues>;
  /** fetch data from the table: "Picklists" */
  Picklists: Array<Picklists>;
  /** fetch aggregated fields from the table: "Picklists" */
  Picklists_aggregate: Picklists_Aggregate;
  /** fetch data from the table: "Picklists" using primary key columns */
  Picklists_by_pk?: Maybe<Picklists>;
  /** fetch data from the table in a streaming manner: "Picklists" */
  Picklists_stream: Array<Picklists>;
  /** fetch data from the table: "ProductUpdates" */
  ProductUpdates: Array<ProductUpdates>;
  /** fetch aggregated fields from the table: "ProductUpdates" */
  ProductUpdates_aggregate: ProductUpdates_Aggregate;
  /** fetch data from the table: "ProductUpdates" using primary key columns */
  ProductUpdates_by_pk?: Maybe<ProductUpdates>;
  /** fetch data from the table in a streaming manner: "ProductUpdates" */
  ProductUpdates_stream: Array<ProductUpdates>;
  /** An array relationship */
  QuestionnaireContactTemplateVariables: Array<QuestionnaireContactTemplateVariables>;
  /** An aggregate relationship */
  QuestionnaireContactTemplateVariables_aggregate: QuestionnaireContactTemplateVariables_Aggregate;
  /** fetch data from the table: "QuestionnaireContactTemplateVariables" using primary key columns */
  QuestionnaireContactTemplateVariables_by_pk?: Maybe<QuestionnaireContactTemplateVariables>;
  /** fetch data from the table in a streaming manner: "QuestionnaireContactTemplateVariables" */
  QuestionnaireContactTemplateVariables_stream: Array<QuestionnaireContactTemplateVariables>;
  /** An array relationship */
  QuestionnaireLinks: Array<QuestionnaireLinks>;
  /** An aggregate relationship */
  QuestionnaireLinks_aggregate: QuestionnaireLinks_Aggregate;
  /** fetch data from the table: "QuestionnaireLinks" using primary key columns */
  QuestionnaireLinks_by_pk?: Maybe<QuestionnaireLinks>;
  /** fetch data from the table in a streaming manner: "QuestionnaireLinks" */
  QuestionnaireLinks_stream: Array<QuestionnaireLinks>;
  /** fetch data from the table: "QuestionnaireResponses" */
  QuestionnaireResponses: Array<QuestionnaireResponses>;
  /** fetch aggregated fields from the table: "QuestionnaireResponses" */
  QuestionnaireResponses_aggregate: QuestionnaireResponses_Aggregate;
  /** fetch data from the table: "QuestionnaireResponses" using primary key columns */
  QuestionnaireResponses_by_pk?: Maybe<QuestionnaireResponses>;
  /** fetch data from the table in a streaming manner: "QuestionnaireResponses" */
  QuestionnaireResponses_stream: Array<QuestionnaireResponses>;
  /** fetch data from the table: "Questionnaires" */
  Questionnaires: Array<Questionnaires>;
  /** fetch aggregated fields from the table: "Questionnaires" */
  Questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "Questionnaires" using primary key columns */
  Questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table in a streaming manner: "Questionnaires" */
  Questionnaires_stream: Array<Questionnaires>;
  /** fetch data from the table: "RRWebRecords" */
  RRWebRecords: Array<RrWebRecords>;
  /** fetch aggregated fields from the table: "RRWebRecords" */
  RRWebRecords_aggregate: RrWebRecords_Aggregate;
  /** fetch data from the table: "RRWebRecords" using primary key columns */
  RRWebRecords_by_pk?: Maybe<RrWebRecords>;
  /** fetch data from the table in a streaming manner: "RRWebRecords" */
  RRWebRecords_stream: Array<RrWebRecords>;
  /** fetch data from the table: "RaceEnum" */
  RaceEnum: Array<RaceEnum>;
  /** fetch aggregated fields from the table: "RaceEnum" */
  RaceEnum_aggregate: RaceEnum_Aggregate;
  /** fetch data from the table: "RaceEnum" using primary key columns */
  RaceEnum_by_pk?: Maybe<RaceEnum>;
  /** fetch data from the table in a streaming manner: "RaceEnum" */
  RaceEnum_stream: Array<RaceEnum>;
  /** fetch data from the table: "RangeValues" */
  RangeValues: Array<RangeValues>;
  /** fetch aggregated fields from the table: "RangeValues" */
  RangeValues_aggregate: RangeValues_Aggregate;
  /** fetch data from the table: "RangeValues" using primary key columns */
  RangeValues_by_pk?: Maybe<RangeValues>;
  /** fetch data from the table in a streaming manner: "RangeValues" */
  RangeValues_stream: Array<RangeValues>;
  /** fetch data from the table: "RejectionTypeEnum" */
  RejectionTypeEnum: Array<RejectionTypeEnum>;
  /** fetch aggregated fields from the table: "RejectionTypeEnum" */
  RejectionTypeEnum_aggregate: RejectionTypeEnum_Aggregate;
  /** fetch data from the table: "RejectionTypeEnum" using primary key columns */
  RejectionTypeEnum_by_pk?: Maybe<RejectionTypeEnum>;
  /** fetch data from the table in a streaming manner: "RejectionTypeEnum" */
  RejectionTypeEnum_stream: Array<RejectionTypeEnum>;
  /** An array relationship */
  Rejections: Array<Rejections>;
  /** An aggregate relationship */
  Rejections_aggregate: Rejections_Aggregate;
  /** fetch data from the table: "Rejections" using primary key columns */
  Rejections_by_pk?: Maybe<Rejections>;
  /** fetch data from the table in a streaming manner: "Rejections" */
  Rejections_stream: Array<Rejections>;
  /** fetch data from the table: "RenderedEmails" */
  RenderedEmails: Array<RenderedEmails>;
  /** fetch aggregated fields from the table: "RenderedEmails" */
  RenderedEmails_aggregate: RenderedEmails_Aggregate;
  /** fetch data from the table: "RenderedEmails" using primary key columns */
  RenderedEmails_by_pk?: Maybe<RenderedEmails>;
  /** fetch data from the table in a streaming manner: "RenderedEmails" */
  RenderedEmails_stream: Array<RenderedEmails>;
  /** An array relationship */
  ResumeClaims: Array<ResumeClaims>;
  /** An aggregate relationship */
  ResumeClaims_aggregate: ResumeClaims_Aggregate;
  /** fetch data from the table: "ResumeClaims" using primary key columns */
  ResumeClaims_by_pk?: Maybe<ResumeClaims>;
  /** fetch data from the table in a streaming manner: "ResumeClaims" */
  ResumeClaims_stream: Array<ResumeClaims>;
  /** fetch data from the table: "ResumeEmailAddresses" */
  ResumeEmailAddresses: Array<ResumeEmailAddresses>;
  /** fetch aggregated fields from the table: "ResumeEmailAddresses" */
  ResumeEmailAddresses_aggregate: ResumeEmailAddresses_Aggregate;
  /** fetch data from the table: "ResumeEmailAddresses" using primary key columns */
  ResumeEmailAddresses_by_pk?: Maybe<ResumeEmailAddresses>;
  /** fetch data from the table in a streaming manner: "ResumeEmailAddresses" */
  ResumeEmailAddresses_stream: Array<ResumeEmailAddresses>;
  /** fetch data from the table: "ResumeIngestStatus" */
  ResumeIngestStatus: Array<ResumeIngestStatus>;
  /** fetch aggregated fields from the table: "ResumeIngestStatus" */
  ResumeIngestStatus_aggregate: ResumeIngestStatus_Aggregate;
  /** fetch data from the table: "ResumeIngestStatus" using primary key columns */
  ResumeIngestStatus_by_pk?: Maybe<ResumeIngestStatus>;
  /** fetch data from the table in a streaming manner: "ResumeIngestStatus" */
  ResumeIngestStatus_stream: Array<ResumeIngestStatus>;
  /** fetch data from the table: "ResumePhoneNumbers" */
  ResumePhoneNumbers: Array<ResumePhoneNumbers>;
  /** fetch aggregated fields from the table: "ResumePhoneNumbers" */
  ResumePhoneNumbers_aggregate: ResumePhoneNumbers_Aggregate;
  /** fetch data from the table: "ResumePhoneNumbers" using primary key columns */
  ResumePhoneNumbers_by_pk?: Maybe<ResumePhoneNumbers>;
  /** fetch data from the table in a streaming manner: "ResumePhoneNumbers" */
  ResumePhoneNumbers_stream: Array<ResumePhoneNumbers>;
  /** An array relationship */
  Resumes: Array<Resumes>;
  /** An aggregate relationship */
  Resumes_aggregate: Resumes_Aggregate;
  /** fetch data from the table: "Resumes" using primary key columns */
  Resumes_by_pk?: Maybe<Resumes>;
  /** fetch data from the table in a streaming manner: "Resumes" */
  Resumes_stream: Array<Resumes>;
  /** fetch data from the table: "ReviewCriteria" */
  ReviewCriteria: Array<ReviewCriteria>;
  /** fetch data from the table: "ReviewCriteriaScore" */
  ReviewCriteriaScore: Array<ReviewCriteriaScore>;
  /** An array relationship */
  ReviewCriteriaScoreAggregates: Array<ReviewCriteriaScoreAggregates>;
  /** An aggregate relationship */
  ReviewCriteriaScoreAggregates_aggregate: ReviewCriteriaScoreAggregates_Aggregate;
  /** fetch data from the table in a streaming manner: "ReviewCriteriaScoreAggregates" */
  ReviewCriteriaScoreAggregates_stream: Array<ReviewCriteriaScoreAggregates>;
  /** fetch aggregated fields from the table: "ReviewCriteriaScore" */
  ReviewCriteriaScore_aggregate: ReviewCriteriaScore_Aggregate;
  /** fetch data from the table: "ReviewCriteriaScore" using primary key columns */
  ReviewCriteriaScore_by_pk?: Maybe<ReviewCriteriaScore>;
  /** fetch data from the table in a streaming manner: "ReviewCriteriaScore" */
  ReviewCriteriaScore_stream: Array<ReviewCriteriaScore>;
  /** fetch aggregated fields from the table: "ReviewCriteria" */
  ReviewCriteria_aggregate: ReviewCriteria_Aggregate;
  /** fetch data from the table: "ReviewCriteria" using primary key columns */
  ReviewCriteria_by_pk?: Maybe<ReviewCriteria>;
  /** fetch data from the table in a streaming manner: "ReviewCriteria" */
  ReviewCriteria_stream: Array<ReviewCriteria>;
  /** An array relationship */
  ReviewGroupContactTemplateVariables: Array<ReviewGroupContactTemplateVariables>;
  /** An aggregate relationship */
  ReviewGroupContactTemplateVariables_aggregate: ReviewGroupContactTemplateVariables_Aggregate;
  /** fetch data from the table: "ReviewGroupContactTemplateVariables" using primary key columns */
  ReviewGroupContactTemplateVariables_by_pk?: Maybe<ReviewGroupContactTemplateVariables>;
  /** fetch data from the table in a streaming manner: "ReviewGroupContactTemplateVariables" */
  ReviewGroupContactTemplateVariables_stream: Array<ReviewGroupContactTemplateVariables>;
  /** An array relationship */
  ReviewGroupCriteria: Array<ReviewGroupCriteria>;
  /** An aggregate relationship */
  ReviewGroupCriteria_aggregate: ReviewGroupCriteria_Aggregate;
  /** fetch data from the table: "ReviewGroupCriteria" using primary key columns */
  ReviewGroupCriteria_by_pk?: Maybe<ReviewGroupCriteria>;
  /** fetch data from the table in a streaming manner: "ReviewGroupCriteria" */
  ReviewGroupCriteria_stream: Array<ReviewGroupCriteria>;
  /** An array relationship */
  ReviewGroupFiles: Array<ReviewGroupFiles>;
  /** An aggregate relationship */
  ReviewGroupFiles_aggregate: ReviewGroupFiles_Aggregate;
  /** fetch data from the table: "ReviewGroupFiles" using primary key columns */
  ReviewGroupFiles_by_pk?: Maybe<ReviewGroupFiles>;
  /** fetch data from the table in a streaming manner: "ReviewGroupFiles" */
  ReviewGroupFiles_stream: Array<ReviewGroupFiles>;
  /** An array relationship */
  ReviewGroupStages: Array<ReviewGroupStages>;
  /** An aggregate relationship */
  ReviewGroupStages_aggregate: ReviewGroupStages_Aggregate;
  /** fetch data from the table: "ReviewGroupStages" using primary key columns */
  ReviewGroupStages_by_pk?: Maybe<ReviewGroupStages>;
  /** fetch data from the table in a streaming manner: "ReviewGroupStages" */
  ReviewGroupStages_stream: Array<ReviewGroupStages>;
  /** fetch data from the table: "ReviewGroupSubTypeEnum" */
  ReviewGroupSubTypeEnum: Array<ReviewGroupSubTypeEnum>;
  /** fetch aggregated fields from the table: "ReviewGroupSubTypeEnum" */
  ReviewGroupSubTypeEnum_aggregate: ReviewGroupSubTypeEnum_Aggregate;
  /** fetch data from the table: "ReviewGroupSubTypeEnum" using primary key columns */
  ReviewGroupSubTypeEnum_by_pk?: Maybe<ReviewGroupSubTypeEnum>;
  /** fetch data from the table in a streaming manner: "ReviewGroupSubTypeEnum" */
  ReviewGroupSubTypeEnum_stream: Array<ReviewGroupSubTypeEnum>;
  /** fetch data from the table: "ReviewGroupTypeEnum" */
  ReviewGroupTypeEnum: Array<ReviewGroupTypeEnum>;
  /** fetch aggregated fields from the table: "ReviewGroupTypeEnum" */
  ReviewGroupTypeEnum_aggregate: ReviewGroupTypeEnum_Aggregate;
  /** fetch data from the table: "ReviewGroupTypeEnum" using primary key columns */
  ReviewGroupTypeEnum_by_pk?: Maybe<ReviewGroupTypeEnum>;
  /** fetch data from the table in a streaming manner: "ReviewGroupTypeEnum" */
  ReviewGroupTypeEnum_stream: Array<ReviewGroupTypeEnum>;
  /** fetch data from the table: "ReviewGroups" */
  ReviewGroups: Array<ReviewGroups>;
  /** fetch aggregated fields from the table: "ReviewGroups" */
  ReviewGroups_aggregate: ReviewGroups_Aggregate;
  /** fetch data from the table: "ReviewGroups" using primary key columns */
  ReviewGroups_by_pk?: Maybe<ReviewGroups>;
  /** fetch data from the table in a streaming manner: "ReviewGroups" */
  ReviewGroups_stream: Array<ReviewGroups>;
  /** An array relationship */
  Reviews: Array<Reviews>;
  /** fetch data from the table: "ReviewsResultEnum" */
  ReviewsResultEnum: Array<ReviewsResultEnum>;
  /** fetch aggregated fields from the table: "ReviewsResultEnum" */
  ReviewsResultEnum_aggregate: ReviewsResultEnum_Aggregate;
  /** fetch data from the table: "ReviewsResultEnum" using primary key columns */
  ReviewsResultEnum_by_pk?: Maybe<ReviewsResultEnum>;
  /** fetch data from the table in a streaming manner: "ReviewsResultEnum" */
  ReviewsResultEnum_stream: Array<ReviewsResultEnum>;
  /** An aggregate relationship */
  Reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "Reviews" using primary key columns */
  Reviews_by_pk?: Maybe<Reviews>;
  /** fetch data from the table in a streaming manner: "Reviews" */
  Reviews_stream: Array<Reviews>;
  /** fetch data from the table: "RoleAccess" */
  RoleAccess: Array<RoleAccess>;
  /** fetch aggregated fields from the table: "RoleAccess" */
  RoleAccess_aggregate: RoleAccess_Aggregate;
  /** fetch data from the table: "RoleAccess" using primary key columns */
  RoleAccess_by_pk?: Maybe<RoleAccess>;
  /** fetch data from the table in a streaming manner: "RoleAccess" */
  RoleAccess_stream: Array<RoleAccess>;
  /** An array relationship */
  Roles: Array<Roles>;
  /** An aggregate relationship */
  Roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "Roles" using primary key columns */
  Roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "Roles" */
  Roles_stream: Array<Roles>;
  /** fetch data from the table: "SearchFilterTypesEnum" */
  SearchFilterTypesEnum: Array<SearchFilterTypesEnum>;
  /** fetch aggregated fields from the table: "SearchFilterTypesEnum" */
  SearchFilterTypesEnum_aggregate: SearchFilterTypesEnum_Aggregate;
  /** fetch data from the table: "SearchFilterTypesEnum" using primary key columns */
  SearchFilterTypesEnum_by_pk?: Maybe<SearchFilterTypesEnum>;
  /** fetch data from the table in a streaming manner: "SearchFilterTypesEnum" */
  SearchFilterTypesEnum_stream: Array<SearchFilterTypesEnum>;
  /** fetch data from the table: "SearchFilters" */
  SearchFilters: Array<SearchFilters>;
  /** fetch aggregated fields from the table: "SearchFilters" */
  SearchFilters_aggregate: SearchFilters_Aggregate;
  /** fetch data from the table: "SearchFilters" using primary key columns */
  SearchFilters_by_pk?: Maybe<SearchFilters>;
  /** fetch data from the table in a streaming manner: "SearchFilters" */
  SearchFilters_stream: Array<SearchFilters>;
  /** fetch data from the table: "SecurityClearances" */
  SecurityClearances: Array<SecurityClearances>;
  /** fetch aggregated fields from the table: "SecurityClearances" */
  SecurityClearances_aggregate: SecurityClearances_Aggregate;
  /** fetch data from the table: "SecurityClearances" using primary key columns */
  SecurityClearances_by_pk?: Maybe<SecurityClearances>;
  /** fetch data from the table in a streaming manner: "SecurityClearances" */
  SecurityClearances_stream: Array<SecurityClearances>;
  /** fetch data from the table: "SequelizeMeta" */
  SequelizeMeta: Array<SequelizeMeta>;
  /** fetch aggregated fields from the table: "SequelizeMeta" */
  SequelizeMeta_aggregate: SequelizeMeta_Aggregate;
  /** fetch data from the table: "SequelizeMeta" using primary key columns */
  SequelizeMeta_by_pk?: Maybe<SequelizeMeta>;
  /** fetch data from the table in a streaming manner: "SequelizeMeta" */
  SequelizeMeta_stream: Array<SequelizeMeta>;
  /** An array relationship */
  StageEmailContracts: Array<StageEmailContracts>;
  /** An aggregate relationship */
  StageEmailContracts_aggregate: StageEmailContracts_Aggregate;
  /** fetch data from the table: "StageEmailContracts" using primary key columns */
  StageEmailContracts_by_pk?: Maybe<StageEmailContracts>;
  /** fetch data from the table in a streaming manner: "StageEmailContracts" */
  StageEmailContracts_stream: Array<StageEmailContracts>;
  /** An array relationship */
  StageEmails: Array<StageEmails>;
  /** An aggregate relationship */
  StageEmails_aggregate: StageEmails_Aggregate;
  /** fetch data from the table: "StageEmails" using primary key columns */
  StageEmails_by_pk?: Maybe<StageEmails>;
  /** fetch data from the table in a streaming manner: "StageEmails" */
  StageEmails_stream: Array<StageEmails>;
  /** fetch data from the table: "StageQuestionnaireMapping" */
  StageQuestionnaireMapping: Array<StageQuestionnaireMapping>;
  /** fetch data from the table: "StageQuestionnaireMappingIdenity" */
  StageQuestionnaireMappingIdenity: Array<StageQuestionnaireMappingIdenity>;
  /** fetch aggregated fields from the table: "StageQuestionnaireMappingIdenity" */
  StageQuestionnaireMappingIdenity_aggregate: StageQuestionnaireMappingIdenity_Aggregate;
  /** fetch data from the table: "StageQuestionnaireMappingIdenity" using primary key columns */
  StageQuestionnaireMappingIdenity_by_pk?: Maybe<StageQuestionnaireMappingIdenity>;
  /** fetch data from the table in a streaming manner: "StageQuestionnaireMappingIdenity" */
  StageQuestionnaireMappingIdenity_stream: Array<StageQuestionnaireMappingIdenity>;
  /** fetch aggregated fields from the table: "StageQuestionnaireMapping" */
  StageQuestionnaireMapping_aggregate: StageQuestionnaireMapping_Aggregate;
  /** fetch data from the table: "StageQuestionnaireMapping" using primary key columns */
  StageQuestionnaireMapping_by_pk?: Maybe<StageQuestionnaireMapping>;
  /** fetch data from the table in a streaming manner: "StageQuestionnaireMapping" */
  StageQuestionnaireMapping_stream: Array<StageQuestionnaireMapping>;
  /** An array relationship */
  StageQuestionnaireTemplateVariables: Array<StageQuestionnaireTemplateVariables>;
  /** An aggregate relationship */
  StageQuestionnaireTemplateVariables_aggregate: StageQuestionnaireTemplateVariables_Aggregate;
  /** fetch data from the table: "StageQuestionnaireTemplateVariables" using primary key columns */
  StageQuestionnaireTemplateVariables_by_pk?: Maybe<StageQuestionnaireTemplateVariables>;
  /** fetch data from the table in a streaming manner: "StageQuestionnaireTemplateVariables" */
  StageQuestionnaireTemplateVariables_stream: Array<StageQuestionnaireTemplateVariables>;
  /** An array relationship */
  StageRoles: Array<StageRoles>;
  /** An aggregate relationship */
  StageRoles_aggregate: StageRoles_Aggregate;
  /** fetch data from the table: "StageRoles" using primary key columns */
  StageRoles_by_pk?: Maybe<StageRoles>;
  /** fetch data from the table in a streaming manner: "StageRoles" */
  StageRoles_stream: Array<StageRoles>;
  /** fetch data from the table: "StageTransitions" */
  StageTransitions: Array<StageTransitions>;
  /** fetch aggregated fields from the table: "StageTransitions" */
  StageTransitions_aggregate: StageTransitions_Aggregate;
  /** fetch data from the table: "StageTransitions" using primary key columns */
  StageTransitions_by_pk?: Maybe<StageTransitions>;
  /** fetch data from the table in a streaming manner: "StageTransitions" */
  StageTransitions_stream: Array<StageTransitions>;
  /** An array relationship */
  Stages: Array<Stages>;
  /** An aggregate relationship */
  Stages_aggregate: Stages_Aggregate;
  /** fetch data from the table: "Stages" using primary key columns */
  Stages_by_pk?: Maybe<Stages>;
  /** fetch data from the table in a streaming manner: "Stages" */
  Stages_stream: Array<Stages>;
  /** fetch data from the table: "StateMachines" */
  StateMachines: Array<StateMachines>;
  /** fetch aggregated fields from the table: "StateMachines" */
  StateMachines_aggregate: StateMachines_Aggregate;
  /** fetch data from the table: "StateMachines" using primary key columns */
  StateMachines_by_pk?: Maybe<StateMachines>;
  /** fetch data from the table in a streaming manner: "StateMachines" */
  StateMachines_stream: Array<StateMachines>;
  /** An array relationship */
  Submissions: Array<Submissions>;
  /** An array relationship */
  SubmissionsOverlaysLinks: Array<SubmissionsOverlaysLinks>;
  /** An aggregate relationship */
  SubmissionsOverlaysLinks_aggregate: SubmissionsOverlaysLinks_Aggregate;
  /** fetch data from the table: "SubmissionsOverlaysLinks" using primary key columns */
  SubmissionsOverlaysLinks_by_pk?: Maybe<SubmissionsOverlaysLinks>;
  /** fetch data from the table in a streaming manner: "SubmissionsOverlaysLinks" */
  SubmissionsOverlaysLinks_stream: Array<SubmissionsOverlaysLinks>;
  /** An aggregate relationship */
  Submissions_aggregate: Submissions_Aggregate;
  /** fetch data from the table: "Submissions" using primary key columns */
  Submissions_by_pk?: Maybe<Submissions>;
  /** fetch data from the table in a streaming manner: "Submissions" */
  Submissions_stream: Array<Submissions>;
  /** fetch data from the table: "TableConfigTypes" */
  TableConfigTypes: Array<TableConfigTypes>;
  /** fetch aggregated fields from the table: "TableConfigTypes" */
  TableConfigTypes_aggregate: TableConfigTypes_Aggregate;
  /** fetch data from the table: "TableConfigTypes" using primary key columns */
  TableConfigTypes_by_pk?: Maybe<TableConfigTypes>;
  /** fetch data from the table in a streaming manner: "TableConfigTypes" */
  TableConfigTypes_stream: Array<TableConfigTypes>;
  /** An array relationship */
  TableConfigs: Array<TableConfigs>;
  /** An aggregate relationship */
  TableConfigs_aggregate: TableConfigs_Aggregate;
  /** fetch data from the table: "TableConfigs" using primary key columns */
  TableConfigs_by_pk?: Maybe<TableConfigs>;
  /** fetch data from the table in a streaming manner: "TableConfigs" */
  TableConfigs_stream: Array<TableConfigs>;
  /** fetch data from the table: "Tags" */
  Tags: Array<Tags>;
  /** fetch aggregated fields from the table: "Tags" */
  Tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "Tags" using primary key columns */
  Tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "Tags" */
  Tags_stream: Array<Tags>;
  /** An array relationship */
  TaskGroupAssignees: Array<TaskGroupAssignees>;
  /** An aggregate relationship */
  TaskGroupAssignees_aggregate: TaskGroupAssignees_Aggregate;
  /** fetch data from the table: "TaskGroupAssignees" using primary key columns */
  TaskGroupAssignees_by_pk?: Maybe<TaskGroupAssignees>;
  /** fetch data from the table in a streaming manner: "TaskGroupAssignees" */
  TaskGroupAssignees_stream: Array<TaskGroupAssignees>;
  /** An array relationship */
  TaskGroups: Array<TaskGroups>;
  /** An aggregate relationship */
  TaskGroups_aggregate: TaskGroups_Aggregate;
  /** fetch data from the table: "TaskGroups" using primary key columns */
  TaskGroups_by_pk?: Maybe<TaskGroups>;
  /** fetch data from the table in a streaming manner: "TaskGroups" */
  TaskGroups_stream: Array<TaskGroups>;
  /** fetch data from the table: "TaskPriorityTypes" */
  TaskPriorityTypes: Array<TaskPriorityTypes>;
  /** fetch aggregated fields from the table: "TaskPriorityTypes" */
  TaskPriorityTypes_aggregate: TaskPriorityTypes_Aggregate;
  /** fetch data from the table: "TaskPriorityTypes" using primary key columns */
  TaskPriorityTypes_by_pk?: Maybe<TaskPriorityTypes>;
  /** fetch data from the table in a streaming manner: "TaskPriorityTypes" */
  TaskPriorityTypes_stream: Array<TaskPriorityTypes>;
  /** fetch data from the table: "TaskTypes" */
  TaskTypes: Array<TaskTypes>;
  /** fetch aggregated fields from the table: "TaskTypes" */
  TaskTypes_aggregate: TaskTypes_Aggregate;
  /** fetch data from the table: "TaskTypes" using primary key columns */
  TaskTypes_by_pk?: Maybe<TaskTypes>;
  /** fetch data from the table in a streaming manner: "TaskTypes" */
  TaskTypes_stream: Array<TaskTypes>;
  /** An array relationship */
  Tasks: Array<Tasks>;
  /** An aggregate relationship */
  Tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "Tasks" using primary key columns */
  Tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner: "Tasks" */
  Tasks_stream: Array<Tasks>;
  /** An array relationship */
  Terminations: Array<Terminations>;
  /** An aggregate relationship */
  Terminations_aggregate: Terminations_Aggregate;
  /** fetch data from the table: "Terminations" using primary key columns */
  Terminations_by_pk?: Maybe<Terminations>;
  /** fetch data from the table in a streaming manner: "Terminations" */
  Terminations_stream: Array<Terminations>;
  /** fetch data from the table: "TimeInStages" */
  TimeInStages: Array<TimeInStages>;
  /** fetch aggregated fields from the table: "TimeInStages" */
  TimeInStages_aggregate: TimeInStages_Aggregate;
  /** fetch data from the table: "TimeInStages" using primary key columns */
  TimeInStages_by_pk?: Maybe<TimeInStages>;
  /** fetch data from the table in a streaming manner: "TimeInStages" */
  TimeInStages_stream: Array<TimeInStages>;
  /** An array relationship */
  TimelineEvents: Array<TimelineEvents>;
  /** An aggregate relationship */
  TimelineEvents_aggregate: TimelineEvents_Aggregate;
  /** fetch data from the table: "TimelineEvents" using primary key columns */
  TimelineEvents_by_pk?: Maybe<TimelineEvents>;
  /** fetch data from the table in a streaming manner: "TimelineEvents" */
  TimelineEvents_stream: Array<TimelineEvents>;
  /** fetch data from the table: "Tokens" */
  Tokens: Array<Tokens>;
  /** fetch aggregated fields from the table: "Tokens" */
  Tokens_aggregate: Tokens_Aggregate;
  /** fetch data from the table: "Tokens" using primary key columns */
  Tokens_by_pk?: Maybe<Tokens>;
  /** fetch data from the table in a streaming manner: "Tokens" */
  Tokens_stream: Array<Tokens>;
  /** fetch data from the table: "UserAccessGroups" */
  UserAccessGroups: Array<UserAccessGroups>;
  /** fetch aggregated fields from the table: "UserAccessGroups" */
  UserAccessGroups_aggregate: UserAccessGroups_Aggregate;
  /** fetch data from the table: "UserAccessGroups" using primary key columns */
  UserAccessGroups_by_pk?: Maybe<UserAccessGroups>;
  /** fetch data from the table in a streaming manner: "UserAccessGroups" */
  UserAccessGroups_stream: Array<UserAccessGroups>;
  /** fetch data from the table: "UserInterfaceState" */
  UserInterfaceState: Array<UserInterfaceState>;
  /** fetch aggregated fields from the table: "UserInterfaceState" */
  UserInterfaceState_aggregate: UserInterfaceState_Aggregate;
  /** fetch data from the table: "UserInterfaceState" using primary key columns */
  UserInterfaceState_by_pk?: Maybe<UserInterfaceState>;
  /** fetch data from the table in a streaming manner: "UserInterfaceState" */
  UserInterfaceState_stream: Array<UserInterfaceState>;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  /** fetch data from the table: "Users" using primary key columns */
  Users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "Users" */
  Users_stream: Array<Users>;
  /** fetch data from the table: "ValueTypes" */
  ValueTypes: Array<ValueTypes>;
  /** fetch aggregated fields from the table: "ValueTypes" */
  ValueTypes_aggregate: ValueTypes_Aggregate;
  /** fetch data from the table: "ValueTypes" using primary key columns */
  ValueTypes_by_pk?: Maybe<ValueTypes>;
  /** fetch data from the table in a streaming manner: "ValueTypes" */
  ValueTypes_stream: Array<ValueTypes>;
  /** fetch data from the table: "VeteranStatusEnum" */
  VeteranStatusEnum: Array<VeteranStatusEnum>;
  /** fetch aggregated fields from the table: "VeteranStatusEnum" */
  VeteranStatusEnum_aggregate: VeteranStatusEnum_Aggregate;
  /** fetch data from the table: "VeteranStatusEnum" using primary key columns */
  VeteranStatusEnum_by_pk?: Maybe<VeteranStatusEnum>;
  /** fetch data from the table in a streaming manner: "VeteranStatusEnum" */
  VeteranStatusEnum_stream: Array<VeteranStatusEnum>;
  /** fetch data from the table: "WhiteLabeling" */
  WhiteLabeling: Array<WhiteLabeling>;
  /** fetch aggregated fields from the table: "WhiteLabeling" */
  WhiteLabeling_aggregate: WhiteLabeling_Aggregate;
  /** fetch data from the table: "WhiteLabeling" using primary key columns */
  WhiteLabeling_by_pk?: Maybe<WhiteLabeling>;
  /** fetch data from the table in a streaming manner: "WhiteLabeling" */
  WhiteLabeling_stream: Array<WhiteLabeling>;
  /** fetch data from the table: "WorkflowRegressions" */
  WorkflowRegressions: Array<WorkflowRegressions>;
  /** fetch aggregated fields from the table: "WorkflowRegressions" */
  WorkflowRegressions_aggregate: WorkflowRegressions_Aggregate;
  /** fetch data from the table: "WorkflowRegressions" using primary key columns */
  WorkflowRegressions_by_pk?: Maybe<WorkflowRegressions>;
  /** fetch data from the table in a streaming manner: "WorkflowRegressions" */
  WorkflowRegressions_stream: Array<WorkflowRegressions>;
  /** execute function "activity_count_stat" which returns "identity_roles_stat" */
  activity_count_stat: Array<Identity_Roles_Stat>;
  /** execute function "activity_count_stat" and query aggregates on result of table type "identity_roles_stat" */
  activity_count_stat_aggregate: Identity_Roles_Stat_Aggregate;
  /** execute function "activity_counts_rm_totals_only" which returns "identity_roles_arr" */
  activity_counts_rm_totals_only: Array<Identity_Roles_Arr>;
  /** execute function "activity_counts_rm_totals_only" and query aggregates on result of table type "identity_roles_arr" */
  activity_counts_rm_totals_only_aggregate: Identity_Roles_Arr_Aggregate;
  /** fetch data from the table: "ams_for_analytics_ui" */
  ams_for_analytics_ui: Array<Ams_For_Analytics_Ui>;
  /** fetch aggregated fields from the table: "ams_for_analytics_ui" */
  ams_for_analytics_ui_aggregate: Ams_For_Analytics_Ui_Aggregate;
  /** fetch data from the table in a streaming manner: "ams_for_analytics_ui" */
  ams_for_analytics_ui_stream: Array<Ams_For_Analytics_Ui>;
  /** fetch data from the table: "analytics_view" */
  analytics_view: Array<Analytics_View>;
  /** fetch aggregated fields from the table: "analytics_view" */
  analytics_view_aggregate: Analytics_View_Aggregate;
  /** fetch data from the table in a streaming manner: "analytics_view" */
  analytics_view_stream: Array<Analytics_View>;
  /** fetch data from the table: "candidate_account_managers" */
  candidate_account_managers: Array<Candidate_Account_Managers>;
  /** fetch aggregated fields from the table: "candidate_account_managers" */
  candidate_account_managers_aggregate: Candidate_Account_Managers_Aggregate;
  /** fetch data from the table in a streaming manner: "candidate_account_managers" */
  candidate_account_managers_stream: Array<Candidate_Account_Managers>;
  /** fetch data from the table: "citizenshipEnum" */
  citizenshipEnum: Array<CitizenshipEnum>;
  /** fetch aggregated fields from the table: "citizenshipEnum" */
  citizenshipEnum_aggregate: CitizenshipEnum_Aggregate;
  /** fetch data from the table: "citizenshipEnum" using primary key columns */
  citizenshipEnum_by_pk?: Maybe<CitizenshipEnum>;
  /** fetch data from the table in a streaming manner: "citizenshipEnum" */
  citizenshipEnum_stream: Array<CitizenshipEnum>;
  /** fetch data from the table: "comments_for_analytics" */
  comments_for_analytics: Array<Comments_For_Analytics>;
  /** fetch aggregated fields from the table: "comments_for_analytics" */
  comments_for_analytics_aggregate: Comments_For_Analytics_Aggregate;
  /** fetch data from the table: "comments_for_analytics_join_underlying_view" */
  comments_for_analytics_join_underlying_view: Array<Comments_For_Analytics_Join_Underlying_View>;
  /** fetch aggregated fields from the table: "comments_for_analytics_join_underlying_view" */
  comments_for_analytics_join_underlying_view_aggregate: Comments_For_Analytics_Join_Underlying_View_Aggregate;
  /** fetch data from the table in a streaming manner: "comments_for_analytics_join_underlying_view" */
  comments_for_analytics_join_underlying_view_stream: Array<Comments_For_Analytics_Join_Underlying_View>;
  /** fetch data from the table in a streaming manner: "comments_for_analytics" */
  comments_for_analytics_stream: Array<Comments_For_Analytics>;
  /** An array relationship */
  contract_emails: Array<Contract_Emails>;
  /** An aggregate relationship */
  contract_emails_aggregate: Contract_Emails_Aggregate;
  /** fetch data from the table: "contract_emails" using primary key columns */
  contract_emails_by_pk?: Maybe<Contract_Emails>;
  /** fetch data from the table in a streaming manner: "contract_emails" */
  contract_emails_stream: Array<Contract_Emails>;
  /** fetch data from the table: "current_wk_activity" */
  current_wk_activity: Array<Current_Wk_Activity>;
  /** fetch aggregated fields from the table: "current_wk_activity" */
  current_wk_activity_aggregate: Current_Wk_Activity_Aggregate;
  /** fetch data from the table in a streaming manner: "current_wk_activity" */
  current_wk_activity_stream: Array<Current_Wk_Activity>;
  /** fetch data from the table: "current_workflows_for_identity" */
  current_workflows_for_identity: Array<Current_Workflows_For_Identity>;
  /** fetch aggregated fields from the table: "current_workflows_for_identity" */
  current_workflows_for_identity_aggregate: Current_Workflows_For_Identity_Aggregate;
  /** fetch data from the table in a streaming manner: "current_workflows_for_identity" */
  current_workflows_for_identity_stream: Array<Current_Workflows_For_Identity>;
  /** fetch data from the table: "dashboard_activity_counts_by_day" */
  dashboard_activity_counts_by_day: Array<Dashboard_Activity_Counts_By_Day>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_day" */
  dashboard_activity_counts_by_day_aggregate: Dashboard_Activity_Counts_By_Day_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_activity_counts_by_day" */
  dashboard_activity_counts_by_day_stream: Array<Dashboard_Activity_Counts_By_Day>;
  /** fetch data from the table: "dashboard_activity_counts_by_hour" */
  dashboard_activity_counts_by_hour: Array<Dashboard_Activity_Counts_By_Hour>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_hour" */
  dashboard_activity_counts_by_hour_aggregate: Dashboard_Activity_Counts_By_Hour_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_activity_counts_by_hour" */
  dashboard_activity_counts_by_hour_stream: Array<Dashboard_Activity_Counts_By_Hour>;
  /** fetch data from the table: "dashboard_activity_counts_by_month" */
  dashboard_activity_counts_by_month: Array<Dashboard_Activity_Counts_By_Month>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_month" */
  dashboard_activity_counts_by_month_aggregate: Dashboard_Activity_Counts_By_Month_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_activity_counts_by_month" */
  dashboard_activity_counts_by_month_stream: Array<Dashboard_Activity_Counts_By_Month>;
  /** fetch data from the table: "dashboard_activity_counts_by_week" */
  dashboard_activity_counts_by_week: Array<Dashboard_Activity_Counts_By_Week>;
  /** fetch aggregated fields from the table: "dashboard_activity_counts_by_week" */
  dashboard_activity_counts_by_week_aggregate: Dashboard_Activity_Counts_By_Week_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_activity_counts_by_week" */
  dashboard_activity_counts_by_week_stream: Array<Dashboard_Activity_Counts_By_Week>;
  /** fetch data from the table: "dashboard_stage_counts_by_day" */
  dashboard_stage_counts_by_day: Array<Dashboard_Stage_Counts_By_Day>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_day" */
  dashboard_stage_counts_by_day_aggregate: Dashboard_Stage_Counts_By_Day_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_stage_counts_by_day" */
  dashboard_stage_counts_by_day_stream: Array<Dashboard_Stage_Counts_By_Day>;
  /** fetch data from the table: "dashboard_stage_counts_by_month" */
  dashboard_stage_counts_by_month: Array<Dashboard_Stage_Counts_By_Month>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_month" */
  dashboard_stage_counts_by_month_aggregate: Dashboard_Stage_Counts_By_Month_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_stage_counts_by_month" */
  dashboard_stage_counts_by_month_stream: Array<Dashboard_Stage_Counts_By_Month>;
  /** fetch data from the table: "dashboard_stage_counts_by_week" */
  dashboard_stage_counts_by_week: Array<Dashboard_Stage_Counts_By_Week>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_week" */
  dashboard_stage_counts_by_week_aggregate: Dashboard_Stage_Counts_By_Week_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_stage_counts_by_week" */
  dashboard_stage_counts_by_week_stream: Array<Dashboard_Stage_Counts_By_Week>;
  /** fetch data from the table: "dashboard_stage_counts_by_year" */
  dashboard_stage_counts_by_year: Array<Dashboard_Stage_Counts_By_Year>;
  /** fetch aggregated fields from the table: "dashboard_stage_counts_by_year" */
  dashboard_stage_counts_by_year_aggregate: Dashboard_Stage_Counts_By_Year_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_stage_counts_by_year" */
  dashboard_stage_counts_by_year_stream: Array<Dashboard_Stage_Counts_By_Year>;
  /** fetch data from the table: "dashboard_stages_counts" */
  dashboard_stages_counts: Array<Dashboard_Stages_Counts>;
  /** fetch aggregated fields from the table: "dashboard_stages_counts" */
  dashboard_stages_counts_aggregate: Dashboard_Stages_Counts_Aggregate;
  /** execute function "dashboard_stages_counts_for_date_range" which returns "dashboard_stages_counts" */
  dashboard_stages_counts_for_date_range: Array<Dashboard_Stages_Counts>;
  /** execute function "dashboard_stages_counts_for_date_range" and query aggregates on result of table type "dashboard_stages_counts" */
  dashboard_stages_counts_for_date_range_aggregate: Dashboard_Stages_Counts_Aggregate;
  /** fetch data from the table in a streaming manner: "dashboard_stages_counts" */
  dashboard_stages_counts_stream: Array<Dashboard_Stages_Counts>;
  /** fetch data from the table: "deleted_ppl_names" */
  deleted_ppl_names: Array<Deleted_Ppl_Names>;
  /** fetch aggregated fields from the table: "deleted_ppl_names" */
  deleted_ppl_names_aggregate: Deleted_Ppl_Names_Aggregate;
  /** fetch data from the table in a streaming manner: "deleted_ppl_names" */
  deleted_ppl_names_stream: Array<Deleted_Ppl_Names>;
  /** fetch data from the table: "eeo_count_by_comp_bucket_disability" */
  eeo_count_by_comp_bucket_disability: Array<Eeo_Count_By_Comp_Bucket_Disability>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_disability" */
  eeo_count_by_comp_bucket_disability_aggregate: Eeo_Count_By_Comp_Bucket_Disability_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_disability_mat" */
  eeo_count_by_comp_bucket_disability_mat: Array<Eeo_Count_By_Comp_Bucket_Disability_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_disability_mat" */
  eeo_count_by_comp_bucket_disability_mat_aggregate: Eeo_Count_By_Comp_Bucket_Disability_Mat_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_disability_mat" */
  eeo_count_by_comp_bucket_disability_mat_stream: Array<Eeo_Count_By_Comp_Bucket_Disability_Mat>;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_disability" */
  eeo_count_by_comp_bucket_disability_stream: Array<Eeo_Count_By_Comp_Bucket_Disability>;
  /** fetch data from the table: "eeo_count_by_comp_bucket_eeo_category" */
  eeo_count_by_comp_bucket_eeo_category: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_eeo_category" */
  eeo_count_by_comp_bucket_eeo_category_aggregate: Eeo_Count_By_Comp_Bucket_Eeo_Category_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_eeo_category_mat" */
  eeo_count_by_comp_bucket_eeo_category_mat: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_eeo_category_mat" */
  eeo_count_by_comp_bucket_eeo_category_mat_aggregate: Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_eeo_category_mat" */
  eeo_count_by_comp_bucket_eeo_category_mat_stream: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat>;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_eeo_category" */
  eeo_count_by_comp_bucket_eeo_category_stream: Array<Eeo_Count_By_Comp_Bucket_Eeo_Category>;
  /** fetch data from the table: "eeo_count_by_comp_bucket_gender" */
  eeo_count_by_comp_bucket_gender: Array<Eeo_Count_By_Comp_Bucket_Gender>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_gender" */
  eeo_count_by_comp_bucket_gender_aggregate: Eeo_Count_By_Comp_Bucket_Gender_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_gender_mat" */
  eeo_count_by_comp_bucket_gender_mat: Array<Eeo_Count_By_Comp_Bucket_Gender_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_gender_mat" */
  eeo_count_by_comp_bucket_gender_mat_aggregate: Eeo_Count_By_Comp_Bucket_Gender_Mat_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_gender_mat" */
  eeo_count_by_comp_bucket_gender_mat_stream: Array<Eeo_Count_By_Comp_Bucket_Gender_Mat>;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_gender" */
  eeo_count_by_comp_bucket_gender_stream: Array<Eeo_Count_By_Comp_Bucket_Gender>;
  /** fetch data from the table: "eeo_count_by_comp_bucket_race" */
  eeo_count_by_comp_bucket_race: Array<Eeo_Count_By_Comp_Bucket_Race>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_race" */
  eeo_count_by_comp_bucket_race_aggregate: Eeo_Count_By_Comp_Bucket_Race_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_race_mat" */
  eeo_count_by_comp_bucket_race_mat: Array<Eeo_Count_By_Comp_Bucket_Race_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_race_mat" */
  eeo_count_by_comp_bucket_race_mat_aggregate: Eeo_Count_By_Comp_Bucket_Race_Mat_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_race_mat" */
  eeo_count_by_comp_bucket_race_mat_stream: Array<Eeo_Count_By_Comp_Bucket_Race_Mat>;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_race" */
  eeo_count_by_comp_bucket_race_stream: Array<Eeo_Count_By_Comp_Bucket_Race>;
  /** fetch data from the table: "eeo_count_by_comp_bucket_vet_status" */
  eeo_count_by_comp_bucket_vet_status: Array<Eeo_Count_By_Comp_Bucket_Vet_Status>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_vet_status" */
  eeo_count_by_comp_bucket_vet_status_aggregate: Eeo_Count_By_Comp_Bucket_Vet_Status_Aggregate;
  /** fetch data from the table: "eeo_count_by_comp_bucket_vet_status_mat" */
  eeo_count_by_comp_bucket_vet_status_mat: Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat>;
  /** fetch aggregated fields from the table: "eeo_count_by_comp_bucket_vet_status_mat" */
  eeo_count_by_comp_bucket_vet_status_mat_aggregate: Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_vet_status_mat" */
  eeo_count_by_comp_bucket_vet_status_mat_stream: Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat>;
  /** fetch data from the table in a streaming manner: "eeo_count_by_comp_bucket_vet_status" */
  eeo_count_by_comp_bucket_vet_status_stream: Array<Eeo_Count_By_Comp_Bucket_Vet_Status>;
  /** fetch data from the table: "eeo_count_by_race_gender_hisp_by_cat_pivot" */
  eeo_count_by_race_gender_hisp_by_cat_pivot: Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot>;
  /** fetch aggregated fields from the table: "eeo_count_by_race_gender_hisp_by_cat_pivot" */
  eeo_count_by_race_gender_hisp_by_cat_pivot_aggregate: Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_count_by_race_gender_hisp_by_cat_pivot" */
  eeo_count_by_race_gender_hisp_by_cat_pivot_stream: Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot>;
  /** fetch data from the table: "eeo_data_point_status_by_identity_view" */
  eeo_data_point_status_by_identity_view: Array<Eeo_Data_Point_Status_By_Identity_View>;
  /** fetch aggregated fields from the table: "eeo_data_point_status_by_identity_view" */
  eeo_data_point_status_by_identity_view_aggregate: Eeo_Data_Point_Status_By_Identity_View_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_data_point_status_by_identity_view" */
  eeo_data_point_status_by_identity_view_stream: Array<Eeo_Data_Point_Status_By_Identity_View>;
  /** fetch data from the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  eeo_data_points_unknown_as_other_by_identity_view: Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View>;
  /** fetch aggregated fields from the table: "eeo_data_points_unknown_as_other_by_identity_view" */
  eeo_data_points_unknown_as_other_by_identity_view_aggregate: Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_data_points_unknown_as_other_by_identity_view" */
  eeo_data_points_unknown_as_other_by_identity_view_stream: Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View>;
  /** fetch data from the table: "eeo_labor_categories_comp_buckets" */
  eeo_labor_categories_comp_buckets: Array<Eeo_Labor_Categories_Comp_Buckets>;
  /** fetch aggregated fields from the table: "eeo_labor_categories_comp_buckets" */
  eeo_labor_categories_comp_buckets_aggregate: Eeo_Labor_Categories_Comp_Buckets_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_labor_categories_comp_buckets" */
  eeo_labor_categories_comp_buckets_stream: Array<Eeo_Labor_Categories_Comp_Buckets>;
  /** fetch data from the table: "eeo_pivot_data_by_comp_bucket" */
  eeo_pivot_data_by_comp_bucket: Array<Eeo_Pivot_Data_By_Comp_Bucket>;
  /** fetch aggregated fields from the table: "eeo_pivot_data_by_comp_bucket" */
  eeo_pivot_data_by_comp_bucket_aggregate: Eeo_Pivot_Data_By_Comp_Bucket_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_pivot_data_by_comp_bucket" */
  eeo_pivot_data_by_comp_bucket_stream: Array<Eeo_Pivot_Data_By_Comp_Bucket>;
  /** fetch data from the table: "eeo_status_by_identity" */
  eeo_status_by_identity: Array<Eeo_Status_By_Identity>;
  /** fetch aggregated fields from the table: "eeo_status_by_identity" */
  eeo_status_by_identity_aggregate: Eeo_Status_By_Identity_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_status_by_identity" */
  eeo_status_by_identity_stream: Array<Eeo_Status_By_Identity>;
  /** fetch data from the table: "eeo_status_by_identity_with_compensation_and_bucket" */
  eeo_status_by_identity_with_compensation_and_bucket: Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket>;
  /** fetch aggregated fields from the table: "eeo_status_by_identity_with_compensation_and_bucket" */
  eeo_status_by_identity_with_compensation_and_bucket_aggregate: Eeo_Status_By_Identity_With_Compensation_And_Bucket_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_status_by_identity_with_compensation_and_bucket" */
  eeo_status_by_identity_with_compensation_and_bucket_stream: Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket>;
  /** fetch data from the table: "eeo_summary_by_gender" */
  eeo_summary_by_gender: Array<Eeo_Summary_By_Gender>;
  /** fetch aggregated fields from the table: "eeo_summary_by_gender" */
  eeo_summary_by_gender_aggregate: Eeo_Summary_By_Gender_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_summary_by_gender" */
  eeo_summary_by_gender_stream: Array<Eeo_Summary_By_Gender>;
  /** fetch data from the table: "eeo_summary_by_has_disability" */
  eeo_summary_by_has_disability: Array<Eeo_Summary_By_Has_Disability>;
  /** fetch aggregated fields from the table: "eeo_summary_by_has_disability" */
  eeo_summary_by_has_disability_aggregate: Eeo_Summary_By_Has_Disability_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_summary_by_has_disability" */
  eeo_summary_by_has_disability_stream: Array<Eeo_Summary_By_Has_Disability>;
  /** fetch data from the table: "eeo_summary_by_race" */
  eeo_summary_by_race: Array<Eeo_Summary_By_Race>;
  /** fetch aggregated fields from the table: "eeo_summary_by_race" */
  eeo_summary_by_race_aggregate: Eeo_Summary_By_Race_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_summary_by_race" */
  eeo_summary_by_race_stream: Array<Eeo_Summary_By_Race>;
  /** fetch data from the table: "eeo_summary_by_veteran_status" */
  eeo_summary_by_veteran_status: Array<Eeo_Summary_By_Veteran_Status>;
  /** fetch aggregated fields from the table: "eeo_summary_by_veteran_status" */
  eeo_summary_by_veteran_status_aggregate: Eeo_Summary_By_Veteran_Status_Aggregate;
  /** fetch data from the table in a streaming manner: "eeo_summary_by_veteran_status" */
  eeo_summary_by_veteran_status_stream: Array<Eeo_Summary_By_Veteran_Status>;
  /** fetch data from the table: "email_events" */
  email_events: Array<Email_Events>;
  /** fetch aggregated fields from the table: "email_events" */
  email_events_aggregate: Email_Events_Aggregate;
  /** fetch data from the table in a streaming manner: "email_events" */
  email_events_stream: Array<Email_Events>;
  /** fetch data from the table: "filled_jobs_report" */
  filled_jobs_report: Array<Filled_Jobs_Report>;
  /** fetch aggregated fields from the table: "filled_jobs_report" */
  filled_jobs_report_aggregate: Filled_Jobs_Report_Aggregate;
  /** fetch data from the table in a streaming manner: "filled_jobs_report" */
  filled_jobs_report_stream: Array<Filled_Jobs_Report>;
  /** fetch data from the table: "filled_jobs_subreport" */
  filled_jobs_subreport: Array<Filled_Jobs_Subreport>;
  /** fetch aggregated fields from the table: "filled_jobs_subreport" */
  filled_jobs_subreport_aggregate: Filled_Jobs_Subreport_Aggregate;
  /** fetch data from the table in a streaming manner: "filled_jobs_subreport" */
  filled_jobs_subreport_stream: Array<Filled_Jobs_Subreport>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geography_columns_stream: Array<Geography_Columns>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometry_columns_stream: Array<Geometry_Columns>;
  /** execute function "global_search_function_contracts" which returns "Contracts" */
  global_search_function_contracts: Array<Contracts>;
  /** execute function "global_search_function_contracts" and query aggregates on result of table type "Contracts" */
  global_search_function_contracts_aggregate: Contracts_Aggregate;
  /** execute function "global_search_function_identities" which returns "Identities" */
  global_search_function_identities: Array<Identities>;
  /** execute function "global_search_function_identities" and query aggregates on result of table type "Identities" */
  global_search_function_identities_aggregate: Identities_Aggregate;
  /** execute function "global_search_function_jobs" which returns "Jobs" */
  global_search_function_jobs: Array<Jobs>;
  /** execute function "global_search_function_jobs" and query aggregates on result of table type "Jobs" */
  global_search_function_jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "identities_for_external_reviews" */
  identities_for_external_reviews: Array<Identities_For_External_Reviews>;
  /** fetch aggregated fields from the table: "identities_for_external_reviews" */
  identities_for_external_reviews_aggregate: Identities_For_External_Reviews_Aggregate;
  /** fetch data from the table in a streaming manner: "identities_for_external_reviews" */
  identities_for_external_reviews_stream: Array<Identities_For_External_Reviews>;
  /** fetch data from the table: "identity_roles_arr" */
  identity_roles_arr: Array<Identity_Roles_Arr>;
  /** fetch aggregated fields from the table: "identity_roles_arr" */
  identity_roles_arr_aggregate: Identity_Roles_Arr_Aggregate;
  /** fetch data from the table in a streaming manner: "identity_roles_arr" */
  identity_roles_arr_stream: Array<Identity_Roles_Arr>;
  /** fetch data from the table: "identity_roles_joined" */
  identity_roles_joined: Array<Identity_Roles_Joined>;
  /** fetch aggregated fields from the table: "identity_roles_joined" */
  identity_roles_joined_aggregate: Identity_Roles_Joined_Aggregate;
  /** fetch data from the table in a streaming manner: "identity_roles_joined" */
  identity_roles_joined_stream: Array<Identity_Roles_Joined>;
  /** fetch data from the table: "identity_roles_stat" */
  identity_roles_stat: Array<Identity_Roles_Stat>;
  /** fetch aggregated fields from the table: "identity_roles_stat" */
  identity_roles_stat_aggregate: Identity_Roles_Stat_Aggregate;
  /** fetch data from the table in a streaming manner: "identity_roles_stat" */
  identity_roles_stat_stream: Array<Identity_Roles_Stat>;
  /** fetch data from the table: "identity_roles_view" */
  identity_roles_view: Array<Identity_Roles_View>;
  /** fetch aggregated fields from the table: "identity_roles_view" */
  identity_roles_view_aggregate: Identity_Roles_View_Aggregate;
  /** fetch data from the table in a streaming manner: "identity_roles_view" */
  identity_roles_view_stream: Array<Identity_Roles_View>;
  /** fetch data from the table: "internal_users" */
  internal_users: Array<Internal_Users>;
  /** fetch aggregated fields from the table: "internal_users" */
  internal_users_aggregate: Internal_Users_Aggregate;
  /** fetch data from the table in a streaming manner: "internal_users" */
  internal_users_stream: Array<Internal_Users>;
  /** fetch data from the table: "job_contracts_group_id" */
  job_contracts_group_id: Array<Job_Contracts_Group_Id>;
  /** fetch aggregated fields from the table: "job_contracts_group_id" */
  job_contracts_group_id_aggregate: Job_Contracts_Group_Id_Aggregate;
  /** fetch data from the table in a streaming manner: "job_contracts_group_id" */
  job_contracts_group_id_stream: Array<Job_Contracts_Group_Id>;
  /** fetch data from the table: "jobboardsnippetscounts" */
  jobboardsnippetscounts: Array<Jobboardsnippetscounts>;
  /** fetch aggregated fields from the table: "jobboardsnippetscounts" */
  jobboardsnippetscounts_aggregate: Jobboardsnippetscounts_Aggregate;
  /** fetch data from the table in a streaming manner: "jobboardsnippetscounts" */
  jobboardsnippetscounts_stream: Array<Jobboardsnippetscounts>;
  /** fetch data from the table: "jobs_available_positions" */
  jobs_available_positions: Array<Jobs_Available_Positions>;
  /** fetch aggregated fields from the table: "jobs_available_positions" */
  jobs_available_positions_aggregate: Jobs_Available_Positions_Aggregate;
  /** fetch data from the table in a streaming manner: "jobs_available_positions" */
  jobs_available_positions_stream: Array<Jobs_Available_Positions>;
  /** fetch data from the table: "matches" */
  matches: Array<Matches>;
  /** fetch aggregated fields from the table: "matches" */
  matches_aggregate: Matches_Aggregate;
  /** fetch data from the table: "matches_from_applications" */
  matches_from_applications: Array<Matches_From_Applications>;
  /** fetch aggregated fields from the table: "matches_from_applications" */
  matches_from_applications_aggregate: Matches_From_Applications_Aggregate;
  /** fetch data from the table in a streaming manner: "matches_from_applications" */
  matches_from_applications_stream: Array<Matches_From_Applications>;
  /** fetch data from the table: "matches_from_board" */
  matches_from_board: Array<Matches_From_Board>;
  /** fetch aggregated fields from the table: "matches_from_board" */
  matches_from_board_aggregate: Matches_From_Board_Aggregate;
  /** fetch data from the table in a streaming manner: "matches_from_board" */
  matches_from_board_stream: Array<Matches_From_Board>;
  /** fetch data from the table: "matches_from_identities" */
  matches_from_identities: Array<Matches_From_Identities>;
  /** fetch aggregated fields from the table: "matches_from_identities" */
  matches_from_identities_aggregate: Matches_From_Identities_Aggregate;
  /** fetch data from the table in a streaming manner: "matches_from_identities" */
  matches_from_identities_stream: Array<Matches_From_Identities>;
  /** fetch data from the table: "matches_from_tasks" */
  matches_from_tasks: Array<Matches_From_Tasks>;
  /** fetch aggregated fields from the table: "matches_from_tasks" */
  matches_from_tasks_aggregate: Matches_From_Tasks_Aggregate;
  /** fetch data from the table in a streaming manner: "matches_from_tasks" */
  matches_from_tasks_stream: Array<Matches_From_Tasks>;
  /** fetch data from the table: "matches_grouped" */
  matches_grouped: Array<Matches_Grouped>;
  /** fetch aggregated fields from the table: "matches_grouped" */
  matches_grouped_aggregate: Matches_Grouped_Aggregate;
  /** fetch data from the table in a streaming manner: "matches_grouped" */
  matches_grouped_stream: Array<Matches_Grouped>;
  /** fetch data from the table in a streaming manner: "matches" */
  matches_stream: Array<Matches>;
  /** fetch data from the table: "matches_union" */
  matches_union: Array<Matches_Union>;
  /** fetch aggregated fields from the table: "matches_union" */
  matches_union_aggregate: Matches_Union_Aggregate;
  /** fetch data from the table in a streaming manner: "matches_union" */
  matches_union_stream: Array<Matches_Union>;
  /** fetch data from the table: "matches_without_author_with_int_uuid" */
  matches_without_author_with_int_uuid: Array<Matches_Without_Author_With_Int_Uuid>;
  /** fetch aggregated fields from the table: "matches_without_author_with_int_uuid" */
  matches_without_author_with_int_uuid_aggregate: Matches_Without_Author_With_Int_Uuid_Aggregate;
  /** fetch data from the table in a streaming manner: "matches_without_author_with_int_uuid" */
  matches_without_author_with_int_uuid_stream: Array<Matches_Without_Author_With_Int_Uuid>;
  /** fetch data from the table: "nylas_events" */
  nylas_events: Array<Nylas_Events>;
  /** fetch aggregated fields from the table: "nylas_events" */
  nylas_events_aggregate: Nylas_Events_Aggregate;
  /** fetch data from the table: "nylas_events" using primary key columns */
  nylas_events_by_pk?: Maybe<Nylas_Events>;
  /** fetch data from the table in a streaming manner: "nylas_events" */
  nylas_events_stream: Array<Nylas_Events>;
  /** fetch data from the table: "ofccp_compliance" */
  ofccp_compliance: Array<Ofccp_Compliance>;
  /** fetch aggregated fields from the table: "ofccp_compliance" */
  ofccp_compliance_aggregate: Ofccp_Compliance_Aggregate;
  /** fetch data from the table in a streaming manner: "ofccp_compliance" */
  ofccp_compliance_stream: Array<Ofccp_Compliance>;
  /** fetch data from the table: "rms" */
  rms: Array<Rms>;
  /** fetch aggregated fields from the table: "rms" */
  rms_aggregate: Rms_Aggregate;
  /** fetch data from the table: "rms_for_analytics_ui" */
  rms_for_analytics_ui: Array<Rms_For_Analytics_Ui>;
  /** fetch aggregated fields from the table: "rms_for_analytics_ui" */
  rms_for_analytics_ui_aggregate: Rms_For_Analytics_Ui_Aggregate;
  /** fetch data from the table in a streaming manner: "rms_for_analytics_ui" */
  rms_for_analytics_ui_stream: Array<Rms_For_Analytics_Ui>;
  /** fetch data from the table in a streaming manner: "rms" */
  rms_stream: Array<Rms>;
  /** fetch data from the table: "search_spec_results" */
  search_spec_results: Array<Search_Spec_Results>;
  /** fetch aggregated fields from the table: "search_spec_results" */
  search_spec_results_aggregate: Search_Spec_Results_Aggregate;
  /** fetch data from the table: "search_spec_results" using primary key columns */
  search_spec_results_by_pk?: Maybe<Search_Spec_Results>;
  /** fetch data from the table in a streaming manner: "search_spec_results" */
  search_spec_results_stream: Array<Search_Spec_Results>;
  /** fetch data from the table: "search_specs" */
  search_specs: Array<Search_Specs>;
  /** fetch aggregated fields from the table: "search_specs" */
  search_specs_aggregate: Search_Specs_Aggregate;
  /** fetch data from the table: "search_specs" using primary key columns */
  search_specs_by_pk?: Maybe<Search_Specs>;
  /** fetch data from the table in a streaming manner: "search_specs" */
  search_specs_stream: Array<Search_Specs>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatial_ref_sys_stream: Array<Spatial_Ref_Sys>;
  /** fetch data from the table: "staffing_status" */
  staffing_status: Array<Staffing_Status>;
  /** fetch aggregated fields from the table: "staffing_status" */
  staffing_status_aggregate: Staffing_Status_Aggregate;
  /** fetch data from the table in a streaming manner: "staffing_status" */
  staffing_status_stream: Array<Staffing_Status>;
  /** fetch data from the table: "stages_conversion_mview" */
  stages_conversion_mview: Array<Stages_Conversion_Mview>;
  /** fetch aggregated fields from the table: "stages_conversion_mview" */
  stages_conversion_mview_aggregate: Stages_Conversion_Mview_Aggregate;
  /** fetch data from the table in a streaming manner: "stages_conversion_mview" */
  stages_conversion_mview_stream: Array<Stages_Conversion_Mview>;
  /** fetch data from the table: "text_messages" */
  text_messages: Array<Text_Messages>;
  /** fetch aggregated fields from the table: "text_messages" */
  text_messages_aggregate: Text_Messages_Aggregate;
  /** fetch data from the table: "text_messages" using primary key columns */
  text_messages_by_pk?: Maybe<Text_Messages>;
  /** fetch data from the table in a streaming manner: "text_messages" */
  text_messages_stream: Array<Text_Messages>;
  /** fetch data from the table: "util.deps_saved_ddl" */
  util_deps_saved_ddl: Array<Util_Deps_Saved_Ddl>;
  /** fetch aggregated fields from the table: "util.deps_saved_ddl" */
  util_deps_saved_ddl_aggregate: Util_Deps_Saved_Ddl_Aggregate;
  /** fetch data from the table: "util.deps_saved_ddl" using primary key columns */
  util_deps_saved_ddl_by_pk?: Maybe<Util_Deps_Saved_Ddl>;
  /** fetch data from the table in a streaming manner: "util.deps_saved_ddl" */
  util_deps_saved_ddl_stream: Array<Util_Deps_Saved_Ddl>;
  /** execute function "verify_api_token" which returns "APITokens" */
  verify_api_token: Array<ApiTokens>;
  /** execute function "verify_api_token" and query aggregates on result of table type "APITokens" */
  verify_api_token_aggregate: ApiTokens_Aggregate;
  /** fetch data from the table: "workflow_contracts" */
  workflow_contracts: Array<Workflow_Contracts>;
  /** fetch aggregated fields from the table: "workflow_contracts" */
  workflow_contracts_aggregate: Workflow_Contracts_Aggregate;
  /** fetch data from the table: "workflow_contracts" using primary key columns */
  workflow_contracts_by_pk?: Maybe<Workflow_Contracts>;
  /** fetch data from the table in a streaming manner: "workflow_contracts" */
  workflow_contracts_stream: Array<Workflow_Contracts>;
  /** An array relationship */
  workflow_departments: Array<Workflow_Departments>;
  /** An aggregate relationship */
  workflow_departments_aggregate: Workflow_Departments_Aggregate;
  /** fetch data from the table: "workflow_departments" using primary key columns */
  workflow_departments_by_pk?: Maybe<Workflow_Departments>;
  /** fetch data from the table in a streaming manner: "workflow_departments" */
  workflow_departments_stream: Array<Workflow_Departments>;
};


export type Subscription_RootApiTokensArgs = {
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Subscription_RootApiTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Subscription_RootApiTokens_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Subscription_RootApiTokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ApiTokens_Stream_Cursor_Input>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Subscription_RootAccessIntentsArgs = {
  distinct_on?: InputMaybe<Array<AccessIntents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessIntents_Order_By>>;
  where?: InputMaybe<AccessIntents_Bool_Exp>;
};


export type Subscription_RootAccessIntents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccessIntents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessIntents_Order_By>>;
  where?: InputMaybe<AccessIntents_Bool_Exp>;
};


export type Subscription_RootAccessIntents_By_PkArgs = {
  intent: Scalars['String'];
};


export type Subscription_RootAccessIntents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AccessIntents_Stream_Cursor_Input>>;
  where?: InputMaybe<AccessIntents_Bool_Exp>;
};


export type Subscription_RootAccessTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessTypeEnum_Order_By>>;
  where?: InputMaybe<AccessTypeEnum_Bool_Exp>;
};


export type Subscription_RootAccessTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccessTypeEnum_Order_By>>;
  where?: InputMaybe<AccessTypeEnum_Bool_Exp>;
};


export type Subscription_RootAccessTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAccessTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AccessTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<AccessTypeEnum_Bool_Exp>;
};


export type Subscription_RootActiveEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<ActiveEntitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActiveEntitlements_Order_By>>;
  where?: InputMaybe<ActiveEntitlements_Bool_Exp>;
};


export type Subscription_RootActiveEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActiveEntitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActiveEntitlements_Order_By>>;
  where?: InputMaybe<ActiveEntitlements_Bool_Exp>;
};


export type Subscription_RootActiveEntitlements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActiveEntitlements_Stream_Cursor_Input>>;
  where?: InputMaybe<ActiveEntitlements_Bool_Exp>;
};


export type Subscription_RootActivityLogTypesArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLogTypes_Order_By>>;
  where?: InputMaybe<ActivityLogTypes_Bool_Exp>;
};


export type Subscription_RootActivityLogTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLogTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLogTypes_Order_By>>;
  where?: InputMaybe<ActivityLogTypes_Bool_Exp>;
};


export type Subscription_RootActivityLogTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootActivityLogTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActivityLogTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<ActivityLogTypes_Bool_Exp>;
};


export type Subscription_RootActivityTemplatesArgs = {
  distinct_on?: InputMaybe<Array<ActivityTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityTemplates_Order_By>>;
  where?: InputMaybe<ActivityTemplates_Bool_Exp>;
};


export type Subscription_RootActivityTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityTemplates_Order_By>>;
  where?: InputMaybe<ActivityTemplates_Bool_Exp>;
};


export type Subscription_RootActivityTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootActivityTemplates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActivityTemplates_Stream_Cursor_Input>>;
  where?: InputMaybe<ActivityTemplates_Bool_Exp>;
};


export type Subscription_RootAoIdentifierTypesArgs = {
  distinct_on?: InputMaybe<Array<AoIdentifierTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AoIdentifierTypes_Order_By>>;
  where?: InputMaybe<AoIdentifierTypes_Bool_Exp>;
};


export type Subscription_RootAoIdentifierTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AoIdentifierTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AoIdentifierTypes_Order_By>>;
  where?: InputMaybe<AoIdentifierTypes_Bool_Exp>;
};


export type Subscription_RootAoIdentifierTypes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAoIdentifierTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AoIdentifierTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<AoIdentifierTypes_Bool_Exp>;
};


export type Subscription_RootApplicationStatusArgs = {
  distinct_on?: InputMaybe<Array<ApplicationStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationStatus_Order_By>>;
  where?: InputMaybe<ApplicationStatus_Bool_Exp>;
};


export type Subscription_RootApplicationStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ApplicationStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationStatus_Order_By>>;
  where?: InputMaybe<ApplicationStatus_Bool_Exp>;
};


export type Subscription_RootApplicationStatus_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootApplicationStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ApplicationStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<ApplicationStatus_Bool_Exp>;
};


export type Subscription_RootApplicationTypeArgs = {
  distinct_on?: InputMaybe<Array<ApplicationType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationType_Order_By>>;
  where?: InputMaybe<ApplicationType_Bool_Exp>;
};


export type Subscription_RootApplicationType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ApplicationType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApplicationType_Order_By>>;
  where?: InputMaybe<ApplicationType_Bool_Exp>;
};


export type Subscription_RootApplicationType_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootApplicationType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ApplicationType_Stream_Cursor_Input>>;
  where?: InputMaybe<ApplicationType_Bool_Exp>;
};


export type Subscription_RootApplicationsArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


export type Subscription_RootApplications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applications_Order_By>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


export type Subscription_RootApplications_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootApplications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Applications_Stream_Cursor_Input>>;
  where?: InputMaybe<Applications_Bool_Exp>;
};


export type Subscription_RootAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Subscription_RootAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Subscription_RootAssignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAssignments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assignments_Stream_Cursor_Input>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Subscription_RootAsyncOperationsArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperations_Order_By>>;
  where?: InputMaybe<AsyncOperations_Bool_Exp>;
};


export type Subscription_RootAsyncOperationsStatesArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperationsStates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperationsStates_Order_By>>;
  where?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
};


export type Subscription_RootAsyncOperationsStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperationsStates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperationsStates_Order_By>>;
  where?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
};


export type Subscription_RootAsyncOperationsStates_By_PkArgs = {
  state: Scalars['String'];
};


export type Subscription_RootAsyncOperationsStates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AsyncOperationsStates_Stream_Cursor_Input>>;
  where?: InputMaybe<AsyncOperationsStates_Bool_Exp>;
};


export type Subscription_RootAsyncOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AsyncOperations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AsyncOperations_Order_By>>;
  where?: InputMaybe<AsyncOperations_Bool_Exp>;
};


export type Subscription_RootAsyncOperations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAsyncOperations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AsyncOperations_Stream_Cursor_Input>>;
  where?: InputMaybe<AsyncOperations_Bool_Exp>;
};


export type Subscription_RootAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Subscription_RootAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Subscription_RootAttachments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAttachments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Attachments_Stream_Cursor_Input>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Subscription_RootAuditCreatesArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


export type Subscription_RootAuditCreates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditCreates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditCreates_Order_By>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


export type Subscription_RootAuditCreates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAuditCreates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AuditCreates_Stream_Cursor_Input>>;
  where?: InputMaybe<AuditCreates_Bool_Exp>;
};


export type Subscription_RootAuditStagesArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


export type Subscription_RootAuditStagesWithWorkflowBeginAndStartedDatesArgs = {
  distinct_on?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Order_By>>;
  where?: InputMaybe<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>;
};


export type Subscription_RootAuditStagesWithWorkflowBeginAndStartedDates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStagesWithWorkflowBeginAndStartedDates_Order_By>>;
  where?: InputMaybe<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>;
};


export type Subscription_RootAuditStagesWithWorkflowBeginAndStartedDates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AuditStagesWithWorkflowBeginAndStartedDates_Stream_Cursor_Input>>;
  where?: InputMaybe<AuditStagesWithWorkflowBeginAndStartedDates_Bool_Exp>;
};


export type Subscription_RootAuditStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuditStages_Order_By>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


export type Subscription_RootAuditStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAuditStages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AuditStages_Stream_Cursor_Input>>;
  where?: InputMaybe<AuditStages_Bool_Exp>;
};


export type Subscription_RootAuthAuditsArgs = {
  distinct_on?: InputMaybe<Array<AuthAudits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthAudits_Order_By>>;
  where?: InputMaybe<AuthAudits_Bool_Exp>;
};


export type Subscription_RootAuthAudits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthAudits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthAudits_Order_By>>;
  where?: InputMaybe<AuthAudits_Bool_Exp>;
};


export type Subscription_RootAuthAudits_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuthAudits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AuthAudits_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthAudits_Bool_Exp>;
};


export type Subscription_RootBadgesArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBadges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBenefitArgs = {
  distinct_on?: InputMaybe<Array<Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Benefit_Order_By>>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};


export type Subscription_RootBenefitTypeIdentitySubTypesArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


export type Subscription_RootBenefitTypeIdentitySubTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypeIdentitySubTypes_Order_By>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


export type Subscription_RootBenefitTypeIdentitySubTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBenefitTypeIdentitySubTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BenefitTypeIdentitySubTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<BenefitTypeIdentitySubTypes_Bool_Exp>;
};


export type Subscription_RootBenefitTypesArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypes_Order_By>>;
  where?: InputMaybe<BenefitTypes_Bool_Exp>;
};


export type Subscription_RootBenefitTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BenefitTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BenefitTypes_Order_By>>;
  where?: InputMaybe<BenefitTypes_Bool_Exp>;
};


export type Subscription_RootBenefitTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBenefitTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BenefitTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<BenefitTypes_Bool_Exp>;
};


export type Subscription_RootBenefit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Benefit_Order_By>>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};


export type Subscription_RootBenefit_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBenefit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Benefit_Stream_Cursor_Input>>;
  where?: InputMaybe<Benefit_Bool_Exp>;
};


export type Subscription_RootBoardFiltersArgs = {
  distinct_on?: InputMaybe<Array<BoardFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardFilters_Order_By>>;
  where?: InputMaybe<BoardFilters_Bool_Exp>;
};


export type Subscription_RootBoardFilters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardFilters_Order_By>>;
  where?: InputMaybe<BoardFilters_Bool_Exp>;
};


export type Subscription_RootBoardFilters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBoardFilters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BoardFilters_Stream_Cursor_Input>>;
  where?: InputMaybe<BoardFilters_Bool_Exp>;
};


export type Subscription_RootBoardSnippetsArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


export type Subscription_RootBoardSnippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BoardSnippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BoardSnippets_Order_By>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


export type Subscription_RootBoardSnippets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBoardSnippets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BoardSnippets_Stream_Cursor_Input>>;
  where?: InputMaybe<BoardSnippets_Bool_Exp>;
};


export type Subscription_RootBullhornSyncStatusArgs = {
  distinct_on?: InputMaybe<Array<BullhornSyncStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BullhornSyncStatus_Order_By>>;
  where?: InputMaybe<BullhornSyncStatus_Bool_Exp>;
};


export type Subscription_RootBullhornSyncStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BullhornSyncStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BullhornSyncStatus_Order_By>>;
  where?: InputMaybe<BullhornSyncStatus_Bool_Exp>;
};


export type Subscription_RootBullhornSyncStatus_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBullhornSyncStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BullhornSyncStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<BullhornSyncStatus_Bool_Exp>;
};


export type Subscription_RootCalcEquationResultsArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


export type Subscription_RootCalcEquationResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalcEquationResults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcEquationResults_Order_By>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


export type Subscription_RootCalcEquationResults_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCalcEquationResults_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CalcEquationResults_Stream_Cursor_Input>>;
  where?: InputMaybe<CalcEquationResults_Bool_Exp>;
};


export type Subscription_RootCalcFormValuesArgs = {
  distinct_on?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcFormValues_Order_By>>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};


export type Subscription_RootCalcFormValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalcFormValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalcFormValues_Order_By>>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};


export type Subscription_RootCalcFormValues_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCalcFormValues_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CalcFormValues_Stream_Cursor_Input>>;
  where?: InputMaybe<CalcFormValues_Bool_Exp>;
};


export type Subscription_RootCalcsArgs = {
  distinct_on?: InputMaybe<Array<Calcs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calcs_Order_By>>;
  where?: InputMaybe<Calcs_Bool_Exp>;
};


export type Subscription_RootCalcs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calcs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calcs_Order_By>>;
  where?: InputMaybe<Calcs_Bool_Exp>;
};


export type Subscription_RootCalcs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCalcs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calcs_Stream_Cursor_Input>>;
  where?: InputMaybe<Calcs_Bool_Exp>;
};


export type Subscription_RootCalculationTemplateVersionsArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplateVersions_Order_By>>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};


export type Subscription_RootCalculationTemplateVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplateVersions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplateVersions_Order_By>>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};


export type Subscription_RootCalculationTemplateVersions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCalculationTemplateVersions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CalculationTemplateVersions_Stream_Cursor_Input>>;
  where?: InputMaybe<CalculationTemplateVersions_Bool_Exp>;
};


export type Subscription_RootCalculationTemplatesArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplates_Order_By>>;
  where?: InputMaybe<CalculationTemplates_Bool_Exp>;
};


export type Subscription_RootCalculationTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationTemplates_Order_By>>;
  where?: InputMaybe<CalculationTemplates_Bool_Exp>;
};


export type Subscription_RootCalculationTemplates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCalculationTemplates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CalculationTemplates_Stream_Cursor_Input>>;
  where?: InputMaybe<CalculationTemplates_Bool_Exp>;
};


export type Subscription_RootCalculationVersionFormInputsArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


export type Subscription_RootCalculationVersionFormInputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CalculationVersionFormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CalculationVersionFormInputs_Order_By>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


export type Subscription_RootCalculationVersionFormInputs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCalculationVersionFormInputs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CalculationVersionFormInputs_Stream_Cursor_Input>>;
  where?: InputMaybe<CalculationVersionFormInputs_Bool_Exp>;
};


export type Subscription_RootCalculationsArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


export type Subscription_RootCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calculations_Order_By>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


export type Subscription_RootCalculations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCalculations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calculations_Stream_Cursor_Input>>;
  where?: InputMaybe<Calculations_Bool_Exp>;
};


export type Subscription_RootCandidateSourcesArgs = {
  distinct_on?: InputMaybe<Array<CandidateSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateSources_Order_By>>;
  where?: InputMaybe<CandidateSources_Bool_Exp>;
};


export type Subscription_RootCandidateSources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CandidateSources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateSources_Order_By>>;
  where?: InputMaybe<CandidateSources_Bool_Exp>;
};


export type Subscription_RootCandidateSources_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCandidateSources_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CandidateSources_Stream_Cursor_Input>>;
  where?: InputMaybe<CandidateSources_Bool_Exp>;
};


export type Subscription_RootCandidateStatusesArgs = {
  distinct_on?: InputMaybe<Array<CandidateStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateStatuses_Order_By>>;
  where?: InputMaybe<CandidateStatuses_Bool_Exp>;
};


export type Subscription_RootCandidateStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CandidateStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CandidateStatuses_Order_By>>;
  where?: InputMaybe<CandidateStatuses_Bool_Exp>;
};


export type Subscription_RootCandidateStatuses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCandidateStatuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CandidateStatuses_Stream_Cursor_Input>>;
  where?: InputMaybe<CandidateStatuses_Bool_Exp>;
};


export type Subscription_RootCaptureNodesArgs = {
  distinct_on?: InputMaybe<Array<CaptureNodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CaptureNodes_Order_By>>;
  where?: InputMaybe<CaptureNodes_Bool_Exp>;
};


export type Subscription_RootCaptureNodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CaptureNodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CaptureNodes_Order_By>>;
  where?: InputMaybe<CaptureNodes_Bool_Exp>;
};


export type Subscription_RootCaptureNodes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCaptureNodes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CaptureNodes_Stream_Cursor_Input>>;
  where?: InputMaybe<CaptureNodes_Bool_Exp>;
};


export type Subscription_RootClientSideExternalSystemIntegrationPluginRecordArgs = {
  distinct_on?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Order_By>>;
  where?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>;
};


export type Subscription_RootClientSideExternalSystemIntegrationPluginRecord_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientSideExternalSystemIntegrationPluginRecord_Order_By>>;
  where?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>;
};


export type Subscription_RootClientSideExternalSystemIntegrationPluginRecord_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClientSideExternalSystemIntegrationPluginRecord_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientSideExternalSystemIntegrationPluginRecord_Bool_Exp>;
};


export type Subscription_RootCognitoUserDataArgs = {
  distinct_on?: InputMaybe<Array<CognitoUserData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CognitoUserData_Order_By>>;
  where?: InputMaybe<CognitoUserData_Bool_Exp>;
};


export type Subscription_RootCognitoUserData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CognitoUserData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CognitoUserData_Order_By>>;
  where?: InputMaybe<CognitoUserData_Bool_Exp>;
};


export type Subscription_RootCognitoUserData_By_PkArgs = {
  cog_id: Scalars['String'];
};


export type Subscription_RootCognitoUserData_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CognitoUserData_Stream_Cursor_Input>>;
  where?: InputMaybe<CognitoUserData_Bool_Exp>;
};


export type Subscription_RootCommentTypesArgs = {
  distinct_on?: InputMaybe<Array<CommentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommentTypes_Order_By>>;
  where?: InputMaybe<CommentTypes_Bool_Exp>;
};


export type Subscription_RootCommentTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommentTypes_Order_By>>;
  where?: InputMaybe<CommentTypes_Bool_Exp>;
};


export type Subscription_RootCommentTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCommentTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CommentTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<CommentTypes_Bool_Exp>;
};


export type Subscription_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootComments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Subscription_RootCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Subscription_RootCompanies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompanies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Companies_Stream_Cursor_Input>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Subscription_RootCompanyEventsArgs = {
  distinct_on?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyEvents_Order_By>>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};


export type Subscription_RootCompanyEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyEvents_Order_By>>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};


export type Subscription_RootCompanyEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompanyEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompanyEvents_Stream_Cursor_Input>>;
  where?: InputMaybe<CompanyEvents_Bool_Exp>;
};


export type Subscription_RootCompanyStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<CompanyStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyStatusEnum_Order_By>>;
  where?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
};


export type Subscription_RootCompanyStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyStatusEnum_Order_By>>;
  where?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
};


export type Subscription_RootCompanyStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCompanyStatusEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompanyStatusEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<CompanyStatusEnum_Bool_Exp>;
};


export type Subscription_RootCompensationsArgs = {
  distinct_on?: InputMaybe<Array<Compensations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compensations_Order_By>>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};


export type Subscription_RootCompensations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compensations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compensations_Order_By>>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};


export type Subscription_RootCompensations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompensations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Compensations_Stream_Cursor_Input>>;
  where?: InputMaybe<Compensations_Bool_Exp>;
};


export type Subscription_RootContactTemplateVariablesEnumArgs = {
  distinct_on?: InputMaybe<Array<ContactTemplateVariablesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactTemplateVariablesEnum_Order_By>>;
  where?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
};


export type Subscription_RootContactTemplateVariablesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactTemplateVariablesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactTemplateVariablesEnum_Order_By>>;
  where?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
};


export type Subscription_RootContactTemplateVariablesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootContactTemplateVariablesEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContactTemplateVariablesEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ContactTemplateVariablesEnum_Bool_Exp>;
};


export type Subscription_RootContractCommentsArgs = {
  distinct_on?: InputMaybe<Array<ContractComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractComments_Order_By>>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};


export type Subscription_RootContractComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractComments_Order_By>>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};


export type Subscription_RootContractComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContractComments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractComments_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractComments_Bool_Exp>;
};


export type Subscription_RootContractFilesArgs = {
  distinct_on?: InputMaybe<Array<ContractFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFiles_Order_By>>;
  where?: InputMaybe<ContractFiles_Bool_Exp>;
};


export type Subscription_RootContractFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractFiles_Order_By>>;
  where?: InputMaybe<ContractFiles_Bool_Exp>;
};


export type Subscription_RootContractFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContractFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractFiles_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractFiles_Bool_Exp>;
};


export type Subscription_RootContractStagesArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


export type Subscription_RootContractStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStages_Order_By>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


export type Subscription_RootContractStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContractStages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractStages_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractStages_Bool_Exp>;
};


export type Subscription_RootContractStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<ContractStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStatusEnum_Order_By>>;
  where?: InputMaybe<ContractStatusEnum_Bool_Exp>;
};


export type Subscription_RootContractStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractStatusEnum_Order_By>>;
  where?: InputMaybe<ContractStatusEnum_Bool_Exp>;
};


export type Subscription_RootContractStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootContractStatusEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractStatusEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractStatusEnum_Bool_Exp>;
};


export type Subscription_RootContractsArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Subscription_RootContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Subscription_RootContracts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContracts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contracts_Stream_Cursor_Input>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Subscription_RootCurrentStagesArgs = {
  distinct_on?: InputMaybe<Array<CurrentStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentStages_Order_By>>;
  where?: InputMaybe<CurrentStages_Bool_Exp>;
};


export type Subscription_RootCurrentStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CurrentStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CurrentStages_Order_By>>;
  where?: InputMaybe<CurrentStages_Bool_Exp>;
};


export type Subscription_RootCurrentStages_By_PkArgs = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
};


export type Subscription_RootCurrentStages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CurrentStages_Stream_Cursor_Input>>;
  where?: InputMaybe<CurrentStages_Bool_Exp>;
};


export type Subscription_RootDataHistoryArgs = {
  distinct_on?: InputMaybe<Array<DataHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataHistory_Order_By>>;
  where?: InputMaybe<DataHistory_Bool_Exp>;
};


export type Subscription_RootDataHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DataHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DataHistory_Order_By>>;
  where?: InputMaybe<DataHistory_Bool_Exp>;
};


export type Subscription_RootDataHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDataHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DataHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<DataHistory_Bool_Exp>;
};


export type Subscription_RootDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Departments_Order_By>>;
  where?: InputMaybe<Departments_Bool_Exp>;
};


export type Subscription_RootDepartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Departments_Order_By>>;
  where?: InputMaybe<Departments_Bool_Exp>;
};


export type Subscription_RootDepartments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDepartments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Departments_Stream_Cursor_Input>>;
  where?: InputMaybe<Departments_Bool_Exp>;
};


export type Subscription_RootDisabilityStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<DisabilityStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DisabilityStatusEnum_Order_By>>;
  where?: InputMaybe<DisabilityStatusEnum_Bool_Exp>;
};


export type Subscription_RootDisabilityStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DisabilityStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DisabilityStatusEnum_Order_By>>;
  where?: InputMaybe<DisabilityStatusEnum_Bool_Exp>;
};


export type Subscription_RootDisabilityStatusEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootDisabilityStatusEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DisabilityStatusEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<DisabilityStatusEnum_Bool_Exp>;
};


export type Subscription_RootEeoJobCategoriesEnumArgs = {
  distinct_on?: InputMaybe<Array<EeoJobCategoriesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EeoJobCategoriesEnum_Order_By>>;
  where?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
};


export type Subscription_RootEeoJobCategoriesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EeoJobCategoriesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EeoJobCategoriesEnum_Order_By>>;
  where?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
};


export type Subscription_RootEeoJobCategoriesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootEeoJobCategoriesEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EeoJobCategoriesEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<EeoJobCategoriesEnum_Bool_Exp>;
};


export type Subscription_RootEarnedBadgesArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


export type Subscription_RootEarnedBadges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EarnedBadges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EarnedBadges_Order_By>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


export type Subscription_RootEarnedBadges_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEarnedBadges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EarnedBadges_Stream_Cursor_Input>>;
  where?: InputMaybe<EarnedBadges_Bool_Exp>;
};


export type Subscription_RootEmailAddressesArgs = {
  distinct_on?: InputMaybe<Array<EmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailAddresses_Order_By>>;
  where?: InputMaybe<EmailAddresses_Bool_Exp>;
};


export type Subscription_RootEmailAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailAddresses_Order_By>>;
  where?: InputMaybe<EmailAddresses_Bool_Exp>;
};


export type Subscription_RootEmailAddresses_By_PkArgs = {
  normalized_email: Scalars['String'];
};


export type Subscription_RootEmailAddresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailAddresses_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailAddresses_Bool_Exp>;
};


export type Subscription_RootEmailEventsArgs = {
  distinct_on?: InputMaybe<Array<EmailEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailEvents_Order_By>>;
  where?: InputMaybe<EmailEvents_Bool_Exp>;
};


export type Subscription_RootEmailEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailEvents_Order_By>>;
  where?: InputMaybe<EmailEvents_Bool_Exp>;
};


export type Subscription_RootEmailEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmailEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailEvents_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailEvents_Bool_Exp>;
};


export type Subscription_RootEmailRecordsArgs = {
  distinct_on?: InputMaybe<Array<EmailRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailRecords_Order_By>>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};


export type Subscription_RootEmailRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailRecords_Order_By>>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};


export type Subscription_RootEmailRecords_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmailRecords_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailRecords_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailRecords_Bool_Exp>;
};


export type Subscription_RootEmailSignaturesArgs = {
  distinct_on?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSignatures_Order_By>>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};


export type Subscription_RootEmailSignatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailSignatures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSignatures_Order_By>>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};


export type Subscription_RootEmailSignatures_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmailSignatures_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailSignatures_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailSignatures_Bool_Exp>;
};


export type Subscription_RootEmailSubFoldersArgs = {
  distinct_on?: InputMaybe<Array<EmailSubFolders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSubFolders_Order_By>>;
  where?: InputMaybe<EmailSubFolders_Bool_Exp>;
};


export type Subscription_RootEmailSubFolders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailSubFolders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailSubFolders_Order_By>>;
  where?: InputMaybe<EmailSubFolders_Bool_Exp>;
};


export type Subscription_RootEmailSubFolders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmailSubFolders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailSubFolders_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailSubFolders_Bool_Exp>;
};


export type Subscription_RootEmailTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<EmailTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypeEnum_Order_By>>;
  where?: InputMaybe<EmailTypeEnum_Bool_Exp>;
};


export type Subscription_RootEmailTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypeEnum_Order_By>>;
  where?: InputMaybe<EmailTypeEnum_Bool_Exp>;
};


export type Subscription_RootEmailTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEmailTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailTypeEnum_Bool_Exp>;
};


export type Subscription_RootEmailTypesArgs = {
  distinct_on?: InputMaybe<Array<EmailTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypes_Order_By>>;
  where?: InputMaybe<EmailTypes_Bool_Exp>;
};


export type Subscription_RootEmailTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTypes_Order_By>>;
  where?: InputMaybe<EmailTypes_Bool_Exp>;
};


export type Subscription_RootEmailTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmailTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailTypes_Bool_Exp>;
};


export type Subscription_RootEmailsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Subscription_RootEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_Order_By>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Subscription_RootEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Emails_Bool_Exp>;
};


export type Subscription_RootEmploymentHireTypeArgs = {
  distinct_on?: InputMaybe<Array<EmploymentHireType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentHireType_Order_By>>;
  where?: InputMaybe<EmploymentHireType_Bool_Exp>;
};


export type Subscription_RootEmploymentHireType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmploymentHireType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentHireType_Order_By>>;
  where?: InputMaybe<EmploymentHireType_Bool_Exp>;
};


export type Subscription_RootEmploymentHireType_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootEmploymentHireType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmploymentHireType_Stream_Cursor_Input>>;
  where?: InputMaybe<EmploymentHireType_Bool_Exp>;
};


export type Subscription_RootEmploymentTypesArgs = {
  distinct_on?: InputMaybe<Array<EmploymentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentTypes_Order_By>>;
  where?: InputMaybe<EmploymentTypes_Bool_Exp>;
};


export type Subscription_RootEmploymentTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmploymentTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmploymentTypes_Order_By>>;
  where?: InputMaybe<EmploymentTypes_Bool_Exp>;
};


export type Subscription_RootEmploymentTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEmploymentTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmploymentTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<EmploymentTypes_Bool_Exp>;
};


export type Subscription_RootEmploymentsArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


export type Subscription_RootEmployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employments_Order_By>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


export type Subscription_RootEmployments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmployments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employments_Stream_Cursor_Input>>;
  where?: InputMaybe<Employments_Bool_Exp>;
};


export type Subscription_RootEntitlementCategoriesArgs = {
  distinct_on?: InputMaybe<Array<EntitlementCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntitlementCategories_Order_By>>;
  where?: InputMaybe<EntitlementCategories_Bool_Exp>;
};


export type Subscription_RootEntitlementCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EntitlementCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EntitlementCategories_Order_By>>;
  where?: InputMaybe<EntitlementCategories_Bool_Exp>;
};


export type Subscription_RootEntitlementCategories_By_PkArgs = {
  category: Scalars['String'];
};


export type Subscription_RootEntitlementCategories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EntitlementCategories_Stream_Cursor_Input>>;
  where?: InputMaybe<EntitlementCategories_Bool_Exp>;
};


export type Subscription_RootEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entitlements_Order_By>>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};


export type Subscription_RootEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entitlements_Order_By>>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};


export type Subscription_RootEntitlements_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEntitlements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Entitlements_Stream_Cursor_Input>>;
  where?: InputMaybe<Entitlements_Bool_Exp>;
};


export type Subscription_RootEquationFormulaOptionArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


export type Subscription_RootEquationFormulaOption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EquationFormulaOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EquationFormulaOption_Order_By>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


export type Subscription_RootEquationFormulaOption_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEquationFormulaOption_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EquationFormulaOption_Stream_Cursor_Input>>;
  where?: InputMaybe<EquationFormulaOption_Bool_Exp>;
};


export type Subscription_RootEquationsArgs = {
  distinct_on?: InputMaybe<Array<Equations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equations_Order_By>>;
  where?: InputMaybe<Equations_Bool_Exp>;
};


export type Subscription_RootEquations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Equations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equations_Order_By>>;
  where?: InputMaybe<Equations_Bool_Exp>;
};


export type Subscription_RootEquations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEquations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Equations_Stream_Cursor_Input>>;
  where?: InputMaybe<Equations_Bool_Exp>;
};


export type Subscription_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootExpenseArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Subscription_RootExpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Expense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Expense_Order_By>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Subscription_RootExpense_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootExpense_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Expense_Stream_Cursor_Input>>;
  where?: InputMaybe<Expense_Bool_Exp>;
};


export type Subscription_RootExternalIntegrationTypesArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrationTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrationTypes_Order_By>>;
  where?: InputMaybe<ExternalIntegrationTypes_Bool_Exp>;
};


export type Subscription_RootExternalIntegrationTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrationTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrationTypes_Order_By>>;
  where?: InputMaybe<ExternalIntegrationTypes_Bool_Exp>;
};


export type Subscription_RootExternalIntegrationTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootExternalIntegrationTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalIntegrationTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalIntegrationTypes_Bool_Exp>;
};


export type Subscription_RootExternalIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrations_Order_By>>;
  where?: InputMaybe<ExternalIntegrations_Bool_Exp>;
};


export type Subscription_RootExternalIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalIntegrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalIntegrations_Order_By>>;
  where?: InputMaybe<ExternalIntegrations_Bool_Exp>;
};


export type Subscription_RootExternalIntegrations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternalIntegrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalIntegrations_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalIntegrations_Bool_Exp>;
};


export type Subscription_RootExternalJobBoardPublishingsArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


export type Subscription_RootExternalJobBoardPublishings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoardPublishings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoardPublishings_Order_By>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


export type Subscription_RootExternalJobBoardPublishings_By_PkArgs = {
  external_job_board_id: Scalars['uuid'];
  job_id: Scalars['Int'];
};


export type Subscription_RootExternalJobBoardPublishings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalJobBoardPublishings_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalJobBoardPublishings_Bool_Exp>;
};


export type Subscription_RootExternalJobBoardsArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoards_Order_By>>;
  where?: InputMaybe<ExternalJobBoards_Bool_Exp>;
};


export type Subscription_RootExternalJobBoards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalJobBoards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalJobBoards_Order_By>>;
  where?: InputMaybe<ExternalJobBoards_Bool_Exp>;
};


export type Subscription_RootExternalJobBoards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternalJobBoards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalJobBoards_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalJobBoards_Bool_Exp>;
};


export type Subscription_RootExternalSystemArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystem_Order_By>>;
  where?: InputMaybe<ExternalSystem_Bool_Exp>;
};


export type Subscription_RootExternalSystemInstanceArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystemInstance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystemInstance_Order_By>>;
  where?: InputMaybe<ExternalSystemInstance_Bool_Exp>;
};


export type Subscription_RootExternalSystemInstance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystemInstance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystemInstance_Order_By>>;
  where?: InputMaybe<ExternalSystemInstance_Bool_Exp>;
};


export type Subscription_RootExternalSystemInstance_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternalSystemInstance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalSystemInstance_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalSystemInstance_Bool_Exp>;
};


export type Subscription_RootExternalSystem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalSystem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalSystem_Order_By>>;
  where?: InputMaybe<ExternalSystem_Bool_Exp>;
};


export type Subscription_RootExternalSystem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternalSystem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalSystem_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalSystem_Bool_Exp>;
};


export type Subscription_RootFeatureFlagsArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlags_Order_By>>;
  where?: InputMaybe<FeatureFlags_Bool_Exp>;
};


export type Subscription_RootFeatureFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlags_Order_By>>;
  where?: InputMaybe<FeatureFlags_Bool_Exp>;
};


export type Subscription_RootFeatureFlags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFeatureFlags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FeatureFlags_Stream_Cursor_Input>>;
  where?: InputMaybe<FeatureFlags_Bool_Exp>;
};


export type Subscription_RootFieldArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


export type Subscription_RootFieldDefArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


export type Subscription_RootFieldDef_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldDef_Order_By>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


export type Subscription_RootFieldDef_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFieldDef_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FieldDef_Stream_Cursor_Input>>;
  where?: InputMaybe<FieldDef_Bool_Exp>;
};


export type Subscription_RootFieldPicklistValuesArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};


export type Subscription_RootFieldPicklistValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldPicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldPicklistValues_Order_By>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};


export type Subscription_RootFieldPicklistValues_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFieldPicklistValues_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FieldPicklistValues_Stream_Cursor_Input>>;
  where?: InputMaybe<FieldPicklistValues_Bool_Exp>;
};


export type Subscription_RootFieldTypeDefArgs = {
  distinct_on?: InputMaybe<Array<FieldTypeDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldTypeDef_Order_By>>;
  where?: InputMaybe<FieldTypeDef_Bool_Exp>;
};


export type Subscription_RootFieldTypeDef_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldTypeDef_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FieldTypeDef_Order_By>>;
  where?: InputMaybe<FieldTypeDef_Bool_Exp>;
};


export type Subscription_RootFieldTypeDef_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFieldTypeDef_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FieldTypeDef_Stream_Cursor_Input>>;
  where?: InputMaybe<FieldTypeDef_Bool_Exp>;
};


export type Subscription_RootField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Field_Order_By>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


export type Subscription_RootField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Field_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Bool_Exp>;
};


export type Subscription_RootFileTagsArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


export type Subscription_RootFileTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FileTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileTags_Order_By>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


export type Subscription_RootFileTags_By_PkArgs = {
  file_id: Scalars['Int'];
  tag_id: Scalars['uuid'];
};


export type Subscription_RootFileTags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FileTags_Stream_Cursor_Input>>;
  where?: InputMaybe<FileTags_Bool_Exp>;
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFormInputsArgs = {
  distinct_on?: InputMaybe<Array<FormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormInputs_Order_By>>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};


export type Subscription_RootFormInputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormInputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormInputs_Order_By>>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};


export type Subscription_RootFormInputs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFormInputs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FormInputs_Stream_Cursor_Input>>;
  where?: InputMaybe<FormInputs_Bool_Exp>;
};


export type Subscription_RootFormSelectOptionsArgs = {
  distinct_on?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormSelectOptions_Order_By>>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};


export type Subscription_RootFormSelectOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormSelectOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormSelectOptions_Order_By>>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};


export type Subscription_RootFormSelectOptions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFormSelectOptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FormSelectOptions_Stream_Cursor_Input>>;
  where?: InputMaybe<FormSelectOptions_Bool_Exp>;
};


export type Subscription_RootFormulaOptionsArgs = {
  distinct_on?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormulaOptions_Order_By>>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};


export type Subscription_RootFormulaOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormulaOptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FormulaOptions_Order_By>>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};


export type Subscription_RootFormulaOptions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFormulaOptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FormulaOptions_Stream_Cursor_Input>>;
  where?: InputMaybe<FormulaOptions_Bool_Exp>;
};


export type Subscription_RootGenderEnumArgs = {
  distinct_on?: InputMaybe<Array<GenderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenderEnum_Order_By>>;
  where?: InputMaybe<GenderEnum_Bool_Exp>;
};


export type Subscription_RootGenderEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenderEnum_Order_By>>;
  where?: InputMaybe<GenderEnum_Bool_Exp>;
};


export type Subscription_RootGenderEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootGenderEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GenderEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<GenderEnum_Bool_Exp>;
};


export type Subscription_RootGeocodingHistoryArgs = {
  distinct_on?: InputMaybe<Array<GeocodingHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GeocodingHistory_Order_By>>;
  where?: InputMaybe<GeocodingHistory_Bool_Exp>;
};


export type Subscription_RootGeocodingHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GeocodingHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GeocodingHistory_Order_By>>;
  where?: InputMaybe<GeocodingHistory_Bool_Exp>;
};


export type Subscription_RootGeocodingHistory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGeocodingHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GeocodingHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<GeocodingHistory_Bool_Exp>;
};


export type Subscription_RootGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Subscription_RootGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Subscription_RootGoals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGoals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Goals_Stream_Cursor_Input>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Subscription_RootGoogleSheetCalcTemplatesArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalcTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalcTemplates_Order_By>>;
  where?: InputMaybe<GoogleSheetCalcTemplates_Bool_Exp>;
};


export type Subscription_RootGoogleSheetCalcTemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalcTemplates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalcTemplates_Order_By>>;
  where?: InputMaybe<GoogleSheetCalcTemplates_Bool_Exp>;
};


export type Subscription_RootGoogleSheetCalcTemplates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGoogleSheetCalcTemplates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoogleSheetCalcTemplates_Stream_Cursor_Input>>;
  where?: InputMaybe<GoogleSheetCalcTemplates_Bool_Exp>;
};


export type Subscription_RootGoogleSheetCalculationsArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalculations_Order_By>>;
  where?: InputMaybe<GoogleSheetCalculations_Bool_Exp>;
};


export type Subscription_RootGoogleSheetCalculations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleSheetCalculations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleSheetCalculations_Order_By>>;
  where?: InputMaybe<GoogleSheetCalculations_Bool_Exp>;
};


export type Subscription_RootGoogleSheetCalculations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGoogleSheetCalculations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoogleSheetCalculations_Stream_Cursor_Input>>;
  where?: InputMaybe<GoogleSheetCalculations_Bool_Exp>;
};


export type Subscription_RootGroupJobsArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


export type Subscription_RootGroupJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GroupJobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GroupJobs_Order_By>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


export type Subscription_RootGroupJobs_By_PkArgs = {
  groupId: Scalars['uuid'];
  jobId: Scalars['Int'];
};


export type Subscription_RootGroupJobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GroupJobs_Stream_Cursor_Input>>;
  where?: InputMaybe<GroupJobs_Bool_Exp>;
};


export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootHighestStagesArgs = {
  distinct_on?: InputMaybe<Array<HighestStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HighestStages_Order_By>>;
  where?: InputMaybe<HighestStages_Bool_Exp>;
};


export type Subscription_RootHighestStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HighestStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HighestStages_Order_By>>;
  where?: InputMaybe<HighestStages_Bool_Exp>;
};


export type Subscription_RootHighestStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootHighestStages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<HighestStages_Stream_Cursor_Input>>;
  where?: InputMaybe<HighestStages_Bool_Exp>;
};


export type Subscription_RootHireTypesEnumArgs = {
  distinct_on?: InputMaybe<Array<HireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HireTypesEnum_Order_By>>;
  where?: InputMaybe<HireTypesEnum_Bool_Exp>;
};


export type Subscription_RootHireTypesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HireTypesEnum_Order_By>>;
  where?: InputMaybe<HireTypesEnum_Bool_Exp>;
};


export type Subscription_RootHireTypesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootHireTypesEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<HireTypesEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<HireTypesEnum_Bool_Exp>;
};


export type Subscription_RootItRequestContractEmailsArgs = {
  distinct_on?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItRequestContractEmails_Order_By>>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};


export type Subscription_RootItRequestContractEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ItRequestContractEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItRequestContractEmails_Order_By>>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};


export type Subscription_RootItRequestContractEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootItRequestContractEmails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ItRequestContractEmails_Stream_Cursor_Input>>;
  where?: InputMaybe<ItRequestContractEmails_Bool_Exp>;
};


export type Subscription_RootIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Subscription_RootIdentitiesOverlaysLinksArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


export type Subscription_RootIdentitiesOverlaysLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitiesOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitiesOverlaysLinks_Order_By>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


export type Subscription_RootIdentitiesOverlaysLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIdentitiesOverlaysLinks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentitiesOverlaysLinks_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentitiesOverlaysLinks_Bool_Exp>;
};


export type Subscription_RootIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Subscription_RootIdentities_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Identities_Stream_Cursor_Input>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Subscription_RootIdentityAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


export type Subscription_RootIdentityAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityAssignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityAssignments_Order_By>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


export type Subscription_RootIdentityAssignments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentityAssignments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityAssignments_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityAssignments_Bool_Exp>;
};


export type Subscription_RootIdentityCommentsArgs = {
  distinct_on?: InputMaybe<Array<IdentityComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityComments_Order_By>>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};


export type Subscription_RootIdentityComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityComments_Order_By>>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};


export type Subscription_RootIdentityComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentityComments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityComments_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityComments_Bool_Exp>;
};


export type Subscription_RootIdentityContractRelationshipArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityContractRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityContractRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityContractRelationship_Order_By>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityContractRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIdentityContractRelationship_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityContractRelationship_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityContractRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityDepartmentRelationshipArgs = {
  distinct_on?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityDepartmentRelationship_Order_By>>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityDepartmentRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityDepartmentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityDepartmentRelationship_Order_By>>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityDepartmentRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIdentityDepartmentRelationship_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityDepartmentRelationship_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityDepartmentRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityGroupsArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


export type Subscription_RootIdentityGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityGroups_Order_By>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


export type Subscription_RootIdentityGroups_By_PkArgs = {
  groupId: Scalars['uuid'];
  identityId: Scalars['Int'];
};


export type Subscription_RootIdentityGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityGroups_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityGroups_Bool_Exp>;
};


export type Subscription_RootIdentityJobRelationshipArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityJobRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityJobRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityJobRelationship_Order_By>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityJobRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIdentityJobRelationship_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityJobRelationship_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityJobRelationship_Bool_Exp>;
};


export type Subscription_RootIdentityPartnerGroupDataArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


export type Subscription_RootIdentityPartnerGroupData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityPartnerGroupData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityPartnerGroupData_Order_By>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


export type Subscription_RootIdentityPartnerGroupData_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentityPartnerGroupData_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityPartnerGroupData_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityPartnerGroupData_Bool_Exp>;
};


export type Subscription_RootIdentityRelationshipTypeArgs = {
  distinct_on?: InputMaybe<Array<IdentityRelationshipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRelationshipType_Order_By>>;
  where?: InputMaybe<IdentityRelationshipType_Bool_Exp>;
};


export type Subscription_RootIdentityRelationshipType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityRelationshipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRelationshipType_Order_By>>;
  where?: InputMaybe<IdentityRelationshipType_Bool_Exp>;
};


export type Subscription_RootIdentityRelationshipType_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootIdentityRelationshipType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityRelationshipType_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityRelationshipType_Bool_Exp>;
};


export type Subscription_RootIdentityRolesArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


export type Subscription_RootIdentityRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityRoles_Order_By>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


export type Subscription_RootIdentityRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityRoles_Bool_Exp>;
};


export type Subscription_RootIdentitySubTypeEmailsArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


export type Subscription_RootIdentitySubTypeEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypeEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypeEmails_Order_By>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


export type Subscription_RootIdentitySubTypeEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentitySubTypeEmails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentitySubTypeEmails_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentitySubTypeEmails_Bool_Exp>;
};


export type Subscription_RootIdentitySubTypesArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypes_Order_By>>;
  where?: InputMaybe<IdentitySubTypes_Bool_Exp>;
};


export type Subscription_RootIdentitySubTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentitySubTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentitySubTypes_Order_By>>;
  where?: InputMaybe<IdentitySubTypes_Bool_Exp>;
};


export type Subscription_RootIdentitySubTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentitySubTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentitySubTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentitySubTypes_Bool_Exp>;
};


export type Subscription_RootIdentityTagsArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


export type Subscription_RootIdentityTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTags_Order_By>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


export type Subscription_RootIdentityTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIdentityTags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityTags_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityTags_Bool_Exp>;
};


export type Subscription_RootIdentityTokensArgs = {
  distinct_on?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTokens_Order_By>>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};


export type Subscription_RootIdentityTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTokens_Order_By>>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};


export type Subscription_RootIdentityTokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentityTokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityTokens_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityTokens_Bool_Exp>;
};


export type Subscription_RootIdentityTypesArgs = {
  distinct_on?: InputMaybe<Array<IdentityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTypes_Order_By>>;
  where?: InputMaybe<IdentityTypes_Bool_Exp>;
};


export type Subscription_RootIdentityTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityTypes_Order_By>>;
  where?: InputMaybe<IdentityTypes_Bool_Exp>;
};


export type Subscription_RootIdentityTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIdentityTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityTypes_Bool_Exp>;
};


export type Subscription_RootIdentityUserAccessGroupArgs = {
  distinct_on?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityUserAccessGroup_Order_By>>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};


export type Subscription_RootIdentityUserAccessGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IdentityUserAccessGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IdentityUserAccessGroup_Order_By>>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};


export type Subscription_RootIdentityUserAccessGroup_By_PkArgs = {
  identity_id: Scalars['Int'];
  user_access_group_id: Scalars['uuid'];
};


export type Subscription_RootIdentityUserAccessGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityUserAccessGroup_Stream_Cursor_Input>>;
  where?: InputMaybe<IdentityUserAccessGroup_Bool_Exp>;
};


export type Subscription_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootImages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootInputTypesArgs = {
  distinct_on?: InputMaybe<Array<InputTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InputTypes_Order_By>>;
  where?: InputMaybe<InputTypes_Bool_Exp>;
};


export type Subscription_RootInputTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InputTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InputTypes_Order_By>>;
  where?: InputMaybe<InputTypes_Bool_Exp>;
};


export type Subscription_RootInputTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootInputTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InputTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<InputTypes_Bool_Exp>;
};


export type Subscription_RootInternalJobCategoriesArgs = {
  distinct_on?: InputMaybe<Array<InternalJobCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalJobCategories_Order_By>>;
  where?: InputMaybe<InternalJobCategories_Bool_Exp>;
};


export type Subscription_RootInternalJobCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalJobCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalJobCategories_Order_By>>;
  where?: InputMaybe<InternalJobCategories_Bool_Exp>;
};


export type Subscription_RootInternalJobCategories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInternalJobCategories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InternalJobCategories_Stream_Cursor_Input>>;
  where?: InputMaybe<InternalJobCategories_Bool_Exp>;
};


export type Subscription_RootInterviewsArgs = {
  distinct_on?: InputMaybe<Array<Interviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interviews_Order_By>>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};


export type Subscription_RootInterviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interviews_Order_By>>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};


export type Subscription_RootInterviews_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInterviews_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Interviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Interviews_Bool_Exp>;
};


export type Subscription_RootJobAvailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<JobAvailabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobAvailabilities_Order_By>>;
  where?: InputMaybe<JobAvailabilities_Bool_Exp>;
};


export type Subscription_RootJobAvailabilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobAvailabilities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobAvailabilities_Order_By>>;
  where?: InputMaybe<JobAvailabilities_Bool_Exp>;
};


export type Subscription_RootJobAvailabilities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJobAvailabilities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobAvailabilities_Stream_Cursor_Input>>;
  where?: InputMaybe<JobAvailabilities_Bool_Exp>;
};


export type Subscription_RootJobCommentsArgs = {
  distinct_on?: InputMaybe<Array<JobComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobComments_Order_By>>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};


export type Subscription_RootJobComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobComments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobComments_Order_By>>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};


export type Subscription_RootJobComments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootJobComments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobComments_Stream_Cursor_Input>>;
  where?: InputMaybe<JobComments_Bool_Exp>;
};


export type Subscription_RootJobFilesArgs = {
  distinct_on?: InputMaybe<Array<JobFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFiles_Order_By>>;
  where?: InputMaybe<JobFiles_Bool_Exp>;
};


export type Subscription_RootJobFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFiles_Order_By>>;
  where?: InputMaybe<JobFiles_Bool_Exp>;
};


export type Subscription_RootJobFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJobFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobFiles_Stream_Cursor_Input>>;
  where?: InputMaybe<JobFiles_Bool_Exp>;
};


export type Subscription_RootJobFundingStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<JobFundingStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFundingStatusEnum_Order_By>>;
  where?: InputMaybe<JobFundingStatusEnum_Bool_Exp>;
};


export type Subscription_RootJobFundingStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobFundingStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobFundingStatusEnum_Order_By>>;
  where?: InputMaybe<JobFundingStatusEnum_Bool_Exp>;
};


export type Subscription_RootJobFundingStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootJobFundingStatusEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobFundingStatusEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<JobFundingStatusEnum_Bool_Exp>;
};


export type Subscription_RootJobHireTypesEnumArgs = {
  distinct_on?: InputMaybe<Array<JobHireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobHireTypesEnum_Order_By>>;
  where?: InputMaybe<JobHireTypesEnum_Bool_Exp>;
};


export type Subscription_RootJobHireTypesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobHireTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobHireTypesEnum_Order_By>>;
  where?: InputMaybe<JobHireTypesEnum_Bool_Exp>;
};


export type Subscription_RootJobHireTypesEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootJobHireTypesEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobHireTypesEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<JobHireTypesEnum_Bool_Exp>;
};


export type Subscription_RootJobPositionsArgs = {
  distinct_on?: InputMaybe<Array<JobPositions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobPositions_Order_By>>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};


export type Subscription_RootJobPositions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobPositions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobPositions_Order_By>>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};


export type Subscription_RootJobPositions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootJobPositions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobPositions_Stream_Cursor_Input>>;
  where?: InputMaybe<JobPositions_Bool_Exp>;
};


export type Subscription_RootJobStatusesArgs = {
  distinct_on?: InputMaybe<Array<JobStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatuses_Order_By>>;
  where?: InputMaybe<JobStatuses_Bool_Exp>;
};


export type Subscription_RootJobStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobStatuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatuses_Order_By>>;
  where?: InputMaybe<JobStatuses_Bool_Exp>;
};


export type Subscription_RootJobStatuses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootJobStatuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobStatuses_Stream_Cursor_Input>>;
  where?: InputMaybe<JobStatuses_Bool_Exp>;
};


export type Subscription_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootJobs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootJobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootMagicLinksArgs = {
  distinct_on?: InputMaybe<Array<MagicLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MagicLinks_Order_By>>;
  where?: InputMaybe<MagicLinks_Bool_Exp>;
};


export type Subscription_RootMagicLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MagicLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MagicLinks_Order_By>>;
  where?: InputMaybe<MagicLinks_Bool_Exp>;
};


export type Subscription_RootMagicLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMagicLinks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MagicLinks_Stream_Cursor_Input>>;
  where?: InputMaybe<MagicLinks_Bool_Exp>;
};


export type Subscription_RootMigrationDataArgs = {
  distinct_on?: InputMaybe<Array<MigrationData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MigrationData_Order_By>>;
  where?: InputMaybe<MigrationData_Bool_Exp>;
};


export type Subscription_RootMigrationData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MigrationData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MigrationData_Order_By>>;
  where?: InputMaybe<MigrationData_Bool_Exp>;
};


export type Subscription_RootMigrationData_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMigrationData_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MigrationData_Stream_Cursor_Input>>;
  where?: InputMaybe<MigrationData_Bool_Exp>;
};


export type Subscription_RootObjectZooArgs = {
  distinct_on?: InputMaybe<Array<ObjectZoo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ObjectZoo_Order_By>>;
  where?: InputMaybe<ObjectZoo_Bool_Exp>;
};


export type Subscription_RootObjectZoo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ObjectZoo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ObjectZoo_Order_By>>;
  where?: InputMaybe<ObjectZoo_Bool_Exp>;
};


export type Subscription_RootObjectZoo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootObjectZoo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ObjectZoo_Stream_Cursor_Input>>;
  where?: InputMaybe<ObjectZoo_Bool_Exp>;
};


export type Subscription_RootOverlayArgs = {
  distinct_on?: InputMaybe<Array<Overlay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Overlay_Order_By>>;
  where?: InputMaybe<Overlay_Bool_Exp>;
};


export type Subscription_RootOverlayDefinitionArgs = {
  distinct_on?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OverlayDefinition_Order_By>>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};


export type Subscription_RootOverlayDefinition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OverlayDefinition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OverlayDefinition_Order_By>>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};


export type Subscription_RootOverlayDefinition_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOverlayDefinition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OverlayDefinition_Stream_Cursor_Input>>;
  where?: InputMaybe<OverlayDefinition_Bool_Exp>;
};


export type Subscription_RootOverlay_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Overlay_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Overlay_Order_By>>;
  where?: InputMaybe<Overlay_Bool_Exp>;
};


export type Subscription_RootOverlay_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOverlay_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Overlay_Stream_Cursor_Input>>;
  where?: InputMaybe<Overlay_Bool_Exp>;
};


export type Subscription_RootPhoneNumbersArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumbers_Order_By>>;
  where?: InputMaybe<PhoneNumbers_Bool_Exp>;
};


export type Subscription_RootPhoneNumbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumbers_Order_By>>;
  where?: InputMaybe<PhoneNumbers_Bool_Exp>;
};


export type Subscription_RootPhoneNumbers_By_PkArgs = {
  e164_form: Scalars['String'];
};


export type Subscription_RootPhoneNumbers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PhoneNumbers_Stream_Cursor_Input>>;
  where?: InputMaybe<PhoneNumbers_Bool_Exp>;
};


export type Subscription_RootPicklistValuesArgs = {
  distinct_on?: InputMaybe<Array<PicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PicklistValues_Order_By>>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};


export type Subscription_RootPicklistValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PicklistValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PicklistValues_Order_By>>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};


export type Subscription_RootPicklistValues_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPicklistValues_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PicklistValues_Stream_Cursor_Input>>;
  where?: InputMaybe<PicklistValues_Bool_Exp>;
};


export type Subscription_RootPicklistsArgs = {
  distinct_on?: InputMaybe<Array<Picklists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picklists_Order_By>>;
  where?: InputMaybe<Picklists_Bool_Exp>;
};


export type Subscription_RootPicklists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Picklists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Picklists_Order_By>>;
  where?: InputMaybe<Picklists_Bool_Exp>;
};


export type Subscription_RootPicklists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPicklists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Picklists_Stream_Cursor_Input>>;
  where?: InputMaybe<Picklists_Bool_Exp>;
};


export type Subscription_RootProductUpdatesArgs = {
  distinct_on?: InputMaybe<Array<ProductUpdates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductUpdates_Order_By>>;
  where?: InputMaybe<ProductUpdates_Bool_Exp>;
};


export type Subscription_RootProductUpdates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductUpdates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductUpdates_Order_By>>;
  where?: InputMaybe<ProductUpdates_Bool_Exp>;
};


export type Subscription_RootProductUpdates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProductUpdates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProductUpdates_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductUpdates_Bool_Exp>;
};


export type Subscription_RootQuestionnaireContactTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Order_By>>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};


export type Subscription_RootQuestionnaireContactTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireContactTemplateVariables_Order_By>>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};


export type Subscription_RootQuestionnaireContactTemplateVariables_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};


export type Subscription_RootQuestionnaireContactTemplateVariables_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionnaireContactTemplateVariables_Stream_Cursor_Input>>;
  where?: InputMaybe<QuestionnaireContactTemplateVariables_Bool_Exp>;
};


export type Subscription_RootQuestionnaireLinksArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireLinks_Order_By>>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};


export type Subscription_RootQuestionnaireLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireLinks_Order_By>>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};


export type Subscription_RootQuestionnaireLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestionnaireLinks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionnaireLinks_Stream_Cursor_Input>>;
  where?: InputMaybe<QuestionnaireLinks_Bool_Exp>;
};


export type Subscription_RootQuestionnaireResponsesArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireResponses_Order_By>>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};


export type Subscription_RootQuestionnaireResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireResponses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireResponses_Order_By>>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};


export type Subscription_RootQuestionnaireResponses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestionnaireResponses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionnaireResponses_Stream_Cursor_Input>>;
  where?: InputMaybe<QuestionnaireResponses_Bool_Exp>;
};


export type Subscription_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestionnaires_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootRrWebRecordsArgs = {
  distinct_on?: InputMaybe<Array<RrWebRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RrWebRecords_Order_By>>;
  where?: InputMaybe<RrWebRecords_Bool_Exp>;
};


export type Subscription_RootRrWebRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RrWebRecords_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RrWebRecords_Order_By>>;
  where?: InputMaybe<RrWebRecords_Bool_Exp>;
};


export type Subscription_RootRrWebRecords_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRrWebRecords_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RrWebRecords_Stream_Cursor_Input>>;
  where?: InputMaybe<RrWebRecords_Bool_Exp>;
};


export type Subscription_RootRaceEnumArgs = {
  distinct_on?: InputMaybe<Array<RaceEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RaceEnum_Order_By>>;
  where?: InputMaybe<RaceEnum_Bool_Exp>;
};


export type Subscription_RootRaceEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RaceEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RaceEnum_Order_By>>;
  where?: InputMaybe<RaceEnum_Bool_Exp>;
};


export type Subscription_RootRaceEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootRaceEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RaceEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<RaceEnum_Bool_Exp>;
};


export type Subscription_RootRangeValuesArgs = {
  distinct_on?: InputMaybe<Array<RangeValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RangeValues_Order_By>>;
  where?: InputMaybe<RangeValues_Bool_Exp>;
};


export type Subscription_RootRangeValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RangeValues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RangeValues_Order_By>>;
  where?: InputMaybe<RangeValues_Bool_Exp>;
};


export type Subscription_RootRangeValues_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRangeValues_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RangeValues_Stream_Cursor_Input>>;
  where?: InputMaybe<RangeValues_Bool_Exp>;
};


export type Subscription_RootRejectionTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<RejectionTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RejectionTypeEnum_Order_By>>;
  where?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
};


export type Subscription_RootRejectionTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RejectionTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RejectionTypeEnum_Order_By>>;
  where?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
};


export type Subscription_RootRejectionTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootRejectionTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RejectionTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<RejectionTypeEnum_Bool_Exp>;
};


export type Subscription_RootRejectionsArgs = {
  distinct_on?: InputMaybe<Array<Rejections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rejections_Order_By>>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};


export type Subscription_RootRejections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rejections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rejections_Order_By>>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};


export type Subscription_RootRejections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRejections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rejections_Stream_Cursor_Input>>;
  where?: InputMaybe<Rejections_Bool_Exp>;
};


export type Subscription_RootRenderedEmailsArgs = {
  distinct_on?: InputMaybe<Array<RenderedEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RenderedEmails_Order_By>>;
  where?: InputMaybe<RenderedEmails_Bool_Exp>;
};


export type Subscription_RootRenderedEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RenderedEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RenderedEmails_Order_By>>;
  where?: InputMaybe<RenderedEmails_Bool_Exp>;
};


export type Subscription_RootRenderedEmails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRenderedEmails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RenderedEmails_Stream_Cursor_Input>>;
  where?: InputMaybe<RenderedEmails_Bool_Exp>;
};


export type Subscription_RootResumeClaimsArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


export type Subscription_RootResumeClaims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeClaims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeClaims_Order_By>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


export type Subscription_RootResumeClaims_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootResumeClaims_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ResumeClaims_Stream_Cursor_Input>>;
  where?: InputMaybe<ResumeClaims_Bool_Exp>;
};


export type Subscription_RootResumeEmailAddressesArgs = {
  distinct_on?: InputMaybe<Array<ResumeEmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeEmailAddresses_Order_By>>;
  where?: InputMaybe<ResumeEmailAddresses_Bool_Exp>;
};


export type Subscription_RootResumeEmailAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeEmailAddresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeEmailAddresses_Order_By>>;
  where?: InputMaybe<ResumeEmailAddresses_Bool_Exp>;
};


export type Subscription_RootResumeEmailAddresses_By_PkArgs = {
  normalized_email: Scalars['String'];
  resume_id: Scalars['Int'];
};


export type Subscription_RootResumeEmailAddresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ResumeEmailAddresses_Stream_Cursor_Input>>;
  where?: InputMaybe<ResumeEmailAddresses_Bool_Exp>;
};


export type Subscription_RootResumeIngestStatusArgs = {
  distinct_on?: InputMaybe<Array<ResumeIngestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeIngestStatus_Order_By>>;
  where?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
};


export type Subscription_RootResumeIngestStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumeIngestStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumeIngestStatus_Order_By>>;
  where?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
};


export type Subscription_RootResumeIngestStatus_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootResumeIngestStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ResumeIngestStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<ResumeIngestStatus_Bool_Exp>;
};


export type Subscription_RootResumePhoneNumbersArgs = {
  distinct_on?: InputMaybe<Array<ResumePhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumePhoneNumbers_Order_By>>;
  where?: InputMaybe<ResumePhoneNumbers_Bool_Exp>;
};


export type Subscription_RootResumePhoneNumbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResumePhoneNumbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResumePhoneNumbers_Order_By>>;
  where?: InputMaybe<ResumePhoneNumbers_Bool_Exp>;
};


export type Subscription_RootResumePhoneNumbers_By_PkArgs = {
  e164_form: Scalars['String'];
  resume_id: Scalars['Int'];
};


export type Subscription_RootResumePhoneNumbers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ResumePhoneNumbers_Stream_Cursor_Input>>;
  where?: InputMaybe<ResumePhoneNumbers_Bool_Exp>;
};


export type Subscription_RootResumesArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


export type Subscription_RootResumes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resumes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resumes_Order_By>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


export type Subscription_RootResumes_By_PkArgs = {
  company_id: Scalars['Int'];
  data_hash: Scalars['String'];
};


export type Subscription_RootResumes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Resumes_Stream_Cursor_Input>>;
  where?: InputMaybe<Resumes_Bool_Exp>;
};


export type Subscription_RootReviewCriteriaArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteria_Order_By>>;
  where?: InputMaybe<ReviewCriteria_Bool_Exp>;
};


export type Subscription_RootReviewCriteriaScoreArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


export type Subscription_RootReviewCriteriaScoreAggregatesArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
};


export type Subscription_RootReviewCriteriaScoreAggregates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScoreAggregates_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
};


export type Subscription_RootReviewCriteriaScoreAggregates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewCriteriaScoreAggregates_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewCriteriaScoreAggregates_Bool_Exp>;
};


export type Subscription_RootReviewCriteriaScore_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteriaScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteriaScore_Order_By>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


export type Subscription_RootReviewCriteriaScore_By_PkArgs = {
  review_criteria_id: Scalars['uuid'];
  review_id: Scalars['Int'];
};


export type Subscription_RootReviewCriteriaScore_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewCriteriaScore_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewCriteriaScore_Bool_Exp>;
};


export type Subscription_RootReviewCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewCriteria_Order_By>>;
  where?: InputMaybe<ReviewCriteria_Bool_Exp>;
};


export type Subscription_RootReviewCriteria_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReviewCriteria_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewCriteria_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewCriteria_Bool_Exp>;
};


export type Subscription_RootReviewGroupContactTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Order_By>>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};


export type Subscription_RootReviewGroupContactTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupContactTemplateVariables_Order_By>>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};


export type Subscription_RootReviewGroupContactTemplateVariables_By_PkArgs = {
  review_group_id: Scalars['Int'];
  template_variable: ContactTemplateVariablesEnum_Enum;
};


export type Subscription_RootReviewGroupContactTemplateVariables_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewGroupContactTemplateVariables_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewGroupContactTemplateVariables_Bool_Exp>;
};


export type Subscription_RootReviewGroupCriteriaArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


export type Subscription_RootReviewGroupCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupCriteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupCriteria_Order_By>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


export type Subscription_RootReviewGroupCriteria_By_PkArgs = {
  review_criteria_id: Scalars['uuid'];
  review_group_id: Scalars['Int'];
};


export type Subscription_RootReviewGroupCriteria_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewGroupCriteria_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewGroupCriteria_Bool_Exp>;
};


export type Subscription_RootReviewGroupFilesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


export type Subscription_RootReviewGroupFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupFiles_Order_By>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


export type Subscription_RootReviewGroupFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReviewGroupFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewGroupFiles_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewGroupFiles_Bool_Exp>;
};


export type Subscription_RootReviewGroupStagesArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupStages_Order_By>>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};


export type Subscription_RootReviewGroupStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupStages_Order_By>>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};


export type Subscription_RootReviewGroupStages_By_PkArgs = {
  review_group_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};


export type Subscription_RootReviewGroupStages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewGroupStages_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewGroupStages_Bool_Exp>;
};


export type Subscription_RootReviewGroupSubTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupSubTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupSubTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupSubTypeEnum_Bool_Exp>;
};


export type Subscription_RootReviewGroupSubTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupSubTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupSubTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupSubTypeEnum_Bool_Exp>;
};


export type Subscription_RootReviewGroupSubTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootReviewGroupSubTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewGroupSubTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewGroupSubTypeEnum_Bool_Exp>;
};


export type Subscription_RootReviewGroupTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
};


export type Subscription_RootReviewGroupTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroupTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroupTypeEnum_Order_By>>;
  where?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
};


export type Subscription_RootReviewGroupTypeEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootReviewGroupTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewGroupTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewGroupTypeEnum_Bool_Exp>;
};


export type Subscription_RootReviewGroupsArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroups_Order_By>>;
  where?: InputMaybe<ReviewGroups_Bool_Exp>;
};


export type Subscription_RootReviewGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewGroups_Order_By>>;
  where?: InputMaybe<ReviewGroups_Bool_Exp>;
};


export type Subscription_RootReviewGroups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReviewGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewGroups_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewGroups_Bool_Exp>;
};


export type Subscription_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Subscription_RootReviewsResultEnumArgs = {
  distinct_on?: InputMaybe<Array<ReviewsResultEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewsResultEnum_Order_By>>;
  where?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
};


export type Subscription_RootReviewsResultEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReviewsResultEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReviewsResultEnum_Order_By>>;
  where?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
};


export type Subscription_RootReviewsResultEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootReviewsResultEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReviewsResultEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ReviewsResultEnum_Bool_Exp>;
};


export type Subscription_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Subscription_RootReviews_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReviews_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Subscription_RootRoleAccessArgs = {
  distinct_on?: InputMaybe<Array<RoleAccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleAccess_Order_By>>;
  where?: InputMaybe<RoleAccess_Bool_Exp>;
};


export type Subscription_RootRoleAccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RoleAccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleAccess_Order_By>>;
  where?: InputMaybe<RoleAccess_Bool_Exp>;
};


export type Subscription_RootRoleAccess_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRoleAccess_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RoleAccess_Stream_Cursor_Input>>;
  where?: InputMaybe<RoleAccess_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootSearchFilterTypesEnumArgs = {
  distinct_on?: InputMaybe<Array<SearchFilterTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilterTypesEnum_Order_By>>;
  where?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
};


export type Subscription_RootSearchFilterTypesEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SearchFilterTypesEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilterTypesEnum_Order_By>>;
  where?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
};


export type Subscription_RootSearchFilterTypesEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootSearchFilterTypesEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SearchFilterTypesEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<SearchFilterTypesEnum_Bool_Exp>;
};


export type Subscription_RootSearchFiltersArgs = {
  distinct_on?: InputMaybe<Array<SearchFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilters_Order_By>>;
  where?: InputMaybe<SearchFilters_Bool_Exp>;
};


export type Subscription_RootSearchFilters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SearchFilters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SearchFilters_Order_By>>;
  where?: InputMaybe<SearchFilters_Bool_Exp>;
};


export type Subscription_RootSearchFilters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSearchFilters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SearchFilters_Stream_Cursor_Input>>;
  where?: InputMaybe<SearchFilters_Bool_Exp>;
};


export type Subscription_RootSecurityClearancesArgs = {
  distinct_on?: InputMaybe<Array<SecurityClearances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecurityClearances_Order_By>>;
  where?: InputMaybe<SecurityClearances_Bool_Exp>;
};


export type Subscription_RootSecurityClearances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SecurityClearances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecurityClearances_Order_By>>;
  where?: InputMaybe<SecurityClearances_Bool_Exp>;
};


export type Subscription_RootSecurityClearances_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSecurityClearances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SecurityClearances_Stream_Cursor_Input>>;
  where?: InputMaybe<SecurityClearances_Bool_Exp>;
};


export type Subscription_RootSequelizeMetaArgs = {
  distinct_on?: InputMaybe<Array<SequelizeMeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SequelizeMeta_Order_By>>;
  where?: InputMaybe<SequelizeMeta_Bool_Exp>;
};


export type Subscription_RootSequelizeMeta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SequelizeMeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SequelizeMeta_Order_By>>;
  where?: InputMaybe<SequelizeMeta_Bool_Exp>;
};


export type Subscription_RootSequelizeMeta_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootSequelizeMeta_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SequelizeMeta_Stream_Cursor_Input>>;
  where?: InputMaybe<SequelizeMeta_Bool_Exp>;
};


export type Subscription_RootStageEmailContractsArgs = {
  distinct_on?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmailContracts_Order_By>>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};


export type Subscription_RootStageEmailContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmailContracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmailContracts_Order_By>>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};


export type Subscription_RootStageEmailContracts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStageEmailContracts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StageEmailContracts_Stream_Cursor_Input>>;
  where?: InputMaybe<StageEmailContracts_Bool_Exp>;
};


export type Subscription_RootStageEmailsArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


export type Subscription_RootStageEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageEmails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageEmails_Order_By>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


export type Subscription_RootStageEmails_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStageEmails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StageEmails_Stream_Cursor_Input>>;
  where?: InputMaybe<StageEmails_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireMappingArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMapping_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireMappingIdenityArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireMappingIdenity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMappingIdenity_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireMappingIdenity_By_PkArgs = {
  identity_id: Scalars['Int'];
  stage_questionnaire_mapping_id: Scalars['uuid'];
};


export type Subscription_RootStageQuestionnaireMappingIdenity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StageQuestionnaireMappingIdenity_Stream_Cursor_Input>>;
  where?: InputMaybe<StageQuestionnaireMappingIdenity_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireMapping_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireMapping_Order_By>>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireMapping_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStageQuestionnaireMapping_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StageQuestionnaireMapping_Stream_Cursor_Input>>;
  where?: InputMaybe<StageQuestionnaireMapping_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireTemplateVariablesArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Order_By>>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireTemplateVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageQuestionnaireTemplateVariables_Order_By>>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};


export type Subscription_RootStageQuestionnaireTemplateVariables_By_PkArgs = {
  contact_variable: ContactTemplateVariablesEnum_Enum;
  stage_questionnaire_mapping_id: Scalars['uuid'];
};


export type Subscription_RootStageQuestionnaireTemplateVariables_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StageQuestionnaireTemplateVariables_Stream_Cursor_Input>>;
  where?: InputMaybe<StageQuestionnaireTemplateVariables_Bool_Exp>;
};


export type Subscription_RootStageRolesArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


export type Subscription_RootStageRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageRoles_Order_By>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


export type Subscription_RootStageRoles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStageRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StageRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<StageRoles_Bool_Exp>;
};


export type Subscription_RootStageTransitionsArgs = {
  distinct_on?: InputMaybe<Array<StageTransitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageTransitions_Order_By>>;
  where?: InputMaybe<StageTransitions_Bool_Exp>;
};


export type Subscription_RootStageTransitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StageTransitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StageTransitions_Order_By>>;
  where?: InputMaybe<StageTransitions_Bool_Exp>;
};


export type Subscription_RootStageTransitions_By_PkArgs = {
  identity_id: Scalars['Int'];
  in_time: Scalars['timestamptz'];
  job_id: Scalars['Int'];
  out_time: Scalars['timestamptz'];
};


export type Subscription_RootStageTransitions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StageTransitions_Stream_Cursor_Input>>;
  where?: InputMaybe<StageTransitions_Bool_Exp>;
};


export type Subscription_RootStagesArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


export type Subscription_RootStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Order_By>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


export type Subscription_RootStages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stages_Stream_Cursor_Input>>;
  where?: InputMaybe<Stages_Bool_Exp>;
};


export type Subscription_RootStateMachinesArgs = {
  distinct_on?: InputMaybe<Array<StateMachines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StateMachines_Order_By>>;
  where?: InputMaybe<StateMachines_Bool_Exp>;
};


export type Subscription_RootStateMachines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StateMachines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StateMachines_Order_By>>;
  where?: InputMaybe<StateMachines_Bool_Exp>;
};


export type Subscription_RootStateMachines_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStateMachines_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StateMachines_Stream_Cursor_Input>>;
  where?: InputMaybe<StateMachines_Bool_Exp>;
};


export type Subscription_RootSubmissionsArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


export type Subscription_RootSubmissionsOverlaysLinksArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


export type Subscription_RootSubmissionsOverlaysLinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubmissionsOverlaysLinks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SubmissionsOverlaysLinks_Order_By>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


export type Subscription_RootSubmissionsOverlaysLinks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSubmissionsOverlaysLinks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SubmissionsOverlaysLinks_Stream_Cursor_Input>>;
  where?: InputMaybe<SubmissionsOverlaysLinks_Bool_Exp>;
};


export type Subscription_RootSubmissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Submissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Submissions_Order_By>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


export type Subscription_RootSubmissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSubmissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Submissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Submissions_Bool_Exp>;
};


export type Subscription_RootTableConfigTypesArgs = {
  distinct_on?: InputMaybe<Array<TableConfigTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigTypes_Order_By>>;
  where?: InputMaybe<TableConfigTypes_Bool_Exp>;
};


export type Subscription_RootTableConfigTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TableConfigTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigTypes_Order_By>>;
  where?: InputMaybe<TableConfigTypes_Bool_Exp>;
};


export type Subscription_RootTableConfigTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTableConfigTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TableConfigTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<TableConfigTypes_Bool_Exp>;
};


export type Subscription_RootTableConfigsArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


export type Subscription_RootTableConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TableConfigs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableConfigs_Order_By>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


export type Subscription_RootTableConfigs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTableConfigs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TableConfigs_Stream_Cursor_Input>>;
  where?: InputMaybe<TableConfigs_Bool_Exp>;
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTaskGroupAssigneesArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


export type Subscription_RootTaskGroupAssignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskGroupAssignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroupAssignees_Order_By>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


export type Subscription_RootTaskGroupAssignees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTaskGroupAssignees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TaskGroupAssignees_Stream_Cursor_Input>>;
  where?: InputMaybe<TaskGroupAssignees_Bool_Exp>;
};


export type Subscription_RootTaskGroupsArgs = {
  distinct_on?: InputMaybe<Array<TaskGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroups_Order_By>>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};


export type Subscription_RootTaskGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskGroups_Order_By>>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};


export type Subscription_RootTaskGroups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTaskGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TaskGroups_Stream_Cursor_Input>>;
  where?: InputMaybe<TaskGroups_Bool_Exp>;
};


export type Subscription_RootTaskPriorityTypesArgs = {
  distinct_on?: InputMaybe<Array<TaskPriorityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskPriorityTypes_Order_By>>;
  where?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
};


export type Subscription_RootTaskPriorityTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskPriorityTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskPriorityTypes_Order_By>>;
  where?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
};


export type Subscription_RootTaskPriorityTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTaskPriorityTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TaskPriorityTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<TaskPriorityTypes_Bool_Exp>;
};


export type Subscription_RootTaskTypesArgs = {
  distinct_on?: InputMaybe<Array<TaskTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskTypes_Order_By>>;
  where?: InputMaybe<TaskTypes_Bool_Exp>;
};


export type Subscription_RootTaskTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskTypes_Order_By>>;
  where?: InputMaybe<TaskTypes_Bool_Exp>;
};


export type Subscription_RootTaskTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTaskTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TaskTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<TaskTypes_Bool_Exp>;
};


export type Subscription_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTerminationsArgs = {
  distinct_on?: InputMaybe<Array<Terminations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminations_Order_By>>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};


export type Subscription_RootTerminations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminations_Order_By>>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};


export type Subscription_RootTerminations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTerminations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Terminations_Stream_Cursor_Input>>;
  where?: InputMaybe<Terminations_Bool_Exp>;
};


export type Subscription_RootTimeInStagesArgs = {
  distinct_on?: InputMaybe<Array<TimeInStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimeInStages_Order_By>>;
  where?: InputMaybe<TimeInStages_Bool_Exp>;
};


export type Subscription_RootTimeInStages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TimeInStages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimeInStages_Order_By>>;
  where?: InputMaybe<TimeInStages_Bool_Exp>;
};


export type Subscription_RootTimeInStages_By_PkArgs = {
  identity_id: Scalars['Int'];
  job_id: Scalars['Int'];
  stage_id: Scalars['Int'];
};


export type Subscription_RootTimeInStages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TimeInStages_Stream_Cursor_Input>>;
  where?: InputMaybe<TimeInStages_Bool_Exp>;
};


export type Subscription_RootTimelineEventsArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


export type Subscription_RootTimelineEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TimelineEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TimelineEvents_Order_By>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


export type Subscription_RootTimelineEvents_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTimelineEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TimelineEvents_Stream_Cursor_Input>>;
  where?: InputMaybe<TimelineEvents_Bool_Exp>;
};


export type Subscription_RootTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Subscription_RootTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Subscription_RootTokens_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Subscription_RootUserAccessGroupsArgs = {
  distinct_on?: InputMaybe<Array<UserAccessGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAccessGroups_Order_By>>;
  where?: InputMaybe<UserAccessGroups_Bool_Exp>;
};


export type Subscription_RootUserAccessGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAccessGroups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAccessGroups_Order_By>>;
  where?: InputMaybe<UserAccessGroups_Bool_Exp>;
};


export type Subscription_RootUserAccessGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserAccessGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserAccessGroups_Stream_Cursor_Input>>;
  where?: InputMaybe<UserAccessGroups_Bool_Exp>;
};


export type Subscription_RootUserInterfaceStateArgs = {
  distinct_on?: InputMaybe<Array<UserInterfaceState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInterfaceState_Order_By>>;
  where?: InputMaybe<UserInterfaceState_Bool_Exp>;
};


export type Subscription_RootUserInterfaceState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInterfaceState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInterfaceState_Order_By>>;
  where?: InputMaybe<UserInterfaceState_Bool_Exp>;
};


export type Subscription_RootUserInterfaceState_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUserInterfaceState_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserInterfaceState_Stream_Cursor_Input>>;
  where?: InputMaybe<UserInterfaceState_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootValueTypesArgs = {
  distinct_on?: InputMaybe<Array<ValueTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ValueTypes_Order_By>>;
  where?: InputMaybe<ValueTypes_Bool_Exp>;
};


export type Subscription_RootValueTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ValueTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ValueTypes_Order_By>>;
  where?: InputMaybe<ValueTypes_Bool_Exp>;
};


export type Subscription_RootValueTypes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootValueTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ValueTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<ValueTypes_Bool_Exp>;
};


export type Subscription_RootVeteranStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<VeteranStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VeteranStatusEnum_Order_By>>;
  where?: InputMaybe<VeteranStatusEnum_Bool_Exp>;
};


export type Subscription_RootVeteranStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VeteranStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VeteranStatusEnum_Order_By>>;
  where?: InputMaybe<VeteranStatusEnum_Bool_Exp>;
};


export type Subscription_RootVeteranStatusEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootVeteranStatusEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<VeteranStatusEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<VeteranStatusEnum_Bool_Exp>;
};


export type Subscription_RootWhiteLabelingArgs = {
  distinct_on?: InputMaybe<Array<WhiteLabeling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhiteLabeling_Order_By>>;
  where?: InputMaybe<WhiteLabeling_Bool_Exp>;
};


export type Subscription_RootWhiteLabeling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WhiteLabeling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhiteLabeling_Order_By>>;
  where?: InputMaybe<WhiteLabeling_Bool_Exp>;
};


export type Subscription_RootWhiteLabeling_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWhiteLabeling_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhiteLabeling_Stream_Cursor_Input>>;
  where?: InputMaybe<WhiteLabeling_Bool_Exp>;
};


export type Subscription_RootWorkflowRegressionsArgs = {
  distinct_on?: InputMaybe<Array<WorkflowRegressions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkflowRegressions_Order_By>>;
  where?: InputMaybe<WorkflowRegressions_Bool_Exp>;
};


export type Subscription_RootWorkflowRegressions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkflowRegressions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkflowRegressions_Order_By>>;
  where?: InputMaybe<WorkflowRegressions_Bool_Exp>;
};


export type Subscription_RootWorkflowRegressions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorkflowRegressions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WorkflowRegressions_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkflowRegressions_Bool_Exp>;
};


export type Subscription_RootActivity_Count_StatArgs = {
  args: Activity_Count_Stat_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Subscription_RootActivity_Count_Stat_AggregateArgs = {
  args: Activity_Count_Stat_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Subscription_RootActivity_Counts_Rm_Totals_OnlyArgs = {
  args: Activity_Counts_Rm_Totals_Only_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Subscription_RootActivity_Counts_Rm_Totals_Only_AggregateArgs = {
  args: Activity_Counts_Rm_Totals_Only_Args;
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Subscription_RootAms_For_Analytics_UiArgs = {
  distinct_on?: InputMaybe<Array<Ams_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ams_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Ams_For_Analytics_Ui_Bool_Exp>;
};


export type Subscription_RootAms_For_Analytics_Ui_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ams_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ams_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Ams_For_Analytics_Ui_Bool_Exp>;
};


export type Subscription_RootAms_For_Analytics_Ui_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ams_For_Analytics_Ui_Stream_Cursor_Input>>;
  where?: InputMaybe<Ams_For_Analytics_Ui_Bool_Exp>;
};


export type Subscription_RootAnalytics_ViewArgs = {
  distinct_on?: InputMaybe<Array<Analytics_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_View_Order_By>>;
  where?: InputMaybe<Analytics_View_Bool_Exp>;
};


export type Subscription_RootAnalytics_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Analytics_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Analytics_View_Order_By>>;
  where?: InputMaybe<Analytics_View_Bool_Exp>;
};


export type Subscription_RootAnalytics_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Analytics_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Analytics_View_Bool_Exp>;
};


export type Subscription_RootCandidate_Account_ManagersArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Account_Managers_Order_By>>;
  where?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
};


export type Subscription_RootCandidate_Account_Managers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Account_Managers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Account_Managers_Order_By>>;
  where?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
};


export type Subscription_RootCandidate_Account_Managers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Account_Managers_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Account_Managers_Bool_Exp>;
};


export type Subscription_RootCitizenshipEnumArgs = {
  distinct_on?: InputMaybe<Array<CitizenshipEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CitizenshipEnum_Order_By>>;
  where?: InputMaybe<CitizenshipEnum_Bool_Exp>;
};


export type Subscription_RootCitizenshipEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CitizenshipEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CitizenshipEnum_Order_By>>;
  where?: InputMaybe<CitizenshipEnum_Bool_Exp>;
};


export type Subscription_RootCitizenshipEnum_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootCitizenshipEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CitizenshipEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<CitizenshipEnum_Bool_Exp>;
};


export type Subscription_RootComments_For_AnalyticsArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Bool_Exp>;
};


export type Subscription_RootComments_For_Analytics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Bool_Exp>;
};


export type Subscription_RootComments_For_Analytics_Join_Underlying_ViewArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>;
};


export type Subscription_RootComments_For_Analytics_Join_Underlying_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_For_Analytics_Join_Underlying_View_Order_By>>;
  where?: InputMaybe<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>;
};


export type Subscription_RootComments_For_Analytics_Join_Underlying_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comments_For_Analytics_Join_Underlying_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_For_Analytics_Join_Underlying_View_Bool_Exp>;
};


export type Subscription_RootComments_For_Analytics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comments_For_Analytics_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_For_Analytics_Bool_Exp>;
};


export type Subscription_RootContract_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


export type Subscription_RootContract_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Emails_Order_By>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


export type Subscription_RootContract_Emails_By_PkArgs = {
  contract_id: Scalars['Int'];
  email_id: Scalars['Int'];
};


export type Subscription_RootContract_Emails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contract_Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Contract_Emails_Bool_Exp>;
};


export type Subscription_RootCurrent_Wk_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Current_Wk_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Wk_Activity_Order_By>>;
  where?: InputMaybe<Current_Wk_Activity_Bool_Exp>;
};


export type Subscription_RootCurrent_Wk_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Wk_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Wk_Activity_Order_By>>;
  where?: InputMaybe<Current_Wk_Activity_Bool_Exp>;
};


export type Subscription_RootCurrent_Wk_Activity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Current_Wk_Activity_Stream_Cursor_Input>>;
  where?: InputMaybe<Current_Wk_Activity_Bool_Exp>;
};


export type Subscription_RootCurrent_Workflows_For_IdentityArgs = {
  distinct_on?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Workflows_For_Identity_Order_By>>;
  where?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
};


export type Subscription_RootCurrent_Workflows_For_Identity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_Workflows_For_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_Workflows_For_Identity_Order_By>>;
  where?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
};


export type Subscription_RootCurrent_Workflows_For_Identity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Current_Workflows_For_Identity_Stream_Cursor_Input>>;
  where?: InputMaybe<Current_Workflows_For_Identity_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_DayArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Day_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Day_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Activity_Counts_By_Day_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Day_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_HourArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Hour_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Hour_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Hour_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Hour_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Hour_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Activity_Counts_By_Hour_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Hour_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_MonthArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Month_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Month_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Month_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Month_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Activity_Counts_By_Month_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Month_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_WeekArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Week_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Activity_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Week_Bool_Exp>;
};


export type Subscription_RootDashboard_Activity_Counts_By_Week_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Activity_Counts_By_Week_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Activity_Counts_By_Week_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_DayArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Day_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Day_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Day_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Stage_Counts_By_Day_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Day_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_MonthArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Month_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Month_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Month_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Month_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Month_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Stage_Counts_By_Month_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Month_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_WeekArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Week_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Week_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Week_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Week_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Stage_Counts_By_Week_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Week_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_YearArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Year_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Year_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stage_Counts_By_Year_Order_By>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Year_Bool_Exp>;
};


export type Subscription_RootDashboard_Stage_Counts_By_Year_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Stage_Counts_By_Year_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Stage_Counts_By_Year_Bool_Exp>;
};


export type Subscription_RootDashboard_Stages_CountsArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Subscription_RootDashboard_Stages_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Subscription_RootDashboard_Stages_Counts_For_Date_RangeArgs = {
  args: Dashboard_Stages_Counts_For_Date_Range_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Subscription_RootDashboard_Stages_Counts_For_Date_Range_AggregateArgs = {
  args: Dashboard_Stages_Counts_For_Date_Range_Args;
  distinct_on?: InputMaybe<Array<Dashboard_Stages_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dashboard_Stages_Counts_Order_By>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Subscription_RootDashboard_Stages_Counts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dashboard_Stages_Counts_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Stages_Counts_Bool_Exp>;
};


export type Subscription_RootDeleted_Ppl_NamesArgs = {
  distinct_on?: InputMaybe<Array<Deleted_Ppl_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deleted_Ppl_Names_Order_By>>;
  where?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
};


export type Subscription_RootDeleted_Ppl_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deleted_Ppl_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deleted_Ppl_Names_Order_By>>;
  where?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
};


export type Subscription_RootDeleted_Ppl_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Deleted_Ppl_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Deleted_Ppl_Names_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_DisabilityArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Disability_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Disability_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Disability_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Disability_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Disability_Mat_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Disability_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Disability_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Eeo_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Eeo_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Eeo_Category_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Eeo_Category_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Eeo_Category_Mat_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Eeo_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Eeo_Category_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_GenderArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Gender_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Gender_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Gender_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Gender_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Gender_Mat_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Gender_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Gender_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_RaceArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Race_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Race_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Race_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Race_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Race_Mat_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Race_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Race_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Vet_StatusArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Vet_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Vet_Status_MatArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Vet_Status_Mat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Vet_Status_Mat_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Mat_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Comp_Bucket_Vet_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Comp_Bucket_Vet_Status_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Race_Gender_Hisp_By_Cat_PivotArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Order_By>>;
  where?: InputMaybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>;
};


export type Subscription_RootEeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Count_By_Race_Gender_Hisp_By_Cat_Pivot_Bool_Exp>;
};


export type Subscription_RootEeo_Data_Point_Status_By_Identity_ViewArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>;
};


export type Subscription_RootEeo_Data_Point_Status_By_Identity_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Point_Status_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>;
};


export type Subscription_RootEeo_Data_Point_Status_By_Identity_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Data_Point_Status_By_Identity_View_Bool_Exp>;
};


export type Subscription_RootEeo_Data_Points_Unknown_As_Other_By_Identity_ViewArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>;
};


export type Subscription_RootEeo_Data_Points_Unknown_As_Other_By_Identity_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Order_By>>;
  where?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>;
};


export type Subscription_RootEeo_Data_Points_Unknown_As_Other_By_Identity_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Data_Points_Unknown_As_Other_By_Identity_View_Bool_Exp>;
};


export type Subscription_RootEeo_Labor_Categories_Comp_BucketsArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Order_By>>;
  where?: InputMaybe<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>;
};


export type Subscription_RootEeo_Labor_Categories_Comp_Buckets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Labor_Categories_Comp_Buckets_Order_By>>;
  where?: InputMaybe<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>;
};


export type Subscription_RootEeo_Labor_Categories_Comp_Buckets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Labor_Categories_Comp_Buckets_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Labor_Categories_Comp_Buckets_Bool_Exp>;
};


export type Subscription_RootEeo_Pivot_Data_By_Comp_BucketArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>;
};


export type Subscription_RootEeo_Pivot_Data_By_Comp_Bucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Pivot_Data_By_Comp_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>;
};


export type Subscription_RootEeo_Pivot_Data_By_Comp_Bucket_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Pivot_Data_By_Comp_Bucket_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Pivot_Data_By_Comp_Bucket_Bool_Exp>;
};


export type Subscription_RootEeo_Status_By_IdentityArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_Bool_Exp>;
};


export type Subscription_RootEeo_Status_By_Identity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_Bool_Exp>;
};


export type Subscription_RootEeo_Status_By_Identity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Status_By_Identity_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Status_By_Identity_Bool_Exp>;
};


export type Subscription_RootEeo_Status_By_Identity_With_Compensation_And_BucketArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>;
};


export type Subscription_RootEeo_Status_By_Identity_With_Compensation_And_Bucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Order_By>>;
  where?: InputMaybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>;
};


export type Subscription_RootEeo_Status_By_Identity_With_Compensation_And_Bucket_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Status_By_Identity_With_Compensation_And_Bucket_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_GenderArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Gender_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Gender_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Gender_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Gender_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Gender_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Gender_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Summary_By_Gender_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Summary_By_Gender_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Has_DisabilityArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Has_Disability_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Has_Disability_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Has_Disability_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Has_Disability_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Has_Disability_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Summary_By_Has_Disability_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Summary_By_Has_Disability_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_RaceArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Race_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Race_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Race_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Race_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Race_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Race_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Race_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Summary_By_Race_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Summary_By_Race_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Veteran_StatusArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Veteran_Status_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Veteran_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eeo_Summary_By_Veteran_Status_Order_By>>;
  where?: InputMaybe<Eeo_Summary_By_Veteran_Status_Bool_Exp>;
};


export type Subscription_RootEeo_Summary_By_Veteran_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eeo_Summary_By_Veteran_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Eeo_Summary_By_Veteran_Status_Bool_Exp>;
};


export type Subscription_RootEmail_EventsArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Subscription_RootEmail_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Events_Order_By>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Subscription_RootEmail_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Events_Bool_Exp>;
};


export type Subscription_RootFilled_Jobs_ReportArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Report_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Report_Bool_Exp>;
};


export type Subscription_RootFilled_Jobs_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Report_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Report_Bool_Exp>;
};


export type Subscription_RootFilled_Jobs_Report_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Filled_Jobs_Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Filled_Jobs_Report_Bool_Exp>;
};


export type Subscription_RootFilled_Jobs_SubreportArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Subreport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Subreport_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Subreport_Bool_Exp>;
};


export type Subscription_RootFilled_Jobs_Subreport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Filled_Jobs_Subreport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Filled_Jobs_Subreport_Order_By>>;
  where?: InputMaybe<Filled_Jobs_Subreport_Bool_Exp>;
};


export type Subscription_RootFilled_Jobs_Subreport_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Filled_Jobs_Subreport_Stream_Cursor_Input>>;
  where?: InputMaybe<Filled_Jobs_Subreport_Bool_Exp>;
};


export type Subscription_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Geography_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Geometry_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_Function_ContractsArgs = {
  args: Global_Search_Function_Contracts_Args;
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_Function_Contracts_AggregateArgs = {
  args: Global_Search_Function_Contracts_Args;
  distinct_on?: InputMaybe<Array<Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contracts_Order_By>>;
  where?: InputMaybe<Contracts_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_Function_IdentitiesArgs = {
  args: Global_Search_Function_Identities_Args;
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_Function_Identities_AggregateArgs = {
  args: Global_Search_Function_Identities_Args;
  distinct_on?: InputMaybe<Array<Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_Order_By>>;
  where?: InputMaybe<Identities_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_Function_JobsArgs = {
  args: Global_Search_Function_Jobs_Args;
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootGlobal_Search_Function_Jobs_AggregateArgs = {
  args: Global_Search_Function_Jobs_Args;
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootIdentities_For_External_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Identities_For_External_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_For_External_Reviews_Order_By>>;
  where?: InputMaybe<Identities_For_External_Reviews_Bool_Exp>;
};


export type Subscription_RootIdentities_For_External_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identities_For_External_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identities_For_External_Reviews_Order_By>>;
  where?: InputMaybe<Identities_For_External_Reviews_Bool_Exp>;
};


export type Subscription_RootIdentities_For_External_Reviews_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Identities_For_External_Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Identities_For_External_Reviews_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_ArrArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_Arr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Arr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Arr_Order_By>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_Arr_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Identity_Roles_Arr_Stream_Cursor_Input>>;
  where?: InputMaybe<Identity_Roles_Arr_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_JoinedArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Joined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Joined_Order_By>>;
  where?: InputMaybe<Identity_Roles_Joined_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_Joined_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Joined_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Joined_Order_By>>;
  where?: InputMaybe<Identity_Roles_Joined_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_Joined_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Identity_Roles_Joined_Stream_Cursor_Input>>;
  where?: InputMaybe<Identity_Roles_Joined_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_StatArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_Stat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_Stat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_Stat_Order_By>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_Stat_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Identity_Roles_Stat_Stream_Cursor_Input>>;
  where?: InputMaybe<Identity_Roles_Stat_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_View_Order_By>>;
  where?: InputMaybe<Identity_Roles_View_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Identity_Roles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Roles_View_Order_By>>;
  where?: InputMaybe<Identity_Roles_View_Bool_Exp>;
};


export type Subscription_RootIdentity_Roles_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Identity_Roles_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Identity_Roles_View_Bool_Exp>;
};


export type Subscription_RootInternal_UsersArgs = {
  distinct_on?: InputMaybe<Array<Internal_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Users_Order_By>>;
  where?: InputMaybe<Internal_Users_Bool_Exp>;
};


export type Subscription_RootInternal_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Internal_Users_Order_By>>;
  where?: InputMaybe<Internal_Users_Bool_Exp>;
};


export type Subscription_RootInternal_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Internal_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Internal_Users_Bool_Exp>;
};


export type Subscription_RootJob_Contracts_Group_IdArgs = {
  distinct_on?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Contracts_Group_Id_Order_By>>;
  where?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
};


export type Subscription_RootJob_Contracts_Group_Id_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Contracts_Group_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Contracts_Group_Id_Order_By>>;
  where?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
};


export type Subscription_RootJob_Contracts_Group_Id_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Contracts_Group_Id_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Contracts_Group_Id_Bool_Exp>;
};


export type Subscription_RootJobboardsnippetscountsArgs = {
  distinct_on?: InputMaybe<Array<Jobboardsnippetscounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobboardsnippetscounts_Order_By>>;
  where?: InputMaybe<Jobboardsnippetscounts_Bool_Exp>;
};


export type Subscription_RootJobboardsnippetscounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobboardsnippetscounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobboardsnippetscounts_Order_By>>;
  where?: InputMaybe<Jobboardsnippetscounts_Bool_Exp>;
};


export type Subscription_RootJobboardsnippetscounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Jobboardsnippetscounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Jobboardsnippetscounts_Bool_Exp>;
};


export type Subscription_RootJobs_Available_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Available_Positions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Available_Positions_Order_By>>;
  where?: InputMaybe<Jobs_Available_Positions_Bool_Exp>;
};


export type Subscription_RootJobs_Available_Positions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Available_Positions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Available_Positions_Order_By>>;
  where?: InputMaybe<Jobs_Available_Positions_Bool_Exp>;
};


export type Subscription_RootJobs_Available_Positions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Jobs_Available_Positions_Stream_Cursor_Input>>;
  where?: InputMaybe<Jobs_Available_Positions_Bool_Exp>;
};


export type Subscription_RootMatchesArgs = {
  distinct_on?: InputMaybe<Array<Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Order_By>>;
  where?: InputMaybe<Matches_Bool_Exp>;
};


export type Subscription_RootMatches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Order_By>>;
  where?: InputMaybe<Matches_Bool_Exp>;
};


export type Subscription_RootMatches_From_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Applications_Order_By>>;
  where?: InputMaybe<Matches_From_Applications_Bool_Exp>;
};


export type Subscription_RootMatches_From_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Applications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Applications_Order_By>>;
  where?: InputMaybe<Matches_From_Applications_Bool_Exp>;
};


export type Subscription_RootMatches_From_Applications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_From_Applications_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_From_Applications_Bool_Exp>;
};


export type Subscription_RootMatches_From_BoardArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Board_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Board_Order_By>>;
  where?: InputMaybe<Matches_From_Board_Bool_Exp>;
};


export type Subscription_RootMatches_From_Board_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Board_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Board_Order_By>>;
  where?: InputMaybe<Matches_From_Board_Bool_Exp>;
};


export type Subscription_RootMatches_From_Board_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_From_Board_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_From_Board_Bool_Exp>;
};


export type Subscription_RootMatches_From_IdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Identities_Order_By>>;
  where?: InputMaybe<Matches_From_Identities_Bool_Exp>;
};


export type Subscription_RootMatches_From_Identities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Identities_Order_By>>;
  where?: InputMaybe<Matches_From_Identities_Bool_Exp>;
};


export type Subscription_RootMatches_From_Identities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_From_Identities_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_From_Identities_Bool_Exp>;
};


export type Subscription_RootMatches_From_TasksArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Tasks_Order_By>>;
  where?: InputMaybe<Matches_From_Tasks_Bool_Exp>;
};


export type Subscription_RootMatches_From_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_From_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_From_Tasks_Order_By>>;
  where?: InputMaybe<Matches_From_Tasks_Bool_Exp>;
};


export type Subscription_RootMatches_From_Tasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_From_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_From_Tasks_Bool_Exp>;
};


export type Subscription_RootMatches_GroupedArgs = {
  distinct_on?: InputMaybe<Array<Matches_Grouped_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Grouped_Order_By>>;
  where?: InputMaybe<Matches_Grouped_Bool_Exp>;
};


export type Subscription_RootMatches_Grouped_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Grouped_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Grouped_Order_By>>;
  where?: InputMaybe<Matches_Grouped_Bool_Exp>;
};


export type Subscription_RootMatches_Grouped_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_Grouped_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_Grouped_Bool_Exp>;
};


export type Subscription_RootMatches_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_Bool_Exp>;
};


export type Subscription_RootMatches_UnionArgs = {
  distinct_on?: InputMaybe<Array<Matches_Union_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Union_Order_By>>;
  where?: InputMaybe<Matches_Union_Bool_Exp>;
};


export type Subscription_RootMatches_Union_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Union_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Union_Order_By>>;
  where?: InputMaybe<Matches_Union_Bool_Exp>;
};


export type Subscription_RootMatches_Union_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_Union_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_Union_Bool_Exp>;
};


export type Subscription_RootMatches_Without_Author_With_Int_UuidArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


export type Subscription_RootMatches_Without_Author_With_Int_Uuid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Matches_Without_Author_With_Int_Uuid_Order_By>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


export type Subscription_RootMatches_Without_Author_With_Int_Uuid_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Matches_Without_Author_With_Int_Uuid_Stream_Cursor_Input>>;
  where?: InputMaybe<Matches_Without_Author_With_Int_Uuid_Bool_Exp>;
};


export type Subscription_RootNylas_EventsArgs = {
  distinct_on?: InputMaybe<Array<Nylas_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nylas_Events_Order_By>>;
  where?: InputMaybe<Nylas_Events_Bool_Exp>;
};


export type Subscription_RootNylas_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nylas_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nylas_Events_Order_By>>;
  where?: InputMaybe<Nylas_Events_Bool_Exp>;
};


export type Subscription_RootNylas_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNylas_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nylas_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Nylas_Events_Bool_Exp>;
};


export type Subscription_RootOfccp_ComplianceArgs = {
  distinct_on?: InputMaybe<Array<Ofccp_Compliance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ofccp_Compliance_Order_By>>;
  where?: InputMaybe<Ofccp_Compliance_Bool_Exp>;
};


export type Subscription_RootOfccp_Compliance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ofccp_Compliance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ofccp_Compliance_Order_By>>;
  where?: InputMaybe<Ofccp_Compliance_Bool_Exp>;
};


export type Subscription_RootOfccp_Compliance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ofccp_Compliance_Stream_Cursor_Input>>;
  where?: InputMaybe<Ofccp_Compliance_Bool_Exp>;
};


export type Subscription_RootRmsArgs = {
  distinct_on?: InputMaybe<Array<Rms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_Order_By>>;
  where?: InputMaybe<Rms_Bool_Exp>;
};


export type Subscription_RootRms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_Order_By>>;
  where?: InputMaybe<Rms_Bool_Exp>;
};


export type Subscription_RootRms_For_Analytics_UiArgs = {
  distinct_on?: InputMaybe<Array<Rms_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Rms_For_Analytics_Ui_Bool_Exp>;
};


export type Subscription_RootRms_For_Analytics_Ui_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rms_For_Analytics_Ui_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rms_For_Analytics_Ui_Order_By>>;
  where?: InputMaybe<Rms_For_Analytics_Ui_Bool_Exp>;
};


export type Subscription_RootRms_For_Analytics_Ui_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rms_For_Analytics_Ui_Stream_Cursor_Input>>;
  where?: InputMaybe<Rms_For_Analytics_Ui_Bool_Exp>;
};


export type Subscription_RootRms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rms_Stream_Cursor_Input>>;
  where?: InputMaybe<Rms_Bool_Exp>;
};


export type Subscription_RootSearch_Spec_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Search_Spec_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Spec_Results_Order_By>>;
  where?: InputMaybe<Search_Spec_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Spec_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Spec_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Spec_Results_Order_By>>;
  where?: InputMaybe<Search_Spec_Results_Bool_Exp>;
};


export type Subscription_RootSearch_Spec_Results_By_PkArgs = {
  combined_id: Scalars['String'];
  search_spec_id: Scalars['uuid'];
};


export type Subscription_RootSearch_Spec_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Search_Spec_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Search_Spec_Results_Bool_Exp>;
};


export type Subscription_RootSearch_SpecsArgs = {
  distinct_on?: InputMaybe<Array<Search_Specs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Specs_Order_By>>;
  where?: InputMaybe<Search_Specs_Bool_Exp>;
};


export type Subscription_RootSearch_Specs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Specs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Specs_Order_By>>;
  where?: InputMaybe<Search_Specs_Bool_Exp>;
};


export type Subscription_RootSearch_Specs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSearch_Specs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Search_Specs_Stream_Cursor_Input>>;
  where?: InputMaybe<Search_Specs_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int'];
};


export type Subscription_RootSpatial_Ref_Sys_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spatial_Ref_Sys_Stream_Cursor_Input>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootStaffing_StatusArgs = {
  distinct_on?: InputMaybe<Array<Staffing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staffing_Status_Order_By>>;
  where?: InputMaybe<Staffing_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staffing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staffing_Status_Order_By>>;
  where?: InputMaybe<Staffing_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staffing_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Staffing_Status_Bool_Exp>;
};


export type Subscription_RootStages_Conversion_MviewArgs = {
  distinct_on?: InputMaybe<Array<Stages_Conversion_Mview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Conversion_Mview_Order_By>>;
  where?: InputMaybe<Stages_Conversion_Mview_Bool_Exp>;
};


export type Subscription_RootStages_Conversion_Mview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stages_Conversion_Mview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stages_Conversion_Mview_Order_By>>;
  where?: InputMaybe<Stages_Conversion_Mview_Bool_Exp>;
};


export type Subscription_RootStages_Conversion_Mview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stages_Conversion_Mview_Stream_Cursor_Input>>;
  where?: InputMaybe<Stages_Conversion_Mview_Bool_Exp>;
};


export type Subscription_RootText_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Subscription_RootText_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Text_Messages_Order_By>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Subscription_RootText_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootText_Messages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Text_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};


export type Subscription_RootUtil_Deps_Saved_DdlArgs = {
  distinct_on?: InputMaybe<Array<Util_Deps_Saved_Ddl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Util_Deps_Saved_Ddl_Order_By>>;
  where?: InputMaybe<Util_Deps_Saved_Ddl_Bool_Exp>;
};


export type Subscription_RootUtil_Deps_Saved_Ddl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Util_Deps_Saved_Ddl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Util_Deps_Saved_Ddl_Order_By>>;
  where?: InputMaybe<Util_Deps_Saved_Ddl_Bool_Exp>;
};


export type Subscription_RootUtil_Deps_Saved_Ddl_By_PkArgs = {
  deps_id: Scalars['Int'];
};


export type Subscription_RootUtil_Deps_Saved_Ddl_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Util_Deps_Saved_Ddl_Stream_Cursor_Input>>;
  where?: InputMaybe<Util_Deps_Saved_Ddl_Bool_Exp>;
};


export type Subscription_RootVerify_Api_TokenArgs = {
  args: Verify_Api_Token_Args;
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Subscription_RootVerify_Api_Token_AggregateArgs = {
  args: Verify_Api_Token_Args;
  distinct_on?: InputMaybe<Array<ApiTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ApiTokens_Order_By>>;
  where?: InputMaybe<ApiTokens_Bool_Exp>;
};


export type Subscription_RootWorkflow_ContractsArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Contracts_Order_By>>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};


export type Subscription_RootWorkflow_Contracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Contracts_Order_By>>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};


export type Subscription_RootWorkflow_Contracts_By_PkArgs = {
  contract_id: Scalars['Int'];
};


export type Subscription_RootWorkflow_Contracts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workflow_Contracts_Stream_Cursor_Input>>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};


export type Subscription_RootWorkflow_DepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};


export type Subscription_RootWorkflow_Departments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workflow_Departments_Order_By>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};


export type Subscription_RootWorkflow_Departments_By_PkArgs = {
  department_id: Scalars['uuid'];
  workflow_id: Scalars['Int'];
};


export type Subscription_RootWorkflow_Departments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workflow_Departments_Stream_Cursor_Input>>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};

/** columns and relationships of "text_messages" */
export type Text_Messages = {
  __typename?: 'text_messages';
  /** An object relationship */
  Company: Companies;
  /** An object relationship */
  Recipient: Identities;
  /** An object relationship */
  Sender: Identities;
  company_id: Scalars['Int'];
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted: Scalars['Boolean'];
  id: Scalars['Int'];
  read: Scalars['Boolean'];
  recipient_id: Scalars['Int'];
  sender_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** aggregated selection of "text_messages" */
export type Text_Messages_Aggregate = {
  __typename?: 'text_messages_aggregate';
  aggregate?: Maybe<Text_Messages_Aggregate_Fields>;
  nodes: Array<Text_Messages>;
};

/** aggregate fields of "text_messages" */
export type Text_Messages_Aggregate_Fields = {
  __typename?: 'text_messages_aggregate_fields';
  avg?: Maybe<Text_Messages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Text_Messages_Max_Fields>;
  min?: Maybe<Text_Messages_Min_Fields>;
  stddev?: Maybe<Text_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Text_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Text_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Text_Messages_Sum_Fields>;
  var_pop?: Maybe<Text_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Text_Messages_Var_Samp_Fields>;
  variance?: Maybe<Text_Messages_Variance_Fields>;
};


/** aggregate fields of "text_messages" */
export type Text_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Text_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Text_Messages_Avg_Fields = {
  __typename?: 'text_messages_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "text_messages". All fields are combined with a logical 'AND'. */
export type Text_Messages_Bool_Exp = {
  Company?: InputMaybe<Companies_Bool_Exp>;
  Recipient?: InputMaybe<Identities_Bool_Exp>;
  Sender?: InputMaybe<Identities_Bool_Exp>;
  _and?: InputMaybe<Array<Text_Messages_Bool_Exp>>;
  _not?: InputMaybe<Text_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Messages_Bool_Exp>>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  read?: InputMaybe<Boolean_Comparison_Exp>;
  recipient_id?: InputMaybe<Int_Comparison_Exp>;
  sender_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "text_messages" */
export enum Text_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  TextMessagesPkey = 'text_messages_pkey'
}

/** input type for incrementing numeric columns in table "text_messages" */
export type Text_Messages_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  recipient_id?: InputMaybe<Scalars['Int']>;
  sender_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "text_messages" */
export type Text_Messages_Insert_Input = {
  Company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  Recipient?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  Sender?: InputMaybe<Identities_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipient_id?: InputMaybe<Scalars['Int']>;
  sender_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Text_Messages_Max_Fields = {
  __typename?: 'text_messages_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  sender_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Text_Messages_Min_Fields = {
  __typename?: 'text_messages_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  sender_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "text_messages" */
export type Text_Messages_Mutation_Response = {
  __typename?: 'text_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Messages>;
};

/** on_conflict condition type for table "text_messages" */
export type Text_Messages_On_Conflict = {
  constraint: Text_Messages_Constraint;
  update_columns?: Array<Text_Messages_Update_Column>;
  where?: InputMaybe<Text_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "text_messages". */
export type Text_Messages_Order_By = {
  Company?: InputMaybe<Companies_Order_By>;
  Recipient?: InputMaybe<Identities_Order_By>;
  Sender?: InputMaybe<Identities_Order_By>;
  company_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  read?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: text_messages */
export type Text_Messages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "text_messages" */
export enum Text_Messages_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "text_messages" */
export type Text_Messages_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipient_id?: InputMaybe<Scalars['Int']>;
  sender_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Text_Messages_Stddev_Fields = {
  __typename?: 'text_messages_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Text_Messages_Stddev_Pop_Fields = {
  __typename?: 'text_messages_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Text_Messages_Stddev_Samp_Fields = {
  __typename?: 'text_messages_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "text_messages" */
export type Text_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Text_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Text_Messages_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipient_id?: InputMaybe<Scalars['Int']>;
  sender_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Text_Messages_Sum_Fields = {
  __typename?: 'text_messages_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  recipient_id?: Maybe<Scalars['Int']>;
  sender_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "text_messages" */
export enum Text_Messages_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  Type = 'type'
}

export type Text_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Text_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Text_Messages_Var_Pop_Fields = {
  __typename?: 'text_messages_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Text_Messages_Var_Samp_Fields = {
  __typename?: 'text_messages_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Text_Messages_Variance_Fields = {
  __typename?: 'text_messages_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recipient_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "tsvector". All fields are combined with logical 'AND'. */
export type Tsvector_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['tsvector']>;
  _gt?: InputMaybe<Scalars['tsvector']>;
  _gte?: InputMaybe<Scalars['tsvector']>;
  _in?: InputMaybe<Array<Scalars['tsvector']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['tsvector']>;
  _lte?: InputMaybe<Scalars['tsvector']>;
  _neq?: InputMaybe<Scalars['tsvector']>;
  _nin?: InputMaybe<Array<Scalars['tsvector']>>;
};

/** columns and relationships of "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl = {
  __typename?: 'util_deps_saved_ddl';
  deps_ddl_to_run?: Maybe<Scalars['String']>;
  deps_id: Scalars['Int'];
  deps_view_name?: Maybe<Scalars['String']>;
  deps_view_schema?: Maybe<Scalars['String']>;
};

/** aggregated selection of "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Aggregate = {
  __typename?: 'util_deps_saved_ddl_aggregate';
  aggregate?: Maybe<Util_Deps_Saved_Ddl_Aggregate_Fields>;
  nodes: Array<Util_Deps_Saved_Ddl>;
};

/** aggregate fields of "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Aggregate_Fields = {
  __typename?: 'util_deps_saved_ddl_aggregate_fields';
  avg?: Maybe<Util_Deps_Saved_Ddl_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Util_Deps_Saved_Ddl_Max_Fields>;
  min?: Maybe<Util_Deps_Saved_Ddl_Min_Fields>;
  stddev?: Maybe<Util_Deps_Saved_Ddl_Stddev_Fields>;
  stddev_pop?: Maybe<Util_Deps_Saved_Ddl_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Util_Deps_Saved_Ddl_Stddev_Samp_Fields>;
  sum?: Maybe<Util_Deps_Saved_Ddl_Sum_Fields>;
  var_pop?: Maybe<Util_Deps_Saved_Ddl_Var_Pop_Fields>;
  var_samp?: Maybe<Util_Deps_Saved_Ddl_Var_Samp_Fields>;
  variance?: Maybe<Util_Deps_Saved_Ddl_Variance_Fields>;
};


/** aggregate fields of "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Util_Deps_Saved_Ddl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Util_Deps_Saved_Ddl_Avg_Fields = {
  __typename?: 'util_deps_saved_ddl_avg_fields';
  deps_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "util.deps_saved_ddl". All fields are combined with a logical 'AND'. */
export type Util_Deps_Saved_Ddl_Bool_Exp = {
  _and?: InputMaybe<Array<Util_Deps_Saved_Ddl_Bool_Exp>>;
  _not?: InputMaybe<Util_Deps_Saved_Ddl_Bool_Exp>;
  _or?: InputMaybe<Array<Util_Deps_Saved_Ddl_Bool_Exp>>;
  deps_ddl_to_run?: InputMaybe<String_Comparison_Exp>;
  deps_id?: InputMaybe<Int_Comparison_Exp>;
  deps_view_name?: InputMaybe<String_Comparison_Exp>;
  deps_view_schema?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "util.deps_saved_ddl" */
export enum Util_Deps_Saved_Ddl_Constraint {
  /** unique or primary key constraint on columns "deps_id" */
  DepsSavedDdlPkey = 'deps_saved_ddl_pkey'
}

/** input type for incrementing numeric columns in table "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Inc_Input = {
  deps_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Insert_Input = {
  deps_ddl_to_run?: InputMaybe<Scalars['String']>;
  deps_id?: InputMaybe<Scalars['Int']>;
  deps_view_name?: InputMaybe<Scalars['String']>;
  deps_view_schema?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Util_Deps_Saved_Ddl_Max_Fields = {
  __typename?: 'util_deps_saved_ddl_max_fields';
  deps_ddl_to_run?: Maybe<Scalars['String']>;
  deps_id?: Maybe<Scalars['Int']>;
  deps_view_name?: Maybe<Scalars['String']>;
  deps_view_schema?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Util_Deps_Saved_Ddl_Min_Fields = {
  __typename?: 'util_deps_saved_ddl_min_fields';
  deps_ddl_to_run?: Maybe<Scalars['String']>;
  deps_id?: Maybe<Scalars['Int']>;
  deps_view_name?: Maybe<Scalars['String']>;
  deps_view_schema?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Mutation_Response = {
  __typename?: 'util_deps_saved_ddl_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Util_Deps_Saved_Ddl>;
};

/** on_conflict condition type for table "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_On_Conflict = {
  constraint: Util_Deps_Saved_Ddl_Constraint;
  update_columns?: Array<Util_Deps_Saved_Ddl_Update_Column>;
  where?: InputMaybe<Util_Deps_Saved_Ddl_Bool_Exp>;
};

/** Ordering options when selecting data from "util.deps_saved_ddl". */
export type Util_Deps_Saved_Ddl_Order_By = {
  deps_ddl_to_run?: InputMaybe<Order_By>;
  deps_id?: InputMaybe<Order_By>;
  deps_view_name?: InputMaybe<Order_By>;
  deps_view_schema?: InputMaybe<Order_By>;
};

/** primary key columns input for table: util.deps_saved_ddl */
export type Util_Deps_Saved_Ddl_Pk_Columns_Input = {
  deps_id: Scalars['Int'];
};

/** select columns of table "util.deps_saved_ddl" */
export enum Util_Deps_Saved_Ddl_Select_Column {
  /** column name */
  DepsDdlToRun = 'deps_ddl_to_run',
  /** column name */
  DepsId = 'deps_id',
  /** column name */
  DepsViewName = 'deps_view_name',
  /** column name */
  DepsViewSchema = 'deps_view_schema'
}

/** input type for updating data in table "util.deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Set_Input = {
  deps_ddl_to_run?: InputMaybe<Scalars['String']>;
  deps_id?: InputMaybe<Scalars['Int']>;
  deps_view_name?: InputMaybe<Scalars['String']>;
  deps_view_schema?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Util_Deps_Saved_Ddl_Stddev_Fields = {
  __typename?: 'util_deps_saved_ddl_stddev_fields';
  deps_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Util_Deps_Saved_Ddl_Stddev_Pop_Fields = {
  __typename?: 'util_deps_saved_ddl_stddev_pop_fields';
  deps_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Util_Deps_Saved_Ddl_Stddev_Samp_Fields = {
  __typename?: 'util_deps_saved_ddl_stddev_samp_fields';
  deps_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "util_deps_saved_ddl" */
export type Util_Deps_Saved_Ddl_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Util_Deps_Saved_Ddl_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Util_Deps_Saved_Ddl_Stream_Cursor_Value_Input = {
  deps_ddl_to_run?: InputMaybe<Scalars['String']>;
  deps_id?: InputMaybe<Scalars['Int']>;
  deps_view_name?: InputMaybe<Scalars['String']>;
  deps_view_schema?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Util_Deps_Saved_Ddl_Sum_Fields = {
  __typename?: 'util_deps_saved_ddl_sum_fields';
  deps_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "util.deps_saved_ddl" */
export enum Util_Deps_Saved_Ddl_Update_Column {
  /** column name */
  DepsDdlToRun = 'deps_ddl_to_run',
  /** column name */
  DepsId = 'deps_id',
  /** column name */
  DepsViewName = 'deps_view_name',
  /** column name */
  DepsViewSchema = 'deps_view_schema'
}

export type Util_Deps_Saved_Ddl_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Util_Deps_Saved_Ddl_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Util_Deps_Saved_Ddl_Set_Input>;
  /** filter the rows which have to be updated */
  where: Util_Deps_Saved_Ddl_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Util_Deps_Saved_Ddl_Var_Pop_Fields = {
  __typename?: 'util_deps_saved_ddl_var_pop_fields';
  deps_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Util_Deps_Saved_Ddl_Var_Samp_Fields = {
  __typename?: 'util_deps_saved_ddl_var_samp_fields';
  deps_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Util_Deps_Saved_Ddl_Variance_Fields = {
  __typename?: 'util_deps_saved_ddl_variance_fields';
  deps_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type Verify_Api_Token_Args = {
  secret_material?: InputMaybe<Scalars['String']>;
};

/** Join table between workflows (state machines) and contracts */
export type Workflow_Contracts = {
  __typename?: 'workflow_contracts';
  /** An object relationship */
  Contract?: Maybe<Contracts>;
  /** An object relationship */
  Workflow?: Maybe<StateMachines>;
  contract_id: Scalars['Int'];
  tracingspancontext?: Maybe<Scalars['String']>;
  workflow_id: Scalars['Int'];
};

/** aggregated selection of "workflow_contracts" */
export type Workflow_Contracts_Aggregate = {
  __typename?: 'workflow_contracts_aggregate';
  aggregate?: Maybe<Workflow_Contracts_Aggregate_Fields>;
  nodes: Array<Workflow_Contracts>;
};

export type Workflow_Contracts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workflow_Contracts_Aggregate_Bool_Exp_Count>;
};

export type Workflow_Contracts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workflow_Contracts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workflow_contracts" */
export type Workflow_Contracts_Aggregate_Fields = {
  __typename?: 'workflow_contracts_aggregate_fields';
  avg?: Maybe<Workflow_Contracts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workflow_Contracts_Max_Fields>;
  min?: Maybe<Workflow_Contracts_Min_Fields>;
  stddev?: Maybe<Workflow_Contracts_Stddev_Fields>;
  stddev_pop?: Maybe<Workflow_Contracts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workflow_Contracts_Stddev_Samp_Fields>;
  sum?: Maybe<Workflow_Contracts_Sum_Fields>;
  var_pop?: Maybe<Workflow_Contracts_Var_Pop_Fields>;
  var_samp?: Maybe<Workflow_Contracts_Var_Samp_Fields>;
  variance?: Maybe<Workflow_Contracts_Variance_Fields>;
};


/** aggregate fields of "workflow_contracts" */
export type Workflow_Contracts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workflow_Contracts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workflow_contracts" */
export type Workflow_Contracts_Aggregate_Order_By = {
  avg?: InputMaybe<Workflow_Contracts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workflow_Contracts_Max_Order_By>;
  min?: InputMaybe<Workflow_Contracts_Min_Order_By>;
  stddev?: InputMaybe<Workflow_Contracts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workflow_Contracts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workflow_Contracts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workflow_Contracts_Sum_Order_By>;
  var_pop?: InputMaybe<Workflow_Contracts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workflow_Contracts_Var_Samp_Order_By>;
  variance?: InputMaybe<Workflow_Contracts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "workflow_contracts" */
export type Workflow_Contracts_Arr_Rel_Insert_Input = {
  data: Array<Workflow_Contracts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workflow_Contracts_On_Conflict>;
};

/** aggregate avg on columns */
export type Workflow_Contracts_Avg_Fields = {
  __typename?: 'workflow_contracts_avg_fields';
  contract_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Avg_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workflow_contracts". All fields are combined with a logical 'AND'. */
export type Workflow_Contracts_Bool_Exp = {
  Contract?: InputMaybe<Contracts_Bool_Exp>;
  Workflow?: InputMaybe<StateMachines_Bool_Exp>;
  _and?: InputMaybe<Array<Workflow_Contracts_Bool_Exp>>;
  _not?: InputMaybe<Workflow_Contracts_Bool_Exp>;
  _or?: InputMaybe<Array<Workflow_Contracts_Bool_Exp>>;
  contract_id?: InputMaybe<Int_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  workflow_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "workflow_contracts" */
export enum Workflow_Contracts_Constraint {
  /** unique or primary key constraint on columns "contract_id" */
  WorkflowContractsPkey = 'workflow_contracts_pkey'
}

/** input type for incrementing numeric columns in table "workflow_contracts" */
export type Workflow_Contracts_Inc_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "workflow_contracts" */
export type Workflow_Contracts_Insert_Input = {
  Contract?: InputMaybe<Contracts_Obj_Rel_Insert_Input>;
  Workflow?: InputMaybe<StateMachines_Obj_Rel_Insert_Input>;
  contract_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Workflow_Contracts_Max_Fields = {
  __typename?: 'workflow_contracts_max_fields';
  contract_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Max_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workflow_Contracts_Min_Fields = {
  __typename?: 'workflow_contracts_min_fields';
  contract_id?: Maybe<Scalars['Int']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Min_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workflow_contracts" */
export type Workflow_Contracts_Mutation_Response = {
  __typename?: 'workflow_contracts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workflow_Contracts>;
};

/** input type for inserting object relation for remote table "workflow_contracts" */
export type Workflow_Contracts_Obj_Rel_Insert_Input = {
  data: Workflow_Contracts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workflow_Contracts_On_Conflict>;
};

/** on_conflict condition type for table "workflow_contracts" */
export type Workflow_Contracts_On_Conflict = {
  constraint: Workflow_Contracts_Constraint;
  update_columns?: Array<Workflow_Contracts_Update_Column>;
  where?: InputMaybe<Workflow_Contracts_Bool_Exp>;
};

/** Ordering options when selecting data from "workflow_contracts". */
export type Workflow_Contracts_Order_By = {
  Contract?: InputMaybe<Contracts_Order_By>;
  Workflow?: InputMaybe<StateMachines_Order_By>;
  contract_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workflow_contracts */
export type Workflow_Contracts_Pk_Columns_Input = {
  contract_id: Scalars['Int'];
};

/** select columns of table "workflow_contracts" */
export enum Workflow_Contracts_Select_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  WorkflowId = 'workflow_id'
}

/** input type for updating data in table "workflow_contracts" */
export type Workflow_Contracts_Set_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Workflow_Contracts_Stddev_Fields = {
  __typename?: 'workflow_contracts_stddev_fields';
  contract_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Stddev_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workflow_Contracts_Stddev_Pop_Fields = {
  __typename?: 'workflow_contracts_stddev_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Stddev_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workflow_Contracts_Stddev_Samp_Fields = {
  __typename?: 'workflow_contracts_stddev_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Stddev_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workflow_contracts" */
export type Workflow_Contracts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Contracts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workflow_Contracts_Stream_Cursor_Value_Input = {
  contract_id?: InputMaybe<Scalars['Int']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Workflow_Contracts_Sum_Fields = {
  __typename?: 'workflow_contracts_sum_fields';
  contract_id?: Maybe<Scalars['Int']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Sum_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** update columns of table "workflow_contracts" */
export enum Workflow_Contracts_Update_Column {
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  WorkflowId = 'workflow_id'
}

export type Workflow_Contracts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workflow_Contracts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workflow_Contracts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workflow_Contracts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workflow_Contracts_Var_Pop_Fields = {
  __typename?: 'workflow_contracts_var_pop_fields';
  contract_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Var_Pop_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workflow_Contracts_Var_Samp_Fields = {
  __typename?: 'workflow_contracts_var_samp_fields';
  contract_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Var_Samp_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workflow_Contracts_Variance_Fields = {
  __typename?: 'workflow_contracts_variance_fields';
  contract_id?: Maybe<Scalars['Float']>;
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workflow_contracts" */
export type Workflow_Contracts_Variance_Order_By = {
  contract_id?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** Join table between workflows (state_machines) and departments. */
export type Workflow_Departments = {
  __typename?: 'workflow_departments';
  /** An object relationship */
  Department?: Maybe<Departments>;
  /** An object relationship */
  StateMachine: StateMachines;
  department_id: Scalars['uuid'];
  tracingspancontext?: Maybe<Scalars['String']>;
  workflow_id: Scalars['Int'];
};

/** aggregated selection of "workflow_departments" */
export type Workflow_Departments_Aggregate = {
  __typename?: 'workflow_departments_aggregate';
  aggregate?: Maybe<Workflow_Departments_Aggregate_Fields>;
  nodes: Array<Workflow_Departments>;
};

export type Workflow_Departments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workflow_Departments_Aggregate_Bool_Exp_Count>;
};

export type Workflow_Departments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workflow_Departments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workflow_departments" */
export type Workflow_Departments_Aggregate_Fields = {
  __typename?: 'workflow_departments_aggregate_fields';
  avg?: Maybe<Workflow_Departments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workflow_Departments_Max_Fields>;
  min?: Maybe<Workflow_Departments_Min_Fields>;
  stddev?: Maybe<Workflow_Departments_Stddev_Fields>;
  stddev_pop?: Maybe<Workflow_Departments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workflow_Departments_Stddev_Samp_Fields>;
  sum?: Maybe<Workflow_Departments_Sum_Fields>;
  var_pop?: Maybe<Workflow_Departments_Var_Pop_Fields>;
  var_samp?: Maybe<Workflow_Departments_Var_Samp_Fields>;
  variance?: Maybe<Workflow_Departments_Variance_Fields>;
};


/** aggregate fields of "workflow_departments" */
export type Workflow_Departments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workflow_Departments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workflow_departments" */
export type Workflow_Departments_Aggregate_Order_By = {
  avg?: InputMaybe<Workflow_Departments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workflow_Departments_Max_Order_By>;
  min?: InputMaybe<Workflow_Departments_Min_Order_By>;
  stddev?: InputMaybe<Workflow_Departments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workflow_Departments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workflow_Departments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workflow_Departments_Sum_Order_By>;
  var_pop?: InputMaybe<Workflow_Departments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workflow_Departments_Var_Samp_Order_By>;
  variance?: InputMaybe<Workflow_Departments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "workflow_departments" */
export type Workflow_Departments_Arr_Rel_Insert_Input = {
  data: Array<Workflow_Departments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workflow_Departments_On_Conflict>;
};

/** aggregate avg on columns */
export type Workflow_Departments_Avg_Fields = {
  __typename?: 'workflow_departments_avg_fields';
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workflow_departments" */
export type Workflow_Departments_Avg_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workflow_departments". All fields are combined with a logical 'AND'. */
export type Workflow_Departments_Bool_Exp = {
  Department?: InputMaybe<Departments_Bool_Exp>;
  StateMachine?: InputMaybe<StateMachines_Bool_Exp>;
  _and?: InputMaybe<Array<Workflow_Departments_Bool_Exp>>;
  _not?: InputMaybe<Workflow_Departments_Bool_Exp>;
  _or?: InputMaybe<Array<Workflow_Departments_Bool_Exp>>;
  department_id?: InputMaybe<Uuid_Comparison_Exp>;
  tracingspancontext?: InputMaybe<String_Comparison_Exp>;
  workflow_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "workflow_departments" */
export enum Workflow_Departments_Constraint {
  /** unique or primary key constraint on columns "department_id", "workflow_id" */
  WorkflowDepartmentsPkey = 'workflow_departments_pkey'
}

/** input type for incrementing numeric columns in table "workflow_departments" */
export type Workflow_Departments_Inc_Input = {
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "workflow_departments" */
export type Workflow_Departments_Insert_Input = {
  Department?: InputMaybe<Departments_Obj_Rel_Insert_Input>;
  StateMachine?: InputMaybe<StateMachines_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Workflow_Departments_Max_Fields = {
  __typename?: 'workflow_departments_max_fields';
  department_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "workflow_departments" */
export type Workflow_Departments_Max_Order_By = {
  department_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workflow_Departments_Min_Fields = {
  __typename?: 'workflow_departments_min_fields';
  department_id?: Maybe<Scalars['uuid']>;
  tracingspancontext?: Maybe<Scalars['String']>;
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "workflow_departments" */
export type Workflow_Departments_Min_Order_By = {
  department_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workflow_departments" */
export type Workflow_Departments_Mutation_Response = {
  __typename?: 'workflow_departments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workflow_Departments>;
};

/** on_conflict condition type for table "workflow_departments" */
export type Workflow_Departments_On_Conflict = {
  constraint: Workflow_Departments_Constraint;
  update_columns?: Array<Workflow_Departments_Update_Column>;
  where?: InputMaybe<Workflow_Departments_Bool_Exp>;
};

/** Ordering options when selecting data from "workflow_departments". */
export type Workflow_Departments_Order_By = {
  Department?: InputMaybe<Departments_Order_By>;
  StateMachine?: InputMaybe<StateMachines_Order_By>;
  department_id?: InputMaybe<Order_By>;
  tracingspancontext?: InputMaybe<Order_By>;
  workflow_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workflow_departments */
export type Workflow_Departments_Pk_Columns_Input = {
  department_id: Scalars['uuid'];
  workflow_id: Scalars['Int'];
};

/** select columns of table "workflow_departments" */
export enum Workflow_Departments_Select_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  WorkflowId = 'workflow_id'
}

/** input type for updating data in table "workflow_departments" */
export type Workflow_Departments_Set_Input = {
  department_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Workflow_Departments_Stddev_Fields = {
  __typename?: 'workflow_departments_stddev_fields';
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workflow_departments" */
export type Workflow_Departments_Stddev_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workflow_Departments_Stddev_Pop_Fields = {
  __typename?: 'workflow_departments_stddev_pop_fields';
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workflow_departments" */
export type Workflow_Departments_Stddev_Pop_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workflow_Departments_Stddev_Samp_Fields = {
  __typename?: 'workflow_departments_stddev_samp_fields';
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workflow_departments" */
export type Workflow_Departments_Stddev_Samp_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workflow_departments" */
export type Workflow_Departments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Departments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workflow_Departments_Stream_Cursor_Value_Input = {
  department_id?: InputMaybe<Scalars['uuid']>;
  tracingspancontext?: InputMaybe<Scalars['String']>;
  workflow_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Workflow_Departments_Sum_Fields = {
  __typename?: 'workflow_departments_sum_fields';
  workflow_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "workflow_departments" */
export type Workflow_Departments_Sum_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};

/** update columns of table "workflow_departments" */
export enum Workflow_Departments_Update_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Tracingspancontext = 'tracingspancontext',
  /** column name */
  WorkflowId = 'workflow_id'
}

export type Workflow_Departments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workflow_Departments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workflow_Departments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workflow_Departments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workflow_Departments_Var_Pop_Fields = {
  __typename?: 'workflow_departments_var_pop_fields';
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workflow_departments" */
export type Workflow_Departments_Var_Pop_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workflow_Departments_Var_Samp_Fields = {
  __typename?: 'workflow_departments_var_samp_fields';
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workflow_departments" */
export type Workflow_Departments_Var_Samp_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workflow_Departments_Variance_Fields = {
  __typename?: 'workflow_departments_variance_fields';
  workflow_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workflow_departments" */
export type Workflow_Departments_Variance_Order_By = {
  workflow_id?: InputMaybe<Order_By>;
};
