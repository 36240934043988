<form [formGroup]="jobsService.jobFilterForm">
  <div class="custom-searchbar">
    <agile-form-input
      style="width: 100%"
      [labelName]="'Search Jobs'"
      [placeholder]="'Search Jobs...'"
      formControlName="searchValue"
    ></agile-form-input>
  </div>

  <div class="filter-container">
    <agile-select-foo
      [items]="locations"
      style="width: 100%"
      [multiple]="true"
      [bindValue]="'name'"
      [labelName]="'Location'"
      [placeholder]="'Select Location...'"
      [bindLabel]="'name'"
      [fillWidth]="true"
      placeholder="All Locations"
      [clearable]="false"
      data-cy="jobs-filter-location-select"
      [closeOnSelect]="true"
      class="job-filter"
      formControlName="location"
    >
    </agile-select-foo>
    <agile-select-foo
      [items]="clearances"
      [multiple]="true"
      style="width: 100%"
      [labelName]="'Clearances'"
      [bindValue]="'name'"
      [placeholder]="'Select Clearance...'"
      [bindLabel]="'name'"
      placeholder="All Clearances"
      [clearable]="false"
      data-cy="jobs-filter-clearance-select"
      [closeOnSelect]="true"
      [fillWidth]="true"
      class="job-filter"
      formControlName="security_clearance"
    >
    </agile-select-foo>
    <agile-select-foo
      [items]="categories"
      style="width: 100%"
      queryName="SecurityClearances"
      [multiple]="true"
      [labelName]="'Category'"
      [placeholder]="'Select Category...'"
      [bindValue]="'name'"
      [bindLabel]="'name'"
      data-cy="jobs-filter-category-select"
      placeholder="All Categories"
      [clearable]="false"
      [closeOnSelect]="true"
      [fillWidth]="true"
      class="job-filter"
      formControlName="category"
    >
    </agile-select-foo>
    <agile-select-foo
      [items]="jobTypes"
      style="width: 100%"
      queryName="SecurityClearances"
      [placeholder]="'Select Job Type...'"
      [multiple]="true"
      [bindValue]="'name'"
      [labelName]="'Job Type'"
      [bindLabel]="'name'"
      data-cy="jobs-filter-type-select"
      placeholder="All Jobs Types"
      [clearable]="false"
      [closeOnSelect]="true"
      [fillWidth]="true"
      class="job-filter"
      formControlName="type"
    >
    </agile-select-foo>
    <agile-select-foo
      [items]="availability"
      style="width: 100%"
      [multiple]="true"
      [bindValue]="'id'"
      [placeholder]="'Select Job Availability...'"
      [labelName]="'Job Availability'"
      [bindLabel]="'name'"
      data-cy="jobs-filter-type-select"
      placeholder="Job Availability"
      [clearable]="false"
      [closeOnSelect]="true"
      [fillWidth]="true"
      class="job-filter"
      formControlName="job_availability_id"
    >
    </agile-select-foo>
  </div>
  <mat-divider></mat-divider>
</form>
