<button
  *ngIf="!jobIdFromRoute"
  id="application-form-close-button"
  mat-icon-button
  color="basic"
  class="details-title-icon"
  (click)="cancelApplication()"
  data-cy="application-form-close-button"
  matTooltip="Return to Jobs List"
  matStepperPrevious
>
  <mat-icon style="color: #c43939">close</mat-icon>
</button>
<form [formGroup]="appForm">
  <div class="application-form" [ngClass]="{ 'application-embed': jobIdFromRoute }">
    <div class="application-form-header">
      <h2 class="my-0">Application Form</h2>
      <p class="job-title" data-cy="application-form-job-title">
        {{ indeed_opt_in ? jobsService.selectedJob?.publishable_title : jobsService.selectedJob?.title }}
      </p>
      <div class="details-title-icon-container">
        <ao-button type="cta-outline" (click)="returnToDetails()" text="Back"> Back </ao-button>
      </div>
      <div class="required-text-app-form">
        <p class="required-text"><span class="required text-left">*</span> REQUIRED</p>
      </div>
    </div>
    <mat-divider style="margin-bottom: 15px"></mat-divider>

    <mat-accordion>
      <!-- SECTION: Upload Resume -->

      <mat-expansion-panel label="Resume Upload" [expanded]="currentStep === 0" (opened)="setStep(0)">
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong>Resume Upload</strong
            ><mat-icon [ngClass]="this.form.bucket_key.invalid ? 'incomplete' : 'complete'">{{
              this.form.bucket_key.invalid ? 'check_circle_outline' : 'check_circle'
            }}</mat-icon></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <agile-resume-upload
            (resumeIngestStatus)="handleResumeParseCompletion($event)"
          ></agile-resume-upload>
          <agile-input-error
            [isRequired]="formHasErrors && form.bucket_key.errors?.required"
            labelName="Resume"
            dataCy="resume"
          ></agile-input-error>
        </div>

        <div class="mat-step-next">
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: Applicant Information -->

      <mat-expansion-panel
        label="Applicant Information"
        [expanded]="currentStep === 1"
        (opened)="setStep(1)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong>Applicant Information</strong
            ><mat-icon
              [ngClass]="
                this.form.name_first.invalid ||
                this.form.name_last.invalid ||
                this.form.mobile_phone.invalid ||
                this.form.personal_email.invalid ||
                this.form.city.invalid ||
                this.form.state.invalid ||
                this.form.zip.invalid
                  ? 'incomplete'
                  : 'complete'
              "
              >{{
                this.form.name_first.invalid ||
                this.form.name_last.invalid ||
                this.form.mobile_phone.invalid ||
                form.mobile_phone?.errors?.invalidPhoneNumber ||
                this.form.personal_email.invalid ||
                this.form.city.invalid ||
                this.form.state.invalid ||
                this.form.zip.invalid
                  ? 'check_circle_outline'
                  : 'check_circle'
              }}</mat-icon
            ></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <div class="form-row name_first_last">
            <agile-form-input
              class="form-input"
              [required]="true"
              labelName="First Name"
              placeholder="Enter First Name..."
              formControlName="name_first"
              dataCy="first-name-input"
              [requiredCondition]="
                (formHasErrors || !form.name_first.pristine) && form.name_first.errors?.required
              "
            ></agile-form-input>
            <agile-form-input
              class="form-input"
              [required]="false"
              labelName="Middle Name"
              placeholder="Enter Middle Name..."
              formControlName="name_middle"
              dataCy="middle-name-input"
              [requiredCondition]="
                (formHasErrors || form.name_middle.pristine) && form.name_middle.errors?.required
              "
            ></agile-form-input>
            <agile-form-input
              class="form-input"
              labelName="Last Name"
              placeholder="Enter Last Name..."
              formControlName="name_last"
              dataCy="last-name-input"
              [required]="true"
              [requiredCondition]="
                (formHasErrors || !form.name_last.pristine) && form.name_last.errors?.required
              "
            ></agile-form-input>
          </div>

          <div class="form-row email_phone">
            <agile-form-input
              class="form-input"
              inputType="email"
              labelName="Email"
              placeholder="Enter Email..."
              formControlName="personal_email"
              dataCy="email-input"
              [required]="true"
              [invalidCondition]="
                (formHasErrors || !form.personal_email.pristine) && form.personal_email.errors?.email
              "
              [requiredCondition]="
                (formHasErrors || !form.personal_email.pristine) && form.personal_email.errors?.required
              "
            ></agile-form-input>
            <agile-form-input
              class="form-input"
              inputType="tel"
              labelName="Phone"
              placeholder="Enter Phone Number..."
              formControlName="mobile_phone"
              dataCy="phone-input"
              [required]="true"
              [invalidCondition]="
                (formHasErrors || !form.mobile_phone.pristine) &&
                form.mobile_phone?.errors?.invalidPhoneNumber
              "
              [requiredCondition]="
                (formHasErrors || !form.mobile_phone.pristine) && form.mobile_phone.errors?.required
              "
            ></agile-form-input>
          </div>

          <div class="form-row cityStateZip">
            <agile-form-input
              id="street"
              class="form-input"
              labelName="Address"
              placeholder="Enter Address..."
              formControlName="street"
              dataCy="street-input"
              [required]="true"
              [requiredCondition]="
                (formHasErrors || !form.street.pristine) && form.street.errors?.required
              "
            ></agile-form-input>
          </div>

          <div class="form-row cityStateZip">
            <agile-form-input
              id="city"
              class="form-input"
              labelName="City"
              placeholder="Enter City..."
              formControlName="city"
              dataCy="city-input"
              [required]="true"
              [requiredCondition]="(formHasErrors || !form.city.pristine) && form.city.errors?.required"
            ></agile-form-input>
            <div style="width: 100%; display: flex; flex-direction: column">
              <agile-select-foo
                id="state"
                [items]="usStates"
                [required]="true"
                style="width: 100%"
                [labelName]="'State'"
                [placeholder]="'Select State...'"
                [multiple]="false"
                [bindValue]="'name'"
                [bindLabel]="'name'"
                formControlName="state"
                [placeholder]="'Select State'"
                data-cy="state-select"
                [dataCy]="'usStates'"
                [fillWidth]="true"
                [clearable]="false"
                [closeOnSelect]="true"
                [invalid]="(formHasErrors || !form.state.pristine) && form.state.errors?.required"
              >
              </agile-select-foo>
            </div>
            <agile-form-input
              id="zipCode"
              class="form-input"
              placeholder="Enter Zip Code..."
              labelName="Zip Code"
              formControlName="zip"
              dataCy="zip-input"
              [required]="true"
              [requiredCondition]="(formHasErrors || !form.zip.pristine) && form.zip.errors?.required"
            ></agile-form-input>
          </div>

          <div class="form-row linkedIn_refferedBy">
            <agile-form-input
              class="form-input"
              [required]="false"
              labelName="LinkedIn URL"
              placeholder="Enter LinkedIn URL..."
              formControlName="linkedin_url"
              dataCy="linkedin-input"
              [invalidCondition]="
                (formHasErrors || !form.linkedin_url.pristine) && form.linkedin_url.invalid
              "
            ></agile-form-input>

            <agile-form-input
              class="form-input"
              [required]="false"
              labelName="Referred By"
              placeholder="Enter Referred By..."
              formControlName="referred_by"
              dataCy="referred-by-input"
              [invalidCondition]="
                (formHasErrors || !form.referred_by.pristine) && form.referred_by.invalid
              "
            ></agile-form-input>
          </div>
        </div>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: Security Clearance Information -->

      <mat-expansion-panel
        label="Security Clearance Information"
        [expanded]="currentStep === 2"
        (opened)="setStep(2)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong>Security Clearance Information</strong
            ><mat-icon [ngClass]="this.form.clearance.invalid ? 'incomplete' : 'complete'">{{
              this.form.clearance.invalid ? 'check_circle_outline' : 'check_circle'
            }}</mat-icon></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <p>
            U.S. citizenship is a basic security clearance eligibility requirement. As outlined in
            Executive Order 12968, Access to Classified Information, eligibility for access to classified
            information may only be granted to employees who are United States citizens.
          </p>
          <div class="form-row securityClearance">
            <div class="form-input">
              <p>
                We are unable to sponsor candidates for a U.S. Security Clearance. If you are applying
                for a position that requires a U.S. Security Clearance, please provide the level of U.S.
                Security Clearance that you have today.
              </p>
            </div>
            <div class="form-input">
              <agile-select-foo
                [items]="clearances"
                [placeholder]="'Select Security Clearance...'"
                [multiple]="false"
                [labelName]="'Security Clearance'"
                bindValue="value"
                bindLabel="text"
                formControlName="clearance_id"
                placeholder="Select Security Clearance"
                data-cy="application-form-clearance-select"
                [dataCy]="'securityClearance'"
                [fillWidth]="true"
                [clearable]="false"
                [required]="true"
                [closeOnSelect]="true"
                [invalid]="(formHasErrors || !form.clearance.pristine) && form.clearance.invalid"
                (valueChange)="setClearance($event)"
              ></agile-select-foo>
            </div>
          </div>
        </div>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: Employment Eligibility Information -->

      <mat-expansion-panel
        label="Employment Eligibility Information"
        [expanded]="currentStep === 3"
        (opened)="setStep(3)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong>Employment Eligibility Information</strong
            ><mat-icon
              [ngClass]="
                this.form.authorized.invalid || this.form.visa_required.invalid
                  ? 'incomplete'
                  : 'complete'
              "
              >{{
                this.form.authorized.invalid || this.form.visa_required.invalid
                  ? 'check_circle_outline'
                  : 'check_circle'
              }}</mat-icon
            ></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <div class="form-row EEI">
            <div class="form-input usAuthorization">
              <p>
                Are you authorized to work in the United States for any employer?
                <span class="required">*</span>
              </p>
              <mat-button-toggle-group [formControl]="appForm?.controls?.authorized">
                <mat-button-toggle [value]="true" data-cy="application-form-auth-yes-toggle"
                  >Yes</mat-button-toggle
                >
                <mat-button-toggle [value]="false" data-cy="application-form-auth-no-toggle"
                  >No</mat-button-toggle
                >
              </mat-button-toggle-group>
              <agile-input-error
                [isRequired]="formHasErrors && form.authorized.errors?.required"
                labelName="Work Authorization status"
                dataCy="auth"
              ></agile-input-error>
            </div>
            <div class="form-input sponsorshipSelector">
              <p>
                Will you now or in the future require visa sponsorship?
                <span class="required">*</span>
              </p>
              <mat-button-toggle-group [formControl]="appForm?.controls?.visa_required">
                <mat-button-toggle [value]="true" data-cy="application-form-visa-yes-toggle"
                  >Yes</mat-button-toggle
                >
                <mat-button-toggle [value]="false" data-cy="application-form-visa-no-toggle"
                  >No</mat-button-toggle
                >
              </mat-button-toggle-group>
              <agile-input-error
                [isRequired]="formHasErrors && form.visa_required.errors?.required"
                labelName="Visa Requirement status"
                dataCy="visa"
              ></agile-input-error>
            </div>
          </div>
        </div>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: Questionnaire -->

      <mat-expansion-panel
        label="Questionnaire"
        [expanded]="currentStep === 4"
        (opened)="setStep(4)"
        *ngIf="
          jobsService.selectedJob?.questionnaire_id &&
          jobsService.selectedJob?.include_questionnaire_on_application
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong>Questionnaire</strong
            ><mat-icon
              [ngClass]="
                this.form?.questionnaire_result?.invalid || this.form.visa_required.invalid
                  ? 'incomplete'
                  : 'complete'
              "
              >{{
                this.form?.questionnaire_result?.invalid || this.form.visa_required.invalid
                  ? 'check_circle_outline'
                  : 'check_circle'
              }}</mat-icon
            ></mat-panel-title
          >
        </mat-expansion-panel-header>
        <survey [model]="surveyModel"></survey>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: Affirmation -->

      <mat-expansion-panel
        label="Affirmation"
        [expanded]="
          jobsService.selectedJob?.questionnaire_id &&
          jobsService.selectedJob?.include_questionnaire_on_application
            ? currentStep === 5
            : currentStep === 4
        "
        (opened)="
          setStep(
            jobsService.selectedJob?.questionnaire_id &&
              jobsService.selectedJob?.include_questionnaire_on_application
              ? 5
              : 4
          )
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong>Affirmation</strong
            ><mat-icon
              [ngClass]="
                this.form.affirmation_signature.invalid ||
                this.form.gov_employment_status.invalid ||
                (this.form.gov_contract_participant.invalid && isGovParticipationStatusRequired)
                  ? 'incomplete'
                  : 'complete'
              "
              >{{
                this.form.affirmation_signature.invalid ||
                this.form.gov_employment_status.invalid ||
                (this.form.gov_contract_participant.invalid && isGovParticipationStatusRequired)
                  ? 'check_circle_outline'
                  : 'check_circle'
              }}</mat-icon
            ></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <p>
            I understand that {{ jobsService.company?.name }} is an "at-will" employer, meaning that my
            employment has no specified term and that the employment relationship may be terminated any
            time at the will of either party on notice to the other. By signing below, I certify that all
            the information on this application is accurate to the best of my knowledge, and that I
            understand that intentional misrepresentation of any information may invalidate this
            application and may result in termination of subsequent employment with
            {{ jobsService.company?.name }}. {{ jobsService.company?.name }} reserves the right to
            conduct background investigations, contact references and verify information provided on the
            application and/or resume. By typing my name below, I give my consent and authorization for
            {{ jobsService.company?.name }} and its designated employees and consultants to collect and
            use the information I submit in considering me for job opportunities at
            {{ jobsService.company?.name }}.
          </p>

          <agile-form-input
            class="form-input"
            labelName="Signature"
            placeholder="Enter First and Last Name (must match the values you entered above in the 'applicant information' section)"
            formControlName="affirmation_signature"
            dataCy="affirmation-signature-input"
            [requiredCondition]="
              (formHasErrors || !form.affirmation_signature.pristine) &&
              form.affirmation_signature.errors?.required
            "
            [invalidCondition]="
              (formHasErrors || !form.affirmation_signature.pristine) &&
              form.affirmation_signature.errors?.required
            "
          ></agile-form-input>

          <div class="form-input">
            <p>
              Are you a current or former employee of any government (including any governmental entities
              at the federal/national, state/provincial, and local levels and including publicly funded
              institutions)?<span class="required">*</span>
            </p>
            <mat-button-toggle-group [formControl]="appForm?.controls?.gov_employment_status" vertical>
              <mat-button-toggle
                value="Never Employed"
                data-cy="application-form-gov-employment-never-toggle"
                (click)="onNeverEmployedClicked()"
              >
                I was never a government employee
              </mat-button-toggle>
              <mat-button-toggle
                value="Former Employee"
                data-cy="application-form-gov-employment-former-toggle"
                (click)="onCurrentEmployeeClicked()"
              >
                I am a former government employee
              </mat-button-toggle>
              <mat-button-toggle
                value="Current Employee"
                data-cy="application-form-gov-employment-current-toggle"
                (click)="onCurrentEmployeeClicked()"
              >
                I am a current government employee
              </mat-button-toggle>
            </mat-button-toggle-group>
            <agile-input-error
              [isRequired]="formHasErrors && form.gov_employment_status.errors?.required"
              labelName="government employment history"
              dataCy="gov-employment"
            ></agile-input-error>
          </div>
          <div class="form-input">
            <p>
              If you are a current or former government employee, did you participate in any stage of a
              procurement or contract for any
              {{ jobsService.company?.name }}-related (to include
              {{ jobsService.company?.name }} subsidiaries) matter?<span
                class="required"
                *ngIf="isGovParticipationStatusRequired"
                >*</span
              >
            </p>
            <mat-button-toggle-group [formControl]="appForm?.controls?.gov_contract_participant">
              <mat-button-toggle [value]="true" data-cy="application-form-gov-participant-yes-toggle">
                Yes
              </mat-button-toggle>
              <mat-button-toggle [value]="false" data-cy="application-form-gov-participant-no-toggle">
                No
              </mat-button-toggle>
            </mat-button-toggle-group>
            <agile-input-error
              [isRequired]="
                isGovParticipationStatusRequired &&
                formHasErrors &&
                form.gov_contract_participant.errors?.required
              "
              labelName="government contracting/procurement history"
              dataCy="gov-employment"
            ></agile-input-error>
          </div>
        </div>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: Authorization and Acknowledgment -->
      <mat-expansion-panel
        label="Authorization and Acknowledgment"
        [expanded]="
          jobsService.selectedJob?.questionnaire_id &&
          jobsService.selectedJob?.include_questionnaire_on_application
            ? currentStep === 6
            : currentStep === 5
        "
        (opened)="
          setStep(
            jobsService.selectedJob?.questionnaire_id &&
              jobsService.selectedJob?.include_questionnaire_on_application
              ? 6
              : 5
          )
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong>Authorization and Acknowledgment</strong
            ><mat-icon [ngClass]="!form.acknowledgement.value ? 'incomplete' : 'complete'">{{
              !form.acknowledgement.value ? 'check_circle_outline' : 'check_circle'
            }}</mat-icon></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <p>
            I certify and affirm that the information provided by me during interviews, on this
            application and all other related documents to be true in all respects and I further
            understand that any misrepresentation or omission will be grounds for immediate termination
            from further consideration or possible employment. I understand that neither the completion
            of this application nor any other part of my consideration for employment establishes any
            obligation for {{ jobsService.company?.name }} to hire me. If I am hired, I understand that
            either {{ jobsService.company?.name }} or I can terminate my employment at any time and for
            any reason, with or without cause and without prior notice. I understand that no
            representative of {{ jobsService.company?.name }} has the authority to make any assurance to
            the contrary. I understand and agree that that any and all claims or disputes that I may have
            with {{ jobsService.company?.name }}, arising out of or related to my application for
            employment, any employment, or any separation from employment, will be resolved exclusively
            by final and binding arbitration. This provision shall be construed as broadly as possible
            and any claims which I may have against the company arising from the employment relationship,
            including discrimination claims under Title VII, the Age Discrimination in Employment Act,
            Americans with Disabilities Act, or any other federal, state or local law prohibiting
            discrimination, claims under the Fair Labor Standards Act, claims based upon contract or
            tort, claims arising out of my employment which I may assert against individuals, and all
            questions of arbitrability, shall be conclusively decided by the arbitrator. Arbitration
            proceedings shall be conducted in accordance with the Rules of the American Arbitration
            Association. I understand that my agreement to binding arbitration is an express condition
            precedent to my employment with {{ jobsService.company?.name }}. Should I be extended an
            offer of employment, my acceptance of such an offer will reaffirm my agreement to final and
            binding arbitration for the resolution of any and all claims. This is a written agreement to
            arbitrate, binding upon me as well as {{ jobsService.company?.name }}, and it shall be
            enforceable pursuant to the provisions of the Federal Arbitration Act, and the Virginia
            Uniform Arbitration Act. If this application leads to employment, I understand that false or
            misleading information in my application or interview may result in my release.
          </p>

          <mat-checkbox formControlName="acknowledgement" style="display: flex">
            I Have Read and Agree to the Terms
          </mat-checkbox>
          <agile-input-error
            [isRequired]="formHasErrors && form.acknowledgement.errors?.required"
            labelName="acknowledgement"
            dataCy="acknowledgement"
          ></agile-input-error>
        </div>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: U.S. Equal Opportunity Employment Information -->
      <mat-expansion-panel
        label="Equal Opportunity Employment Information"
        [expanded]="
          jobsService.selectedJob?.questionnaire_id &&
          jobsService.selectedJob?.include_questionnaire_on_application
            ? currentStep === 7
            : currentStep === 6
        "
        (opened)="
          setStep(
            jobsService.selectedJob?.questionnaire_id &&
              jobsService.selectedJob?.include_questionnaire_on_application
              ? 7
              : 6
          )
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong
              >Equal Opportunity Employment Information
              <small class="text-muted">&nbsp;&nbsp;OPTIONAL</small></strong
            ></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <p>
            {{ jobsService.company?.name }} is an Equal Opportunity Employer, and selects the best
            matched individual for each position based on job related criteria and without regard to
            race, color, sex, citizen status, national origin, religion, marital status, sexual
            orientation, age, disability, veteran status, or any other protected status as defined in
            local, state, or federal fair employment guidelines. Statements made by applicants are
            subject to careful review for accuracy. Any misrepresentation or omission will result in
            disqualification from further consideration for employment or discharge from employment if
            discovered after the individual's hire date. This application will remain in an active file
            for one year. If you require reasonable accommodation to perform the essential functions of
            the job for which you are applying, please contact Human Resources.
          </p>
          <p>
            Completion of the form is entirely voluntary. Whatever your decision, it will not be
            considered in the hiring process or thereafter. Any information that you do provide will be
            recorded and maintained in a confidential file.
          </p>

          <p>
            <strong>Gender & Race</strong>
            <small class="section-button" (click)="showRaceDefs = !showRaceDefs">
              {{ showRaceDefs ? 'Hide' : 'View' }} Race & Ethnicity Definitions
            </small>
          </p>

          <p class="details-text" [ngClass]="{ showDetails: showRaceDefs }">
            <strong>Hispanic or Latino</strong> - A person of Cuban, Mexican, Puerto Rican, South or
            Central American, or other Spanish culture or origin regardless of race. <br /><br />
            <strong>White (Not Hispanic or Latino)</strong> - A person having origins in any of the
            original peoples of Europe, the Middle East, or North Africa. <br /><br />
            <strong>Black or African American (Not Hispanic or Latino)</strong>
            - A person having origins in any of the black racial groups of Africa.<br /><br />
            <strong>Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)</strong>
            - A person having origins in any of the peoples of Hawaii, Guam, Samoa, or other Pacific
            Islands. <br /><br />
            <strong>Asian (Not Hispanic or Latino)</strong> - A person having origins in any of the
            original peoples of the Far East, Southeast Asia, or the Indian Subcontinent, including, for
            example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands,
            Thailand, and Vietnam. <br /><br />
            <strong>American Indian or Alaska Native (Not Hispanic or Latino)</strong>
            - A person having origins in any of the original peoples of North and South America
            (including Central America), and who maintain tribal affiliation or community attachment.<br /><br />
            <strong>Two or More Races (Not Hispanic or Latino)</strong> - All persons who identify with
            more than one of the above five races.
          </p>

          <div class="form-row eeoStatus">
            <div class="form-input genderSelector">
              <agile-select-foo
                [items]="genders"
                [labelName]="'Gender'"
                [placeholder]="'Select Gender...'"
                [multiple]="false"
                [bindValue]="'value'"
                formControlName="gender"
                [placeholder]="'Select Gender'"
                data-cy="application-form-clearance-select"
                [fillWidth]="true"
                [clearable]="false"
                [closeOnSelect]="true"
                [invalid]="formHasErrors && form.clearance.invalid"
              ></agile-select-foo>
            </div>
            <div class="form-input raceEthnicity">
              <agile-select-foo
                [items]="races"
                [labelName]="'Race/Ethnicity'"
                [placeholder]="'Select Race/Ethnicity...'"
                [multiple]="false"
                [bindValue]="'value'"
                formControlName="race"
                placeholder="Select Race/Ethnicity"
                data-cy="application-form-clearance-select"
                [fillWidth]="true"
                [clearable]="false"
                [closeOnSelect]="true"
                [invalid]="formHasErrors && form.clearance.invalid"
              ></agile-select-foo>
            </div>
          </div>

          <p>
            <strong>Veteran Status</strong>
            <small class="section-button" (click)="showVetDefs = !showVetDefs"
              >{{ showVetDefs ? 'Hide' : 'View' }} Veteran Status Definitions</small
            >
          </p>

          <p class="details-text" [ngClass]="{ showDetails: showVetDefs }">
            If you believe you belong to any of the categories of protected veterans listed below, please
            indicate by making the appropriate selection. As a government contractor subject to Vietnam
            Era Veterans Readjustment Assistance Act (VEVRAA), we request this information in order to
            measure the effectiveness of the outreach and positive recruitment efforts we undertake
            pursuant to VEVRAA. Classification of protected categories is as follows:<br /><br />

            A <strong>"disabled veteran"</strong> is one of the following: a veteran of the U.S.
            military, ground, naval or air service who is entitled to compensation (or who but for the
            receipt of military retired pay would be entitled to compensation) under laws administered by
            the Secretary of Veterans Affairs; or a person who was discharged or released from active
            duty because of a service-connected disability.<br /><br />

            A <strong>"recently separated veteran"</strong> means any veteran during the three-year
            period beginning on the date of such veteran's discharge or release from active duty in the
            U.S. military, ground, naval, or air service.<br /><br />

            An
            <strong>"active duty wartime or campaign badge veteran"</strong>
            means a veteran who served on active duty in the U.S. military, ground, naval or air service
            during a war, or in a campaign or expedition for which a campaign badge has been authorized
            under the laws administered by the Department of Defense.<br /><br />

            An <strong>"Armed forces service medal veteran"</strong> means a veteran who, while serving
            on active duty in the U.S. military, ground, naval or air service, participated in a United
            States military operation for which an Armed Forces service medal was awarded pursuant to
            Executive Order 12985.<br /><br />
          </p>

          <div class="form-input">
            <agile-select-foo
              [items]="veterans"
              [multiple]="false"
              [labelName]="'Veteran Status'"
              [placeholder]="'Select Veteran Status...'"
              [bindValue]="'value'"
              formControlName="veteran_status"
              placeholder="Select Veteran Status"
              data-cy="application-form-clearance-select"
              [fillWidth]="true"
              [clearable]="false"
              [closeOnSelect]="true"
              [invalid]="formHasErrors && form.clearance.invalid"
            ></agile-select-foo>
          </div>
        </div>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
      <!-- SECTION: Self-Identification of Disability -->
      <mat-expansion-panel
        label="Self-Identification of Disability"
        [expanded]="
          jobsService.selectedJob?.questionnaire_id &&
          jobsService.selectedJob?.include_questionnaire_on_application
            ? currentStep === 8
            : currentStep === 7
        "
        (opened)="
          setStep(
            jobsService.selectedJob?.questionnaire_id &&
              jobsService.selectedJob?.include_questionnaire_on_application
              ? 8
              : 7
          )
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            style="display: flex; align-items: center; width: 100%; justify-content: space-between"
            ><strong
              >Self-Identification of Disability
              <small class="text-muted">&nbsp;&nbsp;OPTIONAL</small></strong
            ></mat-panel-title
          >
        </mat-expansion-panel-header>
        <div body>
          <div class="disability-form-name">
            <p>Form CC-305<br />OMB Control Number 1250-0005<br />Expires 04/30/2026</p>
          </div>

          <div class="disability-form-header">
            <h4><u>Voluntary Self-Identification of Disability</u></h4>
          </div>
          <div>
            <p>
              <strong>Why are you being asked to complete this form?</strong><br /><br />
              We are a federal contractor or subcontractor. The law requires us to provide equal
              employment opportunity to qualified people with disabilities. We have a goal of having at
              least 7% of our workers as people with disabilities. The law says we must measure our
              progress towards this goal. To do this, we must ask applicants and employees if they have a
              disability or have ever had one. People can become disabled, so we need to ask this
              question at least every five years.
              <br /><br />
              Completing this form is voluntary, and we hope that you will choose to do so. Your answer
              is confidential. No one who makes hiring decisions will see it. Your decision to complete
              the form and your answer will not harm you in any way. If you want to learn more about the
              law or this form, visit the U.S. Department of Labor’s Office of Federal Contract
              Compliance Programs (OFCCP) website at
              <a target="_blank" rel="noopener noreferrer" href="https://www.dol.gov/agencies/ofccp">
                www.dol.gov/ofccp.
              </a>
              <br /><br /><strong>How do you know if you have a disability?</strong><br /><br />
              You are considered to have a disability if you have a physical or mental impairment or
              medical condition that substantially limits a major life activity, or if you have a history
              or record of such an impairment or medical condition.<br /><br />
              Disabilities include, but are not limited to:
            </p>

            <div class="disability-form-list">
              <ul>
                <li>Autism</li>
                <li>
                  Autoimmune disorder, for example, lupus, fibromyalgia, rheumatoid arthritis, or
                  HIV/AIDS
                </li>
                <li>Blind or low vision</li>
                <li>Cancer</li>
                <li>Cardiovascular or heart disease</li>
                <li>Celiac disease</li>
                <li>Cerebral palsy</li>
                <li>Deaf or hard of hearing</li>
                <li>Depression or anxiety</li>
                <li>Diabetes</li>
              </ul>
              <ul>
                <li>Epilepsy</li>
                <li>
                  Gastrointestinal disorders, for example, Crohn's Disease, or irritable bowel syndrome
                </li>
                <li>Intellectual disability</li>
                <li>Missing limbs or partially missing limbs</li>
                <li>
                  Nervous system condition for example, migraine headaches, Parkinson’s disease, or
                  Multiple sclerosis (MS)
                </li>
                <li>
                  Psychiatric condition, for example, bipolar disorder, schizophrenia, PTSD, or major
                  depression
                </li>
              </ul>
            </div>

            <p>
              PUBLIC BURDEN STATEMENT: According to the Paperwork Reduction Act of 1995 no persons are
              required to respond to a collection of information unless such collection displays a valid
              OMB control number. This survey should take about 5 minutes to complete.
            </p>
          </div>

          <p>Disability Status</p>
          <mat-button-toggle-group [formControl]="appForm?.controls?.has_disability" vertical>
            <mat-button-toggle
              value="{{ disabilityStatuses.Yes }}"
              data-cy="application-form-disability-yes-toggle"
              >Yes, I have a disability, or have a history/record of having a disability
            </mat-button-toggle>
            <mat-button-toggle
              value="{{ disabilityStatuses.No }}"
              data-cy="application-form-disability-no-toggle"
              >No, I don't have a disability, or a history/record of having a
              disability</mat-button-toggle
            >
            <mat-button-toggle
              value="{{ disabilityStatuses.Declined }}"
              data-cy="application-form-disability-no-answer-toggle"
              >I don't wish to answer</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
        <div class="mat-step-previous-next">
          <ao-button type="cta-outline" (click)="previousStep()" [text]="'Previous'"> </ao-button>
          <ao-button type="cta" (click)="nextStep()" [text]="'Next'"> </ao-button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-divider></mat-divider>

    <div class="submit-button-container">
      <button
        id="submitButton"
        mat-raised-button
        color="primary"
        (click)="submitForm()"
        data-cy="application-form-submit-button"
        [ngClass]="{ spinner: loading }"
        [disabled]="!appForm?.valid"
      >
        Submit Application
      </button>
    </div>
  </div>
</form>
