import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { GetSignedUrlInput, JobApplicationInput } from 'src/generated/graphql';
import { Model } from 'survey-core';
import { CREATE_JOB_APPLICATION, GET_SIGNED_URL } from '../graphql/mutations'; // prettier-ignore
import { GET_COMPANY_ID_BY_SUBDOMAIN, GET_JOBS_BY_IDS, GET_JOB_FILTER_DATA, GET_RESUME_BUCKET_KEY_BY_DATA_HASH, GET_SECURITY_CLEARANCES_EXTERNAL_QUERY } from '../graphql/queries'; // prettier-ignore
import { GET_PUBLISHED_JOBS, GET_PUBLISHED_JOBS_COUNT } from '../graphql/subscriptions'; // prettier-ignore

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  _selectedJob = new BehaviorSubject<any>(null);
  selectedJobQuestionnaire: Model;
  jobFilterForm: UntypedFormGroup;
  applicationForm: UntypedFormGroup;
  limit: BehaviorSubject<number> = new BehaviorSubject(50);
  jobs: BehaviorSubject<any[]> = new BehaviorSubject([]);
  subdomain: string;
  company: any;
  companyId: number = parseInt(localStorage.getItem('companyId'), 10);
  loading = false;
  parsing = false;
  isEmbedded: boolean;

  constructor(
    private apollo: Apollo,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.getCompanyId();
    this.buildJobFilterForm();
  }

  get selectedJob() {
    return this._selectedJob.getValue();
  }

  set selectedJob(job: any) {
    this._selectedJob.next(job);
  }

  async getCompanyId() {
    this.subdomain = this.getSubdomain();
    this.company = await this.getCompanyIdBySubdomain(this.subdomain).toPromise();
    this.titleService.setTitle(`${this.company?.name} | Jobs`);
    this.companyId = this.company?.id;
  }

  getSubdomain(): string {
    return localStorage.getItem('subdomain');
  }

  getCompanyIdBySubdomain(subdomain: string): Observable<any> {
    return this.apollo
      .query<{ Companies: any[] }>({
        query: GET_COMPANY_ID_BY_SUBDOMAIN,
        variables: { subdomain },
      })
      .pipe(map(({ data }) => data.Companies[0]));
  }

  buildJobFilterForm(): void {
    this.jobFilterForm = this.formBuilder.group({
      searchValue: '',
      location: [],
      type: [],
      category: [],
      security_clearance: [],
      job_availability_id: [],
    });
  }

  getPublishedJobs(company) {
    const variables = company?.indeed_opt_in
      ? {
          deleted: { _eq: false },
          published: { _eq: true },
          publishable_title: { _is_null: false, _neq: '' },
          title: { _is_null: false, _neq: '' },
          zip: { _is_null: false, _neq: '' },
          country: { _is_null: false, _neq: '' },
          city: { _is_null: false, _neq: '' },
          status: { _in: ['Open', 'Hot', 'Covered', 'Proposal'] },
          company_id: { _eq: this.companyId },
        }
      : {
          deleted: { _eq: false },
          published: { _eq: true },
          title: { _is_null: false, _neq: '' },
          zip: { _is_null: false, _neq: '' },
          country: { _is_null: false, _neq: '' },
          city: { _is_null: false, _neq: '' },
          status: { _in: ['Open', 'Hot', 'Covered', 'Proposal'] },
          company_id: { _eq: this.companyId },
        };
    return this.apollo
      .query<{ Jobs: any[] }>({
        query: GET_JOB_FILTER_DATA,
        variables: {
          where: {
            ...variables,
          },
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.Jobs;
        }),
      );
  }

  subscribeToPublishedJobs(company): Observable<any[]> {
    return combineLatest([
      this.jobFilterForm.valueChanges.pipe(startWith({}), debounceTime(100)),
      this.limit.asObservable(),
    ]).pipe(
      switchMap(() => {
        this.loading = true;
        const variables = this.company?.indeed_opt_in
          ? {
              deleted: { _eq: false },
              published: { _eq: true },
              zip: { _is_null: false, _neq: '' },
              title: { _is_null: false, _neq: '' },
              publishable_title: { _is_null: false, _neq: '' },
              country: { _is_null: false, _neq: '' },
              city: { _is_null: false, _neq: '' },
              status: { _in: ['Open', 'Hot', 'Covered', 'Proposal'] },
              company_id: { _eq: this.companyId },
              ...this.generateWhereClause(company),
            }
          : {
              deleted: { _eq: false },
              published: { _eq: true },
              zip: { _is_null: false, _neq: '' },
              title: { _is_null: false, _neq: '' },
              country: { _is_null: false, _neq: '' },
              city: { _is_null: false, _neq: '' },
              status: { _in: ['Open', 'Hot', 'Covered', 'Proposal'] },
              company_id: { _eq: this.companyId },
              ...this.generateWhereClause(company),
            };

        return this.apollo
          .watchQuery<{ Jobs: any[] }>({
            query: GET_PUBLISHED_JOBS,
            fetchPolicy: 'cache-and-network',
            variables: {
              limit: this.limit.getValue(),
              where: {
                ...variables,
              },
            },
          })
          .valueChanges.pipe(
            map(({ data }) => {
              this.loading = false;
              return data.Jobs;
            }),
          );
      }),
    );
  }

  subscribeToTotalPublishedJobs(company): Observable<number> {
    return combineLatest([this.jobFilterForm.valueChanges.pipe(startWith({}), debounceTime(300))]).pipe(
      switchMap(() => {
        const variables = this.company?.indeed_opt_in
          ? {
              deleted: { _eq: false },
              published: { _eq: true },
              zip: { _is_null: false, _neq: '' },
              title: { _is_null: false, _neq: '' },
              publishable_title: { _is_null: false, _neq: '' },
              country: { _is_null: false, _neq: '' },
              city: { _is_null: false, _neq: '' },
              status: { _in: ['Open', 'Hot', 'Covered', 'Proposal'] },
              company_id: { _eq: this.companyId },
              ...this.generateWhereClause(company),
            }
          : {
              deleted: { _eq: false },
              published: { _eq: true },
              zip: { _is_null: false, _neq: '' },
              title: { _is_null: false, _neq: '' },
              country: { _is_null: false, _neq: '' },
              city: { _is_null: false, _neq: '' },
              status: { _in: ['Open', 'Hot', 'Covered', 'Proposal'] },
              company_id: { _eq: this.companyId },
              ...this.generateWhereClause(company),
            };

        this.loading = true;
        return this.apollo
          .watchQuery<{ Jobs_aggregate: any }>({
            query: GET_PUBLISHED_JOBS_COUNT,
            fetchPolicy: 'cache-and-network',
            variables: {
              where: {
                ...variables,
              },
            },
          })
          .valueChanges.pipe(
            map(({ data }) => {
              this.loading = false;
              return data?.Jobs_aggregate?.aggregate?.count;
            }),
          );
      }),
    );
  }

  generateWhereClause(company): any {
    const whereClause: any = {};

    for (const [key, value] of Object.entries(this.jobFilterForm?.value)) {
      if (key === 'searchValue') {
        whereClause._or = [];
        const titleKey = company?.indeed_opt_in ? 'publishable_title' : 'title';
        whereClause._or.push({ [titleKey]: { _ilike: `%${value}%` } });
        whereClause._or.push({ description_publishable: { _ilike: `%${value}%` } });
        whereClause._or.push({ required_skills_publishable: { _ilike: `%${value}%` } });
        whereClause._or.push({ desired_skills_publishable: { _ilike: `%${value}%` } });
        continue;
      }

      if ((value as any)?.length) {
        whereClause[key] = { _in: value };
      }
    }

    whereClause.company_id = { _eq: this.companyId };
    if (whereClause.security_clearance) {
      whereClause.security_clearance_publishable = whereClause.security_clearance;
      delete whereClause.security_clearance;
    }

    return whereClause;
  }

  getJobsByIds(jobIds: number[]): Observable<any[]> {
    return this.apollo
      .subscribe<{ Jobs: any[] }>({
        query: GET_JOBS_BY_IDS,
        variables: { jobIds, company_id: this.companyId },
      })
      .pipe(
        map(({ data }) => {
          return data?.Jobs;
        }),
      );
  }

  getSignedUrl(bucketKey: string): Observable<string> {
    const getSignedUrlInput: GetSignedUrlInput = {
      bucketAlias: 'resume',
      bucketKey,
      companyId: this.companyId,
    };
    return this.apollo.mutate({ mutation: GET_SIGNED_URL, variables: { getSignedUrlInput } }).pipe(
      map(({ data }: any) => {
        return data?.getSignedUrl?.signedUrl;
      }),
    );
  }

  createJobApplication(applicant: JobApplicationInput) {
    return this.apollo.mutate({
      mutation: CREATE_JOB_APPLICATION,
      variables: { applicant },
    });
  }

  getResumeBucketKeyByDataHash(data_hash: string): Promise<any> {
    return this.apollo
      .query<{ Resumes: any[] }>({
        query: GET_RESUME_BUCKET_KEY_BY_DATA_HASH,
        variables: { data_hash },
      })
      .pipe(
        map(({ data }) => {
          return data.Resumes[0];
        }),
      )
      .toPromise();
  }

  notify(status: string, message: string, duration = 6000): void {
    this.snackBar.open(message, '', {
      duration,
      horizontalPosition: 'center',
      panelClass: [status],
    });
  }

  getSecurityClearances$() {
    return this.apollo
      .query<{ SecurityClearances: any }>({
        query: GET_SECURITY_CLEARANCES_EXTERNAL_QUERY,
      })
      .pipe(map(({ data }) => data.SecurityClearances));
  }
}
