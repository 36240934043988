import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Renderer2, Output, ViewChild } from '@angular/core'; // prettier-ignore
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { JobsService } from '../../services/jobs.service';
import { BottomSheetService } from 'src/app/services/bottomsheet.service';

@Component({
  selector: 'agile-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  @Input() showIcons = true;
  @Output() nextStepEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() applicationCanceled: EventEmitter<boolean> = new EventEmitter<boolean>();
  copied = false;
  indeed_opt_in: boolean = false;
  moment = moment;
  routed = false;
  jobLink: string;
  private _subs: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public jobsService: JobsService,
    private clipBoard: Clipboard,
    private bottomSheetService: BottomSheetService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.indeed_opt_in = this.jobsService?.company?.indeed_opt_in;

    this.handleParamMap();

    this.jobLink =
      this.jobsService.subdomain === 'lis'
        ? `https://${this.jobsService.subdomain}-careers.services.agileonboarding.com/jobs/details/${this.jobsService.selectedJob?.id}`
        : `https://${this.jobsService.subdomain}-jobs.services.agileonboarding.com/jobs/details/${this.jobsService.selectedJob?.id}`;
  }

  addJsonLdScript(job) {
    const jsonLd = {
      '@context': 'https://schema.org/',
      '@type': 'JobPosting',
      title: this.indeed_opt_in ? job?.publishable_title : job?.title,
      description: job?.description_publishable,
      identifier: {
        '@type': 'PropertyValue',
        name: this.jobsService.company?.name,
        value: job?.id,
      },
      datePosted: job?.published_date,
      validThrough: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString(),
      employmentType: job?.type,
      hiringOrganization: {
        '@type': 'Organization',
        name: this.jobsService.company?.name,
        logo: this.jobsService.company?.logo,
      },
      jobLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          streetAddress: job?.location,
          addressLocality: job?.city,
          addressRegion: job?.state,
          postalCode: job?.zip,
          addressCountry: job?.country,
        },
      },
    };

    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(jsonLd);
    this.renderer.appendChild(document.head, script);
  }
  ngOnDestroy(): void {
    this._subs?.unsubscribe();
  }

  onChangeJob(direction: string, currentJob: any): void {
    const jobs: any[] = this.jobsService?.jobs?.getValue();
    const currentJobIndex: number = jobs?.findIndex((job) => job?.id === currentJob?.id);
    const nextJobIndex: number = direction === 'next' ? currentJobIndex + 1 : currentJobIndex - 1;
    const nextJob: any = jobs[nextJobIndex];
    this.jobsService.selectedJob = nextJob;
  }

  handleParamMap(): void {
    if (this.jobsService.selectedJob) {
      this.getJobById(this.jobsService.selectedJob.id);
    } else {
      this.route.params.subscribe((params: Params) => {
        this.routed = true;
        const jobId = parseInt(params['id'], 10);
        if (jobId) {
          this.getJobById(jobId);
        } else if (!jobId) {
          this.jobsService.notify('error', 'Sorry, this job is no longer available.');
          this.router.navigateByUrl('/jobs');
        }
      });
    }
  }

  getJobById(jobId: number): void {
    this.jobsService.getJobsByIds([jobId]).subscribe(
      (jobs) => {
        if (jobs.length === 0) {
          this.jobsService.notify('error', 'Sorry, this job is no longer available.');
          this.router.navigateByUrl('/jobs');
        } else {
          this.jobsService.selectedJob = jobs[0];
          this.addJsonLdScript(jobs[0]);
        }
        this.indeed_opt_in = this.jobsService?.company?.indeed_opt_in;
      },
      (err) => console.error(err),
    );
  }

  navigateToJob(jobId: number, publishedOn: Date = new Date()): void {
    if (this.jobsService.isEmbedded) {
      this.router.navigate([`jobs-embed/details/${jobId}/${moment(publishedOn).format('YYYY-MM-DD')}`]);
    } else {
      this.router.navigate([`jobs/details/${jobId}/${moment(publishedOn).format('YYYY-MM-DD')}`]);
    }
  }

  applyToJob(): void {
    const jobId = this.jobsService.selectedJob.id;
    const publishedOn = this.jobsService.selectedJob.published_date;
    if (this.jobsService.isEmbedded) {
      this.router
        .navigate([`jobs-embed/details/${jobId}/${moment(publishedOn).format('YYYY-MM-DD')}/1`])
        .then(() => {});
      this.bottomSheetService.closeSheet();
      this.showIcons = false;
    } else {
      this.router.navigate([`jobs/details/${jobId}/${moment(publishedOn).format('YYYY-MM-DD')}/1`]);
      this.bottomSheetService.closeSheet();
      this.showIcons = false;
    }
  }

  nextStep() {
    this.nextStepEvent.emit();
  }

  navigateToList() {
    if (this.jobsService.isEmbedded) {
      this.router.navigate([`jobs-embed`]);
    } else {
      this.router.navigate([`jobs`]);
    }
  }

  isDisabled(currentJob: any, direction: 'next' | 'previous'): boolean {
    const jobs = this.jobsService?.jobs?.getValue();
    if (!jobs?.length || jobs?.length === 1) {
      return true;
    }
    const jobIndex = jobs?.findIndex((job) => job?.id === currentJob?.id);
    return direction === 'next'
      ? jobIndex === this.jobsService.jobs?.getValue()?.length - 1
      : jobIndex === 0;
  }

  copyLink(): void {
    let jobLink =
      this.jobsService.subdomain === 'lis'
        ? `https://${this.jobsService.subdomain}-careers.services.agileonboarding.com/jobs/details/${this.jobsService.selectedJob?.id}`
        : `https://${this.jobsService.subdomain}-jobs.services.agileonboarding.com/jobs/details/${this.jobsService.selectedJob?.id}`;
    this.clipBoard.copy(jobLink);
    this.copied = true;
    const c = document.getElementById('copiedJob');
    c.style.display = 'flex';
    setTimeout(() => {
      this.copied = false;
      c.style.display = 'none';
    }, 2000);
  }

  cancelApplication(): void {
    this.applicationCanceled.emit(true);
  }

  closeSheet() {
    if (this.showIcons) {
      this.bottomSheetService.closeSheet();
    } else {
      this.navigateToList();
    }
    this.jobsService.selectedJob = null;
  }

  close() {
    this.bottomSheetService.closeSheet();
    if (this.jobsService.isEmbedded) {
      this.router.navigate([`jobs-embed`]);
    } else {
      this.router.navigate([`jobs`]);
    }
  }
}
