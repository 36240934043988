<div class="resume-input-container" *ngIf="!parsing && !file">
  <label
    for="file-upload"
    class="resume-input-small"
    data-cy="resume-upload-label"
    appDragDrop
    (fileDropped)="onFileDrop($event)"
  >
    <span>Click to upload resume (.docx, .doc, .pdf)</span>
  </label>
  <input
    style="display: none"
    id="file-upload"
    type="file"
    data-cy="resume-upload-input"
    accept=".pdf,.docx,.doc"
    #fileInput
    (input)="onFileSelect(fileInput)"
  />
</div>

<div class="chip-list" *ngIf="file && !parsing">
  <mat-chip-list>
    <mat-chip removable (removed)="removeResume()" [attr.data-cy]="'resume-upload-file-' + file?.key">
      {{ file.name }}<mat-icon matChipRemove>cancel</mat-icon></mat-chip
    >
  </mat-chip-list>
</div>

<div class="resume-file-small" *ngIf="parsing">
  <mat-spinner mode="indeterminate" [diameter]="40" data-cy="resume-upload-spinner"></mat-spinner>

  <button class="btn btn-light">Cancel Remaining Resumes and Continue</button>
</div>
