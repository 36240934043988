<!-- Embedded Version -->
<main *ngIf="jobsService.isEmbedded">
  <router-outlet></router-outlet>
</main>

<!-- Non-Embedded Version -->
<div *ngIf="!jobsService.isEmbedded" class="app-container">
  <div class="custom-container">
    <div class="custom-sidebar" [class.is-mobile]="deviceDetectorService.isMobile()">
      <agile-bookmarks-page [drawer]="drawer"></agile-bookmarks-page>
    </div>
    <div class="custom-content" [ngClass]="{ 'job-details-page': onDetailsPage }">
      <header class="logoBanner">
        <div class="logo-container">
          <a
            class="img-logo"
            [routerLink]="jobsService.isEmbedded ? 'jobs-embed' : 'jobs'"
            *ngIf="jobsService.company?.logo"
          >
            <img [src]="jobsService.company?.logo" alt="logo" />
          </a>
        </div>
        <div class="header-wrapper">
          <div class="nav-icon-container" *ngIf="false">
            <mat-icon routerLink="/jobs" matTooltip="Home">home</mat-icon>
            <mat-icon
              matTooltip="Saved Jobs"
              [matBadgeHidden]="history?.savedJobs?.length === 0"
              [matBadge]="history?.savedJobs?.length"
              matBadgeColor="primary"
              (click)="drawer.toggle()"
            >
              bookmark_border
            </mat-icon>
          </div>
        </div>
      </header>

      <main class="main">
        <div class="sidenav-content">
          <router-outlet></router-outlet>
        </div>
      </main>

      <footer class="main-footer">
        <div class="main-footer-wrapper" style="margin-bottom: 0px">
          <div>
            <p>Powered by</p>
            <a
              class="img-avatar"
              target="_blank"
              rel="noopener noreferrer"
              href="https://agileonboarding.com/"
            >
              <img
                class="avatar"
                src="../assets/images/AgileATS_logo_horizontal_blue_clearBG_trimmed_height.png"
                alt="avatar"
                style="height: 80px; width: auto"
              />
            </a>
          </div>
          <strong class="tiny">
            © {{ currentYear }} {{ jobsService.company?.name }} & Portions Agile Onboarding, LLC | All
            Rights Reserved
          </strong>
        </div>
      </footer>
    </div>
  </div>
</div>
