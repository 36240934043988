import uniqBy from 'lodash/uniqBy';
import { Component, OnInit, ViewChild } from '@angular/core';

import { JobsService } from '../../services/jobs.service';
import { ApplicationFormComponent } from '../application-form/application-form.component';

export interface FilterOption {
  name: string;
}

@Component({
  selector: 'agile-jobs-page',
  templateUrl: './jobs-page.component.html',
  styleUrls: ['./jobs-page.component.scss'],
})
export class JobsPageComponent implements OnInit {
  @ViewChild(ApplicationFormComponent, { static: false })
  applicationFormComponent: ApplicationFormComponent;
  loading = false;
  expanded = false;
  totalJobs: number;
  jobs: any[] = [];
  jobTypes: FilterOption[] = [];
  locations: FilterOption[] = [];
  clearances: FilterOption[] = [];
  categories: FilterOption[] = [];
  availability: FilterOption[] = [];

  constructor(public jobsService: JobsService) {}

  async ngOnInit() {
    await this.jobsService?.getCompanyId();

    this.getJobFilterValues();
    this.subscribeToPublishedJobs(this.jobsService.company);
    this.subscribeToTotalPublishedJobs(this.jobsService.company);
  }

  getJobFilterValues() {
    this.jobsService.getPublishedJobs(this.jobsService.company).subscribe(
      (jobs) => {
        this.setFilters(jobs);
      },
      (err) => {
        console.error('ERROR: Unable to get job data', err);
      },
    );
  }

  setFilters(jobs: any[]) {
    this.locations = this.filterBy(jobs, 'location');
    this.categories = this.filterBy(jobs, 'category');
    this.clearances = this.filterBy(jobs, 'security_clearance_publishable');
    this.jobTypes = this.filterBy(jobs, 'type');
    this.availability = uniqBy(
      jobs
        .filter((j) => j.job_availability_id)
        .map((j) => ({
          id: j.job_availability_id,
          name: j.JobAvailability?.title,
        })),
      (ja) => ja.id,
    );
  }

  filterBy(jobs: any[], field: string) {
    return uniqBy(
      jobs
        .filter((job) => job[field])
        .sort((a, b) => this.sortAlphanumerically(a, b, field))
        .map((job) => ({ name: job[field] })),
      'name',
    );
  }

  sortAlphanumerically(a: any, b: any, field: string) {
    return a[field].localeCompare(b[field], 'en', { numeric: true });
  }

  subscribeToPublishedJobs(company): void {
    this.loading = true;
    this.jobsService.subscribeToPublishedJobs(company).subscribe(
      (jobs) => {
        this.loading = false;
        this.handleSuccess(jobs as any[]);
      },
      (err) => {
        this.loading = false;
        this.handleError(err);
      },
    );
  }

  async subscribeToTotalPublishedJobs(company) {
    this.jobsService.loading = true;
    this.jobsService.subscribeToTotalPublishedJobs(company).subscribe(
      (total) => {
        this.totalJobs = total;
      },
      (err) => {
        console.error(err);
      },
    );
  }

  handleSuccess(jobs: any[]): void {
    this.jobs = jobs;
    this.jobsService.jobs.next(jobs);
    this.jobsService.loading = false;
  }

  handleError(err: Error) {
    this.jobsService.loading = false;
    console.error(err);
  }

  handleScroll(e): void {
    const pageLimit = this.jobsService.limit.getValue();
    if (pageLimit >= this.totalJobs) {
      return;
    }

    const buffer = 300;
    const { offsetHeight, scrollHeight, scrollTop } = e.target;
    const limit = scrollHeight - offsetHeight - buffer;

    if (scrollTop > limit) {
      this.jobsService.limit.next(pageLimit + 50);
    }
  }
}
