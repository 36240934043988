<mat-form-field
  appearance="outline"
  class="matFormSelector"
  [floatLabel]="floatLabel"
  [ngClass]="{ invalidError: invalid }"
>
  <mat-label [ngClass]="{ invalidText: invalid }">{{
    invalid && required ? labelName + ' is required' : labelName
  }}</mat-label>
  <mat-select
    #multiSelect
    [multiple]="multiple"
    [compareWith]="compareFn"
    [placeholder]="enableNAOption ? 'N/A' : placeholder"
    [disabled]="readOnly"
    [(ngModel)]="value"
    (onClose)="reset()"
    [required]="required"
    (openedChange)="handleSelectToggled($event)"
    (selectionChange)="optionChangedHandler($event)"
    typeaheadDebounceInterval="60000"
    (focus)="customFocusHandler($event)"
  >
    <mat-select-trigger>
      <div class="d-flex justify-content-start flex-gap align-items-center">
        <mat-chip-list *ngIf="multiple">
          <mat-chip
            *ngFor="let selected of value | slice: (allSelected?.selected ? 1 : 0) : maxShowableItems"
          >
            {{ getItemIdToTextMap(selected) }}
          </mat-chip>
        </mat-chip-list>
        <ao-button
          id="numberButton"
          class="mb-0"
          size="sm"
          type="outline"
          *ngIf="value?.length > maxShowableItems && multiple"
          [matTooltip]="commaSeperatedItems()"
          [text]="'+' + (enableSelectAll ? filteredLength() : value.length - maxShowableItems)"
        >
        </ao-button>
        <span *ngIf="!multiple">
          {{ selectedItem?.text }}
        </span>
      </div>
    </mat-select-trigger>

    <div id="searchInputContainer">
      <input
        #selectSearch
        id="searchInput"
        class="w-100 border-0"
        style="outline: none"
        placeholder="Search..."
        (keyup)="keyupFn.emit($event); searchChange()"
        [(ngModel)]="search"
        (ngModelChange)="searchChange()"
      />
    </div>

    <ao-button
      *ngIf="addButtonText"
      type="clear-primary"
      [text]="addButtonText"
      (click)="addButtonHandler()"
    >
    </ao-button>

    <mat-option *ngIf="enableSelectAll" #allSelected (click)="toggleAllSelection()" [value]="0">
      Select All
    </mat-option>
    <div *ngIf="enableNoneOption">
      <mat-option [value]="'none'" *ngIf="(items?.length == 0 && addButtonText) || enableNoneOption">
        None
      </mat-option>
    </div>
    <div *ngIf="enableNAOption">
      <mat-option [value]="null" *ngIf="enableNAOption"> N/A </mat-option>
    </div>

    <div *ngIf="!enableNoneOption">
      <mat-option [disabled]="true" *ngIf="items?.length == 0 && addButtonText">None</mat-option>
    </div>

    <mat-option *ngIf="enableAllOption" [value]="'all'"> All </mat-option>

    <mat-option *ngFor="let item of items" [value]="item[bindValue]" [ngClass]="dataCy">
      <div class="d-flex flex-gap align-items-center">
        {{ item[bindLabel] }}
      </div>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="invalid" style="margin-top: 10px !important">
    {{ labelName }} is required
  </mat-error>
</mat-form-field>
