<div class="job-details-preview-container">
  <mat-stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>{{ 'show-details' ? 'keyboard_return' : 'index' }}</mat-icon>
    </ng-template>

    <mat-step label="Job Details">
      <div>
        <agile-job-details
          [showIcons]="false"
          (nextStepEvent)="advanceToApplication()"
        ></agile-job-details>
      </div> </mat-step
    ><mat-step label="Apply Now">
      <div class="app-form-container">
        <agile-application-form
          (previousStepEvent)="returnToDetails()"
          [showIcons]="true"
        ></agile-application-form>
      </div>
    </mat-step>
  </mat-stepper>
</div>
