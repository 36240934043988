import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { from, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { LISTEN_FOR_PARSED_RESUMES } from '../../graphql/subscriptions';
import { JobsService } from '../../services/jobs.service';

export interface ResumeIngestStatusResponse {
  object_key: string;
  status_code: string;
}

export interface FileData {
  key: string;
  filename: string;
  file_type: string;
  size: number;
  identity_id: number;
  resume: boolean;
}

@Component({
  selector: 'agile-resume-upload',
  templateUrl: './resume-upload.component.html',
  styleUrls: ['./resume-upload.component.scss'],
})
export class ResumeUploadComponent implements OnDestroy {
  @Output() uploadedResume = new EventEmitter<any>();
  @Output() resumeIngestStatus = new EventEmitter<any>();
  subscriptions: Subscription = new Subscription();
  retrySub: Subscription = new Subscription();
  resumeSub: Subscription = new Subscription();
  file: File;
  fileData: FileData;
  parsing = false;
  currentlyParsingIds = [];
  parsedResumes = [];
  private successNotifier = new Subject<void>();
  private retryNotifier = new Subject<void>(); // New subject for retry notifications

  retryCount = 0;
  maxRetries = 3;

  constructor(
    private apollo: Apollo,
    private jobsService: JobsService,
    private http: HttpClient,
  ) {}

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
    this.retryNotifier?.unsubscribe();
  }

  async onFileSelect(input): Promise<void> {
    await this.initUpload(input.files);
  }

  async onFileDrop(files) {
    await this.initUpload(files);
  }

  async initUpload(files) {
    const dataHash = await this.getDataHash(files);
    const resume = await this.jobsService.getResumeBucketKeyByDataHash(dataHash);

    if (resume) {
      console.log('Duplicate');
      const resumeData: ResumeIngestStatusResponse = {
        object_key: resume.s3bucketkey,
        status_code: 'COMPLETE',
      };

      this.resumeIngestStatus.emit(resumeData);
      return;
    }

    this.uploadResume(this.file);
  }

  async getDataHash(files): Promise<string> {
    this.file = Array.from(files as FileList)[0];
    const arrayBuffer = await this.fileToArrayBuffer(this.file);
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const dataHash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return dataHash;
  }

  fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = (event) => reject(event.target.error);
      reader.onload = (event) => resolve(event.target.result as ArrayBuffer);
      reader.readAsArrayBuffer(file);
    });
  }

  async uploadResume(file: File) {
    this.successNotifier = new Subject<void>(); // Reset successNotifier
    this.retryNotifier = new Subject<void>(); // Reset retryNotifier

    const fileData: FileData = this.generateFileData(file);
    this.jobsService
      .getSignedUrl(fileData.key)
      .pipe(
        switchMap((signedUrl) => {
          return this.http.put(signedUrl, file, {
            headers: {
              'x-amz-tagging': `filename=${fileData.filename}&company_id=${this.jobsService.companyId}`,
            },
          });
        }),
      )
      .subscribe(
        () => {
          console.log('successfully uploaded resume', fileData);
        },
        (err) => {
          console.error('ERROR: Unable to put signed url!', err);
        },
      );

    this.parsing = true;

    this.subscribeToResume(fileData?.key, file);
  }

  generateFileData(file: File): FileData {
    const base64FileName = btoa(file.name.replace(/[^\x00-\x7F]/g, ''));
    const fileKeyExt = file.name.split('.').pop();

    return {
      key: `${uuid()}.${fileKeyExt}`,
      filename: base64FileName,
      file_type: file.type,
      size: file.size,
      identity_id: null,
      resume: true,
    };
  }

  subscribeToResume(resumeId: string, file: File): void {
    this.resumeSub = this.apollo
      .subscribe<any>({
        query: LISTEN_FOR_PARSED_RESUMES,
        variables: { resumeId },
      })
      .pipe(
        filter((event): any => {
          // Filter out events with no data
          if (event?.data?.ResumeIngestStatus?.length === 0) {
            return false;
          }
          // Filter out the resume events that we ened to ignore
          // @ts-ignore
          console.log('EVENTS');
          console.log(event);
          event.data.ResumeIngestStatus = event.data.ResumeIngestStatus.filter((r) => {
            const ignoredEvents = [
              'TEXT_EXTRACT_COMPLETE',
              'SHA_COMPUTE_COMPLETE',
              'NER_COMPLETE',
              'STARTED',
              'EXISTING_IDENTITY',
            ];
            return !ignoredEvents.includes(r.status_code);
          });
          return true;
        }),
        takeUntil(this.successNotifier),
        catchError((error) => {
          console.error('Resume Subscription Error:', error);
          this.parsing = false;
          this.file = null;
          this.jobsService.notify('error', 'Failed to properly parse resume!');
          return of(); // Ensure the observable completes even in case of error
        }),
      )
      .subscribe(
        (event) => {
          // Here we have only success or error events from the resumes that we subscribed to
          this.handleParsedResumes(event.data.ResumeIngestStatus);
        },
        (error) => {
          console.error('Resume Subscription Error:', error);
          this.parsing = false;
          this.file = null;
          this.jobsService.notify('error', 'Failed to properly parse resume!');
        },
      );
    this.subscriptions.add(this.resumeSub);

    this.retrySub = timer(30000)
      .pipe(
        switchMap(() => {
          if (this.retryCount < this.maxRetries) {
            this.retryCount++;
            console.log(`Retry attempt ${this.retryCount}`);
            this.resumeSub?.unsubscribe();
            this.uploadResume(file);
            return from([]);
          } else {
            console.error('Maximum retry attempts reached');
            return throwError('Maximum retry attempts reached');
          }
        }),
        takeUntil(this.successNotifier), // Use successNotifier to stop retry logic
        takeUntil(this.retryNotifier), // Use retryNotifier to stop retry logic
        catchError((error) => {
          console.error('Final error after retries:', error);
          this.handleErrorResume();
          return of(); // Ensure the observable completes even in case of error
        }),
      )
      .subscribe();
  }

  handleParsedResumes(parsedResumeStatuses: ResumeIngestStatusResponse[]): void {
    if (!parsedResumeStatuses.length) return;
    this.parsing = false;
    if (parsedResumeStatuses[0]?.status_code === 'COMPLETE') {
      this.successNotifier.next(); // Notify success to cancel the timer
      this.successNotifier.complete(); // Complete the Subject to clean up
      this.retryNotifier.next(); // Notify to cancel the retry timer
      this.retryNotifier.complete(); // Complete the retryNotifier to clean up
      this.resumeSub.unsubscribe();
      this.retrySub.unsubscribe();
      this.retryCount = 0; // Reset retry count on success
    }
    if (parsedResumeStatuses[0]?.status_code === 'ERROR') {
      this.handleErrorResume();
      return;
    }

    this.resumeIngestStatus.emit(parsedResumeStatuses[0]);
  }

  filterAlreadyParsedResumes(resumeStatuses, currentlyParsingIds) {
    return resumeStatuses.filter((resumeStatus) => {
      return currentlyParsingIds.includes(resumeStatus.object_key);
    });
  }

  clearSubs() {
    this.successNotifier.next(); // Notify success to cancel the timer
    this.successNotifier.complete(); // Complete the Subject to clean up
    this.retryNotifier.next(); // Notify to cancel the retry timer
    this.retryNotifier.complete(); // Complete the retryNotifier to clean up
    this.resumeSub?.unsubscribe();
    this.retrySub?.unsubscribe();
    this.retryCount = 0; // Reset retry count on success
  }

  handleErrorResume(): void {
    this.clearSubs();
    this.parsing = false;
    this.file = null;
    this.jobsService.notify('error', 'Failed to upload resume. Please try again!');
  }

  removeResume(): void {
    this.file = null;
    this.resumeIngestStatus.emit(null);
  }
}
