import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'agile-job-details-preview',
  templateUrl: './job-details-preview.component.html',
  styleUrls: ['./job-details-preview.component.scss'],
})
export class JobDetailsPreviewComponent implements OnInit, AfterViewInit {
  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(private route: ActivatedRoute, private viewportScroller: ViewportScroller) {}
  ngOnInit() {}

  ngAfterViewInit() {
    this.route.params.subscribe((params) => {
      const index = parseInt(params['index'], 10);
      if (index && (index === 1 || index === 0)) {
        this.stepper.selectedIndex = index;
        this.scrollToTop();
      }
    });
  }
  scrollToTop() {
    this.viewportScroller.getScrollPosition();
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  returnToDetails() {
    this.stepper.previous();
  }
  advanceToApplication() {
    this.stepper.next();
  }
}
