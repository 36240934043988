import { Component, Input } from '@angular/core';

@Component({
  selector: 'agile-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent {
  @Input() isRequired = false;
  @Input() isInvalid = false;
  @Input() labelName: string;
  @Input() dataCy: string;
}
