import { Component, Input } from '@angular/core';
import { JobsService } from '../../services/jobs.service';

@Component({
  selector: 'agile-jobs-filter',
  templateUrl: './jobs-filter.component.html',
  styleUrls: ['./jobs-filter.component.scss'],
})
export class JobsFilterComponent {
  @Input() locations = [];
  @Input() jobTypes = [];
  @Input() clearances = [];
  @Input() categories = [];
  @Input() availability = [];

  constructor(public jobsService: JobsService) {}
}
