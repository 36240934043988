<div class="job-grid">
  <div class="expanded" [ngClass]="expanded" id="jobsList">
    <div class="job-header-main">
      <h1>Job Openings</h1>
      <p class="job-header-main-subtitle">Showing {{ jobs?.length }} of {{ totalJobs }} Jobs</p>
    </div>
    <agile-jobs-filter
      [locations]="locations"
      [categories]="categories"
      [jobTypes]="jobTypes"
      [clearances]="clearances"
      [availability]="availability"
    ></agile-jobs-filter>
    <mat-progress-bar mode="indeterminate" *ngIf="jobsService.loading || loading"></mat-progress-bar>

    <div *ngIf="jobs?.length" class="job-list" (scroll)="handleScroll($event)">
      <agile-job-card *ngFor="let job of jobs" [job]="job"></agile-job-card>
    </div>
    <div *ngIf="!jobs?.length && !loading" class="no-jobs-text">
      <p>Thank you for your interest in joining {{ jobsService.company?.name }}</p>
      <p>Unfortunately, we do not have any job openings at the moment. Please check back again later.</p>
    </div>
  </div>
</div>
