import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectGaurd implements CanActivate {
  constructor() {}

  async canActivate(): Promise<boolean> {
    const isDev = window.location.host.startsWith('localhost:');
    let subdomain = isDev ? 'redesign-caci' : window.location.host.split('.')[0];
    if (subdomain.endsWith('-jobs')) {
      subdomain = subdomain.slice(0, -5);
    }
    if (subdomain.endsWith('-careers')) {
      subdomain = subdomain.slice(0, -8);
    }
    return true;
  }
}
