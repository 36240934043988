import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { ApplicationFormComponent } from './components/application-form/application-form.component';
import { JobDetailsPreviewComponent } from './components/job-details-preview/job-details-preview.component';
import { JobsPageComponent } from './components/jobs-page/jobs-page.component';
import { RedirectGaurd } from './guards/redirect.guard';

const routes: Routes = [
  { path: 'jobs', component: JobsPageComponent, canActivate: [RedirectGaurd] },
  {
    path: 'jobs/details/:id',
    component: JobDetailsPreviewComponent,
    canActivate: [RedirectGaurd],
  },
  {
    path: 'jobs/details/:id/:date',
    component: JobDetailsPreviewComponent,
    canActivate: [RedirectGaurd],
  },
  {
    path: 'jobs/details/:id/:date/:index',
    component: JobDetailsPreviewComponent,
    canActivate: [RedirectGaurd],
  },
  {
    path: 'jobs-embed',
    component: JobsPageComponent,
    canActivate: [RedirectGaurd],
  },
  {
    path: 'jobs-embed/details/:id/:date',
    component: JobDetailsPreviewComponent,
    canActivate: [RedirectGaurd],
  },
  {
    path: 'jobs-embed/details/:id/:date/:index',
    component: JobDetailsPreviewComponent,
    canActivate: [RedirectGaurd],
  },
  {
    path: 'jobs-embed/details/:id',
    component: JobDetailsPreviewComponent,
    canActivate: [RedirectGaurd],
  },
  {
    path: 'application-embed/:job-id',
    component: ApplicationFormComponent,
    canActivate: [RedirectGaurd],
  },
  { path: '', redirectTo: 'jobs', pathMatch: 'full' },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
