import { JobsService } from '../services/jobs.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobId',
  pure: true,
})
export class JobIdPipe implements PipeTransform {
  constructor(private jobsService: JobsService) {}

  padWithZero(num: string, targetLength: number) {
    return num.padStart(targetLength, '0');
  }

  transform(job): string {
    if (!job) {
      return '';
    }

    const companySlug = this.jobsService?.company?.slug?.toUpperCase() || '';
    const year = new Date(job.created_at).toLocaleDateString('en', {
      year: '2-digit',
    });

    return `${companySlug}-${year}-${this.padWithZero(job.id.toString(), 8)}`;
  }
}
