<div
  [class]="job?.status === 'Hot' ? 'job-card HotJobs' : 'job-card'"
  [attr.data-cy]="'job-card-' + job?.id"
>
  <div
    class="job-card-content"
    [ngClass]="{ 'selected-job': jobsService.selectedJob?.id === job?.id }"
    [attr.data-cy]="'job-card-content-' + job?.id"
    (click)="openSheet(job)"
  >
    <div style="display: flex; gap: 16px; align-items: center">
      <h3 id="jobTitle" [attr.data-cy]="'job-card-title-' + job?.id" style="margin: 0px">
        {{ indeed_opt_in ? job?.publishable_title : job?.title }}
      </h3>
      <mat-chip-list *ngIf="job?.status === 'Hot'">
        <mat-chip *ngIf="job.status === 'Hot'" class="hot-status"
          >Hot
          <svg class="flame" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              d="M159.3 5.4c7.8-7.3 19.9-7.2 27.7 .1c27.6 25.9 53.5 53.8 77.7 84c11-14.4 23.5-30.1 37-42.9c7.9-7.4 20.1-7.4 28 .1c34.6 33 63.9 76.6 84.5 118c20.3 40.8 33.8 82.5 33.8 111.9C448 404.2 348.2 512 224 512C98.4 512 0 404.1 0 276.5c0-38.4 17.8-85.3 45.4-131.7C73.3 97.7 112.7 48.6 159.3 5.4zM225.7 416c25.3 0 47.7-7 68.8-21c42.1-29.4 53.4-88.2 28.1-134.4c-2.8-5.6-5.6-11.2-9.8-16.8l-50.6 58.8s-81.4-103.6-87.1-110.6C133.1 243.8 112 273.2 112 306.8C112 375.4 162.6 416 225.7 416z"
            />
          </svg>
        </mat-chip>
      </mat-chip-list>
    </div>
    <p [attr.data-cy]="'job-card-location-' + job?.id" style="margin-top: 6px">
      {{ job?.location || 'No Location' }}
    </p>
    <div class="job-card-footer">
      <strong
        [attr.data-cy]="'job-card-category-' + job?.id"
        *ngIf="jobsService.company?.public_metadata?.showIdOnPublicJobsApp"
      >
        {{ job | jobId }}
      </strong>
      <small *ngIf="jobsService.company?.public_metadata?.showIdOnPublicJobsApp">|</small>
      <strong [attr.data-cy]="'job-card-category-' + job?.id">{{
        job?.category?.toUpperCase() || 'No Category'
      }}</strong>
      <small>|</small>
      <small [attr.data-cy]="'job-card-job-type-' + job?.id">{{
        job?.type?.toUpperCase() || 'No Job Type'
      }}</small>
      <span class="visuallyhidden" [attr.data-cy]="'job-' + job?.id + '-company-' + job?.company_id"
        >Company: {{ job?.company_id }}</span
      >
    </div>
  </div>
</div>
