import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const urlRegExp =
  /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export function validateLinkedInUrl(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const linkedInUrl = control.value;

    if (!linkedInUrl) {
      return null;
    }

    return urlRegExp.test(linkedInUrl) ? null : { invalidUrl: true };
  };
}
