<div class="job-details-subcontainer">
  <div class="details-header">
    <div class="details-nav-container">
      <h2
        class="details-job-title"
        (click)="navigateToJob(jobsService.selectedJob?.id, jobsService.selectedJob?.published_date)"
        matTooltip="View Details Page"
        data-cy="job-details-title"
        style="width: fit-content"
      >
        {{ indeed_opt_in ? jobsService.selectedJob?.publishable_title : jobsService.selectedJob?.title }}
      </h2>
      <ao-button
        *ngIf="showIcons"
        [type]="'danger-clear'"
        data-cy="job-details-close-button"
        [matTooltip]="'Close'"
        [icon]="'close'"
        (click)="closeSheet()"
      >
      </ao-button>
    </div>
  </div>
  <div class="job-icons-grid">
    <div class="job-details-text" *ngIf="jobsService.company?.public_metadata?.showIdOnPublicJobsApp">
      <span class="details-postdate" data-cy="job-details-id">
        {{ jobsService.selectedJob | jobId }}
      </span>
    </div>
    <div class="job-details-text">
      <mat-icon class="job-details-icon">place</mat-icon>
      <span class="details-postdate" data-cy="job-details-location">
        {{ jobsService.selectedJob?.location || 'No Location' }}
      </span>
    </div>
    <div class="job-details-text">
      <mat-icon class="job-details-icon">settings</mat-icon>
      <span class="details-postdate" data-cy="job-details-category">
        {{ jobsService.selectedJob?.category || 'No Category' }}</span
      >
    </div>
    <div class="job-details-text">
      <mat-icon class="job-details-icon">work_outline</mat-icon>
      <span class="details-postdate" data-cy="job-details-type">{{
        jobsService.selectedJob?.type || 'No Job Type'
      }}</span>
    </div>
    <div class="job-details-text">
      <mat-icon class="job-details-icon">security</mat-icon>
      <span class="details-postdate" data-cy="job-details-clearance">
        {{ jobsService.selectedJob?.security_clearance_publishable }}
      </span>
    </div>
    <div
      class="job-details-text"
      *ngIf="
        jobsService.company?.public_metadata?.showDatePublishedOnPublicJobsApp !== false &&
        jobsService.selectedJob?.published_date
      "
    >
      <mat-icon class="job-details-icon">schedule</mat-icon>
      <span class="details-postdate" data-cy="job-details-posted">
        Posted
        {{ moment(jobsService.selectedJob?.published_date).format('LL') }}
      </span>
    </div>
  </div>
  <div class="details-nav" style="display: flex; align-items: center !important">
    <ao-button
      [type]="'cta'"
      data-cy="job-details-apply-button"
      (click)="applyToJob()"
      *ngIf="showIcons"
      [text]="'Apply Now'"
    >
    </ao-button>
    <ao-button
      [type]="'cta-outline'"
      data-cy="job-details-apply-button"
      (click)="navigateToList()"
      icon="arrow_back"
      *ngIf="!showIcons"
      text="Return to list"
    >
    </ao-button>

    <div
      class="details-title-icon-container"
      style="display: flex; align-items: center; padding-bottom: 8px"
    >
      <ao-button
        mat-raised-button
        [type]="'cta'"
        (click)="nextStep()"
        *ngIf="!showIcons"
        [text]="'Apply Now'"
      >
      </ao-button>
      <div style="display: flex; flex-direction: column">
        <ao-button
          mat-icon-button
          type="clear"
          style="margin-left: 10px; margin-right: 10px"
          icon="content_copy"
          (click)="copyLink()"
          [matTooltip]="'Copy Link'"
          data-cy="job-details-copy-button"
        >
        </ao-button>
        <div
          class="copied"
          id="copiedJob"
          [ngClass]="{ show: copied }"
          data-cy="job-details-copied-text"
        >
          <div class="check"></div>
          <small>Copied!</small>
        </div>
      </div>

      <ao-button
        mat-icon-button
        [icon]="'arrow_backward'"
        class="details-title-icon"
        style="margin-right: 10px"
        matTooltip="Previous Job"
        [disabled]="isDisabled(jobsService.selectedJob, 'previous')"
        (click)="onChangeJob('previous', jobsService.selectedJob)"
      >
      </ao-button>
      <ao-button
        [icon]="'arrow_forward'"
        matTooltip="Next Job"
        (click)="onChangeJob('next', jobsService.selectedJob)"
        [disabled]="isDisabled(jobsService.selectedJob, 'next')"
      >
      </ao-button>
    </div>
  </div>

  <div class="job-details-body" [ngClass]="{ 'job-details-body-embed': jobsService.isEmbedded }">
    <div
      class="info-box"
      *ngIf="jobsService.selectedJob?.application_due_date"
      data-cy="job-details-duedate-section"
    >
      <mat-icon color="primary">info</mat-icon>
      <div class="info-box-body">
        <strong>
          Applications will be accepted through
          {{ moment(jobsService.selectedJob?.application_due_date).format('LL') }}.
        </strong>
        <p>
          Note: If you don't apply
          {{ moment(jobsService.selectedJob?.application_due_date).format('LL') }}, the candidate
          evaluation process will have begun and applications submitted after that time cannot be
          considered.
        </p>
      </div>
    </div>
    <agile-accordion [expanded]="true">
      <h3 title>Job Description</h3>
      <p
        class="details-body-text"
        body
        [innerHtml]="jobsService.selectedJob?.description_publishable"
        data-cy="job-details-job-description"
      ></p>
    </agile-accordion>

    <mat-divider></mat-divider>

    <agile-accordion [expanded]="true" *ngIf="jobsService.selectedJob?.required_skills_publishable">
      <h3 title>Required Skills</h3>
      <p
        class="details-body-text"
        data-cy="job-details-required-skills"
        body
        [innerHtml]="jobsService.selectedJob?.required_skills_publishable"
      ></p>
    </agile-accordion>
    <mat-divider *ngIf="jobsService.selectedJob?.required_skills_publishable"></mat-divider>

    <agile-accordion *ngIf="jobsService.selectedJob?.desired_skills_publishable" [expanded]="true">
      <h3 title>Desired Skills</h3>
      <p
        class="details-body-text"
        body
        data-cy="job-details-desired-skills"
        [innerHtml]="jobsService.selectedJob?.desired_skills_publishable"
      ></p>
    </agile-accordion>
    <mat-divider *ngIf="jobsService.selectedJob?.desired_skills_publishable"></mat-divider>

    <agile-accordion [expanded]="true" *ngIf="jobsService.selectedJob?.additional_details_publishable">
      <h3 title>Additional Details</h3>
      <p
        class="details-body-text"
        body
        data-cy="additional-details-skills"
        [innerHtml]="jobsService.selectedJob?.additional_details_publishable"
      ></p>
    </agile-accordion>
    <mat-divider *ngIf="jobsService.selectedJob?.additional_details_publishable"></mat-divider>

    <agile-accordion [expanded]="true">
      <h3 title>About Us</h3>
      <p
        body
        class="details-body-text"
        [innerHtml]="jobsService.company?.description"
        data-cy="job-details-company-description"
      ></p>
    </agile-accordion>

    <div class="logo-container-details-page" *ngIf="jobsService.company?.logo && !routed">
      <a class="img-logo">
        <img [src]="jobsService.company?.logo" alt="logo" />
      </a>
    </div>
  </div>
</div>
