import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root',
})
export class BottomSheetService {
  private currentSheetRef: MatBottomSheetRef<any> | null = null;
  constructor(private bottomSheet: MatBottomSheet) {}

  openSheet(component: ComponentType<unknown>, dataConfig?: MatBottomSheetConfig<any>) {
    this.currentSheetRef = this.bottomSheet.open(component, dataConfig);
  }

  getCurrentSheet() {
    return this.currentSheetRef;
  }
  closeSheet() {
    if (this.currentSheetRef) {
      this.currentSheetRef.dismiss();
      this.currentSheetRef = null;
    }
  }
}
