import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, concat, InMemoryCache, split } from '@apollo/client/core'; // prettier-ignore
import { getMainDefinition } from '@apollo/client/utilities';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ApolloLink } from '@apollo/client/link/core';
import { getSubdomainFromUrl } from '../utils/get-subdomain-from-url';
import { JobsLsKeys } from '../utils/local-storage-keys';
import { companyIdLimitingLink } from '../utils/company-id-limiting-link';
import { createClient } from 'graphql-ws';

interface Definintion {
  kind: string;
  operation?: string;
}

let subdomain = getSubdomainFromUrl();

const isDev =
  window.location.host.startsWith('localhost:') || window.location.hostname.includes('.dev.');

const hasuraEndpoint = isDev
  ? `https://api-${subdomain}.dev.agileonboard.com/v1/graphql`
  : `https://api-${subdomain}.services.agileonboarding.com/v1/graphql`;

const hasuraWssEndpoint = isDev
  ? `wss://api-${subdomain}.dev.agileonboard.com/v1/graphql`
  : `wss://api-${subdomain}.services.agileonboarding.com/v1/graphql`;

localStorage.setItem(JobsLsKeys.HASURA_EP, hasuraEndpoint);
localStorage.setItem(JobsLsKeys.HASURA_WS_EP, hasuraEndpoint);
localStorage.setItem(JobsLsKeys.SUBDOMAIN, subdomain);

const generateHeaders = () => ({
  clientId: localStorage.getItem('AOJ_Client_Instance_ID') || '',
  'X-Company-Id': localStorage.getItem('companyId') || '',
});

const headers = generateHeaders();
@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink): ApolloClientOptions<any> {
        const http = httpLink.create({
          uri: hasuraEndpoint,
          withCredentials: true,
          headers: new HttpHeaders(headers) as any,
        });

        const wsLink = new GraphQLWsLink(
          createClient({
            url: hasuraWssEndpoint,
            connectionParams: async () => {
              return {
                headers,
              };
            },
            shouldRetry: () => true,
            retryAttempts: 5,
          }),
        );

        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
          // split based on operation type
          ({ query }) => {
            const { kind, operation }: Definintion = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
          },
          wsLink,
          http,
        );

        return {
          link: concat(companyIdLimitingLink, ApolloLink.from([link])),
          cache: new InMemoryCache(),
          credentials: 'include',
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
