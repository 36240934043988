import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'; // prettier-ignore
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DisabilityStatusEnum_Enum, GenderEnum_Enum, JobApplicationInput, RaceEnum_Enum, VeteranStatusEnum_Enum } from 'src/generated/graphql'; // prettier-ignore
import { Model, StylesManager } from 'survey-core';
import { usStates } from 'src/utils/us-states';
import { JobsService } from '../../services/jobs.service';
import { validateLinkedInUrl } from '../../../utils/linkedin-url-validator';
import { validatePhoneNumber } from '../../../utils/phone-number-validator';
import { ResumeIngestStatusResponse, ResumeUploadComponent } from '../resume-upload/resume-upload.component'; // prettier-ignore
import { MatStepper } from '@angular/material/stepper';

// StylesManager.applyTheme('defaultV2');

@Component({
  selector: 'agile-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
})
export class ApplicationFormComponent implements OnInit, OnDestroy {
  @Input() showIcons = true;
  currentStep = 0;
  @ViewChild(MatStepper) stepper: MatStepper;
  @Output() previousStepEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(ResumeUploadComponent, { static: false })
  resumeUploadComponent: ResumeUploadComponent;
  private _subs = new Subscription();
  surveyModel: Model;
  indeed_opt_in: boolean = false;

  jobIdFromRoute: number;
  isGovParticipationStatusRequired = true;
  appForm: FormGroup;
  usStates = usStates;
  formHasErrors = false;
  loading = false;
  showRaceDefs = false;
  autoReject = true;
  autoIngest = true;
  showVetDefs = false;
  eeoStatus = [];
  resumeStatus = [];
  applicantStatus = [];
  clearanceStatus = [];
  disabilityStatus = [];
  employmentStatus = [];
  affirmationStatus = [];
  questionnaireStatus = [];
  acknowledgement = [];
  disabilityStatuses = DisabilityStatusEnum_Enum;
  clearances: { value: number; text: string }[] = [];
  genders = [
    { value: GenderEnum_Enum.Female, text: 'Female' },
    { value: GenderEnum_Enum.Male, text: 'Male' },
    { value: GenderEnum_Enum.Declined, text: 'Declined to Answer' },
    { value: GenderEnum_Enum.Other, text: 'Other' },
  ];
  veterans = [
    { value: VeteranStatusEnum_Enum.No, text: 'I am not a protected veteran' },
    {
      value: VeteranStatusEnum_Enum.Yes,
      text: 'I identify as one or more of the classifications of a protected veteran',
    },
    { value: VeteranStatusEnum_Enum.Declined, text: 'I do not wish to answer' },
  ];
  races = [
    { value: RaceEnum_Enum.HispanicOrLatino, text: 'Hispanic or Latino' },
    { value: RaceEnum_Enum.White, text: 'White (Not Hispanic or Latino)' },
    { value: RaceEnum_Enum.Black, text: 'Black or African American' },
    {
      value: RaceEnum_Enum.NativeHawaiianOrPacIslander,
      text: 'Native Hawaiian or Other Pacific Islander',
    },
    { value: RaceEnum_Enum.Asian, text: 'Asian' },
    {
      value: RaceEnum_Enum.NativeAmericanOrAlaskaNative,
      text: 'Native American or Alaska Native',
    },
    { value: RaceEnum_Enum.TwoOrMore, text: 'Two or More Races' },
    { value: RaceEnum_Enum.DoNotDisclose, text: 'Do Not Wish to Disclose' },
  ];

  constructor(
    public jobsService: JobsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadClearances();
    this.subToRouteParams();

    const s = this.jobsService._selectedJob.subscribe((job) => {
      this.indeed_opt_in = this.jobsService?.company?.indeed_opt_in;
      if (job?.questionnaire_id && job?.include_questionnaire_on_application) {
        const surveyModel = new Model(job.Questionnaire.model);
        surveyModel.showTitle = false;
        surveyModel.showPageTitles = false;
        surveyModel.showBrandInfo = false;
        surveyModel.showCompletedPage = false;

        surveyModel.onComplete.add((result) => {
          this.appForm.patchValue({ questionnaire_result: result.data });
          this.setApplicantStatus();
        });
        this.surveyModel = surveyModel;
        this.appForm.addControl(
          'questionnaire_id',
          new FormControl(job.Questionnaire.id, [Validators.required]),
        );
        this.appForm.addControl(
          'questionnaire_model',
          new FormControl(job.Questionnaire.model, [Validators.required]),
        );
        this.appForm.addControl('questionnaire_result', new FormControl(null, [Validators.required]));
      } else {
        // Clear the validators if there is no questionnaire
        this.questionnaireStatus = [];
        this.appForm.removeControl('questionnaire_id');
        this.appForm.removeControl('questionnaire_model');
        this.appForm.removeControl('questionnaire_result');
        this.surveyModel?.dispose();
        this.surveyModel = null;
      }
    });

    this._subs.add(s);
  }

  /**
   * subToRouteParams pulls the job-id out of the route when this is rendered as an embedable form.
   * @example route: https://ic-1-jobs.agileonboarding.com/application-embed/:job-id
   */
  subToRouteParams() {
    const s = this.route.paramMap.subscribe((params) => {
      const jobId = +params.get('job-id');

      if (jobId) {
        this.jobIdFromRoute = jobId;
      }
    });

    this._subs.add(s);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  returnToDetails() {
    this.previousStepEvent.emit();
  }
  setStep(index: number) {
    this.currentStep = index;
    if (this.currentStep === 2 && this.appForm?.invalid) {
      this.formHasErrors = true;
      this.markFormGroupDirty(this.appForm);
    }
  }

  nextStep() {
    if (this.currentStep === 1 && this.appForm?.invalid) {
      this.formHasErrors = true;
      this.markFormGroupDirty(this.appForm);
    }
    this.currentStep++;
  }
  previousStep() {
    this.currentStep--;
  }

  markFormGroupDirty(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.markFormGroupDirty(control);
      }
    });
  }

  private loadClearances(): void {
    const s = this.jobsService.getSecurityClearances$().subscribe(
      (clearances) => {
        this.clearances = clearances.map((c) => ({
          value: c.id,
          text: c.title,
        }));
      },
      (err) => {
        console.error(err);
      },
    );

    this._subs.add(s);
  }

  private buildForm(): void {
    this.appForm = this.formBuilder.group({
      name_first: ['', Validators.required],
      name_middle: null,
      name_last: ['', Validators.required],
      personal_email: ['', [Validators.required, Validators.email]],
      mobile_phone: ['', [Validators.required, validatePhoneNumber()]],
      linkedin_url: ['', validateLinkedInUrl()],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: [null, Validators.required],
      zip: ['', Validators.required],
      clearance: ['Unknown', Validators.required],
      clearance_id: [34, Validators.required],
      bucket_key: ['', Validators.required],
      authorized: ['', Validators.required],
      visa_required: ['', Validators.required],
      affirmation_signature: ['', Validators.required],
      gov_employment_status: ['', Validators.required],
      gov_contract_participant: [null, Validators.required],
      gender: GenderEnum_Enum.Declined,
      race: RaceEnum_Enum.DoNotDisclose,
      acknowledgement: [null, Validators.required],
      has_disability: null,
      veteran_status: VeteranStatusEnum_Enum.Declined,
      subdomain: this.jobsService.subdomain,
      recaptcha_token: '',
      referred_by: '',
    });

    this.subscribeToFormChanges();
  }

  onNeverEmployedClicked(): void {
    this.isGovParticipationStatusRequired = false;
    this.appForm.get('gov_contract_participant').clearValidators();
    this.appForm.get('gov_contract_participant').updateValueAndValidity();
    this.setApplicantStatus();
  }

  onCurrentEmployeeClicked(): void {
    this.isGovParticipationStatusRequired = true;
    const govParticipationStatusControl = this.appForm.get('gov_contract_participant');

    if (!govParticipationStatusControl.validator) {
      govParticipationStatusControl.setValidators(Validators.required);
      govParticipationStatusControl.updateValueAndValidity();
      this.setApplicantStatus();
    }
  }

  subscribeToFormChanges() {
    this._subs.add(this.appForm.valueChanges.subscribe(() => this.setApplicantStatus()));
  }

  async submitForm(): Promise<void> {
    this.loading = true;

    if (this.appForm?.invalid) {
      this.formHasErrors = true;
      this.loading = false;
      this.jobsService.notify(
        'error',
        'You are missing fields go back and ensure all sections are filled out.',
      );
      return;
    }

    if (!this.form.acknowledgement.value) {
      this.loading = false;
      this.jobsService.notify(
        'error',
        'You must read and agree to the terms in the Authorization and Acknowledgment section prior to submitting your application.',
      );
      return;
    }

    // Many users have trouble getting this to match exactly and its not a hard requirement to have them type it exactly the same
    // if (!this.nameMatchesSignature()) {
    //   this.loading = false;
    //   this.jobsService.notify(
    //     'error',
    //     'Your first and last name do not match the signature on the affirmation. Please verify your input and try again.',
    //   );
    //   return;
    // }

    if (!this.jobsService.company?.sponsoring_applicants && this.appForm.value.visa_required) {
      this.loading = false;
      this.jobsService.notify(
        'error',
        `Unfortunately, we are not accepting applicants who require visa sponsorship at this time. Thank you for your interest in ${this.jobsService.company?.name}.`,
      );
      return;
    }

    if (!this.appForm.value.authorized && this.jobsService.company?.authorized_applicants) {
      this.loading = false;
      this.jobsService.notify(
        'error',
        `Unfortunately, we are not accepting applicants who are not authorized to work in the U.S. at this time. Thank you for your interest in ${this.jobsService.company?.name}.`,
      );
      return;
    }

    // Here we passed all the validations and are actually submitting the application
    this.createJobApplication();
  }

  setClearance(clearanceId: number) {
    const clearance = this.clearances.find((c) => c.value === clearanceId);
    this.form.clearance.setValue(clearance?.text);
  }

  nameMatchesSignature(): boolean {
    return (
      this.appForm.value.affirmation_signature.trim().toLowerCase() ===
      `${this.appForm.value.name_first.trim()} ${this.appForm.value.name_last.trim()}`.toLowerCase()
    );
  }

  createJobApplication(): void {
    const s = this.jobsService
      .createJobApplication(this.createApplicant())
      .subscribe((response: any) => {
        const successMessage = response.data?.createJobApplication?.message;
        if (successMessage) {
          // Extract autoIngest and autoReject values from the success message
          const autoIngestMatch = successMessage.match(/autoIngest is (true|false)/i);
          const autoRejectMatch = successMessage.match(/autoReject is (true|false)/i);
          if (autoIngestMatch) {
            this.autoIngest = autoIngestMatch[1] === 'true';
          }

          if (autoRejectMatch) {
            this.autoReject = autoRejectMatch[1] === 'true';
          }
        }
        this.handleSuccess();
      });
    this._subs.add(s);
  }

  createApplicant(): JobApplicationInput {
    const form = this.appForm.value;

    const applicant = (({ authorized, visa_required, affirmation_signature, ...form }) => form)(form);
    applicant.job_id = this.jobIdFromRoute || this.jobsService?.selectedJob?.id;
    if (form.questionnaire_id) {
      applicant.questionnaire_id = form.questionnaire_id;
      applicant.questionnaire_model = JSON.stringify(form.questionnaire_model);
      applicant.questionnaire_result = JSON.stringify(form.questionnaire_result);
    }
    return applicant;
  }

  handleSuccess(): void {
    this.loading = false;
    if (!this.clearancesMatch() && this.autoReject) {
      this.jobsService.notify(
        'error',
        `Unfortunately, your security clearance level does not satisfy the security clearance requirements for this job. ${
          this.autoIngest
            ? 'We will be retaining your appliation information in case another job fits your skills and experience. '
            : ''
        }Thank you for your interest in this position at ${this.jobsService.company?.name}.`,
        10000,
      );
    } else {
      this.jobsService.notify(
        'success',
        'Excellent! Your job application has been successfully submitted!',
      );
    }
  }

  handleError(err: Error): void {}

  /** clearancesMatch ensures that the user has a clearance level high enough to apply for the job. */
  clearancesMatch(): boolean {
    const applicantClearance = this.form.clearance.value;
    const jobClearance = this.jobsService?.selectedJob?.security_clearance;

    if (!jobClearance) {
      return true;
    }

    const clearanceRanking = {
      Unknown: 1,
      None: 1,
      'Public Trust': 3,
      Secret: 4,
      TS: 5,
      'TS/SCI': 6,
      'TS/SCI + CI Poly': 7,
      'TS/SCI + Full Scope Poly': 8,
    };

    const applicantClearanceRank = clearanceRanking[applicantClearance];
    const jobClearanceRank = clearanceRanking[jobClearance];

    if (!applicantClearanceRank || !jobClearanceRank) {
      return true;
    }

    return clearanceRanking[applicantClearance] >= clearanceRanking[jobClearance];
  }

  handleResumeParseCompletion(event: ResumeIngestStatusResponse) {
    this.appForm.patchValue({ bucket_key: event?.object_key });
  }

  cancelApplication(): void {
    this.formHasErrors = false;
    this.resumeUploadComponent.file = null;
    this.appForm.patchValue({ bucket_key: '' });
  }

  get form(): any {
    return this.appForm.controls;
  }

  setApplicantStatus() {
    const form: any = this.appForm?.controls;

    if (!form) {
      console.log('no form');
      return;
    }
    console.log(this.appForm?.controls);
    this.applicantStatus = [
      form['name_first'].invalid,
      form['name_last'].invalid,
      form['mobile_phone'].invalid,
      form.personal_email.invalid,
      form.city.invalid,
      form.state.invalid,
      form.zip.invalid,
    ];
    this.clearanceStatus = [form.clearance.invalid];
    this.employmentStatus = [form.authorized.invalid, form.visa_required.invalid];
    this.resumeStatus = [form.bucket_key.invalid];
    this.affirmationStatus = [
      form.affirmation_signature.invalid,
      form.gov_employment_status.invalid,
      form.gov_contract_participant.invalid && !this.isGovParticipationStatusRequired,
    ];
    this.acknowledgement = [form.acknowledgement.invalid];
    this.questionnaireStatus = [form?.questionnaire_result?.invalid];
    this.eeoStatus = [!form.gender.value, !form.race.value, !form.veteran_status.value];

    this.disabilityStatus = [!form.has_disability.value];
  }
}
