import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { JobsService } from '../../services/jobs.service';
import { JobDetailsComponent } from '../job-details/job-details.component';
import { BottomSheetService } from 'src/app/services/bottomsheet.service';

@Component({
  selector: 'agile-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
})
export class JobCardComponent implements OnInit {
  @Input() job: any;
  @Input() showRemoveIcon = false;
  indeed_opt_in: boolean = false;
  constructor(
    private router: Router,
    public jobsService: JobsService,
    public deviceDetectorService: DeviceDetectorService,
    private bottomSheetService: BottomSheetService,
  ) {}

  ngOnInit(): void {
    this.indeed_opt_in = this.jobsService?.company?.indeed_opt_in;
  }

  navigateToJob(job: any): void {
    this.jobsService.selectedJob = job;
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      const jobsRoute = this.jobsService.isEmbedded ? 'jobs' : 'jobs';
      this.router.navigate([`${jobsRoute}/details/${job.id}`]);
    }
  }

  openSheet(job: any): void {
    this.jobsService.selectedJob = job;
    this.bottomSheetService.openSheet(JobDetailsComponent);
  }
}
