<div>
  <div *ngIf="displayCondition" style="margin-bottom: 10px" id="formRow">
    <mat-form-field
      appearance="outline"
      [floatLabel]="floatLabel"
      [color]="invalidCondition || requiredCondition ? 'warn' : 'primary'"
      [isRequired]="required"
      [ngClass]="{ invalidError: invalidCondition || requiredCondition }"
    >
      <mat-label [ngClass]="{ invalidText: invalidCondition || requiredCondition }">{{
        invalidCondition || requiredCondition
          ? value && invalidCondition
            ? labelName + ' is invalid and required'
            : labelName + ' is required'
          : labelName
      }}</mat-label>

      <div class="d-flex w-100">
        <input
          *ngIf="inputType !== 'currency' && inputType !== 'textarea' && inputType !== 'number'"
          matInput
          [type]="inputType"
          [(ngModel)]="value"
          class="custom-form-input"
          [placeholder]="placeholder"
          (keyup)="keyupFn.emit($event); onInputChange($event)"
          (change)="onInputChange($event)"
          (blur)="onTouched()"
          [ngClass]="{ 'input-error': invalidCondition || requiredCondition }"
          [disabled]="(disabled || readOnly) && roIsDisabled"
          [required]="required"
          ng-readonly="readOnly"
          [attr.maxLength]="maxLength ? maxLength : null"
          (input)="setUpperCase ? (value = value.toUpperCase()) : null"
        />

        <!-- The `currencyMask` which should be on this input prevents the form group from updating with the user entered value -->
        <input
          *ngIf="inputType === 'currency'"
          matInput
          [type]="number"
          [(ngModel)]="value"
          class="custom-form-input"
          [placeholder]="placeholder"
          (keyup)="keyupFn.emit($event); onInputChange($event)"
          (change)="onInputChange($event)"
          (blur)="onTouched()"
          [ngClass]="{ 'input-error': invalidCondition || requiredCondition }"
          [disabled]="(disabled || readOnly) && roIsDisabled"
          [required]="required"
          ng-readonly="readOnly"
        />
        <input
          *ngIf="inputType === 'number'"
          matInput
          [type]="inputType"
          [(ngModel)]="value"
          class="custom-form-input"
          [placeholder]="placeholder"
          (keyup)="keyupFn.emit($event)"
          (change)="onInputChange($event)"
          (blur)="onTouched()"
          [ngClass]="{ 'input-error': invalidCondition || requiredCondition }"
          [disabled]="(disabled || readOnly) && roIsDisabled"
          [required]="required"
          ng-readonly="readOnly"
        />
      </div>
    </mat-form-field>
  </div>
</div>
