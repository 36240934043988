<mat-expansion-panel [expanded]="expanded" [hideToggle]="hideToggle">
  <mat-expansion-panel-header #panel>
    <span class="check-icon" *ngIf="showStatus" [ngClass]="completed ? 'color-success' : 'color-basic'">
      <mat-icon>check_circle_outline</mat-icon>
    </span>
    <mat-panel-title>
      <ng-content select="[title]"></ng-content>
    </mat-panel-title>
    <mat-panel-description>
      <ng-content select="[description]"></ng-content>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-content select="[body]"></ng-content>
</mat-expansion-panel>
