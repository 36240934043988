import { FocusOptions } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'; // prettier-ignore
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Apollo } from 'apollo-angular';
import isEqual from 'lodash/isEqual';
import unionWith from 'lodash/unionWith';

@Component({
  selector: 'agile-select-foo',
  templateUrl: './select-foo.component.html',
  styleUrls: ['./select-foo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFooComponent),
      multi: true,
    },
  ],
})
export class SelectFooComponent implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() placeholder = '';
  @Input() optionsQuery;
  @Input() readOnly = false;
  @Input() optionsSubscription;
  @Input() queryName = 'MyQuery';
  @Input() floatLabel: string;
  @Input() optionsQueryVars;
  @Input() clearable = true;
  @Input() fillWidth = true;
  @Input() closeOnSelect = true;
  @Input() bindValue: string;
  @Input() bindLabel = 'text';
  @Input() labelName: string;
  @Input() dataCy: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() notFoundText = 'No items found';
  @Input() invalid = false;
  @Input() showUserImage = false;
  @Input() showWorkEmail = false;
  @Input() showMultiUserImage = false;
  @Input() coloredSelectedLabel = false;
  @Input() enableWhiteLabeling = true;
  @Input() _value: any;
  @Input() items = [];
  @Input() map: (obj: any) => any;
  @Input() required = false;
  @Input() networkOnly = false;
  @Input() displayType: 'contract' | 'job' | 'default' = 'default';
  @Input() activeWorkflowId: number;
  @Input() addButtonText = '';
  @Input() chipValueLimit = 3;
  @Input() maxShowableItems = 4;
  @Input() infiniteScroll = false; // Toggle this if you have gql that supports limit and query params
  @Input() multiple = true;
  @Input() enableAllOption = false;
  @Input() enableNoneOption = false;
  @Input() enableNAOption = false;
  @Input() enableSelectAll = false;
  @Output() keyupFn = new EventEmitter();
  @Output() valueChange = new EventEmitter<any[]>();
  @Output() input = new EventEmitter<any[]>();
  @Output() listOfItemsAcquired = new EventEmitter<any[]>();
  @Output() workflowChanged = new EventEmitter();
  @Output() optionChanged = new EventEmitter();
  @Output() addButtonClicked = new EventEmitter();
  @Output() touched = new EventEmitter();
  customFocusOptions: FocusOptions = {
    preventScroll: true,
  };
  @ViewChild('multiSelect', { static: false }) multiSelect: MatSelect;

  itemIdToTextMap: { [value: string]: string } = {};
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('selectSearch') selectSearch: ElementRef;
  selectedItem: any;
  search = '';

  viewIndex = 10;
  windowSize = 10;
  private readonly PIXEL_TOLERANCE = 3.0;

  filteredLength(): number {
    return this.value.filter((v) => v !== 0).length - this.maxShowableItems;
  }

  customFocusHandler(event: Event) {
    if (this.multiSelect) {
      setTimeout(() => {
        this.multiSelect.focus(this.customFocusOptions);
      }, 10); // Adjust the delay as needed
    }
  }

  defaultMap = (obj) => {
    if (!obj) {
      return {};
    }

    if (typeof obj === 'string') {
      return { text: obj };
    }

    return { ...obj, text: obj[this.bindLabel], value: obj[this.bindValue] };
  };

  constructor(private apollo: Apollo) {}

  async ngOnInit() {
    if (this.optionsSubscription) {
      this.subscribeToItems();
      return;
    }

    this.getItems();
  }

  ngAfterViewInit(): void {
    this.multiSelect.openedChange.subscribe(() => this.registerPanelScrollEvent());
  }

  handleSelectToggled(isOpen: boolean) {
    this.onTouched();
    if (isOpen) {
      this.search = '';
      this.selectSearch?.nativeElement?.focus();
    }
  }

  ngOnChanges() {
    if (!this.map) {
      this.map = this.defaultMap;
    }

    this.getItems();
  }

  commaSeperatedItems() {
    return this.value.map((val) => this.itemIdToTextMap[val]).join(', ');
  }

  sortItemsByMatch() {
    this.search = this.search.toLowerCase();
    if (this.search === '') {
      return this.items;
    }

    // Sort the items based on how closely they match the search term
    return this.items.sort((a, b) => {
      const aLabel = a[this.bindLabel].toLowerCase();
      const bLabel = b[this.bindLabel].toLowerCase();

      if (aLabel === bLabel) {
        return a[this.bindLabel].localeCompare(b[this.bindLabel]); // Sort alphabetically if the labels are the same
      }

      const aIndex = aLabel.indexOf(this.search.toLowerCase());
      const bIndex = bLabel.indexOf(this.search.toLowerCase());

      if (aIndex === -1) {
        return 1; // Move b towards the top if a doesn't contain the search term
      }
      if (bIndex === -1) {
        return -1; // Move a towards the top if b doesn't contain the search term
      }

      return aIndex - bIndex; // Sort based on the index of the search term
    });
  }

  getItems() {
    if (!this.optionsQuery && this.items && this.items.length > 0) {
      this.items = this.items?.map(this.map);
      this.listOfItemsAcquired.emit(this.items);
      this.setSelectedItem();
      this.itemIdToTextMap = {};
      this.items.forEach((item) => {
        this.itemIdToTextMap[item[this.bindValue]] = item[this.bindLabel];
      });
    } else if (this.optionsQuery && !this.infiniteScroll) {
      const s = this.apollo
        .watchQuery({
          query: this.optionsQuery,
          variables: this.getObjectQueryVars(),
          fetchPolicy: 'cache-and-network',
        })
        .valueChanges.subscribe(
          (result) => {
            const operation = this.optionsQuery.definitions.find(
              (def) => def.kind === 'OperationDefinition',
            );
            const operationName = operation && operation.name;
            this.items = (result.data[this.queryName] || result.data[operationName]).map(this.map);

            this.listOfItemsAcquired.emit(this.items);
            this.setSelectedItem();
            this.itemIdToTextMap = {};
            this.items.forEach((item) => {
              this.itemIdToTextMap[item[this.bindValue]] = item[this.bindLabel];
            });

            s.unsubscribe();
          },
          () => {
            s.unsubscribe();
          },
        );
    } else if (this.optionsQuery && this.infiniteScroll) {
      this.loadDataInfiniteScroll();
    }
  }

  compareFn(o1: any, o2: any) {
    return o1 === o2;
  }

  setSelectedItem() {
    this.selectedItem = this.items.find((item) => {
      return item[this.bindValue] === this.value;
    });

    this.valueChange.emit(this.value);
  }

  subscribeToItems() {
    if (this.optionsSubscription) {
      const operation = this.optionsQuery?.definitions.find((def) => def.kind === 'OperationDefinition');
      const operationName = operation && operation.name;

      const s = this.apollo
        .subscribe({
          query: this.optionsSubscription,
          variables: this.getObjectQueryVars(),
        })
        .subscribe(
          ({ data }) => {
            this.items = (data[this.queryName] || data[operationName]).map(this.map);
            this.listOfItemsAcquired.emit(this.items);
            this.setSelectedItem();
            s.unsubscribe();
          },
          (err) => {
            s.unsubscribe();
            console.error(err);
          },
        );
    } else {
      this.items = this.items?.map(this.map);
    }
    this.loading = false;
  }

  getObjectQueryVars() {
    const data =
      this.optionsQueryVars instanceof Function ? this.optionsQueryVars() : this.optionsQueryVars;
    return data;
  }

  @Input()
  get value(): any {
    return this._value;
  }

  get selectedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.filter((item) => this.value.includes(item.value));
  }

  set value(newValue: any) {
    if (this._value !== newValue) {
      this._value = newValue;

      this.onChange(newValue);
      this.writeValue(newValue);
      this.input.emit(newValue);
      this.valueChange.emit(newValue);
    }
  }

  optionChangedHandler(event) {
    this.optionChanged.emit(event.value);
    if (this.items.length) {
      this.setSelectedItem();
    }
  }

  addButtonHandler() {
    this.addButtonClicked.emit();
  }

  /** Functions neccesary for formControl to work */
  onChange = (value) => {
    this.valueChange.emit(value);
    return value;
  };

  onTouched = () => {};

  writeValue(value: any) {
    if (this.items?.length) {
      this.setSelectedItem();
    }

    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  customSearchFunction(searchTerm: string, item: any) {
    return JSON.stringify(item).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
  }

  registerPanelScrollEvent() {
    const panel = this.multiSelect?.panel?.nativeElement;
    panel?.addEventListener('scroll', (event) => this.loadNextOnScroll(event));
  }

  searchChange() {
    this.sortItemsByMatch();
    if (this.infiniteScroll) {
      this.getItems();
    }
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      const allValues = [0];
      this.items.forEach((item) => {
        allValues.push(item[this.bindValue]);
      });

      this.value = allValues;
    } else {
      this.value = [];
    }
  }

  loadDataInfiniteScroll() {
    // infinte scroll queries must take to args limit and query
    const s = this.apollo
      .query({
        query: this.optionsQuery,
        variables: {
          limit: this.viewIndex,
          query: `%${this.search.split(' ')[0]}%`,
          query2: `%${this.search.split(' ')[1] || ''}%`,
        },
      })
      .subscribe(
        (result) => {
          const operation = this.optionsQuery.definitions.find(
            (def) => def.kind === 'OperationDefinition',
          );
          const operationName = operation && operation.name;
          const data = (result.data[this.queryName] || result.data[operationName]).map(this.map);
          const mergedData = unionWith(this.items, data, isEqual);
          this.items = mergedData.sort((a, b) => a[this.bindLabel] - b[this.bindLabel]);
          this.listOfItemsAcquired.emit(this.items);
          this.setSelectedItem();
          this.items.forEach((item) => {
            this.itemIdToTextMap[item[this.bindValue]] = item[this.bindLabel];
          });
          this.multiSelect._keyManager.setActiveItem(this.viewIndex - this.windowSize);

          s.unsubscribe();
        },
        () => {
          s.unsubscribe();
        },
      );
  }

  loadNextOnScroll(event) {
    if (this.hasScrolledToBottom(event.target) && this.infiniteScroll) {
      if (!(this.viewIndex > this.items.length)) {
        this.viewIndex += this.windowSize;
        this.getItems();
      }
    }
  }

  private hasScrolledToBottom(target): boolean {
    return Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) < this.PIXEL_TOLERANCE;
  }

  reset() {
    this.viewIndex = 10;
  }

  getItemIdToTextMap(selected) {
    return this.itemIdToTextMap[selected];
  }
}
